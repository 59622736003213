import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { PostTag, PostDetailResponse } from "../api/post/model";
import { getPostDetail } from "../api/post/api";

import { TFunction } from "react-i18next";
import FeedStore from "../store/FeedStore";
import MainStore from "../store/MainStore";
import { MainFeedTypeCode } from "../constants/FeedType.enum";
import { Post } from "../api/feed/model";

interface ContentsType {
  contentIdx: number;
  mediaIdx: number;
  content: { [key: string]: any };
}

interface carouselIdxListType {
  contentId: number;
  carouselIdx: number;
}
class PostDetailStore {
  @observable errorMessage?: string = "";
  @observable t?: TFunction;

  @observable _loading = false;

  @observable _postDetailResult: PostDetailResponse = {
    feedId: 0,
    feedTitle: "string",
    likeCount: 0,
    commentCount: 0,
    viewCount: 0,
    rating: 0,
    advantage: "string",
    disadvantage: "string",
    contents: [],
    productList: [],
    tagList: [],
    memberName: "",
    nickname: "",
    profileUrl: "",
    profileBackgroundColor: "",
    likeYn: "N",
    bookmarkYn: "N",
    createdDatetime: new Date().toString(),
    createMemberUuid: "string",
    updatedDatetime: new Date().toString(),
    updateMemberUuid: "string",
    previewContents: "",
    openApiFlag: false,
  };

  @observable _postTagList: PostTag[] = [];

  @observable _postContent: any = {
    rating: 0,
    contents: [],
    products: [],
    advantage: "",
    disadvantage: "",
  };

  @observable _likeYn = "N";
  @observable _bookmarkYn = "N";
  @observable _likeCount = 0;

  @observable _postIndex = 0;
  @observable _listIndex: number | undefined = 0;
  @observable _feedId: number | undefined = 0;

  @observable _carouselIdxList: carouselIdxListType[] = [];

  @computed get postDetailResult() {
    return this._postDetailResult;
  }

  @computed get postTags() {
    return this._postTagList;
  }

  @computed get postContent() {
    return this._postContent;
  }

  @computed get likeYn() {
    return this._likeYn;
  }

  @computed get bookmarkYn() {
    return this._bookmarkYn;
  }

  @computed get likeCount() {
    return this._likeCount;
  }

  @computed get postIndex() {
    return this._postIndex;
  }

  @computed get listIndex() {
    return this._listIndex;
  }

  @computed get carouselIdxList() {
    return this._carouselIdxList;
  }

  @computed get loading() {
    return this._loading;
  }

  @computed get feedId() {
    return this._feedId;
  }

  constructor() {
    makeObservable(this);
  }

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    );
  }

  @action
  private _clear = () => {
    this.errorMessage = "";

    this._feedId = 0;

    this._postDetailResult = {
      feedId: 0,
      feedTitle: "",
      likeCount: 0,
      commentCount: 0,
      viewCount: 0,
      rating: 0,
      advantage: "",
      disadvantage: "",
      contents: [],
      productList: [],
      tagList: [],
      memberName: "",
      nickname: "",
      profileUrl: "",
      profileBackgroundColor: "",
      likeYn: "N",
      bookmarkYn: "N",
      createdDatetime: new Date().toString(),
      createMemberUuid: "",
      updatedDatetime: new Date().toString(),
      updateMemberUuid: "",
      previewContents: "",
      openApiFlag: false,
    };

    this._postTagList = [];

    this._postContent = {
      rating: 0,
      contents: [],
      products: [],
      advantage: "",
      disadvantage: "",
    };

    this._likeYn = "N";
    this._bookmarkYn = "N";
    this._likeCount = 0;

    this._carouselIdxList = [];
  };
  public get clear() {
    return this._clear;
  }
  public set clear(value) {
    this._clear = value;
  }

  @action popPostFromMainAndFeed = (feedId: number) => {
    MainStore.popFromMainList(feedId, [
      MainFeedTypeCode.POPULAR_POST,
      MainFeedTypeCode.MAIN_POST,
      MainFeedTypeCode.MAIN_POST_SINGLE,
    ]);
    FeedStore.popPostFromFeed(this.postIndex);
  };

  @action getPostDetail = async (feedId: number, isClear = true) => {
    isClear && this.clear();

    this.setLoading(true);

    const res = await getPostDetail(feedId);

    if (res != null) {
      this.setPostDetailResult(res);
      this.setError("");
    } else {
      this.setError("No Data");
    }

    this.setLoading(false);

    return res;
  };

  @action setPostDetailResult = (postDetail: PostDetailResponse) => {
    this._postDetailResult = postDetail;

    this.setPostTags();
    this.setPostContent();
    this.setLikeYn(this._postDetailResult.likeYn);
    this.setBookmarkYn(this._postDetailResult.bookmarkYn);
    this.setLikeCount(this._postDetailResult.likeCount);
  };

  @action setPostContent = () => {
    const postDetail = this._postDetailResult;

    this._postContent = {
      rating: postDetail?.rating,
      contents: postDetail?.contents,
      products: postDetail?.productList,
      advantage: postDetail?.advantage,
      disadvantage: postDetail?.disadvantage,
    };
  };

  @action setPostTags = () => {
    this._postTagList = this._postDetailResult.tagList;
  };

  @action setLikeYn = (likeYn: string) => {
    this._likeYn = likeYn;
  };

  @action setBookmarkYn = (bookmarkYn: string) => {
    this._bookmarkYn = bookmarkYn;
  };

  @action increaseLikeCount = () => {
    this._likeCount += 1;
  };

  @action decreaseLikeCount = () => {
    this._likeCount -= 1;
  };

  @action syncCommentCount = (feedId: number, newPost: Post) => {
    if (newPost.commentCount && feedId === this._postDetailResult.feedId) {
      this._postDetailResult.commentCount = newPost?.commentCount ?? 0;
    }
  };

  @action setLikeCount = (count: number) => {
    this._likeCount = count;
  };

  @action setT = (t: TFunction) => {
    this.t = t;
  };

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action setIndexes = (postIndex: number, listLindex?: number) => {
    this._postIndex = postIndex;
    this._listIndex = listLindex;
  };

  @action addCarouselIdxList = (contentId: number, carouselIdx: number) => {
    this._carouselIdxList.push({
      contentId: contentId,
      carouselIdx: carouselIdx,
    });
  };

  @action setCarouselIdxList = (contentId: number, carouselIdx: number) => {
    const carousel = this.getCarouselIdxList(contentId);

    if (carousel) {
      carousel.carouselIdx = carouselIdx;
    }
  };

  @action getCarouselIdxList = (contentId: number) => {
    return this._carouselIdxList.filter(
      (data) => data.contentId === contentId
    )[0];
  };

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action setFeedId = (feedId: number) => {
    this._feedId = feedId;
  };
}

export default new PostDetailStore();
