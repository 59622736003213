export const MISSION_RESULT_COUNT = 10;

export enum CouponStatus {
  ACTIVE = "active",
  EXPIRED = "expired",
}

export enum CouponType {
  MANUAL_BARCODE = "MANUAL_BARCODE",
  MANUAL_GIFT = "MANUAL_GIFT",
}

export enum CouponSortType {
  END = "end",
  CREATED = "created",
}
