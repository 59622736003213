import React, { useCallback, useEffect, useState } from 'react'
import TitleHeader from '../../../components/common/TitleHeader'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useEffectOnlyOnce } from '../../../hooks/UseEffectOnlyOnce'
import { CustomActivityIndicator } from '../../../components/common'

import SettingStore from '../../../store/SettingStore'
import AuthStore from '../../../store/AuthStore'
import { getDateFormatKo } from '../../../utils/datetime'
import { postMessageCommon } from '../../../native/WebViewInterface'
import classes from './NoticeDetail.module.scss'
import { goTo } from '../../../hooks/navigate/NavigateFunction'
import clsx from 'clsx'
import { useLocation, useParams } from 'react-router-dom'
import MainStore from '../../../store/MainStore'

const NoticeDetail = observer(() => {
  const { t } = useTranslation()
  const { sessionUser } = AuthStore

  const { noticeId } = useParams() || useLocation().state

  const {
    getNoticeDetail,
    noticeDetail,
    noticeWebviewContent,
    clearNoticeDetail,
  } = SettingStore
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [webviewHeight, setWebviewHeight] = useState(0)

  useEffectOnlyOnce(
    () => {
      setIsLoading(true)
      noticeId &&
        void getNoticeDetail(noticeId).finally(() => {
          setIsLoading(false)
        })
    },
    () => {
      clearNoticeDetail()
    }
  )

  const webViewInterfaceFunction = useCallback(
    (jsonString: string) => {
      if (!postMessageCommon(jsonString)) {
        const { type, payload } = JSON.parse(jsonString)
        switch (type) {
          case 'NAVIGATE':
            if (sessionUser?.memberUuid === undefined) {
              MainStore.setShowJoinModal(true)
            } else {
              goTo(payload)
            }
            break
          case 'SET_HEIGHT':
            setWebviewHeight(Number(payload))
            break
          default:
            break
        }
      }
    },
    [sessionUser?.memberUuid]
  )

  useEffect(() => {
    ;(window as any).ReactNativeWebView.postMessage = webViewInterfaceFunction
  }, [webViewInterfaceFunction])

  return (
    <div style={{ flex: 1 }}>
      <div className={classes.notice_detail} aria-label="Notice Detail">
        <TitleHeader title={t('screen.setting.label.notice')} />
        <div className={classes.scroll}>
          <div className={clsx(classes.option_box, classes.option_box_border)}>
            <div className={classes.option}>
              <div className={classes.top_wrap}>
                <span className={classes.option_txt} aria-label="Notice Title">
                  {noticeDetail.noticeTitle}
                </span>
              </div>
              <span className={classes.txt_date}>
                {!isLoading && getDateFormatKo(noticeDetail.createdDatetime)}
              </span>
            </div>
          </div>
          <div className={classes.contents_wrap}>
            <div
              dangerouslySetInnerHTML={{ __html: noticeWebviewContent }}
              className={clsx(classes.web_view)}
            />
          </div>
        </div>
      </div>
      {isLoading && <CustomActivityIndicator />}
    </div>
  )
})

export default NoticeDetail
