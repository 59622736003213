import { useTranslation } from "react-i18next";
import { Arranges, Colors, FlexBoxs, Spacings, Texts } from "../../../assets/styles";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { observer } from "mobx-react";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import AuthStore from "../../../store/AuthStore";
import { Styles } from "../../../assets/types/Style";
import { BrandListTabType, BrandSubscribeResponse } from "../../../api/brand/model";
import { ReactComponent as IconArrowPurple } from "../../../assets/icons/icon_arrow_right_purple.svg";
import { ReactComponent as IconMoreArrow } from "../../../assets/icons/icon_more_arrow.svg";
import Slider from "react-slick";

const MainSubscribeList = observer(
    ({
        renderData,
        goToBrandHome,
    }: {
        renderData: BrandSubscribeResponse[];
        goToBrandHome: (comunityId: number) => void;
    }) => {
        const { t } = useTranslation();

        const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
        const isLoggedIn = AuthStore.sessionUser?.memberUuid ? true : false;

        const moveTo = (item: BrandSubscribeResponse) => {
            goToBrandHome(item.communityId);
        };

        const profile = isLoggedIn
            ? `${PUBLIC_BUCKET_URL}${AuthStore.sessionUser?.profileUrl || ""}`
            : AuthStore.tempMember?.profileUrl
                ? `${PUBLIC_BUCKET_URL}${AuthStore.tempMember?.profileUrl || ""}`
                : `data:image/jpg;base64,${AuthStore.tempMember?.profileImageFromGallary?.base64 || ""}`;

        const sliderHorizontalsettings = {
            className: "center",
            infinite: false,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            variableWidth: true
        };

        const renderItem = ({ item, index }: { item: BrandSubscribeResponse; index: number }) => {
            return (
                <div style={{ ...styles.recommend_user_item, ...(!index ? styles.first_item : {}), position: 'relative' }}>
                    <button onClick={() => moveTo(item)}
                    >
                        <FastImageWithFallback
                            style={styles.recommend_user_image}
                            source={{ uri: `${PUBLIC_BUCKET_URL}${item.communityLogoUrl || ""}` }}
                        />
                        <div style={styles.bx_brand_name}>
                            <p style={styles.txt_brand_name}>
                                {item.communityName}
                            </p>
                        </div>
                    </button>
                    {item.todayArticleCreatedYn === "Y" && (
                        <div style={styles.flag_new_brand}>
                            <p style={styles.txt_new_brand}>NEW</p>
                        </div>
                    )}
                </div>
            );
        };

        return (
            <>
                {renderData.length > 0 ? (
                    <>
                        <div style={styles.my_wrap}>
                            <div style={styles.my}>
                                <FastImageWithFallback
                                    style={styles.profile_img}
                                    source={{ uri: profile }}
                                    fallbackImageUri={profile}
                                />

                                <div style={styles.my_text_wrap}>
                                    <div style={styles.my_text_wrap1}>
                                        <p style={styles.my_nickname}>
                                            {isLoggedIn ? AuthStore.sessionUser?.nickname : AuthStore.tempMember?.nickname}
                                        </p>
                                        <p style={styles.mimi_kim}>{t("screen.brandCommunity.message.selectBrand")}</p>
                                    </div>
                                </div>
                            </div>
                            <button
                                style={styles.bx_more}
                                onClick={() => {
                                    goTo("/BrandCommunityList", { state: { brandListType: BrandListTabType.SUBSCRIBING } });
                                }}
                            >
                                <p style={styles.txt_more}>{t("screen.brandCommunity.button.more")}</p>
                                <IconMoreArrow />
                            </button>
                        </div>
                        <div style={styles.recommend_users}>
                            <Slider {...sliderHorizontalsettings}>
                                {renderData.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {renderItem({ item, index })}
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    </>
                ) : (
                    <div style={{ ...styles.my_wrap, marginBottom: 5 }}>
                        <div style={styles.my_first}>
                            <div style={styles.my_first_profile}>
                                <FastImageWithFallback
                                    style={styles.profile_img}
                                    source={{ uri: profile }}
                                    fallbackImageUri={profile}
                                />
                                <div style={styles.my_text_wrap1}>
                                    <p style={styles.my_nickname}>
                                        {isLoggedIn ? AuthStore.sessionUser?.nickname : AuthStore.tempMember?.nickname}
                                    </p>
                                    <p style={styles.mimi_kim}>{t("screen.brandCommunity.message.firstWelcome")}</p>
                                </div>
                            </div>

                            <div style={{ marginTop: 15, marginLeft: 10 }}>
                                <div style={styles.my_first_text_wrap}>
                                    <p style={styles.my_first_text1}>{t("screen.brandCommunity.message.firstWelcomeGuide")}</p>
                                </div>
                                <div>
                                    <button
                                        style={styles.lk_search_brand}
                                        onClick={() => {
                                            goTo("/BrandCommunityList", { state: { brandListType: BrandListTabType.TOTAL } });
                                        }}
                                    >
                                        <p style={styles.txt_search_brand}>{t("screen.brandCommunity.button.findBrand")}</p>
                                        <IconArrowPurple />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
);

export default MainSubscribeList;

const styles: Styles = {
    bx_brand_name: {
        width: 64,
        marginTop: 7,
    },
    txt_brand_name: {
        fontSize: 12,
        color: "#666",
        textAlign: "center",
        letterSpacing: -0.48,
        fontWeight: "400",
        overflow: 'hidden', 
        display: '-webkit-box', 
        WebkitLineClamp: '2', 
        lineClamp: '2', 
        WebkitBoxOrient: 'vertical'
    },
    recommend_users: {
        ...Colors.background_255,
    },
    recommend_user_image: {
        width: 64,
        height: 64,
        borderRadius: 32,
    },
    recommend_users_text1: {
        ...Spacings.padding_left_16_responsive,
        ...Spacings.margin_bottom_10,
        ...Texts.contents_text_28,
        ...Texts.font_weight_500,
        ...Colors.font_333,
    },
    recommend_user_item: {
        ...Spacings.padding_top_16,
        ...Spacings.margin_right_16,
    },
    first_item: {
        ...Spacings.margin_left_16,
    },
    flag_new_brand: {
        position: "absolute",
        right: -10,
        top: 10,
        padding: '0px 5px',
        backgroundColor: "#F7DE2A",
        borderRadius: 300,
        paddingVertical: 1,
        paddingHorizontal: 6,
    },
    txt_new_brand: {
        fontSize: 10,
        color: "#222",
        fontWeight: "700",
    },
    my_wrap: {
        ...Spacings.padding_right_left_16,
        marginTop: 14,
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
        justifyContent: "space-between",
    },
    bx_more: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
    },
    txt_more: {
        fontSize: 14,
        fontWeight: "400",
        letterSpacing: -0.56,
        color: "#999",
        marginRight: 2,
    },
    my: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
    },
    profile_img: {
        width: 36,
        height: 36,
        marginRight: 6,
        borderRadius: 300,
        backgroundColor: "#fff",
    },
    my_text_wrap: {},
    my_text_wrap1: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
    },
    my_nickname: {
        fontSize: 14,
        color: "#ca57db",
        fontWeight: "700",
    },
    mimi_kim: {
        fontSize: 14,
        letterSpacing: -0.56,
        textAlign: "left",
        color: "#222",
        marginBottom: 5,
    },
    my_first: {
        ...FlexBoxs.flex,
        ...FlexBoxs.vertical,
    },
    my_first_profile: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
        ...Spacings.margin_bottom_10,
    },
    my_first_text_wrap: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
    },
    my_first_text1: {
        color: "#222",
        fontSize: 16,
        fontWeight: "500",
        letterSpacing: -0.64,
    },
    lk_search_brand: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
        marginTop: 4,
    },
    txt_search_brand: {
        fontSize: 14,
        letterSpacing: -0.56,
        color: "#BE69C2",
    },
};
