import { CommonReport } from "../../store/ReportStore";
import { callAPIWithPOSTMethod } from "../common/api";
import { ReportResult } from "./model";

export const saveReport = async (commonReport: CommonReport, isLoading = true): Promise<ReportResult> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/report",
      body: commonReport,
    },

    isLoading
  );
  return response as ReportResult;
};
