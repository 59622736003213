import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { ReactComponent as TagIcon } from "../../../assets/icons/tag_result_icon.svg";
import SearchCountAndSort from "./SearchCountAndSort";
import {
  SearchSortType,
  SearchTabMenu,
  SEARCH_RESULT_COUNT,
} from "../../../constants/Search";
import SearchStore from "../../../store/SearchStore";
import SearchEmpty from "./SearchEmpty";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { SearchItem } from "../../../api/search/model";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { useTranslation } from "react-i18next";
import { PullToRefresh } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import { ReactComponent as DarkSpinner } from "../../../assets/animations/darkSpinner.svg";
import { useWindowScroll } from "react-use";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import "./SearchTag.scss";
import { useLocation } from "react-router-dom";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import SearchResultLayout from "../SearchResultLayout";
import AuthStore from "../../../store/AuthStore";
import MainStore from "../../../store/MainStore";

const SearchTag = observer(() => {
  const {
    searchText,
    selectedSearchTab,
    tagResult,
    loadingInList,
    tagSortAndIndex,
    getLoadingInList,
    refreshing,
    getSearchResult,
    setRefreshing,
    setToast,
  } = SearchStore;

  const { x, y } = useWindowScroll();

  const { t } = useTranslation();

  const [afterMount, setAfterMount] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setAfterMount(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (afterMount) {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

      if (bottom) {
        if ((tagResult?.totalCount || 0) > 0 && !loadingInList) {
          const tempStart = tagSortAndIndex.lastIndex + 1;
          if ((tagResult?.totalCount || 0) > tempStart * SEARCH_RESULT_COUNT) {
            void getSearchResult(
              searchText,
              SearchTabMenu.Tag,
              tagSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              tempStart
            );
          }
        }
      }
    }
  }, [y]);

  const moveToTagDetail = (item: SearchItem) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.TAG_CLICK,
      (item.tagId ?? 0).toString(), // feedId 값을 넣어달라 하는데.. feedId와 전혀 무관한 데이터
      ActPageCode.SEARCH_TAG,
      undefined,
      item.tagId?.toString(),
      searchText,
      tagSortAndIndex.sort
    );

    if (!AuthStore.sessionUser?.memberUuid) {
      MainStore.setShowJoinModal(true);
    } else {
      goTo(`/tag/${item.tagId}`, {
        state: { tagId: item.tagId },
      });
    }
  };

  return (
    <SearchResultLayout tabType={SearchTabMenu.Tag}>
      <div id="SearchTag" className="tab_content" aria-label="Search tag">
        <PullToRefresh
          onRefresh={async () => {
            await sleep(1000);
            setRefreshing(true);
            void getSearchResult(
              searchText,
              SearchTabMenu.Tag,
              tagSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              0
            );
          }}
          renderText={(status) => {
            return (
              <div>
                {status === "refreshing" && (
                  <DarkSpinner style={{ width: 50, height: 50 }} />
                )}
              </div>
            );
          }}
        >
          <SearchCountAndSort
            type={SearchTabMenu.Tag}
            sort={tagSortAndIndex.sort}
            count={tagResult?.totalCount || 0}
          />
          <div className="scroll_wrap tag_wrap_list">
            {tagResult?.list?.slice() && tagResult?.list?.slice().length > 0 ? (
              tagResult?.list?.slice().map((item: any, index: number) => {
                return (
                  <div
                    className="tag_item"
                    onClick={() => moveToTagDetail(item)}
                    aria-label={`Tag press ${index}`}
                  >
                    <TagIcon />
                    <div className="tag_wrap">
                      <div className="tag_title_text">{item.tagName}</div>
                      <div className="tag_count_text">
                        {t("screen.search.label.posts")}
                        {item.feedCount}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <SearchEmpty isGrid={false} />
            )}
          </div>

          {getLoadingInList ? <CustomActivityIndicatorSmall /> : <></>}
        </PullToRefresh>
      </div>
    </SearchResultLayout>
  );
});

export default SearchTag;
