import React, { useState } from "react";
import { observer } from "mobx-react";
import { PostedPopularProduct } from "../../../api/mall/model";
// import { track } from "../../../hooks/tracker/TrackFunction";

import { ReactComponent as BtnMore } from "../../../assets/icons/icon_more_arrow.svg";
import { ReactComponent as MallTag } from "../../../assets/icons/mall_sale_tag.svg";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
// import HomeScreen from "../Main";
import { useTranslation } from "react-i18next";
import CommonStore from "../../../store/CommonStore";
import "./TodayProduct.scss";
import Slider, { Settings } from "react-slick";
import { ReactComponent as UpTag } from "../../../assets/icons/up_product_label.svg";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

export const TodayProduct = observer(
  ({
    renderData,
    searchTitle,
    isMainSection = false,
  }: {
    renderData: PostedPopularProduct[] | [];
    searchTitle: boolean;
    isMainSection?: boolean;
  }) => {
    const { t } = useTranslation();
    const show_up_product_tag = getStringRemoteConfig("show_up_product_tag");

    const [isClickItem, setIsClickItem] = useState(true);

    const goToProduct = (productId: number) => {
      goTo(`/Product/${productId}`);
    };

    const goToMall = () => {
      // navigate("MainTab", {
      //   screen: "MallMainScreen",
      // });

      CommonStore.setShowDownloadAppDrive(true);
    };

    const settings: Settings = {
      infinite: false,
      arrows: false,
      dots: false,
      variableWidth: true,
      slidesToScroll: 1,
      slidesToShow: 2,
      focusOnSelect: true,
      beforeChange: () => {
        setIsClickItem(false);
      },
      afterChange: () => {
        setTimeout(() => {
          setIsClickItem(true);
        }, 100);
      },
    };

    return (
      <div id="TodayProduct">
        {!searchTitle && <div className="line_height_10" />}
        <div className={`bx_title ${searchTitle && "search"}`}>
          <div style={{ position: "relative" }}>
            {searchTitle ? (
              <div className="txt_search_title">
                {t("screen.brandCommunity.label.SearchTodayMallProduct")}
              </div>
            ) : (
              <>
                <div className="txt_title">
                  {t("screen.brandCommunity.label.todayMallProduct")}
                </div>
              </>
            )}
          </div>

          <div
            onClick={() => {
              goToMall();
            }}
          >
            <div className="bx_title_more">
              <div className="txt_title_more">
                {t("screen.brandCommunity.button.more")}
              </div>
              <BtnMore />
            </div>
          </div>
        </div>

        <div className="item_list">
          <Slider {...settings}>
            {renderData?.length > 0 &&
              renderData.map((item, index: number) => {
                const post = item;
                return (
                  <div
                    key={index}
                    className={`bx_item_wrap ${
                      index === 0
                        ? "first"
                        : index === renderData.length - 1
                        ? "end"
                        : ""
                    }`}
                  >
                    <div
                      onClick={
                        isClickItem
                          ? () => {
                              // track("click_mall_popularitem_post", {
                              //   product_id: item.productId || "",
                              //   product_name: item.productName ?? "",
                              //   content_order: item.sortOrder,
                              // });
                              goToProduct(item.productId);
                            }
                          : () => {}
                      }
                    >
                      <div className="bx_img">
                        <img
                          className="in_img"
                          src={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                            item.mainImagePath || ""
                          }`}
                          alt="in_img"
                        />
                      </div>
                      <div className="bx_info">
                        <div className="info_pd_manufacturer">
                          {item.brandName}
                        </div>
                        <div className="info_pd_name">{item.productName}</div>
                        <div style={{ display: "flex" }}>
                          {show_up_product_tag === "true" &&
                            item.upYn &&
                            item.upYn == "Y" && (
                              <div
                                className="bx_btn_mall"
                                style={{ marginRight: 4 }}
                              >
                                <UpTag />
                              </div>
                            )}
                          {item.lifecareProductId &&
                            item.lifecareProductId !== null && (
                              <div className="bx_btn_mall">
                                <div className="mall_tag">
                                  <MallTag />
                                </div>
                                <div className="txt_btn_mall">
                                  {t("screen.main.label.onSale")}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
          {/* {renderData?.length > 0 &&
            renderData.map((item, index: number) => {
              const post = item;
              return (
                <div
                  className={`bx_item_wrap ${
                    index === 0
                      ? "first"
                      : index === renderData.length - 1
                      ? "end"
                      : ""
                  }`}
                >
                  <div
                    onClick={() => {
                      // track("click_mall_popularitem_post", {
                      //   product_id: item.productId || "",
                      //   product_name: item.productName ?? "",
                      //   content_order: item.sortOrder,
                      // });
                      goToProduct(item.productId);
                    }}
                  >
                    <div className="bx_img">
                      <img
                        className="in_img"
                        src={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                          item.mainImagePath || ""
                        }`}
                        alt="in_img"
                      />
                    </div>
                    <div className="bx_info">
                      <div className="info_pd_manufacturer">
                        {item.brandName}
                      </div>
                      <div className="info_pd_name">{item.productName}</div>
                      {item.lifecareProductId &&
                        item.lifecareProductId !== null && (
                          <div className="bx_btn_mall">
                            <div className="mall_tag">
                              <MallTag />
                            </div>
                            <div className="txt_btn_mall">
                              {t("screen.main.label.onSale")}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              );
            })} */}
        </div>
        {!searchTitle && <div className="line_height_10" />}
        {isMainSection && (
          <div className="MainSectionFooter" style={{ height: "30px" }} />
        )}
      </div>
    );
  }
);
export default TodayProduct;
