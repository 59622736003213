import React, { useCallback, useEffect, useRef, useState } from 'react'
import './AbDetailScreen.scss'

import {
  Arranges,
  Assets,
  Colors,
  FlexBoxs,
  Icons,
  Positions,
  Spacings,
  Texts,
} from '../../assets/styles'
import { CustomActivityIndicator, TitleHeader } from '../../components/common'
import { ReactComponent as HeartOffIcon } from '../../assets/icons/heart_off.svg'
import { ReactComponent as HeartOnIcon } from '../../assets/icons/heart_on.svg'
import { ReactComponent as ChatIcon } from '../../assets/icons/chat.svg'
import { ReactComponent as BookmarkOffIcon } from '../../assets/icons/bookmark_off.svg'
import { ReactComponent as BookmarkOnIcon } from '../../assets/icons/bookmark_on.svg'
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg'
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as TopIcon } from '../../assets/icons/top_btn.svg'

import { observer } from 'mobx-react'
import { getDateStr, getDdayFromToday, isPast } from '../../utils/datetime'
import { Tag } from '../../api/feed/model'
import { AbDetailResult, AbImageSection } from '../../api/ab/model'
import { getNumberFormat } from '../../utils/number'

import LikeStore from '../../store/LikeStore'
import BookmarkStore from '../../store/BookmarkStore'
import CommonStore from '../../store/CommonStore'

import { HeaderItem } from '../../constants/Header'

import { CommentTypeCode } from '../../constants/Comment'
import AuthStore from '../../store/AuthStore'
import { NotificationModal } from '../../components/modals'
import { deleteAb } from '../../api/ab/api'
import TouchableWithAuthCheck from '../../components/common/TouchableWithAuthCheck'
import AbDetailStore from '../../store/AbDetailStore'
import MyHomeStore from '../../store/MyHomeStore'
import ReportStore from '../../store/ReportStore'
import AbListStore from '../../store/AbListStore'
import AccountStore from '../../store/AccountStore'
import TagStore from '../../store/TagStore'

import { sendActHistory } from '../../api/data/api'
import { ActHistoryTypeCode, ActPageCode } from '../../constants/ActHistory'
import { ActHistoryRequest } from '../../api/data/model'
import { AccountFeedTypeCode } from '../../constants/Account'
import { FeedTypeCode } from '../../constants/Feed'

import { ReportTargetTypeCode } from '../../constants/Report'
import AbEndDateBadge from './presenter/AbEndDateBadge'
import AbPercentBar from './presenter/AbPercentBar'
import { AbTypeCode } from '../../constants/ab'
import FastImageWithFallback from '../../components/common/FastImageWithFallback'
import QnaStore from '../../store/QnaStore'
import { UserActTypeCode } from '../../constants/FeedType.enum'
import { LikeTypeCode } from '../../constants/Like'
import { onShare, ShareInfo } from '../../utils/share'
import TouchableWithAsyncTask from '../../components/common/TouchableWithAsyncTask'

import AbMyChoice from './presenter/AbMyChoice'
import { ShareScreenCode } from '../../constants/ShareScreen.enum'
import { handleTopButtonScroll } from '../../utils/common'
import { useTracker } from '../../hooks/tracker'
import { AccountFeedItem } from '../../api/account/model'
import DetailReply from '../comment/presenter/DetailReply'
// import TopButton from "../../components/common/TopButton";
import { ErrorCode } from '../../constants/Error'
import { useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { canGoBack, goBack, goTo } from '../../hooks/navigate/NavigateFunction'
import { getSessionUserFromStorage } from '../../service/Session.service'
import JoinOverlapComponent from '../../components/common/JoinOverlapComponent'
import { useWindowScroll } from 'react-use'

const AbDetail = observer(({ route }: any) => {
  const location = useLocation()
  const params = location.state
  const feedId = useParams().feedId || (params.feedId as number)
  const isShowComment: boolean = params?.isShowComment as boolean
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL

  const {
    abDetail,
    setAbDetail,
    retrieveAbDetail,
    aContent,
    bContent,
    setAContent,
    setBContent,
    aVoteCount,
    bVoteCount,
    isShowBlockedModal,
    setIsShowBlockedModal,
    addAbVote,
    deleteAbVote,
    clearAbDetail,
  } = AbDetailStore

  const { saveLike, cancelLike } = LikeStore
  const { saveBookmark, cancelBookmark } = BookmarkStore
  const { setToastOption } = CommonStore
  const { sessionUser } = AuthStore
  const { openReportBottomSheet } = ReportStore
  const [loading, setLoading] = useState<boolean>(true)
  const { popAB } = AbListStore
  const [isConfirmModal, setIsConfirmModal] = useState(false)

  const loginUserId = String(sessionUser?.memberUuid)

  const { track } = useTracker()
  // const config = useRemoteConfig();

  const myChoice = aContent.isMyPick
    ? AbTypeCode.A
    : bContent.isMyPick
    ? AbTypeCode.B
    : null
  const winner = aContent.isWinner
    ? AbTypeCode.A
    : bContent.isWinner
    ? AbTypeCode.B
    : null
  const votingEnded = isPast(abDetail?.feedEndDate)
  const resultOpened = votingEnded || !!myChoice
  const optionToBeColored = votingEnded ? winner : myChoice

  const { getAccountStore } = MyHomeStore
  const loginUserAccountStore = getAccountStore(loginUserId)
  const accountStore = getAccountStore(String(params?.userId || ''))
  const [showTopIcon, setShowTopIcon] = useState(false)
  const { t } = useTranslation()
  const [modalButtonName, setModalButtonName] = useState<string>(
    t('common.label.check')
  )
  const [tracker] = useState(useTracker())
  const { pathname } = useLocation()
  const isAuthenticated = useRef<boolean>(false)

  const { x, y } = useWindowScroll()

  useEffect(() => {
    setTimeout(() => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
        actPageCode: ActPageCode.AB_DETAIL,
        actSourcePageCode: CommonStore.fixedPreviousRouteName,
        attr1: (y === 0).toString(),
      }
      void sendActHistory(actHistory)
    }, 1000)
  }, [])

  useEffect(() => {
    setLoading(true)

    const { retrieveAbDetail, setIsShowBlockedModal } = AbDetailStore

    void retrieveAbDetail(feedId as number)
      .then((abDetail) => {
        setTimeout(() => {
          if (!abDetail || !abDetail.feedId) {
            setIsShowBlockedModal(true)
          }
        }, 500)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [feedId, params])

  const moveToComment = useCallback(() => {
    goTo(`${pathname}/comment`, {
      state: {
        feedId: feedId,
        feedType: CommentTypeCode.AB,
      },
    })
  }, [feedId])

  useEffect(() => {
    getSessionUserFromStorage().then((res) => {
      isAuthenticated.current = res ? true : false
    })
  }, [isAuthenticated])

  useEffect(() => {
    if (isShowComment) {
      moveToComment()
    }
  }, [isShowComment, moveToComment])

  const updatedFeed = useCallback(
    (
      feedId: number,
      aVoteCount: number,
      bVoteCount: number,
      abDetail: AbDetailResult,
      aContent: AbImageSection,
      bContent: AbImageSection
    ) => {
      const feed = loginUserAccountStore?.getFeedFromList(
        AccountFeedTypeCode.AB,
        feedId
      ) as AccountFeedItem

      if (feed !== undefined) {
        feed.voterCount = aVoteCount + bVoteCount
        feed.processStatus =
          Number(getDdayFromToday(abDetail?.feedEndDate)) < 0
            ? 'PROCESSING'
            : 'DONE'
        feed.feedTitle = abDetail?.feedTitle
        feed.adesc = aContent.desc
        feed.athumbnailFilePath = aContent.thumbnailFilePath
        feed.bdesc = bContent.desc
        feed.bthumbnailFilePath = bContent.thumbnailFilePath
      }

      const ab = {
        feedTitle: abDetail?.feedTitle,
        adesc: aContent.desc,
        bdesc: bContent.desc,
        athumbnailFilePath: aContent.thumbnailFilePath,
        bthumbnailFilePath: bContent.thumbnailFilePath,
        avoteCount: aVoteCount,
        bvoteCount: bVoteCount,
        myAbFlag: aContent.isMyPick ? 'A' : bContent.isMyPick ? 'B' : null,
      }

      QnaStore.syncAb(feedId, ab, UserActTypeCode.MODIFY)
      loginUserAccountStore?.updateAb(feedId, ab)
      TagStore.updateAb(feedId, ab)
    },
    [loginUserAccountStore]
  )

  const deletedFeed = (feedId: number, accountStore: AccountStore) => {
    MyHomeStore.sync(feedId, AccountFeedTypeCode.AB, UserActTypeCode.DELETE)
    TagStore.sync(feedId, FeedTypeCode.AB, UserActTypeCode.DELETE)
    QnaStore.syncAb(feedId, undefined, UserActTypeCode.DELETE)
  }

  // useEffect(() => {
  //   return () => {
  //     if (params?.onLeave !== undefined) {
  //       params?.onLeave();
  //     }
  //     clearAbDetail();
  //     // void FastImage.clearMemoryCache();
  //   };
  // }, [params, clearAbDetail]);

  useEffect(() => {
    updatedFeed(
      abDetail?.feedId,
      aVoteCount,
      bVoteCount,
      abDetail,
      aContent,
      bContent
    )
  }, [abDetail, aVoteCount, bVoteCount, aContent, bContent, updatedFeed])

  useEffect(() => {
    tracker.track('view_ab_detail', {
      content_id: AbDetailStore.abDetail.feedId,
      content_title: AbDetailStore.abDetail.feedTitle,
      content_type: FeedTypeCode.AB,
      like_count: AbDetailStore.abDetail.likeCount || 0,
      comment_count: AbDetailStore.abDetail.commentCount || 0,
      tag:
        AbDetailStore.abDetail.tagList &&
        AbDetailStore.abDetail.tagList.map((t) => t.tagName),
      vote_count:
        (AbDetailStore.abDetail.avoteCount || 0) +
        (AbDetailStore.abDetail.bvoteCount || 0),
      voting_progress: isPast(AbDetailStore.abDetail.feedEndDate)
        ? '종료'
        : '진행 중',
      option_a_title: AbDetailStore.abDetail.adesc,
      option_b_title: AbDetailStore.abDetail.bdesc,
    })
  }, [tracker])

  const callActHistoryByHistoryType = (historyType: string, attr6?: string) => {
    const actHistory: ActHistoryRequest = {
      actHistoryTypeCode: historyType,
      actHistoryTargetId: feedId.toString(),
      actPageCode: ActPageCode.AB_DETAIL,
      actSectionCode: undefined,
      attr1: (params.index || 0).toString(),
      attr2: (abDetail?.likeCount || 0).toString(),
      attr3: (abDetail?.commentCount || 0).toString(),
    }
    if (attr6) {
      actHistory.attr4 = attr6
    }
    void sendActHistory(actHistory)
  }

  const onLikeButtonPress = async (feedId: number, likeYn: string) => {
    if (likeYn == 'Y') {
      await cancelLike(
        feedId,
        FeedTypeCode.AB,
        () => {
          //PPIT210117-4818 LIKE 취소 시 actHistory 처리 추가
          callActHistoryByHistoryType(ActHistoryTypeCode.LIKE, 'CANCEL')
          void retrieveAbDetail(feedId as number)
        },
        () => {
          //fail
        }
      )
    } else {
      await saveLike(
        feedId,
        FeedTypeCode.AB,
        () => {
          callActHistoryByHistoryType(ActHistoryTypeCode.LIKE)
          track('click_like_button', {
            content_id: feedId,
            content_title: abDetail.feedTitle,
            content_type: FeedTypeCode.AB,
            like_count: abDetail.likeCount ? abDetail.likeCount : 0,
            comment_count: abDetail.commentCount ? abDetail.commentCount : 0,
          })
          void retrieveAbDetail(feedId as number)
        },
        () => {
          //fail
        }
      )
    }
  }

  const onBookmarkButtonPress = async (feedId: number, markYn: string) => {
    if (markYn === 'Y') {
      await cancelBookmark(
        feedId,
        FeedTypeCode.AB,
        () => {
          //PPIT210117-4818 BOOKMARK 취소 시 actHistory 처리 추가
          callActHistoryByHistoryType(ActHistoryTypeCode.BOOKMARK, 'CANCEL')
          void retrieveAbDetail(feedId as number)
        },
        () => {
          //fail
        }
      )
    } else {
      await saveBookmark(
        feedId,
        FeedTypeCode.AB,
        () => {
          callActHistoryByHistoryType(ActHistoryTypeCode.BOOKMARK)
          track('click_bookmark', {
            content_id: feedId,
            content_title: abDetail.feedTitle,
            content_type: FeedTypeCode.AB,
            like_count: abDetail.likeCount ? abDetail.likeCount : 0,
            comment_count: abDetail.commentCount ? abDetail.commentCount : 0,
          })
          void retrieveAbDetail(feedId as number)
        },
        () => {
          //fail
        }
      )
    }
  }

  const onLikeListButtonPress = (feedId: number) => {
    goTo('/LikeScreen', {
      state: {
        feedId: feedId,
        feedType: LikeTypeCode.AB,
      },
    })
  }

  const onCommentButtonPress = (feedId: number) => {
    goTo(`${pathname}/comment`, {
      state: {
        feedId: feedId,
        feedType: CommentTypeCode.AB,
        feedAuthorId: abDetail?.createMemberUuid,
        // commentscreen에서 직접 호출하도록 변경
        // afterUpdate: () => {
        //   void retrieveAbDetail(feedId as number);
        // },
      },
    })
  }

  const clearVoting = async () => {
    // 비로그인
    if (sessionUser?.memberUuid === undefined) {
      goTo('/LoginPresenter')
      return
    }
    // 종료된 투표인 경우
    if (votingEnded) {
      setToastOption({
        show: true,
        message1: t('screen.ab.message.endVote'),
      })
      return
    }

    if (!myChoice) {
      return
    }

    await deleteAbVote(feedId as number)

    setAContent({ ...aContent, isMyPick: false, isWinner: false })
    setBContent({ ...bContent, isMyPick: false, isWinner: false })
    setAbDetail({ ...abDetail, voted: 'N' })
  }

  const voting = (contents: AbImageSection) => {
    // 비로그인
    if (sessionUser?.memberUuid === undefined) {
      goTo('JoinModal')
      return
    }
    // 종료된 투표인 경우
    if (votingEnded) {
      setToastOption({
        show: true,
        message1: t('screen.ab.message.endVote'),
      })
      return
    }

    if (myChoice || contents.isMyPick) {
      return
    }

    void addAbVote(feedId as number, contents.ab)
  }

  const getImageSection = (contents: AbImageSection, lR: string) => {
    const selected = contents.ab === optionToBeColored
    // with images
    if (contents.filePath) {
      return (
        <TouchableWithAsyncTask
          className={[
            'select_btn',
            resultOpened ? 'result_opened' : 'result_not_opened',
            selected ? 'selected' : '',
            contents.thumbnailFilePath ? 'has_thumbnail' : 'has_no_thumbnail',
          ].join(' ')}
          onPress={async () => {
            voting(contents)
          }}
          aria-label="voting Button"
          authCheckProps={{
            enabled: true,
            authScreenType: 'MODAL',
          }}
        >
          <FastImageWithFallback
            source={{
              uri: `${PUBLIC_BUCKET_URL}${contents.thumbnailFilePath || ''}`,
            }}
            fallbackImageUri={`${PUBLIC_BUCKET_URL}${contents.filePath}`}
            className="select_image"
            style={
              lR === 'L'
                ? { borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }
                : { borderTopRightRadius: 6, borderBottomRightRadius: 6 }
            }
          />
          <div
            className={lR === 'L' ? 'select_left_inner' : 'select_right_inner'}
            style={
              selected ? { backgroundColor: '#c07bca', opacity: 0.75 } : {}
            }
          />
          <div className="ab_select_text">
            {selected ? (
              <CheckIcon style={{ alignSelf: 'center' }} />
            ) : (
              <p className="ab_select_text1">{contents.ab}</p>
            )}
            <p className="ab_select_text2">{contents.desc}</p>
          </div>
        </TouchableWithAsyncTask>
      )
    } else {
      // without image
      if (!resultOpened) {
        // before vote
        return (
          <TouchableWithAsyncTask
            onPress={async () => {
              return resultOpened ? false : voting(contents)
            }}
            className="select_text_btn"
            style={
              lR === 'L'
                ? { borderBottomLeftRadius: 6, borderTopLeftRadius: 6 }
                : { borderBottomRightRadius: 6, borderTopRightRadius: 6 }
            }
            aria-label="voting Button"
            authCheckProps={{
              enabled: true,
              authScreenType: 'MODAL',
            }}
          >
            <div className="ab_wrap_container">
              <div
                className="ab_wrap"
                style={
                  lR === 'L'
                    ? { backgroundColor: '#c07bca' }
                    : { backgroundColor: '#7094bd' }
                }
              >
                <p className="select_text">{contents.ab}</p>
              </div>
              <div className="select_text_wrap">
                <p className={lR === 'L' ? 'select_a_text' : 'select_b_text'}>
                  {contents.desc}
                </p>
              </div>
            </div>
          </TouchableWithAsyncTask>
        )
      } else {
        // after vote
        if (selected) {
          return (
            <div
              onClick={() => {
                return resultOpened ? false : voting(contents)
              }}
              className="select_text_btn"
              style={
                lR === 'L'
                  ? {
                      borderBottomLeftRadius: 6,
                      borderTopLeftRadius: 6,
                      backgroundColor: '#BE69C3',
                    }
                  : {
                      borderBottomRightRadius: 6,
                      borderTopRightRadius: 6,
                      backgroundColor: '#BE69C3',
                    }
              }
              aria-label="voting Button"
            >
              <div className="check_icon">
                <CheckIcon />
              </div>
              <p className="selected_text">{contents.desc}</p>
            </div>
          )
        } else {
          return (
            <div
              onClick={() => {
                return resultOpened ? false : voting(contents)
              }}
              className="select_text_btn"
              style={
                lR === 'L'
                  ? { borderBottomLeftRadius: 6, borderTopLeftRadius: 6 }
                  : { borderBottomRightRadius: 6, borderTopRightRadius: 6 }
              }
              aria-label="voting Button"
            >
              <div className="not_selected_wrap">
                <p className="select_text">{contents.ab}</p>
              </div>
              <div className="select_text_wrap">
                <p
                  className={lR === 'L' ? 'select_a_text' : 'select_b_text'}
                  style={{ color: '#999' }}
                >
                  {contents.desc}
                </p>
              </div>
            </div>
          )
        }
      }
    }
  }

  const renderResult = () => {
    return (
      <>
        {optionToBeColored && aVoteCount + bVoteCount > 0 && (
          <div className="result_wrap">
            <AbPercentBar
              aVoteCount={aVoteCount}
              bVoteCount={bVoteCount}
              myChoice={myChoice}
              optionToBeColored={optionToBeColored}
            />
          </div>
        )}
        {sessionUser?.memberUuid !== undefined &&
          myChoice &&
          (votingEnded ? (
            <AbMyChoice choice={myChoice} />
          ) : (
            <div
              className="re_vote_btn_wrap
            "
            >
              <div
                className="re_vote_btn"
                onClick={() => {
                  void clearVoting()
                }}
                aria-label="reVoting Button"
              >
                <p className="re_vote_btn_text">
                  {t('screen.ab.label.reVote')}
                </p>
              </div>
            </div>
          ))}
      </>
    )
  }

  const renderAbImageSection = () => {
    return (
      <>
        <div className="select_wrap">
          {getImageSection(aContent, 'L')}
          {getImageSection(bContent, 'R')}
        </div>
        {resultOpened && renderResult()}
      </>
    )
  }

  const renderTagList = () => {
    return (
      <div className="tag_list">
        {abDetail?.tagList?.map((item: Tag, index: number) => (
          <TouchableWithAuthCheck
            className="tag_item"
            key={index}
            onPress={() => {
              CommonStore.callActHistory(
                ActHistoryTypeCode.TAG_CLICK,
                (item.tagId ?? 0).toString(),
                ActPageCode.AB_DETAIL,
                undefined,
                (abDetail.feedId ?? 0).toString()
              )
              goTo(`/tag/${item.tagId}`, {
                state: {
                  tagId: item.tagId,
                },
              })
            }}
          >
            <p className="tag_text">#{item.tagName}</p>
          </TouchableWithAuthCheck>
        ))}
      </div>
    )
  }

  const handleAbDelete = async (feedId: number) => {
    await deleteAb([feedId])
    setIsConfirmModal(false)

    deletedFeed(feedId, loginUserAccountStore)
    AbListStore.popAB(feedId)

    goBack()
  }

  const modifyAbDetail = () => {
    goTo('/AbWriteScreen', {
      state: {
        feedId: feedId,
      },
    })
  }

  // const renderModalText = () => {
  //   return (
  //     <div style={styles.confirm_modal_body}>
  //       <p style={styles.contents_text}>{t("screen.ab.message.deleteModalText")}</p>
  //     </div>
  //   );
  // };

  const toUserHome = () => {
    goTo(`/user/${abDetail.nickname}`, {
      state: {
        targetUserId: abDetail.createMemberUuid,
        nickname: abDetail.nickname,
        profileUrl: abDetail.profileUrl,
        profileBackgroundColor: abDetail.profileBackgroundColor,
      },
    })
  }

  const renderAb = () => {
    return (
      <>
        <div className="ab_detail_wrap">
          <div className="ab_detail_header_left">
            <div className="ab_detail_header">
              <TouchableWithAuthCheck
                onPress={toUserHome}
                aria-label="go to userHome"
              >
                <img
                  className="user_img"
                  src={`${PUBLIC_BUCKET_URL}${abDetail?.profileUrl || ''}`}
                  alt=""
                />
              </TouchableWithAuthCheck>
              <div className="info_wrap">
                <TouchableWithAuthCheck
                  onPress={toUserHome}
                  aria-label="go to userHome"
                >
                  <p className="info_text1">{abDetail?.nickname}</p>
                </TouchableWithAuthCheck>
                <div className="info_inner_wrap">
                  <p className="info_text2">
                    {getDateStr(abDetail?.createdDatetime)}
                  </p>
                  <div className="text_bar" />
                  <p className="info_text2">
                    {t('screen.ab.label.voters')}{' '}
                    {getNumberFormat(aVoteCount + bVoteCount)}
                  </p>
                </div>
              </div>
            </div>
            <AbEndDateBadge endDate={abDetail?.feedEndDate} />
          </div>
          <div className="ab_detail_body">
            <p className="title_text" aria-label={'Ab detail title'}>
              {abDetail?.feedTitle}
            </p>
            <div className="small_bar" />
            <div className="contents_wrap">
              <p className="contents_text">{abDetail?.contents}</p>
            </div>
            {renderTagList()}
            {renderAbImageSection()}
          </div>
        </div>
      </>
    )
  }

  const renderFooter = () => {
    return (
      <div className="action_wrap">
        <div>
          <div className="gradient" />
        </div>
        <div className="action_wrap_padding">
          <div className="action_left">
            <button
              onClick={() => onLikeListButtonPress(abDetail?.feedId)}
              aria-label="Move to like"
            >
              <p className="like_text">
                {t('screen.ab.label.like')}
                <p className="number_text">
                  {getNumberFormat(abDetail?.likeCount)}
                </p>
              </p>
            </button>
            <button
              onClick={() => onCommentButtonPress(abDetail?.feedId)}
              aria-label="Move to comment"
            >
              <p className="action_text">
                {t('screen.ab.label.comment')}
                <p className="number_text">
                  {getNumberFormat(abDetail?.commentCount)}
                </p>
              </p>
            </button>
          </div>
          <div className="action_right">
            <TouchableWithAsyncTask
              className="action_icon_btn"
              onPress={async () => {
                await onLikeButtonPress(
                  abDetail?.feedId,
                  abDetail?.likeYn || 'N'
                )
              }}
              aria-label="like button"
              authCheckProps={{ enabled: true }}
            >
              {abDetail?.likeYn === 'Y' ? <HeartOnIcon /> : <HeartOffIcon />}
            </TouchableWithAsyncTask>
            <TouchableWithAsyncTask
              className="action_icon_btn"
              onPress={async () => {
                await onBookmarkButtonPress(
                  abDetail?.feedId,
                  abDetail?.bookmarkYn || 'N'
                )
              }}
              aria-label="bookmark Button"
              authCheckProps={{ enabled: true }}
            >
              {abDetail?.bookmarkYn === 'Y' ? (
                <BookmarkOnIcon />
              ) : (
                <BookmarkOffIcon />
              )}
            </TouchableWithAsyncTask>
            <TouchableWithAuthCheck
              className="action_icon_btn"
              onPress={() => onCommentButtonPress(abDetail?.feedId)}
              aria-label="Move to comment"
            >
              <ChatIcon />
            </TouchableWithAuthCheck>
            <button
              className="action_icon_btn"
              aria-label="share button"
              onClick={() => {
                const actHistoryData = {
                  actHistoryTypeCode: ActHistoryTypeCode.POST_SHARE,
                  actPageCode: ActPageCode.AB_DETAIL,
                  feedId: (abDetail.feedId ?? 0).toString(),
                  likeCount: (abDetail.likeCount ?? 0).toString(),
                  commentCount: (abDetail.commentCount ?? 0).toString(),
                }
                const shareInfo: ShareInfo = {
                  title: abDetail?.feedTitle,
                  descriptionText: abDetail?.contents,
                  screen: ShareScreenCode.AB_DETAIL_SCREEN,
                  targetId: abDetail?.feedId,
                  path: `AB/${abDetail?.feedId}`,
                }

                void onShare(shareInfo, actHistoryData)

                track('click_share_button', {
                  content_id: abDetail?.feedId.toString(),
                  content_title: abDetail?.feedTitle,
                  content_type: FeedTypeCode.AB,
                  like_count: abDetail.likeCount || 0,
                  comment_count: abDetail.commentCount || 0,
                })
              }}
            >
              <ShareIcon />
            </button>
          </div>
        </div>
      </div>
    )
  }
  const handleHeaderOptionClick = (type: string) => {
    switch (type) {
      case 'MODIFY':
        abDetail?.commentCount === 0 &&
        (aVoteCount || 0) + (bVoteCount || 0) === 0
          ? CommonStore.setShowDownloadAppDrive(true)
          : // modifyAbDetail()
            setToastOption({
              show: true,
              message1: t(`screen.ab.message.existCommentOrVote`),
              // bottomOffset: Platform.OS === "ios" ? insets.bottom : 30,
            })
        track('edit_ab', {})
        break
      case 'DELETE':
        CommonStore.setShowDownloadAppDrive(true)
        // setIsConfirmModal(true);
        track('delete_ab', {})
        break
      case 'REPORT':
        openReportBottomSheet(
          {
            reportTargetId: String(feedId),
            reportTargetTypeCode: ReportTargetTypeCode.AB,
          },
          () => {
            void popAB(Number(feedId))
            deletedFeed(Number(feedId), accountStore)
            if (canGoBack()) {
              goBack()
            }
          }
        )
        break
      default:
        break
    }
  }
  return (
    <div className="ab_detail" aria-label="Ab detail screen">
      <TitleHeader
        isHome
        isBack={true}
        title={t('screen.ab.title.detail')}
        rightItem={HeaderItem.MORE}
        moreOptionMenuList={
          abDetail?.createMemberUuid === sessionUser?.memberUuid
            ? ['MODIFY', 'DELETE']
            : ['REPORT']
        }
        onClickMore={handleHeaderOptionClick}
        needAuth={true}
      />
      {!loading ? (
        isShowBlockedModal ? (
          <NotificationModal
            isVisible={isShowBlockedModal}
            contents1={t('common.message.blockedOrDeleted')}
            onRequestClose={() => {
              goBack()
              setIsShowBlockedModal(false)
            }}
            defaultButtonText={modalButtonName}
          />
        ) : isAuthenticated.current ? (
          <>
            {renderAb()}
            <DetailReply
              feedId={abDetail?.feedId}
              detailStore={abDetail}
              commentTypeCode={CommentTypeCode.AB}
            />
            {renderFooter()}
          </>
        ) : (
          <JoinOverlapComponent>
            <div
              style={{
                height: '100vh',
                overflowY: 'hidden',
                pointerEvents: 'none',
              }}
            >
              {renderAb()}
              <DetailReply
                feedId={abDetail?.feedId}
                detailStore={abDetail}
                commentTypeCode={CommentTypeCode.AB}
              />
            </div>
          </JoinOverlapComponent>
        )
      ) : (
        <CustomActivityIndicator isDarkSpinner={true} />
      )}
      {isConfirmModal && (
        <NotificationModal
          isVisible={isConfirmModal}
          contents1={t('screen.ab.message.deleteModalText')}
          useTwoButton={true}
          isBlack={false}
          defaultButtonText={t('screen.notificationModal.button.OK')}
          extraButtonText={t('screen.notificationModal.button.cancel')}
          onClickDefaultButton={() => void handleAbDelete(abDetail?.feedId)}
          onClickExtraButton={() => setIsConfirmModal(false)}
          onRequestClose={() => setIsConfirmModal(false)}
        />
      )}
    </div>
  )
})
export default AbDetail
