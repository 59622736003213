import { observer } from "mobx-react";
import { ReactComponent as AppInstallTopBannerIcon } from "../../assets/icons/AppInstallTopBannerIcon.svg";
import "./AppInstallTopBanner.scss";
import { useTranslation } from "react-i18next";
import { Platform, PlatformTypes } from "../../native/platform";
import { useCallback } from "react";

const AppInstallTopBanner = observer(() => {
  const { t } = useTranslation();

  const isAppInstallBanner = useCallback(() => {
    return (
      Platform.OS === PlatformTypes.ANDROID_WEB ||
      Platform.OS === PlatformTypes.IOS_WEB
    );
  }, []);

  return (
    <>
      {isAppInstallBanner() && (
        <div id="AppInstallTopBannerWrapper">
          <div id="AppInstallTopBanner">
            <div className="icon">
              <AppInstallTopBannerIcon />
            </div>
            <div className="description">
              <span className="text-line-1">
                {t("common.message.appInstallTopBannerMsg")}
              </span>
              {/* <span className="text-line-2">{"앱에서 미션 참여 시 선물이 팡팡🎁"}</span> */}
            </div>
            <div className="action-wrapper">
              <button
                className="btn"
                onClick={() =>
                  window.open("https://app2.220cordncode.com/IYMzZqsaaLb")
                }
              >
                {t("common.label.appInstall")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
});
export default AppInstallTopBanner;
