import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import {
//   ActivityIndicator,
//   FlatList,
//   Platform,
//   SafeAreaView,
//   StyleSheet,
//   Switch,
//   Text,
//   TouchableOpacity,
//   View,
// } from "react-native";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import TitleHeader from "../../../components/common/TitleHeader";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_small.svg";
//import { responsiveScreenHeight, responsiveScreenWidth, responsiveWidth } from "react-native-responsive-dimensions";
// import { ReactComponent as Lv1Badge } from "../../../assets/icons/img_badge_lv1.svg";
// import { ReactComponent as Lv2Badge } from "../../../assets/icons/img_badge_lv2.svg";
// import { ReactComponent as Lv3Badge } from "../../../assets/icons/img_badge_lv3.svg";
// import { ReactComponent as Lv4Badge } from "../../../assets/icons/img_badge_lv4.svg";
// import { ReactComponent as Lv1BadgeDisable } from "../../../assets/icons/img_badge_lv1_disable.svg";
// import { ReactComponent as Lv2BadgeDisable } from "../../../assets/icons/img_badge_lv2_disable.svg";
// import { ReactComponent as Lv3BadgeDisable } from "../../../assets/icons/img_badge_lv3_disable.svg";
// import { ReactComponent as Lv4BadgeDisable } from "../../../assets/icons/img_badge_lv4_disable.svg";
import { ReactComponent as BarcodeCouponIcon } from "../../../assets/icons/coupon/coupon_barcode.svg";
import { ReactComponent as DiscountCouponIcon } from "../../../assets/icons/coupon/coupon_discount_icon.svg";
import { ReactComponent as NormalCouponIcon } from "../../../assets/icons/coupon/coupon_normal_icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as CheckIcon } from '../../../assets/icons/check_purple.svg';
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CustomBottomSheet from "../../../components/common/CustomBottomSheet";
// import { BottomSheetView } from "@gorhom/bottom-sheet";
import { Coupon, CouponResponse, CouponUseYNRequest } from "../../../api/coupon/model";
import { getMyCouponList, updateCouponUseYN } from "../../../api/coupon/api";
import { CouponSortType, CouponStatus, CouponType } from "../../../constants/coupon";
import { useTranslation } from "react-i18next";
import { getDatetimeStr } from "../../../utils/datetime";
// import FastImage from "react-native-fast-image";
import { handleTopButtonScroll } from "../../../utils/common";
import {
  font_letter_spacing_056,
  font_letter_spacing_072,
  font_size_18,
  font_weight_bold,
  font_weight_normal,
} from "../../../assets/styles/texts";
import NotificationModalWithHideCallback from "../../../components/modals/NotificationModalWithHideCallback";
import dayjs from "dayjs";
// import { useIsFocused } from "@react-navigation/native";
import { useLocation, useParams } from "react-router-dom";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { flex } from "../../../assets/styles/flexBoxs";
import  CustomActivityIndicator from "../../../components/common/CustomActivityIndicatorSmall";
import ListWithStickyTabs from "../../../components/common/ListWithStickyTabs";
import { Virtuoso } from "react-virtuoso";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";

const CouponList = () => {
  const {
    coupon_list,
    tab_box,
    active_tab,
    tab_txt,
    active_tab_txt,
    tab,

    header,
    header_left,
    header_text_wrap,
    header_text1,
    header_text2,
    header_sort_btn,
    header_sort_btn_txt,

    coupon_list_wrap,
    coupon_item,
    coupon_top,
    coupon_bottom,
    discount_text1,
    discount_text2,
    valid_date_range,
    valid_date_range2,
    coupon_icon,
    no_coupon,
    no_coupon_text_wrap,
    no_coupon_text,

    expired_text1,
    expired_text2,
    expired_d_day_text,
    expired_coupon_icon,
    expired_coupon_text,
    sheet,
    close_btn,
    sheet_title_wrap,
    sheet_title,
    sort_list,
    sort_item,
    sort_text,
    sort_active_text,
    color_test
  } = styles;

  const location = useLocation();
  const { t } = useTranslation();
  //const insets = useSafeAreaInsets();

  const [tabState, setTabState] = useState(CouponStatus.ACTIVE);
  const [myCouponList, setMyCouponList] = useState<Coupon[]>([]);
  const [myCouponCount, setMyCouponCount] = useState(0);
  const [myCouponPageIndex, setMyCouponPageIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [myExpiredCouponList, setMyExpiredCouponList] = useState<Coupon[]>([]);
  const [myExpiredCouponCount, setMyExpiredCouponCount] = useState(0);
  const [myExpiredCouponPageIndex, setMyExpiredCouponPageIndex] = useState<number>(0);
  const [expiredRefreshing, setExpiredRefreshing] = useState<boolean>(false);

  const pageSize = 7;
  const [showSortBox, setShowSortBox] = useState(false);
  const [sortType, setSortType] = useState(CouponSortType.END);
  const [expiredSortType, setExpiredSortType] = useState(CouponSortType.END);
  const [showTopButton, setShowTopButton] = useState<boolean>(false);
  const myCouponRef = useRef(null);
  const expiredCouponRef = useRef(null);
  const [existsData, setExistsData] = useState<boolean>(true);
  const [existExpireCoupon, setExistExpireCoupon] = useState<boolean>(true);

  const [showCouponUseYNTooltip, setShowCouponUseYNTooltip] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [couponUseYNRequest, setCouponUseYNRequest] = useState<CouponUseYNRequest>({
    couponId: 0,
    couponCode: "",
    useYn: "",
  });

  // const PUBLIC_BUCKET_URL: string = Config.REACT_NATIVE_PUBLIC_BUCKET_URL;
  const PUBLIC_BUCKET_URL: string = process.env.REACT_APP_PUBLIC_BUCKET_URL || "";

  const useModalinfo = {
    title: t("screen.coupon.label.notice1"),
    contents1: t("screen.coupon.message.useContents1"),
    onClickDefaultButton: () => {
      void couponUseYNUpdate();
      setShowModal(false);
    },
  };
  const unuseModalinfo = {
    title: t("screen.coupon.label.notice2"),
    contents1: t("screen.coupon.message.unuseContents1"),
    onClickDefaultButton: () => {
      void couponUseYNUpdate();
      setShowModal(false);
    },
  };

  //const isFocused = useIsFocused();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    // 화면 포커스 여부 감지
    setIsFocused(true);

    // 컴포넌트 언마운트 시 포커스 여부 갱신
    return () => {
      setIsFocused(false);
    };
  }, [location]);

  useEffect(() => {
    void getCoupons(CouponSortType.END);
    void getExpiredCoupons(CouponSortType.END);

    const result = localStorage.getItem("couponUseYNSwitchTooltip");
    if (result == null) {
        setShowCouponUseYNTooltip(true);
    } else {
      setShowCouponUseYNTooltip(false);
    }

  }, [isFocused]);

  useEffect(() => {
    void getCoupons(sortType);
  }, [sortType]);

  useEffect(() => {
    void getExpiredCoupons(expiredSortType);
  }, [expiredSortType]);

  const getCoupons = async (sortType: CouponSortType) => {
    setIsLoading(true);
    const res: CouponResponse = await getMyCouponList(pageSize, 0, CouponStatus.ACTIVE, sortType);
    if (res) {
      if (res.totalCount === 0) {
        setExistsData(false);
      }
      setMyCouponCount(res.totalCount);
      setMyCouponList(res.list);
    }
    setIsLoading(false);
  };

  const getExpiredCoupons = async (sortType: CouponSortType) => {
    setIsLoading(true);
    const res: CouponResponse = await getMyCouponList(pageSize, 0, CouponStatus.EXPIRED, sortType);
    if (res) {
      if (res.totalCount === 0) {
        setExistExpireCoupon(false);
      }
      setMyExpiredCouponCount(res.totalCount);
      setMyExpiredCouponList(res.list);
    }
    setIsLoading(false);
  };

  const handleMoreCoupons = (type: CouponStatus, sortType: CouponSortType) => {
    if (canLoadMore && !isLoading) {
      setIsLoading(true);
      void getMoreCoupons(
        pageSize,
        type == CouponStatus.ACTIVE ? myCouponPageIndex + 1 : myExpiredCouponPageIndex + 1,
        type,
        sortType
      ).finally(() => setIsLoading(false));
    }
  };

  const getMoreCoupons = async (pageSize: number, pageIndex: number, type: CouponStatus, sortType: CouponSortType) => {
    if (type == CouponStatus.ACTIVE) {
      const res = await getMyCouponList(pageSize, pageIndex, type, sortType);
      const newCoupons: Coupon[] = res ? res.list : [];
      if (pageIndex > 0) {
        if (newCoupons.length > 0) {
          setMyCouponList([...myCouponList, ...newCoupons]);
          setMyCouponPageIndex(pageIndex);
        } else {
          setCanLoadMore(false);
        }
      }
    } else if (type == CouponStatus.EXPIRED) {
      const res = await getMyCouponList(pageSize, pageIndex, type, sortType);
      const newCoupons: Coupon[] = res ? res.list : [];
      if (pageIndex > 0) {
        if (newCoupons.length > 0) {
          setMyExpiredCouponList([...myExpiredCouponList, ...newCoupons]);
          setMyExpiredCouponPageIndex(pageIndex);
        } else {
          setCanLoadMore(false);
        }
      }
    }
  };

  const hideCouponUseYNSwitchTooltip = () => {
    localStorage.setItem("couponUseYNSwitchTooltip", "false");
    setShowCouponUseYNTooltip(false);
  };

  const onValueChangeSwitch = (item: Coupon) => {
    setCouponUseYNRequest({
      couponId: item.couponId,
      couponCode: item.couponCode,
      useYn: tabState === CouponStatus.ACTIVE ? "Y" : "N",
    });
    setShowModal(true);
  };

  const couponUseYNUpdate = async () => {
    const res = await updateCouponUseYN(couponUseYNRequest);
    if (res.successOrNot === "Y") {
      if (tabState === CouponStatus.ACTIVE) {
        const result = myCouponList.filter(
          (item) =>
            !(item.couponId === couponUseYNRequest.couponId && item.couponCode === couponUseYNRequest.couponCode)
        );
        result && setMyCouponList(result);
      } else {
        const result = myExpiredCouponList.filter(
          (item) =>
            !(item.couponId === couponUseYNRequest.couponId && item.couponCode === couponUseYNRequest.couponCode)
        );
        result && setMyExpiredCouponList(result);
      }
    }
  };

  const isAfterCouponEndDate = useCallback((endDate: string) => {
    const now = dayjs();
    return now.isAfter(endDate);
  }, []);

  const renderLoading = useMemo(() => {
      return (
        <div id="coupon-footer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <CustomActivityIndicator />
        </div>
      );
  }, []);

  const renderContentsHeader = (type: CouponStatus) => {
    return (
      <div style={header}>
        <div style={header_left}>
          <div style={header_text_wrap}>
            <div style={header_text1}>
              {t("screen.coupon.label.total")}
              <span style={header_text2}>{type == CouponStatus.ACTIVE ? myCouponCount : myExpiredCouponCount}</span>
            </div>
          </div>
        </div>
        <button style={header_sort_btn} onClick={() => setShowSortBox(true)}>
          <span style={header_sort_btn_txt}>
            {type == CouponStatus.ACTIVE
              ? t(`screen.coupon.label.${sortType}`)
              : t(`screen.coupon.label.${expiredSortType}`)}
          </span>
          <ArrowDown />
        </button>
      </div>
    );
  };

  const renderCouponItem = (index: number, item: Coupon) => {
    return (
      <div
        style={index == 0 ? { ...{ paddingTop: '10px' }, ...coupon_item, } : coupon_item}
        onClick={() => {
          goTo("/coupon/detail", { state: { couponInfo: item } })
        }}
      >
        <div style={{ position: 'relative' }}>
          <FastImageWithFallback
            source={{ uri: `${PUBLIC_BUCKET_URL}/ASSETS/coupon/images/coupon_line_bg.png` }}
            style={{ width: "100%", height: 140, borderRadius: 10 }}
          />
          <div style={{ position: 'absolute', top: '0px', width: '100%', display: 'flex', flexDirection: 'column', height: '140px' }}>
            <div style={coupon_top}>
              <div style={{ 
                display: 'flex', flexDirection: 'column', flexGrow: 3, alignItems: 'start', 
                whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", 
                }}
              >
                <p
                  style={discount_text1}
                >
                  {item.couponProvider}
                </p>
                <p style={discount_text2}
                >
                  {item.couponName}
                </p>
              </div>
              <div>
                {item.couponType === CouponType.MANUAL_BARCODE ? (
                  <BarcodeCouponIcon />
                ) : item.couponType === CouponType.MANUAL_GIFT ? (
                  <NormalCouponIcon />
                ) : (
                  <DiscountCouponIcon />
                )}
              </div>
            </div>
            <div style={coupon_bottom}>
              <p style={valid_date_range}>
                {getDatetimeStr(item.couponStartDate)} ~ {getDatetimeStr(item.couponEndDate)}
              </p>
              {tabState === CouponStatus.ACTIVE ? (
                <button
                  style={{
                    borderColor: "#be69c3",
                    borderWidth: 1,
                    borderStyle: 'solid',
                    padding: '6px 11px',
                    ...Spacings.border_radius_300,
                    ...Arranges.center_v_h,
                    marginRight: '20px'
                  }}
                  onClick={(event) => {
                    onValueChangeSwitch(item);
                    event.stopPropagation();
                  }}
                >
                  <p style={{ ...Texts.contents_text_41, fontWeight: "500", color: "#be69c3" }}>{"사용했어요"}</p>
                </button>
              ) : (
                <button
                  style={{
                    borderColor: "#999999",
                    borderWidth: 1,
                    borderStyle: 'solid',
                    padding: '6px 11px',
                    ...Spacings.border_radius_300,
                    ...Arranges.center_v_h,
                    marginRight: '20px'
                  }}
                  onClick={(event) => {
                    onValueChangeSwitch(item);
                    event.stopPropagation();
                  }}
                >
                  <p style={{ ...Texts.contents_text_41, fontWeight: "500", color: "#999999" }}>{"사용 취소"}</p>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onScrollHandler = (e: any) => {
    handleTopButtonScroll(
      e,
      () => {
        setShowTopButton(true);
      },
      () => {
        setShowTopButton(false);
      },
      tabState === CouponStatus.ACTIVE ? myCouponRef : expiredCouponRef
    );

    // 툴팁 비노출
    hideCouponUseYNSwitchTooltip();
  };

  const renderCouponUseYNSwitchTooltip = () => {
    return (
      <div
        style={{
          position: "absolute",
          display: 'flex',
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
          top: 255,
          right: 65,
        }}
      >
        <button
          onClick={(event) => {
            // 툴팁 비노출
            hideCouponUseYNSwitchTooltip();
            event.stopPropagation();
          }}
        >
          <div
            style={{
              position: 'absolute',
              bottom: -7,
              right: 25,
              width: 0,
              height: 0,
              borderLeft: '15px solid transparent',
              borderRight: '15px solid transparent',
              borderTop: '15px solid #222222'
            }}
          />
          <div
            style={{
              flexDirection: 'row',
              backgroundColor: "#222222",
              ...Arranges.center_v_h,
              marginTop: -5,
              ...Spacings.padding_top_bottom_4,
              ...Spacings.border_radius_8,
              width: 160,
              height: 30,
            }}
          >
            <div
              style={{
                textAlign: "center",
                ...Texts.contents_text_5,
                ...Texts.font_weight_500,
                ...Colors.font_fff,
                // lineHeight: 16,
              }}
            >
              {t("screen.coupon.message.tooltip")}
            </div>
          </div>
        </button>
      </div>
    );
  };

  const renderCoupon = () => {
    return (
      <>
        {renderContentsHeader(CouponStatus.ACTIVE)}
        {myCouponList && myCouponList.length > 0 && showCouponUseYNTooltip && renderCouponUseYNSwitchTooltip()}
        <Virtuoso
          style={{ ...coupon_list_wrap, ...(myCouponList.length === 0 ? { backgroundColor: '#FFFFFF !important' } : {}) }}
          data={myCouponList}
          itemContent={renderCouponItem}
          useWindowScroll
          components={{
            EmptyPlaceholder: () => (
              <>
                {!isLoading && myCouponList.length == 0 && !existsData && (
                <div style={{ position: 'relative' }}>
                  <FastImageWithFallback
                    source={{ uri: `${PUBLIC_BUCKET_URL}/ASSETS/coupon/images/coupon_nodata.png` }}
                    style={no_coupon}
                  />
                  <div style={no_coupon_text_wrap}>
                    <p style={no_coupon_text}>{t("screen.coupon.message.active_empty")}</p>
                  </div>
                </div>
                )}
              </>
            ),
            Footer: () => (
              <>
                {(canLoadMore && isLoading && !refreshing && !expiredRefreshing) && renderLoading}
              </>
            ),
          }}
          endReached={() => {
            if (myCouponCount > myCouponList.length)
              handleMoreCoupons(CouponStatus.ACTIVE, sortType)
          }}
        />
      </>
    );
  };

  const renderExpiredCouponItem = (index: number, item: Coupon) => {
    return isAfterCouponEndDate(item.couponEndDate) ? (
      <button
        style={index == 0 ? { ...{ paddingTop: '10px' }, ...coupon_item } : coupon_item}
        onClick={() => {
          goTo("/coupon/detail", { state: { couponInfo: item } })
        }}
      >
        <div style={{ position: 'relative' }}>
          <FastImageWithFallback
            source={{ uri: `${PUBLIC_BUCKET_URL}/ASSETS/coupon/images/coupon_line_bg.png` }}
            style={{ width: "100%", height: 140, borderRadius: 10 }}
          />
          <div style={{ position: 'absolute', top: '0px', width: '100%', display: 'flex', flexDirection: 'column', height: '140px' }}>
            <div style={coupon_top}>
              <div style={{ 
                  display: 'flex', flexDirection: 'column', flexGrow: 3, alignItems: 'start',
                  whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis",
                  }}
                >
                <p
                  style={expired_text1}
                >
                  {item.couponProvider}
                </p>
                <p style={expired_text2}
                >
                  {item.couponName}
                </p>
              </div>
              <div>
                {item.couponType === CouponType.MANUAL_BARCODE ? (
                  <BarcodeCouponIcon style={{ opacity: 0.5 }} />

                ) : item.couponType === CouponType.MANUAL_GIFT ? (
                  <NormalCouponIcon style={{ opacity: 0.5 }} />
                ) : (
                  <DiscountCouponIcon style={{ opacity: 0.5 }} />
                )}
              </div>
            </div>
            <div style={coupon_bottom}>
              <p style={valid_date_range2}>
                {getDatetimeStr(item.couponStartDate)} ~ {getDatetimeStr(item.couponEndDate)}
              </p>
              <div
                style={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  padding: '6px 11px',
                  ...Spacings.border_radius_300,
                  ...Arranges.center_v_h,
                  marginRight: '20px',
                  borderColor: "#F5F5F5",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <p style={{ ...Texts.contents_text_41, fontWeight: "500", color: "#999999" }}>{"기간 만료"}</p>
              </div>
            </div>
          </div>
        </div>
      </button>
    ) : (
      renderCouponItem(index, item)
    );
  };

  const renderExpiredCoupon = () => {
    return (
      <>
        {renderContentsHeader(CouponStatus.EXPIRED)}
        {myExpiredCouponList && myExpiredCouponList.length > 0 && showCouponUseYNTooltip && renderCouponUseYNSwitchTooltip()}
        <Virtuoso
          style={{ ...coupon_list_wrap, ...(myExpiredCouponList.length === 0 ? { backgroundColor: '#FFFFFF !important' } : {}) }}
          data={myExpiredCouponList}
          itemContent={renderExpiredCouponItem}
          useWindowScroll
          components={{
            EmptyPlaceholder: () => (
              <>
                {!isLoading && myExpiredCouponList.length == 0 && !existExpireCoupon && (
                <div style={{position: 'relative'}}>
                  <FastImageWithFallback
                    source={{ uri: `${PUBLIC_BUCKET_URL}/ASSETS/coupon/images/coupon_nodata.png` }}
                    style={no_coupon}
                  />
                  <div style={no_coupon_text_wrap}>
                    <p style={no_coupon_text}>{t("screen.coupon.message.empty")}</p>
                  </div>
                </div>
                )}
              </>
            ),
            Footer: () => (
              <>
                {(canLoadMore && isLoading && !refreshing && !expiredRefreshing) && renderLoading}
              </>
            ),
          }}
          endReached={() => {
            if (myExpiredCouponCount > myExpiredCouponList.length)
              handleMoreCoupons(CouponStatus.EXPIRED, expiredSortType);
          }}
        />
      </>)
  };

  return (
    <>
      {/* <SafeAreaView */}
      <div style={{
        width: '100%',
        ...(tabState === CouponStatus.ACTIVE ? 
          (myCouponList.length <= 1 ? {backgroundColor: "#F5F5F5", height: '100vh', overflow: 'auto'} : {})
          : (myExpiredCouponList.length <= 1 ? {backgroundColor: "#F5F5F5", height: '100vh', overflow: 'auto'} : {}))}}
      >
      <div style={coupon_list}>
        <TitleHeader title={t("screen.coupon.title")} isHome />
        <div style={tab_box}>
          <button
            style={{ ...tab, ...(tabState === CouponStatus.ACTIVE ? active_tab : {}) }}
            onClick={() => {
              setCanLoadMore(true);
              setMyCouponPageIndex(0);
              void getCoupons(sortType);
              setTabState(CouponStatus.ACTIVE);
            }}
          >
            <div style={{ ...tab_txt, ...(tabState === CouponStatus.ACTIVE ? active_tab_txt : {}) }}>
              {t("screen.coupon.label.activeCoupon")}
            </div>
          </button>
          <button
            style={{ ...tab, ...(tabState === CouponStatus.EXPIRED ? active_tab : {}) }}
            onClick={() => {
              setCanLoadMore(true);
              setMyExpiredCouponPageIndex(0);
              void getExpiredCoupons(expiredSortType);
              setTabState(CouponStatus.EXPIRED);
              // 툴팁 비노출
              hideCouponUseYNSwitchTooltip();
            }}
          >
            <div style={{ ...tab_txt, ...(tabState === CouponStatus.EXPIRED ? active_tab_txt : {}) }}>
              {t("screen.coupon.label.expiredCoupon")}
            </div>
          </button>
        </div>
        {tabState === CouponStatus.ACTIVE ? renderCoupon() : renderExpiredCoupon()}
      </div>

      <CustomBottomSheet
        snapHeight={200}
        open={showSortBox}
        // handleComponent={() => <></>}
        onClose={() => setShowSortBox(false)}
      >
        <div style={sheet}>
          <button style={close_btn} onClick={() => setShowSortBox(false)}
          // accessibilityLabel="Sort close"
          >
            <CloseIcon />
          </button>
          <div style={sheet_title_wrap}>
            {/* accessibilityLabel="Sorting" */}
            <div style={sheet_title}>
              {t("screen.coupon.label.sort")}
            </div>
          </div>

          <div style={sort_list}>
            <button
              style={sort_item}
              onClick={() => {
                tabState === CouponStatus.ACTIVE
                  ? setSortType(CouponSortType.END)
                  : setExpiredSortType(CouponSortType.END);
                setShowSortBox(false);
              }}
            // accessibilityLabel="Sort end"
            >
              <div
                style={{
                  ...sort_text, 
                  ...(tabState === CouponStatus.ACTIVE && sortType === CouponSortType.END
                    ? sort_active_text
                    : tabState === CouponStatus.EXPIRED && expiredSortType === CouponSortType.END
                      ? sort_active_text
                      : {}
                    )
                  }}
              >
                {t("screen.coupon.label.end")}
              </div>
              {tabState === CouponStatus.ACTIVE && sortType === CouponSortType.END && <CheckIcon />}
              {tabState === CouponStatus.EXPIRED && expiredSortType === CouponSortType.END && <CheckIcon />}
            </button>

            <button
              style={sort_item}
              onClick={() => {
                tabState === CouponStatus.ACTIVE
                  ? setSortType(CouponSortType.CREATED)
                  : setExpiredSortType(CouponSortType.CREATED);
                setShowSortBox(false);
              }}
            // accessibilityLabel="Sort created"
            >
              <div
                style={{
                  ...sort_text,
                  ...(tabState === CouponStatus.ACTIVE && sortType === CouponSortType.CREATED
                    ? sort_active_text
                    : tabState === CouponStatus.EXPIRED && expiredSortType === CouponSortType.CREATED
                      ? sort_active_text
                      : {}
                  )
                }}
              >
                {t("screen.coupon.label.created")}
              </div>
              {tabState === CouponStatus.ACTIVE && sortType === CouponSortType.CREATED && <CheckIcon />}
              {tabState === CouponStatus.EXPIRED && expiredSortType === CouponSortType.CREATED && <CheckIcon />}
            </button>
          </div>
        </div>
      </CustomBottomSheet>

      <NotificationModalWithHideCallback
        isVisible={showModal}
        title={tabState === CouponStatus.ACTIVE ? useModalinfo.title : unuseModalinfo.title}
        contents1={tabState === CouponStatus.ACTIVE ? useModalinfo.contents1 : unuseModalinfo.contents1}
        useTwoButton={true}
        isBlack={false}
        defaultButtonText={t("screen.notificationModal.button.confirm")}
        extraButtonText={t("screen.notificationModal.button.cancel")}
        onClickDefaultButton={
          tabState === CouponStatus.ACTIVE ? useModalinfo.onClickDefaultButton : unuseModalinfo.onClickDefaultButton
        }
        onClickExtraButton={() => setShowModal(false)}
        onRequestClose={() => setShowModal(false)}
        modalCloseCallback={() => setShowModal(false)}
        textAlign={"center"}
      />
      </div>
    </>
  );
};
export default CouponList;

export const styles: Styles = {
  coupon_list: {
    ...FlexBoxs.flex_1,
  },
  tab_box: {
    ...flex,
    ...FlexBoxs.spaceAround,
    height: 56,
    borderBottomWidth: 1,
    ...Colors.border_color_eee,
    ...FlexBoxs.horizon,
    ...Colors.background_fff
  },
  tab: {
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 1,
    ...FlexBoxs.flex_1,
    ...Arranges.center_v_h,
    borderColor: '#EEEEEE',
    flexGrow: 1
  },
  tab_txt: {
    ...Texts.contents_text_38,
    ...Colors.font_999,
  },
  active_tab: {
    ...Colors.border_color_222,
  },
  active_tab_txt: {
    ...Colors.font_222,
    fontWeight: "500",
  },
  color_test: {
    ...Colors.font_333,
  },
  header: {
    ...flex,
    ...Arranges.between,
    ...Arranges.center_h,
    ...FlexBoxs.horizon,
    ...Spacings.padding_left_16_responsive,
    ...Spacings.padding_right_16_responsive,
    height: 64,
    ...Colors.background_fff
  },
  header_left: {
    ...FlexBoxs.horizon,
  },
  header_text_wrap: {
    ...Spacings.padding_left_30,
  },
  header_text1: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_bold,
    ...Colors.font_222,
  },
  header_text2: {
    ...Colors.font_a760b1,
  },
  header_sort_btn: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.padding_right_20,
  },
  header_sort_btn_txt: {
    ...Texts.btn_text_1,
    ...Colors.font_666666,
    ...Spacings.margin_right_6,
  },
  coupon_list_wrap: {
    ...Colors.background_f5f5f5,
    // ...Spacings.padding_right_left_16_responsive,
    paddingRight: 10,
    paddingLeft: 0,
    // paddingRight: responsiveWidth(3),
    // paddingLeft: responsiveWidth(3),
    ...Spacings.padding_top_16,
  },
  coupon_item: {
    ...FlexBoxs.flex,
    width: "100%",
    ...FlexBoxs.horizon,
    ...Spacings.padding_bottom_8,
    justifyContent: "center",
  },
  last_item: {
    ...Spacings.margin_bottom_60,
  },
  coupon_center: {
    width: 80
    // width: responsiveScreenWidth(80),
  },
  coupon_top: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    flexGrow: 5,
    alignItems: 'center',
    // ...Arranges.center_v,
    ...Spacings.margin_right_left_20,

  },
  coupon_bottom: {
    // ...FlexBoxs.flex_1,
    // ...Arranges.center_v,
    ...FlexBoxs.flex,
    alignItems: 'center',
    ...FlexBoxs.horizon,
    marginLeft: 20,
    flexGrow: 4,
  },
  badge: {
    ...Positions.absolute,
    top: 8,
    right: 8,
    zIndex: 10,
  },
  logo: {
    width: 68,
    height: 22,
    ...Colors.background_eeeeee,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_4,
  },
  logo_text: {
    ...Texts.contents_text_47,
    ...Colors.font_666666,
  },
  discount_text1: {
    width: '100%',
    // width: responsiveScreenWidth(70),
    ...Texts.contents_text_43,
    ...Colors.font_666666,
    textAlign: 'start',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  discount_text2: {
    width: '100%',
    // width: responsiveScreenWidth(70),
    ...Spacings.margin_top_4,
    ...font_size_18,
    ...font_weight_bold,
    ...font_letter_spacing_072,
    ...Colors.font_222,
    ...Texts.font_weight_600,
    textAlign: 'start',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  valid_date_range: {
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#be69c3",
    flexGrow: 2,
    textAlign: 'start'
  },
  valid_date_range2: {
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: 0,
    color: "#c4c4c4",
    flexGrow: 2,
    textAlign: 'start'
  },
  coupon_icon: {
    zIndex: 1,
    backgroundColor: "white",
    position: "absolute",
    right: 20,
    top: 5,
  },
  coupon_bottom_left: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  d_day: {
    borderWidth: 1,
    height: 22,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_4,
    ...Spacings.margin_right_6,
    ...Spacings.padding_left_right_6,
    ...Colors.border_color_be69c3,
  },
  d_day_text: {
    ...Texts.contents_text_47,
    ...Colors.font_be69c3,
  },
  date_text: {
    ...Texts.contents_text_0,
  },
  coupon_detail_btn: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  coupon_detail_btn_text: {
    ...Texts.contents_text_9,
    ...Texts.font_weight_500,
    ...Spacings.margin_right_2,
  },
  no_coupon: {
    width: '100%',
    height: 283,
  },
  no_coupon_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  no_coupon_text_wrap: {
    ...Positions.fullscreen,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  expired_text1: {

    width: '100%',
    ...Texts.contents_text_43,
    color: "#999999",
    textAlign: 'start',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  expired_d_day: {
    width: 52,
    borderWidth: 0,
    ...Colors.background_d9d9d9,
  },
  expired_text2: {
    width: '100%',
    // width: responsiveScreenWidth(70),
    ...Spacings.margin_top_4,
    ...font_size_18,
    ...font_weight_bold,
    ...font_letter_spacing_056,
    color: "#999999",
    ...Texts.font_weight_600,
    textAlign: 'start',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  expired_d_day_text: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    lineHeight: 14,
    letterSpacing: 0,
    color: "#c4c4c4",
  },
  expired_coupon_icon: {
    zIndex: 1,
    position: "absolute",
    right: 10,
    width: 36,
    height: 36,
    borderRadius: 4,
    backgroundColor: "#999999",
  },
  expired_coupon_text: {
    marginTop: 6,
    marginLeft: 8,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    fontSize: 12,
    fontWeight: "bold",
    letterSpacing: -0.48,
    color: "#ffffff",
  },
  sheet: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_16,
    ...Spacings.padding_bottom_19,
    ...Spacings.padding_left_right_20,
  },
  close_btn: {
    ...Positions.absolute,
    top: 6,
    right: 6,
  },
  sheet_title_wrap: {
    ...Arranges.center_v_h,
    height: 32,
  },
  sheet_title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  sort_list: {
    ...Spacings.margin_top_16,
  },
  sort_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_top_bottom_11,
    width: '100%',
  },
  sort_text: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
  },
  sort_active_text: {
    ...Colors.font_a760b1,
  },
  indicator: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_left_right_7,
  },
};
