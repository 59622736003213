import React, { useEffect, useMemo, useRef, useState } from "react";

import { TitleHeader } from "../../../components/common";
import CommentStore, { CommentInEdit } from "../../../store/CommentStore";
import { HeaderItem } from "../../../constants/Header";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
  Icons,
} from "../../../assets/styles";

import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import { handleTopButtonScroll } from "../../../utils/common";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { Styles } from "../../../assets/types/Style";
import CommentItem from "./CommentItem";
import CommentEmpty from "./CommentEmpty";
import { goBack } from "../../../hooks/navigate/NavigateFunction";

const CommentList = observer(() => {
  const { t } = useTranslation();

  const commentListRef = useRef<HTMLDivElement>(null);

  const {
    commentList,
    commentCount,
    loading,
    retrieveCommentList,
    setCommentInEdit,
    setScrollHandler,
  } = CommentStore;
  const [scrolling, setScrolling] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [showTopButton, setShowTopButton] = useState<boolean>(false);

  useEffect(() => {
    return () => setCommentInEdit({});
  }, []);

  const handleScroll = (event: any) => {
    const position = event.nativeEvent.contentOffset.y;
    setScrolling(position > 0);

    handleTopButtonScroll(
      event,
      () => {
        setShowTopButton(true);
      },
      () => {
        setShowTopButton(false);
      },
      commentListRef
    );
  };

  const handlePressAddReplyBtn = (comment: CommentInEdit) => {
    setCommentInEdit(comment);
  };

  // const scrollFlatlistToIndex = (index: number, waitFor = 0) => {
  //   if (waitFor) {
  //     setTimeout(() => {
  //       commentListRef.current?.scrollToIndex(index);
  //     }, waitFor);
  //   } else {
  //     commentListRef.current?.scrollToIndex(index);
  //   }
  // };

  const handleBestPicked = () => {
    window.scrollTo(0, 0);
  };

  const handleChildCommentShown = (index: number) => () => {
    window.scrollTo(index, 100);
  };

  /* eslint-disable */
  const memoisedTitle = useMemo(() => {
    return (
      <TitleHeader
        showBorder={scrolling}
        title={t("screen.comment.title")}
        rightItem={HeaderItem.NONE}
        // onPress={() => {
        //   commentListRef.current?.scrollToIndex({ index: 0 });
        // }}
      />
    );
  }, [scrolling, commentCount]);
  /* eslint-enable */

  // useEffectOnlyOnce(() => {
  //   setScrollHandler((index: number) => scrollFlatlistToIndex(index));
  // });

  return (
    <>
      <div style={styles.screen_wrap}>
        {memoisedTitle}
        <div ref={commentListRef} style={styles.comments_wrap}>
          {commentList && commentList.length > 0 ? (
            <>
              {commentList.map((item: any, index: number) => (
                <CommentItem
                  key={index}
                  aria-label="Comment list"
                  comment={item}
                  index={index}
                  onPressAddReplyBtn={handlePressAddReplyBtn}
                  // onChildCommentShown={handleChildCommentShown(index)}
                  // onBestPicked={handleBestPicked}
                />
              ))}
              {/* <TopButton showTopButton={showTopButton} ref={commentListRef} /> */}
            </>
          ) : (
            <CommentEmpty />
          )}
        </div>
      </div>
    </>
  );
});

export default CommentList;

const styles: Styles = {
  comments_wrap: {
    ...FlexBoxs.flex_1,
    paddingBottom: 63,
  },
  screen_wrap: {
    ...FlexBoxs.flex_1,
    ...Colors.background_fff,
  },
  header: {
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_bottom_11,
    ...Colors.background_fff,
  },
  left: {
    width: 78,
    ...FlexBoxs.horizon,
  },
  center: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  right: {
    width: 78,
    ...FlexBoxs.horizon,
    ...Arranges.end_v,
  },
  icons: {
    ...Icons.icon_32,
    ...Spacings.margin_right_6,
  },
  title_txt: {
    ...Texts.header_text_0,
  },
  count: {
    ...Texts.header_text_1,
  },
  empty_wrap: {
    ...FlexBoxs.flex_1,
    ...Arranges.center_v_h,
  },
  empty_text: {
    ...Texts.contents_text_0,
  },
  footer_wrap: {
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_9,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Colors.background_fcfafc,
    ...Colors.border_color_f0dff2,
    borderTopWidth: 1,
    // paddingBottom: Platform.OS === 'ios' ? 16 : 8,
    paddingBottom: 8,
    zIndex: 1000,
  },
  comment_input: {
    ...Colors.background_fff,
    ...Colors.border_color_d8b8d8,
    ...Spacings.border_radius_22,
    ...Spacings.margin_right_8,
    ...Spacings.padding_right_left_18,
    ...FlexBoxs.flex_1,
    ...Texts.button_text_4,
    // paddingTop: Platform.OS === 'ios' ? 12 : 8,
    // paddingBottom: Platform.OS === 'ios' ? 12 : 8,
    paddingTop: 8,
    paddingBottom: 8,
    borderWidth: 1,
    maxHeight: 100,
  },
  comment_enter_wrap: {
    ...Spacings.margin_right_8,
    ...Arranges.center_v_h,
  },
  comment_enter_text: {
    ...Texts.button_text_5,
  },
  comment_enter_text_focused: {
    ...Texts.contents_text_10,
  },
  comment_input_focused: {
    ...Colors.border_color_6c2773,
  },
  bottom_sheet_view: {
    ...Spacings.padding_h_default,
    ...Spacings.padding_top_16,
  },
  bottom_sheet_scroll_view: {
    ...Spacings.padding_h_default,
    ...Spacings.padding_bottom_50,
    ...Spacings.padding_top_8,
  },
  bottom_sheet_close: {
    ...Arranges.end_h,
  },
  bottom_sheet_list: {
    height: "100%",
  },
  title_border: {
    // ...Platform.select({
    //   ios: {
    //     shadowColor: '#000000',
    //     shadowOffset: { width: 1, height: 3 },
    //     shadowOpacity: 0.15,
    //   },
    //   android: {
    //     elevation: 6,
    //   },
    // }),
  },
  divider: {
    borderBottomWidth: 1,
    borderColor: "#2222221a",
  },
};
