export enum UploadStatus {
  COMPRESS = "COMPRESS",
  UPLOADING = "UPLOADING",
  COMPLETE = "COMPLETE",
  STOP = "STOP",
  FAIL = "FAIL",
}

export enum UploadFileType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  THUMBNAIL = "THUMBNAIL",
}
