import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import PostDetailStore from "../../store/PostDetailStore";
import PostDetail from "./presenter/PostDetail";
import { useTranslation } from "react-i18next";
import { convertPostDetailToPost } from "../../utils/convert";
import MainStore from "../../store/MainStore";
import PostStore from "../../store/PostStore";
import MissionStore from "../../store/MissionStore";
import { NotificationModal } from "../../components/modals";
import BookmarkStore from "../../store/BookmarkStore";
import MyHomeStore from "../../store/MyHomeStore";
import FeedDetailStore from "../../store/FeedDetailStore";
import { UserActTypeCode } from "../../constants/FeedType.enum";
import { CommentTypeCode } from "../../constants/Comment";
import { useLocation, useParams } from "react-router-dom";
import CommonStore from "../../store/CommonStore";
// import CommentStore from "../../store/CommentStore";
import { Post } from "../../api/feed/model";
import { AccountFeedTypeCode } from "../../constants/Account";
import { FeedTypeCode } from "../../constants/Feed";
import TagStore from "../../store/TagStore";
import SearchStore from "../../store/SearchStore";
import useFeedScreen from "../feed/hook/UseFeedScreen";
// import { PostDetailResponse } from "../../api/post/model";
import "./PostDetailScreen.scss";
import { goBack, goTo } from "../../hooks/navigate/NavigateFunction";

const PostDetailScreen = () => {
  const [showNoDataPopup, setShowNoDataPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const [modalButtonName, setModalButtonName] = useState<string>(
    t("common.label.check")
  );
  const post = useRef<Post>();

  const { id } = useParams();
  const { state } = useLocation();

  const feedId: number = state?.feed_id || id;
  const postIndex: number = state?.postIndex || PostDetailStore.postIndex;
  const listIndex: number = state?.listIndex || PostDetailStore.listIndex;
  const isShowComment: boolean = state?.isShowComment;
  const lifecareProductId: number = state?.lifecareProductId;

  const { getPostDetail, setIndexes, clear } = PostDetailStore;

  const { postsLists, selectedTabCode } = PostStore;

  const { initialFetchedPosts } = useFeedScreen(selectedTabCode);

  // useEffect(() => {
  //   try {
  //     const routes = navigation.getState().routes;
  //     if (routes && routes.length > 0 && routes[0].state?.history) {
  //       const history = routes[0].state?.history as Array<{ key: string; type: string }>;
  //       if (history && history.length > 0 && history[history.length - 1].key.includes("HomeStack")) {
  //         setModalButtonName(t("common.label.gotoHome"));
  //       }
  //     } else if (!routes[0].state) {
  //       setModalButtonName(t("common.label.gotoHome"));
  //     }
  //   } catch (e) {
  //     //
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [navigation]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const moveToComment = useCallback(() => {
    // push("CommentScreen", {
    //   feedId: feedId,
    //   feedType: CommentTypeCode.POST,
    //   // feedAuthorId: PostDetailStore.postDetailResult.createMemberUuid,
    //   // postIndex: postIndex,
    //   // listIndex: listIndex,
    // });
    goTo(`/${CommentTypeCode.POST}/${feedId}/comment`, {
      state: {
        feedId: feedId ?? 0,
        feedType: CommentTypeCode.POST,
        feedAuthorId: post.current?.createMemberUuid,
      },
    });
  }, [feedId]);

  useEffect(() => {
    if (isShowComment) {
      moveToComment();
    }
  }, [isShowComment, moveToComment]);

  useLayoutEffect(() => {
    const asyncFunc = async (feedId: number, isLastUpdate = false) => {
      const res = await getPostDetail(feedId, false);

      if (res == null) {
        // 알림에서 이동 할 경우 팝업이 안뜨는 현상이 있어 수정
        setTimeout(() => {
          setShowNoDataPopup(true);
        }, 500);
      } else {
        setIndexes(postIndex, listIndex);

        const convertedPost = convertPostDetailToPost(res);
        post.current = convertedPost;

        MainStore.updateFeed(UserActTypeCode.MODIFY, feedId, convertedPost);
        PostStore.setPostWithFeedId(convertedPost, feedId);
        MissionStore.setPostWithFeedId(convertedPost, feedId);
        BookmarkStore.setPostWithFeedId(convertedPost, feedId);
        MyHomeStore.updatePost(feedId, convertedPost);
        FeedDetailStore.updatePostInAllFeedListStore(
          UserActTypeCode.MODIFY,
          feedId,
          convertedPost
        );
      }
    };

    if (feedId) {
      asyncFunc(feedId).finally(() => {
        setIsLoading(false);
      });
    }

    setIsLoading(false);
  }, [
    post,
    feedId,
    postIndex,
    listIndex,
    getPostDetail,
    clear,
    setIndexes,
    setShowNoDataPopup,
  ]);

  const updatePost = useCallback(
    (
      feedId: number,
      actionType: UserActTypeCode,
      listIndex: number,
      postIndex: number
    ) => {
      if (
        MainStore.mainList.length > 0 &&
        MainStore.popularPostsList.length > 0
      ) {
        MainStore.updateFeed(actionType, feedId);
      }

      if (PostStore.postsLists.length > 0) {
        PostStore.updatePostStoreList(actionType, feedId);
      }

      if (FeedDetailStore.feedList && FeedDetailStore.feedList.length > 0) {
        FeedDetailStore.updatePostInAllFeedListStore(actionType, feedId);
        post.current && FeedDetailStore.updateFeedList(feedId, post.current);
      }

      MyHomeStore.sync(feedId, AccountFeedTypeCode.POST, actionType);
      TagStore.sync(feedId, FeedTypeCode.POST, actionType);
      SearchStore.syncPost(feedId, actionType);

      if (postsLists) {
        if (
          UserActTypeCode.CANCEL_LIKE === actionType &&
          postsLists[postIndex].likeYn != "N"
        ) {
          postsLists[postIndex] = {
            ...postsLists[postIndex],
            likeCount: ((postsLists[postIndex].likeCount as number) ?? 1) - 1,
            likeYn: "N",
          };
        } else if (
          UserActTypeCode.SAVE_LIKE === actionType &&
          postsLists[postIndex].likeYn != "Y"
        ) {
          postsLists[postIndex] = {
            ...postsLists[postIndex],
            likeCount: ((postsLists[postIndex].likeCount as number) ?? 0) + 1,
            likeYn: "Y",
          };
        } else if (UserActTypeCode.CANCEL_BOOKMARK === actionType) {
          postsLists[postIndex] = {
            ...postsLists[postIndex],
            bookmarkYn: "N",
          };
        } else if (UserActTypeCode.SAVE_BOOKMARK === actionType) {
          postsLists[postIndex] = {
            ...postsLists[postIndex],
            bookmarkYn: "Y",
          };
        }
      } else {
        if (
          UserActTypeCode.CANCEL_LIKE === actionType &&
          initialFetchedPosts[postIndex].likeYn != "N"
        ) {
          initialFetchedPosts[postIndex] = {
            ...initialFetchedPosts[postIndex],
            likeCount:
              ((initialFetchedPosts[postIndex].likeCount as number) ?? 1) - 1,
            likeYn: "N",
          };
        } else if (
          UserActTypeCode.SAVE_LIKE === actionType &&
          initialFetchedPosts[postIndex].likeYn != "Y"
        ) {
          initialFetchedPosts[postIndex] = {
            ...initialFetchedPosts[postIndex],
            likeCount:
              ((initialFetchedPosts[postIndex].likeCount as number) ?? 0) + 1,
            likeYn: "Y",
          };
        } else if (UserActTypeCode.CANCEL_BOOKMARK === actionType) {
          initialFetchedPosts[postIndex] = {
            ...initialFetchedPosts[postIndex],
            bookmarkYn: "N",
          };
        } else if (UserActTypeCode.SAVE_BOOKMARK === actionType) {
          initialFetchedPosts[postIndex] = {
            ...initialFetchedPosts[postIndex],
            bookmarkYn: "Y",
          };
        }
      }
    },
    [MainStore, PostStore, FeedDetailStore, MyHomeStore, TagStore, SearchStore]
  );

  return (
    <div id="PostDetailScreen">
      {!showNoDataPopup ? (
        <PostDetail
          isLoading={isLoading}
          onLike={(like: string) => {
            if (post.current?.likeYn === "Y" && post.current?.likeYn != like) {
              post.current.likeCount = post.current.likeCount - 1;
              post.current.likeYn = like;

              updatePost(
                feedId,
                UserActTypeCode.CANCEL_LIKE,
                listIndex,
                postIndex
              );
            } else if (
              post.current?.likeYn === "N" &&
              post.current?.likeYn != like
            ) {
              post.current.likeCount = post.current.likeCount + 1;
              post.current.likeYn = like;

              updatePost(
                feedId,
                UserActTypeCode.SAVE_LIKE,
                listIndex,
                postIndex
              );
            }
          }}
          onBookmark={(bookmark: string) => {
            if (post.current) {
              post.current.bookmarkYn = bookmark;
            }
            if (bookmark === "N")
              updatePost(
                feedId,
                UserActTypeCode.CANCEL_BOOKMARK,
                listIndex,
                postIndex
              );
            else
              updatePost(
                feedId,
                UserActTypeCode.SAVE_BOOKMARK,
                listIndex,
                postIndex
              );
          }}
          // lifecareProductId={lifecareProductId}
          onDelete={
            // FeedScreen.onReportDelete
            () => {
              CommonStore.setShowDownloadAppDrive(true);
            }
          }
        />
      ) : (
        <NotificationModal
          isVisible={showNoDataPopup}
          contents1={t("common.message.blockedOrDeleted")}
          useTwoButton={false}
          isBlack={true}
          onRequestClose={() => {
            setShowNoDataPopup(false);
            goBack();
          }}
          defaultButtonText={modalButtonName}
        />
      )}
    </div>
  );
};

export default PostDetailScreen;
