import React, { useState } from "react";
import "./BasicHeader.scss";

import { ReactComponent as SearchIcon } from "../../assets/icons/new_search.svg";
import { ReactComponent as MenuIcon } from "../../assets/icons/new_menu.svg";
import { ReactComponent as AngleIcon } from "../../assets/icons/new_back_arrow.svg";
import { ReactComponent as HomeIconWhite } from "../../assets/icons/gnb-home-w-32.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/gnb-home-32.svg";

import TouchableWithAuthCheck from "../common/TouchableWithAuthCheck";
import MainStore from "../../store/MainStore";
import TabStore from "../../store/TabStore";
import RightNav from "../common/RightNav";
import { goBack, goTo } from "../../hooks/navigate/NavigateFunction";

export interface BasicHeaderProps {
  title: string;
  isHome?: boolean;
  isHomeWhite?: boolean;
  onSearch?: () => void;
  onClickHome?: () => void;
  backColor?: string;
}

const BasicHeader = ({
  title,
  isHome,
  isHomeWhite,
  onSearch,
  onClickHome,
  backColor,
}: BasicHeaderProps) => {
  const { setTabBarDisplay } = TabStore;
  const { showBottomSheet } = MainStore;

  const [showRightNav, setShowRightNav] = useState<boolean>(false);

  const moveToSearch = () => {
    if (onSearch) {
      onSearch();
    } else {
    }
  };

  const showDrawerMenu = () => {
    setShowRightNav(true);
  };

  const moveToHome = () => {
    if (onClickHome !== undefined) onClickHome();
    else {
      goTo("/Main");
    }
  };

  return (
    <>
      <div className="header common-header-system">
        <div className="left" style={{height: "100%", alignItems: "center", gap: 6}}>
          <button onClick={() => goBack()} aria-label="back" style={{display: "flex"}}>
            <AngleIcon />
          </button>
          {isHome && (
            <button onClick={moveToHome} aria-label="home" style={{display: "flex"}}>
              {isHomeWhite ? <HomeIconWhite /> : <HomeIcon />}
            </button>
          )}
        </div>
        <div className="center">
          <p className="title_txt">{title}</p>
        </div>
        <div className="right" style={{height: "100%", alignItems: "center", gap: 6}}>
          <button
            onClick={moveToSearch}
            className="search_btn"
            aria-label="search"
            style={{display: "flex"}}
          >
            <SearchIcon />
          </button>
          <button onClick={showDrawerMenu} aria-label="menu" style={{display: "flex"}}>
            <MenuIcon />
          </button>
        </div>
      </div>
      <RightNav
        open={showRightNav}
        onClose={() => {
          setShowRightNav(false);
        }}
      />
    </>
  );
};
export default BasicHeader;
