import React, { useState } from "react";
import { ReactComponent as ImgPushAgree } from "../../assets/icons/img_push_agree.svg";
import { useTranslation } from "react-i18next";
import CommonStore from "../../store/CommonStore";
import { getDateFormatKo } from "../../utils/datetime";
import { AllowTypeCode } from "../../constants/CommonCode.enum";
import Modal from "react-modal";
import "./MarketingAgreeModal.scss";

export interface MarketingAgreeModalInterface {
  isModalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onAgreeBtnPress: () => void;
  onDisagreeBtnPress: () => void;
  onCloseCallback: () => void | undefined;
}

export default function MarketingAgreeModal({
  isModalVisible,
  setModalVisible,
  onAgreeBtnPress,
  onDisagreeBtnPress,
  onCloseCallback,
}: MarketingAgreeModalInterface): JSX.Element {
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const showToast = (ynValue: AllowTypeCode) => {
    const date = getDateFormatKo();
    const notificationType = t(`screen.setting.label.updateAlramToast.ALARM`);
    const isAgree = t(`screen.setting.label.updateAlramToast.${ynValue}`);

    CommonStore.setToastOption({
      show: true,
      message1: `${t("screen.setting.label.updateAlramToast.message1", {
        date,
      })}`,
      message2: `${t("screen.setting.label.updateAlramToast.message2", {
        notificationType,
        isAgree,
      })}`,
      // bottomOffset: 30,
    });
  };

  const onModalHideCallback = () => {
    onCloseCallback && onCloseCallback();
  };

  const { t } = useTranslation();
  const modalStyle: Modal.Styles = {
    content: {
      position: "fixed",
      bottom: 0,
      top: 0,
      left: "clamp(0px, calc(50% - 225px), 50vw)",
      maxWidth: "450px",
      border: "none",
      padding: "0",
      backgroundColor: "transparent",
      width: "100%",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 10000,
      maxWidth: 450,
      margin: "auto",
    },
  }
  return (
    <Modal
      isOpen={isModalVisible}
      style={modalStyle}
      id="MarketingAgreeModal"
      onRequestClose={onModalHideCallback}
    >
      <div className="marketing_agree_modal">
        <div className="modal_header">
          <div className="header_text">
            {t("screen.MarketingAgreeModal.title.text1")}
          </div>
          <div className="header_text">
            {t("screen.MarketingAgreeModal.title.text2")}
          </div>
        </div>
        <div className="modal_body">
          <div className="icons">
            <ImgPushAgree />
          </div>
          <div className="text_0">
            {t("screen.MarketingAgreeModal.body.text1")}
          </div>
          <div className="text_0">
            {t("screen.MarketingAgreeModal.body.text2")}
          </div>
        </div>
        <div className="modal_footer">
          <div
            className="button"
            onClick={() => {
              toggleModal()
              onAgreeBtnPress()
              showToast(AllowTypeCode.Y)
            }}
          >
            <div className="footer_button_text">
              {t("screen.MarketingAgreeModal.footer.agree")}
            </div>
          </div>
        </div>
      </div>
      <div
        className="later_receive"
        onClick={() => {
          toggleModal()
          onDisagreeBtnPress()
          showToast(AllowTypeCode.N)
        }}
      >
        <div className="later_receive_text">
          {t("screen.MarketingAgreeModal.footer.disagree")}
        </div>
      </div>
    </Modal>
  )
}
