import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import classes from "./NotificationModal.module.scss";
import clsx from "clsx";
import { ReactNode } from "react";

export interface NotificationModalProps {
  isVisible: boolean;
  title?: string;
  contents1?: string;
  contents2?: string;
  defaultButtonText?: string;
  extraButtonText?: string;
  isBlack?: boolean;
  useTwoButton?: boolean;
  onRequestClose?: (() => void) | undefined;
  onClickDefaultButton?: (() => void) | undefined;
  onClickExtraButton?: (() => void) | undefined;
  modalCloseCallback?: (() => void) | undefined;
  textAlign?: "auto" | "left" | "right" | "center" | "justify";
  ref?: any | undefined;
  modalWidth?: number;
  children?: ReactNode;
}

const NotificationModal = ({
  title, // 타이틀
  contents1, // 내용1
  contents2, // 내용2
  defaultButtonText, // 기본 버튼 텍스트
  extraButtonText, // 2개 이상일때 왼쪽 버튼 텍스트
  isVisible, // 팝업 표시 여부
  isBlack, // 블랙 컬러의 버튼
  useTwoButton,
  onRequestClose, // 팝업 닫힌 후 본 페이지에서 해야할 동작
  onClickDefaultButton, // 기본 닫기 버튼 클릭 이벤트 (2개 이상일때는 오른쪽)
  onClickExtraButton, // 2개 버튼 팝업에서 왼쪽 버튼 클릭 이벤트
  modalCloseCallback, // 모달이 화면에서 사라질 경우 호출되는 콜백 메소드.
  textAlign,
  ref,
  modalWidth,
  children,
}: NotificationModalProps) => {
  const { t } = useTranslation();

  const dismiss = () => {
    if (onRequestClose !== undefined) onRequestClose();
  };

  const onDefaultButtonClicked = () => {
    if (onClickDefaultButton !== undefined) onClickDefaultButton();
    else dismiss();
  };

  const onExtraButtonClicked = () => {
    if (onClickExtraButton !== undefined) onClickExtraButton();
  };

  const onModalHideCallback = () => {
    document.body.classList.remove("modal-open");
    if (modalCloseCallback !== undefined) modalCloseCallback();
  };

  const onAfterOpen = () => {
    document.body.classList.add("modal-open");
  };

  return (
    <Modal
      ref={ref}
      isOpen={isVisible}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          maxWidth: 450,
          margin: "auto",
          zIndex: 100000,
        },
        content: {
          padding: 0,
          border: "none",
          borderRadius: "12px",
          top: "50%",
          left: "50%",
          backgroundColor: "unset",
          right: "auto",
          bottom: "auto",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
        },
      }}
      onAfterOpen={onAfterOpen}
      onRequestClose={dismiss}
      onAfterClose={onModalHideCallback}
    >
      <div
        className={classes.notification_modal}
        style={modalWidth ? { width: modalWidth } : undefined}
      >
        <div className={classes.modal_container}>
          <div className={classes.modal_header}>
            <div className={classes.header_text} aria-label="Title">
              {title && title !== undefined
                ? title
                : t("screen.notificationModal.label.alert")}
            </div>
            <button
              className={classes.icons}
              onClick={dismiss}
              aria-label="Close"
            >
              <CloseIcon />
            </button>
          </div>
          {contents1 && contents1.length !== 0 && (
            <div className={classes.modal_body} style={{ textAlign: textAlign as any }}>
              <div className={classes.text_0} aria-label="Contents1">
                {contents1}
              </div>
              {contents2 && contents2 !== undefined && (
                <div className={classes.text_1} aria-label="Contents2">
                  {contents2}
                </div>
              )}
            </div>
          )}
        </div>
        {children && <>{children}</>}
        {useTwoButton === true ? (
          <div className={classes.modal_footer_two}>
            <button
              className={clsx(
                classes.button_two,
                classes.button_left,
                isBlack ? classes.black : classes.gray
              )}
              onClick={onExtraButtonClicked}
              aria-label="Left button"
            >
              <span
                className={clsx(
                  classes.footer_button_text,
                  !isBlack && classes.footer_button_gray_text
                )}
              >
                {extraButtonText && extraButtonText !== undefined
                  ? extraButtonText
                  : t("screen.notificationModal.button.close")}
              </span>
            </button>
            <button
              className={clsx(
                classes.button_two,
                classes.button_right,
                classes.black
              )}
              onClick={onDefaultButtonClicked}
              aria-label="Right button"
            >
              <span className={classes.footer_button_text}>
                {defaultButtonText && defaultButtonText !== undefined
                  ? defaultButtonText
                  : t("screen.notificationModal.button.close")}
              </span>
            </button>
          </div>
        ) : (
          <div className={classes.modal_footer}>
            <button
              className={clsx(classes.button, classes.black)}
              onClick={onDefaultButtonClicked}
              aria-label="Close button"
            >
              <span className={classes.footer_button_text}>
                {defaultButtonText && defaultButtonText !== undefined
                  ? defaultButtonText
                  : t("screen.notificationModal.button.close")}
              </span>
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default NotificationModal;
