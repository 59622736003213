import { t } from "i18next";
import { action, computed, makeObservable, observable } from "mobx";
import { NotifyModalContext } from "../screens/mission/common/model/NotifyModalContext";
import UploadStore from "./upload/UploadStore";
import { track } from "../hooks/tracker/TrackFunction";
import { FeedMode, FeedTypeCode } from "../constants/Feed";
import PostWriteStore from "./PostWriteStore";
import QnaWriteStore from "./QnaWriteStore";
// import AbWriteStore from "./AbWriteStore";
import ArticleWriteStore from "./ArticleWriteStore";

export const INITIAL_WRITE_BUTTON_BOTTOM = 30;

class WriteButtonStore {
  constructor() {
    makeObservable(this);
  }
  @observable _bottom = INITIAL_WRITE_BUTTON_BOTTOM;
  @observable _showWriteButton = false;
  @observable _showWriteButtonBottomSheet = false;
  @observable _notifyContext: NotifyModalContext = {
    type: "",
    isShow: false,
    title: "",
    contents: "",
  };

  @computed get showWriteButton() {
    return this._showWriteButton;
  }

  @computed get showWriteButtonBottomSheet() {
    return this._showWriteButtonBottomSheet;
  }

  @computed get notifyContext() {
    return this._notifyContext;
  }
  @computed get bottom() {
    return this._bottom;
  }
  @action setBottom = (x: number) => {
    this._bottom = x;
  };

  @action setShowWriteButton = (x: boolean) => {
    this._showWriteButton = x;
  };

  @action setShowWriteButtonBottomSheet = (x: boolean) => {
    this._showWriteButtonBottomSheet = x;
  };

  @action setNotifyContext = (x: NotifyModalContext) => {
    this._notifyContext = x;
  };

  @action ClickWriteButton = (props?: any) => {
    const uploadStop = () => {
      UploadStore.abortController?.abort();
      UploadStore.stop();
      this.setNotifyContext({ ...this.notifyContext, isShow: false });
    };

    if (UploadStore.isDefaultStatus) {
      if (props && props.action) {
        props.action();
      } else {
        track("click_creation_button", {});
        this.setShowWriteButtonBottomSheet(true);
      }
    }

    if (UploadStore.isUploading) {
      const contents = () => {
        if (UploadStore.type === FeedTypeCode.POST) {
          return t("screen.notificationModal.message.unregisterPost");
        }
        if (UploadStore.type === FeedTypeCode.QNA) {
          return t("screen.notificationModal.message.unregisterQna");
        }
        if (UploadStore.type === FeedTypeCode.AB) {
          return t("screen.notificationModal.message.unregisterAB");
        }
        if (UploadStore.type === FeedTypeCode.ARTICLE) {
          return t("screen.notificationModal.message.unregisterArticle");
        }
      };

      this.setNotifyContext({
        title: t("screen.notificationModal.label.alert"),
        contents: contents(),
        isShow: true,
        needTwoButton: true,
        textAlign: "center",
        defaultButtonText: t("screen.notificationModal.button.confirm"),
        extraButtonText: t("screen.notificationModal.label.unregister"),
        onClickExtraButton: () => uploadStop(),
        onClickDefaultButton: () => {
          this.setNotifyContext({ ...this.notifyContext, isShow: false });
        },
      });
    }

    if (UploadStore.isFail) {
      const contents = () => {
        if (UploadStore.type === FeedTypeCode.POST) {
          return t("screen.notificationModal.message.registerFailPost");
        }
        if (UploadStore.type === FeedTypeCode.QNA) {
          return t("screen.notificationModal.message.registerFailQna");
        }
        if (UploadStore.type === FeedTypeCode.AB) {
          return t("screen.notificationModal.message.registerFailAB");
        }
        if (UploadStore.type === FeedTypeCode.ARTICLE) {
          return t("screen.notificationModal.message.registerFailArticle");
        }
      };

      this.setNotifyContext({
        title: t("screen.notificationModal.label.alert"),
        contents: contents(),
        isShow: true,
        needTwoButton: true,
        textAlign: "center",
        defaultButtonText: t("screen.notificationModal.button.retry"),
        extraButtonText: t("screen.notificationModal.button.cancel"),
        onClickDefaultButton: () => {
          this.setNotifyContext({ ...this.notifyContext, isShow: false });
          const mode = UploadStore.isUpdate ? FeedMode.MODIFY : FeedMode.WRITE;
          if (UploadStore.type === FeedTypeCode.POST) {
            PostWriteStore.asyncPosting(
              UploadStore.isUpdate ? FeedMode.MODIFY : FeedMode.WRITE
            );
          }
          if (UploadStore.type === FeedTypeCode.QNA) {
            QnaWriteStore.asyncCreateQna(mode);
          }
          if (UploadStore.type === FeedTypeCode.AB) {
            // AbWriteStore.asyncCreateAb();
          }
          if (UploadStore.type === FeedTypeCode.ARTICLE) {
            ArticleWriteStore.asyncCreateArticle(mode);
          }
        },
        onClickExtraButton: () => uploadStop(),
      });
    }
  };
}

export default new WriteButtonStore();
