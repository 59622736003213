import { action, computed, makeObservable, observable } from "mobx";
import { Qna } from "../api/qna/model";
import { getRecentlyQnaList, getInterestingQnaList } from "../api/qna/api";
import { getPopularQnaList } from "../api/feed/api";
import { getPopularAbListWithPagination } from "../api/ab/api";
import { AbDetail, PopularAbRequest } from "../api/ab/model";

class QnaMainStore {
  constructor() {
    makeObservable(this);
  }
  @observable _recentlyQnaList: Qna[] = [];
  @observable _popularAbList: AbDetail[] = [];
  @observable _popularQnaList: Qna[] = [];
  @observable _interestingQnaList: Qna[] = [];

  @observable _loading = false;

  @computed get recentlyQnaList() {
    return this._recentlyQnaList;
  }

  @computed get popularAbList() {
    return this._popularAbList;
  }

  @computed get popularQnaList() {
    return this._popularQnaList;
  }

  @computed get interestingQnaList() {
    return this._interestingQnaList;
  }

  @computed get loading() {
    return this._loading;
  }

  @action setRecentlyQnaList = (qnaList: Qna[]) => {
    this._recentlyQnaList = qnaList;
  };

  @action setpopularAbList = (abList: AbDetail[]) => {
    this._popularAbList = abList;
  };

  @action setPopularQnaList = (qnaList: Qna[]) => {
    this._popularQnaList = qnaList;
  };

  @action setInterestingQnaList = (qnaList: Qna[]) => {
    this._interestingQnaList = qnaList;
  };

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action clearQnaMainStore = () => {
    this._recentlyQnaList = [];
    this._popularAbList = [];
    this._popularQnaList = [];
    this._interestingQnaList = [];
  };

  @action initList = async (memberUuid: string) => {
    this.setLoading(true);
    await Promise.all([
      this.getRecentlyQnaList(),
      this.getPopularQnaList(),
      this.getInterestingQnaList(memberUuid),
      this.getpopularAbList(),
    ]);

    this.setLoading(false);
  };

  @action refreshQnaList = (memberUuid: string) => {
    void this.getRecentlyQnaList();
    void this.getPopularQnaList();
    void this.getInterestingQnaList(memberUuid);
  };

  @action getRecentlyQnaList = async () => {
    const pageSize = 5;
    const pageIndex = 0;
    const res = await getRecentlyQnaList(pageSize, pageIndex);

    if (typeof res === "string") {
      // failAction
    } else {
      this.setRecentlyQnaList(res);
    }
  };

  @action getPopularQnaList = async () => {
    const pageSize = 10;
    const pageIndex = 0;
    const res = await getPopularQnaList(pageSize, pageIndex);
    const data = res.data as Qna[];

    if (typeof res === "string") {
      // failAction
    } else {
      this.setPopularQnaList(data);
    }
  };

  @action getInterestingQnaList = async (memberUuid: string) => {
    const pageSize = 10;
    const pageIndex = 0;
    const res = await getInterestingQnaList(pageSize, pageIndex, memberUuid);
    if (typeof res === "string") {
      // failAction
    } else {
      this.setInterestingQnaList(res);
    }
  };

  @action getpopularAbList = async () => {
    const request: PopularAbRequest = {
      pageIndex: 0,
      pageSize: 10,
      endYn: "N",
    };

    const res = await getPopularAbListWithPagination(request);
    // console.log("res", res);
    if (res && res.successOrNot === "Y" && res.data && res.data.list) {
      this.setpopularAbList(res.data.list);
    }
  };

  @action updateQna = (feedId: number, qna: Qna) => {
    const recentlyQnaIdx = this._recentlyQnaList?.findIndex(
      (x) => x.feedId === feedId
    );
    const popularQnaIdx = this._popularQnaList?.findIndex(
      (x) => x.feedId === feedId
    );
    const interestingQnaIdx = this._interestingQnaList?.findIndex(
      (x) => x.feedId === feedId
    );

    if (recentlyQnaIdx !== -1) {
      this._recentlyQnaList[recentlyQnaIdx] = qna;
    }

    if (popularQnaIdx !== -1) {
      this._popularQnaList[popularQnaIdx] = qna;
    }

    if (interestingQnaIdx !== -1) {
      this._interestingQnaList[interestingQnaIdx] = qna;
    }
  };

  @action removeQna = (feedId: number) => {
    this._recentlyQnaList = this._recentlyQnaList.filter(
      (x) => x.feedId !== feedId
    );
    this._popularQnaList = this._popularQnaList.filter(
      (x) => x.feedId !== feedId
    );
    this._interestingQnaList = this._interestingQnaList.filter(
      (x) => x.feedId !== feedId
    );
  };

  @action updateAb = (feedId: number, ab: any) => {
    const abIdx = this._popularAbList.findIndex((x) => x.feedId === feedId);
    if (abIdx !== -1) {
      const abDetail = this._popularAbList[abIdx];

      abDetail.feedTitle = ab.feedTitle;
      abDetail.adesc = ab.adesc;
      abDetail.bdesc = ab.bdesc;
      abDetail.athumbnailFilePath = ab.athumbnailFilePath;
      abDetail.bthumbnailFilePath = ab.bthumbnailFilePath;
      abDetail.avoteCount = ab.avoteCount;
      abDetail.bvoteCount = ab.bvoteCount;
      abDetail.myAbFlag = ab.myAbFlag;

      this._popularAbList[abIdx] = abDetail;
    }
  };

  @action removeAb = (feedId: number) => {
    this._popularAbList = this._popularAbList.filter(
      (x) => x.feedId !== feedId
    );
  };

  @action clear = () => {
    this._recentlyQnaList = [];
    this._interestingQnaList = [];
    this._popularAbList = [];
    this._popularQnaList = [];
  };
}

export default QnaMainStore;
