import { useState } from "react";
// import { View, Text, TouchableOpacity, StyleSheet, NativeModules, Platform } from "react-native";
import { Colors, FlexBoxs, Spacings, Texts, Arranges } from "../../../assets/styles";
import {ReactComponent as PinIcon} from "../../../assets/icons/pin.svg";
import {ReactComponent as TelephoneIcon} from "../../../assets/icons/telephone.svg";
// import Accordion from "react-native-collapsible/Accordion";
import Accordion, { IAccordionSection } from '../../../components/common/collapsible/Accordion'
import {ReactComponent as ArrowDownIcon} from "../../../assets/icons/arrow_down.svg";
import {ReactComponent as ArrowUpIcon} from "../../../assets/icons/arrow_up.svg";
import { Styles } from "../../../assets/types/Style";
import { ProductAsCenter } from "../../../api/product/model";
import CustomParsedText from "../../../components/common/CustomParsedText";
import { useTranslation } from "react-i18next";
// import { responsiveScreenWidth } from "react-native-responsive-dimensions";

const AsCenter = ({ item }: { item: ProductAsCenter }) => {
  const {
    wrap,
    as_item,
    // last_as_item,
    as_info,
    as_info_top,
    as_text1,
    represent,
    as_text2,
    as_info_bottom,
    as_text3,
    as_text4,

    as_time_header,
    as_time_item,
    as_time_item_last,
    as_time_item_text,
    as_time_btn,
    as_time_btn_text,
    as_url_text,
    as_call_button,
    // no_accordian_url,
  } = styles;

  const asSections: IAccordionSection[] = [
    {
      title: '',
      content: item.operationHours && [...item.operationHours.split("\n")],
    }
  ]

  const { t } = useTranslation();
  // const { RNCommon } = NativeModules;

  const [activeAsSection, setActiveAsSection] = useState<number[]>([]);
  const updateAsSections = (activeAsSections: any) => setActiveAsSection(activeAsSections);
  
  const renderAsHeader = (section: IAccordionSection, index: string | number, isActive: boolean) => {
    return (
      <div style={as_time_header}>
        <p style={as_time_item_text}>
          {isActive ? section?.content[section?.content.length - 1].trim() : section?.content[0].trim()}{" "}
        </p>
        <div style={as_time_btn}>
          <p style={as_time_btn_text}>
            {isActive ? t(`screen.product.label.asCenter.collapse`) : t(`screen.product.label.asCenter.expand`)}
          </p>
          {isActive ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </div>
      </div>
    );
  };

  const renderAsContent = (section: IAccordionSection) => {
    return section.content
      .filter((item: string, index: number) => index < section.content.length - 1)
      .map((item: string, index: number) => {
        if (section.content.length > 1) {
          return (
            <div key={index} style={index == section.content.length - 2 ? as_time_item_last : as_time_item}>
              <p style={as_time_item_text}>{item.trim()}</p>
            </div>
          );
        }
      });
  };

  return (
    <div style={wrap}>
      <div style={as_item}>
        <div style={as_info}>
          <div style={as_info_top}>
            <button style={{height: 24}}>
              <PinIcon />
            </button>
            <span style={as_text1}>{item && item.officeName}</span>
            {item && item.officialYn == "Y" && (
              <div style={represent}>
                <p style={as_text2}>{t(`screen.product.label.asCenter.official`)}</p>
              </div>
            )}
            {item.distance != null && (
              <p style={as_text4}>
                {item.distance}
                {t(`screen.product.label.asCenter.km`)}
              </p>
            )}
          </div>
          <div style={as_info_bottom}>
            <p style={as_text3}>
              {item && item.address}
            </p>
          </div>
          {asSections[0].content && asSections[0].content.length > 1 ? (
            <Accordion
              sections={asSections}
              activeSections={activeAsSection}
              renderHeader={renderAsHeader}
              renderContent={renderAsContent}
              onChange={updateAsSections}
              underlayColor={"#fff"}
              expandFromBottom={true}
            />
          ) : asSections[0].content && asSections[0].content.length == 1 ? (
            <div style={as_time_item}>
              <p style={{
                ...as_time_item_text, 
                ...{overflow: 'hidden', display: '-webkit-box', '-webkit-line-clamp': '2', 'line-clamp': '2', '-webkit-box-orient': 'vertical'}
                }}
              >
                {asSections[0].content[0]}
              </p>
            </div>
          ) : (
            <></>
          )}

          {item && item.officeUrl && (
            <button style={{marginTop: '16px'}}>
              <CustomParsedText 
                // accessibilityLabel="Sns url" 
                urlStyle={as_url_text}
              >
                {item.officeUrl}
              </CustomParsedText>
            </button>
          )}
        </div>
        <button
          style={as_call_button}
          onClick={() => {
            // RNCommon.callNumber(item.phoneNumber, (res: any) => {
            //   /*success*/
            // });
            window.open(`tel:${item.phoneNumber}`, '_self');
          }}
        >
          <TelephoneIcon />
        </button>
      </div>
      <hr style={{border: '1px solid #EEEEEE', marginTop: '48px'}}/>
    </div>
  );
};

export default AsCenter;

const styles : Styles ={
  wrap: {
    ...Spacings.margin_bottom_24,
  },
  as_item: {
    ...FlexBoxs.flex,
    ...Arranges.between,
    ...FlexBoxs.horizon,
    ...Colors.border_color_eee,
    borderBottomWidth: 1,
  },
  last_as_item: {
    borderBottomWidth: 0,
  },
  as_info: {
    // width: responsiveScreenWidth(80),
    width: '80%'
  },
  as_info_top: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_right_60
  },
  as_text1: {
    ...Texts.contents_text_34,
    ...Texts.font_weight_500,
    height: 24,
    verticalAlign: "middle"
  },
  represent: {
    width: 32,
    height: 18,
    ...Arranges.center_v_h,
    ...Colors.background_fce74e,
    ...Spacings.margin_left_4,
    // ...Platform.select({
    //   ios: { borderRadius: 6 },
    //   android: { borderRadius: 8 },
    // }),
    display: "flex",
    borderRadius: 6,
  },
  as_text2: {
    ...Texts.contents_text_48,
    ...Colors.font_222,
  },
  as_info_bottom: {
    ...Spacings.margin_top_8,
    ...Spacings.margin_bottom_6,
  },
  as_text3: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2', 
    'line-clamp': '2', 
    '-webkit-box-orient': 'vertical'
  },
  as_text4: {
    ...Spacings.margin_left_4,
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },

  as_time_header: {
    ...Spacings.margin_bottom_6,
    ...Spacings.margin_top_2,
  },
  as_time_item: {
    ...Spacings.margin_bottom_6,
  },
  as_time_item_last: {
    ...Spacings.margin_bottom_3,
  },
  as_time_item_text: {
    ...Texts.contents_text_8,
  },
  as_time_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_top_4,
  },
  as_time_btn_text: {
    ...Texts.contents_text_8,
    ...Texts.font_weight_500,
  },
  as_url_text: {
    ...Spacings.margin_left_4,
    ...Spacings.margin_top_18,
    ...Texts.contents_text_25,
    ...Texts.text_underline,
    ...Colors.font_999,
  },
  as_call_button: {
    ...FlexBoxs.flex,
    alignItems: 'start'
  }
};
