import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Arranges, Colors, Spacings, Texts } from '../../../assets/styles'
import { CategoryInfo } from '../../../api/mallCategory/model'
import FastImageWithFallback from '../../../components/common/FastImageWithFallback'
import { goToLifecare, goToLifecareMain } from '../../../utils/mall'
import CommonStore from '../../../store/CommonStore'
import { track } from '../../../hooks/tracker/TrackFunction'
import { useNavigate } from 'react-router-dom'
import classes from './MallMainCategoryList.module.scss'

const MallMainCategoryList = observer(
  ({ categoryList }: { categoryList: CategoryInfo[] }) => {
    const [renderData, setRenderData] = useState<CategoryInfo[]>([])
    const navigate = useNavigate()
    const widthCategory = (CommonStore.widthApp - 40 - 16 * 4) / 5

    useEffect(() => {
      const temp: CategoryInfo[] = [
        {
          displayCategoryId: -1,
          displayCategoryName: '전체보기',
          displayImageUrl: '/MALL_CATEGORY/all.png',
          targetLink: '',
          parentDisplayCategoryId: '-1',
          sortOrder: -1,
        },
      ]
      setRenderData([...categoryList, ...temp])
    }, [categoryList])

    const onPress = (item: CategoryInfo) => {
      track('click_div_category_item', {
        category_name: item.displayCategoryName,
        category_id: item.displayCategoryId,
      })
      if (item.displayCategoryId === -1) {
        goToLifecareMain()
      } else if (item.targetLink.length > 0) {
        goToLifecare(item.targetLink)
      } else {
        // CommonStore.setLoading(true)
        navigate(`/mall/category/large/${item.displayCategoryId}`)
      }
    }

    const Card = (props: CategoryInfo) => {
      return (
        <div className={classes.btn_category} onClick={() => onPress(props)}>
          <FastImageWithFallback
            className={classes.bx_icon_r}
            wrapperStyle={{
              width: widthCategory,
              height: widthCategory,
              borderRadius: '50%',
              backgroundColor: '#F7F4FA',
            }}
            style={{
              width: '100%',
              transform:
                props.displayCategoryId === -1 ? 'unset' : 'scale(0.9)',
            }}
            source={{
              uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${props.displayImageUrl}`,
            }}
          />
          <span className={classes.txt_category_name}>
            {props.displayCategoryName}
          </span>
        </div>
      )
    }

    const renderItem = (index: number, item: CategoryInfo) => {
      const dataLength = renderData.length
      const endIndex =
        dataLength < 10
          ? 5
          : dataLength % 2 === 0
          ? dataLength / 2
          : Math.floor(dataLength / 2) + 1

      return index < endIndex ? (
        <div key={item.displayCategoryId} className={classes.group_item}>
          <Card {...renderData[index]} />
          {renderData[index + endIndex] && (
            <Card {...renderData[index + endIndex]} />
          )}
        </div>
      ) : (
        <></>
      )
    }

    return renderData && renderData.length > 0 ? (
      <>
        <div className={classes.bx_scroll_div}>
          <div className={classes.mall_category_list}>
            {renderData.map((item, index) => renderItem(index, item))}
          </div>
          {/* 몰메인 툴팁1 */}
          {CommonStore.isShowMallMainTooltip1 && (
            <div
              style={{
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
                bottom: -40,
                right: '8%',
                zIndex: 1000,
              }}
            >
              <div
                onClick={() => {
                  CommonStore.disabledMallMainTooltip()
                }}
              >
                <div
                  style={{
                    top: -14,
                    right: 20,
                    position: 'absolute',
                    width: 20,
                    height: 20,
                    // TODO:
                    // transform: [{ rotate: '45deg' }],
                    borderRadius: 3,
                    backgroundColor: '#222222',
                  }}
                />
                <div
                  style={{
                    flexDirection: 'row',
                    backgroundColor: '#222222',
                    ...Arranges.center_v_h,
                    marginTop: -5,
                    ...Spacings.padding_top_bottom_4,
                    ...Spacings.border_radius_12,
                    width: 120,
                    height: 56,
                  }}
                >
                  <span
                    style={{
                      textAlign: 'center',
                      ...Texts.contents_text_5,
                      ...Texts.font_weight_500,
                      ...Colors.font_fff,
                      lineHeight: 16,
                    }}
                  >
                    <span
                      style={{
                        color: '#f7de2a',
                      }}
                    >
                      220몰의 모든 제품
                    </span>
                    을{'\n'}
                    구경해 보세요!
                    {/** TODO: 다국어화 */}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    ) : (
      <></>
    )
  }
)
export default MallMainCategoryList
