export enum BannerTypeCodes {
  MAIN_BANNER = "MAIN_BANNER",
  MAIN_POP_UP_BANNER = "MAIN_POP_UP_BANNER",
  MAIN_POP_UP_NOTIFICATION_BANNER = "MAIN_POP_UP_NOTIFICATION_BANNER",
  MAIN_MIDDLE_BAND_BANNER = "MAIN_MIDDLE_BAND_BANNER",
  COMMUNITY_ARTICLE_TOP_BANNER = "COMMUNITY_ARTICLE_TOP_BANNER",
  COMMUNITY_ARTICLE_TOP_AD = "COMMUNITY_ARTICLE_TOP_AD",
  COMMUNITY_ARTICLE_LIST_AD = "COMMUNITY_ARTICLE_LIST_AD",
  MALL_MAIN_BANNER = "MALL_MAIN_BANNER",
  MALL_MAIN_BAND_BANNER = "MALL_MAIN_BAND_BANNER",
  MALL_DISPLAY_AD = "MALL_DISPLAY_AD",
  BRAND_MYHOME_BANNER = "BRAND_MYHOME_BANNER",
  BRAND_COMMUNITY_MAIN_BANNER = "BRAND_COMMUNITY_MAIN_BANNER",
  BRAND_HOME_TOP_BANNER = "BRAND_HOME_TOP_BANNER",
  BRAND_HOME_TEXT_BANNER = "BRAND_HOME_TEXT_BANNER",
  BRAND_HOME_LIST_AD = "BRAND_HOME_LIST_AD",
  BRAND_HOME_AD = "BRAND_HOME_AD",
  ROLLING_1_AD = "ROLLING_1_AD",
  ROLLING_2_AD = "ROLLING_2_AD",
}

export enum LinkType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
  MALL = "MALL",
}

export enum ContentType {
  POST = "POST",
  MAGAZINE = "MAGAZINE",
  QNA = "QNA",
  MISSION = "MISSION",
  MY_HOME = "MYHOME",
  RANKING = "RANKING",
  SETTING = "SETTING",
  NOTIFICATION = "NOTIFICATION",
  AB = "AB",
  PRODUCT = "PRODUCT",
  MAGAZINE_HOME = "MAGAZINE_HOME",
  MISSION_HOME = "MISSION_HOME",
  QNA_HOME = "QNA_HOME",
  QNA_LIST = "QNA_LIST",
  AB_LIST = "AB_LIST",
  POST_HOME = "POST_HOME",
  NOTICE = "NOTICE",
  FRIEND_RECOMMEND = "FRIEND_RECOMMEND",
  BRAND_HOME = "BRAND_HOME",
  BRAND_COMMUNITY_MAIN = "BRAND_COMMUNITY_MAIN",
  BRAND_COMMUNITY_POST = "BRAND_COMMUNITY_POST",
  MALL_DISPLAY_PAGE = "MALL_DISPLAY_PAGE",
  MALL_PRODUCT = "MALL_PRODUCT",
  MALL_CATEGORY = "MALL_CATEGORY",
}
