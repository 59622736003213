import React from "react";
import { ReactComponent as NothingSearchGridImage } from "../../../assets/images/nodata_search_grid_bg.svg";
import { ReactComponent as NothingSearchListImage } from "../../../assets/images/nodata_search_list_bg.svg";
import { observer } from "mobx-react";
import SearchStore from "../../../store/SearchStore";
import "./SearchEmpty.scss";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

const SearchEmpty = observer(({ isGrid }: { isGrid: boolean }) => {
  const { t } = useTranslation();

  return (
    <div id="SearchEmpty" className={clsx("nothing_search_img", isGrid ? "grid" : "list")}>
      {isGrid ? <NothingSearchGridImage /> : <NothingSearchListImage />}
      <div className="empty_wrap">
        <div className="empty_text" aria-label="Search result no data">
          {SearchStore.getLoadingInList === false &&
            t("screen.search.message.nodata")}
        </div>
      </div>
    </div>
  );
});

export default SearchEmpty;
