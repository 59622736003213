import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  CategoryInfo,
  CategoryScreenType,
} from '../../../api/mallCategory/model'
import MallStore from '../../../store/MallStore'
import classes from './CategoryList.module.scss'

const CategoryList = observer(
  ({
    currentCategory,
    renderData,
    goToCategoryScreen,
  }: {
    currentCategory: CategoryScreenType
    renderData: CategoryInfo[]
    goToCategoryScreen: (
      type: CategoryScreenType,
      categoryId: number,
      categoryName: string
    ) => void
  }) => {
    return (
      <>
        <div className={classes.lk_cate_wrap}>
          {renderData.map((item, index) => (
            <div
              key={index}
              className={classes.lk_cate_title}
              // style={[
              //   index === 0 || index === 1 || index === 2
              //     ? {
              //         borderTopWidth: 0,
              //       }
              //     : {},
              //   index % 3 === 2 ? { borderRightWidth: 0 } : {},
              // ]}
              onClick={() => {
                MallStore.setIsMallCateforyFilterReset(true)
                const type =
                  currentCategory === CategoryScreenType.L
                    ? CategoryScreenType.M
                    : CategoryScreenType.S
                goToCategoryScreen(
                  type,
                  item.displayCategoryId,
                  item.displayCategoryName
                )
              }}
            >
              <text className={classes.txt_cate_title}>
                {item.displayCategoryName}
              </text>
            </div>
          ))}
        </div>
      </>
    )
  }
)

export default CategoryList
