import { observer } from 'mobx-react'
import FastImageWithFallback from '../../../components/common/FastImageWithFallback'
import { ReactComponent as BookmarkOff } from '../../../assets/icons/icon_bookmark_off.svg'
import { ReactComponent as BookmarkON } from '../../../assets/icons/icon_bookmark_on.svg'
import { getFormatprice } from '../../../utils/mall'
import { PostedPopularProduct } from '../../../api/mall/model'
import { ReactComponent as IconMoreArrow } from '../../../assets/icons/btn_txt_arrow.svg'
import './MallMainPopularProduct.scss'
import { Virtuoso } from 'react-virtuoso'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import Slider, { Settings } from 'react-slick'
import CommonStore from '../../../store/CommonStore'

const MallMainPopularProduct = observer(
  ({
    listKey,
    renderData,
    goToFeedScreen,
    goToProduct,
    handleBookmark,
    dataChangeToggle,
  }: {
    listKey: string
    renderData: PostedPopularProduct[]
    goToFeedScreen: (
      index: number,
      productId: number,
      lifecareProductId: number,
      item?: any
    ) => void
    goToProduct: (item: any) => void
    handleBookmark: (
      bookmarkYn: string,
      targetId: number,
      listKey?: string
    ) => void
    dataChangeToggle?: boolean
  }) => {
    const { t } = useTranslation()
    const { widthApp } = CommonStore;

    const settings: Settings = {
      arrows: false,
      dots: false,
      infinite: false,
      variableWidth: true,
      className: "slider-wrapper",
    }

    const renderItem = (item: PostedPopularProduct, index: number) => {
      const widthBxMain = (widthApp - 16 * 2 - 8 - 16 - 8 * 3 / 4) / 5 * 4;
      return (
        <div
          className="bx_item_wrap"
          style={{ marginLeft: 16 }}
        >
          <div className="btn_bookmark" style={{ top: widthBxMain + 13 }}>
            {item.bookmarkYn === "Y" ? (
              <div
                onClick={() =>
                  void handleBookmark("N", item.productId, listKey)
                }
              >
                <BookmarkON />
              </div>
            ) : (
              <div
                onClick={() =>
                  void handleBookmark("Y", item.productId, listKey)
                }
              >
                <BookmarkOff />
              </div>
            )}
          </div>
          <div key={index} style={{ width: widthBxMain }} className="bx_main" onClick={() => goToProduct(item)}>
            <FastImageWithFallback
              className="bx_img"
              wrapperStyle={{
                position: "relative",
                paddingTop: "100%",
              }}
              style={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: 4,
                border: "1px solid #2222221A",
              }}
              source={{
                uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item.mainImagePath}`,
              }}
            />
            <div className="bx_info">
              <span className="info_pd_manufacturer">
                {item.brandName}
              </span>
              <span
                className="info_pd_name"
                // numberOfLines={2}
              >
                {item.productName}
              </span>
              {item.discountRate > 0 ? (
                <div className="bx_info_price">
                  <span className="txt_info_percent">
                    {item.discountRate}%
                  </span>
                  <span className="txt_info_price">
                    {getFormatprice(item.salesPrice)}
                    <span className="txt_price_unit">
                      {t("screen.mall.label.won")}
                    </span>
                  </span>
                  <span className="txt_info_before_price">
                    {getFormatprice(item.consumerPrice)}
                    {t("screen.mall.label.won")}
                  </span>
                </div>
              ) : (
                <div className="bx_info_price">
                  <span className="txt_info_price">
                    {getFormatprice(item.salesPrice)}
                    <span className="txt_price_unit">
                      {t("screen.mall.label.won")}
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="bx_review">
            <div className="bx_review_photo" style={{ height: widthBxMain }}>
              {item.postList.map((item2, index) => {
                return (
                  <div
                    key={index}
                    style={{ width: (widthBxMain - 8 * 3) / 4 }}
                    onClick={() =>
                      goToFeedScreen(
                        index,
                        item.productId,
                        item.lifecareProductId,
                        item2
                      )
                    }
                  >
                    <FastImageWithFallback
                      className="photo_wrap"
                      wrapperStyle={{
                        position: "relative",
                        paddingTop: "100%",
                      }}
                      style={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 4,
                        border: "1px solid #2222221A",
                      }}
                      source={{
                        uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item2.thumbnailFilePath}`,
                      }}
                    />
                  </div>
                );
              })}

              <div
                className="photo_num"
                onClick={() =>
                  goToFeedScreen(0, item.productId, item.lifecareProductId)
                }
              >
                <span className="txt_photo_num">
                  {t("screen.mall.button.reviewMore")}
                </span>
                <IconMoreArrow className="icon_photo_num" />
              </div>
            </div>
          </div>
        </div>
      );
    };

    return renderData && renderData.length > 0 ? (
      <div className='mall-popular-product-wrapper'>
        <div className="bx_title">
          <div style={{ position: 'relative' }}>
            <div className="bg_title"></div>
            <span className="txt_title">
              {t('screen.mall.title.popularProduct')}
            </span>
          </div>
        </div>
        <Slider {...settings}>
          {renderData.map((item, index) => renderItem(item, index))}
        </Slider>
        {/* <Virtuoso
          // horizontalDirection
          className={classes.flat_box}
          data={renderData}
          key={listKey}
          // extraData={dataChangeToggle}
          computeItemKey={(index, item) => `${listKey}_${index}`}
          itemContent={(index, item) => (
            <div
              className={classes.bx_item_wrap}
              style={index === 0 ? { marginLeft: 16 } : {}}
            >
              <div className={classes.btn_bookmark}>
                {item.bookmarkYn === 'Y' ? (
                  <div
                    onClick={() =>
                      void handleBookmark('N', item.productId, listKey)
                    }
                  >
                    <BookmarkON />
                  </div>
                ) : (
                  <div
                    onClick={() =>
                      void handleBookmark('Y', item.productId, listKey)
                    }
                  >
                    <BookmarkOff />
                  </div>
                )}
              </div>
              <div key={index} onClick={() => goToProduct(item)}>
                <FastImageWithFallback
                  className={classes.bx_img}
                  source={{
                    uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item.mainImagePath}`,
                  }}
                />
                <div className={classes.bx_info}>
                  <span className={classes.info_pd_manufacturer}>
                    {item.brandName}
                  </span>
                  <span
                    className={classes.info_pd_name}
                    // numberOfLines={2}
                  >
                    {item.productName}
                  </span>
                  {item.discountRate > 0 ? (
                    <div className={classes.bx_info_price}>
                      <span className={classes.txt_info_percent}>
                        {item.discountRate}%
                      </span>
                      <span className={classes.txt_info_price}>
                        {getFormatprice(item.salesPrice)}
                        <span className={classes.txt_price_unit}>
                          {t('screen.mall.label.won')}
                        </span>
                      </span>
                      <span className={classes.txt_info_before_price}>
                        {getFormatprice(item.consumerPrice)}
                        {t('screen.mall.label.won')}
                      </span>
                    </div>
                  ) : (
                    <div className={classes.bx_info_price}>
                      <span className={classes.txt_info_price}>
                        {getFormatprice(item.salesPrice)}
                        <span className={classes.txt_price_unit}>
                          {t('screen.mall.label.won')}
                        </span>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.bx_review}>
                <div className={classes.bx_review_photo}>
                  {item.postList.map((item2, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() =>
                          goToFeedScreen(
                            index,
                            item.productId,
                            item.lifecareProductId,
                            item2
                          )
                        }
                      >
                        <FastImageWithFallback
                          className={classes.photo_wrap}
                          source={{
                            uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item2.thumbnailFilePath}`,
                          }}
                        />
                      </div>
                    )
                  })}

                  <div
                    className={clsx(classes.photo_wrap, classes.photo_num)}
                    onClick={() =>
                      goToFeedScreen(0, item.productId, item.lifecareProductId)
                    }
                  >
                    <span className={classes.txt_photo_num}>
                      {t('screen.mall.button.reviewMore')}
                    </span>
                    <IconMoreArrow className={classes.icon_photo_num} />
                  </div>
                </div>
              </div>
            </div>
          )}
        /> */}
      </div>
    ) : (
      <></>
    )
  }
)
export default MallMainPopularProduct
