import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Coupon } from "../../api/coupon/model";
import CouponDetailNew from "./presenter/CouponDetailNew";
import TitleHeader from "../../components/common/TitleHeader";
import { useTranslation } from "react-i18next";
import { Color } from "../../assets/styles/variable";
import { useLocation } from "react-router-dom";

const CouponDetailScreen = observer((data: any) => {
  const location = useLocation();
  const params = location.state;
  const couponInfo: Coupon = params?.couponInfo;

  const { t } = useTranslation();

  return (
    <div
      style={{ flex: 1, backgroundColor: Color.WHITE }}
      aria-label={'Coupon detail screen'}
    >
      <TitleHeader title={t("screen.couponDetail.title")} isHome={false} />
      <CouponDetailNew couponInfo={couponInfo}/>
    </div>
  );
});

export default CouponDetailScreen;
