import { observer } from "mobx-react";
import LoginAttractMain from "./presenter/LoginAttractMain";
import LoginAttractMainV2 from "./presenter/LoginAttractMainV2";
import { getStringRemoteConfig } from "../../utils/remoteConfig";
import { useLocation } from "react-router-dom";

const LoginAttractScreen = observer(() => {
  const location = useLocation();
  const { hideLookingAroundBtn } = location.state || {};
  // const joinStyle = getStringRemoteConfig("joinStyle");
  const joinStyle = "A";

  return (
    <>
      {joinStyle.toUpperCase() === "A".toUpperCase() ? (
        <LoginAttractMain hideLookingAroundBtn={hideLookingAroundBtn || true} />
      ) : (
        <LoginAttractMainV2
          hideLookingAroundBtn={hideLookingAroundBtn || true}
        />
      )}
    </>
  );
});

export default LoginAttractScreen;
