import { openDB } from "idb";

const dbPromise = openDB("220", 1, {
  upgrade(db) {
    db.createObjectStore("store");
  },
});

export async function getIndexedDB(key: IDBKeyRange | IDBValidKey) {
  return (await dbPromise).get("store", key);
}
export async function setIndexedDB(key: IDBKeyRange | IDBValidKey, val: any) {
  return (await dbPromise).put("store", val, key);
}
export async function delIndexedDB(key: IDBKeyRange | IDBValidKey) {
  return (await dbPromise).delete("store", key);
}
export async function clearIndexedDB() {
  return (await dbPromise).clear("store");
}
export async function getAllKeys() {
  return (await dbPromise).getAllKeys("store");
}
