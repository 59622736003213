import Konva from 'konva'
import { useRef, useEffect, memo } from 'react'
import { Stage, Layer, Image } from 'react-konva'
import { FilterItem } from './filters'
import './FilterItemComponent.scss'

const FilterItemComponent = ({
  image,
  filterItem,
}: {
  image: HTMLImageElement | undefined
  filterItem: FilterItem
}) => {
  const SIZE_ITEM = 100
  const imageRef = useRef<Konva.Image>(null)

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.cache()
      imageRef.current.filters([filterItem.filter])
      imageRef.current.getLayer()?.batchDraw()
    }
  }, [image, filterItem])

  return (
    <div className="filter-item-wrapper">
      <Stage className="stage" width={SIZE_ITEM} height={SIZE_ITEM}>
        <Layer>
          <Image
            image={image}
            ref={imageRef}
            cornerRadius={6}
            width={SIZE_ITEM}
            height={SIZE_ITEM}
          />
        </Layer>
      </Stage>
    </div>
  )
}

export default memo(FilterItemComponent)
