import { observer } from 'mobx-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Color } from '../../assets/styles/variable'
import { CustomActivityIndicator, TitleHeader } from '../../components/common'
import { ecommerceSignup, getEcommerceGenerateKey } from '../../api/member/api'
import {
  goToMallmain,
  LIFECARE_AUTH_GATE_URL_PREFIX,
  LIFECARE_CART_URL_PREFIX,
  LIFECARE_MAIN_URL_PREFIX,
  LIFECARE_ORDER_COMPLETE_DETAIL_URL_REGEXP,
  LIFECARE_ORDER_COMPLETE_URL_PREFIX,
  LIFECARE_ORDER_PURCHASE_URL_PREFIX,
  LIFECARE_PRODUCT_URL_PREFIX,
  LIFECARE_SEARCH_URL_PREFIX,
  LIFECARE_SESSION_EXPIRED_URL_PREFIX,
} from '../../utils/mall'
import { Platform, PlatformTypes } from '../../native/platform'
import { useLocation, useNavigate } from 'react-router-dom'
import { openInAppBrowser } from '../../utils/common'
import { useEffectOnlyOnce } from '../../hooks/UseEffectOnlyOnce'
import { HeaderItem } from '../../constants/Header'
import {
  getBooleanRemoteConfig,
  getNumberRemoteConfig,
} from '../../utils/remoteConfig'
import {
  eCommerceTermsAgree,
  getEcommerceNeedAgreeTerms,
} from '../../api/mall/api'
import AuthStore from '../../store/AuthStore'
import { TermsResult } from '../../api/terms/model'
import MainStore from '../../store/MainStore'
import NotificationModal from '../../components/modals/NotificationModal'
import { goBack } from '../../hooks/navigate/NavigateFunction'
import LifecareErrorModal from './LifecareErrorModal'
import LifecareMaintenanceModal from './LifecareMaintenanceModal'
import TermsAgreeBottomSheet from '../../components/modals/TermsAgreeBottomSheet'
import { track } from '../../hooks/tracker/TrackFunction'
import CommonStore from '../../store/CommonStore'
import TermsOfUseModal, {
  TermsDetailProps,
} from '../../components/modals/TermsOfUseModal'
import MainTabNavigator from '../../components/common/MainTabNavigator'
import { useTranslation } from 'react-i18next'
import TabStore from '../../store/TabStore'
import { ActHistoryData, onShareLifecare, ShareInfo } from '../../utils/share'
import { ShareScreenCode } from '../../constants/ShareScreen.enum'
import { ActHistoryTypeCode, ActPageCode } from '../../constants/ActHistory'
import LifecareStore from '../../store/LifecareStore'
import LifecareRealNameModal from './LifecareRealNameModal'
import { safeParseJSON } from '../../utils/convert'

const LifecareScreen = observer(() => {
  const domainUrl = process.env.REACT_APP_ECOMMERCE_DOMAIN_URL
  const params = useLocation().state || {
    mode: 'redirect',
    redirectInfo: { url: '' },
  }
  const { mode, redirectInfo, popupInfo } = params
  const [ecommerceKey, setEcommerceKey] = useState<string>()
  const [url, setUrl] = useState<string>()
  let currentUrl: string = ''
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [iframeCanGoBack, setIframeCanGoBack] = useState<boolean>(false) // TODO: webviewinterface 에서 뭔가 주면 계속 변경해줘야 함

  const defaultTermsDetailProps = useMemo(
    () => ({
      show: false,
      termsId: '',
      termsTypeCode: '',
      onClose: () => {},
    }),
    []
  )

  const timeoutInterval: number = getNumberRemoteConfig('lifecare_timeout')
  const isMaintenance: boolean = getBooleanRemoteConfig('lifecare_maintenance')

  const [isPopupShowing, setPopupShowing] = useState(false)
  const [isShowTimeoutModal, showTimeoutModal] = useState(false)
  const [isShowSessionExpiredModal, showSessionExpiredModal] = useState(false)
  const [isShowLifecareErrorModal, showLifecareErrorModal] = useState(false)
  const [isShowMaintenanceModal, showMaintenanceModal] = useState(isMaintenance)
  const [isShowRealNameModal, showRealNameModal] = useState(false)
  const [isShowHeader, setShowHeader] = useState(true)
  const [isShowTermsAgreeModal, setShowTermsAgreeModal] = useState(false)
  const [terms, setTerms] = useState<TermsResult[]>()
  const [termsDetailProps, setTermsDetailProps] = useState<TermsDetailProps>(
    defaultTermsDetailProps
  )

  const [isKeyLoading, setKeyLoading] = useState<boolean>(false)
  const [showRealNamePath, setShowRealNamePath] = useState<string>()

  const redirectTo = useCallback(
    (redirectUrl: string, path: string | undefined = undefined) => {
      void getEcommerceNeedAgreeTerms()
        .then(async (result) => {
          if (result.successOrNot === 'Y') {
            if ((result.data?.length || 0) > 0) {
              setTerms(result.data)
              return Promise.reject({
                code: 'ECOMMERCE_NEED_TERMS',
              })
            } else {
              const sessionUser = AuthStore.sessionUser
              if (
                !sessionUser?.ecommerceMemberNo ||
                !sessionUser?.ecommerceUpdateYn ||
                sessionUser?.ecommerceUpdateYn === 'N'
              ) {
                return ecommerceSignup()
              } else {
                return Promise.resolve()
              }
            }
          } else {
            return Promise.reject({
              code: result.statusCode,
              message: result.errorMessage,
            })
          }
        })
        .then((result) => {
          if (result?.successOrNot) {
            if (result.successOrNot === 'Y') {
              const sessionUser = AuthStore.sessionUser
              if (sessionUser) {
                const { ecommerceMemberNo } = result.data
                AuthStore.setSessionUser({
                  ...sessionUser,
                  ecommerceMemberNo,
                  ecommerceUpdateYn: 'Y',
                })
              }
            } else {
              return Promise.reject({
                code: result.statusCode,
                message: result.errorMessage,
              })
            }
          }
          return getEcommerceGenerateKey()
        })
        .then((res) => {
          const key = encodeURIComponent(res)
          if (!path) {
            path = LIFECARE_AUTH_GATE_URL_PREFIX
          }
          const media = Platform.OS
          const url = `${domainUrl}${path}?key=${key}&media=${media}&redirectUrl=${encodeURIComponent(
            redirectUrl
          )}`
          setUrl(url)
        })
        .catch((reason) => {
          if (reason.code === 'ECOMMERCE_NEED_TERMS') {
            setShowTermsAgreeModal(true)
          } else {
            showLifecareErrorModal(true)
          }
        })
        .finally(() => {
          void MainStore.trackAllUserProperties()
          setKeyLoading(false)
        })
    },
    [domainUrl]
  )

  useEffectOnlyOnce(() => {
    if (isMaintenance) {
      setKeyLoading(false)
      showMaintenanceModal(true)
    } else {
      if (mode === 'popup') {
        moveTo(popupInfo.url)
      } else {
        // } else if (mode === 'redirect') {
        const info = redirectInfo
        redirectTo(info.url, info.path)
      }
    }
  })

  const webViewInterfaceFunction = useCallback((event: MessageEvent<any>) => {
    console.log(`webViewInterface : ${JSON.stringify(event.data)}`)

    try {
      const {
        type,
        payload,
      }: {
        type?: string
        pageType?: string
        payload?: string | any | undefined
      } = safeParseJSON(event.data)

      switch (type) {
        case 'INVOKE_BROWSER': // 내부/외부 링크 호출 시
          invokeBrowser(payload)
          break
        case 'CLICK_SHARE': // 공유하기 클릭 시
          clickShare(payload)
          break
        case 'NAVIGATING_TO': // 커머스 화면 이동 시
          navigatingTo(payload as string)
          break
        case 'SET_BACK': // 화면 뒤로갈때
          goBack()
          break
        case 'SHOW_SORRY': // 뭔가 오류가 발생했을 때
          showSorry()
          break
        case 'SHOW_POPUP': // 브라우저 팝업 호출
          showPopup(payload)
          break
        case 'CLOSE_POPUP': // 팝업으로 띄운 경우 화면 닫기
          closePopup(payload)
          break
        case 'SET_PREFERENCE': // 설정 저장
          setPreference(payload)
          break
        case 'GET_PREFERENCE': // 설정 가져오기
          getPreference(payload)
          break
        case 'SHOW_REAL_NAME': // 실명인증
          showRealName(payload)
          break
        default:
          break
      }
    } catch (error) {}
  }, [])

  function navigatingTo(payload: string) {
    currentUrl = payload
    updateVisiblity(`${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}${payload}`)
    // TODO: 뒤로가기 가능한 상태인지 알수있는 방법이 필요함
    if (iframeCanGoBack && payload === 'about:blank') {
      goBack()
    } else if (payload.includes(LIFECARE_SESSION_EXPIRED_URL_PREFIX)) {
      // 세션 만료 처리
      // clearTimeout(timeoutId.current)
      setKeyLoading(false)
      showSessionExpiredModal(true)
    }
  }

  function updateVisiblity(url: string) {
    // 이동하는 페이지에 따라 탭바 표시설정 변경
    if (url.includes(LIFECARE_PRODUCT_URL_PREFIX)) {
      // 제품상세
      TabStore.setTabBarDisplay('none')
    } else if (url.includes(LIFECARE_CART_URL_PREFIX)) {
      // 장바구니
      TabStore.setTabBarDisplay('none')
    } else if (url.includes(LIFECARE_ORDER_PURCHASE_URL_PREFIX)) {
      // 주문 결제
      TabStore.setTabBarDisplay('none')
    } else {
      TabStore.setTabBarDisplay(undefined)
    }

    // 이동하는 페이지에 따라 헤더 표시설정 변경
    if (url.includes(LIFECARE_SEARCH_URL_PREFIX)) {
      // 검색
      setShowHeader(false)
    } else {
      setShowHeader(true)
    }

    // TODO: 이동하는 페이지에 따라 safeArea 하단 영역 배경색 변경
    // if (url.includes(LIFECARE_PRODUCT_URL_PREFIX)) {
    //   // 제품상세
    //   setBackgroundColor("#f5f5f5");
    //   setBottomInset(inset.bottom);
    // } else {
    //   setBackgroundColor("#ffffff");
    //   setBottomInset(0);
    // }
  }

  function invokeBrowser(payload: any) {
    const { inner, url }: { inner: boolean; url: string } = payload.options
    void openInAppBrowser(url)
  }

  function clickShare(payload: any) {
    // TODO: iframe 에서 보안상의 이유로 url을 가져올수 없음
    const { image }: { image: string } = payload
    const { text, title }: { text: string; title: string } = payload
    const targetUrl =
      currentUrl?.replace(
        process.env.REACT_APP_ECOMMERCE_DOMAIN_URL || '',
        ''
      ) || '/'
    const shareInfo: ShareInfo = {
      title: title,
      imageUrl: image,
      screen: ShareScreenCode.LIFECARE_SCREEN,
      target: targetUrl,
    }
    const actHistoryData: ActHistoryData = {
      actHistoryTypeCode: ActHistoryTypeCode.LIFECARE_SHARE,
      actPageCode: ActPageCode.LIFECARE,
      feedId: targetUrl,
      likeCount: '0',
      commentCount: '0',
    }
    void onShareLifecare(shareInfo, actHistoryData)
  }

  function showPopup(payload: any) {
    const { options, callback } = payload
    const {
      url,
      title,
    }: { url: string; title: string; is_show_title_bar: boolean } = options
    const { name, param }: { name: string; param: any } = callback

    LifecareStore.popupCallback = (result: any) => {
      setPopupShowing(false)
      if (result.result === 'cancel') {
        // close(result)
        goBack()
      } else {
        const letParams = result.options
        iframeRef.current?.contentWindow?.postMessage({ name, letParams })
      }
    }
    setPopupShowing(true)
    navigate('/lifecare', {
      state: {
        mode: 'popup',
        popupInfo: {
          title,
          url,
        },
      },
    })
  }

  function closePopup(payload: any) {
    // close(payload);
    window.close()
  }

  function showSorry() {
    // clearTimeout(timeoutId.current);
    showLifecareErrorModal(true)
  }

  function setPreference(payload: any) {
    const { options } = payload
    const { key, val }: { key: string; val: string } = options
    const prefsKey = `prefs_${key}`
    window.localStorage.setItem(prefsKey, val)
  }

  function getPreference(payload: any) {
    const { options, callback } = payload
    const { key }: { key: string } = options
    const { name, param }: { name: string; param: any } = callback
    const prefsKey = `prefs_${key}`

    const value = window.localStorage.getItem(key)
    iframeRef.current?.contentWindow?.postMessage({
      name,
      params: { prefsKey, value },
    })
  }

  function showRealName(payload: any) {
    const { path, message }: { path: string; message: string } = payload
    setShowRealNamePath(path)
    showRealNameModal(true)
  }

  function moveToCart() {
    if (isShowError()) {
      redirectTo(LIFECARE_CART_URL_PREFIX)
      clearErrorModal()
    } else {
      setUrl(`${domainUrl}${LIFECARE_CART_URL_PREFIX}`)
    }
  }

  function moveToSearch() {
    if (isShowError()) {
      redirectTo(LIFECARE_SEARCH_URL_PREFIX)
      clearErrorModal()
    } else {
      setUrl(`${domainUrl}${LIFECARE_SEARCH_URL_PREFIX}`)
    }
  }

  /**
   * 에러 목록
   * 유형0 : 타임아웃
   * 유형1 : 라이프케어 오류
   * 유형2 : 라이프케어 점검중
   * 유형3 : 세션만료
   */
  const clearErrorModal = useCallback(() => {
    showTimeoutModal(false)
    showLifecareErrorModal(false)
    showMaintenanceModal(false)
    showSessionExpiredModal(false)
  }, [
    showTimeoutModal,
    showLifecareErrorModal,
    showMaintenanceModal,
    showSessionExpiredModal,
  ])

  const isShowError = useCallback(() => {
    return (
      isShowTimeoutModal ||
      isShowSessionExpiredModal ||
      isShowLifecareErrorModal ||
      isShowMaintenanceModal
    )
  }, [
    isShowTimeoutModal,
    isShowSessionExpiredModal,
    isShowLifecareErrorModal,
    isShowMaintenanceModal,
  ])

  const close = useCallback(
    (result: any = { result: 'cancel' }) => {
      goBack()
      if (mode === 'popup') {
        setTimeout(() => {
          LifecareStore.popupCallback?.(result)
        }, 500)
      }
    },
    [mode]
  )

  const moveToMallmain = useCallback(() => {
    close()
    goToMallmain()
  }, [])

  const moveTo = (moveUrl: string) => {
    // setWebViewSource({ uri: moveUrl });
    setUrl(moveUrl)
  }

  /* eslint-disable */
  const moveToLifecareHome = useCallback(() => {
    if (isShowError()) {
      redirectTo('/')
      clearErrorModal()
    } else {
      setUrl(`${domainUrl}${LIFECARE_MAIN_URL_PREFIX}`)
    }
  }, [isShowError, clearErrorModal])
  /* eslint-enable */

  const onClickBack = useCallback(() => {
    if (isShowError()) {
      close()
    } else if (
      currentUrl?.includes(LIFECARE_ORDER_COMPLETE_URL_PREFIX) ||
      currentUrl?.match(LIFECARE_ORDER_COMPLETE_DETAIL_URL_REGEXP)
    ) {
      // 주문완료 화면에서 뒤로가기시 라이프케어 홈으로
      // 주문내역 보기 화면에서 뒤로가기시 라이프케어 홈으로
      moveToLifecareHome()
    } else if (
      currentUrl === `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/` ||
      currentUrl ===
        `${process.env.REACT_APP_ECOMMERCE_DOMAIN_URL}/?media=${Platform.OS}`
    ) {
      // 라이프케어 홈 화면에서 뒤로가기시 220몰으로
      moveToMallmain()
    } else {
      // TODO: iframe에서 뒤로가기 가능한 상태인지 확인하는 방법 필요함
      if (iframeCanGoBack) {
        goBack()
        // setProcessingBackNavigation(true)
        // webViewRef.current?.goBack()
      } else {
        close()
      }
    }

    return true
  }, [
    close,
    currentUrl,
    iframeCanGoBack,
    isShowError,
    moveToLifecareHome,
    moveToMallmain,
  ])

  useEffect(() => {
    window.addEventListener('message', webViewInterfaceFunction)
    return () => {
      window.removeEventListener('message', webViewInterfaceFunction)
    }
  }, [webViewInterfaceFunction])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flex: 1,
          height: '100vh',
          flexDirection: 'column',
          backgroundColor: Color.WHITE,
        }}
      >
        {isShowHeader && (
          <TitleHeader
            title={
              params?.popupInfo?.title && params?.popupInfo?.title.length > 0
                ? params?.popupInfo?.title
                : '220몰'
            }
            isBack
            isHome={mode === 'redirect'}
            hasSearch={mode === 'redirect'}
            rightItem={mode === 'redirect' ? HeaderItem.CART : HeaderItem.NONE}
            onClickHome={moveToMallmain}
            onClickCart={moveToCart}
            onClickSearch={moveToSearch}
            onClickBack={onClickBack}
          />
        )}
        <iframe
          title="lifecare webview"
          ref={iframeRef}
          style={{ display: 'flex', flex: 1, height: '100%', border: 'none' }}
          src={url}
          onError={(error) => {
            setKeyLoading(false)
            showLifecareErrorModal(true)
          }}
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>
      <MainTabNavigator />

      <LifecareMaintenanceModal
        isVisible={isShowMaintenanceModal}
        title="안내"
        onRequestClose={() => {
          showMaintenanceModal(false)
          goBack()
        }}
      />

      {(isShowLifecareErrorModal || isShowTimeoutModal) && (
        <LifecareErrorModal
          isVisible={isShowLifecareErrorModal || isShowTimeoutModal}
          onRequestClose={() => {
            goBack()
          }}
          contentTitle={t('screen.lifecare.errorModalContentTitle')}
          title={t('screen.lifecare.errorModalTitle')}
          content={t('screen.lifecare.errorModalContent')}
        />
      )}

      {isShowSessionExpiredModal && (
        <LifecareErrorModal
          isVisible={isShowSessionExpiredModal}
          onRequestClose={() => {
            goBack()
          }}
          contentTitle={t('screen.lifecare.sessionModalContentTitle')}
          title={t('screen.lifecare.sessionModalTitle')}
          content={t('screen.lifecare.sessionModalContent')}
        />
      )}

      <TermsOfUseModal termsDetailProps={termsDetailProps} />

      <TermsAgreeBottomSheet
        open={isShowTermsAgreeModal}
        contents={t('screen.lifecare.termsModalContent1')}
        contents2={t('screen.lifecare.termsModalContent2')}
        terms={terms}
        onTermsClick={(term) => {
          setShowTermsAgreeModal(false)
          const termsDetailProps = {
            show: true,
            termsId: term.termsId,
            termsTypeCode: term.termsTypeCode,
            onClose: () => {
              setTermsDetailProps({
                show: false,
                termsId: '',
                termsTypeCode: '',
                onClose: () => {},
              })
              setShowTermsAgreeModal(true)
            },
          }
          setTermsDetailProps(termsDetailProps)
        }}
        onClose={() => {
          setShowTermsAgreeModal(false)
          goBack()
        }}
        onConfirm={(termsCheckState) => {
          const memberUuid = AuthStore.sessionUser?.memberUuid || ''
          setKeyLoading(true)
          void eCommerceTermsAgree(memberUuid, termsCheckState || [])
            .then((result) => {
              if (result.successOrNot === 'Y') {
                track('click_mall_allow_agreement')

                redirectTo(redirectInfo.url, redirectInfo.path)

                CommonStore.setToastOption({
                  show: true,
                  message1: t('screen.lifecare.termsCompleteMessage'),
                })
              } else {
                showSorry()
              }
            })
            .finally(() => {
              setShowTermsAgreeModal(false)
              setKeyLoading(false)
            })
        }}
      />

      {isShowRealNameModal && (
        <LifecareRealNameModal
          isVisible={isShowRealNameModal}
          onRequestClose={() => {
            showRealNameModal(false)
            // goBack()
            onClickBack()
          }}
          onClick={() => {
            showRealNameModal(false)
            redirectTo(redirectInfo?.url as string, showRealNamePath)
          }}
        />
      )}

      {isKeyLoading && <CustomActivityIndicator isDarkSpinner={true} />}
    </div>
  )
})
export default LifecareScreen
