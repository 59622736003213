import "./QnaWriteButtons.scss";

import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import CommonStore from "../../../store/CommonStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const QnaWriteButtons = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="write_btns">
      <div style={{ flex: 1 }}>
        <TouchableWithAuthCheck
          className="write_btn"
          onPress={() => {
            // goTo("/qna/write");
            CommonStore.setShowDownloadAppDrive(true);
          }}
          aria-label={"Qna Main move write qna"}
        >
          <p className="write_btn_text">{t(`screen.qna.button.write_qna2`)}</p>
        </TouchableWithAuthCheck>
      </div>
      <div style={{ width: 8 }} />
      <div style={{ flex: 1 }}>
        <TouchableWithAuthCheck
          className="write_btn"
          onPress={() => {
            CommonStore.setShowDownloadAppDrive(true);
          }}
          aria-label={"Qna Main move write ab"}
        >
          <p className="write_btn_text">{t(`screen.qna.button.write_ab2`)}</p>
        </TouchableWithAuthCheck>
      </div>
      <div style={{ width: 8 }} />
      <div style={{ flex: 1 }}>
        <TouchableWithAuthCheck
          className="write_btn"
          onPress={() => {
            console.log("1:1문의");
          }}
          aria-label={"1:1 Main move write 1:1"}
        >
          <p className="write_btn_text">{t("screen.qna.button.write_qa2")}</p>
        </TouchableWithAuthCheck>
      </div>
    </div>
  );
});

export default QnaWriteButtons;
