import {
  font_666666,
  font_fff,
  font_6c2773,
  font_222,
  font_d8b8d8,
  font_999,
  font_a466a4,
  font_f0dff2,
  font_6a0,
  font_c60053,
  font_000,
} from "./colors";

export const font_size_10 = {
  fontSize: 10,
};
export const font_size_11 = {
  fontSize: 11,
};
export const font_size_12 = {
  fontSize: 12,
};
export const font_size_13 = {
  fontSize: 13,
};
export const font_size_14 = {
  fontSize: 14,
};
export const font_size_16 = {
  fontSize: 16,
};

export const font_size_16_responsive = {
  // fontSize: responsiveFontSize(16),
  fontSize: 16,
};

export const font_size_17 = {
  fontSize: 17,
};
export const font_size_18 = {
  fontSize: 18,
};

export const font_size_18_responsive = {
  fontSize: 18,
};

export const font_size_20 = {
  fontSize: 20,
};
export const font_size_22 = {
  fontSize: 22,
};
export const font_size_24 = {
  fontSize: 24,
};
export const font_size_25 = {
  fontSize: 25,
};
export const font_size_26 = {
  fontSize: 26,
};
export const font_size_28 = {
  fontSize: 28,
};
export const font_size_30 = {
  fontSize: 30,
};
export const font_size_32 = {
  fontSize: 32,
};
export const font_size_52 = {
  fontSize: 52,
};

export const font_family_LGSmart_H2_0_R = {
  fontFamily: "LGSmart_H2.0-R",
};

export const font_family_LGSmart_H2_0_SB = {
  fontFamily: "LGSmart_H2.0-SB",
};

export const font_letter_spacing_024 = {
  letterSpacing: -0.24,
};

export const font_letter_spacing_028 = {
  letterSpacing: -0.28,
};

export const font_letter_spacing_032 = {
  letterSpacing: -0.32,
};

export const font_letter_spacing_036 = {
  letterSpacing: -0.36,
};

export const font_letter_spacing_04 = {
  letterSpacing: -0.4,
};

export const font_letter_spacing_042 = {
  letterSpacing: -0.42,
};

export const font_letter_spacing_044 = {
  letterSpacing: -0.44,
};

export const font_letter_spacing_048 = {
  letterSpacing: -0.48,
};

export const font_letter_spacing_05 = {
  letterSpacing: -0.5,
};

export const font_letter_spacing_052 = {
  letterSpacing: -0.52,
};

export const font_letter_spacing_056 = {
  letterSpacing: -0.56,
};

export const font_letter_spacing_064 = {
  letterSpacing: -0.64,
};

export const font_letter_spacing_068 = {
  letterSpacing: -0.68,
};

export const font_letter_spacing_072 = {
  letterSpacing: -0.72,
};

export const font_letter_spacing_08 = {
  letterSpacing: -0.8,
};

export const font_letter_spacing_088 = {
  letterSpacing: -0.88,
};

export const font_letter_spacing_096 = {
  letterSpacing: -0.96,
};

export const font_letter_spacing_104 = {
  letterSpacing: -1.04,
};

export const font_letter_spacing_144 = {
  letterSpacing: -1.44,
};

export const font_letter_spacing_112 = {
  letterSpacing: -1.12,
};

export const font_letter_spacing_16 = {
  letterSpacing: -1.6,
};

export const font_letter_spacing_224 = {
  letterSpacing: -2.24,
};

export const font_letter_spacing_256 = {
  letterSpacing: -2.56,
};

export const font_letter_spacing_128 = {
  letterSpacing: -1.28,
};

export const font_weight_normal = {
  fontWeight: "normal",
};

export const font_weight_bold = {
  fontWeight: "bold",
};

export const font_weight_400 = {
  fontWeight: "400",
};

export const font_weight_500 = {
  fontWeight: "500",
};

export const font_weight_600 = {
  fontWeight: "600",
};

export const font_weight_700 = {
  fontWeight: "700",
};

export const font_weight_900 = {
  fontWeight: "900",
};

export const line_height_11 = {
  lineHeight: 11,
};

export const line_height_14 = {
  lineHeight: 14,
};

export const line_height_22 = {
  lineHeight: 22,
};

export const line_height_24 = {
  lineHeight: 24,
};

export const line_height_28 = {
  lineHeight: 28,
};

export const line_height_32 = {
  lineHeight: 32,
};

export const line_height_42 = {
  lineHeight: 42,
};

export const text_underline = {
  textDecorationLine: "underline",
};

export const button_text_0 = {
  ...font_weight_bold,
  ...font_fff,
  ...font_size_18,
};

export const button_text_1 = {
  ...font_size_16,
  ...font_letter_spacing_064,
  ...font_6c2773,
};

export const button_text_2 = {
  ...font_size_14,
  ...font_letter_spacing_064,
  ...font_6c2773,
};

export const button_text_3 = {
  ...font_size_16,
  ...font_letter_spacing_064,
  ...font_weight_bold,
  ...font_fff,
};

export const button_text_6 = {
  ...font_size_16,
  ...font_letter_spacing_064,
  ...font_weight_bold,
  ...font_222,
};

export const button_text_4 = {
  ...font_size_14,
  ...font_letter_spacing_056,
  ...font_222,
};

export const button_text_5 = {
  ...font_size_14,
  ...font_letter_spacing_056,
  ...font_weight_bold,
  ...font_999,
};

export const button_text_7 = {
  ...font_size_16,
  ...font_letter_spacing_064,
  ...font_weight_bold,
};

export const button_text_8 = {
  ...font_size_14,
  ...font_letter_spacing_056,
  ...font_6c2773,
};

export const button_text_9 = {
  ...font_size_12,
  ...font_letter_spacing_048,
};

export const button_text_10 = {
  ...font_size_14,
  ...font_letter_spacing_056,
};

export const button_text_11 = {
  ...font_size_16,
  ...font_letter_spacing_056,
  ...font_weight_500,
};

export const icon_text_0 = {
  ...font_size_10,
  ...font_fff,
  ...font_weight_bold,
  ...line_height_11,
};

export const header_text_0 = {
  ...font_size_16,
  ...font_222,
  ...font_weight_bold,
  ...font_letter_spacing_064,
};

export const header_text_1 = {
  ...font_size_16,
  ...font_6c2773,
  ...font_weight_bold,
  ...font_letter_spacing_064,
};

export const header_text_2 = {
  ...font_size_16,
  ...font_fff,
  ...font_weight_bold,
  ...font_letter_spacing_064,
};

export const header_text_3 = {
  ...font_size_14,
  ...font_999,
  ...font_letter_spacing_056,
};

export const contents_text_0 = {
  ...font_size_14,
  ...font_666666,
  ...font_letter_spacing_056,
  ...font_weight_500,
};

export const contents_text_1 = {
  ...font_size_20,
  ...font_222,
  ...font_letter_spacing_08,
};

export const contents_text_2 = {
  ...font_size_20,
  ...font_222,
  ...font_letter_spacing_08,
  ...font_weight_bold,
};

export const contents_text_3 = {
  ...font_size_14,
  ...font_222,
  ...font_letter_spacing_056,
  ...font_weight_500,
};

export const contents_text_4 = {
  ...font_size_20,
  ...font_222,
  ...font_letter_spacing_08,
};

export const contents_text_5 = {
  ...font_size_12,
  ...font_222,
  ...font_letter_spacing_048,
  ...font_weight_500,
};

export const contents_text_5_1 = {
  ...font_size_11,
  ...font_222,
  ...font_letter_spacing_048,
};

export const contents_text_6 = {
  ...font_size_12,
  ...font_6c2773,
  ...font_letter_spacing_048,
  ...font_weight_bold,
};

export const contents_text_7 = {
  ...font_size_12,
  ...font_666666,
  ...font_letter_spacing_048,
  ...font_weight_bold,
};

export const contents_text_8 = {
  ...font_size_14,
  ...font_666666,
  ...font_letter_spacing_056,
};

export const contents_text_9 = {
  ...font_size_12,
  ...font_666666,
  ...font_letter_spacing_048,
};

export const contents_text_10 = {
  ...font_size_14,
  ...font_6c2773,
  ...font_letter_spacing_056,
  ...font_weight_bold,
};

export const contents_text_11 = {
  ...font_size_12,
  ...font_a466a4,
  ...font_letter_spacing_048,
};

export const contents_text_12 = {
  ...font_size_12,
  ...font_a466a4,
  ...font_letter_spacing_048,
};

export const contents_text_13 = {
  ...font_size_10,
  ...font_f0dff2,
  ...font_letter_spacing_04,
};

export const contents_text_14 = {
  ...font_size_20,
  ...font_222,
  ...font_letter_spacing_16,
  ...line_height_28,
};

export const contents_Text_15 = {
  ...font_size_32,
  ...font_222,
  ...font_letter_spacing_256,
  ...line_height_42,
};

export const contents_text_16 = {
  ...font_size_18,
  ...font_222,
  ...font_letter_spacing_144,
};

export const contents_text_17 = {
  ...font_size_18,
  ...font_6c2773,
  ...font_letter_spacing_144,
};

export const contents_text_18 = {
  ...font_size_24,
  ...font_222,
  ...font_letter_spacing_096,
};

export const contents_text_19 = {
  ...font_size_14,
  ...font_6a0,
  ...font_letter_spacing_056,
};

export const contents_text_20 = {
  ...font_size_12,
  ...font_c60053,
  ...font_letter_spacing_048,
};

export const contents_text_21 = {
  ...font_size_16,
  ...font_666666,
  ...font_letter_spacing_064,
  ...font_weight_500,
};

export const contents_text_18_072 = {
  ...font_size_18,
  ...font_000,
  ...font_letter_spacing_072,
};

export const contents_text_22 = {
  ...font_size_16,
  ...font_fff,
  ...font_letter_spacing_064,
};

export const contents_text_23 = {
  ...font_size_14,
  ...font_c60053,
  ...font_letter_spacing_056,
};

export const contents_text_24 = {
  ...font_size_24,
  ...font_999,
  ...font_letter_spacing_096,
};

export const contents_text_25 = {
  ...font_size_14,
  ...font_fff,
  ...font_letter_spacing_056,
};

export const contents_text_26 = {
  ...font_size_12,
  ...font_222,
  ...font_letter_spacing_048,
  ...font_weight_bold,
};

export const contents_text_27 = {
  ...font_size_12,
  ...font_666666,
  ...font_letter_spacing_048,
  ...font_weight_bold,
};

export const contents_text_28 = {
  ...font_size_16,
  ...font_letter_spacing_064,
  ...font_222,
  ...font_weight_500,
};

export const contents_text_29 = {
  ...font_size_28,
  ...font_letter_spacing_224,
  ...font_222,
};

export const contents_text_30 = {
  ...font_size_13,
  ...font_letter_spacing_052,
  ...font_222,
};

export const contents_text_31 = {
  ...font_size_14,
  ...font_letter_spacing_056,
};

export const contents_text_32 = {
  ...font_size_22,
  ...font_letter_spacing_088,
};

export const contents_text_33 = {
  ...font_size_18,
  ...font_weight_bold,
  ...font_letter_spacing_072,
};

export const contents_text_34 = {
  ...font_size_16,
  ...font_letter_spacing_064,
  ...font_222,
  ...font_weight_bold,
};

export const contents_text_35 = {
  ...font_size_12,
  ...font_222,
  ...font_letter_spacing_056,
};

export const contents_text_36 = {
  ...font_size_18,
  ...font_letter_spacing_036,
  ...font_weight_500,
};

export const contents_text_37 = {
  ...font_size_12,
  ...font_letter_spacing_036,
  ...font_weight_500,
};

export const contents_text_39 = {
  ...font_size_32,
  ...font_letter_spacing_128,
};

export const contents_text_41 = {
  ...font_size_14,
  ...font_letter_spacing_042,
};

export const contents_text_42 = {
  ...font_size_25,
  ...font_letter_spacing_05,
};

export const contents_text_43 = {
  ...font_size_14,
  ...font_letter_spacing_028,
};

export const contents_text_44 = {
  ...font_size_28,
  ...font_letter_spacing_056,
};

export const contents_text_45 = {
  ...font_size_26,
  ...font_letter_spacing_104,
};

export const contents_text_46 = {
  ...font_size_32,
};

export const contents_text_47 = {
  ...font_size_10,
  ...font_weight_bold,
  ...font_letter_spacing_04,
};

export const contents_text_48 = {
  ...font_size_11,
  ...font_weight_500,
  ...font_letter_spacing_044,
};

export const contents_text_49 = {
  ...font_size_52,
  ...font_weight_bold,
  ...font_letter_spacing_04,
};

export const contents_text_50 = {
  ...font_size_30,
  ...font_weight_bold,
  ...font_letter_spacing_04,
};

export const contents_text_51 = {
  ...font_size_16,
  ...font_weight_500,
  ...font_letter_spacing_032,
};

export const contents_text_53 = {
  ...font_size_22,
  ...font_letter_spacing_044,
};

export const footer_tab_text_0 = {
  ...font_size_10,
  ...font_letter_spacing_04,
  ...font_d8b8d8,
};

export const action_text_0 = {
  ...font_size_12,
  ...font_6c2773,
  ...font_letter_spacing_048,
};

export const action_text_1 = {
  ...font_size_17,
  ...font_222,
  ...font_letter_spacing_068,
};

export const modal_text_0 = {
  ...font_size_18,
  ...font_222,
  ...font_weight_bold,
  ...font_letter_spacing_072,
};

export const btn_text_0 = {
  ...font_size_18,
  ...font_weight_bold,
  ...font_letter_spacing_072,
};

export const btn_text_1 = {
  ...font_size_14,
  ...font_letter_spacing_056,
};

export const btn_text_2 = {
  ...font_size_12,
  ...font_weight_bold,
  ...font_letter_spacing_048,
};

export const btn_text_3 = {
  ...font_size_14,
  ...font_letter_spacing_028,
};

export const btn_text_4 = {
  ...font_size_20,
  ...font_letter_spacing_08,
  ...font_weight_500,
};

export const contents_text_38 = {
  ...font_size_16,
  ...font_letter_spacing_064,
  ...font_weight_500,
};

export const contents_text_40 = {
  ...font_size_20,
  ...font_letter_spacing_08,
};

export const contents_text_52 = {
  ...font_size_12,
  ...font_letter_spacing_024,
};

export const contents_text_55 = {
  ...font_size_28,
  ...font_letter_spacing_112,
  ...font_weight_bold,
};

export const contents_text_56 = {
  ...font_size_14,
  ...font_letter_spacing_064,
  ...font_222,
  ...font_weight_bold,
};
