import { useImperativeHandle, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import PreferenceStore from "../../../store/PreferenceStore";
import { validateNicknameFormat } from "../../../utils/nickname";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow_down.svg";
import { PreferenceTypeCode } from "../../../constants/Preference.enum";
import { GenderTypeCode } from "../../../constants/CommonCode.enum";
import RecommenderStore from "../../../store/RecommenderStore";
import RecommenderSection from "../../recommender/presenter/RecommenderSection";
import AuthStore from "../../../store/AuthStore";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import { getRecommendNickname } from "../../../api/member/api";
import { RecommendedNicknameCode } from "../../../constants/CommonCode.enum";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";
import PreferenceHeader from "./PreferenceHeader";
import BirthYearBottomSheet from "../../../components/common/BirthYearBottomSheet";
import { useTracker } from "../../../hooks/tracker";
import classes from "./MyInfoPreference.module.scss";
import clsx from "clsx";

type MyInfoPreferenceProps = {
  ref: any;
  nextHandler?: () => void;
};

const MyInfoPreference = observer(
  ({ ref, nextHandler }: MyInfoPreferenceProps) => {
    useImperativeHandle(ref, () => ({
      handel,
    }));
    const handel = () => {
      nickNameInputRef?.current?.blur();
      recommenderInputRef?.current?.blur();
    };
    const { t } = useTranslation();

    const [showBirthYearBottomSheet, setShowBirthYearBottomSheet] =
      useState<boolean>(false);

    const nickNameInputRef = useRef<any>(null);
    const recommenderInputRef = useRef<any>(null);

    const { track } = useTracker();

    const {
      nicknameValidationResult,
      myGender,
      myBirthYear,
      myNickname,
      myRecommendedNicknameYn,
      setMyNickname,
      setMyGender,
      setMyBirthYear,
      setMyRecommendedNicknameYn,
      preferenceSteps,
      setShowFooter,
      isMyInfoSelectionSatisfied,
    } = PreferenceStore;
    const { sessionUser } = AuthStore;
    const { recommenderExist, recommenderYn } = RecommenderStore;

    useEffectOnlyOnce(() => {
      if (isMyInfoSelectionSatisfied) return;

      void recommenderExist();
      setMyBirthYear(sessionUser?.birthYear || "NA");
      setMyGender(sessionUser?.genderCode || "NA");

      void getRecommendNickname().then((result) => {
        if (result && result.length > 0) {
          void setMyNickname(result);
          setMyRecommendedNicknameYn(RecommendedNicknameCode.RECOMMENDED);
        }
      });

      track("complete_agree_term", {});
    });

    const onNicknameChange = (nickname: string) => {
      void setMyNickname(nickname);
      setMyRecommendedNicknameYn(RecommendedNicknameCode.NA);
    };

    const renderNicknameGuideComponent = (nickname: string) => {
      if (nickname.length < 4) {
        return (
          <div className={classes.alert_wrap}>
            <span className={clsx(classes.alert_text, classes.alert_text2)}>
              {t("screen.preference.myInfo.nickname.guideLabel.inputGuide")}
            </span>
          </div>
        );
      } else if (nickname.length > 15) {
        return (
          <div className={classes.alert_wrap}>
            <span className={clsx(classes.alert_text, classes.alert_text2)}>
              {t("screen.preference.myInfo.nickname.guideLabel.invalidLength")}
            </span>
          </div>
        );
      } else if (!validateNicknameFormat(nickname)) {
        return (
          <div className={classes.alert_wrap}>
            <span className={clsx(classes.alert_text, classes.alert_text2)}>
              {t("screen.preference.myInfo.nickname.guideLabel.invalidFormat")}
            </span>
          </div>
        );
      }

      if (nicknameValidationResult) {
        if (nicknameValidationResult.hasBadWord) {
          return (
            <div className={classes.alert_wrap}>
              <span className={clsx(classes.alert_text, classes.alert_text2)}>
                {t("screen.preference.myInfo.nickname.guideLabel.hasBadWord")}
              </span>
            </div>
          );
        }

        if (nicknameValidationResult.isExist === "N") {
          return (
            <div className={classes.alert_wrap}>
              <span
                className={clsx(classes.alert_text, classes.alert_text1)}
                aria-label="nickName available"
              >
                {t(
                  "screen.preference.myInfo.nickname.guideLabel.validNickname"
                )}
              </span>
            </div>
          );
        } else {
          return (
            <>
              <div className={classes.alert_wrap}>
                <span className={clsx(classes.alert_text, classes.alert_text2)}>
                  {t(
                    "screen.preference.myInfo.nickname.guideLabel.nicknameAlreadyInUse"
                  )}
                </span>
              </div>
              {nicknameValidationResult.recommendedNicknameList &&
                nicknameValidationResult.recommendedNicknameList.length > 0 && (
                  <div className={classes.recommend}>
                    <div className={classes.recommend_text_wrap}>
                      <span className={classes.recommend_text1}>
                        <span className={classes.recommend_text2}>
                          {`'${nicknameValidationResult.recommendedNicknameList[0]}'`}
                        </span>
                        {t(
                          "screen.preference.myInfo.nickname.guideLabel.suggestNicknameFirstLabel"
                        )}
                      </span>
                      <span className={classes.recommend_text1}>
                        {t(
                          "screen.preference.myInfo.nickname.guideLabel.suggestNicknameSecondLabel"
                        )}
                      </span>
                    </div>
                    <button
                      className={classes.use_btn}
                      onClick={() => {
                        void setMyNickname(
                          nicknameValidationResult
                            ?.recommendedNicknameList?.[0] || ""
                        );
                      }}
                    >
                      <span className={classes.use_btn_text}>
                        {t(
                          "screen.preference.myInfo.nickname.guideLabel.changeNicknameButtonLabel"
                        )}
                      </span>
                    </button>
                  </div>
                )}
            </>
          );
        }
      }
    };

    const isGenderSelected = (gender: string) => {
      return gender === myGender;
    };

    const currentStep =
      preferenceSteps.findIndex((item) => item === PreferenceTypeCode.MY_INFO) +
      1;
    const totalStep = preferenceSteps.length;
    const isShowRecommenderSection =
      (sessionUser &&
        sessionUser.memberUuid !== undefined &&
        (recommenderYn === "Y" ||
          getStringRemoteConfig("show_recommend_input") === "true")) ||
      false;

    return (
      <>
        <div
          className={classes.basic_info}
          aria-label={PreferenceTypeCode.MY_INFO}
        >
          <div className={classes.contents}>
            <PreferenceHeader
              currentStep={currentStep}
              totalStep={totalStep}
              title1={t("screen.preference.myInfo.title1")}
              title2={t("screen.preference.myInfo.title2")}
            />
            <div className={classes.info_list}>
              <div className={classes.info_item}>
                <div className={classes.info_item2}>
                  <span className={classes.label}>
                    {t("screen.preference.myInfo.nickname.title")}
                  </span>
                  {myRecommendedNicknameYn ===
                    RecommendedNicknameCode.RECOMMENDED && (
                    <span className={classes.alert_text3}>
                      {t(
                        "screen.preference.myInfo.nickname.guideLabel.recommendnicknameGuide"
                      )}
                    </span>
                  )}
                </div>

                <div className={classes.input_wrap}>
                  <input
                    ref={nickNameInputRef}
                    maxLength={15}
                    className={classes.input}
                    onChange={(e) => onNicknameChange(e.target.value)}
                    value={myNickname}
                    placeholder={t(
                      "screen.preference.myInfo.nickname.placeholder"
                    )}
                    aria-label={`nickname`}
                    autoCapitalize={"none"}
                    // returnKeyType={"next"}
                    autoComplete={"off"}
                    // autoCorrect={false}
                  />
                </div>
                {myRecommendedNicknameYn === RecommendedNicknameCode.NA &&
                  myNickname?.length > 0 &&
                  renderNicknameGuideComponent(myNickname)}
              </div>

              {getStringRemoteConfig("show_gender_birthyear") === "true" && (
                <>
                  {(!sessionUser?.genderCode ||
                    sessionUser?.genderCode === null ||
                    sessionUser?.genderCode === "NA") && (
                    <div className={classes.info_item}>
                      <span className={classes.label}>
                        {t("screen.preference.myInfo.gender.title")}
                      </span>
                      <div className={classes.btn_wrap}>
                        {[GenderTypeCode.FEMALE, GenderTypeCode.MALE].map(
                          (gender) => (
                            <button
                              key={gender}
                              className={clsx(
                                classes.gender_btn,
                                isGenderSelected(gender) && classes.active_btn
                              )}
                              onClick={() => {
                                // Keyboard.dismiss();
                                setMyGender(gender);
                              }}
                              aria-label={t(
                                `screen.preference.myInfo.gender.${gender.toLowerCase()}`
                              )}
                            >
                              <span
                                key={gender}
                                className={clsx(
                                  classes.gender_text,
                                  isGenderSelected(gender) &&
                                    classes.active_btn_text
                                )}
                              >
                                {t(
                                  `screen.preference.myInfo.gender.${gender.toLowerCase()}`
                                )}
                              </span>
                            </button>
                          )
                        )}
                      </div>
                    </div>
                  )}
                  {(!sessionUser?.birthYear ||
                    sessionUser?.birthYear === null ||
                    sessionUser?.birthYear === "NA") && (
                    <div className={classes.info_item}>
                      <span className={classes.label}>
                        {" "}
                        {t("screen.preference.myInfo.birthYear.header")}
                      </span>
                      <button
                        className={classes.year_btn}
                        onClick={() => {
                          // Keyboard.dismiss();
                          setShowFooter(false);
                          setShowBirthYearBottomSheet(true);
                        }}
                        aria-label={"birthYear"}
                      >
                        <>
                          <span className={classes.year_btn_text}>
                            {myBirthYear
                              ? myBirthYear === "NA"
                                ? t("common.label.select")
                                : t(
                                    "screen.preference.myInfo.birthYear.itemLabel",
                                    { birthYear: myBirthYear }
                                  )
                              : t(
                                  "screen.preference.myInfo.birthYear.placeholder"
                                )}
                          </span>
                          <ArrowDownIcon />
                        </>
                      </button>
                      <span className={classes.genderYearGuide}>
                        {t("screen.preference.myInfo.genderYearGuide")}
                      </span>
                    </div>
                  )}
                </>
              )}

              {isShowRecommenderSection && (
                <RecommenderSection inputRef={recommenderInputRef} />
              )}
            </div>
          </div>
          {getStringRemoteConfig("show_gender_birthyear") === "true" && (
            <BirthYearBottomSheet
              open={showBirthYearBottomSheet}
              selectedYear={myBirthYear}
              onClose={() => {
                setShowFooter(true);
                setShowBirthYearBottomSheet(false);
              }}
              onSelect={(year: string) => {
                setMyBirthYear(year);
              }}
            />
          )}
        </div>
      </>
    );
  }
);

export default MyInfoPreference;
