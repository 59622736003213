import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import {
//   StyleSheet,
//   Text,
//   TouchableOpacity,
//   div,
//   Scrolldiv,
//   FlatList,
//   ViewToken,
//   Platform,
//   BackHandler,
// } from "react-native";
import {
  Arranges,
  FlexBoxs,
  Spacings,
  Texts,
  Colors,
} from "../../../assets/styles";
import { Styles } from "../../../assets/types/Style";
// import { BottomSheetView } from "@gorhom/bottom-sheet";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as BackIcon } from "../../../assets/icons/new_back_arrow.svg";
import { ReactComponent as NextIcon } from "../../../assets/icons/arrow_right_gray.svg";
import PostStore, { SubFilterList } from "../../../store/PostStore";
import CustomBottomSheet from "../../../components/common/CustomBottomSheet";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import Slider from "react-slick";
import classes from "./PostFilterBottomSheet.module.scss";

// import { responsiveScreenWidth } from "react-native-responsive-dimensions";
// import { useIsFocused } from "@react-navigation/native";

const PostFilterBottomSheet = observer(() => {
  const { t } = useTranslation();
  const {
    filterList,
    postFilterInfo,
    filterQuery,
    setFilterQuery,
    setPostFilterInfo,
    postFilterReset,
  } = PostStore;
  const FILTER_CODE = "FILTER_CODE";
  const SUB_FILTER_CODE = "SUB_FILTER_CODE";
  type TYPE = "FILTER_CODE" | "SUB_FILTER_CODE";
  const scrollRef = useRef<HTMLDivElement>(null);
  const [subFilterList, setSubFilterList] = useState<SubFilterList[]>();
  const [viewType, setViewType] = useState<TYPE | string>(FILTER_CODE);
  const [dataListType, setDataListType] = useState<TYPE | string>(FILTER_CODE);
  const [choiceFilterCode, setChoiceFilterCode] = useState<string>("");
  const [sliderIndex, setSliderIndex] = useState(0);
  const sliderRef = useRef<Slider>(null);

  //   const isFocused = useIsFocused();

  const sliderSettings = {
    dots: false, // 하단 점 표시 여부
    infinite: false, // 무한 반복 설정
    speed: 500, // 슬라이딩 속도
    slidesToShow: 1, // 한 번에 보여줄 슬라이드 수
    slidesToScroll: 1, // 스크롤 할 때마다 넘어가는 슬라이드 수
    swipe: false, // 드래그를 통한 슬라이드 전환 비활성화
    arrows: false, // 화살표 버튼 비활성화
    beforeChange: (current: number, next: number) => setSliderIndex(next), // 슬라이드가 전환될 때 인덱스 업데이트
  };

  const dataList = [
    {
      id: FILTER_CODE,
    },
    {
      id: SUB_FILTER_CODE,
    },
  ];

  useEffect(() => {
    if (postFilterInfo.isShow) {
      if (postFilterInfo.filterCode && postFilterInfo.filterCode.length > 0) {
        setViewType(SUB_FILTER_CODE);
        const resultFilterItem = filterList?.find(
          (item) => item.codeValue === postFilterInfo.filterCode
        );
        resultFilterItem &&
          setSubFilterList([...resultFilterItem.subFilterList]);
      } else {
        setViewType(FILTER_CODE);
      }
    }
  }, [postFilterInfo.isShow, postFilterInfo.filterCode, filterList]);

  useEffect(() => {
    if (choiceFilterCode.length > 0) {
      setDataListType(SUB_FILTER_CODE);
      const resultFilterItem = filterList?.find(
        (item) => item.codeValue === choiceFilterCode
      );
      resultFilterItem && setSubFilterList([...resultFilterItem.subFilterList]);
    } else {
      setDataListType(FILTER_CODE);
    }
  }, [choiceFilterCode, filterList]);

  const getFilterName = (filterCode: string) => {
    return filterList?.find((item) => item.codeValue == filterCode)?.codeName;
  };

  const getChoiceSubFilterName = (filterCode: string) => {
    const choiceSubFilterCode = filterQuery[filterCode]
      ? filterQuery[filterCode]
      : "";

    const codeName = "";
    if (choiceSubFilterCode.length > 0) {
      const fiterItem = filterList?.find(
        (item) => item.codeValue == filterCode
      );
      return fiterItem?.subFilterList.find(
        (subItem) => subItem.codeValue === choiceSubFilterCode
      )?.codeName;
    }
    return codeName;
  };

  const closeBottomSheet = () => {
    setPostFilterInfo(false);
  };

  const viewabilityConfig = useMemo(() => {
    return {
      waitForInteraction: true,
      viewAreaCoveragePercentThreshold: 100,
    };
  }, []);

  // const handleViewableItemsChanged = useCallback((info: { viewableItems: ViewToken[]; changed: ViewToken[] }) => {
  //   if (info.viewableItems && info.viewableItems.length > 0) {
  //     setDataListType(info.viewableItems[0].key);
  //   }
  // }, []);

  //   const getOriginItemLayout = useCallback((data: any, index: number) => {
  //     return {
  //       index,
  //       length: responsiveScreenWidth(100),
  //       offset: responsiveScreenWidth(100) * index,
  //     };
  //   }, []);

  const renderItem = (index: number, item: any) => {
    return item.id == FILTER_CODE && index == 0 ? (
      <div className={classes.sheet_container}>
        {/* 전체 필터 리스트  */}
        <div className={classes.sheet_title_wrap}>
          <div className={classes.back_btn}></div>
          <div className={classes.sheet_title}>
            <span className={classes.sheet_title_txt}>{t("필터")}</span>
          </div>
          <div className={classes.close_btn}>
            <div
              className={classes.close_btn_icon}
              onClick={() => {
                closeBottomSheet();
              }}
            >
              <CloseIcon />
            </div>
          </div>
        </div>
        <div
          ref={scrollRef}
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            flex: 1,
          }}
        >
          <div className={classes.sheet_contents1}>
            {filterList &&
              filterList.map((item, index) => (
                <div
                  className={classes.contents}
                  key={index}
                  onClick={() => {
                    setChoiceFilterCode(item.codeValue);
                    sliderRef.current?.slickGoTo(1);
                    scrollRef.current?.scrollTo({
                      top: 0,
                    });
                  }}
                >
                  <div className={classes.contents_item1}>
                    <div className={classes.contents_text}>{item.codeName}</div>
                  </div>

                  <div className={classes.contents_item2}>
                    <span className={classes.contents_text}>
                      {/* <span className={classes.contents_text, styles.contents_active_text}> */}
                      {getChoiceSubFilterName(item.codeValue)}
                    </span>
                    <div className={classes.next_btn}>
                      <NextIcon />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={classes.footer}>
          <div
            onClick={() => {
              postFilterReset();
            }}
            className={classes.button_reset}
          >
            <span className={classes.button_reset_text}>{t("초기화")}</span>
          </div>
          <div
            onClick={() => {
              closeBottomSheet();
            }}
            className={classes.button_save}
          >
            <span className={classes.button_save_text}>{t("확인")}</span>
          </div>
        </div>
      </div>
    ) : (
      //   <div style={[{ width: responsiveScreenWidth(100) }]}>
      <div style={{ width: "100vw" }} className={classes.sheet_container}>
        {/* 서브 필터 리스트 */}
        <div className={classes.sheet_title_wrap}>
          <div className={classes.back_btn}>
            <div
              className={classes.back_btn_icon}
              onClick={() => {
                // filterListRef.current?.scrollToIndex({ index: 0 });
                sliderRef.current?.slickGoTo(0);
              }}
            >
              <BackIcon />
            </div>
          </div>
          <div className={classes.sheet_title}>
            <span className={classes.sheet_title_txt}>
              {getFilterName(choiceFilterCode)}
            </span>
            <span className={classes.text_max_cnt}> (최대 1개)</span>
          </div>
          <div className={classes.view_reset}>
            <div
              onClick={() => {
                if (filterQuery[choiceFilterCode]) {
                  void PostStore.deleteFilterOption(choiceFilterCode);
                }
              }}
            >
              <span className={classes.btn_text_reset}>초기화</span>
            </div>
          </div>
        </div>
        <div
          ref={scrollRef}
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
          }}
        >
          <div className={classes.sheet_contents2}>
            {subFilterList &&
              subFilterList.map((item, index) => (
                <div
                  className={classes.sort_item}
                  onClick={() => {
                    setFilterQuery(choiceFilterCode, item.codeValue);
                    setChoiceFilterCode("");
                    // filterListRef.current?.scrollToIndex({ index: 0 });
                    sliderRef.current?.slickGoTo(0);
                  }}
                  key={index}
                >
                  <span
                    // style={[
                    //   styles.sort_text,
                    //   item.codeValue == filterQuery[choiceFilterCode] && styles.sort_active_text,
                    // ]}
                    className={
                      item.codeValue == filterQuery[choiceFilterCode]
                        ? classes.sort_active_text
                        : classes.sort_text
                    }
                  >
                    {item.codeName}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomBottomSheet
        open={postFilterInfo.isShow}
        onClose={() => {
          closeBottomSheet();
        }}
        modalContentStyles={{
          padding: "unset",
        }}
        snapHeight={390} // 바텀 시트가 멈추는 위치 (포인트나 퍼센트로 구현)
      >
        <div className={classes.bottom_sheet_view}>
          {postFilterInfo.isShow &&
            (viewType === FILTER_CODE ? (
              <Slider ref={sliderRef} {...sliderSettings}>
                {dataList.map((item, index) => {
                  return renderItem(index, item);
                })}
              </Slider>
            ) : (
              // <div style={[{ width: responsiveScreenWidth(100) }]}>
              <div
                // style={{ width: "100vw" }}
                className={classes.sheet_container}
              >
                {/* 서브 필터 리스트 */}
                <div className={classes.sheet_title_wrap}>
                  <div className={classes.back_btn}></div>
                  <div className={classes.sheet_title}>
                    <span className={classes.sheet_title_txt}>
                      {getFilterName(postFilterInfo.filterCode)}
                    </span>
                    <span className={classes.text_max_cnt}> (최대 1개)</span>
                  </div>
                  <div
                    onClick={() => {
                      if (filterQuery[postFilterInfo.filterCode]) {
                        void PostStore.deleteFilterOption(
                          postFilterInfo.filterCode
                        );
                      }
                    }}
                  >
                    <span className={classes.btn_text_reset}>초기화</span>
                  </div>
                </div>
                {/* <div ref={scrollRef} scrollEventThrottle={0}> */}
                <div
                  className={classes.sheet_contents2_wrapper}
                  ref={scrollRef}
                >
                  <div className={classes.sheet_contents2}>
                    {subFilterList &&
                      subFilterList.map((item, index) => (
                        <div
                          className={classes.sort_item}
                          onClick={() => {
                            setFilterQuery(
                              postFilterInfo.filterCode,
                              item.codeValue
                            );
                            closeBottomSheet();
                          }}
                          key={index}
                        >
                          <span
                            className={
                              item.codeValue ==
                              filterQuery[postFilterInfo.filterCode]
                                ? classes.sort_active_text
                                : classes.sort_text
                            }
                          >
                            {item.codeName}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </CustomBottomSheet>
    </>
  );
});

export default PostFilterBottomSheet;
