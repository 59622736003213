import { SignUpPath } from "../../constants/CommonCode.enum";
import { Platform, PlatformTypes } from "../../native/platform";
import { callAPIWithPOSTMethod } from "../common/api";
import CommonResponse from "../http";
import { AddMemberResponse } from "../member/model";
import { AddSocialMemberRequest } from "./model";

export const createMember = async (
  addSocialMemberRequest: AddSocialMemberRequest,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    { url: "/v1/member", body: addSocialMemberRequest },
    isLoading
  );
};

export const addSocialMember = async (
  addSocialMemberRequest: AddSocialMemberRequest,
  isLoading = true
): Promise<CommonResponse<AddMemberResponse>> => {
  const signUpPath = Platform.isApp
    ? SignUpPath.MOBILE
    : Platform.OS === PlatformTypes.ANDROID_WEB ||
      Platform.OS === PlatformTypes.IOS_WEB
    ? SignUpPath.MOBILE_WEB
    : SignUpPath.WEB;

  return await callAPIWithPOSTMethod(
    {
      url: "/v1/memberSocial",
      body: { ...addSocialMemberRequest, signUpPath },
    },
    isLoading
  );
};
