import { useCallback, useEffect } from "react";

export const useEffectOnlyOnce = (
  fn?: () => void,
  clear?: () => void
): void => {
  /* eslint-disable */
  const fnCallback = useCallback(() => {
    fn && fn();
  }, []);

  const fnClearCallback = useCallback(() => {
    clear && clear();
  }, []);

  useEffect(() => {
    fnCallback();

    return fnClearCallback;
  }, []);
  /* eslint-enable */
};
