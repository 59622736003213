import { TrackFunction } from "../types";
import { trackViewEventLog } from "../../../api/track/api";
import AuthStore from "../../../store/AuthStore";

type Use220BackEnd = () => {
  log: TrackFunction;
};

export const use220BackEnd: Use220BackEnd = () => {
  const log: TrackFunction = (event, properties) => {
    if (properties) {
      const memberUuid = AuthStore.sessionUser?.memberUuid
        ? AuthStore.sessionUser?.memberUuid
        : AuthStore.tempMember?.tempMemberUUID
        ? AuthStore.tempMember?.tempMemberUUID
        : "";
      const userType = AuthStore.sessionUser?.memberUuid ? "NORMAL" : "TEMP";

      void trackViewEventLog(event, memberUuid, userType, properties);
    }
  };

  return {
    log,
  };
};
