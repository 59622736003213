import { callAPIWithGETMethod } from "../common/api";
import {
  PopularSearchResponse,
  RankingSearchResponse,
  SearchItem,
  SearchResultResponse,
  SuggestionSearchResponse,
  TypoCorrectionResponse,
} from "./model";

export const getSearchResult = async (
  type: string,
  query: string,
  pageSize: number,
  pageIndex: number,
  sort: string,
  tempMemberUuid?: string, // 임시회원일때만 전달
  openApiFlag?: boolean,
  webServiceFlag: boolean = true,
  isLoading = true
): Promise<SearchResultResponse<SearchItem>> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/data/search/${type}`,
      params: {
        query,
        pageSize,
        pageIndex,
        sort,
        tempMemberUuid,
        openApiFlag,
        webServiceFlag,
      },
    },
    isLoading
  );
  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as SearchResultResponse<SearchItem>;
};

export const getAutoComplete = async (
  query: string,
  what?: string,
  isLoading = true
): Promise<string[]> => {
  let url = `/v1/keyword/autocomplete?query=${query}`;
  if (what !== undefined) url += `&what=${what}`;

  const response = await callAPIWithGETMethod(
    {
      url: url,
    },
    isLoading
  );

  return response?.successOrNot === "Y" ? response?.data : null;
};

export const getSuggestionSearch = async (
  memberUuid?: string,
  isLoading = true
): Promise<SuggestionSearchResponse> => {
  let url = `/v1/keyword/suggested`;
  if (memberUuid !== undefined) url += `?memberUuid=${memberUuid}`;

  const response = await callAPIWithGETMethod(
    {
      url: url,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as SuggestionSearchResponse;
};

export const getPopularSearch = async (
  memberUuid?: string,
  isLoading = true
): Promise<PopularSearchResponse> => {
  let url = `/v1/keyword/popular`;
  if (memberUuid !== undefined) url += `?memberUuid=${memberUuid}`;

  const response = await callAPIWithGETMethod(
    {
      url: url,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as PopularSearchResponse;
};

export const getRankingSearch = async (
  memberUuid?: string,
  isLoading = true
): Promise<RankingSearchResponse> => {
  let url = `/v1/keyword/ranking`;
  if (memberUuid !== undefined) url += `?memberUuid=${memberUuid}`;

  const response = await callAPIWithGETMethod(
    {
      url: url,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as RankingSearchResponse;
};

export const getTypoCorrection = async (
  query: string,
  isLoading = true
): Promise<TypoCorrectionResponse> => {
  let url = `/v1/keyword/typoCorrection`;
  if (query !== undefined) url += `?query=${query}`;

  const response = await callAPIWithGETMethod(
    {
      url: url,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as TypoCorrectionResponse;
};
