import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { saveLike, cancelLike, searchLikeList } from '../api/like/api'
import { LikeListItem, LikeTarget } from '../api/like/model'
import { BadgeCode } from '../constants/Badge.enum'
import BadgeStore from './BadgeStore'

class LikeStore {
  constructor() {
    makeObservable(this)
  }

  @observable loading = false
  @observable _isLoadingMore = false
  @observable _likeTarget: LikeTarget | null = null
  @observable _likeList: LikeListItem[] | null = null
  @observable _pageIndex = 0
  @observable _canLoadMore = true

  @computed get isLoadingMore() {
    return this._isLoadingMore
  }

  @computed get likeTarget() {
    return this._likeTarget
  }

  @computed get likeList() {
    return this._likeList
  }

  @computed get pageIndex() {
    return this._pageIndex
  }

  @computed get canLoadMore() {
    return this._canLoadMore
  }

  @action setIsLoadingMore = (isLoadingMore: boolean) => {
    this._isLoadingMore = isLoadingMore
  }

  @action setLikeList = (list: LikeListItem[] | null) => {
    this._likeList = list
  }

  @action setLoading = (isLoading: boolean) => {
    this.loading = isLoading
  }

  @action setPageIndex = (pageIndex: number) => {
    this._pageIndex = pageIndex
  }

  @action setCanLoadMore = (canLoadMore: boolean) => {
    this._canLoadMore = canLoadMore
  }

  @action saveLike = async (
    likeTargetId: number,
    likeTypeCode: string,
    successAction: () => void,
    failAction: () => void
  ) => {
    const res = await saveLike(likeTargetId, likeTypeCode)
    if (res) {
      void BadgeStore.obtainBadge(BadgeCode.LIKE)
      successAction()
    } else {
      failAction()
    }
  }

  @action cancelLike = async (
    likeTargetId: number,
    likeTypeCode: string,
    successAction: () => void,
    failAction: () => void
  ) => {
    const res = await cancelLike(likeTargetId, likeTypeCode)
    if (res) {
      successAction()
    } else {
      failAction()
    }
  }

  @action getLikeList = (target: LikeTarget) => {
    this.setIsLoadingMore(true)
    void runInAction(async () => {
      void searchLikeList(
        target.likeTargetId,
        target.likeTypeCode,
        target.pageSize,
        this._pageIndex
      )
        .then((res) => {
          this.setLikeList(res?.list || [])
          if (res?.list?.length > 0) this.setPageIndex(this._pageIndex + 1)
          else this.setCanLoadMore(false)
        })
        .finally(() => {
          this.setIsLoadingMore(false)
        })
    })
  }

  @action getLikeListMore = (target: LikeTarget) => {
    this.setIsLoadingMore(true)
    if (this._likeList && this._canLoadMore) {
      void searchLikeList(
        target.likeTargetId,
        target.likeTypeCode,
        target.pageSize,
        this._pageIndex
      )
        .then((res) => {
          this._likeList?.push(...(res.list || []))
          if (res?.list?.length > 0) this.setPageIndex(this._pageIndex + 1)
          else this.setCanLoadMore(false)
        })
        .finally(() => {
          this.setIsLoadingMore(false)
        })
    }
  }

  @action clear = () => {
    this._isLoadingMore = false
    this._likeList = []
    this._likeTarget = null
    this._pageIndex = 0
    this._canLoadMore = true
  }
}

const instance = new LikeStore()
export default instance
