import { useEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow_down.svg'
import { ReactComponent as ArrowUp } from '../../../assets/icons/arrow_up.svg'
import { useTranslation } from 'react-i18next'
import { useEffectOnlyOnce } from '../../../hooks/UseEffectOnlyOnce'
import { getCommonCode } from '../../../api/commonCode/api'
import { CommonGroupCode } from '../../../constants/CommonCode.enum'
import { TermsTypeCode } from '../../../constants/Terms.enum'
import SettingStore from '../../../store/SettingStore'
import { observer } from 'mobx-react-lite'
import { goTo } from '../../../hooks/navigate/NavigateFunction'
import classes from './SettingFooter.module.scss'
import TermsOfUseModal, {
  TermsDetailProps,
} from '../../../components/modals/TermsOfUseModal'

const SettingFooter = observer(() => {
  const settingFooterRef = useRef<HTMLDivElement>(null)
  const [settingFooterHeight, setSettingFooterHeight] = useState(0)
  const { t } = useTranslation()
  const { footerExpand, setFooterExpand } = SettingStore
  const [footerContents, setFooterContents] = useState<string>('')

  const defaultTermsDetailProps = useMemo(
    () => ({
      show: false,
      termsId: '',
      termsTypeCode: '',
      onClose: () => {},
    }),
    []
  )

  const [termsDetailProps, setTermsDetailProps] = useState<TermsDetailProps>(
    defaultTermsDetailProps
  )

  const onCloseTermsOfUseModal = useMemo(() => {
    return () => setTermsDetailProps(defaultTermsDetailProps)
  }, [defaultTermsDetailProps])

  const getCompany = async () => {
    const response = await getCommonCode(CommonGroupCode.SETTING_TEXT_CODE)
    response && response[0].attr1 && setFooterContents(response[0].attr1)
  }

  useEffectOnlyOnce(() => {
    void getCompany()
  })

  useEffect(() => {
    if (settingFooterRef.current)
      setSettingFooterHeight(settingFooterRef.current.clientHeight)
  }, [footerExpand])

  return (
    <>
      <div className={classes.action_wrap} ref={settingFooterRef}>
        <div className={classes.action_wrap_padding}>
          <span className={classes.footer_title}>
            {t('screen.setting.label.footerTitle')}
          </span>
          <button
            className={classes.icon_wrap}
            onClick={() => setFooterExpand(!footerExpand)}
          >
            {footerExpand ? <ArrowUp /> : <ArrowDown />}
          </button>
        </div>
        <div
          className={
            footerExpand
              ? classes.footer_expand_open
              : classes.footer_expand_close
          }
        ></div>
        {footerExpand && (
          <div>
            <span className={classes.footer_text}>{footerContents}</span>
            <div className={classes.action_footer} style={{ marginBottom: 15 }}>
              <div
                className={classes.action_footer_btn}
                aria-label="Footer Service"
              ></div>
              <button
                className={classes.action_footer_btn}
                aria-label="Footer Privacy"
                onClick={() =>
                  window.open(
                    'https://www.ftc.go.kr/bizCommPop.do?wrkr_no=1168119477&apv_perm_no',
                    '_blank'
                  )
                }
              >
                <span className={classes.footer_title}>
                  {t('screen.setting.label.businessInfo')}
                </span>
              </button>
            </div>
          </div>
        )}

        <div className={classes.action_footer}>
          <button
            className={classes.action_footer_btn}
            aria-label="Footer Service"
            onClick={() =>
              setTermsDetailProps({
                show: true,
                termsId: '',
                termsTypeCode: TermsTypeCode.SERVICE,
                onClose: () => onCloseTermsOfUseModal(),
              })
            }
          >
            <span className={classes.footer_title}>
              {t('screen.setting.label.footerService')}
            </span>
          </button>
          <button
            className={classes.action_footer_btn}
            aria-label="Footer Privacy"
            onClick={() =>
              setTermsDetailProps({
                show: true,
                termsId: '',
                termsTypeCode: TermsTypeCode.PRIVACY_DETAIL,
                onClose: () => onCloseTermsOfUseModal(),
              })
            }
          >
            <span className={classes.footer_title_bold}>
              {t('screen.setting.label.footerPrivacy')}
            </span>
          </button>
        </div>
      </div>
      <div style={{ height: settingFooterHeight }} />

      <TermsOfUseModal termsDetailProps={termsDetailProps} />
    </>
  )
})

export default SettingFooter
