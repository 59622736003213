import React, { ReactNode, MouseEvent } from "react";
import { getSessionUserFromStorage } from "../../service/Session.service";
import MainStore from "../../store/MainStore";
import { goTo } from "../../hooks/navigate/NavigateFunction";
interface TouchableWithAuthCheckProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  authScreenType?: "MODAL" | "SCREEN";
  onPress?: (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void;
  beforeOnPress?: () => void;
  withoutFeedback?: boolean;
}

const TouchableWithAuthCheck: React.FC<TouchableWithAuthCheckProps> = ({
  children,
  onPress,
  beforeOnPress,
  authScreenType = "MODAL",
  withoutFeedback = false,
  ...props
}) => {
  const navigateToAuthScreen = () => {
    authScreenType === "MODAL" && MainStore.setShowJoinModal(true);
    authScreenType === "SCREEN" && goTo("/loginpresenter");
  };

  const handlePress = (
    event: MouseEvent<HTMLButtonElement | HTMLDivElement>
  ) => {
    event.stopPropagation();
    beforeOnPress && beforeOnPress();
    getSessionUserFromStorage()
      .then((res) => {
        if (res) {
          onPress && onPress(event);
        } else {
          navigateToAuthScreen();
        }
      })
      .catch(() => {
        navigateToAuthScreen();
      });
  };

  return withoutFeedback ? (
    <button {...props} onClick={handlePress}>
      {children}
    </button>
  ) : (
    <button {...props} onClick={handlePress}>
      {children}
    </button>
  );
};

export default TouchableWithAuthCheck;
