export const validatePassword = (password: string): boolean => {
  const regExp =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,20}$/g;

  return regExp.test(password);
};

// 8자리 이상 20자리 이하
export const passwordCondition1 = (password: string): boolean => {
  const regExp = /.{8,20}$/g;

  return regExp.test(password);
};

// 영문, 숫자, 특수문자 포함
export const passwordCondition2 = (password: string): boolean => {
  const regExp =
    /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{1,}$/g;

  return regExp.test(password);
};

// \ (역슬래시) 포함 여부 (포함 안 되어 있을때 true를 리턴해야 함)
export const passwordCondition3 = (password: string): boolean => {
  return !password.includes("\\");
};
