import React, { RefObject, useEffect, useState } from "react";
import { observer } from "mobx-react";

import { useTranslation } from "react-i18next";

import { ReactComponent as DeleteIcon } from "../../../assets/icons/close_purple_background.svg";
import { ReactComponent as PlusPurple } from "../../../assets/icons/plus_purple.svg";
import { ReactComponent as DimImage } from "../../../assets/icons/image_add_dim.svg";

import { NewProduct } from "../../../api/product/model";
import QnaWriteStore from "../../../store/QnaWriteStore";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
import { FeedMode, FeedTypeCode } from "../../../constants/Feed";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

import "./QnaWriteProduct.scss";
import clsx from "clsx";

const QnaWriteProduct = observer(
  ({
    onProductInputFocus,
    mode,
  }: {
    onProductInputFocus?: (() => void) | undefined;
    mode: FeedMode;
  }) => {
    const { t } = useTranslation();
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    const {
      tempProductList,
      productUpdated,
      addProduct,
      setRepresentProduct,
      deleteProduct,
      setToast,
    } = QnaWriteStore;

    const moveToProductSearch = () => {
      if (tempProductList.length < 5) {
        ProductSearchAddStore.setFeedMode(
          mode === FeedMode.MODIFY ? FeedMode.QNA_MODIFY : FeedMode.QNA_WRITE
        );

        goTo("/Product/Search", {
          state: {
            feedTypeCode: FeedTypeCode.QNA,
            screenFrom: "qna",
          },
        });
      } else setToast(t("screen.feedProduct.message.max5Count"));
    };

    return (
      <div id="qna_write_product">
        <div
          className="home_app_list_wrap"
          style={!tempProductList.length ? { display: "none" } : {}}
        >
          {tempProductList.map((item, index) => {
            const imgPath = item.product.originalFilePath;
            return (
              <>
                <div className="home_app_item" key={`Product item ${index}`}>
                  {!item.product?.openApiFlag &&
                  item.product?.productId !== undefined &&
                  item.product?.productId > 0 ? (
                    <div className="info_wrap">
                      <div className="image_wrap">
                        <FastImageWithFallback
                          className="home_app_image"
                          wrapperClassName="home_app_image_wrap"
                          source={{
                            uri: `${PUBLIC_BUCKET_URL}${
                              item.product?.originalFilePath || ""
                            }`,
                          }}
                        />
                      </div>
                      <div className="info">
                        <p
                          className="info_text_1"
                          // numberOfLines={2}
                        >
                          [{item.product?.manufacturerName}]{" "}
                          {item.product?.productName}
                        </p>
                        <p className="info_text_2">
                          {item.product?.productModelName}
                        </p>
                        <div
                          className={clsx(
                            "rep_info",
                            item?.isRepresent ? "active" : "inactive"
                          )}
                          onClick={() => setRepresentProduct(index)}
                        >
                          <p>{t("screen.feedProduct.button.represent")}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="info_wrap">
                      <div className="image_wrap">
                        <FastImageWithFallback
                          wrapperClassName="home_app_image_wrap"
                          className="home_app_image"
                          source={{
                            uri: `${
                              imgPath?.includes("https:")
                                ? item.product.originalFilePath
                                : `${PUBLIC_BUCKET_URL}${
                                    item.product?.originalFilePath || ""
                                  }`
                            }`,
                          }}
                          fallbackImageUri={imgPath}
                        />
                        <DimImage className="dim_image" />
                      </div>
                      <div className="info">
                        <p
                          className="info_text_1"
                          // numberOfLines={2}
                          // ellipsizeMode={"tail"}
                        >
                          [{item.product?.manufacturerName}]{" "}
                          {item.product?.productName}
                        </p>
                        <p className="info_text_2">
                          {item.product?.productModelName}
                        </p>
                        <p className="alert_text">
                          {t("screen.feedProduct.label.willApprove")}
                        </p>
                      </div>
                    </div>
                  )}
                  <div
                    className="icon_wrap"
                    onClick={() => deleteProduct(index)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="add_home_app_button" onClick={moveToProductSearch}>
          <PlusPurple />
          <p className="add_home_app_button_text">
            {t("screen.post.button.addHa")}
          </p>
        </div>
      </div>
    );
  }
);

export default QnaWriteProduct;
