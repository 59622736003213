import React, { ReactNode } from "react";
import reactStringReplace from "react-string-replace";
import { Colors, Texts } from "../../assets/styles";
import {
  getTagNicknameFromTagUserInfo,
  getUuidFromTagUserInfo,
} from "../../utils/comment";
import { openInAppBrowser } from "../../utils/common";
import { goTo } from "../../hooks/navigate/NavigateFunction";

type ParseType = "TAG" | "URL";

export function ParsedTextToText(value: string) {
  const parseTagUserInfo = (text: string | ReactNode[]) => {
    const tagUserInfoRegex = /(\[[^:]+:[^\]]+\])/gm;
    return reactStringReplace(text, tagUserInfoRegex, (match, i) =>
      getTagNicknameFromTagUserInfo(match)
    );
  };
  const parseUrl = (text: string | ReactNode[]) => {
    const urlRegex = /(https?:\/\/[^\s<]+|www\.[^\s<]+)/gi;

    return reactStringReplace(text, urlRegex, (match, i) => match);
  };
  const ParseFunctions: Function[] = [parseUrl, parseTagUserInfo];
  const applyParsers = (inputText: string) => {
    return ParseFunctions.reduce((text, parser) => parser(text), inputText);
  };

  return applyParsers(value);
}

export interface CustomParsedTextProps {
  children: string;
  pressEnabled?: boolean;
  onPressParsed?: (text: string, index: number) => void;
  urlStyle?: any;
  style?: any;
}
const CustomParsedText = ({
  children,
  pressEnabled = true,
  onPressParsed,
  urlStyle,
  style,
  ...props
}: CustomParsedTextProps) => {

  const handleParsedPress = (type: ParseType, text: string, index: number) => {
    if (pressEnabled) {
      switch (type) {
        case "TAG":
          goTo(`/user/${getTagNicknameFromTagUserInfo(text)}`, {
            state: { targetUserId: getUuidFromTagUserInfo(text) },
          });
          break;
        case "URL":
          // void openInAppBrowser(text);
          break;
      }

      onPressParsed && onPressParsed(text, index);
    }
  };

  const parseUrl = (text: string | ReactNode[]) => {
    const urlRegex = /(https?:\/\/[^\s<]+|www\.[^\s<]+)/gi;

    return reactStringReplace(text, urlRegex, (match, i) => (
      <a
        href={match}
        key={`parsed_url_${i}`}
        style={{
          ...Colors.font_3366bb,
          textDecorationLine: "underline",
          ...urlStyle,
        }}
        onClick={() => handleParsedPress("URL", match, i)}
      >
        {match}
      </a>
    ));
  };

  const parseTagUserInfo = (text: string | ReactNode[]) => {
    const tagUserInfoRegex = /(\[[^:]+:[^\]]+\])/gm;
    return reactStringReplace(text, tagUserInfoRegex, (match, i) => (
      <span
        key={`tag_user_info_${i}`}
        style={
          pressEnabled
            ? { ...Texts.contents_text_10, ...Colors.font_be69c3 }
            : {}
        }
        onClick={() => handleParsedPress("TAG", match, i)}
      >
        {getTagNicknameFromTagUserInfo(match)}
      </span>
    ));
  };

  const ParseFunctions: Function[] = [parseUrl, parseTagUserInfo];

  const applyParsers = (inputText: string) => {
    return ParseFunctions.reduce((text, parser) => parser(text), inputText);
  };

  return (
    <div style={{ whiteSpace: "pre-line" }} {...props}>
      {applyParsers(children)}
    </div>
  );
};

export default CustomParsedText;
