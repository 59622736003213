import { observer } from "mobx-react";
import { Virtuoso } from "react-virtuoso";
import { Arranges, Colors, FlexBoxs, Spacings, Texts } from "../../../assets/styles";
import { Styles } from "../../../assets/types/Style";
import { Category, Tab } from "../../../constants/Article.enum";
import { t } from "i18next";
import { ReactComponent as IconBookmark } from "../../../assets/icons/icon_bookmark.svg";
import { ReactComponent as IconFire } from "../../../assets/icons/fire.svg";
import ArticleListStore from "../../../store/ArticleListStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { FeedTypeCode } from "../../../constants/Feed";

const ArticleListFilter = observer(() => {
  const {
    tab_item_wrap,
    tab_item,
    tab_active_item,
    tab_txt,
    tab_active_txt,
    sub_item,
    sub_active_item,
    sub_txt,
    sub_active_txt,
    wrapper,
  } = styles;

  const { selectedTab, selectedCategory, toggleTab, toggleCategory } = ArticleListStore;

  const tabs = {
    category: [
      { code: Category.ALL, text: t("screen.brandCommunity.articleList.filter.category.all") },
      { code: Category.TIP, text: t("screen.brandCommunity.articleList.filter.category.tip") },
      { code: Category.REVIEW, text: t("screen.brandCommunity.articleList.filter.category.review") },
      { code: Category.ASK, text: t("screen.brandCommunity.articleList.filter.category.ask") },
      { code: Category.CHAT, text: t("screen.brandCommunity.articleList.filter.category.chat") },
      { code: Category.DEAL, text: t("screen.brandCommunity.articleList.filter.category.deal") },
      { code: Category.ETC, text: t("screen.brandCommunity.articleList.filter.category.etc") },
    ],
    tabType: [
      { code: Tab.ALL, text: t("screen.brandCommunity.articleList.tabtype.all") },
      { code: Tab.POPULAR, text: t("screen.brandCommunity.articleList.tabtype.popular") },
      { code: Tab.NOTICE, text: t("screen.brandCommunity.articleList.tabtype.notice") },
      { code: Tab.MY, text: t("screen.brandCommunity.articleList.tabtype.my") },
    ],
  };

  return (
    <>
      <div style={wrapper}>
        <div style={styles.tab_option}>
          <div style={{ flexGrow: 1 }}>
            <Virtuoso
              style={{ height: 50, ...tab_item_wrap }} //  
              horizontalDirection
              data={tabs.tabType}
              initialItemCount={tabs.tabType.length}
              itemContent={(index: number, item: any) => {
                return (
                  <div style={{ paddingRight: 16 }} key={index}>
                    <div
                      style={selectedTab == item.code ? { ...tab_item, ...tab_active_item } : tab_item}
                      onClick={() => toggleTab(item.code)}
                    >
                      {item.code === Tab.POPULAR && <IconFire width={16} height={16} />}
                      <p style={{ ...(selectedTab == item.code ? { ...tab_txt, ...tab_active_txt } : tab_txt) }}>{item.text}</p>
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <div style={styles.icon_bookmark}>
            <div style={{height: 21}}
              onClick={() => {
                goTo("/Bookmark", { state: { feedType: FeedTypeCode.ARTICLE } });
              }}
            >
              <IconBookmark />
            </div>
          </div>
        </div>
        {selectedTab !== Tab.NOTICE && (
          <div style={styles.tab_sub_option}>
            <Virtuoso
              style={{ height: 43, ...tab_item_wrap }} //  
              horizontalDirection
              data={tabs.category}
              initialItemCount={tabs.tabType.length}
              itemContent={(index: number, item: any) => {
                return (
                  <div style={{ paddingRight: 6 }}>
                    <div
                      style={selectedCategory == item.code ? { ...sub_item, ...sub_active_item } : sub_item}
                      key={index}
                      onClick={() => toggleCategory(item.code)}
                    >
                      <p style={{ ...(selectedCategory == item.code ? { ...sub_txt, ...sub_active_txt } : sub_txt) }}>{item.text}</p>
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}
      </div>
    </>
  );
});
export default ArticleListFilter;

const styles: Styles = {
  tabs: {
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Colors.border_color_eee,
    borderStyle: 'solid'
  },
  tab_option: {
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    ...Colors.background_fff,
    ...Colors.border_color_eee,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  tab_item_wrap: {
    ...Spacings.padding_right_left_20,
  },
  tab_item: {
    height: 48,
    paddingLeft: 10,
    paddingRight: 10,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Colors.border_color_fff,
  },
  tab_active_item: {
    borderBottomWidth: 2,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    ...Colors.border_color_222,
  },
  tab_txt: {
    ...Texts.contents_text_28,
    marginBottom: -2,
  },
  tab_active_txt: {
    ...Texts.font_weight_bold,
    marginBottom: -2,
  },
  icon_bookmark: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.padding_right_16,
    flexBasis: 18
  },
  tab_sub_option: {
    ...Spacings.padding_top_10,
    ...Spacings.margin_bottom_12,
  },
  sub_item: {
    ...Spacings.padding_top_bottom_10,
    ...Spacings.padding_left_right_20,
    ...Colors.border_color_d9d9d9,
    ...Colors.background_fff,
    ...Spacings.border_radius_300,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
  },
  sub_active_item: {
    ...Colors.background_be69c3,
    ...Colors.border_color_be69c3,
  },
  sub_txt: {
    ...Texts.contents_text_0,
    ...Colors.font_222,
    ...Texts.font_weight_normal,
  },
  sub_active_txt: {
    ...Colors.font_fff,
    ...Texts.font_weight_bold,
  },

  tab_btn: {
    height: 46,
    borderBottomWidth: 2,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    ...Colors.border_color_fff,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.padding_left_right_6,
    ...Spacings.margin_right_10,
  },
  tab_text: {
    ...Texts.button_text_6,
    ...Texts.font_weight_normal,
    ...Colors.font_999,
  },
  tab_active_btn: {
    ...Colors.border_color_222,
  },
  tab_active_btn_text: {
    ...Texts.button_text_6,
    ...Texts.font_weight_500,
  },
  wrapper: {
    ...Colors.background_fff,
  },
  wrapper_top_padding: {
    ...Colors.background_fff,
    ...Spacings.padding_top_60,
  },

  header_option: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Arranges.between,
    ...Spacings.padding_left_right_20,
    ...Spacings.padding_top_bottom_6,
    ...Spacings.margin_top_4,
  },
  header_check_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  header_check_btn_txt: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    ...Spacings.margin_left_6,
  },
  header_sort_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  header_sort_btn_txt: {
    ...Texts.btn_text_1,
    ...Colors.font_666666,
    ...Spacings.margin_right_6,
  },
};
