import { action, computed, makeObservable, observable } from "mobx";
import {
  checkCertification,
  sendCertification,
} from "../api/certification/api";
import { SendCertificationResult } from "../api/like/model";
import { UserInformationUpdate } from "../api/member/model";
import { CertificationCodeStatus } from "../constants/Certification.enum";
import { SuccessOrNot } from "../constants/Common.enum";

import {
  deHyphenatePhoneNumber,
  replacePhoneNumber,
  validateHypenPhoneNumber,
} from "../utils/phoneNumber";
// import CookieManager from "@react-native-cookies/cookies";
import { t } from "i18next";
import CommonStore from "./CommonStore";
// import { resolve } from "react-native-svg/lib/typescript/lib/resolve";

export const EMPTY_STRING = "";

class PhoneAuthStore {
  @observable _phoneNumber = EMPTY_STRING;
  @observable _isPhoneNumberValid = false;
  @observable _isPhoneAuthSectionShow = false;
  @observable _inputPhoneCertificationCode = EMPTY_STRING;
  @observable _phoneCertificationCodeStatus = EMPTY_STRING;
  @observable _isPhoneNumberSectionComplete = false;
  @observable _timerStart = false;
  @observable _phoneAuthCertificationId = EMPTY_STRING;
  @observable _invalidCertificationCount = 0;

  constructor() {
    makeObservable(this);
  }

  @computed get invalidCertificationCount() {
    return this._invalidCertificationCount;
  }
  @action setInvalidCertificationCount = (x: number) => {
    this._invalidCertificationCount = x;
  };
  @action resetInvalidCertificationCount = () => {
    this._invalidCertificationCount = 0;
  };

  @computed get phoneAuthCertificationId() {
    return this._phoneAuthCertificationId;
  }

  @action setPhoneAuthCertificationId = (data: string) => {
    this._phoneAuthCertificationId = data;
  };

  @computed get phoneNumber() {
    return this._phoneNumber;
  }

  @computed get deHyphenatePhoneNumber() {
    return deHyphenatePhoneNumber(this._phoneNumber);
  }

  @action setPhoneNumber = (data: string) => {
    this._phoneNumber = data;
  };

  @computed get isPhoneNumberValid() {
    return this._isPhoneNumberValid;
  }

  @action setIsPhoneNumberValid = (data: boolean) => {
    this._isPhoneNumberValid = data;
  };

  @computed get isPhoneAuthSectionShow() {
    return this._isPhoneAuthSectionShow;
  }

  @action setIsPhoneAuthSectionShow = (data: boolean) => {
    this._isPhoneAuthSectionShow = data;
  };

  @computed get inputPhoneCertificationCode() {
    return this._inputPhoneCertificationCode;
  }

  @action setInputPhoneCertificationCode = (data: string) => {
    this._inputPhoneCertificationCode = data;
  };

  @computed get phoneCertificationCodeStatus() {
    return this._phoneCertificationCodeStatus;
  }

  @action setPhoneCertificationCodeStatus = (data: string) => {
    this._phoneCertificationCodeStatus = data;
  };

  @computed get isPhoneNumberSectionComplete() {
    return this._isPhoneNumberSectionComplete;
  }

  @action setIsPhoneNumberSectionComplete = (data: boolean) => {
    this._isPhoneNumberSectionComplete = data;
  };

  @computed get timerStart() {
    return this._timerStart;
  }

  @action setTimerStart = (data: boolean) => {
    this._timerStart = data;
  };

  @action sendAuthPhone = async (
    authType: "phone" | "sms" = "phone",
    accountInfo?: UserInformationUpdate
  ) => {
    this.resetInvalidCertificationCount();
    this.setTimerStart(false);
    this.setInputPhoneCertificationCode(EMPTY_STRING);
    this.setPhoneCertificationCodeStatus(EMPTY_STRING);
    if (
      accountInfo &&
      accountInfo.phoneNumberConfirmDatetime &&
      deHyphenatePhoneNumber(accountInfo.phoneNumber) ===
        deHyphenatePhoneNumber(this.phoneNumber)
    ) {
      // 기존에 인증된 번호 재인증시도 시 인증하지 않음
      this.setIsPhoneNumberSectionComplete(true);
    } else {
      const response: SendCertificationResult = await sendCertification(
        deHyphenatePhoneNumber(this.phoneNumber),
        authType
      );
      if (response) {
        this.setIsPhoneAuthSectionShow(true);

        this.setTimerStart(true);
        this.setPhoneAuthCertificationId(
          response.certificationId || EMPTY_STRING
        );
      } else {
        this.setTimerStart(false);
      }
    }
  };

  @action resetPhoneAuthSection = () => {
    this.setPhoneNumber(EMPTY_STRING);
    this.setIsPhoneNumberValid(false);
    this.setIsPhoneNumberSectionComplete(false);
    this.setIsPhoneAuthSectionShow(false);
  };

  @action handleChangePhoneNumber = (text: string) => {
    this.setPhoneNumber(replacePhoneNumber(text));
    if (this.isPhoneNumberValid) {
      this.setIsPhoneNumberValid(false);
      this.setIsPhoneNumberSectionComplete(false);
      this.setIsPhoneAuthSectionShow(false);
    }
    if (validateHypenPhoneNumber(replacePhoneNumber(text))) {
      this.setIsPhoneNumberValid(true);
    }
  };

  @action resetAuthLogic = () => {
    this.setInputPhoneCertificationCode(EMPTY_STRING);
    this.setPhoneCertificationCodeStatus(EMPTY_STRING);
    this.setIsPhoneAuthSectionShow(false);
    this.setTimerStart(false);
    // void CookieManager.clearAll(true);
  };

  @action checkPhoneCertCode = async () => {
    const response = await checkCertification(
      this.phoneAuthCertificationId,
      this.inputPhoneCertificationCode,
      "phone"
    );
    if (response) {
      if (response.successOrNot === SuccessOrNot.Y && response.data) {
        this.setIsPhoneNumberSectionComplete(true);
        this.resetAuthLogic();
        setTimeout(() => {
          CommonStore.setToastOption({
            show: true,
            message1: t("screen.join.joinPhone.authComplete"),
          });
        }, 500);
        return EMPTY_STRING;
      } else {
        switch (response.statusCode) {
          case CertificationCodeStatus.EXPIRED_CERTIFICATION_CODE:
            this.setPhoneCertificationCodeStatus(
              CertificationCodeStatus.EXPIRED_CERTIFICATION_CODE
            );
            break;
          case CertificationCodeStatus.INVALID_CERTIFICATION_CODE:
            this.setPhoneCertificationCodeStatus(
              CertificationCodeStatus.INVALID_CERTIFICATION_CODE
            );
            break;
          case CertificationCodeStatus.NO_CERTIFICATION_REQUEST:
            this.setPhoneCertificationCodeStatus(
              CertificationCodeStatus.NO_CERTIFICATION_REQUEST
            );
            break;
          default:
            this.setPhoneCertificationCodeStatus(
              CertificationCodeStatus.NO_CERTIFICATION_REQUEST
            );
            break;
        }
        return response.statusCode;
      }
    }
    return null;
  };
}

export default new PhoneAuthStore();
