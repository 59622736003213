import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { observer } from "mobx-react";
import BrandProductListStore from "../../../store/BrandCommunityProductListStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { PRODUCT_SEARCH_COUNT } from "../../../constants/Product";
import { bottomOffset } from "../../../utils/common";
import { Styles } from "../../../assets/types/Style";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
import { Arranges, FlexBoxs, Positions, Colors, Texts, Spacings } from "../../../assets/styles";
import { OptionType } from "../../../components/common/ProductPopupMenu";
import BrandCommunityProductOptionBox from "./BrandCommunityProductOptionBox";
import { PostLocalSave } from "../../../store/PostWriteStore";
import { POST_TEMP_DATA } from "../../../constants/Storage";
import { in60Days } from "../../../utils/datetime";
import { SearchProductItem } from "../../../api/brand/model";
import { NewProduct } from "../../../api/product/model";
import { NotificationModal } from "../../../components/modals";
import { showBottomToast } from "../../../utils/Toast";
import { setMyProduct } from "../../../api/product/api";
import { CreateType } from "../../../constants/Common.enum";
import { goToLifecareProductPage } from "../../../utils/mall";
import { ReactComponent as MallTag } from "../../../assets/icons/mall_sale_tag.svg";
import CustomActivityIndicator from "../../../components/common/CustomActivityIndicatorSmall";
import { Virtuoso } from "react-virtuoso";

const BrandCommunityProductList = observer(() => {

  const { t } = useTranslation();

  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

  const {
    searchResult,
    keyword,
    brandCommunityName,
    sort,
    refreshing,
    loading,
    loadingInList,
    searchPageIndex,
    setRefreshing,
    setLoadingInList,
    getSearchResult,
    brandCommunityId,
    brandCommunitySubscriptionYn,
  } = BrandProductListStore;

  const { setBrandCommunityProductList } = ProductSearchAddStore;

  const [showPostPopup, setShowPostPopup] = useState(false);
  const [postData, setPostData] = useState({} as PostLocalSave | null);
  const [newProduct, setNewProduct] = useState({} as NewProduct);

  const [width, setWidth] = useState(0);
  const [maxNamelength, setMaxNamelength] = useState(0);
  const [maxInfolength, setMaxInfolength] = useState(0);
  const wrapRef = useRef<HTMLDivElement>(null);
  const { innerHeight } = window;
  const headerHeight = 60;
  const filterHeight = 52;
  const searchHeight = 44;
  
  useEffect(() => {
    if(wrapRef && wrapRef.current) {
      if (innerHeight > wrapRef.current.scrollHeight + headerHeight + filterHeight + searchHeight) {
        setInitialRender(false)
      }
    }
  }, [wrapRef, wrapRef.current])

  const [y, setY] = useState(window.scrollY);
  const [initialRender, setInitialRender] = useState(true);

  const handleNavigation = (e: any) => {
    const scrollWindow = e.currentTarget;
    if (y < scrollWindow.scrollY) {
      setInitialRender(false)
    }
    setY(scrollWindow.scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation)

    return () => {
      window.removeEventListener("scroll", handleNavigation)
    }
  }, [handleNavigation])

  useEffect(() => {
    if (wrapRef?.current?.offsetWidth) {
      setWidth(wrapRef?.current?.offsetWidth);
      setMaxNamelength(width > 370 ? 25 : 22);
      setMaxInfolength(width > 370 ? 22 : 19);
    }
  }, [wrapRef.current]);

  useEffect(() => {
    const { setRefreshing, keyword, getSearchResult } = BrandProductListStore;
    setRefreshing(true);
    keyword ? void getSearchResult(keyword, 0) : void getSearchResult("", 0);
  }, [sort]);

  const checkSavedPostThenMove = (product: NewProduct) => {
    setPostData(null);
    let result = localStorage.getItem(POST_TEMP_DATA);

    if (result) {
      const storedData: PostLocalSave = JSON.parse(result);
      const postTempDate = storedData.date;
      if (postTempDate && in60Days(postTempDate)) {
        setPostData(storedData);
        moveToWritePost(storedData);
      } else {
        moveToWritePostNewProduct(product);
      }
    } else {
      moveToWritePostNewProduct(product);
    }
  };

  const moveToWritePost = (data?: PostLocalSave) => {
    data ? setShowPostPopup(true) : writeNewPost(null);
  };

  const writeNewPost = (formerData: PostLocalSave | undefined | null) => {
    goTo("/post/write", { state: { data: formerData } });
  };

  const moveToWritePostNewProduct = (targetProduct: NewProduct) => {
    goTo("/post/write", { state: { productInfo: targetProduct } });
  };

  const handleMenuAction = (type: OptionType, item: SearchProductItem, index: number) => {
    const selectedProduct: NewProduct = {
      thumbnailFilePath: item.thumbnailFilePath,
      productId: item.productId,
      productName: item.productName || "",
      manufacturerName: item.brandNameKor || "",
      originalFilePath: item?.thumbnailFilePath?.replace("THUMBNAIL", "ORIGINAL"),
      productModelName: item.productModelName,
      openApiFlag: false,
    };
    setNewProduct(selectedProduct);

    switch (type) {
      case "WRITE_ARTICLE":
        goTo("/ArticleWrite", {
          state: {
            communityId: brandCommunityId,
            subscriptionYn: brandCommunitySubscriptionYn,
            productInfo: selectedProduct,
          }
        });
        break;
      case "WRITE_POST":
        checkSavedPostThenMove(selectedProduct);
        break;
      case "WRITE_QNA":
        goTo("/QnA/write", { state: { productInfo: selectedProduct } });
        break;
      case "REGIST_MYAPPLIANCE":
        void setMyProduct([{ productId: item.productId, productFlag: true }]).then((result) => {
          if (result.successOrNot === "Y") {
            const changeTarget: SearchProductItem = {
              ...item,
              myProductId: item.productId,
            };
            searchResult?.list?.splice(index, 1, changeTarget);
            showBottomToast(t("common.message.completeRegistMyHomeAppliances"), bottomOffset());
          }
        });
        break;
      case "BUY_PRODUCT":
        goToLifecareProductPage(item.lifecareProductId || 0);
        break;
    }
  };

  const handleCheckDisabled = (type: OptionType, item: SearchProductItem) => {
    switch (type) {
      case "REGIST_MYAPPLIANCE":
        return item.myProductId ? true : false;
      case "BUY_PRODUCT":
        return item.lifecareProductId === undefined || item.lifecareProductId <= 0;
      default:
        break;
    }
    return false;
  };

  const goProductAddScreen = () => {
    setBrandCommunityProductList(true);
    goTo("/Product/Add", {
      state: {
        createType: CreateType.NEW,
      }
    });
  };

  return (
    <div ref={wrapRef}>
      <Virtuoso
        style={{ ...styles.product_list }}
        useWindowScroll
        data={searchResult?.list || []}
        itemContent={(index: number, item: any) => {
          return (
            <>
              <div style={styles.list_wrap} key={index}>
                <div style={styles.list_item_wrap}>
                  <button
                    style={styles.product_item}
                    onClick={() => {
                      goTo(`/Product/${item.productId}`);
                    }}
                  >
                    <div style={styles.list_item_info}>
                      <div style={styles.list_item_image}>
                        <FastImageWithFallback
                          source={{
                            uri: `${PUBLIC_BUCKET_URL}${item?.thumbnailFilePath || ""}`,
                          }}
                          fallbackImageUri={`${PUBLIC_BUCKET_URL}${item?.thumbnailFilePath?.replace("THUMBNAIL", "ORIGINAL") || ""}`}
                          style={styles.product_image}
                        />
                      </div>
                      <div style={styles.list_item_title}>
                        <p style={styles.list_item_title_text}>
                          {item?.productName && item.productName.length > maxNamelength
                            ? item.productName.substring(0, 25 - 3).concat("...")
                            : item.productName}
                        </p>
                        <div style={styles.list_item_info_text}>
                          <p style={styles.info_text}>
                            {item?.brandNameKor
                              ? item.brandNameKor.length > 10
                                ? item.brandNameKor.substring(0, 10 - 3).concat("...")
                                : item.brandNameKor.length > 0
                                  ? item.brandNameKor
                                  : "-"
                              : "-"}
                          </p>
                          <div style={styles.dividing_line}></div>
                          <p style={styles.info_text}>
                            {item?.productModelName
                              ? item.brandNameKor
                                ? item.productModelName.length >= maxInfolength - item.brandNameKor.length
                                  ? item.productModelName
                                    .substring(0, maxInfolength - item.brandNameKor.length - 3)
                                    .concat("...")
                                  : item.productModelName.length > 0
                                    ? item.productModelName
                                    : "-"
                                : item.productModelName.length > maxInfolength
                                  ? item.productModelName.substring(0, maxInfolength - 3).concat("...")
                                  : item.productModelName.length > 0
                                    ? item.productModelName
                                    : "-"
                              : "-"}
                          </p>
                        </div>
                        {item?.lifecareProductId && item.lifecareProductId !== null ? (
                          <div style={styles.on_sale_tag_wrap}>
                            <div style={styles.mall_tag}>
                              <MallTag />
                            </div>
                            <p style={styles.on_sale_tag_text}>{t("screen.main.label.onSale")}</p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </button>
                  <div>
                    <BrandCommunityProductOptionBox
                      index={index}
                      product={item}
                      onAction={handleMenuAction}
                      onCheckDisabled={handleCheckDisabled}
                    />
                  </div>
                </div>
              </div>
              {(searchResult?.totalCount || 0) > 0 &&
                searchResult &&
                searchResult?.list &&
                searchResult.list.length > 0 &&
                searchResult.list.length === index + 1 &&
                !loadingInList && (
                  <>
                    <div style={styles.empty_div}></div>
                    <div style={styles.empty_wrap}>
                      <p style={styles.empty_text1}>
                        {t("screen.brandCommunity.label.noProduct")}
                      </p>
                      <div style={styles.empty_info}>
                        <p style={styles.empty_text2}>
                          {t("screen.brandCommunity.label.createNew", {
                            brandName:
                              brandCommunityName &&
                              (brandCommunityName?.length > 12
                                ? brandCommunityName?.substring(0, 10).concat("...")
                                : brandCommunityName),
                          })}
                        </p>
                        <p style={styles.empty_text2}>
                          {t("screen.brandCommunity.label.createGuide")}
                        </p>
                      </div>
                      <button
                        style={styles.add_home_app_button}
                        onClick={goProductAddScreen}
                      >
                        <p style={styles.add_home_app_button_text}>
                          {t("screen.brandCommunity.button.newProduct")}
                        </p>
                      </button>
                    </div>
                  </>
                )}
            </>
          );
        }}
        components={{
          EmptyPlaceholder: () => (
            !loading ? (
              <div style={styles.empty_wrap}>
                <p style={styles.empty_text1}>
                  {t("screen.brandCommunity.label.noProduct")}
                </p>
                <div style={styles.empty_info}>
                  <p style={styles.empty_text2}>
                    {t("screen.brandCommunity.label.createNew", { brandName: brandCommunityName })}
                  </p>
                  <p style={styles.empty_text2}>
                    {t("screen.brandCommunity.label.createGuide")}
                  </p>
                </div>
                <button
                  style={styles.add_home_app_button}
                  onClick={goProductAddScreen}
                >
                  <p style={styles.add_home_app_button_text}>
                    {t("screen.brandCommunity.button.newProduct")}
                  </p>
                </button>
              </div>
            ) : (
              <></>
            )
          ),
          Footer: () => (
            loadingInList ?
              <div id="loading" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CustomActivityIndicator />
              </div>
              : <></>
          )
        }}
        endReached={() => {
          if (
            (searchResult?.totalCount || 0) > 0 &&
            searchResult &&
            searchResult.list &&
            searchResult.list.length > 0
          ) {
            if(!initialRender) {
              const tempStart = searchPageIndex + 1;
              if ((searchResult?.totalCount || 0) > tempStart * PRODUCT_SEARCH_COUNT) {
                setLoadingInList(true);
                if (keyword) void getSearchResult(keyword, tempStart);
                else void getSearchResult("", tempStart);
              }
            }
          }
        }}
      />

      <div style={{ height: 15 }} />

      {showPostPopup && (
        <NotificationModal
          isVisible={showPostPopup}
          title={t("screen.post.title.loadPost")}
          contents1={t("screen.post.message.loadTempSaved")}
          useTwoButton={true}
          isBlack={true}
          defaultButtonText={t("screen.post.button.newOne")}
          extraButtonText={t("screen.post.button.load")}
          onClickDefaultButton={() => {
            moveToWritePostNewProduct(newProduct);
            setShowPostPopup(false);
          }}
          onClickExtraButton={() => {
            writeNewPost(postData);
            setShowPostPopup(false);
          }}
          onRequestClose={() => {
            setShowPostPopup(false);
          }}
        />
      )}
    </div>
  );
});

export default BrandCommunityProductList;

const styles: Styles = {
  empty_div: {
    width: '100%',
    height: 6,
    backgroundColor: "#f5f5f5",
    paddingBottom: 10,
    paddingLeft: -20,
    paddingRight: -20,
    paddingTop: 10,
  },
  empty_text1: {
    height: 23,
    fontSize: 16,
    fontWeight: "bold",
    color: "#666666",
    marginTop: 40,
    alignSelf: "center",
  },
  empty_text2: {
    fontSize: 14,
    color: "#999",
    textAlign: "center",
  },
  product_list: {
    ...Spacings.margin_left_6,
    ...Spacings.margin_right_6,
    ...Spacings.margin_bottom_10,
  },
  product_image: {
    width: 60,
    height: 60,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: "rgba(34, 34, 34, 0.1)",
    ...Spacings.border_radius_6,
    ...Spacings.margin_right_12,
  },
  product_item: {
    width: "100%",
  },
  empty_wrap: {
    ...Spacings.margin_bottom_20,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical
  },
  empty_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Positions.relative,
    ...Arranges.center_v_h,
    ...Spacings.margin_top_16,
    ...Spacings.margin_left_16,
  },
  add_home_app_button: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Colors.background_f7f4fa,
    ...Spacings.border_radius_4,
    ...Spacings.margin_top_32,
    width: 190,
    height: 44,
    borderRadius: 300,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: "#be69c3",
    alignSelf: "center",
    backgroundColor: "#fff",
  },
  add_home_app_button_text: {
    ...Colors.font_a760b1,
    ...Texts.button_text_1,
    ...Texts.font_weight_500,
    color: "#be69c3",
  },
  list_wrap: {
    ...Spacings.padding_top_8,
  },

  list_item_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_16,
    ...Spacings.padding_bottom_14,
    width: "100%",
    overflow: "hidden",
  },
  list_item_info: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  list_item_image: {
    width: 60,
    height: 60,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    ...Colors.border_color_ebebeb,
    ...Spacings.margin_right_12,
    ...Spacings.border_radius_6,
  },
  list_item_info_text: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  list_item_title: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
  },
  list_item_title_text: {
    ...Texts.font_size_14,
    ...Colors.font_222,
    ...Spacings.margin_bottom_4,
    ...Texts.font_weight_500,
    textAlign: 'start',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    lineClamp: '1',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-wrap'
  },
  info_text: {
    ...Colors.font_999,
  },
  dividing_line: {
    height: 12,
    width: 1,
    backgroundColor: "#d9d9d9",
    ...Spacings.margin_right_left_5,
  },
  on_sale_tag_wrap: {
    width: "fit-content",
    backgroundColor: "#F0DFF2",
    borderRadius: 4,
    alignSelf: "flex-start",
    height: 17,
    marginTop: 4,
    marginRight: 4,
    marginBottom: 2,
    ...FlexBoxs.flex,
    flexDirection: "row",
    ...Arranges.center_v_h,
  },
  on_sale_tag_text: {
    color: "#BE69C2",
    fontSize: 10,
    fontWeight: "700",
    letterSpacing: -0.4,
    paddingLeft: 1,
    paddingRight: 6,
    paddingTop: 1,
    paddingBottom: 1,
    textAlign: "right",
  },
  mall_tag: {
    paddingLeft: 6,
    paddingTop: 3,
    paddingBottom: 3,
  },
};
