import React, { Component, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ReactComponent as QuitIcon01 } from '../../../../assets/icons/img_quit_01.svg'
import { ReactComponent as QuitIcon02 } from '../../../../assets/icons/img_quit_02.svg'
import { ReactComponent as QuitIcon03 } from '../../../../assets/icons/img_quit_03.svg'
import dayjs from 'dayjs'
import { getDateFormatKo } from '../../../../utils/datetime'
import AuthStore from '../../../../store/AuthStore'
import { Trans, useTranslation } from 'react-i18next'
import { useTracker } from '../../../../hooks/tracker'
import { TitleHeader } from '../../../../components/common'
import { HeaderItem } from '../../../../constants/Header'
import { WithdrawalMemberInfoRequest } from '../../../../api/member/model'
import { withdrawalMemberInfo } from '../../../../api/member/api'
import { font_222 } from '../../../../assets/styles/colors'
import CommonStore from '../../../../store/CommonStore'
import {
  getStringRemoteConfig,
  remoteConfig,
} from '../../../../utils/remoteConfig'
import { goBack, goTo } from '../../../../hooks/navigate/NavigateFunction'
import clsx from 'clsx'
import classes from './WithdrawAgree.module.scss'
import WithdrawAgreeConfirmModal from '../../../../components/modals/WithdrawAgreeConfirmModal'

const WithdrawAgree = observer(() => {
  const { t } = useTranslation()
  const { sessionUser } = AuthStore
  const [tracker] = useState(useTracker())
  const [
    isVisibleWithdrawAgreConfirmeModal,
    setIsVisibleWithdrawAgreConfirmeModal,
  ] = useState(false)

  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL)
  const ASSET_IMAGE = '/ASSETS/WithdrawAgree.jpg'
  const ASSET_IMAGE_NEW = '/ASSETS/WithdrawAgree_naver.png'

  const withdrawalMemberInfoRequest: WithdrawalMemberInfoRequest = {
    memberUuid: AuthStore.sessionUser?.memberUuid || '',
    calDate: '2023-10-06 23:59:59',
    widthdrawYn: '',
  }

  const getWithdrawDate = () => {
    const now = dayjs()
    return getDateFormatKo(now.add(30, 'days').toString())
  }

  const clickWithdrawButton = () => {
    tracker.track('click_withdraw_button', {})
    void withdrawalMemberInfo({
      ...withdrawalMemberInfoRequest,
      widthdrawYn: 'Y',
    })
    goTo('/withdraw/reason')

    // 충성고객 확인
    // const response = await withdrawalCondition(AuthStore.sessionUser?.memberUuid || "");
    // if (response.successOrNot === "Y" && response.statusCode === "OK" && response.data === true) {
    //   setIsVisibleWithdrawAgreConfirmeModal(true);
    // } else {
    // goTo('/withdraw/reason')
    // }
  }

  return (
    <div className={classes.withdraw}>
      <TitleHeader
        title={t('screen.setting.label.withdraw.memberWithdrawal')}
        isBack={false}
        rightItem={HeaderItem.CLOSE}
        onClickClose={() => {
          goTo('/')
        }}
      />
      {getStringRemoteConfig('show_withdrawagree_gift') === 'true' ? (
        <div
        // showsVerticalScrollIndicator={false}
        >
          <div className={classes.title_withdraw_first_view}>
            <span className={classes.title_withdraw_first_text}>
              <Trans
                components={[
                  <span className={classes.title_withdraw_second_text}></span>,
                ]}
              >
                {t('screen.setting.message.withDrawGift.title')}
              </Trans>
            </span>
          </div>

          <div className={classes.view_img}>
            <img
              src={`${PUBLIC_BUCKET_URL}${ASSET_IMAGE_NEW}`}
              alt=""
              className={classes.img}
            ></img>
          </div>
          <div
            style={{
              marginTop: 6,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span
              style={{
                fontSize: 12,
                fontWeight: 'normal',
                letterSpacing: -0.96,
                color: '#666666',
              }}
            >
              <Trans>
                {t('screen.setting.message.withDrawGift.payDescription')}
              </Trans>
            </span>
          </div>

          <div className={classes.remove_info_list}>
            <div className={classes.remove_info_item}>
              <span className={classes.remove_info_text}>
                <Trans
                  components={[
                    <span className={classes.remove_info_text_bold} />,
                  ]}
                >
                  {t('screen.setting.message.withDrawGift.meetyou')}
                </Trans>
              </span>
            </div>

            <div
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 12,
              }}
            >
              <div>
                <span style={{ fontSize: 18, fontWeight: 'bold', ...font_222 }}>
                  <Trans>
                    {t('screen.setting.message.withDrawGift.visitdayTitle')}
                  </Trans>
                </span>
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    ...font_222,
                    marginTop: 12,
                  }}
                >
                  <Trans>
                    {t('screen.setting.message.withDrawGift.giftdayTitle')}
                  </Trans>
                </span>
              </div>
              <div>
                <span
                  style={{
                    fontWeight: 'normal',
                    ...font_222,
                    fontSize: 18,
                  }}
                >
                  <Trans>
                    {t('screen.setting.message.withDrawGift.visitday')}
                  </Trans>
                </span>
                <span
                  style={{
                    fontWeight: 'normal',
                    ...font_222,
                    fontSize: 18,
                    marginTop: 12,
                  }}
                >
                  <Trans>
                    {t('screen.setting.message.withDrawGift.giftday')}
                  </Trans>
                </span>
              </div>
            </div>
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 6,
              }}
            >
              <span
                style={{
                  width: 244,
                  fontSize: 12,
                  fontWeight: 'normal',
                  letterSpacing: -0.48,
                  marginBottom: 10,
                  color: '#666666',
                  paddingBottom: 5,
                  textAlign: 'center',
                }}
              >
                <Trans>
                  {t('screen.setting.message.withDrawGift.giftexam1')}
                </Trans>
              </span>
            </div>
            <div style={{ justifyContent: 'center', alignItems: 'center' }}>
              <span
                style={{ color: 'red', fontSize: 14, letterSpacing: -0.56 }}
              >
                <Trans>
                  {t('screen.setting.message.withDrawGift.giftexam2')}
                </Trans>
              </span>
            </div>
          </div>

          <div className={clsx(classes.footer, { marginTop: 10 })}>
            <button
              className={clsx(classes.no_withdraw_btn, {
                backgroundColor: '#a760b1',
                width: '70%',
              })}
              onClick={() => {
                void withdrawalMemberInfo({
                  ...withdrawalMemberInfoRequest,
                  widthdrawYn: 'N',
                })
                goTo('/')
                CommonStore.setToastOption({
                  show: true,
                  message1: t(
                    'screen.setting.message.withDrawGift.giftrequestcomplete'
                  ),
                })
              }}
            >
              <span
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  letterSpacing: -0.72,
                  textAlign: 'center',
                  color: '#ffffff',
                }}
              >
                {t('screen.setting.message.withDrawGift.withdrawpositive')}
                &rarr;
              </span>
            </button>
          </div>

          <div className={clsx(classes.footer, { marginBottom: 10 })}>
            <button
              className={classes.withdraw_btn}
              onClick={clickWithdrawButton}
            >
              <span className={classes.withdraw_btn_text}>
                {t('screen.setting.message.withDrawGift.withdrawnegative')}
              </span>
            </button>
          </div>

          <div className={classes.bar} />

          <div className={clsx(classes.note_wrap, { marginBottom: 40 })}>
            <div className={classes.note}>
              <span className={classes.note_title_text}>
                <Trans>
                  {t(
                    'screen.setting.message.withDrawGift.giftinfocautiontitle'
                  )}
                </Trans>
              </span>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans
                    components={[
                      <span className={classes.note_contents_text3} />,
                      <span className={classes.note_contents_text2} />,
                    ]}
                  >
                    {t('screen.setting.message.withDrawGift.giftinfocaution1')}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div style={{ marginLeft: 8 }} />
                <span className={classes.note_contents_text1}>
                  <Trans components={[<span style={{ color: '#be69c3' }} />]}>
                    {t('screen.setting.message.withDrawGift.giftinfocaution2')}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans>
                    {t('screen.setting.message.withDrawGift.giftinfocaution3')}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans
                    components={[
                      <span className={classes.note_contents_text2} />,
                    ]}
                  >
                    {t('screen.setting.message.withDrawGift.giftinfocaution4')}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans
                    components={[
                      <span className={classes.note_contents_text2} />,
                    ]}
                  >
                    {t('screen.setting.message.withDrawGift.giftinfocaution5')}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans
                    components={[
                      <span className={classes.note_contents_text2} />,
                    ]}
                  >
                    {t('screen.setting.message.withDrawGift.giftinfocaution6')}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans>
                    {t('screen.setting.message.withDrawGift.giftinfocaution7')}
                  </Trans>
                </span>
              </div>
            </div>

            <div className={classes.note_bar} />

            <div className={classes.note}>
              <span className={classes.note_title_text}>
                {t('screen.setting.message.withDrawlAgree.cautionTitle')}
              </span>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans
                    components={[
                      <span className={classes.note_contents_text2} />,
                      <span className={classes.note_contents_text3} />,
                    ]}
                  >
                    {t('screen.setting.message.withDrawlAgree.caution1', {
                      withdrawDate: getWithdrawDate(),
                    })}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  {t('screen.setting.message.withDrawlAgree.caution6')}
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans
                    components={[
                      <span className={classes.note_contents_text3} />,
                    ]}
                  >
                    {t('screen.setting.message.withDrawlAgree.caution7', {
                      withdrawDate: getWithdrawDate(),
                    })}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  {t('screen.setting.message.withDrawlAgree.caution12')}
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  {t('screen.setting.message.withDrawlAgree.caution13')}
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  {t('screen.setting.message.withDrawlAgree.caution14')}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
        // showsVerticalScrollIndicator={false}
        >
          <div className={classes.title_wrap}>
          <div
              className={classes.title_text3}
              dangerouslySetInnerHTML={{
                __html: t("screen.setting.message.withDrawlAgree.infoTitle", {
                  nickName: sessionUser?.nickname,
                }),
              }}
            />
          </div>
          <div className={classes.remove_info_list}>
            <div className={classes.remove_info_item}>
              <QuitIcon01 className={classes.icon} />
              <span className={classes.remove_info_text}>
                {t('screen.setting.message.withDrawlAgree.info1')}
              </span>
            </div>
            <div className={classes.remove_info_item}>
              <QuitIcon02 className={classes.icon} />
              <span className={classes.remove_info_text}>
                {t('screen.setting.message.withDrawlAgree.info2')}
              </span>
              <span className={classes.remove_info_text}>
                {t('screen.setting.message.withDrawlAgree.info3')}
              </span>
            </div>
            <div className={classes.remove_info_item}>
              <QuitIcon03 className={classes.icon} />
              <span className={classes.remove_info_text}>
                {t('screen.setting.message.withDrawlAgree.info4')}
              </span>
              <span className={classes.remove_info_text}>
                {t('screen.setting.message.withDrawlAgree.info5')}
              </span>
            </div>
          </div>
          <div className={clsx(classes.note_wrap, { marginBottom: 40 })}>
            <div className={classes.note}>
              <span className={classes.note_title_text}>
                <Trans>
                  {t('screen.setting.message.withDrawlAgree.cautionTitle')}
                </Trans>
              </span>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans
                    components={[
                      <span className={classes.note_contents_text2} />,
                      <span className={classes.note_contents_text3} />,
                    ]}
                  >
                    {t('screen.setting.message.withDrawlAgree.caution1', {
                      withdrawDate: getWithdrawDate(),
                    })}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans>
                    {t('screen.setting.message.withDrawlAgree.caution6')}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  <Trans
                    components={[
                      <span className={classes.note_contents_text3} />,
                    ]}
                  >
                    {t('screen.setting.message.withDrawlAgree.caution7', {
                      withdrawDate: getWithdrawDate(),
                    })}
                  </Trans>
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  {t('screen.setting.message.withDrawlAgree.caution12')}
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  {t('screen.setting.message.withDrawlAgree.caution13')}
                </span>
              </div>
              <div className={classes.note_contents}>
                <div className={classes.dot} />
                <span className={classes.note_contents_text1}>
                  {t('screen.setting.message.withDrawlAgree.caution14')}
                </span>
              </div>
            </div>
          </div>

          <div className={classes.bar} />

          <div className={classes.footer}>
            <span className={classes.footer_text}>
              {t('screen.setting.message.withDrawlAgree.sure')}
            </span>
            <button
              className={classes.withdraw_btn}
              onClick={clickWithdrawButton}
            >
              <span className={classes.withdraw_btn_text}>
                {t('screen.setting.message.withDrawlAgree.yesWithdrawal')}
              </span>
            </button>
            <button
              className={classes.no_withdraw_btn}
              onClick={() => {
                goTo('/')
              }}
            >
              <span className={classes.no_withdraw_btn_text}>
                {t('screen.setting.message.withDrawlAgree.noWithdrawal')}
              </span>
            </button>
            <div style={{ height: 52 }} />
          </div>
        </div>
      )}

      <WithdrawAgreeConfirmModal
        isVisible={isVisibleWithdrawAgreConfirmeModal}
        setIsVisible={setIsVisibleWithdrawAgreConfirmeModal}
        onAgreeBtnPress={() => {
          goTo('/')
        }}
        onDisagreeBtnPress={clickWithdrawButton}
      />
    </div>
  )
})

export default WithdrawAgree
