import { SuccessOrNot } from "../../constants/Common.enum";
import {
  MISSION_RESULT_COUNT,
  MISSION_TYPE_PATH,
} from "../../constants/mission";

import {
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
  callAPIWithPUTMethod,
} from "../common/api";
import CommonResponse from "../http";
import {
  MissionCommentListRequestResult,
  MissionParticipateRequest,
  MissionPostResponse,
  MissionShareRequest,
  ParticipantInfo,
} from "./model";

export const getMissionList = async (
  missionTypePath: string,
  pageSize: number,
  pageIndex: number,
  isLoading = true
): Promise<CommonResponse> => {
  
  let baseUrl = `/v1/missions`;
  if (missionTypePath === MISSION_TYPE_PATH.PARTICIPATE) {
    baseUrl += `/participate`;
  }
  return await callAPIWithGETMethod(
    {
      url: `${baseUrl}?&pageSize=${pageSize}&pageIndex=${pageIndex}&missionTypePath=${missionTypePath}`,
    },
    isLoading
  );
};

export const getMissionDetail = async (
  missionId: number,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: `/v1/missions/${missionId}`,
    },
    isLoading
  );
};

/** 당첨자 여부 조회 */
export const getMissionWinnerInfo = async (
  missionId: number,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: `/v1/missions/result/${missionId}`,
    },
    isLoading
  );
};

/** 미션 참여 */
export const postMissionParticipate = async (
  missionId: number,
  missionParticipateRequest: MissionParticipateRequest,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: `/v1/missions/${missionId}`,
      body: {
        missionTypeCode: missionParticipateRequest.missionTypeCode,
        missionTargetId: missionParticipateRequest.missionTargetId,
        participantInfo: {
          participantName:
            missionParticipateRequest.participantInfo?.participantName,
          participantPhoneNumber:
            missionParticipateRequest.participantInfo?.participantPhoneNumber,
          participantAddress:
            missionParticipateRequest.participantInfo?.participantAddress,
          participantAddressDetail:
            missionParticipateRequest.participantInfo?.participantAddressDetail,
          infoAgreeYn: missionParticipateRequest.participantInfo?.infoAgreeYn,
          provideToThirdAgreeYn:
            missionParticipateRequest.participantInfo?.provideToThirdAgreeYn,
        },
      },
    },
    isLoading
  );
};

/** 응모자 정보 수정 */
export const putParticipantInfo = async (
  missionId: number,
  participantInfo: ParticipantInfo,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPUTMethod(
    {
      url: `/v1/missions/participant/${missionId}`,
      body: participantInfo,
    },
    isLoading
  );
};

export const getMissionPost = async (
  missionId: number,
  cursor: string | null,
  viewType?: string,
  pageSize: number = MISSION_RESULT_COUNT,
  isLoading = true
): Promise<MissionPostResponse> => {
  let params: any = { pageSize };

  if (cursor) {
    params = { ...params, cursor };
  }

  if (viewType) {
    params = { ...params, viewType };
  }

  const response = await callAPIWithGETMethod(
    {
      url: `/v1/missions/postByCursor/${missionId}`,
      params,
    },
    isLoading
  );
  return (
    response?.successOrNot === SuccessOrNot.Y ? response?.data : null
  ) as MissionPostResponse;
};

export const getMisionCommentList = async (
  missionId: number,
  pageSize: number,
  pageIndex: number,
  isLoading = true
): Promise<MissionCommentListRequestResult> => {
  const response = await callAPIWithGETMethod<CommonResponse>(
    {
      url: `/v1/missions/comment/${missionId}?pageSize=${pageSize}&pageIndex=${pageIndex}`,
    },
    isLoading
  );
  return (
    response?.successOrNot === "Y" && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as MissionCommentListRequestResult;
};

export const getMisionCommentCursorList = async (
  missionId: number,
  pageSize: number,
  cursor?: number,
  isLoading = true
): Promise<MissionCommentListRequestResult> => {
  const response = await callAPIWithGETMethod<CommonResponse>(
    {
      url: `/v1/missions/commentCursor/${missionId}`,
      params: { pageSize, cursor },
    },
    isLoading
  );
  return (
    response?.successOrNot === "Y" && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as MissionCommentListRequestResult;
};

export const deleteMissionParticipant = async (
  data: MissionParticipateRequest,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithDELETEMethod(
    {
      url: `v1/missions/participation`,
      body: data,
    },
    isLoading
  );
};

export const updateShareCount = async (
  data: MissionShareRequest,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: `v1/missions/share/count`,
      body: data,
    },
    isLoading
  );
};

export const recommenderExist = async (
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: `v1/missions/recommender/exist`,
    },
    isLoading
  );
};

export const recommenderValid = async (
  recommenderNickname: string,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: `v1/missions/recommender/valid`,
      params: {
        recommenderNickname,
      },
    },
    isLoading
  );
};

export const recommenderSave = async (
  recommenderNickname: string,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: `v1/missions/recommender`,
      body: {
        recommenderNickname,
      },
    },
    isLoading
  );
};

export const getStampMissions = async (
  missionId: number,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: `/v1/missions/stamp/${missionId}`,
    },
    isLoading
  );
};

// 뽑기미션 참여 가능 여부 체크
export const getRandomCouponStatus = async (
  missionId: number,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: `v1/random-coupon/status?missionId=${missionId}`,
    },
    isLoading
  );
};

// 뽑기미션 쿠폰 발급 내역 조회
export const getRandomCouponMember = async (
  missionId: number,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: `v1/random-coupon/member?missionId=${missionId}`,
    },
    isLoading
  );
};

// 뽑기미션 참여(쿠폰발급)
export const randomCouponDraw = async (
  missionId: number,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: `v1/random-coupon/draw`,
      body: {
        missionId,
      },
    },
    isLoading
  );
};
