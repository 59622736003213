import SearchStore from '../store/SearchStore'

export const setSearchTextInStorage = async (
  latestText: string
): Promise<boolean> => {
  return updateSearchTextFromStorage(latestText)
}

export const removeSearchTextFromStorage = async (
  latestText: string
): Promise<boolean> => {
  return updateSearchTextFromStorage(latestText, true)
}

export const getSearchTextFromStorage =
  async (): Promise<SearchText | null> => {
    try {
      const searchText = window.localStorage.getItem('search_text')
      // const searchText = await EncryptedStorage.getItem("search_text");
      if (searchText) {
        return JSON.parse(searchText) as SearchText
      } else {
        return null
      }
    } catch (error) {
      return null
      // There was an error on the native side
    }
  }

export const removeAllSearchTextFromStorage = async (): Promise<boolean> => {
  try {
    window.localStorage.removeItem('search_text')
    // await EncryptedStorage.removeItem("search_text");
    SearchStore.setLastestSearchText(null)
    return true
  } catch (error) {
    return false
    // There was an error on the native side
  }
}

const updateSearchTextFromStorage = async (
  latestText: string,
  isDelete = false
): Promise<boolean> => {
  try {
    const savedText =
      SearchStore.latestSearchTextList?.text.filter(
        (text: string) => text !== latestText
      ) || []

    if (!isDelete) {
      savedText.length === 6 && savedText.shift()
      savedText.push(latestText)
    }

    const searchText: SearchText = {
      text: savedText,
    }

    window.localStorage.setItem('search_text', JSON.stringify(searchText))
    // await EncryptedStorage.setItem('search_text', JSON.stringify(searchText))
    SearchStore.setLastestSearchText(searchText)
    return true
  } catch (error) {
    return false
  }
}

export interface SearchText {
  text: string[]
}
