import { useEffect, useRef, useState } from "react";
import NaverIcon from "../../../assets/icons/btn_sns_login_Naver.svg";
import GoogleIcon from "../../../assets/icons/btn_sns_login_google.svg";
import AppleIcon from "../../../assets/icons/icon_login_apple.svg";
import KakaoIcon from "../../../assets/icons/icon_login_kakao.svg";
import { useTranslation } from "react-i18next";
import { SignUpCode } from "../../../constants/SignUp.enum";
import {
  getTempMemberFromStorage,
  removeTempMemberFromStorage,
} from "../../../service/Preference.service";
import AuthStore from "../../../store/AuthStore";
import SocialMemberStore from "../../../store/SocialMemberStore";
import { LAST_LOGIN_TYPE } from "../../../constants/Storage";
import { SocialTypeCode } from "../../../constants/Common.enum";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import { observer } from "mobx-react";
import classes from "./LoginAttractMain.module.scss";
import { Platform, PlatformTypes } from "../../../native/platform";
import { track } from "../../../hooks/tracker/TrackFunction";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const LoginAttractMain = observer(
  ({ hideLookingAroundBtn }: { hideLookingAroundBtn: boolean }) => {
    const {
      signInWithApple,
      signInWithGoogle,
      signInWithNaver,
      signInWithKakao,
    } = SocialMemberStore;
    const [lastLoginType, setLastLoginType] = useState<SocialTypeCode>();
    const { t } = useTranslation();
    const ref = useRef<any>();

    useEffect(() => {
      track("view_signup_page", {
        is_modal: false,
        source_component: "LoginAttractScreen",
      });
    }, []);

    const clickSignUpBtn = (sort: string) => {
      switch (sort) {
        case SignUpCode.NAVER:
          void signInWithNaver();
          break;
        case SignUpCode.GOOGLE:
          void signInWithGoogle();
          break;
        case SignUpCode.KAKAO:
          void signInWithKakao();
          break;
        case SignUpCode.APPLE:
          void signInWithApple();
          break;
        case SignUpCode.EMAIL:
          goTo("/JoinWithEmailScreen");
          break;
      }

      track("click_signup", {
        method: sort,
        component: "LoginAttractScreen",
      });
    };

    const clickLookingAroundBtn = async () => {
      AuthStore.clear();
      const tempMember = await getTempMemberFromStorage();

      if (!tempMember?.nickname) {
        goTo("/PreferenceScreen");
      } else if (
        !tempMember?.profileUrl &&
        !tempMember?.profileImageFromGallary
      ) {
        goTo("/ProfileSetting");
      } else {
        await removeTempMemberFromStorage();
        goTo("/PreferenceScreen");
      }

      track("skip_signup", {
        component: "LoginAttractScreen",
      });
    };

    const clickEmailSignInBtn = () => {
      goTo("/LoginPresenter");
    };

    const getAndSetLastLoginType = async () => {
      setLastLoginType(
        (await localStorage.getItem(LAST_LOGIN_TYPE)) as SocialTypeCode
      );
    };

    useEffectOnlyOnce(() => {
      void getAndSetLastLoginType();
    });

    const renderKakaoJoin = () => {
      return (
        <div className={classes.kakao_join_popover}>
          <div className={classes.signupStyle_kakao_join_popover_inner}>
            <div className={classes.signupStyle_triangle} />
            <p className={classes.signupStyle_kakao_join_popover_text1}>
              {t("screen.loginAttract.label.kakao_popover1")}
              <span className={classes.signupStyle_kakao_join_popover_text2}>
                {t("screen.loginAttract.label.kakao_popover2")}
              </span>
            </p>
          </div>
        </div>
      );
    };

    return (
      <div className={classes.attract}>
        <div className={classes.main}>
          {hideLookingAroundBtn ? (
            <div className={classes.service}></div>
          ) : (
            <div
              className={classes.service}
              aria-label="take a look inside"
              onClick={() => {
                void clickLookingAroundBtn();
              }}
            >
              <p className={classes.service_text}>
                {t("screen.loginAttract.label.around")}
              </p>
            </div>
          )}

          <div className={classes.logo_wrap} aria-label="attract login">
            <div className={classes.signupStyle_logo_text1}>
              {t("screen.loginAttract.label.contentsBox1Alt")}
            </div>
            <div className={classes.signupStyle_logo_text1}>
              {t("screen.loginAttract.label.contentsBox2Alt")}
            </div>

            <div className={classes.signupStyle_logo_wrap}>
              <p className={classes.signupStyle_logo_text2}>
                {t("screen.loginAttract.label.contentsBox3Alt")}
              </p>
              <p className={classes.signupStyle_logo_text2}>
                <span className={classes.logo_text2}>
                  {t("screen.loginAttract.label.contentsBox4Alt")}
                </span>
                {t("screen.loginAttract.label.contentsBox5Alt")}
              </p>
            </div>

            {lastLoginType && (
              <div className={classes.last_login_info}>
                <p className={classes.last_login_info_txt}>
                  {t(`common.social.${lastLoginType}`)}
                  {t("screen.loginAttract.label.lastLogin")}
                </p>
              </div>
            )}
          </div>
          <div className={classes.login_wrap}>
            {renderKakaoJoin()}
            <div
              className={classes.kakao}
              onClick={() => {
                clickSignUpBtn(SignUpCode.KAKAO);
              }}
              aria-label="sign up with kakao"
              ref={ref}
            >
              <img src={KakaoIcon} alt="Kakao Login" />
              <p className={classes.txt_btn}>
                {t("screen.loginAttract.label.kakao")}
              </p>
            </div>
            {Platform.OS === PlatformTypes.IOS_APP && (
              <div
                className={classes.apple}
                // onClick={() => clickSignUpBtn(SignUpCode.APPLE)}
                aria-label="sign up with apple"
                ref={ref}
              >
                <img src={AppleIcon} alt="Apple Login" />
                <p className={classes.txt_btn_white}>
                  {t("screen.loginAttract.label.apple")}
                </p>
              </div>
            )}
            <div className={classes.join_subscription}>
              <span className={classes.join_subscription_txt}>
                {t("screen.loginAttract.label.joinSbscription")}
              </span>
            </div>
            <div className={classes.sns}>
              <div
                className={classes.sns_img}
                onClick={() => clickSignUpBtn(SignUpCode.NAVER)}
                aria-label="sign up with naver"
              >
                <img src={NaverIcon} alt="Naver Login" />
              </div>
              <div
                className={classes.sns_img}
                onClick={() => clickSignUpBtn(SignUpCode.GOOGLE)}
                aria-label="sign up with google"
              >
                <img src={GoogleIcon} alt="Google Login" />
              </div>
            </div>
            <div className={classes.bottom_area}>
              <div className={classes.bottom_left}>
                <div
                  onClick={() => clickEmailSignInBtn()}
                  aria-label="sign in with email"
                >
                  <span className={classes.txt_1}>
                    {t("screen.loginAttract.label.bottomArea2")}
                  </span>
                </div>
              </div>
              <div className={classes.bottom_area_line} />
              <div className={classes.bottom_right}>
                <div
                  onClick={() => clickSignUpBtn(SignUpCode.EMAIL)}
                  aria-label="sign up with email"
                >
                  <span className={classes.txt_1}>
                    {t("screen.loginAttract.label.bottomArea1")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.problem_wrap}>
          <button
            className={classes.problem_btn}
            onClick={() => {
              goTo("/FAQ", { state: { isFromLoginAttraction: true } });
            }}
          >
            <span className={classes.problem_btn_text}>
              {t("screen.loginAttract.label.problem")}
            </span>
          </button>
        </div>
      </div>
    );
  }
);

export default LoginAttractMain;
