import { action, computed, makeObservable, observable } from "mobx";

export const PAGE_SIZE = 20;

class MallStore {
  constructor() {
    makeObservable(this);
  }

  @observable _isMallCateforyFilterReset = false;

  @computed get isMallCateforyFilterReset() {
    return this._isMallCateforyFilterReset;
  }
  @action setIsMallCateforyFilterReset(x: boolean) {
    this._isMallCateforyFilterReset = x;
  }
}

export default new MallStore();
