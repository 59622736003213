import React from "react";
import "./QnaDetailProfile.scss";

import { getDateStr } from "../../../utils/datetime";
import { observer } from "mobx-react";
// import CommentStore from "../../../store/CommentStore";
import { useTranslation } from "react-i18next";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Qna } from "../../../api/qna/model";
import QnaDetailStore from "../../../store/QnaDetailStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";

const QnaDetailProfile = observer(
  ({
    feedId,
    qnaDetailStore,
  }: {
    feedId: number;
    qnaDetailStore: QnaDetailStore;
  }) => {
    const { t } = useTranslation();

    const { qnaDetail } = qnaDetailStore;

    const PUBLIC_PROFILE_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    return (
      <div id="qna_detail_profile">
        <div
          className="user_qna_detail_header"
          aria-label={"Qna detail profile"}
        >
          <TouchableWithAuthCheck
            className="user_qna_detail_header_left"
            onPress={() => {
              goTo(`/user/${qnaDetail.nickname}`, {
                state: {
                  targetUserId: qnaDetail.createMemberUuid,
                  nickname: qnaDetail.nickname,
                  profileUrl: qnaDetail.profileUrl,
                  profileBackgroundColor: qnaDetail.profileBackgroundColor,
                },
              });
            }}
            aria-label={"Qna detail profile image and nickname"}
          >
            {qnaDetail.profileUrl != "" && (
              <FastImageWithFallback
                source={{
                  uri: `${PUBLIC_PROFILE_BUCKET_URL}${
                    qnaDetail.profileUrl ?? ""
                  }`,
                }}
                className="user_img"
              />
            )}
            <div className="info_wrap">
              <p className="info_text1">{qnaDetail.nickname}</p>
              <div className="info_inner_wrap">
                <p className="info_text2">
                  {getDateStr(qnaDetail.createdDatetime)}
                </p>
                <div className="text_bar" />
                <p className="info_text2">
                  {t(`screen.qna.label.attend`)} {qnaDetail.commentCount}
                </p>
              </div>
            </div>
          </TouchableWithAuthCheck>
        </div>
      </div>
    );
  }
);

export default QnaDetailProfile;
