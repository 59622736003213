import React, { useState } from "react";

import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { useTranslation } from "react-i18next";
import { LikeListItem } from "../../../api/like/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { observer } from "mobx-react";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import AuthStore from "../../../store/AuthStore";
import LikeStore from "../../../store/LikeStore";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { UserActTypeCode } from "../../../constants/FeedType.enum";
import FollowerStore from "../../../store/FollowerStore";
import { follow, unfollow } from "../../../api/follow/api";
import { Styles } from "../../../assets/types/Style";
import classes from "./LikeItem.module.scss";
import clsx from "clsx";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

export interface LikeItemProps {
  likeItem: LikeListItem;
  index: number;
}
const LikeItem = observer(({ likeItem, index }: LikeItemProps) => {
  const { clear } = LikeStore;

  const { t } = useTranslation();
  const { sessionUser } = AuthStore;
  const [isFollow, setIsFollow] = useState<boolean>(
    likeItem.myMemberFollowing === "Y" ? true : false
  );
  const isMe = sessionUser?.memberUuid === likeItem.memberUuid ? true : false;
  const { setToastOption } = CommonStore;

  const onToast = (follow: boolean) => {
    setToastOption({
      show: true,
      message1: follow
        ? t("screen.like.message.followToast", { nickname: likeItem.nickname })
        : t("screen.like.message.unfollowToast", {
            nickname: likeItem.nickname,
          }),
      // bottomOffset: 30,
    });
  };

  const unFollowUser = async () => {
    //PPIT210117-4818 UNFOLLOW 시 actHistory 처리 추가
    CommonStore.callActHistory(
      ActHistoryTypeCode.USER_FOLLOWING,
      likeItem.memberUuid,
      ActPageCode.FOLLOWING_MANAGEMENT,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      "CANCEL"
    );
    const res = await unfollow(likeItem.memberUuid);
    FollowerStore.handlePostFollowUpdate(
      UserActTypeCode.UN_FOLLOW,
      likeItem.memberUuid
    );
    if (res) {
      setIsFollow(false);
      onToast(false);
    }
  };

  const followUser = async () => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.USER_FOLLOWING,
      likeItem.memberUuid,
      ActPageCode.FOLLOWING_MANAGEMENT
    );
    const res = await follow(likeItem.memberUuid);
    FollowerStore.handlePostFollowUpdate(
      UserActTypeCode.FOLLOW,
      likeItem.memberUuid
    );
    if (res) {
      setIsFollow(true);
      onToast(true);
      // TODO: 네이티브 연동시에는 필요함
      // void BadgeStore.obtainBadge(BadgeCode.FOLLOW)
    }
  };

  const onUserButtonPress = () => {
    goTo(`/user/${likeItem.nickname}`, {
      state: {
        targetUserId: likeItem.memberUuid,
        nickname: likeItem.nickname,
        profileUrl: likeItem.profileUrl,
        profileBackgroundColor: likeItem.profileBackgroundColor,
      },
    });
  };

  const renderFollowButton = () => {
    return isMe ? (
      <></>
    ) : isFollow ? (
      <TouchableWithAuthCheck
        className={clsx(classes.following_btn, classes.btn)}
        onPress={(event) => {
          event.stopPropagation();
          void unFollowUser();
        }}
        // authCheckProps={{ enabled: true }}
      >
        <span
          className={clsx(classes.following_btn_text, classes.btn_text)}
          aria-label="Followed"
        >
          {t("screen.like.button.follwing")}
        </span>
      </TouchableWithAuthCheck>
    ) : (
      <TouchableWithAuthCheck
        className={clsx(classes.follower_btn, classes.btn)}
        onPress={(event) => {
          event.stopPropagation();
          void followUser();
        }}
        // authCheckProps={{ enabled: true }}
      >
        <span
          className={clsx(classes.follower_btn_text, classes.btn_text)}
          aria-label="Follow"
        >
          {t("screen.like.button.follow")}
        </span>
      </TouchableWithAuthCheck>
    );
  };

  return (
    <div className={classes.like_item}>
      <TouchableWithAuthCheck
        className={classes.like}
        onPress={() => {
          void onUserButtonPress();
        }}
      >
        <FastImageWithFallback
          aria-label="Profile Image"
          source={{
            uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${likeItem.profileUrl}`,
          }}
          wrapperStyle={{ display: "flex" }}
          className={classes.image}
        />
        <div className={classes.info_wrap}>
          <text className={classes.id_text} aria-label={`Nickname${index}`}>
            {likeItem.nickname}
          </text>
          <text
            className={classes.tag_text}
            // numberOfLines={1}
          >
            {likeItem.interestedTags || " "}
          </text>
        </div>
      </TouchableWithAuthCheck>
      {renderFollowButton()}
    </div>
  );
});

export default LikeItem;
