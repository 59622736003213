import { observer } from 'mobx-react'
import MallCategoryForm from './presenter/MallCategoryForm'
import { useParams } from 'react-router-dom'

const LargeCategoryScreen = observer(() => {
  const { categoryId } = useParams()

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        backgroundColor: '#fff',
      }}
      aria-label="Mall category screen"
    >
      <MallCategoryForm caregoryId={parseInt(categoryId || '0')} />
    </div>
  )
})

export default LargeCategoryScreen
