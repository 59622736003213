import React from "react";
import { observer } from "mobx-react";
import { Arranges, FlexBoxs, Spacings, Texts } from "../../../assets/styles";
import { getDateStr } from "../../../utils/datetime";
import PostDetailStore from "../../../store/PostDetailStore";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Styles } from "../../../assets/types/Style";
import "./PostUserInfo.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";

const PostUserInfo = observer((data: any) => {
  const { postDetailResult } = PostDetailStore;
  const {
    createMemberUuid,
    nickname,
    profileUrl,
    createdDatetime,
    profileBackgroundColor,
  } = postDetailResult;

  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

  return (
    <div className="post-user-info-wrapper">
      <TouchableWithAuthCheck
        // style={styles.post_header_left}
        className="post-user-info-left"
        onPress={() => {
          goTo(`/user/${nickname}`, {
            state: {
              targetUserId: createMemberUuid,
              nickname: nickname,
              profileUrl: profileUrl,
              profileBackgroundColor: profileBackgroundColor,
            },
          });
        }}
      >
        <FastImageWithFallback
          style={{ display: "flex" }}
          className="user-img"
          source={{ uri: `${PUBLIC_BUCKET_URL}${profileUrl || ""}` }}
        />
        <div className="info-wrap">
          <span className="info-text1">{nickname}</span>
          <span className="info-text2">{getDateStr(createdDatetime)}</span>
        </div>
      </TouchableWithAuthCheck>
    </div>
  );
});

export default PostUserInfo;
