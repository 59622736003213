import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as PeoplePurpleIcon } from "../../../assets/icons/people_purple.svg";
import { ReactComponent as PeopleGrayIcon } from "../../../assets/icons/people_gray.svg";
import { AbTypeCode } from "../../../constants/ab";
import "./AbPercentBar.scss";
import AbDetailStore from "../../../store/AbDetailStore";

interface AbPercentBarProps {
  aVoteCount: number;
  bVoteCount: number;
  myChoice: AbTypeCode | null;
  optionToBeColored: AbTypeCode;
}
const AbPercentBar = ({
  aVoteCount,
  bVoteCount,
  myChoice,
  optionToBeColored,
}: AbPercentBarProps) => {
  const SHOW_LABEL_THRESHOLD = 0.5;
  const ANIMATION_DURATION = 250;

  const [animValue, setAnimValue] = useState<number>(0);
  // const growthAnim = useRef(new Animated.Value(0)).current;

  const showLabel = animValue > SHOW_LABEL_THRESHOLD;
  const colorOptionA = optionToBeColored === AbTypeCode.A;
  const totalCount = aVoteCount + bVoteCount;
  const percentOfA = totalCount > 0 ? (aVoteCount / totalCount) * 100 : 50;

  // useEffect(() => {
  //   growthAnim.addListener((state) => {
  //     setAnimValue(state.value);
  //   });
  //   Animated.timing(growthAnim, {
  //     toValue: 1,
  //     duration: ANIMATION_DURATION,
  //     useNativeDriver: false,
  //   }).start();
  // }, [growthAnim]);

  // const chooseAAnimation = {
  //   aWidth: growthAnim.interpolate({
  //     inputRange: [0, 1],
  //     outputRange: ["0%", `${percentOfA}%`],
  //   }),
  //   bWidth: growthAnim.interpolate({
  //     inputRange: [0, 1],
  //     outputRange: ["100%", `${100 - percentOfA}%`],
  //   }),
  // };

  // const chooseBAnimation = {
  //   aWidth: growthAnim.interpolate({
  //     inputRange: [0, 1],
  //     outputRange: ["100%", `${percentOfA}%`],
  //   }),
  //   bWidth: growthAnim.interpolate({
  //     inputRange: [0, 1],
  //     outputRange: ["0%", `${100 - percentOfA}%`],
  //   }),
  // };

  return (
    <div aria-label={`Ab percent bar`} className="result_wrap">
      <div className="result">
        <div
          className="result_left"
          style={{
            backgroundColor: colorOptionA ? "#f0dff2" : "#f5f5f5",
            width: `${percentOfA}%`,
            position: "relative",
          }}
        >
          <p
            aria-label={`percent of A`}
            style={{ position: "absolute", left: 10, top: 5 }}
            className={
              colorOptionA ? "result_left_text_win" : "result_left_text_lose"
            }
          >{`${parseFloat(percentOfA.toFixed(1))}%`}</p>
        </div>

        <div
          className="result_right"
          style={{
            backgroundColor: colorOptionA ? "#f5f5f5" : "#f0dff2",
            width: `${100 - percentOfA}%`,
            position: "relative",
          }}
        >
          <p
            aria-label={`percent of B`}
            style={{ position: "absolute", right: 10, top: 5 }}
            className={
              colorOptionA ? "result_right_text_lose" : "result_right_text_win"
            }
          >{`${parseFloat((100 - percentOfA).toFixed(1))}%`}</p>
        </div>
      </div>
      <div className="result_count">
        <div className="result_count_left">
          {colorOptionA ? (
            <>
              <PeoplePurpleIcon className="result_count_right_icon" />
              <p
                aria-label={`vote count of A`}
                className="result_count_win_text"
              >
                {aVoteCount}
              </p>
            </>
          ) : (
            <>
              <PeopleGrayIcon className="result_count_right_icon" />
              <p
                aria-label={`vote count of A`}
                className="result_count_lose_text"
              >
                {aVoteCount}
              </p>
            </>
          )}
        </div>
        <div className="result_count_right">
          {colorOptionA ? (
            <>
              <p
                aria-label={`vote count of B`}
                className="result_count_lose_text"
              >
                {bVoteCount}
              </p>
              <PeopleGrayIcon className="result_count_left_icon" />
            </>
          ) : (
            <>
              <p
                aria-label={`vote count of B`}
                className="result_count_win_text"
              >
                {bVoteCount}
              </p>
              <PeoplePurpleIcon className="result_count_left_icon" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AbPercentBar;
