import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Styles } from "../../../assets/types/Style";
import { ReactComponent as HeartOffIcon } from "../../../assets/icons/heart_off.svg";
import { ReactComponent as HeartOnIcon } from "../../../assets/icons/heart_on.svg";
import { ReactComponent as BookmarkOffIcon } from "../../../assets/icons/bookmark_off.svg";
import { ReactComponent as BookmarkOnIcon } from "../../../assets/icons/bookmark_on.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";

import QnaDetailStore from "../../../store/QnaDetailStore";
import LikeStore from "../../../store/LikeStore";
// import BookmarkStore from "../../../store/BookmarkStore";
import { useTranslation } from "react-i18next";
import { CommentTypeCode } from "../../../constants/Comment";
// import CommentStore from "../../../store/CommentStore";
import { LikeTypeCode } from "../../../constants/Like";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { ActHistoryRequest } from "../../../api/data/model";
import { sendActHistory } from "../../../api/data/api";
// import { onShare, onShareWithBranch, ShareInfo } from "../../../utils/share";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
import { FeedTypeCode } from "../../../constants/Feed";
// import { useTracker } from "../../../hooks/tracker";
import { useLoaderData, useLocation } from "react-router-dom";
import CommentStore from "../../../store/CommentStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { ShareInfo, onShare } from "../../../utils/share";
import { useTracker } from "../../../hooks/tracker";
import BookmarkStore from "../../../store/BookmarkStore";

const QnaDetailFooter = observer(
  ({
    selfWidth,
    feedId,
    qnaDetailStore,
    qnaListIndex,
  }: {
    selfWidth: number;
    feedId: number;
    qnaDetailStore: QnaDetailStore;
    qnaListIndex: number;
  }) => {
    const { t } = useTranslation();

    // TODO : 복구
    // const insets = useSafeAreaInsets();
    const { track } = useTracker();
    // const config = useRemoteConfig();
    const { pathname } = useLocation();

    const { saveBookmark, cancelBookmark } = BookmarkStore;
    const { saveLike, cancelLike } = LikeStore;
    const { qnaDetail, cancelQnaLike, saveQnaLike, setBookmarkYn } =
      qnaDetailStore;

    //PPIT210117-4818 LIKE 취소 시 attr6 CANCEL 반영
    const callActHistoryByHistoryType = (
      historyType: string,
      attr6?: string
    ) => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: historyType,
        actHistoryTargetId: qnaDetail.feedId.toString(),
        actPageCode: ActPageCode.QNA_DETAIL,
        actSectionCode: undefined,
        attr1: (qnaListIndex ?? 0).toString(),
        attr2: (qnaDetail.likeCount ?? 0).toString(),
        attr3: (qnaDetail.commentCount ?? 0).toString(),
      };
      if (attr6) {
        actHistory.attr6 = attr6;
      }
      void sendActHistory(actHistory);
    };

    const onLikeButtonPress = async () => {
      if (qnaDetail.likeYn === "Y") {
        await cancelLike(
          qnaDetail.feedId,
          "QNA",
          () => {
            //PPIT210117-4818 LIKE 취소 시 actHistory 처리 추가
            callActHistoryByHistoryType(ActHistoryTypeCode.LIKE, "CANCEL");
            cancelQnaLike();
          },
          () => {
            // fail
          }
        );
      } else {
        await saveLike(
          qnaDetail.feedId,
          "QNA",
          () => {
            callActHistoryByHistoryType(ActHistoryTypeCode.LIKE);
            track("click_like_button", {
              content_id: feedId,
              content_title: qnaDetail.feedTitle,
              content_type: FeedTypeCode.QNA,
              like_count: qnaDetail.likeCount ? qnaDetail.likeCount : 0,
              comment_count: qnaDetail.commentCount
                ? qnaDetail.commentCount
                : 0,
            });
            saveQnaLike();
          },
          () => {
            // fail
          }
        );
      }
    };

    const onBookmarkButtonPress = async () => {
      // TODO : onBookmarkButtonPress 기능 복구
      if (qnaDetail.bookmarkYn == "Y") {
        await cancelBookmark(
          feedId,
          "QNA",
          () => {
            setBookmarkYn("N");
          },
          () => {
            // fail
          }
        );
      } else {
        await saveBookmark(
          feedId,
          "QNA",
          () => {
            setBookmarkYn("Y");
            track("click_bookmark", {
              content_id: feedId,
              content_title: qnaDetail.feedTitle,
              content_type: FeedTypeCode.QNA,
              like_count: qnaDetail.likeCount ? qnaDetail.likeCount : 0,
              comment_count: qnaDetail.commentCount
                ? qnaDetail.commentCount
                : 0,
            });
          },
          () => {
            // fail
          }
        );
      }
    };

    const onShareButtonPress = () => {
      // TODO : onShareButtonPress 기능 복구
      const actHistoryData = {
        actHistoryTypeCode: ActHistoryTypeCode.POST_SHARE,
        actPageCode: ActPageCode.QNA_DETAIL,
        feedId: (qnaDetail.feedId ?? 0).toString(),
        likeCount: (qnaDetail.likeCount ?? 0).toString(),
        commentCount: (qnaDetail.commentCount ?? 0).toString(),
      };
      const shareInfo: ShareInfo = {
        title: qnaDetail?.feedTitle,
        descriptionText: qnaDetail?.contents,
        screen: ShareScreenCode.QNA_DETAIL_SCREEN,
        targetId: qnaDetail?.feedId,
        path: `QnA/${qnaDetail?.feedId}`,
      };

      void onShare(shareInfo, actHistoryData);

      track("click_share_button", {
        content_title: qnaDetail.feedTitle,
        content_type: FeedTypeCode.QNA,
        like_count: qnaDetail.likeCount || 0,
        comment_count: qnaDetail.commentCount || 0,
      });
    };

    const moveToComment = () => {
      CommentStore.clear();
      goTo(`${pathname}/comment`, {
        state: {
          feedId: qnaDetail?.feedId,
          feedType: CommentTypeCode.QNA,
          feedAuthorId: qnaDetail?.createMemberUuid,
        },
      });
    };
    const onLikeListButtonPress = () => {
      goTo("/LikeScreen", {
        state: {
          feedId: qnaDetail.feedId,
          feedType: LikeTypeCode.QNA,
        },
      });
    };

    return (
      <div className="action_wrap" aria-label={"Qna detail footer"}>
        <div className="action_wrap_padding">
          <div className="action_left">
            <button
              onClick={() => {
                onLikeListButtonPress();
              }}
            >
              <p className="action_text">
                <p className="like_text">{t(`screen.qna.label.like`)} </p>
                <p className="number_text">{qnaDetail.likeCount}</p>
              </p>
            </button>

            <button
              onClick={() => {
                moveToComment();
              }}
            >
              <p className="action_text">
                <p className="comment_text">{t(`screen.qna.label.comment`)} </p>
                <p className="number_text">{qnaDetail.commentCount}</p>
              </p>
            </button>
          </div>
          <div className="action_right">
            <TouchableWithAsyncTask
              className="action_icon_btn"
              onPress={onLikeButtonPress}
              aria-label={"Qna detail like button"}
              authCheckProps={{ enabled: true }}
            >
              {qnaDetail.likeYn === "Y" ? <HeartOnIcon /> : <HeartOffIcon />}
            </TouchableWithAsyncTask>
            <TouchableWithAsyncTask
              className="action_icon_btn"
              onPress={onBookmarkButtonPress}
              aria-label={"Qna detail bookmark button"}
              authCheckProps={{ enabled: true }}
            >
              {qnaDetail.bookmarkYn === "Y" ? (
                <BookmarkOnIcon />
              ) : (
                <BookmarkOffIcon />
              )}
            </TouchableWithAsyncTask>
            <div
              className="action_icon_btn"
              onClick={onShareButtonPress}
              aria-label={"Qna detail share button"}
            >
              <ShareIcon />
            </div>
          </div>
        </div>
        <div
          style={{
            borderBottomWidth: 1,
            borderBottomColor: "#EEE",
            marginLeft: "5.1vw",
            marginRight: "5.1vw",
          }}
        ></div>
      </div>
    );
  }
);

export default QnaDetailFooter;
