import "./QnaCardItem.scss";

import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
// import { Text, StyleSheet, View } from "react-native";
import { Styles } from "../../../assets/types/Style";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
// import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { getNumberFormat } from "../../../utils/number";
import { QnaCard } from "../../../api/qna/model";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { ActPageCode, ActSectionCode } from "../../../constants/ActHistory";
import { useTranslation } from "react-i18next";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const QnaCardItem = observer(
  ({
    qna,
    index,
    isClickItem = true,
  }: {
    qna: QnaCard;
    index: number;
    isClickItem?: boolean;
  }) => {
    const {
      feedId,
      feedTitle,
      nickname,
      profileUrl,
      thumbnailFilePath,
      commentCount,
    } = qna;

    const { t } = useTranslation();
    const [loadFailed, setLoadFailed] = useState(false);
    const [colorIndex, setColorIndex] = useState(1);

    const PURPLE_STYLE_LIST = [
      "qna_purple_wrap1",
      "qna_purple_wrap2",
      "qna_purple_wrap3",
    ];

    useEffect(() => {
      setColorIndex(getRandomNumber());
    }, []);

    const getRandomNumber = () => {
      return Math.floor(Math.random() * 3);
    };

    const moveQnaDetail = (feedId: number) => {
      goTo(`/QnA/${feedId}`, {
        state: {
          feedId: feedId,
          actData: {
            actPageCode: ActPageCode.QNA,
            actSectionCode: ActSectionCode.POPULAR_QNA,
          },
          index: index,
        },
      });
    };

    const REACT_APP_PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    return (
      <div id="QnaCardItem">
        <button
          className={`popular_qna_item${
            thumbnailFilePath ? " has_thumbnail" : ""
          }`}
          onClick={
            isClickItem
              ? () => {
                  moveQnaDetail(feedId);
                }
              : () => {}
          }
          aria-label={`Qna Main popular qna item ${index}`}
        >
          {thumbnailFilePath ? (
            <img
              className="popular_qna_image"
              src={`${REACT_APP_PUBLIC_BUCKET_URL}${thumbnailFilePath || ""}`}
              alt={thumbnailFilePath}
            />
          ) : (
            <div
              className={`popular_qna_image ${PURPLE_STYLE_LIST[colorIndex]}`}
            />
          )}
          {/* {!loadFailed && <div className="popular_qna_inner" />} */}
          <div className="popular_qna_contents_wrap">
            <p className="popular_qna_contents_text">{feedTitle}</p>
            <div className="popular_qna_info">
              <div className="popular_qna_profile">
                <img
                  src={`${REACT_APP_PUBLIC_BUCKET_URL}${profileUrl ?? ""}`}
                  alt={profileUrl}
                  className="popular_qna_profile_image"
                />
                <p className="id_text">{nickname}</p>
              </div>
              <div className="comment">
                <p className="comment_text1">{t(`screen.qna.label.comment`)}</p>
                <p className="comment_text2">{getNumberFormat(commentCount)}</p>
              </div>
            </div>
          </div>
        </button>
      </div>
    );
  }
);

export default QnaCardItem;
