import { callAPIWithGETMethod } from '../common/api'
import { Magazine, MagazineDetail, RequestParameters } from './model'

export const getMagazineList = async (
  requestParameters: RequestParameters,
  isLoading = true
): Promise<Magazine[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: '/v1/feed/magazines',
      params: {
        pageIndex: requestParameters.pageIndex,
        pageSize: requestParameters.pageSize,
        sort: requestParameters.sort,
      },
    },
    isLoading
  )
  return (
    response?.successOrNot === 'Y' ? response?.data?.list : null
  ) as Magazine[]
}

export const getMagazineByFeedId = async (
  feedId: string | number,
  isLoading = true
): Promise<MagazineDetail> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/feed/magazine/${feedId}`,
    },
    isLoading
  )
  return (
    response?.successOrNot === 'Y' ? response?.data : null
  ) as MagazineDetail
}
