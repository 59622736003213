import { CSSProperties, useEffect } from "react";
import { ReactComponent as Spinner } from "../../assets/animations/spinner.svg";
import { ReactComponent as DarkSpinner } from "../../assets/animations/darkSpinner.svg";

import classes from "./CustomActivityIndicator.module.scss";

const CustomActivityIndicator = ({
  isDarkSpinner,
  wrapperStyle,
}: {
  isDarkSpinner?: Boolean;
  wrapperStyle?: CSSProperties;
}) => {
  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => document.body.classList.remove("modal-open");
  }, []);

  return (
    <>
      {isDarkSpinner ? (
        <div
          className={classes.wrap}
          style={{ backgroundColor: "transparent", ...wrapperStyle }}
        >
          <div
            className="mobile-max-width"
            style={{
              height: "100%",
              position: "relative",
            }}
          >
            <div className={classes.indicator}>
              <DarkSpinner style={{ width: 100, height: 100 }} />
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.wrap}>
          <div
            className="mobile-max-width"
            style={{
              height: "100%",
              position: "relative",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            }}
          >
            <div className={classes.indicator}>
              <Spinner style={{ width: 100, height: 100 }} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomActivityIndicator;
