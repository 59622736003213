import React, { useEffect, useRef, useState } from "react";
import Search from "./presenter/Search";
import SearchResult from "./presenter/SearchResult";
import { NotificationModal } from "../../components/modals";
import { observer } from "mobx-react";
import SearchStore from "../../store/SearchStore";
import CommonStore from "../../store/CommonStore";
import { ReactComponent as SearchIcon } from "../../assets/icons/search1.svg";
import { ReactComponent as ClosePurple } from "../../assets/icons/close_purple_background.svg";
import { ReactComponent as LatestIcon } from "../../assets/icons/clock_purple.svg";
import { ReactComponent as SearchGrayIcon } from "../../assets/icons/search_gray.svg";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import { SEARCH_RESULT_COUNT, SearchTabMenu } from "../../constants/Search";
import { ReactComponent as AngleIcon } from "../../assets/icons/new_back_arrow.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/new_home.svg";
import { Platform, PlatformTypes } from "../../native/platform";
import { useTranslation } from "react-i18next";
import "./SearchScreen.scss";
import { goBack, goTo } from "../../hooks/navigate/NavigateFunction";
import { useLocation, useParams } from "react-router-dom";

const useSafeAreaInsets = () => ({ top: 0, bottom: 0 });

const SearchScreen = observer((data: any) => {
  const {
    hasError,
    errorMessage,
    toastMessage,
    searchFocus,
    searchText,
    latestSearchTextList,
    autoCompleteResult,
    initTabName,
    getSelectedSearchTab,
    setError,
    setSearchText,
    setSearchFocus,
    setChangedText,
    getAutoComplete,
    getSuggestionSearch,
    getRankingSearch,
    doSearch,
    getLastestSearchText,
    clear,
    setInitTabName,
    setSelectedTab,
    needToSearch,
    getSortType,
    getSearchResult,
  } = SearchStore;
  const insets = useSafeAreaInsets();

  const EMPTY_STR = "";
  const searchRef: React.RefObject<any> = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);
  useEffect(() => {
    if (!searchText || searchText.length === 0) {
      setSearchFocus(true);
      void getLastestSearchText();
      void getSuggestionSearch();
      void getRankingSearch();
    }
  }, [
    // isFocused,
    getLastestSearchText,
    getSuggestionSearch,
    getRankingSearch,
    setSearchFocus,
    searchText,
  ]);

  useEffect(() => {
    if (toastMessage !== undefined && toastMessage.trim().length > 0) {
      CommonStore.setToastOption({
        show: true,
        message1: toastMessage,
      });
      SearchStore.setToast(EMPTY_STR);
    }
  }, [toastMessage, insets]);

  const matchSearchTextInAutoCompleteText = (
    searchText: string,
    autoCompleteText: string
  ) => {
    let matchText = "";
    for (let index = 0; index < searchText.length; index++) {
      if (
        searchText.charAt(index).toUpperCase() ===
        autoCompleteText.charAt(index).toUpperCase()
      ) {
        matchText += searchText.charAt(index);
      }
    }
    return matchText;
  };

  const renderAutoComplete = () => {
    let searchedText: string[] = [];
    let autoCompleteText: string[] = [];
    if (latestSearchTextList && latestSearchTextList.text.length > 0) {
      const upperLatestSearchTextList = latestSearchTextList.text.map((text) =>
        text.toUpperCase()
      );
      searchedText =
        (autoCompleteResult &&
          autoCompleteResult.length > 0 &&
          autoCompleteResult.filter((item) =>
            upperLatestSearchTextList.includes(item.toUpperCase())
          )) ||
        [];

      autoCompleteText =
        (autoCompleteResult &&
          autoCompleteResult.length > 0 &&
          autoCompleteResult.filter(
            (item) => !upperLatestSearchTextList.includes(item.toUpperCase())
          )) ||
        [];
    } else {
      autoCompleteText = autoCompleteResult;
    }

    return (
      <div>
        <div className="auto_complete_list">
          {searchedText
            .slice()
            .reverse()
            .map((item, index) => {
              const matchText = matchSearchTextInAutoCompleteText(
                searchText,
                item
              );
              return (
                <div
                  className="auto_complete_item"
                  key={index}
                  onClick={async () => await onSearch(item)}
                >
                  <LatestIcon />
                  <div className="auto_complete_text">
                    {/* <span className="auto_complete_match_text">
                      {item.slice(0, matchText.length)}
                    </span> */}

                    <span style={{ color: "#a760b1" }}>
                      {item.slice(0, matchText.length)}
                    </span>
                    {item.slice(matchText.length)}
                  </div>
                </div>
              );
            })}
          {autoCompleteText.map((item, index) => {
            const matchText = matchSearchTextInAutoCompleteText(
              searchText,
              item
            );
            return (
              <div
                className="auto_complete_item"
                key={index}
                onClick={async () => await onSearch(item)}
              >
                <SearchGrayIcon />
                <p className="auto_complete_text">
                  <span className="auto_complete_match_text">
                    {item.slice(0, matchText.length)}
                  </span>
                  <span>{item.slice(matchText.length)}</span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const onChangeText = (text: string) => {
    setInitTabName(SearchTabMenu.Total);

    setChangedText(true);
    void getAutoComplete(text);
    setSearchFocus(true);
    setSearchText(text);
  };

  const onSearch = async (
    text: string,
    goToTab: string = SearchTabMenu.Total
  ) => {
    if (text.length > 0) {
      setSearchFocus(false);
      setSearchText(text);
      setSelectedTab(goToTab as SearchTabMenu);
      searchRef.current?.blur();

      goToTab === SearchTabMenu.Total
        ? void doSearch(text)
        : void getSearchResult(
            text,
            goToTab,
            getSortType(goToTab),
            SEARCH_RESULT_COUNT,
            0
          );
      goTo(`/Search/${text}/${goToTab}`);
    } else {
      const msg = t("screen.search.message.noSearchText");
      SearchStore.setToast(msg);
    }
  };

  const handleBack = () => {
    goTo("/Main");
  };

  return (
    <div id="SearchScreen" className="search">
      <div className="search_header">
        <div className="search_back" onClick={handleBack}>
          <AngleIcon />
        </div>
        <div className="nick_name_wrap">
          <div className="input_wrap">
            <input
              autoFocus={true}
              className="nick_name"
              placeholder={t("screen.search.message.searchPlaceholder")}
              autoCapitalize="none"
              ref={searchRef}
              value={searchText}
              type="text"
              onChange={(event) => {
                onChangeText(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  void onSearch(searchText);
                }
              }}
            />
            <div
              className={`close_purple ${!(searchText.length > 0) && "hidden"}`}
            >
              <div
                onClick={() => {
                  onChangeText(EMPTY_STR);
                  searchRef.current?.focus();
                }}
              >
                <ClosePurple />
              </div>
            </div>
          </div>

          <div className="search_icon">
            <div onClick={() => void onSearch(searchText)}>
              <SearchIcon width={30} height={30} />
            </div>
          </div>
        </div>
        <div
          className="search_home"
          onClick={() => {
            goTo("/Main");
          }}
        >
          <HomeIcon />
        </div>
      </div>

      {SearchStore.searchFocus &&
        (SearchStore.searchText.length > 0 ? (
          renderAutoComplete()
        ) : (
          <Search onSearch={onSearch} />
        ))}

      {hasError && (
        <NotificationModal
          isVisible={hasError}
          contents1={errorMessage!}
          onRequestClose={() => {
            setError(EMPTY_STR);
          }}
        />
      )}
    </div>
  );
});

export default SearchScreen;
