import { callAPIWithGETMethod, callAPIWithPOSTMethod } from "../common/api";
import CommonResponse from "../http";
import { TermsResult } from "../terms/model";
import {
  DisplayProduct,
  PostedPopularProduct,
  RecommendProduct,
} from "./model";

// 포스트 인기 제품(220몰 메인 화면)목록 조회
export const getPostedPopularProduct = async (
  isLoading = true
): Promise<PostedPopularProduct[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/mall/posted-popular-product?cursor=0&pageSize=5`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as PostedPopularProduct[];
};

// 인기 제품(220 메인 화면)목록 조회
export const getTodayPopularProduct = async (
  pageSize: number,
  cursor: number,
  isLoading = true
): Promise<PostedPopularProduct[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/mall/posted-popular-product?cursor=${cursor}&pageSize=${pageSize}`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as PostedPopularProduct[];
};

// 포스트 인기 제품 포스트 상세 목록 조회
export const getMallPostedPopularProductPostdetail = async (
  pageSize: number,
  pageIndex: string,
  productId: number,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: `/v1/mall/posted-popular-product-postdetail?productId=${productId}&cursor=${pageIndex}&pageSize=${pageSize}`,
    },
    isLoading
  );
};

// 전시 제품 목록 조회
export const getDisplayProduct = async (
  isLoading = true
): Promise<DisplayProduct[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/mall/display-product`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as DisplayProduct[];
};

// 추천 상품 목록 조회
export const getRecommendProduct = async (
  memberUuid: string,
  isLoading = true
): Promise<RecommendProduct> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/mall/recommend-product?memberUuid=${memberUuid}`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as RecommendProduct;
};

// 카테고리별 베스트 상품(220몰 메인 화면)목록 조회
export const getMallCategoryBestProduct = async (
  categoryId: number,
  pageIndex: number,
  pageSize: number,
  isLoading = true
): Promise<DisplayProduct[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/mall/category-best-product?displayCategoryId=${categoryId}&cursor=${pageIndex}&pageSize=${pageSize}`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as DisplayProduct[];
};

// 220몰 동의 필요한 약관 조회
export const getEcommerceNeedAgreeTerms = async (
  isLoading = true
): Promise<CommonResponse<TermsResult[]>> => {
  return await callAPIWithGETMethod(
    {
      url: `/v1/member/ecommerce/terms`,
    },
    isLoading
  );
};

// 220몰 전체 약관 조회
export const getEcommerceTerms = async (
  isLoading = true
): Promise<CommonResponse<TermsResult[]>> => {
  return await callAPIWithGETMethod(
    {
      url: `/v1/terms/ecommerce`,
    },
    isLoading
  );
};

// 설정 > 약관 동의 해제 여부 확인 및 동의 약관 목록
export const eCommerceTermsAgreed = async (isLoading = true) => {
  return await callAPIWithGETMethod(
    {
      url: `/v1/member/ecommerce/termsagreed`,
    },
    isLoading
  );
};

// 220몰 약관 동의하기
export const eCommerceTermsAgree = async (
  memberUuid: string,
  termsAgree: { termsId: number; termsAgreeYn: string }[],
  isLoading = true
): Promise<CommonResponse<any>> => {
  return await callAPIWithPOSTMethod(
    {
      url: `/v1/member/ecommerce/terms/agree`,
      body: {
        memberUuid,
        termsAgree,
      },
    },
    isLoading
  );
};

// 220몰 약관 동의 철회하기
export const eCommerceTermsDisagree = async (
  memberUuid: string,
  termsAgree: { termsId: number; termsAgreeYn: string }[],
  isLoading = true
): Promise<CommonResponse<any>> => {
  return await callAPIWithPOSTMethod(
    {
      url: `/v1/member/ecommerce/terms/disagree`,
      body: {
        memberUuid,
        termsAgree,
      },
    },
    isLoading
  );
};
