import { callAPIWithPOSTMethod } from "../common/api";
import { BadWordRequestResult } from "./model";

export const checkBadWord = async (
  content: string,
  shouldReturnContentBadWordList = "N",
  isLoading = true
): Promise<BadWordRequestResult> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/badWord/validation/content",
      body: {
        content,
        shouldReturnContentBadWordList,
      },
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" && response?.statusCode === "OK" ? response?.data : null
  ) as BadWordRequestResult;
};
