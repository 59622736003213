import CommentStore from "../store/CommentStore";
import PostDetailStore from "../store/PostDetailStore";
import PostWriteStore from "../store/PostWriteStore";
import PreferenceStore from "../store/PreferenceStore";
import TermsStore from "../store/TermsStore";
import PostModifyStore from "../store/PostModifyStore";
import MyHomeStore from "../store/MyHomeStore";
import FollowerStore from "../store/FollowerStore";
import QnaWriteStore from "../store/QnaWriteStore";
import QnaStore from "../store/QnaStore";
import QnaDetailStore from "../store/QnaDetailStore";
import BookmarkStore from "../store/BookmarkStore";
import SearchStore from "../store/SearchStore";
import ProductSearchAddStore from "../store/ProductSearchAddStore";
import UploadStore from "../store/upload/UploadStore";
// import CookieManager from "@react-native-cookies/cookies";

export const clearStore = () => {
  TermsStore.clear();
  PreferenceStore.clear();
  PostWriteStore.clear();
  PostDetailStore.clear();
  MyHomeStore.clear();
  CommentStore.clear();
  FollowerStore.clear();
  PostModifyStore.clear();
  QnaWriteStore.clear();
  QnaStore.clear();
  BookmarkStore.clearBookmarkStore();
  SearchStore.clear();
  ProductSearchAddStore.clear();
  UploadStore.clear();
  // void CookieManager.clearAll(true);
};
