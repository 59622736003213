import { useEffect, useRef, useState } from "react";
import { ReactComponent as NaverIcon } from "../../../assets/icons/naver.svg";
import { ReactComponent as KakaoIcon } from "../../../assets/icons/kakao.svg";
import { ReactComponent as AppleIcon } from "../../../assets/icons/apple.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/google.svg";
import { ReactComponent as TriangleRightRedIcon } from "../../../assets/icons/triangle_right_red.svg";
import { ReactComponent as TriangleRightGreen } from "../../../assets/icons/triangle_right_green.svg";
import { ReactComponent as CircleCloseIcon } from "../../../assets/icons/circle_close_purple.svg";
import { Color } from "../../../assets/styles/variable";
import {
  CustomActivityIndicator,
  TitleHeader,
} from "../../../components/common";
import { observer } from "mobx-react";
import SettingStore from "../../../store/SettingStore";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import {
  MemberStateCode,
  StatusCode,
  SuccessOrNot,
} from "../../../constants/Common.enum";
import { useTranslation } from "react-i18next";
import {
  deHyphenatePhoneNumber,
  hyphenatePhoneNumber,
} from "../../../utils/phoneNumber";
import { SignUpCode } from "../../../constants/SignUp.enum";
import CommonStore from "../../../store/CommonStore";
import { ReactComponent as PwShow } from "../../../assets/icons/pw_show_purple.svg";
import { ReactComponent as PwHide } from "../../../assets/icons/pw_hide_black.svg";
import { passwordValidate } from "../../../api/member/api";
import { NotificationModal } from "../../../components/modals";
import MemberStore from "../../../store/MemberStore";
import PhoneAuthStore from "../../../store/PhoneAuthStore";
import PhoneAuthSection from "../../../components/common/PhoneAuthSection";
import { UserInformationUpdate } from "../../../api/member/model";
import { useTracker } from "../../../hooks/tracker";
import classes from "./UserScreen.module.scss";
import clsx from "clsx";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const TOTAL_COUNT = 5;

const UserScreen = observer(() => {
  const {
    phoneNumber,
    setPhoneNumber,
    isPhoneNumberValid,
    setIsPhoneNumberValid,
    isPhoneAuthSectionShow,
    isPhoneNumberSectionComplete,
    setIsPhoneNumberSectionComplete,
    resetPhoneAuthSection,
    sendAuthPhone,
    handleChangePhoneNumber,
    resetAuthLogic,
  } = PhoneAuthStore;

  const { getAccountInfo, changePhoneNumber } = SettingStore;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const insets = useSafeAreaInsets()
  const { track } = useTracker();
  // const [tracker] = useState(useTracker());

  const inputRef = useRef<HTMLInputElement>(null);

  const [readyState, setReadyState] = useState<boolean>(false);
  const [changing, setChanging] = useState<boolean>(false);
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [socialPlatformCode, setSocialPlatformCode] = useState<string>("");
  const [showNoticePopup, setShowNoticePopup] = useState(false);
  const [pwScreen, setPwScreen] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [passwordFocus, setPasswordFocus] = useState<boolean>(true);
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [attemptCount, setAttemptCount] = useState<number>(0);
  const [modalContents, setModalContents] = useState<string>("");
  const [memberLock, setMemberLock] = useState<boolean>(false);
  const [accountInfo, setAccountInfo] = useState<
    UserInformationUpdate | undefined
  >();
  const [isShowInitAlert, setIsShowInitAlert] = useState<boolean>(false);
  const [certifiedPhoneNumber, setCertifiedPhoneNumber] = useState<string>("");

  useEffectOnlyOnce(() => {
    resetPhoneAuthSection();
    resetAuthLogic();
    setLoading(true);
    void (async function () {
      const accountInfo = await getAccountInfo();
      setAccountInfo(accountInfo);
      if (!accountInfo) return;
      const {
        emailAddress,
        memberName,
        phoneNumber,
        socialPlatformCode,
        phoneNumberConfirmDatetime,
      } = accountInfo;
      if (phoneNumber) {
        setPhoneNumber(hyphenatePhoneNumber(phoneNumber));
        setIsPhoneNumberValid(true);
      }
      if (phoneNumberConfirmDatetime == null) {
        setChanging(true);
        setIsShowInitAlert(true);
      } else {
        setIsPhoneNumberSectionComplete(true);
        setCertifiedPhoneNumber(phoneNumber);
      }
      setEmailAddress(() => emailAddress);
      setName(memberName ?? "");
      setSocialPlatformCode(socialPlatformCode);
      setReadyState(true);
      setLoading(false);
    })();
  });
  const changeNumber = async () => {
    if (
      deHyphenatePhoneNumber(phoneNumber) ===
      deHyphenatePhoneNumber(certifiedPhoneNumber)
    ) {
      CommonStore.setToastOption({
        show: true,
        // bottomOffset: Platform.OS === "ios" ? insets.bottom : 30,
        autoHide: true,
        message1: t("screen.join.joinPhone.authSuccess"),
        type: "tomatoToast",
      });
    } else {
      const result = await changePhoneNumber(
        deHyphenatePhoneNumber(phoneNumber)
      );
      if (result.successOrNot !== SuccessOrNot.Y) return;
      CommonStore.setToastOption({
        show: true,
        // bottomOffset: Platform.OS === "ios" ? insets.bottom : 30,
        autoHide: true,
        message1: t("screen.setting.label.memberInfo.toast"),
        type: "tomatoToast",
      });
    }

    setChanging((changing) => !changing);
    const accountInfo = await getAccountInfo();
    setAccountInfo(accountInfo);
    setCertifiedPhoneNumber(accountInfo?.phoneNumber || "");
  };
  /* eslint-disable */
  useEffect(() => {
    if (isPhoneNumberSectionComplete && changing) {
      void changeNumber();
      setIsShowInitAlert(false);
    }
  }, [isPhoneNumberSectionComplete]);
  useEffect(() => {
    if (isShowInitAlert) {
      setIsShowInitAlert(false);
    }
  }, [phoneNumber]);
  /* eslint-enable */
  const handleChanging = () => {
    CommonStore.setToastOption({ show: false });
    setIsPhoneNumberSectionComplete(false);
    setChanging(true);

    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const onPressDeleteButton = () => {
    resetPhoneAuthSection();
    inputRef.current?.focus();
  };

  const onBottomSheet = () => {
    goTo("/withdraw/agree");
  };

  const deleteBars = (text: string): string => {
    return text.split("-").join("");
  };
  const formatText = (text: string): string => {
    const barDeleted = deleteBars(text);
    return `${barDeleted.slice(0, 3)}-****-${barDeleted.slice(7)}`;
  };

  const renderEnterValidPhoneNumberGuide = () => {
    if (isPhoneNumberSectionComplete || isPhoneAuthSectionShow) {
      return <></>;
    } else if (isShowInitAlert) {
      return (
        <div className={classes.alert_wrap}>
          <div className={classes.alert} aria-label={"invalid phone number"}>
            <TriangleRightRedIcon />
            <span className={classes.phone_not_valid}>
              {t("screen.join.joinPhone.invalidPhoneNumberGuide3")}
            </span>
          </div>
        </div>
      );
    } else if (!isPhoneNumberValid) {
      return (
        <div className={classes.alert_wrap}>
          <div className={classes.alert} aria-label={"invalid phone number"}>
            <TriangleRightRedIcon />
            <span className={classes.phone_not_valid}>
              {t("screen.join.joinPhone.invalidPhoneNumberGuide")}
            </span>
          </div>
        </div>
      );
    } else if (
      deHyphenatePhoneNumber(phoneNumber) ===
      deHyphenatePhoneNumber(certifiedPhoneNumber)
    ) {
      return (
        <div className={classes.alert_wrap}>
          <div className={classes.alert} aria-label={"invalid phone number"}>
            <TriangleRightRedIcon />
            <span className={classes.phone_not_valid}>
              {t("screen.join.joinPhone.invalidPhoneNumberGuide2")}
            </span>
          </div>
        </div>
      );
    } else if (isPhoneNumberValid) {
      return (
        <div className={classes.alert_wrap}>
          <div className={classes.alert} aria-label={"invalid phone number"}>
            <TriangleRightGreen />
            <span className={classes.phone_success_valid}>
              {t("screen.join.joinPhone.successPhoneNumberGuide")}
            </span>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const maskEmailAddress = (emailAddress: string): string => {
    const id = emailAddress
      .split("@")[0]
      .split("")
      .map((char, index) => (index < 2 ? char : "*"))
      .join("");
    const domain = emailAddress
      .split("@")[1]
      .split("")
      .map((char, index) => (char === "." || index < 2 ? char : "*"))
      .join("");
    return `${id}@${domain}`;
  };

  const onPasswordVisible = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onPasswordFocus = (isPassword: boolean) => {
    setPasswordFocus(isPassword);
  };

  const onChangePassword = (pw: string) => {
    const trimPassword = pw.trim();
    setPasswordValue(trimPassword);
  };

  const onPasswordCheck = async () => {
    // Keyboard.dismiss()
    // TODO: 키보드 내리기
    setLoading(true);
    const emailLoginResponse = await passwordValidate(
      emailAddress,
      passwordValue
    );
    const userInfo = { ...emailLoginResponse.data };

    if (
      emailLoginResponse.successOrNot === SuccessOrNot.Y &&
      emailLoginResponse.data?.memberStateCode === MemberStateCode.NORMAL
    ) {
      setPwScreen(true);
      setMemberLock(false);
    } else {
      if (
        emailLoginResponse.successOrNot === SuccessOrNot.N &&
        emailLoginResponse.statusCode === StatusCode.LOCK
      ) {
        setModalContents(t("common.message.lock"));
        setMemberLock(true);
      } else {
        const count = Number(userInfo.passwordWrongCount);
        setAttemptCount(count);
        count < TOTAL_COUNT
          ? setModalContents(
              t("screen.setting.passwordCheck.notEqualPw", {
                attemptCount: count,
                totalCount: TOTAL_COUNT,
              })
            )
          : setModalContents(
              t("screen.setting.passwordCheck.wrongPw", {
                totalCount: TOTAL_COUNT,
              })
            );
      }
      setPasswordValue("");
      setShowNoticePopup(true);
    }
    setLoading(false);
  };

  const onCloseNoticeModal = () => {
    setShowNoticePopup(false);
    if (attemptCount >= TOTAL_COUNT || memberLock) {
      goTo("/PasswordReset");
    }
  };

  const renderPasswordCheck = () => {
    return (
      <div
        style={{ flex: 1, backgroundColor: Color.WHITE }}
        // accessible={true}
        aria-label={"Password Check Screen"}
      >
        <TitleHeader
          title={t("screen.setting.passwordCheck.additionalCheck")}
          isHome={false}
        />

        <div className={classes.info_text}>
          <span>{t("screen.setting.passwordCheck.info")}</span>
        </div>

        <div
          className={clsx(
            classes.pw_input_wrap,
            passwordFocus && classes.pw_input_wrap_focus
          )}
        >
          <input
            type={passwordVisible ? "text" : "password"}
            className={classes.pw_input}
            value={passwordValue}
            onChange={(e) => {
              onChangePassword(e.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && passwordValue) {
                void onPasswordCheck();
              }
            }}
            maxLength={20}
            placeholder={t("screen.setting.passwordCheck.passwordInput")}
            onFocus={() => onPasswordFocus(true)}
            aria-label={"Password Value"}
          />
          <button className={classes.toggle_btn} onClick={onPasswordVisible}>
            {passwordVisible ? <PwShow /> : <PwHide />}
          </button>
        </div>

        <div className={classes.footer}>
          <button
            aria-label="Check Password"
            className={clsx(
              classes.btn_ok,
              passwordValue ? classes.active : classes.in_active,
              { height: 30 }
            )}
            onClick={() => void onPasswordCheck()}
            disabled={!passwordValue}
          >
            <span
              className={clsx(
                classes.btn_ok_text,
                passwordValue ? classes.txt_active : classes.txt_in_active
              )}
            >
              {t("screen.setting.passwordCheck.ok")}
            </span>
          </button>
        </div>

        {showNoticePopup && (
          <NotificationModal
            isVisible={showNoticePopup}
            contents1={modalContents}
            isBlack={true}
            defaultButtonText={t("screen.post.button.confirm")}
            onClickDefaultButton={onCloseNoticeModal}
            onRequestClose={onCloseNoticeModal}
            textAlign="center"
          />
        )}

        {loading && <CustomActivityIndicator isDarkSpinner={true} />}
      </div>
    );
  };

  return !readyState ? (
    <CustomActivityIndicator isDarkSpinner={true} />
  ) : !!socialPlatformCode || pwScreen ? (
    <div
      style={{ flex: 1, backgroundColor: Color.WHITE }}
      // accessible={true}
      aria-label={"User Info Update Screen"}
    >
      <div className={classes.member_editor}>
        <TitleHeader
          isHome={false}
          title={t(`screen.setting.label.memberInfo.title`)}
        />
        <div
        // keyboardShouldPersistTaps={'handled'}
        >
          <div className={clsx(classes.wrap, classes.top_wrap)}>
            <div className={classes.top_edit_wrap}>
              <span className={classes.sub_label_text}>
                {t("screen.setting.label.memberInfo.email")}
              </span>
              <div className={classes.email}>
                <span className={classes.contents_text}>
                  {maskEmailAddress(emailAddress)}
                </span>
                {socialPlatformCode === SignUpCode.NAVER ? (
                  <NaverIcon className={classes.sns_icon} />
                ) : socialPlatformCode === SignUpCode.KAKAO ? (
                  <KakaoIcon className={classes.sns_icon} />
                ) : socialPlatformCode === SignUpCode.GOOGLE ? (
                  <GoogleIcon className={classes.sns_icon} />
                ) : socialPlatformCode === SignUpCode.APPLE ? (
                  <AppleIcon className={classes.sns_icon} />
                ) : (
                  <></>
                )}
              </div>
            </div>

            {!!socialPlatformCode || (
              <div className={classes.edit_wrap}>
                <span className={classes.sub_label_text} aria-label="Password">
                  {t("screen.setting.label.memberInfo.password")}
                </span>
                <div className={classes.password}>
                  <span className={classes.contents_text}>
                    {t("screen.setting.label.memberInfo.passwordValue")}
                  </span>
                  <button
                    className={classes.modify_btn}
                    onClick={() => {
                      goTo("/PasswordReset", {
                        state: { email: emailAddress },
                      });
                    }}
                    aria-label="Reset Password"
                  >
                    <span className={classes.modify_btn_text}>
                      {t("screen.setting.label.memberInfo.change")}
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className={classes.wrap_p}>
            <div>
              <span className={classes.sub_label_text}>
                {t(`screen.setting.label.memberInfo.phoneNo`)}
              </span>
              {!changing ? (
                <div className={classes.phone}>
                  <form>
                    <input
                      contentEditable={false}
                      className={classes.contents_text}
                      value={phoneNumber ? formatText(phoneNumber) : ""}
                      onChange={(e) => {
                        handleChangePhoneNumber(e.target.value);
                      }}
                      type="tel"
                      maxLength={13}
                      aria-label="Phone Number Input"
                    />
                  </form>
                  <div className={classes.btns}>
                    <button
                      className={classes.modify_btn}
                      onClick={() => handleChanging()}
                      aria-label="Change Phone Number"
                    >
                      <span className={classes.modify_btn_text}>
                        {t("screen.setting.label.memberInfo.change")}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className={classes.phone}>
                  <div className={classes.contents_text_wrap}>
                    <input
                      className={classes.contents_text}
                      ref={inputRef}
                      value={phoneNumber}
                      onChange={(e) => {
                        handleChangePhoneNumber(e.target.value);
                      }}
                      type="tel"
                      maxLength={13}
                      aria-label="Phone Number Input"
                    />
                  </div>
                  <div className={classes.btns}>
                    <button
                      className={classes.clear_btn}
                      onClick={onPressDeleteButton}
                      aria-label="Delete Input"
                    >
                      <CircleCloseIcon />
                    </button>
                    <button
                      className={classes.modify_btn}
                      onClick={() => void sendAuthPhone("phone", accountInfo)}
                      aria-label="Confirm Phone Number Change"
                      disabled={
                        !isPhoneNumberValid ||
                        isPhoneAuthSectionShow ||
                        isPhoneNumberSectionComplete
                      }
                    >
                      <span
                        className={
                          !isPhoneNumberValid ||
                          isPhoneAuthSectionShow ||
                          isPhoneNumberSectionComplete
                            ? classes.disable_modify_btn_text
                            : classes.modify_btn_text
                        }
                      >
                        {t("screen.setting.label.memberInfo.authButton")}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {renderEnterValidPhoneNumberGuide()}
              {isPhoneAuthSectionShow && <PhoneAuthSection />}
            </div>
          </div>

          <div className={classes.bar} />

          <div className={classes.wrap}>
            <button
              onClick={() => {
                track("click_withdraw_before_button", {});
                onBottomSheet();
              }}
              aria-label="Withdraw"
            >
              <span className={classes.withdraw_btn_text}>
                {t("screen.setting.label.memberInfo.withdraw")}
              </span>
            </button>
          </div>
        </div>
        {loading && <CustomActivityIndicator isDarkSpinner={true} />}
      </div>
    </div>
  ) : (
    renderPasswordCheck()
  );
});

export default UserScreen;
