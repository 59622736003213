import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import "./ProductSearchResult.scss";

import { t } from "i18next";
import { ProductSearchContext } from "../ProductSearchScreen";
import { observer } from "mobx-react";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
import { PRODUCT_SEARCH_COUNT } from "../../../constants/Product";
import { Color } from "../../../assets/styles/variable";
import { handleTopButtonScroll } from "../../../utils/common";
import { SearchItem } from "../../../api/search/model";
import { ProductSearchItem } from "./item/ProductSearchItem";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { CreateType } from "../../../constants/Common.enum";
import { SearchSortType } from "../../../constants/Search";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { CustomActivityIndicator } from "../../../components/common";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Virtuoso } from "react-virtuoso";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import "./ProductSearchResult.scss";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";

const ProductSearchResult = observer(({ params }: any) => {
  const { fromMyAppliance } = useLocation().state;
  const { _screenFrom, addProductList } = useContext(ProductSearchContext);
  const { t } = useTranslation();

  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const ASSET_IMAGE = "/ASSETS/naverapi.png";

  const {
    searchResult,
    keyword,
    refreshing,
    loading,
    loadingInList,
    searchPageIndex,
    setRefreshing,
    setLoadingInList,
    getSearchResult,
  } = ProductSearchAddStore;
  const [showTopButton, setShowTopButton] = useState<boolean>(false);

  const ref = useRef(null);

  useEffect(() => {
    return () => {
      ProductSearchAddStore.searchSearchProductMap.clear();
    };
  }, []);

  const renderItem = useCallback(
    (item: any, index: number) => {
      return (
        <ProductSearchItem
          item={item}
          fromMyAppliance={fromMyAppliance}
          index={index}
        />
      );
    },
    [fromMyAppliance]
  );

  const hasMoreData = () => {
    const totalCount = searchResult?.totalCount ?? 0;
    const result = searchResult?.list ?? [];
    return !!(totalCount > 0 && keyword && result.length > 0);
  };

  const needShowFooter = () => {
    if (searchResult?.openApiFlag) {
      return !!(keyword && searchResult?.totalCount > 0);
    } else {
      return !!(keyword && searchResult?.totalCount == 0);
    }
  };

  const needShowNaverImgComponent = () => {
    return !!(hasMoreData() && searchResult?.openApiFlag);
  };

  return (
    <div>
      <Virtuoso
        data={searchResult?.list}
        endReached={() => {
          if (hasMoreData() && !searchResult?.openApiFlag) {
            const tempStart = searchPageIndex + 1;
            if (
              keyword &&
              (searchResult?.totalCount || 0) > tempStart * PRODUCT_SEARCH_COUNT
            ) {
              setLoadingInList(true);
              void getSearchResult(keyword, tempStart, SearchSortType.POPULAR);
            }
          }
        }}
        increaseViewportBy={1400}
        itemContent={(index: number, item: any) => item && renderItem(item, index)}
        useWindowScroll
        className="product_search_result_list"
        components={{
          Footer: () => {
            if (loadingInList) {
              return (
                <>
                  <CustomActivityIndicatorSmall isDarkSpinner={true} />
                  <div style={{ height: 76, backgroundColor: "#fff" }} />
                </>
              );
            } else {
              return (
                <>
                  <div>
                    {needShowNaverImgComponent() && (
                      <>
                        <div className="naver_image_wrap">
                          <LazyLoadImage
                            src={`${PUBLIC_BUCKET_URL}${ASSET_IMAGE}`}
                            className="naver_image"
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {needShowFooter() && (
                    <>
                      <div className="naverDiv" />
                      <div className="naver_wrap">
                        <p
                          className="empty_text1"
                          aria-label="No search result"
                        >
                          {t("screen.productAdd.label.noProduct")}
                        </p>
                        <div className="empty_info">
                          <p
                            className="empty_text2"
                            aria-label="Product create new"
                          >
                            {t("screen.productAdd.label.createNew")}
                          </p>
                          <p
                            className="empty_text2"
                            aria-label="Product create"
                          >
                            {t("screen.productAdd.label.createGuide")}
                          </p>
                        </div>
                        <div
                          aria-label="Move to Product Add Screen"
                          className="add_home_app_button"
                          onClick={() =>
                            goTo("/Product/Add", {
                              state: {
                                myApplianceScreen: true,
                                screenFrom: _screenFrom,
                                createType: CreateType.NEW,
                                // addProductList: addProductList,
                              },
                            })
                          }
                        >
                          <p
                            className="add_home_app_button_text"
                            aria-label="Add product"
                          >
                            {t("screen.productAdd.button.new")}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  <div style={{ height: 76, backgroundColor: "#fff" }} />
                </>
              );
            }
          },
          EmptyPlaceholder: () =>
            !loading && (
              <div className="empty_wrap">
                <div className="empty_text1" aria-label="No search result">
                  {t("screen.productAdd.label.noProduct")}
                </div>
                <div className="empty_info">
                  <div className="empty_text2" aria-label="Product create new">
                    {t("screen.productAdd.label.createNew")}
                  </div>
                  <div className="empty_text2" aria-label="Product create">
                    {t("screen.productAdd.label.createGuide")}
                  </div>
                </div>
                <div
                  aria-label="Move to Product Add Screen"
                  className="add_home_app_button"
                  onClick={() => {
                    goTo("/Product/Add", {
                      state: {
                        myApplianceScreen: fromMyAppliance,
                        screenFrom: _screenFrom,
                        createType: CreateType.NEW,
                        // addProductList: addProductList,
                      },
                    });
                  }}
                >
                  <div
                    className="add_home_app_button_text"
                    aria-label="Add product"
                  >
                    {t("screen.productAdd.button.new")}
                  </div>
                </div>
              </div>
            ),
          Item: forwardRef(
            (
              { style, children, ...props },
              ref: React.LegacyRef<HTMLDivElement> | undefined
            ) => (
              <div
                ref={ref}
                {...props}
                style={{
                  ...style,
                  margin: 0,
                }}
              >
                {children}
                <div style={{ height: 4, backgroundColor: "transparent" }} />
              </div>
            )
          ),
          List: forwardRef(
            (
              { children, ...props },
              ref: React.LegacyRef<HTMLDivElement> | undefined
            ) => (
              <div
                ref={ref}
                {...props}
                className="product_search_result_list_inner"
              >
                {children}
              </div>
            )
          ),
        }}
      />
    </div>
  );
});

export default ProductSearchResult;
