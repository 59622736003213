export enum Category {
  ALL = "ALL",
  SOUND = "SOUND",
  SEASON = "SEASON",
  COOK = "COOK",
  VIDEO = "VIDEO",
  HOUSEWORK = "HOUSEWORK",
  BEAUTY = "BEAUTY",
  HYGIENE = "HYGIENE",
  CLOTHES = "CLOTHES",
  HEALTH = "HEALTH",
  DIGITAL = "DIGITAL",
}

export enum Topic {
  ALL = "전체",
  INTERIOR = "인테리어",
  FAILURE = "고장",
  WARRANTY = "A/S",
  TIPS = "사용팁",
}
