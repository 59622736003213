import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as ArrowRightBlack } from '../../../assets/icons/arrow_right_black.svg'
import TitleHeader from '../../../components/common/TitleHeader'
import { useTranslation } from 'react-i18next'
import { getDateFormatKo } from '../../../utils/datetime'
import SettingStore from '../../../store/SettingStore'
import { observer } from 'mobx-react'
import { useEffectOnlyOnce } from '../../../hooks/UseEffectOnlyOnce'
import { CustomActivityIndicator } from '../../../components/common'
import { handleTopButtonScroll } from '../../../utils/common'
import { goTo } from '../../../hooks/navigate/NavigateFunction'
import { Virtuoso } from 'react-virtuoso'
import classes from './Notice.module.scss'
import clsx from 'clsx'

const Notice = observer((data: any) => {
  const {
    getNoticeList,
    getMoreNoticeList,
    noticeList,
    setIsNoticeLoadingMore,
    isNoticeLoadingMore,
    setIsNoticeEndPage,
    isNoticeEndPage,
    clearNoticeList,
  } = SettingStore

  const { t } = useTranslation()
  const [showTopButton, setShowTopButton] = useState<boolean>(false)

  const ref = useRef(null)

  const pageSize = 10
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [isLoading, setLoading] = useState<boolean>(true)

  const getInitNoticeList = () => {
    setLoading(true)
    setIsNoticeLoadingMore(true)
    setIsNoticeEndPage(false)
    void getNoticeList(pageSize, pageIndex).finally(() => {
      setLoading(false)
      setIsNoticeLoadingMore(true)
      setIsNoticeEndPage(false)
    })
  }

  useEffectOnlyOnce(
    () => {
      getInitNoticeList()
    },
    () => {
      clearNoticeList()
    }
  )

  const renderNotice = (noticeIndex: number, eachNoti: any) => {
    const noticeId: number = eachNoti.noticeId
    const noticeTitle: string = eachNoti.noticeTitle
    const createdDatetime: string = eachNoti.createdDatetime

    return (
      <div
        className={clsx(classes.option_box, classes.option_box_border)}
        key={noticeId}
        aria-label={`Notice ${noticeIndex}`}
      >
        <button
          className={classes.option}
          onClick={() => goTo(`/notice/${noticeId}`)}
        >
          <div className={classes.top_wrap}>
            <span
              className={classes.option_txt}
              // numberOfLines={2}
              // ellipsizeMode="tail"
              aria-label={`Notice ${noticeIndex} Title`}
            >
              {noticeTitle}
            </span>
            <ArrowRightBlack className={classes.icon} />
          </div>
          <span className={classes.txt_date}>
            {getDateFormatKo(createdDatetime)}
          </span>
        </button>
      </div>
    )
  }

  const renderFooter = () => {
    return <CustomActivityIndicator isDarkSpinner={true} />
  }

  return (
    <div className={classes.notice} aria-label="notice list">
      <TitleHeader title={t('screen.setting.label.notice')} />

      {noticeList !== null &&
        noticeList !== undefined &&
        noticeList.length > 0 && (
          <Virtuoso
            ref={ref}
            className={classes.scroll}
            data={noticeList}
            itemContent={renderNotice}
            components={{
              Footer: () => (isLoading ? renderFooter() : <></>),
            }}
            endReached={() => {
              if (isNoticeLoadingMore) {
                void getMoreNoticeList(pageSize, pageIndex + 1)
                setPageIndex(pageIndex + 1)
                setIsNoticeLoadingMore(false) // 중복 호출 방지
              }
            }}
            onScroll={(event) =>
              handleTopButtonScroll(
                event,
                () => {
                  setShowTopButton(true)
                },
                () => {
                  setShowTopButton(false)
                },
                ref
              )
            }
            useWindowScroll
          />
        )}
    </div>
  )
})

export default Notice
