import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";

import { Arranges, Colors, FlexBoxs, Spacings } from "../../assets/styles";
import { ProductAdd } from "./presenter";
import CommonStore from "../../store/CommonStore";
import ProductSearchAddStore from "../../store/ProductSearchAddStore";
import { SearchItem } from "../../api/search/model";
import { useTracker } from "../../hooks/tracker";
import { CreateType } from "../../constants/Common.enum";
import { Color } from "../../assets/styles/variable";
import { Styles } from "../../assets/types/Style";
import { useLocation } from "react-router-dom";

const ProductAddScreen = observer((data: any) => {
  const params = useLocation().state;
  const searchItem = params?.searchItem as SearchItem;
  const { track } = useTracker();
  // const insets = useSafeAreaInsets();
  const { toastMessage } = ProductSearchAddStore;
  const myApplianceScreen = params?.myApplianceScreen;
  const createType = params?.createType;
  const screenFrom = params?.screenFrom;
  const onComplete = params?.onComplete;

  useEffect(() => {
    if (toastMessage !== undefined && toastMessage.trim().length > 0) {
      CommonStore.setToastOption({
        show: true,
        message1: toastMessage,
        // bottomOffset: Platform.OS === "ios" ? insets.bottom : 30,
      });
      ProductSearchAddStore.setToast("");
    }
  }, [toastMessage]);

  useEffect(() => {
    if (createType !== CreateType.MODIFY) {
      track("click_regist_my_product", {});
    }
  }, [track, createType]);

  return (
    <>
      {/* <StatusBar backgroundColor={Color.WHITE} barStyle="dark-content" /> */}
      <ProductAdd
        myApplianceScreen={myApplianceScreen}
        addProductList={params.addProductList}
        createType={createType}
        screenFrom={screenFrom}
        searchItem={searchItem}
        onComplete={onComplete}
      />
    </>
  );
});

export default ProductAddScreen;
