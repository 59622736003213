import { callAPIWithGETMethod } from "../common/api";
import { Preference } from "./model";

export const getPreferences = async (
  isLoading = true
): Promise<Array<Preference>> => {
  const response = await callAPIWithGETMethod(
    { url: "/v1/preference" },
    isLoading
  );

  return (
    response.successOrNot === "Y" ? response.data : null
  ) as Array<Preference>;
};
