import { Category } from "../../constants/Article.enum";
import { SuccessOrNot } from "../../constants/Common.enum";
import { SearchSortType } from "../../constants/Search";
// import ArticleListStore from "../../store/ArticleListStore";
import AuthStore from "../../store/AuthStore";
import { BannerInfo } from "../banner/model";
import {
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
} from "../common/api";
import CommonResponse from "../http";
import {
  GetArticleListRequest,
  GetArticleListResponse,
  BrandSubscribeResponse,
  BrandRecommendResponse,
  BrandCommunityLineUpResponse,
  BrandCommunityPopularArticleResponse,
  BrandCommunityHomeInfoResponse,
  TabTypeCode,
  SearchProductResultResponse,
  FeedItem,
} from "./model";

// 구독 중 브랜드커뮤니티 목록 조회
export const getBrandSubscribe = async (
  memberUuid: string,
  isLoading = true
): Promise<BrandSubscribeResponse[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/brand-community/subscribe`,
      params: {
        memberUuid,
      },
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as BrandSubscribeResponse[];
};

// 게시글 목록 조회
export const getArticleList = async (
  getArticleListRequest: GetArticleListRequest,
  isLoading = true
): Promise<GetArticleListResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/brand-community/article",
      params: {
        communityId: getArticleListRequest.communityId,
        query: getArticleListRequest.query,
        // categories: getArticleListRequest.categories?.join(),
        category: getArticleListRequest.category,
        articleType: getArticleListRequest.tab,
        articleTopFixYn: getArticleListRequest.articleTopFixYn,
        sort: getArticleListRequest.sort,
        pageSize: getArticleListRequest.pageSize,
        pageIndex: getArticleListRequest.pageIndex,
      },
    },
    isLoading
  );
  return response?.successOrNot === SuccessOrNot.Y
    ? response?.data
    : response.statusCode;
};

// 브랜드 구독
export const brandSubscribe = async (
  communityId: number,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: `/v1/brand-community/subscribe`,
      body: {
        memberUuid: AuthStore.sessionUser?.memberUuid || "",
        communityId,
      },
    },
    isLoading
  );
};

// 브랜드 구독 취소
export const brandSubscribeDelete = async (
  communityId: number,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithDELETEMethod(
    {
      url: `/v1/brand-community/subscribe`,
      body: {
        memberUuid: AuthStore.sessionUser?.memberUuid || "",
        communityId,
      },
    },
    isLoading
  );
};

//추천 브랜드커뮤니티 목록 조회
export const getBrandCommunityRecommend = async (
  memberUuid: string,
  isLoading = true
): Promise<BrandRecommendResponse[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/brand-community/recommend?pageSize=10&pageIndex=1&what=brandCommunity&memberUuid=${memberUuid}`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as BrandRecommendResponse[];
};

// 브랜드 라인업
export const getBrandCommunityLineUp = async (
  memberUuid: string,
  sortType: string,
  pageSize: number,
  pageIndex: number | string,
  subscribeYn?: boolean,
  includingSubscribedPage?: boolean,
  isLoading = true
): Promise<BrandCommunityLineUpResponse[]> => {
  let url = `v1/brand-community?memberUuid=${memberUuid}&sortType=${sortType}&pageSize=${pageSize}&pageIndex=${pageIndex}`;
  // 구독중/전체
  if (subscribeYn !== undefined && subscribeYn)
    url = url.concat(`&subscribeYn=Y`);
  //  구독중 포함 여부. accuracyDESC 일 때, DS API Target으로 동작.
  if (includingSubscribedPage !== undefined && includingSubscribedPage)
    url = url.concat(`&includingSubscribedPage=Y`);

  const response = await callAPIWithGETMethod(
    {
      url: url,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as BrandCommunityLineUpResponse[];
};

// 인기 게시글 목록 조회
export const getPopularArticle = async (
  memberUuid: string,
  cursor: number,
  pageSize: number,
  communityId?: number,
  isLoading = true
): Promise<BrandCommunityPopularArticleResponse[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/brand-community/popular-article`,
      params: {
        memberUuid,
        communityId,
        cursor,
        pageSize,
      },
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as BrandCommunityPopularArticleResponse[];
};

//브랜드 커뮤니티 홈 정보 조회
export const brandCommunityHomeInfo = async (
  memberUuid: string,
  communityId: number,
  isLoading = true
): Promise<BrandCommunityHomeInfoResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/brand-community/home-info?memberUuid=${memberUuid}&communityId=${communityId}`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as BrandCommunityHomeInfoResponse;
};

// 브랜드 홈 추천 피드 목록 조회
export const brandCommunityFeed = async (
  memberUuid: string,
  brandCommunityId: number,
  tab: TabTypeCode,
  pageIndex: number,
  pageSize: number,
  sort = "dateDESC",
  where = "brandCommunity",
  isLoading = true
): Promise<FeedItem[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/brand-community/feed`,
      params: {
        memberUuid,
        where,
        tab,
        brandCommunityId,
        sort,
        pageIndex,
        pageSize,
      },
    },
    isLoading
  );

  return (response?.successOrNot === "Y" ? response?.data : null) as FeedItem[];
};

// 브랜드 제품 목록 조회
export const getBrandProductSearchResult = async (
  type: string,
  brandCommunityId: number,
  query: string,
  pageSize: number,
  pageIndex: number,
  sort: string,
  tempMemberUuid?: string, // 임시회원일때만 전달
  isLoading = true
): Promise<SearchProductResultResponse> => {
  const response = await callAPIWithGETMethod(
    {
      // url: `/v1/brand-community/${type}`,
      url: `/v1/brand-community/product`,
      params: {
        query,
        brandCommunityId,
        pageSize,
        pageIndex,
        sort,
        tempMemberUuid,
      },
    },
    isLoading
  );
  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as SearchProductResultResponse;
};

// 브랜드 커뮤니티 검색 자동 완성
export const getBrandAutoComplete = async (
  query: string,
  what?: string,
  brandCommunityId?: number,
  isLoading = true
): Promise<string[]> => {
  let url = `/v1/keyword/autocomplete?query=${query}`;
  if (what !== undefined) url += `&what=${what}`;
  if (brandCommunityId !== undefined)
    url += `&brandCommunityId=${brandCommunityId}`;

  const response = await callAPIWithGETMethod(
    {
      url: url,
    },
    isLoading
  );

  return response?.successOrNot === "Y" ? response?.data : null;
};

export const getBrandBannerList = async (
  bannerTypeCodes: string,
  brandCommunityId: string,
  isLoading = true
): Promise<Array<BannerInfo>> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/brand-community/banners",
      params: {
        bannerTypeCodes,
        brandCommunityId,
      },
    },
    isLoading
  );

  return (
    response.successOrNot === "Y" ? response.data : null
  ) as Array<BannerInfo>;
};

export const getMyHomeBannerList = async (
  bannerTypeCodes: string,
  corporateMemberId: string,
  isLoading = true
): Promise<Array<BannerInfo>> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/brand-community/banners",
      params: {
        bannerTypeCodes,
        corporateMemberId,
      },
    },
    isLoading
  );

  return (
    response.successOrNot === "Y" ? response.data : null
  ) as Array<BannerInfo>;
};
