// import { View, Platform, StyleSheet, Text, TouchableOpacity, Dimensions } from "react-native";
import { Styles } from "../../assets/types/Style";
import {
  Arranges,
  Assets,
  Colors,
  Icons,
  Positions,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../assets/styles";
// import Modal from "react-native-modal";
import Modal from "react-modal";
// import { responsiveWidth } from "react-native-responsive-dimensions";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
// import { getStatusBarHeight } from "react-native-status-bar-height";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
// import Pdf from "react-native-pdf";
import { useTranslation } from "react-i18next";

const PdfModal = ({
  isVisible,
  source,
  onRequestClose,
}: {
  isVisible: boolean;
  source: string;
  onRequestClose?: () => void;
}) => {
  const { t } = useTranslation();
  const { modal, modal_header, header_text, modal_body, icons, pdf } = styles;
  // const insets = useSafeAreaInsets();
  // const statusBarHeight = getStatusBarHeight();

  const dismiss = () => {
    onRequestClose && onRequestClose();
  };

  return (
    <Modal
      isOpen={isVisible}
      // style={{
      //   margin: 0,
      //   top: Platform.OS === "ios" ? statusBarHeight + 10 : 0,
      //   bottom: insets.bottom,
      //   left: 0,
      //   right: 0,
      // }}
      // hasBackdrop={false}
      onRequestClose={onRequestClose}
    >
      <div style={modal}>
        <div style={modal_header}>
          <p style={header_text}>{t(`screen.product.label.productInfo.pdf`)}</p>
          <button style={icons} onClick={dismiss}>
            <Close />
          </button>
        </div>
        <div style={modal_body}>
          {/* <Pdf source={{ uri: source, cache: true }} style={pdf} /> */}
          <object data={source} type="application/pdf" style={pdf} />
        </div>
      </div>
    </Modal>
  );
};

export default PdfModal;

const styles: Styles = {
  modal: {
    // width: responsiveWidth(100),
    width: "100%",
    ...Positions.fullscreen,
    ...Colors.background_fff,
    ...Colors.border_color_transparent,
    ...Arranges.margin_center_v_h,
    ...Assets.modal_box_shadow,
    ...Spacings.padding_top_10,
  },
  modal_header: {
    ...FlexBoxs.flex,
    height: 48,
    ...Arranges.center_v_h,
    ...Positions.relative,
  },
  modal_body: {
    flex: 1,
    ...Spacings.padding_top_20,
    ...Spacings.padding_bottom_40,
    width: "100%",
    height: "100%",
  },
  icons: {
    ...Icons.icon_32,
    ...Positions.absolute,
    ...Positions.top_6,
    ...Positions.right_10,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  modal_body_title: {
    ...Spacings.margin_bottom_40,
  },
  pdf: {
    // flex: 1,
    // width: Dimensions.get("window").width,
    // height: Dimensions.get("window").height,
    width: "100%",
    height: "100%",
  },
};
