import React, { memo } from "react";
// import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
// import Config from "react-native-config";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Positions,
  Texts,
} from "../../../assets/styles";
import { ReactComponent as BadgeImg } from "../../../assets/icons/badge_img.svg";
import { ReactComponent as BadgeVideo } from "../../../assets/icons/badge_video.svg";
// import { responsiveWidth } from "react-native-responsive-dimensions";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
// import { ActHistoryTypeCode, ActPageCode, ActSectionCode } from "../../../constants/ActHistory";
import PostStore from "../../../store/PostStore";
// import CommonStore from "../../../store/CommonStore";
// import { Styles } from "../../../../types/Style";
// import PostVideoImage from "../../../components/common/PostVideoImage";
import { Styles } from "../../../assets/types/Style";
import { relative } from "path";
import classes from "./PostItem.module.scss";
import CommonStore from "../../../store/CommonStore";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";

const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

export interface PostItemProps {
  feedIndex: number;
  feedId: number;
  feedTitle: string;
  nickname: string;
  videoCount?: number;
  imageCount?: number;
  thumbnailFilePath: string;
  originalFilePath: string;
  onPressPost: (feedId: number) => void;
}
const PostItem = memo(
  ({
    feedIndex,
    feedId,
    feedTitle,
    nickname,
    videoCount,
    imageCount,
    thumbnailFilePath,
    originalFilePath,
    onPressPost,
  }: PostItemProps) => {
    const total = (videoCount || 0) + (imageCount || 0);
    const sectionCodeBySelectedTab: ActSectionCode[] = [
      ActSectionCode.POPULAR_POST, // Default
      ActSectionCode.RECENT_POST,
      ActSectionCode.FOLLOWING_POST,
    ];
    const extension = thumbnailFilePath?.split(".")[1];
    const isHEIC = extension === "heic" || extension === "HEIC";
    const { selectedTab } = PostStore;
    const { callActHistory } = CommonStore;

    const handlePostItemPress = () => {
      const filter = PostStore.filterQuery;
      callActHistory(
        ActHistoryTypeCode.POST_READ,
        feedId.toString(),
        ActPageCode.POST,
        sectionCodeBySelectedTab[selectedTab],
        feedIndex.toString(),
        filter["CATEGORY1"] ?? "",
        filter["THEME"] ?? "",
        filter["LIFE_STYLE"] ?? "",
        filter["SPACE"] ?? ""
      );
      onPressPost(feedId);
    };

    return (
      <div className={classes.post_item}>
        <div>
          <div
            className={classes.list_img_wrap}
            key={feedId}
            onClick={handlePostItemPress}
          >
            <FastImageWithFallback
              //     index={feedIndex}
              //       fallback={true}
              // style={styles.list_img}
              className={classes.list_img}
              source={{ uri: `${PUBLIC_BUCKET_URL}${thumbnailFilePath}` }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${originalFilePath}`}
              aria-label={`feed image container${feedIndex}`}
              carouselIdx={feedIndex}
            />
            {total > 1 && <BadgeImg className={classes.badge_img} />}
            {total == 1 && videoCount == 1 && (
              <BadgeVideo className={classes.badge_img} />
            )}
            <div className={classes.description}>
              <span
                className={classes.des_txt}
                // numberOfLines={2}
                // ellipsizeMode={"tail"}
                aria-label={`feed title${feedIndex}`}
              >
                {feedTitle}
              </span>
              <span
                className={classes.des_id}
                aria-label={`feed nickName${feedIndex}`}
              >
                {nickname}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

PostItem.displayName = "PostItem";
export default PostItem;
