import {
  RefObject,
  useCallback,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from "react"

const useDragScrollWithMouse = (ref: RefObject<any>, customSelector?: string, ignoreSelector?: string): boolean => {
  const [_isProcessDrag, setIsProcessDrag] = useState(false)
  const isProcessDrag = useDeferredValue(_isProcessDrag)
  const timerIdRef = useRef<any>(null)
  const listRef = useRef<any>(ref?.current)
  let mouseDown = false
  let startX: any, scrollLeft: any

  const startDragging = (e: any) => {
    if (ignoreSelector && typeof e.target.className === "string" && ('.' + e?.target?.className?.split(' ')?.join('.'))?.includes(ignoreSelector)) return
    mouseDown = true
    if (ref.current) {
      startX = e.pageX - listRef.current.offsetLeft
      scrollLeft = listRef.current.scrollLeft
    }
  }

  const stopDragging = (e: any) => {
    mouseDown = false
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current)
    }
    timerIdRef.current = setTimeout(() => {
      setIsProcessDrag(false)
    }, 300)
  }

  const move = (e: any) => {
    e.preventDefault()
    if (!mouseDown) {
      return
    }
    setIsProcessDrag(true)
    if (ref.current) {
      const x = e.pageX - listRef.current.offsetLeft
      const scroll = x - startX
      listRef.current.scrollLeft = scrollLeft - scroll
    }
  }

  const isTouchScreen = useCallback(() => {
    return window.matchMedia("(pointer: coarse)").matches
  }, [])

  useEffect(() => {
    if(!customSelector && ref.current) {
      listRef.current = ref.current
    }
  }, [ref.current, isTouchScreen()])

  useEffect(() => {
    if (ref.current && !isTouchScreen() && !customSelector) {
      ref.current.addEventListener("mousemove", move, false)
      ref.current.addEventListener("mousedown", startDragging, false)
      ref.current.addEventListener("mouseup", stopDragging, false)
      ref.current.addEventListener("mouseleave", stopDragging, false)
    }

    return () => {
      if (ref.current && !isTouchScreen() && !customSelector) {
        ref.current.removeEventListener("mousemove", move, false)
        ref.current.removeEventListener("mousedown", startDragging, false)
        ref.current.removeEventListener("mouseup", stopDragging, false)
        ref.current.removeEventListener("mouseleave", stopDragging, false)
        if (timerIdRef.current) clearTimeout(timerIdRef.current)
      }
    }
  }, [ref.current, isTouchScreen()])

  useEffect(() => {
    if (!customSelector) return
    const ele = document.querySelector(customSelector!)
    if (ele && !isTouchScreen()) {
      listRef.current = ele
      ele.addEventListener("mousemove", move, false)
      ele.addEventListener("mousedown", startDragging, false)
      ele.addEventListener("mouseup", stopDragging, false)
      ele.addEventListener("mouseleave", stopDragging, false)
    }
    return () => {
      if (ele && !isTouchScreen()) {
        ele.removeEventListener("mousemove", move, false)
        ele.removeEventListener("mousedown", startDragging, false)
        ele.removeEventListener("mouseup", stopDragging, false)
        ele.removeEventListener("mouseleave", stopDragging, false)
        if (timerIdRef.current) clearTimeout(timerIdRef.current)
      }
    }
  }, [customSelector, isTouchScreen()])

  return isProcessDrag
}

export default useDragScrollWithMouse
