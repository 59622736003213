import { createRef, useEffect, useRef } from "react";

export interface InfiniteScrollObserverProps {
  onObserver: () => void;
  height?: number;
  threshold?: number;
  rootMargin?: string;
}

export const InfiniteScrollObserver = ({
  onObserver,
  height = 70,
  threshold = 0.2,
  rootMargin = "",
}: InfiniteScrollObserverProps) => {
  const EndReachedIndicatorRef = createRef<HTMLDivElement>();
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onObserver();
        }
      },
      {
        threshold: threshold,
        rootMargin: rootMargin,
      }
    );

    EndReachedIndicatorRef.current &&
      observer.current.observe(EndReachedIndicatorRef.current);
  }, [EndReachedIndicatorRef, onObserver]);

  return (
    <div
      id="end-reached-indicator"
      style={{ height: height }}
      ref={EndReachedIndicatorRef}
    ></div>
  );
};
