import style from "./NotificationItem.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import { follow, unfollow } from "../../../api/follow/api";
import { Notification } from "../../../api/notification/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { NotificationTypeCode } from "../../../constants/Notification.enum";
import {
  callNotificationActHistory,
  handleNotificationPressed,
  parseLink,
} from "../../../service/Notification.service";
import CommonStore from "../../../store/CommonStore";
import { getDateStr } from "../../../utils/datetime";
import NotificationStore from "../../../store/NotificationStore";
import { observer } from "mobx-react";
import {
  background_fff,
  background_fff6ff,
} from "../../../assets/styles/colors";
import { track } from "../../../hooks/tracker/TrackFunction";
import BadgeStore from "../../../store/BadgeStore";
import { BadgeCode } from "../../../constants/Badge.enum";
import {
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
  Buttons,
} from "../../../assets/styles";
import { useTranslation } from "react-i18next";
import { Styles } from "../../../assets/types/Style";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import clsx from "clsx";
export interface NotificationItemProps {
  notification: Notification;
  index: number;
}

const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

const NotificationItem = observer(
  ({ notification, index }: NotificationItemProps) => {
    const { t } = useTranslation();
    const {
      followingUuids,
      removeUuidFromFollowingUuids,
      addUuidToFollowingUuids,
    } = NotificationStore;

    const [doIFollowTargetMember, setDoIFollowTargetMember] = useState(
      followingUuids.has(notification.targetUuid)
    );

    const showFollowUnfollowBtn =
      notification.notificationTypeCode ===
      NotificationTypeCode.MEMBER_FOLLOW_ME;

    const handleNicknamePress = useCallback((uuid: string) => {
      // console.log("#handleNicknamePress#");
      // replace("UserHomeScreen", { targetUserId: uuid });
    }, []);

    const handleNotificationItemPressed = () => {
      // 앱 유도
      CommonStore.setShowDownloadAppDrive(true);
      track("click_alarm_detail", {
        notification_type_code: notification.notificationTypeCode,
      });

      // TODO YY
      // if (notification.notificationTypeCode === "TEAM220_APP_PUSH") {
      //   const { targetTypeCode } = parseLink(
      //     notification.notificationLinkUrl || ""
      //   );
      //   handleNotificationPressed({
      //     pushType: targetTypeCode || "",
      //     link: notification.notificationLinkUrl,
      //   });
      // } else {
      //   handleNotificationPressed({
      //     pushType: notification.notificationTypeCode,
      //     link: notification.notificationLinkUrl,
      //   });
      // }
      // callNotificationActHistory(
      //   notification.notificationTypeCode,
      //   notification.notificationLinkUrl,
      //   undefined,
      //   notification.requestorUuid
      // );
      // void NotificationStore.updateCheckedYnAndSyncNotCheckedCount([
      //   notification.notificationId,
      // ]);
    };

    const handleUnfollowPress = async () => {
      CommonStore.callActHistory(
        ActHistoryTypeCode.USER_FOLLOWING,
        notification.requestorUuid || "",
        ActPageCode.NOTIFICATION,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "CANCEL"
      );

      removeUuidFromFollowingUuids(notification.requestorUuid);
      const res = await unfollow(notification.requestorUuid);
      if (res) {
        showFollowUnfollowToast(false);
      } else {
        addUuidToFollowingUuids(notification.requestorUuid);
      }
    };

    const handleFollowPress = async () => {
      CommonStore.callActHistory(
        ActHistoryTypeCode.USER_FOLLOWING,
        notification.requestorUuid || "",
        ActPageCode.NOTIFICATION,
        undefined
      );

      addUuidToFollowingUuids(notification.requestorUuid);
      const res = await follow(notification.requestorUuid);

      if (res) {
        showFollowUnfollowToast(true);
        void BadgeStore.obtainBadge(BadgeCode.FOLLOW);
      } else {
        removeUuidFromFollowingUuids(notification.requestorUuid);
      }
    };

    const showFollowUnfollowToast = (follow: boolean) => {
      CommonStore.setToastOption({
        show: true,
        message1: follow
          ? t("screen.myHome.message.followToast", {
              nickname: notification.requestorNickname,
            }) + " "
          : t("screen.myHome.message.unfollowToast", {
              nickname: notification.requestorNickname,
            }) + " ",
      });
    };

    useEffect(() => {
      setDoIFollowTargetMember(followingUuids.has(notification.requestorUuid));
    }, [followingUuids, notification.requestorUuid]);

    const patterns = [
      {
        pattern: /{target_nickname}/g,
        style: notification_styles.alarm_text_2,
        renderText: () => `@${notification.targetNickname}`,
        onPress: () => handleNicknamePress(notification.targetUuid),
      },
      {
        pattern: /{requestor_nickname}/g,
        style: notification_styles.alarm_text_2,
        renderText: () => notification.requestorNickname,
        onPress: () => handleNicknamePress(notification.requestorUuid),
      },
      {
        pattern: /{inputMessage}/g,
        style: notification_styles.alarm_text_1,
        renderText: () => notification.inputMessage || "",
      },
    ];

    const parseText = (text: any) => {
      let result = [];
      let lastIndex = 0;

      text.replace(
        /{target_nickname}|{requestor_nickname}|{inputMessage}/g,
        (match: any, offset: any) => {
          result.push(text.substring(lastIndex, offset));
          lastIndex = offset + match.length;

          const patternObj = patterns.find((p) => p.pattern.test(match));
          if (patternObj) {
            result.push(
              <span
                key={offset}
                style={patternObj.style}
                onClick={patternObj.onPress}
              >
                {patternObj.renderText()}
              </span>
            );
          }
        }
      );

      result.push(text.substring(lastIndex));

      return result;
    };

    return (
      <div
        onClick={handleNotificationItemPressed}
        className={clsx(style.container, {
          [style.background_fff]: notification.checkedYn === "Y",
          [style.background_fff6ff]: notification.checkedYn !== "Y",
        })}
      >
        <FastImageWithFallback
          className={style.user_img}
          source={{
            uri: `${PUBLIC_BUCKET_URL}${notification.notificationImageUrl}`,
          }}
        />

        <div className={style.alarm_info}>
          <div
            className={style.alarm_text_1}
            aria-label={`notification content${index}`}
          >
            {parseText(notification.contentFormat)}
          </div>
          <div className={style.alarm_text_3}>
            {getDateStr(notification.createdDatetime)}
          </div>
        </div>

        {showFollowUnfollowBtn && (
          <div>
            {doIFollowTargetMember ? (
              <>
                {/* 팔로우 후 */}
                <TouchableWithAsyncTask
                  className={clsx(
                    style.following_btn,
                    style.btn,
                    style.touchable_with_async_task
                  )}
                  onPress={handleUnfollowPress}
                  authCheckProps={{ enabled: true }}
                >
                  <span
                    className={clsx(style.following_btn_text, style.btn_text)}
                    aria-label="Followed"
                  >
                    {t("screen.notificationScreen.label.following")}
                  </span>
                </TouchableWithAsyncTask>
              </>
            ) : (
              <>
                {/* 팔로우 전 */}
                <TouchableWithAsyncTask
                  className={clsx(
                    style.follow_btn,
                    style.btn,
                    style.touchable_with_async_task_2
                  )}
                  onPress={handleFollowPress}
                  authCheckProps={{ enabled: true }}
                >
                  <span
                    className={clsx(style.follow_btn_text, style.btn_text)}
                    aria-label="Follow"
                  >
                    {t("screen.notificationScreen.label.follow")}
                  </span>
                </TouchableWithAsyncTask>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default NotificationItem;

export const notification_styles: Styles = {
  alarm: {
    ...FlexBoxs.flex_1,
  },
  alarm_list: {
    ...Colors.background_fff,
  },
  user_img: {
    width: 48,
    height: 48,
    ...Spacings.border_radius_100,
  },
  alarm_item_1: {
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_20,
    ...Spacings.padding_bottom_19,
    ...FlexBoxs.horizon,
    borderBottomWidth: 1,
    borderColor: Colors.border_color_eee.borderColor,
    backgroundColor: Colors.background_fff,
  },
  alarm_item_2: {
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_20,
    ...Spacings.padding_bottom_19,
    ...FlexBoxs.horizon,
    borderBottomWidth: 1,
    borderColor: Colors.border_color_eee.borderColor,
    backgroundColor: Colors.background_fff6ff,
  },
  alarm_info: {
    ...Spacings.margin_left_10,
    ...FlexBoxs.flex_1,
  },
  alarm_text_1: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    ...Spacings.margin_bottom_6,
    whiteSpace: "pre-wrap",
  },
  alarm_text_2: {
    color: Colors.font_be69c3.color,
  },
  alarm_text_3: {
    fontSize: Texts.contents_text_37.fontSize,
    color: Colors.font_999.color,
  },
  btn: {
    ...Spacings.border_radius_300,
    ...Spacings.padding_top_bottom_8,
    ...Spacings.padding_right_left_20,
    marginLeft: Spacings.margin_left_20.marginLeft,
    textAlign: "center",
  },
  btn_text: {
    fontSize: Texts.button_text_4.fontSize,
  },
  follow_btn: {
    ...Buttons,
    borderWidth: 1,
    borderColor: Colors.border_color_be69c3.borderColor,
  },
  follow_btn_text: {
    color: Colors.font_be69c3.color,
  },
  following_btn: {
    ...Buttons,
    backgroundColor: Colors.background_be69c3.backgroundColor,
  },
  following_btn_text: {
    color: Colors.font_fff.color,
  },
};
