import "./QnaDetail.scss";
import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";

import QnaDetailFooter from "./QnaDetailFooter";
import QnaDetailProfile from "./QnaDetailProfile";
import QnaDetailContents from "./QnaDetailContents";

import { NotificationModal } from "../../../components/modals";
import { useTranslation } from "react-i18next";
import QnaStore from "../../../store/QnaStore";
import CommonStore from "../../../store/CommonStore";
import MainStore from "../../../store/MainStore";
import QnaDetailStore from "../../../store/QnaDetailStore";
import ReportStore from "../../../store/ReportStore";

import { CommentTypeCode } from "../../../constants/Comment";

import { CommentFooter, DetailReply } from "../../comment/presenter";
import CommentStore from "../../../store/CommentStore";
import { getSessionUserFromStorage } from "../../../service/Session.service";
import { goBack } from "../../../hooks/navigate/NavigateFunction";
import { useTracker } from "../../../hooks/tracker";
import { useWindowScroll } from "react-use";
import { ActHistoryRequest } from "../../../api/data/model";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";

const QnaDetail = observer(
  ({
    qnaDetailStore,
    feedId,
    callState,
    index,
  }: {
    qnaDetailStore: QnaDetailStore;
    feedId: number;
    callState: string;
    index: number;
  }) => {
    const { t } = useTranslation();
    const { track } = useTracker();

    const { qnaDetail } = qnaDetailStore;

    const isAuthenticated = useRef<boolean>(false);

    const { y } = useWindowScroll();

    useEffect(() => {
      setTimeout(() => {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
          actPageCode: ActPageCode.QNA_DETAIL,
          actSourcePageCode: CommonStore.fixedPreviousRouteName,
          attr1: (y === 0).toString(),
        };
        void sendActHistory(actHistory);
      }, 1000);
    }, []);

    useEffect(() => {
      getSessionUserFromStorage().then((res) => {
        isAuthenticated.current = res ? true : false;
      });
    }, [isAuthenticated]);

    // const insets = useSafeAreaInsets();
    const [selfWidth, setSelfWidth] = useState(window.innerWidth);
    const [showDeletePopup, setDeletePopup] = useState(false);

    useEffect(() => {
      if (qnaDetail) {
        track("view_qna_detail", {
          content_id: feedId,
          content_title: qnaDetail.feedTitle,
          content_type: "qna",
          like_count: qnaDetail.likeCount || 0,
          comment_count: qnaDetail.commentCount || 0,
          review_product_count: qnaDetail.productList
            ? qnaDetail.productList.length
            : 0,
          review_product_id: qnaDetail.productList
            ? qnaDetail.productList.map((p) => p.productId)
            : null,
          review_product_name: qnaDetail.productList
            ? qnaDetail.productList.map(
                (p) => "[" + p.brandNameKor + "]" + p.productName
              )
            : null,
          tag: qnaDetail.tagList && qnaDetail.tagList.map((t) => t.tagName),
        });
      }
    }, [track, feedId, qnaDetail]);

    // const onMeasure = (e: LayoutChangeEvent) => {
    //   setSelfWidth(e.nativeEvent.layout.width);
    // };

    return (
      <div id="qna_detail">
        <div className="qna_wrap" aria-label={"Qna detail body"}>
          <div className="user_qna_detail_wrap">
            <QnaDetailProfile feedId={feedId} qnaDetailStore={qnaDetailStore} />
            <QnaDetailContents
              selfWidth={selfWidth}
              feedId={feedId}
              qnaDetailStore={qnaDetailStore}
            />
            <QnaDetailFooter
              selfWidth={selfWidth}
              feedId={feedId}
              qnaDetailStore={qnaDetailStore}
              qnaListIndex={index}
            />
            <DetailReply
              feedId={feedId}
              detailStore={qnaDetail}
              commentTypeCode={CommentTypeCode.QNA}
            />
          </div>
        </div>
        {isAuthenticated.current && (
          <CommentFooter commentTypeCode={CommentTypeCode.QNA} />
        )}
      </div>
    );
  }
);

export default QnaDetail;
