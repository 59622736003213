import React from "react";
import { observer } from "mobx-react";
import "./AccountMenu.scss";
import { ReactComponent as IconOrder } from "../../../assets/icons/icon_my_order.svg";
import { ReactComponent as IconVolt } from "../../../assets/icons/icon_my_volt.svg";
import { ReactComponent as IconCommunity } from "../../../assets/icons/icon_my_community.svg";
import { ReactComponent as IconArrowRight } from "../../../assets/icons/btn_arrow_right_white.svg";

import { formatVoltAmount } from "../../../utils/number";
import { MemberInfoResponse } from "../../../api/member/model";
// import { goToLifecareMyPage } from "../../../utils/mall";
import { useTranslation } from "react-i18next";
import { BrandListTabType } from "../../../api/brand/model";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";
import CommonStore from "../../../store/CommonStore";
import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
} from "../../../constants/ActHistory";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

export interface AccountMenuProps {
  isMy: boolean;
  accountInfo: MemberInfoResponse;
}

const AccountMenu = observer((props: AccountMenuProps) => {
  const { t } = useTranslation();

  function goToBrandCommunityListScreen(
    brandListType: BrandListTabType = BrandListTabType.SUBSCRIBING
  ) {
    // push("BrandCommunityListScreen", { brandListType });
  }
  function goToVolt() {
    // CommonStore.setShowDownloadAppDrive(true);
    CommonStore.callActHistory(
      ActHistoryTypeCode.PAGE_CLICK,
      ActHistoryTargetId.VOLT,
      ActPageCode.MY_HOME
    );
    goTo("/MyVoltHistory");
  }

  const gotToLifeCare = () => {
    CommonStore.setShowDownloadAppDrive(true);
    CommonStore.callActHistory(
      ActHistoryTypeCode.PAGE_CLICK,
      ActHistoryTargetId.ORDER_HISTORY,
      ActPageCode.MY_HOME
    );
    // goToLifecareMyPage();
  };

  return (
    <>
      <div
        className="bx_menu_wrap"
        style={{
          backgroundColor:
            props.accountInfo.profileBackgroundColor || "#672C78",
        }}
      >
        <div className="volt_comm_wrap">
          {getStringRemoteConfig("show_brand_page") === "true" ? (
            <>
              <div className="bx_comm_top">
                <div>
                  <div className="bx_in_touch" onClick={gotToLifeCare}>
                    <div className="label_volt_comm">
                      <div className="in_label">
                        <IconOrder />
                        <p className="txt_label_volt">
                          {t("screen.rightNav.label.orderHistory")}
                        </p>
                      </div>
                      <div className="bx_value">
                        <p className="txt_value_volt">
                          {t("screen.couponDetail.button.link")}
                        </p>
                        <IconArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bx_comm_row">
                <div className="bx_volt_comm">
                  <div className="bx_in">
                    <div className="bx_in_touch" onClick={goToVolt}>
                      <div className="label_volt_comm">
                        <div className="in_label">
                          <IconVolt />
                          <p className="txt_label_volt">
                            {t("screen.myHome.label.volt")}
                          </p>
                        </div>
                        <div className="bx_value">
                          <p className="txt_value_volt">
                            {formatVoltAmount(props.accountInfo.voltAmount)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bx_volt_comm">
                  <div className="bx_in">
                    <div
                      className="bx_in_touch"
                      onClick={() => {
                        if ((props.accountInfo.communityCount || 0) > 0) {
                          goToBrandCommunityListScreen(
                            BrandListTabType.SUBSCRIBING
                          );
                        } else {
                          goToBrandCommunityListScreen(BrandListTabType.TOTAL);
                        }
                      }}
                    >
                      <div className="label_volt_comm">
                        <div className="in_label">
                          <IconCommunity />
                          <p className="txt_label_volt">
                            {t("screen.myHome.label.myCommunity")}
                          </p>
                        </div>
                        <div className="bx_value">
                          <p className="txt_value_volt">
                            {props.accountInfo.communityCount || "0"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="bx_comm_row">
              <div className="bx_volt_comm">
                <div className="bx_in">
                  <div className="bx_in_touch" onClick={gotToLifeCare}>
                    <div className="label_volt_comm">
                      <div className="in_label">
                        <IconOrder />
                        <p className="txt_label_volt">
                          {t("screen.rightNav.label.orderHistory")}
                        </p>
                      </div>
                      <div className="bx_value">
                        <IconArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bx_volt_comm">
                <div className="bx_in">
                  <div className="bx_in_touch" onClick={goToVolt}>
                    <div className="label_volt_comm">
                      <div className="in_label">
                        <IconVolt />
                        <p className="txt_label_volt">
                          {t("screen.myHome.label.volt")}
                        </p>
                      </div>
                      <div className="bx_value">
                        <p className="txt_value_volt">
                          {formatVoltAmount(props.accountInfo.voltAmount)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default AccountMenu;
