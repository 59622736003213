import { observer } from 'mobx-react'
import MallMain from './presenter/MallMain'
import classes from './MallMainScreen.module.scss'

const MallMainScreen = observer(() => {
  return (
    <div className={classes.main_wrap} aria-label={'Mall main screen'}>
      <MallMain />
    </div>
  )
})

export default MallMainScreen
