import { CSSProperties, useCallback } from 'react'
import { observer } from 'mobx-react'

import { sendActHistory } from '../../api/data/api'
import { FlexBoxs, Arranges, Colors, Positions, Spacings, Texts } from '../../assets/styles';
import CustomCarousel from './CustomCarousel'
import { BannerInfo, BannerLinkInfo } from '../../api/banner/model'
import { openInAppBrowser } from '../../utils/common'
import {
  BannerTypeCodes,
  ContentType,
  LinkType,
} from '../../constants/Banner.enum'
import { ActHistoryRequest } from '../../api/data/model'
import { useTracker } from '../../hooks/tracker/UseTracker'
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from '../../constants/ActHistory'
import AuthStore from '../../store/AuthStore'
import CommonBannerImage from './CommonBannerImage'
import { goToLifecare, goToLifecareProductPage } from '../../utils/mall'
import { useNavigate } from 'react-router-dom'
import classes from './CommonBanner.module.scss'
import { Styles } from '../../assets/types/Style';
import { Settings } from 'react-slick';
import { goTo } from '../../hooks/navigate/NavigateFunction';

const LineBanner = observer(
  ({
    bannerList,
    bannerSizeStyle,
    showDotIndicator,
    showTitleIndicator,
    textIndexStyle,
    textIndexContainerStyle,
    showTextIndicator,
    dotContainerStyle,
    dotStyle,
    inactiveDotStyle,
    titleTextStyle,
    showTitleDim,
    textPositionStyle,
    otherCustomCarouselSettings,
  }: {
    bannerList: BannerInfo[]
    bannerSizeStyle?: CSSProperties
    showDotIndicator?: boolean
    showTitleIndicator?: boolean
    textIndexStyle?: CSSProperties
    textIndexContainerStyle?: CSSProperties
    showTextIndicator?: boolean
    dotContainerStyle?: CSSProperties
    dotStyle?: CSSProperties
    inactiveDotStyle?: CSSProperties
    titleTextStyle?: CSSProperties
    showTitleDim?: boolean
    textPositionStyle?: CSSProperties
    otherCustomCarouselSettings?: Settings
  }) => {
    const { track } = useTracker()
    const navigate = useNavigate()

    const callActHistory = (
      sectionCode: string,
      feedId: number,
      index: number,
      contentType: ContentType
    ) => {
      let actHistory: ActHistoryRequest = {
        actHistoryTypeCode: '',
        actHistoryTargetId: feedId.toString(),
        // TODO YY
        // actPageCode: ActPageCode.MAIN,
        actPageCode: '',
        actSectionCode: sectionCode,
        attr1: index.toString(),
      }

      switch (contentType) {
        case ContentType.POST:
          actHistory = {
            ...actHistory,
            actHistoryTypeCode: ActHistoryTypeCode.POST_READ,
          }
          break
        case ContentType.QNA:
          actHistory = {
            ...actHistory,
            actHistoryTypeCode: ActHistoryTypeCode.QNA_READ,
          }
          break
        case ContentType.AB:
          actHistory = {
            ...actHistory,
            actHistoryTypeCode: ActHistoryTypeCode.AB_READ,
          }
          break
        case ContentType.MISSION:
          actHistory = {
            ...actHistory,
            actHistoryTypeCode: ActHistoryTypeCode.MISSION_READ,
          }
          break
        case ContentType.MAGAZINE:
          actHistory = {
            ...actHistory,
            actHistoryTypeCode: ActHistoryTypeCode.MAGAZINE_READ,
          }
          break
        default:
          break
      }

      if (actHistory.actHistoryTypeCode !== '') {
        void sendActHistory(actHistory)
      }
    }

    /* eslint-disable */
    const handleBannerPress = useCallback(
      (index: number, item: BannerInfo) => {
        console.log(`handleBannerPress : ${JSON.stringify(item, null, 2)}`)
        const { contentId, contentType, linkType, linkUrl } =
          item.bannerLinkInfo

        if (linkType === LinkType.INTERNAL && contentType) {
          if (contentId !== undefined && contentId >= 0) {
            callActHistory(
              ActSectionCode.BANNER,
              contentId,
              index,
              contentType as ContentType
            )
          }

          const needLogin = AuthStore.sessionUser?.memberUuid === undefined

          switch (contentType) {
            case ContentType.POST:
              navigate(`/posts/feed/${contentId}`, {
                state: {
                  postIndex: index,
                },
              })
              break
            case ContentType.MAGAZINE:
              navigate(`/magazine/${contentId}`)
              break
            case ContentType.QNA:
              navigate(`/QnA/${contentId}`)
              break
            case ContentType.MISSION:
              needLogin
                ? navigate('JoinModal')
                : navigate(`/mission/${contentId}`)
              break
            case ContentType.AB:
              navigate(`/AB/${contentId}`)
              break
            case ContentType.PRODUCT:
              navigate(`/Product/${contentId}`);
              break
            case ContentType.MY_HOME:
              needLogin ? navigate('JoinModal') : navigate('/myhome')
              break
            case ContentType.MAGAZINE_HOME:
              navigate('/magazine')
              break
            case ContentType.RANKING:
              // navigate('RankingScreen')
              showDownloadAppDrive()
              break
            case ContentType.MISSION_HOME:
              navigate('/mission')
              break
            case ContentType.QNA_HOME:
              navigate('/qna')
              break
            case ContentType.QNA_LIST:
              navigate('/qnalist')
              break
            case ContentType.AB_LIST:
              navigate('/abList')
              break
            case ContentType.POST_HOME:
              navigate('/posts')
              break
            case ContentType.SETTING:
              needLogin ? navigate('JoinModal') : navigate('/setting')
              break
            case ContentType.NOTICE:
              navigate('/notice')
              break
            case ContentType.FRIEND_RECOMMEND:
              navigate('/FriendRecommend')
              break
            case ContentType.BRAND_HOME:
              navigate('/BrandCommunityHome', {
                state: {
                  targetBrandId: contentId?.toString()
                }
              })
              break
            case ContentType.BRAND_COMMUNITY_MAIN:
              navigate('/BrandCommunityMain')
              break
            case ContentType.BRAND_COMMUNITY_POST:
              navigate(`/ArticleDetail/${contentId}`)
              break
            case ContentType.MALL_DISPLAY_PAGE:
              navigate('/mall')
              break
            case ContentType.MALL_PRODUCT:
              goToLifecareProductPage(contentId || 0)
              break
            case ContentType.MALL_CATEGORY:
              navigate(`/mall/category/large/${contentId}`)
              break
            default:
              break
          }

          const banner = bannerList ? bannerList[index] : ({} as BannerInfo)
          if (banner) {
            // const banner_title = banner.bannerMainTitle
            //   ? banner.bannerMainTitle
            //   : banner.bannerTitle
            // track("click_main_banner", {
            //   banner_id: banner.bannerId || null,
            //   banner_title: banner_title,
            //   banner_order: index + 1,
            //   banner_type: contentType.toLowerCase(),
            // });
          }
        } else if (linkType === LinkType.MALL) {
          goToLifecare(linkUrl || '/')
        } else {
          if(linkUrl) void openInAppBrowser(linkUrl)
        }

        track('click_mall_banner', {
          bannerId: item.bannerId,
          banner_order: index,
          banner_title: item.bannerTitle,
        })

        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.PAGE_CLICK,
          actHistoryTargetId: item.bannerId.toString(),
          actPageCode: ActPageCode.MALL_MAIN,
          actSectionCode: ActSectionCode.BANNER,
          attr1: index.toString(),
          attr2: 'MALL_MAIN_BANNER',
        }
        void sendActHistory(actHistory)
      },
      [track, bannerList]
    )
    /* eslint-enable */

    const getTextBannerTitle = (title: string) => {
      return title && title.length > 23 ? title.substring(0, 23) + '...' : title
    }

    const renderItem = (item: BannerInfo, index: number) => {
      return item.bannerTypeCode === BannerTypeCodes.BRAND_HOME_TEXT_BANNER ? (
        <button
          style={bannerSizeStyle}
          onClick={() => handleBannerPress(index, item)}
        >
          <div className={classes.text_banner}>
            <span className={classes.text_banner_text}>
              {getTextBannerTitle(item.bannerMainTitle)}
            </span>
          </div>
        </button>
      ) : (
        <CommonBannerImage
          key={item.bannerId}
          banner={item}
          index={index}
          height={bannerSizeStyle?.height}
          textPositionStyle={textPositionStyle}
          onPress={handleBannerPress}
          showTitleIndicator={showTitleIndicator}
          titleTextStyle={titleTextStyle}
          showTitleDim={showTitleDim}
        />
      )
    }

    return bannerList && bannerList.length > 0 ? (
      <div
        aria-label={'banner'}
        style={
          bannerSizeStyle
          // ? bannerSizeStyle
          // : { width: selfWidth, height: selfWidth },
        }
      >
        <CustomCarousel
          windowSize={3}
          initialNumToRender={10}
          accessibilityLabel={'banner carousel'}
          data={bannerList}
          renderItem={renderItem}
          showTextIndicator={showTextIndicator}
          showDotIndicator={showDotIndicator}
          textIndexContainerStyle={
            textIndexContainerStyle
              ? textIndexContainerStyle
              : {
                  position: 'absolute',
                  ...Arranges.center_v_h,
                  ...Spacings.border_radius_28,
                  backgroundColor: 'rgba(34,34,34,0.4)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 38,
                  height: 28,
                  zIndex: 10,
                  top: 16,
                  right: 16,
                }
          }
          textIndexStyle={
            textIndexStyle
              ? textIndexStyle
              : {
                  ...Texts.contents_text_9,
                  ...Texts.font_weight_500,
                }
          }
          dotContainerStyle={
            dotContainerStyle ? dotContainerStyle : { marginTop: -28 }
          }
          inactiveDotStyle={
            inactiveDotStyle
              ? inactiveDotStyle
              : {
                  backgroundColor: 'rgba(34,34,34,0.4)',
                  marginLeft: 3,
                  marginRight: 3,
                }
          }
          dotStyle={
            dotStyle
              ? dotStyle
              : { marginLeft: 3, marginRight: 3, backgroundColor: '#fff' }
          }
          otherSettings={{
            arrows: false,
            dots: false,
          }}
        />
      </div>
    ) : (
      <></>
    )
  }
)

export default LineBanner
function showDownloadAppDrive() {
  throw new Error('Function not implemented.')
}
