import React from "react";
import { t } from "i18next";
import { observer } from "mobx-react";
import NotificationModal from "../modals/NotificationModal";

export interface ReportConfirmPopupProps {
  open: boolean;
  onClose: () => void;
}

const ReportConfirmPopup = observer(
  ({ open, onClose }: ReportConfirmPopupProps) => {
    return (
      <NotificationModal
        isVisible={open}
        title={t("screen.notificationModal.label.alert")}
        contents1={t("screen.notificationModal.message.reportText1")}
        contents2={t("screen.notificationModal.message.reportText2")}
        defaultButtonText={t("screen.notificationModal.button.confirm")}
        onClickDefaultButton={onClose}
        onRequestClose={onClose}
        textAlign={"center"}
      />
    );
  }
);

export default ReportConfirmPopup;
