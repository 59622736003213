import { notifyListeners } from "mobx/dist/internal";
import {
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
  callAPIWithPUTMethod,
  callAPIWithDELETEMethod,
} from "../../common/api";
import CommonResponse from "../../http";

export const getQas = async (
  pageSize: number,
  pageIndex: number,
  qaStatusCode: string,
  isLoading = true
): Promise<CommonResponse> => {
  let url = `/v1/setting/qas?pageSize=${pageSize}&pageIndex=${pageIndex}`;
  if (qaStatusCode && qaStatusCode.length > 0 && qaStatusCode !== "ALL")
    url = url + `&qaStatusCode=${qaStatusCode}`;
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: url,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

export const getDetail = async (
  qaId: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/setting/qa/${qaId}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

export const deleteQaDetail = async (
  qaId: number,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: `/v1/setting/qa/${qaId}`,
    },
    isLoading
  );

  return response?.successOrNot === "Y";
};
