import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../../assets/styles";
import PostStore from "../../../../store/PostStore";
// import { Tab } from "../../../../components/common/FlatListWithStickyTabs";
import { observer } from "mobx-react";
// import { Styles } from "../../../../../types/Style";
import { ReactComponent as ArrowDownOff } from "../../../../assets/icons/post/post_arrow_off.svg";
import { ReactComponent as ArrowDownOn } from "../../../../assets/icons/post/post_arrow_on.svg";
import { ReactComponent as Close } from "../../../../assets/icons/post/close_filter.svg";
import { ReactComponent as CloseToolTip } from "../../../../assets/icons/post/close_white.svg";
import { ReactComponent as FilterOff } from "../../../../assets/icons/post/post_filter_off.svg";
import { ReactComponent as FilterOn } from "../../../../assets/icons/post/post_filter_on.svg";
import { ReactComponent as Reset } from "../../../../assets/icons/post/reset_btn.svg";
import { CATEGORY_TOOLTIP } from "../../../../constants/Storage";
import { contents_text_31 } from "../../../../assets/styles/texts";
import { Styles } from "../../../../assets/types/Style";
import "./PostStickyHeader.scss";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useTracker } from "../../../../hooks/tracker";

export interface FilterStickyHeaderProps {
  showToolTip?: boolean;
}
const PostStickyHeader = observer(
  ({ showToolTip }: FilterStickyHeaderProps) => {
    const { t } = useTranslation();

    const [showGroupFilter, setGroupShowFilter] = useState<boolean>(true);
    const [savedShowToolTipFlag, setSavedShowToolTipFlag] =
      useState<boolean>(false);
    const [contentWidth, setContentWidth] = useState<number>(0);
    const [resetWidth, setResetWidth] = useState<number>(0);
    const { track } = useTracker();

    const filterList = PostStore.filterList ?? [];
    // const { width } = Dimensions.get("window");

    // useEffect(() => {
    //   const needShowToolTip = async () => {
    //     const savedShowToolTipFlag = await AsyncStorage.getItem(CATEGORY_TOOLTIP);
    //     if (savedShowToolTipFlag && savedShowToolTipFlag === "false") {
    //       setSavedShowToolTipFlag(false);
    //     } else {
    //       setSavedShowToolTipFlag(showToolTip ?? false);
    //     }
    //   };
    //   void needShowToolTip();
    // }, [showToolTip]);
    interface Tab {
      key: string;
      title?: string;
      accessibilityLabel?: string;
      content?: ReactElement | ((isActive: boolean) => ReactElement);
    }

    const tabList: Tab[] = useMemo(() => {
      return [
        {
          key: "POPULAR", // Default
          title: t("screen.post.label.popular"),
          accessibilityLabel: "popular",
        },
        {
          key: "RECENT",
          title: t("screen.post.label.recently"),
          accessibilityLabel: "recently",
        },
        {
          key: "FOLLOWING",
          title: t("screen.post.label.following"),
          accessibilityLabel: "following",
        },
      ];
    }, []);

    useEffect(() => {
      if (PostStore.selectedTab == 2) {
        setGroupShowFilter(false);
      } else {
        setGroupShowFilter(true);
      }
    }, [PostStore.selectedTab, setGroupShowFilter]);

    return (
      <>
        <div className="post-sticky-header">
          <div className="tab-list">
            {tabList?.map((tab, index) => {
              return (
                <div
                  className="tab-wrapper-style"
                  aria-label={tab.accessibilityLabel}
                  onClick={() => {
                    track("change_post_tab", {
                      tab_name: tab.key,
                    });
                    PostStore.setSelectedTab(index);
                    setSavedShowToolTipFlag(false);

                    if (PostStore.selectedTab === 2) {
                      setGroupShowFilter(false);
                    } else {
                      setGroupShowFilter(true);
                    }
                    void PostStore.initPostsByFilter();
                  }}
                >
                  <div
                    className={
                      PostStore.selectedTab === index
                        ? "tab-text-container-active"
                        : "tab-text-container"
                    }
                  >
                    <span
                      className={clsx(
                        "tab-text",
                        PostStore.selectedTab === index && "tab-text-active"
                      )}
                    >
                      {tab.title}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={
              showGroupFilter
                ? "category-group-scroll-wrapper-with-group"
                : "category-group-scroll-wrapper"
            }
          >
            <div className="category-group-wrap">
              <div className="category-list-wrap">
                <div // TouchableOpacity : On press down, the opacity of the wrapped view is decreased, dimming it.
                  className="category-group-items-icon"
                  onClick={() => {
                    setSavedShowToolTipFlag(false);
                    PostStore.setPostFilterInfo(true, "");
                  }}
                >
                  {PostStore.hasFilterOption ? <FilterOn /> : <FilterOff />}
                </div>

                {filterList?.map((filterGroup, index) => {
                  const isFilterOn =
                    PostStore.filterQuery[filterGroup.codeValue] !== undefined;

                  return (
                    <div // TouchableOpacity
                      key={index}
                      className={clsx(
                        "category-group-items",
                        isFilterOn
                          ? "category-group-items-on"
                          : "category-group-items-off"
                      )}
                      // className={isFilterOn ? "category-group-items-on" : "category-group-items-off"}
                      onClick={() => {
                        setSavedShowToolTipFlag(false);
                        PostStore.setPostFilterInfo(
                          true,
                          filterGroup.codeValue
                        );
                      }}
                    >
                      <span
                        className={
                          isFilterOn
                            ? "category-item-text"
                            : "category-item-text-off"
                        }
                      >
                        {filterGroup.codeName}
                      </span>
                      {isFilterOn ? <ArrowDownOn /> : <ArrowDownOff />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              showGroupFilter && PostStore.hasFilterOption
                ? "category-upper-wrapper-active"
                : "category-upper-wrapper",
              Object.entries(PostStore.filterQuery).length > 0 &&
                "category-filter-wrapper"
            )}
          >
            <div
              className="category-upper-wrapper-container"
              // style={{
              //   backgroundolor: "white",
              //   display: "flex",
              //   overflow-x : "scroll",
              //   marginLeft: 4,
              // }}
              // scrollEnabled={contentWidth > width - resetWidth}
              // onContentSizeChange={onContentSizeChange}
            >
              <div className="category-wrap">
                <div className="category-item-list-wrap">
                  {Object.entries(PostStore.filterQuery).map(
                    ([key, value], index) => {
                      return (
                        <div // touchableOpacity
                          key={index}
                          className="category-items"
                          onClick={() => {
                            void PostStore.deleteFilterOption(key);
                          }}
                        >
                          <span className="category-item-text">
                            {PostStore.subFilterMap?.get(key + "_" + value)}
                          </span>
                          <Close />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            {Object.entries(PostStore.filterQuery)?.length > 0 && (
              <div
                // onLayout={(event) => {
                //   setResetWidth(event.nativeEvent.layout.width);
                // }}
                className="category-reset-view"
              >
                <div
                  className="category-reset"
                  onClick={() => {
                    PostStore.postFilterReset();
                  }}
                >
                  <span className="category-reset-text">
                    {t("screen.post.button.reset")}
                  </span>
                  <Reset />
                </div>
              </div>
            )}
          </div>
          {savedShowToolTipFlag && (
            <div className="category-notice-popover">
              <div
              // onClick={() => {
              //   setSavedShowToolTipFlag(false);
              //   void AsyncStorage.setItem(CATEGORY_TOOLTIP, String(false));
              // }}
              >
                <div className="triangle" />
                <div className="category-notice-popover-inner">
                  <span className="category-notice-popover-text1">
                    다양한 가전코드를 {"\n"}
                    찾을 수 있어요!
                  </span>
                  <CloseToolTip className="category-close-btn" />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default PostStickyHeader;

// const styles: Styles = {
//   tab_list : {
//     flexDirection: "row",
//     backgroundColor: "white",
//     ...Spacings.padding_left_16_responsive,
//     paddingTop: 6,
//     display: "flex",
//     overflowX : "scroll"
//   },
//   horizontal_scroll_tab_list : {
//     backgroundColor: "white",
//     // // display: showGroupFilter ? "flex" : "none",
//     display: "flex",
//     overflowX : "scroll"
//   },
//   tabTextContainerStyle: {
//     ...Colors.background_transparent,
//   },
//   tabTextStyle: {
//     ...Texts.contents_text_2,
//     opacity: 0.4,
//     // lineHeight: 30,
//     textAlignVertical: "bottom",
//     ...Spacings.padding_right_16,
//   },
//   tabTextActiveStyle: {
//     ...Texts.contents_text_2,
//     // lineHeight: 30,
//     textAlignVertical: "bottom",
//     ...Spacings.padding_right_16,
//   },
//   tabWrapperStyle: {
//     marginBottom: 10,
//   },
//   tabsContainerStyle: {
//     flexDirection: "row",
//   },
//   tabTextContainerActiveStyle: {},
//   category_group_items_on: {
//     ...FlexBoxs.horizon,
//     backgroundColor: "#ab5eaf",
//     // paddingLeft: responsiveWidth(3),
//     // paddingRight: responsiveWidth(3),
//     ...Spacings.border_radius_300,
//     ...Spacings.margin_left_6,
//     ...Arranges.center_v_h,
//     height: 28,
//   },
//   category_group_items_off: {
//     ...FlexBoxs.horizon,
//     backgroundColor: "#f4e9f6",
//     // paddingLeft: responsiveWidth(3),
//     // paddingRight: responsiveWidth(3),
//     ...Spacings.border_radius_300,
//     ...Spacings.margin_left_6,
//     ...Arranges.center_v_h,
//     height: 28,
//   },
//   category_items: {
//     ...FlexBoxs.horizon,
//     ...Arranges.center_v_h,
//     ...Spacings.border_radius_4,
//     borderStyle: "solid",
//     borderWidth: 1,
//     borderColor: "#d9d9d9",
//     marginRight: 4,
//   },
//   category_group_wrap: {
//     // paddingLeft: responsiveWidth(3.5),
//     paddingRight: 4,
//   },
//   category_wrap: {
//     // paddingLeft: responsiveWidth(4.1),
//     paddingRight: 4,
//   },
//   category_list_wrap: {
//     display: "flex",
//     ...FlexBoxs.horizon,
//     flexWrap: "wrap",
//     ...Spacings.padding_right_left_4,
//     paddingBottom: 12,
//   },
//   category_item_list_wrap: {
//     ...FlexBoxs.horizon,
//     ...Spacings.padding_right_5,
//     paddingBottom: 12,
//   },
//   category_reset_view: {
//     marginRight: 16,
//     backgroundColor: "white",
//   },
//   category_reset: {
//     paddingTop: 3,
//     paddingBottom: 3,
//     ...FlexBoxs.horizon,
//     paddingRight: 3,
//     ...Arranges.center_v_h,
//     color: "#999999",
//     borderStyle: "solid",
//     borderLeftWidth: 1,
//     borderColor: "#999999",
//   },
//   category_reset_text: {
//     marginLeft: 7,
//     ...Texts.contents_text_41,
//     color: "#999999",
//     fontWeight: "500",
//     textAlign: "left",
//   },
//   category_item_text_style: {
//     ...Texts.contents_text_41,
//     color: "#ab5eaf",
//     fontWeight: "500",
//     textAlign: "left",
//     paddingLeft: 8,
//   },
//   category_item_text: {
//     ...Texts.contents_text_41,
//     color: "#ffffff",
//     fontWeight: "500",
//     textAlign: "left",
//   },
//   tab_text_container_active_style: {

//   },
//   category_item_text_off: {
//     ...Texts.contents_text_41,
//     fontWeight: "500",
//     textAlign: "left",
//     color: "#ab5eaf",
//   },
//   category_notice_popover: {
//     ...Positions.absolute,
//     ...Arranges.center_v_h,
//     top: 90,
//     right: 35,
//     zIndex: 10,
//   },
//   category_notice_popover_inner: {
//     ...FlexBoxs.horizon,
//     backgroundColor: "#222222",
//     ...Arranges.center_v_h,
//     marginTop: -5,
//     ...Spacings.padding_top_bottom_4,
//     ...Spacings.border_radius_12,
//     width: 140,
//     height: 56,
//   },
//   category_notice_popover_text1: {
//     textAlign: "center",
//     ...Texts.contents_text_5,
//     ...Texts.font_weight_500,
//     ...Colors.font_fff,
//     lineHeight: 16,
//   },
//   triangle: {
//     top: -14,
//     right: 55,
//     ...Positions.absolute,
//     width: 20,
//     height: 20,
//     transform: [{ rotate: "45deg" }],
//     borderRadius: 3,
//     backgroundColor: "#222222",
//   },
//   category_close_btn: {
//     ...Positions.absolute,
//     ...Positions.top,
//     right: 4,
//   },
// };
