// import { responsiveHeight, responsiveWidth } from "react-native-responsive-dimensions";
import { border_color_d8b8d8, border_color_f0dff2 } from "./colors";

export const border_width_default = {
  borderWidth: 1,
};

export const border_radius_5 = {
  borderRadius: 5,
};

export const border_radius_100 = {
  borderRadius: 100,
};

export const border_radius_56 = {
  borderRadius: 56,
};

export const btn_h_39 = {
  height: 39,
};

export const btn_h_28 = {
  height: 28,
};

export const big = {
  // width: responsiveWidth(87.8),
  width: "87%",
  // height: responsiveHeight(7),
  height: "7%",
  ...border_radius_100,
};

export const middle_0 = {
  ...border_width_default,
  ...border_radius_100,
  ...border_color_d8b8d8,
  // width: responsiveWidth(33.33),
  width: "33%",
  ...btn_h_39,
};

export const small_0 = {
  ...border_width_default,
  ...border_radius_5,
  ...border_color_f0dff2,
  // width: responsiveWidth(10.76),
  width: "10%",
  ...btn_h_28,
};

export const big_1 = {
  height: 52,
  ...border_radius_56,
};
