import React, { useEffect } from "react";

import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../assets/styles";
import { CommentList, CommentFooter } from "./presenter";
import { CommentTypeCode } from "../../constants/Comment";
import { NotificationModal } from "../../components/modals";
import { t } from "i18next";
import { observer } from "mobx-react";
import FeedDetailStore from "../../store/FeedDetailStore";
import { UserActTypeCode } from "../../constants/FeedType.enum";
import { Post } from "../../api/feed/model";
import PostStore from "../../store/PostStore";
import MainStore from "../../store/MainStore";
import MyHomeStore from "../../store/MyHomeStore";
import { AccountFeedTypeCode } from "../../constants/Account";
import { FeedTypeCode } from "../../constants/Feed";
import CommentStore from "../../store/CommentStore";
import { useLocation, useParams } from "react-router-dom";
import CustomActivityIndicator from "../../components/common/CustomActivityIndicator";
import { Styles } from "../../assets/types/Style";
import TagStore from "../../store/TagStore";
import SearchStore from "../../store/SearchStore";
import PostDetailStore from "../../store/PostDetailStore";
import "./CommentScreen.scss";
import AbDetailStore from "../../store/AbDetailStore";
import { getMagazineByFeedId } from "../../api/magazine/api";
import MagazineStore from "../../store/MagazineStore";

const CommentScreen = observer(() => {
  const location = useLocation();
  const params = location.state;
  const feedId = useParams().feedId || (params?.feedId as number);

  const {
    loading,
    setCommentTargetAndRetrieveCommentList,
    deleteComment,
    deleteTargetCommentInfo,
    setDeleteTargetCommentInfo,
    setAfterUpdateHandler,
  } = CommentStore;
  const { retrieveAbDetail } = AbDetailStore;

  useEffect(() => {
    setCommentTargetAndRetrieveCommentList({
      authorId: params.feedAuthorId as string,
      commentTypeCode: params.feedType as CommentTypeCode,
      commentTargetId: params.feedId as number,
      postIndex: params.postIndex as number,
      listIndex: params.listIndex as number,
    });

    if (params?.onLeave !== undefined) {
      // navigation.addListener('beforeRemove', (e: any) => {
      //   params?.onLeave()
      // })
    }

    // setAfterUpdateHandler()
    if (params.feedType == CommentTypeCode.QNA)
      setAfterUpdateHandler(() => void retrieveAbDetail(feedId as number));
    if (params.feedType == CommentTypeCode.MAGAZINE)
      setAfterUpdateHandler(async () => {
        const updatedMagazine = await getMagazineByFeedId(feedId as number);
        MagazineStore.updateItem({
          ...params?.item,
          commentCount: updatedMagazine?.magazine?.commentCount,
        });
      });

    return () => {
      const newPost: Post = {
        ...FeedDetailStore.postEmptyData,
        commentCount: CommentStore.commentCount,
      };

      PostStore.updatePostStoreList(
        UserActTypeCode.UPDATE_COMMENT,
        params.feedId,
        newPost
      );
      FeedDetailStore.updatePostInAllFeedListStore(
        UserActTypeCode.UPDATE_COMMENT,
        params.feedId,
        newPost
      );
      MainStore.updateFeed(
        UserActTypeCode.UPDATE_COMMENT,
        params.feedId,
        newPost
      );
      MyHomeStore.sync(
        params.feedId,
        AccountFeedTypeCode.POST,
        UserActTypeCode.UPDATE_COMMENT,
        newPost
      );
      TagStore.sync(
        params.feedId,
        FeedTypeCode.POST,
        UserActTypeCode.UPDATE_COMMENT,
        newPost
      );
      SearchStore.syncPost(
        params.feedId,
        UserActTypeCode.UPDATE_COMMENT,
        newPost
      );
      PostDetailStore.syncCommentCount(params.feedId, newPost);
    };
  }, [params, setAfterUpdateHandler, setCommentTargetAndRetrieveCommentList]);

  return (
    <div id="CommentScreen">
      <div
        className="comment_list"
        style={{
          flex: 1,
          ...Colors.background_fff,
        }}
        // edges={['top', 'left', 'right']}
        aria-label="Comment Screen"
      >
        <div
          style={{ flex: 1 }}
          // behavior={Platform.OS === 'ios' ? 'padding' : undefined}
          // keyboardVerticalOffset={
          //   Platform.OS === 'ios' ? 0 : getStatusBarHeight() + 10
          // }
        >
          {!loading ? (
            <CommentList />
          ) : (
            <CustomActivityIndicator
              isDarkSpinner={true}
              // size={'large'} style={{ flex: 1 }}
            />
          )}
          <CommentFooter />
        </div>
      </div>

      {deleteTargetCommentInfo && (
        <NotificationModal
          isVisible={deleteTargetCommentInfo !== null}
          title={t("screen.notificationModal.label.alert")}
          contents1={t("screen.notificationModal.message.deleteMessage")}
          useTwoButton={true}
          isBlack={false}
          defaultButtonText={t("screen.notificationModal.button.OK")}
          extraButtonText={t("screen.notificationModal.button.cancel")}
          onClickDefaultButton={() => {
            void deleteComment(deleteTargetCommentInfo);
          }}
          onClickExtraButton={() => {
            setDeleteTargetCommentInfo(null);
          }}
          onRequestClose={() => {
            setDeleteTargetCommentInfo(null);
          }}
        />
      )}
    </div>
  );
});

const styles: Styles = {
  member_editor: {
    ...FlexBoxs.flex_1,
  },
  write_box: {
    ...Spacings.padding_top_20,
  },

  wrap: {
    ...Spacings.padding_top_bottom_32,
    ...Spacings.padding_right_left_20_responsive,
  },
  top_wrap: {
    ...Spacings.padding_top_20,
  },
  label_text: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  edit_wrap: {
    ...Spacings.margin_top_20,
  },
  top_edit_wrap: {
    ...Spacings.margin_top_24,
  },
  sub_label_text: {
    ...Texts.contents_text_31,
    ...Colors.font_999,
    ...Spacings.margin_bottom_6,
  },
  email: {
    ...FlexBoxs.horizon,
  },
  sns_icon: {
    ...Spacings.margin_left_6,
  },
  contents_text: {
    ...Texts.contents_text_38,
    ...Colors.font_222,
  },
  password: {
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
  },
  phone: {
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
  },
  phone_edit: {
    ...Colors.background_fafafa,
    ...Spacings.padding_top_bottom_14,
    ...Spacings.padding_left_20,
    ...Spacings.padding_right_14,
  },
  btns: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  clear_btn: {
    ...Spacings.margin_right_14,
  },
  modify_btn: {
    ...Spacings.padding_top_bottom_8,
    ...Spacings.padding_right_left_8,
    ...Spacings.border_radius_6,
    borderColor: "rgba(34, 34, 34, 0.1)",
    borderWidth: 1,
  },
  modify_btn_text: {
    ...Texts.btn_text_3,
    ...Colors.font_a760b1,
  },
  withdraw_btn_text: {
    ...Texts.btn_text_1,
    ...Texts.text_underline,
    ...Texts.font_weight_500,
    ...Colors.font_999,
  },

  bar: {
    ...Colors.background_f5f5f5,
    height: 6,
  },

  toast: {
    ...Spacings.border_radius_10,
    ...Spacings.padding_right_left_20,
    ...Spacings.padding_top_bottom_20,
    ...Arranges.center_v_h,
    width: "90%",
    backgroundColor: "black",
    borderWidth: 1,
    // ...Platform.select({
    //   ios: {
    //     shadowColor: '#000',
    //     shadowOffset: { width: 4, height: 4 },
    //     shadowOpacity: 0.3,
    //   },
    //   android: { elevation: 5 },
    // }),
  },
  toast_text: {
    ...Texts.contents_text_22,
  },

  reportSheet: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_bottom_16,
  },
  reportConfirmSheet: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_bottom_10,
  },
  close_btn: {
    marginLeft: 350,
    //...Positions.absolute,
    // top: 6,
    //right: 6,
  },
  title_wrap: {
    ...Arranges.center_v_h,
    ...Spacings.margin_bottom_12,
    height: 32,
  },
  title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  reason_list: {
    ...Spacings.padding_right_left_20,
  },
  reason_item: {
    ...Spacings.margin_bottom_18,
  },
  reason_text: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    ...Spacings.margin_left_12,
  },
  reason_btn: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },

  confirm_btn: {
    ...Positions.absolute,
    ...Positions.bottom,
    ...Positions.left,
    ...Positions.right,
    ...Arranges.center_v_h,
    ...Colors.background_c4c4c4,
  },
  confirm_active_btn: {
    ...Colors.background_222,
  },
  confirm_btn_text: {
    ...Texts.contents_text_33,
    ...Colors.font_fff,
  },
  warning_wrap: {
    ...Spacings.padding_right_left_20,
    ...Spacings.margin_bottom_16,
  },
  warning_text: {
    ...Texts.contents_text_31,
    ...Colors.font_999,
  },

  logo_wrap: {
    ...Arranges.center_v_h,
    ...Spacings.margin_top_24,
  },
  alert_text: {
    ...Texts.contents_text_28,
    ...Spacings.margin_top_6,
  },

  guide_list: {
    ...Spacings.margin_top_32,
  },
  guide_item: {
    ...FlexBoxs.horizon,
    ...Spacings.margin_bottom_14,
    ...Spacings.padding_left_20_responsive,
    ...Spacings.padding_right_25_responsive,
  },
  guide_text: {
    ...Spacings.margin_left_4,
    ...Texts.contents_text_31,
    ...Colors.font_666666,
  },
  dot: {
    ...Colors.background_666,
    ...Spacings.border_radius_6,
    marginTop: 7,
    width: 3,
    height: 3,
  },
  agree_wrap: {
    ...Spacings.padding_right_left_16_responsive,
  },
  agree: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  agree_text: {
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    ...Spacings.margin_left_8,
  },
  agree_warn_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_ea174a,
    ...Spacings.margin_left_30,
    ...Spacings.margin_top_6,
  },
  text_0: {
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Spacings.margin_bottom_12,
    ...Colors.font_222,
  },
  text_1: {
    width: 160,
    ...Texts.font_size_14,
    ...Arranges.center_v_h,
    ...Spacings.margin_bottom_26,
    ...Arranges.text_align_center,
    ...Texts.font_letter_spacing_056,
    ...Colors.font_222,
  },
  modal_body: {
    ...FlexBoxs.vertical,
    ...Arranges.center_h,
  },
};

export default CommentScreen;
