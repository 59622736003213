import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CustomCarousel from "../../../components/common/CustomCarousel";
import { SearchProductItem } from "../../../api/brand/model";
import { ReactComponent as MallTag } from "../../../assets/icons/mall_sale_tag.svg";
import { useEffect, useState } from "react";

const BrandProducRankingtList = observer(({ renderData }: { renderData: SearchProductItem[][] }) => {
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

  const { t } = useTranslation();

  const handleRankPress = (productId: number) => () => {
    goTo(`/Product/${productId}`);
  };

  const [screenWidth, setScreenWidth] = useState<number>();
  const root = document.getElementById("root");

  useEffect(() => {
    if(root) {
      setScreenWidth(root.clientWidth * 0.9)
    }
  }, [root])

  const popularRankItem = ({ item, index }: { item: SearchProductItem[]; index: number }) => {
    return (
      item.length > 0 && (
        <div style={styles.column}>
          {item.map((_item, _index) => (
            <div
              key={_item.productId}
              style={styles.row}
              onClick={handleRankPress(_item.productId || 0)}
            >
              <FastImageWithFallback
                style={styles.home_app_img}
                source={{ uri: `${PUBLIC_BUCKET_URL}${_item.thumbnailFilePath || ""}` }}
              />
              <div style={{ ...styles.bx_ranking_num, ...(index === 0 ? styles.bx_ranking_num_strong : {}) }}>
                <p style={styles.popular_ranking_text1}>{index * 3 + _index + 1}</p>
              </div>
              <div style={styles.popular_ranking_info_wrap}>
                <p style={styles.popular_ranking_text2}>
                  {_item.productName}
                </p>
                <div style={styles.home_app_num}>
                  <p style={styles.popular_ranking_text3}>
                    {_item.manufacturerNameKor || ""}
                  </p>
                  {_item.manufacturerNameKor != null && (
                    <p style={{ ...styles.popular_ranking_text3, ...styles.bar_text }}></p>
                  )}
                  <p style={styles.popular_ranking_text3}>
                    {_item.productModelName}
                  </p>
                </div>
                {_item.lifecareProductId && _item.lifecareProductId != null ? (
                  <div style={{ marginTop: 6, marginBottom: 2 }}>
                    <span style={styles.on_sale_tag_wrap}>
                      <span style={styles.mall_tag}>
                        <MallTag />
                      </span>
                      <span style={styles.on_sale_tag_text}>{t("screen.main.label.onSale")}</span>
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </div>
      )
    );
  };

  return renderData && renderData.length > 0 ? (
    <>
      <div style={styles.popular_ranking}>
        <div style={styles.bx_in_title}>
          <div style={styles.title_text}>
            <div style={styles.bg_title} />
            <p style={styles.title_text_st1}>{t("screen.brandCommunity.label.productRanking")}</p>
          </div>
        </div>
        <div style={styles.popular_ranking_list}>
          <CustomCarousel
            otherSettings={{
              className: "center",
              swipeToSlide: true,
              arrows: false,
              dots: false,
              variableWidth: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            }}
            accessibilityLabel={"Popular product rank carousel"}
            data={renderData}
            renderItem={(item: any, index: number) => <div key={index} style={{width: screenWidth}}>{popularRankItem({ item, index })}</div>}
          />
        </div>
      </div>
    </>
  ) : (
    <></>
  );
});

const styles: Styles = {
  contents_wrap: {
    top: 0,
    ...Colors.background_fff,
    ...Positions.absolute,
    ...Positions.left,
    ...Positions.right,
    ...Positions.bottom,
    height: "110%",
    zIndex: 100,
  },
  popular_ranking: {
    ...Spacings.margin_top_12,
  },
  title_wrap: {
    ...Spacings.margin_left_20,
    ...FlexBoxs.wrap,
  },
  popular_ranking_title_text: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    zIndex: 10,
  },
  title_background: {
    ...Colors.background_f7e237,
    ...Positions.absolute,
    ...Positions.bottom,
    left: -2,
    right: -2,
    height: 8,
  },
  popular_ranking_list: {
    ...Spacings.margin_top_14,
  },
  column: {
    ...Spacings.margin_right_18,
    paddingLeft: 16
  },
  row: {
    ...Positions.relative,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.margin_bottom_10,
    alignItems: "center",
  },
  home_app_img: {
    ...Spacings.margin_right_12,
    ...Spacings.border_radius_6,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: "rgba(0, 0, 0, 0.1)",
    width: 100,
    height: 100,
  },
  bx_ranking_num: {
    ...Positions.absolute,
    left: 0,
    top: 0,
    width: 22,
    height: 22,
    borderTopLeftRadius: 6,
    borderBottomRightRadius: 6,
    backgroundColor: "#999",
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  bx_ranking_num_strong: {
    backgroundColor: "#222",
  },
  popular_ranking_info_wrap: {
    width: '100%'
  },
  popular_ranking_text1: {
    fontSize: 12,
    letterSpacing: -0.48,
    color: "#fff",
    marginBottom: 2,
  },
  ranking_active_text: {
    ...Colors.font_222,
  },
  ranking_inactive_text: {
    color: "rgba(34, 34, 34, 0.3)",
  },
  popular_ranking_text2: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_333,
    marginBottom: 4,
    overflow: 'hidden', 
    display: '-webkit-box', 
    WebkitLineClamp: '2', 
    lineClamp: '2', 
    WebkitBoxOrient: 'vertical'
  },
  popular_ranking_text3: {
    ...Texts.contents_text_31,
    ...Colors.font_999,
    overflow: 'hidden', 
    display: '-webkit-box', 
    WebkitLineClamp: '1', 
    lineClamp: '1', 
    WebkitBoxOrient: 'vertical'
  },
  bar_text: {
    width: 1,
    height: 10,
    backgroundColor: "#d9d9d9",
    marginHorizontal: 6,
  },
  home_app_num: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h
  },
  on_sale_tag_wrap: {
    backgroundColor: "#F0DFF2",
    borderRadius: 4,
  },
  on_sale_tag_text: {
    color: "#BE69C2",
    fontSize: 10,
    fontWeight: "700",
    letterSpacing: -0.4,
    paddingLeft: 1,
    paddingRight: 6,
    paddingVertical: 1,
    textAlign: "right",
  },
  mall_tag: {
    paddingLeft: 6,
    paddingVertical: 3,
  },
  bg_title: {
    height: 8,
    ...Positions.absolute,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#F7E237",
  },
  bx_in_title: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.margin_bottom_6,
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_top_30,
  },
  title_text: {
    ...Positions.relative,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  title_text_st1: {
    fontSize: 18,
    fontWeight: "500",
    color: "#222",
    letterSpacing: -0.72,
    zIndex: 1,
  },
  first_text: {
    ...Colors.font_be69c3,
  },
  title_info_text: {
    ...Spacings.margin_top_4,
    ...Texts.contents_text_0,
  },
  popular_wrap: {
    flex: 1,
  },
  popular_title_wrap: {
    ...Spacings.margin_bottom_16,
    ...Spacings.margin_top_32,
  },
  popular_title: {
    ...Texts.contents_text_40,
    ...Texts.font_weight_bold,
    ...Colors.font_222,
    ...Spacings.padding_left_right_20,
  },
  popular_product_item: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Colors.border_color_eee,
    ...Spacings.padding_bottom_24,
    ...Spacings.margin_bottom_24,
    ...Spacings.margin_left_right_16,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: 'solid'
  },
  item_item_info: {
    flexGrow: 1,
    flexShark: 1,
    flexBasis: "auto",
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v,
  },
  num_img_wrap: {
    flexGrow: 0,
    flexShark: 0,
    flexBasis: "auto",
    ...Positions.relative,
    backgroundColor: "#000",
  },
  btn_cart_icon: {
    flexGrow: 0,
    flexShark: 0,
    flexBasis: "auto",
    backgroundColor: "#F7F4FA",
    width: 40,
    height: 40,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_25,
  },
  popular_product_number: {
    width: 22,
    height: 22,
    zIndex: 9,
    top: 2,
    left: 2,
    ...Positions.absolute,
    ...Spacings.border_radius_4,
    ...Colors.background_f7e237,
    ...Arranges.center_v_h,
  },
  popular_product_number_text: {
    ...Texts.contents_text_47,
    ...Colors.font_222,
  },
  popular_product_image: {
    width: 72,
    height: 72,
    ...Spacings.border_radius_6,
  },
  border_image: {
    borderWidth: 1,
    borderColor: "rgba(34, 34, 34, 0.1)",
  },
  small_bar: {
    ...Spacings.margin_right_left_6,
    height: 10,
    width: 1,
    ...Colors.background_d9d9d9,
  },

  empty_product: {
    height: 200,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.margin_top_50,
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
    ...Spacings.margin_bottom_60,
  },
  empty_wrap: {
    ...Positions.fullscreen,
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_h,
  },
  ranking_list_info: {
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    height: "100%",
  },
  ranking_num_img: {
    ...Positions.relative,
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "auto",
  },
  pd_info_wrap: {
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
    paddingLeft: 14,
    paddingRight: 14,
    ...FlexBoxs.flex,
    ...Arranges.center_v
  },
  pd_info_title: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
  },
  pd_sub_info_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    marginTop: 5,
  },
  pd_sub_info_text: {
    ...Texts.contents_text_0,
    ...Colors.font_999,
    maxWidth: 100,
  },
};

export default BrandProducRankingtList;
