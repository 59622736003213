import { useState, useEffect } from "react";
import { ReactComponent as HalfStar } from "../../assets/icons/star_half.svg";
import { ReactComponent as FullStar } from "../../assets/icons/star_full.svg";
import { ReactComponent as EmptyStar } from "../../assets/icons/star_empty.svg";
import { observer } from "mobx-react";
import CommonStore from "../../store/CommonStore";

const StarRating = observer(
  ({
    defaultPoint,
    startXPoistion,
    onRating,
    onScrollAvailable,
  }: {
    defaultPoint?: number;
    startXPoistion?: number; // rating 뷰의 시작 x position 정보
    onRating?: (pointValue: number) => void;
    onScrollAvailable?: (isAvailabe: boolean) => void; // rating 밖의 뷰 스크롤 컨트롤
  }) => {
    const [point, setPoint] = useState(
      defaultPoint !== undefined ? defaultPoint : 0
    );
    const [numbers, setNumbers] = useState([0, 0, 0, 0, 0]);
    const { offsetLeftApp } = CommonStore

    useEffect(() => {
      setPoint(defaultPoint !== undefined ? defaultPoint : 0);
    }, [defaultPoint]);

    const calcPointing = (position: number) => {
      const imageSize = 48;
      const contentSize = imageSize + 8;
      if (startXPoistion === undefined) startXPoistion = 0;

      const isMobile = window.navigator.userAgent.indexOf("Mobile") !== -1;

      const newPosition = isMobile ? position : position - offsetLeftApp;

      const x = newPosition - startXPoistion;
      let tempPoint = 0;
      if (x < 2) {
        tempPoint = 0;
        setPoint(0);
      } else if (x > 1 && x < imageSize / 2) {
        tempPoint = 0.5;
        setPoint(0.5);
      } else if (x > imageSize / 2 && x < imageSize) {
        tempPoint = 1;
        setPoint(1);
      } else if (x > contentSize && x < contentSize + imageSize / 2) {
        tempPoint = 1.5;
        setPoint(1.5);
      } else if (
        x > contentSize + imageSize / 2 &&
        x < contentSize + imageSize
      ) {
        tempPoint = 2;
        setPoint(2);
      } else if (x > contentSize * 2 && x < contentSize * 2 + imageSize / 2) {
        tempPoint = 2.5;
        setPoint(2.5);
      } else if (
        x > contentSize * 2 + imageSize / 2 &&
        x < contentSize * 2 + imageSize
      ) {
        tempPoint = 3;
        setPoint(3);
      } else if (x > contentSize * 3 && x < contentSize * 3 + imageSize / 2) {
        tempPoint = 3.5;
        setPoint(3.5);
      } else if (
        x > contentSize * 3 + imageSize / 2 &&
        x < contentSize * 3 + imageSize
      ) {
        tempPoint = 4;
        setPoint(4);
      } else if (x > contentSize * 4 && x < contentSize * 4 + imageSize / 2) {
        tempPoint = 4.5;
        setPoint(4.5);
      } else if (x > contentSize * 4 + imageSize / 2) {
        tempPoint = 5;
        setPoint(5);
      } else {
        tempPoint = -1;
      }
      if (onRating !== undefined && tempPoint === point) {
        setPoint(0);
        onRating(0);
      } else if (onRating !== undefined && tempPoint >= 0) onRating(tempPoint);
    };

    const renderStar = (value: number, index: number) => {
      if (value === 0)
        return (
          <button
            aria-label={`star${index}`}
            key={index}
            onClick={(event) => {
              calcPointing(event.nativeEvent.pageX);
            }}
          >
            <EmptyStar style={styles.star} />
          </button>
        );
      else if (value === 0.5)
        return (
          <button
            aria-label={`star${index}`}
            key={index}
            onClick={(event) => {
              calcPointing(event.nativeEvent.pageX);
            }}
          >
            <HalfStar style={styles.star} />
          </button>
        );
      else
        return (
          <button
            aria-label={`star${index}`}
            key={index}
            onClick={(event) => {
              calcPointing(event.nativeEvent.pageX);
            }}
          >
            <FullStar style={styles.star} />
          </button>
        );
    };

    useEffect(() => {
      const calcNumbers = () => {
        switch (point) {
          case 0:
            setNumbers([0, 0, 0, 0, 0]);
            break;
          case 0.5:
            setNumbers([0.5, 0, 0, 0, 0]);
            break;
          case 1:
            setNumbers([1, 0, 0, 0, 0]);
            break;
          case 1.5:
            setNumbers([1, 0.5, 0, 0, 0]);
            break;
          case 2:
            setNumbers([1, 1, 0, 0, 0]);
            break;
          case 2.5:
            setNumbers([1, 1, 0.5, 0, 0]);
            break;
          case 3:
            setNumbers([1, 1, 1, 0, 0]);
            break;
          case 3.5:
            setNumbers([1, 1, 1, 0.5, 0]);
            break;
          case 4:
            setNumbers([1, 1, 1, 1, 0]);
            break;
          case 4.5:
            setNumbers([1, 1, 1, 1, 0.5]);
            break;
          case 5:
            setNumbers([1, 1, 1, 1, 1]);
            break;
          default:
            break;
        }
      };
      calcNumbers();
    }, [defaultPoint, point]);

    return (
      <div style={styles.container}>
        <div>
          <div style={{ flexDirection: "row" }} aria-label="Star rating">
            {numbers.map((item, index) => {
              return renderStar(item, index);
            })}
          </div>
        </div>
      </div>
    );
  }
);

export default StarRating;

const styles = {
  container: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  star: {
    alignItems: "flex-start",
    marginRight: 8,
  },
};
