import React, { useEffect } from "react";
import { observer } from "mobx-react";
import CommonStore from "../../store/CommonStore";
import WriteButtonStore from "../../store/WriteButtonStore";
import { ReactComponent as TopIcon } from "../../assets/icons/top_btn.svg";
import TabStore from "../../store/TabStore";
import "./CommonTopButton.scss";

const CommonTopButton = observer(() => {
  const currentRouteName = CommonStore.currentRouteName;

  useEffect(() => {
    CommonStore.resetTopButtonOption();
  }, [currentRouteName]);

  // return CommonStore.topButtonOption.isShow ? (
  //   <>
  //     <div
  //       className="top_button"
  //       style={{
  //         bottom: WriteButtonStore.showWriteButton
  //           ? WriteButtonStore.bottom + 50
  //           : TabStore.tabFocused
  //           ? WriteButtonStore.bottom
  //           : // : Platform.OS === "ios"
  //             // ? 100
  //             70,
  //       }}
  //       onClick={() => {
  //         // if (CommonStore.topButtonOption.onTopPress) {
  //         //   CommonStore.topButtonOption.onTopPress();
  //         // } else if (CommonStore.topButtonOption.ref?.current?.scrollToOffset) {
  //         //   CommonStore.topButtonOption.ref?.current?.scrollToOffset({
  //         //     offset: 0,
  //         //     animated: true,
  //         //   });
  //         // } else {
  //         //   CommonStore.topButtonOption.ref?.current?.scrollTo({
  //         //     y: 0,
  //         //     animated: true,
  //         //   });
  //         // }
  //       }}
  //     >
  //       <TopIcon />
  //     </div>
  //   </>
  // ) : (
  //   <></>
  // );

  return (
    <div
      id="CommonTopButton"
      style={{
        bottom: WriteButtonStore.showWriteButton
          ? WriteButtonStore.bottom + 50
          : WriteButtonStore.bottom,
      }}
      onClick={() => {
        // if (CommonStore.topButtonOption.onTopPress) {
        //   CommonStore.topButtonOption.onTopPress();
        // } else if (CommonStore.topButtonOption.ref?.current?.scrollToOffset) {
        //   CommonStore.topButtonOption.ref?.current?.scrollToOffset({
        //     offset: 0,
        //     animated: true,
        //   });
        // } else {
        //   CommonStore.topButtonOption.ref?.current?.scrollTo({
        //     y: 0,
        //     animated: true,
        //   });
        // }
        window.scrollTo(0, 0);
      }}
    >
      <TopIcon />
    </div>
  );
});

export default CommonTopButton;
