import { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { BannerInfo } from '../../../api/banner/model'
import { parseQueryParam } from '../../../utils/queryParam'
import { getBannerList } from '../../../api/banner/api'
import { BannerTypeCodes } from '../../../constants/Banner.enum'

import MainHeader from '../../../components/common/MainHeader'
import CommonBanner from '../../../components/common/CommonBanner'

import { getLargeCategoryInfo } from '../../../api/mallCategory/api'
import { CategoryInfo } from '../../../api/mallCategory/model'
import MallMainCategoryList from './MallMainCategoryList'
import MallMainPopularProduct from './MallMainPopularProduct'
import ProductList from './ProductList'
import MallMainRecommend from './MallMainRecommend'
import MallMainCategoryBestTab from './MallMainCategoryBestTab'

import { Arranges, Colors, Texts } from '../../../assets/styles'
import BookmarkStore from '../../../store/BookmarkStore'
import { FeedTypeCode } from '../../../constants/Feed'
import { track } from '../../../hooks/tracker/TrackFunction'
import {
  getDisplayProduct,
  getMallCategoryBestProduct,
  getMallPostedPopularProductPostdetail,
  getPostedPopularProduct,
  getRecommendProduct,
} from '../../../api/mall/api'
import {
  DisplayProduct,
  PostedPopularProduct,
  RecommendCategoryProductList,
} from '../../../api/mall/model'
import AuthStore from '../../../store/AuthStore'
import CommonStore from '../../../store/CommonStore'
import {
  goToLifecareCartPage,
  goToLifecareProductPage,
  goToLifecareSearchPage,
} from '../../../utils/mall'
import { useEffectOnlyOnce } from '../../../hooks/UseEffectOnlyOnce'
import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from '../../../constants/ActHistory'
import { sendActHistory } from '../../../api/data/api'
import { ActHistoryRequest } from '../../../api/data/model'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ListWithStickyTabs from '../../../components/common/ListWithStickyTabs'
import classes from './MallMain.module.scss'
import MainTabNavigator from '../../../components/common/MainTabNavigator'

const MallMain = observer(() => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const stickyTabsRef = useRef<HTMLDivElement>(null)

  const [mainBannerList, setMainBannerList] = useState<BannerInfo[]>([])
  const [categoryList, setCategoryList] = useState<CategoryInfo[]>([])
  const [popularProductList, setPopularProductList] = useState<
    PostedPopularProduct[]
  >([])
  const [mainBandBannerList, setMainBandBannerList] = useState<BannerInfo[]>([])
  const [pickList01, setPickList01] = useState<DisplayProduct[]>([])
  const [pickList02, setPickList02] = useState<DisplayProduct[]>([])
  const [pickList03, setPickList03] = useState<DisplayProduct[]>([])
  const [pickList04, setPickList04] = useState<DisplayProduct[]>([])

  const [recommendList01, setRecommendList01] =
    useState<RecommendCategoryProductList>()
  const [recommendList02, setRecommendList02] =
    useState<RecommendCategoryProductList>()
  const [recommendList03, setRecommendList03] =
    useState<RecommendCategoryProductList>()
  const [mainADBannerList, setMainADBannerList] = useState<BannerInfo[]>([])

  const [currentCategoryBestTab, setCurrentCategoryBestTab] =
    useState<CategoryInfo>()
  const [categoryBestProductList, setCategoryBestProductList] = useState<
    DisplayProduct[]
  >([])

  const [dataChangeToggle, setDataChangeToggle] = useState<boolean>(false)
  const [currentY, setCurrentY] = useState<number>(0)

  const { saveBookmark, cancelBookmark } = BookmarkStore

  // useScrollToTop(stickyTabsRef)
  // const isFocused = useIsFocused()

  useEffectOnlyOnce(() => {
    // [5950] 툴팁 노출 제어
    const isShowMallTutorial = localStorage.getItem('isShowMallTutorial')
    if (isShowMallTutorial && isShowMallTutorial === 'false') {
      const showMallMainTooltip2 = localStorage.getItem('showMallMainTooltip2')
      if (!showMallMainTooltip2 || showMallMainTooltip2 !== 'SUCCESS') {
        CommonStore.setIsShowMallMainTooltip2(true)
      }
    }
  })

  const getMallMainBanner = async () => {
    const response = await getBannerList(BannerTypeCodes.MALL_MAIN_BANNER)
    if (response !== null) {
      const res = response.map((bannerInfo) => ({
        ...bannerInfo,
        bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
      }))
      res.length > 10 && res.splice(10)
      setMainBannerList(res)
    } else {
      setMainBannerList([])
    }
  }
  const getMallMainBandBanner = async () => {
    const response = await getBannerList(BannerTypeCodes.MALL_MAIN_BAND_BANNER)
    if (response !== null) {
      const res = response.map((bannerInfo) => ({
        ...bannerInfo,
        bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
      }))
      res.length > 5 && res.splice(5)
      setMainBandBannerList(res)
    } else {
      setMainBandBannerList([])
    }
  }
  const getMallMainADBanner = async () => {
    const response = await getBannerList(BannerTypeCodes.MALL_DISPLAY_AD)
    if (response !== null) {
      const res = response.map((bannerInfo) => ({
        ...bannerInfo,
        bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
      }))
      res.length > 5 && res.splice(5)
      setMainADBannerList(res)
    } else {
      setMainADBannerList([])
    }
  }

  const getLargeCategoryList = async () => {
    const res = await getLargeCategoryInfo()
    if (res !== null) {
      setCategoryList(res)
      onChangeCategoryBestTab(res[0])
    } else {
      setCategoryList([])
    }
  }

  const getPopularProductList = async () => {
    const res = await getPostedPopularProduct()
    if (res !== null) {
      setPopularProductList(res)
    } else {
      setPopularProductList([])
    }
  }

  const getPickList = async () => {
    const res = await getDisplayProduct()

    if (res != null) {
      if (res.length > 30) {
        setPickList01(res.slice(0, 10))
        setPickList02(res.slice(10, 20))
        setPickList03(res.slice(20, 30))
        setPickList04(res.slice(30, res.length))
      } else if (res.length > 20) {
        setPickList01(res.slice(0, 10))
        setPickList02(res.slice(10, 20))
        setPickList03(res.slice(20, res.length))
        setPickList04([])
      } else if (res.length > 10) {
        setPickList01(res.slice(0, 10))
        setPickList02(res.slice(10, res.length))
        setPickList03([])
        setPickList04([])
      } else {
        setPickList01(res)
        setPickList02([])
        setPickList03([])
        setPickList04([])
      }
    } else {
      setPickList01([])
      setPickList02([])
      setPickList03([])
      setPickList04([])
    }
  }

  const getRecommendList = async () => {
    const memberUuid =
      AuthStore.sessionUser?.memberUuid ||
      AuthStore.tempMember?.tempMemberUUID ||
      ''

    const res = await getRecommendProduct(memberUuid)

    if (
      res !== null &&
      res.recommendCategoryProductList != null &&
      res.recommendCategoryProductList.length > 0
    ) {
      res.recommendCategoryProductList[0]
        ? setRecommendList01(res.recommendCategoryProductList[0])
        : setRecommendList01(undefined)
      res.recommendCategoryProductList[1]
        ? setRecommendList02(res.recommendCategoryProductList[1])
        : setRecommendList02(undefined)
      res.recommendCategoryProductList[2]
        ? setRecommendList03(res.recommendCategoryProductList[2])
        : setRecommendList03(undefined)
    } else {
      setRecommendList01(undefined)
      setRecommendList02(undefined)
      setRecommendList03(undefined)
    }
  }

  const getCategoryBestProductList = async (displayCategoryId: number) => {
    const res = await getMallCategoryBestProduct(displayCategoryId, 0, 20)

    if (res != null) {
      setCategoryBestProductList(res)
    } else {
      setCategoryBestProductList([])
    }
  }

  const goToFeedScreen = (
    targetIndex: number,
    productId: number,
    lifecareProductId: number
  ) => {
    void getMallPostedPopularProductPostdetail(10, '0', productId).then(
      (res) => {
        let productName: string =
          res.data.feedFindVOList[0].productList[0].productName || ''
        productName =
          productName.length > 15
            ? productName.substring(0, 16) + '...'
            : productName

        navigate('/posts/feed', {
          state: {
            itemIndex: targetIndex,
            feedParentTypeCode: 'MALL_MAIN_POPULAR_PRODUCT',
            extraData: {
              initPostList: res.data.feedFindVOList,
              productId: productId,
              productName: productName,
              lifecareProductId: lifecareProductId,
            },
          },
        })
      }
    )
  }

  const createActHistoryRequestForMallProductRead = (
    item: DisplayProduct | PostedPopularProduct,
    typeCode: ActHistoryTypeCode,
    sectionCode: ActSectionCode
  ) => {
    return {
      actHistoryTypeCode: typeCode,
      actHistoryTargetId: item.lifecareProductId.toString(),
      actPageCode: ActPageCode.MALL_MAIN,
      actSectionCode: sectionCode,
      attr1: item.productId.toString() ?? '',
      attr2: item.sortOrder?.toString() ?? '',
      attr3: item.consumerPrice?.toString() ?? '',
      attr4: item.salesPrice?.toString() ?? '',
      attr5: item.discountRate?.toString() ?? '',
    } as ActHistoryRequest
  }

  const goToProduct = (
    item: DisplayProduct,
    categoryName: string,
    order: number
  ) => {
    track('click_mall_curation', {
      productId: item.productId,
      product_name: item.productName,
      category_name: categoryName,
    })

    const request = createActHistoryRequestForMallProductRead(
      item,
      ActHistoryTypeCode.MALL_PRODUCT_READ,
      ActSectionCode.RECOMMENDED_CATEGORY
    )
    request.attr6 = item.averageStar?.toString()
    request.attr7 = item.reviewTotCnt?.toString()
    request.attr8 = categoryName
    request.attr9 = order.toString()
    void sendActHistory(request)

    goToLifecareProductPage(item.lifecareProductId)
  }

  const callActHistoryByBookmark = (
    item: DisplayProduct | PostedPopularProduct,
    sectionCode: ActSectionCode,
    sort: number
  ) => {
    const log = createActHistoryRequestForMallProductRead(
      item,
      ActHistoryTypeCode.BOOKMARK,
      sectionCode
    )
    log.attr2 = sort.toString()
    void sendActHistory(log)
  }

  const handleBookmark = async (
    bookmarkYn: string,
    targetId: number,
    listKey?: string
  ) => {
    if (bookmarkYn === 'N') {
      await cancelBookmark(
        targetId,
        FeedTypeCode.PRODUCT,
        () => {
          if (listKey === 'MallMainPopularProduct') {
            const temp = popularProductList
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setPopularProductList(temp)
          }
          if (listKey === 'Pick01') {
            const temp = pickList01
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setPickList01(temp)
          }
          if (listKey === 'Pick02') {
            const temp = pickList02
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setPickList02(temp)
          }
          if (listKey === 'Pick03') {
            const temp = pickList03
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setPickList02(temp)
          }
          if (listKey === 'Pick04') {
            const temp = pickList04
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setPickList02(temp)
          }
          if (listKey === 'MallMainRecommend01') {
            const temp = recommendList01
            temp?.recommendProductList.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setRecommendList01(temp)
          }
          if (listKey === 'MallMainRecommend02') {
            const temp = recommendList02
            temp?.recommendProductList.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setRecommendList02(temp)
          }
          if (listKey === 'MallMainRecommend03') {
            const temp = recommendList03
            temp?.recommendProductList.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setRecommendList03(temp)
          }
          if (listKey === 'categoryBestProductList') {
            const temp = categoryBestProductList
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setCategoryBestProductList(temp)
          }
        },
        () => {
          // fail
        }
      )
    } else {
      await saveBookmark(
        targetId,
        FeedTypeCode.PRODUCT,
        () => {
          if (listKey === 'MallMainPopularProduct') {
            const foundItem = popularProductList.find(
              (item) => targetId === item.productId
            )
            if (foundItem) {
              foundItem.bookmarkYn = 'Y'
              const index = popularProductList.indexOf(foundItem)
              callActHistoryByBookmark(
                foundItem,
                ActSectionCode.POPULAR_PRODUCT,
                index
              )
            }
            setPopularProductList(popularProductList)
          }
          if (listKey === 'Pick01') {
            const foundItem = pickList01.find(
              (item) => targetId === item.productId
            )
            if (foundItem) {
              foundItem.bookmarkYn = 'Y'
              const index = pickList01.indexOf(foundItem)
              callActHistoryByBookmark(
                foundItem,
                ActSectionCode.PICK_220S,
                index
              )
            }
            setPickList01(pickList01)
          }
          if (listKey === 'Pick02') {
            const foundItem = pickList02.find(
              (item) => targetId === item.productId
            )
            if (foundItem) {
              foundItem.bookmarkYn = 'Y'
              const index = pickList02.indexOf(foundItem)
              callActHistoryByBookmark(
                foundItem,
                ActSectionCode.PICK_220S,
                index
              )
            }
            setPickList02(pickList02)
          }
          if (listKey === 'Pick03') {
            const foundItem = pickList03.find(
              (item) => targetId === item.productId
            )
            if (foundItem) {
              foundItem.bookmarkYn = 'Y'
              const index = pickList03.indexOf(foundItem)
              callActHistoryByBookmark(
                foundItem,
                ActSectionCode.PICK_220S,
                index
              )
            }
            setPickList02(pickList03)
          }
          if (listKey === 'Pick04') {
            const foundItem = pickList04.find(
              (item) => targetId === item.productId
            )
            if (foundItem) {
              foundItem.bookmarkYn = 'Y'
              const index = pickList04.indexOf(foundItem)
              callActHistoryByBookmark(
                foundItem,
                ActSectionCode.PICK_220S,
                index
              )
            }
            setPickList02(pickList04)
          }
          if (listKey === 'MallMainRecommend01') {
            const foundItem = recommendList01?.recommendProductList.find(
              (item) => targetId === item.productId
            )
            if (foundItem) {
              foundItem.bookmarkYn = 'Y'
              const index =
                recommendList01?.recommendProductList.indexOf(foundItem)
              callActHistoryByBookmark(
                foundItem,
                ActSectionCode.RECOMMENDED_CATEGORY,
                index ?? 0
              )
            }
            setRecommendList01(recommendList01)
          }
          if (listKey === 'MallMainRecommend02') {
            const foundItem = recommendList02?.recommendProductList.find(
              (item) => targetId === item.productId
            )
            if (foundItem) {
              foundItem.bookmarkYn = 'Y'
              const index =
                recommendList02?.recommendProductList.indexOf(foundItem)
              callActHistoryByBookmark(
                foundItem,
                ActSectionCode.RECOMMENDED_CATEGORY,
                index ?? 0
              )
            }
            setRecommendList02(recommendList02)
          }
          if (listKey === 'MallMainRecommend03') {
            const foundItem = recommendList03?.recommendProductList.find(
              (item) => targetId === item.productId
            )
            if (foundItem) {
              foundItem.bookmarkYn = 'Y'
              const index =
                recommendList03?.recommendProductList.indexOf(foundItem)
              callActHistoryByBookmark(
                foundItem,
                ActSectionCode.RECOMMENDED_CATEGORY,
                index ?? 0
              )
            }
            setRecommendList03(recommendList03)
          }
          if (listKey === 'categoryBestProductList') {
            const foundItem = categoryBestProductList.find(
              (item) => targetId === item.productId
            )
            if (foundItem) {
              foundItem.bookmarkYn = 'Y'
              const index = categoryBestProductList.indexOf(foundItem)
              callActHistoryByBookmark(
                foundItem,
                ActSectionCode.CATEGORY_BEST,
                index ?? 0
              )
            }
            setCategoryBestProductList(categoryBestProductList)
          }
        },
        () => {
          // fail
        }
      )
    }
    setDataChangeToggle((x) => !x)
  }

  const trackGoToProduct = (item: DisplayProduct) => {
    track('click_mall_pickitem', {
      product_id: item.productId,
      product_name: item.productName,
      category_name: item.displayCategoryName || '',
    })

    const request = createActHistoryRequestForMallProductRead(
      item,
      ActHistoryTypeCode.MALL_PRODUCT_READ,
      ActSectionCode.PICK_220S
    )
    request.attr6 = item.averageStar?.toString()
    request.attr7 = item.reviewTotCnt?.toString()
    void sendActHistory(request)

    goToLifecareProductPage(item.lifecareProductId)
  }

  const onChangeCategoryBestTab = (
    item: CategoryInfo,
    sort?: number,
    scrollToIndex?: boolean
  ) => {
    setCurrentCategoryBestTab(item)
    void getCategoryBestProductList(item.displayCategoryId)
    if (sort && sort >= 0) {
      callActHistory(
        ActHistoryTargetId.MALL_CATEGORY,
        ActPageCode.MALL_MAIN,
        ActSectionCode.CATEGORY,
        sort.toString(),
        item.displayCategoryName
      )
    }
    // TODO:
    // scrollToIndex &&
    //   stickyTabsRef?.current?.scrollToIndex({
    //     animated: true,
    //     index: 2,
    //     viewOffset: 40,
    //   })
  }

  const init = () => {
    void getMallMainBanner()
    void getLargeCategoryList()
    void getPopularProductList()
    void getMallMainBandBanner()
    void getPickList()
    void getRecommendList()
    void getMallMainADBanner()
  }

  const onRefresh = () => {
    void init()
  }

  useEffectOnlyOnce(() => {
    void init()

    const actHistory: ActHistoryRequest = {
      actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
      actPageCode: ActPageCode.MALL_MAIN,
      actSourcePageCode: CommonStore.fixedPreviousRouteName,
      attr1: (currentY === 0).toString(),
    }
    void sendActHistory(actHistory)
  })

  const callActHistory = (
    targetId: string,
    actPageCode: string,
    actSectionCode: string,
    attr1: string,
    attr2: string
  ) => {
    const log: ActHistoryRequest = {
      actHistoryTypeCode: ActHistoryTypeCode.PAGE_CLICK,
      actHistoryTargetId: targetId,
      actPageCode: actPageCode,
      actSectionCode: actSectionCode,
      attr1: attr1,
      attr2: attr2,
    }
    void sendActHistory(log)
  }

  return (
    <div className={classes.main_wrap}>
      <MainHeader
        onSearch={() => {
          track('click_mall_search', {})
          CommonStore.callActHistory(
            ActHistoryTypeCode.PAGE_CLICK,
            ActHistoryTargetId.MALL_SEARCH,
            ActPageCode.TOP_GNB
          )
          goToLifecareSearchPage()
        }}
        onCart={() => {
          CommonStore.callActHistory(
            ActHistoryTypeCode.PAGE_CLICK,
            ActHistoryTargetId.ORDER_HISTORY,
            ActPageCode.TOP_GNB
          )
          goToLifecareCartPage()
        }}
      />

      <ListWithStickyTabs
        contentScrollRef={stickyTabsRef}
        onScroll={(event) => {
          setCurrentY(event.nativeEvent.view?.scrollY || 0)
          CommonStore.disabledMallMainTooltip()
        }}
        scrollableHeader={
          <div>
            <CommonBanner
              bannerList={mainBannerList}
              bannerSizeStyle={{
                width: '100%',
                height: 250,
                position: 'relative',
              }}
              showTitleIndicator
              showTextIndicator
              titleTextStyle={{
                ...Texts.contents_text_45,
                ...Texts.font_weight_500,
                ...Colors.font_000,
              }}
              textIndexStyle={{
                color: '#fff',
                fontSize: 12,
                fontWeight: '500',
              }}
            />
            <MallMainCategoryList categoryList={categoryList} />
            <div className={classes.line_thick} />

            <MallMainPopularProduct
              listKey={'MallMainPopularProduct'}
              renderData={popularProductList}
              goToFeedScreen={(
                index: number,
                productId: number,
                lifecareProductId: number,
                item?: any
              ) => {
                if (item) {
                  track('click_mall_popularitem', {
                    product_id: productId,
                    content_id: item.feedId,
                  })

                  const request = {
                    actHistoryTypeCode: ActHistoryTypeCode.POST_READ,
                    actHistoryTargetId: item.feedId.toString(),
                    actPageCode: ActPageCode.MALL_MAIN,
                    actSectionCode: ActSectionCode.POPULAR_PRODUCT,
                    attr1: index.toString(),
                  } as ActHistoryRequest
                  void sendActHistory(request)
                }

                void goToFeedScreen(index, productId, lifecareProductId)
              }}
              goToProduct={(item: DisplayProduct) => {
                track('click_mall_popularitem_post', {
                  product_id: item.productId || '',
                  product_name: item.productName ?? '',
                  content_order: item.sortOrder,
                })

                const request = createActHistoryRequestForMallProductRead(
                  item,
                  ActHistoryTypeCode.MALL_PRODUCT_READ,
                  ActSectionCode.POPULAR_PRODUCT
                )
                void sendActHistory(request)
                goToLifecareProductPage(item.lifecareProductId)
              }}
              handleBookmark={(
                bookmarkYn: string,
                targetId: number,
                listKey?: string
              ) => void handleBookmark(bookmarkYn, targetId, listKey)}
              dataChangeToggle={dataChangeToggle}
            />
            <CommonBanner
              bannerList={mainBandBannerList}
              bannerSizeStyle={{
                width: '100%',
                height: 84,
                position: 'relative',
              }}
              showTitleIndicator
              textIndexContainerStyle={{
                position: 'absolute',
                display: 'flex',
                ...Arranges.center_v_h,
                backgroundColor: 'rgba(34,34,34,0.4)',
                borderRadius: 28,
                width: 29,
                height: 19,
                zIndex: 10,
                top: 6,
                right: 16,
              }}
              textPositionStyle={{
                paddingLeft: 20,
                paddingBottom: 16,
              }}
              textIndexStyle={{
                color: '#fff',
                fontSize: 10,
                fontWeight: '500',
              }}
              titleTextStyle={{
                fontSize: 16,
                fontWeight: 500,
                letterSpacing: -0.64,
              }}
              showTextIndicator
            />
            {mainBandBannerList.length > 0 && (
              <div style={{ marginBottom: 32 }} />
            )}
            {pickList01 && pickList01.length > 0 && (
              <ProductList
                title={t('screen.mall.title.220pick')}
                renderData={pickList01}
                goToProduct={trackGoToProduct}
                handleBookmark={(
                  bookmarkYn: string,
                  targetId: number,
                  listKey?: string
                ) => void handleBookmark(bookmarkYn, targetId, listKey)}
                listKey={'Pick01'}
                dataChangeToggle={dataChangeToggle}
              />
            )}
            <MallMainRecommend
              listKey={'MallMainRecommend01'}
              renderData={recommendList01?.recommendProductList || []}
              categoryName={recommendList01?.category2 || ''}
              goToProduct={(data, categoryName) => {
                goToProduct(data, categoryName, 1)
              }}
              handleBookmark={(
                bookmarkYn: string,
                targetId: number,
                listKey?: string
              ) => void handleBookmark(bookmarkYn, targetId, listKey)}
            />
            {pickList02 && pickList02.length > 0 && (
              <ProductList
                renderData={pickList02}
                goToProduct={trackGoToProduct}
                handleBookmark={(
                  bookmarkYn: string,
                  targetId: number,
                  listKey?: string
                ) => void handleBookmark(bookmarkYn, targetId, listKey)}
                listKey={'Pick02'}
                dataChangeToggle={dataChangeToggle}
              />
            )}
            <MallMainRecommend
              listKey={'MallMainRecommend02'}
              renderData={recommendList02?.recommendProductList || []}
              categoryName={recommendList02?.category2 || ''}
              goToProduct={(data, categoryName) => {
                goToProduct(data, categoryName, 2)
              }}
              handleBookmark={(
                bookmarkYn: string,
                targetId: number,
                listKey?: string
              ) => void handleBookmark(bookmarkYn, targetId, listKey)}
            />
            {pickList03 && pickList03.length > 0 && (
              <ProductList
                renderData={pickList03}
                goToProduct={trackGoToProduct}
                handleBookmark={(
                  bookmarkYn: string,
                  targetId: number,
                  listKey?: string
                ) => void handleBookmark(bookmarkYn, targetId, listKey)}
                listKey={'Pick03'}
                dataChangeToggle={dataChangeToggle}
              />
            )}
            <MallMainRecommend
              listKey={'MallMainRecommend03'}
              renderData={recommendList03?.recommendProductList || []}
              categoryName={recommendList03?.category2 || ''}
              goToProduct={(data, categoryName) => {
                goToProduct(data, categoryName, 3)
              }}
              handleBookmark={(
                bookmarkYn: string,
                targetId: number,
                listKey?: string
              ) => void handleBookmark(bookmarkYn, targetId, listKey)}
            />
            {pickList04 && pickList04.length > 0 && (
              <ProductList
                renderData={pickList04}
                goToProduct={trackGoToProduct}
                handleBookmark={(
                  bookmarkYn: string,
                  targetId: number,
                  listKey?: string
                ) => void handleBookmark(bookmarkYn, targetId, listKey)}
                listKey={'Pick04'}
                dataChangeToggle={dataChangeToggle}
              />
            )}

            <CommonBanner
              bannerList={mainADBannerList}
              bannerSizeStyle={{
                width: '100%',
                height: 84,
                position: 'relative',
              }}
              textIndexContainerStyle={{
                position: 'absolute',
                ...Arranges.center_v_h,
                display: 'flex',
                borderWidth: 1,
                borderColor: '#c4c4c4',
                backgroundColor: 'rgba(255,255,255,0.4)',
                borderRadius: 29,
                width: 29,
                height: 18,
                zIndex: 10,
                top: 14,
                right: 14,
              }}
              textPositionStyle={{
                paddingLeft: 20,
                paddingBottom: 16,
              }}
              textIndexStyle={{
                color: '#666',
                fontSize: 10,
                fontWeight: '700',
              }}
              titleTextStyle={{
                fontSize: 16,
                fontWeight: 500,
                letterSpacing: -0.64,
              }}
              showTextIndicator
            />
            <div className={classes.bx_title}>
              <div style={{ position: 'relative' }}>
                <div className={classes.bg_title}></div>
                <span className={classes.txt_title}>
                  {t('screen.mall.title.categoryBest')}
                </span>
              </div>
            </div>
          </div>
        }
        stickyTabsCustom={
          <MallMainCategoryBestTab
            renderData={categoryList}
            currentTab={currentCategoryBestTab}
            onChange={(item: CategoryInfo, index: number) => {
              onChangeCategoryBestTab(item, index, true)
            }}
          />
        }
        stickyItems={
          <ProductList
            listKey={'categoryBestProductList'}
            renderData={categoryBestProductList}
            goToProduct={(item: DisplayProduct) => {
              track('click_mall_categorybest', {
                product_id: item.productId,
                product_name: item.productName,
                category_name:
                  currentCategoryBestTab?.displayCategoryName ?? '',
              })

              const request = createActHistoryRequestForMallProductRead(
                item,
                ActHistoryTypeCode.MALL_PRODUCT_READ,
                ActSectionCode.CATEGORY_BEST
              )
              request.attr6 = item.averageStar?.toString()
              request.attr7 = item.reviewTotCnt?.toString()
              request.attr8 = currentCategoryBestTab?.displayCategoryName
              void sendActHistory(request)

              goToLifecareProductPage(item.lifecareProductId)
            }}
            handleBookmark={(
              bookmarkYn: string,
              targetId: number,
              listKey?: string
            ) => void handleBookmark(bookmarkYn, targetId, listKey)}
            handleMore={() => {
              callActHistory(
                ActHistoryTargetId.MALL_CATEGORY_DETAIL,
                ActPageCode.MALL_MAIN,
                ActSectionCode.CATEGORY,
                currentCategoryBestTab?.sortOrder.toString() ?? '',
                currentCategoryBestTab?.displayCategoryName ?? ''
              )
              navigate(
                `/mall/category/large/${currentCategoryBestTab?.displayCategoryId}`
              )
            }}
            moreBtnLabel={
              currentCategoryBestTab?.displayCategoryName
                ? currentCategoryBestTab?.displayCategoryName +
                  ' ' +
                  t('screen.mall.button.more')
                : t('screen.mall.button.more')
            }
          />
        }
        showScrollToTop={true}
        onRefresh={onRefresh}
      />

      <MainTabNavigator />
    </div>
  )
})

export default MallMain
