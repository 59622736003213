import { action, computed, makeObservable, observable } from "mobx";
import {
  getAccountFeed,
  getAccountFullFeed,
  getAccountMission,
} from "../api/account/api";
import { AccountFeedItem, AccountRequest } from "../api/account/model";
import { Post } from "../api/feed/model";
import { Mission } from "../api/mission/model";
import { Qna } from "../api/qna/model";
import { AccountFeedTypeCode, MY_HOME_FEED_COUNT } from "../constants/Account";
import { UserActTypeCode } from "../constants/FeedType.enum";
import { MISSION_TYPE_PATH } from "../constants/mission";
import { PAGE_SIZE } from "./AbListStore";

class AccountStore {
  constructor() {
    makeObservable(this);
  }
  @observable _userId = "";

  @observable _loading = false;

  @observable _postList: AccountFeedItem[] = [];
  @observable _qnaList: AccountFeedItem[] = [];
  @observable _abList: AccountFeedItem[] = [];
  @observable _magazineList: AccountFeedItem[] = [];
  @observable _missionList: Mission[] = [];

  @observable _postPageIndex = 0;
  @observable _qnaPageIndex = 0;
  @observable _abPageIndex = 0;
  @observable _magazinePageIndex = 0;
  @observable _missionPageIndex = 0;

  @observable _postCanLoadMore = true;
  @observable _qnaCanLoadMore = true;
  @observable _abCanLoadMore = true;
  @observable _magazineCanLoadMore = true;
  @observable _missionCanLoadMore = true;

  @observable errorMessage?: string;
  @observable showReportPopup = false;

  @computed get postList() {
    return this._postList;
  }

  @computed get qnaList() {
    return this._qnaList;
  }

  @computed get abList() {
    return this._abList;
  }

  @computed get magazineList() {
    return this._magazineList;
  }

  @computed get missionList() {
    return this._missionList;
  }

  @computed get postPageIndex() {
    return this._postPageIndex;
  }

  @computed get qnaPageIndex() {
    return this._qnaPageIndex;
  }

  @computed get abPageIndex() {
    return this._abPageIndex;
  }

  @computed get magazinePageIndex() {
    return this._magazinePageIndex;
  }

  @computed get missionPageIndex() {
    return this._missionPageIndex;
  }

  @computed get postCanLoadMore() {
    return this._postCanLoadMore;
  }
  @computed get qnaCanLoadMore() {
    return this._qnaCanLoadMore;
  }

  @computed get abCanLoadMore() {
    return this._abCanLoadMore;
  }

  @computed get magazineCanLoadMore() {
    return this._magazineCanLoadMore;
  }

  @computed get missionCanLoadMore() {
    return this._missionCanLoadMore;
  }

  @computed get userId() {
    return this._userId;
  }

  @computed get loading() {
    return this._loading;
  }

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    );
  }

  @computed get isShowReport() {
    return this.showReportPopup;
  }

  @action setUserId = (userId: string) => {
    this._userId = userId;
  };

  @action setPostList = (list: AccountFeedItem[]) => {
    this._postList = list;
  };

  @action setQnaList = (list: AccountFeedItem[]) => {
    this._qnaList = list;
  };

  @action setAbList = (list: AccountFeedItem[]) => {
    this._abList = list;
  };

  @action setMagazineList = (list: AccountFeedItem[]) => {
    this._magazineList = list;
  };

  @action setMissionList = (list: Mission[]) => {
    this._missionList = list;
  };

  @action addPostList = (list: AccountFeedItem[]) => {
    this._postList = [...this._postList, ...list];
  };

  @action addQnaList = (list: AccountFeedItem[]) => {
    this._qnaList = [...this._qnaList, ...list];
  };

  @action addAbList = (list: AccountFeedItem[]) => {
    this._abList = [...this._abList, ...list];
  };

  @action addMagazineList = (list: AccountFeedItem[]) => {
    this._magazineList = [...this._magazineList, ...list];
  };

  @action addMissionList = (list: Mission[]) => {
    this._missionList = [...this._missionList, ...list];
  };

  @action setPostPageIndex = (index: number) => {
    this._postPageIndex = index;
  };

  @action setQnaPageIndex = (index: number) => {
    this._qnaPageIndex = index;
  };

  @action setAbPageIndex = (index: number) => {
    this._abPageIndex = index;
  };

  @action setMagazinePageIndex = (index: number) => {
    this._magazinePageIndex = index;
  };

  @action setMissionPageIndex = (index: number) => {
    this._missionPageIndex = index;
  };

  @action setPostCanLoadMore = (canLoadMore: boolean) => {
    this._postCanLoadMore = canLoadMore;
  };

  @action setQnaCanLoadMore = (canLoadMore: boolean) => {
    this._qnaCanLoadMore = canLoadMore;
  };

  @action setAbCanLoadMore = (canLoadMore: boolean) => {
    this._abCanLoadMore = canLoadMore;
  };

  @action setMagazineCanLoadMore = (canLoadMore: boolean) => {
    this._magazineCanLoadMore = canLoadMore;
  };

  @action setMissionCanLoadMore = (canLoadMore: boolean) => {
    this._missionCanLoadMore = canLoadMore;
  };

  @action getAccountMission = async (start: number) => {
    const missionRes = await getAccountMission(
      MISSION_TYPE_PATH.CORPORATE,
      this._userId,
      MY_HOME_FEED_COUNT,
      start
    );

    if (missionRes?.data?.list !== undefined) {
      const canLoadMore =
        missionRes?.data?.list?.length < MY_HOME_FEED_COUNT ? false : true;
      this.setMissionCanLoadMore(canLoadMore);
      this.addMissionList(missionRes?.data?.list);
    }
    this.setLoading(false);
  };

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action updateAb = (feedId: number, ab: any) => {
    const abIdx = this._abList.findIndex((x) => x.feedId === feedId);
    if (abIdx !== -1) {
      const abDetail = this._abList[abIdx];

      abDetail.feedTitle = ab.feedTitle;
      abDetail.adesc = ab.adesc;
      abDetail.bdesc = ab.bdesc;
      abDetail.athumbnailFilePath = ab.athumbnailFilePath;
      abDetail.bthumbnailFilePath = ab.bthumbnailFilePath;
      abDetail.abFlag = ab.myAbFlag;

      this._abList[abIdx] = abDetail;
    }
  };

  @action getAccountFeed = async (start: number, type: AccountFeedTypeCode) => {
    this.setLoading(true);
    if (start == 0) {
      if (type === AccountFeedTypeCode.POST) {
        this.setPostList([]);
      } else if (type === AccountFeedTypeCode.QNA) {
        this.setQnaList([]);
      } else if (type === AccountFeedTypeCode.AB) {
        this.setAbList([]);
      } else if (type === AccountFeedTypeCode.MAGAZINE) {
        this.setMagazineList([]);
      } else if (type === AccountFeedTypeCode.MISSION) {
        this.setMissionList([]);
      }
    }

    const params: AccountRequest = {
      memberUuid: this._userId,
      blocked: "N",
      pageSize: MY_HOME_FEED_COUNT,
      pageIndex: start,
    };

    if (type === "missions") {
      void this.getAccountMission(start);
      return;
    }
    const res =
      type == AccountFeedTypeCode.POST
        ? await getAccountFullFeed(params)
        : await getAccountFeed(type, params);
    const canLoadMore = res?.list?.length < MY_HOME_FEED_COUNT ? false : true;
    if (res?.list) {
      if (type === AccountFeedTypeCode.POST) {
        this.setPostCanLoadMore(canLoadMore);
        this.addPostList(res?.list);
      } else if (type === AccountFeedTypeCode.QNA) {
        this.setQnaCanLoadMore(canLoadMore);
        this.addQnaList(res?.list);
      } else if (type === AccountFeedTypeCode.AB) {
        this.setAbCanLoadMore(canLoadMore);
        this.addAbList(res?.list);
      } else if (type === AccountFeedTypeCode.MAGAZINE) {
        this.setMagazineCanLoadMore(canLoadMore);
        this.addMagazineList(res?.list);
      }
    }
    this.setLoading(false);
  };

  @action getFeedFromList = (
    type: AccountFeedTypeCode | Mission,
    feedId: number
  ) => {
    if (type === AccountFeedTypeCode.POST) {
      return this.postList.filter((x) => x.feedId === feedId)[0];
    } else if (type === AccountFeedTypeCode.QNA) {
      return this.qnaList.filter((x) => x.feedId === feedId)[0];
    } else if (type === AccountFeedTypeCode.AB) {
      return this.abList.filter((x) => x.feedId === feedId)[0];
    } else if (type === AccountFeedTypeCode.MAGAZINE) {
      return this.magazineList.filter((x) => x.feedId === feedId)[0];
    } else if (type === AccountFeedTypeCode.MISSION) {
      return this.missionList.filter((x) => x.missionId === feedId)[0];
    }
  };

  @action removeFeedFromList = (
    type: AccountFeedTypeCode | Mission,
    feedId: number
  ) => {
    if (type === AccountFeedTypeCode.POST) {
      this._postList = this._postList.filter((x) => x.feedId !== feedId);
    } else if (type === AccountFeedTypeCode.QNA) {
      this._qnaList = this._qnaList.filter((x) => x.feedId !== feedId);
    } else if (type === AccountFeedTypeCode.AB) {
      this._abList = this._abList.filter((x) => x.feedId !== feedId);
    } else if (type === AccountFeedTypeCode.MAGAZINE) {
      this._magazineList = this._magazineList.filter(
        (x) => x.feedId !== feedId
      );
    } else if (type === AccountFeedTypeCode.MISSION) {
      this._missionList = this._missionList.filter(
        (x) => x.missionId !== feedId
      );
    }
  };

  @action updatePost = (feedId: number, data: Post) => {
    const index = this._postList.findIndex((x) => x.feedId === feedId);

    if (index != -1) {
      const feed = this._postList[index];

      feed.thumbnailFilePath = data.thumbnailFilePath;
      feed.originalFilePath = data.filePath;
      feed.commentCount = data.commentCount;
      feed.likeYn = data.likeYn;
      feed.likeCount = data.likeCount;
      feed.bookmarkYn = data.bookmarkYn;
      feed.imageCount = data.imageCount;
      feed.viewCount = data.viewCount;
      feed.contents = data.contents;
      feed.feedTitle = data.feedTitle;
      feed.feedContentCount = data.feedContentCount;
      feed.feedTypeCode = data.feedTypeCode;
      feed.advantage = data.advantage;
      feed.disadvantage = data.disadvantage;
      feed.tagList = data.tagList;
      feed.fileList = data.fileList;
      feed.productList = data.productList;
      feed.createdDatetime = data.createdDatetime ?? "";
      this._postList[index] = { ...feed };
      //   this._postList.splice(index, 1, feed);
    }
  };

  @action updateLikeAndBookmark = (
    feedId: number,
    actionType: UserActTypeCode
  ) => {
    const index = this._postList.findIndex((x) => x.feedId == feedId);

    if (index != -1) {
      const post = this._postList[index];
      const newPost = { ...post };

      switch (actionType) {
        case UserActTypeCode.SAVE_LIKE:
          newPost.likeYn = "Y";
          newPost.likeCount = (newPost.likeCount ?? 0) + 1;

          break;
        case UserActTypeCode.CANCEL_LIKE:
          newPost.likeYn = "N";
          newPost.likeCount = (newPost.likeCount ?? 1) - 1;

          break;
        case UserActTypeCode.SAVE_BOOKMARK:
          newPost.bookmarkYn = "Y";

          break;
        case UserActTypeCode.CANCEL_BOOKMARK:
          newPost.bookmarkYn = "N";

          break;
      }
      this._postList[index] = { ...newPost };
      //      this._postList.splice(index, 1, newPost);
    }
  };

  @action updateCommentCount = (feedId: number, data: Post) => {
    const index = this._postList.findIndex((x) => x.feedId === feedId);

    if (index != -1) {
      const feed = this._postList[index];
      feed.commentCount = data.commentCount;

      this._postList.splice(index, 1, feed);
    }
  };

  @action updateQna = (feedId: number, data: any) => {
    const index = this._qnaList.findIndex((x) => x.feedId === feedId);

    if (index != -1) {
      const feed = this._qnaList[index];
      feed.feedTitle = data.feedTitle;
      feed.thumbnailFilePath = data.thumbnailFilePath;
      feed.originalFilePath = data.filePath;
      feed.commentCount = data.commentCount;

      this._qnaList.splice(index, 1);
      this._qnaList.splice(index, 0, feed);
    }
  };

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action clear = () => {
    this.errorMessage = "";
    this.showReportPopup = false;

    this._userId = "";
    this._loading = false;

    this._postList = [];
    this._qnaList = [];
    this._abList = [];
    this._magazineList = [];
    this._missionList = [];

    this._postPageIndex = 0;
    this._qnaPageIndex = 0;
    this._abPageIndex = 0;
    this._magazinePageIndex = 0;
    this._missionPageIndex = 0;

    this._postCanLoadMore = true;
    this._qnaCanLoadMore = true;
    this._abCanLoadMore = true;
    this._magazineCanLoadMore = true;
    this._missionCanLoadMore = true;
  };

  @action setPageIndex = (start: number, feedType: AccountFeedTypeCode) => {
    switch (feedType) {
      case AccountFeedTypeCode.POST:
        this.setPostPageIndex(start);
        break;
      case AccountFeedTypeCode.QNA:
        this.setQnaPageIndex(start);
        break;
      case AccountFeedTypeCode.AB:
        this.setAbPageIndex(start);
        break;
    }
  };

  @action removeFeed = (feedId: number, feedType: AccountFeedTypeCode) => {
    switch (feedType) {
      case AccountFeedTypeCode.POST: {
        const postIndex = this._postList.findIndex((x) => x.feedId === feedId);

        if (postIndex !== -1) {
          this._postList.splice(postIndex, 1);
        }
        break;
      }
      case AccountFeedTypeCode.QNA: {
        const qnaIndex = this._qnaList.findIndex((x) => x.feedId === feedId);

        if (qnaIndex !== -1) {
          this._qnaList.splice(qnaIndex, 1);
        }
        break;
      }
      case AccountFeedTypeCode.AB: {
        const abIndex = this._abList.findIndex((x) => x.feedId === feedId);

        if (abIndex !== -1) {
          this._abList.splice(abIndex, 1);
        }
        break;
      }
    }
  };
}

export default AccountStore;
