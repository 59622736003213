import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { FeedTypeCode, FeedTypePath } from "../constants/Feed";
import { saveBookmark, cancelBookmark } from "../api/bookmark/api";
import { BookmarkFeedItem, BookmarkRequest } from "../api/bookmark/model";
import { getBookmarkFeeds } from "../api/bookmark/api";
import CommonStore from "./CommonStore";
import { Post } from "../api/feed/model";

const INITIAL_VALUE = {
  _bookmarkFeeds: new Map<FeedTypeCode, BookmarkFeedItem[]>(),
  _selectedBookmarkFeeds: new Map<FeedTypeCode, Set<number>>(),
  _isEdit: new Map<FeedTypeCode, boolean>([
    [FeedTypeCode.POST, false],
    [FeedTypeCode.QNA, false],
    [FeedTypeCode.AB, false],
    [FeedTypeCode.PRODUCT, false],
    [FeedTypeCode.ARTICLE, false],
  ]),
  _isTotalChecked: new Map<FeedTypeCode, boolean>([
    [FeedTypeCode.POST, false],
    [FeedTypeCode.QNA, false],
    [FeedTypeCode.AB, false],
    [FeedTypeCode.PRODUCT, false],
    [FeedTypeCode.ARTICLE, false],
  ]),
  _currentPage: new Map<FeedTypeCode, number>([
    [FeedTypeCode.POST, 0],
    [FeedTypeCode.QNA, 0],
    [FeedTypeCode.AB, 0],
    [FeedTypeCode.PRODUCT, 0],
    [FeedTypeCode.ARTICLE, 0],
  ]),
  _canLoadMoreByType: new Map<FeedTypeCode, boolean>([
    [FeedTypeCode.POST, true],
    [FeedTypeCode.QNA, true],
    [FeedTypeCode.AB, true],
    [FeedTypeCode.PRODUCT, true],
    [FeedTypeCode.ARTICLE, true],
  ]),
  _postPageSize: 1000,
  _normalPageSize: 1000,
};

class BookmarkStore {
  constructor() {
    makeObservable(this);

    this._selectedBookmarkFeeds.set(FeedTypeCode.POST, new Set<number>([]));
    this._selectedBookmarkFeeds.set(FeedTypeCode.QNA, new Set<number>([]));
    this._selectedBookmarkFeeds.set(FeedTypeCode.AB, new Set<number>([]));
    this._selectedBookmarkFeeds.set(FeedTypeCode.PRODUCT, new Set<number>([]));
    this._selectedBookmarkFeeds.set(FeedTypeCode.ARTICLE, new Set<number>([]));
  }

  @observable loading = false;
  @observable _isLoadingMore = false;
  @observable _canLoadMore = false;
  @observable _bookmarkFeeds: Map<FeedTypeCode, BookmarkFeedItem[]> =
    INITIAL_VALUE._bookmarkFeeds;
  @observable _selectedBookmarkFeeds: Map<FeedTypeCode, Set<number>> =
    INITIAL_VALUE._selectedBookmarkFeeds;
  @observable _isEdit: Map<FeedTypeCode, boolean> = INITIAL_VALUE._isEdit;
  @observable _isTotalChecked: Map<FeedTypeCode, boolean> =
    INITIAL_VALUE._isTotalChecked;
  @observable _currentPage: Map<FeedTypeCode, number> =
    INITIAL_VALUE._currentPage;
  @observable _currentTab: FeedTypeCode = FeedTypeCode.POST;
  @observable _canLoadMoreByType: Map<FeedTypeCode, boolean> =
    INITIAL_VALUE._canLoadMoreByType;
  @observable _showTopButton = false;
  @observable _scrollY: number = 0;

  @computed get scrollY() {
    return this._scrollY;
  }

  @computed get bookmarkFeeds() {
    return this._bookmarkFeeds;
  }

  @computed get selectedBookmarkFeeds() {
    return this._selectedBookmarkFeeds;
  }

  @computed get isTotalChecked() {
    return this._isTotalChecked;
  }

  @computed get isEdit() {
    return this._isEdit;
  }

  @computed get currentTab() {
    return this._currentTab;
  }

  @computed get isLoadingMore() {
    return this._isLoadingMore;
  }

  @computed get canLoadMore() {
    return this._canLoadMore;
  }

  @computed get canLoadMoreByType() {
    return this._canLoadMoreByType;
  }

  @computed get showTopButton() {
    return this._showTopButton;
  }

  @action setScrollY = (y: number) => {
    this._scrollY = y;
  };

  @action setShowTopButton = (show: boolean) => {
    this._showTopButton = show;
  };

  @action setLoading = (isLoading: boolean) => {
    this.loading = isLoading;
  };

  @action setCurrentTab = (tab: FeedTypeCode) => {
    this._currentTab = tab;
  };

  @action setIsLoadingMore = (isLoadingMore: boolean) => {
    this._isLoadingMore = isLoadingMore;
  };

  @action setCanLoadMore = (canLoadMore: boolean) => {
    this._canLoadMore = canLoadMore;
  };

  @action setPageIndex = (type: FeedTypeCode, newIndex: number) => {
    this._currentPage.set(type, newIndex);
  };

  @action setCanLoadMoreByType = (type: FeedTypeCode, state: boolean) => {
    this._canLoadMoreByType.set(type, state);
  };

  @action setBookmarFeed = (type: FeedTypeCode, list: BookmarkFeedItem[]) => {
    this._bookmarkFeeds.set(type, list);
  };

  @action showToast = (message?: string) => {
    CommonStore.setToastOption({
      show: true,
      message1: message,
    });
  };

  @action clickEachTab = (tab: FeedTypeCode) => {
    this.setShowTopButton(false);
    this.setCurrentTab(tab);
  };

  @action updateWithNewPost = (listIndex: number, val: number) => {
    const formerPosts = this._bookmarkFeeds.get(this.currentTab);

    if (this.currentTab === FeedTypeCode.QNA && formerPosts) {
      formerPosts[listIndex].commentCount = val;
    } else if (this.currentTab === FeedTypeCode.AB && formerPosts) {
      formerPosts[listIndex].voterCount = val;
    }
  };

  @action setPostWithFeedId = (newPost: Post, feedId: number) => {
    const formerPosts = this._bookmarkFeeds.get(this.currentTab);

    const matchedIndex =
      formerPosts?.findIndex((x) => x.feedId === feedId) ?? -1;

    if (matchedIndex != -1 && formerPosts) {
      formerPosts[matchedIndex].thumbnailFilePath = newPost.thumbnailFilePath;
    }
  };

  @action updateWithPoppedPosts = (listIndex: number) => {
    const formerPosts = this._bookmarkFeeds.get(this.currentTab);
    formerPosts?.splice(listIndex, 1);
  };

  @action updateWithMultiplePoppedPosts = (bookmarkTargetIds: number[]) => {
    bookmarkTargetIds.forEach((bookmarkTargetId) => {
      const currentFeeds = this._bookmarkFeeds.get(this._currentTab);
      let filteredFeeds: BookmarkFeedItem[] | undefined = [];
      if (this._currentTab == FeedTypeCode.PRODUCT) {
        filteredFeeds = currentFeeds?.filter(
          (list) => list.productId !== bookmarkTargetId
        );
      } else {
        filteredFeeds = currentFeeds?.filter(
          (list) => list.feedId !== bookmarkTargetId
        );
      }
      filteredFeeds && this._bookmarkFeeds.set(this._currentTab, filteredFeeds);
    });
    this.clearSelectedBookmarkFeeds(this._currentTab);
  };

  @action getCanLoadMoreByType = (typeCode: FeedTypeCode) => {
    return this._canLoadMoreByType.get(typeCode);
  };

  @action getCurrentPageByType = (typeCode: FeedTypeCode) => {
    return this._currentPage.get(typeCode) || 0;
  };

  @action addBackgroundIndex = (items: BookmarkFeedItem[]) => {
    items.forEach((item: BookmarkFeedItem) => {
      item.backColorIndex = Math.floor(Math.random() * 2);
    });
  };

  @action initBookmarkPosts = async (typeCode: FeedTypeCode) => {
    this.clearBookmarkStore(); // for 100% clear
    const currentIndex: number = this._currentPage.get(typeCode) || 0;

    const bookmarkRequest: BookmarkRequest = {
      blocked: "N",
    };

    const path =
      typeCode === FeedTypeCode.POST
        ? FeedTypePath.POST
        : typeCode === FeedTypeCode.QNA
        ? FeedTypePath.QNA
        : typeCode === FeedTypeCode.AB
        ? FeedTypePath.AB
        : typeCode === FeedTypeCode.PRODUCT
        ? FeedTypePath.PRODUCT
        : typeCode === FeedTypeCode.ARTICLE
        ? FeedTypePath.ARTICLE
        : FeedTypePath.POST;

    const pageSize =
      typeCode === FeedTypeCode.POST
        ? INITIAL_VALUE._postPageSize
        : INITIAL_VALUE._normalPageSize;

    const res = await getBookmarkFeeds(path, bookmarkRequest, pageSize, 0);

    runInAction(() => {
      this.addBackgroundIndex(res);
      this._bookmarkFeeds.set(typeCode, res);
      this.setPageIndex(typeCode, currentIndex + 1);
      this.setIsLoadingMore(false);
      if (res && res.length < pageSize) {
        this.setCanLoadMoreByType(typeCode, false);
      }
    });
  };

  @action loadMoreBookmarkPosts = async (typeCode: FeedTypeCode) => {
    if (this._canLoadMoreByType.get(typeCode)) {
      const currentIndex: number = this._currentPage.get(typeCode) || 0;

      const bookmarkRequest: BookmarkRequest = {
        blocked: "N",
      };

      const path =
        typeCode === FeedTypeCode.POST
          ? FeedTypePath.POST
          : typeCode === FeedTypeCode.QNA
          ? FeedTypePath.QNA
          : typeCode === FeedTypeCode.AB
          ? FeedTypePath.AB
          : typeCode === FeedTypeCode.PRODUCT
          ? FeedTypePath.PRODUCT
          : typeCode === FeedTypeCode.ARTICLE
          ? FeedTypePath.ARTICLE
          : FeedTypePath.POST;

      const pageSize =
        typeCode === FeedTypeCode.POST
          ? INITIAL_VALUE._postPageSize
          : INITIAL_VALUE._normalPageSize;

      const res = await getBookmarkFeeds(
        path,
        bookmarkRequest,
        pageSize,
        currentIndex
      );

      runInAction(() => {
        const formerPosts = this._bookmarkFeeds.get(typeCode);
        formerPosts?.push(...res);
        this.setPageIndex(typeCode, currentIndex + 1);
        this.setIsLoadingMore(false);

        if (res && res.length < pageSize) {
          this.setCanLoadMoreByType(typeCode, false);
        }
      });
    } else {
      this.setIsLoadingMore(false);
    }
  };

  @action setIsTotalChecked = (
    feedTypeCode: FeedTypeCode,
    isTotalChecked: boolean
  ) => {
    this._isTotalChecked.set(feedTypeCode, isTotalChecked);
  };

  @action setIsEdit = (feedTypeCode: FeedTypeCode, isEdit: boolean) => {
    this._isEdit.set(feedTypeCode, isEdit);
    this._isTotalChecked.set(feedTypeCode, false);
  };

  @action saveBookmark = async (
    bookmarkTargetId: number,
    bookmarkTypeCode: string,
    successAction: () => void,
    failAction: () => void
  ) => {
    const res = await saveBookmark(bookmarkTargetId, bookmarkTypeCode);
    if (res) {
      successAction();
    } else {
      failAction();
    }
  };

  @action cancelBookmark = async (
    bookmarkTargetId: number,
    bookmarkTypeCode: string,
    successAction: () => void,
    failAction: () => void
  ) => {
    const res = await cancelBookmark(bookmarkTargetId, bookmarkTypeCode);
    if (res) {
      successAction();
    } else {
      failAction();
    }
  };

  @action clearSelectedBookmarkFeeds = (feedTypeCode: FeedTypeCode) => {
    this.selectedBookmarkFeeds
      .get(feedTypeCode)
      ?.forEach((bookmarkTargetId) => {
        this.selectedBookmarkFeeds.get(feedTypeCode)?.delete(bookmarkTargetId);
      });
  };

  @action selectBookmarkFeed = (
    feedTypeCode: FeedTypeCode,
    bookmarkTargetId: number
  ) => {
    if (this.selectedBookmarkFeeds.get(feedTypeCode)?.has(bookmarkTargetId)) {
      this.selectedBookmarkFeeds.get(feedTypeCode)?.delete(bookmarkTargetId);
    } else {
      this.selectedBookmarkFeeds.get(feedTypeCode)?.add(bookmarkTargetId);
    }
  };

  @action selectBookmarkFeeds = (
    feedTypeCode: FeedTypeCode,
    bookmarkTargetIds: number[]
  ) => {
    bookmarkTargetIds.forEach((bookmarkTargetId) => {
      if (
        !this.selectedBookmarkFeeds.get(feedTypeCode)?.has(bookmarkTargetId)
      ) {
        this.selectedBookmarkFeeds.get(feedTypeCode)?.add(bookmarkTargetId);
      }
    });
  };

  @action clearBookmarkStore = () => {
    this.clearSelectedBookmarkFeeds(FeedTypeCode.POST);
    this.clearSelectedBookmarkFeeds(FeedTypeCode.QNA);
    this.clearSelectedBookmarkFeeds(FeedTypeCode.AB);
    this.clearSelectedBookmarkFeeds(FeedTypeCode.PRODUCT);
    this.clearSelectedBookmarkFeeds(FeedTypeCode.ARTICLE);

    this.setIsEdit(FeedTypeCode.POST, false);
    this.setIsEdit(FeedTypeCode.QNA, false);
    this.setIsEdit(FeedTypeCode.AB, false);
    this.setIsEdit(FeedTypeCode.PRODUCT, false);
    this.setIsEdit(FeedTypeCode.ARTICLE, false);

    this.setPageIndex(FeedTypeCode.POST, 0);
    this.setPageIndex(FeedTypeCode.QNA, 0);
    this.setPageIndex(FeedTypeCode.AB, 0);
    this.setPageIndex(FeedTypeCode.PRODUCT, 0);
    this.setPageIndex(FeedTypeCode.ARTICLE, 0);

    this.setCanLoadMoreByType(FeedTypeCode.POST, true);
    this.setCanLoadMoreByType(FeedTypeCode.QNA, true);
    this.setCanLoadMoreByType(FeedTypeCode.AB, true);
    this.setCanLoadMoreByType(FeedTypeCode.PRODUCT, true);
    this.setCanLoadMoreByType(FeedTypeCode.ARTICLE, true);

    this.setBookmarFeed(FeedTypeCode.POST, []);
    this.setBookmarFeed(FeedTypeCode.QNA, []);
    this.setBookmarFeed(FeedTypeCode.AB, []);
    this.setBookmarFeed(FeedTypeCode.PRODUCT, []);
    this.setBookmarFeed(FeedTypeCode.ARTICLE, []);
  };

  @action updateBookmarkList = (
    feedId: number,
    type: FeedTypeCode,
    updatedFeed: any
  ) => {
    const list: BookmarkFeedItem[] = this._bookmarkFeeds.get(
      type
    ) as BookmarkFeedItem[];

    if (list === undefined || list.length === 0) return;

    const listIndex = list.findIndex((x) => x.feedId == feedId);

    const bookmark = list[listIndex];
    if (updatedFeed && updatedFeed.bookmarkYn === "Y") {
      if (type === FeedTypeCode.QNA) {
        bookmark.feedTitle = updatedFeed.feedTitle;
        bookmark.thumbnailFilePath = updatedFeed.thumbnailFilePath;
        bookmark.commentCount = updatedFeed.commentCount;
      }
    } else if (updatedFeed && updatedFeed.bookmarkYn === "N") {
      this.updateWithPoppedPosts(listIndex);
    } else {
      this.updateWithPoppedPosts(listIndex);
    }
  };
}

export default new BookmarkStore();
