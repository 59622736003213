import { action, computed, makeObservable, observable } from "mobx";
import { Post } from "../api/feed/model";
import { UserActTypeCode } from "../constants/FeedType.enum";
import { FeedParentTypeCode } from "../screens/feed/hook/UseFeedScreen";
import ListStore from "./ListStore";
import PostStore from "./PostStore";

class FeedDetailStore {
  constructor() {
    makeObservable(this);
  }

  @observable _postEmptyData: Post = {
    bookmarkYn: "",
    contents: "",
    feedContentCount: -1,
    feedContentId: -1,
    feedId: -1,
    feedTitle: "",
    fileList: [],
    tagList: [],
    likeCount: -1,
    likeYn: "N",
    currentCarouIndex: 0,
    commentCount: 0,
  };

  @observable _feedStoreList: Map<FeedParentTypeCode, ListStore<Post>> =
    new Map<FeedParentTypeCode, ListStore<Post>>();
  @observable _feedList: Post[] | undefined;

  @observable _tempPost?: Post;

  @computed get feedList() {
    return this._feedList;
  }

  @action setFeedList = (list: Post[]) => {
    this._feedList = list;
  };

  @action updateFeedList = (feedId: number, post: Post) => {
    if (this._feedList && this._feedList.length > 0) {
      const temp: Post[] = [...this._feedList];

      const index = temp.findIndex((x) => (x as Post).feedId == feedId);

      if (index != -1) {
        temp.splice(index, 1, post);
        this._feedList = [...temp];
      }
    }
  };

  @computed get postEmptyData() {
    return this._postEmptyData;
  }

  @computed get feedStoreList() {
    return this._feedStoreList;
  }

  @computed get tempPost() {
    return this._tempPost;
  }

  @action clear = () => {
    this._feedStoreList.clear();
    this._tempPost = undefined;
  };

  @action setTempPost = (post: Post | undefined) => {
    this._tempPost = post;
  };

  @action generateFeedListStore = (
    feedParentTypeCode: FeedParentTypeCode,
    extraData?: any
  ) => {
    const isExist = this._feedStoreList.has(feedParentTypeCode);

    if (isExist) {
      return this._feedStoreList.get(feedParentTypeCode);
    } else {
      const newStore = new ListStore<Post>({
        _keyForIdentify: "feedId",
        _updater: (action: any, prev: Post) => {
          let next: Post = { ...prev };
          let formerCount: number;

          switch (action) {
            case UserActTypeCode.CANCEL_LIKE:
              formerCount = prev.likeCount ?? 1;
              next = {
                ...next,
                likeCount: formerCount - 1,
                likeYn: "N",
              };
              break;
            case UserActTypeCode.SAVE_LIKE:
              formerCount = prev.likeCount ?? 0;
              next = {
                ...next,
                likeCount: formerCount + 1,
                likeYn: "Y",
              };
              break;
            case UserActTypeCode.CANCEL_BOOKMARK:
              next = {
                ...next,
                bookmarkYn: "N",
              };

              break;
            case UserActTypeCode.SAVE_BOOKMARK:
              next = {
                ...next,
                bookmarkYn: "Y",
              };
              break;
            case UserActTypeCode.UPDATE_COMMENT:
              next = {
                ...next,
                commentCount: this.tempPost?.commentCount ?? 0,
              };

              break;
            case UserActTypeCode.MODIFY:
              if (this.tempPost !== undefined) {
                next = { ...this.tempPost };
              }
              this.setTempPost(undefined);
              break;
          }

          return { next };
        },
      });

      this.setFeedListStoreList(feedParentTypeCode, newStore);

      return newStore;
    }
  };

  @action setFeedListStoreList = (
    feedParentTypeCode: FeedParentTypeCode,
    list: ListStore<Post>
  ) => {
    this._feedStoreList.set(feedParentTypeCode, list);
  };

  @action clearFeedListStoreList = (feedParentTypeCode: FeedParentTypeCode) => {
    const isExist = this._feedStoreList.has(feedParentTypeCode);

    if (isExist) {
      const listStore = this._feedStoreList.get(feedParentTypeCode);
      listStore?.clear();

      this._feedStoreList.delete(feedParentTypeCode);
    }
  };

  @action updatePostInAllFeedListStore = (
    actionType: UserActTypeCode,
    feedId: number,
    post?: Post
  ) => {
    const keys = this._feedStoreList.keys();

    if (
      (actionType === UserActTypeCode.MODIFY ||
        actionType === UserActTypeCode.UPDATE_COMMENT) &&
      post != undefined
    ) {
      this.setTempPost(post);
    }

    this._feedStoreList.forEach((feedListStore, key) => {
      feedListStore.updateList(actionType, feedId);
    });
  };
}

export default new FeedDetailStore();
