import { SuccessOrNot } from '../../constants/Common.enum'
import {
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
} from '../common/api'
import WriteCommonResponse from '../post/model'
import { Article, ArticleWriteRequest } from './model'

// 게시글 등록
export const saveArticle = async (
  item: ArticleWriteRequest,
  isLoading = true
): Promise<WriteCommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: '/v1/brand-community/article',
      body: item,
    },
    isLoading
  )
}

// 게시글 상세 조회
export const articleDetail = async (
  articleId: number,
  isLoading = true
): Promise<Article> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/brand-community/article/${articleId}`,
    },
    isLoading
  )
  return (
    response?.successOrNot === SuccessOrNot.Y ? response?.data : null
  ) as Article
}

// 게시글 삭제
export const deleteArticle = async (
  articleIds: number[],
  communityId: number,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: '/v1/brand-community/article',
      body: { articleIds, communityId },
    },
    isLoading
  )
  return (
    response?.successOrNot === SuccessOrNot.Y ? response?.data : false
  ) as boolean
}
