import { action, computed, makeObservable, observable } from 'mobx'
import { Product } from '../api/product/model'
import VideoStore from './VideoStore'
import { articleDetail, deleteArticle } from '../api/article/api'
import { Article } from '../api/article/model'

class ArticleDetailStore {
  constructor() {
    makeObservable(this)
  }

  @observable _videoStore: VideoStore = new VideoStore()

  @observable _articleDetail: Article = {
    articleId: -1,
    communityId: -1,
    articleTypeCode: '',
    articleTypeName: '',
    articleTitle: '',
    articleLikeCount: 0,
    articleViewCount: 0,
    likeYn: 'N',
    bookmarkYn: 'N',
    articleContents: '',
    articleFileGroupId: -1,
    files: [],
    productList: [],
    articleStartDate: '',
    articleEndDate: '',
    memberName: '',
    nickname: '',
    profileUrl: '',
    createdDatetime: '',
    createMemberUuid: '',
    updatedDatetime: '',
    commentCount: 0,
    subscriptionYn: 'N',
    articleCanCommentYn: 'Y',
    corpMemberYn: 'N',
  }

  @observable _productList: Product[] = []

  @observable loading = false

  @observable _isDeleted = false

  @observable _errorStatus = ''

  @computed get videoStore() {
    return this._videoStore
  }

  @computed get articleDetail() {
    return this._articleDetail
  }

  @computed get productList() {
    return this._productList
  }

  @computed get isDeleted() {
    return this._isDeleted
  }

  @computed get errorStatus() {
    return this._errorStatus
  }

  @action clearArticleDetailStore = () => {
    this._articleDetail = {
      articleId: -1,
      communityId: -1,
      articleTypeCode: '',
      articleTypeName: '',
      articleTitle: '',
      articleLikeCount: 0,
      articleViewCount: 0,
      likeYn: 'N',
      bookmarkYn: 'N',
      articleContents: '',
      articleFileGroupId: -1,
      files: [],
      productList: [],
      articleStartDate: '',
      articleEndDate: '',
      memberName: '',
      nickname: '',
      profileUrl: '',
      createdDatetime: '',
      createMemberUuid: '',
      updatedDatetime: '',
      commentCount: 0,
      subscriptionYn: 'N',
      articleCanCommentYn: 'Y',
      corpMemberYn: 'N',
    }

    this._productList = []

    this._videoStore = new VideoStore()
  }

  @action setLoading = (isLoading: boolean) => {
    this.loading = isLoading
  }

  @action setIsDeleted = (isDeleted: boolean) => {
    this._isDeleted = isDeleted
  }

  @action setErrorStatus = (statusCode: string) => {
    this._errorStatus = statusCode
  }

  @action setArticleDetail = (item: Article) => {
    item.files?.sort(function (a, b) {
      const athum = a.thumbnailYn
      const bthum = b.thumbnailYn
      if (athum > bthum) return -1
      else if (athum < bthum) return 1
      else return 0
    })
    this._articleDetail = item
  }

  @action updateCommentCount = (count: any) => {
    this._articleDetail.commentCount = count
  }

  @action setProductList = (productList: any) => {
    this._productList = productList
  }

  @action saveArticleLike = () => {
    this._articleDetail.likeYn = 'Y'
    this._articleDetail.articleLikeCount += 1
  }
  @action cancelArticleLike = () => {
    this._articleDetail.likeYn = 'N'
    this._articleDetail.articleLikeCount -= 1
  }

  @action setBookmarkYn = (bookmarkYn: string) => {
    this._articleDetail.bookmarkYn = bookmarkYn
  }

  @action deleteArticle = async (
    articleId: number,
    communityId: number
  ): Promise<boolean> => {
    return await deleteArticle([articleId], communityId)
  }

  @action getArticleDetail = async (articleId: number): Promise<boolean> => {
    const res = await articleDetail(articleId)
    if (res === null) {
      return false
    } else {
      const data: Article = res
      this.setArticleDetail(data)
      this.setProductList(data.productList)
      return true
    }
  }
}

export default new ArticleDetailStore()
