export enum SearchType {
  ALL = "ALL",
  PARTIAL = "PARTIAL",
}

export enum IsMember {
  Y = "Y",
  N = "N",
}

export enum BadgeCode {
  JOIN = "JOIN",
  LIKE = "LIKE",
  MISSION = "MISSION",
  FOLLOW = "FOLLOW",
  LIKE_BY_OTHERS = "LIKE_BY_OTHERS",
  FEED = "FEED",
  REPLY = "REPLY",
}
