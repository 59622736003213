import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as VideoIcon } from "../../../assets/icons/grid_video.svg";
import { ReactComponent as MultiImageIcon } from "../../../assets/icons/grid_multi.svg";
import { observer } from "mobx-react";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { ActHistoryRequest } from "../../../api/data/model";
import { sendActHistory } from "../../../api/data/api";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { TagFeedItem, TagFeedUIList } from "../../../api/tag/model";
import { FeedTypeCode } from "../../../constants/Feed";
import EmptyFeed from "./EmptyFeed";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import "./TagPostComponent.scss";
import clsx from "clsx";

const INNER_POST_LENGTH = 6;

const TagPostComponent = observer(
  ({
    dataRefresh,
    postList,
    isProduct,
    tagId,
    tagName,
    loading,
  }: {
    dataRefresh?: () => void;
    postList: TagFeedItem[];
    isProduct: boolean;
    tagId: number;
    tagName: string;
    loading: boolean;
  }) => {
    const [feedList, setFeedList] = useState<TagFeedUIList[]>([]);

    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

    useEffect(() => {
      setFeedList([]);
      remakePostFeedData(postList.slice(), 0);
    }, [postList]);

    const remakePostFeedData = useCallback(
      (list: TagFeedItem[], start: number) => {
        const tempTotalList: TagFeedUIList[] = [];
        let tempList: TagFeedItem[] = [];
        let feedLength = 0;
        let feedInsideLength = 6;
        let calcIndex = 0;

        if (start > 0) {
          feedLength = feedList?.length;
          feedInsideLength = feedList[feedLength - 1]?.data?.length;
        }

        list.forEach((item, index) => {
          if (
            feedInsideLength > 0 &&
            feedInsideLength < INNER_POST_LENGTH &&
            index < INNER_POST_LENGTH - feedInsideLength
          ) {
            if (index == 0) {
              tempList = feedList[feedLength - 1]?.data;
              setFeedList((prevArray) => [
                ...prevArray.slice(0, feedLength - 1),
              ]);
            }
            tempList.push(item);

            if (index === INNER_POST_LENGTH - feedInsideLength - 1) {
              tempTotalList.push({
                sectionIndex: feedLength - 1,
                data: tempList,
              });
              tempList = [];
            }
          } else {
            tempList.push(item);
            calcIndex = index - (INNER_POST_LENGTH - feedInsideLength);
            if (
              calcIndex % INNER_POST_LENGTH == 5 ||
              index == list.length - 1
            ) {
              tempTotalList.push({
                sectionIndex: feedList.length,
                data: tempList,
              });
              tempList = [];
            }
          }
        });

        tempTotalList.forEach((item) => {
          setFeedList((prevArray) => [...prevArray, item]);
        });
      },
      [feedList]
    );

    const moveToPostDetail = (idx: number, seq: number) => {
      goTo("/posts/feed", {
        state: {
          feedParentTypeCode: "TAG_POSTS",
          itemIndex: idx * 6 + seq,
          extraData: {
            tagId: tagId,
          },
        },
      });

      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.POST_READ,
        actHistoryTargetId: feedList[idx]?.data[seq].feedId.toString(),
        actPageCode: isProduct
          ? ActPageCode.TAG_PRODUCT
          : ActPageCode.TAG_GENERAL,
        actSectionCode: ActSectionCode.POST,
        attr1: seq.toString(),
      };
      void sendActHistory(actHistory);
    };

    const addContentsIcon = (idx: number, seq: number) => {
      const imageCount =
        (feedList[idx]?.data[seq].imageCount !== undefined &&
          feedList[idx]?.data[seq].imageCount) ||
        0;
      const videoCount =
        (feedList[idx]?.data[seq].videoCount !== undefined &&
          feedList[idx]?.data[seq].videoCount) ||
        0;
      return (
        <>
          {imageCount + videoCount > 1 ? (
            <div className="image_icon" aria-label="Multi contents">
              <MultiImageIcon />
            </div>
          ) : (
            <>
              {imageCount == 0 && videoCount > 0 && (
                <div className="image_icon" aria-label="Video">
                  <VideoIcon />
                </div>
              )}
            </>
          )}
        </>
      );
    };

    const getImageThumbnailPath = (idx: number, seq: number) => {
      return feedList[idx].data[seq].thumbnailFilePath || "";
    };

    const getImageOriginalPath = (idx: number, seq: number) => {
      return feedList[idx].data[seq].originalFilePath || "";
    };

    const normalGrid = (idx: number, seq: number, isFull?: boolean) => {
      return (
        <div
          onClick={() => {
            moveToPostDetail(idx, seq);
          }}
          key={`${idx}_${seq}`}
          className={clsx(
            "data_item_wrap",
            idx % 2 === 0 ? "data_item_wrap_left" : "data_item_wrap_right",
            `data_item_${seq + 1}`,
            isFull && "group_full"
          )}
          aria-label={`Post feed item ${idx}_${seq}`}
        >
          <FastImageWithFallback
            wrapperClassName="image_wrap_inner"
            source={{
              uri: `${PUBLIC_BUCKET_URL}${
                getImageThumbnailPath(idx, seq) || ""
              }`,
            }}
            fallbackImageUri={`${PUBLIC_BUCKET_URL}${
              getImageOriginalPath(idx, seq) || ""
            }`}
          />
          {addContentsIcon(idx, seq)}
        </div>
      );
    };

    const renderGrid = ({
      item,
      index,
    }: {
      item: TagFeedUIList;
      index: number;
    }) => {
      return (
        <div
          className="tag-post-list-wrapper"
          aria-label={`Account Post List Item ${index}`}
        >
          {item.data.map((item: any, seq: number) =>
            normalGrid(index, seq, item.data?.length === 6)
          )}
        </div>
      );
    };

    return (
      <>
        <div
          id="tag-post-component"
          aria-label={`Account Post List`}
        >
          {feedList && feedList.length > 0 ? (
            feedList.map((item, index) => {
              return <div className="tag-post-group">{renderGrid({ item, index })}</div>;
            })
          ) : loading ? (
            <></>
          ) : (
            <EmptyFeed
              tagName={tagName}
              type={FeedTypeCode.POST}
              dataRefresh={() => {
                dataRefresh && dataRefresh();
              }}
            />
          )}
        </div>
        {loading && <CustomActivityIndicatorSmall />}
      </>
    );
  }
);

export default TagPostComponent;
