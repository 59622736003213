import { useEffect, useState } from "react";
import { Styles } from "../../../assets/types/Style";
// import {
//   FlatList,
//   ListRenderItem,
//   PixelRatio,
//   Platform,
//   ScrollView,
//   StyleSheet,
//   Text,
//   TouchableOpacity,
//   View,
// } from "react-native";
import { useLocation } from "react-router-dom";
import { TitleHeader } from "../../../components/common";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
// import { responsiveScreenWidth, responsiveScreenHeight, responsiveWidth } from "react-native-responsive-dimensions";
import MyAppliancesStore from "../../../store/MyAppliancesStore";
//import { navigate, navigationRef } from "../../../navigation/NavigationFunction";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { bottomOffset } from "../../../utils/common";
import { showBottomToast } from "../../../utils/Toast";
import MyApplianceDeleteModal from "../../../components/modals/MyApplianceDeleteModal";
import MyAppliancesFlatList from "./MyAppliancesFlatList";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
import AuthStore from "../../../store/AuthStore";
import { CreateType } from "../../../constants/Common.enum";
// import { SmallActivityIndicator } from "../../../components/common/indicator/220ActivityIndicator";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import { useTracker } from "../../../hooks/tracker";
// import { useIsFocused } from "@react-navigation/native";

const MyAppliancesMain = observer(
  ({
    targetMemberUuid,
    nickname,
    isLoading,
  }: {
    targetMemberUuid: string;
    nickname: string;
    isLoading: boolean;
  }) => {
    const isMy =
      !targetMemberUuid ||
      targetMemberUuid === AuthStore.sessionUser?.memberUuid;
    const titleNickname = !isMy && nickname ? `${nickname}` + "의 " : "";

    const location = useLocation();
    const { t } = useTranslation();

    // const insets = useSafeAreaInsets();
    // const calHeight = insets.bottom / PixelRatio.get();

    const [isDelMode, setIsDelMode] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [showConfirmPopup, setConfirmPopup] = useState(false);
    const [isLoadingFlatList, setIsLoadingFlatList] = useState(false);

    const { track } = useTracker();
    const { setEditInfo, totalCount, mode } = MyAppliancesStore;

    // const isFocus = useIsFocused();
    const [isFocus, setIsFocused] = useState(false);

    useEffect(() => {
      // 화면 포커스 여부 감지
      setIsFocused(true);

      // 컴포넌트 언마운트 시 포커스 여부 갱신
      return () => {
        setIsFocused(false);
      };
    }, [location]);

    useEffect(() => {
      setEditInfo(0, 0, false);
      clearState();
    }, [isFocus, setEditInfo]);

    const clearState = () => {
      MyAppliancesStore.setMode("none");
      setIsEditMode(false);
      setIsDelMode(false);
    };

    const onPressTab = (code: string) => {
      setIsLoadingFlatList(true);
      clearState();
      MyAppliancesStore.clickEachTab(code);
      void MyAppliancesStore.getMyProduct().then(() => {
        setIsLoadingFlatList(false);
      });
    };

    const clickButton = (mode: string) => {
      setIsLoadingFlatList(true);

      MyAppliancesStore.setMode(mode);

      void MyAppliancesStore.getMyProduct().then((result) => {
        if (result !== "block") {
          if (isDelMode && !isEditMode) {
            setIsDelMode(false);
            clearState();
            MyAppliancesStore.unCheckedAll();
          }

          if (!isDelMode && isEditMode) {
            setIsEditMode(false);
            clearState();
            setEditInfo(0, 0, false);
          }

          if (!isDelMode && !isEditMode) {
            if (mode == "del") {
              setIsDelMode(true);
            }
            if (mode == "edit") {
              setIsEditMode(true);
            }
          }
        } else {
          MyAppliancesStore.setMode("none");
        }

        setIsLoadingFlatList(false);
      });
    };

    const changeButton = () => {
      return (
        <>
          {isDelMode && !isEditMode && (
            <button
              style={styles.header_btn}
              onClick={() => {
                clickButton("none");
              }}
            >
              <p style={styles.header_btn_text}>
                {t("screen.productAdd.button.cancel")}
              </p>
            </button>
          )}

          {!isDelMode && isEditMode && (
            <button
              style={styles.header_btn}
              onClick={() => {
                clickButton("none");
              }}
            >
              <p style={styles.header_btn_text}>
                {t("screen.productAdd.button.cancel")}
              </p>
            </button>
          )}

          {!isDelMode && !isEditMode && (
            <div style={{ display: 'flex', flexDirection: "row" }}>
              <button
                style={{ ...styles.header_btn, marginRight: 8 }}
                onClick={() => {
                  clickButton("del");
                }}
              >
                <p style={styles.header_btn_text}>{t("삭제")}</p>
              </button>
              <button
                style={styles.header_btn}
                onClick={() => {
                  clickButton("edit");
                }}
              >
                <p style={styles.header_btn_text}>{t("수정")}</p>
              </button>
            </div>
          )}
        </>
      );
    };

    const HeaderView = () => {
      return (
        <div style={styles.header}>
          <div style={styles.header_left}>
            <div style={styles.header_text_wrap}>
              <div style={styles.header_text1}>
                {t("screen.productAdd.label.productAll")}
                <p style={styles.header_text2}> {totalCount ?? 0}</p>
              </div>
            </div>
          </div>
          {isMy && totalCount > 0 && changeButton()}
        </div>
      );
    };

    const toProductSearchScreen = () => {
      track("click_regist_my_product");
      goTo("/Product/Search", {
        state: {
          myApplianceScreen: true,
          screenFrom: "my",
        },
      });
    };

    const EmptyView = () => {
      return (
        <>
          {isMy && (
            <>
              <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
                <p style={styles.empty_text}>{t("screen.productAdd.message.emptyMyAppliances")}</p>
                <p style={styles.empty_text}>{t("screen.productAdd.message.registerEmptyAppliance")}</p>
              </div>
              <button
                //accessibilityLabel="Move to Product Add Screen"
                style={styles.add_home_app_button}
                onClick={toProductSearchScreen}
              >
                <p
                  style={styles.add_home_app_button_text}
                //accessibilityLabel="Add product"
                >
                  {t("screen.productAdd.button.new_register")}
                </p>
              </button>
            </>
          )}

          {!isMy && (
            <>
              <div style={{ marginTop: 100, display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <p style={styles.empty_text}>{t("screen.productAdd.message.emptyMyAppliances2")}</p>
              </div>
            </>
          )}
        </>
      );
    };

    return (
      <>
        <div
          style={{
            ...(!isLoading && !isLoadingFlatList && MyAppliancesStore.totalCount > 0 ? {}: styles.wrap),
            backgroundColor: "#fff"
          }}
        >
          <TitleHeader title={titleNickname + t("screen.productAdd.button.myAppliance")} isHome />

          {!isLoading && !MyAppliancesStore.isEmpty && (
            <>
              <div style={styles.header_tab}>
                <button
                  style={{
                    ...styles.tab,
                    ...(MyAppliancesStore.currentTab === "ALL" ? styles.tab_active : styles.tab_inactive),
                  }}
                  onClick={() => {
                    onPressTab("ALL");
                  }}
                //accessibilityLabel="Click tab"
                >
                  <p
                    style={{
                      ...styles.tab_text,
                      ...(MyAppliancesStore.currentTab === "ALL" ? { fontWeight: 'bold' } : { fontWeight: 'normal' }),
                    }}
                  >
                    {t("전체")}
                  </p>
                </button>
                {MyAppliancesStore.categoryList &&
                  MyAppliancesStore.categoryList.map((item, index) => {
                    return (
                      <button
                        key={index}
                        style={{
                          ...styles.tab,
                          ...(MyAppliancesStore.currentTab === item.codeValue ? styles.tab_active : styles.tab_inactive),
                        }}
                        onClick={() => {
                          onPressTab(item.codeValue);
                        }}
                      // accessibilityLabel="Click tab"
                      >
                        <p
                          style={{
                            ...styles.tab_text,
                            ...(MyAppliancesStore.currentTab === item.codeValue ? styles.tab_active_text : styles.tab_inactive_text),
                          }}
                        >
                          {item.codeName}
                        </p>
                      </button>
                    );
                  })}
              </div>
              <div style={styles.wrap_list}>
                {MyAppliancesStore.totalCount > 0 && <HeaderView />}
                {!isLoadingFlatList && MyAppliancesStore.totalCount > 0 && (
                  <div style={{...(!isLoading && isMy ? {marginBottom: 56} : {})}}>
                    <MyAppliancesFlatList from={""} mode={mode} tempSize={0} />
                  </div>
                )}
                {!isLoadingFlatList && MyAppliancesStore.totalCount == 0 && (
                  <div style={styles.wrap_wrap_empty_view}>
                    <div style={styles.wrap_empty_view}>
                      <EmptyView />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {!isLoading && MyAppliancesStore.isEmpty && (
            <>
              {isMy && (
                <div style={styles.wrap_wrap_empty_view}>
                  <div style={styles.wrap_empty_view}>
                    <EmptyView />
                  </div>
                </div>
              )}

              {!isMy && (
                <div style={styles.wrap_wrap_empty_view}>
                  <div style={styles.wrap_empty_view}>
                    <p style={styles.empty_text}>{t("screen.productAdd.message.emptyMyAppliances2")}</p>
                  </div>
                </div>
              )}
            </>
          )}

          {!isLoading && isMy && (!mode || mode === "" || mode === "none") && (
            <div style={styles.footer}>
              <button
                style={{
                  ...styles.btn_1, ...styles.active,
                  height: 56
                }}
                onClick={toProductSearchScreen}
              // accessibilityLabel="Click button"
              >
                {isMy && (<p style={styles.footer_btn_text}>{t("screen.productAdd.button.new_register")}</p>)}
              </button>
            </div>
          )}

          {!isLoading && isMy && mode === "edit" && (
            <div style={styles.footer}>
              <button
                style={{
                  ...styles.btn_1,
                  ...(MyAppliancesStore.isEditValid ? styles.active : styles.in_active),
                  height: 56
                }}
                onClick={() => {
                  if (!MyAppliancesStore.isEditValid) {
                    showBottomToast(t("항목을 선택해 주세요."), bottomOffset());
                    return;
                  }

                  void ProductSearchAddStore.getMyProductDetail(
                    MyAppliancesStore.editInfo.productId,
                    MyAppliancesStore.editInfo.productFlag
                  ).then(() => {
                    goTo("/Product/Add", {
                      state: {
                        myApplianceScreen: true,
                        createType: CreateType.MODIFY,
                        screenFrom: "my",
                      },
                    });
                  });
                }}
              // accessibilityLabel="Click button"
              >
                <p style={styles.footer_btn_text}>{t("수정하기")}</p>
              </button>
            </div>
          )}

          {!isLoading && isMy && mode === "del" && (
            <div style={styles.footer}>
              <button
                style={{
                  ...styles.btn_1,
                  ...(MyAppliancesStore.isValid ? styles.active : styles.in_active),
                  height: 56,
                }}
                onClick={() => {
                  const delCount = MyAppliancesStore.selectedCount;
                  if (!MyAppliancesStore.isValid) {
                    showBottomToast(t("항목을 선택해 주세요."), bottomOffset());
                    return;
                  }
                  goTo(`/MyApplianceDeleteScreen/${delCount}`);
                }}
              // accessibilityLabel="Click button"
              >
                <p
                  style={{
                    ...styles.footer_btn_text,
                    ...(MyAppliancesStore.isValid ? styles.txt_active : styles.txt_in_active),
                  }}
                >
                  {t("screen.productAdd.button.delete2")}
                </p>
              </button>
            </div>
          )}

          {(isLoading || isLoadingFlatList) &&
            <div style={{ ...FlexBoxs.flex, ...Arranges.center_v_h, marginBottom: 56 }}>
              <CustomActivityIndicatorSmall />
            </div>
          }
        </div>

        {showConfirmPopup && (
          <MyApplianceDeleteModal
            isVisible={showConfirmPopup}
            contents1={t("screen.productAdd.message.deleteConfirmText1")}
            textAlign={"center"}
            useTwoButton={true}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.OK")}
            extraButtonText={t("screen.notificationModal.button.cancel")}
            onClickDefaultButton={() => {
              goTo("/MyApplianceDeleteScreen");
              setConfirmPopup(false);
              //void deleteSelectedProductList()
            }}
            onClickExtraButton={() => setConfirmPopup(false)}
            onRequestClose={() => setConfirmPopup(false)}
          />
        )}
      </>
    );
  }
);
export default MyAppliancesMain;

const styles: Styles = {
  wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...FlexBoxs.flex_1,
    height: '100vh'
  },
  header_tab: {
    // ...FlexBoxs.flex,
    // ...FlexBoxs.vertical,
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    // columnGap: '18px',
    height: 48,
    minHeight: 48
  },
  tab: {
    // ...FlexBoxs.flex_1,
    // ...FlexBoxs.flex,
    // ...Arranges.center_v_h,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    // paddingLeft: responsiveWidth(3),
    // paddingRight: responsiveWidth(3),
    padding: '10px 20px'
  },
  tab_text: {
    ...Texts.contents_text_38,
  },
  tab_inactive: {
    ...Colors.border_color_eee,
  },
  tab_inactive_text: {
    ...Colors.font_999,
  },
  tab_active: {
    ...Colors.border_color_222,
  },
  tab_active_text: {
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  wrap_list: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    height: '100%'
    // ...FlexBoxs.flex_1,
    // marginBottom: -10,
  },
  header: {
    ...Arranges.between,
    ...Arranges.center_h,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_left_16,
    ...Spacings.padding_right_16,
    height: 64,
  },
  header_left: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  header_text_wrap: {
    ...Spacings.padding_left_4,
  },
  header_text1: {
    ...FlexBoxs.flex,
    ...Texts.contents_text_38,
    ...Texts.font_weight_bold,
    ...Colors.font_222,
  },
  header_text2: {
    ...Colors.font_a760b1,
    ...Spacings.padding_left_4
  },
  header_btn: {
    ...Spacings.padding_top_bottom_8,
    ...Spacings.padding_right_left_12,
    ...Spacings.border_radius_100,
    ...Colors.background_f6f4fa,
  },
  header_btn_text: {
    ...Texts.btn_text_1,
    ...Colors.font_a760b1,
  },
  header_check_btn: {
    ...Spacings.margin_right_4,
    ...Spacings.margin_left_2,
  },

  empty: {
    ...Spacings.margin_top_8,
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  empty_image: {
    // width: responsiveScreenWidth(95),
    width: "95%",
  },
  wrap_wrap_empty_view: {
    position: 'fixed',
    top: '50%',
    bottom: '50%',
    left: '50%',
    right: '50%',
    transform: 'translate(-50%, -50%)',
    height: 200,
    width: '100%'
  },
  wrap_empty_view: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
    width: '100%',
    height: '100%',
  },
  empty_view: {
    ...Positions.absolute,
    ...Positions.left,
    ...Positions.right,
    // top: responsiveScreenHeight(21.9),
    // top: "21.9%",
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  footer_btn: {
    ...Colors.background_222,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  footer_btn_text: {
    ...Texts.btn_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  product: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  product_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.margin_top_14,
  },
  product_list: {
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_bottom_10,
  },
  product_image: {
    width: 60,
    height: 60,
    borderWidth: 1,
    borderColor: "rgba(34, 34, 34, 0.1)",
    ...Spacings.border_radius_6,
    ...Spacings.margin_right_12,
  },
  temp_product_image: {
    width: 60,
    height: 60,
    borderWidth: 1,
    borderColor: "rgba(34, 34, 34, 0.1)",
    ...Spacings.border_radius_6,
    ...Spacings.margin_right_12,
    opacity: 0.5,
  },
  product_info: {
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    // width: responsiveScreenWidth(60),
    width: "60%",
  },
  product_text1: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    ...Spacings.margin_bottom_4,
  },
  product_info_bottom: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  product_text2: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
  },
  product_text3: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
    ...Spacings.margin_right_left_6,
  },
  product_check_btn: {
    ...Spacings.margin_right_14,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  span_txt: {
    width: 60,
    height: 20,
    position: "absolute",
    textAlign: "center",
    marginVertical: 22,
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.56,
  },
  product_image_wrap: {
    position: "relative",
    width: 60,
    height: 60,
    ...Spacings.border_radius_6,
    ...Spacings.margin_right_12,
    backgroundColor: "rgba(22, 22, 22, 0.5)",
  },
  product_option_button_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  product_option_button: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  txt_active: {
    ...Colors.font_fff,
  },
  txt_in_active: {
    ...Colors.font_999,
  },
  in_active: {
    ...Colors.background_eeeeee,
    ...Colors.border_color_d9d9d9,
  },
  active: {
    ...Colors.background_222,
  },
  footer: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    width: '100%',
    maxWidth: '450px',
    marginTop: 'auto',
    position: 'fixed',
    ...Positions.bottom,
    zIndex: '100'
  },
  btn_1: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
  },
  add_home_app_button: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Colors.background_f7f4fa,
    ...Spacings.border_radius_4,
    ...Spacings.margin_top_14,
    width: 120,
    height: 44,
    borderRadius: 300,
    borderWidth: 1,
    borderColor: "#be69c3",
    borderStyle: 'solid',
    alignSelf: "center",
    backgroundColor: "#fff",
  },
  add_home_app_button_text: {
    ...Colors.font_a760b1,
    ...Texts.button_text_1,
    ...Texts.font_weight_500,
    color: "#be69c3",
  },
};
