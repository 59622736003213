import React, { ReactNode } from "react";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

import { observer } from "mobx-react";
import CustomBottomSheet from "./CustomBottomSheet";
import { BadgeItem } from "../../api/badge/model";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import "./BadgeBottomSheet.scss";

export interface BadgeBottomSheetProps {
  open: boolean;
  badgeItem: BadgeItem;
  onClose: () => void;
  children?: ReactNode;
}

const BadgeBottomSheet = observer(
  ({ open, badgeItem, onClose }: BadgeBottomSheetProps) => {
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    const { t } = useTranslation();

    return (
      <CustomBottomSheet
        open={open}
        onClose={onClose}
        idSheet="badge_bottom_sheet"
        // handleComponent={() => <></>}
      >
        <div className="badge_sheet_wrapper">
          <button className="close_btn" onClick={onClose}>
            <CloseIcon />
          </button>

          <div className="sheet_body">
            <div className="sheet_icon_wrapper">
              <LazyLoadImage
                src={`${PUBLIC_BUCKET_URL}/BADGE/${
                  badgeItem.badgeCode || ""
                }_GET.png`}
                className="sheet_icon"
                style={{ width: 180, height: 120 }}
              />
            </div>
            <div className="sheet_label">
              <p className="sheet_label_text1">
                <p className="sheet_label_text2">
                  {t("screen.badge.label.get", {
                    badge: `${badgeItem.badgeName}`,
                  })}
                </p>
              </p>
            </div>
            <div className="sheet_info">
              <p className="sheet_info_text1">{badgeItem.badgeNotice}</p>
            </div>
          </div>

          <button className="confirm_btn" onClick={onClose}>
            <p className="confirm_btn_text">{t("screen.badge.label.check")}</p>
          </button>
        </div>
      </CustomBottomSheet>
    );
  }
);
export default BadgeBottomSheet;
