import { useState } from "react";
import { useTranslation } from "react-i18next";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_small.svg";
import CustomBottomSheet from "../../../components/common/CustomBottomSheet";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { BrandCommunityLineUpResponse, BrandLineUpSortType, BrandListTabType } from "../../../api/brand/model";
import { countFollow } from "../../../utils/numberCount";
import { ReactComponent as IconFollowCheck } from "../../../assets/icons/icon_follow_check.svg";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { Virtuoso } from "react-virtuoso";

const BrandLineup = ({
  renderData,
  sortType,
  changeSortType,
  actionBrandSubscribe,
  goToBrandHome,
}: {
  renderData: BrandCommunityLineUpResponse[] | [];
  sortType: BrandLineUpSortType;
  changeSortType: (sortType: BrandLineUpSortType) => void;
  actionBrandSubscribe: (comunityId: number) => void;
  goToBrandHome: (comunityId: number) => void;
}) => {
  const [showSortBox, setShowSortBox] = useState<boolean>(false);

  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";

  const { t } = useTranslation();

  return (
    <>
      {renderData.length > 0 && (
        <Virtuoso
          style={styles.follower_list}
          useWindowScroll
          data={renderData.slice(0, 5)}
          itemContent={(index: number, item: BrandCommunityLineUpResponse) => {
            return (
              <div key={`BrandLineUP_${index.toString()}`}>
                {
                  <div style={styles.follower_item}>
                    <div
                      style={styles.follower}
                      onClick={() => {
                        goToBrandHome(item.communityId);
                      }}
                    >
                      <FastImageWithFallback
                        style={styles.profileImage}
                        source={{ uri: `${PUBLIC_BUCKET_URL}${item.communityLogoUrl || ""}` }}
                        fallbackImageUri={FALLBACK_IMAGE_URL}
                      />
                      <div style={styles.info_wrap}>
                        <div style={styles.id_wrap}>
                          <p style={styles.id_text} >
                            {item.communityName}
                          </p>
                        </div>
                        <div style={styles.tag_list}>
                          <p style={styles.tag_text}>
                            {item.communityIntroduce}
                          </p>
                        </div>
                        <div style={styles.num_view_wrap}>
                          <p style={styles.view_text1}>{t("screen.brandCommunity.label.subscriber")}&nbsp;</p>
                          <p style={styles.view_text2}>{countFollow(Number(item.communitySubscriberCount))}</p>
                          <div style={styles.div_dot}>&nbsp;</div>
                          <p style={styles.view_text1}>{t("screen.brandCommunity.label.article")}&nbsp;</p>
                          <p style={styles.view_text2}>{countFollow(Number(item.communityArticleCount))}</p>
                          <div style={styles.div_dot}>&nbsp;</div>
                          <p style={styles.view_text1}>{t("screen.brandCommunity.label.product")}&nbsp;</p>
                          <p style={styles.view_text2}>{countFollow(Number(item.communityProductCount))}</p>
                        </div>
                      </div>
                    </div>

                    {item.subscriptionYn == "Y" ? (
                      <div style={styles.action_btn}>
                        <IconFollowCheck />
                        <p style={styles.action_btn_text}>
                          {t("screen.brandCommunity.label.subscribing")}
                        </p>
                      </div>
                    ) : (
                      <TouchableWithAuthCheck
                        style={styles.follower_btn}
                        onPress={() => actionBrandSubscribe(item.communityId)}
                      >
                        <p style={styles.follower_btn_text}>
                          {t("screen.brandCommunity.button.subscribe")}
                        </p>
                      </TouchableWithAuthCheck>
                    )}
                  </div>
                }
              </div>
            );
          }}
          components={{
            Header: () => (
              <div style={styles.bx_in_title}>
                <div style={styles.title_text}>
                  <div style={styles.bg_title} />
                  <p style={styles.title_text_st1}>{t("screen.brandCommunity.label.brandLineup")}</p>
                </div>
                <div
                  style={styles.header_sort_btn}
                  onClick={() => {
                    setShowSortBox(true);
                  }}
                >
                  <p style={styles.header_sort_btn_txt}>
                    {sortType === BrandLineUpSortType.LATEST
                      ? t("screen.brandCommunity.sortType.latest")
                      : sortType === BrandLineUpSortType.POPULAR
                        ? t("screen.brandCommunity.sortType.popular")
                        : t("screen.brandCommunity.sortType.accuracy")}
                  </p>
                  <ArrowDown />
                </div>
              </div>
            ),
            EmptyPlaceholder: () => (
              <></>
            ),
            Footer: () => (
              <>
                <div style={styles.btn_wrap}>
                  <TouchableWithAuthCheck
                    style={styles.all_view_btn}
                    onPress={() => {
                      goTo("/BrandCommunityList", { state: { brandListType: BrandListTabType.TOTAL } });
                    }}
                  >
                    <p style={styles.all_view_btn_text}>{t("screen.brandCommunity.button.viewAllBrandLineup")}</p>
                  </TouchableWithAuthCheck>
                </div>

                <div style={{ paddingBottom: 30 }}></div>
              </>
            )
          }}
        />
      )}

      <CustomBottomSheet
        snapHeight={236}
        open={showSortBox}
        onClose={() => setShowSortBox(false)}
      >
        <div style={styles.sheet}>
          <div
            style={styles.close_btn}
            onClick={() => setShowSortBox(false)}
          >
            <CloseIcon />
          </div>
          <div style={styles.sheet_title_wrap}>
            <p style={styles.sheet_title}>
              {t("screen.search.label.sort")}
            </p>
          </div>

          <div style={styles.sort_list}>
            <div
              style={styles.sort_item}
              onClick={() => {
                setShowSortBox(false);
                changeSortType(BrandLineUpSortType.POPULAR);
              }}
            >
              <p style={{ ...styles.sort_text, ...(sortType === BrandLineUpSortType.POPULAR ? styles.sort_active_text : {}) }}>
                {t("screen.brandCommunity.sortType.popular")}
              </p>
            </div>
            <div
              style={styles.sort_item}
              onClick={() => {
                setShowSortBox(false);
                changeSortType(BrandLineUpSortType.LATEST);
              }}
            >
              <p style={{ ...styles.sort_text, ...(sortType === BrandLineUpSortType.LATEST ? styles.sort_active_text : {}) }}>
                {t("screen.brandCommunity.sortType.latest")}
              </p>
            </div>

            <div
              style={styles.sort_item}
              onClick={() => {
                setShowSortBox(false);
                changeSortType(BrandLineUpSortType.ACCURACY);
              }}
            >
              <p style={{ ...styles.sort_text, ...(sortType === BrandLineUpSortType.ACCURACY ? styles.sort_active_text : {}) }}>
                {t("screen.brandCommunity.sortType.accuracy")}
              </p>
            </div>
          </div>
        </div>
      </CustomBottomSheet>
    </>
  );
};

export default BrandLineup;

export const styles: Styles = {
  num_view_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_top_2,
  },
  view_text1: {
    ...Texts.contents_text_9,
  },
  view_text2: {
    ...Texts.contents_text_9,
    ...Colors.font_672c78,
  },
  div_dot: {
    width: 3,
    height: 3,
    borderRadius: 3,
    ...Colors.background_666,
    ...Spacings.margin_right_left_5,
  },
  bg_title: {
    height: 8,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#F7E237",
  },
  bx_in_title: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.margin_bottom_20,
  },
  title_text: {
    position: "relative",
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  title_text_st1: {
    fontSize: 18,
    fontWeight: "500",
    color: "#222",
    letterSpacing: -0.72,
    zIndex: 1,
  },
  header_sort_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  header_sort_btn_txt: {
    ...Texts.contents_text_0,
  },
  follower_manage: {
    ...FlexBoxs.flex_1,
    ...Colors.background_fff,
  },
  tab_box: {
    height: 56,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    ...Colors.border_color_eee,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Colors.background_fff,
  },
  tab: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Colors.background_fff,
  },
  tab_txt: {
    ...Texts.contents_text_38,
    ...Colors.font_999,
  },
  active_tab: {
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    ...Colors.border_color_222,
  },
  active_tab_txt: {
    ...Colors.font_222,
    fontWeight: "500",
  },
  follower_contents: {
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_10,
    ...Spacings.padding_bottom_10,
    ...Colors.background_fff,
  },
  nick_name: {
    height: 64,
    paddingLeft: 24,
    paddingRight: 50,
    ...Colors.background_fafafa,
    ...Texts.button_text_10,
    borderRadius: 6,
  },
  search_icon: {
    ...FlexBoxs.flex_1,
    ...Positions.absolute,
    right: 20,
  },
  nick_name_wrap: {
    marginTop: 10,
    marginBottom: 10,
    ...FlexBoxs.flex,
    ...Arranges.center_v,
  },
  follower_list: {
    ...Spacings.margin_top_20,
    ...Spacings.margin_left_16,
    ...Spacings.margin_right_16,
    ...Colors.background_fff,
  },
  follower_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_bottom_24,
    ...Arranges.between,
    ...Arranges.center_h,
  },
  follower: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    ...FlexBoxs.horizon,
  },
  info_wrap: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v,
    marginLeft: 14,
    marginRight: 16,
  },
  id_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    marginBottom: 5,
  },
  follower_small_btn_text: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
    ...Spacings.margin_left_6,
  },
  id_text: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  id_text_matched: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },
  tag_list: {
    marginBottom: 2,
  },
  tag_text: {
    ...Texts.contents_text_27,
    ...Texts.font_weight_normal,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  action_btn: {
    ...Spacings.border_radius_4,
    ...Colors.border_color_fff,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    flexDirection: "row",
    width: 52,
    height: 32,
  },
  action_btn_text: {
    ...Texts.btn_text_1,
    color: "#BE69C3",
    marginLeft: 2,
  },
  follower_btn: {
    ...Spacings.border_radius_4,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Colors.background_be69c3,
    width: 52,
    height: 32,
  },
  follower_btn_text: {
    ...Texts.btn_text_1,
    ...Colors.font_fff,
    fontWeight: "500",
  },

  save_btn: {
    ...Positions.absolute,
    ...Positions.bottom,
    ...Positions.left,
    ...Positions.right,
    ...Colors.background_222,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  save_btn_text: {
    ...Texts.contents_text_33,
    ...Colors.font_fff,
  },

  delete_image: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    marginTop: 58,
    height: 100,
  },
  top_text: {
    ...Spacings.margin_top_10,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  bottom_text: {
    ...Spacings.margin_top_10,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  delete_text_1: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
    textAlign: "center",
  },
  delete_text_2: {
    ...Texts.contents_text_3,
    textAlign: "center",
  },
  profileImage: {
    width: 64,
    height: 64,
    borderRadius: 300,
  },
  btn_wrap: {},
  all_view_btn: {
    width: '100%',
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    height: 53,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_4,
    ...Colors.border_color_eee,
  },
  all_view_btn_text: {
    ...Texts.button_text_6,
    ...Texts.font_weight_500,
  },
  sheet: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_16,
    ...Spacings.padding_bottom_19,
    ...Spacings.padding_left_right_20,
  },
  close_btn: {
    ...Positions.absolute,
    zIndex: 999,
    top: 6,
    right: 6,
  },
  sheet_title_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 32,
  },
  sheet_title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  sort_list: {
    ...Spacings.margin_top_16,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_h,
  },
  sort_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_top_bottom_15,
  },
  sort_text: {
    ...Texts.contents_text_38,
  },
  sort_active_text: {
    ...Colors.font_be69c3,
    fontWeight: "bold",
  },
};
