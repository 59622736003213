import { useTranslation } from "react-i18next";
import { CommentStatusCode } from "../../../constants/Comment";
import { AllowTypeCode } from "../../../constants/CommonCode.enum";
import './CommentBlock.scss'

export interface CommentBlockProps {
  statusCode?: CommentStatusCode;
  reportYn?: AllowTypeCode;
  memberReportYn?: AllowTypeCode;
  unknownMember?: boolean;
}
const CommentBlock = ({
  statusCode,
  reportYn,
  memberReportYn,
  unknownMember,
}: CommentBlockProps) => {
  const { t } = useTranslation();

  const getCommentBlockMessage = () => {
    if (statusCode === CommentStatusCode.ADMIN_DELETE)
      return t(`screen.comment.label.admin_delete`);
    else if (statusCode === CommentStatusCode.USER_DELETE)
      return t(`screen.comment.label.user_delete`);
    else if (reportYn === AllowTypeCode.Y)
      return t(`screen.comment.label.admin_delete`);
    else if (memberReportYn === AllowTypeCode.Y)
      return t(`screen.comment.label.admin_review`);
    else if (unknownMember) return t(`screen.comment.label.unknownMember`);
  };

  return (
    <div className="comment_block">
      <span aria-label="Comment Block Label" className="comment_block_label">
        {getCommentBlockMessage()}
      </span>
    </div>
  );
};

export default CommentBlock;
