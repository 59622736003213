import { SuccessOrNot } from "../../constants/Common.enum";
import {
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
} from "../common/api";
import CommonResponse from "../http";
import WriteCommonResponse, {
  PostWriteRequest,
  PostDetailResponse,
  RecommendTagResponse,
} from "./model";

export const createPost = async (
  postItem: PostWriteRequest,
  isLoading = true
): Promise<WriteCommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/feed/post",
      body: postItem,
    },
    isLoading
  );
};

export const getRecommendTag = async (
  productIds?: number[],
  isLoading = true
): Promise<RecommendTagResponse[]> => {
  let url = "/v1/recommendation/tags";
  if (productIds && productIds.length > 0) {
    url = url + "?productIds=" + productIds.toString();
  }
  const response = await callAPIWithGETMethod(
    {
      url: url,
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y ? response?.data : null
  ) as RecommendTagResponse[];
};

export const getPostDetail = async (
  feedId: number,
  isLoading = true
): Promise<PostDetailResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/feed/post/${feedId}`,
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y ? response?.data : null
  ) as PostDetailResponse;
};

export const deletePost = async (
  feedIds: number[],
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/feed/post",
      body: { feedIds },
    },
    isLoading
  );
  return (
    response?.successOrNot === SuccessOrNot.Y ? response?.data : false
  ) as boolean;
};

// 포스트 조회수 증가
export const setPostViewCountUp = async (
  feedId: string | number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  response = await callAPIWithPOSTMethod(
    {
      url: "/v1/feed/post/viewCount",
      body: {
        feedId: feedId,
      },
    },
    isLoading
  );
  return response;
};
