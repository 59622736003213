import { action, computed, makeAutoObservable, observable } from "mobx";
import { getMagazineList } from "../api/magazine/api";
import { Magazine, RequestParameters } from "../api/magazine/model";
import { SORT } from "../constants/Feed";

class MagazineStore {
  @observable _latestList: Magazine[] = [];
  @observable _popularList: Magazine[] = [];
  @observable _isLatestLoading = false;
  @observable _isPopularLoading = false;
  @observable _isLatestMoreLoading = false;
  @observable _isPopularMoreLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  @computed get latestList() {
    return this._latestList;
  }

  @computed get popularList() {
    return this._popularList;
  }

  @computed get isLatestLoading() {
    return this._isLatestLoading;
  }

  @computed get isPopularLoading() {
    return this._isPopularLoading;
  }

  @computed get isLatestMoreLoading() {
    return this._isLatestLoading;
  }

  @computed get isPopularMoreLoading() {
    return this._isPopularLoading;
  }

  @action initLatestList = () => {
    this._isLatestLoading = true;
    void getMagazineList({
      sort: SORT.DATE,
      pageIndex: 0,
    })
      .then((magazineList) => {
        this.setLatestList(magazineList);
      })
      .catch((error) => {
        // console.log(
        //   `getMagazineList error : ${JSON.stringify(error, null, 2)}}`
        // );
      })
      .finally(
        action(() => {
          this._isLatestLoading = false;
        })
      );
  };

  @action initPopularList = () => {
    this._isPopularLoading = true;
    void getMagazineList({
      sort: SORT.POPULARITY,
      pageIndex: 0,
    })
      .then((magazineList) => {
        this.setPopularList(magazineList);
      })
      .catch((error) => {
        // console.log(
        //   `getMagazineList error : ${JSON.stringify(error, null, 2)}}`
        // );
      })
      .finally(
        action(() => {
          this._isPopularLoading = false;
        })
      );
  };

  @action getMoreMagazineList = async (
    requestParameters: RequestParameters
  ) => {
    const extraList = await getMagazineList(requestParameters);
    if (requestParameters.sort === SORT.DATE) {
      const newList = this._latestList.concat(extraList);
      this.setLatestList(newList);
      return;
    }
    const newList = this._popularList.concat(extraList);
    this.setPopularList(newList);
  };

  @action getMoreLatestList = async (requestParameters: RequestParameters) => {
    this._isLatestMoreLoading = true;
    const extraList = await getMagazineList(requestParameters);
    const newList = this._latestList.concat(extraList);
    this.setLatestList(newList);
    this._isLatestMoreLoading = false;
  };

  @action getMorePopularList = async (requestParameters: RequestParameters) => {
    this._isPopularMoreLoading = true;
    const extraList = await getMagazineList(requestParameters);
    const newList = this._popularList.concat(extraList);
    this.setPopularList(newList);
    this._isPopularMoreLoading = false;
  };

  @action setLatestList = (latestList: Magazine[]) => {
    this._latestList = latestList;
  };

  @action setPopularList = (popularList: Magazine[]) => {
    this._popularList = popularList;
  };

  @action updateItem = (newItem: Magazine) => {
    const modifiedListLatest = this._latestList.map((item) => {
      if (item.feedId !== newItem.feedId) {
        return item;
      }
      return newItem;
    });
    this.setLatestList(modifiedListLatest);

    const modifiedListPopular = this._popularList.map((popularItem) => {
      if (popularItem.feedId !== newItem.feedId) {
        return popularItem;
      }
      return newItem;
    });
    this.setPopularList(modifiedListPopular);
  };
}

const instance = new MagazineStore();

export default instance;
