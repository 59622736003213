import React, { memo, useContext, useEffect, useState } from "react";
import MyAppliancesStore, {
  MyAppliancesList,
} from "../../../store/MyAppliancesStore";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { useTranslation } from "react-i18next";

import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/prd_search_arrow_right.svg";
import { ReactComponent as UpTag } from "../../../assets/icons/up_product_label.svg";
import { ReactComponent as DimImage } from "../../../assets/icons/image_add_dim.svg";

import { showBottomToast } from "../../../utils/Toast";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
import "./MyAppliancesItemForProduct.scss";

export const MyAppliancesItemForProduct = memo(
  ({
    item,
    mode,
    index,
    tempSize,
  }: {
    item: MyAppliancesList;
    mode: string;
    index: number;
    tempSize: number;
  }) => {
    const { t } = useTranslation();
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const [isClicked, setIsClicked] = useState(false);

    //5개 초과 선택되었는지 체크
    const isMax = () => {
      const myCnt =
        MyAppliancesStore.myAppliancesList?.filter((x) => x.isSelected)
          .length ?? 0;
      return tempSize + myCnt >= 5;
    };

    //중복 체크
    const isDuplicate = () => {
      const dupleCnt =
        ProductSearchAddStore.tempProductList?.filter((temp) => {
          //220제품일 경우
          if (
            temp.product.productId &&
            temp.product.productId === item.productId
          ) {
            return true;
          }
          //등록 대기중일 경우
          if (
            temp.product.tempProductId &&
            temp.product.tempProductId === item.tempProductId
          ) {
            return true;
          }
          //네이버 api로 등록 후 바로 다시 조회할 경우
          if (
            temp.product.nproductId &&
            !item.productFlag &&
            temp.product.nproductId === item.productId
          ) {
            return true;
          }
          return false;
        }).length ?? 0;

      return dupleCnt > 0;
    };

    const MyProductItem = () => {
      return (
        <div className={`${isClicked ? "product product_checked" : "product"}`}>
          <div
            className="product_item"
            aria-label={`Product result ${index}`}
            onClick={() => {
              if (isClicked) {
                MyAppliancesStore.selectProduct(item);
              } else {
                if (isMax()) {
                  showBottomToast(t("screen.feedProduct.message.max5Count"));
                  return;
                }
                if (isDuplicate()) {
                  showBottomToast(t("screen.feedProduct.message.alreadyAdd"));
                  return;
                }
                MyAppliancesStore.selectProduct(item);
              }
              setIsClicked(!isClicked);
            }}
          >
            <div className="product_image_wrap">
              <FastImageWithFallback
                aria-label="Product image"
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${item.imageUrl || ""}`,
                }}
                className="product_image"
              />
              {item.tempProductId && (
                <div className="empty_img">
                  <p className="empty_txt">{"등록\n대기중"}</p>
                </div>
              )}
            </div>
            <div className="product_info">
              <p
                className={`${
                  isClicked ? "product_text1" : "product_text1"
                }`}
                aria-label="Product name"
              >
                {item.productName && item.productName.length > 27
                  ? item.productName.substring(0, 27 - 3) + "..."
                  : item.productName}
              </p>
              <div className="product_info_bottom">
                <p className="product_text2" aria-label="Brand name">
                  {item.brandName}
                </p>
                <p className="product_text3">|</p>
                <p className="product_text2" aria-label="Product model name">
                  {item.modelName}
                </p>
              </div>
            </div>
            {isClicked && (
              <div
                className="icon_wrap"
              >
                <ArrowRightIcon />
              </div>
            )}
          </div>
        </div>
      );
    };

    return (
      <div id="my_appliance_item_for_product">
        <MyProductItem />
      </div>
    );
  }
);

MyAppliancesItemForProduct.displayName = "MyAppliancesItemForProduct";
