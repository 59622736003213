import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  useParams,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
// import './App.css';
import MissionStore from "../../store/MissionStore";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../assets/styles";

import PictureMission from "./presenter/PictureMission";
// import CommentMission from '../presenter/CommentMission';
import CouponMission from "./presenter/CouponMission";
import SurveyMission from "./presenter/SurveyMission";
import RaffleMission from "./presenter/RaffleMission";
import RecommendMission from "./presenter/RecommendMission";
// import StampMission from '../presenter/StampMission';
// import RandomCouponMission from '../presenter/randomCoupon/RandomCouponMission';
// import MallMission from '../presenter/MallMission';
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import {
  MISSION_RESULT_COUNT,
  MISSION_SUB_TYPE,
  MISSION_TYPE,
  MISSION_TYPE_PATH,
} from "../../constants/mission";
import { TitleHeader } from "../../components/common";
import { t } from "i18next";

// import { useTracker } from "../../hooks/tracker";
import { isPast } from "../../utils/datetime";
// import MissionExposedLimitPopup from "./common/popup/MissionExposedLimitPopup";
// import MissionParticipationLimitPopup from "./common/popup/MissionParticipationLimitPopup";
import { Styles } from "../../assets/types/Style";
import StampMission from "./presenter/StampMission";
import MallMission from "./presenter/MallMission";
import RandomCouponMission from "./presenter/randomCoupon/RandomCouponMission";
import CommonMission from "./presenter/CommonMission";

// const MissionDetailScreen = observer(({ route }: any) => {
const MissionDetailScreen = observer(() => {
  // const isScreenFocused = useIsFocused();
  const location = useLocation();
  const route = location.state;
  const params = useParams();
  const missionId = params.missionId || route?.missionId;

  const [isScreenFocused, setIsFocused] = useState(false);

  useEffect(() => {
    // 화면 포커스 여부 감지
    setIsFocused(true);

    // 컴포넌트 언마운트 시 포커스 여부 갱신
    return () => {
      setIsFocused(false);
    };
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { missionDetail, getMissionDetail, clearMssionDetail } = MissionStore;

  // const safeAreaInsets = useSafeAreaInsets();
  // const { track } = useTracker();

  useEffectOnlyOnce(undefined, () => {
    clearMssionDetail();
    // void FastImage.clearMemoryCache();
  });

  useEffect(() => {
    if (isScreenFocused === true) {
      MissionStore.setLoading(true);
      void MissionStore.getMissionDetail(missionId as number).then(() => {
        //진행중 탭에서 미션 상세화면 진입시 유효성 체크 강화,
        if (
          MissionStore.selectedTab &&
          MissionStore.selectedTab == "ongoing" &&
          MissionStore.missionDetail?.missionId == 0 &&
          MissionStore.missionDetail?.missionTypeCode == ""
        ) {
          // Alert.alert(t("screen.mission.message.endAlready"), "", [
          //   {
          //     text: t("common.label.check"),
          //     onPress: () => {
          //       //진행 중 미션 리스트 초기화
          //       void MissionStore.getMissionList(MISSION_TYPE_PATH.ONGOING, MISSION_RESULT_COUNT, 0).finally(() => {
          //         // goBack();
          //       });
          //     },
          //   },
          // ]);
        }

        if (MissionStore.missionDetail.missionId > 0) {
          // track("view_mission_detail", {
          //   mission_id: MissionStore.missionDetail.missionId,
          //   mission_title: MissionStore.missionDetail.missionTitle,
          //   mission_type: MissionStore.missionDetail.missionTypeCode,
          //   participation_count: MissionStore.missionDetail.participationCount,
          //   mission_progress:
          //     MissionStore.missionDetail.missionEndDate && isPast(MissionStore.missionDetail.missionEndDate)
          //       ? "종료"
          //       : "진행 중",
          // });
        }
      });
    }
  }, [isScreenFocused, route, missionId]);

  const renderEmptyPage = (title: string) => {
    return (
      <div>
        <TitleHeader title={title} isHome />
      </div>
    );
  };

  const renderDetailPage = (type: string) => {
    let detailPage = null;
    let title = "";

    detailPage = <CommonMission />;

    //  detailPage = <PictureMission />;

    //detailPage = <PictureMission />;

    // switch (type) {
    //   case MISSION_TYPE.SURVEY:
    //     detailPage = <SurveyMission />;
    //     break;
    //   case MISSION_TYPE.COMMENT:
    //     detailPage = <CommentMission />;
    //     break;
    //   case MISSION_TYPE.PICTURE:
    //     detailPage = <PictureMission />;
    //     break;
    //   case MISSION_TYPE.COUPON:
    //     detailPage = <CouponMission />;
    //     break;
    //   case MISSION_TYPE.RAFFLE:
    //     detailPage = <RaffleMission />;
    //     break;
    //   case MISSION_TYPE.RECOMMENDER:
    //     detailPage = <RecommendMission title={t("screen.mission.label.recommender")} />;
    //     break;
    //   case MISSION_TYPE.STAMP:
    //     detailPage = <StampMission />;
    //     break;
    //   case MISSION_TYPE.COMMON:
    //     if (missionDetail.missionSubType === MISSION_SUB_TYPE.LOTTERY) {
    //       detailPage = <RandomCouponMission />;
    //     }
    //     if (missionDetail.missionSubType === MISSION_SUB_TYPE.MALL) {
    //       detailPage = <MallMission />;
    //     }
    //     break;
    // }

    switch (route?.params?.missionTypeCode) {
      case MISSION_TYPE.SURVEY:
        title = t("screen.mission.label.survey");
        break;
      case MISSION_TYPE.COMMENT:
        title = t("screen.mission.label.comment");
        break;
      case MISSION_TYPE.PICTURE:
        title = t("screen.mission.label.pictureMission");
        break;
      case MISSION_TYPE.COUPON:
        title = t("screen.mission.label.coupon");
        break;
      case MISSION_TYPE.RAFFLE:
        title = t("screen.mission.label.raffle");
        break;
      case MISSION_TYPE.RECOMMENDER:
        title = t("screen.mission.label.recommender");
        break;
      case MISSION_TYPE.STAMP:
        title = t("screen.mission.label.stamp");
        break;
      case MISSION_TYPE.COMMON:
        if (missionDetail.missionSubType === MISSION_SUB_TYPE.LOTTERY) {
          title = t("screen.mission.label.randomCoupon");
        }
        break;
    }

    if (detailPage === null) {
      detailPage = renderEmptyPage(title);
    }

    return detailPage;
  };

  return (
    // <SafeAreaView
    //   style={{ flex: 1, backgroundColor: Color.WHITE }}
    //   accessible={true}
    //   edges={["top", "left", "right"]}
    //   accessibilityLabel={"Mission Detail screen"}
    // >
    //   {renderDetailPage(missionDetail.missionTypeCode)}
    //   {/* 미션 노출 제한 팝업 */}
    //   <MissionExposedLimitPopup />
    //   {/* 미션 응모 제한 팝업 */}
    //   <MissionParticipationLimitPopup />
    // </SafeAreaView>

    <div>
      {renderDetailPage(missionDetail.missionTypeCode)}
      {/* 미션 노출 제한 팝업 */}
      {/* <MissionExposedLimitPopup /> */}
      {/* 미션 응모 제한 팝업 */}
      {/* <MissionParticipationLimitPopup /> */}
    </div>
  );
});

export default MissionDetailScreen;

export const styles: Styles = {
  comment_mission: {
    ...FlexBoxs.flex_1,
  },
  title_wrap: {
    ...Spacings.margin_top_20,
    ...Spacings.padding_left_right_20_responsive,
  },
  icon_wrap: {
    ...FlexBoxs.horizon,
  },
  d_day_icon: {
    ...Arranges.center_v_h,
    ...Colors.background_a760b1,
    ...Spacings.border_radius_6,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.padding_right_left_8,
  },
  d_day_text: {
    ...Texts.contents_text_6,
    ...Colors.font_fff,
  },
  expire_icon: {
    ...Arranges.center_v_h,
    ...Colors.background_999999,
    ...Spacings.border_radius_6,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.padding_right_left_8,
  },
  icon_text: {
    ...Texts.contents_text_6,
    ...Colors.font_fff,
  },
  title: {
    ...Spacings.margin_top_8,
    ...Texts.contents_text_4,
    ...Texts.font_weight_500,
  },
  image: {
    ...Spacings.margin_bottom_16,
  },
  desc_wrap: {
    ...Spacings.padding_top_20,
  },
  contents_wrap: {
    ...Spacings.padding_bottom_16,
    ...Spacings.padding_left_right_20_responsive,
  },
  contents_item: {
    ...Spacings.margin_bottom_24,
  },
  label: {
    ...Texts.contents_text_34,
    ...Spacings.margin_bottom_6,
  },
  contents: {
    ...Texts.contents_text_3,
  },
  list_contents: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  dot_wrap: {
    ...Arranges.start_v,
    ...Spacings.padding_top_10,
  },
  dot: {
    width: 3,
    height: 3,
    ...Colors.background_666,
    ...Spacings.border_radius_4,
    ...Spacings.margin_right_4,
  },

  footer_btn: {
    ...Colors.background_c4c4c4,
    ...Arranges.center_v_h,
  },
  footer_btn_active: {
    ...Colors.background_222,
    ...Arranges.center_v_h,
  },
  footer_btn_inactive: {
    ...Colors.background_eeeeee,
    ...Arranges.center_v_h,
  },
  footer_btn_text: {
    ...Texts.btn_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  footer_btn_text_inactive: {
    ...Texts.btn_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
  comment_wrap: {
    ...Spacings.margin_top_8,
  },
  comment_input_wrap: {
    height: 104,
    ...Spacings.padding_top_bottom_15,
    ...Spacings.padding_left_right_16_responsive,
    ...Colors.background_f5f5f5,
  },
  comment_input_inner_wrap: {
    ...Colors.background_fff,
    ...FlexBoxs.horizon,
    ...FlexBoxs.flex_1,
    ...Arranges.center_h,
    ...Spacings.border_radius_6,
    ...Spacings.padding_top_bottom_6,
    ...Spacings.padding_left_16,
    ...Spacings.padding_right_6,
    ...Colors.border_color_eee,
    borderWidth: 1,
  },
  input: {
    ...Spacings.margin_right_18,
    ...Texts.contents_text_3,
  },
  write_btn: {
    width: 60,
    height: 60,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_6,
    ...Colors.background_a760b1,
  },
  write_btn_text: {
    ...Texts.button_text_9,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  my_comment: {
    ...Spacings.margin_top_32,
    ...Spacings.padding_left_right_20_responsive,
  },
  small_bar: {
    height: 1,
    ...Colors.background_eeeeee,
  },
  comment_title: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    ...Spacings.margin_bottom_23,
  },
  comment: {
    ...FlexBoxs.horizon,
    ...Spacings.margin_bottom_30,
  },
  profile_image: {
    width: 34,
    height: 34,
    ...Spacings.border_radius_32,
    ...Spacings.margin_right_6,
  },
  comment_info: {},
  comment_header: {
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.padding_top_bottom_6,
  },
  id: {
    ...Texts.contents_text_3,
  },
  comment_contents: {},
  comment_bottom_info: {
    ...FlexBoxs.horizon,
    ...Spacings.margin_top_14,
  },
  date: {
    ...Texts.contents_text_9,
    ...Spacings.margin_right_10,
  },
  like: {
    ...Texts.contents_text_9,
  },
  line_number: {
    ...Texts.font_weight_bold,
  },
  comment_list: {
    ...Spacings.margin_top_32,
    ...Spacings.padding_left_right_20_responsive,
  },
  comment_title_number: {
    ...Colors.font_a760b1,
  },
  txt_bar: {
    width: 1,
    height: 13,
    ...Colors.background_c4c4c4,
    ...Spacings.margin_top_4,
    ...Spacings.margin_left_8,
    ...Spacings.margin_right_8,
  },
  txt_bar_top: {
    marginTop: 4,
  },
  contents_txt_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  contents_txt_wrap_top: {
    ...FlexBoxs.horizon,
  },
  vertical: {
    ...FlexBoxs.vertical,
  },
  modify_btn: {
    width: 96,
    height: 35,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_6,
    ...Spacings.margin_top_10,
    borderColor: "rgba(34,34,34,0.1)",
    borderWidth: 1,
  },
  navigate_btn: {
    width: 96,
    height: 35,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_6,
    ...Spacings.margin_top_5,
    ...Spacings.margin_bottom_4,
    borderColor: "rgba(34,34,34,0.1)",
    borderWidth: 1,
  },
  modify_btn_text: {
    ...Texts.btn_text_3,
    ...Colors.font_666666,
  },
};
