import React, { useCallback, useEffect, useRef, useState } from "react";
import { FollowersTab, FOLLOWER_LIST_COUNT } from "../../../constants/Account";
import {
  FollowerItem,
  FollowersRequest,
  FollowersResponse,
} from "../../../api/follow/model";
import AuthStore from "../../../store/AuthStore";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { follow, getFollowerList, unfollow } from "../../../api/follow/api";
import { EmptyFollower } from ".";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { UserActTypeCode } from "../../../constants/FeedType.enum";
import FollowerStore from "../../../store/FollowerStore";
import KeywordText from "../../../components/common/KeywordText";
import NickNameSearch from "./NickNameSearch";

import BadgeStore from "../../../store/BadgeStore";
import { BadgeCode } from "../../../constants/Badge.enum";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import "./Followings.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { useWindowScroll } from "react-use";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";

const Followings = ({ isMy, userId }: { isMy: boolean; userId: string }) => {
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { t } = useTranslation();
  const { x, y } = useWindowScroll();

  // 첫 렌더링시에만 실행
  useEffect(() => {
    setIsFocused(true);
  }, []);

  const [originFollowingData, setOriginFollowingData] = useState(
    {} as FollowersResponse
  );
  const [followingData, setFollowingData] = useState([] as FollowerItem[]);
  const [keyword, setKeyword] = useState("");
  const [searchStart, setSearchStart] = useState(0);
  const [onSearching, setSearching] = useState(true);
  const [noSearchData, setNoSearchData] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [showNickNameSearch, setShowNickNameSearch] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const ref = useRef<any>(null);
  // const insets = useSafeAreaInsets();
  // useScrollToTop(ref);

  useEffect(() => {
    const bottom =
      window.scrollY !== 0 &&
      Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

    if (bottom && !loading) {
      const start = searchStart + 1;
      if (originFollowingData?.totalCount > start * FOLLOWER_LIST_COUNT) {
        void getFollowers(start, keyword);
        setSearchStart(start);
      }
    }
  }, [y]);

  const onToast = (follow: boolean, item: any) => {
    CommonStore.setToastOption({
      show: true,
      message1: follow
        ? t("screen.myHome.message.followToast", {
            nickname: item.nickname,
          })
        : t("screen.myHome.message.unfollowToast", {
            nickname: item.nickname,
          }),
    });
  };

  const followUser = async (item: any) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.USER_FOLLOWING,
      item.memberUuid,
      ActPageCode.FOLLOWING_MANAGEMENT
    );

    changeFollowStatusInList(item, true);
    const res = await follow(item.memberUuid);
    FollowerStore.handlePostFollowUpdate(
      UserActTypeCode.FOLLOW,
      item.memberUuid
    );
    if (!res) changeFollowStatusInList(item, false);
    else {
      onToast(true, item);
      void BadgeStore.obtainBadge(BadgeCode.FOLLOW);
    }
  };

  const unFollowUser = async (item: any) => {
    //PPIT210117-4818 UNFOLLOW 시 actHistory 처리 추가
    CommonStore.callActHistory(
      ActHistoryTypeCode.USER_FOLLOWING,
      item.memberUuid,
      ActPageCode.FOLLOWING_MANAGEMENT,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      "CANCEL"
    );

    changeFollowStatusInList(item, false);
    const res = await unfollow(item.memberUuid);
    FollowerStore.handlePostFollowUpdate(
      UserActTypeCode.UN_FOLLOW,
      item.memberUuid
    );
    if (!res) changeFollowStatusInList(item, true);
    else onToast(false, item);
  };

  const followingUser = (item: any) => {
    const myAccount = item.memberUuid === AuthStore.sessionUser?.memberUuid;
    return (
      <div className="item-wrapper">
        <div
          onClick={() =>
            goTo(`/user/${item.nickname}`, {
              state: {
                targetUserId: item.memberUuid,
                nickname: item.nickname,
                profileUrl: item.profileUrl,
                profileBackgroundColor: item.profileBackgroundColor,
              },
            })
          }
          className="item-left-wrapper"
        >
          <FastImageWithFallback
            aria-label="Profile image"
            className="profile-image"
            source={{
              uri: `${PUBLIC_BUCKET_URL}${item.profileUrl || ""}`,
            }}
            fallbackImageUri={FALLBACK_IMAGE_URL}
          />
          <div className="info-user-wrapper">
            <div className="info-user-top">
              <KeywordText
                containerStyle={{ display: "flex" }}
                matchTextStyle={{ color: "#be69c3" }}
                sentence={item.nickname}
                keyword={keyword}
                accessibilityLabel="Nickname"
              />
            </div>
            <div className="info-user-bottom">
              <p className="tags-text" aria-label="Tags">
                {item.interestedTags}
              </p>
            </div>
          </div>
        </div>
        {!myAccount &&
          (item.myMemberFollowing === "Y" ? (
            <TouchableWithAsyncTask
              onPress={async () => await unFollowUser(item)}
              className="item-right-wrapper un-follow-btn"
            >
              <p aria-label="Following">
                {t("screen.followers.button.follwing")}
              </p>
            </TouchableWithAsyncTask>
          ) : (
            <TouchableWithAsyncTask
              onPress={async () => await followUser(item)}
              className="item-right-wrapper follow-btn"
            >
              <p aria-label="Follow">{t("screen.followers.button.follow")}</p>
            </TouchableWithAsyncTask>
          ))}
      </div>
    );
  };

  const changeFollowStatusInList = (item: any, isFollowed: boolean) => {
    const tempItem = item;
    tempItem.myMemberFollowing = isFollowed ? "Y" : "N";

    setFollowingData((prevData) => {
      const updatedData = [...prevData];
      if (item.index >= 0 && item.index < updatedData.length) {
        updatedData[item.index] = tempItem;
      }
      return updatedData;
    });
  };

  const filteringData = (text: string) => {
    setKeyword(text);
    setSearchStart(0);
    void getFollowers(0, text);
  };

  const getFollowers = async (startIndex: number, nickname?: string) => {
    setSearching(true);
    setLoading(true);

    if (startIndex == 0) {
      setFollowingData([]);
    }
    setNoSearchData(nickname !== undefined && nickname.length > 0);

    const params: FollowersRequest = {
      searchMemberUuid: userId,
      myMemberUuid: AuthStore.sessionUser?.memberUuid || "",
      type: FollowersTab.FOLLOWINGS,
      pageSize: FOLLOWER_LIST_COUNT,
      pageIndex: startIndex,
      nickname: nickname,
    };
    const res = await getFollowerList(params);
    setSearching(false);
    setLoading(false);

    if (startIndex > 0)
      setFollowingData((prevArray) => [
        ...new Set([...prevArray, ...res.list]),
      ]);
    else setFollowingData(res.list);

    setOriginFollowingData(res);
  };

  // get follower info
  useEffect(() => {
    if (AuthStore.sessionUser?.memberUuid !== undefined && isFocused) {
      setSearching(true);
      setLoading(true);

      const getFollowers = async () => {
        const params: FollowersRequest = {
          searchMemberUuid: userId,
          myMemberUuid: AuthStore.sessionUser?.memberUuid || "",
          type: FollowersTab.FOLLOWINGS,
          pageSize: FOLLOWER_LIST_COUNT,
          pageIndex: 0,
          nickname: keyword,
        };
        const res = await getFollowerList(params);
        setSearching(false);
        setLoading(false);

        setFollowingData(res?.list);
        setOriginFollowingData(res || "");
      };

      if (userId !== undefined && userId !== null && userId.length > 0) {
        void getFollowers();
      }
    }
  }, [userId, isFocused, keyword]);

  // 스크롤시 닉네임 검색 헤더 처리
  // TODO: 애니메이션 처리
  // const translation = useRef(new Animated.Value(0)).current;
  // const maxHeight = translation.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: [-100, 0], // <-- value that larger than your content's height
  // });

  // useEffect(() => {
  //   Animated.timing(translation, {
  //     toValue: showNickNameSearch ? 1 : 0,
  //     duration: 150,
  //     useNativeDriver: false,
  //   }).start();
  // }, [showNickNameSearch, translation]);

  // const shared = useSharedValue(0);
  // const isScrolling = useSharedValue(false);

  return (
    <div className="following-list-wrapper">
      <div className="followingList" aria-label="Following list">
        <NickNameSearch keyword={keyword} filteringData={filteringData} />
        {followingData.length > 0 ? (
          followingData.map((item: any, index: number) => followingUser(item))
        ) : (
          <EmptyFollower
            type={FollowersTab.FOLLOWERS}
            isSearchEmpty={noSearchData}
            onSearching={onSearching}
          />
        )}
        <div style={{ height: 60, backgroundColor: "transparent" }} />
      </div>
      {/* <Virtuoso
          className="followingList"
          aria-label="Following list"
          data={followingData}
          itemContent={(index, item) => {
            return followingUser(item);
          }}
          useWindowScroll
          increaseViewportBy={window.innerHeight}
          endReached={() => {
            const start = searchStart + 1;
            if (originFollowingData?.totalCount > start * FOLLOWER_LIST_COUNT) {
              void getFollowers(start, keyword);
              setSearchStart(start);
            }
          }}
          components={{
            Header: () => (
              <NickNameSearch keyword={keyword} filteringData={filteringData} />
            ),
            EmptyPlaceholder: () => (
              <EmptyFollower
                type={FollowersTab.FOLLOWINGS}
                isSearchEmpty={noSearchData}
                onSearching={onSearching}
              />
            ),
            Footer: () => (
              <div style={{ height: 60, backgroundColor: "transparent" }} />
            ),
          }}
        /> */}
    </div>
  );
};

export default Followings;
