import React, { useCallback, useEffect, useState } from "react";

const useDebounce = <T, S = T>(value: T, fn: (value: T) => S, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState<S>();

  /* eslint-disable */
  const callbackFn = useCallback((value: T) => {
    return fn(value);
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(callbackFn(value));
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  /* eslint-enable */

  return debouncedValue;
};

export default useDebounce;
