import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Icons,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { getProductDetail, getRelatedProductList } from "../../../api/product/api";
import { ProductInfo, RelatedProduct } from "../../../api/product/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import BasicHeader from "../../../components/headers/BasicHeader";
import { useTranslation } from "react-i18next";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { ReactComponent as MallTag } from "../../../assets/icons/mall_sale_tag.svg";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { Styles } from "../../../assets/types/Style";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";
import "./RelatedProductList.scss";
import clsx from "clsx";

const RelatedProductList = observer(
  ({ productId, productName }: { productId: number; productName: string }) => {
    const {
      bar,
      modal,
      header_text,
      modal_body,
      icons,
      modal_header,
      modal_tag_text,
      modal_body_text,
      modal_body_title,
      row,
      last_row,
      relation_product_item,
      relation_product_first_item,
      relation_product_image,
      relation_product_info,
      relation_product_item_text1,
      relation_product_item_text2,
      indicator,
      text_bar,
      list_footer,
    } = styles;
    const { t } = useTranslation();
    const [relatedProductList, setRelatedProductList] = useState<
      RelatedProduct[]
    >([]);
    const pageSize = 10;
    const [pageIndex, setPageIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [productTagName, setProductTagName] = useState<string>(productName);

    const addRelatedProductList = (
      newFeedList: RelatedProduct[],
      pageIndex: number
    ) => {
      if (newFeedList.length > 0) {
        setRelatedProductList([...relatedProductList, ...newFeedList]);
        setPageIndex(pageIndex);
      } else {
        setCanLoadMore(false);
      }
    };

    const getMoreRelatedProducts = async (
      productId: number,
      pageSize: number,
      pageIndex: number
    ) => {
      setIsLoading(true);
      const res = await getRelatedProductList(productId, pageSize, pageIndex);
      const productLists: RelatedProduct[] = res == null ? [] : res;
      if (pageIndex > 0) {
        addRelatedProductList(productLists, pageIndex);
      }
      setIsLoading(false);
    };

    useEffect(() => {
      const initRelatedProductList = async () => {
        setIsLoading(true);
        const res = await getRelatedProductList(productId, pageSize, 0);
        const productLists: RelatedProduct[] = res == null ? [] : res;
        setRelatedProductList(productLists);
        setIsLoading(false);
      };
      void initRelatedProductList();
    }, [productId]);
    
    useEffect(() => {
      const getProductName = async() => {
        setIsLoading(true);
        const res : ProductInfo = await getProductDetail(productId);
        setProductTagName(res.tagName);
        setIsLoading(false);
      }
      
      productName === undefined && productId && void getProductName()
    }, [productId, productName]);

    const handleMoreRealPostList = () => {
      if (!isLoading) {
        void getMoreRelatedProducts(productId, pageSize, pageIndex + 1);
      }
    };

    const renderRelatedProductItem = ({
      item,
      index,
    }: {
      item: RelatedProduct;
      index: number;
    }) => {
      return (
        <div
          // style={relation_product_item}
          key={index}
          onClick={() => {
            CommonStore.callActHistory(
              ActHistoryTypeCode.PRODUCT_READ,
              item.productId.toString(),
              ActPageCode.RELATED_PRODUCT,
              undefined,
              index.toString()
            );
            goTo(`/Product/${item.productId}`);
          }}
          className={clsx("related-product-item")}
        >
          <FastImageWithFallback
            source={{
              uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                item.thumbnailFilePath || ""
              }`,
            }}
            wrapperClassName="relation_product_image_wrapper"
            className="relation_product_image"
            fallbackImageUri={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
              item.originalFilePath || ""
            }`}
            // style={relation_product_image}
          />
          <div className="relation_product_item_text1">{item.productName}</div>
          <div className="relation_product_info">
            <span className="relation_product_item_text2 left">{item.brandName}</span>
            <div className="bar" />
            <span className="relation_product_item_text2 right">
              {item.productModelName}
            </span>
          </div>
          {item.lifecareProductId && item.lifecareProductId !== null ? (
            <div className="on_sale_tag_wrap">
              <div className="mall_tag">
                <MallTag />
              </div>
              <span className="on_sale_tag_text">
                {t("screen.main.label.onSale")}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    };

    const renderHeader = () => {
      return (
        <div className="modal_body_title">
          <div className="modal_tag_text">
            {productTagName}
            <span className="modal_body_text">
              {t(`screen.product.label.relatedProduct.modalTitle1`)}
            </span>
          </div>
          <div className="modal_body_text">
            {t(`screen.product.label.relatedProduct.modalTitle2`)}
          </div>
        </div>
      );
    };
    const renderFooter = (canLoadMore: boolean, isLoading: boolean) => {
      return canLoadMore && isLoading ? (
        <CustomActivityIndicatorSmall />
      ) : (
        <></>
      );
    };

    return (
      <div id="related-product-list-wrapper">
        <BasicHeader
          title={t(`screen.product.label.relatedProduct.modalTitle`)}
          onSearch={() => {
            goTo("/Search");
          }}
        />
        <div className="title-header-wrapper">
          {renderHeader()}
          <div
            className="related_products_wrapper"
            // style={{ display: "flex", flexWrap: "wrap" }}
          >
            {relatedProductList &&
              relatedProductList.length > 0 &&
              relatedProductList.map((item, index) => {
                return renderRelatedProductItem({ item, index });
              })}
          </div>
          {canLoadMore ? (
            renderFooter(canLoadMore, isLoading)
          ) : (
            <div style={{ paddingBottom: 50 }} />
          )}
          {canLoadMore && (
            <InfiniteScrollObserver onObserver={handleMoreRealPostList} />
          )}
        </div>
      </div>
    );
  }
);

export default RelatedProductList;

const styles: Styles = {
  modal: {
    ...FlexBoxs.flex_1,
    width: "100%",
    ...Colors.background_fff,
    ...Colors.border_color_transparent,
  },
  modal_header: {
    height: 48,
    ...Arranges.center_v_h,
    ...Positions.relative,
  },
  modal_body: {
    ...Spacings.padding_left_right_16_responsive,
    ...Spacings.padding_top_20,
  },
  icons: {
    ...Icons.icon_32,
    ...Positions.absolute,
    ...Positions.top_6,
    ...Positions.right_10,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  modal_tag_text: {
    ...Texts.contents_text_2,
    ...Colors.font_a760b1,
  },
  modal_body_text: {
    ...Texts.contents_text_2,
  },
  list_footer: {
    paddingBottom: 50,
  },
  modal_body_title: {
    ...Spacings.margin_bottom_40,
  },

  relation_product_item: {
    width: "44.4%",
    ...Spacings.margin_right_8,
    ...Spacings.margin_bottom_20,
  },
  relation_product_image: {
    width: "44.4%",
    height: "44.4%",
    ...Spacings.border_radius_8,
    ...Spacings.margin_bottom_8,
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.1)",
  },
  relation_product_info: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  relation_product_item_text1: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    display: "-webkit-box",
    WebkitLineClamp: 2,
  },
  relation_product_item_text2: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
  },
  indicator: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_left_right_7,
  },
  text_bar: {
    height: 10,
    width: 1,
    ...Colors.background_eeeeee,
    ...Spacings.margin_right_left_6,
  },
  on_sale_tag_wrap: {
    width: "auto",
    backgroundColor: "#F0DFF2",
    borderRadius: 4,
    alignSelf: "flex-start",
    height: 17,
    marginTop: 4,
    marginRight: 4,
    marginBottom: 2,
    flexDirection: "row",
    ...Arranges.center_v_h,
  },
  on_sale_tag_text: {
    color: "#BE69C2",
    fontSize: 10,
    fontWeight: "700",
    letterSpacing: -0.4,
    lineHeight: 14,
    paddingLeft: 1,
    paddingRight: 6,
    paddingVertical: 1,
    textAlign: "right",
  },
  mall_tag: {
    paddingLeft: 6,
    paddingVertical: 3,
  },
};
