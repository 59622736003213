import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useWindowScrollPositions } from "./useWindowScrollPositions";

type ITEM_TYPE =
  | "FIXED_HEADER"
  | "SCROLLABLE_HEADER"
  | "STICKY_TABS"
  | "STICKY_TABS_CUSTOM"
  | "STICKY_ITEMS";

interface T {
  type: ITEM_TYPE;
}

export interface Tab {
  key: string;
  title?: string;
  accessibilityLabel?: string;
  content?: React.ReactElement | ((isActive: boolean) => React.ReactElement);
}

export interface FlatListWithStickyTabsProps {
  scrollableHeader?: React.ReactElement;
  stickyItems?: React.ReactElement;
  listFooterComponent?: React.ReactElement;
  stickyTabs?: Tab[];
  stickyTabsCustom?: React.ReactElement;
  onChangeTab?: (key: string) => void;
  activeTabKey?: string;
  tabStyles?: {
    tabTextContainerStyle?: React.CSSProperties;
    tabTextContainerActiveStyle?: React.CSSProperties;
    tabTextStyle?: React.CSSProperties;
    tabTextActiveStyle?: React.CSSProperties;
    tabWrapperStyle?: React.CSSProperties;
    tabsContainerStyle?: React.CSSProperties;
  };
  onScroll?: (scrollX: number, scrollY: number) => void;
  showScrollToTop?: boolean;
  topButtonRight?: number;
  contentScrollRef: React.RefObject<HTMLDivElement>;
  onRefresh?: () => void;
}

export const Tab = ({
  activeTabIndex,
  stickyTabs,
  onChangeTab,
  tabStyles,
}: {
  activeTabIndex: number;
} & Pick<
  FlatListWithStickyTabsProps,
  "stickyTabs" | "onChangeTab" | "tabStyles"
>) => {
  const handleTabPress = useCallback(
    (key: string) => () => {
      onChangeTab && onChangeTab(key);
    },
    [onChangeTab]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        ...tabStyles?.tabsContainerStyle,
      }}
    >
      {stickyTabs?.map((tab, index) => (
        <span
          key={tab.key}
          style={
            activeTabIndex === index
              ? { ...tabStyles?.tabTextContainerActiveStyle }
              : { ...tabStyles?.tabTextContainerStyle }
          }
          onClick={handleTabPress(tab.key)}
        >
          <span
            style={
              activeTabIndex === index
                ? { ...tabStyles?.tabTextActiveStyle }
                : { ...tabStyles?.tabTextStyle }
            }
          >
            {tab.title}
          </span>
        </span>
      ))}
    </div>
  );
};

// const Tab = ({
//   activeTabIndex,
//   stickyTabs,
//   onChangeTab,
//   tabStyles,
// }: {
//   activeTabIndex: number;
// } & Pick<FlatListWithStickyTabsProps, "stickyTabs" | "onChangeTab" | "tabStyles">) => {
//   const handleTabPress = useCallback(
//     (key: string) => () => {
//       onChangeTab && onChangeTab(key);
//     },
//     [onChangeTab]
//   );

//   return (
//     <div style={{ flexDirection: "row", ...tabStyles?.tabsContainerStyle }}>
//       {stickyTabs?.map((tab, index) => {
//         return (
//           <button
//             aria-label={tab.accessibilityLabel}
//             key={tab.key}
//             style={tabStyles?.tabWrapperStyle}
//             onClick={handleTabPress(tab.key)}
//           >
//             <div
//               style={
//                 activeTabIndex === index ? tabStyles?.tabTextContainerActiveStyle : tabStyles?.tabTextContainerStyle
//               }
//             >
//               <span style={activeTabIndex === index ? tabStyles?.tabTextActiveStyle : tabStyles?.tabTextStyle}>
//                 {tab.title}
//               </span>
//             </div>
//           </button>
//         );
//       })}
//     </div>
//   );
// };

const FlatListWithStickyTabs = ({
  scrollableHeader,
  stickyItems,
  listFooterComponent,
  stickyTabs,
  stickyTabsCustom,
  onChangeTab,
  activeTabKey,
  tabStyles,
  onScroll,
  topButtonRight,
  showScrollToTop,
  contentScrollRef,
  onRefresh,
  ...props
}: FlatListWithStickyTabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    stickyTabs?.findIndex((tab) => tab.key === activeTabKey) || 0
  );
  const { scrollX, scrollY } = useWindowScrollPositions();
  const [bounceable, setBounceable] = useState<boolean>(false);
  const [showTopButton, setShowTopButton] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  // const scrollRef = useRef<FlatList<any>>(null);

  const handleTabChange = useCallback(
    (key: string) => {
      setActiveTabIndex(stickyTabs?.findIndex((tab) => tab.key === key) || 0);
      if (contentScrollRef && contentScrollRef.current) {
        //contentScrollRef.current.scrollToIndex({ index: 0 });
        contentScrollRef.current.scrollTo({ top: 0 });
      }
      onChangeTab && onChangeTab(key);
    },
    [onChangeTab, stickyTabs, contentScrollRef]
  );

  // const FlatListWithStickyTabs = ({
  //   scrollableHeader,
  //   stickyItems,
  //   listFooterComponent,
  //   stickyTabs,
  //   stickyTabsCustom,
  //   onChangeTab,
  //   activeTabKey,
  //   tabStyles,
  //   onScroll,
  //   showScrollToTop,
  //   contentScrollRef,
  //   onRefresh,
  //   ...props
  // }: FlatListWithStickyTabsProps) => {

  //   const [activeTabIndex, setActiveTabIndex] = useState<number>(
  //     stickyTabs?.findIndex((tab) => tab.key === activeTabKey) || 0
  //   );
  //   const [bounceable, setBounceable] = useState<boolean>(false);
  //   const scrollRef = useRef<HTMLDivElement>(null);

  //   const handleTabChange = useCallback(
  //     (key: string) => {
  //       setActiveTabIndex(stickyTabs?.findIndex((tab) => tab.key === key) || 0);
  //       if (contentScrollRef.current) {
  //         contentScrollRef.current.scrollTo({ top: 0 });
  //       }
  //       onChangeTab && onChangeTab(key);
  //     },
  //     [onChangeTab, stickyTabs, contentScrollRef]
  //   );

  const memoisedStickyHeaderIndices = useMemo(
    () => (scrollableHeader ? [1] : [0]),
    [scrollableHeader]
  );

  const memoisedData = useMemo(() => {
    const _data: T[] = [];
    scrollableHeader && _data.push({ type: "SCROLLABLE_HEADER" });
    stickyTabs && _data.push({ type: "STICKY_TABS" });
    stickyTabsCustom && _data.push({ type: "STICKY_TABS_CUSTOM" });
    stickyItems && _data.push({ type: "STICKY_ITEMS" });
    return _data;
  }, [scrollableHeader, stickyTabs, stickyItems, stickyTabsCustom]);

  const memoisedRenderItem = useCallback(
    (item: { type?: ITEM_TYPE }, index: number) => {
      if (scrollableHeader && item.type === "SCROLLABLE_HEADER") {
        return scrollableHeader;
      } else if (stickyTabsCustom && item.type === "STICKY_TABS_CUSTOM") {
        return stickyTabsCustom;
      } else if (stickyTabs && item.type === "STICKY_TABS") {
        return (
          <div style={{ backgroundColor: "white" }}>
            <Tab
              activeTabIndex={activeTabIndex}
              stickyTabs={stickyTabs}
              onChangeTab={handleTabChange}
              tabStyles={tabStyles}
            />
          </div>
        );
      } else if (stickyItems && item.type === "STICKY_ITEMS") {
        return stickyItems;
      } else {
        return null;
      }
    },
    [
      activeTabIndex,
      handleTabChange,
      scrollableHeader,
      stickyTabs,
      stickyItems,
      tabStyles,
      stickyTabsCustom,
    ]
  );

  // const memoisedRenderItem: ListRenderItem<T> = useCallback(
  //   (info: ListRenderItemInfo<T>) => {
  //     if (scrollableHeader && info.item.type === "SCROLLABLE_HEADER") {
  //       return scrollableHeader;
  //     } else if (stickyTabsCustom && info.item.type === "STICKY_TABS_CUSTOM") {
  //       return stickyTabsCustom;
  //     } else if (stickyTabs && info.item.type === "STICKY_TABS") {
  //       return (
  //         <View style={{ backgroundColor: "white" }}>
  //           <Tab
  //             activeTabIndex={activeTabIndex}
  //             stickyTabs={stickyTabs}
  //             onChangeTab={handleTabChange}
  //             tabStyles={tabStyles}
  //           />
  //         </View>
  //       );
  //     } else if (stickyItems && info.item.type === "STICKY_ITEMS") {
  //       return stickyItems;
  //     } else {
  //       return null;
  //     }
  //   },
  //   [activeTabIndex, handleTabChange, scrollableHeader, stickyTabs, stickyItems, tabStyles, stickyTabsCustom]
  // );

  const memoisedKeyExtractor = useCallback(
    (item: T | { type?: ITEM_TYPE }, index: number) => {
      let key = "";
      if ((item as any).type as ITEM_TYPE) {
        key = (item as any).type;
      }
      return key;
    },

    []
  );

  // /* eslint-disable */
  // const handleScroll = useCallback(
  //   (e: NativeSyntheticEvent<NativeScrollEvent>) => {
  //     const { height } = Dimensions.get("screen");
  //     setBounceable(e.nativeEvent.contentOffset.y >= responsiveScreenHeight(100));
  //     if (onScroll) {
  //       onScroll(e);
  //     }
  //     handleTopButtonScroll(
  //       e,
  //       () => {
  //         setShowTopButton(true);
  //       },
  //       () => {
  //         setShowTopButton(false);
  //       },
  //       contentScrollRef
  //     );
  //   },
  //   [onScroll]
  // );
  // /* eslint-enable */

  useEffect(() => {
    const { height } = window.screen;
    setBounceable(scrollY >= height);
    if (onScroll) {
      onScroll(scrollX, scrollY);
    }
  }, [scrollX, scrollY]);

  useEffect(() => {
    setActiveTabIndex(
      stickyTabs?.findIndex((tab) => tab.key === activeTabKey) || 0
    );
  }, [activeTabKey, stickyTabs]);
  const [state, setState] = useState({
    isFetching: false,
  });

  const onRefresh2 = () => {
    setState({ isFetching: false });

    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <>
      {onRefresh !== undefined ? (
        <div
          ref={contentScrollRef}
          // style={{ overflowY: "scroll" }}
        >
          {memoisedData.map((item, index) => (
            <div key={memoisedKeyExtractor(item, index)} style={{ ...(item.type === "STICKY_ITEMS" ? { position: 'sticky', top: 60, zIndex: 99 } : {}) }}>
              {memoisedRenderItem(item, index)}
            </div>
          ))}
          {/* 형변환 추가 */}
          {
            stickyTabs ? stickyTabs[activeTabIndex]?.content as React.ReactNode : listFooterComponent
          }
          {/* 위의 줄은 ListFooterComponent를 나타냅니다. */}
        </div>
      ) : (
        <div
          ref={contentScrollRef}
          // style={{ overflowY: "scroll" }}
        >
          {memoisedData.map((item, index) => (
            <div key={index} style={{ ...(item.type === "STICKY_ITEMS" ? { position: 'sticky', top: 60, zIndex: 99 } : {}) }}>
              {memoisedRenderItem(item, index)}
            </div>
          ))}
          {/* 형변환 추가 */}
          {
            stickyTabs ? stickyTabs[activeTabIndex]?.content as React.ReactNode : listFooterComponent
          }
          {/* 위의 줄은 ListFooterComponent를 나타냅니다. */}
        </div>
      )}
    </>
  );
};

export default FlatListWithStickyTabs;