import { observer } from "mobx-react";
import {
  BrandCommunityLineUpResponse,
  BrandLineUpSortType,
  BrandListTabType,
} from "../../../api/brand/model";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_small.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { TitleHeader } from "../../../components/common";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import CustomBottomSheet from "../../../components/common/CustomBottomSheet";
import {
  brandSubscribe,
  brandSubscribeDelete,
  getBrandCommunityLineUp,
} from "../../../api/brand/api";
import AuthStore from "../../../store/AuthStore";
import { countFollow } from "../../../utils/numberCount";
import { showBottomToast } from "../../../utils/Toast";
import { bottomOffset } from "../../../utils/common";
import { Styles } from "../../../assets/types/Style";
import {
  margin_left_4,
  margin_right_4,
  margin_top_10,
} from "../../../assets/styles/spacings";
import { Virtuoso } from "react-virtuoso";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
// import { BrandPopupMenu } from "./presenter/BrandPopupMenu";
import { ContentsPopupMenu } from "../../../components/common/ContentsPopupMenu";

const BrandCommunityListScreen = observer((data: any) => {
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";
  const PAGE_SIZE = 200;

  const [brandList, setBrandList] = useState<BrandCommunityLineUpResponse[]>(
    []
  );
  const [selectedTab, setSelectedTab] = useState<BrandListTabType>(
    BrandListTabType.TOTAL
  );
  const [sortType, setSortType] = useState<BrandLineUpSortType>(
    BrandLineUpSortType.POPULAR
  );
  const [pageIndex, setPageindex] = useState<number>(0);
  const [showSortBox, setShowSortBox] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { t } = useTranslation();

  const memberUuid = AuthStore.sessionUser?.memberUuid
    ? AuthStore.sessionUser?.memberUuid
    : AuthStore.tempMember?.tempMemberUUID
    ? AuthStore.tempMember?.tempMemberUUID
    : "";

  const brandListType =
    data?.route?.params.brandListType === undefined
      ? BrandListTabType.TOTAL
      : (data?.route?.params.brandListType as BrandListTabType);
  const initSortType =
    data?.route?.params.sortType === undefined
      ? BrandLineUpSortType.POPULAR
      : (data?.route?.params.sortType as BrandLineUpSortType);

  useEffect(() => {
    if (brandListType === BrandListTabType.SUBSCRIBING)
      setSelectedTab(BrandListTabType.SUBSCRIBING);
    else setSelectedTab(BrandListTabType.TOTAL);
  }, [brandListType]);

  useEffect(() => {
    if (initSortType !== undefined) setSortType(initSortType);
  }, [initSortType]);

  useEffect(() => {
    getBrandList();
    // eslint-disable-next-line
  }, [selectedTab, sortType, memberUuid]);

  useEffect(() => {
    setRefreshing(true);
  }, [memberUuid]);

  const getBrandList = () => {
    if (isFirst) {
      if (brandListType === BrandListTabType.SUBSCRIBING) {
        void getBrandCommunityLineUp(
          memberUuid,
          initSortType,
          PAGE_SIZE,
          pageIndex,
          true
        ).then((res) => {
          res === null ? setBrandList([]) : setBrandList(res);
          setIsFirst(false);
        });
      } else {
        setPageindex(0);
        void getBrandCommunityLineUp(
          memberUuid,
          initSortType,
          PAGE_SIZE,
          pageIndex
        ).then((res) => {
          res === null ? setBrandList([]) : setBrandList(res);
          setIsFirst(false);
        });
      }
    } else {
      if (selectedTab === BrandListTabType.TOTAL) {
        setPageindex(0);
        void getBrandCommunityLineUp(
          memberUuid,
          sortType,
          PAGE_SIZE,
          pageIndex
        ).then((res) => {
          res === null ? setBrandList([]) : setBrandList(res);
        });
      } else if (selectedTab === BrandListTabType.SUBSCRIBING) {
        void getBrandCommunityLineUp(
          memberUuid,
          sortType,
          PAGE_SIZE,
          pageIndex,
          true
        ).then((res) => {
          res === null ? setBrandList([]) : setBrandList(res);
        });
      }
    }
  };

  const actionBrandSubscribe = async (
    brand: BrandCommunityLineUpResponse,
    index: number
  ) => {
    setRefreshing(true);
    await brandSubscribe(brand.communityId).then((res) => {
      if (res.successOrNot === "Y") {
        brandList?.splice(index, 1, {
          ...brand,
          subscriptionYn: "Y",
          communitySubscriberCount: (
            Number(brand.communitySubscriberCount) + 1
          ).toString(),
        });
        showBottomToast(
          t("screen.brandCommunity.message.subscribe"),
          bottomOffset()
        );
      }
    });
    setRefreshing(false);
  };

  const actionBrandSubscribeCancel = async (
    brand: BrandCommunityLineUpResponse,
    index: number
  ) => {
    setRefreshing(true);
    await brandSubscribeDelete(brand.communityId).then((res) => {
      if (res.successOrNot === "Y") {
        brandList?.splice(index, 1, {
          ...brand,
          subscriptionYn: "N",
          communitySubscriberCount: (
            Number(brand.communitySubscriberCount) - 1
          ).toString(),
        });
        showBottomToast(
          t("screen.brandCommunity.message.subscribeCancel"),
          bottomOffset()
        );
      }
    });
    setRefreshing(false);
  };

  const goToBrandPageHome = (communityId: number) => {
    goTo("/BrandCommunityHome", {
      state: {
        targetBrandId: communityId.toString(),
      },
    });
  };

  const renderHeader = () => {
    return (
      <>
        <div style={styles.header_tab}>
          <button
            style={{
              ...styles.tab,
              ...(selectedTab === BrandListTabType.TOTAL
                ? styles.tab_active
                : styles.tab_inactive),
            }}
            onClick={() => {
              setSelectedTab(BrandListTabType.TOTAL);
              setSortType(BrandLineUpSortType.POPULAR);
            }}
          >
            <p
              style={{
                ...styles.tab_text,
                ...(selectedTab === BrandListTabType.TOTAL
                  ? styles.tab_active_text
                  : styles.tab_inactive_text),
              }}
            >
              {t("screen.brandCommunity.label.total")}
            </p>
          </button>
          <button
            style={{
              ...styles.tab,
              ...(selectedTab === BrandListTabType.SUBSCRIBING
                ? styles.tab_active
                : styles.tab_inactive),
            }}
            onClick={() => {
              setSelectedTab(BrandListTabType.SUBSCRIBING);
              setSortType(BrandLineUpSortType.POPULAR);
            }}
          >
            <p
              style={{
                ...styles.tab_text,
                ...(selectedTab === BrandListTabType.SUBSCRIBING
                  ? styles.tab_active_text
                  : styles.tab_inactive_text),
              }}
            >
              {t("screen.brandCommunity.label.subscribing")}
            </p>
          </button>
        </div>
        {brandList.length > 0 && (
          <div style={styles.list_title}>
            <div style={styles.list_title_text} />
            <button
              style={styles.header_sort_btn}
              onClick={() => {
                setShowSortBox(true);
              }}
            >
              <p style={styles.header_sort_btn_txt}>
                {sortType === BrandLineUpSortType.LATEST
                  ? t("screen.brandCommunity.sortType.latest")
                  : sortType === BrandLineUpSortType.POPULAR
                  ? t("screen.brandCommunity.sortType.popular")
                  : sortType === BrandLineUpSortType.ACCURACY
                  ? t("screen.brandCommunity.sortType.accuracy")
                  : t("screen.brandCommunity.sortType.popular")}
              </p>
              <ArrowDown />
            </button>
          </div>
        )}
      </>
    );
  };

  const renderEmpty = () => {
    return (
      <div style={styles.empty_wrap}>
        <p style={styles.empty_text_01}>
          {t("screen.brandCommunity.message.noSubscribeBrand1")}
        </p>
        <p style={styles.empty_text_02}>
          {t("screen.brandCommunity.message.noSubscribeBrand2")}
        </p>
      </div>
    );
  };

  return (
    <div style={{ flex: 1, backgroundColor: "#fff" }}>
      <TitleHeader
        title={t("screen.brandCommunity.label.brandList")}
        navigation={goTo}
        isBack
      />
      {renderHeader()}
      <div>
        <Virtuoso
          style={styles.brand_list}
          id="community brand list"
          data={brandList}
          useWindowScroll
          itemContent={(index: number, item: BrandCommunityLineUpResponse) => {
            return (
              <>
                <div style={styles.brand_item}>
                  <button
                    style={styles.info_wrap}
                    onClick={() => {
                      goToBrandPageHome(item.communityId);
                    }}
                  >
                    <FastImageWithFallback
                      style={styles.profileImage}
                      source={{
                        uri: `${PUBLIC_BUCKET_URL}${
                          item.communityLogoUrl || ""
                        }`,
                      }}
                      fallbackImageUri={FALLBACK_IMAGE_URL}
                    />
                    <div style={styles.info_text_wrap}>
                      <p style={styles.txt_info_title}>{item.communityName}</p>
                      <p style={styles.txt_info_detail}>
                        {item.communityIntroduce}
                      </p>
                      <div style={styles.num_view_wrap}>
                        <div style={styles.num_item_wrap}>
                          &nbsp;
                          <p style={styles.view_text1}>
                            {t("screen.brandCommunity.label.subscriber")}&nbsp;
                          </p>
                          <p style={styles.view_text2}>
                            {countFollow(Number(item.communitySubscriberCount))}
                            &nbsp;
                          </p>
                        </div>
                        <div style={styles.div_dot}>&nbsp;</div>
                        <div style={styles.num_item_wrap}>
                          &nbsp;
                          <p style={styles.view_text1}>
                            {t("screen.brandCommunity.label.article")}&nbsp;
                          </p>
                          <p style={styles.view_text2}>
                            {countFollow(Number(item.communityArticleCount))}
                            &nbsp;
                          </p>
                        </div>
                        <div style={styles.div_dot}>&nbsp;</div>
                        <div style={styles.num_item_wrap}>
                          &nbsp;
                          <p style={styles.view_text1}>
                            {t("screen.brandCommunity.label.product")}&nbsp;
                          </p>
                          <p style={styles.view_text2}>
                            {countFollow(Number(item.communityProductCount))}
                          </p>
                        </div>
                      </div>
                    </div>
                  </button>

                  {item.subscriptionYn === "Y" ? (
                    <div
                      style={{ ...styles.btn_follow, ...styles.btn_following }}
                    >
                      <p
                        style={{
                          ...styles.txt_btn_follow,
                          ...styles.txt_btn_following,
                        }}
                      >
                        {t("screen.brandCommunity.label.subscribing")}
                      </p>
                      <button>
                        <ContentsPopupMenu
                          optionTypesToShow={["CANCEL"]}
                          onMenuClick={(types) => {
                            if (types === "CANCEL") {
                              void actionBrandSubscribeCancel(item, index);
                            }
                          }}
                          needAuth={true}
                        />
                      </button>
                    </div>
                  ) : (
                    <TouchableWithAuthCheck
                      style={styles.btn_follow}
                      onPress={() => {
                        void actionBrandSubscribe(item, index);
                      }}
                    >
                      <p style={styles.txt_btn_follow}>
                        {t("screen.brandCommunity.button.subscribe")}
                      </p>
                    </TouchableWithAuthCheck>
                  )}
                </div>
              </>
            );
          }}
          components={{
            EmptyPlaceholder: () => <>{renderEmpty()}</>,
          }}
        />
      </div>

      <CustomBottomSheet
        snapHeight={236}
        open={showSortBox}
        onClose={() => setShowSortBox(false)}
      >
        <div style={styles.sheet}>
          <button
            style={styles.close_btn}
            onClick={() => setShowSortBox(false)}
          >
            <CloseIcon />
          </button>
          <div style={styles.sheet_title_wrap}>
            <p style={styles.sheet_title}>{t("screen.search.label.sort")}</p>
          </div>

          <div style={styles.sort_list}>
            <button
              style={styles.sort_item}
              onClick={() => {
                setShowSortBox(false);
                setSortType(BrandLineUpSortType.POPULAR);
              }}
            >
              <p
                style={{
                  ...styles.sort_text,
                  ...(sortType === BrandLineUpSortType.POPULAR &&
                    styles.sort_active_text),
                }}
              >
                {t("screen.brandCommunity.sortType.popular")}
              </p>
            </button>
            <button
              style={styles.sort_item}
              onClick={() => {
                setShowSortBox(false);
                setSortType(BrandLineUpSortType.LATEST);
              }}
            >
              <p
                style={{
                  ...styles.sort_text,
                  ...(sortType === BrandLineUpSortType.LATEST &&
                    styles.sort_active_text),
                }}
              >
                {t("screen.brandCommunity.sortType.latest")}
              </p>
            </button>

            {selectedTab === BrandListTabType.TOTAL && (
              <button
                style={styles.sort_item}
                onClick={() => {
                  setShowSortBox(false);
                  setSortType(BrandLineUpSortType.ACCURACY);
                }}
              >
                <p
                  style={{
                    ...styles.sort_text,
                    ...(sortType === BrandLineUpSortType.ACCURACY &&
                      styles.sort_active_text),
                  }}
                >
                  {t("screen.brandCommunity.sortType.accuracy")}
                </p>
              </button>
            )}
          </div>
        </div>
      </CustomBottomSheet>
    </div>
  );
});

export default BrandCommunityListScreen;

export const styles: Styles = {
  line_height_10: {
    height: 10,
    backgroundColor: "#f5f5f5",
  },

  header_tab: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    height: 48,
  },
  tab: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    ...Arranges.center_v_h,
    borderStyle: "solid",
    borderBottomWidth: 2,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  tab_text: {
    ...Texts.contents_text_38,
  },
  tab_inactive: {
    ...Colors.border_color_eee,
  },
  tab_inactive_text: {
    ...Colors.font_222,
  },
  tab_active: {
    ...Colors.border_color_222,
  },
  tab_active_text: {
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },

  list_title: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.padding_right_left_16,
  },
  list_title_text: {
    ...FlexBoxs.flex,
    position: "relative",
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  bg_list_title: {
    height: 8,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#F7E237",
  },
  list_title_text_st1: {
    fontSize: 18,
    fontWeight: "500",
    color: "#222",
    letterSpacing: -0.72,
  },
  header_sort_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Spacings.padding_top_bottom_10,
  },
  header_sort_btn_txt: {
    ...FlexBoxs.flex,
    ...Texts.contents_text_0,
    ...Arranges.center_v_h,
  },

  brand_list: {
    ...Spacings.margin_left_right_16,
    ...Colors.background_fff,
    zIndex: 1,
  },
  brand_item: {
    ...FlexBoxs.flex,
    overflow: "hidden",
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.padding_bottom_24,
    ...FlexBoxs.flex_1,
  },
  info_wrap: {
    ...FlexBoxs.flex,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    overflow: "hidden",
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  info_text_wrap: {
    paddingLeft: 14,
    paddingRight: 14,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
  },
  txt_info_title: {
    ...Texts.font_size_16,
    ...Texts.font_weight_500,
    ...Texts.font_letter_spacing_064,
    ...Colors.font_222,
    ...FlexBoxs.flex,
    textAlign: "start",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    lineClamp: "1",
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
  },
  txt_info_detail: {
    marginTop: 5,
    marginBottom: 1.89,
    ...Texts.font_size_12,
    ...Texts.font_weight_400,
    ...Texts.font_letter_spacing_048,
    ...Colors.font_666666,
    ...FlexBoxs.flex,
    textAlign: "start",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: "1",
    lineClamp: "1",
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
  },
  num_view_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  num_item_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...FlexBoxs.horizon,
  },
  view_text1: {
    ...Colors.font_666666,
    ...Texts.font_size_12,
    ...Texts.font_letter_spacing_048,
    ...Spacings.margin_right_2,
  },
  view_text2: {
    color: "#682C78",
    ...Texts.font_size_12,
    ...Texts.font_letter_spacing_048,
  },
  div_dot: {
    ...Colors.background_666,
    width: 3,
    height: 3,
    borderRadius: 3,
    ...Spacings.margin_right_left_5,
  },
  brand_btn: {
    ...FlexBoxs.flex,
    height: 36,
    ...Spacings.border_radius_300,
    ...Spacings.padding_left_right_14,
    ...Arranges.center_v_h,
    ...Colors.background_be69c3,
  },
  btn_follow: {
    ...FlexBoxs.flex,
    minWidth: 52,
    height: 32,
    borderRadius: 4,
    ...Arranges.center_v_h,
    ...Colors.background_BE69C3,
    ...Spacings.margin_right_2,
    ...FlexBoxs.horizon,
    flexShrink: 0,
  },
  btn_following: {
    backgroundColor: "#fff",
  },
  txt_btn_follow: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "500",
  },
  txt_btn_following: {
    color: "#BE69C3",
  },
  profileImage: {
    width: 64,
    height: 64,
    borderWidth: 1.4,
    borderColor: "#fff",
    borderRadius: 100,
    overflow: "hidden",
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
  },

  sheet: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_16,
    ...Spacings.padding_bottom_19,
    ...Spacings.padding_left_right_20,
  },
  close_btn: {
    ...Positions.absolute,
    zIndex: 999,
    top: 6,
    right: 6,
  },
  sheet_title_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 32,
  },
  sheet_title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  sort_list: {
    ...FlexBoxs.flex,
    ...Spacings.margin_top_16,
    ...Arranges.center_h,
    ...FlexBoxs.vertical,
  },
  sort_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_top_bottom_15,
  },
  sort_text: {
    ...Texts.contents_text_38,
  },
  sort_active_text: {
    ...Colors.font_be69c3,
    ...Texts.font_weight_bold,
  },

  empty_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
    marginHorizontal: 66,
    marginTop: 83,
  },
  empty_text_01: {
    ...Colors.font_222,
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    fontWeight: "400",
  },
  empty_text_02: {
    ...Colors.font_666666,
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Arranges.text_align_center,
    fontWeight: "400",
    marginTop: 10,
  },
};
