import { action, computed, makeObservable, observable } from "mobx";

import { getLastTerms, getLastTermsDetail } from "../api/terms/api";
import {
  TermsPrivacyResult,
  TermsResult,
  TermsServiceResult,
} from "../api/terms/model";
import { TermsTypeCode, TermsLevelCode } from "../constants/Terms.enum";
export interface TermsAgreeInfo {
  termsId: string;
  termsTypeCode: string;
  termsTitle: string;
  termsLevelCode: string;
  termsAgreeYn: boolean;
}
class TermsStore {
  @observable _termsServiceResult: TermsServiceResult = {
    termsId: "",
  };

  @observable _termsPrivacyResult: TermsPrivacyResult = {
    termsId: "",
  };

  @observable _lastTerms: TermsResult[] = [];

  @observable _termsAgree: TermsAgreeInfo[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed get termsServiceId() {
    return this._termsServiceResult.termsId;
  }

  @computed get termsPrivacyId() {
    return this._termsPrivacyResult.termsId;
  }

  @computed get lastTerms() {
    return this._lastTerms;
  }

  @computed get termsAgree() {
    return this._termsAgree;
  }

  @computed get isRequiredTermsAgree() {
    return (
      this._termsAgree &&
      this._termsAgree.length > 0 &&
      this._termsAgree
        .filter(
          ({ termsLevelCode }) => termsLevelCode === TermsLevelCode.MANDATORY
        )
        .every(({ termsAgreeYn }) => termsAgreeYn)
    );
  }
  @action setTermsServiceId = (termsId: string) => {
    this._termsServiceResult.termsId = termsId;
  };

  @action setTermsPrivacyId = (termsId: string) => {
    this._termsPrivacyResult.termsId = termsId;
  };

  @action setLastTerms = (lastTerms: TermsResult[]) => {
    this._lastTerms = lastTerms;
  };

  @action pushTermsAgree = (term: TermsAgreeInfo) => {
    this._termsAgree.push(term);
  };

  @action setAllTermsAgreeN = () => {
    this._termsAgree = this._termsAgree.map((term) => ({
      ...term,
      termsAgreeYn: false,
    }));
  };

  @action toggleAllterms = () => {
    const isAllTermsAgree = this._termsAgree.every(
      ({ termsAgreeYn }) => termsAgreeYn
    );

    this._termsAgree = this._termsAgree.map((term) => ({
      ...term,
      termsAgreeYn: !isAllTermsAgree,
    }));
  };

  @action toggleTermsAgreeYn = (termsId: string) => {
    this._termsAgree = this._termsAgree.map((term) =>
      term.termsId === termsId
        ? { ...term, termsAgreeYn: !term.termsAgreeYn }
        : term
    );
  };

  @action togglePromotionTermsAgreeYn = () => {
    const isPromotionChecked = this._termsAgree
      .filter(({ termsTypeCode }) =>
        termsTypeCode.includes(TermsTypeCode.PROMOTION)
      )
      .every(({ termsAgreeYn }) => termsAgreeYn);

    if (isPromotionChecked) {
      this._termsAgree = this._termsAgree.map((term) =>
        term.termsTypeCode.includes(TermsTypeCode.PROMOTION)
          ? { ...term, termsAgreeYn: false }
          : term
      );
    } else {
      this._termsAgree = this._termsAgree.map((term) =>
        term.termsTypeCode.includes(TermsTypeCode.PROMOTION)
          ? { ...term, termsAgreeYn: true }
          : term
      );
    }
  };

  @action clearTermsAgree = () => {
    this._termsAgree = [];
  };

  @action initTermsServiceResult = async () => {
    const termsServiceResult = await getLastTermsDetail(TermsTypeCode.SERVICE);
    this.setTermsServiceId(termsServiceResult.termsId);
  };

  @action initTermsPrivacyResult = async () => {
    const termsPrivacyResult = await getLastTermsDetail(
      TermsTypeCode.PRIVACY_DETAIL
    );
    this.setTermsPrivacyId(termsPrivacyResult.termsId);
  };

  @action initLastTerms = async () => {
    const lastTerms = await getLastTerms();
    this.setLastTerms(lastTerms);
  };

  @action clear = () => {
    this._lastTerms = [];
    this._termsAgree = [];
    this._termsPrivacyResult = {
      termsId: "",
    };
    this._termsServiceResult = {
      termsId: "",
    };
  };
}

export default new TermsStore();
