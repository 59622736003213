import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { ReactComponent as NaverIcon } from "../../assets/icons/btn_sns_login_Naver.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/btn_sns_login_google.svg";
import { ReactComponent as AppleIcon } from "../../assets/icons/icon_login_apple.svg";
import { useTranslation } from "react-i18next";
import { SignUpCode } from "../../constants/SignUp.enum";
import { observer } from "mobx-react";
import SocialMemberStore from "../../store/SocialMemberStore";
import { useTracker, withTrack } from "../../hooks/tracker";
import "./JoinOverlapComponent.scss";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import { REDIRECT_PATH } from "../../constants/Storage";
import { useLocation } from "react-router-dom";

interface JoinOverlapComponentProps {
  children: ReactNode;
}

const JoinOverlapComponent: React.FC<JoinOverlapComponentProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    // signInWithApple,
    signInWithGoogle,
    signInWithNaver,
    signInWithKakao,
  } = SocialMemberStore;

  const [sentLog, setSentLog] = useState<boolean>(false);

  const { track } = useTracker();

  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 1);

    return setShow(false);
  }, []);

  const clickSignUpBtn = (sort: string) => {
    switch (sort) {
      case SignUpCode.NAVER:
        void signInWithNaver();
        break;
      case SignUpCode.GOOGLE:
        void signInWithGoogle();
        break;
      case SignUpCode.KAKAO:
        void signInWithKakao();
        break;
      // case SignUpCode.APPLE:
      //   void signInWithApple();
      //   break;
      case SignUpCode.EMAIL:
        // navigate("JoinWithEmailScreen");
        goTo("/JoinWithEmailScreen");
        break;
    }

    track("click_signup", {
      method: sort,
      component: "JoinModal",
    });
  };

  const clickEmailSignInBtn = () => {
    // navigation.goBack();
    // navigate("LoginScreen");
    goTo("/LoginPresenter");
  };

  // React.useEffect(() => {
  //   setTabBarDisplay("none");
  //   return () => {
  //     setTabBarDisplay(undefined);
  //   };
  // }, [setTabBarDisplay]);

  React.useEffect(() => {
    if (!sentLog) {
      track("view_signup_page", {
        is_modal: true,
        component: "JoinModal",
      });
      setSentLog(true);
    }
    localStorage.setItem(
      REDIRECT_PATH,
      JSON.stringify({ path: location.pathname, showMainJoinModal: false })
    );
  }, [location.pathname, track]);

  const renderJoinComponent = useCallback(() => {
    return (
      <div id="JoinComponent" className="join_guidance_wrap">
        <div className="list_wrap">
          <div className="join_text_wrap">
            <div className="join_text">
              {t("screen.joinOverlapComponent.label.join")}
            </div>
          </div>

          <div className="warning_text_wrap">
            <div className="warning_text">
              {t("screen.joinOverlapComponent.label.introduce1")}
            </div>
            <div className="warning_text">
              {t("screen.joinOverlapComponent.label.introduce2")}
            </div>
          </div>

          {/* <div className="join_popover">
          <div className="join_popover_inner">
            <div className="triangle-wrapper"><div className="triangle" /></div>
            <div className="join_popover_text1">
              {t("screen.joinOverlapComponent.label.join_popover_text1")}
              <div className="join_popover_text2">
                {t("screen.joinOverlapComponent.label.join_popover_text2")}
              </div>
            </div>
          </div>
        </div> */}

          <div className="kakao_btn_wrap">
            <div
              className="kakao"
              onClick={() => clickSignUpBtn(SignUpCode.KAKAO)}
            >
              <div className="text_btn">
                {t("screen.joinOverlapComponent.label.kakao")}
              </div>
            </div>
            {/* {Platform.OS === PlatformTypes.IOS_APP ||
            (Platform.OS === PlatformTypes.IOS_WEB && ( */}
            {/* <div
            className="apple"
            onClick={() => clickSignUpBtn(SignUpCode.APPLE)}
          >
            <AppleIcon />
            <div className="text_btn_white">
              {t("screen.joinOverlapComponent.label.apple")}
            </div>
          </div> */}
            {/* ))} */}
          </div>

          <div className="middle_bar">
            {/* <div className="middle_line" /> */}
            <div className="middle_text">
              {t("screen.joinOverlapComponent.label.middleBar")}
            </div>
            {/* <div className="middle_line" /> */}
          </div>

          <div className="sns">
            <div
              className="sns_img"
              onClick={() => clickSignUpBtn(SignUpCode.NAVER)}
            >
              <NaverIcon />
            </div>
            <div
              className="sns_img"
              onClick={() => clickSignUpBtn(SignUpCode.GOOGLE)}
            >
              <GoogleIcon />
            </div>
          </div>

          <div className="bottom_area">
            <div
              className="bottom_btn  email_btn"
              onClick={() => clickSignUpBtn(SignUpCode.EMAIL)}
            >
              <div className="bottom_text">
                {t("screen.joinOverlapComponent.label.bottom1")}
              </div>
            </div>
            {/* <div className="bottom_area_line" /> */}
            <div className="bottom_btn" onClick={() => clickEmailSignInBtn()}>
              <div className="bottom_text">
                {t("screen.joinOverlapComponent.label.bottom2")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [clickSignUpBtn]);

  return (
    <div
      className="join-overlap-wrapper"
      style={{ position: "relative", width: "100%", pointerEvents: "auto" }}
    >
      <div
        className="join-overlap-content-area"
        style={{
          position: "relative",
          width: "100%",
        }}
      >
        {children}
      </div>
      {show && (
        <div className="overlapped-join-area">{renderJoinComponent()}</div>
      )}
    </div>
  );
};

export default JoinOverlapComponent;
