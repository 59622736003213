import { observer } from 'mobx-react'
import CustomBottomSheet from './CustomBottomSheet'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as BtnSnsLoginKakao } from '../../assets/icons/btn_sns_login_kakao.svg'
import { ReactComponent as BtnShareCopyLink } from '../../assets/icons/icon_share_copylink.svg'
import CommonStore from '../../store/CommonStore'
import { onShareByKakao } from '../../utils/share'
import { useTranslation } from 'react-i18next'
import style from './ShareBottomSheet.module.scss'

export const ShareBottomSheet = observer(
  ({ show, onClose }: { show: boolean; onClose: () => void }) => {
    const { t } = useTranslation()

    const closeBottomSheet = () => {
      onClose && onClose()
    }

    const shareKakao = () => {
      // 카카오톡 공유
      CommonStore.currentShareInfo &&
        onShareByKakao(CommonStore.currentShareInfo)
      closeBottomSheet()
    }

    const copyLink = () => {
      // 링크복사
      if (navigator.clipboard) {
        const info = CommonStore.currentShareInfo
        const url = `${window.location.protocol}//${window.location.host}/${info?.path}`
        void navigator.clipboard
          .writeText(url)
          .then(() => {
            CommonStore.setToastOption({
              show: true,
              message1: t('common.share.copyresultsuccess'),
            })
          })
          .catch(() => {
            CommonStore.setToastOption({
              show: true,
              message1: t('common.share.copyresultfail'),
            })
          })
          .finally(() => closeBottomSheet())
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t('common.share.copyresultnotcompatable'),
        })
        closeBottomSheet()
      }
    }

    return (
      <div id="ShareBottomSheet" className={style.share_bottom_sheet}>
        <CustomBottomSheet
          open={show}
          onClose={closeBottomSheet}
          snapHeight={204}
        >
          <div className={style.share_bottom_sheet_content}>
            <div className={style.share_bottom_sheet_header}>
              <div className={style.share_bottom_sheet_title}>
                {t('common.share.title')}
              </div>
              <button
                className={style.share_bottom_sheet_close_button}
                onClick={closeBottomSheet}
              >
                <CloseIcon />
              </button>
            </div>
            <div className={style.share_bottom_sheet_body}>
              <button
                className={style.share_bottom_sheet_button}
                onClick={shareKakao}
              >
                <BtnSnsLoginKakao style={{ width: 42, height: 42 }} />
                <div className={style.text}>{t('common.share.kakaotalk')}</div>
              </button>
              <button
                className={style.share_bottom_sheet_button}
                onClick={copyLink}
              >
                <BtnShareCopyLink style={{ width: 42, height: 42 }} />
                <div className={style.text}>{t('common.share.copylink')}</div>
              </button>
            </div>
          </div>
        </CustomBottomSheet>
      </div>
    )
  }
)
