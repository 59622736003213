import React, { forwardRef, useCallback, useEffect, useState } from "react";

import "./AccountQna.scss";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { GridComponents, VirtuosoGrid } from "react-virtuoso";

import { AccountFeedItem } from "../../../api/account/model";
import { AccountFeedTypeCode } from "../../../constants/Account";
import EmptyFeed from "./EmptyFeed";

import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import MyHomeStore from "../../../store/MyHomeStore";
import { FEED_CALL_STATE } from "../../../constants/Feed";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import AuthStore from "../../../store/AuthStore";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import clsx from "clsx";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { useWindowScroll } from "react-use";
import { List } from "react-virtualized";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";

const AccountQna = observer(
  ({
    isMy,
    userId,
    dataRefresh,
    endReached,
  }: {
    isMy: boolean;
    userId: string;
    dataRefresh?: () => void;
    endReached: () => void;
  }) => {
    const { t } = useTranslation();
    const { getAccountStore } = MyHomeStore;
    const { sessionUser } = AuthStore;
    const accountStore = getAccountStore(userId);
    // const { y } = useWindowScroll();
    const [coloredQnaList, setColoredQnaList] = useState<Object[]>([]);

    const getRandomNumber = () => {
      return Math.floor(Math.random() * 2);
    };

    useEffect(() => {
      let temp = accountStore?.qnaList.map((qna, index) => ({
        ...qna,
        ...PURPLE_STYLE_LIST[getRandomNumber()],
      }));
      setColoredQnaList(temp);
    }, [accountStore?.qnaList]);

    const PURPLE_STYLE_LIST = [
      { backgroundColor: "#6c2773" },
      { backgroundColor: "#a760b1" },
    ];
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const handleQnaPress = (feedId: number, index: number) => {
      goTo(`/Qna/${feedId}`, {
        state: {
          feedId: feedId,
          callState: FEED_CALL_STATE.MY_HOME,
        },
      });

      if (userId !== sessionUser?.memberUuid) {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.QNA_READ,
          actHistoryTargetId: feedId.toString(),
          actPageCode: ActPageCode.OTHER_USER_HOME,
          actSectionCode: ActSectionCode.QNA,
          attr1: userId,
        };
        void sendActHistory(actHistory);
      }
    };

    // useEffect(() => {
    //   const bottom =
    //     window.scrollY !== 0 &&
    //     Math.ceil(window.innerHeight + window.scrollY) >=
    //       document.documentElement.scrollHeight;

    //   if (bottom && accountStore?.qnaCanLoadMore) {
    //     accountStore?.getAccountFeed(
    //       accountStore?.qnaPageIndex + 1,
    //       AccountFeedTypeCode.QNA
    //     );
    //     accountStore?.setQnaPageIndex(accountStore?.qnaPageIndex + 1);
    //   }
    // }, [y]);

    const handleMoreRequest = useCallback(() => {
      if (accountStore?.qnaCanLoadMore) {
        accountStore?.getAccountFeed(
          accountStore?.qnaPageIndex + 1,
          AccountFeedTypeCode.QNA
        );
        accountStore?.setQnaPageIndex(accountStore?.qnaPageIndex + 1);
      }
    }, [accountStore.getAccountFeed, accountStore.setQnaPageIndex]);

    const renderContent = (item: AccountFeedItem, index: number) => {
      return (
        <div
          className="account_qna_wrap"
          style={{
            backgroundColor: item.thumbnailFilePath && "rgba(0, 0, 0, 0.6)",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          aria-label={`Account Qna List Item ${index} Content`}
        >
          <div
            className="qna_top"
            aria-label={`Account Qna List Item ${index} Content Title`}
          >
            <p className="title_text">{item.feedTitle}</p>
          </div>
          <div
            className="qna_bottom"
            aria-label={`Account Qna List Item ${index} Content CommentCount`}
          >
            <p className="qna_bottom_text">
              {t(`screen.qna.label.comment`)}{" "}
              <p className="qna_bottom_text_number">
                {item.commentCount ? item.commentCount : 0}
              </p>
            </p>
          </div>
        </div>
      );
    };

    const renderQna = (
      index: number,
      item: AccountFeedItem & { backgroundColor: string }
    ) => {
      return (
        <div
          style={{ position: "relative" }}
          onClick={() => handleQnaPress(item.feedId, index)}
          aria-label={`Account Qna List Item ${index}`}
          className={clsx("qna_item_wrap", {
            qna_item_wrap_thumbnail: !!item.thumbnailFilePath,
          })}
        >
          {item.thumbnailFilePath ? (
            <>
              <FastImageWithFallback
                className="qna_image"
                style={{ display: "flex" }}
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
                }}
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                  item.originalFilePath || ""
                }`}
                renderFallbackNode={({ children }) => {
                  return (
                    <div
                      className="qna_image"
                      style={{
                        backgroundColor: item.backgroundColor,
                        borderRadius: 8,
                      }}
                    >
                      {children}
                    </div>
                  );
                }}
              />
              {renderContent(item, index)}
            </>
          ) : (
            <div
              className="qna_image_overlay"
              style={{
                backgroundColor: item.backgroundColor,
              }}
            >
              {renderContent(item, index)}
            </div>
          )}
        </div>
      );
    };

    const gridComponents: GridComponents = {
      List: forwardRef(
        (
          { children, ...props },
          ref: React.LegacyRef<HTMLDivElement> | undefined
        ) => (
          <div
            ref={ref}
            {...props}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {children}
          </div>
        )
      ),
      Item: ({ children, ...props }) => (
        <div
          {...props}
          style={{ ...props.style, width: "calc(50% - 4px)" }}
          className="qna_item"
        >
          {children}
        </div>
      ),
      Footer: () => (
        <div style={{ height: 60, backgroundColor: "transparent" }} />
      ),
    };

    return (
      <>
        {accountStore?.qnaList.length > 0 ? (
          <>
            <div className="qna_list_wrapper" style={{ padding: "0 16px" }}>
              {coloredQnaList.map((qna: any, colIndex: number) =>
                renderQna(colIndex, qna)
              )}
              {/* <VirtuosoGrid
                className=""
                data={accountStore?.qnaList.map((qna) => ({
                  ...qna,
                  ...PURPLE_STYLE_LIST[getRandomNumber()],
                }))}
                itemContent={renderQna}
                useWindowScroll
                endReached={() => {
                  if (accountStore?.qnaCanLoadMore) {
                    accountStore?.getAccountFeed(
                      accountStore?.qnaPageIndex + 1,
                      AccountFeedTypeCode.QNA
                    );
                    accountStore?.setQnaPageIndex(accountStore?.qnaPageIndex + 1);
                  }
                }}
                components={gridComponents}
              /> */}
            </div>
            <InfiniteScrollObserver onObserver={handleMoreRequest} />
          </>
        ) : (
          <EmptyFeed
            isMy={isMy}
            type={AccountFeedTypeCode.QNA}
            dataRefresh={() => {
              dataRefresh && dataRefresh();
            }}
          />
        )}
      </>
    );
  }
);

export default AccountQna;
