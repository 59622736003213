// import { t } from "i18next";
// import { BackHandler, NativeModules, Platform } from "react-native";
// import { Item } from "react-native-paper/lib/typescript/components/List/List";
// import { AlbumCode } from "../constants/Native";
// import CommonStore from "../store/CommonStore";
// import { compressVideoFile } from "./file";

// const { RNCommon } = NativeModules;

export const callNativeAlbum = (
  maxImageCount: number,
  maxVideoCount: number,
  isAndType: boolean,
  contentType: string,
  compressVideo = true,
  w = 3,
  h = 3
) => {
  return new Promise((resolve, reject) => {
    reject("not implemented");
  });
  //   if (maxVideoCount == 0) {
  //     return callNativeOnlyPhotoAlbum(maxImageCount, contentType, w, h);
  //   } else {
  //     return new Promise((resolve, reject) => {
  //       RNCommon.showAlbum3(maxImageCount, maxVideoCount, isAndType, contentType, w, h, async (data: any) => {
  //         CommonStore.setLoading(true);
  //         let remake = data as AlbumMulitResponse;
  //         if (remake.code != AlbumCode.SUCCESS) {
  //           CommonStore.setLoading(false);
  //           reject(remake.msg);
  //           return;
  //         }
  //         if (compressVideo) {
  //           const videos = remake.param.list
  //             .filter((item) => item.type.toUpperCase() === "VIDEO")
  //             .map((item) => {
  //               return { ...item, compressed: false };
  //             });
  //           if (videos.length > 0) {
  //             let abortController: AbortController | null = new AbortController();
  //             const backHandler = BackHandler.addEventListener("hardwareBackPress", () => {
  //               abortController?.abort();
  //               return true;
  //             });
  //             try {
  //               let index = 0;
  //               for (const video of videos) {
  //                 CommonStore.setToastOption({
  //                   type: "touchableToast",
  //                   show: true,
  //                   autoHide: false,
  //                   message1: t("common.message.compress.ing", {
  //                     progress: 0,
  //                     index: index + 1,
  //                     totalCount: videos.length,
  //                   }),
  //                   message2: t("common.message.compress.cancel"),
  //                   onPress: () => {
  //                     abortController?.abort();
  //                   },
  //                 });
  //                 const compressedFileInfo = await compressVideoFile(
  //                   video.fileUri,
  //                   abortController.signal,
  //                   (progress: number) => {
  //                     abortController !== null &&
  //                       CommonStore.setToastOption({
  //                         type: "touchableToast",
  //                         show: true,
  //                         autoHide: false,
  //                         message1: t("common.message.compress.ing", {
  //                           progress: parseFloat((progress * 100).toFixed(1)),
  //                           index: index + 1,
  //                           totalCount: videos.length,
  //                         }),
  //                         message2: t("common.message.compress.cancel"),
  //                         onPress: () => {
  //                           abortController?.abort();
  //                         },
  //                       });
  //                   }
  //                 );
  //                 if (compressedFileInfo) {
  //                   const indexOfVideo = remake.param.list.findIndex((file) => file.fileUri === video.fileUri);
  //                   remake.param.list[indexOfVideo].fileUri = compressedFileInfo.filePath;
  //                   remake.param.list[indexOfVideo].fileSize = compressedFileInfo.fileSize;
  //                   remake.param.list[indexOfVideo].fileName = compressedFileInfo.fileName;
  //                   video.fileUri = compressedFileInfo.filePath;
  //                   video.compressed = true;
  //                 }
  //                 CommonStore.setToastOption({
  //                   type: "touchableToast",
  //                   show: true,
  //                   autoHide: false,
  //                   message1: t("common.message.compress.ing", {
  //                     progress: 100,
  //                     index: index + 1,
  //                     totalCount: videos.length,
  //                   }),
  //                   message2: t("common.message.compress.cancel"),
  //                   onPress: () => {
  //                     abortController?.abort();
  //                   },
  //                 });
  //                 index++;
  //               }
  //               CommonStore.setToastOption({
  //                 show: true,
  //                 autoHide: true,
  //                 message1: t("common.message.compress.done"),
  //               });
  //             } catch (error) {
  //               // when compression aborted
  //               CommonStore.setToastOption({
  //                 show: true,
  //                 autoHide: true,
  //                 message1: t("common.message.compress.aborted"),
  //                 durationTime: 2000,
  //               });
  //               CommonStore.setLoading(false);
  //               remake = {
  //                 code: remake.code,
  //                 msg: "video compression aborted",
  //                 param: {
  //                   list: remake.param.list.filter(
  //                     (item) =>
  //                       item.type.toUpperCase() === "IMAGE" ||
  //                       (item.type.toUpperCase() === "VIDEO" &&
  //                         videos.some((video) => video.compressed === true && video.fileUri === item.fileUri))
  //                   ),
  //                 },
  //               };
  //               resolve(remake);
  //               return;
  //             } finally {
  //               abortController = null;
  //               backHandler.remove();
  //             }
  //           }
  //         }
  //         resolve(remake);
  //       });
  //     });
  //   }
};

export const callNativeSelectOnePicture = (contentType: string) => {
  return;
  // return new Promise((resolve, reject) => {
  //     RNCommon.showOnlyPhotoAlbum2(contentType, (data: any) => {
  //       const remake = data as AlbumResponse;
  //       if (remake.code != AlbumCode.SUCCESS) {
  //         reject(remake.msg);
  //         return;
  //       }
  //       resolve(remake);
  //     });
  //   });
};

// export const callNativeSelectOnePictureRatio = (contentType: string, w = 3, h = 3) => {
//   return new Promise((resolve, reject) => {

//       RNCommon.showOnlyPhotoAlbumRatio(contentType, w, h, (data: any) => {
//         const remake = data as AlbumResponse;
//         if (remake.code != AlbumCode.SUCCESS) {
//           reject(remake.msg);
//           return;
//         }
//         resolve(remake);
//       });
//     });

// };

export const callNativeOnlyPhotoAlbum = (
  maxCount: number,
  contentType: string,
  w = 3,
  h = 3
) => {
  return new Promise((resolve, reject) => {
    reject("not implemented");
    //     RNCommon.showAlbumOnlyPhoto3(maxCount, contentType, w, h, (data: any) => {
    //       CommonStore.setLoading(true);
    //       const remake = data as AlbumMulitResponse;
    //       if (remake.code != AlbumCode.SUCCESS) {
    //         CommonStore.setLoading(false);
    //         reject(remake.msg);
    //         return;
    //       }
    //       resolve(remake);
    //     });
  });
};

export interface AlbumMulitResponse {
  code: string;
  msg: string;
  param: ImageListParmaForUI;
}

export interface AlbumResponse {
  code: string;
  msg: string;
  param: ImageParmaForUI;
}

export interface ImageListParmaForUI {
  list: ImageForUI[];
}

export interface ImageParmaForUI {
  image: ImageForUI;
}

export interface ImageForUI {
  base64: string;
  fileName: string;
  fileSize: string;
  fileUri: string;
  thumbnailImagepath: string;
  type: string;
  resThumbX: string;
  resThumbY: string;
  resOriginX: string;
  resOriginY: string;
}
