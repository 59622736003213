import { observer } from 'mobx-react'
import { CSSProperties, useEffect, useMemo, useState } from 'react'
import { CustomActivityIndicator, TitleHeader } from '../../components/common'
import ArrowRightBlack from '../../assets/icons/arrow_right_black.svg'
import { ReactComponent as ImgNoti } from '../../assets/images/img_noti.svg'
import TermsAgreeBottomSheet from '../../components/modals/TermsAgreeBottomSheet'
import { TermsResult } from '../../api/terms/model'
import {
  eCommerceTermsAgree,
  eCommerceTermsAgreed,
  eCommerceTermsDisagree,
  getEcommerceNeedAgreeTerms,
  getEcommerceTerms,
} from '../../api/mall/api'
import AuthStore from '../../store/AuthStore'
import { withdrawalIsPossible } from '../../api/member/api'
import { goToLifecareOrderListPage } from '../../utils/mall'
import CommonStore from '../../store/CommonStore'
import MainStore from '../../store/MainStore'
import { track } from '../../hooks/tracker/TrackFunction'
import ContentsNotificationModal from '../../components/modals/ContentsNotificationModal'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { Virtuoso } from 'react-virtuoso'
import classes from './ServiceTermsScreen.module.scss'
import TermsOfUseModal, {
  TermsDetailProps,
} from '../../components/modals/TermsOfUseModal'
import { NotificationModal } from '../../components/modals'
import BasicModal from '../../components/modals/BasicModal'

type TermsMenuItem = {
  accessibilityLabel: string
  screenName?: string
  screenParam?: any
  label: string
  desc?: string
  onPress?: () => void
  textStyle?: CSSProperties
  touchDisabled?: boolean
}

const ServiceTermsScreen = observer(() => {
  const [isShowTermsAgreeModal, setShowTermsAgreeModal] = useState(false)
  const [terms, setTerms] = useState<TermsResult[]>()
  const [isTermsAgree, setTermsAgree] = useState(false)
  const [agreedTerms, setAgreedTerms] = useState<number[]>([])
  const [isLoading, setLoading] = useState(false)
  const [isShowWithdrawalImpossibleModal, setShowWithdrawalImpossibleModal] =
    useState(false)
  const [isShowEcommerceNotSignupModal, setShowEcommerceNotSignupModal] =
    useState(false)
  const [isShowDisagreeConfirmModal, setShowDisagreeConfirmModal] =
    useState(false)
  const [menuList, setMenuList] = useState<TermsMenuItem[]>([])
  const navigate = useNavigate()
  const defaultTermsDetailProps = useMemo(() => {
    return {
      show: false,
      termsId: '',
      termsTypeCode: '',
      onClose: () => {},
    }
  }, [])
  const [termsDetailProps, setTermsDetailProps] = useState<TermsDetailProps>(
    defaultTermsDetailProps
  )

  const onCloseTermsOfUseModal = useMemo(() => {
    return () => setTermsDetailProps(defaultTermsDetailProps)
  }, [defaultTermsDetailProps])

  useEffect(() => {
    void getEcommerceTerms().then((result) => {
      if (result.successOrNot === 'Y' && (result.data?.length || 0) > 0) {
        const data = result.data?.flatMap((term) => {
          return {
            accessibilityLabel: term.termsTitle,
            label: term.termsTitle,
            screenParam: term,
            onPress: () => {
              setTermsDetailProps({
                show: true,
                onClose: onCloseTermsOfUseModal,
                ...term,
              })
            },
          } as TermsMenuItem
        })

        data && setMenuList(data)
      }
    })

    void eCommerceTermsAgreed().then((result) => {
      if (result.successOrNot === 'Y') {
        setAgreedTerms(result.data as number[])
      }
      setTermsAgree((result?.data?.length || 0) > 0)
    })
  }, [onCloseTermsOfUseModal])

  const onPressAgree = () => {
    setLoading(true)
    void getEcommerceNeedAgreeTerms()
      .then((result) => {
        setTerms(result.data)
        setShowTermsAgreeModal(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onPressDisagree = () => {
    const memberUuid = AuthStore.sessionUser?.memberUuid || ''
    setLoading(true)
    void withdrawalIsPossible(memberUuid)
      .then((result) => {
        if (result.data !== true) {
          if (result.statusCode === 'INVALID_PROCESS_ERR') {
            return Promise.reject({ code: 'BUSINESS_ERROR' })
          } else if (result.statusCode === 'USER_NOT_FOUND') {
            // 해당 케이스 성공으로 처리
          } else {
            return Promise.reject({ code: 'SERVER_ERROR' })
          }
        }

        const disagreeTerms = agreedTerms?.flatMap((termsId) => {
          return { termsId, termsAgreeYn: 'N' }
        })
        return eCommerceTermsDisagree(memberUuid, disagreeTerms || [])
      })
      .then((result) => {
        if (result.successOrNot === 'Y') {
          setTermsAgree(false)

          CommonStore.setToastOption({
            show: true,
            message1: '220mall 약관동의가 철회되었습니다.',
          })

          // 믹스패널 사용자 속성정보 업데이트
          void MainStore.trackAllUserProperties()

          const sessionUser = AuthStore.sessionUser
          AuthStore.setSessionUser({
            ...sessionUser,
            isLongTermUnused: sessionUser?.isLongTermUnused || false,
            ecommerceUpdateYn: 'N',
            ecommerceUpdateDateTime: undefined,
          })
          // void CookieManager.clearAll(true)
        } else {
          return Promise.reject({ code: 'SERVER_ERROR' })
        }
      })
      .catch((reason) => {
        if (reason.code === 'BUSINESS_ERROR') {
          setShowWithdrawalImpossibleModal(true)
        } else {
          setShowEcommerceNotSignupModal(true)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div
      className={classes.setting}
      // edges={['top']}
      aria-label="Setting"
    >
      <TitleHeader title={'220mall'} isBack={true} />
      <Virtuoso
        style={{ display: 'flex', flexDirection: 'column' }}
        useWindowScroll
        data={menuList}
        computeItemKey={(index, item) => `${item.label}${index}`}
        itemContent={(index, item) => (
          <div
            key={item.screenParam.termsId}
            className={clsx(classes.option_box, classes.option_box_border)}
            aria-label={item.accessibilityLabel}
          >
            <div
              className={classes.option}
              onClick={(event) => {
                item.onPress
                  ? item.onPress()
                  : navigate(item.screenName || '', item.screenParam)
              }}
              aria-disabled={!!item.touchDisabled}
            >
              <text style={item.textStyle} className={classes.option_txt}>
                {item.label}
              </text>
              {item.desc && (
                <text className={classes.version_info}>{item.desc}</text>
              )}
              {item.screenName && <ArrowRightBlack />}
            </div>
          </div>
        )}
        components={{
          Group: () => <div className={classes.line} />,
          Footer: () => {
            return isTermsAgree ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                  marginTop: 20,
                  marginRight: 20,
                }}
                onClick={() => {
                  setShowDisagreeConfirmModal(true)
                }}
              >
                <span
                  style={{
                    color: '#999999',
                    textDecorationLine: 'underline',
                    fontSize: 14,
                    alignSelf: 'flex-end',
                  }}
                >
                  {'동의 철회하기'}
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  marginTop: 40,
                  marginLeft: 16,
                  marginRight: 16,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    borderRadius: 4,
                    flex: 1,
                    backgroundColor: '#be69c2',
                    height: 56,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={onPressAgree}
                >
                  <text
                    style={{
                      textAlign: 'center',
                      color: '#ffffff',
                      fontWeight: 'bold',
                      fontSize: 16,
                      letterSpacing: -0.64,
                    }}
                  >
                    {'220mall 서비스 이용하기 >'}
                  </text>
                </div>
              </div>
            )
          },
        }}
      />

      <TermsAgreeBottomSheet
        open={isShowTermsAgreeModal}
        contents={'220mall 이용을 위해 동의해 주세요.'}
        contents2={'220앱 사용과 별개로 추가 동의가 필요합니다.'}
        terms={terms}
        onTermsClick={(term) => {
          setShowTermsAgreeModal(false)
          const termsDetailProps = {
            show: true,
            ...term,
            onClose: () => {
              setShowTermsAgreeModal(true)
            },
          }
          setTermsDetailProps(termsDetailProps)
        }}
        onClose={() => {
          setShowTermsAgreeModal(false)
        }}
        onConfirm={(termsCheckState) => {
          const memberUuid = AuthStore.sessionUser?.memberUuid || ''
          setLoading(true)
          void eCommerceTermsAgree(memberUuid, termsCheckState || [])
            .then((result) => {
              if (result.successOrNot === 'Y') {
                setShowTermsAgreeModal(false)
                setTermsAgree(true)

                track('click_mall_allow_agreement')

                CommonStore.setToastOption({
                  show: true,
                  message1: '220mall 약관동의가 완료되었습니다.',
                })
              } else {
                CommonStore.setToastOption({
                  show: true,
                  message1: '서버 통신 중 에러가 발생했습니다.',
                })
              }
            })
            .finally(() => {
              setLoading(false)
            })
        }}
      />

      <NotificationModal
        isVisible={isShowDisagreeConfirmModal}
        modalWidth={360}
        title="안내"
        useTwoButton
        defaultButtonText="돌아가기"
        onClickDefaultButton={() => {
          setShowDisagreeConfirmModal(false)
        }}
        extraButtonText="철회하기"
        onClickExtraButton={() => {
          setShowDisagreeConfirmModal(false)
          onPressDisagree()
        }}
        onRequestClose={() => {
          setShowDisagreeConfirmModal(false)
        }}
      >
        <div className={classes.centered_div}>
          <div className={classes.modal_div}>
            <div className={classes.modal_content_wrap}>
              <ImgNoti />
              <text className={classes.txt_content_title}>
                {'"220mall" 서비스 동의를\n철회하시겠습니까?'}
              </text>
              <text className={classes.txt_content}>
                아래 유의 사항을 꼭 확인해 주세요!
              </text>
              <div
                style={{
                  width: '100%',
                  marginTop: 12,
                  borderRadius: 4,
                  backgroundColor: '#fafafa',
                  paddingLeft: 14,
                  paddingRight: 14,
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div>
                  <div className={classes.bx_cont_li}>
                    <div
                      style={{
                        width: 3,
                        height: 3,
                        borderRadius: 3,
                        marginRight: 6,
                        backgroundColor: '#666',
                        marginTop: 12,
                      }}
                    />
                    <text
                      style={{
                        fontSize: 14,
                        color: '#666',
                        letterSpacing: -0.56,
                      }}
                    >
                      {
                        '동의 철회일 기준 220몰에서 90일 이내에 제품을 구매 했거나, 현재 진행중인 주문이 있는 경우(구매 확정이 완료되지 않은 경우 포함) 철회가 불가능합니다.'
                      }
                    </text>
                  </div>
                  <div className={classes.bx_cont_li}>
                    <div
                      style={{
                        width: 3,
                        height: 3,
                        borderRadius: 3,
                        marginRight: 6,
                        backgroundColor: '#666',
                        marginTop: 12,
                      }}
                    />
                    <text
                      style={{
                        fontSize: 14,
                        color: '#666',
                        letterSpacing: -0.56,
                      }}
                    >
                      &apos;220mall&apos; 서비스 동의 철회 후 계정 복구 또는
                      재가입 시, 철회일 기준 7일간 220몰 기능은 사용할 수
                      없습니다. (포스트 등록/조회, 미션 참여 등 220의 다른
                      기능은 정상적으로 사용 가능)
                    </text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NotificationModal>

      <BasicModal
        modalWidth={360}
        isVisible={isShowWithdrawalImpossibleModal}
        onRequestClose={() => {
          setShowWithdrawalImpossibleModal(false)
        }}
      >
        <div className={classes.centered_div}>
          <div className={classes.modal_div}>
            <div className={classes.modal_content_wrap}>
              <div className={classes.modal_header_wrap}>
                <text className={classes.txt_header_title}>안내</text>
              </div>
              <ImgNoti />
              <text className={classes.txt_content_title}>
                지금은 탈퇴를{'\n'}신청할 수 없는 상태에요.
              </text>
              <text className={classes.txt_content}>
                아래와 같은 경우에는{'\n'}고객 테이터 관리를 위하여 탈퇴를 할 수
                없습니다.
              </text>
              <div className={classes.bx_noti_info}>
                <div>
                  <div className={classes.bx_cont_li}>
                    <div className={classes.txt_dot} />
                    <text
                      className={classes.txt_noti_st1}
                      style={{ fontWeight: '700' }}
                    >
                      220몰에서 현재 진행중인 주문이 있는 경우{'\n'}
                      <text
                        className={classes.txt_noti_st1}
                        style={{ fontWeight: 'normal' }}
                      >
                        (구매확정이 완료되지 않은 경우 포함)
                      </text>
                    </text>
                  </div>
                  <div className={classes.bx_cont_li}>
                    <div className={classes.txt_dot} />
                    <text
                      style={{ fontWeight: '700' }}
                      className={classes.txt_noti_st1}
                    >
                      90일 이내에 220몰에서 제품을 구매 했을 경우
                    </text>
                  </div>
                </div>
              </div>
              <text className={classes.txt_content}>
                위 내용을 확인하신 후에{'\n'}다시 신청하여 주세요.
              </text>
            </div>
            <div className={classes.modal_footer_wrap}>
              <div
                style={{ width: '60%' }}
                className={classes.btn_footer_default}
                onClick={goToLifecareOrderListPage}
              >
                <text className={classes.txt_btn_footer}>
                  내 주문ㆍ배송 보러가기
                </text>
              </div>
              <div
                style={{ width: '40%' }}
                className={clsx(classes.btn_footer_default, classes.btn_bg_bk)}
                onClick={() => setShowWithdrawalImpossibleModal(false)}
              >
                <text
                  className={clsx(
                    classes.txt_btn_footer,
                    classes.txt_btn_white
                  )}
                >
                  돌아가기
                </text>
              </div>
            </div>
          </div>
        </div>
      </BasicModal>

      <ContentsNotificationModal
        isVisible={isShowEcommerceNotSignupModal}
        onRequestClose={() => {
          setShowEcommerceNotSignupModal(false)
        }}
        title="안내"
        buttonLabel="돌아가기"
      >
        <div className={classes.centered_div}>
          <div className={classes.modal_div}>
            <div className={classes.modal_content_wrap}>
              <ImgNoti />
              <text className={classes.txt_content_title}>
                잠시 후 다시 시도해주세요.
              </text>
              <text className={classes.txt_content}>
                진행 중 문제가 발생했습니다.{'\n'}잠시 후에 다시 시도하시거나
                {'\n'}1:1 문의를 통해 문의해주세요.
              </text>
            </div>
          </div>
        </div>
      </ContentsNotificationModal>

      <TermsOfUseModal termsDetailProps={termsDetailProps} />

      {isLoading && <CustomActivityIndicator isDarkSpinner={true} />}
    </div>
  )
})

export default ServiceTermsScreen
