import AuthStore from "../../store/AuthStore";
import { callAPIWithPUTMethod } from "../common/api";
import { FrontErrorLogRequest } from "./model";

export const callFrontErrorLog = async (
  frontErrorLogRequest: FrontErrorLogRequest,
  isLoading = true
): Promise<boolean> => {
  const memberUuid = AuthStore.sessionUser?.memberUuid || AuthStore.tempMember?.tempMemberUUID || "";

  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/frontErrorLog",
      body: { ...frontErrorLogRequest, memberUuid },
    },
    isLoading
  );

  return response?.successOrNot === "Y" && response?.statusCode === "OK" ? true : false;
};
