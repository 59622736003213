import { t } from "i18next";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { checkBadWord } from "../api/badWord/api";
import { FileUrlRequest, UploadFile } from "../api/file/model";
import { findNickname } from "../api/member/api";
import { getRecommendTag } from "../api/post/api";
import {
  FeedFileForUploadS3,
  FeedFileForUploadS3Type,
  RecommendTagResponse,
} from "../api/post/model";
import { Qna, QnaWriteRequest, QnaWriteResponse } from "../api/qna/model";
import { searchTag } from "../api/tag/api";
import { TagSearchRequest, TagSearchResponse } from "../api/tag/model";
import {
  BLANK_TEMP_FILE,
  FEED_TAG_SEARCH_COUNT,
  FeedMode,
  FeedTypeCode,
  POST_BLANK_FILE,
  QNA_BLANK,
  UploadTypeCode,
} from "../constants/Feed";

import Resizer from "react-image-file-resizer";

import {
  AlbumMulitResponse,
  callNativeAlbum,
  ImageForUI,
} from "../utils/album";
import CommonStore from "./CommonStore";
import { Buffer } from "buffer";
import { SuccessOrNot } from "../constants/Common.enum";
import { getFilePreSignedUrl } from "../api/file/api";
import { getQnaDetail, getRecommendUser, saveQna } from "../api/qna/api";
import { MemberItem } from "../api/member/model";
import { BucketTypeCode, FileType } from "../constants/File";
import { ProductForWrite } from "./ProductSearchAddStore";
import { NewProduct } from "../api/product/model";
import { track } from "../hooks/tracker/TrackFunction";
import UploadStore from "./upload/UploadStore";
import { UploadStatus } from "../constants/upload/UploadStatus";
import QnaStore from "./QnaStore";
import AuthStore from "./AuthStore";
import { bottomOffset, sleep } from "../utils/common";
import { ErrorCode } from "../constants/Error";
import { CustomError } from "../utils/customError";
import { showBottomToast } from "../utils/Toast";
import { goTo } from "../hooks/navigate/NavigateFunction";

export interface QnaModifyModel<T> {
  id: number;
  contents: T;
  modified?: boolean;
}

class QnaWriteStore {
  constructor() {
    makeObservable(this);
  }

  @observable errorMessage?: string;
  @observable fileList: QnaModifyModel<UploadFile>[] = [
    { id: 0, contents: POST_BLANK_FILE },
  ];
  @observable tagList: QnaModifyModel<string>[] = [];
  @observable tagSearchResult?: TagSearchResponse | null;
  @observable isVisibleTagList = false;
  @observable tagUpdated = false;
  @observable recommendTags: RecommendTagResponse[] = [];
  @observable successRegister = false;

  @observable userList: QnaModifyModel<MemberItem>[] = [];
  @observable userSearchResult?: MemberItem[] | null;
  @observable isVisibleUserList = false;
  @observable userUpdated = false;
  @observable recommendUsers: MemberItem[] = [];
  @observable wholeData: QnaWriteRequest = QNA_BLANK;
  @observable toastMessage = "";
  @observable tempProductList: ProductForWrite[] = [];
  @observable productUpdated = false;
  @observable voltAmount = 0;

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    );
  }

  @computed get isValid() {
    let checkingValid = false;
    checkingValid =
      this.tagList?.length > 0 &&
      this.wholeData?.title?.trim()?.length > 0 &&
      this.wholeData?.content?.trim()?.length > 0;

    // checkingValid = checkingValid && !this.videoCompressing;

    return checkingValid;
  }

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action clear = () => {
    this.errorMessage = "";
    this.toastMessage = "";
    this.fileList = [{ id: 0, contents: POST_BLANK_FILE }];
    this.tagList = [];
    this.tagSearchResult = null;
    this.isVisibleTagList = false;
    this.tagUpdated = false;
    this.tagSearchResult = null;
    this.successRegister = false;
    this.recommendTags = [];
    this.userList = [];
    this.userSearchResult = null;
    this.isVisibleUserList = false;
    this.userUpdated = false;
    this.recommendUsers = [];
    this.wholeData = QNA_BLANK;
    this.lastUploadIndex = 0;
    this.callIndex = 0;
    this.tempProductList = [];
    this.uploadFileClear();
  };

  @action showValidInfo = () => {
    if (this.wholeData?.title?.trim()?.length < 1) {
      showBottomToast(t("screen.post.message.inputTitle"), 40);
      return;
    }
    if (this.wholeData?.content?.trim()?.length < 1) {
      showBottomToast(t("screen.post.message.inputContent"), 40);
      return;
    }
    if (this.tagList?.length < 1) {
      showBottomToast(t("screen.post.message.inputTag"), 40);
    }
  };

  @action setWholeData = (data: Qna) => {
    // clear
    this.clear();

    this.wholeData.title = data.feedTitle;
    this.wholeData.content = data.contents ?? "";
    this.wholeData.feedContentId = data.feedContentId;
    this.wholeData.feedFileGroupId = data.feedFileGroupId;
    this.wholeData.feedId = data.feedId;

    data.tagList?.forEach((item, index) => {
      this.tagList.push({ id: index, contents: item.tagName, modified: false });
    });

    this.fileList = [];
    data.files?.forEach((item, index) => {
      const uploadFile = {
        id: index,
        contents: item,
        modified: false,
      } as QnaModifyModel<UploadFile>;
      uploadFile.contents.modified = false;

      this.fileList.push({ id: index, contents: item, modified: false });
    });

    if (data.files !== undefined && data.files.length < 10) {
      this.fileList.push({
        id: data.files?.length,
        contents: POST_BLANK_FILE,
        modified: false,
      });
    }

    data.reviewRequest?.forEach((item, index) => {
      this.userList.push({ id: index, contents: item, modified: false });
    });

    data.productList?.forEach((item) => {
      this.tempProductList.push({
        isRepresent: item.thumbnailYn === "Y",
        product: {
          productId: item.productId,
          tempProductId: item.tempProductId,
          thumbnailFilePath: item.productFiles[0]?.thumbnailFilePath,
          originalFilePath:
            item.productFiles[0]?.thumbnailFilePath?.replace(
              "THUMBNAIL",
              "ORIGINAL"
            ) || "",
          manufacturerName: item.manufacturerNameKor,
          productModelName: item.productModelName,
          productName: item.productName,
          openApiFlag: item.openApiFlag,
          nproductId: item.nproductId,
        },
      });
    });

    if (this.tempProductList.length > 0) {
      this.productUpdated = !this.productUpdated;
      const tempRemakeProductIds: number[] = [];
      this.tempProductList?.forEach(
        (item) =>
          item?.product?.productId &&
          tempRemakeProductIds.push(item.product.productId)
      );
      void this.getRecommendTag(tempRemakeProductIds);
    } else {
      void this.getRecommendTag();
    }
  };

  @action setTitle = (title: string) => {
    this.wholeData.title = title;
  };

  @action setContent = (content: string) => {
    this.wholeData.content = content;
  };

  @action setToast = (message: string) => {
    this.toastMessage = message;
  };

  @action addTag = (tag: string) => {
    if (tag.trim()?.length < 1) return;
    if (this.tagList?.length > 9) {
      showBottomToast(t("screen.post.message.maxTag"), bottomOffset());
      return;
    }
    const duplication = this.tagList?.findIndex(
      (item) => item.contents === tag
    );
    if (duplication < 0)
      this.tagList?.push({
        id: this.tagList.length,
        contents: tag,
        modified: true,
      });
    this.tagUpdated = !this.tagUpdated;
    this.setVisibleTagList(false);
  };

  @action deleteTag = (tag: string) => {
    const targetIndex = this.tagList?.findIndex(
      (item) => item.contents === tag
    );
    this.tagList?.splice(targetIndex, 1);
  };

  @action setVisibleTagList = (isVisible: boolean) => {
    this.isVisibleTagList = isVisible;
  };

  @action setVisibleUserList = (isVisible: boolean) => {
    this.isVisibleUserList = isVisible;
  };

  @action checkValidWord = async (word: string, successAction?: () => void) => {
    if (word.trim().length < 1) return;
    const res = await checkBadWord(word);
    if (res != null && res !== undefined) {
      if (res?.hasBadWord === "N") {
        if (successAction !== undefined) successAction();
      } else {
        showBottomToast(t("screen.post.message.validError"), bottomOffset());
      }
    } else {
      showBottomToast(t("common.error.server"), bottomOffset());
    }
  };

  @action getRecommendTag = async (productIds?: number[]) => {
    const res = await getRecommendTag(productIds);
    runInAction(() => {
      if (res != null) {
        this.recommendTags = res;
      } else {
        showBottomToast(t("common.error.server"), bottomOffset());
      }
    });
  };

  @action searchTag = async (word: string, startNumber: number) => {
    if (startNumber === 0) {
      this.tagSearchResult = null;
    }
    const requestItem: TagSearchRequest = {
      query: word,
      synonymYn: "Y",
      pageSize: FEED_TAG_SEARCH_COUNT,
      pageIndex: startNumber,
    };

    const res = await searchTag(requestItem);
    this.setTagSearchResult(res, startNumber);
  };

  @action setTagSearchResult = (
    response: TagSearchResponse | null,
    startNumber: number
  ) => {
    if (startNumber === 0) {
      this.tagSearchResult = response;
      this.isVisibleTagList = true;
    } else response?.list && this.tagSearchResult?.list.push(...response?.list);
    this.tagUpdated = !this.tagUpdated;
  };

  @action showNativeAlbum = async () => {
    CommonStore.setLoading(true);
    let imageFileLength = 0;
    let videoFileLength = 0;
    this.fileList.forEach((item) => {
      if (
        item.contents.fileType === FileType.IMAGE &&
        item.contents.fileSize >= 0
      )
        imageFileLength = imageFileLength + 1;
      if (
        item.contents.fileType === FileType.VIDEO &&
        item.contents.fileSize >= 0
      )
        videoFileLength = videoFileLength + 1;
    });

    const itemtype = this.fileList;
    let w = 0;
    let h = 0;
    if (this.fileList.length > 0) {
      //  const arrf = f.contents.fileName.split("___");
      const f = this.fileList[0];
      if (
        f != null &&
        f.contents != null &&
        f.contents.fileResolution != null
      ) {
        const strR = f.contents.fileResolution.split("x");
        if (strR.length === 2) {
          const firstW = Number(strR[0]);
          const firstH = Number(strR[1]);
          w = firstW;
          h = firstH;
        }
      }
    }

    //this.videoCompressing = true;
    const res = (await callNativeAlbum(
      0,
      10 - videoFileLength - imageFileLength,
      false,
      "QNA",
      false,
      w,
      h
    )) as AlbumMulitResponse;
    //this.videoCompressing = false;
    runInAction(() => {
      const files: ImageForUI[] = res.param.list;
      if (files.length > 0) {
        const blankIndex = this.fileList.findIndex(
          (i) => i.contents.fileSize < 0
        );
        if (blankIndex > -1) this.fileList.splice(blankIndex, 1);

        files.forEach((item) => {
          if (this.fileList.length < 10) {
            const sp = item.fileName.split(".");
            this.fileList.push({
              id: this.fileList.length,
              contents: {
                originalFileName: item.fileName,
                fileName: sp[0],
                filePath: item.base64,
                imageLocalFilePath:
                  item.type.toLocaleUpperCase() === "IMAGE" ? item.fileUri : "",
                videoLocalFilePath:
                  item.type.toLocaleUpperCase() === "VIDEO" ? item.fileUri : "",
                thumbnailTempPath: item.thumbnailImagepath,
                fileResolution: item.resThumbX + "x" + item.resThumbY,
                fileSize: Number(item.fileSize),
                fileType: item.type.toLocaleUpperCase(),
                fileExtension: sp[sp.length - 1],
                thumbnailYn: "N",
                modified: true,
              },
              modified: true,
            });
          }
        });

        this.checkAddedFiles();
      }
    });

    CommonStore.setLoading(false);
  };

  @observable fileUpdated = false;
  @action checkAddedFiles = () => {
    const fileLength = this.fileList.length;
    if (fileLength <= 10)
      this.fileList.unshift({
        id: fileLength,
        contents: POST_BLANK_FILE,
        modified: false,
      }); // 사진 추가하는 화면

    this.fileUpdated = !this.fileUpdated;
  };

  @action deleteFile = (fileIndex: number) => {
      this.fileList?.splice(fileIndex, 1);
    if (
      this.fileList?.length <= 10 &&
      this.fileList?.findIndex((item) => item.contents.filePath === "") < 0
    )
      this.fileList.unshift({
        id: this.fileList.length,
        contents: POST_BLANK_FILE,
        modified: false,
      });

    this.fileUpdated = !this.fileUpdated;
  };

  @action successInit = () => {
    this.successRegister = false;
  };

  @action getRecommendUser = async () => {
    const productIds: number[] = [];
    this.tempProductList.forEach((item) => {
      if (item.product.productId || item.product.tempProductId)
        productIds.push(
          item.product.productId || item.product.tempProductId || -1
        );
    });

    const res = await getRecommendUser(productIds);
    runInAction(() => {
      if (res != null) {
        this.recommendUsers = res.answererList;
      } else {
        showBottomToast(t("common.error.server"), bottomOffset());
      }
    });
  };

  @action searchUsers = async (nickname: string) => {
    const response = await findNickname(nickname);
    if (response) {
      runInAction(() => {
        this.setUserSearchResult(response);
        this.setVisibleUserList(response.length > 0);
      });
    }
  };

  @action searchUsersAndAdd = async (nickname: string) => {
    const response = await findNickname(nickname);
    if (response) {
      runInAction(() => {
        this.setVisibleUserList(response.length > 0);
        const pickedUser = response?.find((item) => item.nickname === nickname);
        if (pickedUser !== undefined && pickedUser !== null) {
          this.addUser(pickedUser);
          this.setUserSearchResult(null);
        } else {
          showBottomToast(
            t("screen.qnaWrite.message.noSearchUser"),
            bottomOffset()
          );
          this.setUserSearchResult(null);
        }
      });
    }
  };

  @action setUserSearchResult = (response: MemberItem[] | null) => {
    this.userSearchResult = response;
    this.userUpdated = !this.userUpdated;
  };

  @action addUser = (userInfo: MemberItem) => {
    if (this.userList.length === 5) {
      showBottomToast(t("screen.qnaWrite.message.maxAnswerer"), bottomOffset());
      return;
    }
    const duplication = this.userList.findIndex(
      (item) => item.contents.memberUuid === userInfo.memberUuid
    );
    if (duplication < 0)
      this.userList.push({
        id: this.userList.length,
        contents: userInfo,
        modified: true,
      });
    this.userUpdated = !this.userUpdated;
    this.setVisibleUserList(false);
  };

  @action deleteUser = (index: number) => {
    this.userList.splice(index, 1);
    this.userUpdated = !this.userUpdated;
  };

  @action addProduct = (item: NewProduct) => {
    const duplication = this.tempProductList.findIndex(
      (info) =>
        item.productId ===
        (info.product.productId || info.product.tempProductId)
    );
    if (duplication < 0) {
      this.tempProductList.push({ product: item, isRepresent: false });
      this.setAutoRepresent();
    } else {
      showBottomToast(
        t("screen.feedProduct.message.alreadyAdd"),
        bottomOffset()
      );
    }
    const tempProductIds: number[] = [];
    this.tempProductList?.forEach(
      (item) =>
        item?.product?.productId &&
        !item?.product?.openApiFlag &&
        tempProductIds.push(item.product.productId)
    );
    void this.getRecommendTag(tempProductIds);
  };

  @action setAutoRepresent = () => {
    if (this.tempProductList && this.tempProductList.length > 0) {
      const representIndex = this.tempProductList.findIndex(
        (item) => item.isRepresent && item.product.productId
      );
      if (representIndex < 0) {
        const willBeRepresentIndex = this.tempProductList.findIndex(
          (item) => item.product.productId
        );
        if (willBeRepresentIndex > -1)
          this.tempProductList[willBeRepresentIndex].isRepresent = true;
      }
    }
    this.productUpdated = !this.productUpdated;
    this.dataUnion();
  };

  @action deleteProduct = (index: number) => {
    this.tempProductList.splice(index, 1);
    this.setAutoRepresent();

    const tempProductIds: number[] = [];
    this.tempProductList?.forEach(
      (item) =>
        item?.product?.productId &&
        !item?.product?.openApiFlag &&
        tempProductIds.push(item.product.productId)
    );
    void this.getRecommendTag(tempProductIds);
  };

  @action setRepresentProduct = (index: number) => {
    this.tempProductList.forEach((item) => (item.isRepresent = false));
    this.tempProductList[index].isRepresent = true;
    this.productUpdated = !this.productUpdated;
    this.dataUnion();
  };

  @action dataUnion = () => {
    this.wholeData.tagNames = [];
    this.tagList.forEach((item) => {
      this.wholeData.tagNames.push(item.contents);
    });

    this.wholeData.files = [];
    this.fileList.forEach((item) => {
      if (item.contents.fileSize > -1 && item.contents.filePath.length > 0)
        this.wholeData.files.push(item.contents);
    });

    this.wholeData.reviewRequestMemberUuids = [];
    this.userList.forEach((item) => {
      this.wholeData.reviewRequestMemberUuids.push(item.contents.memberUuid);
    });

    this.wholeData.products = [];
    this.tempProductList.forEach((item) => {
      item.product.openApiFlag && item.product.nproductId === undefined
        ? item.product.myProductId && item.product.myProductId != null
          ? this.wholeData.products?.push({
              id: item.product.myProductId,
              thumbnailYn: item.isRepresent ? "Y" : "N",
              tempProductYn:
                item.product?.openApiFlag || item.product?.tempProductId
                  ? "Y"
                  : "N",
              product_from: item.product.from ?? "",
            })
          : this.wholeData.products?.push({
              id: -1,
              openApiFlag: true,
              productId: item.product?.productId,
              productName: item.product?.productName,
              brandNameKor: item.product?.brandName,
              productModelName: item.product?.productModelName,
              lprice: item.product?.lprice,
              hprice: item.product?.hprice,
              thumbnailFilePath: item.product?.thumbnailFilePath,
              category1: item.product?.category1,
              category2: item.product?.category2,
              category3: item.product?.category3,
              productUrl: item.product?.productUrl,
            })
        : this.wholeData.products?.push({
            id: item.product?.productId || item.product?.tempProductId || -1,
            thumbnailYn: item.isRepresent ? "Y" : "N",
            tempProductYn:
              item.product?.openApiFlag || item.product?.tempProductId
                ? "Y"
                : "N",
            product_from: item.product.from ?? "",
          });
    });
  };

  @action saveQna = async (mode: FeedMode) => {
    const res = await saveQna(this.wholeDataForSaveCall);

    this.wholeDataForSaveCall = QNA_BLANK;
    CommonStore.setLoading(false);

    if (res.successOrNot === SuccessOrNot.Y && res.statusCode === "OK") {
      runInAction(() => {
        const data: QnaWriteResponse = res.data as QnaWriteResponse;
        if (data?.feedId !== undefined && data?.feedId > 0) {
          this.clear();
          if (mode === FeedMode.WRITE) {
            track("complete_upload_qna", {});
            showBottomToast(
              t("screen.qnaWrite.message.registerd"),
              bottomOffset()
            );
            goTo("/QnA");
          } else {
            showBottomToast(
              t("screen.qnaWrite.message.modified"),
              bottomOffset()
            );
            this.successRegister = true;
          }
        }
      });
    } else {
      runInAction(() => {
        if (res.statusCode === ErrorCode.HAS_BAD_WORD.code) {
          this.setError(t(ErrorCode.HAS_BAD_WORD.message));
        } else if (res.statusCode === ErrorCode.HAS_BLOCKED_TAG.code) {
          if (res?.blockedTagNames !== undefined) {
            const tagString = res?.blockedTagNames
              ?.replace("[", "")
              .replace("]", "");
            this.setError(
              t(ErrorCode.HAS_BLOCKED_TAG.message, { tag: tagString })
            );
          }
        } else if (res.statusCode === ErrorCode.PARAM_LENGTH_ERR.code) {
          if (res.lengthErrorParameter) {
            this.setError(t(ErrorCode.PARAM_LENGTH_ERR.message));
          }
        } else {
          this.setError(t("common.error.server"));
        }
      });
    }
  };

  @observable lastUploadIndex = 0;
  @observable callIndex = 0;
  @observable tempUploadFile: FeedFileForUploadS3 = BLANK_TEMP_FILE;
  @observable wholeDataForSaveCall: QnaWriteRequest = QNA_BLANK;

  @action getUploadUrl = (mode: FeedMode) => {
    CommonStore.setLoading(true);
    this.dataUnion();
    this.lastUploadIndex = 0;
    this.callIndex = 0;
    this.tempUploadFile = BLANK_TEMP_FILE;
    this.wholeDataForSaveCall = JSON.parse(JSON.stringify(this.wholeData));

    this.fileList.forEach((file, fileIndex) => {
      if (
        file.contents.filePath.length > 0 &&
        file.contents.fileSize >= 0 &&
        file.modified === true
      ) {
        const isVideo = file.contents.fileType.toUpperCase() === FileType.VIDEO;
        //image upload
        const imageFileName =
          file.contents.fileName +
          (isVideo ? "_thumb.jpg" : "." + file.contents.fileExtension);
        const urlRequest: FileUrlRequest = {
          bucketTypeCode: BucketTypeCode.PUBLIC,
          uploadTypeCode: UploadTypeCode.QNA,
          fileNamesWithExtension: [imageFileName],
          generateThumbnailYN: "Y",
        };

        this.callIndex++;
        void this.callSignedUrl(
          urlRequest,
          fileIndex,
          isVideo ? "VIDEO_THUMBNAIL" : "IMAGE"
        );

        if (isVideo !== undefined && isVideo === true) {
          // video upload
          const videoFileName =
            file.contents.fileName + "." + file.contents.fileExtension;
          const videoUrlRequest: FileUrlRequest = {
            bucketTypeCode: BucketTypeCode.PUBLIC,
            uploadTypeCode: UploadTypeCode.QNA,
            fileNamesWithExtension: [videoFileName],
            generateThumbnailYN: "Y",
          };

          this.callIndex++;
          void this.callSignedUrl(videoUrlRequest, fileIndex, "VIDEO");
        }
      }
    });
    if (this.callIndex === 0) {
      // todo be 수정에 따라 제거 될수도 있음
      if (
        mode === FeedMode.MODIFY &&
        this.fileList.findIndex((item) => item.contents.fileSize > -1) < 0
      ) {
        delete this.wholeDataForSaveCall.feedFileGroupId;
      }
      void this.saveQna(mode);
    }
  };

  @action checkLastCall = (file: FeedFileForUploadS3, mode: FeedMode) => {
    if (file.type === "IMAGE") {
      this.wholeDataForSaveCall.files[file.fileIndex].filePath = file.filePath;
      this.wholeDataForSaveCall.files[file.fileIndex].thumbnailFilePath =
        file.thumbPath;
    } else if (file.type === "VIDEO") {
      this.wholeDataForSaveCall.files[file.fileIndex].filePath = file.filePath;
    } else if (file.type === "VIDEO_THUMBNAIL") {
      this.wholeDataForSaveCall.files[file.fileIndex].thumbnailFilePath =
        file.thumbPath;
    }

    this.lastUploadIndex++;
    if (this.lastUploadIndex === this.callIndex) void this.saveQna(mode);
  };

  @action callSignedUrl = async (
    urlRequest: FileUrlRequest,
    fileIndex: number,
    type: FeedFileForUploadS3Type
  ) => {
    const res = await getFilePreSignedUrl(urlRequest);
    runInAction(() => {
      if (res != null) {
        if (res.length > 0) {
          this.tempUploadFile = {
            contentIndex: 0,
            fileIndex: fileIndex,
            signedPath: res[0].presignedUrl,
            filePath: res[0].filePath,
            thumbPath: res[0].fileThumbnailPath,
            type,
          };
        }
      } else {
        this.uploadFileClear();
        CommonStore.setLoading(false);
        this.setError(t("common.error.server"));
      }
    });
  };

  @action uploadFile = async (
    file: UploadFile,
    preSignedUrl: string,
    isVideo: boolean
  ) => {
    // TODO: FS 구현하면 주석해제 해야함
    // const filePath = isVideo
    //   ? await fs.readFile(file.thumbnailFilePath || '', 'base64')
    //   : file.filePath
    const filePath = file.filePath;
    const spOriginalFileName = file.originalFileName.split(".");
    const videoSide =
      spOriginalFileName[spOriginalFileName.length - 1]
        .toLowerCase()
        .indexOf("mp4") > -1 ||
      spOriginalFileName[spOriginalFileName.length - 1]
        .toLowerCase()
        .indexOf("mov") > -1;
    const buffer = Buffer.from(filePath, "base64");

    let contentType = `${file.fileType.toLocaleLowerCase()}/${
      file.fileExtension
    }`;
    if (videoSide && !isVideo)
      contentType = `${FileType.IMAGE.toLocaleLowerCase()}/jpg`;

    return fetch(preSignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": `${contentType}; charset=utf-8`,
      },
      body: buffer,
    });
  };

  @action uploadFileClear = () => {
    this.tempUploadFile = BLANK_TEMP_FILE;
  };

  @action getDetailInfo = async (feedId: number) => {
    const res = await getQnaDetail(feedId);
    runInAction(() => {
      if (res != null && typeof res !== "string") {
        this.setWholeData(res);
      } else {
        showBottomToast(t("common.error.server"), bottomOffset());
      }
    });
  };

  // 신규 버전
  @action asyncCreateQna = (mode: FeedMode) => {
    const backGroundPostingTimer = setTimeout(() => {
      UploadStore.start(
        FeedTypeCode.QNA,
        this.wholeData.title,
        mode === FeedMode.MODIFY
      );
      UploadStore.setAbortController(new AbortController());

      void this.showStartToast()
        .then(() => sleep(200))
        .then(() => this.compressFiles(mode))
        .then(() => UploadStore.clearAbortController())
        .then(() => this.uploadFilesToS3(mode))
        .then(this.createQna)
        .then(() => {
          if (UploadStore.isFail) throw CustomError(UploadStatus.FAIL);
        })
        // TODO: Native 에서는 필요함
        // .then(() => BadgeStore.obtainBadge(BadgeCode.FEED))
        .then(() => UploadStore.complete())
        .then(this.initList)
        .then(() => this.showCompleteToast(mode))
        .then(this.clear)
        .catch((e) => {
          if (e.name === UploadStatus.STOP || e.name === "abortVideo") {
            UploadStore.clear();
          } else {
            UploadStore.fail();
          }
        })
        .finally(() => {
          clearTimeout(backGroundPostingTimer);
        });
    }, 500);
  };

  @action compressFiles = async (mode: FeedMode) => {
    this.dataUnion();

    const resultQueue = await UploadStore.compressByQueue(
      this.wholeData.files,
      mode
    );
    //유효성 체크
    UploadStore.validateQueue(resultQueue);
    //최종 데이터 병합
    this.mergeData(resultQueue);
  };

  @action uploadFilesToS3 = async (mode: FeedMode) => {
    if (UploadStore.isStop) throw CustomError(UploadStatus.STOP);

    UploadStore.uploading();
    const resultQueue = await UploadStore.putFileToS3ByQueue(
      this.wholeData.files,
      UploadTypeCode.QNA,
      mode
    );
    //유효성 체크
    UploadStore.validateQueue(resultQueue);
    //최종 데이터 병합
    this.mergeData(resultQueue);
    //서버 전송 데이터 포맷으로 변경
    this.wholeDataForSaveCall = JSON.parse(JSON.stringify(this.wholeData));
  };

  @action createQna = async () => {
    if (UploadStore.isStop) {
      throw CustomError(UploadStatus.STOP);
    }

    await saveQna(this.wholeDataForSaveCall)
      .then((res) => {
        if (res.successOrNot === SuccessOrNot.Y && res.statusCode === "OK") {
          const data: QnaWriteResponse = res.data as QnaWriteResponse;
          if (data?.feedId !== undefined && data?.feedId > 0) {
            if (data.voltMessage === "Success") {
              this.voltAmount = data.voltAmount || 0;
            } else {
              this.voltAmount = 0;
            }
            this.clear();
            this.wholeDataForSaveCall = QNA_BLANK;
          } else {
            throw CustomError(UploadStatus.FAIL);
          }
        } else {
          throw CustomError(UploadStatus.FAIL);
        }
      })
      .catch((e) => {
        if (e.name === UploadStatus.FAIL) UploadStore.fail();
      });
  };

  @action dataSetting = async (inputFiles: FileList): Promise<ImageForUI[]> => {
    const files: ImageForUI[] = [];
    let count = 0;
    return await new Promise((resolve, reject) => {
      [].forEach.call(inputFiles, (file: File, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file!);
        reader.onload = function (event: ProgressEvent<FileReader>) {
          const base64Encod = event.target?.result as string;
          if (file.type.split("/")[0].toLocaleUpperCase() === FileType.IMAGE) {
            const img = new Image();
            img.src = base64Encod;

            img.onload = function async() {
              const { width, height } = img;
              const temp = {
                base64: base64Encod,
                fileName: file.name,
                fileSize: file.size.toString(),
                fileUri: base64Encod,
                thumbnailImagepath: "",
                type: file.type.split("/")[0],
                resThumbX: "",
                resThumbY: "",
                resOriginX: width.toString(),
                resOriginY: height.toString(),
              };

              files.push(temp);
              count++;
              count === inputFiles?.length && resolve(files);
            };
          } else {
            const videoElement = document.createElement("video");
            videoElement.src = URL.createObjectURL(file);
            videoElement.onloadeddata = () => {
              videoElement.currentTime = 0;
            };

            videoElement.onseeked = async () => {
              const canvasElement = document.createElement("canvas");
              canvasElement.width = videoElement.videoWidth;
              canvasElement.height = videoElement.videoHeight;
              const context = canvasElement.getContext("2d");
              context?.drawImage(
                videoElement,
                0,
                0,
                canvasElement.width,
                canvasElement.height
              );

              const thumbnailDataUrl = canvasElement.toDataURL();
              const temp = {
                base64: base64Encod,
                fileName: file.name,
                fileSize: file.size.toString(),
                fileUri: base64Encod,
                thumbnailImagepath: thumbnailDataUrl,
                type: file.type.split("/")[0],
                resThumbX: videoElement.videoWidth.toString(),
                resThumbY: videoElement.videoHeight.toString(),
                resOriginX: videoElement.videoWidth.toString(),
                resOriginY: videoElement.videoHeight.toString(),
              };
              files.push(temp);
              count++;
              count === inputFiles?.length && resolve(files);
            };
          }
        };
      });
    });
  };

  @action contentsTempDataSet = (files: ImageForUI[]) => {
    if (files.length > 0) {
      runInAction(() => {
        const blankIndex = this.fileList.findIndex(
          (i) => i.contents.fileSize < 0
        );
        if (blankIndex > -1) this.fileList.splice(blankIndex, 1);

        files.forEach((item, index) => {
          if (this.fileList.length <= 10) {
            const sp = item.fileName.split(".");
            this.fileList.push({
              id: this.fileList.length,
              contents: {
                originalFileName: item.fileName,
                fileName: sp[0],
                filePath: item.base64,
                imageLocalFilePath:
                  item.type.toLocaleUpperCase() === "IMAGE" ? item.fileUri : "",
                videoLocalFilePath:
                  item.type.toLocaleUpperCase() === "VIDEO" ? item.fileUri : "",
                thumbnailTempPath: item.thumbnailImagepath,
                imageOriginLocalFilePath:
                  item.type.toLocaleUpperCase() === "IMAGE" ? item.fileUri : "",
                fileResolution: item.resThumbX + "x" + item.resThumbY,
                fileSize: Number(item.fileSize),
                fileType: item.type.toLocaleUpperCase(),
                fileExtension: sp[sp.length - 1],
                thumbnailYn: "N",
              },
            });
          }
        });
      });

      this.checkAddedFiles();
    }
  };

  resizeThumbImg = async (files: ImageForUI[]): Promise<ImageForUI[]> => {
    try {
      return await Promise.all(
        files.map(async (file) => {
          if (file.type.toLocaleUpperCase() === FileType.IMAGE)
            return new Promise<ImageForUI>((resolve, reject) => {
              Resizer.imageFileResizer(
                UploadStore.b64toBlob(file.base64),
                720,
                720,
                "JPEG",
                100,
                0,
                (uri) => {
                  file.thumbnailImagepath = uri as string;
                  const img = new Image();
                  img.src = uri as string;
                  img.onload = () => {
                    const { width, height } = img;
                    file.resThumbX = width.toString();
                    file.resThumbY = height.toString();
                    resolve(file);
                  };
                },
                "base64",
                300,
                300
              );
            });
          else return file;
        })
      );
    } catch (error) {
      throw new Error("resizeThumbImg");
    }
  };

  showStartToast = () => {
    return new Promise((resolve, reject) => {
      if (!UploadStore.isDefaultStatus) {
        showBottomToast(t("screen.qnaWrite.message.starting"), bottomOffset());
        resolve("success");
      } else {
        reject(CustomError(UploadStatus.FAIL));
      }
    });
  };

  showCompleteToast = (mode: FeedMode) => {
    return new Promise((resolve, reject) => {
      if (UploadStore.status === UploadStatus.COMPLETE) {
        if (mode === FeedMode.WRITE) {
          if (this.voltAmount > 0) {
            showBottomToast(
              t("screen.qnaWrite.message.registerdVolt", {
                volt: this.voltAmount,
              }),
              bottomOffset()
            );
          } else {
            showBottomToast(
              t("screen.qnaWrite.message.registerd"),
              bottomOffset()
            );
          }

          track("complete_upload_ab", {});
        }
        if (mode === FeedMode.MODIFY) {
          CommonStore.setToastOption({
            show: true,
            message1: t("screen.qnaWrite.message.modified"),
          });
        }
        resolve("success");
      } else {
        reject(CustomError(UploadStatus.FAIL));
      }
    });
  };

  initList = () => {
    if (UploadStore.status === UploadStatus.COMPLETE) {
      void QnaStore.qnaMainStore.initList(
        AuthStore.sessionUser?.memberUuid ?? ""
      );
    }
  };

  mergeData = (resultQueue: UploadFile[]) => {
    //최종 데이터 병합
    this.wholeData.files.forEach((prevFile) => {
      for (const compressFile of resultQueue) {
        if (
          prevFile.fileType === FileType.IMAGE &&
          prevFile.imageLocalFilePath === compressFile.imageLocalFilePath
        ) {
          prevFile = compressFile;
        }
        if (
          prevFile.fileType === FileType.VIDEO &&
          prevFile.videoLocalFilePath === compressFile.videoLocalFilePath
        ) {
          prevFile = compressFile;
        }
      }
    });
  };
}

export default new QnaWriteStore();
