import dayjs from "dayjs";
import { AccountFeedTypeCode, FollowersTab } from "../constants/Account";
import { ActHistoryTypeCode, ActPageCode } from "../constants/ActHistory";
import { CommentTypeCode } from "../constants/Comment";
import { NotificationTypeCode } from "../constants/Notification.enum";
// import { goBack, navigate, navigationRef, replace, reset } from "../navigation/NavigationFunction";
import { ShareScreenCode } from "../constants/ShareScreen.enum";
import AuthStore from "../store/AuthStore";
import CommonStore from "../store/CommonStore";
// import { LIFECARE_PRODUCT_URL_PREFIX } from "../utils/mall";
// import { goToRandomCouponWinnerView } from "../screens/mission/presenter/randomCoupon/RandomCouponMission";
// import { AbListScreenTabType } from "../screens/ab/AbListScreen";

interface Notification {
  action: string;
  badge: string;
  data: {
    actionIdentifier: string;
    aps: { alert: any };
    "gcm.message_id": string;
    "google.c.a.e": string;
    "google.c.fid": string;
    "google.c.sender.id": string;
    link?: string;
    pushType?: string;
    userInteraction: number; // 0: not-clicked / 1:clicked
    sqsMessageType?: string;
    targetId?: string;
    targetTypeCode?: string;
    body?: string;
  };
  finish: () => void;
  foreground: boolean;
  id: string;
  message: string;
  reply_text: string;
  soundName: string;
  subtitle: string;
  title: string;
  userInteraction: boolean;
  expireDateTimeStr?: string;
}

export interface InitialNotification {
  data: { link: string; pushType: string };
  from: string;
  messageId: string;
  notification: { body: string; title: string };
}

export interface NotificationCore {
  messageId?: string;
  link?: string;
  pushType?: string;
  body?: string;
  title?: string;
  channel?: string;
  sendType?: string; // MARKETING, REALTIME
  pushMasterIdx?: string;
  memberId?: string;
  expireDateTimeStr?: string;
}

export interface NavigationProps {
  screenName?:
    | "QaDetail"
    | "HomeScreen"
    | "CommentScreen"
    | "PostDetailScreen"
    | "QnaDetailScreen"
    | "AbDetailScreen"
    | "MagazineDetailScreen"
    | "MissionDetailScreen"
    | "FollowersScreen"
    | "UserHomeScreen"
    | "BadgeScreen"
    | "VoltInfoScreen"
    | "ProductDetailScreen"
    | "MainTab"
    | "Qa"
    | "AlarmScreen"
    | "CouponListScreen"
    | "LoginAttractScreen"
    | "PostWriteScreen"
    | "PostStack"
    | "MissionStack"
    | "ArticleDetailScreen"
    | "FriendRecommendScreen"
    | "LifecareScreen"
    | "QnaStack"
    | "MagazineScreen"
    | "MallMainScreen"
    | "AbListScreen";
  param?: object;
}

type NavigationTypeMap = {
  [key: string]: () => NavigationProps;
};

export interface NotificationTarget {
  targetId?: string;
  targetTypeCode?: string;
  notificationId?: number;
  location?: string;
  memberId?: number;
  memberUuid?: string;
}

export const parseLink = (link: string) => {
  const params = link.split("&");
  const parsed: any = {};
  params.forEach((param) => {
    const [key, value] = param.split("=");
    parsed[key] = value;
  });

  return parsed as NotificationTarget;
};

export const handleNativeNotififactionPressed = (param: {
  pushType: string;
  link?: string | null;
  expireDateTimeStr?: string;
}) => {
  handleNotificationPressed({ ...param, navigationType: "NAVIGATE" });
};
export const handleNotificationPressed = ({
  pushType,
  link,
  expireDateTimeStr,
  navigationType = "REPLACE",
}: {
  pushType: string;
  link?: string | null;
  expireDateTimeStr?: string;
  navigationType?: "NAVIGATE" | "REPLACE";
}) => {
  const { targetTypeCode, targetId, location } = parseLink(link || "");

  const isNotificationExpired = expireDateTimeStr
    ? dayjs().isAfter(dayjs(expireDateTimeStr))
    : false;

  // if (!isNotificationExpired) {
  //   if (AuthStore.sessionUser?.memberUuid) {
  //     const navigationProps = getNavigationProps(pushType, targetTypeCode, targetId, location);
  //     if (navigationProps?.screenName) {
  //       if (navigationType === "NAVIGATE") {
  //         navigate(navigationProps.screenName, navigationProps.param);
  //       } else if (navigationType === "REPLACE") {
  //         replace(navigationProps.screenName, navigationProps.param);
  //       }
  //     }
  //   } else {
  //     switch (pushType) {
  //       case NotificationTypeCode.NON_MEMBER_HOME:
  //         reset("MainTab");
  //         break;
  //       case NotificationTypeCode.NON_MEMBER_SIGN_UP:
  //         navigate("JoinModal");
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }
};

export const getNavigationProps = (
  pushType: string,
  targetTypeCode?: string,
  targetId?: string,
  location?: string
) => {
  let props: NavigationProps | null = null;

  // 게시물 진입시 바로 댓글 화면으로 이동
  let isShowComment = false;
  switch (pushType) {
    case NotificationTypeCode.MEMBER_COMMENT_MY_POST: // 내 포스트에 댓글
    // case NotificationTypeCode.MEMBER_COMMENT_MY_AB: // 내 AB에 댓글
    // case NotificationTypeCode.MEMBER_COMMENT_MY_QNA: // 내 QNA에 댓글
    /* falls through */
    case NotificationTypeCode.MEMBER_COMMENT_MY_MAGAZINE: // 내 매거진에 댓글
    case NotificationTypeCode.MEMBER_MENTION_ME_POST_COMMENT: // 포스트에 언급
    case NotificationTypeCode.MEMBER_MENTION_ME_QNA_COMMENT: // QNA에 언급
    case NotificationTypeCode.MEMBER_MENTION_ME_AB_COMMENT: // AB에 언급
    case NotificationTypeCode.MEMBER_MENTION_ME_MAGAZINE_COMMENT: // 매거진에 언급
      isShowComment = true;
      break;
    default:
      break;
  }

  const typeToFunctionMap: NavigationTypeMap = {
    [ShareScreenCode.FRIEND_RECOMMEND_SCREEN]: () => ({
      screenName: ShareScreenCode.FRIEND_RECOMMEND_SCREEN,
    }),
    [ShareScreenCode.QNA_SCREEN]: () => ({ screenName: "QnaStack" }),
    [ShareScreenCode.MAGAZINE_SCREEN]: () => ({
      screenName: ShareScreenCode.MAGAZINE_SCREEN,
    }),
    [NotificationTypeCode.HOME_TAB]: () => ({ screenName: "MainTab" }),
  };

  //TODO : 추후에 타입별로 함수로 빼기(위에 typeToFunctionMap 참고), case limit 30
  switch (pushType) {
    case ShareScreenCode.LOGIN_ATTRACT_SCREEN:
      props = {
        screenName: "LoginAttractScreen",
        param: { hideLookingAroundBtn: true },
      };
      break;
    case NotificationTypeCode.MEMBER_JOIN:
      props = { screenName: "MainTab" };
      break;
    case NotificationTypeCode.MEMBER_REPORT_MY_COMMENT:
    case NotificationTypeCode.MEMBER_LIKE_MY_COMMENT:
      if (targetTypeCode === CommentTypeCode.AB)
        props = {
          screenName: "AbDetailScreen",
          param: { feedId: targetId },
        };
      else if (targetTypeCode === CommentTypeCode.MAGAZINE)
        props = {
          screenName: "MagazineDetailScreen",
          param: { magazineId: targetId },
        };
      else if (targetTypeCode === CommentTypeCode.MISSION)
        props = {
          screenName: "MissionDetailScreen",
          param: { missionId: targetId },
        };
      else if (targetTypeCode === CommentTypeCode.POST)
        props = {
          screenName: "PostDetailScreen",
          param: { feedId: targetId },
        };
      else if (targetTypeCode === CommentTypeCode.PRODUCT)
        props = {
          screenName: "ProductDetailScreen",
          param: { productId: targetId },
        };
      else if (targetTypeCode === CommentTypeCode.QNA)
        props = {
          screenName: "QnaDetailScreen",
          param: { feedId: targetId },
        };
      break;
    case NotificationTypeCode.MEMBER_LIKE_MY_POST:
    case NotificationTypeCode.MEMBER_COMMENT_MY_POST:
    case NotificationTypeCode.MEMBER_MENTION_ME_POST_COMMENT:
    case NotificationTypeCode.PARTICIPATION_POST_BEST_PICK:
    case NotificationTypeCode.FOLLOWING_MEMBER_NEW_POST:
    case ShareScreenCode.POST_DETAIL_SCREEN:
      if (targetId?.length == 36) {
        props = {
          screenName: "UserHomeScreen",
          param: {
            targetUserId: targetId,
            initialActiveTab: AccountFeedTypeCode.POST,
          },
        };
      } else {
        props = {
          screenName: "PostDetailScreen",
          param: { feedId: targetId, isShowComment },
        };
      }
      break;
    case ShareScreenCode.LIFECARE_SCREEN:
      props = {
        screenName: "LifecareScreen",
        param: {
          mode: "redirect",
          redirectInfo: {
            url: targetId || "/",
          },
        },
      };
      break;
    case NotificationTypeCode.MEMBER_LIKE_MY_QNA:
    case NotificationTypeCode.MEMBER_COMMENT_MY_QNA:
    case NotificationTypeCode.MEMBER_MENTION_ME_QNA_COMMENT:
    case NotificationTypeCode.PARTICIPATION_QNA_BEST_PICK:
    case NotificationTypeCode.MEMBER_REQUEST_REVIEW_QNA:
    case NotificationTypeCode.FOLLOWING_MEMBER_NEW_QNA:
    case ShareScreenCode.QNA_DETAIL_SCREEN:
      if (targetId?.length == 36) {
        props = {
          screenName: "UserHomeScreen",
          param: {
            targetUserId: targetId,
            initialActiveTab: AccountFeedTypeCode.QNA,
          },
        };
      } else {
        props = {
          screenName: "QnaDetailScreen",
          param: { feedId: targetId, isShowComment },
        };
      }
      break;
    case ShareScreenCode.ARTICLE_DETAIL_SCREEN:
      props = {
        screenName: "ArticleDetailScreen",
        param: { articleId: targetId },
      };
      break;
    case NotificationTypeCode.MEMBER_LIKE_MY_AB:
    case NotificationTypeCode.MEMBER_COMMENT_MY_AB:
    case NotificationTypeCode.MEMBER_MENTION_ME_AB_COMMENT:
    case NotificationTypeCode.PARTICIPATION_AB_BEST_PICK:
    case NotificationTypeCode.MEMBER_REQUEST_REVIEW_AB:
    case NotificationTypeCode.MISSION_END_MY_AB:
    case NotificationTypeCode.FOLLOWING_MEMBER_NEW_AB:
    case ShareScreenCode.AB_DETAIL_SCREEN:
      if (targetId?.length == 36) {
        props = {
          screenName: "UserHomeScreen",
          param: {
            targetUserId: targetId,
            initialActiveTab: AccountFeedTypeCode.AB,
          },
        };
      } else {
        props = {
          screenName: "AbDetailScreen",
          param: { feedId: targetId, isShowComment },
        };
      }
      break;
    case NotificationTypeCode.MEMBER_LIKE_MY_MAGAZINE:
    case NotificationTypeCode.MEMBER_COMMENT_MY_MAGAZINE:
    case NotificationTypeCode.MEMBER_MENTION_ME_MAGAZINE_COMMENT:
    case NotificationTypeCode.FOLLOWING_MEMBER_NEW_MAGAZINE:
    case ShareScreenCode.MAGAZINE_DETAIL_SCREEN:
      if (targetId?.length == 36) {
        props = {
          screenName: "UserHomeScreen",
          param: {
            targetUserId: targetId,
            initialActiveTab: AccountFeedTypeCode.MAGAZINE,
          },
        };
      } else {
        props = {
          screenName: "MagazineDetailScreen",
          param: { feedId: targetId, isShowComment },
        };
      }
      break;
    case NotificationTypeCode.MEMBER_MENTION_ME_MISSION_COMMENT:
      break;
    case ShareScreenCode.MISSION_DETAIL_SCREEN:
    case NotificationTypeCode.MISSION_RESULT:
      props = {
        screenName: "MissionDetailScreen",
        param: { missionId: targetId },
      };
      break;
    case NotificationTypeCode.MEMBER_FOLLOW_ME:
      props = {
        screenName: "FollowersScreen",
        param: {
          targetUserId: AuthStore.sessionUser?.memberUuid,
          targetUserNickname: AuthStore.sessionUser?.nickname,
          type: FollowersTab.FOLLOWERS,
        },
      };
      break;
    case ShareScreenCode.PRODUCT_DETAIL_SCREEN:
      props = {
        screenName: "ProductDetailScreen",
        param: {
          productId: targetId,
        },
      };
      break;
    case NotificationTypeCode.MEMBER_OBTAIN_BADGE:
      props = {
        screenName: "BadgeScreen",
      };
      break;
    case NotificationTypeCode.VOLT_SCHEDULED_EXPIRY:
      props = {
        screenName: "VoltInfoScreen",
      };
      break;
    case NotificationTypeCode.MEMBER_LEVEL_UPGRADE:
      props = {
        screenName: "UserHomeScreen",
        param: {
          targetUserId: AuthStore.sessionUser?.memberUuid,
        },
      };
      break;
    case ShareScreenCode.QA_SCREEN:
      props = {
        screenName: "Qa",
        param: {}, //화면내에서 사용 필수
      };
      break;
    case ShareScreenCode.ALARM_SCREEN:
      props = {
        screenName: "AlarmScreen",
      };
      break;
    case NotificationTypeCode.MISSION_COUPON_EXPIRATION:
      props = {
        screenName: "CouponListScreen",
      };
      break;
    case NotificationTypeCode.MEMBER_REPORT_MY_POST:
      break;
    case NotificationTypeCode.PRODUCT_REJECT:
    case NotificationTypeCode.QA_ANSWER_COMPLETED:
      props = {
        screenName: "QaDetail",
        param: { qaId: targetId },
      };
      break;
    case ShareScreenCode.POST_WRITE:
      props = {
        screenName: "PostWriteScreen",
        param: {},
      };
      break;
    //navigate("PostStack",{ screen : "PostScreen" , params: { tab: 'POPULAR' });
    case NotificationTypeCode.POST_TAB:
      props = {
        screenName: "MainTab",
        param: {
          screen: "PostStack",
          params: { tab: targetTypeCode, location: location },
        },
      };
      break;
    case NotificationTypeCode.MALL_TAB:
      props = {
        screenName: "MainTab",
        param: { screen: "MallMainScreen" },
      };
      break;
    case NotificationTypeCode.PRODUCT_COMPLETED:
    case NotificationTypeCode.PRODUCT_EXIST:
      props = {
        screenName: "ProductDetailScreen",
        param: { productId: targetId },
      };
      break;
    case NotificationTypeCode.MISSION_LIST:
      props = {
        screenName: "MainTab",
        param: { screen: "MissionStack", params: { location: location } },
      };
      break;
    // case "RANDOMCOUPON_MISSION":
    //   goToRandomCouponWinnerView({ missionId: targetId, missionTypeCode: "COMMON" });
    //   props = null;
    //   break;
    // case NotificationTypeCode.MISSION_END_PARTICIPANT_AB:
    //   props = {
    //     screenName: "AbListScreen",
    //     param: { tapType: AbListScreenTabType.myAbListTab },
    //   };
    //   break;
    default:
      props = typeToFunctionMap[pushType]
        ? typeToFunctionMap[pushType]()
        : null;
      break;
  }
  return props;
};

export const callNotificationActHistory = (
  pushType: string,
  link?: string | null,
  expireDateTimeStr?: string,
  requestorUuid?: string
) => {
  const { targetTypeCode, targetId } = parseLink(link || "");
  const isNotificationExpired = expireDateTimeStr
    ? dayjs().isAfter(dayjs(expireDateTimeStr))
    : false;
  if (!isNotificationExpired) {
    const navigationProps = getNavigationProps(
      pushType,
      targetTypeCode,
      targetId
    );
    if (navigationProps?.screenName === "PostDetailScreen") {
      CommonStore.callActHistory(
        ActHistoryTypeCode.POST_READ,
        targetId || "",
        ActPageCode.NOTIFICATION,
        undefined,
        requestorUuid || ""
      );
    }
  }
};
