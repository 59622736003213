import { Styles } from "../../../assets/types/Style";
import {
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
  Buttons,
  Arranges,
} from "../../../assets/styles";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as NaverIcon } from "../../../assets/icons/btn_sns_login_Naver.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/btn_sns_login_google.svg";
import { ReactComponent as AppleIcon } from "../../../assets/icons/icon_login_apple.svg";
import { ReactComponent as KakaoIcon } from "../../../assets/icons/icon_login_kakao.svg";
import { ReactComponent as LoginCodeIcon } from "../../../assets/icons/icon_login_code.svg";
import { useTranslation } from "react-i18next";
import { SignUpCode } from "../../../constants/SignUp.enum";
import {
  getTempMemberFromStorage,
  removeTempMemberFromStorage,
} from "../../../service/Preference.service";
import AuthStore from "../../../store/AuthStore";
import SocialMemberStore from "../../../store/SocialMemberStore";
import { LAST_LOGIN_TYPE } from "../../../constants/Storage";
import { SocialTypeCode } from "../../../constants/Common.enum";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import { observer } from "mobx-react";
// import { useTracker } from "../../../hooks/tracker";
import CustomBottomSheet from "../../../components/common/CustomBottomSheet";
import Lottie from "lottie-react";
import classes from "./LoginAttractMainV2.module.scss";
import { Platform, PlatformTypes } from "../../../native/platform";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const LoginAttractMainV2 = observer(
  ({ hideLookingAroundBtn }: { hideLookingAroundBtn: boolean }) => {
    const {
      attract,
      service,
      service_text,
      kakao,
      apple,
      txt_btn,
      txt_btn_white,
      sns,
      bottom_area,
      txt_1,
      bottom_area_line,
      bottom_left,
      last_login_info_txt,
      last_login_info,
      main,
      logo_wrap,
      login_wrap,
      problem_wrap,
      problem_btn,
      kakao_join_popover,
      new_signupStyle_logo_text1,
      signupStyle_kakao_join_popover_inner,
      signupStyle_kakao_join_popover_text1,
      signupStyle_kakao_join_popover_text2,
      signupStyle_triangle,
    } = styles;

    const {
      signInWithApple,
      signInWithGoogle,
      signInWithNaver,
      signInWithKakao,
    } = SocialMemberStore;
    // const [tracker] = useState(useTracker());
    const [lastLoginType, setLastLoginType] = useState<SocialTypeCode>();
    const [showOtherLogin, setShowOtherLogin] = useState<boolean>(false);
    const { t } = useTranslation();

    const ref = useRef<any>();

    // useEffect(() => {
    //   tracker.track("view_signup_page_v2", {
    //     is_modal: false,
    //     source_component: "LoginAttractMainV2",
    //   });
    // }, [tracker]);

    const clickSignUpBtn = (sort: string) => {
      let event = "";
      let method = "";
      switch (sort) {
        case SignUpCode.NAVER:
          void signInWithNaver();
          event = "click_second_signup_v2";
          method = SignUpCode.NAVER;
          break;
        case SignUpCode.GOOGLE:
          void signInWithGoogle();
          event = "click_second_signup_v2";
          method = SignUpCode.GOOGLE;
          break;
        case SignUpCode.KAKAO:
          void signInWithKakao();
          event = "click_main_signup_v2";
          method = SignUpCode.KAKAO;
          break;
        case SignUpCode.APPLE:
          void signInWithApple();
          event = "click_main_signup_v2";
          method = SignUpCode.APPLE;

          break;
        case SignUpCode.EMAIL:
          goTo("/JoinWithEmailScreen");
          event = "click_second_signup_v2";
          method = SignUpCode.EMAIL;
          break;
      }

      // tracker.track(event, {
      //   source_component: "LoginAttractMainV2",
      //   method: method,
      // });
    };

    const clickLookingAroundBtn = async () => {
      AuthStore.clear();
      const tempMember = await getTempMemberFromStorage();

      if (!tempMember?.nickname) {
        goTo("/PreferenceScreen");
      } else if (
        !tempMember?.profileUrl &&
        !tempMember?.profileImageFromGallary
      ) {
        goTo("/ProfileSetting");
      } else {
        await removeTempMemberFromStorage();
        goTo("/PreferenceScreen");
      }

      // tracker.track("skip_signup_v2", {
      //   component: "LoginAttractMainV2",
      // });
    };

    const clickEmailSignInBtn = () => {
      // tracker.track("click_second_signup_v2", {
      //   source_component: "LoginAttractMainV2",
      //   method: "EMAIL_SIGNIN",
      // });
      goTo("/LoginPresenter");
    };

    const getAndSetLastLoginType = async () => {
      setLastLoginType(
        (await localStorage.getItem(LAST_LOGIN_TYPE)) as SocialTypeCode
      );
    };

    useEffectOnlyOnce(() => {
      void getAndSetLastLoginType();
    });

    // useFocusEffect(
    //   useCallback(() => {
    //     return () => {
    //       handleBottomSheetClose();
    //     };
    //   }, [])
    // );

    const renderKakaoJoin = () => {
      return (
        <div className={classes.kakao_join_popover}>
          <div className={classes.signupStyle_kakao_join_popover_inner}>
            <div className={classes.signupStyle_triangle} />
            <p className={classes.signupStyle_kakao_join_popover_text1}>
              {t("screen.loginAttract.label.kakao_popover1")}
              <span className={classes.signupStyle_kakao_join_popover_text2}>
                {t("screen.loginAttract.label.kakao_popover2")}
              </span>
            </p>
          </div>
        </div>
      );
    };

    const handleBottomSheetClose = () => {
      setShowOtherLogin(false);
    };

    const renderOtherLogin = () => {
      return (
        <CustomBottomSheet
          open={showOtherLogin}
          onClose={() => {
            setShowOtherLogin(false);
          }}
          snapHeight={200}
        >
          <div style={sns}>
            <div
              style={{ marginRight: 10 }}
              onClick={() => clickSignUpBtn(SignUpCode.NAVER)}
              aria-label="sign up with naver"
            >
              <NaverIcon />
            </div>
            <div
              style={{ marginLeft: 10 }}
              onClick={() => clickSignUpBtn(SignUpCode.GOOGLE)}
              aria-label="sign up with google"
            >
              <GoogleIcon />
            </div>
          </div>
          <div style={bottom_area}>
            <div style={bottom_left}>
              <div
                onClick={() => clickEmailSignInBtn()}
                aria-label="sign in with email"
              >
                <span style={txt_1}>
                  {t("screen.loginAttract.label.bottomArea2")}
                </span>
              </div>
            </div>
            <div style={bottom_area_line} />
            <div style={{ flex: 1 }}>
              <div
                onClick={() => clickSignUpBtn(SignUpCode.EMAIL)}
                aria-label="sign up with email"
              >
                <span style={txt_1}>
                  {t("screen.loginAttract.label.bottomArea1")}
                </span>
              </div>
            </div>
          </div>
        </CustomBottomSheet>
      );
    };
    return (
      <div style={attract}>
        {/* <StatusBar backgroundColor={"transparent"} barStyle={"dark-content"} /> */}
        <div
          style={{
            position: "absolute",
          }}
        >
          <Lottie
            style={{
              width: "100%",
              height: "100%",
            }}
            animationData={require("../../../assets/animations/login_bg.json")}
            autoPlay
            loop
          />
        </div>
        <div style={main}>
          {!hideLookingAroundBtn && (
            <div
              style={service}
              onClick={() => void clickLookingAroundBtn()}
              aria-label="take a look inside"
            >
              <span style={service_text}>
                {t("screen.loginAttract.label.around")}
              </span>
            </div>
          )}

          <div style={logo_wrap} aria-label="attract login">
            <span style={new_signupStyle_logo_text1}>
              {t("screen.loginAttract.label.newcontentsBox1Alt")}
            </span>
            <span style={new_signupStyle_logo_text1}>
              {t("screen.loginAttract.label.newcontentsBox2Alt")}
            </span>
            <div style={{ marginTop: 32, marginBottom: 32 }}>
              <LoginCodeIcon />
            </div>
            <span style={new_signupStyle_logo_text1}>
              {t("screen.loginAttract.label.newcontentsBox3Alt")}
            </span>
            <span style={new_signupStyle_logo_text1}>
              {t("screen.loginAttract.label.newcontentsBox4Alt")}
            </span>

            {lastLoginType && (
              <div style={last_login_info}>
                <span style={last_login_info_txt}>
                  {t(`common.social.${lastLoginType}`)}
                  {t("screen.loginAttract.label.lastLogin")}
                </span>
              </div>
            )}
          </div>

          <div style={login_wrap}>
            {renderKakaoJoin()}
            <div
              style={kakao}
              onClick={() => clickSignUpBtn(SignUpCode.KAKAO)}
              aria-label="sign up with kakao"
              ref={ref}
            >
              <KakaoIcon />
              <span style={txt_btn}>
                {t("screen.loginAttract.label.kakao")}
              </span>
            </div>
            {Platform.OS === PlatformTypes.IOS_APP && (
              <div
                style={apple}
                onClick={() => clickSignUpBtn(SignUpCode.APPLE)}
                aria-label="sign up with apple"
                ref={ref}
              >
                <AppleIcon />
                <span style={txt_btn_white}>
                  {t("screen.loginAttract.label.apple")}
                </span>
              </div>
            )}
            <div style={styles.join_subscription}>
              <div
                onClick={() => {
                  setShowOtherLogin(true);

                  // tracker.track("click_other_signup_v2", {
                  //   source_component: "LoginAttractMainV2",
                  // });
                }}
              >
                <span
                  style={{
                    ...Texts.button_text_10,
                    ...Colors.font_fff,
                    fontWeight: "400",
                  }}
                >
                  {t("screen.loginAttract.label.newjoinSbscription")}
                </span>
              </div>
            </div>

            <div style={problem_wrap}>
              <div
                style={problem_btn}
                onClick={() => {
                  // navigate("FAQ", { isFromLoginAttraction: true });
                }}
              >
                <span
                  style={{
                    ...Texts.button_text_10,
                    ...Colors.font_fff,
                    fontWeight: "400",
                  }}
                >
                  {t("screen.loginAttract.label.problem")}
                </span>
              </div>
            </div>
          </div>
        </div>
        {renderOtherLogin()}
      </div>
    );
  }
);

export default LoginAttractMainV2;

const styles: Styles = {
  attract: {
    ...Positions.fullscreen,
    backgroundColor: "gray",
  },
  service: {
    ...Arranges.center_v,
    ...Spacings.padding_right_left_20,
    height: 56,
  },
  service_text: {
    ...Texts.button_text_4,
    ...Colors.font_999,
  },
  logo: {
    ...Spacings.margin_bottom_30,
    ...Arranges.center_v_h,
  },
  contents_box: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_30,
  },
  text_wrap: {
    ...Arranges.center_v_h,
  },
  txt_0: {
    ...Texts.contents_text_14,
  },
  txt_point: {
    ...Colors.font_6c2773,
  },
  txt_line: {
    width: 42,
    height: 2,
    ...Colors.background_6c2773,
    ...Spacings.margin_bottom_30,
    ...Spacings.margin_top_12,
  },
  kakao: {
    ...FlexBoxs.horizon,
    ...Buttons.big_1,
    ...Spacings.border_radius_10,
    ...Arranges.center_v_h,
    ...Spacings.margin_bottom_20,
    ...Colors.background_d90000,
    ...Colors.border_color_fce74e,
    ...Buttons.border_width_default,
    ...Colors.border_color_ffc700,
    ...Spacings.margin_right_left_40_responsive,
  },
  apple: {
    ...FlexBoxs.horizon,
    ...Buttons.big_1,
    ...Spacings.border_radius_10,
    ...Arranges.center_v_h,
    ...Spacings.margin_bottom_20,
    ...Colors.background_222,
    ...Colors.border_color_000,
    ...Colors.font_fff,
    ...Buttons.border_width_default,
    ...Spacings.margin_right_left_40_responsive,
  },
  txt_btn: {
    ...Spacings.margin_left_6,
    ...Texts.button_text_6,
  },
  txt_btn_white: {
    ...Spacings.margin_left_10,
    ...Texts.button_text_3,
  },
  middle_bar: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_right_left_40_responsive,
    height: 19,
  },
  middle_line: {
    ...FlexBoxs.flex_1,
    ...Colors.background_d9d9d9,
    height: 1,
  },
  middle_txt: {
    width: 44,
    ...Arranges.text_align_center,
    ...Texts.button_text_4,
  },
  sns: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v,
    ...Spacings.margin_top_25,
  },
  sns_img: {
    ...Spacings.margin_right_10,
  },
  bottom_area: {
    ...FlexBoxs.horizon,
    ...Spacings.padding_top_20,
    ...Arranges.center_v,
  },
  bottom_left: {
    ...FlexBoxs.flex_1,
    ...Arranges.end_h,
  },
  txt_1: {
    ...Texts.button_text_4,
    color: "#666",
  },
  bottom_area_line: {
    width: 1,
    ...Colors.background_c4c4c4,
    opacity: 0.3,
    ...Spacings.margin_left_right_13,
  },
  indicator: {
    ...FlexBoxs.flex_1,
    ...Arranges.center_v_h,
    ...Positions.absolute,
    ...Positions.left,
    ...Positions.right,
    top: 36,
    ...Positions.bottom,
  },
  last_login_info: {
    height: 32,
    ...Arranges.center_v_h,
    ...Spacings.margin_top_9,
  },
  last_login_info_txt: {
    ...Texts.button_text_10,
    ...Colors.font_fff,
    opacity: 0.6,
    fontWeight: "500",
  },
  join_subscription: {
    height: 32,
    ...Arranges.center_v_h,
  },
  join_subscription_txt: {
    ...Texts.button_text_10,
    ...Colors.font_fff,
    fontWeight: "500",
  },
  main: {
    ...Arranges.between,
    ...FlexBoxs.flex_1,
    display: "flex",
    position: "relative",
  },
  logo_wrap: {
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  login_wrap: {},
  problem_wrap: {
    ...Spacings.margin_top_45,
    ...Spacings.margin_bottom_32,
    ...Arranges.center_h,
  },
  problem_btn: {},
  problem_btn_text: {
    ...Texts.button_text_11,
    color: "rgba(255, 255, 255, 0.2)",
  },
  kakao_join_popover: {
    ...Positions.absolute,
    ...Positions.right,
    ...Positions.left,
    ...Arranges.center_v_h,
  },
  kakao_join_popover_inner: {
    ...Colors.background_762e8a,
    ...Colors.border_color_be69c3,
    ...Arranges.center_v_h,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.border_radius_12,
    borderWidth: 1,
    width: 136,
    height: 30,
  },
  kakao_join_popover_text1: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
    lineHeight: 16,
  },
  kakao_join_popover_text2: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_bold,
    lineHeight: 16,
    ...Colors.font_f7e237,
  },
  triangle: {
    ...Positions.absolute,
    ...Colors.background_762e8a,
    transform: [{ rotate: "45deg" }],
    left: 58,
    bottom: -5,
    width: 8,
    height: 8,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#be69c3",
  },
  logo_text1: {
    ...Texts.contents_text_53,
    ...Colors.font_fff,
    lineHeight: 34,
  },
  logo_text2: {
    ...Texts.font_weight_bold,
  },
  signupStyle_kakao_join_popover_inner: {
    ...Colors.background_fff,
    ...Colors.border_color_be69c3,
    ...Arranges.center_v_h,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.border_radius_12,
    borderWidth: 1,
    width: 136,
    height: 30,
  },
  signupStyle_kakao_join_popover_text1: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    lineHeight: 16,
  },
  signupStyle_kakao_join_popover_text2: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_bold,
    lineHeight: 16,
    color: "#d073d5",
  },
  signupStyle_triangle: {
    ...Positions.absolute,
    ...Colors.background_fff,
    transform: [{ rotate: "45deg" }],
    left: 58,
    bottom: -5,
    width: 8,
    height: 8,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#be69c3",
  },
  signupStyle_logo_text1: {
    ...Texts.contents_text_53,
    ...Colors.font_fff,
    lineHeight: 34,
    ...Texts.font_weight_bold,
  },
  new_signupStyle_logo_text1: {
    fontSize: 24,
    fontWeight: 700,
    letterSpacing: -0.96,
    color: "#fff",
  },
  signupStyle_logo_text2: {
    ...Texts.contents_text_53,
    ...Texts.font_size_14,
    ...Colors.font_fff,
    lineHeight: 20,
  },
  signupStyle_logo_wrap: {
    height: 32,
    ...Arranges.center_v_h,
    ...Spacings.margin_top_20,
    ...Spacings.margin_bottom_20,
  },
};
