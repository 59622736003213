export const center_v = {
  justifyContent: "center",
};

export const center_h = {
  alignItems: "center",
};

export const start_v = {
  justifyContent: "flex-start",
};

export const start_h = {
  alignItems: "flex-start",
};

export const end_v = {
  justifyContent: "flex-end",
};

export const end_h = {
  alignItems: "flex-end",
};

export const center_v_h = {
  ...center_h,
  ...center_v,
};

export const end_v_h = {
  ...end_h,
  ...end_v,
};

export const between = {
  justifyContent: "space-between",
};

export const margin_center_h = {
  marginTop: 0,
  marginBottom: 0,
  marginLeft: "auto",
  marginRight: "auto",
};

export const margin_center_v = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: 0,
  marginRight: 0,
};

export const margin_center_v_h = {
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "auto",
  marginRight: "auto",
};

export const text_align_center = {
  textAlign: "center",
};
