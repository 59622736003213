import { Platform, PlatformTypes } from '../native/platform'

export const goToAppDetail = () => {
  // void moveApp().then((installed) => {
  //   if (!installed) {
  switch (Platform.OS) {
    case PlatformTypes.ANDROID_APP:
    case PlatformTypes.ANDROID_WEB:
    case PlatformTypes.WINDOWS_WEB:
    case PlatformTypes.WEB:
      goToPlayStore('com.lgcns.reactnative.moga')
      break
    case PlatformTypes.IOS_APP:
    case PlatformTypes.IOS_WEB:
      goToAppStoreApp('1619024995')
      break
    case PlatformTypes.MACOS_WEB:
      goToAppStoreWeb('1619024995')
      break
  }
  //   }
  // })
}

const goToAppStoreApp = (appId: string) => {
  const link = `itms-apps://itunes.apple.com/app/${appId}`
  window.location.href = link
}

const goToAppStoreWeb = (appId: string) => {
  const link = `https://apps.apple.com/app/id${appId}`
  window.open(link)
}

const goToPlayStore = (packageName: string) => {
  const link = `https://play.google.com/store/apps/details?id=${packageName}`
  window.open(link)
}

export const moveApp = (): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    let isAppInstalled = false

    const link = 'cordncode220://' // 앱이 설치되어 있을땐 열리지만 설치되어 있지 않을때는 오류창이 표시됨
    // const link = 'https://tgwdm.test-app.link' // 아예 동작을 안함
    window.location.href = link

    let timer = setTimeout(() => {
      isAppInstalled =
        window.document.hidden ||
        ((window.document as any).webkitHidden as boolean)
      clearTimeout(timer)
      resolve(isAppInstalled)
    }, 1500)
  })
}

// cj one
// var applink = function (apps, name) {
//   var app = apps[name];
//   var ua = navigator.userAgent.toLocaleLowerCase();
//   if (!app) return { send: function () { throw "No App exists"; }};
//   return {
//       _app : app,
//       _ua : ua,
//       _os : "",
//     _browser : {},
//       init : function () {
//         this._os = this._osInfo();
//           this._browser = this._browserInfo();
//       },
//     send : function (params) {
//           if(typeof this._os=='undefined' || this._os==null || this._os=='') return;
//       var action_url = this._app.base_url;

//           if (params.hasOwnProperty('action')) {
//               action_url = "//webtoapp?action=" + params['action'];
//           }
//           action_url += this._serialized(params);

//           if (this._os == "ios") {
//               try {
//                   window.location = this._app.schema + ":" + action_url;
//               } catch(e) {}

//               setTimeout(function(o) {
//                   if (!(document.webkitHidden || document.hidden)) {
//                       if (confirm("앱스토어로 이동하시겠습니까?") == true){
//                           o._installRedirect();
//                       } else {
//                           if (o._app.hasOwnProperty('redirect_url')) {
//                               window.location.href = o._app.redirect_url;
//                           }
//                       }

//                       console.log('앱이 없습니다.');
//                   }
//               }, 1500, this);
//           } else if (this._os == "android") {
//               var intentURI = [
//                   'intent:' + action_url + '#Intent',
//                   'scheme=' + this._app.schema,
//                   'package=' + this._app.package,
//                   'end'
//               ].join(';');

//               if (this._isSupportingIntent()){
//                   window.location = intentURI;
//               } else {
//                   var iframe = document.createElement('iframe');
//                   iframe.width = '0';
//                   iframe.height = '0';
//                   iframe.style.disply = 'none';
//                   iframe.src = intentURI.replace(/package=.*?;/, '');
//                   document.body.appendChild(iframe);
//               }
//           } else {
//             if (this._app.hasOwnProperty('redirect_url')) {
//                 $(location).attr('href', _app.redirect_url);
//             }
//           };
//       },
//       _browserInfo : function () {
//           var browser = {};
//           var nav = window.navigator;
//           var appName = nav.appName.replace(/\s/g, '_');
//           var userAgent = nav.userAgent;

//           var rIE = /MSIE\s([0-9]+[.0-9]*)/;
//           var rIE11 = /Trident.*rv:11\./;
//           var rEdge = /Edge\/(\d+)\./;
//           var versionRegex = {
//             firefox: /Firefox\/(\d+)\./,
//             chrome: /Chrome\/(\d+)\./,
//             safari: /Version\/([\d.]+).*Safari\/(\d+)/
//           };

//           var key, tmp;

//           var detector = {
//             Microsoft_Internet_Explorer: function() { // eslint-disable-line camelcase
//               var detectedVersion = userAgent.match(rIE);

//               if (detectedVersion) { // ie8 ~ ie10
//                 browser.msie = true;
//                 browser.version = parseFloat(detectedVersion[1]);
//               } else { // no version information
//                 browser.others = true;
//               }
//             },
//             Netscape: function() { // eslint-disable-line complexity
//               var detected = false;

//               if (rIE11.exec(userAgent)) {
//                 browser.name = "msie";
//                 browser.version = 11;
//                 detected = true;
//               } else if (rEdge.exec(userAgent)) {
//                 browser.name = "edge";
//                 browser.version = userAgent.match(rEdge)[1];
//                 detected = true;
//               } else {
//                 for (key in versionRegex) {
//                   if (versionRegex.hasOwnProperty(key)) {
//                     tmp = userAgent.match(versionRegex[key]);
//                     if (tmp && tmp.length > 1) { // eslint-disable-line max-depth
//                       browser.name = key.toLocaleLowerCase();
//                       browser.version = parseFloat(tmp[1] || 0);
//                       detected = true;
//                       break;
//                     }
//                   }
//                 }
//               }
//               if (!detected) {
//                 browser.name = "others";
//                 browser.version = 0;
//               }
//             }
//           };

//           var fn = detector[appName];
//           if (fn) { detector[appName](); }

//             return browser;
//       },
//       _osInfo : function () {
//           if (this._ua.match(/android/)) {
//               return "android";
//           } else if (this._ua.match(/iphone|ipad|ipod/)) {
//             return "ios";
//           }
//           return "";
//       },
//       _installRedirect : function (){
//           window.location.href = this._app.store[this._os];
//       },
//       _serialized : function (params) {
//           var stripped = [];
//           for (var k in params) {
//               if (params.hasOwnProperty(k) && k != "action") {
//                   stripped.push(k + "=" + encodeURIComponent(decodeURIComponent(params[k])));
//               }
//           }

//           if (stripped.length > 0) {
//               return "&" + stripped.join("&");
//           }
//           return stripped.join("&");
//       },
//       _isSupportingIntent : function () {
//           return this._browser.name == "chrome" &&
//           +(this._browser.version) > 24 &&
//           !(/firefox|opr|fb_iab/i.test(this._ua))
//       }
//   };
// };
