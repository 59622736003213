import { useState, useEffect, useRef} from "react";
// import {
//   ActivityIndicator,
//   Dimensions,
//   Platform,
//   Pressable,
//   SafeAreaView,
//   ScrollView,
//   StyleSheet,
//   Text,
//   TouchableOpacity,
//   View,
// } from "react-native";
// import ReadMore from "@fawazahmed/react-native-read-more";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
// import { responsiveScreenWidth } from "react-native-responsive-dimensions";
import {ReactComponent as DownloadIcon} from "../../../assets/icons/download.svg";
// import Accordion from "react-native-collapsible/Accordion";
import Accordion, { IAccordionSection } from '../../../components/common/collapsible/Accordion'
import {ReactComponent as InfoIcon} from "../../../assets/icons/info.svg";
import {ReactComponent as ArrowDownIcon} from "../../../assets/icons/arrow_down.svg";
import {ReactComponent as ArrowUpIcon} from "../../../assets/icons/arrow_up.svg";
import {ReactComponent as BadgeImageIcon} from "../../../assets/icons/badge_img.svg";
import {ReactComponent as VideoIcon} from "../../../assets/icons/grid_video.svg";
// import LinearGradient from "react-native-linear-gradient";
import {ReactComponent as HeartOffIcon} from "../../../assets/icons/heart_off.svg";
import {ReactComponent as HeartOnIcon} from "../../../assets/icons/heart_on.svg";
import {ReactComponent as ChatIcon} from "../../../assets/icons/chat.svg";
import {ReactComponent as BookmarkOffIcon} from "../../../assets/icons/bookmark_off.svg";
import {ReactComponent as BookmarkOnIcon} from "../../../assets/icons/bookmark_on.svg";
import {ReactComponent as ShareIcon} from "../../../assets/icons/share.svg";
import {ReactComponent as Ai220Icon} from "../../../assets/images/5026_ai.svg";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
// import { FlatList } from "react-native-gesture-handler";
import AsCenter from "./AsCenter";
// import Config from "react-native-config";
import { ProductInfo, ProductSpec, ProductAsCenter, RelatedProduct } from "../../../api/product/model";
// import { navigate, popToTop, push } from "../../../navigation/NavigationFunction";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { Feed } from "../../../api/product/model";
import Slider from "react-slick";
import { getProductFeedList } from "../../../api/feed/api";
import { getRelatedProductList } from "../../../api/product/api";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";
import LikeStore from "../../../store/LikeStore";
import BookmarkStore from "../../../store/BookmarkStore";
import { FeedTypeCode, FEED_CALL_STATE } from "../../../constants/Feed";
import PdfModal from "../../../components/modals/PdfModal";
import AsCenterModal from "./AsCenterModal";
import BasicHeader from "../../../components/headers/BasicHeader";
import { SearchTabMenu } from "../../../constants/Search";
import { useTranslation } from "react-i18next";
import { sendActHistory } from "../../../api/data/api";
import { ActHistoryRequest } from "../../../api/data/model";
import { ActHistoryTypeCode, ActPageCode, ActSectionCode } from "../../../constants/ActHistory";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { onShare, ShareInfo } from "../../../utils/share";
import { LikeTypeCode } from "../../../constants/Like";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
// import {ReactComponent as TopIcon} from "../../../assets/icons/top_btn.svg";
import {ReactComponent as ProductSpecAnchorIcon} from "../../../assets/icons/4896_product_spec_anchor.svg";
import {ReactComponent as UpTag} from "../../../assets/icons/up_product_label.svg";
import {ReactComponent as AddIconOn} from "../../../assets/icons/product_add_on.svg";
import {ReactComponent as AddIconOff} from "../../../assets/icons/product_add_off.svg";
import { handleTopButtonScroll } from "../../../utils/common";
import { FeedParentTypeCode } from "../../feed/hook/UseFeedScreen";
// import { useIsFocused } from "@react-navigation/native";
import { track } from "../../../hooks/tracker/TrackFunction";
// import { useRemoteConfig } from "../../../hooks/UseRemoteConfig";
// import { showBottomToast } from "../../../utils/Toast";
// import { font_letter_spacing_072 } from "../../../assets/styles/texts";
import {ReactComponent as MallTag} from "../../../assets/icons/mall_sale_tag.svg";
import { goToLifecareProductPage } from "../../../utils/mall";
// import relatedPosts from "../../magazine/presenter/RelatedPosts";
import { SearchItem } from "../../../api/search/model";
import { CreateType } from "../../../constants/Common.enum";
// import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
// import MyAppliancesStore from "../../../store/MyAppliancesStore";
import { EventProperty } from "../../../hooks/tracker/types";
import CommonStore from "../../../store/CommonStore";
import RemoteConfigStore from "../../../store/RemoteConfigStore";
import  CustomActivityIndicator from "../../../components/common/CustomActivityIndicatorSmall";
import "./ProductDetail.scss";
import { CommentTypeCode } from "../../../constants/Comment";
import AuthStore from "../../../store/AuthStore";

const ProductDetail = observer(
  ({
    navigation,
    productId,
    productInfo,
    asCenterList,
    from,
  }: {
    navigation: any;
    productId: number;
    productInfo: ProductInfo;
    asCenterList: ProductAsCenter[];
    from?: string;
  }) => {
    const {
      chatgpt_summary_container,
      // chatgpt_summary_desc,
      chatgpt_summary_title,
      chatgpt_summary_title_container,
      // chatgpt_summary_more,
      chatgpt_summary_info_text,
      chatgpt_summary_info_text_container,

      product_detail,

      contents,

      title_area,
      title_between,
      title_left,
      title_right,
      // title_bottom,
      title_image,
      // title_bar,
      // product_info,
      product_main_text,
      product_sub_text,

      related_post,
      related_post_header,
      related_post_header_text1,
      related_post_header_text2,
      related_post_header_text3,
      first_item,
      big_post_list,
      big_post_item,
      big_post_image,
      big_post_info,
      big_post_info_text1,
      big_post_info_text2,
      // small_post_list,
      small_post_item,
      small_post_image,
      // custom_carousel_padding_left,

      // buy_button,

      write_btn,
      write_btn_text,

      qna,
      qna_header,
      qna_header_text1,
      qna_header_text2,
      // qna_list,
      qna_item,
      qna_image,
      qna_inner,
      qna_purple_inner,
      qna_contents_wrap,
      qna_contents_text,
      qna_info,
      qna_profile,
      qna_profile_image,
      id_text,
      comment,
      comment_text1,
      comment_text2,

      bar,
      tip,
      tip_header,
      tip_header_text1,
      tip_header_text2,
      tip_list,
      tip_item,
      badge_icon,
      tip_image,
      tip_contents,
      tip_contents_text1,
      tip_contents_text2,

      download_wrap,
      download_btn,
      download_btn_text,

      as,
      as_header,
      as_header_text1,
      as_header_text2,
      as_list,

      spec,
      spec_header_text,
      accordion_header,
      accordion_header_top,
      accordion_header_text1,
      accordion_header_text2,
      accordion_header_bottom,
      spec_list,
      spec_item,
      // label_wrap,
      label_text,
      // value_list,
      value_text,

      related_product,
      related_product_header,
      related_product_header_text1,
      // related_product_header_text2,
      related_product_header_text3,
      related_product_list,
      related_product_item,
      related_product_image,
      related_product_contents,
      related_product_info,
      text_bar,
      related_product_contents_text1,
      related_product_contents_text2,

      action_wrap,
      action_wrap_padding,
      action_left,
      action_right,
      action_icon_btn,
      action_text,
      number_text,
      like_text,
      // gradient,

      // image_wrap,
      // selected,

      // indicator,

      product_spec_moving_view,
      // my_appl_moving_view,
      product_spec_moving_text,
      my_product_text_on,
      my_product_text_off,

      up_tag_wrap,
    } = styles;

    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    // const insets = useSafeAreaInsets();
    // const config = useRemoteConfig();

    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
    
    const sliderHorizontalsettings = {
      className: "center",
      infinite: false,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      variableWidth: true
    };

    const sliderRealPostListsettings = {
      className: "center",
      infinite: false,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      variableWidth: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const sliderRealPostThumbnailsettings = {
      className: "center",
      infinite: false,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      variableWidth: false,
      focusOnSelect: true,
      slidesToShow: 5,
      slidesToScroll: 1,
    };

    let realPostSliderRef : Slider;
    let realPostThumnailSliderRef : Slider;

    const pageSize = 5;
    const [realPostPageIndex, setRealPostPageIndex] = useState(0);
    const [useMethodPageIndex, setUseMethodPageIndex] = useState(0);
    const [qnaPageIndex, setQnaPageIndex] = useState(0);
    const [relatedProductPageIndex, setRelatedProductPageIndex] = useState(0);
    
    const [realPostTotalCount, setRealPostTotalCount] = useState(0);
    const [realPostList, setRealPostList] = useState<Feed[]>([]);
    const [useMethodTotalCount, setUseMethodTotalCount] = useState(0);
    const [useMethodList, setUseMethodList] = useState<Feed[]>([]);
    const [qnaTotalCount, setQnaTotalCount] = useState<number>(0);
    const [qnaList, setQnaList] = useState<Feed[]>([]);
    const [relatedProductList, setRelatedProductList] = useState<RelatedProduct[]>([]);

    const [realPostIsLoading, setRealPostIsLoading] = useState(false);
    const [useMethodIsLoading, setUseMethodIsLoading] = useState(false);
    const [qnaIsLoading, setQnaIsLoading] = useState(false);
    const [relatedProductIsLoading, setRelatedProductIsLoading] = useState(false);

    const [realPostCanLoadMore, setRealPostCanLoadMore] = useState(true);
    const [useMethodCanLoadMore, setUseMethodCanLoadMore] = useState(true);
    const [qnaCanLoadMore, setQnaCanLoadMore] = useState(true);
    const [relatedProductCanLoadMore, setRelatedProductCanLoadMore] = useState(true);
    const [chatGptSummaryExpanded, setChatGptSummaryExpanded] = useState(false);

    const [productSpec, setProductSpec] = useState<ProductSpec[]>([]);
    const SPEC_COUNT = 5;
    const moreSections: IAccordionSection[] = [
      {
        title: 'productSpec',
        content: productSpec ? productSpec.slice(SPEC_COUNT, productSpec.length) : [],
      }
    ]

    const [isPdfModal, setIsPdfModal] = useState(false);
    const [isAsCenterModal, setIsAsCenterModal] = useState(false);

    const [likeCount, setLikeCount] = useState(0);
    const [likeYn, setLikeYn] = useState("N");
    const [bookmarkYn, setBookmarkYn] = useState("N");

    const { saveLike, cancelLike } = LikeStore;
    const { saveBookmark, cancelBookmark } = BookmarkStore;
    //const ref = useRef<ScrollView>(null);
    const [showTopIcon, setShowTopIcon] = useState(false);
    const [tempMyProductFlag, setTempMyProductFlag] = useState(false);
    const [currentY, setCurrentY] = useState<number>(0);

// const settings = {
    //   infinite: false,
    //   afterChange: handleAfterChange,
    // };

    // const isFocused = useIsFocused();
    const [isFocused, setIsFocused] = useState(false);
    const initialRender = 10;

    useEffect(() => {
      // 화면 포커스 여부 감지
      setIsFocused(true);
  
      // 컴포넌트 언마운트 시 포커스 여부 갱신
      return () => {
        setIsFocused(false);
      };
    }, [location]);

    /* eslint-disable */
    useEffect(() => {
      if (isFocused) {
        setTimeout(() => {
          const actHistory: ActHistoryRequest = {
            actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
            actPageCode: ActPageCode.PRODUCT_DETAIL,
            actSourcePageCode: CommonStore.previousRouteName,
            attr1: (currentY === 0).toString(),
          };
          void sendActHistory(actHistory);
        }, 1000);
      }
    }, [isFocused]);
    /* eslint-enable  */

    useEffect(() => {
      productInfo?.spec && setProductSpec(productInfo?.spec);

      setLikeYn(productInfo?.likeYn);
      setLikeCount(productInfo?.likeCount);
      setBookmarkYn(productInfo?.bookmarkYn);
      setTempMyProductFlag(productInfo?.myProductFlag);
    }, [productInfo]);

    const setComplete = (flag?: boolean) => {
      if (flag) {
        setTempMyProductFlag(flag);
      }
    };

    const handleMoreRealPostList = () => {
      if (!realPostIsLoading) {
        void getMoreProductFeeds(pageSize, realPostPageIndex + 1, FEED_CALL_STATE.PRODUCT_REALPOST, productId);
      }
    };

    const handleMoreUseMethodList = () => {
      if (!useMethodIsLoading) {
        void getMoreProductFeeds(pageSize, useMethodPageIndex + 1, FEED_CALL_STATE.PRODUCT_USE_METHOD, productId);
      }
    };

    const handleMoreQnaList = () => {
      if (!qnaIsLoading) {
        void getMoreProductFeeds(pageSize, qnaPageIndex + 1, FEED_CALL_STATE.PRODUCT_QNA, productId);
      }
    };

    const handleMoreRelatedProductList = () => {
      if (!relatedProductIsLoading) {
        void getMoreRelatedProducts(productId, pageSize, relatedProductPageIndex + 1);
      }
    };

    const addRealPostList = (newFeedList: Feed[], pageIndex: number) => {
      if (newFeedList?.length > 0) {
        setRealPostList([...realPostList, ...newFeedList]);
        setRealPostPageIndex(pageIndex);
      } else {
        setRealPostCanLoadMore(false);
      }
    };

    const addUseMethodList = (newFeedList: Feed[], pageIndex: number) => {
      if (newFeedList?.length > 0) {
        setUseMethodList([...useMethodList, ...newFeedList]);
        setUseMethodPageIndex(pageIndex);
      } else {
        setUseMethodCanLoadMore(false);
      }
    };

    const addQnaList = (newFeedList: Feed[], pageIndex: number) => {
      if (newFeedList?.length > 0) {
        setQnaList([...qnaList, ...newFeedList]);
        setQnaPageIndex(pageIndex);
      } else {
        setQnaCanLoadMore(false);
      }
    };

    const addRelatedProductList = (newFeedList: RelatedProduct[], pageIndex: number) => {
      if (newFeedList?.length > 0) {
        setRelatedProductList([...relatedProductList, ...newFeedList]);
        setRelatedProductPageIndex(pageIndex);
      } else {
        setRelatedProductCanLoadMore(false);
      }
    };

    const getMoreProductFeeds = async (
      pageSize: number,
      pageIndex: number,
      productFeedSearchType: string,
      productId: number
    ) => {
      if (productFeedSearchType == FEED_CALL_STATE.PRODUCT_REALPOST && realPostTotalCount > initialRender && realPostTotalCount > pageSize * pageIndex) {
        setRealPostIsLoading(true);
        const res = await getProductFeedList(pageSize, pageIndex, productFeedSearchType, productId);
        const feedLists: Feed[] = res.successOrNot == "Y" ? res.data.feedFindVOList : [];
        if (pageIndex > 0) {
          addRealPostList(feedLists, pageIndex);
        }
        setRealPostIsLoading(false);
      } else if (
        productFeedSearchType == FEED_CALL_STATE.PRODUCT_USE_METHOD &&
        useMethodTotalCount > pageSize * pageIndex
      ) {
        setUseMethodIsLoading(true);
        const res = await getProductFeedList(pageSize, pageIndex, productFeedSearchType, productId);
        const feedLists: Feed[] = res.successOrNot == "Y" ? res.data.feedFindVOList : [];
        if (pageIndex > 0) {
          addUseMethodList(feedLists, pageIndex);
        }
        setUseMethodIsLoading(false);
      } else if (productFeedSearchType == FEED_CALL_STATE.PRODUCT_QNA && qnaTotalCount > pageSize * pageIndex) {
        setQnaIsLoading(true);
        const res = await getProductFeedList(pageSize, pageIndex, productFeedSearchType, productId);
        const feedLists: Feed[] = res.successOrNot == "Y" ? res.data.feedFindVOList : [];
        if (pageIndex > 0) {
          addQnaList(feedLists, pageIndex);
        }
        setQnaIsLoading(false);
      }
    };

    const getMoreRelatedProducts = async (productId: number, pageSize: number, pageIndex: number) => {
      setRelatedProductIsLoading(true);
      const res = await getRelatedProductList(productId, pageSize, pageIndex);
      const productLists: RelatedProduct[] = res == null ? [] : res;
      if (pageIndex > 0) {
        addRelatedProductList(productLists, pageIndex);
      }
      setRelatedProductIsLoading(false);
    };

    useEffect(() => {
      const initRealPostList = async (pageSize: number, pageIndex: number) => {
        setRealPostIsLoading(true);
        const res = await getProductFeedList(pageSize, pageIndex, FEED_CALL_STATE.PRODUCT_REALPOST, productId);
        const feedLists: Feed[] = res.successOrNot == "Y" ? res.data.feedFindVOList : [];
        const totalCount: number = res.successOrNot == "Y" ? res.data.totalCount : 0;
        setRealPostTotalCount(totalCount);
        setRealPostList(feedLists);
        setRealPostIsLoading(false);
      };
      const initUseMethodList = async (pageSize: number, pageIndex: number) => {
        setRealPostIsLoading(true);
        const res = await getProductFeedList(pageSize, pageIndex, FEED_CALL_STATE.PRODUCT_USE_METHOD, productId);
        const feedLists: Feed[] = res.successOrNot == "Y" ? res.data.feedFindVOList : [];
        const totalCount: number = res.successOrNot == "Y" ? res.data.totalCount : 0;
        setUseMethodTotalCount(totalCount);
        setUseMethodList(feedLists);
        setUseMethodIsLoading(false);
      };
      const initQnaList = async () => {
        setQnaIsLoading(true);
        const res = await getProductFeedList(pageSize, 0, FEED_CALL_STATE.PRODUCT_QNA, productId);
        const feedLists: Feed[] = res.successOrNot == "Y" ? res.data.feedFindVOList : [];
        const totalCount: number = res.successOrNot == "Y" ? res.data.totalCount : 0;
        setQnaTotalCount(totalCount);
        setQnaList(feedLists);
        setQnaIsLoading(false);
      };
      const initRelatedProductList = async () => {
        setRelatedProductIsLoading(true);
        const res = await getRelatedProductList(productId, pageSize, 0);
        const productLists: RelatedProduct[] = res == null ? [] : res;
        setRelatedProductList(productLists);
        setRelatedProductIsLoading(false);
      };

      void initRealPostList(initialRender, 0);
      void initUseMethodList(initialRender, 0);
      void initQnaList();
      void initRelatedProductList();
    }, [productId]);

    useEffect(() => {
      if (productInfo.productId && !realPostIsLoading) {
        const props = {
          product_id: productInfo.productId,
          product_name: productInfo.productName,
          brand_name: productInfo.brandName,
          product_model_name: productInfo.productModelName,
          real_post_count: realPostTotalCount,
          like_count: productInfo.likeCount,
          comment_count: productInfo.commentCount,
        } as EventProperty;

        if (from) {
          props.source_screen_from = from;
        }

        track("view_product_detail", props);
      }
    }, [productInfo, realPostIsLoading, realPostTotalCount, from]);
    
    const renderOriginImageItem = (index: number, item: Feed) => {     
      return (
        <button
          key={index}
          onClick={() => handleNavigateFeedDetail("PROCUDT_REAL_POSTS", index, item.feedId)}
          style={{width: '100%'}}
          // activeOpacity={0.7}
        >
          <div style={{position: 'relative', margin: '0px 16px'}}>
            <FastImageWithFallback
              source={{ uri: `${PUBLIC_BUCKET_URL}${item.originalFilePath || ""}` }}
                            fallbackImageUri={`${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`}
              style={big_post_image}               
            />
            {/* <div style={styles.post_gradient}>
              <div style={big_post_info}>
                <p style={big_post_info_text1}>{item.nickname}</p>
                <p style={big_post_info_text2} 
                  // numberOfLines={1}
                >
                  {item.feedTitle}
                </p>
              </div>
            </div> */}
            <div style={styles.post_gradient}>
              <div style={big_post_info}>
                <p style={big_post_info_text1}>{item.nickname}</p>
                <p style={big_post_info_text2}>
                  {item.feedTitle}
                </p>
              </div>
            </div>
          </div>
        </button>
      );
    };

    const renderLoading = (height?: number | string) => {
        return (
          <div>
            <div id="product-footer" style={{ display: 'flex', alignItems: 'center', height: (height ? height:'238px') }}>
              <CustomActivityIndicator />
            </div>
          </div>
        );
    };

    const handleNavigateFeedDetail = (feedParentTypeCode: FeedParentTypeCode, index: number, feedId?: number) => {
      if (feedId && index > -1) {
        if (feedParentTypeCode == "PROCUDT_REAL_POSTS") {
          const actHistory: ActHistoryRequest = {
            actHistoryTypeCode: ActHistoryTypeCode.POST_READ,
            actHistoryTargetId: feedId.toString(),
            actPageCode: ActPageCode.PRODUCT_DETAIL,
            actSectionCode: ActSectionCode.REAL_POST,
            attr1: index.toString(),
          };
          void sendActHistory(actHistory);
        } else if (feedParentTypeCode === "PRODUCT_USAGE_POSTS") {
          const actHistory: ActHistoryRequest = {
            actHistoryTypeCode: ActHistoryTypeCode.POST_READ,
            actHistoryTargetId: feedId.toString(),
            actPageCode: ActPageCode.PRODUCT_DETAIL,
            actSectionCode: ActSectionCode.USE_METHOD_POST,
            attr1: index.toString(),
          };
          void sendActHistory(actHistory);
        }
      }

      goTo("/FeedScreen", {
        state: {
          itemIndex: index,
          feedParentTypeCode,
          extraData: {
            productId: productId,
          },
          postDatas: realPostList,
        }
      });
    };

    const renderChatGptSummary = () => {
      return (
        <div style={chatgpt_summary_container}>
          <div style={chatgpt_summary_title_container}>
            <Ai220Icon />
            <p style={chatgpt_summary_title}>{t(`screen.product.label.chatgpt.title`)}</p>
          </div>
          {/* TODO */}
          {/* <ReadMore
            accessibilityLabel="chatgpt summary readmore"
            style={chatgpt_summary_desc}
            numberOfLines={2}
            seeMoreText="more"
            seeMoreStyle={chatgpt_summary_more}
            expandOnly={true}
            animate={false}
            onExpand={() => {
              setChatGptSummaryExpanded(true);
            }}
          >
            &quot;{productInfo.chatGptSummary}&quot;
          </ReadMore> */}
          {chatGptSummaryExpanded ? renderChatGptInfo() : <div></div>}
        </div>
      );
    };

    const renderChatGptInfo = () => {
      return (
        <div style={chatgpt_summary_info_text_container}>
          <InfoIcon />
          <p style={chatgpt_summary_info_text}>{t(`screen.product.label.chatgpt.info`)}</p>
        </div>
      );
    };

    const renderRelatedPost = () => {
      return (
        <div style={related_post}>
          {realPostList?.length > 0 && (
            <>
              <div style={related_post_header}>
                <p style={related_post_header_text1}>
                  {t(`screen.product.label.realPost.title`)}
                  <span style={related_post_header_text2}>{realPostTotalCount > 0 && realPostTotalCount}</span>
                </p>
                <button
                  //accessibilityLabel={"More realpost"}
                  onClick={() => handleNavigateFeedDetail("PROCUDT_REAL_POSTS", 0)}
                >
                  <p style={related_post_header_text3}>{t(`screen.product.label.realPost.more`)}</p>
                </button>
              </div>
              <div style={big_post_list}>
                {/* <CustomCarousel
                  data={realPostList}
                  renderItem={({ item, index }: { item: Feed; index: number }) => {
                    return (
                      <View style={[big_post_item, { width: responsiveScreenWidth(100) }]}>
                        {renderOriginImageItem(index, item)}
                      </View>
                    );
                  }}
                  onEndReached={handleMoreRealPostList}
                  ListFooterComponent={() => renderFooter(realPostIsLoading, realPostCanLoadMore)}
                  showThumbnailCarousel
                  renderThumbnailCarouselItem={({ item, index }: { item: Feed; index: number }) => (
                    <FastImageWithFallback
                      source={{ uri: `${Config.REACT_NATIVE_PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}` }}
                      fallbackImageUri={`${Config.REACT_NATIVE_PUBLIC_BUCKET_URL}${item.originalFilePath || ""}`}
                      style={[small_post_item, small_post_image]}
                    />
                  )}
                  thumbnailLeftOffsetMargin={responsiveScreenWidth(4)}
                  thumbnailCarouselWrapperStyle={[small_post_list, { paddingLeft: responsiveScreenWidth(4) }]}
                  thumbnailSelectedStyle={[image_wrap, selected]}
                  ThumbnailFooterComponent={<View style={{ minWidth: responsiveScreenWidth(4) }} />}
                /> */}
                <Slider 
                  ref={(slider:Slider) => {
                    realPostSliderRef = slider;
                  }}
                                    {...sliderRealPostListsettings}
                  afterChange={currentSlide => {
                    if (realPostList.length > 0 && currentSlide === realPostList.length - 1) {
                      handleMoreRealPostList();
                    }
                  }}
                  beforeChange={(_, next) => realPostThumnailSliderRef?.slickGoTo(next)}
                >
                  {realPostList.map((item, index) => {
                    return (
                      <div key={index} style={{ ...big_post_item }}>
                        {renderOriginImageItem(index, item)}
                      </div>
                    );
                  })}
                  {
                    (realPostIsLoading && realPostCanLoadMore) && 
                    <div>
                      <div style={{display: 'flex', justifyContent: 'center'}}>{renderLoading()}</div>
                    </div>
                  }
                </Slider>
                <div style={{height: 8}}></div>
                <div style={{paddingLeft: '12px'}}>
                  <Slider 
                    ref={(slider:Slider) => {
                      realPostThumnailSliderRef = slider;
                    }}
                                        {...sliderRealPostThumbnailsettings}
                    afterChange={currentSlide => {
                      if (realPostList.length > 0 && currentSlide === realPostList.length - 1) {
                        handleMoreRealPostList();
                      }
                    }}
                  >
                    {realPostList.map((item, index) => {
                      return (
                        <div 
                          key={index} 
                          onClick={() => {
                            realPostSliderRef?.slickGoTo(index)
                          }}
                        >
                          <FastImageWithFallback
                            source={{ uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}` }}
                            fallbackImageUri={`${PUBLIC_BUCKET_URL}${item.originalFilePath || ""}`}
                            style={{...small_post_item, ...small_post_image}}
                          />
                        </div>
                      );
                    })}
                    {
                      (realPostIsLoading && realPostCanLoadMore) && 
                      <div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>{renderLoading('60px')}</div>
                      </div>
                    }
                  </Slider>
                </div>
              </div>
            </>
          )}
          <div style={related_post_header}>
            <p style={related_post_header_text1}>{t(`screen.product.label.realPost.noData`)}</p>
            <TouchableWithAuthCheck
              //accessibilityLabel={"Write post"}
              style={write_btn}
              onPress={() => {
                const tagList: string[] = [];
                productInfo?.brandName && tagList.push(productInfo?.brandName?.replace(/ /g, "").toLowerCase());
                productInfo?.productModelName &&
                  tagList.push(productInfo?.productModelName?.replace(/ /g, "").toLowerCase());
                productInfo?.productName && tagList.push(productInfo?.productName?.replace(/ /g, "").toLowerCase());
                goTo("/post/write", {
                  state: {
                    tags: tagList,
                    productInfo: {
                      ...productInfo,
                    },
                  }
                });
              }}
            >
              <p style={write_btn_text}>{t(`screen.product.label.realPost.writePost`)}</p>
            </TouchableWithAuthCheck>
          </div>
        </div>
      );
    };

    const renderQnaItem = ({ item, index }: { item: Feed; index: number }) => {
      return (
        <div key={index}>
          <button
            style={index == 0 ? {...qna_item, ...styles.qna_firstitem} : qna_item}
            onClick={() => {
              const actHistory: ActHistoryRequest = {
                actHistoryTypeCode: ActHistoryTypeCode.QNA_READ,
                actHistoryTargetId: item.feedId.toString(),
                actPageCode: ActPageCode.PRODUCT_DETAIL,
                actSectionCode: ActSectionCode.PRODUCT_QNA,
                attr1: index.toString(),
              };
              void sendActHistory(actHistory);
              goTo(`/QnA/${item.feedId}`);
            }}
          >
            <FastImageWithFallback
              source={{ uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}` }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${item.originalFilePath || ""}`}
              style={qna_image}
              wrapperStyle={{height: "100%"}}
            />
            {/* <div style={item.thumbnailFilePath ? qna_inner : {...qna_inner, ...qna_purple_inner}} />
              <div style={qna_contents_wrap}>
                <p style={qna_contents_text}>{item.feedTitle}</p>
                <div style={qna_info}>
                  <button style={qna_profile}>
                    <FastImageWithFallback
                      source={{ uri: `${PUBLIC_BUCKET_URL}${item.profileUrl || ""}` }}
                      style={qna_profile_image}
                    />

                    <p style={id_text} 
                      //numberOfLines={1}
                    >
                      {item.nickname}
                    </p>
                  </button>
                  <button style={comment}>
                    <p style={comment_text1}>{t(`screen.product.label.qna.comment`)}</p>
                    <p style={comment_text2}>{item.commentCount}</p>
                  </button>
                </div>
              </div> */}
              {/* <div style={item.thumbnailFilePath ? qna_inner : {...qna_inner, ...qna_purple_inner}} /> */}
              <div style={qna_contents_wrap}>
                <p style={qna_contents_text}>{item.feedTitle}</p>
                <div style={qna_info}>
                  <div style={qna_profile}>
                    <FastImageWithFallback
                      source={{ uri: `${PUBLIC_BUCKET_URL}${item.profileUrl || ""}` }}
                      style={qna_profile_image}
                    />
                    <p style={id_text}>
                      {item.nickname}
                    </p>
                  </div>
                  <div style={comment}>
                    <p style={comment_text1}>{t(`screen.product.label.qna.comment`)}</p>
                    <p style={comment_text2}>{item.commentCount}</p>
                  </div>
              </div>
              </div>
          </button>
        </div>
      );
    };

    const renderQna = () => {
      return (
        <div style={qna}>
          {qnaList?.length > 0 ? (
            <>
              <div style={qna_header}>
                <p style={qna_header_text1}>{t(`screen.product.label.qna.title`)}</p>
                <button
                  // accessibilityLabel={"More qna"}
                  onClick={() =>
                    goTo(`/Search/${productInfo?.productName}/${SearchTabMenu.Qna}`)
                  }
                >
                  <p style={qna_header_text2}>{t(`screen.product.label.qna.more`)}</p>
                </button>
              </div>
              {/* <FlatList
                horizontal
                showsHorizontalScrollIndicator={false}
                data={qnaList}
                renderItem={renderQnaItem}
                style={qna_list}
                onEndReachedThreshold={0.01}
                onEndReached={handleMoreQnaList}
                ListFooterComponent={() => renderFooter(qnaIsLoading, qnaCanLoadMore)}
              /> */}
              <Slider {...sliderHorizontalsettings}
                afterChange={currentSlide => {
                  if (qnaList.length > 0 && currentSlide === qnaList.length - 1){
                    handleMoreQnaList();
                  }
                }}
              >
                {qnaList.map((item, index) => {
                  return (
                    <div key={index}>
                      {renderQnaItem({ item, index })}
                    </div>
                  )
                })}
                { (qnaIsLoading && qnaCanLoadMore) && renderLoading()}
              </Slider>
            </>
          ) : (
            <div style={qna_header}>
              <p style={qna_header_text1}>{t(`screen.product.label.qna.noData`)}</p>
              <TouchableWithAuthCheck
                // accessibilityLabel={"Write qna"}
                style={write_btn}
                onPress={() => {
                  const tagList: string[] = [];
                  productInfo?.brandName && tagList.push(productInfo?.brandName?.replace(/ /g, "").toLowerCase());
                  productInfo?.productModelName &&
                    tagList.push(productInfo?.productModelName?.replace(/ /g, "").toLowerCase());
                  productInfo?.productName && tagList.push(productInfo?.productName?.replace(/ /g, "").toLowerCase());
                  goTo("/QnA/write", {
                    state: {
                      tags: tagList,
                      productInfo: {
                        ...productInfo,
                      },
                    }
                  });
                }}
              >
                <p style={write_btn_text}>{t(`screen.product.label.qna.writeQna`)}</p>
              </TouchableWithAuthCheck>
            </div>
          )}
        </div>
      );
    };

    const renderTipItem = ({ item, index }: { item: Feed; index: number }) => {
      return (
        <div>
          <button
            style={index == 0 ? {...tip_item, ...styles.tip_firstitem} : tip_item}
            onClick={() => handleNavigateFeedDetail("PRODUCT_USAGE_POSTS", index, item.feedId)}
          >
            <FastImageWithFallback
              source={{ uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}` }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${item.originalFilePath || ""}`}
              style={tip_image}
            />

            {item.videoCount > 0 ? (
              <VideoIcon style={badge_icon} />
            ) : item.imageCount > 1 ? (
              <BadgeImageIcon style={badge_icon} />
            ) : (
              <></>
            )}

            <div style={tip_contents}>
              <p style={tip_contents_text1}>
                {item.feedTitle}
              </p>
              <p style={tip_contents_text2}>{item.nickname}</p>
            </div>
          </button>
        </div>
      );
    };

    const renderTip = () => {
      return (
        <div style={tip}>
          <div style={tip_header}>
            <p style={tip_header_text1}>{t(`screen.product.label.useMethod.title`)}</p>
            <button
              // accessibilityLabel={"More usemethod"}
              onClick={() => handleNavigateFeedDetail("PRODUCT_USAGE_POSTS", 0)}
            >
              <p style={tip_header_text2}>{t(`screen.product.label.useMethod.more`)}</p>
            </button>
          </div>
          {/* <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            data={useMethodList}
            renderItem={renderTipItem}
            style={tip_list}
            onEndReachedThreshold={0.01}
            onEndReached={handleMoreUseMethodList}
            ListFooterComponent={() => renderFooter(useMethodIsLoading, useMethodCanLoadMore)}
          /> */}
          <div style={tip_list}>
            <Slider {...sliderHorizontalsettings}
              afterChange={currentSlide => {
                if (useMethodList.length > 0 && currentSlide === useMethodList.length - 1){
                  handleMoreUseMethodList();
                }
              }}
            >
              {useMethodList.map((item, index) => {
                return (
                  <div key={index}>
                    {renderTipItem({ item, index })}
                  </div>
                )
              })}
              { (useMethodIsLoading && useMethodCanLoadMore) && renderLoading()}
            </Slider>
          </div>
        </div>
      );
    };

    const renderAsItem = (section: any) => {      
      return (
        section &&
        section.map((item: ProductAsCenter, index: number) => {
          if (index < 3) return <AsCenter key={index} item={item} />;
        })
      );
    };

    const renderAs = () => {
      return (
        <>
          <div style={as}>
            <div style={as_header}>
              <p style={as_header_text1}>{t(`screen.product.label.asCenter.title`)}</p>
              {asCenterList?.length > 3 && (
                <button
                  // accessibilityLabel={"More ascenter"}
                  onClick={() => {
                    setIsAsCenterModal(!isAsCenterModal);
                  }}
                >
                  <p style={as_header_text2}>{t(`screen.product.label.asCenter.more`)}</p>
                </button>
              )}
            </div>
            <div style={as_list}>{renderAsItem(asCenterList)}</div>
          </div>
          <div style={bar} />
        </>
      );
    };

    const renderSpecHeader = (section: IAccordionSection, index: string | number, isActive: boolean, sections: IAccordionSection[]) => {
      return (
        <div style={accordion_header}>
          <div style={accordion_header_top}>
            <InfoIcon />
            <p style={accordion_header_text1}>{t(`screen.product.label.asCenter.info`)}</p>
          </div>
          <hr style={{border: '1px solid #EEEEEE'}}/>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <button
              style={accordion_header_bottom}
              onClick={() => {
                if (isActive) {
                  setActiveSection([]);
                } else {
                  CommonStore.callActHistory(
                    ActHistoryTypeCode.PRODUCT_SPEC_READ,
                    String(productId),
                    ActPageCode.PRODUCT_DETAIL,
                    ActSectionCode.PRODUCT_SPEC
                  );
                  setActiveSection([0]);
                }
              }}
            >
              <p style={accordion_header_text2}>
                {isActive ? t(`screen.product.label.asCenter.collapse`) : t(`screen.product.label.asCenter.expand`)}
              </p>
              {isActive ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </button>
          </div>
        </div>
      );
    };

    const renderSpecContent = (section: IAccordionSection) => {
      return section.content.map((d: { [key: string]: any }, index: number) => {
        return (
          <div style={spec_item} key={index}>
            {/* <div style={label_wrap}> */}
              <p style={label_text}>{d.label}</p>
            {/* </div> */}
            {/* <div style={value_list}> */}
              <p style={value_text}>{d.value}</p>
            {/* </div> */}
          </div>
        );
      });
    };

    const renderSpecItem = (spec: ProductSpec[]) => {
      return (
        spec &&
        spec
          .filter((item: ProductSpec, index: number) => index < SPEC_COUNT)
          .map((item: ProductSpec, index: number) => {
            return (
              <div key={index} style={spec_item}>
                <p style={label_text}>{item.label}</p>
                <p style={value_text}>{item.value}</p>
              </div>
            );
          })
      );
    };

    // const [dataSourceCords, setDataSourceCords] = useState(0);
    let specInfoRef : HTMLDivElement | null;
    const moveSpecInfo = () => {
      track("click_product_info", {});
      // ref?.current?.scrollTo({
      //   x: 0,
      //   y: dataSourceCords,
      //   animated: true,
      // });
      specInfoRef?.scrollIntoView({
        behavior: "smooth",
      });
    };

    const [activeSection, setActiveSection] = useState<number[]>([]);
    const updateSections = (activeSections: any) => setActiveSection(activeSections);
    const renderSpec = () => {
      return (
        <>
          <div
            style={spec}
            // onLayout={(event) => {
            //   const layout = event.nativeEvent.layout;
            //   setDataSourceCords(layout.y);
            // }}
          >
            <p style={spec_header_text}>{t(`screen.product.label.productInfo.title`)}</p>
            <div style={spec_list}>
              {renderSpecItem(productSpec)}
              {productSpec?.length > SPEC_COUNT ? (
                <Accordion
                  sections={moreSections}
                  activeSections={activeSection}
                  renderHeader={renderSpecHeader}
                  renderContent={renderSpecContent}
                  onChange={updateSections}
                  underlayColor={"#fff"}
                  expandFromBottom={true}
                />
              ) : (
                <div style={accordion_header_top}>
                  <InfoIcon />
                  <p style={accordion_header_text1}>{t(`screen.product.label.asCenter.info`)}</p>
                </div>
              )}
            </div>
          </div>
          <div style={bar} />
        </>
      );
    };

    const renderRelatedProductItem = ({ item, index }: { item: RelatedProduct; index: number }) => {
      return (
        <div>
          <button
            style={index == 0 ? {...related_product_item, ...styles.related_firstitem} : related_product_item}
            key={index}
            onClick={() => {
              const actHistory: ActHistoryRequest = {
                actHistoryTypeCode: ActHistoryTypeCode.PRODUCT_READ,
                actHistoryTargetId: item.productId.toString(),
                actPageCode: ActPageCode.PRODUCT_DETAIL,
                actSectionCode: ActSectionCode.RELATED_PRODUCT,
                attr1: index.toString(),
              };
              void sendActHistory(actHistory);
              goTo(`/Product/${item.productId}`);
            }}
          >
            <FastImageWithFallback
              source={{ uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}` }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${item.originalFilePath || ""}`}
              style={related_product_image}
            />
            <div style={related_product_contents}>
              <p style={related_product_contents_text1}>
                {item.productName}
              </p>
              <div style={related_product_info}>
                <p style={{...styles.related_product_contents_text2, flexShrink: 0}}>{item.brandName}</p>
                <div style={text_bar} />
                <p style={related_product_contents_text2}>{item.productModelName}</p>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: "row" }}>
              {RemoteConfigStore.show_up_product_tag === "true" && item.upYn && item.upYn == "Y" && (
                <div style={styles.up_tag_wrap}>
                  <UpTag />
                </div>
              )}
              {item.lifecareProductId && item.lifecareProductId !== null ? (
                <div style={styles.on_sale_tag_wrap}>
                  <div style={styles.mall_tag}>
                    <MallTag />
                  </div>
                  <p style={styles.on_sale_tag_text}>{t("screen.main.label.onSale")}</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          </button>
        </div>
      );
    };

    const renderRelatedProduct = () => {
      return (
        <div style={related_product}>
          <div style={related_product_header}>
            <p style={related_product_header_text1}>{t(`screen.product.label.relatedProduct.newTitle`)}</p>
            <button
              // accessibilityLabel={"More related product"}
              onClick={() =>
                goTo(`/products/${productId}/relates`, { state: { productName: productInfo?.productName } })
              }
            >
              <p style={related_product_header_text3}>{t(`screen.product.label.relatedProduct.more`)}</p>
            </button>
          </div>
          {/* <FlatList
            horizontal
            showsHorizontalScrollIndicator={false}
            data={relatedProductList}
            renderItem={renderRelatedProductItem}
            style={related_product_list}
            onEndReachedThreshold={0.01}
            onEndReached={handleMoreRelatedProductList}
            ListFooterComponent={() => renderFooter(relatedProductIsLoading, relatedProductCanLoadMore)}
          /> */}
          <div style={related_product_list}>
            <Slider {...sliderHorizontalsettings}
              afterChange={currentSlide => {
                if (relatedProductList.length > 0 && currentSlide === relatedProductList.length - 1){
                  handleMoreRelatedProductList();
                }
              }}
            >
              {relatedProductList.map((item, index) => {
                return (
                  <div key={index}>
                    {renderRelatedProductItem({item, index})}
                  </div>
                )
              })}
              { (relatedProductIsLoading && relatedProductCanLoadMore) && renderLoading()}
            </Slider>
          </div>
        </div>
      );
    };

    const onLikeButtonPress = () => {
      if (likeYn === "Y") {
        void cancelLike(
          productInfo.productId,
          FeedTypeCode.PRODUCT,
          () => {
            setLikeYn("N");
            setLikeCount((preCount) => preCount - 1);
            //PPIT210117-4818 LIKE 취소 시 actHistory 처리 추가
            const actHistory: ActHistoryRequest = {
              actHistoryTypeCode: ActHistoryTypeCode.LIKE,
              actHistoryTargetId: productId.toString(),
              actPageCode: ActPageCode.PRODUCT_DETAIL,
              attr2: likeCount.toString(),
              attr3: (productInfo?.commentCount || 0).toString(),
              attr4: (realPostList?.length || 0).toString(),
              attr5: (qnaList?.length || 0).toString(),
              attr6: "CANCEL",
            };
            void sendActHistory(actHistory);
          },
          () => {
            // fail
          }
        );
      } else {
        void saveLike(
          productInfo.productId,
          FeedTypeCode.PRODUCT,
          () => {
            setLikeYn("Y");
            setLikeCount((preCount) => preCount + 1);
            const actHistory: ActHistoryRequest = {
              actHistoryTypeCode: ActHistoryTypeCode.LIKE,
              actHistoryTargetId: productId.toString(),
              actPageCode: ActPageCode.PRODUCT_DETAIL,
              attr2: likeCount.toString(),
              attr3: (productInfo?.commentCount || 0).toString(),
              attr4: (realPostList?.length || 0).toString(),
              attr5: (qnaList?.length || 0).toString(),
            };
            void sendActHistory(actHistory);
          },
          () => {
            // fail
          }
        );

        track("click_like_button", {
          content_id: productInfo.productId.toString(),
          content_type: FeedTypeCode.PRODUCT,
          content_name: productInfo.productName,
          like_count: productInfo.likeCount || 0,
          comment_count: productInfo.commentCount || 0,
        });
      }
    };

    const onBookmarkButtonPress = async () => {
      if (bookmarkYn == "Y") {
        await cancelBookmark(
          productInfo.productId,
          FeedTypeCode.PRODUCT,
          () => {
            setBookmarkYn("N");
            //PPIT210117-4818 BOOKMARK 취소 시 actHistory 처리 추가
            const actHistory: ActHistoryRequest = {
              actHistoryTypeCode: ActHistoryTypeCode.BOOKMARK,
              actHistoryTargetId: productId.toString(),
              actPageCode: ActPageCode.PRODUCT_DETAIL,
              attr2: likeCount.toString(),
              attr3: (productInfo?.commentCount || 0).toString(),
              attr4: (realPostList?.length || 0).toString(),
              attr5: (qnaList?.length || 0).toString(),
              attr6: "CANCEL",
            };
            void sendActHistory(actHistory);
          },
          () => {
            // fail
          }
        );
      } else {
        await saveBookmark(
          productInfo.productId,
          FeedTypeCode.PRODUCT,
          () => {
            setBookmarkYn("Y");
            const actHistory: ActHistoryRequest = {
              actHistoryTypeCode: ActHistoryTypeCode.BOOKMARK,
              actHistoryTargetId: productId.toString(),
              actPageCode: ActPageCode.PRODUCT_DETAIL,
              attr2: likeCount.toString(),
              attr3: (productInfo?.commentCount || 0).toString(),
              attr4: (realPostList?.length || 0).toString(),
              attr5: (qnaList?.length || 0).toString(),
            };
            void sendActHistory(actHistory);
          },
          () => {
            // fail
          }
        );
      }

      track("click_bookmark", {
        content_id: productId.toString(),
        content_title: productInfo.productName,
        content_type: FeedTypeCode.PRODUCT,
        like_count: productInfo.likeCount || 0,
        comment_count: productInfo.commentCount || 0,
      });
    };

    const onCommentButtonPress = () => {
      navigate(`/${LikeTypeCode.PRODUCT}/${productInfo.productId}/comment`, {
        state: {
          feedAuthorId: AuthStore.sessionUser?.memberUuid,
          feedType: CommentTypeCode.PRODUCT, 
          feedId: productInfo.productId
        }
      });
      // track("click_comment_button", {
      //   content_id: productInfo.productId.toString(),
      //   content_title: productInfo.productName,
      //   content_type: FeedTypeCode.PRODUCT,
      //   like_count: productInfo.likeCount || 0,
      //   comment_count: productInfo.commentCount || 0,
      // });
    };

    const onLikeListButtonPress = () => {      
      goTo(`/${LikeTypeCode.PRODUCT}/${productInfo.productId}/like`);
    };

    const onUpTagButtonPress = () => {      
      goTo(`/magazine/${RemoteConfigStore.up_product_magazine_feedId}`);
    };
    
    return (
      <div style={product_detail}>
        <BasicHeader
          title={t(`screen.product.title`)}
          onSearch={() => {
            goTo("/Search");
          }}
          isHome
        />
        {/* <ScrollView
          style={contents}
          showsVerticalScrollIndicator={false}
          ref={ref}
          scrollEventThrottle={0}
          onScroll={(event) => {
            setCurrentY(event.nativeEvent.contentOffset.y);
            handleTopButtonScroll(
              event,
              () => {
                setShowTopIcon(true);
              },
              () => {
                setShowTopIcon(false);
              },
              ref
            );
          }}
        > */}
        <div
          style={contents}
          onScroll={(event) =>{
            // setCurrentY(event.nativeEvent.contentOffset.y);
            handleTopButtonScroll(
              event,
              () => {
                setShowTopIcon(true);
              },
              () => {
                setShowTopIcon(false);
              }
            )
          }}
        >
          <div style={title_area}>
            <div style={title_right}>
              <div style={title_between}>
                <div style={title_left}>
                  {productInfo?.thumbnailFilePath && (
                    <FastImageWithFallback
                      source={{
                        uri: `${PUBLIC_BUCKET_URL}${productInfo?.thumbnailFilePath || ""}`,
                      }}
                      fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                        productInfo?.originalFilePath || ""
                      }`}
                      style={title_image}
                    />
                  )}
                </div>
                <div style={title_right}>
                  <p style={product_main_text}>
                    {RemoteConfigStore.show_up_product_tag === "true" &&
                    productInfo?.upYn &&
                    productInfo?.upYn == "Y" ? (
                      <>
                        {productInfo?.productName && productInfo?.productName.length > 25
                          ? productInfo?.productName.substring(0, 25) + "・・・"
                          : productInfo?.productName}
                        {RemoteConfigStore.up_product_magazine_feedId > 0 ? (
                          <button style={up_tag_wrap} onClick={onUpTagButtonPress}>
                            <UpTag />
                          </button>
                        ) : (
                          <div style={up_tag_wrap}>
                            <UpTag />
                          </div>
                        )}
                      </>
                    ) : (
                      <>{productInfo?.productName}</>
                    )}
                  </p>
                  <p style={product_sub_text}>
                    {productInfo?.brandName}
                  </p>
                  <p style={product_sub_text}>
                    {productInfo?.productModelName}
                  </p>
                  {productSpec?.length > 0 && (
                    <div style={{...product_sub_text, marginTop: 10, display:'flex' as const, ...FlexBoxs.flex_1 }}>
                      <div onClick={moveSpecInfo}>
                        <div style={product_spec_moving_view}>
                          <p style={{...product_spec_moving_text, ...FlexBoxs.flex_1}}>{t("screen.productAdd.title.productInfo")}</p>
                          <ProductSpecAnchorIcon />
                        </div>
                      </div>
                      {!tempMyProductFlag ? (
                        <TouchableWithAuthCheck
                          onPress={() => {
                            goTo("/MyAppliance");
                          }}
                        >
                          <div style={product_spec_moving_view}>
                            <p style={my_product_text_off}>{t("screen.productAdd.title.myApplianceNew1")}</p>
                            <AddIconOn />
                          </div>
                        </TouchableWithAuthCheck>
                      ) : (
                        <TouchableWithAuthCheck
                          onPress={() => {                            
                              goTo("/Product/Add", {
                                state: {
                                  myApplianceScreen: true,
                                  screenFrom: "product",
                                  createType: CreateType.NEW,
                                  searchItem: {
                                    productId: productInfo?.productId,
                                    productName: productInfo?.productName,
                                    productModelName: productInfo?.productModelName,
                                    brandNameKor: productInfo?.brandName,
                                  } as SearchItem,
                                  // onComplete: setComplete,
                                }
                              });
                          }}
                        >
                          <div style={product_spec_moving_view}>
                            <p style={my_product_text_on}>{t("screen.productAdd.title.myApplianceNew")}</p>
                            <AddIconOff />
                          </div>
                        </TouchableWithAuthCheck>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* <View style={title_bottom}>
                {productSpec?.length > 0 && (
                  <Pressable onPress={moveSpecInfo}>
                    <View style={product_spec_moving_view}>
                      <Text style={product_spec_moving_text}>제품 정보</Text>
                      <ProductSpecAnchorIcon />
                    </View>
                  </Pressable>
                )}
              </View> */}
              {productInfo.lifecareProductId && productInfo.lifecareProductId !== null ? (
              <TouchableWithAuthCheck
                  style={{...styles.btn_shopping_wrap, ...styles.buy_button}}
                  onPress={() => {
                    track("click_mall_productdetail", {
                      product_id: productInfo.productId,
                      product_name: productInfo.brandName ?? "" + " " + productInfo.productName ?? "",
                      category_name: productInfo.category1,
                    });
                    const actHistory: ActHistoryRequest = {
                      actHistoryTypeCode: ActHistoryTypeCode.MALL_PRODUCT_READ,
                      actHistoryTargetId: productInfo.lifecareProductId?.toString() ?? "",
                      actPageCode: ActPageCode.PRODUCT_DETAIL,
                      attr1: productInfo.productId.toString(),
                    };
                    void sendActHistory(actHistory);

                    goToLifecareProductPage(productInfo.lifecareProductId || 0);
                  }}
                >
                  <p style={styles.txt_btn_shopping}>{t(`screen.mall.button.buying`)}</p>
              </TouchableWithAuthCheck>
              ) : (
                <></>
              )}
            </div>
          </div>

          {productInfo.chatGptSummary ? renderChatGptSummary() : <div></div>}
          {renderRelatedPost()}
          {renderQna()}
          <div style={bar} />

          {useMethodList?.length > 0 && renderTip()}
          {productInfo?.useMethodFileUrl != null && (
            <div style={download_wrap}>
              <button
                style={download_btn}
                onClick={() => {
                  setIsPdfModal(!isPdfModal);
                }}
              >
                <p style={download_btn_text}>{t(`screen.product.label.productInfo.pdf`)}</p>
                <DownloadIcon />
              </button>
            </div>
          )}
          {useMethodList?.length < 1 && productInfo?.useMethodFileUrl == null ? <></> : <div style={bar} />}

          <div ref={ref => specInfoRef = ref}>
            {productSpec && productSpec?.length > 0 && renderSpec()}
          </div>
          
          {asCenterList?.length > 0 && renderAs()}
          
          {relatedProductList?.length > 0 && renderRelatedProduct()}
          <div style={{ height: 80, background: 'rgb(238, 238, 238)' }} />
        </div>
        <div style={action_wrap}>
            {/* <LinearGradient
              colors={["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0.06)"]}
              start={{ x: 1, y: 0 }}
              end={{ x: 1, y: 1 }}
              style={[gradient]}
            /> */}
          <div style={{...styles.background_gradient, height: 4, position: "absolute", top: -4, left: 0, right: 0, zIndex: 1}}></div>
          <div style={action_wrap_padding}>
            <div style={action_right}>
              <TouchableWithAuthCheck 
                  // accessibilityLabel="like count" 
                  onPress={onLikeListButtonPress}>
                <p style={{...action_text, ...like_text}}>
                  {t(`screen.product.label.like`)} <span style={number_text}>{likeCount}</span>
                </p>
              </TouchableWithAuthCheck>
              <TouchableWithAuthCheck 
                // accessibilityLabel="comment count" 
                onPress={onCommentButtonPress}>
                <p style={action_text}>
                  {t(`screen.product.label.comment`)} <span style={number_text}>{productInfo?.commentCount}</span>
                </p>
              </TouchableWithAuthCheck>
            </div>
            <div style={action_left}>
              <TouchableWithAuthCheck
                // accessibilityLabel="like button"
                style={action_icon_btn}
                onPress={onLikeButtonPress}
              >
                {likeYn == "Y" ? <HeartOnIcon /> : <HeartOffIcon />}
              </TouchableWithAuthCheck>
              <TouchableWithAuthCheck
                // accessibilityLabel="comment button"
                style={action_icon_btn}
                onPress={onCommentButtonPress}
              >
                <ChatIcon />
              </TouchableWithAuthCheck>
              <TouchableWithAsyncTask
                // accessibilityLabel="bookmark button"
                style={action_icon_btn}
                onPress={onBookmarkButtonPress}
                authCheckProps={{ enabled: true }}
              >
                {bookmarkYn == "Y" ? <BookmarkOnIcon /> : <BookmarkOffIcon />}
              </TouchableWithAsyncTask>
              <TouchableWithAuthCheck
                // accessibilityLabel="share button"
                onPress={() => {
                  const actHistoryData = {
                    actHistoryTypeCode: ActHistoryTypeCode.POST_SHARE,
                    actPageCode: ActPageCode.PRODUCT_DETAIL,
                    feedId: (productInfo.productId ?? 0).toString(),
                    likeCount: (productInfo.likeCount ?? 0).toString(),
                    commentCount: (productInfo.commentCount ?? 0).toString(),
                    postCount: realPostTotalCount.toString(),
                    qnaCount: qnaTotalCount.toString(),
                  };
                  const shareInfo: ShareInfo = {
                    title: productInfo?.productName,
                    descriptionText: productInfo?.brandName + " " + productInfo?.productModelName,
                    imageUrl: productInfo?.thumbnailFilePath,
                    screen: ShareScreenCode.PRODUCT_DETAIL_SCREEN,
                    targetId: productInfo?.productId,
                  };
                  // const share_method = config.getConfig("share_method", "dynamiclink");
                  const share_method = getStringRemoteConfig("share_method");
                  // if (share_method == "branch") {
                  //   void onShareWithBranch(shareInfo, actHistoryData);
                  // } else {
                  //   void onShare(shareInfo, actHistoryData);
                  // }
                  void onShare(shareInfo);
                  track("click_share_button", {
                    content_id: productInfo?.productId,
                    content_title: productInfo.productName,
                    content_type: FeedTypeCode.PRODUCT,
                    like_count: productInfo.likeCount || 0,
                    comment_count: productInfo.commentCount || 0,
                  });
                }}
              >
                <ShareIcon />
              </TouchableWithAuthCheck>
            </div>
          </div>
        </div>
        {/* {showTopIcon && (
          <TouchableOpacity
            style={[styles.top_btn, { bottom: Platform.OS === "ios" ? insets.bottom + 50 : 80 }]}
            onPress={() => {
              ref.current?.scrollTo({
                y: 0,
                animated: true,
              });
            }}
          >
            <TopIcon />
          </TouchableOpacity>
        )} */}
        <PdfModal
          isVisible={isPdfModal}
          source={`${PUBLIC_BUCKET_URL}${productInfo?.useMethodFileUrl || ""}`}
          onRequestClose={() => {
            setIsPdfModal(!isPdfModal);
          }}
        />
        <AsCenterModal
          isVisible={isAsCenterModal}
          productId={productId}
          productName={productInfo?.productName}
          onRequestClose={() => {
            setIsAsCenterModal(!isAsCenterModal);
          }}
        />
      </div>
    );
  }
);

export default ProductDetail;

const styles : Styles = {
  btn_shopping_wrap: {
    borderWidth: 1,
    borderColor: "#be69c2",
    paddingVertical: 14,
    borderRadius: 4,
  },
  buy_button: { 
    padding: '14,12,14,12', 
    border: '1px solid #BE69C2', 
    borderRadius: '4px', 
    ...FlexBoxs.flex, 
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    ...Spacings.margin_top_30,    
  },
  txt_btn_shopping: {
    fontSize: 14,
    color: "#be69c2",
  },
  on_sale_tag_wrap: {
    width: "auto",
    backgroundColor: "#F0DFF2",
    borderRadius: 4,
    alignSelf: "flex-start",
    height: 17,
    ...Spacings.margin_top_4,
    ...Spacings.margin_right_4,
    ...Spacings.margin_bottom_2,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    display: "flex"
  },
  up_tag_wrap: {
    width: "auto",
    alignSelf: "flex-start",
    height: 17,
    ...Spacings.margin_top_4,
    ...Spacings.margin_right_4,
    ...Spacings.margin_bottom_2,
    ...Spacings.margin_left_2,
    // paddingLeft: Platform.OS === "ios" ? 0 : 2,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    display: "flex"
  },
  on_sale_tag_text: {
    color: "#BE69C2",
    fontSize: 10,
    fontWeight: "700",
    letterSpacing: -0.4,
    lineHeight: 14,
    ...Spacings.padding_left_1,
    ...Spacings.padding_right_6,
    paddingVertical: 1,
    textAlign: "right",
  },
  mall_tag: {
    ...Spacings.padding_left_6,
    paddingVertical: 3,
  },
  chatgpt_summary_container: {
    ...Spacings.padding_20,
  },
  chatgpt_summary_title_container: {
    ...FlexBoxs.flex,
    ...Spacings.margin_bottom_6,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  chatgpt_summary_title: {
    ...Texts.font_size_18,
    ...Texts.font_weight_500,
    ...Texts.font_letter_spacing_072,
    ...Colors.font_222,
  },
  chatgpt_summary_desc: {
    ...Texts.font_size_14,
    ...Colors.font_222,
    ...Texts.font_letter_spacing_056,
    ...Texts.line_height_22,
  },
  chatgpt_summary_more: {
    ...Texts.font_size_12,
    ...Colors.font_222,
    ...Texts.font_letter_spacing_048,
    ...Texts.font_weight_bold,
    ...Texts.text_underline,
  },
  chatgpt_summary_info_text: {
    ...Colors.font_999,
    ...Texts.font_size_12,
    ...Texts.font_letter_spacing_048,
    ...Spacings.margin_left_3,
  },
  chatgpt_summary_info_text_container: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.margin_top_8,
    ...Spacings.margin_bottom_10,
  },
  product_detail: {
    ...FlexBoxs.flex_1,
    width: "100%"
  },

  contents: {
    ...Spacings.padding_top_30,
  },
  bx_bottom_btn: {
    ...Spacings.margin_top_10,
  },
  bx_btn_row: {
    ...FlexBoxs.horizon,
    alignItems: "center",
    justifyContent: "space-between",
  },
  title_area: {
    ...FlexBoxs.horizon,
    ...Spacings.margin_left_16,
    ...Spacings.margin_right_16,
    ...Spacings.margin_bottom_24,
  },
  title_between: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    ...Arranges.between,
    ...FlexBoxs.horizon,
  },
  title_left: {
    ...Arranges.center_v_h,
    ...Spacings.border_radius_300,
    ...Spacings.margin_right_20,
    ...Colors.background_f5f5f5,
    width: 100,
    height: 100,
  },
  title_right: {
    ...FlexBoxs.flex_1,
    ...Arranges.center_v,
  },
  title_bottom: {
    ...FlexBoxs.horizon,
    height: 24,
    ...Spacings.margin_top_10,
  },
  title_image: {
    ...Spacings.border_radius_300,
    width: 100,
    height: 100,
  },
  product_spec_moving_view: {
    ...FlexBoxs.flex,
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    ...Spacings.margin_left_0,
    ...Spacings.margin_right_10,
    ...Spacings.margin_bottom_0,
    ...Spacings.padding_left_0,
    ...Spacings.padding_right_0,
    ...Spacings.padding_top_0,
  },
  my_appl_moving_view: {
    // width: 72,
    height: 24,
    flex: 1,
    ...FlexBoxs.horizon,
    justifyContent: "space-between",
    alignItems: "flex-start",
    ...Spacings.margin_left_0,
    ...Spacings.margin_bottom_0,
    ...Spacings.padding_left_0,
    ...Spacings.padding_right_0,
    ...Spacings.padding_top_0,
  },
  product_spec_moving_text: {
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: "right",
    ...Colors.font_be69c3,
    ...Spacings.margin_right_5,
  },
  my_product_text_on: {
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: "right",
    ...Colors.font_be69c3,
    ...Spacings.margin_right_5,
  },
  my_product_text_off: {
    fontSize: 12,
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: "right",
    ...Colors.font_999,
    ...Spacings.margin_right_5,
  },
  title_bar: {
    ...Spacings.margin_right_left_6,
    ...Colors.background_eeeeee,
    height: 10,
    width: 1,
  },
  product_info: {
    ...Arranges.center_h,
    ...FlexBoxs.horizon,
  },
  product_main_text: {
    ...Texts.contents_text_18,
    ...Texts.font_weight_bold,
    ...Spacings.margin_bottom_4,
    overflow: 'hidden', 
    display: '-webkit-box', 
    '-webkit-line-clamp': '2', 
    'line-clamp': '2', 
    '-webkit-box-orient': 'vertical'
  },
  product_sub_text: {
    ...Texts.contents_text_31,
    ...Colors.font_999,
    ...Spacings.margin_top_2,
    //width: responsiveScreenWidth(25),
    overflow: 'hidden', 
    display: '-webkit-box', 
    '-webkit-line-clamp': '2', 
    'line-clamp': '2', 
    '-webkit-box-orient': 'vertical'
  },

  related_post: {
    //...Spacings.margin_top_40,
    ...Spacings.padding_top_30,
    ...Spacings.padding_bottom_40,
    ...Colors.background_fcfafc,
  },
  related_post_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_20,
  },
  related_post_header_text1: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222
  },
  related_post_header_text2: {
    ...Colors.font_a760b1,
  },
  related_post_header_text3: {
    ...Texts.btn_text_1,
    ...Colors.font_999,
  },
  big_post_list: {
    ...Spacings.margin_top_14,
    ...Spacings.margin_bottom_24,
  },
  first_item: {
    //...Spacings.margin_left_16,
    paddingLeft: '0px !important'
  },
  big_post_item: {
    ...FlexBoxs.flex,
    // ...Spacings.margin_right_5,
    ...Arranges.center_v_h,
  },
  big_post_image: {
    // width: responsiveScreenWidth(92.3),
    width: '100%',
    aspectRatio: '1 / 1',
    //height: responsiveScreenWidth(92.3),
    ...Spacings.border_radius_6,
    objectFit: "cover"
  },
  big_post_info: {
    ...FlexBoxs.flex,
    ...Spacings.padding_right_left_16,
  },
  big_post_info_text1: {
    ...Texts.contents_text_25,
    ...Texts.font_weight_bold,
    ...Spacings.margin_right_8,
  },
  big_post_info_text2: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    ...Texts.contents_text_25,
    ...Texts.font_weight_500,
    overflow: 'hidden', 
    display: '-webkit-box', 
    '-webkit-line-clamp': '1', 
    'line-clamp': '1', 
    '-webkit-box-orient': 'vertical'
  },
  small_post_list: {
    ...Spacings.margin_top_8,
  },
  small_post_item: {
    // ...Spacings.padding_left_right_4,
  },
  small_post_image: {
    // width: responsiveScreenWidth(17.4),
    width: '100%',
    // height: responsiveScreenWidth(17.4),
    //height: '17.4%',
    aspectRatio: '1 / 1',
    ...Spacings.border_radius_6,
    objectFit: "cover",
  },

  write_btn: {
    width: 105,
    height: 44,
    ...Spacings.border_radius_300,
    ...Colors.border_color_a760b1,
    borderWidth: 1,
    ...Arranges.center_v_h,
    borderStyle: 'solid',
  },
  write_btn_text: {
    
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },

  qna: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Spacings.margin_top_40,
  },
  qna_header: {
    width: '100%',
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_20,
  },
  qna_header_text1: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  qna_header_text2: {
    ...Texts.btn_text_1,
    ...Colors.font_999,
  },
  qna_list: {
    ...Spacings.margin_top_16,
  },
  qna_item: {
    ariaHidden: 'false',
    position: 'relative',
    width: 220,
    height: 220,
    margin: "16px 8px 16px 0",
    // ...Spacings.margin_right_8,
  },
  qna_image: {
    // width: responsiveScreenWidth(56.4),
    width: '100%',
    // height: responsiveScreenWidth(56.4),
    height: '100%',
    ...Spacings.border_radius_6,
    objectFit: "cover"
  },
  qna_inner: {
    //...Positions.fullscreen,
    ...Spacings.border_radius_6,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  qna_purple_inner: {
    ...Colors.background_a760b1,
  },
  qna_contents_wrap: {
    ...FlexBoxs.flex,
    flexDirection: 'column',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 20,
    position: 'absolute',
    ...Arranges.between,
  },
  qna_contents_text: {
    textAlign: 'start',
    ...Texts.contents_text_38,
    ...Colors.font_fff,
    ...Texts.font_weight_500,
  },
  qna_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    justifyContent: 'space-between'
  },
  qna_profile: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  qna_profile_image: {
    width: 24,
    height: 24,
    ...Spacings.border_radius_25,
    ...Spacings.margin_right_6,
  },
  id_text: {
    textAlign: 'start',
    ...Texts.contents_text_5,
    ...Colors.font_fff,
    width: '100%',
    overflow: 'hidden', 
    display: '-webkit-box', 
    '-webkit-line-clamp': '1', 
    'line-clamp': '1', 
    '-webkit-box-orient': 'vertical',
    marginRight: 2
  },
  qna_firstitem: {
    marginLeft: 16,
  },
  comment: {
    ...FlexBoxs.flex,
    flexShrink: 0,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  comment_text1: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
    ...Spacings.margin_right_2,
  },
  comment_text2: {
    ...Texts.contents_text_25,
    ...Texts.font_weight_500,
  },
  bar: {
    height: 6,
    ...Colors.background_f5f5f5,
    ...Spacings.margin_top_30,
  },

  tip: {
    ...Spacings.margin_top_40,
  },
  tip_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_20,
  },
  tip_header_text1: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  tip_header_text2: {
    ...Texts.btn_text_1,
    ...Colors.font_999,
  },

  tip_list: {
    ...Spacings.margin_top_16,
  },
  tip_item: {
    ariaHidden: 'false',
    width: 175,
    margin: "16px 8px 16px 0",
    position: "relative"
    // width: responsiveScreenWidth(44.8),
    //width: '44.8%',
    //..Spacings.margin_right_8,
  },
  tip_firstitem: {
    marginLeft: 16,
  },
  badge_icon: {
    ...Positions.absolute,
    top: 8,
    right: 8,
  },
  tip_image: {
    width: '100%',
    // width: responsiveScreenWidth(44.8),
    // height: responsiveScreenWidth(44.8),
    ...Spacings.border_radius_6,
    aspectRatio: "1 / 1",
    objectFit: "cover"
  },
  tip_contents: {
    textAlign: 'start',
    //...Spacings.margin_top_14,
    ...Spacings.padding_left_right_4,
    marginTop: 14,
  },
  tip_contents_text1: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    overflow: 'hidden', 
    display: '-webkit-box', 
    '-webkit-line-clamp': '2', 
    'line-clamp': '2', 
    '-webkit-box-orient': 'vertical'
  },
  tip_contents_text2: {
    ...Spacings.margin_top_4,
    ...Texts.contents_text_31,
    ...Colors.font_999,
    overflow: 'hidden', 
    whiteSpace: 'nowrap', 
    textOverflow: 'ellipsis', 
    maxWidth: '100%'
  },

  download_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.margin_top_40,
  },
  download_btn: {
    width: 190,
    height: 48,
    ...Arranges.center_v_h,
    ...FlexBoxs.horizon,
    ...Spacings.border_radius_100,
    ...Colors.border_color_222,
    borderWidth: 1,
  },
  download_btn_text: {
    ...Spacings.margin_right_6,
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },

  as: {
    ...Spacings.margin_top_40,
    ...Spacings.padding_left_right_20,
  },
  as_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
  },
  as_header_text1: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  as_header_text2: {
    ...Texts.btn_text_1,
    ...Colors.font_999,
  },
  as_list: {
    ...Spacings.margin_top_40,
  },

  spec: {
    ...Spacings.margin_top_40,
    ...Spacings.padding_left_right_20,
  },
  spec_header_text: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },

  accordion_header: {
    ...Colors.background_fafafa,
    ...Spacings.border_bottom_left_right_radius_6,
  },
  accordion_header_top: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.padding_bottom_30,
  },
  accordion_header_text1: {
    ...Spacings.margin_left_4,
    ...Texts.contents_text_5,
    ...Colors.font_999,
  },
  accordion_header_text2: {
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_666666,
  },
  accordion_header_bottom: {
    ...FlexBoxs.flex,
    ...Colors.border_color_eee,
    borderTopWidth: 1,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    height: 54,
  },
  spec_list: {
    ...Spacings.border_top_left_right_radius_6,
    ...Spacings.margin_top_16,
    ...Spacings.padding_top_30,
    ...Spacings.padding_left_right_20,
    ...Colors.background_fafafa,
  },
  spec_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    ...FlexBoxs.horizon,
    ...Spacings.margin_bottom_18,
  },
  label_wrap: {},
  label_text: {
    // width: responsiveScreenWidth(20.5),
    width: '25%',
    ...Spacings.margin_right_8,
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_999,
  },
  value_list: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
  },
  value_text: {
    // width: responsiveScreenWidth(55.5),
    width: '75%',
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },

  related_product: {
    ...Spacings.margin_top_40,
    ...Spacings.margin_bottom_20,
  },
  related_product_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_20,
  },
  related_product_header_text1: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  related_product_header_text2: {
    ...Colors.font_a760b1,
  },
  related_product_header_text3: {
    ...Texts.btn_text_1,
    ...Colors.font_999,
  },
  related_product_list: {
    ...Spacings.margin_top_16,
  },
  related_product_item: {
    ariaHidden: 'false',
    width: 175,
    margin: '0 8px 0 0',
    // width: responsiveScreenWidth(44.8),
    //width: '44.8%',
    //..Spacings.margin_right_8,
  },
  related_firstitem: {
    marginLeft: 16,
  },
  related_product_image: {
    width: '100%',
    height: '100%',
    // ...Spacings.padding_top_bottom_16,
    // ...Spacings.padding_right_left_16,
    // width: responsiveScreenWidth(44.8),
    // height: responsiveScreenWidth(44.8),
    ...Spacings.border_radius_6,
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.1)",
    borderStyle: 'solid',
  },
  related_product_contents: {
    textAlign: 'start',
    //...Spacings.margin_top_14,
  },
  related_product_contents_text1: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    overflow: 'hidden', 
    display: '-webkit-box', 
    '-webkit-line-clamp': '2', 
    'line-clamp': '2', 
    '-webkit-box-orient': 'vertical',
    marginTop: 8
  },
  related_product_contents_text2: {
    ...Texts.contents_text_31,
    // ...Colors.font_999,
    overflow: 'hidden', 
    whiteSpace: 'nowrap', 
    textOverflow: 'ellipsis', 
    maxWidth: '100%'
  },
  related_product_info: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_top_4,
    display: "flex"
  },
  text_bar: {
    height: 10,
    width: 1,
    ...Colors.background_eeeeee,
    ...Spacings.margin_right_left_6,
  },
  background_gradient: {
    background: "linear-gradient(0deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.1) 20%, rgba(255,255,255,0) 100%)"
  },
  action_wrap: {
    position: 'fixed',
    // ...Positions.absolute,
    ...Positions.bottom,
    // ...Positions.left,
    // ...Positions.right,
    maxWidth: '450px',
    width: '100%',
    ...Colors.background_fff,
  },
  action_wrap_padding: {
    ...FlexBoxs.flex,
    ...Spacings.padding_right_left_20,
    ...Spacings.padding_top_bottom_20,
    ...Arranges.between,
    ...Arranges.center_h,
    ...FlexBoxs.horizon,
  },
  action_left: {
    ...FlexBoxs.horizon,
  },
  action_right: {
    ...FlexBoxs.horizon,
  },
  action_icon_btn: {
    ...Spacings.margin_right_10,
  },
  action_text: {
    ...Texts.contents_text_35,
    ...Texts.font_weight_500,
  },
  number_text: {},
  like_text: {
    ...Spacings.margin_right_10,
  },
  gradient: {
    ...Positions.absolute,
    ...Positions.right,
    ...Positions.left,
    ...FlexBoxs.flex_1,
    top: -30,
    height: 30,
  },

  image_wrap: {
    ...FlexBoxs.flex_1,
  },
  selected: {
    ...Colors.background_a760b1,
    opacity: 0.3,
    ...Positions.absolute,
    zIndex: 10,
    // width: responsiveScreenWidth(17.4),
    width: '17.4%',
    // height: responsiveScreenWidth(17.4),
    height: '17.4%',
    ...Spacings.border_radius_6,
  },
  indicator: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_left_right_7,
  },
  post_gradient: {
    position: 'absolute', 
    bottom: '30px'
  },
  top_btn: {
    ...Positions.absolute,
    right: 24,
  },
  bx_comm_wrap: {
    marginHorizontal: 16,
    marginBottom: 40,
  },
  txt_comm_title: {
    fontSize: 18,
    color: "#222",
    letterSpacing: -0.72,
    marginTop: 6,
  },
  bx_info_brand: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 6,
    backgroundColor: "#fafafa",
    paddingHorizontal: 20,
    paddingVertical: 16,
    ...Spacings.margin_top_16,
  },
  bx_img_wrap: {
    borderRadius: 300,
    width: 64,
    height: 64,
    backgroundColor: "#000",
  },
  bx_info_text: {
    paddingLeft: 14,
  },
  txt_info_title: {
    fontSize: 16,
    lineHeight: 16,
    letterSpacing: -0.64,
    color: "#222",
    marginBottom: 5,
  },
  txt_info_intro: {
    fontSize: 12,
    color: "#666",
  },
  bx_info_row: {
    flexDirection: "row",
    marginTop: 2,
    alignItems: "center",
  },
  info_dot: {
    width: 3,
    height: 3,
    borderRadius: 3,
    backgroundColor: "#666",
    marginHorizontal: 6,
  },
  txt_info_label: {
    fontSize: 12,
    letterSpacing: -0.48,
    color: "#666",
  },
  txt_info_value: {
    fontSize: 12,
    letterSpacing: -0.48,
    color: "#682c78",
  },
  bx_btn_mall: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 4,
  },
  txt_btn_mall: {
    marginLeft: 4,
    color: "#6C2773",
    fontSize: 12,
    fontWeight: "700",
    letterSpacing: -0.48,
  },
  custom_carousel_padding_left: {
    paddingLeft: '4%'
  }
};
