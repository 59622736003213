import "./QnaMain.scss";

import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";

import { Styles } from "../../../assets/types/Style";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";

import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/arrow_right_gray.svg";

//import { FlatList } from "react-native-gesture-handler";
import QnaListItem from "./QnaListItem";
import QnaCardItem from "./QnaCardItem";
import QnaInterestingCard from "./QnaInterestingCard";
import QnaWriteButtons from "./QnaWriteButtons";

// import AbCard from "../../ab/presenter/AbListCard";

// import QnaInterestingCard from "./QnaInterestingCard";
// import Toast from "react-native-toast-message";
// import { ParamListBase, useNavigation, useScrollToTop } from "@react-navigation/native";
// import { DrawerNavigationProp } from "@react-navigation/drawer";
import QnaStore from "../../../store/QnaStore";
// import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import AuthStore from "../../../store/AuthStore";
import { useTranslation } from "react-i18next";
import { SearchSortType } from "../../../constants/Search";
//import CustomCarousel from "../../../components/common/CustomCarousel";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import AbStore from "../../../store/AbListStore";
import HorizontalAbList from "../../ab/presenter/HorizontalAbList";
import { AbVoteResult } from "../../../api/ab/model";
import { AbTypeCode } from "../../../constants/ab";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
// import { handleTopButtonScroll } from "../../../utils/common";
import { Qna } from "../../../api/qna/model";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import Slider from "react-slick";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { Platform, PlatformTypes } from "../../../native/platform";
import { useWindowScroll } from "react-use";
import { ActHistoryRequest } from "../../../api/data/model";
import CommonStore from "../../../store/CommonStore";
import { sendActHistory } from "../../../api/data/api";

const cardSlidersettings = {
  infinite: false,
  swipeToSlide: true,
  arrows: false,
  dots: false,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: true,
};

const settings = {
  dots: true,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const QnaMain = observer(() => {
  const {
    recentlyQnaList,
    popularAbList,
    popularQnaList,
    interestingQnaList,
    loading,
    initList,
  } = QnaStore.qnaMainStore;

  const { t } = useTranslation();

  const { sessionUser, tempMember } = AuthStore;

  const {
    updatePopularAbVoteResultTemporaily,
    updateMyAbVoteResultTemporaily,
  } = AbStore;
  const nickname = sessionUser?.nickname
    ? sessionUser?.nickname
    : tempMember?.nickname;
  const memberUuid = sessionUser?.memberUuid
    ? sessionUser?.memberUuid
    : tempMember?.tempMemberUUID;

  const [selfWidth, setSelfWidth] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const isPlatformIOSWEB = Platform.OS === PlatformTypes.IOS_WEB;

  const [toastMessage, setToast] = useState("");
  // const navigation = useNavigation<DrawerNavigationProp<ParamListBase>>();
  const ref = useRef();
  const [showTopIcon, setShowTopIcon] = useState(false);
  // const insets = useSafeAreaInsets();

  // useScrollToTop(ref);

  const { activePopularAbs, retrieveActivePopularAbList, clear } = AbStore;
  const { y } = useWindowScroll();

  useEffect(() => {
    setTimeout(() => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
        actPageCode: ActPageCode.QNA,
        actSourcePageCode: CommonStore.fixedPreviousRouteName,
        attr1: (y === 0).toString(),
      };
      void sendActHistory(actHistory);
    }, 1000);
  }, []);

  useEffectOnlyOnce(
    () => {
      void retrieveActivePopularAbList();
    },
    () => {
      clear();
    }
  );

  useEffect(() => {
    if (toastMessage !== undefined && toastMessage?.trim().length > 0) {
      // Toast.show({
      //   type: "tomatoToast",
      //   autoHide: true,
      //   text1: toastMessage + " ",
      //   onHide: () => setToast(""),
      // });
    }
  }, [toastMessage]);

  // const onMeasure = (e: LayoutChangeEvent) => setSelfWidth(e.nativeEvent.layout.width);

  const handleAlarm = () => {};

  const handleSearch = () => {};

  const handleVotePopularAbSuccess = (
    res: AbVoteResult,
    myAbFlag: AbTypeCode
  ) => {
    updatePopularAbVoteResultTemporaily(res, myAbFlag);
    updateMyAbVoteResultTemporaily(res, myAbFlag);
  };

  // const showDrawerMenu = () => {
  //   navigation?.openDrawer();
  // };

  const moveToAbList = () => {
    goTo("/abList");
  };

  const moveToQnaList = (type: SearchSortType) => {
    QnaStore.qnaListStore.setSort(type);
    goTo("/qnalist");
  };

  // 관심있어 할 질문
  const renderInterestingQna = () => {
    return (
      <div className="interesting_question">
        <div className="interesting_question_header">
          <p className="interesting_question_header_title">
            {t(`screen.qna.label.interested_qna`, { nickname: nickname })}
          </p>
        </div>
        <div className="interesting_question_list">
          <Slider {...settings}>
            {interestingQnaList.map((qna, index) => {
              return (
                <QnaInterestingCard item={qna} index={index} key={index} />
              );
            })}
          </Slider>
        </div>
      </div>
    );
  };

  // 인기 Q&A
  const renderPopularQna = () => {
    return (
      <div className="wrap">
        <div className="horizontal_list_title">
          <div className="title_background" />
          <p className="title">{t(`screen.qna.label.popular_qna`)}</p>
        </div>
        <div className="popular_qna_list">
          <div className="slider-container">
            <Slider {...cardSlidersettings}>
              {popularQnaList.map((qna, index) => {
                return <QnaCardItem qna={qna} index={index} />;
              })}
            </Slider>
          </div>
        </div>
        <div className="btn_wrap">
          {/* 웹전환: for all user */}
          <button
            className="all_view_btn"
            onClick={() => moveToQnaList(SearchSortType.POPULAR)}
            aria-label={"Qna Main popular qna list qna button"}
          >
            <p className="all_view_btn_text">
              {t(`screen.qna.button.all_qna`)}
            </p>
          </button>
        </div>
      </div>
    );
  };

  // 화제의 A/B
  const renderAB = () => {
    return (
      <div className="wrap ab">
        <div className="horizontal_list_title">
          <div className="title_background" />
          <p className="title">{t(`screen.ab.label.activePopular`)}</p>
        </div>
        <HorizontalAbList
          abs={activePopularAbs}
          actSectionCode={ActSectionCode.POPULAR_AB}
          onVoteSuccess={handleVotePopularAbSuccess}
        />
        <div className="btn_wrap">
          {/* 웹전환: for all user */}
          <button className="all_view_btn" onClick={() => moveToAbList()}>
            <p className="all_view_btn_text">{t(`screen.qna.button.all_ab`)}</p>
          </button>
        </div>
      </div>
    );
  };

  // 최신 Q&A
  const renderRecentlyQna = () => {
    return (
      <div className="wrap last">
        <div className="horizontal_list_title">
          <div className="title_background" />
          <p className="title">{t(`screen.qna.label.recent_qna`)}</p>
        </div>
        <div className="qna_list">
          {recentlyQnaList.map((qna, index) => {
            return <QnaListItem qna={qna} index={index} key={index} />;
          })}
        </div>
        <div className="btn_wrap last">
          {/* 웹전환: for all user */}
          <button
            className="all_view_btn"
            onClick={() => moveToQnaList(SearchSortType.LATEST)}
          >
            <p className="all_view_btn_text">
              {t(`screen.qna.button.all_qna`)}
            </p>
          </button>
        </div>
      </div>
    );
  };

  const handleRefresh = async () => {
    memberUuid && (await initList(memberUuid));
    setRefreshing(false);
  };

  return (
    <div id="QnaMain" style={{ paddingBottom: isPlatformIOSWEB ? 20 : 0 }}>
      <div className="on_layout_width">
        <div style={{ flex: 1 }} />
      </div>
      <div className="contents">
        <div className="qna_home_title_wrap">
          <p className="qna_home_title_text">{t(`screen.qna.title.qna`)}</p>
          {/* for all user */}
          <button
            className="qna_home_header_ab_list_btn"
            onClick={() => moveToAbList()}
            aria-label={"Qna Main move ab list"}
          >
            <p className="qna_home_header_ab_list_btn_text">
              {t(`screen.qna.button.list_ab`)}
            </p>
            <ArrowRightIcon />
          </button>
        </div>
        <p className="qna_home_header_info_text">
          {t(`screen.qna.title.sub_title`)}
        </p>
        {/* 관심QnA, 로그인 한 사람한테만 */}
        {sessionUser &&
          interestingQnaList?.length > 0 &&
          renderInterestingQna()}
        <QnaWriteButtons />
        {/* 인기QnA */}
        {!loading && popularQnaList?.length > 0 && renderPopularQna()}
        {/* 화제AB */}
        {!loading && activePopularAbs?.length > 0 && renderAB()}
        {/* 최신QnA */}
        {!loading && recentlyQnaList?.length > 0 && renderRecentlyQna()}
      </div>
      {/* <TopButton showTopButton={showTopIcon} ref={ref} /> */}
    </div>
  );
});

export default QnaMain;
