import { memo, useCallback, useEffect, useState } from "react";
import {
  Arranges,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
  Colors,
} from "../../../assets/styles";
import { Styles } from "../../../assets/types/Style";
import MyAppliancesStore, {
  MyAppliancesList,
} from "../../../store/MyAppliancesStore";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";

import { useTranslation } from "react-i18next";

import { ReactComponent as CheckOnIcon } from "../../../assets/icons/checkbox_big_on.svg";
import { ReactComponent as CheckOffIcon } from "../../../assets/icons/checkbox_big_off.svg";
import { ReactComponent as RadioCheckedIcon } from "../../../assets/icons/radio_checked_button.svg";
import { ReactComponent as MyAppliancePlusIcon } from "../../../assets/icons/my_appliance_plus_icon.svg";
import { ReactComponent as MoveToPostIcon } from "../../../assets/icons/move_to_post_icon.svg";
import { ReactComponent as UpTag } from "../../../assets/icons/up_product_label.svg";

import { getDateYYMMDD } from "../../../utils/datetime";
import AuthStore from "../../../store/AuthStore";
import { font_weight_normal } from "../../../assets/styles/texts";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import RemoteConfigStore from "../../../store/RemoteConfigStore";

export const MyAppliancesItem = memo(
  ({
    item,
    index,
    mode,
    isRadioSelected,
  }: {
    item: MyAppliancesList;
    index: number;
    mode: string;
    isRadioSelected: boolean;
  }) => {
    const { t } = useTranslation();
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    // const [checked, setChecked] = useState(false);

    const getCategoryName = (code: string) => {
      const result = MyAppliancesStore.categoryList?.find((x) => x.codeValue == code);
      return result ? result.codeName : "";
    };

    // useEffect(() => {
    //   setChecked(false);
    // }, [item.productId, item.tempProductId, mode]);

    const isMyProduct = () => {
      return (
        !MyAppliancesStore.targetMemberUuid || AuthStore.sessionUser?.memberUuid === MyAppliancesStore.targetMemberUuid
      );
    };

    const isViewMode = () => {
      return mode === "" || mode === "none";
    };

    const isEditMode = () => {
      return mode === "edit";
    };

    const isDelMode = () => {
      return mode === "del";
    };

    const checkIcon = useCallback(() => {
      if (isRadioSelected) {
        return <CheckOnIcon />;
      } else {
        return <CheckOffIcon />;
      }
    }, [isRadioSelected]);

    const emptyImage = () => {
      let view = <></>;

      if (isMyProduct() && item.feedId) {
        view = (
            <div style={{minWidth: 60, position: 'absolute', top: '44px'}}>
              <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: "center",
                  backgroundColor: "rgba(190, 105, 195, 0.7)", 
                  width: 60, 
                  height: 16, 
                  borderBottomLeftRadius: '6px', 
                  borderBottomRightRadius: '6px',
                  borderWidth: '1px',
                  borderColor: 'rgba(34, 34, 34, 0.1)',
                  borderStyle: 'solid'
                  }}
                >
                <p style={styles.img_post_txt}>{"포스트"}</p>
              </div>
            </div>
        );
      }

      if (item.productFlag) {
        return <>{view}</>;
      }

      return (
          <div style={styles.empty_img}>
            <p style={styles.empty_txt}>{"등록\n대기중"}</p>
          </div>
      );
    };

    const PlusButton = () => {
      return (
        <>
          <div
            style={styles.product_option_button}
            onClick={() => {
              const tagList: string[] = [];
              item?.brandName && tagList.push(item?.brandName?.replace(/ /g, "").toLowerCase());
              item?.modelName && tagList.push(item?.modelName?.replace(/ /g, "").toLowerCase());
              item?.productName && tagList.push(item?.productName?.replace(/ /g, "").toLowerCase());

              goTo("/post/write", {
                state: {
                  tags: tagList,
                  productInfo: {
                    productId: item.productId,
                    tempProductId: item.tempProductId,
                    productName: item.productName,
                    productModelName: item.modelName,
                    thumbnailFilePath: item.imageUrl,
                    originalFilePath: item.imageUrl,
                    brandName: item.brandName,
                    manufacturerName: item.modelName,
                    myProductFlag: item.productId ? item.productId : item.tempProductId,
                  },
                },
              });
            }}
          >
            <MyAppliancePlusIcon />
          </div>
        </>
      );
    };

    const MoveToPostButton = () => {
      return (
        <>
          <div
            style={styles.product_option_button}
            onClick={() => {
              goTo(`/posts/feed/${item.feedId}`);
            }}
          >
            <MoveToPostIcon />
          </div>
        </>
      );
    };

    const onClick = () => {
      if (isViewMode() && item.productFlag) {
        goTo(`/Product/${item.productFlag ? item.productId : item.tempProductId}`);
        return;
      }

      if (isDelMode()) {
        MyAppliancesStore.selectProduct(item);
        // setChecked(!checked);
        return;
      }
      if (isEditMode()) {
        const productId = item.productFlag ? item.productId : item.tempProductId;
        const productFlag = item.productFlag ?? false;

        MyAppliancesStore.setEditInfo(item.idx, productId ?? 0, productFlag);
      }
    };

    const MyProductItem = () => {
      return (
        <div key={index} id="my_appliance_item" style={{...styles.product, ...(index !== 0 ? {paddingTop: 20} : {})}}>
          <div
            style={styles.product_item}
            // aria-label={`Product result ${index}`}
            onClick={onClick}
          >
            {isDelMode() && (<div style={styles.product_check_btn}>{checkIcon()}</div>)}

            {isEditMode() && (
              <div style={isRadioSelected ? styles.radio_checked_btn : styles.radio_btn}>
                {isRadioSelected && <RadioCheckedIcon />}
              </div>
            )}

            <div
              style={{
                ...FlexBoxs.flex,
                flexDirection: "column",
                ...Spacings.margin_right_12,
                alignItems: "center",
                paddingLeft: '20px',
                position: 'relative'
              }}
            >
              <FastImageWithFallback
                // aria-label="Product image"
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${item.imageUrl || ""}`,
                }}
                style={styles.product_image}
              />
              {emptyImage()}
              <p
                style={{
                  fontSize: 12,
                  color: "#999999",
                  letterSpacing: -0.48,
                }}
              >
                {getDateYYMMDD(item.createDatetime ?? "")}
              </p>
            </div>
            <div style={styles.product_info}>
              {item.categoryCode && (
                <>
                  <p style={{...styles.product_text1, color: "#ab5eaf" }}>
                    {getCategoryName(item.categoryCode || "")}
                  </p>
                </>
              )}
              <p style={{...styles.product_text1, ...(item.productFlag ? {} : styles.product_text1_inactive)}}
                // aria-label="Product name"
              >
                {item.productName && item.productName.length > 27
                  ? item.productName.substring(0, 27 - 3) + "..."
                  : item.productName}
              </p>
              <div style={styles.product_info_bottom}>
                <p style={{...styles.product_text2, ...(item.productFlag ? {} : { color: "#c5c5c5", ...font_weight_normal }), flexShrink: 0}}
                  // aria-label="Brand name"
                >
                  {item.brandName}
                </p>
                <p
                  style={{...styles.product_text3, ...(item.productFlag ? {} : { color: "#c5c5c5", ...font_weight_normal })}}
                >
                  |
                </p>
                <p style={{...styles.product_text2, ...(item.productFlag ? {} : { color: "#c5c5c5", ...font_weight_normal })}}
                  // aria-label="Product model name"
                >
                  {item.modelName}
                </p>
              </div>
              {RemoteConfigStore.show_up_product_tag === "true" && item.upYn && item.upYn == "Y" && (
                  <div style={styles.up_tag_wrap}>
                    <UpTag />
                  </div>
              )}
            </div>
          </div>

          <div style={styles.product_option_button_wrap}>
            {isViewMode() && isMyProduct() && !item.feedId && <PlusButton />}
            {isViewMode() && isMyProduct() && item.feedId && <MoveToPostButton />}
          </div>
        </div>
      );
    };

    return (
      <>
        <MyProductItem />
      </>
    );
  }
);

MyAppliancesItem.displayName = "MyAppliancesItem";

const styles: Styles = {
  wrap: {
    ...FlexBoxs.flex_1,
  },
  header_tab: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    height: 56,
  },
  tab: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    // paddingLeft: responsiveWidth(3),
    // paddingRight: responsiveWidth(3),
  },
  tab_text: {
    ...Texts.contents_text_38,
  },
  tab_inactive: {
    ...Colors.border_color_eee,
  },
  tab_inactive_text: {
    ...Colors.font_999,
  },
  tab_active: {
    ...Colors.border_color_222,
  },
  tab_active_text: {
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  wrap_list: {
    ...FlexBoxs.flex_1,
    marginBottom: -10,
  },
  header: {
    ...Arranges.between,
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...FlexBoxs.horizon,
    ...Spacings.padding_left_16,
    ...Spacings.padding_right_16,
    height: 64,
  },
  header_left: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  header_text_wrap: {
    ...Spacings.padding_left_4,
  },
  header_text1: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_bold,
    ...Colors.font_222,
  },
  header_text2: {
    ...Colors.font_a760b1,
  },
  header_btn: {
    ...Spacings.padding_top_bottom_8,
    ...Spacings.padding_right_left_12,
    ...Spacings.border_radius_100,
    ...Colors.background_f6f4fa,
  },
  header_btn_text: {
    ...Texts.btn_text_1,
    ...Colors.font_a760b1,
  },
  header_check_btn: {
    ...Spacings.margin_right_4,
    ...Spacings.margin_left_2,
  },

  empty: {
    ...Spacings.margin_top_8,
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  empty_image: {
    // width: responsiveScreenWidth(95),
    width: '95%'
  },
  empty_view: {
    ...Positions.absolute,
    ...Positions.left,
    ...Positions.right,
    // top: responsiveScreenHeight(21.9),
    top: '21.9',
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  // footer: {
  //   // ...FlexBoxs.horizon,
  //   // bottom: responsiveScreenHeight(0),
  //   // bottom:insets.bottom,
  // },
  footer_btn: {
    ...Colors.background_222,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  footer_btn_text: {
    ...Texts.btn_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  product: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    borderStyle: 'solid',
    borderBottomWidth: 0.5,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: "#eeeeee",
    paddingBottom: 20,
  },
  product_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    overflow: "hidden",
    alignItems: "center"
  },
  product_image: {
    width: 60,
    height: 60,
    borderWidth: 1,
    borderColor: "rgba(34, 34, 34, 0.1)",
    borderStyle: 'solid',
    ...Spacings.border_radius_6,
  },
  temp_product_image: {
    width: 60,
    height: 60,
    borderWidth: 1,
    borderColor: "rgba(34, 34, 34, 0.1)",
    borderStyle: 'solid',
    ...Spacings.border_radius_6,
    ...Spacings.margin_right_12,
    opacity: 0.5,
  },
  product_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v,
    overflow: "hidden",
    marginRight: 6,
    // width: responsiveScreenWidth(60),
    // width: '60%'
  },
  product_text1: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    ...Spacings.margin_bottom_4,
    overflow: 'hidden', 
    whiteSpace: 'nowrap', 
    textOverflow: 'ellipsis', 
    maxWidth: '100%'
  },
  product_text1_inactive: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    ...Spacings.margin_bottom_4,
    color: "#999999",
  },
  product_info_bottom: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.end_h,
  },
  up_tag_wrap: {
    ...Spacings.margin_top_6,
  },
  product_text2: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
    ...Texts.font_weight_500,
    overflow: 'hidden', 
    whiteSpace: 'nowrap', 
    textOverflow: 'ellipsis', 
    maxWidth: '100%'
  },
  product_text3: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
    ...Spacings.margin_right_left_6,
  },
  product_check_btn: {
    // ...Spacings.margin_right_14,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  span_txt: {
    width: 60,
    height: 20,
    position: "absolute",
    textAlign: "center",
    marginVertical: 22,
    color: "#fff",
    fontSize: 14,
    fontWeight: "bold",
    letterSpacing: 0.56,
  },
  product_image_wrap: {
    position: "relative",
    width: 60,
    height: 60,
    ...Spacings.border_radius_6,
    ...Spacings.margin_right_12,
    backgroundColor: "rgba(22, 22, 22, 0.5)",
  },
  product_option_button_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    flexShrink: 0,
  },
  product_option_button: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  txt_active: {
    ...Colors.font_fff,
  },
  txt_in_active: {
    ...Colors.font_999,
  },
  in_active: {
    ...Colors.background_eeeeee,
    ...Colors.border_color_d9d9d9,
  },
  active: {
    ...Colors.background_222,
  },
  footer: {
    ...FlexBoxs.horizon,
    ...Positions.bottom,
  },
  btn_1: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
  },

  radio_btn: {
    flexBasis: 24,
    minWidth: 24,
    width: 24,
    height: 24,
    borderRadius: 15,
    borderWidth: 1,
    borderColor: "#c4c4c4",
    borderStyle: 'solid',
  },
  radio_checked_btn: {
    marginRight: 10,
  },
  img_post_txt: {
    fontSize: 10,
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: -0.4,
    textAlign: "center",
    color: "#ffffff",
  },
  empty_img: {
    // ...Positions.fullscreen,
    ...Positions.absolute,
    ...Positions.top,
    ...Positions.bottom,
    ...Spacings.border_radius_6,
    ...FlexBoxs.flex,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(34, 34, 34, 0.5)",
    minWidth: 60
  },
  empty_txt: {
    fontSize: 14,
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: -0.56,
    textAlign: "center",
    color: "#ffffff",
  },
};
