// import { getStatusBarHeight } from "react-native-status-bar-height";
// import { responsiveHeight, responsiveWidth } from "react-native-responsive-dimensions";
// const StatusBarHeight = getStatusBarHeight();

export const auto_0 = {
  marginTop: 0,
  marginBottom: 0,
  marginLeft: "auto",
  marginRight: "auto",
};

export const margin_0 = {
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  marginBottom: 0,
};

export const margin_h_default = {
  marginLeft: 20,
  marginRight: 20,
};

export const margin_top_minus10 = {
  marginTop: -10,
};

export const margin_top_2 = {
  marginTop: 2,
};

export const margin_top_4 = {
  marginTop: 4,
};

export const margin_top_5 = {
  marginTop: 5,
};

export const margin_top_6 = {
  marginTop: 6,
};

export const margin_top_7 = {
  marginTop: 7,
};

export const margin_top_8 = {
  marginTop: 8,
};

export const margin_top_9 = {
  marginTop: 9,
};

export const margin_top_10 = {
  marginTop: 10,
};

export const margin_top_12 = {
  marginTop: 10,
};

export const margin_top_13 = {
  marginTop: 13,
};

export const margin_top_14 = {
  marginTop: 14,
};

export const margin_top_16 = {
  marginTop: 16,
};

export const margin_top_17 = {
  marginTop: 17,
};

export const margin_top_18 = {
  marginTop: 18,
};

export const margin_top_19 = {
  marginTop: 19,
};

export const margin_top_20 = {
  marginTop: 20,
};

export const margin_top_21 = {
  marginTop: 21,
};

export const margin_top_22 = {
  marginTop: 22,
};

export const margin_top_23 = {
  marginTop: 23,
};

export const margin_top_24 = {
  marginTop: 24,
};

export const margin_top_25 = {
  marginTop: 25,
};

export const margin_top_29 = {
  marginTop: 29,
};

export const margin_top_30 = {
  marginTop: 30,
};

export const margin_top_32 = {
  marginTop: 32,
};

export const margin_top_36 = {
  marginTop: 36,
};

export const margin_top_40 = {
  marginTop: 40,
};

export const margin_top_43 = {
  marginTop: 43,
};

export const margin_top_48 = {
  marginTop: 48,
};

export const margin_top_45 = {
  // marginTop: 45 + StatusBarHeight,
};

export const margin_top_50 = {
  marginTop: 50,
};

export const margin_top_60 = {
  marginTop: 60,
};

export const margin_bottom_statusbar = {
  // marginBottom: StatusBarHeight,
};

export const margin_bottom_4 = {
  marginBottom: 4,
};

export const margin_top_bottom_13 = {
  marginTop: 13,
  marginBottom: 13,
};

export const margin_top_bottom_14 = {
  marginTop: 14,
  marginBottom: 14,
};

export const margin_top_bottom_20 = {
  marginTop: 20,
  marginBottom: 20,
};

export const margin_top_bottom_24 = {
  marginTop: 24,
  marginBottom: 24,
};

export const margin_bottom_0 = {
  marginBottom: 0,
};

export const margin_bottom_2 = {
  marginBottom: 2,
};

export const margin_bottom_3 = {
  marginBottom: 3,
};

export const margin_bottom_24 = {
  marginBottom: 24,
};

export const margin_bottom_32 = {
  marginBottom: 32,
};

export const margin_bottom_35 = {
  marginBottom: 35,
};

export const margin_bottom_6 = {
  marginBottom: 6,
};

export const margin_bottom_7 = {
  marginBottom: 7,
};

export const margin_bottom_8 = {
  marginBottom: 8,
};

export const margin_bottom_11 = {
  marginBottom: 11,
};

export const margin_bottom_9 = {
  marginBottom: 9,
};

export const margin_bottom_10 = {
  marginBottom: 10,
};

export const margin_bottom_12 = {
  marginBottom: 12,
};

export const margin_bottom_14 = {
  marginBottom: 14,
};

export const margin_bottom_15 = {
  marginBottom: 15,
};

export const margin_bottom_17 = {
  marginBottom: 17,
};

export const margin_bottom_20 = {
  // marginBottom: `${(20 / windowHeight) * 100}%`
  marginBottom: 20,
};

export const margin_bottom_23 = {
  marginBottom: 23,
};

export const margin_bottom_26 = {
  marginBottom: 26,
};

export const margin_bottom_28 = {
  marginBottom: 28,
};

export const margin_bottom_30 = {
  marginBottom: 30,
};

export const margin_bottom_30_responsive = {
  // marginBottom: responsiveHeight(3.55),
};

export const margin_bottom_40 = {
  marginBottom: 40,
};

export const margin_bottom_48 = {
  marginBottom: 48,
};

export const margin_bottom_50 = {
  marginBottom: 50,
};

export const margin_bottom_56 = {
  marginBottom: 56,
};

export const margin_bottom_110 = {
  marginBottom: 110,
};

export const margin_right_8_responsive = {
  // marginRight: responsiveWidth(2),
};

export const margin_right_0 = {
  marginRight: 0,
};

export const margin_right_2 = {
  marginRight: 2,
};

export const margin_right_4 = {
  marginRight: 4,
};

export const margin_right_5 = {
  marginRight: 5,
};

export const margin_right_6 = {
  marginRight: 6,
};

export const margin_right_7 = {
  marginRight: 7,
};

export const margin_right_8 = {
  marginRight: 8,
};

export const margin_right_10 = {
  marginRight: 10,
};

export const margin_right_12 = {
  marginRight: 12,
};

export const margin_right_14 = {
  marginRight: 14,
};

export const margin_right_16 = {
  marginRight: 16,
};

export const margin_right_18 = {
  marginRight: 18,
};

export const margin_right_20 = {
  marginRight: 20,
};

export const margin_right_24 = {
  marginRight: 24,
};

export const margin_right_28 = {
  marginRight: 28,
};

export const margin_right_30 = {
  marginRight: 30,
};

export const margin_right_60 = {
  marginRight: 60,
};

export const margin_right_6_responsive = {
  // marginRight: responsiveWidth(1.53),
};

export const margin_right_left_3 = {
  marginRight: 2,
  marginLeft: 2,
};

export const margin_right_left_5 = {
  marginRight: 5,
  marginLeft: 5,
};

export const margin_right_left_6 = {
  marginRight: 6,
  marginLeft: 6,
};

export const margin_right_left_9 = {
  marginRight: 9,
  marginLeft: 9,
};

export const margin_left_0 = {
  marginLeft: 0,
};

export const margin_left_3 = {
  marginLeft: 3,
};

export const margin_left_4 = {
  marginLeft: 4,
};

export const margin_left_8 = {
  marginLeft: 8,
};

export const margin_left_9 = {
  marginLeft: 9,
};

export const margin_left_10 = {
  marginLeft: 10,
};

export const margin_left_12 = {
  marginLeft: 12,
};

export const margin_left_2 = {
  marginLeft: 2,
};

export const margin_left_13 = {
  marginLeft: 13,
};

export const margin_top_15 = {
  marginTop: 15,
};

export const margin_left_16 = {
  marginLeft: 16,
};

export const margin_left_16_responsive = {
  // marginLeft: responsiveWidth(4.46),
};

export const margin_left_20_responsive = {
  // marginLeft: responsiveWidth(5.1),
};

export const margin_left_right_30_responsive = {
  // marginLeft: responsiveWidth(7.69),
  // marginRight: responsiveWidth(7.69),
};

export const margin_left_24 = {
  marginLeft: 24,
};

export const margin_left_30 = {
  marginLeft: 30,
};

export const margin_left_32 = {
  marginLeft: 32,
};

export const margin_top_bottom_10 = {
  marginTop: 10,
  marginBottom: 10,
};

export const margin_left_6 = {
  marginLeft: 6,
};

export const margin_left_20 = {
  marginLeft: 20,
};

export const margin_right_bottom_6 = {
  marginRight: 6,
  marginBottom: 6,
};

export const margin_right_bottom_8 = {
  marginRight: 8,
  marginBottom: 8,
};

export const margin_right_left_20 = {
  marginRight: 20,
  marginLeft: 20,
};

export const margin_left_right_10 = {
  marginRight: 10,
  marginLeft: 10,
};

export const margin_left_right_13 = {
  marginRight: 13,
  marginLeft: 13,
};

export const margin_left_right_7 = {
  marginRight: 7,
  marginLeft: 7,
};

export const margin_left_right_16 = {
  marginRight: 16,
  marginLeft: 16,
};

export const margin_bottom_60 = {
  marginBottom: 60,
};

export const margin_bottom_13 = {
  marginBottom: 13,
};

export const margin_bottom_16 = {
  marginBottom: 16,
};

export const margin_bottom_18 = {
  marginBottom: 18,
};

export const margin_bottom_25 = {
  marginBottom: 25,
};

export const margin_top_bottom_40 = {
  marginTop: 40,
  marginBottom: 40,
};

export const margin_right_bottom_left_0 = {
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,
};

export const margin_left_right_16_responsive = {
  // marginLeft: responsiveWidth(4.46),
  // marginRight: responsiveWidth(4.46),
};

export const margin_left_right_4_responsive = {
  // marginLeft: responsiveWidth(1.02),
  // marginRight: responsiveWidth(1.02),
};

export const border_bottom_left_radius_6 = {
  borderBottomLeftRadius: 6,
};

export const padding_h_default = {
  paddingLeft: 20,
  paddingRight: 20,
};

export const padding_6 = {
  paddingLeft: 6,
  paddingRight: 6,
  paddingTop: 6,
  paddingBottom: 6,
};

export const padding_10 = {
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 10,
  paddingBottom: 10,
};

export const padding_20 = {
  paddingLeft: 20,
  paddingRight: 20,
  paddingTop: 20,
  paddingBottom: 20,
};

export const padding_18 = {
  paddingLeft: 18,
  paddingRight: 18,
  paddingTop: 18,
  paddingBottom: 18,
};

export const padding_top_0 = {
  paddingTop: 0,
};

export const padding_top_2 = {
  paddingTop: 2,
};

export const padding_top_6 = {
  paddingTop: 6,
};

export const padding_top_7 = {
  paddingTop: 7,
};

export const padding_top_8 = {
  paddingTop: 8,
};

export const padding_top_9 = {
  paddingTop: 9,
};

export const padding_top_10 = {
  paddingTop: 10,
};

export const padding_top_12 = {
  paddingTop: 12,
};

export const padding_top_14 = {
  paddingTop: 14,
};

export const padding_left_right_32_responsive = {
  // paddingLeft: responsiveWidth(8.2),
  // paddingRight: responsiveWidth(8.2),
};

export const padding_top_16 = {
  paddingTop: 16,
};

export const padding_top_20 = {
  paddingTop: 20,
};

export const padding_top_22 = {
  paddingTop: 22,
};

export const padding_top_24 = {
  paddingTop: 24,
};

export const padding_top_25 = {
  paddingTop: 25,
};

export const padding_top_26 = {
  paddingTop: 26,
};

export const padding_top_30 = {
  paddingTop: 30,
};

export const padding_top_32 = {
  paddingTop: 32,
};

export const padding_top_50 = {
  paddingTop: 50,
};

export const padding_top_60 = {
  paddingTop: 60,
};

export const padding_top_88 = {
  paddingTop: 88,
};

export const padding_top_90 = {
  paddingTop: 90,
};

export const padding_right_0 = {
  paddingRight: 0,
};

export const padding_right_5 = {
  paddingRight: 5,
};

export const padding_right_6 = {
  paddingRight: 6,
};

export const padding_right_8 = {
  paddingRight: 8,
};

export const padding_right_9 = {
  paddingRight: 9,
};

export const padding_right_10 = {
  paddingRight: 10,
};

export const padding_right_12 = {
  paddingRight: 12,
};

export const padding_right_14 = {
  paddingRight: 14,
};

export const padding_right_16 = {
  paddingRight: 16,
};

export const padding_right_16_responsive = {
  // paddingRight: responsiveWidth(4.1),
};

export const padding_right_17 = {
  paddingRight: 17,
};

export const padding_right_18 = {
  paddingRight: 18,
};

export const padding_right_18_responsive = {
  // paddingRight: responsiveWidth(4.6),
};

export const padding_left_18_responsive = {
  // paddingLeft: responsiveWidth(4.6),
};

export const padding_right_20 = {
  paddingRight: 20,
};

export const padding_right_20_responsive = {
  // paddingRight: responsiveWidth(5.1),
};

export const padding_right_25_responsive = {
  // paddingRight: responsiveWidth(6.4),
};

export const padding_right_28_responsive = {
  // paddingRight: responsiveWidth(7.1),
};

export const padding_right_30 = {
  paddingRight: 30,
};

export const padding_right_37_responsive = {
  // paddingRight: responsiveWidth(9.4),
};

export const padding_right_37 = {
  paddingRight: 37,
};

export const padding_right_50 = {
  paddingRight: 50,
};

export const padding_right_145 = {
  paddingRight: 145,
};

export const padding_bottom_6 = {
  paddingBottom: 6,
};

export const padding_bottom_7 = {
  paddingBottom: 7,
};

export const padding_bottom_8 = {
  paddingBottom: 8,
};

export const padding_bottom_10 = {
  paddingBottom: 10,
};

export const padding_bottom_12 = {
  paddingBottom: 12,
};

export const padding_bottom_13 = {
  paddingBottom: 13,
};

export const padding_bottom_14 = {
  paddingBottom: 14,
};

export const padding_bottom_16 = {
  paddingBottom: 16,
};

export const padding_bottom_17 = {
  paddingBottom: 17,
};

export const padding_bottom_20 = {
  paddingBottom: 20,
};

export const padding_bottom_25 = {
  paddingBottom: 25,
};

export const padding_top_bottom_14 = {
  paddingTop: 14,
  paddingBottom: 14,
};

export const padding_top_40 = {
  paddingTop: 40,
};

export const padding_bottom_30 = {
  paddingBottom: 30,
};

export const padding_bottom_40 = {
  paddingBottom: 40,
};

export const padding_bottom_48 = {
  paddingBottom: 48,
};

export const padding_bottom_80 = {
  paddingBottom: 80,
};

export const padding_bottom_100 = {
  paddingBottom: 100,
};

export const padding_bottom_150 = {
  paddingBottom: 150,
};

export const padding_left_0 = {
  paddingLeft: 0,
};

export const padding_left_1 = {
  paddingLeft: 1,
};

export const padding_left_4 = {
  paddingLeft: 4,
};

export const padding_left_6 = {
  paddingLeft: 6,
};

export const padding_left_7 = {
  paddingLeft: 7,
};

export const padding_left_8 = {
  paddingLeft: 8,
};

export const padding_left_16_responsive = {
  // paddingLeft: responsiveWidth(4.1),
};

export const padding_left_10 = {
  paddingLeft: 10,
};

export const padding_left_16 = {
  paddingLeft: 16,
};

export const padding_left_20 = {
  paddingLeft: 20,
};

export const padding_left_22 = {
  paddingLeft: 22,
};

export const padding_left_20_responsive = {
  // paddingLeft: responsiveWidth(5.1),
};

export const padding_left_23 = {
  paddingLeft: 23,
};

export const padding_left_24 = {
  paddingLeft: 24,
};

export const padding_left_26 = {
  paddingLeft: 26,
};

export const padding_left_26_responsive = {
  // paddingLeft: responsiveWidth(6.6),
};

export const padding_left_30 = {
  paddingLeft: 30,
};

export const padding_left_32 = {
  paddingLeft: 32,
};

export const padding_bottom_21 = {
  paddingBottom: 21,
};

export const padding_bottom_24 = {
  paddingBottom: 24,
};

export const padding_left_40 = {
  paddingLeft: 40,
};

export const padding_left_56 = {
  paddingLeft: 56,
};

export const padding_left_62 = {
  paddingLeft: 62,
};

export const padding_bottom_26 = {
  paddingBottom: 26,
};

export const padding_bottom_36 = {
  paddingBottom: 36,
};

export const padding_left_right_6 = {
  paddingLeft: 6,
  paddingRight: 6,
};

export const padding_left_right_8 = {
  paddingLeft: 8,
  paddingRight: 8,
};

export const padding_left_right_4 = {
  paddingLeft: 4,
  paddingRight: 4,
};

export const padding_left_right_6_responsive = {
  // paddingLeft: responsiveWidth(1.5),
  // paddingRight: responsiveWidth(1.5),
};

export const padding_left_right_14 = {
  paddingLeft: 14,
  paddingRight: 14,
};

export const padding_bottom_88 = {
  paddingBottom: 88,
};

export const padding_left_right_14_responsive = {
  // paddingLeft: responsiveWidth(3.5),
  // paddingRight: responsiveWidth(3.5),
};

export const padding_left_right_16 = {
  paddingLeft: 16,
  paddingRight: 16,
};

export const padding_left_right_20 = {
  paddingLeft: 20,
  paddingRight: 20,
};

export const padding_left_right_20_responsive = {
  // paddingLeft: responsiveWidth(5.1),
  // paddingRight: responsiveWidth(5.1),
};

export const padding_left_right_16_responsive = {
  // paddingLeft: responsiveWidth(4.46),
  // paddingRight: responsiveWidth(4.46),
};

export const padding_left_right_30_responsive = {
  // paddingLeft: responsiveWidth(7.6),
  // paddingRight: responsiveWidth(7.6),
};

export const padding_right_left_4 = {
  paddingRight: 4,
  paddingLeft: 4,
};

export const padding_bottom_19 = {
  paddingBottom: 19,
};

export const padding_bottom_50 = {
  paddingBottom: 50,
};

export const padding_right_left_8 = {
  paddingRight: 8,
  paddingLeft: 8,
};

export const padding_right_left_8_responsive = {
  // paddingRight: responsiveWidth(2.05),
  // paddingLeft: responsiveWidth(2.05),
};

export const padding_right_left_10 = {
  paddingRight: 10,
  paddingLeft: 10,
};

export const padding_right_left_10_responsive = {
  // paddingRight: responsiveWidth(2.5),
  // paddingLeft: responsiveWidth(2.5),
};

export const padding_right_left_12 = {
  paddingRight: 12,
  paddingLeft: 12,
};

export const padding_right_left_12_responsive = {
  // paddingRight: responsiveWidth(3.07),
  // paddingLeft: responsiveWidth(3.07),
};

export const padding_top_bottom_13 = {
  paddingTop: 13,
  paddingBottom: 13,
};

export const padding_right_left_14 = {
  paddingRight: 14,
  paddingLeft: 14,
};

export const padding_right_left_14_responsive = {
  // paddingRight: responsiveWidth(3.58),
  // paddingLeft: responsiveWidth(3.58),
};

export const padding_right_left_16 = {
  paddingRight: 16,
  paddingLeft: 16,
};

export const padding_right_left_24 = {
  paddingRight: 24,
  paddingLeft: 24,
};

export const padding_right_left_16_responsive = {
  // paddingRight: responsiveWidth(4.1),
  // paddingLeft: responsiveWidth(4.1),
};

export const padding_right_left_4_responsive = {
  // paddingRight: responsiveWidth(1.02),
  // paddingLeft: responsiveWidth(1.02),
};

export const padding_right_left_18 = {
  paddingRight: 18,
  paddingLeft: 18,
};

export const padding_right_left_18_responsive = {
  // paddingRight: responsiveWidth(4.61),
  // paddingLeft: responsiveWidth(4.61),
};

export const padding_right_left_19 = {
  paddingRight: 19,
  paddingLeft: 19,
};

export const padding_right_left_19_responsive = {
  // paddingRight: responsiveWidth(4.87),
  // paddingLeft: responsiveWidth(4.87),
};

export const padding_right_left_20 = {
  paddingRight: 20,
  paddingLeft: 20,
};

export const padding_right_left_20_responsive = {
  // paddingRight: responsiveWidth(5.1),
  // paddingLeft: responsiveWidth(5.1),
};

export const padding_right_left_21 = {
  paddingRight: 21,
  paddingLeft: 21,
};

export const padding_right_left_30 = {
  paddingRight: 30,
  paddingLeft: 30,
};

export const padding_right_left_30_responsive = {
  // paddingRight: responsiveWidth(7.7),
  // paddingLeft: responsiveWidth(7.7),
};

export const padding_right_left_40 = {
  paddingRight: 40,
  paddingLeft: 40,
};

export const padding_right_left_40_responsive = {
  // paddingRight: responsiveWidth(10.2),
  // paddingLeft: responsiveWidth(10.2),
};

export const margin_right_left_40_responsive = {
  // marginRight: responsiveWidth(10.2),
  // marginLeft: responsiveWidth(10.2),
};

export const padding_top_bottom_24 = {
  paddingTop: 24,
  paddingBottom: 24,
};

export const padding_top_bottom_3 = {
  paddingTop: 3,
  paddingBottom: 3,
};

export const padding_top_bottom_4 = {
  paddingTop: 4,
  paddingBottom: 4,
};

export const padding_top_bottom_5 = {
  paddingTop: 5,
  paddingBottom: 5,
};

export const padding_top_bottom_6 = {
  paddingTop: 6,
  paddingBottom: 6,
};

export const padding_top_bottom_32 = {
  paddingTop: 32,
  paddingBottom: 32,
};

export const padding_left_right_30 = {
  paddingLeft: 30,
  paddingRight: 30,
};

export const padding_top_bottom_8 = {
  paddingTop: 8,
  paddingBottom: 8,
};

export const padding_top_bottom_10 = {
  paddingTop: 10,
  paddingBottom: 10,
};

export const padding_top_bottom_11 = {
  paddingTop: 11,
  paddingBottom: 11,
};

export const padding_top_bottom_12 = {
  paddingTop: 12,
  paddingBottom: 12,
};

export const padding_top_bottom_15 = {
  paddingTop: 15,
  paddingBottom: 15,
};

export const padding_top_bottom_16 = {
  paddingTop: 16,
  paddingBottom: 16,
};

export const padding_top_bottom_17 = {
  paddingTop: 17,
  paddingBottom: 17,
};

export const padding_top_bottom_20 = {
  paddingTop: 20,
  paddingBottom: 20,
};

export const padding_top_bottom_30 = {
  paddingTop: 30,
  paddingBottom: 30,
};

export const padding_top_bottom_40 = {
  paddingTop: 40,
  paddingBottom: 40,
};

export const padding_left_top_bottom_20 = {
  paddingTop: 20,
  paddingRight: 20,
  paddingLeft: 20,
};

export const padding_bottom_32 = {
  paddingBottom: 32,
};

export const border_radius_4 = {
  borderRadius: 4,
};

export const border_radius_5 = {
  borderRadius: 5,
};

export const border_radius_6 = {
  borderRadius: 6,
};

export const border_radius_8 = {
  borderRadius: 8,
};

export const border_radius_10 = {
  borderRadius: 10,
};

export const border_radius_13 = {
  borderRadius: 13,
};

export const border_radius_12 = {
  borderRadius: 12,
};

export const border_radius_15 = {
  borderRadius: 15,
};

export const border_radius_21 = {
  borderRadius: 21,
};

export const border_radius_22 = {
  borderRadius: 22,
};

export const border_radius_25 = {
  borderRadius: 25,
};

export const border_radius_28 = {
  borderRadius: 28,
};

export const border_radius_32 = {
  borderRadius: 32,
};

export const border_radius_44 = {
  borderRadius: 44,
};

export const border_radius_60 = {
  borderRadius: 60,
};

export const border_radius_70 = {
  borderRadius: 70,
};

export const border_radius_90 = {
  borderRadius: 90,
};

export const border_radius_100 = {
  borderRadius: 100,
};

export const border_radius_300 = {
  borderRadius: 300,
};

export const border_top_right_left_radius_10 = {
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
};

export const border_top_left_right_radius_6 = {
  borderTopLeftRadius: 6,
  borderTopRightRadius: 6,
};

export const border_top_left_radius_12 = {
  borderTopLeftRadius: 12,
};

export const border_top_left_radius_6 = {
  borderTopLeftRadius: 6,
};

export const border_top_left_radius_25 = {
  borderTopLeftRadius: 25,
};

export const border_top_left_radius_32 = {
  borderTopLeftRadius: 32,
};

export const border_top_right_radius_6 = {
  borderTopRightRadius: 6,
};

export const border_top_right_radius_25 = {
  borderTopRightRadius: 25,
};

export const border_top_right_radius_32 = {
  borderTopRightRadius: 32,
};

export const border_top_right_radius_12 = {
  borderTopRightRadius: 12,
};

export const border_top_right_radius_100 = {
  borderTopRightRadius: 100,
};

export const border_bottom_left_right_radius_6 = {
  borderBottomLeftRadius: 6,
  borderBottomRightRadius: 6,
};

export const border_bottom_left_right_radius_12 = {
  borderBottomLeftRadius: 12,
  borderBottomRightRadius: 12,
};

export const border_bottom_left_radius_12 = {
  borderBottomLeftRadius: 12,
};

export const border_bottom_right_radius_6 = {
  borderBottomRightRadius: 6,
};

export const border_bottom_right_radius_12 = {
  borderBottomRightRadius: 12,
};

export const border_bottom_left_radius_25 = {
  borderBottomLeftRadius: 25,
};

export const border_bottom_left_radius_32 = {
  borderBottomLeftRadius: 32,
};

export const border_bottom_right_radius_32 = {
  borderBottomRightRadius: 32,
};

export const padding_top_bottom_0 = {
  paddingTop: 0,
  paddingBottom: 0,
};
