import React, { useEffect, useRef, useState, useCallback } from "react";
import "./QnaList.scss";
import QnaStore from "../../../store/QnaStore";

//import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { getDateStr } from "../../../utils/datetime";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import { useTranslation } from "react-i18next";
import { useWindowScroll } from "react-use";

import { ReactComponent as NoData } from "../../../assets/icons/nodata_qnalist_bg.svg";
import { ReactComponent as QuestionIcon } from "../../../assets/icons/new_question_active.svg";
import { ReactComponent as CommentIcon } from "../../../assets/icons/new_comment_purple.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/new_heart_off.svg";
import { ReactComponent as ArrowRightPurple } from "../../../assets/icons/arrow_right_purple3.svg";

import { sendActHistory } from "../../../api/data/api";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { Virtuoso } from "react-virtuoso";

import { indexOf } from "lodash";
//import Toast from "react-native-toast-message";
// import ToastConfig from "../../../components/common/ToastConfig";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { ReactComponent as DarkSpinner } from "../../../assets/animations/darkSpinner.svg";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";
import CommonStore from "../../../store/CommonStore";

const QnaList = observer(() => {
  const { qnaListStore } = QnaStore;
  const width = window.innerWidth;
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

  const { t } = useTranslation();

  const ref = useRef(null);

  useEffect(() => {
    qnaListStore.clearStore();
    autorun(async () => {
      await qnaListStore.initQnaList({
        sort: qnaListStore.sort,
        waitingAnswerOnly: qnaListStore.waitingAnswerOnly,
        topics: [...qnaListStore.selectedTopics],
        categories: [...qnaListStore.selectedCategories],
      });
    });
  }, [qnaListStore]);

  // useEffect(() => {
  //   if (
  //     qnaListStore.toastMessage !== undefined &&
  //     qnaListStore.toastMessage?.length > 0
  //   ) {
  //     Toast.show({
  //       type: "tomatoToast",
  //       autoHide: true,
  //       text1: qnaListStore.toastMessage + " ",
  //       onHide: () => qnaListStore.setToast(""),
  //     });
  //   }
  // }, [qnaListStore.toastMessage, qnaListStore]);

  const renderLargeQnaInfo = (
    nickname: string,
    commentCount: number,
    likeCount: number,
    createdDateTime: string
  ) => {
    return (
      <div className="qna_info_wrap">
        <div className="qna_info">
          <p className="qna_item_text3">{nickname}</p>
          <div className="qna_item_bar" />
          <div className="qna_comment">
            <CommentIcon className="comment_icon" />
            <p className="qna_item_text4">{commentCount}</p>
          </div>
          <div className="qna_item_bar" />
          <div className="like">
            <HeartIcon />
            <p className="qna_item_text5, like_text">{likeCount}</p>
          </div>
          <div className="qna_item_bar" />
          <p className="qna_item_text5">{getDateStr(createdDateTime)}</p>
        </div>
        <p className="reply_btn_text">{t("screen.qnaList.reply")}</p>
      </div>
    );
  };

  const renderSmallQnaInfo = (
    nickname: string,
    commentCount: number,
    likeCount: number,
    createdDateTime: string
  ) => {
    return (
      <div className="qna_info_wrap">
        <div className="qna_info">
          <p className="qna_item_text3">{nickname}</p>
          <div className="small_wrap">
            <div className="small_wrap_inner">
              <div className="qna_comment">
                <CommentIcon className="comment_icon" />
                <p className="qna_item_text4">{commentCount}</p>
              </div>
              <div className="qna_item_bar" />
              <div className="like">
                <HeartIcon />
                <p className="qna_item_text5">{likeCount}</p>
              </div>
              <div className="qna_item_bar" />
              <p className="qna_item_text5">{getDateStr(createdDateTime)}</p>
            </div>
            <p className="reply_btn_text">{t("screen.qnaList.reply")}</p>
          </div>
        </div>
      </div>
    );
  };

  const moveToDetail = (feedId: number, qnaListIndex: number) => {
    void sendActHistory({
      actHistoryTypeCode: ActHistoryTypeCode.QNA_READ,
      actHistoryTargetId: `${feedId}`,
      actPageCode: ActPageCode.QNA_TOTAL,
      attr1: `${qnaListIndex}`,
      attr2: [...qnaListStore.selectedCategories].toString(),
      attr3: qnaListStore.waitingAnswerOnly ? "true" : "false",
      attr4: `${qnaListStore.sort}`,
    });
    goTo(`/Qna/${feedId}`, {
      state: { feedId: feedId, index: qnaListIndex },
    });
  };

  const handleMoreRequest = useCallback(() => {
    if (!qnaListStore.loading) {
      qnaListStore.getMoreQnaList();
    }
  }, [qnaListStore.getMoreQnaList]);

  const rowRenderer = (index: number, item: any) => {
    return (
      <div key={index} onClick={() => moveToDetail(item.feedId, index)}>
        <div className="qna_item">
          <QuestionIcon className="question_icon" />
          <div className="qna_wrap">
            <div className="qna_top">
              <div className="qna">
                <p className="qna_item_text1">{item.feedTitle}</p>
                <p className="qna_item_text2">{item.contents}</p>
              </div>
              {!!item.thumbnailFilePath && (
                <div>
                  <FastImageWithFallback
                    className="qna_image"
                    source={{
                      uri: `${PUBLIC_BUCKET_URL}${
                        item.thumbnailFilePath || ""
                      }`,
                    }}
                  />
                </div>
              )}
            </div>
            {width > 370
              ? renderLargeQnaInfo(
                  item.nickname,
                  item.commentCount,
                  item.likeCount,
                  item.createdDatetime
                )
              : renderSmallQnaInfo(
                  item.nickname,
                  item.commentCount,
                  item.likeCount,
                  item.createdDatetime
                )}
          </div>
        </div>
      </div>
    );
  };

  const renderQna = () => {
    return (
      <div className="qna_list">
        {qnaListStore.qnaList.map((qna: any, colIndex: number) =>
          rowRenderer(colIndex, qna)
        )}
        <InfiniteScrollObserver onObserver={handleMoreRequest} />
        <div style={{ height: 60, backgroundColor: "transparent" }} />
      </div>

      // <Virtuoso
      //   className="qna_list"
      //   data={qnaListStore.qnaList}
      //   endReached={() => {
      //     void qnaListStore.getMoreQnaList();
      //   }} // 끝에 다다르면 불러올 함수
      //   itemContent={rowRenderer}
      //   useWindowScroll
      //   aria-label="Qna List"
      //   components={{
      //     Footer: () => <div style={{ height: 60 }} />,
      //   }}
      // />
    );
  };

  const renderNoQna = () => {
    return (
      <div className="empty">
        <div className="no_data">
          <NoData />
        </div>
        <div className="empty_wrap">
          <p className="empty_text">{t("screen.qnaList.question.emptyText")}</p>
          <div
            className="write_qna_btn"
            onClick={() => {
              // goTo("/QnA/write");
              CommonStore.setShowDownloadAppDrive(true);
            }}
            aria-label="Qna write button for empty list"
          >
            <p className="write_qna_btn_text">
              {t("screen.qnaList.question.emptyButton")}
            </p>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {qnaListStore.qnaList && qnaListStore.qnaList.length === 0
        ? renderNoQna()
        : renderQna()}
    </>
  );
});

export default QnaList;
