import { callAPIWithPOSTMethod } from "../common/api";
import { PushClickEvent } from "./model";
import { EventProperty } from "../../hooks/tracker/types";
import AuthStore from "../../store/AuthStore";

export const trackUserProperties = async (memberUuid: string, type: string, isLoading = true): Promise<any> => {
  await callAPIWithPOSTMethod(
    {
      url: "/v1/track/sandbox/user",
      params: {
        memberUuid,
        type,
      },
    },
    isLoading
  );
};

//푸쉬 클릭 로그
export const trackPushClickEvent = async (pushClickEvent: PushClickEvent, isLoading = true): Promise<any> => {
  await callAPIWithPOSTMethod(
    {
      url: "/v1/track/action/push",
      body: pushClickEvent,
    },
    isLoading
  );
};

export const trackDsSyncEventProperties = async (
  type: string,
  event: string,
  properties: EventProperty,
  isLoading = true
): Promise<any> => {
  const eventProps = {
    memberUuid: AuthStore.sessionUser?.memberUuid ?? "",
    type: type,
    event: event,
    properties: properties,
  };
  await callAPIWithPOSTMethod(
    {
      url: "/v1/track/sandbox/ds/sync",
      body: eventProps,
    },
    isLoading
  );
};

export const trackViewEventLog = async (
  eventName: string,
  memberUuid: string,
  userType: string,
  properties: EventProperty,
  isLoading = true
): Promise<any> => {
  const eventProps = {
    memberUuid: memberUuid,
    eventName: eventName,
    screen: properties["source_screen"] ?? "",
    screenFrom: properties["source_screen_from"] ?? "",
    userType: userType,
  };

  await callAPIWithPOSTMethod(
    {
      url: "/v1/track/action/view/log",
      body: eventProps,
    },
    isLoading,
    false
  );
};
