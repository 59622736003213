import { useRef, useState } from "react";
import { TitleHeader } from "../../components/common";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { SocialTypeCode, SuccessOrNot } from "../../constants/Common.enum";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel.svg";
import PhoneAuthStore from "../../store/PhoneAuthStore";
import PhoneAuthSection from "../../components/common/PhoneAuthSection";
import { ReactComponent as TriangleRightBlackIcon } from "../../assets/icons/triangle_right_black.svg";
import { ReactComponent as TriangleRightRedIcon } from "../../assets/icons/triangle_right_red.svg";
import { ReactComponent as TriangleRightGreen } from "../../assets/icons/triangle_right_green.svg";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import CommonStore from "../../store/CommonStore";
import { deHyphenatePhoneNumber } from "../../utils/phoneNumber";
import SettingStore from "../../store/SettingStore";
import AuthStore from "../../store/AuthStore";
import EmailAuthStore from "../../store/EmailAuthStore";
import EmailAuthSection from "../../components/common/EmailAuthSection";
import { ReactComponent as Pass } from "../../assets/icons/pass.svg";
import { setSessionUserInStorage } from "../../service/Session.service";
import { UserInfo } from "../../api/member/model";
import { HeaderItem } from "../../constants/Header";
import { useLocation } from "react-router-dom";
import classes from "./LongtermUnusedScreen.module.scss";
import clsx from "clsx";
import { goBack, goTo } from "../../hooks/navigate/NavigateFunction";

const LongTermUnusedScreen = observer((): JSX.Element => {
  const {
    phoneNumber,
    isPhoneNumberValid,
    isPhoneAuthSectionShow,
    isPhoneNumberSectionComplete,
    resetPhoneAuthSection,
    sendAuthPhone,
    handleChangePhoneNumber,
    resetAuthLogic,
  } = PhoneAuthStore;

  const {
    sendEmailAuthCode,
    setEmail,
    isEmailAuthSectionShow,
    email,
    isEmailAuthComplete,
  } = EmailAuthStore;

  const viewRef = useRef(null);
  const scrollViewRef = useRef(null);
  const location = useLocation();

  const email_temp: string = location.state?.email || "";

  CommonStore.setLoading(false);

  const { t } = useTranslation();

  const [showPhoneAuth, setShowPhoneAuth] = useState<boolean>(false);
  const [showEmailAuth, setShowEmailAuth] = useState<boolean>(false);

  const goPhoneAuth = () => {
    setShowPhoneAuth(true);
    setShowEmailAuth(false);
  };

  useEffectOnlyOnce(() => {
    const code: string = location.state?.socialTypeCode || "";
    switch (code) {
      case SocialTypeCode.APPLE:
      case SocialTypeCode.GOOGLE:
        setShowEmailAuth(false);
        setShowPhoneAuth(true);
        break;
      case SocialTypeCode.EMAIL:
        setShowEmailAuth(true);
        break;
    }
    EmailAuthStore.clear();
    setEmail(email_temp ?? "");
    resetPhoneAuthSection();
    resetAuthLogic();
  });

  const renderEnterPhoneNumberGuide = () => {
    if (phoneNumber) {
      return <></>;
    } else {
      return (
        <div className={classes.alert} aria-label={"phone number guide"}>
          <TriangleRightBlackIcon />
          <span className={clsx(classes.alert_text, classes.phone_alert_text)}>
            {t("screen.join.joinPhone.enterPhoneNumberGuideOne")}
            <span className={classes.bold_text}>
              {t("screen.join.joinPhone.enterPhoneNumberGuideTwo")}
            </span>
            {t("screen.join.joinPhone.enterPhoneNumberGuideThree")}
          </span>
        </div>
      );
    }
  };

  const renderEnterValidPhoneNumberGuide = () => {
    if (phoneNumber && !isPhoneNumberValid) {
      return (
        <div className={classes.alert} aria-label={"invalid phone number"}>
          <TriangleRightRedIcon />
          <span className={clsx(classes.alert_text, classes.phone_not_valid)}>
            {t("screen.join.joinPhone.invalidPhoneNumberGuide")}
          </span>
        </div>
      );
    } else if (isPhoneNumberValid) {
      return (
        <div className={classes.alert} aria-label={"invalid phone number"}>
          <TriangleRightGreen />
          <span
            className={clsx(classes.alert_text, classes.phone_success_valid)}
          >
            {t("screen.join.joinPhone.successPhoneNumberGuide1")}
          </span>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const updatePhoneNumber = async () => {
    const result = await SettingStore.changePhoneNumber(
      deHyphenatePhoneNumber(phoneNumber)
    );
    if (result.successOrNot !== SuccessOrNot.Y) {
      alert("휴대폰 번호가 인증되지 않았습니다.");
      return;
    }

    AuthStore.setSessionUser({
      ...AuthStore.sessionUser,
      isLongTermUnused: false,
    });

    // 로그인 시간 업데이트
    await AuthStore.updateLoginTime();
    await setSessionUserInStorage({ ...AuthStore.sessionUser } as UserInfo);
  };

  const renderEmail = () => {
    return (
      <>
        {isEmailAuthComplete ? (
          <div className={classes.step_body}>
            <div className={classes.auth_check_ok}>
              <span className={classes.step_header_active_text}>{email}</span>
              <Pass />
            </div>
          </div>
        ) : (
          <div className={classes.step_body}>
            <div
              className={clsx(classes.email_wrap, classes.email_wrap_active)}
            >
              <span className={classes.email_input}>{email}</span>
              <button
                className={clsx(
                  classes.auth_btn,
                  !isEmailAuthSectionShow &&
                    !isEmailAuthComplete &&
                    classes.auth_btn_active
                )}
                disabled={isEmailAuthSectionShow || isEmailAuthComplete}
                onClick={() => {
                  void sendEmailAuthCode();
                }}
              >
                <span
                  className={clsx(
                    classes.auth_btn_text,
                    !isEmailAuthSectionShow &&
                      !isEmailAuthComplete &&
                      classes.auth_btn_text_active
                  )}
                >
                  {t("screen.password.emailAuth.authButton")}
                </span>
              </button>
            </div>
            {isEmailAuthSectionShow && <EmailAuthSection />}
          </div>
        )}
      </>
    );
  };

  const renderPhone = () => {
    return (
      <>
        {isPhoneNumberSectionComplete ? (
          <div className={classes.step_body}>
            <div className={classes.auth_check_ok}>
              <span className={classes.step_header_active_text}>
                {phoneNumber}
              </span>
              <Pass />
            </div>
          </div>
        ) : (
          <div className={classes.step_body}>
            <div
              className={clsx(
                classes.phone_input_wrap,
                classes.phone_input_wrap_focus
              )}
            >
              <div className={classes.phone_input_wrapper}>
                <input
                  // editable={true}
                  className={classes.phone_input}
                  placeholder={t(
                    "screen.join.joinPhone.phoneNumberInputPlaceholder"
                  )}
                  // keyboardType="number-pad"
                  value={phoneNumber}
                  onChange={(e) => {
                    if (!isPhoneAuthSectionShow) {
                      handleChangePhoneNumber(e.target.value);
                    }
                  }}
                  maxLength={13}
                />
              </div>
              {!!phoneNumber && (
                <button
                  className={classes.cancel_btn}
                  onClick={resetPhoneAuthSection}
                >
                  <CancelIcon />
                </button>
              )}
              <button
                className={clsx(
                  classes.auth_btn,
                  isPhoneNumberValid &&
                    !isPhoneAuthSectionShow &&
                    !isPhoneNumberSectionComplete &&
                    classes.auth_btn_active
                )}
                onClick={() => {
                  void sendAuthPhone();
                }}
                disabled={
                  !isPhoneNumberValid ||
                  isPhoneAuthSectionShow ||
                  isPhoneNumberSectionComplete
                }
                aria-label={"send authentication phone"}
              >
                <span
                  className={clsx(
                    classes.auth_btn_text,
                    isPhoneNumberValid &&
                      !isPhoneAuthSectionShow &&
                      !isPhoneNumberSectionComplete &&
                      classes.auth_btn_text_active
                  )}
                >
                  {t(
                    "screen.join.joinPhone.authenticateButtonPlaceholderFirst"
                  )}
                </span>
              </button>
            </div>
            <div className={classes.alert_wrap}>
              {renderEnterPhoneNumberGuide()}
              {renderEnterValidPhoneNumberGuide()}
            </div>
            {isPhoneAuthSectionShow && <PhoneAuthSection />}
          </div>
        )}
      </>
    );
  };

  const authHeaderText = () => {
    if (showEmailAuth) {
      return t("screen.join.joinWithEmail.header");
    }
    if (showPhoneAuth) {
      return t("screen.join.joinPhone.header");
    }
  };

  const footerText = () => {
    if (showEmailAuth) {
      return t("screen.join.infoAddAgree.next");
    }
    if (showPhoneAuth) {
      return t("screen.join.infoAddAgree.start");
    }
  };

  const close = () => {
    goBack();
    return true;
  };

  return (
    <button
      style={{
        width: "100%",
      }}
      onClick={() => {
        // Keyboard.dismiss
      }}
    >
      <>
        <div className={classes.join_email}>
          <div
            style={{ flex: 1 }}
            // behavior={isPlatformIOS ? "padding" : undefined}
            // enabled={true}
            // keyboardVerticalOffset={isPlatformIOS ? 0 : StatusBarHeight}
          >
            <div className={classes.header}>
              <TitleHeader
                title={t("screen.join.infoAddAgree.reCertification")}
                isBack={false}
                rightItem={HeaderItem.CLOSE}
                onClickClose={close}
              />
            </div>

            <div className={classes.contents_box}>
              <div
                // showsVerticalScrollIndicator={false}
                // keyboardShouldPersistTaps={"handled"}
                className={classes.step_list_wrapper}
                ref={scrollViewRef}
                // scrollEventThrottle={0}
              >
                <div
                  className={classes.step_list}
                  aria-label={"join with email contents"}
                >
                  <div className={classes.top_header}>
                    <span className={classes.top_header_active_text1}>
                      {t("screen.join.infoAddAgree.longtermUnused1")}
                    </span>
                    <span className={classes.top_header_active_text1}>
                      {t("screen.join.infoAddAgree.longtermUnused2")}
                    </span>
                  </div>
                  <div className={classes.second_header}>
                    <span className={classes.top_header_active_text2}>
                      {t("screen.join.infoAddAgree.longtermUnused3")}
                    </span>
                    <span className={classes.top_header_active_text2}>
                      {t("screen.join.infoAddAgree.longtermUnused4")}
                    </span>
                    <span className={classes.top_header_active_text2}>
                      {t("screen.join.infoAddAgree.longtermUnused5")}
                    </span>
                  </div>

                  <div className={classes.step_header} ref={viewRef}>
                    <button
                      //  activeOpacity={0.8}
                      // style={step_header_btn}
                      className={classes.step_header_btn}
                    >
                      <span className={classes.step_header_active_text}>
                        {authHeaderText()}
                      </span>
                    </button>
                  </div>

                  {showEmailAuth && renderEmail()}
                  {showPhoneAuth && renderPhone()}
                </div>
              </div>
              <div className={classes.space_bottom} />
              <div className={classes.footer}>
                <button
                  disabled={false}
                  className={clsx(
                    classes.btn_1,
                    isEmailAuthComplete || isPhoneNumberSectionComplete
                      ? classes.active
                      : classes.in_active
                  )}
                  onClick={() => {
                    if (showEmailAuth && isEmailAuthComplete) {
                      goPhoneAuth();
                      return;
                    }
                    if (showPhoneAuth && isPhoneNumberSectionComplete) {
                      CommonStore.setLoading(true);
                      void updatePhoneNumber().then(() => {
                        CommonStore.setLoading(false);
                        goTo("/Main", { replace: true });
                      });
                    }
                  }}
                >
                  <span
                    className={clsx(
                      classes.btn_1_txt,
                      isEmailAuthComplete || isPhoneNumberSectionComplete
                        ? classes.txt_active
                        : classes.txt_in_active
                    )}
                  >
                    {footerText()}
                  </span>
                  {/* <div style={{ height: bottomInset }} /> */}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </button>
  );
});
export default LongTermUnusedScreen;
