import { observer } from "mobx-react";
import { Color } from "../../assets/styles/variable";
import CouponList from "./presenter/CouponList";

const CouponListScreen = observer((data: any) => {

  return (
    <div
      style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: Color.WHITE }}
      // accessible={true}
      aria-label={'Coupon list screen'}
    >
      <CouponList />
    </div>
  );
});

export default CouponListScreen;
