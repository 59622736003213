import classes from './FilterSticky.module.scss'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/mall/icon_filter_del.svg'
import { ReactComponent as RefreshIcon } from '../../../assets/icons/mall/icon_refresh.svg'
import { Virtuoso } from 'react-virtuoso'
import { useRef } from 'react'
import useDragScrollWithMouse from '../../../hooks/useDragScrollWithMouse'
import CommonStore from '../../../store/CommonStore'

const SelectFilterList = ({ selectFilterList, selectFilter, resetFilter }: any) => {
  const listTabsRef = useRef<HTMLDivElement>(null)
  const isDragging = useDragScrollWithMouse(listTabsRef)
  const widthApp = CommonStore.widthApp;

  if (selectFilterList && selectFilterList !== null && selectFilterList.length > 0) {
    return (
    <div className={classes.bx_select_list} style={{ width: widthApp }}>
      <div ref={listTabsRef} className={classes.bx_select_wrap} aria-label={"category best"}>
        {selectFilterList.map((item: any, index: number) => (
          <div
            className={classes.btn_select_list}
            key={index}
            onClick={() =>
              !isDragging && selectFilter(item.parentId || 0, item.attributeName ?? '', item)
            }
          >
            <text className={classes.txt_select_list}>
              {item.attributeValueName}
            </text>
            <DeleteIcon />
          </div>
        ))}
      </div>
      <div className={classes.bx_refresh} onClick={resetFilter}>
        <RefreshIcon />
      </div>
    </div>
    )
  }
    
}

export default SelectFilterList