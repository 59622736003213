import { AccountFeedTypeCode } from "../../constants/Account";
import { callAPIWithGETMethod } from "../common/api";
import CommonResponse from "../http";
import { Mission } from "../mission/model";
import {
  AccountFeedItem,
  AccountRequest,
  AccountResponse,
  SimilarUserRequest,
  SimilarUserResponse,
} from "./model";

export const getAccountFeed = async (
  feedTypeCode: AccountFeedTypeCode,
  requestItem: AccountRequest,
  isLoading = true
): Promise<AccountResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/myhome/${feedTypeCode}`,
      params: requestItem,
    },
    isLoading
  );
  return (
    response?.successOrNot === "Y" && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as AccountResponse;
};

export const getAccountFullFeed = async (
  requestItem: AccountRequest,
  isLoading = true
) => {
  let response: CommonResponse<{
    feedFindVOList?: AccountFeedItem[];
    totalCount?: number;
  }> = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  response = await callAPIWithGETMethod(
    {
      url: `/v1/feed/postDetails?pageSize=${requestItem.pageSize}&pageIndex=${requestItem.pageIndex}`,
      params: requestItem,
    },
    isLoading
  );
  const convertedRes = {
    totalCount: response.data?.totalCount ?? 0,
    list: response.data?.feedFindVOList ?? [],
  };
  return (
    response?.successOrNot === "Y" && response?.statusCode === "OK"
      ? convertedRes
      : null
  ) as AccountResponse;
};

export const getAccountMission = async (
  missionTypePath: string,
  writerMemberUuid: string,
  pageSize: number,
  pageIndex: number,
  isLoading = true
): Promise<CommonResponse<{ list: Mission[] }>> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/missions`,
      params: { missionTypePath, writerMemberUuid, pageSize, pageIndex },
    },
    isLoading
  );
  return (
    response?.successOrNot === "Y" && response?.statusCode === "OK"
      ? response
      : null
  ) as CommonResponse;
};

export const getSimilarUsers = async (
  request: SimilarUserRequest,
  isLoading = true
): Promise<SimilarUserResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/recommendation/similarMembers",
      params: {
        maxSimilarMemberCount: request.maxSimilarMemberCount || 10,
        memberUuid: request.memberUuid,
      },
    },
    isLoading
  );
  return (
    response?.successOrNot === "Y" && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as SimilarUserResponse;
};
