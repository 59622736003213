export enum EventTypeCode {
  ACCUM = "ACCUM",
  USE = "USE",
  ACCUM_CANCEL = "ACCUM_CANCEL",
  USE_CANCEL = "USE_CANCEL",
}

export enum VoltPolicyCode {
  MISSION_ACCUM = "MISSION_ACCUM",
  MISSION_USE = "MISSION_USE",
}
