import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react";

import "./ProductSearchScreen.scss";

import { t } from "i18next";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../assets/styles";

import { ProductSearchResult, ProductSearchWord } from "./presenter";

import { TitleHeader } from "../../components/common";
import { ReactComponent as SearchIcon } from "../../assets/icons/search1.svg";
import { ReactComponent as ClosePurple } from "../../assets/icons/close_purple_background.svg";

import ProductSearchAddStore from "../../store/ProductSearchAddStore";
import MyAppliancesStore from "../../store/MyAppliancesStore";
import { showBottomToast } from "../../utils/Toast";
import { useTracker } from "../../hooks/tracker";
import { Color } from "../../assets/styles/variable";
import MyAppliancesFlatList from "../myAppliances/presenter/MyAppliancesFlatList";
// import TopButton from "../../components/common/TopButton";
import { NewProduct } from "../../api/product/model";
import { ProductSelectFromEnum } from "../../constants/product/ProductSelectFrom";
import { SearchSortType } from "../../constants/Search";
import { FeedTypeCode } from "../../constants/Feed";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import { goBack } from "../../hooks/navigate/NavigateFunction";
import QnaWriteStore from "../../store/QnaWriteStore";
import ArticleWriteStore from "../../store/ArticleWriteStore";
import CommentStore from "../../store/CommentStore";
import PostWriteStore from "../../store/PostWriteStore";

interface IProductSearchContext {
  _screenFrom: string;
  addProductList: (product: NewProduct) => void;
}

export const ProductSearchContext = createContext<IProductSearchContext>({
  _screenFrom: "",
  addProductList: () => {
    //
  },
});

const ProductSearchScreen = observer((data: any) => {
  const {
    state: { feedTypeCode, screenFrom },
  } = useLocation();

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (screenFrom && screenFrom !== "my") {
      MyAppliancesStore.clear();
      void MyAppliancesStore.initMyProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenFrom]);

  const {
    keyword,
    showWord,
    showResult,
    setKeyword,
    setRecentWord,
    getSearchResult,
    setShowWord,
    setShowResult,
  } = ProductSearchAddStore;

  const doSearch = (type?: string) => {
    if (keyword && keyword?.length > 0) {
      void setRecentWord(keyword);
      void getSearchResult(keyword, 0, SearchSortType.POPULAR);
      inputRef.current?.blur();
      setShowWord(false);
      setShowResult(true);
    } else {
      showBottomToast(t("screen.search.message.noSearchText"));
    }
  };

  const blur = () => {
    if (inputRef) inputRef.current?.blur();
  };

  useEffect(() => {
    ProductSearchAddStore.clear();
    void ProductSearchAddStore.getRecentWord();
    ProductSearchAddStore.initTempProductListByType();
  }, [data]);

  const addProductList = (selectedProduct: NewProduct) => {
    switch (feedTypeCode) {
      case FeedTypeCode.QNA:
        QnaWriteStore.addProduct(selectedProduct);
        break;
      case FeedTypeCode.ARTICLE:
        ArticleWriteStore.addProduct(selectedProduct);
        break;
      case FeedTypeCode.COMMENT:
        CommentStore.addProduct(selectedProduct);
        break;
      case FeedTypeCode.POST:
        PostWriteStore.addProduct(selectedProduct);
        break;
      default:
        break;
    }
  };
  //내가전
  const saveMyProductList = () => {
    //중복 체크
    const tempPrdCnt = ProductSearchAddStore.tempProductList.length;

    if (MyAppliancesStore.myAppliancesList) {
      const result = MyAppliancesStore.myAppliancesList?.filter(
        (x) => x.isSelected
      );

      const maxSize = tempPrdCnt + result.length;

      if (maxSize > 5) {
        showBottomToast(t("screen.feedProduct.message.max5Count"));
      } else {
        if (!MyAppliancesStore.isValidRegister) {
          MyAppliancesStore.myAppliancesList?.forEach((myAppliancesList, i) => {
            if (myAppliancesList.isSelected) {
              const newProduct = {
                thumbnailFilePath: myAppliancesList.imageUrl,
                productName: myAppliancesList.productName || "",
                manufacturerName: myAppliancesList.brandName || "",
                originalFilePath: myAppliancesList.imageUrl?.replace(
                  "THUMBNAIL",
                  "ORIGINAL"
                ),
                productModelName: myAppliancesList.modelName,
                // openApiFlag: !myAppliancesList.productFlag,
                from: ProductSelectFromEnum.MY,
              } as NewProduct;

              if (myAppliancesList.tempProductId) {
                newProduct.tempProductId = myAppliancesList.tempProductId;
              }

              if (myAppliancesList.productId) {
                newProduct.productId = myAppliancesList.productId;
              }

              if (addProductList) {
                addProductList(newProduct);
              }
            }
          });

          goBack();
        } else {
          showBottomToast(t("screen.feedProduct.message.max5Count"));
        }
      }
    }
  };

  //검색 제품 등록
  const saveSearchProduct = () => {
    const tempPrdCnt = ProductSearchAddStore.tempProductList.length;
    const values = Array.from(
      ProductSearchAddStore.selectedSearchProductMap.values()
    );
    const newProductsCnt = values.length;

    if (tempPrdCnt + newProductsCnt > 5) {
      showBottomToast(t("screen.feedProduct.message.max5Count"));
      return false;
    }

    values.forEach((p) => {
      addProductList(ProductSearchAddStore.createNewProductObj(p));
    });

    goBack();
  };

  const currentSelectedCnt = () => {
    return (
      ProductSearchAddStore.tempProductList.length +
      (MyAppliancesStore.myAppliancesList?.filter((x) => x.isSelected).length ??
        0) +
      ProductSearchAddStore.selectedSearchProductCnt
    );
  };

  const SearchGuideView = () => {
    return (
      <>
        <div style={{ alignItems: "center", marginTop: 40, marginBottom: 127 }}>
          <p className="no_search_text">
            {t("screen.productAdd.label.searchGuideText")}
          </p>
        </div>
      </>
    );
  };

  // My applicen list(My가전 리스트)
  const NoSearchKeywordViewWithMyProductList = useCallback(() => {
    return (
      <>
        <SearchGuideView />
        {MyAppliancesStore.existMyList && (
          <div
            style={{
              height: 6,
              marginBottom: 20,
              backgroundColor: "#f5f5f5",
            }}
          />
        )}
        <div ref={ref}>
          {[{}, {}].map((_, index) => {
            if (!MyAppliancesStore.existMyList) {
              return <></>;
            }

            if (index === 0) {
              return (
                <>
                  <div
                    style={{
                      paddingBottom: 18,
                      backgroundColor: "#fff",
                      paddingTop: 20,
                    }}
                  >
                    <div className="my_product_list_wrap">
                      <p className="my_product_list_text">
                        {t("screen.feedProduct.label.myProductList")}
                      </p>
                      <p className="my_product_max_cnt_text">
                        {t("screen.feedProduct.label.maxCnt")}
                      </p>
                    </div>
                  </div>
                </>
              );
            }
            return (
              <>
                <div>
                  <MyAppliancesFlatList
                    from={"product"}
                    mode={""}
                    tempSize={ProductSearchAddStore.tempProductList.length}
                  />
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  }, []);

  const isActiveButton = () => {
    const mySelectedCount = MyAppliancesStore.selectedCount ?? 0;
    return (
      ProductSearchAddStore.searchSearchProductMap.size > 0 ||
      mySelectedCount > 0
    );
  };

  //검색된 리스트에서 선택된 내역 등록하기 버튼
  const FooterButton = () => {
    return (
      <div className="footer">
        <div
          className={clsx(
            "save_button",
            isActiveButton() ? "active" : "inactive"
          )}
          style={
            isActiveButton()
              ? {}
              : { backgroundColor: "#eeeeee", borderColor: "#d9d9d9" }
          }
          onClick={() => {
            if (!isActiveButton()) {
              return;
            }
            if (ProductSearchAddStore.searchSearchProductMap.size > 0) {
              void saveSearchProduct();
            } else {
              saveMyProductList();
            }
          }}
          aria-label="Click button"
        >
          <div className="content">
            <div className="footer_num_text">
              <p
                style={isActiveButton() ? {} : { color: "#eeeeee" }}
                className={clsx(
                  isActiveButton() ? "active" : "inactive",
                  "text_number"
                )}
              >
                {currentSelectedCnt()}
              </p>
            </div>
            <div>
              <p className="footer_btn_text">{t("등록하기")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="product_search">
      <ProductSearchContext.Provider
        value={{
          _screenFrom: screenFrom,
          addProductList: addProductList,
        }}
      >
        <div style={{ flex: 1, backgroundColor: "#fff" }}>
          <TitleHeader title={t("screen.productAdd.title.add")} />
          <div className="home_app_wrap">
            <div className="home_app_input_wrap">
              <input
                onBlur={() => {
                  //Keyboard.dismiss();
                }}
                //   onLayout={() => {
                //     if (screenFrom === "my") {
                //       inputRef.current?.focus();
                //     }
                //   }}
                aria-label="Search keyword"
                className="home_app_input"
                placeholder={t("screen.productAdd.label.keywordPlaceholder")}
                value={keyword}
                ref={inputRef}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter" && e.nativeEvent.isComposing === false)
                    doSearch();
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setKeyword(e.target.value);
                  setShowResult(false);
                  setShowWord(e.target.value?.length > 0);
                }}
              />
            </div>
            <div
              aria-label="Delete keyword"
              className="close_purple icon_wrap"
              style={{ display: keyword ? "flex" : "none" }}
              onClick={() => {
                setKeyword("");
                setShowWord(false);
                setShowResult(false);
              }}
            >
              <ClosePurple />
            </div>
            <div
              aria-label="Search product"
              className="search_icon icon_wrap"
              onClick={() => doSearch()}
            >
              <SearchIcon />
            </div>
          </div>
          {/** 검색어 미입력 시 노출 문구 **/}
          {!showWord && !showResult && (!screenFrom || screenFrom === "my") && (
            <SearchGuideView />
          )}
          {!showWord &&
            !showResult &&
            (screenFrom === "post" ||
              screenFrom === "qna" ||
              screenFrom === "article" ||
              screenFrom === FeedTypeCode.COMMENT) && (
              <NoSearchKeywordViewWithMyProductList />
            )}

          {showWord && <ProductSearchWord clickCallback={blur} />}
          {showResult && <ProductSearchResult params={data} />}
        </div>
        {/** submit buttons **/}
        {(screenFrom === "post" ||
          screenFrom === "qna" ||
          screenFrom === "article" ||
          screenFrom === FeedTypeCode.COMMENT) &&
          FooterButton()}
      </ProductSearchContext.Provider>
    </div>
  );
});

export default ProductSearchScreen;
