import axios from "axios";

const REDIRECT_PATH = "/auth";

export const googleAuthorize = () => {
  window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${window.location.origin}${REDIRECT_PATH}&response_type=code&scope=email profile`;
};

export const getGoogleToken = async (code: string) => {
  try {
    const res = await axios.post("https://oauth2.googleapis.com/token", {
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      code: code,
      grant_type: "authorization_code",
      redirect_uri: `${window.location.origin}${REDIRECT_PATH}`,
    });

    return res.data;
  } catch (err) {
    return undefined;
  }
};
export const getGoogleUser = async (accessToken: string) => {
  try {
    const res = await axios.get(
      `https://www.googleapis.com/oauth2/v2/userinfo?access_token=${accessToken}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return res.data;
  } catch (err) {
    return undefined;
  }
};
