import { useTranslation } from "react-i18next";
import { withdrawalEmailMasking } from "../../utils/email";
import { SocialLoginRequest } from "../../api/member/model";
import { observer } from "mobx-react";
import { SuccessOrNot } from "../../constants/Common.enum";
import { TitleHeader } from "../../components/common";
import classes from "./MemberRecoveryScreen.module.scss";
import clsx from "clsx";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import { useLocation } from "react-router-dom";
import { restoreEmailMember, restoreSocialMember } from "../../api/member/api";
import CommonStore from "../../store/CommonStore";

export interface WithdrawalMemberInterface {
  memberUuid: string;
  emailAddress: string;
  emailLoginRequest?: EmailLoginRequest;
  socialLoginRequest?: SocialLoginRequest;
}

interface EmailLoginRequest {
  emailAddress: string;
  password: string;
}

const MemberRecoveryScreen = observer(() => {
  const { t } = useTranslation();
  const {
    emailAddress,
    emailLoginRequest,
    socialLoginRequest,
  }: WithdrawalMemberInterface = useLocation().state;

  const onConfirmBtnPress = async () => {
    let successOrNot;
    if (socialLoginRequest) {
      successOrNot = await restoreSocialMember(socialLoginRequest);
    } else if (emailLoginRequest) {
      successOrNot = await restoreEmailMember(emailLoginRequest);
    }

    if (successOrNot === SuccessOrNot.Y) {
      CommonStore.setNotificationModalProps({
        isVisible: true,
        title: t("screen.WithdrawalMember.modal.title"),
        contents1: `${t("screen.WithdrawalMember.modal.contents")}`,
        defaultButtonText: t("common.label.check"),
        onRequestClose: async () => {
          CommonStore.resetNotificationModalProps();
          goTo("/", { replace: true });
        },
      });
    }
  };

  return (
    <>
      <div className={classes.wrap}>
        <div className={classes.header} aria-label={"Withdrawal Member Title"}>
          <TitleHeader
            title={t("screen.WithdrawalMember.title")}
            isBack={true}
          />
        </div>
        <div
          className={classes.contents_box}
          aria-label="Withdrawal Member Screen"
        >
          <div>
            {t("screen.WithdrawalMember.contents", {
              emailAddress: withdrawalEmailMasking(emailAddress),
            })}
          </div>
          <div>{t("screen.WithdrawalMember.restore")}</div>
          <div>{t("screen.WithdrawalMember.nonRestore")}</div>
        </div>

        <div className={classes.footer}>
          <button
            className={clsx([classes.btn_1, classes.active])}
            onClick={onConfirmBtnPress}
            aria-label={"Check Button"}
          >
            <span className={clsx([classes.btn_1_txt, classes.txt_active])}>
              {t("common.label.check")}
            </span>
          </button>
        </div>
      </div>
    </>
  );
});

export default MemberRecoveryScreen;
