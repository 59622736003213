import { useCallback, useRef, useState } from 'react'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow_down.svg'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as CircleCloseIcon } from '../../../assets/icons/circle_close_purple.svg'
import { TitleHeader } from '../../../components/common'
import { useEffectOnlyOnce } from '../../../hooks/UseEffectOnlyOnce'
import SettingStore from '../../../store/SettingStore'
import { CommonGroupCode } from '../../../constants/CommonCode.enum'
import { CommonCode } from '../../../api/commonCode/model'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { NotificationModal } from '../../../components/modals'
import { useTranslation } from 'react-i18next'
import CustomBottomSheet from '../../../components/common/CustomBottomSheet'
import { removeSessionUserFromStorage } from '../../../service/Session.service'
import { clearStore } from '../../../service/Common.service'
import TouchableWithAsyncTask from '../../../components/common/TouchableWithAsyncTask'
import CommonStore from '../../../store/CommonStore'
import { useLocation } from 'react-router-dom'
import { goBack } from '../../../hooks/navigate/NavigateFunction'
import classes from './InquiryWrite.module.scss'
import clsx from 'clsx'
import { Virtuoso } from 'react-virtuoso'

const InquiryWrite = observer(() => {
  const { t } = useTranslation()

  const {
    inquiryType,
    isUploadFile,
    inquiryWriteFileName,
    errorMessage,
    hasError,
    inquiryComplete,
    getCommonCode,
    inquirySubmit,
    setError,
    clearInquiryWrite,
    getQaList,
    setIsQaInit,
    logout,
    setInquiryWriteFile,
  } = SettingStore

  const { isHaltMember }: { isHaltMember: boolean } = useLocation().state

  const [selectedInquiryType, setSelectedInquiryType] = useState<CommonCode>()
  const [showBottomSheet, setShowBottomSheet] = useState<boolean>(false)
  const [content, setContent] = useState('')
  const [submitDisable, setSubmitDisable] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffectOnlyOnce(() => {
    clearInquiryWrite()
    void getCommonCode(CommonGroupCode.QA_TYPE_CODE)
  })

  const reGetQaList = useCallback(async () => {
    setIsQaInit(true)
    await getQaList(9999, 0, 'ALL')
  }, [getQaList, setIsQaInit])

  useEffect(() => {
    if (inquiryComplete) {
      const { clearInquiryWrite } = SettingStore
      clearInquiryWrite()
      void reGetQaList()
      !isHaltMember && goBack()
    }
  }, [inquiryComplete, isHaltMember, reGetQaList])

  const onLogout = async () => {
    void (await logout())
    removeSessionUserFromStorage()
    clearStore()
  }

  const onSubmit = async () => {
    if (selectedInquiryType && content.length > 0) {
      setSubmitDisable(true)
      await inquirySubmit(selectedInquiryType.code, content)
      if (isHaltMember) {
        CommonStore.setToastOption({
          show: true,
          message1: t('screen.setting.inquiryWrite.success'),
          // bottomOffset: 30,
          durationTime: 1000,
        })
        await onLogout()
      }
    }
  }

  const onBottomSheet = () => {
    setShowBottomSheet(true)
  }

  const onCloseBottomSheet = () => {
    setShowBottomSheet(false)
  }

  const renderType = (index: number, item: CommonCode) => {
    return (
      <div className={classes.type_item} aria-label="Inquiry Type List">
        <button
          onClick={() => {
            setSelectedInquiryType(item)
            onCloseBottomSheet()
          }}
        >
          <span
            className={clsx(
              classes.type_text,
              selectedInquiryType && selectedInquiryType.code === item.code
                ? classes.type_active_text
                : {}
            )}
          >
            {item.codeName}
          </span>
        </button>
      </div>
    )
  }

  return (
    <>
      <div className={classes.qna_write} aria-label="Inquriy Write">
        <div style={{ flex: 1 }}>
          <TitleHeader
            title={t('screen.setting.inquiryWrite.title')}
            isBack={true}
          />
          <div
            // contentContainerStyle={write_box}
            aria-label="Inquiry Type"
          >
            <div className={clsx(classes.wrap, classes.top_wrap)}>
              <span className={classes.label_text}>
                {t('screen.setting.inquiryWrite.type')}
              </span>
              <div className={classes.qna_wrap}>
                <button
                  className={classes.qna_btn}
                  onClick={() => onBottomSheet()}
                >
                  {selectedInquiryType ? (
                    <span className={classes.contents_text}>
                      {selectedInquiryType.codeName}{' '}
                    </span>
                  ) : (
                    <span className={classes.init_text}>
                      {t('screen.setting.inquiryWrite.typePlaceholder')}
                    </span>
                  )}
                  <ArrowDownIcon />
                </button>
              </div>
            </div>

            <div className={classes.bar} />

            <div className={classes.wrap} aria-label="Inquiry Content">
              <span className={classes.label_text}>
                {t('screen.setting.inquiryWrite.content')}
                <span className={classes.sub_text}>
                  ({t('screen.setting.inquiryWrite.contentMax')})
                </span>
              </span>
              <div className={classes.qna_wrap}>
                <textarea
                  placeholder={t(
                    'screen.setting.inquiryWrite.contentPlaceholder'
                  )}
                  className={clsx(
                    classes.contents_text,
                    classes.contents_text_input
                  )}
                  // textAlignVertical={'top'}
                  // multiline={true}
                  // placeholderTextColor={'#999'}
                  value={content}
                  onChange={(e) => {
                    setContent(e.target.value)
                  }}
                  maxLength={500}
                />
              </div>
              <div className={classes.qna_guide}>
                <span className={classes.qna_guide_txt}>
                  {t('screen.setting.inquiryWrite.contentGuideOne')}
                </span>
                <span className={classes.qna_guide_txt}>
                  {t('screen.setting.inquiryWrite.contentGuideTwo')}
                </span>
              </div>
            </div>

            <div className={classes.bar} />
            {/** TODO: 파일첨부 기능 개발 후 진행 */}
            {/* <div className={classes.wrap} aria-label="Inquiry File">
              <span className={classes.label_text}>
                {t('screen.setting.inquiryWrite.file')}
              </span>
              <div className={classes.qna_wrap}>
                {isUploadFile ? (
                  <div className={classes.qna_btn}>
                    <span className={classes.contents_text}>
                      {inquiryWriteFileName}
                    </span>
                    <button
                      onClick={() => {
                        // deleteInquiryWriteFile()
                      }}
                    >
                      <CircleCloseIcon />
                    </button>
                  </div>
                ) : (
                  <div className={classes.empty_file_wrap}>
                    <form style={{ display: 'none' }}>
                      <input
                        type="file"
                        accept="image/*"
                        ref={fileInputRef}
                        onChange={(e) => {
                          // TODO: 파일 첨부
                          // setInquiryWriteFile(e.target.files?.item(0)?.webkitRelativePath)
                        }}
                      ></input>
                    </form>
                    <span className={classes.init_text}>
                      {t('screen.setting.inquiryWrite.filePlaceholder')}
                    </span>
                    <PlusIcon
                      onClick={() => {
                        fileInputRef.current?.click()
                      }}
                    />
                  </div>
                )}
              </div>
              <span className={classes.info_text}>
                {t('screen.setting.inquiryWrite.fileMax')}
              </span>
            </div> */}
          </div>
          <div className={classes.spacer} />

          <div className={classes.footer_wrap} aria-label="Inquiry Submit">
            <TouchableWithAsyncTask
              className={clsx(
                classes.footer_btn,
                selectedInquiryType && content.length > 0
                  ? classes.footer_active_btn
                  : classes.footer_in_active_btn
              )}
              onClick={onSubmit}
              disabled={submitDisable}
            >
              <span className={classes.footer_btn_text}>
                {t('screen.setting.inquiryWrite.submit')}
              </span>
            </TouchableWithAsyncTask>
          </div>

          <CustomBottomSheet
            open={showBottomSheet}
            onClose={onCloseBottomSheet}
            // snapHeight={360}
          >
            <div className={classes.sheet} aria-label="Inqury Type BottomSheet">
              <div className={classes.title_wrap}>
                <span className={classes.title}>
                  {t('screen.setting.inquiryWrite.type')}
                </span>
              </div>
              <button
                className={classes.close_btn}
                onClick={onCloseBottomSheet}
              >
                <CloseIcon />
              </button>
              {/* <Virtuoso
                className={classes.type_list}
                data={inquiryType}
                itemContent={renderType}
                useWindowScroll
                style={{ height: 300 }}
              /> */}
              <div className={classes.type_list}>
                {inquiryType.map((item, index) => renderType(index, item))}
              </div>
            </div>
          </CustomBottomSheet>
        </div>
      </div>
      {hasError && (
        <NotificationModal
          isVisible={hasError}
          contents1={errorMessage!}
          onRequestClose={() => {
            setError('')
          }}
        />
      )}
    </>
  )
})

export default InquiryWrite
