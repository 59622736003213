import React, { useRef, useMemo, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as Camera } from "../../assets/icons/camera_black.svg";
import { ReactComponent as ProfileSelect } from "../../assets/icons/profile_select.svg";
import { useTranslation } from "react-i18next";
import FastImageWithFallback from "../../components/common/FastImageWithFallback";
import {
  AlbumResponse,
  callNativeSelectOnePicture,
  ImageForUI,
} from "../../utils/album";
import ProfileStore from "../../store/ProfileStore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomBottomSheet from "../../components/common/CustomBottomSheet";
import clsx from "clsx";
import classes from "./ProfileSelectComponent.module.scss";
import CommonStore from "../../store/CommonStore";

export interface ProfileSelectComponentProps {
  prefixUri: string;
  selectedUri: string;
  handleSelect: (uri: string) => () => void;
  onClose: () => void;
}

interface ProfileData {
  id: number;
  topUri: string;
  bottomUri: string;
  isLastColumn: boolean;
}

const ProfileSelectComponent = ({
  prefixUri,
  selectedUri,
  handleSelect,
  onClose,
}: ProfileSelectComponentProps) => {
  const SAMPLE_IMAGE_COUNT = 9;

  const { t } = useTranslation();
  const sheetRef = useRef(null);
  const [checkProfile, setCheckProfile] = useState(selectedUri);

  const { setCameraProfile } = ProfileStore;

  const uris = useMemo(() => {
    const retVal: ProfileData[] = [];

    const firstVal: ProfileData = {
      id: 0,
      topUri: "",
      bottomUri: `${prefixUri}0.png`,
      isLastColumn: false,
    };
    retVal.push(firstVal);

    for (let index = 1; index < SAMPLE_IMAGE_COUNT; index++) {
      const setVal: ProfileData = {
        id: index,
        topUri: `${prefixUri}${String(index++)}.png`,
        bottomUri: `${prefixUri}${String(index)}.png`,
        isLastColumn: index + 1 === SAMPLE_IMAGE_COUNT,
      };

      retVal.push(setVal);
    }

    return retVal;
  }, [prefixUri]);

  const showNativeAlbum = async () => {
    // TODO
    // const res = (await callNativeSelectOnePicture("PROFILE")) as AlbumResponse;
    // const file: ImageForUI = res.param.image;
    // setCheckProfile(file.fileUri);
    // setCameraProfile(file);
  };

  const handleCheck = (uri: string) => () => {
    setCheckProfile(uri);
  };

  const renderSelected = (uri: string, isLastColumn?: boolean) => {
    if (checkProfile === uri) {
      return (
        <>
          <div
            // style={{ ...checked_image_position, ...checked_image_background }}
            className={clsx(
              classes.checked_image_background,
              classes.checked_image_position
            )}
          />
          <div className={classes.checked_image_position}>
            <ProfileSelect />
          </div>
        </>
      );
    }
  };

  const renderItem = (item: ProfileData) => {
    return (
      <div key={item.id} className={classes.image_column}>
        {item.id === 0 ? (
          <button
            className={clsx(classes.sample_image, classes.camera_btn)}
            onClick={
              () => {
                onClose();
                CommonStore.setShowDownloadAppDrive(true);
              }
              // void showNativeAlbum()
            }
          >
            <Camera />
          </button>
        ) : (
          <button
            className={
              item.isLastColumn
                ? clsx(classes.sample_image_wrap, classes.image_column_last)
                : classes.sample_image_wrap
            }
            onClick={handleCheck(item.topUri)}
          >
            <FastImageWithFallback
              wrapperStyle={{ display: "flex" }}
              className={classes.sample_image}
              source={{ uri: item.topUri }}
            />
            {renderSelected(item.topUri, item.isLastColumn)}
          </button>
        )}

        <button
          className={clsx(classes.sample_image_wrap, classes.bottom_row)}
          onClick={handleCheck(item.bottomUri)}
        >
          <FastImageWithFallback
            wrapperStyle={{ display: "flex" }}
            className={classes.sample_image}
            source={{ uri: item.bottomUri }}
          />
          {renderSelected(item.bottomUri, item.isLastColumn)}
        </button>
      </div>
    );
  };

  return (
    <CustomBottomSheet
      open={true}
      modalContentStyles={{
        padding: "unset",
        height: "auto",
      }}
      onClose={() => {
        onClose();
      }}
      snapHeight={494}
    >
      <>
        <div className={classes.close_btn}>
          <button onClick={onClose} aria-label={"Close Button"}>
            <CloseIcon />
          </button>
        </div>
        <div
          className={classes.selected_image_wrap}
          aria-label={"Selected Profile Image"}
        >
          <LazyLoadImage
            className={classes.selected_image}
            src={checkProfile}
          />
        </div>

        {/* <FlatList
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item) => String(item.id)}
          data={uris}
          renderItem={renderItem}
          horizontal={true}
          style={sample_image_list}
          aria-label={"Profile Image"}
        /> */}
        <div className={classes.image_list}>
          {uris.map((x) => renderItem(x))}
        </div>
        <div className={classes.footer}>
          <button
            // style={{ height: 65, ...btn_1 }}
            className={clsx(classes.save_btn)}
            onClick={handleSelect(checkProfile)}
            aria-label={"Save Profile"}
          >
            <span className={classes.save_btn_text}>
              {t("screen.profile.setting.save")}
            </span>
          </button>
        </div>
      </>
    </CustomBottomSheet>
  );
};

export default ProfileSelectComponent;
