import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {useParams} from "react-router-dom";
// import { SafeAreaView, View } from "react-native";
import { Color } from "../../assets/styles/variable";
import { ProductDetail } from "./presenter";
import { getProductDetail, getProductAsCenters } from "../../api/product/api";
import { ProductInfo, ProductAsCenter } from "../../api/product/model";
// import FastImage from "react-native-fast-image";

const ProductDetailScreen = observer((data: any) => {
  const params = useParams();
  const navigation = data?.navigation;
  const productId = Number(params.productId);
  const from = String(params?.from || "");

  const [productInfo, setProductInfo] = useState({} as ProductInfo);
  const [asCenterList, setAsCenterList] = useState([] as ProductAsCenter[]);

  useEffect(() => {
    const getProductInfo = async () => {
      setProductInfo(await getProductDetail(productId));
      setAsCenterList((await getProductAsCenters(productId)).slice(0, 4));
    };

    void getProductInfo();

    // return () => {
    //   void FastImage.clearMemoryCache();
    // };
  }, [productId]);

  return (
    <div style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: Color.WHITE }}
      // accessible={true}
      // accessibilityLabel={"Product detail screen"}
    >
      <ProductDetail
        navigation={navigation}
        productId={productId}
        productInfo={productInfo}
        asCenterList={asCenterList}
        from={from}
      />
    </div>
  );
});

export default ProductDetailScreen;
