import React from "react";
import CommentStore from "../../../store/CommentStore";
import { CommentListItem } from "../../../api/comment/model";
import { observer } from "mobx-react";
import AuthStore from "../../../store/AuthStore";
import {
  ContentsPopupMenu,
  OptionType,
} from "../../../components/common/ContentsPopupMenu";
import { CommentTypeCode } from "../../../constants/Comment";

export interface CommentOptionBoxProps {
  comment: CommentListItem;
  onAction?: (type: OptionType) => void;
}
const CommentOptionBox = observer(
  ({ comment, onAction }: CommentOptionBoxProps) => {
    const { commentTarget } = CommentStore;

    const { sessionUser } = AuthStore;

    const isCommentOwner =
      comment?.memberUuid !== undefined &&
      sessionUser?.memberUuid !== undefined &&
      comment?.memberUuid === sessionUser?.memberUuid;
    const isFeedOwnder = sessionUser?.memberUuid === commentTarget?.authorId;
    const isSubComment =
      comment?.parentCommentId !== undefined && comment?.parentCommentId > 0;
    const isBestPick = comment?.bestPickYn === "Y";

    const optinoTypesToShow = () => {
      const optionList: OptionType[] = [];
      if (isCommentOwner) {
        optionList.push("DELETE");
      }

      if (
        isFeedOwnder &&
        !isSubComment &&
        !isCommentOwner &&
        !isBestPick &&
        comment.commentTypeCode !== CommentTypeCode.ARTICLE
      ) {
        optionList.push("BEST_PICK");
      }

      if (isCommentOwner) {
        optionList.push("MODIFY");
      }

      if (!isCommentOwner) {
        optionList.push("REPORT");
      }

      return optionList;
    };

    const handleAction = (type: OptionType) => {
      onAction && onAction(type);
    };

    return (
      <ContentsPopupMenu
        optionTypesToShow={optinoTypesToShow()}
        onMenuClick={handleAction}
        needAuth
      />
    );
  }
);

export default CommentOptionBox;
