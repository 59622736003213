import React, { memo, ReactNode } from "react";
import "./AbListCard.scss";

import { AbDetail, AbVoteResult } from "../../../api/ab/model";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { AbTypeCode } from "../../../constants/ab";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { useTranslation } from "react-i18next";
import AbEndDateBadge from "./AbEndDateBadge";
import useAb from "../hook/UseAb";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
// import { TouchableOpacity } from "react-native-gesture-handler";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import { isPast } from "../../../utils/datetime";

import { track } from "../../../hooks/tracker/TrackFunction";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

export interface AbItemProps {
  index: number;
  ab: AbDetail;
  onVoteSuccess?: (res: AbVoteResult, type: AbTypeCode) => void;
  isFirst?: boolean;
  actSectionCode: ActSectionCode;
}

const AbCardWrapper = ({
  resultOpened,
  isFirst,
  children,
  onClick,
}: {
  isFirst: boolean;
  resultOpened: boolean;
  children: ReactNode;
  onClick: () => void;
}) => {
  return resultOpened ? (
    <div
      className="ab_horizontal_item"
      style={isFirst ? { marginLeft: "4.46vw" } : {}}
      onClick={onClick}
    >
      {children}
    </div>
  ) : (
    <div
      className="ab_horizontal_item"
      style={isFirst ? { marginLeft: "4.46vw" } : {}}
    >
      {children}
    </div>
  );
};

const AbCard = ({ index, ab, actSectionCode, onVoteSuccess }: AbItemProps) => {
  const {
    totalVoteCount,
    percentOfA,
    status,
    myChoice,
    handleAbPress,
    resultOpened,
  } = useAb(ab, onVoteSuccess);
  const isFirst = index === 0;
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const { t } = useTranslation();

  const handleAuthorPress = () => {
    goTo(`/user/${ab.nickname}`, {
      state: {
        targetUserId: ab.createMemberUuid,
        nickname: ab.nickname,
        profileUrl: ab.profileUrl,
        profileBackgroundColor: ab.profileBackgroundColor,
      },
    });
  };

  const handleNativageToDetailScreen = () => {
    goTo(`/AB/${ab.feedId}`, {
      state: { feedId: ab.feedId, userId: ab.createMemberUuid, index: index },
    });

    const actHistory: ActHistoryRequest = {
      actHistoryTypeCode: ActHistoryTypeCode.AB_READ,
      actHistoryTargetId: ab.feedId.toString(),
      actPageCode: ActPageCode.AB_TOTAL,
      actSectionCode: actSectionCode,
      attr1: index.toString(),
    };
    switch (actSectionCode) {
      case ActSectionCode.POPULAR_AB:
        actHistory.attr2 = isPast(ab.feedEndDate) ? "Y" : "N";
        actHistory.attr3 = ab.commentCount.toString();
        break;
      case ActSectionCode.EXPIRED_AB:
        actHistory.attr2 = ab.commentCount.toString();
        break;
    }
    void sendActHistory(actHistory);
  };

  return (
    <AbCardWrapper
      isFirst={isFirst}
      resultOpened={resultOpened}
      onClick={handleNativageToDetailScreen}
    >
      <div className="ab_horizontal_header">
        <div
          className="ab_horizontal_left"
          // TODO : 터치이벤트처리
          // onStartShouldSetResponder={(event) => true}
          // onTouchEnd={(e) => {
          //   e.stopPropagation();
          // }}
        >
          <TouchableWithAuthCheck onPress={handleAuthorPress}>
            <FastImageWithFallback
              aria-label="Ab writer's profile image"
              source={{
                uri: `${PUBLIC_BUCKET_URL}${ab.profileUrl || ""}`,
              }}
              className="profile_image"
            />
          </TouchableWithAuthCheck>
          <div
            className="ab_horizontal_info"
            // TODO : 터치이벤트처리
            // onStartShouldSetResponder={(event) => true}
            // onTouchEnd={(e) => {
            //   e.stopPropagation();
            // }}
          >
            <TouchableWithAuthCheck onPress={handleAuthorPress}>
              <p
                aria-label="Ab writer's nickname"
                className="ab_horizontal_header_text"
              >
                {ab.nickname}
              </p>
            </TouchableWithAuthCheck>
            <div className="text_bar" />
            <p
              aria-label="Ab vote total count"
              className="ab_horizontal_header_text"
            >
              {t("screen.ab.label.voteCount", { count: totalVoteCount })}
            </p>
          </div>
        </div>
        <AbEndDateBadge endDate={ab.feedEndDate} />
      </div>
      {/* 웹전환: for all user */}
      <button
        onClick={(e) => {
          e.stopPropagation();
          handleNativageToDetailScreen();
        }}
      >
        <div className="ab_horizontal_contents">
          <p
            aria-label={`Ab title${index}`}
            className="ab_horizontal_contents_text"
          >
            {ab.feedTitle}
          </p>
        </div>
      </button>
      <div className="ab_horizontal_select" id="AbListCard">
        {[AbTypeCode.A, AbTypeCode.B].map((option) => {
          const optionIsChoosen = status !== "EXPIRED" && myChoice === option;
          const isOptionA = option === AbTypeCode.A;
          const label = isOptionA ? ab.adesc : ab.bdesc;
          const filePath = isOptionA ? ab.afilePath : ab.bfilePath;
          const thumbnailFilePath = isOptionA
            ? ab.athumbnailFilePath
            : ab.bthumbnailFilePath;
          const percent = isOptionA ? percentOfA : 100 - percentOfA;

          return (
            <TouchableWithAuthCheck
              className={[
                isOptionA ? "select_A" : "select_B",
                resultOpened ? "result_opened" : "result_not_opened",
                optionIsChoosen ? "selected" : "",
                thumbnailFilePath ? "has_thumbnail" : "has_no_thumbnail",
              ].join(" ")}
              style={{ flex: 1, aspectRatio: 1, position: "relative" }}
              key={`${ab.feedId || ""}-${option || ""}`}
              aria-label={`${option || ""} vote button`}
              // disabled={resultOpened}
              onPress={() => {
                if (!resultOpened)
                  void handleAbPress(option).then(() => {
                    track("click_ab_card", {
                      content_id: ab.feedId,
                      content_title: ab.feedTitle,
                      content_order: index,
                    });
                  });
              }}
            >
              <FastImageWithFallback
                aria-label={`${option || ""} image`}
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${thumbnailFilePath || ""}`,
                }}
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${filePath || ""}`}
                className={
                  isOptionA ? "left_horizontal_image" : "right_horizontal_image"
                }
                // style={[styles.ab_horizontal_image, stylesByPosition.image]}
                renderFallbackNode={({ children, style }) => {
                  return (
                    <div>
                      <div
                        style={{
                          backgroundColor: isOptionA ? "#fcfafc" : "#fafbfc",
                          width: 160,
                          height: 160,
                        }}
                        className="ab_horizontal_image"
                      >
                        {resultOpened ? (
                          <>
                            <div
                              style={{
                                backgroundColor: isOptionA
                                  ? "#fcfafc"
                                  : "#fafbfc",
                                width: 160,
                                height: 160,
                              }}
                            />
                            {optionIsChoosen ? (
                              <CheckIcon />
                            ) : (
                              <div
                                className={
                                  isOptionA ? "left_inner" : "right_inner"
                                }
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                <p
                                  className="ab_horizontal_select_text2"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {label}
                                </p>

                                <p
                                  aria-label={`percent of ${option || ""}`}
                                  className="ab_horizontal_select_text4"
                                >{`${parseFloat(
                                  percent.toFixed(1)
                                ).toString()}%`}</p>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="container_ab_wrap">
                            <div
                              className="ab_wrap"
                              style={
                                optionIsChoosen || resultOpened
                                  ? { backgroundColor: "#c4c4c4" }
                                  : isOptionA
                                  ? { backgroundColor: "#c07bca" }
                                  : { backgroundColor: "#7094bd" }
                              }
                            >
                              <p className="select_text">{option}</p>
                            </div>
                            <div className="select_text_wrap">
                              <p
                                style={{
                                  fontSize: 14,
                                  letterSpacing: -0.56,
                                  fontWeight: "500",
                                  color: isOptionA ? "#c07bca" : "#7094bd",
                                }}
                              >
                                {label}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }}
              />
              <div className={isOptionA ? "left_inner" : "right_inner"}>
                {optionIsChoosen ? (
                  <div className="check_icon">
                    <CheckIcon />
                  </div>
                ) : (
                  <p className="ab_horizontal_select_text1">{option}</p>
                )}
                <p className="ab_horizontal_select_text2">{label}</p>

                {resultOpened && (
                  <p
                    aria-label={`percent of ${option || ""}`}
                    className="ab_horizontal_select_text4"
                  >{`${parseFloat(percent.toFixed(1)).toString()}%`}</p>
                )}
              </div>
            </TouchableWithAuthCheck>
          );
        })}
      </div>
    </AbCardWrapper>
  );
};

const memoisedAbCard = memo(
  AbCard,
  (prev, next) =>
    prev.ab.feedId === next.ab.feedId &&
    prev.ab.feedEndDate === next.ab.feedEndDate &&
    prev.ab.avoteCount === next.ab.avoteCount &&
    prev.ab.bvoteCount === next.ab.bvoteCount &&
    prev.ab.myAbFlag === next.ab.myAbFlag
);

export default memoisedAbCard;
memoisedAbCard.displayName = "memoisedAbCard";
