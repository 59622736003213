export enum Color {
  DEFAULT = "#000000",
  DEFAULT_TRANS = "#00000080",
  PRIMARY = "#fe5746",
  PRIMARY_TRANS = "#fe5746",
  SECONDARY = "#fe5746",
  SECONDARY_TRANS = "fe5746",
  PURPLE = "#6c2773",
  LIGHT_PURPLE = "#d8b8d8",
  WHITE = "#ffffff",
  GREY = "#505050",
  LIGHT_GREY = "#999DA0",
}
