import { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import {
  BrandCommunityProductList,
  BrandCommunityProductSearchWord,
  BrandCommunityProductListFilter,
} from "./presenter";
import { Styles } from "../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../assets/styles";
import { ReactComponent as SearchIcon } from "../../assets/icons/search1.svg";
import { ReactComponent as ClosePurple } from "../../assets/icons/close_purple_background.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { goTo, goBack } from "../../hooks/navigate/NavigateFunction";
import BrandCommunityProductListStore from "../../store/BrandCommunityProductListStore";
import CommonStore from "../../store/CommonStore";
import MyAppliancesStore from "../../store/MyAppliancesStore";
import CustomBottomSheet from "../../components/common/CustomBottomSheet";
import FlatListWithStickyTabs from "../../components/common/FlatListWithStickyTabs";
import { SearchSortType } from "../../constants/Search";
import PostsListHeader from "../../components/headers/PostsListHeader";

const BrandCommunityProductListScreen = observer((data: any) => {

  const { t } = useTranslation();
  const location = useLocation();
  const params = location.state;

  const brandCommId: number = (useParams()?.brandCommunityId || params?.brandCommunityId) as number;
  const brandCommName: string = useParams()?.brandCommunityName || params?.brandCommunityName;
  const memberUuid: string = useParams()?.targetMemberUuid || params?.targetMemberUuid;
  const brandCommunitySubscriptionYn: string = useParams()?.brandCommunitySubscriptionYn || params?.brandCommunitySubscriptionYn;

  const {
    sort,
    toastMessage,
    keyword,
    showWord,
    showSortBox,
    showResult,
    setKeyword,
    setBrandCommunityId,
    setBrandCommunityName,
    setRecentWord,
    getSearchResult,
    setShowWord,
    setShowResult,
    setShowSortBox,
    setSort,
    setBrandCommunitySubscriptionYn,
  } = BrandCommunityProductListStore;

  const { setTargetMemberUuid, getCategoryList, getMyProduct, clear } = MyAppliancesStore;

  const inputRef = useRef<any>(null);
  const stickyTabsRef = useRef<any>(null);

  const doSearch = (searchText?: string) => {
    if (searchText && searchText?.length > 0) {
      void setRecentWord(searchText);
      void getSearchResult(searchText, 0);
    } else {
      void setRecentWord("");
      void getSearchResult("", 0);
    }
    inputRef.current?.blur();
    setShowWord(false);
    setShowResult(true);
  };

  /* eslint-disable */
  useEffect(() => {
    if (brandCommId) setBrandCommunityId(brandCommId);
    if (brandCommName) setBrandCommunityName(brandCommName);
    if (brandCommunitySubscriptionYn) setBrandCommunitySubscriptionYn(brandCommunitySubscriptionYn);

    setTargetMemberUuid(memberUuid || "");
    void getCategoryList();
    void getMyProduct();

    doSearch();

    return () => {
      clear();
    };
  }, [brandCommId, brandCommName, brandCommunitySubscriptionYn]);
  /* eslint-enable */

  useEffect(() => {
    const { clear, getRecentWord } = BrandCommunityProductListStore;
    clear();
    void getRecentWord();
    inputRef.current?.focus();
  }, [data]);

  useEffect(() => {
    if (toastMessage !== undefined && toastMessage.trim().length > 0) {
      CommonStore.setToastOption({
        show: true,
        message1: toastMessage,
      });
      BrandCommunityProductListStore.setToast("");
    }
  }, [toastMessage]);

  const renderSearchInput = () => {
    return (
      <>
        <div style={styles.home_app_wrap}>
          <input
            style={styles.home_app}
            placeholder={t("screen.productAdd.label.keywordPlaceholder")}
            // placeholderTextColor={"#999999"}
            value={keyword}
            ref={inputRef}
            onKeyDown={(e) => {
              if (e.key === "Enter" && keyword) {
                doSearch(keyword.trim());
              }
            }}
            onChange={(e) => {
              let text = e.target.value;
              setKeyword(text);
              setShowResult(false);
              setShowWord(text?.length > 0);
            }}
          />
          <button
            style={{ display: keyword ? "flex" : "none", ...styles.close_purple }}
            onClick={() => {
              setKeyword("");
              setShowWord(false);
              setShowResult(false);
            }}
          >
            <ClosePurple />
          </button>
          <button
            style={styles.search_icon}
            onClick={() => doSearch(keyword)}
          >
            <SearchIcon />
          </button>
        </div>
      </>
    );
  };

  return (
    <div
      style={{
        ...FlexBoxs.flex_1,
        ...Colors.background_fff,
      }}
    >
      <PostsListHeader
        title={t("screen.brandCommunity.label.productList")}
        goBack={goBack}
        wrapStyle={{ backgroundColor: 'white', zIndex: 999 }}
      />
      {renderSearchInput()}
      <FlatListWithStickyTabs
        contentScrollRef={stickyTabsRef}
        stickyItems={<><BrandCommunityProductListFilter /></>}
        listFooterComponent={
          <>
            <div style={styles.search}>
              {/** 검색어 미입력 시 전체목록 노출 **/}
              {!showWord && !showResult && <BrandCommunityProductList />}
              {/** 검색어 자동 완성 **/}
              {showWord && <BrandCommunityProductSearchWord />}
              {/** 검색 결과 **/}
              {showResult && <BrandCommunityProductList />}
            </div>
          </>
        }
        topButtonRight={16}
        showScrollToTop={true}
      />

      <CustomBottomSheet
        snapHeight={200}
        open={showSortBox}
        onClose={() => setShowSortBox(false)}
      >
        <div style={styles.sheet}>
          <div style={styles.sort_header}>
            <button
              style={styles.close_btn}
              onClick={() => setShowSortBox(false)}
            >
              <CloseIcon />
            </button>
            <div style={styles.sheet_title_wrap}>
              <p style={styles.sheet_title}>
                {t("screen.brandCommunity.label.sortBy")}
              </p>
            </div>
          </div>

          <div style={styles.sort_list}>
            <button
              style={styles.sort_item}
              onClick={() => setSort(SearchSortType.LATEST)}
            >
              <p style={{ ...styles.sort_text, ...(sort === SearchSortType.LATEST ? styles.sort_active_text : {}) }}>
                {t("screen.brandCommunity.sortType.latest")}
              </p>
            </button>
            <button
              style={styles.sort_item}
              onClick={() => setSort(SearchSortType.POPULAR)}
            >
              <p style={{ ...styles.sort_text, ...(sort === SearchSortType.POPULAR ? styles.sort_active_text : {}) }}>
                {t("screen.brandCommunity.sortType.popular")}
              </p>
            </button>
          </div>
        </div>
      </CustomBottomSheet>
    </div>
  );
});

export default BrandCommunityProductListScreen;

export const styles: Styles = {
  search: {
    ...FlexBoxs.flex_1,
    ...Colors.background_fff,
  },
  home_app: {
    paddingLeft: 24,
    paddingRight: 75,
    borderRadius: 6,
    ...Colors.background_fafafa,
    ...Texts.contents_text_28,
    ...Texts.font_weight_400,
    width: '100%'
  },
  search_icon: {
    ...FlexBoxs.flex_1,
    ...Positions.absolute,
    right: '9.23%',
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    height: 44,
  },
  home_app_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    ...Spacings.padding_left_right_16,
    height: 44,
  },
  title: {
    ...Texts.contents_text_34,
    ...Spacings.margin_bottom_20,
  },
  close_purple: {
    ...Positions.absolute,
    ...Positions.zIndex_top,
    right: '17.9%',
    ...Arranges.center_h,
    height: 44,
  },
  close_btn: {
    ...Positions.absolute,
    top: 0,
    right: 0,
  },
  auto_complete_list: {
    ...Positions.relative,
    ...Spacings.padding_left_right_16,
    ...Colors.background_fff,
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_8,
    zIndex: 100,
  },
  auto_complete_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_right_left_20,
    ...Spacings.padding_top_bottom_11,
    ...Arranges.center_h,

  },
  auto_complete_text: {
    ...Texts.contents_text_3,
    ...Spacings.padding_left_6,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    lineClamp: '1',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-wrap',
    paddingBottom: 3,
    paddingRight: 2,
  },
  auto_complete_match_text: {
    ...Colors.font_a760b1,
  },
  sheet_title_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 32,
  },
  sheet_title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  sort_list: {
    ...Spacings.margin_top_16,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.vertical
  },
  sort_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_top_bottom_11,
  },
  sort_text: {
    ...Texts.contents_text_38,
    ...Colors.font_222,
  },
  sort_active_text: {
    ...Texts.font_weight_bold,
    ...Colors.font_be69c3,
  },
  sort_header: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.margin_left_right_16,
    ...Spacings.margin_top_16,
    ...Positions.relative,
  }
};
