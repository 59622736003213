import React from "react";
import { observer } from "mobx-react";
import { Colors, FlexBoxs, Spacings, Texts } from "../../../assets/styles";
import PostDetailStore from "../../../store/PostDetailStore";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { Tag } from "../../../api/tag/model";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { Styles } from "../../../assets/types/Style";
import "./PostTag.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

interface PostTag {
  feedId: number;
}

const PostTag = observer(({ feedId }: PostTag) => {
  const { postTags } = PostDetailStore;

  return (
    <div className="post-tag-wrapper">
      {postTags?.map((tag: Tag, i: number) => {
        return (
          <TouchableWithAuthCheck
            key={i}
            // style={styles.tag_item}
            className="tag_item"
            beforeOnPress={() => {
              CommonStore.callActHistory(
                ActHistoryTypeCode.TAG_CLICK,
                (tag.tagId ?? 0).toString(),
                ActPageCode.POST_DETAIL,
                undefined,
                (feedId ?? 0).toString()
              );
              goTo(`/tag/${tag.tagId}`, {
                state: { tagId: tag.tagId },
              });
            }}
          >
            <span className="tag_text">#{tag.tagName}</span>
          </TouchableWithAuthCheck>
        );
      })}
    </div>
  );
});

export default PostTag;

const styles: Styles = {
  tag_list: {
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
    ...Spacings.padding_right_left_16_responsive,
    paddingBottom: 140,
  },
  tag_item: {
    ...Spacings.padding_right_left_12,
    ...Spacings.padding_top_bottom_8,
    ...Spacings.margin_right_6,
    ...Spacings.margin_bottom_6,
    ...Spacings.border_radius_32,
    ...Colors.background_f7f4fa,
  },
  tag_text: {
    ...Texts.contents_text_37,
    ...Colors.font_a760b1,
  },
};
