import { useEffect, useState } from "react";
import {
  CustomActivityIndicator,
  TitleHeader,
} from "../../../../components/common";
import { observer } from "mobx-react";
import { HeaderItem } from "../../../../constants/Header";
import SettingStore from "../../../../store/SettingStore";
import { WithdrawTypeCode } from "../../../../constants/Withdrawal.enum";
import { WithdrawalReason } from "../../../../api/setting/notice/model";
import MemberStore from "../../../../store/MemberStore";
import { Trans, useTranslation } from "react-i18next";
import { getCurrentDate, getDateFormatKo } from "../../../../utils/datetime";
import CommonStore from "../../../../store/CommonStore";
import { removeSessionUserFromStorage } from "../../../../service/Session.service";
import { SuccessOrNot } from "../../../../constants/Common.enum";
import { clearStore } from "../../../../service/Common.service";
import NotificationModal from "../../../../components/modals/NotificationModal";
import dayjs from "dayjs";
import { withdrawalIsPossible } from "../../../../api/member/api";
import AuthStore from "../../../../store/AuthStore";
import { ReactComponent as ImgNoti } from "../../../../assets/images/img_noti.svg";
import { goToLifecareOrderListPage } from "../../../../utils/mall";
import { goTo } from "../../../../hooks/navigate/NavigateFunction";
import clsx from "clsx";
import ContentsNotificationModal from "../../../../components/modals/ContentsNotificationModal";
import Modal from "react-modal";
import classes from "./WithdrawReason.module.scss";
import CustomCheckBox from "../../../../components/common/CustomCheckBox";

const WithdrawReason = observer(() => {
  const [isModal, setIsModal] = useState(false);
  // const insets = useSafeAreaInsets();
  // const [bottomInset, setBottomInset] = useState(insets.bottom / PixelRatio.get());
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [isShowWithdrawalImpossibleModal, setShowWithdrawalImpossibleModal] =
    useState(false);
  const [isShowEcommerceNotSignupModal, setShowEcommerceNotSignupModal] =
    useState(false);

  const {
    toggleReason,
    clearWithdrawReason,
    selectedReasons,
    etcDesc,
    setEtcDesc,
    withdrawAgree,
    setWithdrawAgree,
  } = SettingStore;

  const { tryWithdrawal } = MemberStore;
  const [numberOfChecked, setnumberOfChecked] = useState<number>(0);

  // const showKeyboardActionName: KeyboardEventName = Platform.select({
  //   android: "keyboardDidShow",
  //   default: "keyboardWillShow",
  // });
  // const hideKeyboardActionName: KeyboardEventName = Platform.select({
  //   android: "keyboardDidHide",
  //   default: "keyboardWillHide",
  // });

  // const showKeyboardSubscription = Keyboard.addListener(showKeyboardActionName, () => {
  //   setBottomInset(0);
  // });
  // const hideKeyboardSubscription = Keyboard.addListener(hideKeyboardActionName, () => {
  //   setBottomInset(insets.bottom / PixelRatio.get());
  // });

  // useEffect(() => {
  //   return () => {
  //     showKeyboardSubscription.remove()
  //     hideKeyboardSubscription.remove()
  //   }
  // })

  useEffect(() => {
    return () => {
      clearWithdrawReason();
      setnumberOfChecked(0);
    };
  }, [clearWithdrawReason]);

  const withdrawReasons: WithdrawalReason[] = [
    {
      code: WithdrawTypeCode.NOT_USEFUL,
      name: t(`screen.setting.label.withdraw.reason1`),
      id: 1,
    },
    {
      code: WithdrawTypeCode.TO_USE_ANOTHER_ACCOUNT,
      name: t("screen.setting.label.withdraw.reason2"),
      id: 2,
    },
    {
      code: WithdrawTypeCode.TOO_MANY_ERRORS,
      name: t("screen.setting.label.withdraw.reason3"),
      id: 3,
    },
    {
      code: WithdrawTypeCode.TO_USE_THE_OTHER_SERVICE,
      name: t("screen.setting.label.withdraw.reason4"),
      id: 4,
    },
    {
      code: WithdrawTypeCode.NOT_ENOUGH_INFO,
      name: t("screen.setting.label.withdraw.reason5"),
      id: 5,
    },
    {
      code: WithdrawTypeCode.RARELY_USED,
      name: t("screen.setting.label.withdraw.reason6"),
      id: 6,
    },
    {
      code: WithdrawTypeCode.INCORRECT_INFO,
      name: t("screen.setting.label.withdraw.reason7"),
      id: 7,
    },
    {
      code: WithdrawTypeCode.ETC,
      name: t("screen.setting.label.withdraw.reason8"),
      id: 8,
    },
  ];

  const checkReason = (reason: WithdrawalReason) => {
    toggleReason(reason.code);
  };

  const checkValidation = () => {
    var message: string = "";
    var result: boolean = false;
    if (selectedReasons.size < 1) {
      message = t("screen.setting.message.withdrawReason.reasonTitle");
    } else if (
      selectedReasons.has(WithdrawTypeCode.ETC) &&
      etcDesc.length < 1
    ) {
      message = t("screen.setting.message.withdrawReason.reasonInfo3");
    } else if (withdrawAgree !== "Y") {
      message = t("screen.setting.message.withdrawReason.agreeTitle");
    } else {
      result = true;
    }

    CommonStore.setToastOption({
      show: !result,
      message1: message,
    });
    return result;
  };

  const tryWithdraw = () => {
    let message = t("common.error.server");

    const memberUuid = AuthStore.sessionUser?.memberUuid || "";
    setLoading(true);
    void withdrawalIsPossible(memberUuid)
      .then((result) => {
        if (result.data !== true) {
          if (result.statusCode === "INVALID_PROCESS_ERR") {
            return Promise.reject({ code: "BUSINESS_ERROR" });
          } else if (result.statusCode === "USER_NOT_FOUND") {
            // 해당 케이스 성공으로 처리
          } else {
            return Promise.reject({ code: "SERVER_ERROR" });
          }
        }
        return tryWithdrawal(selectedReasons, withdrawAgree, etcDesc);
      })
      .then((withdrawResult) => {
        if (withdrawResult?.successOrNot === SuccessOrNot.Y) {
          const date = getCurrentDate();
          message = t("screen.setting.label.withdraw.toast", { date });
          void removeSessionUserFromStorage();
          clearStore();
          goTo("/");
        }
        CommonStore.setToastOption({
          show: true,
          message1: message,
          // bottomOffset: 30,
        });
      })
      .catch((reason) => {
        if (reason.code === "BUSINESS_ERROR") {
          setShowWithdrawalImpossibleModal(true);
        } else {
          setShowEcommerceNotSignupModal(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getWithdrawDate = () => {
    const now = dayjs();
    return getDateFormatKo(now.add(30, "days").toString());
  };

  return (
    <div className={classes.withdraw_reason}>
      {isLoading && <CustomActivityIndicator isDarkSpinner={true} />}
      <div
        style={{ flex: 1 }}
        // behavior={Platform.OS == 'ios' ? 'padding' : undefined}
      >
        <TitleHeader
          title={t("screen.setting.label.withdraw.memberWithdrawal")}
          isBack={false}
          rightItem={HeaderItem.CLOSE}
          onClickClose={() => {
            goTo("/"); // 왜 메인으로 보내는가
          }}
        />
        <div className={classes.withdraw}>
          <div className={classes.withdraw_reason_select_wrap}>
            <span className={classes.withdraw_label_text}>
              {t("screen.setting.message.withdrawReason.reasonTitle")}
            </span>
            <span className={classes.withdraw_info_text}>
              {t("screen.setting.message.withdrawReason.reasonInfo1")}
            </span>
            <div className={classes.withdraw_reason_select_list}>
              {withdrawReasons.length > 0 &&
                withdrawReasons.map((reason, i) => {
                  return (
                    <div
                      key={i}
                      className={classes.withdraw_reason_select_item}
                    >
                      <CustomCheckBox
                        label={reason.name}
                        wrapperStyle={
                          reason.code === WithdrawTypeCode.ETC
                            ? { marginBottom: 0 }
                            : {}
                        }
                        labelStyle={{ fontWeight: "normal" }}
                        // iconProps={{
                        //   width: 30,
                        //   height: 30,
                        // }}
                        onChangeCheck={(selected) => {
                          if (!selected) {
                            setnumberOfChecked(numberOfChecked + 1);
                          } else {
                            setnumberOfChecked(numberOfChecked - 1);
                            if (reason.code === WithdrawTypeCode.ETC) {
                              setEtcDesc("");
                            }
                          }
                          checkReason(reason);
                        }}
                        numberOfChecked={numberOfChecked}
                      />
                      {reason.code === WithdrawTypeCode.ETC &&
                        selectedReasons.has(WithdrawTypeCode.ETC) &&
                        etcDesc.length === 0 && (
                          <div className={classes.reason_wrap}>
                            <span className={classes.waring_text}>
                              {t(
                                "screen.setting.message.withdrawReason.reasonInfo2"
                              )}
                            </span>
                          </div>
                        )}
                    </div>
                  );
                })}
            </div>
          </div>

          <div className={classes.etc_wrap}>
            <div className={classes.etc}>
              <input
                value={etcDesc}
                // editable={selectedReasons.has(WithdrawTypeCode.ETC)}
                onChange={(e) => {
                  setEtcDesc(e.target.value);
                }}
                maxLength={50}
                className={classes.etc_input}
                placeholder={t(
                  "screen.setting.message.withdrawReason.reasonInfo3"
                )}
              />
            </div>
          </div>

          <div className={classes.bar} />

          <div className={classes.footer}>
            <div className={classes.withdraw_label_text_wrap}>
              <span className={classes.withdraw_label_text}>
                {t("screen.setting.message.withdrawReason.agreeTitle")}
              </span>
            </div>
            <div className={classes.note_btn_wrap}>
              <button
                className={classes.note_btn}
                onClick={() => {
                  setIsVisible(true);
                }}
              >
                <span className={classes.note_btn_text}>
                  {t("screen.setting.message.withdrawReason.agreeInfo1")}
                </span>
              </button>
            </div>
            <div className={classes.agree_wrap}>
              <CustomCheckBox
                label={t("screen.setting.message.withdrawReason.agreeInfo2")}
                labelStyle={{ fontWeight: 500 }}
                wrapperStyle={{ marginBottom: 6 }}
                onChangeCheck={(selected) => {
                  if (!selected) {
                    setWithdrawAgree("Y");
                  } else {
                    setWithdrawAgree("N");
                  }
                }}
              />
              {withdrawAgree !== "Y" && (
                <span className={classes.agree_text2}>
                  {t("screen.setting.message.withdrawReason.agreeInfo3")}
                </span>
              )}
            </div>
          </div>
        </div>
        <button
          className={clsx(classes.confirm_btn, classes.active, { height: 56 })}
          onClick={() => {
            if (checkValidation()) {
              setIsModal(true);
            }
          }}
        >
          <span className={classes.confirm_btn_text}>
            {t("screen.setting.message.withdrawConfirmModal.confirm")}
          </span>
        </button>

        {/* [1622] 공통 팝업 */}
        {isModal && (
          <NotificationModal
            isVisible={isModal}
            contents1={t(
              "screen.setting.message.withdrawConfirmModal.areYouSure"
            )}
            useTwoButton={true}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.OK")}
            extraButtonText={t("screen.notificationModal.button.cancel")}
            onClickDefaultButton={() => {
              setIsModal(false);
              void tryWithdraw();
            }}
            onClickExtraButton={() => setIsModal(false)}
            onRequestClose={() => setIsModal(false)}
          />
        )}

        {isVisible && (
          <ContentsNotificationModal
            isVisible={isVisible}
            onRequestClose={() => setIsVisible(false)}
            title={t("screen.setting.message.withDrawlAgree.cautionTitle")}
          >
            {
              <div>
                <div className={classes.note}>
                  <div className={classes.note_contents}>
                    <div className={classes.dot} />
                    <span className={classes.note_contents_text1}>
                      <Trans
                        components={[
                          <span className={classes.note_contents_text2} />,
                          <span className={classes.note_contents_text3} />,
                        ]}
                      >
                        {t("screen.setting.message.withDrawlAgree.caution1", {
                          withdrawDate: getWithdrawDate(),
                        })}
                      </Trans>
                    </span>
                  </div>
                  <div className={classes.note_contents}>
                    <div className={classes.dot} />
                    <span className={classes.note_contents_text1}>
                      {t("screen.setting.message.withDrawlAgree.caution6")}
                    </span>
                  </div>
                  <div className={classes.note_contents}>
                    <div className={classes.dot} />
                    <span className={classes.note_contents_text1}>
                      <Trans
                        components={[
                          <span className={classes.note_contents_text3} />,
                        ]}
                      >
                        {t("screen.setting.message.withDrawlAgree.caution7", {
                          withdrawDate: getWithdrawDate(),
                        })}
                      </Trans>
                    </span>
                  </div>
                </div>
              </div>
            }
          </ContentsNotificationModal>
        )}

        <Modal
          isOpen={isShowWithdrawalImpossibleModal}
          onRequestClose={() => {
            setShowWithdrawalImpossibleModal(false);
          }}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 10000,
              maxWidth: 450,
              margin: "auto",
              position: "fixed",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            content: {
              backgroundColor: "#fff",
              position: "relative",
              borderRadius: 12,
              outline: "none",
              width: "90%",
              border: "none",
              padding: "0",
              inset: "unset",
            },
          }}
        >
          <div className={classes.centered_view}>
            <div className={classes.modal_view}>
              <div className={classes.modal_header_wrap}>
                <span className={classes.txt_header_title}>
                  {t(
                    "screen.setting.message.withdrawError.impossible.headerTitle"
                  )}
                </span>
              </div>
              <div className={classes.modal_content_wrap}>
                <ImgNoti />
                <span className={classes.txt_content_title}>
                  {t(
                    "screen.setting.message.withdrawError.impossible.contentTitle"
                  )}
                </span>
                <span className={classes.txt_content}>
                  <Trans>
                    {t(
                      "screen.setting.message.withdrawError.impossible.content"
                    )}
                  </Trans>
                </span>
                <div className={classes.bx_noti_info}>
                  <div>
                    <div className={classes.bx_cont_li}>
                      <div className={classes.txt_dot} />
                      <span
                        className={clsx(classes.txt_noti_st1, {
                          fontWeight: "700",
                        })}
                      >
                        {t(
                          "screen.setting.message.withdrawError.impossible.noti1"
                        )}
                      </span>
                    </div>
                    <div className={classes.bx_cont_li}>
                      <div className={classes.txt_dot} />
                      <span className={classes.txt_noti_st1}>
                        {t(
                          "screen.setting.message.withdrawError.impossible.noti2"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <span className={classes.txt_content}>
                  <Trans>
                    {t(
                      "screen.setting.message.withdrawError.impossible.content2"
                    )}
                  </Trans>
                </span>
              </div>
              <div className={classes.modal_footer_wrap}>
                <button
                  className={clsx(classes.btn_footer_default, { width: "60%" })}
                  onClick={goToLifecareOrderListPage}
                >
                  <span className={clsx(classes.txt_btn_footer)}>
                    {t(
                      "screen.setting.message.withdrawError.impossible.negativeButton"
                    )}
                  </span>
                </button>
                <button
                  className={clsx(
                    classes.btn_footer_default,
                    classes.btn_bg_bk,
                    { width: "40%" }
                  )}
                  onClick={() => setShowWithdrawalImpossibleModal(false)}
                >
                  <span
                    className={clsx(
                      classes.txt_btn_footer,
                      classes.txt_btn_white
                    )}
                  >
                    {t(
                      "screen.setting.message.withdrawError.impossible.positiveButton"
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={isShowEcommerceNotSignupModal}
          onRequestClose={() => {
            setShowEcommerceNotSignupModal(false);
          }}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 10000,
              maxWidth: 450,
              margin: "auto",
              position: "fixed",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            content: {
              backgroundColor: "#fff",
              position: "relative",
              borderRadius: 12,
              outline: "none",
              width: "90%",
              border: "none",
              padding: "0",
              inset: "unset",
            },
          }}
        >
          <div className={classes.centered_view}>
            <div className={classes.modal_view}>
              <div className={classes.modal_header_wrap}>
                <span className={classes.txt_header_title}>
                  {t(
                    "screen.setting.message.withdrawError.commerceNotSignedUp.title"
                  )}
                </span>
              </div>
              <div className={classes.modal_content_wrap}>
                <ImgNoti />
                <span className={classes.txt_content_title}>
                  {t(
                    "screen.setting.message.withdrawError.commerceNotSignedUp.headerTitle"
                  )}
                </span>
                <span className={classes.txt_content}>
                  <Trans>
                    {t(
                      "screen.setting.message.withdrawError.commerceNotSignedUp.content"
                    )}
                  </Trans>
                </span>
              </div>
              <div className={classes.modal_footer_wrap}>
                <button
                  className={clsx(
                    classes.btn_footer_default,
                    classes.btn_bg_bk,
                    { width: "100%" }
                  )}
                  onClick={() => setShowEcommerceNotSignupModal(false)}
                >
                  <span
                    className={clsx(
                      classes.txt_btn_footer,
                      classes.txt_btn_white
                    )}
                  >
                    {t(
                      "screen.setting.message.withdrawError.commerceNotSignedUp.button"
                    )}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
});

export default WithdrawReason;
