import { callAPIWithGETMethod } from "../common/api";
import { UserVoltHistoryResponse, UserVoltInfo } from "./model";
import { EventTypeCode } from "../../constants/Volt.enum";

export const getMyVolt = async (memberUuid: string, isLoading = true): Promise<UserVoltInfo> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/member/volt",
      params: { memberUuid },
    },
    isLoading
  );

  return (response?.successOrNot === "Y" ? response?.data : null) as UserVoltInfo;
};

export const getMyVoltHistory = async (
  memberUuid: string,
  eventTypeCode?: EventTypeCode,
  pageSize?: number,
  cursor?: number,
  isLoading = true
): Promise<UserVoltHistoryResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/member/volt/history",
      params: { memberUuid, eventTypeCode, pageSize, cursor },
    },
    isLoading
  );

  return (response?.successOrNot === "Y" ? response?.data : null) as UserVoltHistoryResponse;
};
