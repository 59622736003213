export enum ShareScreenCode {
  POST_DETAIL_SCREEN = "PostDetailScreen",
  PRODUCT_DETAIL_SCREEN = "productDetailScreen",
  QNA_DETAIL_SCREEN = "QnaDetailScreen",
  AB_DETAIL_SCREEN = "AbDetailScreen",
  MISSION_DETAIL_SCREEN = "MissionDetailScreen",
  MAGAZINE_DETAIL_SCREEN = "MagazineDetailScreen",
  QA_SCREEN = "QaScreen",
  ALARM_SCREEN = "AlarmScreen",
  LOGIN_ATTRACT_SCREEN = "LoginAttractScreen",
  POST_WRITE = "PostWriteScreen",
  ARTICLE_DETAIL_SCREEN = "ArticleDetailScreen",
  FRIEND_RECOMMEND_SCREEN = "FriendRecommendScreen",
  LIFECARE_SCREEN = "LifecareScreen",
  QNA_SCREEN = "QnaScreen",
  MAGAZINE_SCREEN = "MagazineScreen",
}
