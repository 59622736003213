import React, { useState, useEffect, useCallback, CSSProperties } from "react";

import "./AccountPost.scss";
import { Arranges, FlexBoxs, Positions } from "../../../assets/styles";
import { AccountFeedTypeCode, FeedUIList } from "../../../constants/Account";
// import { push } from "../../../navigation/NavigationFunction";
import { observer } from "mobx-react";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { AccountFeedItem } from "../../../api/account/model";
import { Virtuoso } from "react-virtuoso";
import EmptyFeed from "./EmptyFeed";
import MyHomeStore from "../../../store/MyHomeStore";
// import { CustomActivityIndicator } from "../../../components/common";
import { ActHistoryRequest } from "../../../api/data/model";
import { sendActHistory } from "../../../api/data/api";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import AuthStore from "../../../store/AuthStore";

import { ReactComponent as VideoIcon } from "../../../assets/icons/grid_video.svg";
import { ReactComponent as MultiImageIcon } from "../../../assets/icons/grid_multi.svg";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CommonStore from "../../../store/CommonStore";
import { useWindowScroll } from "react-use";
import { useTranslation } from "react-i18next";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";

const AccountPost = observer(
  ({
    isMy,
    userId,
    dataRefresh,
    contentScrollRef,
    endReached,
  }: {
    isMy: boolean;
    userId: string;
    dataRefresh?: () => void;
    contentScrollRef?: any;
    endReached: () => void;
  }) => {
    const {
      getAccountStore,
      selectedFeedId,
      setSelectedFeedId,
      updatedPost,
      setUpdatedPost,
    } = MyHomeStore;
    const widthApp = CommonStore.widthApp;
    const { sessionUser } = AuthStore;
    const accountStore = getAccountStore(userId);
    const [feedList, setFeedList] = useState<FeedUIList[]>([]);
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    const { t } = useTranslation();

    const [isScreenFocused, setIsScreenFocused] = useState(!document.hidden);
    useEffect(() => {
      setFeedList([]);
      accountStore && remakePostFeedData(accountStore?.postList.slice(), 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, accountStore, accountStore?.postList, isScreenFocused]);

    // const { y } = useWindowScroll();

    // useEffect(() => {
    //   const bottom =
    //     window.scrollY !== 0 &&
    //     Math.ceil(window.innerHeight + window.scrollY) >=
    //       document.documentElement.scrollHeight;

    //   if (bottom && accountStore?.postCanLoadMore) {
    //     accountStore?.getAccountFeed(
    //       accountStore?.postPageIndex + 1,
    //       AccountFeedTypeCode.POST
    //     );
    //     accountStore?.setPostPageIndex(accountStore?.postPageIndex + 1);
    //   }
    // }, [y]);

    const handleMoreRequest = useCallback(() => {
      if (accountStore?.postCanLoadMore) {
        accountStore?.getAccountFeed(
          accountStore?.postPageIndex + 1,
          AccountFeedTypeCode.POST
        );
        accountStore?.setPostPageIndex(accountStore?.postPageIndex + 1);
      }
    }, [accountStore.getAccountFeed, accountStore.setPostPageIndex]);

    useEffect(() => {
      if (isScreenFocused && selectedFeedId >= 0 && updatedPost) {
        setFeedList((prev) => {
          const newFeedList = [...prev];
          for (const feedUIs of feedList) {
            for (const data of feedUIs.data) {
              if (data.feedId === selectedFeedId) {
                data.thumbnailFilePath = updatedPost.thumbnailFilePath;
              }
            }
          }
          return newFeedList;
        });
        setSelectedFeedId(-1);
        setUpdatedPost(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isScreenFocused]);

    const remakePostFeedData = useCallback(
      (list: AccountFeedItem[], start: number) => {
        // 6개 단위로 자르기
        const tempTotalList: FeedUIList[] = [];
        let tempList: AccountFeedItem[] = [];
        let feedLength = 0;
        let feedInsideLength = 6;
        let calcIndex = 0;

        if (start > 0) {
          feedLength = feedList?.length;
          feedInsideLength = feedList[feedLength - 1]?.data?.length;
        }

        list.forEach((item, index) => {
          if (
            feedInsideLength > 0 &&
            feedInsideLength < 6 &&
            index < 6 - feedInsideLength
          ) {
            if (index == 0) {
              tempList = feedList[feedLength - 1].data;
              setFeedList((prevArray) => [
                ...prevArray.slice(0, feedLength - 1),
              ]);
            }
            tempList.push(item);

            if (index === 6 - feedInsideLength - 1) {
              tempTotalList.push({
                sectionIndex: feedLength - 1,
                data: tempList,
              });
              tempList = [];
            }
          } else {
            tempList.push(item);
            calcIndex = index - (6 - feedInsideLength);
            if (calcIndex % 6 == 5 || index == list.length - 1) {
              tempTotalList.push({
                sectionIndex: feedList.length,
                data: tempList,
              });
              tempList = [];
            }
          }
        });

        // setTimeout(() => {
        tempTotalList.forEach((item) => {
          setFeedList((prevArray) => [...prevArray, item]);
        });
        // }, 1000)
      },
      [feedList]
    );

    const moveToPostDetail = (idx: number, seq: number) => {
      setSelectedFeedId(feedList[idx].data[seq].feedId);

      // TODO : FeedScreen으로 이동
      goTo("/posts/feed", {
        state: {
          itemIndex: idx * 6 + seq,
          feedParentTypeCode: "MYHOME_POSTS",
          extraData: { accountScreenOwnerId: userId },
        },
      });

      if (userId !== sessionUser?.memberUuid) {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.POST_READ,
          actHistoryTargetId: feedList[idx].data[seq].feedId.toString(),
          actPageCode: ActPageCode.OTHER_USER_HOME,
          actSectionCode: ActSectionCode.POST,
          attr1: userId,
        };
        void sendActHistory(actHistory);
      }
    };

    const addContentsIcon = (idx: number, seq: number) => {
      const imageCount =
        (feedList[idx].data[seq].imageCount !== undefined &&
          feedList[idx].data[seq].imageCount) ||
        0;
      const videoCount =
        (feedList[idx].data[seq].videoCount !== undefined &&
          feedList[idx].data[seq].videoCount) ||
        0;
      return (
        <>
          {imageCount + videoCount > 1 ? (
            <div className="image_icon" aria-label="Multi contents">
              <MultiImageIcon />
            </div>
          ) : (
            <>
              {imageCount == 0 && videoCount > 0 && (
                <div className="image_icon" aria-label="Video">
                  <VideoIcon />
                </div>
              )}
            </>
          )}
        </>
      );
    };

    const getImageThumbnailPath = (idx: number, seq: number) => {
      return feedList[idx].data[seq].thumbnailFilePath || "";
    };

    const getImageOriginalPath = (idx: number, seq: number) => {
      return feedList[idx].data[seq].originalFilePath || "";
    };

    const getSizeImageStyle = (
      className: string
    ): CSSProperties | undefined => {
      switch (className) {
        case "data_item_right":
        case "data_item":
          return {
            width: `calc(${widthApp / 3}px - 2px)`,
            height: `calc(${widthApp / 3}px - 2px)`,
          };

        case "data_big_item":
        case "data_big_item_right":
          return {
            width: `calc(${(widthApp / 3) * 2}px - 1px)`,
            height: `calc(${(widthApp / 3) * 2}px - 1px)`,
          };
        default:
          return undefined;
      }
    };

    const normalGrid = (idx: number, seq: number) => {
      return (
        <div
          onClick={() => moveToPostDetail(idx, seq)}
          key={`${idx}_${seq}`}
          aria-label={`Post feed item ${idx}_${seq}`}
          className="image_wrapper"
        >
          <FastImageWithFallback
            className={seq % 3 != 2 ? "data_item_right" : "data_item"}
            style={getSizeImageStyle(
              seq % 3 != 2 ? "data_item_right" : "data_item"
            )}
            source={{
              uri: `${PUBLIC_BUCKET_URL}${
                getImageThumbnailPath(idx, seq) || ""
              }`,
            }}
            fallbackImageUri={`${PUBLIC_BUCKET_URL}${
              getImageOriginalPath(idx, seq) || ""
            }`}
          />
          {addContentsIcon(idx, seq)}
        </div>
      );
    };

    const rightBigGird = (idx: number) => {
      return (
        <>
          <div
            onClick={() => moveToPostDetail(idx, 0)}
            aria-label={`Post feed item ${idx}_0`}
            className="image_wrapper"
          >
            <FastImageWithFallback
              className="data_item_right"
              style={getSizeImageStyle("data_item_right")}
              source={{
                uri: `${PUBLIC_BUCKET_URL}${
                  getImageThumbnailPath(idx, 0) || ""
                }`,
              }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                getImageOriginalPath(idx, 0) || ""
              }`}
            />
            {addContentsIcon(idx, 0)}
          </div>
          <div
            onClick={() => moveToPostDetail(idx, 1)}
            aria-label={`Post feed item ${idx}_1`}
            className="image_wrapper"
          >
            <FastImageWithFallback
              className="data_item_right"
              style={getSizeImageStyle("data_item_right")}
              source={{
                uri: `${PUBLIC_BUCKET_URL}${
                  getImageThumbnailPath(idx, 1) || ""
                }`,
              }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                getImageOriginalPath(idx, 1) || ""
              }`}
            />
            {addContentsIcon(idx, 1)}
          </div>
          <div
            onClick={() => moveToPostDetail(idx, 2)}
            aria-label={`Post feed item ${idx}_2`}
            className="image_wrapper"
          >
            <FastImageWithFallback
              className="data_item"
              style={getSizeImageStyle("data_item")}
              source={{
                uri: `${PUBLIC_BUCKET_URL}${
                  getImageThumbnailPath(idx, 2) || ""
                }`,
              }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                getImageOriginalPath(idx, 2) || ""
              }`}
            />
            {addContentsIcon(idx, 2)}
          </div>
          <div className="data_wrap">
            <div className="data_margin_right">
              <div
                onClick={() => moveToPostDetail(idx, 3)}
                aria-label={`Post feed item ${idx}_3`}
                className="image_wrapper"
              >
                <FastImageWithFallback
                  className="data_item"
                  style={getSizeImageStyle("data_item")}
                  source={{
                    uri: `${PUBLIC_BUCKET_URL}${
                      getImageThumbnailPath(idx, 3) || ""
                    }`,
                  }}
                  fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                    getImageOriginalPath(idx, 3) || ""
                  }`}
                />
                {addContentsIcon(idx, 3)}
              </div>
              <div
                onClick={() => moveToPostDetail(idx, 4)}
                aria-label={`Post feed item ${idx}_4`}
                className="image_wrapper"
              >
                <FastImageWithFallback
                  className="data_item"
                  style={getSizeImageStyle("data_item")}
                  source={{
                    uri: `${PUBLIC_BUCKET_URL}${
                      getImageThumbnailPath(idx, 4) || ""
                    }`,
                  }}
                  fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                    getImageOriginalPath(idx, 4) || ""
                  }`}
                />
                {addContentsIcon(idx, 4)}
              </div>
            </div>
            <div
              onClick={() => moveToPostDetail(idx, 5)}
              aria-label={`Post feed item ${idx}_5`}
              className="image_wrapper"
            >
              <FastImageWithFallback
                className="data_big_item"
                style={getSizeImageStyle("data_big_item")}
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${
                    getImageThumbnailPath(idx, 5) || ""
                  }`,
                }}
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                  getImageOriginalPath(idx, 5) || ""
                }`}
              />
              {addContentsIcon(idx, 5)}
            </div>
          </div>
        </>
      );
    };

    const leftBigGird = (idx: number) => {
      return (
        <>
          <div
            onClick={() => moveToPostDetail(idx, 0)}
            aria-label={`Post feed item ${idx}_0`}
            className="image_wrapper"
          >
            <FastImageWithFallback
              className="data_item_right"
              style={getSizeImageStyle("data_item_right")}
              source={{
                uri: `${PUBLIC_BUCKET_URL}${
                  getImageThumbnailPath(idx, 0) || ""
                }`,
              }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                getImageOriginalPath(idx, 0) || ""
              }`}
            />

            {addContentsIcon(idx, 0)}
          </div>
          <div
            onClick={() => moveToPostDetail(idx, 1)}
            aria-label={`Post feed item ${idx}_1`}
            className="image_wrapper"
          >
            <FastImageWithFallback
              className="data_item_right"
              style={getSizeImageStyle("data_item_right")}
              source={{
                uri: `${PUBLIC_BUCKET_URL}${
                  getImageThumbnailPath(idx, 1) || ""
                }`,
              }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                getImageOriginalPath(idx, 1) || ""
              }`}
            />

            {addContentsIcon(idx, 1)}
          </div>
          <div
            onClick={() => moveToPostDetail(idx, 2)}
            aria-label={`Post feed item ${idx}_2`}
            className="image_wrapper"
          >
            <FastImageWithFallback
              className="data_item"
              style={getSizeImageStyle("data_item")}
              source={{
                uri: `${PUBLIC_BUCKET_URL}${
                  getImageThumbnailPath(idx, 2) || ""
                }`,
              }}
              fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                getImageOriginalPath(idx, 2) || ""
              }`}
            />

            {addContentsIcon(idx, 2)}
          </div>
          <div className="data_reverse_wrap">
            <div>
              <div
                onClick={() => moveToPostDetail(idx, 3)}
                aria-label={`Post feed item ${idx}_3`}
                className="image_wrapper"
              >
                <FastImageWithFallback
                  className="data_item"
                  style={getSizeImageStyle("data_item")}
                  source={{
                    uri: `${PUBLIC_BUCKET_URL}${
                      getImageThumbnailPath(idx, 3) || ""
                    }`,
                  }}
                  fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                    getImageOriginalPath(idx, 3) || ""
                  }`}
                />

                {addContentsIcon(idx, 3)}
              </div>
              <div
                onClick={() => moveToPostDetail(idx, 4)}
                aria-label={`Post feed item ${idx}_4`}
                className="image_wrapper"
              >
                <FastImageWithFallback
                  className="data_item"
                  style={getSizeImageStyle("data_item")}
                  source={{
                    uri: `${PUBLIC_BUCKET_URL}${
                      getImageThumbnailPath(idx, 4) || ""
                    }`,
                  }}
                  fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                    getImageOriginalPath(idx, 4) || ""
                  }`}
                />
                {addContentsIcon(idx, 4)}
              </div>
            </div>
            <div
              onClick={() => moveToPostDetail(idx, 5)}
              aria-label={`Post feed item ${idx}_5`}
              className="image_wrapper"
            >
              <FastImageWithFallback
                className="data_big_item_right"
                style={getSizeImageStyle("data_big_item_right")}
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${
                    getImageThumbnailPath(idx, 5) || ""
                  }`,
                }}
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                  getImageOriginalPath(idx, 5) || ""
                }`}
              />
              {addContentsIcon(idx, 5)}
            </div>
          </div>
        </>
      );
    };
    const renderGrid = (index: number, item: FeedUIList) => {
      return (
        <div
          className="data_list_row"
          aria-label={`Account Post List Item ${index}`}
        >
          {item.data.length < 6
            ? item.data.map((item: any, seq: number) => normalGrid(index, seq))
            : index % 2 == 1
            ? rightBigGird(index)
            : leftBigGird(index)}
        </div>
      );
    };

    return (
      <div className="account-post-list-wrapper">
        {feedList.length > 0 ? (
          <>
            {feedList.map((item: any, index: number) => {
              return renderGrid(index, item);
            })}
            <InfiniteScrollObserver onObserver={handleMoreRequest} />
          </>
        ) : (
          <EmptyFeed
            isMy={isMy}
            type={AccountFeedTypeCode.POST}
            dataRefresh={() => {
              dataRefresh && dataRefresh();
            }}
          />
        )}
      </div>
    );
  }
);

export default AccountPost;
