import { routerInfo } from '../Router'
import { goTo } from '../hooks/navigate/NavigateFunction'
import CommonStore from '../store/CommonStore'
import MissionStore from '../store/MissionStore'
import { onShareByCustomLink } from '../utils/share'
import { t } from 'i18next'

export enum WebViewActionType {
  KAKAO_SHARE = 'kakao_share',
  URL_SHARE = 'url_share',
  COPY_NICKNAME = 'copy_nickname',
  APPLY = 'apply',
  COPY_TEXT = 'COPY_TEXT',
  SHARE_CUSTOM_LINK = 'SHARE_CUSTOM_LINK',
  SET_HEIGHT = 'SET_HEIGHT',
  OUTLINK = 'OUTLINK',
  IN_APP_BROWSER = 'IN_APP_BROWSER',
  NAVIGATE = 'NAVIGATE_WITH_PARAM',
}

// 공통으로 처리되었으면 true, 아니면 false
export function postMessageCommon(jsonString: any) {
  const params = JSON.parse(jsonString)
  const type = params.type
  const payload = params.payload

  switch (type) {
    case 'NEED_CONTENTS_CLICK_CHECK':
      MissionStore.setNeedContentClick(true)
      break
    case 'WEBVIEW_CONTENTS_CLICK_EVENT':
      MissionStore.setContentClick(true)
      window.location.href = payload.url
      break
    case WebViewActionType.NAVIGATE:
      navigateWithParam(payload)
      break
    case WebViewActionType.SHARE_CUSTOM_LINK:
      onShareByCustomLink(payload.url)
      break
    case WebViewActionType.OUTLINK:
    case WebViewActionType.IN_APP_BROWSER:
      window.location.href = payload.url
      break
    case WebViewActionType.COPY_NICKNAME:
    case WebViewActionType.COPY_TEXT:
      if (navigator.clipboard) {
        navigator.clipboard.writeText(payload.text).then(() => {
          CommonStore.setToastOption({
            show: true,
            message1: t('screen.mission.message.copyCompelete2'),
          })
        })
      }
      break
    default:
      return false
  }

  return true
}

class WebViewInterface {
  public postMessage(jsonString: any) {
    // postMessageCommon(jsonString)
  }
}

function navigateWithParam(payload: any) {
  const screen = payload.screenName
  if (!isRouterExistScreen(screen)) {
    // 미구현 화면 앱설치 유도
    CommonStore.setShowDownloadAppDrive(true)
  } else {
    goTo(`/${payload.screenName}`, { state: payload.param })
  }
}

function isRouterExistScreen(screen: string) {
  return (
    routerInfo.filter((value) => {
      return value.path.includes(`/${screen}`)
    }).length > 0
  )
}

const instance = new WebViewInterface()
export default instance
