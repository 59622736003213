import { useRef } from "react";
import { ReactComponent as CommentIcon } from "../../../assets/icons/new_comment_gray.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/new_heart_off.svg";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { BrandCommunityPopularArticleResponse } from "../../../api/brand/model";
import { ReactComponent as ViewIcon } from "../../../assets/icons/icon_view.svg";
import { ReactComponent as BtnMore } from "../../../assets/icons/icon_more_arrow.svg";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CommonStore from "../../../store/CommonStore";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { Virtuoso } from "react-virtuoso";

const TodayArticle = observer(
  ({
    disabledBrandName,
    renderData,
  }: {
    disabledBrandName?: boolean;
    renderData: BrandCommunityPopularArticleResponse[] | [];
  }) => {
    const {
      line_height_10,
      header_wrap,
      section_name_wrap,
      title_bg,
      title_txt,
      title_more_btn,
      title_more_txt,
      article_list,
      article_item_wrap,
      article_item_wrap_no_bottom,
      article_wrap,
      flag_default,
      flag_default_text,
      flag_notice,
      flag_notice_text,
      article_item_title_wrap,
      article_title_text1,
      article_contnets_text2,
      article_summary_wrap,
      article_info_item,
      article_info_item_first,
      article_item_text3,
      article_item_text4,
      article_item_bar,
      article_image_wrap,
      article_image,
      image_num_wrap,
      image_num_text,
      notice_badge_back,
      notice_badge_text,
      default_badge_back,
      default_badge_text
    } = styles;

    const ref = useRef(null);
    const { t } = useTranslation();

    const goToArticleDetail = (articleId: number) => {
      goTo(`/ArticleDetail/${articleId}`);
    };

    const goToPouplarArticleList = () => {
      CommonStore.setIsScrollToBrandPopularArticle(true);
      goTo("/BrandCommunityMain");
    };

    const renderItem = () => {
      return (
        <Virtuoso
          style={article_list}
          useWindowScroll
          data={renderData}
          ref={ref}
          itemContent={(index: number, item: any) => {
            return (
              <TouchableWithAuthCheck
                key={index}
                style={{ width: "100%" }}
                onPress={() => {
                  goToArticleDetail(item.articleId);
                }}
              >
                <div
                  style={{
                    ...styles.article_item_wrap,
                    ...(index === 0 ? { paddingTop: 0 } : {}),
                    ...(index === renderData.length - 1 ? article_item_wrap_no_bottom : {}),
                  }}
                >
                  <div style={article_wrap}>
                    <div style={article_item_title_wrap}>
                      <div style={{ position: "relative", height: "100%", width: "100%", display: "flex" }}>
                        <div style={{ ...(item.articleTypeCode === "NOTICE" ? notice_badge_back : default_badge_back) }}>
                          <p style={item.articleTypeCode === "NOTICE" ? notice_badge_text : default_badge_text}>{item.articleTypeName}</p>
                        </div>
                        <p style={article_title_text1}>
                          {item.articleTypeCode === "NOTICE" ? (
                            <span style={flag_notice_text}>{t("screen.brandCommunity.label.notice")}</span>
                          ) : (
                            <span style={flag_default_text}>{item.articleTypeName}</span>
                          )}
                          <span style={{ ...(item.articleTypeCode === "NOTICE" ? notice_badge_text : default_badge_text), paddingLeft: 14, overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '1', lineClamp: '1', WebkitBoxOrient: 'vertical', whiteSpace: 'pre-wrap' }}>
                            {item.articleTitle}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div style={article_summary_wrap}>
                      {!disabledBrandName && (
                        <>
                          <div style={{ ...styles.article_info_item, ...styles.article_info_item_first }}>
                            <p style={article_item_text3}>
                              {item.communityName &&
                                (item.communityName.length > 17
                                  ? item.communityName.substring(0, 16).concat("...")
                                  : item.communityName)}
                            </p>
                          </div>
                          <div style={article_item_bar} />
                        </>
                      )}

                      <div style={article_info_item}>
                        <CommentIcon />
                        <p style={article_item_text4}>{item.commentCount}</p>
                      </div>
                      <div style={article_info_item}>
                        <ViewIcon />
                        <p style={article_item_text4}>{item.articleViewCount}</p>
                      </div>
                      <div style={article_info_item}>
                        <HeartIcon />
                        <p style={article_item_text4}>{item.articleLikeCount}</p>
                      </div>
                    </div>
                  </div>
                  <div style={{height: "100%", display: "flex", alignItems: "center"}}>
                    {item.thumbnailFilePath && (
                      <div style={article_image_wrap}>
                        <FastImageWithFallback
                          style={article_image}
                          source={{ uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}` }}
                        />
                        {item.fileCount > 1 && (
                          <div style={image_num_wrap}>
                            <p style={image_num_text}>{item.fileCount}</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </TouchableWithAuthCheck >
            );
          }}
        />
      );
    };

    return (
      <>
        <div style={line_height_10} />
        <div style={header_wrap}>
          <div style={section_name_wrap}>
            <div style={title_bg}></div>
            <p style={title_txt}>{t("screen.brandCommunity.label.todayPopularArticle")}</p>
          </div>
          <div>
            <button style={title_more_btn} onClick={goToPouplarArticleList}>
              <p style={title_more_txt}>{t("screen.brandCommunity.button.more")}</p>
              <BtnMore />
            </button>
          </div>
        </div>
        {renderData && renderData.length > 0 && renderItem()}
        <div style={line_height_10} />
      </>
    );
  }
);

const styles: Styles = {
  line_height_10: {
    height: 10,
    backgroundColor: "#f5f5f5",
  },
  title_txt: {
    fontSize: 18,
    fontWeight: "500",
    color: "#222",
    zIndex: 1,
  },
  title_bg: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#F7E237",
    height: 8,
  },
  header_wrap: {
    marginLeft: 16,
    marginRight: 16,
    flexWrap: "wrap",
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.margin_top_20,

  },
  section_name_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    position: "relative",
  },
  title_more_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  title_more_txt: {
    ...Texts.contents_text_0,
    ...Colors.font_999,
    ...Spacings.margin_right_2,
  },
  article_wrap: {
    ...FlexBoxs.flex,
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 2,
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around",
  },
  article_item_title_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    overflow: "hidden",
  },

  flag_notice: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "auto",
    ...Colors.background_f7e237,
    ...Spacings.border_radius_4,
    ...Spacings.padding_left_right_8,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.margin_right_6,
  },
  flag_default: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "auto",
    ...Spacings.padding_right_8,
  },

  flag_notice_text: {
    opacity: 0,
    color: "rgba(0,0,0,0)",
    ...Texts.font_size_16,
    ...Texts.font_weight_500,
    ...Texts.font_letter_spacing_064,
  },
  flag_default_text: {
    ...Texts.font_size_16,
    ...Texts.font_weight_500,
    ...Texts.font_letter_spacing_064,
    ...Colors.font_be69c3,
  },

  notice_badge_back: {
    position: "absolute",
    top: 0,
    left: 0,
    minWidth: 34,
    ...FlexBoxs.flex,
    alignItems: "center",
    justifyContent: "center",
    height: 22,
    borderRadius: 4,
    backgroundColor: "#F7E237",
    marginBottom: -1,
  },
  notice_badge_text: {
    fontSize: 10,
    fontWeight: "700",
  },

  default_badge_back: {
    position: "absolute",
    top: 0,
    left: 0,
    minWidth: 34,
    ...FlexBoxs.flex,
    alignItems: "center",
    justifyContent: "center",
    height: 22,
    borderRadius: 4,
    backgroundColor: "#F5F5F5",
    marginBottom: -1,
  },
  default_badge_text: {
    fontSize: 10,
    fontWeight: "700",
    ...Colors.font_be69c3,
  },

  bx_comm_popular_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  bx_comm_pp_left: {
    ...FlexBoxs.flex,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
  },
  article_image_wrap: {
    width: 72,
    height: 72,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    marginLeft: 8,
    ...Positions.relative,
    ...Spacings.border_radius_4,
    ...Colors.background_f5f5f5,
    overflow: "hidden",
  },
  image_num_wrap: {
    width: 22,
    height: 22,
    ...Positions.absolute,
    ...Colors.background_666,
    ...Spacings.border_radius_4,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    opacity: 0.8,
    right: 0,
    bottom: 0,
  },
  image_num_text: {
    ...Texts.contents_text_47,
    ...Colors.font_fff,
  },
  article_list: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 16,
  },
  article_item_wrap: {
    paddingTop: 16,
    paddingBottom: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#eee",
    ...FlexBoxs.flex,
    flexDirection: "row",
    width: "100%",
    height: 106,
  },
  article_item_wrap_no_bottom: {
    borderBottomWidth: 0,
  },
  article_title_text1: {
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Colors.font_222,
    ...Texts.font_weight_500,
    overflow: 'hidden',
    whiteSpace: "nowrap",
    maxWidth: '100%',
    ...FlexBoxs.flex,
    alignItems: "center",
    paddingRight: 2,
  },
  article_contnets_text2: {
    fontSize: 14,
    color: "#222",
    letterSpacing: -0.56,
    marginTop: 4,
  },
  article_summary_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_top_8,
  },
  article_item_text3: {
    ...Texts.contents_text_9,
    ...Texts.font_weight_500,
    ...Colors.font_999,
    maxWidth: 40,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // maxWidth: '100%'
  },
  article_detail: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    ...Spacings.margin_right_8,
  },
  article_image: {
    width: 72,
    height: 72,
    ...Spacings.border_radius_6,
  },
  article_info_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Spacings.padding_left_right_4,
  },
  article_info_item_first: {
    paddingLeft: 0,
  },
  article_item_text4: {
    ...Texts.contents_text_9,
    ...Colors.font_999,
    ...Spacings.margin_left_2,
  },
  article_item_bar: {
    width: 1,
    height: 14,
    ...Colors.background_eeeeee,
    ...Spacings.margin_left_4,
    ...Spacings.margin_right_4,
  },
};

export default TodayArticle;
