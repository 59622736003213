import { t } from "i18next";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
// import { TextInput } from "react-native";
import { checkBadWord } from "../api/badWord/api";
import { MemberTagItem } from "../api/member/model";
import {
  interestedTag,
  deleteInterestedTag,
  addInterestedTag,
  searchInterestedTag,
} from "../api/tag/api";
import MainStore from "./MainStore";
const { setInterestedTagList } = MainStore;
class TagManagementStore {
  constructor() {
    makeObservable(this);
  }

  @observable errorMessage?: string;
  @observable loading = false;
  @observable interestedTagList: MemberTagItem[] = [];
  @observable searchInterestedTagList: MemberTagItem[] = [];
  @observable _tagPageIndex = 0;
  @observable lastFocus: any;

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    );
  }

  @computed get interestedTags() {
    return this.interestedTagList ?? [];
  }

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action setTagPageIndex = (tagPageIndex: number) => {
    this._tagPageIndex = tagPageIndex;
  };

  @action setLoading = (isLoading: boolean) => {
    this.loading = isLoading;
  };

  @action clear = () => {
    this.errorMessage = "";
    this.interestedTagList = [];
    this.searchInterestedTagList = [];
    this._tagPageIndex = 0;
  };

  @action getInterestedTagList = async (memberUuid: string) => {
    const response = await interestedTag(memberUuid);
    runInAction(() => {
      this.interestedTagList = response;
      setInterestedTagList(this.interestedTagList);
    });
  };

  @action deleteInterestedTag = async (
    memberUuid: string,
    tagId: number | null
  ) => {
    const response = await deleteInterestedTag(memberUuid, tagId);
    if (response === true) {
      this.interestedTagList =
        this.interestedTagList?.filter((elem) => elem.tagId !== tagId) || null;
      setInterestedTagList(this.interestedTagList);
    }
  };

  @action addInterestedTag = async (
    memberUuid: string,
    tagId: number | null,
    tagName: string,
    alreadyAdded: () => void
  ) => {
    const response = await addInterestedTag(memberUuid, tagId, tagName);
    if (response != null && response !== undefined) {
      if (response.statusCode == "ALREADY_REGISTERED") {
        alreadyAdded && alreadyAdded();
        return;
      }

      runInAction(() => {
        if (response.successOrNot === "Y") {
          this.interestedTagList?.unshift({ tagId: response.data, tagName });
          setInterestedTagList(this.interestedTagList);
        }
      });
    } else {
      this.setError(t("common.error.server"));
    }
  };

  // @action getSearchInterestedTagList = async (
  //   memberUuid: string,
  //   searchKeyWord: string,
  //   pageSize: number
  // ) => {
  //   const response = await searchInterestedTag(
  //     memberUuid,
  //     searchKeyWord,
  //     this._tagPageIndex,
  //     pageSize
  //   );
  //   runInAction(() => {
  //     if (response.totalCount > 0) {
  //       if (this._tagPageIndex === 0) {
  //         this.searchInterestedTagList = [];
  //       }
  //       this.searchInterestedTagList = this.searchInterestedTagList.concat(
  //         response.list
  //       );
  //       this.setTagPageIndex(this._tagPageIndex + 1);
  //     } else {
  //       this.searchInterestedTagList = response.list;
  //       this.searchInterestedTagList?.push({
  //         tagId: null ,
  //         tagName: searchKeyWord,
  //       });
  //     }
  //   });
  // };

  // @action checkValidWord = async (
  //   word: string,
  //   focus: TextInput | null,
  //   successAction?: () => void,
  //   failAction?: () => void
  // ) => {
  //   if (word.trim().length < 1) return;
  //   this.lastFocus = focus;
  //   const res = await checkBadWord(word);
  //   if (res != null && res !== undefined) {
  //     if (res?.hasBadWord === "N") {
  //       if (successAction !== undefined) successAction();
  //     } else {
  //       failAction && failAction();
  //     }
  //   }
  // };

  @action initSearchInterestedTagList = () => {
    this.searchInterestedTagList = [];
  };
}

export default new TagManagementStore();
