import { Post, Tag } from '../api/feed/model'
import { File } from '../api/file/model'
import { PostDetailResponse } from '../api/post/model'
import { TagFeedItem } from '../api/tag/model'

export const convertPostDetailToPost = (data: PostDetailResponse) => {
  const fileList = (data?.contents?.length > 0 &&
    data?.contents[0]?.files) as File[]
  const thumbnailYList =
    fileList.filter((value, _) => value.thumbnailYn === 'Y') || []
  const imageList =
    fileList.filter((value, _) => value.fileType === 'IMAGE') || []
  const videoList =
    fileList.filter((value, _) => value.fileType === 'VIDEO') || []

  const modifiedPost: Post = {
    advantage: data.advantage,
    bookmarkYn: data.bookmarkYn,
    commentCount: data.commentCount,
    contents: (data?.contents.length > 0 && data?.contents[0].contents) || '',
    createMemberUuid: data.createMemberUuid,
    disadvantage: data.disadvantage,
    feedContentCount: data.contents.length,
    feedContentId:
      (data?.contents.length > 0 && data?.contents[0].feedContentId) || -1,
    feedId: data.feedId,
    feedTitle: data.feedTitle,
    fileList: fileList,
    thumbnailFilePath:
      (thumbnailYList.length > 0 && thumbnailYList[0].thumbnailFilePath) || '',
    originalFilePath: fileList[0].filePath || '',
    filePath: data?.contents[0]?.files[0]?.filePath,
    likeCount: data.likeCount,
    likeYn: data.likeYn,
    nickname: data.nickname,
    //TODO: feedStartDate 삭제해야됨
    feedStartDate: data.createdDatetime,
    createdDatetime: data.createdDatetime,
    rating: data.rating,
    tagList: data.tagList as Tag[],
    showMore: true,
    currentCarouIndex: 0,
    productList: data.productList,
    profileUrl: data.profileUrl,
    imageCount: imageList.length,
    videoCount: videoList.length,
    openApiFlag: data.openApiFlag,
  }
  return modifiedPost
}

export const convertPostToTagFeedItem = (data: PostDetailResponse) => {
  const fileList = (data?.contents?.length > 0 &&
    data?.contents[0]?.files) as File[]
  const thumbnailYList =
    fileList.filter((value, _) => value.thumbnailYn === 'Y') || []
  const modifiedPost: TagFeedItem = {
    advantage: data.advantage,
    bookmarkYn: data.bookmarkYn,
    commentCount: data.commentCount,
    contents: (data?.contents.length > 0 && data?.contents[0].contents) || '',
    createMemberUuid: data.createMemberUuid,
    disadvantage: data.disadvantage,
    feedContentCount: data.contents.length,
    feedContentId:
      (data?.contents.length > 0 && data?.contents[0].feedContentId) || -1,
    feedId: data.feedId,
    feedTitle: data.feedTitle,
    fileList: fileList,
    thumbnailFilePath:
      (thumbnailYList.length > 0 && thumbnailYList[0].thumbnailFilePath) || '',
    originalFilePath: fileList[0].filePath || '',
    likeCount: data.likeCount,
    likeYn: data.likeYn,
    nickname: data.nickname,
    feedStartDate: data.createdDatetime,
    createdDatetime: data.createdDatetime,
    rating: data.rating,
    tagList: data.tagList as Tag[],
    productList: data.productList,
    profileUrl: data.profileUrl,
  }
  return modifiedPost
}

export function safeParseJSON(input: unknown): any {
  // input이 undefined인 경우 null 반환
  if (input === undefined) {
    return null
  }

  // input이 object인 경우 그대로 반환
  if (typeof input === 'object') {
    return input
  }

  // input이 string인 경우 JSON 파싱 시도
  if (typeof input === 'string') {
    try {
      return JSON.parse(input)
    } catch (error) {
      // console.error('Invalid JSON string:', error)
      return null // 파싱 실패 시 null 반환
    }
  }

  // 그 외의 타입은 null 반환
  return null
}
