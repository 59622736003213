// 네이티브 소스
// import React from "react";
// import { Text, View } from "react-native";
// import CameraIcon from "../../../assets/icons/camera_purple.svg";
// import CommentIcon from "../../../assets/icons/comment_purple2.svg";
// import CouponIcon from "../../../assets/icons/coupon_purple.svg";
// import SurveyIcon from "../../../assets/icons/survey_purple.svg";
// import RaffleIcon from "../../../assets/icons/raffle.svg";
// import LotteryIcon from "../../../assets/icons/mission_gift.svg";
// import RecommenderIcon from "../../../assets/icons/recommender.svg";
// import { Mission } from "../../../api/mission/model";
// import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
// import Config from "react-native-config";
// import { getDateMD, getDatetimeStr, getDDay } from "../../../utils/datetime";
// import { useTranslation } from "react-i18next";
// import dayjs from "dayjs";
// import { MISSION_SUB_TYPE, MISSION_TYPE } from "../../../constants/mission";
// import ShowWinnerButton from "./ShowWinnerButton";
// import { styles } from "./MissionMain";
// import { navigate } from "../../../navigation/NavigationFunction";
// import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
// import CommonStore from "../../../store/CommonStore";
// import { ActHistoryTypeCode, ActPageCode, ActSectionCode } from "../../../constants/ActHistory";
// import MissionStore from "../../../store/MissionStore";
// import { VoltPolicyCode } from "../../../constants/Volt.enum";
// import { goToRandomCouponWinnerView } from "./randomCoupon/RandomCouponMission";

import React from "react";
// import CameraIcon from "../../../assets/icons/camera_purple.svg";
// import CommentIcon from "../../../assets/icons/comment_purple2.svg";
// import CouponIcon from "../../../assets/icons/coupon_purple.svg";
// import SurveyIcon from "../../../assets/icons/survey_purple.svg";
// import RaffleIcon from "../../../assets/icons/raffle.svg";
// import LotteryIcon from "../../../assets/icons/mission_gift.svg";
// import RecommenderIcon from "../../../assets/icons/recommender.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { Mission } from "../../../api/mission/model";
import { getDateMD, getDatetimeStr, getDDay } from "../../../utils/datetime";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { MISSION_SUB_TYPE, MISSION_TYPE } from "../../../constants/mission";
import ShowWinnerButton from "./ShowWinnerButton";
import { styles } from "./MissionMain";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import CommonStore from "../../../store/CommonStore";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import MissionStore from "../../../store/MissionStore";
import { VoltPolicyCode } from "../../../constants/Volt.enum";
import { ReactComponent as CameraIcon } from "../../../assets/icons/camera_purple.svg";
import { ReactComponent as CommentIcon } from "../../../assets/icons/comment_purple2.svg";
import { ReactComponent as CouponIcon } from "../../../assets/icons/coupon_purple.svg";
import { ReactComponent as SurveyIcon } from "../../../assets/icons/survey_purple.svg";
import { ReactComponent as RaffleIcon } from "../../../assets/icons/raffle.svg";
import { ReactComponent as LotteryIcon } from "../../../assets/icons/mission_gift.svg";
import { ReactComponent as RecommenderIcon } from "../../../assets/icons/recommender.svg";
import "./MissionItem.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

//개발 필요
// import { goToRandomCouponWinnerView } from "./randomCoupon/RandomCouponMission";

//추가

export interface MissionItemProps {
  listIndex: number;
  mission: Mission;
}

const MissionItem = ({ listIndex, mission }: MissionItemProps) => {
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const renderDday = (date: string) => {
    const now = dayjs();
    const dday = getDDay(date);

    if (now.isAfter(mission.missionEndDate)) {
      return <></>;
    } else {
      if (dday > 0 && dday <= 7) {
        return <div className="d-day">D-{dday}</div>;
      } else if (dday == 0) {
        return (
          <div className="d-day">{t("screen.mission.label.endToday")}</div>
        );
      } else {
        return <></>;
      }
    }
  };

  const renderDuration = () => {
    const now = dayjs();
    if (now.isAfter(mission.missionEndDate)) {
      return (
        <div className="date-label">{t("screen.mission.label.missionEnd")}</div>
      );
    } else {
      return (
        <>
          <div className="date-label">{t("screen.mission.label.duration")}</div>
          <div className="date">
            {getDatetimeStr(mission.missionStartDate)}
            {" ~ "}
            {getDatetimeStr(mission.missionEndDate)}
          </div>
        </>
      );
    }
  };

  const renderMissionType = () => {
    const type = mission.missionTypeCode;
    const subType = mission.missionSubType;

    if (type === MISSION_TYPE.PICTURE) {
      return (
        <div className="mission-type">
          <CameraIcon />
          <p className="mission-type-text">
            {t("screen.mission.label.pictureMission")}
          </p>
        </div>
      );
    } else if (type === MISSION_TYPE.SURVEY) {
      return (
        <div className="mission-type">
          <SurveyIcon />
          <p className="mission-type-text">
            {t("screen.mission.label.surveyMission")}
          </p>
        </div>
      );
    } else if (type === MISSION_TYPE.COMMENT) {
      return (
        <div className="mission-type">
          <CommentIcon />
          <p className="mission-type-text">
            {t("screen.mission.label.commentMission")}
          </p>
        </div>
      );
    } else if (type === MISSION_TYPE.COUPON) {
      return (
        <div className="mission-type">
          <CouponIcon />
          <p className="mission-type-text">
            {t("screen.mission.label.couponMission")}
          </p>
        </div>
      );
    } else if (type === MISSION_TYPE.RAFFLE) {
      return (
        <div className="mission-type">
          <RaffleIcon />
          <p className="mission-type-text">
            {t("screen.mission.label.raffleMission")}
          </p>
        </div>
      );
    } else if (type === MISSION_TYPE.RECOMMENDER) {
      return (
        <div className="mission-type">
          <RecommenderIcon />
          <p className="mission-type-text">
            {t("screen.mission.label.recommenderMission")}
          </p>
        </div>
      );
    } else if (type === MISSION_TYPE.STAMP) {
      return (
        <div className="mission-type">
          <CameraIcon />
          <p className="mission-type-text">
            {t("screen.mission.label.stampMission")}
          </p>
        </div>
      );
    } else if (type === MISSION_TYPE.COMMON) {
      if (subType === MISSION_SUB_TYPE.LOTTERY) {
        return (
          <div className="mission-type">
            <LotteryIcon />
            <p className="mission-type-text">
              {t("screen.mission.label.randomCoupon")}
            </p>
          </div>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  const renderShowWinnerButton = () => {
    const now = dayjs();
    if (
      mission.missionTypeCode === MISSION_TYPE.COMMON &&
      mission.missionSubType === MISSION_SUB_TYPE.LOTTERY &&
      now.isAfter(mission.missionEndDate)
    ) {
      return (
        // <TouchableWithAuthCheck
        <button
          className="winner_btn"
          onClick={() => {
            CommonStore.setShowDownloadAppDrive(true);
            // goToRandomCouponWinnerView({
            //   missionId: mission.missionId,
            //   missionTypeCode: mission.missionTypeCode,
            //   isShowLoserScreen: true,
            // });
          }}
        >
          <p className="winner_btn_text">
            {t("screen.mission.label.winnerAnnounce")}
          </p>
        </button>
        // </TouchableWithAuthCheck>
      );
    } else if (
      now.isBefore(mission.missionEndDate) ||
      now.isSame(mission.missionEndDate)
    ) {
      //미션 종료일 이전
      return <></>;
    } else if (
      now.isAfter(mission.missionEndDate) &&
      mission.lotteryYn == "N"
    ) {
      //미션 종료일 이후 && 당첨자 발표가 안났을 때
      return (
        <div className="winner_btn">
          <p className="winner_btn_text">
            {getDateMD(mission?.lotteryDate || "")}{" "}
            {t("screen.mission.label.winnerAnnounceExpect")}
          </p>
        </div>
      );
    } else if (
      now.isAfter(mission.missionEndDate) &&
      mission.lotteryYn == "Y"
    ) {
      //미션 종료일 이후 && 당첨자 발표가 났을 때
      // return <ShowWinnerButton isDetail={false} missionId={mission.missionId} />;
      return (
        // <TouchableWithAuthCheck
        <button
          className="winner_btn"
          onClick={() => {
            CommonStore.setShowDownloadAppDrive(true);
          }}
        >
          <p className="winner_btn_text">
            {t("screen.mission.label.winnerAnnounce")}
          </p>
        </button>
        // </TouchableWithAuthCheck>
      );
    } else {
      return <></>;
    }
  };

  const endImage = () => {
    const now = dayjs();
    if (now.isAfter(mission.missionEndDate)) {
      return <div style={styles.inner} />;
    } else {
      return <></>;
    }
  };

  const logActHistory = () => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.MISSION_READ,
      (mission.missionId ?? 0).toString(),
      ActPageCode.MISSION,
      MissionStore.missionActSectionCode(),
      listIndex.toString(),
      mission.missionTypeCode
    );
  };

  //네이티브 소스
  //   return (
  //     <View style={styles.mission_item}>
  //       <TouchableWithAuthCheck
  //         key={listIndex}
  //         accessibilityLabel={`go to mission Detail ${listIndex}`}
  //         onPress={() => {
  //           logActHistory();
  //           if (mission.missionTypeCode === MISSION_TYPE.COMMON && mission.missionSubType === MISSION_SUB_TYPE.LOTTERY) {
  //             goToRandomCouponWinnerView({
  //               missionId: mission.missionId,
  //               missionTypeCode: mission.missionTypeCode,
  //             });
  //           } else {
  //             navigate("MissionDetailScreen", { missionId: mission.missionId, missionTypeCode: mission.missionTypeCode });
  //           }
  //         }}
  //         withoutFeedback={true}
  //       >
  //         <FastImageWithFallback
  //           source={{ uri: `${PUBLIC_BUCKET_URL}${mission.thumbnailFilePath || ""}` }}
  //           style={[styles.mission_image, { borderRadius: 6 }]}
  //         >
  //           {endImage()}
  //         </FastImageWithFallback>
  //         <View style={styles.mission_info_wrap}>
  //           <Text style={styles.mission_title} numberOfLines={2}>
  //             {mission.missionTitle}
  //           </Text>
  //           {mission.voltPolicyCode && mission.voltPolicyCode == VoltPolicyCode.MISSION_USE && (
  //             <View style={styles.mission_volt_info}>
  //               <Text style={styles.mission_volt_text}>
  //                 <Text style={{ fontWeight: "700" }}>{t("screen.mission.label.entry").concat(" ")}</Text>
  //                 {t("screen.volt.label.nVolt", { voltAmount: Math.abs(mission.voltAmount || 0) })}
  //               </Text>
  //             </View>
  //           )}
  //           <View style={styles.mission_info}>
  //             <View style={styles.date_wrap}>
  //               {renderDuration()}
  //               {mission?.missionEndDate && renderDday(mission.missionEndDate)}
  //             </View>
  //             {renderMissionType()}
  //           </View>
  //         </View>
  //       </TouchableWithAuthCheck>
  //       {mission.missionTypeCode != MISSION_TYPE.COUPON && renderShowWinnerButton()}
  //     </View>
  //   );
  // };

  return (
    <div className="mission-item">
      <div
        key={listIndex}
        onClick={() => {
          logActHistory();
          if (
            mission.missionTypeCode === MISSION_TYPE.COMMON &&
            mission.missionSubType === MISSION_SUB_TYPE.LOTTERY
          ) {
            goTo("/MissionDetail", {
              state: {
                missionId: mission.missionId,
                missionTypeCode: mission.missionTypeCode,
              },
            });
            // CommonStore.setShowDownloadAppDrive(true);
          } else {
            goTo("/MissionDetail", {
              state: {
                missionId: mission.missionId,
                missionTypeCode: mission.missionTypeCode,
              },
            });
            // CommonStore.setShowDownloadAppDrive(true);
          }
        }}
      >
        <div className="mission-image-container">
          <img
            src={`${PUBLIC_BUCKET_URL}${mission.thumbnailFilePath || ""}`}
            alt={mission.missionTitle}
            className="mission-image"
          />
        </div>
        {endImage()}
        <div className="mission-info-wrap">
          <p className="mission-title">{mission.missionTitle}</p>
          {mission.voltPolicyCode &&
            mission.voltPolicyCode == VoltPolicyCode.MISSION_USE && (
              <div className="mission-volt-info">
                <p className="mission-volt-text">
                  <p style={{ fontWeight: "700" }}>
                    {t("screen.mission.label.entry").concat(" ")}
                  </p>
                  {t("screen.volt.label.nVolt", {
                    voltAmount: Math.abs(mission.voltAmount || 0),
                  })}
                </p>
              </div>
            )}
          <div className="mission-info">
            <div className="date-wrap">
              {renderDuration()}
              {mission?.missionEndDate && renderDday(mission.missionEndDate)}
            </div>
            {renderMissionType()}
          </div>
        </div>
      </div>
      {mission.missionTypeCode != MISSION_TYPE.COUPON &&
        renderShowWinnerButton()}
    </div>
  );
};

export default MissionItem;
