import { observer } from "mobx-react";
import PreferenceStore from "../../../store/PreferenceStore";
import { PreferenceTypeCode } from "../../../constants/Preference.enum";
import SeletablePreference from "./SeletablePreference";

const HousematePreference = observer(() => {
  const {
    housematePreference,
    selectedHousematePreferenceListCodes,
    selectHousematePreferenceListCodes,
    preferenceSteps,
  } = PreferenceStore;

  const handleSelect = (item: string) => {
    selectHousematePreferenceListCodes(item);
  };

  const currentStep =
    preferenceSteps.findIndex((item) => item === PreferenceTypeCode.HOUSEMATE) +
    1;
  const totalStepCount = preferenceSteps.length;

  return housematePreference ? (
    <SeletablePreference
      preference={housematePreference}
      selectedPreferenceCodes={selectedHousematePreferenceListCodes}
      onSelectPrefereceCode={handleSelect}
      currentStep={currentStep}
      totalStepCount={totalStepCount}
    />
  ) : (
    <></>
  );
});

export default HousematePreference;
