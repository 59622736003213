import { SuccessOrNot } from "../../constants/Common.enum";
import {
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
  callAPIWithPUTMethod,
} from "../common/api";
import CommonResponse from "../http";
import WriteCommonResponse, {
  AbWriteRequest,
  AbDetail,
  AbRequest,
  AbVoteRequest,
  AbVoteResult,
  PopularAbRequest,
  AbDetailResult,
  AbModifyRequest,
} from "./model";

export const createAb = async (item: AbWriteRequest, isLoading = true): Promise<WriteCommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/feed/ab",
      body: item,
    },
    isLoading
  );
};

export const modifyAb = async (item: AbModifyRequest, isLoading = true): Promise<WriteCommonResponse> => {
  return await callAPIWithPUTMethod(
    {
      url: "/v1/feed/ab",
      body: item,
    },
    isLoading
  );
};

export const getAbDetail = async (feedId: number, isLoading = true): Promise<AbDetailResult> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/feed/ab/${feedId}`,
    },
    isLoading
  );

  return (response?.successOrNot === "Y" ? response?.data : null) as AbDetailResult;
};

export const getAbDetailResponse = async (feedId: number, isLoading = true): Promise<CommonResponse<any>> => {
  return await callAPIWithGETMethod(
    {
      url: `/v1/feed/ab/${feedId}`,
    },
    isLoading
  );
};

export const deleteAb = async (feedIds: number[], isLoading = true): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/feed/ab",
      body: { feedIds },
    },
    isLoading
  );
  return (response?.successOrNot === SuccessOrNot.Y ? response?.data : false) as boolean;
};

export const addAbVote = async (voteTargetId: number, abFlag: string, isLoading = true): Promise<AbVoteResult> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/feed/abVote",
      body: {
        voteTargetId,
        abFlag,
      },
    },
    isLoading
  );
  return (response?.successOrNot === "Y" ? response?.data : false) as AbVoteResult;
};

export const deleteAbVote = async (voteTargetId: number, isLoading = true): Promise<AbVoteResult> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/feed/abVote",
      params: { voteTargetId: voteTargetId },
    },
    isLoading
  );
  return (response?.successOrNot === "Y" ? response?.data : false) as AbVoteResult;
};

export const getAbListWithPagination = async (
  { votedYn, endYn, pageIndex, pageSize, sortType }: AbRequest,
  isLoading = true
): Promise<CommonResponse<{ list: AbDetail[] }>> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  let url = `/v1/feed/abs?votedYn=${votedYn}&pageSize=${pageSize}&pageIndex=${pageIndex}`;
  url += sortType ? `&sort=${sortType}` : "";
  url += endYn ? `&endYn=${endYn}` : "";

  response = await callAPIWithGETMethod(
    {
      url,
    },
    isLoading
  );

  return response;
};

export const getPopularAbListWithPagination = async (
  { endYn, pageIndex, pageSize }: PopularAbRequest,
  isLoading = true
): Promise<CommonResponse<{ list: AbDetail[] }>> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  response = await callAPIWithGETMethod(
    {
      url: `/v1/popularity/abs?endYn=${endYn}&pageSize=${pageSize}&pageIndex=${pageIndex}`,
    },
    isLoading
  );

  return response;
};

export const voteAb = async (request: AbVoteRequest, isLoading = true): Promise<CommonResponse<AbVoteResult>> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  response = await callAPIWithPOSTMethod(
    {
      url: `/v1/feed/abVote`,
      body: request,
    },
    isLoading
  );

  return response;
};
