import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../assets/styles";
import { useTranslation } from "react-i18next";
import { Styles } from "../../assets/types/Style";
import MainTabNavigator from "../../components/common/MainTabNavigator";
import FlatListWithStickyTabs from "../../components/common/FlatListWithStickyTabs";
import ArticleHeader from "../../components/headers/ArticleListHeader";
import ArticleList from "./presenter/ArticleList";
import { goBack, goTo } from "../../hooks/navigate/NavigateFunction";
import ArticleListFilter from "./presenter/ArticleListFilter";
import CustomBottomSheet from "../../components/common/CustomBottomSheet";
import { SearchSortType } from "../../constants/Search";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/icon_user_tick.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow_right_white.svg";
import { getBrandSubscribe } from "../../api/brand/api";
import AuthStore from "../../store/AuthStore";
import { BannerInfo } from "../../api/banner/model";
import { getBrandCommunityBannerList } from "../../api/banner/api";
import { BannerTypeCodes } from "../../constants/Banner.enum";
import { parseQueryParam } from "../../utils/queryParam";
import ArticleListStore from "../../store/ArticleListStore";
import { autorun } from "mobx";
import FastImageWithFallback from "../../components/common/FastImageWithFallback";

const BrandCommunityArticleListScreen = observer((data: any) => {

  const location = useLocation();
  const params = location.state;

  const {
    brandCommunityId,
    brandCommunityName,
    showSortBox,
    sort,
    setBrandCommunityId,
    setBrandCommunityName,
    setShowSortBox,
    setSort,
    setCorpMemberYn,
    setIsLoading,
  } = ArticleListStore;

  const { t } = useTranslation();

  const [subscription, setSubscription] = useState<boolean>(false);
  const [brandBanner, setBrandBanner] = useState<BannerInfo | null>(null);

  const { sessionUser, tempMember } = AuthStore;

  const loginUserId = String(
    sessionUser?.memberUuid === undefined || sessionUser?.memberUuid === null || sessionUser?.memberUuid?.length < 1
      ? tempMember?.tempMemberUUID
      : sessionUser?.memberUuid
  );

  const stickyTabsRef = useRef<any>(null);

  const BRAND_BANNER_HEIGHT = 196;

  const getSubscribeStatus = async (brandCommunityId: number) => {
    await getBrandSubscribe(loginUserId).then((res) => {
      if (res === null) {
        setSubscription(false);
      } else {
        const result = res.find((x) => x.communityId === brandCommunityId);
        result ? setSubscription(true) : setSubscription(false);
      }
    });
  };

  const getBrandBanner = async (brandCommunityId: number) => {
    await getBrandCommunityBannerList([BannerTypeCodes.COMMUNITY_ARTICLE_TOP_BANNER].toString(), brandCommunityId).then(
      (response) => {
        const res = response.map((bannerInfo) => ({
          ...bannerInfo,
          bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
        }));
        setBrandBanner(res[0]);
      }
    );
  };

  const handleRefresh = () => {
    autorun(async () => {
      setIsLoading(true);
      await ArticleListStore.initArticleList();
      setIsLoading(false);
    });
  };

  useEffect(() => {
    const brandCommunityId: number = params?.brandCommunityId as number
    const brandCommunityName: string = params?.brandCommunityName;
    const corparationMemberYn: string = params?.corpMemberYn;

    if (brandCommunityId != null) {
      setBrandCommunityId(brandCommunityId);
      void getBrandBanner(brandCommunityId);
      void getSubscribeStatus(brandCommunityId);
    }
    if (brandCommunityName != null) {
      setBrandCommunityName(brandCommunityName);
    }
    if (corparationMemberYn != null) {
      setCorpMemberYn(corparationMemberYn);
    }
    // eslint-disable-next-line
  }, [params]);

  const moveToProductList = () => {
    if (brandCommunityId != 0 && brandCommunityName != "") {
      goTo("/BrandCommunityProductList", {
        state: {
          brandCommunityId: brandCommunityId,
          brandCommunityName: brandCommunityName,
        }
      });
    } else goTo("/Search");
  };

  const renderForeground = () => {
    return brandBanner && brandBanner?.filePath ? (
      <div style={{ height: BRAND_BANNER_HEIGHT, position: 'relative', width: '100%' }}>
        <div style={{ height: BRAND_BANNER_HEIGHT, width: '100%' }}>
          <FastImageWithFallback
            wrapperStyle={{ height: BRAND_BANNER_HEIGHT, width: '100%', flex: 1 }}
            style={{ height: BRAND_BANNER_HEIGHT, width: '100%' }}
            source={{ uri: process.env.REACT_APP_PUBLIC_BUCKET_URL + (brandBanner?.filePath || "") }}
          />
        </div>
        <div style={{ ...styles.bx_comm_top_text, position: 'absolute', top: 0, left: 0, height: "100%", width: '100%' }}>
          {subscription && (
            <div style={styles.bx_user_state}>
              <UserIcon />
              <p style={styles.comm_top_text_st1}>{t("screen.brandCommunity.label.subscribing")}</p>
            </div>
          )}
          <div style={styles.bx_brand_name}>
            <p style={styles.brand_name_text}>
              {brandCommunityName}
            </p>
            <div onClick={moveToProductList}>
              <div style={styles.btn_all_product}>
                <p style={styles.all_prodeuct_text}>{t("screen.brandCommunity.label.totalProductList")}</p>
                <ArrowRightIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      // backgroundColor 는 임시로 지정한 상태로, Color 받아서 정식 진행
      <div style={{ display: 'flex', height: BRAND_BANNER_HEIGHT, width: '100%', backgroundColor: "#999" }}>
        <div style={styles.bx_comm_top_text}>
          {subscription && (
            <div style={styles.bx_user_state}>
              <UserIcon />
              <p style={styles.comm_top_text_st1}>{t("screen.brandCommunity.label.subscribing")}</p>
            </div>
          )}
          <div style={styles.bx_brand_name}>
            <p style={styles.brand_name_text}>
              {brandCommunityName}
            </p>
            <div
              style={styles.btn_all_product}
              onClick={moveToProductList}
            >
              <p style={styles.all_prodeuct_text}>{t("screen.brandCommunity.label.totalProductList")}</p>
              <ArrowRightIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="MainLayout">
      <div className="main_body" style={{ paddingBottom: 56 }}>
        <div className="main_body_item">
          <div style={{ flex: 1, backgroundColor: "#fff" }}>
            <ArticleHeader
              title={""}
              brandCommunityId={brandCommunityId}
              subscriptionStatus={subscription}
              goBack={goBack}
            />
            <FlatListWithStickyTabs
              contentScrollRef={stickyTabsRef}
              scrollableHeader={renderForeground()}
              stickyItems={<ArticleListFilter />}
              tabStyles={{
                tabTextActiveStyle: styles.tabTextActiveStyle,
                tabTextContainerActiveStyle: styles.tabTextContainerActiveStyle,
                tabTextContainerStyle: styles.tabTextContainerStyle,
                tabTextStyle: styles.tabTextStyle,
                tabWrapperStyle: styles.tabWrapperStyle,
                tabsContainerStyle: styles.tabsContainerStyle,
              }}
              listFooterComponent={<ArticleList />}
              showScrollToTop={true}
              onRefresh={handleRefresh}
            />
            <CustomBottomSheet
              snapHeight={200}
              open={showSortBox}
              onClose={() => setShowSortBox(false)}
            >
              <div style={styles.sheet}>
                <div
                  style={styles.close_btn}
                  onClick={() => setShowSortBox(false)}
                >
                  <CloseIcon />
                </div>
                <div style={styles.sheet_title_wrap}>
                  <p style={styles.sheet_title}>
                    {t("screen.search.label.sort")}
                  </p>
                </div>

                <div style={styles.sort_list}>
                  <div
                    style={styles.sort_item}
                    onClick={() => setSort(SearchSortType.LATEST)}
                  >
                    <p style={{ ...styles.sort_text, ...(sort === SearchSortType.LATEST ? styles.sort_active_text : {}) }}>
                      {t("screen.search.label.latest")}
                    </p>
                  </div>
                  <div
                    style={styles.sort_item}
                    onClick={() => setSort(SearchSortType.POPULAR)}
                  >
                    <p style={{ ...styles.sort_text, ...(sort === SearchSortType.POPULAR ? styles.sort_active_text : {}) }}>
                      {t("screen.search.label.popular")}
                    </p>
                  </div>
                </div>
              </div>
            </CustomBottomSheet>
          </div>
        </div>
      </div>
      <MainTabNavigator />
    </div>
  );
});

export default BrandCommunityArticleListScreen;

export const styles: Styles = {
  sheet: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_16,
    ...Spacings.padding_bottom_19,
    ...Spacings.padding_left_right_20,
  },
  close_btn: {
    ...Positions.absolute,
    top: 6,
    right: 6,
    zIndex: 999,
  },
  sheet_title_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 32,
  },
  sheet_title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  sort_list: {
    ...Spacings.margin_top_16,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
  },
  sort_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_top_bottom_11,
  },
  sort_text: {
    ...Texts.contents_text_38,
    ...Colors.font_222,
  },
  sort_active_text: {
    ...Texts.font_weight_bold,
    ...Colors.font_be69c3,
  },

  tabWrapperStyle: {},
  tabsContainerStyle: {
    ...FlexBoxs.flex,
    flexDirection: "row",
    ...Spacings.padding_left_right_16,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    ...Colors.border_color_eee,
    borderBottomColor: "#eee",
  },
  tabTextStyle: {
    ...Texts.contents_text_28,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 15,
    paddingTop: 15,
  },
  tabTextActiveStyle: {
    ...Texts.contents_text_28,
    ...Texts.font_weight_bold,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 15,
    paddingTop: 15,
  },
  tabTextContainerStyle: {
    marginRight: 20,
  },
  tabTextContainerActiveStyle: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderBottomWidth: 2,
    borderBottomColor: "#222",
    marginRight: 20,
  },

  bx_comm_top_text: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...FlexBoxs.flex_1,
    ...Arranges.end_v,
    ...Spacings.padding_20,
  },
  bx_user_state: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  comm_top_text_st1: {
    ...Colors.font_fff,
    ...Spacings.margin_left_2,
    ...Texts.contents_text_37,
  },
  bx_brand_name: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.margin_top_4,
  },
  brand_name_text: {
    ...Texts.contents_text_53,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
    maxWidth: "70%",
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  btn_all_product: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.padding_top_bottom_8,
    paddingLeft: 12,
    paddingRight: 12,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderBottomWidth: 1,
    ...Colors.border_color_fff,
    ...Spacings.border_radius_4,
  },
  all_prodeuct_text: {
    ...Texts.button_text_9,
    ...Colors.font_fff,
  },
};
