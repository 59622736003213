import {
  createContext,
  useCallback,
  ReactNode,
  Context,
  useEffect,
} from "react";
import {
  NavigateOptions,
  To,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { navigateRef } from "./NavigateFunction";
import MainStore from "../../store/MainStore";
import SearchStore from "../../store/SearchStore";
import CommonStore from "../../store/CommonStore";

interface NavigationContextType {
  goTo: (path: To, options?: NavigateOptions) => void;
  goBack: () => void;
  goBackTwice: () => void;
}

const NavigationContext: Context<NavigationContextType | undefined> =
  createContext<NavigationContextType | undefined>(undefined);

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const goTo = useCallback(
    (path: To, navigateOptions?: NavigateOptions) => {
      const options = {
        ...navigateOptions,
        state: { ...navigateOptions?.state, prePath: location.pathname },
      };
      CommonStore.setFixedPreviousRouteName(
        CommonStore.fixedCurrentRouteName || ""
      );
      MainStore.setMainTabScroll(location.pathname.toLowerCase());
      SearchStore.setSearchResultTabScroll(location.pathname.toLowerCase());
      navigate(path, options);
    },
    [location.pathname, navigate]
  );

  const goBack = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      CommonStore.setFixedPreviousRouteName(
        CommonStore.fixedCurrentRouteName || ""
      );
      navigate(-1);
    } else {
      navigate("/");
    }
  }, [navigate]);

  const goBackTwice = useCallback(() => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-2);
    } else {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    if (navigateRef) {
      navigateRef.current = {
        goTo: goTo,
        goBack: goBack,
        goBackTwice: goBackTwice,
      };
    }
  }, [goBack, goTo, goBackTwice]);

  return (
    <NavigationContext.Provider value={{ goTo, goBack, goBackTwice }}>
      {children}
    </NavigationContext.Provider>
  );
};
