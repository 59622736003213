export const getNumberFormat = (number: any) => {
  if (number == isNaN || number == undefined) {
    return
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getNumberFormatKM = (number: number, digits = 0) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (number >= si[i].value) {
      break
    }
  }
  return (number / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export const CommaAddInTextInput = (value: string) => {
  const removeCommaValue = value?.replace(/,/g, '')
  return removeCommaValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatVoltAmount = (
  voltAmount?: number,
  minFormatAmount = 999999
) => {
  return (voltAmount || 0) > minFormatAmount
    ? getNumberFormatKM(voltAmount || 0)
    : CommaAddInTextInput(voltAmount?.toString() || '-')
}

export const clamp = (value: number, min: number, max: number) => {
  return Math.max(min, Math.min(max, value))
}
