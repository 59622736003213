import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { t } from "i18next";
import { PreSignedUrlResponseForAllPath, UploadFile } from "../api/file/model";
import { createPost, deletePost, getRecommendTag } from "../api/post/api";
import {
  PostContents,
  PostWriteRequest,
  PostWriteResponse,
  RecommendTagResponse,
} from "../api/post/model";
import { searchTag } from "../api/tag/api";
import { TagSearchRequest, TagSearchResponse } from "../api/tag/model";
import {
  FEED_TAG_SEARCH_COUNT,
  FeedMode,
  FeedTypeCode,
  POST_BLANK,
  POST_BLANK_CONTENTS,
  POST_BLANK_FILE,
  UploadTypeCode,
} from "../constants/Feed";
import dayjs, { Dayjs } from "dayjs";
import CommonStore from "./CommonStore";
import { ErrorCode } from "../constants/Error";
import {
  AlbumMulitResponse,
  callNativeAlbum,
  ImageForUI,
} from "../utils/album";
import { SuccessOrNot } from "../constants/Common.enum";
import { checkBadWord } from "../api/badWord/api";
import { BucketTypeCode, FileType } from "../constants/File";
import { NewProduct } from "../api/product/model";
import { ProductForWrite } from "./ProductSearchAddStore";
import PostStore from "./PostStore";
import {
  Mission,
  MissionParticipateRequest,
  MissionSubmitResponse,
} from "../api/mission/model";
import { postMissionParticipate } from "../api/mission/api";
import { MISSION_EXCEPTION } from "../constants/mission";
import BadgeStore from "./BadgeStore";
import { BadgeCode } from "../constants/Badge.enum";
import PostModifyStore from "./PostModifyStore";
import { track } from "../hooks/tracker/TrackFunction";
import MissionStore from "./MissionStore";
import UploadStore from "./upload/UploadStore";
import { POST_TEMP_DATA } from "../constants/Storage";
import { showBottomToast } from "../utils/Toast";
import { UploadFileType, UploadStatus } from "../constants/upload/UploadStatus";
import { CustomError } from "../utils/customError";
import { bottomOffset, sleep } from "../utils/common";
// import { DragEndParams } from 'react-native-draggable-flatlist'
// import ImageResizer from 'react-native-image-resizer'
import Resizer from "react-image-file-resizer";
// import RNFS from 'react-native-fs'
import { ProductSelectFromEnum } from "../constants/product/ProductSelectFrom";
import { callFrontErrorLog } from "../api/log/api";
import { goBack, goTo } from "../hooks/navigate/NavigateFunction";
import { resolve } from "path";
import { reject } from "lodash";
import { delIndexedDB } from "../utils/IndexedDB";
import PostDetailStore from "./PostDetailStore";
import {
  convertPostDetailToPost,
  convertPostToTagFeedItem,
} from "../utils/convert";
import { UserActTypeCode } from "../constants/FeedType.enum";
import FeedDetailStore from "./FeedDetailStore";
import MyHomeStore from "./MyHomeStore";
import SearchStore from "./SearchStore";
import TagStore from "./TagStore";
import BookmarkStore from "./BookmarkStore";
import MainStore from "./MainStore";
import { AccountFeedTypeCode } from "../constants/Account";
import { ActHistoryTypeCode, ActPageCode } from "../constants/ActHistory";
export interface PostLocalSave {
  wholeTempData: PostWriteRequest;
  date: Dayjs;
  products: ProductForWrite[];
}
export interface PostUIContents {
  id: number;
  contents: PostContents;
  modified?: boolean;
}
class PostWriteStore {
  @observable errorMessage?: string;
  @observable toastMessage?: string;

  @observable hasTemp = false;
  @observable wholeTempData: PostWriteRequest = POST_BLANK;
  @observable contentsTempData: PostUIContents[] = [
    { id: 0, contents: POST_BLANK_CONTENTS },
  ];
  @observable tempUpdated = false;
  @observable isShowRating = false;
  @observable tagSearchResult?: TagSearchResponse | null;
  @observable tempTagList: string[] = [];
  @observable tagUpdated = false;
  @observable isVisibleTagList = false;
  @observable isValidContents = false;
  @observable isChanged = false;
  @observable lastFocus: any;
  @observable recommendTags: RecommendTagResponse[] = [];
  @observable allContentValid = true;
  @observable allFilesValid = true;
  @observable debounceTimer: NodeJS.Timeout | undefined;
  @observable tempProductList: ProductForWrite[] = [];
  @observable productUpdated = false;
  @observable missionMessage?: string;
  @observable missionMessageTitle?: string;
  @observable missionData: Mission | null = null;
  @observable missionParam: MissionParticipateRequest | null = null;
  //@observable videoCompressing = false;
  @observable isPostSaveIng = false;
  @observable isPostRegistError = false;
  @observable wholeDataForSaveCall: PostWriteRequest = POST_BLANK;
  @observable voltAmount = 0;
  @observable subElementHeight: number[] = [];
  @observable subElementCount = 0;
  @observable subElementChanged = "";

  constructor() {
    makeObservable(this);
  }

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    );
  }

  @computed get missonSubmit() {
    return (
      this.missionMessage !== null &&
      this.missionMessage !== undefined &&
      this.missionMessage.length > 0
    );
  }

  @computed get isValid() {
    let checkingValid = false;
    // befor
    // if (
    //   this.wholeTempData === null ||
    //   JSON.stringify(this.wholeTempData) === JSON.stringify(POST_BLANK)
    // )
    // after
    if (
      this.wholeTempData === null ||
      (this.wholeTempData.title.length === 0 &&
        this.wholeTempData.tagNames.length === 0 &&
        this.wholeTempData.products?.length === 0 &&
        this.wholeTempData.contents[0].content?.length === 0 &&
        this.wholeTempData.contents[0].files[0].fileSize === -1)
    )
      return false;

    checkingValid =
      this.wholeTempData?.title?.trim()?.length > 0 &&
      this.tempTagList?.length > 0 &&
      this.isValidContents;

    if (this.contentsTempData.length > 0) {
      const advantage =
        (this.wholeTempData?.advantage?.trim()?.length || 0) > 0;
      const disadvatage =
        (this.wholeTempData?.disadvantage?.trim()?.length || 0) > 0;
      const rating = (this.wholeTempData?.rating || 0) > 0;
      checkingValid =
        checkingValid &&
        advantage === disadvatage &&
        advantage === rating &&
        disadvatage === rating;
    }

    // checkingValid = checkingValid && !this.videoCompressing;

    return checkingValid;
  }

  @action showValidInfo = () => {
    if (this.wholeTempData?.title?.trim()?.length < 1) {
      showBottomToast(t("screen.post.message.inputTitle"), bottomOffset());
      return;
    }
    if (this.allFilesValid === false) {
      showBottomToast(t("screen.post.message.inputFile"), bottomOffset());
      return;
    }
    if (this.allContentValid === false) {
      showBottomToast(t("screen.post.message.inputContent"), bottomOffset());
      return;
    }
    if (this.tempTagList?.length < 1) {
      showBottomToast(t("screen.post.message.inputTag"), bottomOffset());
      return;
    }
    if (
      (this.contentsTempData.length > 0 &&
        this.wholeTempData?.advantage &&
        this.wholeTempData?.advantage?.trim()?.length > 0) ||
      (this.wholeTempData?.disadvantage &&
        this.wholeTempData?.disadvantage?.trim()?.length > 0) ||
      (this.wholeTempData?.rating && this.wholeTempData?.rating > 0)
    ) {
      // 단락 하나 이상일 때 등록 조건
      if (
        !this.wholeTempData?.advantage ||
        this.wholeTempData?.advantage?.trim()?.length < 1
      ) {
        showBottomToast(
          t("screen.post.message.inputAdvantage"),
          bottomOffset()
        );
        return;
      }
      if (
        !this.wholeTempData?.disadvantage ||
        this.wholeTempData?.disadvantage?.trim()?.length < 1
      ) {
        showBottomToast(
          t("screen.post.message.inputDisadvatage"),
          bottomOffset()
        );
        return;
      }
      if (!this.wholeTempData?.rating || this.wholeTempData?.rating === 0) {
        showBottomToast(t("screen.post.message.inputRating"), bottomOffset());
        return;
      }
    }
  };

  @action setSubElementCount = () => {
    let tempSubElementCount = 0;

    for (let i = 0; i < 10; i++) {
      if (this.subElementHeight[i] > 0) {
        tempSubElementCount += 1;
      }
    }
    this.subElementCount = tempSubElementCount;
  };

  @action setSubElementHeight = (index: number, height: number) => {
    if (this.subElementHeight.length - 1 >= index) {
      this.subElementHeight[index] = height;
    } else {
      this.subElementHeight.push(height);
    }
  };

  @action removeSubElementHeight = (index: number) => {
    if (index > -1) {
      this.subElementHeight.splice(index, 1);
    }
  };

  @action setSubElementChanged = (changed: string) => {
    this.subElementChanged = changed;
  };

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action setToast = (message?: string) => {
    this.toastMessage = message;
  };

  @action setMissionMessage = (message?: string) => {
    this.missionMessage = message;
  };

  @action setMissionMessageTitle = (message?: string) => {
    this.missionMessageTitle = message;
  };

  @action setMissionData = (
    data: Mission,
    params: MissionParticipateRequest
  ) => {
    this.missionData = data;
    this.missionParam = params;
    if (data?.missionPicture?.missionTagName !== undefined)
      this.addTag(data?.missionPicture?.missionTagName || "");
  };

  @action clear = () => {
    this.errorMessage = "";
    this.toastMessage = "";
    this.wholeTempData = POST_BLANK;
    this.contentsTempData = [{ id: 0, contents: POST_BLANK_CONTENTS }];
    this.tempTagList = [];
    // this.lastUploadIndex = 0;
    // this.callIndex = 0;
    this.allContentValid = true;
    this.allFilesValid = true;
    // this.uploadFileClear();
    this.wholeDataForSaveCall = POST_BLANK;
    this.tagSearchResult = null;
    this.tempProductList = [];
    this.missionMessage = "";
    this.missionMessageTitle = "";
    this.missionData = null;
    this.missionParam = null;
    this.subElementHeight = [];
    this.subElementCount = 0;
    this.subElementChanged = "";
    this.change();
  };

  @action clearFocus = () => {
    this.subElementHeight = [];
    this.subElementCount = 0;
    this.subElementChanged = "";
  };

  @action change = () => {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      runInAction(() => {
        this.isChanged = !this.isChanged;
      });
    }, 200);
  };

  @action dataUnion = () => {
    // 각각의 리스트의 데이터를 wholeTempData에 합체
    this.wholeTempData.contents = [];
    this.contentsTempData?.forEach((item) =>
      this.wholeTempData.contents.push(item.contents)
    );
    this.wholeTempData.products = [];
    this.tempProductList.forEach((item) => {
      item.product.openApiFlag && item.product.nproductId === undefined
        ? item.product.myProductId && item.product.myProductId != null
          ? this.wholeTempData.products?.push({
              id: item.product.myProductId,
              thumbnailYn: item.isRepresent ? "Y" : "N",
              tempProductYn:
                item.product?.openApiFlag || item.product?.tempProductId
                  ? "Y"
                  : "N",
              product_from: item.product.from ?? "",
            })
          : this.wholeTempData.products?.push({
              id: -1,
              openApiFlag: true,
              productId: item.product?.productId,
              productName: item.product?.productName,
              brandNameKor: item.product?.brandName,
              productModelName: item.product?.productModelName,
              lprice: item.product?.lprice,
              hprice: item.product?.hprice,
              thumbnailFilePath: item.product?.thumbnailFilePath,
              category1: item.product?.category1,
              category2: item.product?.category2,
              category3: item.product?.category3,
              productUrl: item.product?.productUrl,
            })
        : this.wholeTempData.products?.push({
            id: item.product?.productId || item.product?.tempProductId || -1,
            thumbnailYn: item.isRepresent ? "Y" : "N",
            tempProductYn:
              item.product?.openApiFlag || item.product?.tempProductId
                ? "Y"
                : "N",
            product_from: item.product.from ?? "",
          });
    });
    this.wholeTempData.tagNames = this.tempTagList;
    this.change();
  };

  @action isValidContent = () => {
    this.allContentValid = true;
    this.allFilesValid = true;
    let tempValid = true;
    this.contentsTempData?.forEach((_, index: number) => {
      const fileCheck =
        this.contentsTempData[index].contents?.files.findIndex(
          (item) => item.fileName.trim()?.length > 0
        ) > -1;
      const contentCheck =
        (this.contentsTempData[index].contents?.content || "").trim()?.length >
        0;
      tempValid = tempValid && fileCheck && contentCheck;
      if (fileCheck === false) this.allFilesValid = false;
      if (contentCheck === false) this.allContentValid = false;
    });
    this.isValidContents = tempValid;
    this.dataUnion();
  };

  @action setWholeTempData = (data?: PostLocalSave | null) => {
    this.clear();
    if (data) {
      this.wholeTempData = data?.wholeTempData
        ? data.wholeTempData
        : POST_BLANK;
      this.contentsTempData = [];
      this.wholeTempData?.contents?.forEach((item, index) =>
        this.contentsTempData.push({ id: index, contents: item })
      );
      this.tempTagList = this.wholeTempData?.tagNames;
      this.tempProductList = data?.products ? data.products : [];
    }
    this.checkShowRating();
    this.isValidContent();
    this.tempUpdated = !this.tempUpdated;
    this.tagUpdated = !this.tagUpdated;
    this.productUpdated = !this.productUpdated;
  };

  @observable fileUpdated = false;
  @observable finishFileUpdate = false;
  @observable finishFileUpdateIndex = -1;
  @action checkAddedFiles = (contentsIndex: number) => {
    let imageFileLength = 0;
    let videoFileLength = 0;
    this.contentsTempData[contentsIndex].contents.files.forEach((item) => {
      if (item.fileType === FileType.IMAGE && item.fileSize >= 0)
        imageFileLength = imageFileLength + 1;
      if (item.fileType === FileType.VIDEO && item.fileSize >= 0)
        videoFileLength = videoFileLength + 1;
    });

    if (contentsIndex === 0) {
      if (imageFileLength + videoFileLength < 10)
        this.contentsTempData[contentsIndex].contents.files.push(
          POST_BLANK_FILE
        ); // 사진 추가하는 화면
    } else {
      if (
        (imageFileLength === 0 && videoFileLength < 1) ||
        (videoFileLength === 0 && imageFileLength < 3)
      ) {
        this.contentsTempData[contentsIndex].contents.files.push(
          POST_BLANK_FILE
        );
      }
    }
    if (
      contentsIndex === 0 &&
      this.contentsTempData[0].contents.files.findIndex(
        (i) => i.thumbnailYn === "Y"
      ) < 0
    )
      this.contentsTempData[0].contents.files[0].thumbnailYn = "Y";

    this.fileUpdated = !this.fileUpdated;
    this.finishFileUpdate = true;
    this.finishFileUpdateIndex = contentsIndex;
    this.isValidContent();
    this.change();
  };

  @action setFinishFileUpdate = (finishFileUpdate: boolean) => {
    this.finishFileUpdate = finishFileUpdate;
  };

  @action setFinishFileUpdateIndex = (contentsIndex: number) => {
    this.finishFileUpdateIndex = contentsIndex;
  };

  @action updateFilterFile2 = (
    contentsIndex: number,
    fileInfo: { fileIndex: number; uri: string; urithumbnail: string }[]
  ) => {
    fileInfo.forEach((fileitem) => {
      const item =
        this.contentsTempData[contentsIndex]?.contents?.files[
          fileitem.fileIndex
        ];

      item.thumbnailTempPath = fileitem.urithumbnail;
      item.imageLocalFilePath = fileitem.uri;

      this.contentsTempData[contentsIndex]?.contents?.files.splice(
        fileitem.fileIndex,
        1
      );
      this.contentsTempData[contentsIndex]?.contents?.files.splice(
        fileitem.fileIndex,
        0,
        item
      );
    });

    this.updateItem(contentsIndex);
  };

  @action deleteFile = (contentsIndex: number, fileIndex: number) => {
    if (contentsIndex === 0) {
      this.contentsTempData[0]?.contents?.files.splice(fileIndex, 1);
      if (this.contentsTempData[0]?.contents?.files.length > 0)
        this.setRepresentation(0);
    } else {
      this.contentsTempData[contentsIndex]?.contents?.files.splice(
        fileIndex,
        1
      );
    }
    this.updateItem(contentsIndex);
    return this.contentsTempData[contentsIndex]?.contents?.files;
  };

  updateItem = (contentsIndex: number) => {
    let imageFileLength = 0;
    let videoFileLength = 0;
    let notExistEmptyPathFile = true;
    this.contentsTempData[contentsIndex]?.contents?.files.forEach((item) => {
      if (item.fileType === FileType.IMAGE && item.fileSize >= 0)
        imageFileLength = imageFileLength + 1;
      if (item.fileType === FileType.VIDEO && item.fileSize >= 0)
        videoFileLength = videoFileLength + 1;
      if (item.filePath === "") notExistEmptyPathFile = false;
    });

    if (contentsIndex === 0) {
      if (imageFileLength + videoFileLength < 10 && notExistEmptyPathFile)
        this.contentsTempData[contentsIndex].contents.files.push(
          POST_BLANK_FILE
        ); // 사진 추가하는 화면
    } else {
      if (
        (imageFileLength === 0 &&
          videoFileLength < 1 &&
          notExistEmptyPathFile) ||
        (videoFileLength === 0 && imageFileLength < 3 && notExistEmptyPathFile)
      )
        this.contentsTempData[contentsIndex].contents.files.push(
          POST_BLANK_FILE
        );
    }

    this.fileUpdated = !this.fileUpdated;
    this.isValidContent();
    this.change();
  };

  @action checkShowRating = () => {
    this.isShowRating = this.contentsTempData?.length > 0;
    this.tempUpdated = !this.tempUpdated;
  };

  @action addContents = () => {
    if (this.contentsTempData.length < 10) {
      this.contentsTempData.push({
        id: this.contentsTempData[this.contentsTempData.length - 1].id + 1,
        contents: POST_BLANK_CONTENTS,
      });
      this.tempUpdated = !this.tempUpdated;
      this.isValidContent();

      this.setSubElementChanged("add");
    } else {
      showBottomToast(t("screen.post.message.maxContentes"));
    }
    this.checkShowRating();
    this.change();
  };
  @action moveItem = (
    contentsIndex: number,
    fromIndex: number,
    toIndex: number
  ) => {
    const item = this.contentsTempData[contentsIndex]?.contents?.files.splice(
      fromIndex,
      1
    )[0];
    this.contentsTempData[contentsIndex]?.contents?.files.splice(
      toIndex,
      0,
      item
    );
    contentsIndex === 0 && this.setRepresentation(0);
    this.updateItem(contentsIndex);

    return [...this.contentsTempData[contentsIndex]?.contents?.files];
  };
  @action removeContents = (index: number) => {
    this.contentsTempData.splice(index, 1);
    this.tempUpdated = !this.tempUpdated;
    //4920 컨텐츠 삭제 시 장단점이 1개였을떄도 노출되도록 로직변경 떄문에 해당 내용 주석처리
    if (this.contentsTempData.length < 1) {
      delete this.wholeTempData?.disadvantage;
      delete this.wholeTempData?.advantage;
      delete this.wholeTempData?.rating;
    }

    this.removeSubElementHeight(index);
    this.setSubElementChanged("remove");
    this.checkShowRating();
    this.isValidContent();
    this.change();
  };

  @action setRepresentation = (index: number) => {
    this.contentsTempData[0].contents.files.forEach(
      (item) => (item.thumbnailYn = "N")
    );
    this.contentsTempData[0].contents.files[index].thumbnailYn = "Y";
    this.fileUpdated = !this.fileUpdated;
    this.change();
  };

  @action setContentContents = (contentsIndex: number, contents: string) => {
    this.contentsTempData[contentsIndex].contents.content = contents;
    this.tempUpdated = !this.tempUpdated;
    this.isValidContent();
    this.change();
  };

  @action setTitle = (title: string) => {
    this.wholeTempData.title = title;
    this.change();
  };

  @action setAdvantage = (advantage: string) => {
    this.wholeTempData.advantage = advantage;
    this.change();
  };

  @action setDisadvantage = (disadvantage: string) => {
    this.wholeTempData.disadvantage = disadvantage;
    this.change();
  };

  @action setRatingPoint = (point: number) => {
    this.wholeTempData.rating = point;
    this.tempUpdated = !this.tempUpdated;
    this.change();
  };

  @action searchTag = async (word: string, startNumber: number) => {
    if (startNumber === 0) {
      this.tagSearchResult = null;
    }
    const requestItem: TagSearchRequest = {
      query: word,
      synonymYn: "Y",
      pageSize: FEED_TAG_SEARCH_COUNT,
      pageIndex: startNumber,
    };

    const res = await searchTag(requestItem);

    this.setTagSearchResult(res, startNumber);
  };

  @action setTagSearchResult = (
    response: TagSearchResponse | null,
    startNumber: number
  ) => {
    if (startNumber === 0) {
      this.tagSearchResult = response;
      this.isVisibleTagList = true;
    } else response?.list && this.tagSearchResult?.list.push(...response?.list);
    this.tagUpdated = !this.tagUpdated;
  };

  @action addTag = (tag: string) => {
    if (tag.trim()?.length < 1) return;
    if (this.tempTagList.length > 9) {
      showBottomToast(t("screen.post.message.maxTag"), bottomOffset());
      return;
    }
    const duplication = this.tempTagList.findIndex((text) => text === tag);
    if (duplication < 0) this.tempTagList.push(tag);
    this.setVisibleTagList(false);
    this.dataUnion();
  };

  @action deleteTag = (tag: string) => {
    const targetIndex = this.tempTagList.findIndex((text) => text === tag);
    this.tempTagList.splice(targetIndex, 1);
    this.dataUnion();
  };

  @action setVisibleTagList = (isVisible: boolean) => {
    this.isVisibleTagList = isVisible;
  };

  @action checkValidWord = async (
    word: string,
    focus: any,
    inputName: string,
    successAction?: () => void
  ) => {
    if (word.trim().length < 1) return;
    this.lastFocus = focus;
    const res = await checkBadWord(word);
    if (res != null && res !== undefined) {
      if (res?.hasBadWord === "N") {
        if (successAction !== undefined) successAction();
      } else {
        showBottomToast(t("screen.post.message.validError"), bottomOffset());
      }
    } else {
      showBottomToast(t("common.error.server"), bottomOffset());
    }
  };

  @action addProduct = (item: NewProduct) => {
    const duplication = this.tempProductList.findIndex(
      (info) =>
        item.productId ===
        (info.product.productId || info.product.tempProductId)
    );
    if (duplication < 0) {
      this.tempProductList.push({ product: item, isRepresent: false });
      this.setAutoRepresent();
    } else {
      showBottomToast(
        t("screen.feedProduct.message.alreadyAdd"),
        bottomOffset()
      );
    }
    const tempProductIds: number[] = [];
    this.tempProductList?.forEach(
      (item) =>
        item?.product?.productId &&
        !item?.product?.openApiFlag &&
        tempProductIds.push(item.product.productId)
    );
    void this.getRecommendTag(tempProductIds);
  };

  @action setAutoRepresent = () => {
    if (this.tempProductList && this.tempProductList.length > 0) {
      const representIndex = this.tempProductList.findIndex(
        (item) => item.isRepresent && item.product.productId
      );
      if (representIndex < 0) {
        const willBeRepresentIndex = this.tempProductList.findIndex(
          (item) => item.product.productId
        );
        if (willBeRepresentIndex > -1)
          this.tempProductList[willBeRepresentIndex].isRepresent = true;
      }
    }
    this.productUpdated = !this.productUpdated;
    this.dataUnion();
  };

  @action deleteProduct = (index: number) => {
    this.tempProductList.splice(index, 1);
    this.setAutoRepresent();

    const tempProductIds: number[] = [];
    this.tempProductList?.forEach(
      (item) =>
        item?.product?.productId &&
        !item?.product?.openApiFlag &&
        tempProductIds.push(item.product.productId)
    );
    void this.getRecommendTag(tempProductIds);
  };

  @action setRepresentProduct = (index: number) => {
    this.tempProductList.forEach((item) => (item.isRepresent = false));
    this.tempProductList[index].isRepresent = true;
    this.productUpdated = !this.productUpdated;
    this.dataUnion();
  };

  @action saveMissionPost = async (feedId: number) => {
    CommonStore.setLoading(true);
    if (this.missionParam) this.missionParam.missionTargetId = feedId;
    if (this.missionData && this.missionParam) {
      const response = await postMissionParticipate(
        this.missionData?.missionId,
        this.missionParam
      );

      if (response?.successOrNot === SuccessOrNot.Y) {
        const result = response.data as MissionSubmitResponse;

        void BadgeStore.obtainBadge(BadgeCode.MISSION);

        void PostStore.initPostLists();
        track("complete_mission", {
          mission_id: MissionStore.missionDetail.missionId || 0,
          mission_title: MissionStore.missionDetail.missionTitle || "",
          mission_type: MissionStore.missionDetail.missionTypeCode || "",
          participation_count:
            MissionStore.missionDetail.participationCount || 0,
        });

        if (
          (result.participationCount || 0) >= (result.missionGoalNumber || 0)
        ) {
          MissionStore.setShowCompletePopup(true);
          MissionStore.setIsEndFirstPostingLoad(false);
          MissionStore.getMissionPost(
            MissionStore.missionDetail.missionId,
            null
          ).finally(() => {
            MissionStore.setIsEndFirstPostingLoad(true);
            goBack();
          });
        } else {
          MissionStore.setShowCompletePopup(false);
          this.setMissionMessageTitle(
            t("screen.mission.label.wroteCount", {
              count: result.participationCount,
            })
          );
          this.setMissionMessage(
            t("screen.mission.message.wroteCount", {
              count1: result.participationCount,
              count2:
                (result.missionGoalNumber || 0) -
                (result.participationCount || 0),
            })
          );
        }
        CommonStore.setLoading(false);
      } else {
        //미션 참여 실패됐을 경우 포스팅 삭제처리
        void (await PostModifyStore.deletePostAtList(feedId));
        CommonStore.setLoading(false);

        if (
          response?.statusCode ===
          MISSION_EXCEPTION.MISSION_PARTICIPANTION_INFO_ERR
        ) {
          this.setError(t("screen.mission.message.error_info"));
        } else if (
          response?.statusCode === MISSION_EXCEPTION.MISSION_LEVEL_ERR
        ) {
          this.setError(t("screen.mission.message.error_level"));
        } else if (
          response?.statusCode === MISSION_EXCEPTION.MISSION_BADGE_ERR
        ) {
          this.setError(t("screen.mission.message.error_badge"));
        } else if (
          response?.statusCode === MISSION_EXCEPTION.MISSION_DUPULICATE_ERR
        ) {
          this.setError(t("screen.mission.message.error_dup_phone"));
        } else if (response?.statusCode === MISSION_EXCEPTION.MISSION_NOT_WON) {
          this.setError(t("screen.mission.message.error_not_won"));
        } else if (
          response?.statusCode ===
          MISSION_EXCEPTION.MISSION_PARTICIPATION_DATE_ERR
        ) {
          this.setError(t("screen.mission.message.error_end"));
        } else {
          this.setError(t("common.error.server"));
        }
      }
    }
  };

  @action savePost = (successAction?: () => void) => {
    // this.trackUploadPost();
    // const trackingData = this.wholeTempData;
    // createPost(this.wholeDataForSaveCall)
    //   .then((res) => {
    //     this.wholeDataForSaveCall = POST_BLANK;
    //     CommonStore.setLoading(false);
    //     this.setIsPostSaveIng(false);
    //     if (res.successOrNot === SuccessOrNot.Y && res.statusCode === "OK") {
    //       runInAction(() => {
    //         const data: PostWriteResponse = res.data as PostWriteResponse;
    //         if (data?.feedId !== undefined && data?.feedId > -1) {
    //           if ((this.missionData?.missionId || -1) > -1) {
    //             // 미션으로tag 등록된 경우
    //             void this.saveMissionPost(data?.feedId, successAction);
    //           } else {
    //             //공통 액션
    //             if (successAction) successAction();
    //             //일반 등록인 경우
    //             CommonStore.setToastOption({
    //               show: true,
    //               message1: t("screen.post.message.registerd"),
    //               // bottomOffset: 30,
    //             });
    //             PostStore.postFilterReset();
    //             void PostStore.getRecentFeedDetailList(10, null, "POST", "N");
    //             PostStore.setSelectedTab(1);
    //             goTo("PostStack", {
    //               state: { selectedTab: "RECENT" },
    //             });
    //           }
    //           this.trackCompleteUploadPost(trackingData);
    //         }
    //       });
    //     } else {
    //       runInAction(() => {
    //         if (res.statusCode === ErrorCode.HAS_BAD_WORD.code) {
    //           this.setError(t(ErrorCode.HAS_BAD_WORD.message));
    //         } else if (res.statusCode === ErrorCode.HAS_BLOCKED_TAG.code) {
    //           if (res?.blockedTagNames !== undefined) {
    //             const tagString = res?.blockedTagNames
    //               ?.replace("[", "")
    //               .replace("]", "");
    //             this.setError(
    //               t(ErrorCode.HAS_BLOCKED_TAG.message, { tag: tagString })
    //             );
    //           }
    //         } else if (res.statusCode === ErrorCode.PARAM_LENGTH_ERR.code) {
    //           if (res.lengthErrorParameter === "advantage") {
    //             this.setError(t("common.error.lengthLimit.advantage"));
    //           } else if (res.lengthErrorParameter === "disadvantage") {
    //             this.setError(t("common.error.lengthLimit.disadvantage"));
    //           } else if (res.lengthErrorParameter === "title") {
    //             this.setError(t("common.error.lengthLimit.title"));
    //           } else {
    //             this.setError(t("common.error.lengthLimit.default"));
    //           }
    //         } else {
    //           this.setError(t("common.error.server"));
    //         }
    //       });
    //     }
    //   })
    //   .catch(() => {
    //     this.wholeDataForSaveCall = POST_BLANK;
    //     CommonStore.setLoading(false);
    //     this.setIsPostSaveIng(false);
    //   });
  };

  @action callCreatePostApi = async () => {
    this.trackUploadPost(); // 트래킹

    return await createPost(this.wholeDataForSaveCall)
      .then((res) => {
        this.wholeDataForSaveCall = POST_BLANK;
        return res;
      })
      .then((res) => {
        if (res.successOrNot === SuccessOrNot.Y && res.statusCode === "OK") {
          return res;
        } else {
          throw CustomError(
            UploadStatus.FAIL,
            "file:PostWriteStore.ts,func:callCreatePostApi FAIL createPost"
          );
        }
      })
      .then((res) => {
        const data: PostWriteResponse = (res.data as PostWriteResponse) ?? {
          feedId: 0,
        };
        if (data?.feedId !== undefined && data?.feedId > -1) {
          CommonStore.callActHistory(
            ActHistoryTypeCode.FEED_WRITE,
            String(data.feedId),
            ActPageCode.POST_WRITE
          );
          return data;
        } else {
          throw CustomError(
            UploadStatus.FAIL,
            "file:PostWriteStore.ts,func:callCreatePostApi FAIL feedId"
          );
        }
      })
      .then((data) => {
        if (data.voltMessage === "Success") {
          this.voltAmount = data.voltAmount || 0;
        } else {
          this.voltAmount = 0;
        }
        if (this.isPostRegistError || UploadStore.isStop) {
          deletePost([data.feedId ?? 0]);
        }
        if (UploadStore.isStop) {
          throw CustomError(
            UploadStatus.STOP,
            "file:PostWriteStore.ts,func:callCreatePostApi STOP"
          );
        }
        if (this.isPostRegistError) {
          throw CustomError(
            UploadStatus.FAIL,
            "file:PostWriteStore.ts,func:callCreatePostApi FAIL"
          );
        }
        return data.feedId || 0;
      })
      .catch((e) => {
        if (e.name === UploadStatus.FAIL) UploadStore.fail();
        throw CustomError(String(e.name), String(e.message));
      });
  };
  @action callUpdatePostApi = () => {
    const apiRequest = this.createRequest();

    return createPost(apiRequest)
      .then((res) => {
        this.wholeDataForSaveCall = POST_BLANK;
        this.setIsPostSaveIng(false);
        return res;
      })
      .then((res) => {
        if (res.successOrNot === SuccessOrNot.Y && res.statusCode === "OK") {
          return res;
        } else {
          throw new Error("fail");
        }
      })
      .then((res) => {
        const data: PostWriteResponse = res.data as PostWriteResponse;
        if (data?.feedId !== undefined && data?.feedId > 0) {
          return data.feedId;
        } else {
          throw new Error("fail");
        }
      })
      .catch((e) => {
        if (e.name === "fail") UploadStore.fail();
        return -1;
      });
  };

  @action createRequest = () => {
    const apiData: PostWriteRequest = {
      feedId: this.wholeDataForSaveCall.feedId,
      title: this.wholeDataForSaveCall.title,
      contents: [],
      products: this.wholeDataForSaveCall.products,
      tagNames: this.wholeDataForSaveCall.tagNames,
      rating: this.wholeDataForSaveCall.rating,
      advantage: this.wholeDataForSaveCall.advantage,
      disadvantage: this.wholeDataForSaveCall.disadvantage,
    };

    this.wholeDataForSaveCall.contents.forEach((item, index) => {
      const contentItem: PostContents = {
        feedId: item.feedId,
        bucketTypeCode: item.bucketTypeCode || BucketTypeCode.PUBLIC,
        uploadTypeCode: item.uploadTypeCode || UploadTypeCode.POST,
        files: [],
        content: item.content,
      };

      contentItem.feedContentId = item.feedContentId;
      contentItem.feedFileGroupId = item.feedFileGroupId;

      item.files.forEach((file) => {
        contentItem.files.push({
          fileId: file.fileId,
          fileResolution: file.fileResolution,
          originalFileName: file.originalFileName,
          fileName: file.fileName,
          filePath: file.filePath,
          thumbnailFilePath: file.thumbnailFilePath,
          fileSize: file.fileSize,
          fileType: file.fileType,
          fileExtension: file.fileExtension,
          thumbnailYn: file.thumbnailYn,
          bucketTypeCode: file.bucketTypeCode || BucketTypeCode.PRIVATE,
          uploadTypeCode: file.uploadTypeCode || UploadTypeCode.POST,
        });
      });

      apiData.contents.push(contentItem);
    });

    return apiData;
  };

  preProcessWholeData = () => {
    this.wholeTempData.contents.forEach((item) => {
      item.files = item.files.filter((x) => x.filePath.trim().length > 1);
    });
  };

  trackUploadPost = () => {
    const fileTypes = this.wholeDataForSaveCall.contents.flatMap((f) =>
      f.files.flatMap((f) => f.fileType)
    );
    const image_count = fileTypes.filter((t) => t === "IMAGE").length;
    const video_count = fileTypes.filter((t) => t === "VIDEO").length;
    const review_product_count = this.wholeDataForSaveCall.products
      ? this.wholeDataForSaveCall.products.length
      : 0;
    const tagNames = this.wholeDataForSaveCall.tagNames;

    track("upload_post", {
      image_count: image_count,
      video_count: video_count,
      review_product_count: review_product_count,
      tag: tagNames.map((t) => t),
    });
  };

  trackCompleteUploadPost = (data: PostWriteRequest) => {
    const fileTypes = data.contents.flatMap((f) =>
      f.files.flatMap((f) => f.fileType)
    );
    const image_count = fileTypes.filter((t) => t === "IMAGE").length;
    const video_count = fileTypes.filter((t) => t === "VIDEO").length;
    const review_product_count = data.products ? data.products.length : 0;
    const tagNames = data.tagNames.map((t) => t);
    const is_pros = data.advantage ? "Y" : "N";
    const is_cons = data.disadvantage ? "Y" : "N";
    const resolution =
      data.contents.length > 0
        ? data.contents[0].files
            .filter((x) => x.thumbnailYn === "Y")
            .map((x) => (x.fileResolution ? x.fileResolution : ""))
        : "";

    const ttz_product_count =
      data.products?.filter((x) => x.product_from === ProductSelectFromEnum.TTZ)
        .length ?? 0;
    const naver_product_count =
      data.products?.filter(
        (x) => x.product_from === ProductSelectFromEnum.NAVER
      ).length ?? 0;
    const my_product_count =
      data.products?.filter((x) => x.product_from === ProductSelectFromEnum.MY)
        .length ?? 0;

    track("complete_upload_post", {
      content_type: FeedTypeCode.POST,
      image_count: image_count,
      video_count: video_count,
      review_product_count: review_product_count,
      tag: tagNames,
      is_pros: is_pros,
      is_cons: is_cons,
      resolution: resolution,
      "220_product_count": ttz_product_count,
      naver_product_count: naver_product_count,
      my_product_count: my_product_count,
    });
  };

  @action setIsPostSaveIng = (isPostSaveIng: boolean) => {
    this.isPostSaveIng = isPostSaveIng;
  };

  @action setIsPostRegistError = (isPostRegistError: boolean) => {
    this.isPostRegistError = isPostRegistError;
  };

  @action getMissionDetailForValid = async (missionId: number) => {
    const response = await MissionStore.getMissionDetailForValid(missionId);
    const mission = response.data as Mission;
    const now = dayjs();
    if (response.successOrNot === "N") {
      CommonStore.setLoading(false);
      this.setError(t("screen.mission.message.endMission"));
      return false;
    } else {
      if (response.successOrNot === "Y" && mission && mission.missionId > 0) {
        if (
          (mission.missionApproveYn && mission.missionApproveYn === "N") ||
          now.isAfter(mission.missionEndDate)
        ) {
          //승인 완료상태가 아닌 경우 또는 미션 종료기간이 지난경우
          CommonStore.setLoading(false);
          this.setError(t("screen.mission.message.endMission"));
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  };

  // 포스팅전 데이터 처리
  @action compressFiles = async (mode?: FeedMode) => {
    this.preProcessWholeData(); //데이터 전처리

    const compressFiles = [];
    for (const content of this.wholeTempData.contents) {
      compressFiles.push(...content.files);
    }
    const resultQueue = await UploadStore.compressByQueue(compressFiles, mode);

    //유효성 체크
    UploadStore.validateQueue(resultQueue);
    //최종 데이터 병합
    this.mergeData(resultQueue);
  };

  // [PPIT210117 - 4815] 포스트 저장 로직 변경
  @action postRegist = async (successAction?: () => void) => {
    this.setIsPostRegistError(false);
    if (!this.isPostSaveIng) {
      try {
        this.setIsPostSaveIng(true);
        let missionValid = true;

        if (this.missionData?.missionId) {
          //미션 포스팅전 최종 유효성 체크 후 진행한다.
          missionValid = await this.getMissionDetailForValid(
            this.missionData.missionId
          );
        }
        if (missionValid) {
          this.wholeDataForSaveCall = JSON.parse(
            JSON.stringify(this.wholeTempData)
          );
          await Promise.all(
            this.wholeTempData.contents.map(async (content, contentIndex) => {
              await Promise.all(
                content.files.map(async (file, fileIndex) => {
                  if (file.filePath.trim().length > 0 && file.fileSize >= 0) {
                    const isVideo =
                      file.fileType.toUpperCase() === FileType.VIDEO;
                    const isImage =
                      file.fileType.toUpperCase() === FileType.IMAGE;

                    let originalFileName = file.fileName;
                    if (isVideo) {
                      originalFileName = file.fileName;
                    }
                    if (isImage) {
                      originalFileName =
                        file.fileName + "." + file.fileExtension;
                    }

                    const thumbnailFileName = originalFileName.replace(
                      "." + file.fileExtension,
                      "_thumb.jpg"
                    );

                    if (this.isPostRegistError) {
                      throw CustomError(
                        "MISSIONWRITE",
                        "file:PostWriteStore.ts,func:postRegist isPostRegistError"
                      );
                    }

                    const request = UploadStore.createPreSignedUrlRequest(
                      originalFileName,
                      thumbnailFileName,
                      UploadTypeCode.POST
                    );
                    const response =
                      await UploadStore.getPreSignedUrlForAllPath(request);

                    // 1.이미지 업로드 case
                    if (isImage) {
                      const [imageResponse, thumbNailResponse] =
                        await Promise.all([
                          UploadStore.uploadFileToS3(
                            file,
                            response,
                            UploadFileType.IMAGE
                          ),
                          UploadStore.uploadFileToS3(
                            file,
                            response,
                            UploadFileType.THUMBNAIL,
                            true
                          ),
                        ]);

                      if (!imageResponse.ok || !thumbNailResponse.ok) {
                        throw CustomError(
                          "MISSIONWRITE",
                          "file:PostWriteStore.ts,func:postRegist imageResponse"
                        );
                      }

                      this.changeSaveDataThumbnailPath(
                        contentIndex,
                        fileIndex,
                        response
                      );
                      this.changeSaveDataFilePath(
                        contentIndex,
                        fileIndex,
                        response
                      );
                    }

                    // 2.영상 업로드 case
                    // 썸네일 이미지와 영상 파일을 각각 업로드함.
                    if (isVideo) {
                      const [videoResponse, thumbNailResponse] =
                        await Promise.all([
                          UploadStore.uploadFileToS3(
                            file,
                            response,
                            UploadFileType.VIDEO
                          ), // video upload
                          UploadStore.uploadFileToS3(
                            file,
                            response,
                            UploadFileType.THUMBNAIL,
                            true
                          ), //video thumbnail upload
                        ]);

                      if (!thumbNailResponse.ok || !videoResponse.ok) {
                        throw CustomError(
                          "MISSIONWRITE",
                          "file:PostWriteStore.ts,func:postRegist thumbNailResponse"
                        );
                      }

                      this.changeSaveDataThumbnailPath(
                        contentIndex,
                        fileIndex,
                        response
                      );
                      this.changeSaveDataFilePath(
                        contentIndex,
                        fileIndex,
                        response
                      );
                    }
                  } //end if
                })
              );
            })
          );
          if (!this.isPostRegistError) {
            void this.savePost(successAction);
          }
        }
      } catch (e: any) {
        this.postingError();
        UploadStore.clear();
        void callFrontErrorLog({
          funcName: e.name + "_" + e.message,
          errorMessage: e.stack,
        });
      }
    }
  };

  @action uploadFilesToS3 = async (mode: FeedMode) => {
    if (UploadStore.isStop)
      throw CustomError(
        UploadStatus.STOP,
        "file:PostWriteStore.ts,func:uploadFilesToS3"
      );

    UploadStore.uploading();
    const files = [];
    if (this.isPostSaveIng) {
      //업로드 필요한 파일데이터 신규 배열 저장.
      for (const content of this.wholeTempData.contents) {
        files.push(...content.files);
      }
      const resultQueue = await UploadStore.putFileToS3ByQueue(
        files,
        UploadTypeCode.POST,
        mode
      );
      //유효성 체크
      UploadStore.validateQueue(resultQueue);
      //최종 데이터 병합
      this.mergeData(resultQueue);

      this.wholeDataForSaveCall = JSON.parse(
        JSON.stringify(this.wholeTempData)
      );
      for (const content of this.wholeDataForSaveCall.contents) {
        content.files.forEach((file) => {
          file.imageLocalFilePath = "";
          file.imageOriginLocalFilePath = "";
          file.imageTempPath = "";
          file.thumbnailTempPath = "";
          file.videoLocalFilePath = "";
          file.videoTempPath = "";
        });
      }
    }
  };

  @action getRecommendTag = async (productIds?: number[]) => {
    const res = await getRecommendTag(productIds);

    runInAction(() => {
      if (res != null) {
        this.recommendTags = res;
      } else {
        showBottomToast(t("common.error.server"), bottomOffset());
      }
    });
  };

  @action postingError = () => {
    this.setIsPostRegistError(true);
    this.setError(t("common.error.server"));
    CommonStore.setLoading(false);
    this.setIsPostSaveIng(false);
  };

  @action changeSaveDataThumbnailPath = (
    contentIndex: number,
    fileIndex: number,
    response: PreSignedUrlResponseForAllPath
  ) => {
    this.wholeDataForSaveCall.contents[contentIndex].files[
      fileIndex
    ].thumbnailFilePath = response.thumbnailFilePath;
  };

  @action changeSaveDataFilePath = (
    contentIndex: number,
    fileIndex: number,
    response: PreSignedUrlResponseForAllPath
  ) => {
    this.wholeDataForSaveCall.contents[contentIndex].files[fileIndex].filePath =
      response.originalFilePath;
  };

  @action asyncPosting = (mode: FeedMode) => {
    const backGroundPostingTimer = setTimeout(() => {
      mode === FeedMode.MISSION_POST_WRITE && CommonStore.setLoading(true);
      this.setIsPostSaveIng(true);
      UploadStore.start(
        FeedTypeCode.POST,
        this.wholeTempData.title,
        mode === FeedMode.MODIFY
      );
      UploadStore.setAbortController(new AbortController());

      void this.showStartToast()
        .then(() => sleep(200))
        .then(() =>
          this.compressFiles(
            mode === FeedMode.MODIFY ? FeedMode.MODIFY : FeedMode.WRITE
          )
        )
        .then(UploadStore.clearAbortController)
        .then(() =>
          this.uploadFilesToS3(
            mode === FeedMode.MODIFY ? FeedMode.MODIFY : FeedMode.WRITE
          )
        ) // s3 업로드
        .then(() =>
          this.savePosts(
            mode === FeedMode.MODIFY ? FeedMode.MODIFY : FeedMode.WRITE
          )
        ) // 백엔드 저장
        .then((feedId) => {
          if (mode === FeedMode.MODIFY) {
            void PostDetailStore.getPostDetail(feedId).then((res) => {
              const convertPost = convertPostDetailToPost(res);

              FeedDetailStore.updateFeedList(feedId, convertPost);
              FeedDetailStore.updatePostInAllFeedListStore(
                UserActTypeCode.MODIFY,
                feedId,
                convertPost
              );
              PostStore.updatePost(feedId, convertPost);
              MyHomeStore.updatePost(feedId, convertPost);
              SearchStore.updatePost(feedId, convertPost);
              TagStore.updatePost(feedId, convertPostToTagFeedItem(res));
              BookmarkStore.setPostWithFeedId(convertPost, feedId);
              MainStore.updateFeed(UserActTypeCode.MODIFY, feedId, convertPost);
            });
            MyHomeStore.sync(
              feedId,
              AccountFeedTypeCode.POST,
              UserActTypeCode.MODIFY
            );
          }
          if (mode === FeedMode.WRITE) {
            PostStore.postFilterReset();
            void PostStore.getRecentFeedDetailList(10, null, "POST", "N");
            PostStore.setSelectedTab(0);
          }
          if (mode === FeedMode.MISSION_POST_WRITE) {
            void this.saveMissionPost(feedId);
          }
        })
        .then(() => delIndexedDB(POST_TEMP_DATA)) // 임시저장 캐시 삭제
        .then(() => BadgeStore.obtainBadge(BadgeCode.FEED)) // 뱃지 저장
        .then(UploadStore.complete) // 완료 처리
        .then(() => this.showCompleteToast(mode)) // 완료 토스트 팝업 노출
        .then(this.clear)
        .catch((e) => {
          CommonStore.setLoading(false);
          if (e.name === UploadStatus.STOP || e.name === "abortVideo") {
            UploadStore.clear();
          } else {
            UploadStore.fail();
          }
          void callFrontErrorLog({
            funcName: e.message,
            errorMessage: e.stack,
          });
        })
        .finally(() => {
          this.setIsPostSaveIng(false);
          clearTimeout(backGroundPostingTimer);
        });
    }, 1000);
  };

  savePosts = async (mode: FeedMode) => {
    if (UploadStore.isStop) {
      throw CustomError(
        UploadStatus.FAIL,
        "file:PostWriteStore.ts,func:savePosts STOP"
      );
    }
    if (this.isPostRegistError) {
      throw CustomError(
        UploadStatus.FAIL,
        "file:PostWriteStore.ts,func:savePosts FAIL"
      );
    }
    try {
      return mode === FeedMode.MODIFY
        ? await this.callUpdatePostApi()
        : await this.callCreatePostApi();
    } catch (e: any) {
      throw CustomError(
        String(e.name) || UploadStatus.FAIL,
        String(e.message) || "file:PostWriteStore.ts,func:savePosts ERROR"
      );
    }
  };

  showStartToast = () => {
    return new Promise((resolve, reject) => {
      if (!UploadStore.isDefaultStatus) {
        showBottomToast(t("screen.post.message.starting"));
        resolve("success");
      } else {
        reject(
          CustomError(
            UploadStatus.FAIL,
            "file:PostWriteStore.ts,func:showStartToast"
          )
        );
      }
    });
  };

  showCompleteToast = (mode: FeedMode) => {
    return new Promise((resolve, reject) => {
      if (UploadStore.status === UploadStatus.COMPLETE) {
        if (mode === FeedMode.MODIFY) {
          showBottomToast(t("screen.post.message.modified"), bottomOffset());
        } else {
          if (this.voltAmount > 0) {
            showBottomToast(
              t("screen.post.message.registerdVolt", { volt: this.voltAmount }),
              bottomOffset()
            );
          } else {
            showBottomToast(t("screen.post.message.registerd"), bottomOffset());
          }
        }
        this.trackCompleteUploadPost(this.wholeTempData);
        resolve("success");
      } else {
        reject(
          CustomError(
            UploadStatus.FAIL,
            "file:PostWriteStore.ts,func:showCompleteToast"
          )
        );
      }
    });
  };

  mergeData = (resultQueue: UploadFile[]) => {
    for (const content of this.wholeTempData.contents) {
      content.files.forEach((prevFile) => {
        for (const compressFile of resultQueue) {
          if (
            prevFile.fileType === FileType.IMAGE &&
            prevFile.imageLocalFilePath === compressFile.imageLocalFilePath
          ) {
            prevFile = compressFile;
          }
          if (
            prevFile.fileType === FileType.VIDEO &&
            prevFile.videoLocalFilePath === compressFile.videoLocalFilePath
          ) {
            prevFile = compressFile;
          }
        }
      });
    }
  };

  resizeThumbImg = async (files: ImageForUI[]): Promise<ImageForUI[]> => {
    try {
      return await Promise.all(
        files.map(async (file) => {
          if (file.type.toLocaleUpperCase() === FileType.IMAGE)
            return new Promise<ImageForUI>((resolve, reject) => {
              Resizer.imageFileResizer(
                UploadStore.b64toBlob(file.base64),
                720,
                720,
                "JPEG",
                100,
                0,
                (uri) => {
                  file.thumbnailImagepath = uri as string;
                  const img = new Image();
                  img.src = uri as string;
                  img.onload = () => {
                    const { width, height } = img;
                    file.resThumbX = width.toString();
                    file.resThumbY = height.toString();
                    resolve(file);
                  };
                },
                "base64",
                300,
                300
              );
            });
          else return file;
        })
      );
    } catch (error) {
      throw new Error("resizeThumbImg");
    }
  };

  @action dataSetting = async (inputFiles: FileList): Promise<ImageForUI[]> => {
    const files: ImageForUI[] = [];
    let count = 0;
    return await new Promise((resolve, reject) => {
      [].forEach.call(inputFiles, (file: File, index) => {
        const reader = new FileReader();
        reader.readAsDataURL(file!);
        reader.onload = function (event: ProgressEvent<FileReader>) {
          const base64Encod = event.target?.result as string;
          if (file.type.split("/")[0].toLocaleUpperCase() === FileType.IMAGE) {
            const img = new Image();
            img.src = base64Encod;

            img.onload = function async() {
              const { width, height } = img;
              const temp = {
                base64: base64Encod,
                fileName: file.name,
                fileSize: file.size.toString(),
                fileUri: base64Encod,
                thumbnailImagepath: "",
                type: file.type.split("/")[0],
                resThumbX: "",
                resThumbY: "",
                resOriginX: width.toString(),
                resOriginY: height.toString(),
              };

              files.push(temp);
              count++;
              count === inputFiles?.length && resolve(files);
            };
          } else {
            const videoElement = document.createElement("video");
            videoElement.src = URL.createObjectURL(file);
            videoElement.onloadeddata = () => {
              videoElement.currentTime = 0;
            };

            videoElement.onseeked = async () => {
              const canvasElement = document.createElement("canvas");
              canvasElement.width = videoElement.videoWidth;
              canvasElement.height = videoElement.videoHeight;
              const context = canvasElement.getContext("2d");
              context?.drawImage(
                videoElement,
                0,
                0,
                canvasElement.width,
                canvasElement.height
              );

              const thumbnailDataUrl = canvasElement.toDataURL();
              const temp = {
                base64: base64Encod,
                fileName: file.name,
                fileSize: file.size.toString(),
                fileUri: base64Encod,
                thumbnailImagepath: thumbnailDataUrl,
                type: file.type.split("/")[0],
                resThumbX: videoElement.videoWidth.toString(),
                resThumbY: videoElement.videoHeight.toString(),
                resOriginX: videoElement.videoWidth.toString(),
                resOriginY: videoElement.videoHeight.toString(),
              };
              files.push(temp);
              count++;
              count === inputFiles?.length && resolve(files);
            };
          }
        };
      });
    });
  };

  checkFileCount = (fileList: FileList, contentIndex: number) => {
    let inputImageFileLength = 0;
    let inputVideoFileLength = 0;
    [].forEach.call(fileList, (file: File) => {
      if (file.type.split("/")[0].toLocaleUpperCase() === FileType.IMAGE)
        inputImageFileLength = inputImageFileLength + 1;
      if (file.type.split("/")[0].toLocaleUpperCase() === FileType.VIDEO)
        inputVideoFileLength = inputVideoFileLength + 1;
    });

    let imageFileLength = 0;
    let videoFileLength = 0;
    this.contentsTempData[contentIndex].contents.files.forEach((item) => {
      if (item.fileType === FileType.IMAGE && item.fileSize >= 0)
        imageFileLength = imageFileLength + 1;
      if (item.fileType === FileType.VIDEO && item.fileSize >= 0)
        videoFileLength = videoFileLength + 1;
    });

    const maxImageCount =
      contentIndex === 0
        ? 6 - imageFileLength
        : videoFileLength > 0
        ? 0
        : 3 - imageFileLength < 0
        ? 0
        : 3 - imageFileLength;
    const maxVideoCount =
      contentIndex === 0
        ? 4 - videoFileLength
        : imageFileLength > 0
        ? 0
        : 1 - videoFileLength < 0
        ? 0
        : 1 - videoFileLength;
    if (
      inputImageFileLength > maxImageCount ||
      inputVideoFileLength > maxVideoCount
    ) {
      throw new Error("checkFileCount", {
        cause: { maxImageCount, maxVideoCount },
      });
    }
  };

  @action contentsTempDataSet = (files: ImageForUI[], contentIndex: number) => {
    if (files.length > 0) {
      runInAction(() => {
        const blankIndex = this.contentsTempData[
          contentIndex
        ].contents.files.findIndex((i) => i.fileSize < 0);
        if (blankIndex > -1)
          this.contentsTempData[contentIndex].contents.files.splice(
            blankIndex,
            1
          );

        files.forEach((item) => {
          if (this.contentsTempData[contentIndex].contents.files.length < 10) {
            const sp = item.fileName.split(".");
            this.contentsTempData[contentIndex].contents.files.push({
              originalFileName: item.fileName,
              fileName: sp[0],
              filePath: item.base64,
              imageLocalFilePath:
                item.type.toLocaleUpperCase() === "IMAGE" ? item.fileUri : "",
              videoLocalFilePath:
                item.type.toLocaleUpperCase() === "VIDEO" ? item.fileUri : "",
              thumbnailTempPath: item.thumbnailImagepath,
              imageOriginLocalFilePath:
                item.type.toLocaleUpperCase() === "IMAGE" ? item.fileUri : "",
              fileResolution: item.resThumbX + "x" + item.resThumbY,
              fileSize: Number(item.fileSize),
              fileType: item.type.toLocaleUpperCase(),
              fileExtension: sp[sp.length - 1],
              thumbnailYn: "N",
            });
          }
        });
      });

      this.checkAddedFiles(contentIndex);
    }
  };
}

export default new PostWriteStore();
