export enum Category {
  ALL = "ALL",
  TIP = "TIP", // 꿀팁공유
  REVIEW = "REVIEW", // 생생후기
  ASK = "ASK", // 질문있어요
  CHAT = "CHAT", // 잡담
  DEAL = "DEAL", // 중고거래
  ETC = "ETC", // 기타
}

export enum Tab {
  ALL = "ALL",
  POPULAR = "POPULAR",
  NOTICE = "NOTICE",
  MY = "MY",
}
