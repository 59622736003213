import {
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
  callAPIWithPUTMethod,
} from "../common/api";
import { CommentTypeCode } from "../../constants/Comment";
import {
  CommentCreateResult,
  CommentListRequestResult,
  CommentUpdateItem,
  CommentUpdateResult,
} from "./model";
import { injectMentionInfoIntoContents } from "../../utils/comment";

export const getCommentList = async (
  commentTypeCode: CommentTypeCode,
  commentTargetId: number,
  orderByLike?: string,
  isLoading = true
): Promise<CommentListRequestResult> => {
  const response = await callAPIWithGETMethod<CommentListRequestResult>(
    {
      url: "/v1/comment",
      params: {
        commentTypeCode,
        commentTargetId,
        orderByLike,
      },
    },
    isLoading
  );

  if (response.successOrNot === "Y" && response.data) {
    response.data.commentList = response.data.commentList?.map((comment) => {
      return {
        ...comment,
        childCommentMemberList: comment.childCommentMemberList.map(
          (childComment) => {
            return {
              ...childComment,
              contents: injectMentionInfoIntoContents(
                childComment.mentionList || [],
                childComment.contents || ""
              ),
            };
          }
        ),
        contents: injectMentionInfoIntoContents(
          comment.mentionList || [],
          comment.contents || ""
        ),
      };
    });
  }

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as CommentListRequestResult;
};

export const createComment = async (
  commentItem: CommentUpdateItem,
  isLoading = true
): Promise<CommentCreateResult | null> => {
  const response = await callAPIWithPOSTMethod<CommentCreateResult>(
    {
      url: "/v1/comment",
      body: commentItem,
    },
    isLoading
  );

  if (
    response.successOrNot === "Y" &&
    response.statusCode === "OK" &&
    response.data
  ) {
    response.data.contents = injectMentionInfoIntoContents(
      response.data.mentionList || [],
      response.data.contents || ""
    );
  }

  return response?.successOrNot === "Y" &&
    response?.statusCode === "OK" &&
    response?.data
    ? response?.data
    : null;
};

export const updateComment = async (
  commentItem: CommentUpdateItem,
  isLoading = true
): Promise<CommentUpdateResult | null> => {
  const response = await callAPIWithPUTMethod<CommentUpdateResult>(
    {
      url: "/v1/comment",
      body: commentItem,
    },
    isLoading
  );

  if (
    response.successOrNot === "Y" &&
    response.statusCode === "OK" &&
    response.data
  ) {
    response.data.contents = injectMentionInfoIntoContents(
      response.data.mentionList || [],
      response.data.contents || ""
    );
  }

  return response?.successOrNot === "Y" &&
    response?.statusCode === "OK" &&
    response?.data
    ? response?.data
    : null;
};

export const deleteComment = async (
  commentId: number,
  isLoading = true
): Promise<number | null> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/comment",
      body: { commentId },
    },
    isLoading
  );

  return response?.successOrNot === "Y" && response?.statusCode === "OK"
    ? response?.data
    : null;
};

export const likeComment = async (
  commentId: number,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/like",
      body: {
        likeTargetId: commentId,
        likeTypeCode: "COMMENT",
      },
    },
    isLoading
  );

  return response?.successOrNot === "Y";
};

export const cancelLikeComment = async (
  commentId: number,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/like",
      body: {
        likeTargetId: commentId,
        likeTypeCode: "COMMENT",
      },
    },
    isLoading
  );

  return response?.successOrNot === "Y";
};

export const bestPickComment = async (
  commentId: number,
  commentTypeCode: CommentTypeCode,
  commentTargetId: number,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/comment/bestPick",
      body: {
        commentId,
        commentTypeCode,
        commentTargetId,
      },
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" && response?.statusCode === "OK"
      ? response?.data
      : false
  ) as boolean;
};
