// Slider 탭이 넘어가거나 컴포넌트가 화면 영역을 벗어나면 비디오가 멈추고 초기화되어야함.
// 자체 UI가 아닌 mute와 pause/resume 버튼만 별도로 생성해서 이걸 이용해
import React, {
  Component,
  CSSProperties,
  memo,
  MutableRefObject,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import { Arranges, Colors, Positions, Spacings } from "../../assets/styles";
import PropTypes from "prop-types";
import { Styles } from "../../assets/types/Style";
import { ReactComponent as PauseCircle } from "../../assets/icons/pause_circle.svg";
import { ReactComponent as PlayCircle } from "../../assets/icons/play_circle.svg";
import { ReactComponent as VolumeFull } from "../../assets/icons/volume_full.svg";
import { ReactComponent as VolumeMute } from "../../assets/icons/volume_mute.svg";
import { flex } from "../../assets/styles/flexBoxs";
import "./VideoPlayerWithFallback.scss";

export interface VideoPlayerWithFallbackProps {
  videoRef?: (player: ReactPlayer) => void;
  style?: CSSProperties;
  wrapperStyle?: any;
  sourceUri: string;
  disabled?: boolean;
  keystring?: string;
  thumbnailurl?: string;
  onLoad2?: (index: number, event: React.FormEvent<HTMLImageElement>) => void;
  carouselIdx?: number;
  defAudioMute: boolean;
  onMute: (mute: boolean) => void;
  onVisible?: (visiblePercent: number, centerDist: number) => void;
  wrapperVideoStyle?: CSSProperties;
}

const PUBLIC_BUCKET_URL = String(process.env.REACT_NATIVE_PUBLIC_BUCKET_URL);
const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";

const VideoPlayerWithFallback = ({
  disabled,
  onVisible,
  sourceUri,
  videoRef,
  style,
  thumbnailurl,
  keystring,
  onLoad2,
  carouselIdx,
  onMute,
  defAudioMute,
  wrapperVideoStyle,
  ...props
}: VideoPlayerWithFallbackProps) => {
  const [error, setError] = useState(false);
  const [wholeTime, setWholeTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [thumbnailLoad, setThumbnailLoad] = useState(false);
  const [textState, setTextState] = useState("");
  const [audioMute, setAudioMute] = useState(defAudioMute);
  const [isMount, setIsMount] = useState(false);

  const viewRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer>();

  useEffect(() => {
    setIsMount(true);

    return () => {
      setIsMount(false);
    };
  }, []);

  const handleRef = useCallback(
    (player: ReactPlayer) => {
      if (player != undefined) {
        playerRef.current = player;
        if (videoRef !== null && videoRef !== undefined) videoRef(player);
      }
    },
    [playerRef, videoRef]
  );

  const handlePlayControlBtn = useCallback(() => {
    if (isPlaying) {
      setIsPlaying(!isPlaying);
      if (playerRef && playerRef.current)
        playerRef.current.getInternalPlayer().pause();
    } else {
      setIsPlaying(!isPlaying);
      if (playerRef && playerRef.current)
        playerRef.current.getInternalPlayer().play();
    }
  }, [isPlaying]);

  useEffect(() => {
    if (disabled && playerRef && playerRef.current) {
      playerRef.current.seekTo(0);
    }
  }, [disabled]);

  const handleMuteBtn = useCallback(() => {
    setAudioMute(!audioMute);
  }, [audioMute]);

  const onError = useCallback((msg: string) => {
    setError(true);
    setIsPlaying(false);
  }, []);

  const onLazyImageLoad2 = useCallback(() => {
    setThumbnailLoad(true);
  }, []);

  useEffect(() => {
    onMute(audioMute);
  }, [audioMute, onMute]);

  return (
    <>
      <div className="video-player-with-fallback" ref={viewRef} style={style}>
        {!error && isMount ? (
          <>
            <ReactPlayer
              className="video-player"
              width="100%"
              height="100%"
              style={{
                width: "100%",
                height: "100%",
                ...style,
                ...wrapperVideoStyle,
              }}
              ref={handleRef}
              onError={(e) => {
                onError("");
              }}
              url={sourceUri}
              muted={audioMute}
              playing={!disabled}
              controls={false}
              onPlay={() => setIsPlaying(true)}
              onReady={(e) => {
                setIsPlaying(true);
                setWholeTime(e.getDuration());
                setTextState(
                  `00:${e.getDuration() < 10 ? "0" : ""}${Math.floor(
                    e.getDuration()
                  )}`
                );
              }}
              onEnded={() => setIsPlaying(false)}
              progressInterval={200}
              playsinline
              onProgress={(e) => {
                if (wholeTime != 0) {
                  const diff = wholeTime - e.playedSeconds;
                  setTextState(
                    `00:${diff < 10 ? "0" : ""}${Math.max(Math.floor(diff), 0)}`
                  );
                }
              }}
              fallback={
                <LazyLoadImage
                  className="fallbackThumbnail"
                  style={{
                    width: "100%",
                    height: "100%",
                    ...style,
                    ...wrapperVideoStyle,
                  }}
                  src={error ? FALLBACK_IMAGE_URL : thumbnailurl}
                  onLoad={(event: React.FormEvent<HTMLImageElement>) => {
                    onLoad2 && onLoad2(carouselIdx ? carouselIdx : 0, event);
                    onLazyImageLoad2();
                  }}
                  onError={() => {
                    onLazyImageLoad2();
                  }}
                />
              }
            />
          </>
        ) : (
          <div style={style ? style : { opacity: 0 }}></div>
        )}
        {!error && !disabled && isMount && (
          <div
            className={"playControlButton"}
            style={{
              width: 40,
              height: 40,
              position: "absolute",
              left: 14,
              bottom: 18,
            }}
            onClick={handlePlayControlBtn}
          >
            {isPlaying ? (
              <PauseCircle
                fillOpacity="white"
                style={{ width: "100%", height: "100%" }}
              />
            ) : (
              <PlayCircle style={{ width: "100%", height: "100%" }} />
            )}
          </div>
        )}
        {!error && !disabled && isMount && (
          <div className="video-player-with-fallback-info">
            <span
              style={{
                ...Colors.font_fff,
                fontSize: 12,
                lineHeight: "24px",
                whiteSpace: "nowrap",
              }}
            >
              {textState}
            </span>
            <div
              style={{ width: 16, overflow: "hidden", display: "flex" }}
              onClick={handleMuteBtn}
            >
              {audioMute ? (
                <VolumeMute
                  fill="white"
                  style={{ width: "100%", height: 16 }}
                />
              ) : (
                <VolumeFull
                  fill="white"
                  style={{ width: "100%", height: 16 }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(VideoPlayerWithFallback);
VideoPlayerWithFallback.displayName = "VideoPlayerWithFallback";
