import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockerFunction, useBlocker, useLocation } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import Switch from 'react-switch'
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import { MaxLengthTextInput, TitleHeader } from "../../../components/common";
import { ReactComponent as QnaPhoto } from "../../../assets/icons/qna_photo.svg";
import CommonStore from "../../../store/CommonStore";
import ArticleWriteStore, { ARTICLE_BLANK, ArticleModifyModel } from "../../../store/ArticleWriteStore";
import { FeedMode } from "../../../constants/Feed";
import { observer } from "mobx-react";
import { HeaderItem } from "../../../constants/Header";
import { goBack, goTo } from "../../../hooks/navigate/NavigateFunction";
import { NotificationModal } from "../../../components/modals";
import { UploadFile } from "../../../api/file/model";
import { Styles } from "../../../assets/types/Style";
import { FileType } from "../../../constants/File";
import NotificationModalWithHideCallback from "../../../components/modals/NotificationModalWithHideCallback";
import UploadStore from "../../../store/upload/UploadStore";
import ArticleWriteProduct from "./ArticleWriteProduct";
import { ReactComponent as ClosePurpleIcon } from "../../../assets/icons/icon_del_bg_purple.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_small.svg";
import CustomBottomSheet from "../../../components/common/CustomBottomSheet";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/icon_info_circle.svg";
import { ReactComponent as TooltipArrow } from "../../../assets/icons/tooltip_arrow_left.svg";
import { ReactComponent as CloseTooltipIcon } from "../../../assets/icons/close_white_sm.svg";
import { CommonGroupCode } from "../../../constants/CommonCode.enum";
import { getCommonCode } from "../../../api/commonCode/api";
import { brandSubscribe } from "../../../api/brand/api";
import { showBottomToast } from "../../../utils/Toast";
import { bottomOffset } from "../../../utils/common";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ArticleWrite = observer(({ mode, communityId, subscriptionYn }: { mode: FeedMode; communityId: number; subscriptionYn?: string }) => {

    const location = useLocation();
    const { t } = useTranslation();
    const [isScreenFocused, setIsFocused] = useState(false);
    useEffect(() => {
        // 화면 포커스 여부 감지
        setIsFocused(true);

        // 컴포넌트 언마운트 시 포커스 여부 갱신
        return () => {
            setIsFocused(false);
        };
    }, [location]);

    const {
        wholeData,
        isValid,
        fileList,
        fileUpdated,
        tagList,
        userList,
        hasError,
        errorMessage,
        toastMessage,
        setError,
        clear,
        setTitle,
        setContent,
        deleteFile,
        showValidInfo,
        setArticleDisclosureYn,
        setArticleTypeCode,
        setRepresentation,
    } = ArticleWriteStore;

    const [showSortBox, setShowSortBox] = useState<boolean>(false);
    const [showTooltip, setShowTooltip] = useState<boolean>(mode === FeedMode.WRITE ? true : false);
    const [scrolling, setScrolling] = useState(false);
    const [showConfirmPopup, setConfirmPopup] = useState(false);
    const [showSavePopup, setSavePopup] = useState(false);
    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
    const [registering, setRegistering] = useState<boolean>(false);
    const [showSubscriptionNotice, setShowSubscriptionNotice] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    let whiteListRoutes = ["/Product/Search", "/main"];

    let checkBlocking = useCallback<BlockerFunction>(({currentLocation, nextLocation}) => {
        let checkValid = whiteListRoutes.includes(nextLocation.pathname)
        return currentLocation.pathname !== nextLocation.pathname && !checkValid}, [])
  
    let shouldBlock = useBlocker(checkBlocking);

    const checkTempContents = () => {
        if (
            JSON.stringify(wholeData) !== JSON.stringify(ARTICLE_BLANK) ||
            fileList.length > 1 ||
            tagList.length > 0 ||
            userList.length > 0
        )
            setConfirmPopup(true);
        else {
            shouldBlock.proceed?.();
            // goBack()
        };
    };

    const onClickSave = () => {
        if (!UploadStore.isDefaultStatus) {
            setRegistering(true);
            return;
        }
        if (isValid) {
            setSavePopup(true);
        } else {
            showValidInfo();
        }
    };

    useEffect(() => {
        if (shouldBlock.state === "blocked") {
            checkTempContents()
        }
    }, [shouldBlock.state, showConfirmPopup])

    useEffect(() => {
        return () => {
            shouldBlock.reset?.()
        }
    }, [])

    useEffect(() => {
        if (subscriptionYn === "N") {
            setShowSubscriptionNotice(true);
        }
    }, [subscriptionYn]);

    useEffect(() => {
        const { wholeData, fileList, tagList, userList, clear, setCommunityId } = ArticleWriteStore;
        void getCommonCode(CommonGroupCode.ARTICLE_TYPE_CODE).then((res) => {
            if (res) {
                ArticleWriteStore.setArticleTypeCodeList(res);
            }
        });
        if (isScreenFocused && mode === FeedMode.WRITE) {
            setCommunityId(communityId);
        }
    }, [communityId, isScreenFocused, mode]);

    useEffect(() => {
        if (toastMessage !== undefined && toastMessage.trim().length > 0) {
            CommonStore.setToastOption({
                show: true,
                message1: toastMessage,
            });
            ArticleWriteStore.setToast("");
        }
    }, [toastMessage]);

    const fileRender = (item: ArticleModifyModel<UploadFile>, index: number) => {
        if (index == 0) {
            return (
                <>
                    {/** 이미지 등록 전 **/}
                    <div style={{ marginRight: 16, position: "relative" }}>
                        <img style={styles.upload}></img>
                        <div style={{ position: "absolute", zIndex: 10, top: 0, left: 0, width: 80, height: 80 }}
                            onClick={() => fileInputRef?.current?.click()}
                        >
                            <button style={styles.upload}>
                                <div style={styles.wrap_icons}>
                                    <QnaPhoto />
                                </div>
                                <p style={styles.upload_txt}>
                                    <span>{fileList.length - 1}</span>/10
                                </p>
                            </button>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            id="file"
                            accept="image/*, video/*"
                            style={{ display: 'none' }}
                            multiple
                            onChange={(e) => {
                                const inputFiles = e.target.files;
                                if (inputFiles && inputFiles.length > 0) {
                                    CommonStore.setLoading(false)
                                    UploadStore.checkFileExtension(inputFiles)
                                        .then(() => UploadStore.checkFileSize(inputFiles))
                                        .then(() =>
                                            ArticleWriteStore.checkFileCount(inputFiles, index)
                                        )
                                        .then(() => ArticleWriteStore.dataSetting(inputFiles))
                                        .then((files) => ArticleWriteStore.resizeThumbImg(files))
                                        .then((files) =>
                                            ArticleWriteStore.contentsTempDataSet(files, index)
                                        )
                                        .then(() => CommonStore.setLoading(false))
                                        .catch((e: Error) => {
                                            CommonStore.setLoading(false)
                                            if (e.message === 'checkFileExtension') {
                                                CommonStore.setToastOption({
                                                    show: true,
                                                    message1: t('common.message.checkFileExtension'),
                                                })
                                            } else if (e.message === 'checkFileSize') {
                                                CommonStore.setToastOption({
                                                    show: true,
                                                    message1: t('common.message.checkFileSize'),
                                                })
                                            } else if (e.message === 'checkFileCount') {
                                                const { maxImageCount, maxVideoCount } = e.cause as {
                                                    maxImageCount: number
                                                    maxVideoCount: number
                                                }
                                                CommonStore.setToastOption({
                                                    show: true,
                                                    message1: t('common.message.checkFileCount', {
                                                        maxImageCount,
                                                        maxVideoCount,
                                                    }),
                                                })
                                            }
                                        })
                                }
                            }}
                        />
                    </div>
                    {/** 이미지 등록 전 **/}
                </>
            );
        } else {
            const modified = item?.modified !== undefined && item?.modified === true;
            const isVideo = item.contents.fileType === FileType.VIDEO;
            const localFilePath = `${item.contents.thumbnailTempPath}`;
            const remoteThumbFilePath = `${PUBLIC_BUCKET_URL}${item?.contents?.thumbnailFilePath || ""}`;
            const remoteImageFilePath = `${PUBLIC_BUCKET_URL}${item?.contents?.filePath || ""}`;
            const filePath = modified ? localFilePath : isVideo ? remoteThumbFilePath : remoteImageFilePath;

            return (
                <>
                    <div style={styles.img_wrap}>
                        <LazyLoadImage
                            className="draggable-image"
                            style={{
                                height: 80,
                                aspectRatio: 1 / 1,
                                objectFit: "cover",
                                objectPosition: "center",
                            }}
                            visibleByDefault
                            src={filePath}
                        />
                        <button
                            onClick={() => setRepresentation(item.id)}
                            style={{ ...styles.icon_thumbnail, ...(item.contents.thumbnailYn === "Y" ? styles.icon_thumbnail_on : {}) }}
                        >
                            <p>{t("screen.article.label.representative")}</p>
                        </button>
                    </div>
                    <div style={styles.image_info_wrap}>
                        <button
                            style={{ alignContent: "flex-start" }}
                            onClick={() => {
                                deleteFile(index);
                            }}
                        >
                            <ClosePurpleIcon />
                        </button>
                    </div>
                    {/** 이미지 등록 후 **/}
                </>
            );
        }
    };

    console.log(wholeData.articleDisclosureYn);

    return (
        <>
            <div style={styles.contents}>
                <TitleHeader
                    title={mode === FeedMode.WRITE ? t("screen.article.title.write") : t("screen.article.title.modify")}
                    rightItem={isValid ? HeaderItem.REGISTER_ON : HeaderItem.REGISTER_OFF}
                    buttonText={
                        mode === FeedMode.WRITE ? t("screen.article.button.registration") : t("screen.article.button.modify")
                    }
                    showBorder={scrolling}
                    onClickBack={goBack}
                    onClickSave={onClickSave}
                    onClickSaveInactivated={onClickSave}
                />
                <div style={styles.contents}>
                    <Virtuoso
                        style={styles.entire_qna_write}
                        useWindowScroll
                        data={[{}]}
                        itemContent={(_) => {
                            return (
                                <div style={{ overflowX: "hidden" }}>
                                    <button
                                        style={styles.header_sort_btn}
                                        onClick={() => {
                                            setShowTooltip(false);
                                            setShowSortBox(true);
                                        }}
                                    >
                                        <div style={styles.select_box}>
                                            <p
                                                style={{ ...styles.header_sort_btn_txt, ...(wholeData.articleTypeCode.length > 0 ? { color: "black" } : {}) }}
                                            >
                                                {wholeData.articleTypeCode.length === 0
                                                    ? t("screen.article.message.inputTopic")
                                                    : ArticleWriteStore.getArticleTypeCodeName(wholeData.articleTypeCode)}
                                            </p>
                                            <ArrowDown />
                                        </div>
                                    </button>
                                    <MaxLengthTextInput
                                        accessibilityLabel="title"
                                        style={styles.subject}
                                        placeholder={t("screen.article.placeholder.title")}
                                        placeholderTextColor={"#999"}
                                        multiline={true}
                                        maxLength={50}
                                        value={wholeData.articleTitle}
                                        onChangeText={(text: string) => {
                                            setShowTooltip(false);
                                            setTitle(text);
                                        }}
                                    />

                                    <div style={styles.line} />
                                    <MaxLengthTextInput
                                        type="textarea"
                                        accessibilityLabel="Content"
                                        style={styles.content}
                                        placeholder={t("screen.article.placeholder.contents")}
                                        placeholderTextColor={"#999"}
                                        multiline={true}
                                        maxLength={5000}
                                        value={wholeData.articleContents}
                                        onChangeText={(text: string) => {
                                            if (text.length > 5000) showBottomToast(t("screen.article.message.maxContentes"), bottomOffset());
                                            setShowTooltip(false);
                                            setContent(text);
                                        }}
                                    />

                                    <div style={styles.tooltip_info_wrap}>
                                        <div style={styles.tooltip_bg}>
                                            <button onClick={() => setShowTooltip(true)}>
                                                <InfoIcon />
                                            </button>
                                            {showTooltip && (
                                                <>
                                                    <TooltipArrow style={styles.tooltip_arrow} />
                                                    <p style={styles.tooltip_bx}>{t("screen.article.message.tooltip")}</p>
                                                    <button style={styles.btn_tooltip_close} onClick={() => setShowTooltip(false)}>
                                                        <CloseTooltipIcon />
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    <div style={{ overflow: "scroll" }}>
                                        <Virtuoso
                                            style={{ height: 80, ...styles.upload_scroll }}
                                            horizontalDirection
                                            increaseViewportBy={1000}
                                            data={fileList}
                                            itemContent={(index: number, item: ArticleModifyModel<UploadFile>) => {
                                                return (
                                                    <div key={index} style={{ position: "relative" }}>
                                                        {fileRender(item, index)}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>

                                    <p style={styles.warning_private}>{t("screen.article.message.notice")}</p>
                                    <div style={styles.line} />

                                    <div>
                                        <ArticleWriteProduct mode={mode} />
                                    </div>

                                    <div style={{ ...styles.line, marginTop: 20 }}></div>
                                    <div style={styles.bx_undisclosed}>
                                        <div style={styles.txt_undisclosed}>
                                            {wholeData.articleDisclosureYn === "Y" ? (
                                                <>
                                                    <p style={styles.txt_undis_title}>{t("screen.article.button.nondisclosure")}</p>
                                                    <p style={styles.txt_undis_subtitle}>
                                                        {t("screen.article.message.nondisclosureNotice")}
                                                    </p>
                                                </>
                                            ) : (
                                                <>
                                                    <p style={styles.txt_undis_title}>{t("screen.article.button.closedArticle")}</p>
                                                    <p style={styles.txt_undis_subtitle}>
                                                        {t("screen.article.message.closedArticleNotice")}
                                                    </p>
                                                </>
                                            )}
                                        </div>

                                        <Switch
                                            onChange={() => {
                                                wholeData.articleDisclosureYn == "Y"
                                                    ? setArticleDisclosureYn("N")
                                                    : setArticleDisclosureYn("Y")
                                            }}
                                            checkedIcon={false}
                                            uncheckedIcon={false}
                                            width={40}
                                            height={24}
                                            activeBoxShadow="unset"
                                            offColor="#d9d9d9"
                                            onColor="#be69c3"
                                            onHandleColor="#ffffff"
                                            offHandleColor="#ffffff"
                                            checked={wholeData.articleDisclosureYn == "N"}
                                        />

                                    </div>
                                    <div style={{ height: 56 }} />
                                </div>
                            )
                        }}
                    />
                </div>
            </div >

            <CustomBottomSheet
                open={showSortBox}
                onClose={() => setShowSortBox(false)}
            >
                <div style={styles.sheet} >
                    <button
                        style={styles.sheet_close_btn}
                        onClick={() => setShowSortBox(false)}
                    >
                        <CloseIcon />
                    </button>
                    <div style={styles.sheet_title_wrap}>
                        <p style={styles.sheet_title}>
                            {t("screen.article.message.inputTopic")}
                        </p>
                    </div>

                    <div style={styles.sort_list}>
                            {ArticleWriteStore.articleTypeCodeList.map((item, index) => {
                                return item.code !== "NOTICE" && item.useYn !== "N" ? (
                                    <>
                                        <button
                                            key={index}
                                            style={styles.sort_item}
                                            onClick={() => {
                                                setArticleTypeCode(item.code);
                                                setShowSortBox(false);
                                            }}
                                        >
                                            <p style={{ ...styles.sort_text, ...(item.code === wholeData.articleTypeCode ? styles.sort_active_text : {}) }}>
                                                {item.codeName}
                                            </p>
                                        </button>
                                    </>
                                ) : (
                                    <></>
                                );
                            })}
                    </div>
                </div>
            </CustomBottomSheet>

            {
                hasError && (
                    <NotificationModal
                        isVisible={hasError}
                        contents1={errorMessage!}
                        onRequestClose={() => {
                            setError("");
                        }}
                    />
                )
            }

            {
                showSubscriptionNotice && (
                    <NotificationModal
                        isVisible={showSubscriptionNotice}
                        title={t("screen.article.label.subscription")}
                        contents1={t("screen.article.message.subscriptionWriteNotice")}
                        useTwoButton={true}
                        isBlack={false}
                        defaultButtonText={t("screen.article.button.subscription")}
                        extraButtonText={t("screen.notificationModal.button.cancel")}
                        onClickDefaultButton={() => {
                            void brandSubscribe(communityId);
                            setShowSubscriptionNotice(false);
                        }}
                        onClickExtraButton={() => {
                            setShowSubscriptionNotice(false);
                            goBack();
                        }}
                        onRequestClose={() => {
                            setShowSubscriptionNotice(false);
                            goBack();
                        }}
                        textAlign={"center"}
                    />
                )
            }

            {
                showSavePopup && (
                    <NotificationModal
                        isVisible={showSavePopup}
                        contents1={
                            mode === FeedMode.WRITE
                                ? t("screen.article.message.writeconfirm")
                                : t("screen.article.message.modifyconfirm")
                        }
                        useTwoButton={true}
                        isBlack={false}
                        defaultButtonText={t("screen.notificationModal.button.OK")}
                        extraButtonText={t("screen.notificationModal.button.cancel")}
                        onClickDefaultButton={() => {
                            shouldBlock.proceed?.();
                            setSavePopup(false);
                            ArticleWriteStore.asyncCreateArticle(mode);
                            // goBack();
                            goTo("/main");
                        }}
                        onClickExtraButton={() => setSavePopup(false)}
                        onRequestClose={() => setSavePopup(false)}
                    />
                )
            }

            {
                showConfirmPopup && (
                    <NotificationModal
                        isVisible={showConfirmPopup}
                        contents1={
                            mode === FeedMode.WRITE ? t("screen.article.message.writeLeave") : t("screen.article.message.modifyLeave")
                        }
                        useTwoButton={true}
                        isBlack={false}
                        defaultButtonText={t("screen.notificationModal.button.OK")}
                        extraButtonText={t("screen.notificationModal.button.cancel")}
                        onClickDefaultButton={() => {
                            clear();
                            setConfirmPopup(false);
                            shouldBlock.proceed?.();
                            // goBack();
                        }}
                        onClickExtraButton={() => {
                            setConfirmPopup(false)
                            shouldBlock.reset?.()
                        }}
                        onRequestClose={() => setConfirmPopup(false)}
                        textAlign={"center"}
                    />
                )
            }

            {
                registering && (
                    <NotificationModalWithHideCallback
                        isVisible={registering}
                        contents1={t("screen.post.message.registering")}
                        useTwoButton={false}
                        isBlack={false}
                        defaultButtonText={t("screen.notificationModal.button.confirm")}
                        onClickDefaultButton={() => setRegistering(false)}
                        onClickExtraButton={() => setRegistering(false)}
                        onRequestClose={() => setRegistering(false)}
                        modalCloseCallback={() => setRegistering(false)}
                        textAlign={"center"}
                    />
                )
            }
        </>
    );
});

export default ArticleWrite;

const styles: Styles = {
    entire_qna_write: {},
    bx_undisclosed: {
        ...FlexBoxs.flex,
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: 16,
        paddingRight: 16,
        alignItems: "center",
    },
    txt_undisclosed: {
        ...FlexBoxs.flex,
        flexDirection: "row",
        alignItems: "flex-end",
    },
    txt_undis_title: {
        fontSize: 16,
        color: "#222",
    },
    txt_undis_subtitle: {
        fontSize: 12,
        color: "#999",
    },
    contents: {
        flex: 1,
        ...Spacings.margin_top_2,
    },
    subject: {
        ...Texts.contents_text_1,
        ...Spacings.padding_left_right_20,
        ...Spacings.margin_bottom_14,
        ...Spacings.padding_top_20,
        width: '100%',
        ...Texts.font_weight_400,
    },
    line: {
        height: 1,
        ...Colors.background_eeeeee,
        ...Spacings.margin_bottom_10,
        marginLeft: 16,
        marginRight: 16
    },
    content: {
        ...Spacings.padding_left_right_20,
        ...Texts.contents_text_28,
        ...Texts.font_weight_400,
        textAlign: "left",
        minHeight: 180,
        textAlignVertical: "top",
        width: '100%',
        resize: 'none'
    },
    upload_scroll: {
        ...Spacings.margin_left_16,
        ...Spacings.margin_right_16,
        ...Spacings.margin_top_bottom_20,
        overflow: "visible",
    },
    upload: {
        ...Colors.border_color_eee,
        ...Colors.background_fafafa,
        ...FlexBoxs.flex,
        ...FlexBoxs.vertical,
        ...Arranges.center_v_h,
        width: 80,
        height: 80,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderStyle: 'solid',
        borderRadius: 6,
    },
    warning_private: {
        ...Spacings.padding_left_right_16,
        ...Texts.button_text_9,
        ...Colors.font_999,
        ...Spacings.margin_bottom_20,
    },
    line_thick: {
        ...Colors.background_f5f5f5,
        height: 6,
    },
    wrap_icons: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
    },
    upload_txt: {
        ...Texts.button_text_9,
        ...Colors.font_c4c4c4,
        fontWeight: "500",
    },
    images: {
        width: 80,
        height: 80,
        borderRadius: 6,
    },
    img_wrap: {
        paddingRight: 16,
        height: 80,
    },

    qna_user_list: {},
    qna_user_first_item: {
        ...Spacings.margin_left_16,
    },
    qna_user_item: {
        ...FlexBoxs.flex,
        ...Arranges.center_v_h,
        ...Spacings.margin_right_16,
    },
    close_btn: {
        ...Positions.absolute,
        top: 2,
        right: 2,
        zIndex: 10,
    },
    user_img: {
        ...Spacings.border_radius_32,
        ...Spacings.margin_bottom_4,
        width: 64,
        height: 64,
    },
    user_id_text: {
        width: 56,
        ...Texts.contents_text_5,
        ...Colors.font_a760b1,
        textAlign: "center",
    },
    user_id_text2: {
        width: 56,
        ...Texts.contents_text_5,
        textAlign: "center",
    },
    request_bar: {
        height: 1,
        ...Spacings.margin_top_19,
        ...Spacings.margin_bottom_20,
        ...Spacings.margin_left_right_16,
        ...Colors.background_eeeeee,
    },
    footer: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
    },
    btn_1: {
        ...FlexBoxs.flex,
        ...FlexBoxs.flex_1,
        ...FlexBoxs.vertical,
        ...Arranges.center_v_h,
    },
    btn_1_txt: {
        ...Texts.btn_text_0,
    },
    txt_active: {
        ...Colors.font_fff,
    },
    txt_in_active: {
        ...Colors.font_999,
    },
    in_active: {
        ...Colors.background_eeeeee,
        ...Colors.border_color_d9d9d9,
    },
    active: {
        ...Colors.background_222,
    },

    toast: {
        ...Spacings.border_radius_10,
        ...Spacings.padding_right_left_20,
        ...Spacings.padding_top_bottom_20,
        ...FlexBoxs.flex,
        ...Arranges.center_v_h,
        width: "90%",
        backgroundColor: "black",
    },
    toast_text: {
        ...Texts.contents_text_22,
    },
    image_info_wrap: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        position: "absolute",
        right: -18,
        top: -30,
        ...Spacings.padding_right_left_20,
        ...Spacings.padding_top_14,
    },
    icon_thumbnail: {
        ...Texts.font_size_12,
        ...Colors.font_999,
        ...Texts.font_weight_500,
        ...Spacings.padding_top_bottom_4,
        ...Spacings.padding_right_left_8,
        ...Colors.background_fff,
        ...Spacings.border_radius_4,
        overflow: "hidden",
        position: "absolute",
        left: 1,
        bottom: 1,
    },
    icon_thumbnail_on: {
        ...Colors.background_f7e237,
        ...Colors.font_222,
    },
    nondis_text: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        paddingLeft: 16,
        paddingRight: 16,
        ...Arranges.center_h,
        marginBottom: 10,
    },
    nondis_icon: {
        marginRight: 3,
    },
    nondis_title: {
        fontSize: 16,
        color: "#222",
    },
    nondis_title_off: {
        ...Colors.font_999,
    },
    nondis_sub: {
        fontSize: 14,
        color: "#999",
        marginLeft: 6,
    },
    header_sort_btn: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Spacings.padding_left_right_20,
        width: '100%'
    },
    select_box: {
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: 'solid',
        flex: 1,
        ...Arranges.between,
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Colors.border_color_eee,
        ...Spacings.padding_top_bottom_20,
    },
    header_sort_btn_txt: {
        ...Colors.font_999,
        ...Texts.font_size_16,
    },
    sheet: {
        ...FlexBoxs.flex_1,
        ...Spacings.padding_top_16,
        ...Spacings.padding_left_right_20,
    },
    sheet_close_btn: {
        ...Positions.absolute,
        top: 6,
        right: 6,
    },
    sheet_title_wrap: {
        ...FlexBoxs.flex,
        ...Arranges.center_v_h,
        height: 32,
    },
    sheet_title: {
        ...Texts.contents_text_33,
        ...Colors.font_222,
    },
    sort_list: {
        ...Spacings.margin_top_16,
        ...FlexBoxs.flex,
        ...FlexBoxs.vertical,
        ...Arranges.center_h,
    },
    sort_item: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.between,
        ...Spacings.padding_top_bottom_15,
        ...Arranges.center_v,
    },
    sort_text: {
        ...Texts.contents_text_38,
    },
    sort_active_text: {
        ...Colors.font_be69c3,
        fontWeight: "bold",
    },
    tooltip_info_wrap: {
        position: "relative",
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
        ...Spacings.padding_left_right_20,
        ...Spacings.margin_top_40,
        zIndex: 99,
    },
    tooltip_bg: {
        position: "absolute",
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
        left: 20,
    },
    tooltip_arrow: {
        marginRight: -1,
    },
    tooltip_bx: {
        ...Spacings.padding_top_bottom_10,
        ...Spacings.padding_right_left_16,
        ...Colors.background_222,
        ...Spacings.border_radius_6,
        ...Colors.font_fff,
        ...Texts.font_size_12,
        ...Texts.font_weight_500,
        textAlign: "center",
        overflow: "hidden",
        whiteSpace: "pre",
        maxWidth: 140,
    },
    btn_tooltip_close: {
        position: "absolute",
        right: 4,
        top: 4,
    },
};