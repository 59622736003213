import { CommentMentionInfo, CommentTagUserInfo } from "../api/comment/model";

export const getTagNicknameFromTagUserInfo = (contents: string) => {
  const match = contents.match(/\[([^:]+):([^\]]+)\]/m);

  return match ? `@${match[1]}` : null;
};

export const replaceTagUserInfoToTagNickname = (contents: string) => {
  return contents.replace(
    /\[([^:]+):([^\]]+)\]/g,
    (match: string, p1: string) => {
      return p1 ? `@${p1}` : "";
    }
  );
};

export const getUuidFromTagUserInfo = (contents: string) => {
  const pattern = /\[([^:]+):([^\]]+)\]/m;
  const match = contents.match(pattern);
  return match ? match[2] : null;
};

export const getTagUserInfo = (contents: string) => {
  const pattern = /\[([^:]+):([^\]]+)\]/m;
  return contents.split(pattern);
};

export const getValidTagNickname = (nickname: string) => {
  const pattern = /[^a-zA-Z0-9._]/gm;

  const match = nickname.split(pattern);
  return match ? match[0] : null;
};

export const checkValidTagNickname = (contents: string) => {
  const pattern = /(\s|[^a-zA-Z0-9._])/gm;
  return contents.match(pattern);
};

export const createTagInfoStr = (nickname: string, memberUuid: string) => {
  return "[" + nickname + ":" + memberUuid + "]";
};

export const getCloseTagPosition = (
  comment: string,
  textInputPos: number
): number => {
  let closePos = -1;
  const tagPos = Array<number>();

  let pos = 0;
  for (;;) {
    const foundPos = comment.indexOf("@", pos);
    if (foundPos == -1) break;
    tagPos.push(foundPos);
    pos = foundPos + 1;
  }

  tagPos.sort((a: number, b: number): number => {
    return b - a;
  });

  for (let idx = 0; idx < tagPos.length; idx++) {
    if (tagPos[idx] <= textInputPos) {
      closePos = tagPos[idx];
      break;
    }
  }

  return closePos;
};

export const getSearchTagIndicator = () => {
  return "[*^:!MOGA^]";
};

export const injectMentionInfoIntoContents = (
  mentionList: CommentMentionInfo[],
  contents: string
) => {
  let result = contents;

  mentionList.forEach((item) => {
    if (item.nickname)
      result = result.replace(
        `[mention:${item.memberUuid}]`,
        `[${item.nickname}:${item.memberUuid}]`
      );
  });

  return result;
};

export const extractMentionInfoFromContents = (
  originalMentionList: CommentMentionInfo[],
  tagList: CommentTagUserInfo[],
  contents: string
) => {
  const regexp = /\[([^:]+):([^\]]+)\]/g;
  const _nextMentionedUuids = [...contents.matchAll(regexp)].map(
    (item) => item[2]
  );
  const _prevMentionedUuids = originalMentionList.map((mention) => {
    return mention.memberUuid;
  });

  const mentionMap = new Map<string, string>();

  _nextMentionedUuids.forEach((uuid) => {
    if (!_prevMentionedUuids.includes(uuid)) {
      mentionMap.set(uuid, "N");
    }
  });

  _prevMentionedUuids.forEach((uuid) => {
    if (!_nextMentionedUuids.includes(uuid)) {
      mentionMap.set(uuid, "Y");
    }
  });

  const result: { contents: string; mentionList: CommentMentionInfo[] } = {
    contents: contents,
    mentionList: Array.from(mentionMap, ([name, value]) => {
      return { memberUuid: name, deleteYn: value };
    }),
  };

  tagList.forEach((item) => {
    const uuid = getUuidFromTagUserInfo(item.userInfo);
    if (uuid) {
      const idx = result.mentionList.findIndex(
        (item) => item.memberUuid === uuid
      );
      const mention = idx >= 0 ? result.mentionList[idx] : null;

      if (mention === null) {
        result.mentionList.push({ memberUuid: uuid });
        result.contents = result.contents.replace(
          item.userInfo,
          `[mention:${uuid}]`
        );
      } else if (mention.deleteYn === "Y") {
        result.mentionList.splice(idx, 1);
        result.contents = result.contents.replace(
          item.userInfo,
          `[mention:${uuid}]`
        );
      }
    }
  });

  return result;
};
