import React from 'react'
import { observer } from 'mobx-react'
import FastImageWithFallback from '../../../components/common/FastImageWithFallback'
import { ReactComponent as BookmarkOff } from '../../../assets/icons/icon_bookmark_off.svg'
import { ReactComponent as BookmarkON } from '../../../assets/icons/icon_bookmark_on.svg'
import { getFormatprice } from '../../../utils/mall'
import { DisplayProduct } from '../../../api/mall/model'
import { useTranslation } from 'react-i18next'
import './MallMainRecommend.scss'
import Slider, { Settings } from 'react-slick'
import CommonStore from '../../../store/CommonStore'

const MallMainRecommend = observer(
  ({
    listKey,
    renderData,
    categoryName,
    goToProduct,
    handleBookmark,
  }: {
    listKey: string
    renderData: DisplayProduct[]
    categoryName: string
    goToProduct: (productId: DisplayProduct, categoryName: string) => void
    handleBookmark: (
      bookmarkYn: string,
      targetId: number,
      requestType?: string
    ) => void
  }) => {
    const { t } = useTranslation()
    const { widthApp } = CommonStore
    const widthItem = (widthApp - 16 - 8 * 2 - 38) / 2

    const settings: Settings = {
      arrows: false,
      dots: false,
      infinite: false,
      variableWidth: true,
      className: 'slider-wrapper',
      slidesToScroll: 2,
      slidesToShow: 2,
    }

    const renderItem = (item: DisplayProduct, index: number) => {
      return (
        <div
          className="bx_item_wrap"
          // style={index === 0 ? { marginLeft: 16 } : {}}
        >
          <div
            className="btn_bookmark"
            onClick={(event) => {
              void handleBookmark(
                item.bookmarkYn === 'Y' ? 'N' : 'Y',
                item.productId,
                listKey
              )
            }}
          >
            {item.bookmarkYn === 'Y' ? <BookmarkON /> : <BookmarkOff />}
          </div>
          <div
            key={index}
            onClick={(event) => {
              goToProduct(item, categoryName)
            }}
            style={{ width: widthItem }}
            aria-label={`${listKey}_${index}`}
          >
            <div>
              <div className="bg_img_blur"></div>
              <FastImageWithFallback
                className="bx_img"
                wrapperStyle={{
                  position: 'relative',
                  paddingTop: '137.5%',
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
                source={{
                  uri: `${process.env.REACT_APP_LIFECARE_THUMBNAIL_IMG_URL}${item.mainImagePath}`,
                }}
              />
            </div>

            <div className="bx_info" style={{ width: widthItem }}>
              <div className="bg_info_wrap">
                <span className="info_brand_name">{item.brandName}</span>
                <span className="info_pd_name">{item.productName}</span>
                {item.discountRate > 0 ? (
                  <div className="bx_info_price">
                    <div className="bx_sale_per">
                      <span className="txt_percent">{item.discountRate}%</span>
                    </div>
                    <span className="txt_info_price">
                      {getFormatprice(item.salesPrice)}
                      <span className="txt_price_unit">
                        {t('screen.mall.label.won')}
                      </span>
                    </span>
                  </div>
                ) : (
                  <div className="bx_info_price">
                    <span className="txt_info_price">
                      {getFormatprice(item.salesPrice)}
                      <span className="txt_price_unit">
                        {t('screen.mall.label.won')}
                      </span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return renderData && renderData.length > 0 ? (
      <div className="mall_recommend_wrap" aria-label={listKey}>
        <div className="bx_title">
          <div style={{ position: 'relative' }}>
            <span className="txt_title">
              {t('screen.mall.title.MallMainRecommend01')}
              <span className="txt_title_bold">{categoryName}</span>
              {t('screen.mall.title.MallMainRecommend02')}
            </span>
          </div>
        </div>
        <Slider {...settings}>
          {renderData.map((item, index) => renderItem(item, index))}
        </Slider>
      </div>
    ) : (
      <></>
    )
  }
)
export default MallMainRecommend
