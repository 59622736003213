import { callAPIWithPOSTMethod } from "../common/api";
import CommonResponse from "../http";
import { AddQaRequest } from "./model";

export const createInquiry = async (
  qaItem: AddQaRequest,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/setting/qa",
      body: qaItem,
    },
    isLoading
  );
};
