import React, { useCallback, useState, useEffect, createRef } from "react";
import { observer } from "mobx-react";
import PostStore from "../../../../store/PostStore";
import PostStickyHeader from "./PostStickyHeader";
import { FeedParentTypeCode } from "../../../feed/hook/UseFeedScreen";
import PostEmpty from "./PostEmpty";
// import { handleTopButtonScroll } from "../../../../utils/common";
import { useTranslation } from "react-i18next";
import PostItem from "../PostItem";
import AuthStore from "../../../../store/AuthStore";
import CustomActivityIndicatorSmall from "../../../../components/common/CustomActivityIndicatorSmall";
import classes from "./PostMainV2.module.scss";
import { useWindowScroll } from "react-use";
import { goTo } from "../../../../hooks/navigate/NavigateFunction";
import { InfiniteScrollObserver } from "../../../../components/common/InfiniteScrollObserver";
import {
  ActHistoryTypeCode,
  ActPageCode,
} from "../../../../constants/ActHistory";
import { ActHistoryRequest } from "../../../../api/data/model";
import CommonStore from "../../../../store/CommonStore";
import { sendActHistory } from "../../../../api/data/api";

const PostMainV2 = observer(() => {
  // const dynamicLinkLocation = param?.dynamicLinkPosition; // 딥링크 관련된건지 모르겠는데 바로 랜딩되는 위치같음

  const {
    loading,
    isLoadingMore,
    postsLists,
    selectedTab,
    canLoadMore,
    pageIndex,
    selectedTabType,
  } = PostStore;

  const { setLoading, setIsLoadingMore, setPageIndex, setSelectedFeedIndex } =
    PostStore;

  const [showToolTip, setShowToolTip] = useState<boolean>(true);
  const tabType = selectedTabType;
  const { t } = useTranslation();

  // const { y } = useWindowScroll();
  const { y } = useWindowScroll();

  useEffect(() => {
    setTimeout(() => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
        actPageCode: ActPageCode.POST,
        actSourcePageCode: CommonStore.fixedPreviousRouteName,
        attr1: (y === 0).toString(),
      };
      void sendActHistory(actHistory);
    }, 1000);
  }, []);

  useEffect(() => {
    const handleBack = () => {
      if (PostStore.postFilterInfo.isShow) {
        PostStore.setPostFilterInfo(false);
      }
    };

    window.addEventListener("popstate", handleBack);

    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, []);

  useEffect(() => {
    // 딥링크 시 FeedScreen으로 바로 넘기는 로직
    if (!PostStore.isDeepLink) {
      return;
    }
    // if (!dynamicLinkLocation || dynamicLinkLocation !== "DETAIL") {
    //   return;
    // }
    if (!postsLists || postsLists.length === 0) {
      return;
    }
    let feedParentTypeCode: FeedParentTypeCode = "POSTTAB_POPULAR_POSTS"; // Default

    if (tabType === "RECENT") {
      feedParentTypeCode = "POSTTAB_RECENT_POSTS";
      PostStore.setSelectedTab(1);
    } else if (tabType === "FOLLOWING") {
      PostStore.setSelectedTab(2);
      feedParentTypeCode = "POSTTAB_FOLLOWING_POSTS";
    }

    goTo("/posts/feed", {
      state: {
        itemIndex: 0,
        feedParentTypeCode: feedParentTypeCode,
      },
    });
  }, [postsLists, tabType]);

  const handleAlarm = useCallback(() => {
    // navigate("NotificationScreen");
  }, []);

  const handleSearch = useCallback(() => {
    // navigate("SearchScreen");
  }, []);

  const showDrawerMenu = useCallback(() => {
    setShowToolTip(false);
    // navigation?.openDrawer();
  }, []);

  const handleMoreRequest = useCallback(() => {
    if (PostStore.postsLists.length < 10) {
      return;
    }
    if (!loading && !isLoadingMore) {
      setIsLoadingMore(true);
      setPageIndex(pageIndex + 1);
      if (PostStore.hasFilterOption) {
        void PostStore.findPostsByFilter();
      } else {
        void PostStore.findPostsByNoFilter();
      }
      setLoading(false);
    }
  }, [loading, isLoadingMore, pageIndex, setIsLoadingMore, setPageIndex]);

  // useEffect(() => {
  //   const bottom =
  //     window.scrollY !== 0 &&
  //     Math.ceil(window.innerHeight + window.scrollY) >=
  //       document.documentElement.scrollHeight;

  //   if (bottom) handleMoreRequest();
  // }, [y]);

  const clickPost = useCallback((targetIndex: number) => {
    let feedParentTypeCode: FeedParentTypeCode = "POSTTAB_POPULAR_POSTS"; // Default
    switch (PostStore.selectedTab) {
      case 0:
        feedParentTypeCode = "POSTTAB_POPULAR_POSTS";
        break;
      case 1:
        feedParentTypeCode = "POSTTAB_RECENT_POSTS";
        break;
      case 2:
        feedParentTypeCode = "POSTTAB_FOLLOWING_POSTS";
        break;
    }
    setSelectedFeedIndex(targetIndex);
    goTo("/posts/feed");
  }, []);

  const ListFooterActivityIndicator = useCallback(() => {
    return canLoadMore && isLoadingMore ? (
      <CustomActivityIndicatorSmall />
    ) : (
      <div style={{ height: 70 }} />
    );
  }, [canLoadMore, isLoadingMore]);

  return (
    <div id="post-main" className={classes.post_main}>
      <div id="post-main-header" className={classes.post_main_header}>
        <span className={classes.title}>{t("screen.post.title.main")}</span>
        <span className={classes.title2}>
          {t("screen.post.message.mainSubTitle")}
        </span>
      </div>
      <PostStickyHeader />
      {postsLists.length == 0 && (
        <PostEmpty
          tabIdx={selectedTab}
          isLoading={PostStore.loading}
          userId={
            AuthStore.sessionUser?.memberUuid
              ? AuthStore.sessionUser?.memberUuid
              : ""
          }
        />
      )}
      <div className={classes.post_main_container}>
        {postsLists &&
          postsLists.map((item: any, index: number) => {
            return (
              <div className={classes.post_main_item}>
                <PostItem
                  feedIndex={index}
                  feedId={item.feedId}
                  feedTitle={item.feedTitle}
                  nickname={item.nickname || ""}
                  videoCount={item.videoCount}
                  imageCount={item.imageCount}
                  thumbnailFilePath={item.thumbnailFilePath || ""}
                  originalFilePath={item.originalFilePath || ""}
                  onPressPost={() => {
                    clickPost(index);
                  }}
                />
              </div>
            );
          })}
      </div>
      <InfiniteScrollObserver onObserver={handleMoreRequest} />
      <ListFooterActivityIndicator />
    </div>
  );
});

export default PostMainV2;
