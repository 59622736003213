import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as TabHome } from '../../assets/icons/tab_home.svg'
import { ReactComponent as TabPost } from '../../assets/icons/tab_post.svg'
import { ReactComponent as TabQna } from '../../assets/icons/tab_qna.svg'
import { ReactComponent as TabMall } from '../../assets/icons/tab_mall.svg'
import { ReactComponent as TabCommunity } from '../../assets/icons/tab_community.svg'
import { ReactComponent as TabHomeF } from '../../assets/icons/tab_home_focused.svg'
import { ReactComponent as TabPostF } from '../../assets/icons/tab_post_focused.svg'
import { ReactComponent as TabQnaF } from '../../assets/icons/tab_qna_focused.svg'
import { ReactComponent as TabMallF } from '../../assets/icons/tab_mall_focused.svg'
import { ReactComponent as TabCommunityF } from '../../assets/icons/tab_community_focused.svg'
import { ReactComponent as TabMission } from '../../assets/icons/mission_32_off.svg'
import { ReactComponent as TabMissionF } from '../../assets/icons/mission_32_on.svg'
import TabStore from '../../store/TabStore'
import { observer } from 'mobx-react'
// import { StackTypeCode } from "../../constants/Stack";
// import { navigate } from "./NavigationFunction";
import CommonStore from '../../store/CommonStore'
// import {
//   ActHistoryTargetId,
//   ActHistoryTypeCode,
//   ActPageCode,
// } from "../constants/ActHistory";
// import MallMainScreen from "../../screens/mall/MallMainScreen";
// import BrandCommunityMain from "../../screens/brand/BrandCommunityMain";
// import LifecareScreen from "../../screens/lifecare/LifecareScreen";
// import BrandCommunityHome from "../../screens/brand/BrandCommunityHome";
// import { MissionScreen } from "../../screens/mission";
// import MagazineScreen from "../../screens/magazine/MagazineScreen";
import AuthStore from '../../store/AuthStore'
import { useLocation } from 'react-router-dom'
import './MainTabNavigator.scss'
import MainStore from '../../store/MainStore'
import { useWindowScroll } from 'react-use'
import { MAIN_TAB } from '../../constants/main'
import { Platform, PlatformTypes } from '../../native/platform'
import { goTo } from '../../hooks/navigate/NavigateFunction'
import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
} from '../../constants/ActHistory';
import { StackTypeCode } from '../../constants/Stack';
import { getStringRemoteConfig } from '../../utils/remoteConfig';
import TouchableWithAuthCheck from './TouchableWithAuthCheck';

const MainTabNavigator = observer(() => {
  const [isShowTutorial, setIsShowTutorial] = useState<boolean>(false)
  const [isShowMallTutorial, setIsShowMallTutorial] = useState<boolean>(false)
  const [isShowWriteBtn, setShowWriteBtn] = useState<boolean>(false)

  const { t } = useTranslation()
  const { pathname } = useLocation()

  const lowerPathname = pathname.toLowerCase()

  return (
    <div id="MainTabNavigator">
      <div
        className={`tab_list ${
          Platform.OS === PlatformTypes.IOS_APP ||
          Platform.OS === PlatformTypes.IOS_WEB
            ? 'ios_bottom'
            : ''
        }`}
      >
        <button
          className="HomeStack tab_item"
          onClick={() => {
            CommonStore.callActHistory(
              ActHistoryTypeCode.PAGE_CLICK,
              ActHistoryTargetId.MAIN,
              ActPageCode.BOTTOM_TAB
            )
            TabStore.setCurrentTab(StackTypeCode.HOME_STACK)
            goTo('/main')
          }}
        >
          <div className="tab_img">
            {lowerPathname === '/' || lowerPathname === '/main' ? (
              <TabHomeF />
            ) : (
              <TabHome />
            )}
          </div>
          <div className="tab_label_wrap">
            <div
              className={`tab_text ${
                lowerPathname === '/' || lowerPathname === '/main'
                  ? 'tab_text_focus'
                  : 'tab_text_unfocused'
              }`}
            >
              {t('screen.tabNavigator.label.home')}
            </div>
          </div>
        </button>

        <button
          className="PostStack tab_item"
          onClick={() => {
            CommonStore.callActHistory(
              ActHistoryTypeCode.PAGE_CLICK,
              ActHistoryTargetId.POST,
              ActPageCode.BOTTOM_TAB
            )
            TabStore.setCurrentTab(StackTypeCode.POST_STACK)
            goTo('/posts')
          }}
        >
          <div className="tab_img">
            {lowerPathname === '/posts' ? <TabPostF /> : <TabPost />}
          </div>
          <div className="tab_label_wrap">
            <div
              className={`tab_text ${
                lowerPathname === '/posts'
                  ? 'tab_text_focus'
                  : 'tab_text_unfocused'
              }`}
            >
              {t('screen.tabNavigator.label.post')}
            </div>
          </div>
        </button>

        <button
          className="MallMainScreen tab_item"
          onClick={() => {
            CommonStore.callActHistory(
              ActHistoryTypeCode.PAGE_CLICK,
              ActHistoryTargetId.MALL_MAIN,
              ActPageCode.BOTTOM_TAB
            )
            // TabStore.setCurrentTab(StackTypeCode.MALLMAIN_STACK)
            // if (AuthStore.sessionUser) {
            //   goTo('/mall')
            // } else {
            //   MainStore.setShowJoinModal(true)
            // }
            CommonStore.setShowDownloadAppDrive(true)
          }}
        >
          <div className="tab_img">
            {lowerPathname === '/mall' ? <TabMallF /> : <TabMall />}
          </div>
          <div className="tab_label_wrap">
            <div
              className={`tab_text ${
                lowerPathname === '/mall'
                  ? 'tab_text_focus'
                  : 'tab_text_unfocused'
              }`}
            >
              {t('screen.tabNavigator.label.220mall')}
            </div>
          </div>
        </button>

        {getStringRemoteConfig('show_brand_page') === 'true' && (
          <TouchableWithAuthCheck
            className='BrandCommunityMain tab_item'
            onPress={() => {
              CommonStore.callActHistory(
                ActHistoryTypeCode.PAGE_CLICK,
                ActHistoryTargetId.BRAND_COMMUNITY_MAIN,
                ActPageCode.BOTTOM_TAB
              )
              TabStore.setCurrentTab(StackTypeCode.BRANDCOMMUNITYMAIN_STACK);
              goTo('/BrandCommunityMain')
            }}
          >
            <div className='tab_img'>
              {(lowerPathname === '/brandcommunitymain' || lowerPathname === '/brandcommunityhome' || lowerPathname === '/brandcommunityarticlelist') ? <TabCommunityF /> : <TabCommunity />}
            </div>
            <div className='tab_label_wrap'>
              <div
                className={`tab_text ${(lowerPathname === '/brandcommunitymain' || lowerPathname === '/brandcommunityhome' || lowerPathname === '/brandcommunityarticlelist')
                  ? 'tab_text_focus'
                  : 'tab_text_unfocused'
                  }`}
              >
                {t('screen.tabNavigator.label.brand')}
              </div>
            </div>
          </TouchableWithAuthCheck>
        )}

        <button
          className="QnaStack tab_item"
          onClick={() => {
            CommonStore.callActHistory(
              ActHistoryTypeCode.PAGE_CLICK,
              ActHistoryTargetId.QNA,
              ActPageCode.BOTTOM_TAB
            )
            TabStore.setCurrentTab(StackTypeCode.QNA_STACK)
            goTo('/qna')
          }}
        >
          <div className="tab_img">
            {lowerPathname === '/qna' ? <TabQnaF /> : <TabQna />}
          </div>
          <div className="tab_label_wrap">
            <div
              className={`tab_text ${
                lowerPathname === '/qna'
                  ? 'tab_text_focus'
                  : 'tab_text_unfocused'
              }`}
            >
              {t('screen.tabNavigator.label.qna')}
            </div>
          </div>
        </button>

        <button
          className="MissionStack tab_item"
          onClick={() => {
            CommonStore.callActHistory(
              ActHistoryTypeCode.PAGE_CLICK,
              ActHistoryTargetId.MISSION,
              ActPageCode.BOTTOM_TAB
            )
            TabStore.setCurrentTab(StackTypeCode.MISSION_STACK)
            goTo('/mission')
          }}
        >
          <div className="tab_img">
            {lowerPathname === '/mission' ? <TabMissionF /> : <TabMission />}
          </div>
          <div className="tab_label_wrap">
            <div
              className={`tab_text ${
                lowerPathname === '/mission'
                  ? 'tab_text_focus'
                  : 'tab_text_unfocused'
              }`}
            >
              {t('screen.tabNavigator.label.mission')}
            </div>
          </div>
        </button>
      </div>
    </div>
  )
})

export default MainTabNavigator
