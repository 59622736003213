import { observer } from "mobx-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Styles } from "../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../assets/styles";
import { t } from "i18next";
import { bottomOffset } from "../../utils/common";
import MainSubscribeList from "./presenter/MainSubscribeList";
import BrandRecommend from "./presenter/BrandRecommend";
import BrandLineup from "./presenter/BrandLineup";
import PopularArticle from "./presenter/PopularArticle";
import { BannerInfo } from "../../api/banner/model";
import { BannerTypeCodes } from "../../constants/Banner.enum";
import { parseQueryParam } from "../../utils/queryParam";
import AuthStore from "../../store/AuthStore";
import MainLayout from "../main/MainLayout";
import { goTo } from "../../hooks/navigate/NavigateFunction";

import {
  brandSubscribe,
  getBrandBannerList,
  getBrandCommunityLineUp,
  getBrandSubscribe,
  getPopularArticle,
} from "../../api/brand/api";
import {
  BrandSubscribeResponse,
  BrandRecommendResponse,
  BrandCommunityLineUpResponse,
  BrandLineUpSortType,
  BrandCommunityPopularArticleResponse,
} from "../../api/brand/model";
import { showBottomToast } from "../../utils/Toast";
import { Color } from "../../assets/styles/variable";
import CommonBanner from "../../components/common/CommonBanner";
import BrandCommunityIntroduction from "./BrandCommunityIntroduction";
import RemoteConfigStore from "../../store/RemoteConfigStore";
import { BRAND_COMMUNITY_INTRODUCTION } from "../../constants/Storage";
import CommonStore from "../../store/CommonStore";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { getStringRemoteConfig } from "../../utils/remoteConfig";
import { useWindowScroll } from "react-use";
import { useLocation } from "react-router-dom";

const BrandCommunityMain = observer((props: any) => {

  const [showIntroductionModal, setShowIntroductionModal] = useState(false);

  const [bannerList, setBannerList] = useState<BannerInfo[]>([]);
  const [ad01bannerList, setAd01bannerList] = useState<BannerInfo[]>([]);
  const [ad02bannerList, setAd02bannerList] = useState<BannerInfo[]>([]);
  const [brandSubscribeList, setBrandSubscribeList] = useState<BrandSubscribeResponse[] | undefined>(undefined);
  const [brandRecommendList, setBrandRecommendList] = useState<BrandRecommendResponse[] | undefined>(undefined);

  const [brandLineupList, setBrandLineupList] = useState<BrandCommunityLineUpResponse[]>([]);
  const [brandLineupSortType, setBrandLineupSortType] = useState<BrandLineUpSortType>(BrandLineUpSortType.POPULAR);

  const [popularArticleList, setPopularArticleList] = useState<BrandCommunityPopularArticleResponse[]>([]);
  const [loadingPopularArticle, setLoadingPopularArticle] = useState<boolean>(false);
  const [noDataPopularArticle, setNodataPopularArticle] = useState<boolean>(false);

  const virtuosoRef = useRef<VirtuosoHandle>(null);

  const [showMainBannerLoading, setShowMainBannerLoading] = useState(false);
  const [showSubscribeListLoading, setShowSubscribeListLoading] = useState(false);
  const [showBrandRecommendListLoading, setShowBrandRecommendListLoading] = useState(false);
  const [showAD01BannerLoading, setShowAD01BannerLoading] = useState(false);

  const memberUuid = AuthStore.sessionUser?.memberUuid
    ? AuthStore.sessionUser?.memberUuid
    : AuthStore.tempMember?.tempMemberUUID
      ? AuthStore.tempMember?.tempMemberUUID
      : "";

  const { y } = useWindowScroll();
  const { setShowProfileBannerBrandPage } = AuthStore;
  const location = useLocation();
  const bannerHeightRef = useRef<HTMLDivElement>(null);
  const subscribeListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(showIntroductionModal) {
      document.body.style.overflow = "hidden"
    } else if (!showIntroductionModal) {
      document.body.style.overflow = "unset"
    }
  }, [showIntroductionModal])

  useEffect(() => {
    const heightOfMainBanner: number = bannerHeightRef.current?.offsetHeight || 0;
    const heightOfSubscribeList: number = subscribeListRef.current?.offsetHeight || 0;

    if (location.pathname.includes("BrandCommunityMain")) {
      if (y > heightOfMainBanner + heightOfSubscribeList) {
        setShowProfileBannerBrandPage(true)
      } else {
        setShowProfileBannerBrandPage(false)
      }
    }
  }, [y])

  const getSubscribeList = () => {
    void getBrandSubscribe(memberUuid).then((res) => {
      setShowSubscribeListLoading(true)
      res === null ? setBrandSubscribeList([]) : setBrandSubscribeList(res);
    });
  };

  const getBrandMainBannerList = async () => {
    let response: BannerInfo[] = await getBrandBannerList(BannerTypeCodes.BRAND_COMMUNITY_MAIN_BANNER, "");
    setShowMainBannerLoading(true)
    response = response.map((bannerInfo) => ({
      ...bannerInfo,
      bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
    }));
    response === null ? setBannerList([]) : setBannerList(response);
  };

  const getAD01BannerList = async () => {
    let response: BannerInfo[] = await getBrandBannerList(BannerTypeCodes.ROLLING_1_AD, "");
    setShowAD01BannerLoading(true)
    response = response.map((bannerInfo) => ({
      ...bannerInfo,
      bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
    }));
    response === null ? setAd01bannerList([]) : setAd01bannerList(response);
  };

  const getAD02BannerList = async () => {
    let response: BannerInfo[] = await getBrandBannerList(BannerTypeCodes.ROLLING_2_AD, "");
    response = response.map((bannerInfo) => ({
      ...bannerInfo,
      bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
    }));
    response === null ? setAd02bannerList([]) : setAd02bannerList(response);
  };

  const getBrandRecommendList = () => {
    const pageSize = 10;
    const pageIndex = 0;
    void getBrandCommunityLineUp(memberUuid, BrandLineUpSortType.ACCURACY, pageSize, pageIndex, false, true).then(
      (res) => {
        setShowBrandRecommendListLoading(true)
        res === null ? setBrandRecommendList([]) : setBrandRecommendList(res);
      }
    );
  };

  const getBrandLineUp = (sortType: BrandLineUpSortType) => {
    const pageSize = 5;
    const pageIndex = sortType === BrandLineUpSortType.LATEST ? "" : 0;
    void getBrandCommunityLineUp(memberUuid, sortType, pageSize, pageIndex).then((res) => {
      res === null ? setBrandLineupList([]) : setBrandLineupList(res);
    });
  };

  const getPopularArticleList = (cursor: number, pageSize: number) => {
    setLoadingPopularArticle(true);
    void getPopularArticle(memberUuid, cursor, pageSize)
      .then((res) => {
        if (res === null) {
          setPopularArticleList([]);
        } else if (res.length === 0) {
          setNodataPopularArticle(true);
        } else if (cursor === 0) {
          setPopularArticleList([...res]);
        } else {
          setPopularArticleList([...popularArticleList, ...res]);
        }
      })
      .finally(() => {
        setLoadingPopularArticle(false);
      });
  };

  const checkIntroductIonModal = async (): Promise<boolean> => {
    let returnVal;
       
    if (getStringRemoteConfig('show_brand_community_introduction') === "true") {
      let result;
      if (AuthStore.sessionUser?.memberUuid) {
        result = localStorage.getItem(`${BRAND_COMMUNITY_INTRODUCTION}_${AuthStore.sessionUser?.memberUuid}`);
      } else {
        result = localStorage.getItem(
          `${BRAND_COMMUNITY_INTRODUCTION}_${AuthStore.tempMember?.tempMemberUUID || ""}`
        );
      }
      if (result !== "Y") {
        setShowIntroductionModal(true);
        returnVal = true;
      } else {
        returnVal = false;
      }
    } else {
      setShowIntroductionModal(false);
      returnVal = false;
    }
    return returnVal;
  };

  const init = async () => {
    let cursor;
    let pageSize;
    if (popularArticleList.length > 0) {
      cursor = 0;
      pageSize = popularArticleList.length;
    } else {
      cursor = 0;
      pageSize = 10;
    }

    await Promise.all([
      void getBrandMainBannerList(),
      void getSubscribeList(),
      void getBrandRecommendList(),
      void getAD01BannerList(),
      void getAD02BannerList(),
      void getBrandLineUp(brandLineupSortType),
      void getPopularArticleList(cursor, pageSize),
    ]).finally(() => {
      if (CommonStore.isScrollToBrandPopularArticle) {
        setTimeout(() => {
          scrollToPopularArticle();
        }, 1000);
      }
    });
  };

  /* eslint-disable */
  useEffect(() => {
    void init();
    void checkIntroductIonModal();
  }, []);

  /* eslint-disable */

  const scrollToPopularArticle = () => {
    virtuosoRef && virtuosoRef.current && virtuosoRef.current.scrollToIndex({ index: "LAST" });
    CommonStore.setIsScrollToBrandPopularArticle(false);
  }

  const PopularArticleMore = () => {
    if (!loadingPopularArticle && !noDataPopularArticle) {
      const cursor = popularArticleList[popularArticleList.length - 1].sortOrder;
      const pageSize = 10;
      void getPopularArticleList(cursor, pageSize);
    }
  };

  const goToBrandHome = (communityId: number) => {
    goTo("/BrandCommunityHome", { state: { targetBrandId: communityId } });
  };

  const actionBrandSubscribe = (communityId: number) => {
    void brandSubscribe(communityId).then((res) => {
      res.successOrNot === "Y" && showBottomToast(t("screen.brandCommunity.message.subscribe"), bottomOffset());

      void init();
    });
  };

  const changeBrandLineUpSortType = (sortType: BrandLineUpSortType) => {
    setBrandLineupSortType(sortType);
    void getBrandLineUp(sortType);
  };

  const goToArticleDetail = (articleId: number) => {
    goTo(`/ArticleDetail/${articleId}`);
  };

  const renderCommonBannerForMainBannerList = useMemo(() => (
    <>
      {bannerList && bannerList.length > 0 ?
        (
          <div ref={bannerHeightRef}>
            <CommonBanner
              bannerList={bannerList}
              bannerSizeStyle={{ width: '100%', height: 390 }}
              showTitleIndicator
              showTextIndicator
              showTitleDim
              textIndexStyle={{ color: "#fff", fontSize: 12, fontWeight: "500" }}
              otherCustomCarouselSettings={{
                nextArrow: <></>,
                prevArrow: <></>,
              }}
            />
          </div>
        ) : (<div style={{ minHeight: 1 }}></div>)}
    </>
  ), [bannerList])

  const renderMainSubscribeList = useMemo(() => (
    <>
      {showMainBannerLoading && brandSubscribeList !== undefined ?
        (
          <div ref={subscribeListRef}>
            <MainSubscribeList renderData={brandSubscribeList} goToBrandHome={goToBrandHome} />
          </div>
        ) : (<div style={{ minHeight: 1 }}></div>)}
    </>
  ), [showMainBannerLoading, brandSubscribeList])

  const renderBrandRecommendForBrandRecommendList = useMemo(() => (
    <>
      {showMainBannerLoading && showSubscribeListLoading && brandRecommendList !== undefined ?
        (
          <>
            <BrandRecommend
              renderData={brandRecommendList}
              goToBrandHome={goToBrandHome}
              actionBrandSubscribe={actionBrandSubscribe}
            />
            <div style={{ height: 15 }} />
          </>
        ) : (<div style={{ minHeight: 1 }}></div>)}
    </>
  ), [showMainBannerLoading, showSubscribeListLoading, brandRecommendList])

  const renderCommonBannerForAd01BannerList = useMemo(() => (
    <>
      {showMainBannerLoading && showSubscribeListLoading && showBrandRecommendListLoading && ad01bannerList && ad01bannerList.length > 0 ?
        (
          <CommonBanner
            bannerList={ad01bannerList}
            bannerSizeStyle={{ width: '100%', height: 84 }}
            showTextIndicator
            textIndexContainerStyle={{
              position: "absolute",
              ...FlexBoxs.flex,
              ...Arranges.center_v_h,
              backgroundColor: "rgba(34,34,34,0.4)",
              borderRadius: 28,
              width: 29,
              height: 19,
              zIndex: 10,
              top: 6,
              right: 16,
            }}
            textIndexStyle={{ color: "#fff", fontSize: 10, fontWeight: "500" }}
            otherCustomCarouselSettings={{
              nextArrow: <></>,
              prevArrow: <></>,
            }}
          />
        ) : (<div style={{ minHeight: 1 }}></div>)}
    </>
  ), [showMainBannerLoading, showSubscribeListLoading, showBrandRecommendListLoading, ad01bannerList])

  const renderBrandLineupList = useMemo(() => (
    <>
      {showMainBannerLoading && showSubscribeListLoading && showBrandRecommendListLoading && showAD01BannerLoading && brandLineupList && brandLineupList.length > 0 ?
        (
          <div style={{ minHeight: 1 }}>
            <BrandLineup
              renderData={brandLineupList}
              sortType={brandLineupSortType}
              changeSortType={changeBrandLineUpSortType}
              actionBrandSubscribe={actionBrandSubscribe}
              goToBrandHome={goToBrandHome}
            />
          </div>
        ) : (<div style={{ minHeight: 1 }}></div>)}
    </>
  ), [showMainBannerLoading, showSubscribeListLoading, showBrandRecommendListLoading, showAD01BannerLoading, brandLineupList, brandLineupSortType])

  const renderCommonBannerForAd02BannerList = useMemo(() => (
    <>
      {showMainBannerLoading && showSubscribeListLoading && showBrandRecommendListLoading && showAD01BannerLoading && ad02bannerList && ad02bannerList.length > 0 ?
        (
          <CommonBanner
            bannerList={ad02bannerList}
            bannerSizeStyle={{ width: '100%', height: 84 }}
            showTextIndicator
            textIndexContainerStyle={{
              position: "absolute",
              ...FlexBoxs.flex,
              ...Arranges.center_v_h,
              backgroundColor: "rgba(34,34,34,0.4)",
              borderRadius: 28,
              width: 29,
              height: 19,
              zIndex: 10,
              top: 6,
              right: 16,
            }}
            textIndexStyle={{ color: "#fff", fontSize: 10, fontWeight: "500" }}
            otherCustomCarouselSettings={{
              nextArrow: <></>,
              prevArrow: <></>,
            }}
          />
        ) : (<div style={{ minHeight: 1 }}></div>)}
    </>
  ), [showMainBannerLoading, showSubscribeListLoading, showBrandRecommendListLoading, showAD01BannerLoading, ad02bannerList])

  const renderPopularArticle = useMemo(() => (
    <>
      {showMainBannerLoading && showSubscribeListLoading && showBrandRecommendListLoading && showAD01BannerLoading && popularArticleList && popularArticleList.length > 0 ?
        (
          <>
            <PopularArticle
              pageType={"BrandCommunityMain"}
              renderData={popularArticleList}
              goToArticleDetail={goToArticleDetail}
              onEndReached={PopularArticleMore}
            />
            <div style={{ height: 50 }}></div>
          </>
        ) : (<div style={{ minHeight: 1 }}></div>)}
    </>
  ), [showMainBannerLoading, showSubscribeListLoading, showBrandRecommendListLoading, showAD01BannerLoading, popularArticleList])

  const renderBrandCommunityIntroduction = useMemo(() => (
    <>
      {showIntroductionModal &&
        (
          <BrandCommunityIntroduction
            show={showIntroductionModal}
            onClose={() => {
              setShowIntroductionModal(false);
            }}
          />
        )}
    </>
  ), [showIntroductionModal])

  const renderList = [
    renderCommonBannerForMainBannerList,
    renderMainSubscribeList,
    renderBrandRecommendForBrandRecommendList,
    renderCommonBannerForAd01BannerList,
    renderBrandLineupList,
    renderCommonBannerForAd02BannerList,
    renderPopularArticle
  ];

  return (
    <MainLayout>
      <div id="brandCommunityMain" style={styles.main_wrap}>
        <>
          <div style={{ flex: 1, backgroundColor: Color.WHITE }}>
            <Virtuoso
              // defaultItemHeight={}
              useWindowScroll
              data={renderList}
              ref={virtuosoRef}
              itemContent={(index: number, item: any) => (
                <>
                  <div key={index}>
                    {item}
                  </div>
                </>
              )
              }
            />
          </div>
          {renderBrandCommunityIntroduction}
        </>
      </div>
    </MainLayout>
  );
});

export default BrandCommunityMain;

const styles: Styles = {
  main_wrap: {
    flex: 1,
    backgroundColor: "#fff",
  },
  ranking: {
    ...FlexBoxs.flex_1,
    backgroundColor: "white",
  },
  title_wrap: {
    ...Spacings.margin_top_24,
    ...Spacings.padding_left_right_16,

    ...Spacings.margin_bottom_20,
  },
  title_text: {
    ...Texts.contents_text_39,
    ...Texts.font_weight_bold,
    ...Colors.font_222,
  },
  title_info_text: {
    ...Spacings.margin_top_8,
    ...Texts.contents_text_0,
  },

  sheet: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_16,
    ...Spacings.padding_bottom_19,
    ...Spacings.padding_left_right_20,
  },
  sheet_contents: {
    ...Spacings.padding_top_bottom_16,
    ...Spacings.margin_left_6,
  },

  close_btn: {
    ...Positions.absolute,
    top: 6,
    right: 6,
    zIndex: 999,
  },
  sheet_title_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 32,
  },
  sheet_title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  filter_list_wrap: {
    ...Spacings.padding_left_16,
    ...Spacings.margin_bottom_24,
  },
  filter_label: {
    ...Spacings.padding_right_left_4,
    ...Texts.contents_text_28,
    ...Texts.font_weight_500,
  },
  filter_list: {
    ...Spacings.margin_top_10,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
  },
  filter_item: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Colors.border_color_ebebeb,
    ...Spacings.border_radius_4,
    ...Spacings.margin_right_bottom_6,
    height: 53,
    width: '25%',
    borderWidth: 1,
  },

  save_btn: {
    ...Positions.absolute,
    ...Positions.bottom,
    ...Positions.left,
    ...Positions.right,
    ...Colors.background_222,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  save_btn_text_ios: {
    ...Texts.contents_text_33,
    ...Spacings.padding_top_22,
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...Colors.font_fff,
  },
  save_btn_text_aos: {
    ...Texts.contents_text_33,
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...Colors.font_fff,
  },
  selected_filter_item: {
    ...Colors.border_color_be69c3,
  },
  selected_filter_text: {
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
  banner_ad: {
    height: 84,
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    ...Spacings.padding_top_bottom_14,
    ...Spacings.padding_left_right_20,
    backgroundColor: "#f2e6e4",
  },
  banner_ad_title: {
    ...Texts.header_text_0,
    ...Texts.font_letter_spacing_024,
  },
  banner_ad_sub: {
    ...Texts.contents_text_0,
    ...Texts.font_letter_spacing_024,
  },
};
