import { LikeTypeCode } from '../../constants/Like'
import {
  callAPIWithPOSTMethod,
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
} from '../common/api'
import { LikeListRequestResult } from './model'

export const saveLike = async (
  likeTargetId: number,
  likeTypeCode: string,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: '/v1/like',
      body: {
        likeTargetId,
        likeTypeCode,
      },
    },
    isLoading
  )

  return response?.successOrNot === 'Y'
}

export const cancelLike = async (
  likeTargetId: number,
  likeTypeCode: string,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: '/v1/like',
      body: {
        likeTargetId,
        likeTypeCode,
      },
    },
    isLoading
  )

  return response?.successOrNot === 'Y'
}

export const searchLikeList = async (
  likeTargetId: number | string,
  likeTypeCode: LikeTypeCode,
  pageSize?: number,
  pageIndex?: number,
  isLoading = true
): Promise<LikeListRequestResult> => {
  const response = await callAPIWithGETMethod(
    {
      url: '/v1/like/members',
      params: {
        likeTargetId,
        likeTypeCode,
        pageSize,
        pageIndex,
      },
    },
    isLoading
  )
  return (
    response?.successOrNot === 'Y' ? response?.data : null
  ) as LikeListRequestResult
}
