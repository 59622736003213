import { ReactComponent as BackIcon } from "../../assets/icons/arrow_left_black.svg";
import classes from "./ProfileHeader.module.scss";
import { goBack } from "../../hooks/navigate/NavigateFunction";

export interface ProfileHeaderProps {
  onSkip?: () => void;
  title: string;
  buttonName?: string;
  hideBack?: boolean;
  beforeBack?: () => void;
  disabled?: boolean;
}

export default function ProfileHeader({
  title,
  buttonName,
  onSkip,
  hideBack,
  beforeBack,
  disabled,
}: ProfileHeaderProps): JSX.Element {
  const backAndRefresh = () => {
    beforeBack && beforeBack();
    goBack();
  };
  // TODO
  // useEffect(() => {
  //   const backHandler = BackHandler.addEventListener(
  //     "hardwareBackPress",
  //     () => {
  //       goBack();
  //       return true;
  //     }
  //   );
  //   return () => backHandler.remove();
  // });

  return (
    <div className={classes.header}>
      <div className={classes.center} aria-label={"title"}>
        <span className={classes.title_txt}>{title}</span>
      </div>
      <div className={classes.left}>
        {hideBack !== true && (
          <button onClick={backAndRefresh} aria-label={"back"}>
            <BackIcon />
          </button>
        )}
      </div>
      {buttonName && (
        <div className={classes.right}>
          <button
            disabled={disabled}
            className={
              disabled === true ? classes.disabled_btn : classes.skip_btn
            }
            onClick={() => {
              onSkip && onSkip();
            }}
            aria-label={"skip"}
          >
            <span className={classes.skip_text}>{buttonName}</span>
          </button>
        </div>
      )}
    </div>
  );
}
