import { action, computed, makeObservable, observable } from "mobx";
import { Post, Tag } from "../api/feed/model";
import { Qna } from "../api/qna/model";
import { TagFeedItem } from "../api/tag/model";
import { AccountFeedTypeCode } from "../constants/Account";
import { FeedTypeCode } from "../constants/Feed";
import { UserActTypeCode } from "../constants/FeedType.enum";
import { BLANK_TOTAL_RESULT } from "../constants/Search";
import AccountStore from "./AccountStore";
import TagDetailStore from "./TagDetailStore";

class TagStore {
  constructor() {
    makeObservable(this);
  }

  @observable _loading = false;

  @observable _tagDetailStoreList: TagDetailStore[] = [];

  @observable errorMessage?: string;

  @observable _tagId = -1;

  @computed get tagDetailStoreList() {
    return this._tagDetailStoreList;
  }

  @computed get loading() {
    return this._loading;
  }

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    );
  }

  @computed get tagId() {
    return this._tagId;
  }

  @action initTagDetailStore = async (tagId: number) => {
    this.setLoading(true);
    const isExist = this._tagDetailStoreList.filter((x) => x.tagId === tagId);
    this.setTagId(tagId);
    if (isExist.length === 0) {
      const tagDetailStore = new TagDetailStore();

      tagDetailStore.setTagId(tagId);

      await tagDetailStore.getTagDetail(tagId);
      if (tagDetailStore.tagDetail) {
        await tagDetailStore.getFeedList(0, FeedTypeCode.POST, tagId);
        this.addTagDetailStore(tagDetailStore);
      }

      this.setLoading(false);
      return tagDetailStore.tagDetail ? tagDetailStore : null;
    }
    this.setLoading(false);
    return isExist[0];
  };

  @action addTagDetailStore = (tagDetailStore: TagDetailStore) => {
    this._tagDetailStoreList.push(tagDetailStore);
  };

  @action removeAccountStore = (tagId: number) => {
    this._tagDetailStoreList = this._tagDetailStoreList.filter(
      (x) => x.tagId !== tagId
    );
  };

  @action getTagDetailStore = (tagId: number) => {
    return this._tagDetailStoreList.filter(
      (x) => Number(x.tagId) === Number(tagId)
    )[0];
  };

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action setTagId = (tagId: number) => {
    this._tagId = tagId;
  };

  @action clear = () => {
    this._tagDetailStoreList.forEach((tagDetailStore, i) => {
      tagDetailStore.clear();
    });

    this._tagId = -1;
    this._tagDetailStoreList = [];
  };

  @action clearTagDetailStore = (tagId: number) => {
    const tagDetailStore = this._tagDetailStoreList.filter(
      (x) => x.tagId === tagId
    );

    if (tagDetailStore.length > 0) {
      tagDetailStore[0].clear();
    }
  };

  @action sync = (
    feedId: number,
    feedType: FeedTypeCode,
    actType: UserActTypeCode,
    extraData?: any
  ) => {
    switch (actType) {
      case UserActTypeCode.CREATE:
        this._tagDetailStoreList.forEach((tagDetailStore, i) => {
          tagDetailStore.setPageIndex(0, feedType);
          void tagDetailStore.getTagDetail(this.tagId);
          void tagDetailStore.getFeedList(0, feedType, this.tagId);
        });
        break;
      case UserActTypeCode.MODIFY:
        break;
      case UserActTypeCode.REPORT:
      case UserActTypeCode.DELETE:
        this._tagDetailStoreList.forEach((tagDetailStore, i) => {
          tagDetailStore.removeFeed(feedId, feedType);
        });
        break;
      case UserActTypeCode.SAVE_LIKE:
      case UserActTypeCode.CANCEL_LIKE:
      case UserActTypeCode.SAVE_BOOKMARK:
      case UserActTypeCode.CANCEL_BOOKMARK:
        this._tagDetailStoreList.forEach((tagDetailStore, i) => {
          tagDetailStore.updateLikeAndBookmark(feedId, actType);
        });
        break;
      case UserActTypeCode.UPDATE_COMMENT:
        this._tagDetailStoreList.forEach((tagDetailStore, i) => {
          tagDetailStore.updateCommentCount(feedId, extraData as Post);
        });
        break;
    }
  };

  @action syncInterestedTags = (tagList: Tag[]) => {
    this._tagDetailStoreList.forEach((tagDetailStore, i) => {
      tagDetailStore.tagDetail.interestedTagList = tagList;

      if (
        tagList.findIndex(
          (x) => x.tagName === tagDetailStore.tagDetail.tagName
        ) != -1
      ) {
        tagDetailStore.tagDetail.interestedTagYn = "Y";
      }
    });
  };

  @action updatePost = (feedId: number, post: TagFeedItem) => {
    this._tagDetailStoreList.forEach((tagDetailStore, i) => {
      tagDetailStore.updatePost(feedId, post);
    });
  };

  @action updateQna = (feedId: number, qnaDetail: Qna) => {
    this._tagDetailStoreList.forEach((tagDetailStore, i) => {
      tagDetailStore.updateQna(feedId, qnaDetail);
    });
  };

  @action updateAb = (feedId: number, ab: any) => {
    this._tagDetailStoreList.forEach((tagDetailStore, i) => {
      tagDetailStore.updateAb(feedId, ab);
    });
  };
}

export default new TagStore();
