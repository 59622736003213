import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";

import {
  getPopularFeedDetailList,
  getUserFeedList,
  getRecommendFeedDetailList,
  getSearchingFeedList,
  getProductFeedDetailList,
  getRecentFeedDetailList,
  getTagFeedDetailList,
} from "../api/feed/api";
import { FeedInfo, Post, Tag } from "../api/feed/model";
// import VideoStore from "./VideoStore";
import { UserActTypeCode } from "../constants/FeedType.enum";
import { SearchTabMenu, SEARCH_RESULT_COUNT } from "../constants/Search";
import { t } from "i18next";
import CommonStore from "./CommonStore";
import { FeedTypeCode } from "../constants/Feed";
import { goBack } from "../hooks/navigate/NavigateFunction";

// import { goBack } from "../navigation/NavigationFunction";

const PADDING_VERTICAL_VAL = 40;

interface FeedContentLineType {
  idx: number;
  lines: number;
  overTwoLine: boolean;
}
class FeedStore {
  constructor() {
    makeObservable(this);
    // this.videoStore = new VideoStore();
  }

  // videoStore: VideoStore;
  @observable _loading = true;

  @observable _isLoadingMore = false;

  @observable _canLoadMore = true;

  @observable _postsLists: Post[] = [];

  @observable _feedInfo: FeedInfo | null = null;

  @observable _feedContentLines: FeedContentLineType[] = [];

  @observable _afterReportHandler: (() => void) | null = null;

  @observable _targetHeight = 0;

  @observable _renderedPostsCount = 0;

  @observable _isRenderDone = false;

  @observable _selectedPostIndex = 0;

  @computed get targetHeight() {
    return this._targetHeight;
  }

  @computed get isRenderDone() {
    return this._isRenderDone;
  }

  @computed get selectedPostIndex() {
    return this._selectedPostIndex;
  }

  @action isCalledWhenPostFinishedRender = (
    postIndex: number,
    initHeight: number
  ) => {
    if (this.postsLists[postIndex] && !this.isRenderDone) {
      this._renderedPostsCount++;
      this.postsLists[postIndex].initialHeight = initHeight;

      //NOTE: 이동하려고 선택한 포스트 인덱스 이전 포스트들의 높이만 더해 targetHeight을 구함
      if (postIndex < this._selectedPostIndex) {
        this._targetHeight =
          this._targetHeight + initHeight + PADDING_VERTICAL_VAL;
      }

      if (this._renderedPostsCount >= this.postsLists.length) {
        this._isRenderDone = true;
      }
    }
  };

  @computed get loading() {
    return this._loading;
  }

  @computed get isLoadingMore() {
    return this._isLoadingMore;
  }

  @computed get canLoadMore() {
    return this._canLoadMore;
  }

  @computed get postsLists() {
    return this._postsLists;
  }

  @computed get feedInfo() {
    return this._feedInfo;
  }

  @computed get feedContentLines() {
    return this._feedContentLines;
  }

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action setAfterReportHandlerFeedStore = (handler: (() => void) | null) => {
    this._afterReportHandler = handler;
  };

  @action setIsLoadingMore = (loading: boolean) => {
    this._isLoadingMore = loading;
  };

  @action setCanLoadMore = (val: boolean) => {
    this._canLoadMore = val;
  };

  @action setPostLists = (posts: Post[]) => {
    this._postsLists = posts;
  };

  @action setFeedPost = (post: Post, index: number) => {
    this._postsLists[index] = post;
  };

  @action setFeedInfo = (info: FeedInfo) => {
    this._feedInfo = info;
  };

  @action setRenderedPostsCount = (val: number) => {
    this._renderedPostsCount = val;
  };

  @action setSelectedPostIndex = (val: number) => {
    this._selectedPostIndex = val;
  };

  @action clearFeed = () => {
    this._loading = true;
    this._isLoadingMore = false;
    this._canLoadMore = true;
    this._postsLists = [];
    // this.videoStore?.videoStoreClear();
    this._afterReportHandler = null;
    this._targetHeight = 0;
    this._renderedPostsCount = 0;
    this._isRenderDone = false;
  };

  @action getUserFeed = async (
    pageSize: number,
    start: number,
    topFeedId: number,
    memberUuid: string
  ) => {
    this.clearFeed();
    const userFeed = await getUserFeedList(
      pageSize,
      start,
      topFeedId,
      memberUuid
    );

    runInAction(() => {
      if (userFeed.successOrNot == "Y") {
        const postLists: Post[] = userFeed.data.feedFindVOList;
        this.setPostLists(postLists);
        this.addAdditionals(0);
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
      this.setLoading(false);
    });
  };

  @action getMoreUserFeed = async (
    pageSize: number,
    start: number,
    topFeedId: number,
    memberUuid: string
  ) => {
    const moreUserFeed = await getUserFeedList(
      pageSize,
      start,
      topFeedId,
      memberUuid
    );
    const formerPostsLength = this._postsLists.length;

    runInAction(() => {
      if (moreUserFeed.successOrNot == "Y") {
        const newPostLists: Post[] = moreUserFeed.data.feedFindVOList;
        this._postsLists.push(...newPostLists);
        if (newPostLists.length > 0) {
          this.addAdditionals(formerPostsLength);
          this.setIsLoadingMore(false);
        } else {
          this.setCanLoadMore(false);
        }
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
    });
  };

  @action getPopularFeedDetailList = async (
    pageSize: number,
    pageIndex: number
  ) => {
    this.clearFeed();

    const popularFeed = await getPopularFeedDetailList(pageSize, pageIndex);

    runInAction(() => {
      if (popularFeed.successOrNot == "Y") {
        const postLists: Post[] = popularFeed.data.feedFindVOList;
        this.setPostLists(postLists);
        this.addAdditionals(0);
        this.setCanLoadMore(false);
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          bottomOffset: 30,
        });
      }
      this.setLoading(false);
    });
  };

  @action getRecommendFeedDetailList = async (
    topFeedId: number,
    memberUuid: string
  ) => {
    this.clearFeed();

    const recommendedFeed = await getRecommendFeedDetailList(
      topFeedId,
      memberUuid
    );

    runInAction(() => {
      if (recommendedFeed.successOrNot == "Y") {
        const postLists: Post[] = recommendedFeed.data.feedFindVOList;
        this.setPostLists(postLists);
        this.addAdditionals(0);
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
      this.setLoading(false);
    });
  };

  @action getSearchingFeedDetailList = async (
    searchTabMenu: SearchTabMenu,
    query: string,
    topFeedId: number,
    pageSize = SEARCH_RESULT_COUNT,
    pageIndex = 0
  ) => {
    this.clearFeed();

    const searchingFeed = await getSearchingFeedList(
      searchTabMenu,
      query,
      topFeedId,
      pageSize,
      pageIndex
    );

    runInAction(() => {
      if (searchingFeed.successOrNot === "Y") {
        const postLists: Post[] = searchingFeed.data.feedFindVOList;
        this.setPostLists(postLists);
        this.addAdditionals(0);
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
      this.setLoading(false);
    });
  };

  @action getMoreSearchingFeed = async (
    searchTabMenu: SearchTabMenu,
    query: string,
    topFeedId: number,
    pageIndex: number,
    pageSize = SEARCH_RESULT_COUNT
  ) => {
    const moreSearchingFeed = await getSearchingFeedList(
      searchTabMenu,
      query,
      topFeedId,
      pageSize,
      pageIndex
    );
    const formerPostsLength = this._postsLists.length;

    runInAction(() => {
      if (moreSearchingFeed.successOrNot == "Y") {
        const newPostLists: Post[] = moreSearchingFeed.data.feedFindVOList;
        this._postsLists.push(...newPostLists);
        if (newPostLists.length > 0) {
          this.addAdditionals(formerPostsLength);
          this.setIsLoadingMore(false);
        } else {
          this.setCanLoadMore(false);
        }
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
    });
  };

  @action getProductFeedDetailList = async (
    topFeedId: number,
    pageSize: number,
    pageIndex: number,
    productFeedSearchType: string,
    productId: number
  ) => {
    this.clearFeed();

    const productList = await getProductFeedDetailList(
      topFeedId,
      pageSize,
      pageIndex,
      productFeedSearchType,
      productId
    );

    runInAction(() => {
      if (productList.successOrNot == "Y") {
        const postLists: Post[] = productList.data.feedFindVOList;
        this.setPostLists(postLists);
        this.addAdditionals(0);
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
      this.setLoading(false);
    });
  };

  @action getMoreProductFeed = async (
    pageSize: number,
    pageIndex: number,
    productFeedSearchType: string,
    productId: number
  ) => {
    const moreProduct = await getProductFeedDetailList(
      NaN,
      pageSize,
      pageIndex,
      productFeedSearchType,
      productId
    );
    const formerPostsLength = this._postsLists.length;

    runInAction(() => {
      if (moreProduct.successOrNot == "Y") {
        const newPostLists: Post[] = moreProduct.data.feedFindVOList;
        this._postsLists.push(...newPostLists);
        if (newPostLists.length > 0) {
          this.addAdditionals(formerPostsLength);
          this.setIsLoadingMore(false);
        } else {
          this.setCanLoadMore(false);
        }
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
    });
  };

  @action getTagFeedDetailList = async (
    type: FeedTypeCode,
    pageSize: number,
    pageIndex: number,
    tagId: number
  ) => {
    this.clearFeed();

    const feedList = await getTagFeedDetailList(
      type,
      pageSize,
      pageIndex,
      tagId
    );

    runInAction(() => {
      if (feedList.successOrNot == "Y") {
        const postLists: Post[] = feedList.data.list;
        this.setPostLists(postLists);
        this.addAdditionals(0);
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
      this.setLoading(false);
    });
  };

  @action getMoreTagFeed = async (
    type: FeedTypeCode,
    pageSize: number,
    pageIndex: number,
    tagId: number
  ) => {
    const moreList = await getTagFeedDetailList(
      type,
      pageSize,
      pageIndex,
      tagId
    );
    const formerPostsLength = this._postsLists.length;

    runInAction(() => {
      if (moreList.successOrNot == "Y") {
        const newPostLists: Post[] = moreList.data.list;
        this._postsLists.push(...newPostLists);
        if (newPostLists.length > 0) {
          this.addAdditionals(formerPostsLength);
          this.setIsLoadingMore(false);
        } else {
          this.setCanLoadMore(false);
        }
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
    });
  };

  @action addPostsLists = (newPostLists: Post[]) => {
    this._postsLists.push(...newPostLists);
  };

  @action unshiftPostsLists = (newPostLists: Post[]) => {
    this._postsLists.unshift(...newPostLists);
  };

  @action findMatchedPostIndex = (feedId: number) => {
    for (let postIndex = 0; postIndex < this._postsLists.length; postIndex++) {
      if (this._postsLists[postIndex].feedId === feedId) {
        return postIndex;
      }
    }
    return 0;
  };

  @action addAdditionals = (startIndex: number) => {
    for (
      let postIndex = startIndex;
      postIndex < this._postsLists.length;
      postIndex++
    ) {
      this._postsLists[postIndex].showMore = true;
      this._postsLists[postIndex].currentCarouIndex = 0;
      this._postsLists[postIndex].showDeletePopup = false;
    }
  };

  @action popPostFromFeed = (postIndex: number) => {
    if (this._postsLists.length > 0) {
      this._postsLists.splice(postIndex, 1);
      if (this._postsLists.length == 0) {
        goBack();
      }
    }
  };

  @action popPostFromFeedWithFeedId = (feedId: number) => {
    this._postsLists.forEach((elem, index) => {
      elem.feedId === feedId && this._postsLists.splice(index, 1);
    });
  };

  @action popPostsFromFeed = (createMemberUuid: string) => {
    this._postsLists.forEach((elem, index) => {
      if (elem.createMemberUuid === createMemberUuid) {
        this._postsLists.splice(index, 1);
      }
    });
  };

  @action updateFeedList = (order: string, postIndex: number) => {
    const matchedIndex: number = postIndex;
    let updatedPost: Post;

    switch (order) {
      case UserActTypeCode.CANCEL_LIKE:
        if (this._postsLists[matchedIndex].likeYn === "Y") {
          updatedPost = {
            ...this._postsLists[matchedIndex],
            likeYn: "N",
            likeCount: this._postsLists[matchedIndex].likeCount - 1,
          };
          this.setFeedPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SAVE_LIKE:
        if (this._postsLists[matchedIndex].likeYn === "N") {
          updatedPost = {
            ...this._postsLists[matchedIndex],
            likeYn: "Y",
            likeCount: this._postsLists[matchedIndex].likeCount + 1,
          };
          this.setFeedPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.CANCEL_BOOKMARK:
        if (this._postsLists[matchedIndex].bookmarkYn === "Y") {
          updatedPost = {
            ...this._postsLists[matchedIndex],
            bookmarkYn: "N",
          };
          this.setFeedPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SAVE_BOOKMARK:
        if (this._postsLists[matchedIndex].bookmarkYn === "N") {
          updatedPost = {
            ...this._postsLists[matchedIndex],
            bookmarkYn: "Y",
          };
          this.setFeedPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SHOW_MORE:
        if (this._postsLists[matchedIndex].showMore === true) {
          updatedPost = {
            ...this._postsLists[matchedIndex],
            showMore: false,
          };
          this.setFeedPost(updatedPost, matchedIndex);
        } else {
          updatedPost = {
            ...this._postsLists[matchedIndex],
            showMore: true,
          };
          this.setFeedPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.OPEN_MODAL:
        if (this._postsLists[matchedIndex]) {
          updatedPost = {
            ...this._postsLists[matchedIndex],
            showDeletePopup: true,
          };
          this.setFeedPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.CLOSE_MODAL:
        if (this._postsLists[matchedIndex]) {
          updatedPost = {
            ...this._postsLists[matchedIndex],
            showDeletePopup: false,
          };
          this.setFeedPost(updatedPost, matchedIndex);
        }
        break;
    }
  };

  @action addFeedContentLines = (idx: number, lines: number) => {
    this._feedContentLines.push({
      idx: idx,
      lines: lines,
      overTwoLine: false,
    });
  };

  @action setFeedContentLines = (
    idx: number,
    lines: number,
    overTwoLine: boolean
  ) => {
    const content = this.getFeedContentLines(idx);

    if (content) {
      content.lines = lines;
      content.overTwoLine = overTwoLine;
    }
  };

  @action getFeedContentLines = (idx: number) => {
    return this._feedContentLines.filter((data) => data.idx === idx)[0];
  };

  @action addAdditionalsWithList = (needToBeAdded: Post[]) => {
    for (let postIndex = 0; postIndex < needToBeAdded.length; postIndex++) {
      if (needToBeAdded[postIndex]) {
        needToBeAdded[postIndex].showMore = true;
        needToBeAdded[postIndex].currentCarouIndex = 0;
      }
    }
  };

  @action getRecentFeedDetailList = async (
    pageSize: number,
    cursor: string | null,
    feedTypeCode: string,
    followingYn: string
  ) => {
    this.setLoading(false);
    this.clearFeed();

    const recentFeedDetail = await getRecentFeedDetailList(
      pageSize,
      cursor,
      feedTypeCode,
      followingYn
    );

    runInAction(() => {
      if (recentFeedDetail.successOrNot == "Y") {
        const postLists: Post[] = recentFeedDetail.data.feedFindVOList;
        this.addAdditionalsWithList(postLists);
        this.setPostLists(postLists);

        if (postLists.length > 0) {
          this.setIsLoadingMore(false);
        } else {
          this.setCanLoadMore(false);
        }
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
      this.setLoading(false);
    });
  };

  @action getMoreRecentFeedDetailList = async (
    pageSize: number,
    cursor: string | null,
    feedTypeCode: string,
    followingYn: string,
    addToFront: boolean
  ) => {
    const recentFeedDetail = await getRecentFeedDetailList(
      pageSize,
      cursor,
      feedTypeCode,
      followingYn
    );

    runInAction(() => {
      if (recentFeedDetail.successOrNot == "Y") {
        const postLists: Post[] = recentFeedDetail.data.feedFindVOList;
        this.addAdditionalsWithList(postLists);

        addToFront
          ? this.unshiftPostsLists(postLists)
          : this.addPostsLists(postLists);

        if (postLists.length > 0) {
          this.setIsLoadingMore(false);
        } else {
          this.setCanLoadMore(false);
        }
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
      this.setLoading(false);
    });
  };
}

export default new FeedStore();
