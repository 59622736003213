import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Styles } from "../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../assets/styles";
import { t } from "i18next";
import { Virtuoso } from "react-virtuoso";
import { bottomOffset, openInAppBrowser } from "../../utils/common";
import PopularArticle from "./presenter/PopularArticle";
import { BannerInfo } from "../../api/banner/model";
import { BannerTypeCodes } from "../../constants/Banner.enum";
import { parseQueryParam } from "../../utils/queryParam";
import AuthStore from "../../store/AuthStore";
import { TitleHeader } from "../../components/common";
import { HeaderItem } from "../../constants/Header";
import BrandHomeHeader from "./presenter/BrandHomeHeader";
import BrandProducRankingtList from "./presenter/BrandProducRankingtList";
import BrandStoryList from "./presenter/BrandStoryList";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import {
  brandCommunityFeed,
  brandCommunityHomeInfo,
  brandSubscribe,
  getBrandBannerList,
  getBrandProductSearchResult,
  getPopularArticle,
} from "../../api/brand/api";
import {
  BrandCommunityHomeInfoResponse,
  BrandCommunityPopularArticleResponse,
  FeedItem,
  SearchProductItem,
  TabTypeCode,
} from "../../api/brand/model";
import { showBottomToast } from "../../utils/Toast";
import WriteButtonStore from "../../store/WriteButtonStore";
import CommonBanner from "../../components/common/CommonBanner";
import CustomActivityIndicator from "../../components/common/CustomActivityIndicatorSmall";
import MainTabNavigator from "../../components/common/MainTabNavigator";
import WriteButtonBottomSheet from "../../components/common/WriteButtonBottomSheet";

const BrandCommunityHome = observer((props: any) => {
  const location = useLocation();
  const params = location.state;
  const targetBrandId = (useParams()?.targetBrandId || params?.targetBrandId || 0) as number

  const selfWidth: string = '100%';

  const [bannerList, setBannerList] = useState<BannerInfo[]>([]);
  const [textBannerList, setTextBannerList] = useState<BannerInfo[]>([]);
  const [homeADBannerList, setHomeADBannerList] = useState<BannerInfo[]>([]);
  const [homeListADBannerList, setHomeListADBannerList] = useState<BannerInfo[]>([]);
  const [homeInfo, setHomeInfo] = useState({} as BrandCommunityHomeInfoResponse);
  const [popularArticleList, setPopularArticleList] = useState<BrandCommunityPopularArticleResponse[]>([]);
  const [firstRenderLoading, setRenderLoading] = useState(true);

  const [feedList01, setFeedList01] = useState<FeedItem[]>([]);
  const [feedList, setFeedList] = useState<FeedItem[]>([]);
  const [feedPageIndex, setFeedPageIndex] = useState<number>(0);

  const [productRankingList, setProductRankingList] = useState<SearchProductItem[][]>([]);
  const [isEndReached, setIsEndReached] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabTypeCode>(TabTypeCode.POST);

  const memberUuid = AuthStore.sessionUser?.memberUuid
    ? AuthStore.sessionUser?.memberUuid
    : AuthStore.tempMember?.tempMemberUUID
      ? AuthStore.tempMember?.tempMemberUUID
      : "";

  const getPopularArticleList = (communityId?: number) => {
    const cursor = 0;
    const pageSize = 5;
    void getPopularArticle(memberUuid, cursor, pageSize, communityId).then((res) => {
      res === null ? setPopularArticleList([]) : setPopularArticleList(res);
      void getBrandHomeADBanner();
    });
  };

  const getBrandCommunityFeed = () => {
    const pageIndex = feedPageIndex;
    const pageSize = currentTab === TabTypeCode.AB ? 5 : 10;
    void brandCommunityFeed(memberUuid, homeInfo.communityId, currentTab, pageIndex, pageSize)
      .then((res) => {
        if (res !== null) {
          if (pageIndex === 0) {
            if (res.length === 0) {
              setIsEndReached(false);
              setFeedList([]);
              setFeedList01([]);
            } else {
              setIsEndReached(true);
              setFeedList(res);
              setFeedList01([]);
              setFeedPageIndex(feedPageIndex + 1);
            }
          } else {
            if (res.length === 0) {
              setIsEndReached(false);
              setFeedList01([...feedList01]);
            } else {
              setIsEndReached(true);
              setFeedList01([...feedList01, ...res]);
              setFeedPageIndex(feedPageIndex + 1);
            }
          }
        } else {
          setIsEndReached(false);
          setFeedList([]);
          setFeedList01([]);
        }
      })
      .finally(() => {
        setLoading(false);
        if (firstRenderLoading) {
          void getBrandHomeListADBanner();
        }
      });
  };

  const goToInAppBrowser = (url: string) => {
    void openInAppBrowser(url);
  };

  const goToBrandCommunityProductListScreen = (homeInfo: BrandCommunityHomeInfoResponse) => {
    goTo("/BrandCommunityProductList", {
      state: {
        brandCommunityId: homeInfo.communityId,
        brandCommunityName: homeInfo.communityName,
        brandCommunitySubscriptionYn: homeInfo.subscriptionYn,
      }
    });
  };

  const goToBrandCommunityArticleListScreen = (homeInfo: BrandCommunityHomeInfoResponse) => {
    goTo("/BrandCommunityArticleList", {
      state: {
        brandCommunityId: homeInfo.communityId,
        brandCommunityName: homeInfo.communityName,
        corpMemberYn: homeInfo.corpMemberYn,
      }
    });
  };

  const actionBrandSubscribe = (communityId: number) => {
    void brandSubscribe(communityId).then((res) => {
      res.successOrNot === "Y" && showBottomToast(t("screen.brandCommunity.message.subscribe"), bottomOffset());
      void getBrandCommunityHomeInfo(targetBrandId);
    });
  };

  const goToArticleDetail = (articleId: number) => {
    goTo(`/ArticleDetail/${articleId}`);
  };

  const onEndReached = () => {
    if (!loading && isEndReached) {
      setLoading(true);
      void getBrandCommunityFeed();
    }
  };

  const getBrandHomeTopBanner = () => {
    void getBrandBannerList(BannerTypeCodes.BRAND_HOME_TOP_BANNER, targetBrandId.toString()).then((response) => {
      if (response !== null) {
        const res = response.map((bannerInfo) => ({
          ...bannerInfo,
          bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
        }));
        setBannerList(res);
      } else {
        setBannerList([]);
      }
    });
  };

  const getBrandCommunityHomeInfo = (targetBrandId: number, isFirstRender: boolean = false) => {
    void brandCommunityHomeInfo(memberUuid, targetBrandId).then((res) => {
      res === null ? setHomeInfo({} as BrandCommunityHomeInfoResponse) : setHomeInfo(res);
      if (isFirstRender) getPopularArticleList(res ? res.communityId : undefined);
    });
  };

  const getBrandHomeTextBanner = () => {
    void getBrandBannerList(BannerTypeCodes.BRAND_HOME_TEXT_BANNER, targetBrandId.toString()).then((response) => {
      if (response !== null) {
        const res = response.map((bannerInfo) => ({
          ...bannerInfo,
          bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
        }));
        setTextBannerList(res);
      } else {
        setTextBannerList([]);
      }
    });
  };

  const getBrandHomeADBanner = () => {
    void getBrandBannerList(BannerTypeCodes.BRAND_HOME_AD, "").then((response) => {
      if (response !== null) {
        const res = response.map((bannerInfo) => ({
          ...bannerInfo,
          bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
        }));
        setHomeADBannerList(res);
      } else {
        setHomeADBannerList([]);
      }
      void getBrandProductRankingList();
    });
  };

  const getBrandProductRankingList = () => {
    const type = "";
    const brandCommunityId = targetBrandId;
    const query = "";
    const pageSize = 12;
    const pageIndex = 0;
    const sort = "popularityDESC";
    void getBrandProductSearchResult(type, brandCommunityId, query, pageSize, pageIndex, sort).then((response) => {
      if (response !== null && response.list && response.list.length > 0) {
        const temp: SearchProductItem[][] = [];
        for (let i = 0; i < 4; i++) {
          temp.push(response.list.slice(i * 3, (i + 1) * 3) || []);
        }
        setProductRankingList(temp.filter((x) => x.length > 0));
      } else {
        setProductRankingList([]);
      }
      void getBrandCommunityFeed();
    });
  };

  const getBrandHomeListADBanner = () => {
    void getBrandBannerList(BannerTypeCodes.BRAND_HOME_LIST_AD, "").then((response) => {
      if (response !== null) {
        const res = response.map((bannerInfo) => ({
          ...bannerInfo,
          bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
        }));
        setHomeListADBannerList(res);
      } else {
        setHomeListADBannerList([]);
      }
      setRenderLoading(false);
    });
  };

  const init = () => {
    void getBrandHomeTopBanner();
    void getBrandCommunityHomeInfo(targetBrandId, true);
    void getBrandHomeTextBanner();
  };
  /* eslint-disable */
  useEffect(() => {
    void init();
  }, []);

  useEffect(() => {
    if (!firstRenderLoading) {
      // 브랜드 피드
      void getBrandCommunityFeed();
    }
  }, [currentTab, firstRenderLoading]);

  const renderCommonBannerForBannerList = useMemo(() => (
    <>
      {bannerList && bannerList.length > 0 && (
        <CommonBanner
          bannerList={bannerList}
          bannerSizeStyle={{ width: selfWidth, height: 250 }}
          showTitleIndicator
          showTextIndicator
          showTitleDim
          textIndexStyle={{ color: "#fff", fontSize: 12, fontWeight: "500" }}
          otherCustomCarouselSettings={{
            nextArrow: <></>,
            prevArrow: <></>,
          }}
        />
      )}
    </>
  ), [bannerList]);

  const renderBrandHomeHeader = useMemo(() => (
    <>
      {Object.keys(homeInfo).length > 0 && (
        <BrandHomeHeader
          renderData={homeInfo}
          actionBrandSubscribe={actionBrandSubscribe}
          goToInAppBrowser={goToInAppBrowser}
          goToBrandCommunityProductListScreen={goToBrandCommunityProductListScreen}
          goToBrandCommunityArticleListScreen={goToBrandCommunityArticleListScreen}
        />
      )}
    </>
  ), [homeInfo]);

  const renderCommonBannerForTextBannerList = useMemo(() => (
    <>
      {textBannerList && textBannerList.length > 0 && (
        <CommonBanner
          bannerSizeStyle={{ width: selfWidth, height: textBannerList.length === 1 ? 50 : 80, position: "relative" }}
          bannerList={textBannerList}
          showDotIndicator
          dotStyle={styles.carousel_pagination_active}
          inactiveDotStyle={styles.carousel_pagination_inactive}
          otherCustomCarouselSettings={{
            nextArrow: <></>,
            prevArrow: <></>,
          }}
          dotContainerStyle={{position: "absolute", bottom: 14, left: 0, right: 0}}
        />
      )}
    </>
  ), [textBannerList]);

  const renderPopularArticleList = useMemo(() => (
    <>
      {popularArticleList && popularArticleList.length > 0 && (
        <PopularArticle
          pageType={"BrandCommunityHome"}
          renderData={popularArticleList}
          goToArticleDetail={goToArticleDetail}
          handleMore={() => {
            goToBrandCommunityArticleListScreen(homeInfo);
          }}
        />
      )}
    </>
  ), [popularArticleList]);

  const renderCommonBannerForHomeADBannerList = useMemo(() => (
    <>
      {homeADBannerList && homeADBannerList.length > 0 && (
        <CommonBanner
          bannerList={homeADBannerList}
          bannerSizeStyle={{ width: selfWidth, height: 84 }}
          otherCustomCarouselSettings={{
            nextArrow: <></>,
            prevArrow: <></>,
          }}
        />
      )}
    </>
  ), [homeADBannerList]);

  const renderBrandProducRankingtList = useMemo(() => (
    <>
      {productRankingList?.length > 0 && (
        <BrandProducRankingtList renderData={productRankingList} />
      )}
    </>
  ), [productRankingList]);

  const renderBrandStoryList = useMemo(() => (
    <>
      {!firstRenderLoading && (
        <div>
          <BrandStoryList
            currentTab={currentTab}
            changeTab={(tab: TabTypeCode) => {
              if (currentTab !== tab) {
                setLoading(true);
                setFeedList([]);
                setFeedList01([]);
                setFeedPageIndex(0);
                setCurrentTab(tab);
                setIsEndReached(true);
              }
            }}
            feedList={feedList}
            feedList01={feedList01}
            bannerList={homeListADBannerList}
            onEndReached={onEndReached}
          />
        </div>
      )}
    </>
  ), [firstRenderLoading, currentTab, feedList, feedList01, homeListADBannerList]);

  const renderLoading = useMemo(() => {
    return loading ? <CustomActivityIndicator /> : <></>
  }, [loading]);

  return (
    <div id="MainLayout">
      <div className="main_body" style={{ paddingBottom: 56 }}>
        <div className="main_body_item">
          <div style={{ ...FlexBoxs.flex_1, backgroundColor: "#fff" }}>
            <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", zIndex: 99 }}>
              <TitleHeader
                style={{ width: "100%"}}
                title={homeInfo.communityName}
                rightItem={HeaderItem.WRITE}
                onClickWrite={() =>
                  WriteButtonStore.ClickWriteButton({
                    action: () => {
                      goTo("/ArticleWrite", {
                        state: {
                          communityId: homeInfo.communityId,
                          subscriptionYn: homeInfo.subscriptionYn,
                        }
                      });
                    },
                  })
                }
              />
            </div>

            <Virtuoso
              useWindowScroll
              data={[{}]}
              itemContent={(_) => {
                return (
                  <>
                    {renderCommonBannerForBannerList}
                    {renderBrandHomeHeader}
                    {renderCommonBannerForTextBannerList}
                    <div style={{ borderStyle: 'solid', borderWidth: 5, borderColor: "#eee", marginBottom: -5 }}></div>
                    {renderPopularArticleList}
                    <div style={{ height: 30 }}></div>
                    {renderCommonBannerForHomeADBannerList}
                    {renderBrandProducRankingtList}
                    {renderBrandStoryList}
                    {renderLoading}
                  </>
                )
              }}
            />
          </div>
        </div>
        <WriteButtonBottomSheet
          show={WriteButtonStore.showWriteButtonBottomSheet}
          onClose={() => WriteButtonStore.setShowWriteButtonBottomSheet(false)}
        />
      </div>
      <MainTabNavigator />
    </div>
  );
});
/* eslint-enable */

export default BrandCommunityHome;

const styles: Styles = {
  line_h10: {
    height: 10,
    backgroundColor: "#f5f5f5",
  },
  ranking: {
    ...FlexBoxs.flex_1,
    backgroundColor: "white",
  },
  title_wrap: {
    ...Spacings.margin_top_24,
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_bottom_20,
  },
  title_text: {
    ...Texts.contents_text_39,
    ...Texts.font_weight_bold,
    ...Colors.font_222,
  },
  title_info_text: {
    ...Spacings.margin_top_8,
    ...Texts.contents_text_0,
  },

  sheet: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_16,
    ...Spacings.padding_bottom_19,
    ...Spacings.padding_left_right_20,
  },
  sheet_contents: {
    ...Spacings.padding_top_bottom_16,
    ...Spacings.margin_left_6,
  },

  close_btn: {
    ...Positions.absolute,
    top: 6,
    right: 6,
    zIndex: 999,
  },
  sheet_title_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 32,
  },
  sheet_title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  filter_list_wrap: {
    ...Spacings.padding_left_16,
    ...Spacings.margin_bottom_24,
  },
  filter_label: {
    ...Spacings.padding_right_left_4,
    ...Texts.contents_text_28,
    ...Texts.font_weight_500,
  },
  filter_list: {
    ...Spacings.margin_top_10,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
  },
  filter_item: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Colors.border_color_ebebeb,
    ...Spacings.border_radius_4,
    ...Spacings.margin_right_bottom_6,
    height: 53,
    width: '25%',
    borderWidth: 1,
    borderStyle: 'solid'
  },

  save_btn: {
    ...Positions.absolute,
    ...Positions.bottom,
    ...Positions.left,
    ...Positions.right,
    ...Colors.background_222,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  save_btn_text_ios: {
    ...Texts.contents_text_33,
    ...Spacings.padding_top_22,
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...Colors.font_fff,
  },
  save_btn_text_aos: {
    ...Texts.contents_text_33,
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...Colors.font_fff,
  },
  selected_filter_item: {
    ...Colors.border_color_be69c3,
  },
  selected_filter_text: {
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
  banner_ad: {
    height: 84,
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    ...Spacings.padding_top_bottom_14,
    ...Spacings.padding_left_right_20,
    backgroundColor: "#f2e6e4",
  },
  banner_ad_title: {
    ...Texts.header_text_0,
    ...Texts.font_letter_spacing_024,
  },
  banner_ad_sub: {
    ...Texts.contents_text_0,
    ...Texts.font_letter_spacing_024,
  },

  dividing_line: {
    height: 10,
    ...Colors.background_f5f5f5,
  },
  carousel_pagination_container: {
    marginTop: -20,
  },
  carousel_pagination_active: {
    ...Spacings.border_radius_5,
    ...Colors.background_999999,
    width: 4,
    height: 4,
    marginHorizontal: 4,
  },
  carousel_pagination_inactive: {
    ...Spacings.border_radius_5,
    ...Colors.background_c4c4c4,
    width: 4,
    height: 4,
    marginHorizontal: 4,
    opacity: 0.4,
  },
};
