import React from "react";
import "./HorizontalAbList.scss";
import { AbDetail, AbVoteResult } from "../../../api/ab/model";
// import FadeInView from "../../../components/common/FadeInView";
import { AbTypeCode } from "../../../constants/ab";
import { ActSectionCode } from "../../../constants/ActHistory";
import AbCard from "./AbListCard";
import { Virtuoso } from "react-virtuoso";
import Slider from "react-slick";

interface HorizontalAbListProps {
  abs: AbDetail[];
  actSectionCode: ActSectionCode;
  onVoteSuccess?: (res: AbVoteResult, type: AbTypeCode) => void;
}

const cardSlidersettings = {
  className: "center",
  infinite: false,
  centerPadding: "60px",
  swipeToSlide: true,
  arrows: false,
  dots: false,
  variableWidth: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: true,
};

const HorizontalAbList = ({
  abs,
  actSectionCode,
  onVoteSuccess,
}: HorizontalAbListProps) => {
  return (
    <div className="ab_horizontal_list">
      <div className="slider-container">
        <Slider {...cardSlidersettings}>
          {abs.map((item, index) => {
            return (
              <AbCard
                index={index}
                ab={item}
                key={item.feedId}
                onVoteSuccess={onVoteSuccess}
                actSectionCode={actSectionCode}
                isFirst={index === 0}
              />
            );
          })}
        </Slider>
      </div>
      {/* {abs.map((item, index) => {
        return (
          <AbCard
            index={index}
            ab={item}
            key={item.feedId}
            onVoteSuccess={onVoteSuccess}
            actSectionCode={actSectionCode}
            isFirst={index === 0}
          />
        );
      })} */}
    </div>
  );
};

export default HorizontalAbList;
