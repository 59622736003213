import { ApplePerformResponse } from './applelogin/applelogin'
import { AppleCredentialState } from './applelogin/constants'
import { Platform, PlatformTypes } from './platform'
import { ToastParams } from './toast'

class Native {
  /**
   * 애플 로그인 (iOS Only)
   *
   * @returns @see ApplePerformResponse
   */
  requestAppleLogin(): Promise<ApplePerformResponse> {
    return callNative('requestAppleLogin')
  }
  /**
   * 애플로그인 인증상태
   * @param userId @see ApplePerformResponse.user
   * @returns
   */
  getAppleCredentialStateForUser(
    userId: string
  ): Promise<AppleCredentialState> {
    return callNative('getAppleCredentialStateForUser', { userId })
  }
  /**
   *
   * @returns 앱에서 생성된 푸시토큰
   */
  getPushToken(): Promise<string> {
    return callNative('getPushToken')
  }
  /**
   * 토스트 표시
   * @param params @see {ToastParams}
   * @returns
   */
  showToast(params: ToastParams): Promise<void> {
    return callNative('showToast', params)
  }
  /**
   * 앱 버전명
   * @returns 앱 버전 (versionName)
   */
  getAppVersion(): Promise<string> {
    return callNative('getAppVersion')
  }
  /**
   * 런타임 권한 요청 (android only)
   * @param name 요청할 권한 이름 (@see https://developer.android.com/reference/android/Manifest.permission)
   * @returns 동의여부
   */
  requestPermission(name: string): Promise<boolean> {
    return callNative('requestPermission', { name })
  }
  /**
   * 권한 승인여부 (android only)
   * @param names 요청할 권한 이름 (@see https://developer.android.com/reference/android/Manifest.permission)
   * @returns
   *  name: 권한 이름
   *  result: 동의여부
   */
  checkPermissions(
    names: [string]
  ): Promise<{ name: string; result: boolean }[]> {
    return new Promise<{ name: string; result: boolean }[]>(
      (resolve, reject) => {
        void callNative('checkPermissions', { names })
          .then((result: string) => {
            const resultArray = JSON.parse(result)
            const mappedResult = resultArray.map(
              (item: { name: string; result: number }) => {
                return {
                  name: item.name,
                  result: item.result === 0 ? true : false,
                }
              }
            )
            resolve(mappedResult)
          })
          .catch(reject)
      }
    )
  }

  /**
   *
   * @returns 마지막으로 클릭한 알림 정보
   */
  lastClickedNotification(): Promise<any> {
    return callNative('lastClickedNotification')
  }

  /**
   *
   * @returns 마지막으로 클릭한 알림 정보
   */
  lastReceivedNotification(): Promise<any> {
    return callNative('lastReceivedNotification')
  }

  /**
   *
   * @returns 마지막으로 클릭한 딥링크 정보
   */
  currentDeeplink(): Promise<any> {
    return callNative('currentDeeplinkData')
  }
}

interface AndroidInterface {
  runAsync(funcName: string, callbackName: string, params: string): void
  runAsyncResult(callbackName: string): string
}
export const android: AndroidInterface = (window as any).android
export const native: Native = new Native()

/**
 *
 * @param funcName 메서드 이름
 * @param params 형식을 미리 알수 없으므로 항상 object 타입으로 보내야 함
 * @returns
 */
export async function callAndroidAsync(funcName: string, params: any = {}) {
  const rand = 'asyncJava_' + Math.floor(Math.random() * 1000000)

  window.androidCallbacks[rand] = {
    callback: (isSuccess: boolean) => {
      const dataOrErr = android.runAsyncResult(rand)
      if (isSuccess) window.androidCallbacks[rand].resolve(dataOrErr)
      else window.androidCallbacks[rand].reject(dataOrErr)
      delete window.androidCallbacks[rand] // clean up
    },
  }
  const promise = new Promise<any>((resolve, reject) => {
    window.androidCallbacks[rand].resolve = (data: any) => resolve(data)
    window.androidCallbacks[rand].reject = (err: any) => reject(err)
  })

  android.runAsync(rand, funcName, JSON.stringify(params))
  return promise
}

/**
 *
 * @param funcName 메서드 이름
 * @param params 형식을 미리 알수가 없으므로 항상 object 타입으로 보내야 함
 * @returns
 */
export async function callIosAsync(funcName: string, params?: any) {
  const webkit = (window as any).webkit
  const messageHandlers = webkit.messageHandlers
  const ios = messageHandlers.ios
  return ios.postMessage({
    name: funcName,
    params: params,
  })
}

export async function callNative(funcName: string, params?: any): Promise<any> {
  switch (Platform.OS) {
    case PlatformTypes.ANDROID_APP:
      return callAndroidAsync(funcName, params)
    case PlatformTypes.IOS_APP:
      return callIosAsync(funcName, params)
    default:
      break
  }
}
