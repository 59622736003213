import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { Arranges, Colors, Spacings, Texts } from "../../../assets/styles";

import { observer } from "mobx-react";
import { FeedTypeCode, FEED_CALL_STATE } from "../../../constants/Feed";
import { PostLocalSave } from "../../../store/PostWriteStore";
import { POST_TEMP_DATA } from "../../../constants/Storage";
import { in60Days } from "../../../utils/datetime";
import { NotificationModal } from "../../../components/modals";
import CommonStore from "../../../store/CommonStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { Styles } from "../../../assets/types/Style";
import "./EmptyFeed.scss";

const EmptyFeed = observer(
  ({
    type,
    tagName,
    dataRefresh,
  }: {
    type: FeedTypeCode;
    tagName: string;
    dataRefresh: () => void;
  }) => {
    const { t } = useTranslation();
    const [feedType, setFeedType] = useState("");
    const [showPostPopup, setShowPostPopup] = useState(false);
    const [postData, setPostData] = useState({} as PostLocalSave | null);

    const checkSavedPostThenMove = () => {
      // TODO : Post 작성 반영되면 indexedDB에서 받아오도록 수정
    //   setPostData(null);
    //   const result = localStorage.getItem(POST_TEMP_DATA);
    //   if (result) {
    //     const storedData: PostLocalSave = JSON.parse(result); // 캐시에서 데이터 바다옴
    //     const postTempDate = storedData.date;
    //     if (
    //       postTempDate != null &&
    //       postTempDate !== undefined &&
    //       in60Days(postTempDate)
    //     ) {
    //       setPostData(storedData);
    //       moveToWritePost(storedData);
    //     } else {
    //       moveToWritePost();
    //     }
    //   } else {
    //     moveToWritePost();
    //   }
      moveToWritePost();
    };

    const moveToWritePost = (data?: PostLocalSave) => {
      // data
      //   ? setShowPostPopup(true)
      //   : goTo("/post/write", {
      //       state: {
      //         data: null,
      //         onLeave: (result: boolean) => {
      //           if (result) dataRefresh();
      //         },
      //         callState: FEED_CALL_STATE.TAG,
      //       },
      //     });
    };

    const moveToWrite = () => {
      CommonStore.setShowDownloadAppDrive(true);
      // switch (type) {
      //   case FeedTypeCode.POST:
      //     checkSavedPostThenMove();
      //     break;
      //   case FeedTypeCode.QNA:
      //     goTo("/QnA/write", {
      //       state: {
      //         onLeave: (result: boolean) => {
      //           if (result) dataRefresh();
      //         },
      //       },
      //     });
      //     break;
      //   case FeedTypeCode.AB:
      //     goTo("/AB/write", {
      //       state: {
      //         onLeave: (result: boolean) => {
      //           if (result) dataRefresh();
      //         },
      //       },
      //     });
      //     break;
      //   default:
      //     break;
      // }
    };

    useEffect(() => {
      switch (type) {
        case FeedTypeCode.POST:
          setFeedType(t("screen.tag.label.post"));
          break;
        case FeedTypeCode.QNA:
          setFeedType(t("screen.tag.label.qna"));
          break;
        case FeedTypeCode.AB:
          setFeedType(t("screen.tag.label.ab"));
          break;
        default:
          setFeedType(t("screen.tag.label.post"));
          break;
      }
    }, [type, t]);

    const emptyDataStyle = useMemo(() => {
      switch (type) {
        //스타일도 POST QNA AB 별로 다르게 주실 때 사용하세용
        case FeedTypeCode.QNA:
        case FeedTypeCode.AB:
          return { ...styles.empty_data, width: 413 };
        case FeedTypeCode.POST:
        default:
          return styles.empty_data;
      }
    }, [type]);

    const emptyDataBackgroundSource = useMemo(() => {
      switch (type) {
        case FeedTypeCode.QNA:
          return require("../../../assets/images/empty_qna_list.png");
        case FeedTypeCode.AB:
          return require("../../../assets/images/empty_ab_list2.png");
        case FeedTypeCode.POST:
        default:
          return require("../../../assets/images/empty_post_list.png");
      }
    }, [type]);

    return (
      <div className="tag-empty-wrap">
        <div className="tag-empty-inner" style={emptyDataStyle}>
          <img src={emptyDataBackgroundSource} className="empty-image" />
          <div className="empty-content">
            <span className="empty-text" aria-label="Creat first one">
              {t("screen.tag.message.create", {
                tagName: tagName,
                feedType: feedType,
              })
                .replace("&amp;", "&")
                .replace("&#x2F;", "/")}
            </span>
            <div
              className="empty-btn"
              style={styles.empty_btn}
              onClick={moveToWrite}
            >
              <span
                className="empty-btn-text"
                style={styles.empty_btn_text}
                aria-label="Create"
              >
                {t("screen.tag.button.moveWrite", { feedType: feedType })
                  .replace("&amp;", "&")
                  .replace("&#x2F;", "/")}
              </span>
            </div>
          </div>
        </div>
        {/* </ImageBackground> */}

        {showPostPopup && (
          <NotificationModal
            isVisible={showPostPopup}
            title={t("screen.post.title.loadPost")}
            contents1={t("screen.post.message.loadTempSaved")}
            useTwoButton={true}
            isBlack={true}
            defaultButtonText={t("screen.post.button.newOne")}
            extraButtonText={t("screen.post.button.load")}
            onClickDefaultButton={() => {
              setTimeout(() => {
                goTo("PostWriteScreen", { state: { data: null } });
                setShowPostPopup(false);
              }, 200);
            }}
            onClickExtraButton={() => {
              setTimeout(() => {
                goTo("PostWriteScreen", { state: { data: postData } });
                setShowPostPopup(false);
              }, 200);
            }}
            onRequestClose={() => {
              setShowPostPopup(false);
            }}
          />
        )}
      </div>
    );
  }
);

export default EmptyFeed;

const styles: Styles = {
  background_image: { width: "100%", height: "100%" },
  empty_wrap: {
    display: "flex",
    ...Arranges.center_v_h,
  },
  empty_data: {
    display: "flex",
    ...Arranges.center_v_h,
    maxWidth: 450,
  },
  empty: {
    ...Arranges.center_v_h,
    height: 167,
    position: "absolute",
    zIndex: 3,
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  empty_btn: {
    ...Spacings.border_radius_100,
    ...Spacings.margin_top_14,
    ...Arranges.center_v_h,
    ...Colors.border_color_be69c3,
    borderWidth: 1,
    height: 44,
    width: 157,
  },
  empty_btn_text: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
};
