import { Arranges, FlexBoxs, Spacings, Texts, Colors, Positions } from "../../assets/styles";
import { Styles } from "../../assets/types/Style";
import { ReactComponent as AngleIcon } from "../../assets/icons/new_back_arrow.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_black.svg";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import { ReactComponent as HomeIcon } from "../../assets/icons/gnb-home-32.svg";
import { ReactComponent as WriteIcon } from "../../assets/icons/icon_header_pencil.svg";
import WriteButtonStore from "../../store/WriteButtonStore";

export interface ArticleListHeaderProps {
  title: string;
  brandCommunityId: number;
  subscriptionStatus: boolean;
  isHome?: boolean;
  goBack?: () => void;
  onClickHome?: () => void;
  onSearch?: () => void;
  onWriteArticle?: () => void;
}

const ArticleListHeader = ({
  title,
  brandCommunityId,
  subscriptionStatus,
  isHome,
  goBack,
  onClickHome,
  onSearch,
  onWriteArticle,
}: ArticleListHeaderProps) => {
  const { header, left, center, title_txt, right, between_header, search_btn, write_btn } = styles;

  const toggleScreen = () => {
    if (goBack) {
      goBack();
    } else {
      goTo("/Search");
    }
  };

  const moveToSearch = () => {
    if (onSearch) {
      onSearch();
    } else {
      goTo("/BrandCommunityArticleSearch");
    }
  };

  const moveToHome = () => {
    if (onClickHome !== undefined) onClickHome();
    else {
      goTo("/");
    }
  };

  const moveToWriteArticle = () => {
    if (onWriteArticle !== undefined) onWriteArticle();
    else {
      WriteButtonStore.ClickWriteButton({
        action: () => {
          goTo("/ArticleWrite", {
            state: {
              communityId: brandCommunityId,
              subscriptionYn: subscriptionStatus ? "Y" : "N",
            }
          });
        },
      });
    }
  };

  return (
    <div style={{ ...header, ...between_header }} className="common-header-system">
      <div style={center}>
        <div>
          <p style={title_txt}>
            {title.length > 12 ? title.substring(0, 10).concat("...") : title}
          </p>
        </div>
      </div>
      <div style={left}>
        <div onClick={toggleScreen}>
          {<AngleIcon />}
        </div>
        {isHome && (
          <div onClick={moveToHome}>
            <HomeIcon />
          </div>
        )}
      </div>
      <div style={right}>
        <div onClick={moveToSearch} style={search_btn}>
          <SearchIcon />
        </div>
        <div onClick={moveToWriteArticle} style={write_btn}>
          <WriteIcon />
        </div>
      </div>
    </div>
  );
};

const styles: Styles = {
  header: {
    height: 60,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_top_bottom_11,
    ...Arranges.center_h,
    ...Spacings.padding_right_left_16,
    ...Colors.background_fff,
    ...Positions.zIndex_top,
  },
  between_header: {
    ...FlexBoxs.flex,
    ...Arranges.between,
  },
  left: {
    width: 78,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  center: {
    position: "absolute",
    left: 80,
    right: 80,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  title_txt: {
    ...Texts.header_text_0,
  },
  right: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  search_btn: {
    ...Spacings.margin_right_6,
  },
  write_btn: {
    ...Spacings.margin_top_4,
  },
};

export default ArticleListHeader;
