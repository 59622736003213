import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { voteAb } from "../../../api/ab/api";
import { AbDetail, AbVoteRequest, AbVoteResult } from "../../../api/ab/model";
import { AbTypeCode } from "../../../constants/ab";
import CommonStore from "../../../store/CommonStore";
import { isPast } from "../../../utils/datetime";

type AbStatus = "NOT_VOTED" | "ALREADY_VOTED" | "CURRENTLY_VOTED" | "EXPIRED";
const useAb = (
  ab: AbDetail,
  onVoteSuccess?: (res: AbVoteResult, type: AbTypeCode) => void
) => {
  const [myChoice, setMyChoice] = useState<string | null>(ab.myAbFlag);
  const [voteCount, setVoteCount] = useState<AbVoteResult>({ ...ab });

  const status = useMemo(() => {
    const expired = isPast(ab.feedEndDate);
    const alreadVoted = ab.myAbFlag !== null;
    const currentlyVoted = myChoice !== null && !alreadVoted;
    let status = "NOT_VOTED";
    if (expired) {
      status = "EXPIRED";
    } else if (alreadVoted) {
      status = "ALREAD_VOTED";
    } else if (currentlyVoted) {
      status = "CURRENTLY_VOTED";
    }
    return status as AbStatus;
  }, [ab.myAbFlag, ab.feedEndDate, myChoice]);

  const totalVoteCount = voteCount.avoteCount + voteCount.bvoteCount;
  const aVoteCount = voteCount.avoteCount;
  const percentOfA =
    totalVoteCount > 0 ? (aVoteCount / totalVoteCount) * 100 : 50;
  const winner = percentOfA > 50 ? AbTypeCode.A : AbTypeCode.B;
  const resultOpened = status !== "NOT_VOTED";

  const handleAbPress = async (type: AbTypeCode) => {
    if (isPast(ab.feedEndDate)) {
      CommonStore.setToastOption({
        show: true,
        message1: t("screen.ab.message.alreadyExpired"),
      });
    } else {
      const request: AbVoteRequest = {
        abFlag: type,
        voteTargetId: ab.feedId,
      };

      const res = await voteAb(request);

      if (res && res.successOrNot === "Y" && res.data) {
        setMyChoice(type.toString());
        setVoteCount(res.data);
        onVoteSuccess && onVoteSuccess(res.data, type);
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("screen.ab.label.voteFail"),
        });
      }
    }
  };

  useEffect(() => {
    setVoteCount({ avoteCount: ab.avoteCount, bvoteCount: ab.bvoteCount });
  }, [ab.avoteCount, ab.bvoteCount]);

  useEffect(() => {
    setMyChoice(ab.myAbFlag);
  }, [ab.myAbFlag]);

  return {
    totalVoteCount,
    percentOfA,
    aVoteCount,
    status,
    winner,
    myChoice,
    handleAbPress,
    resultOpened,
  };
};

export default useAb;
