import CryptoJS from "crypto-js";

const seedKey = process.env.REACT_APP_ENCRYPT_KEY || "";

/* 암호화 */
export const encrypt = (text: string) => {
  try {
    const key = CryptoJS.enc.Utf8.parse(seedKey);
    const encrypted = CryptoJS.AES.encrypt(text, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Iso10126,
    });
    return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  } catch (error) {
    return text;
  }
};

/* 복호화 */
export const decrypt = (text: string) => {
  try {
    const key = CryptoJS.enc.Utf8.parse(seedKey);
    const decrypted = CryptoJS.AES.decrypt(
      {
        ciphertext: CryptoJS.enc.Base64.parse(text),
      } as any,
      key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Iso10126,
      }
    );
    return CryptoJS.enc.Utf8.stringify(decrypted);
  } catch (error) {
    return text;
  }
};
