import React, { useCallback, useEffect, useState } from "react";
import { TitleHeader } from "../../../components/common";
import "./BookmarkMain.scss";

import BookmarkFeed from "./BookmarkFeed";
import { FeedTypeCode } from "../../../constants/Feed";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import BookmarkStore from "../../../store/BookmarkStore";
import RemoteConfigStore from "../../../store/RemoteConfigStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";

const BookmarkMain = observer(() => {
  const {
    currentTab,
    clickEachTab,
    getCanLoadMoreByType,

    getCurrentPageByType,
    isLoadingMore,
    setIsLoadingMore,
    loadMoreBookmarkPosts,
  } = BookmarkStore;
  const { t } = useTranslation();
  const { show_brand_page } = RemoteConfigStore;

  const tabData =
    show_brand_page === "true"
      ? [
          {
            tabName: t("screen.bookmark.label.post"),
            value: FeedTypeCode.POST,
          },
          {
            tabName: t("screen.bookmark.label.brandPage"),
            value: FeedTypeCode.ARTICLE,
          },
          { tabName: t("screen.bookmark.label.qna"), value: FeedTypeCode.QNA },
          { tabName: t("screen.bookmark.label.ab"), value: FeedTypeCode.AB },
          {
            tabName: t("screen.bookmark.label.product"),
            value: FeedTypeCode.PRODUCT,
          },
        ]
      : [
          {
            tabName: t("screen.bookmark.label.post"),
            value: FeedTypeCode.POST,
          },
          { tabName: t("screen.bookmark.label.qna"), value: FeedTypeCode.QNA },
          { tabName: t("screen.bookmark.label.ab"), value: FeedTypeCode.AB },
          {
            tabName: t("screen.bookmark.label.product"),
            value: FeedTypeCode.PRODUCT,
          },
        ];

  const handleMoreRequest = async () => {
    if (
      !isLoadingMore &&
      getCanLoadMoreByType(currentTab) &&
      getCurrentPageByType(currentTab) > 0
    ) {
      setIsLoadingMore(true);
      await loadMoreBookmarkPosts(currentTab);
      setIsLoadingMore(false);
    }
  };

  return (
    <div id="bookmark_main">
      <TitleHeader title={t("screen.bookmark.label.title")} isHome />
      <div className="header_tab">
        {tabData.map((item, index) => (
          <div
            key={index}
            className={
              currentTab === item.value ? "tab active" : "tab inactive"
            }
            onClick={() => {
              clickEachTab(item.value);
              window.scrollTo(0, 0);
            }}
            aria-label={"Click ".concat(item.tabName).concat(" tab")}
          >
            <p
              className={
                currentTab === item.value
                  ? "tab_active_text"
                  : "tab_inactive_text"
              }
            >
              {item.tabName}
            </p>
          </div>
        ))}
      </div>
      <BookmarkFeed feedTypeCode={currentTab} />
      {isLoadingMore && <CustomActivityIndicatorSmall />}
      <InfiniteScrollObserver onObserver={handleMoreRequest} />
    </div>
  );
});
export default BookmarkMain;
