import { useCallback, useEffect, useRef } from "react";
import {
  Arranges,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { Styles } from "../../../assets/types/Style";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyImg } from "../../../assets/icons/empty_appliances.svg";
import MyAppliancesStore from "../../../store/MyAppliancesStore";
// import { handleTopButtonScroll } from "../../../utils/common";
import { MyAppliancesItem } from "./MyAppliancesItem";
import { MyAppliancesItemForProduct } from "./MyAppliancesItemForProduct";
// import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";
import { Virtuoso } from "react-virtuoso";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";

const MyAppliancesFlatList = observer(
  ({
    from,
    mode,
    tempSize,
  }: {
    from: string;
    mode: string;
    tempSize: number;
  }) => {
    const { t } = useTranslation();
    const ref = useRef(null);

    const { editNum, handleTotalCheck } = MyAppliancesStore;

    let checkedOptionData = MyAppliancesStore.myAppliancesList && MyAppliancesStore.myAppliancesList.map((data) => data.isSelected)

    useEffectOnlyOnce(() => {
      return () => {
        handleTotalCheck(false);
      };
    });

    const renderItem = useCallback(
      (item: any, index: number) => {        
        if (from === "product") {
          return (
            <MyAppliancesItemForProduct
              item={item}
              mode={mode}
              index={index}
              tempSize={tempSize}
            />
          );
        } else {
          return (
            <MyAppliancesItem
              item={item}
              index={index}
              mode={mode}
              isRadioSelected={mode === "del" ? (checkedOptionData && checkedOptionData[index]) === true : editNum === item.idx}
            />
          );
        }
      },
      [from, mode, editNum, tempSize, checkedOptionData]
    );

    const renderEmpty = () => {
      return (
        <div style={styles.empty}>
          <div style={{ ...FlexBoxs.flex, ...Arranges.center_v_h, position: 'relative', width: '100%' }}>
            <EmptyImg />
            <div style={styles.empty_view}>
              <p style={styles.empty_text}>{t("screen.productAdd.message.emptyMyAppliances")}</p>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "column",
          rowGap: 4,
          backgroundColor: 'white'
        }}
        ref={ref}
        // aria-label="Product search result list"
      >
        <Virtuoso
          useWindowScroll
          data={MyAppliancesStore.myAppliancesList}
          itemContent={(index, item) => {
            return (
              <div key={index}>
                {renderItem(item, index)}
              </div>
            );
          }}
          components={{
            EmptyPlaceholder: () => (<>{renderEmpty()} </>),
          }}
          endReached={() =>{
            if (
              MyAppliancesStore.myAppliancesList &&
              MyAppliancesStore.myAppliancesList?.length < MyAppliancesStore.totalCount
            ) {              
              void MyAppliancesStore.getMyProduct(true);
            }
          }}
        />          
      </div>
    );
  }
);

export default MyAppliancesFlatList;

const styles : Styles = {
  product_list: {
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_bottom_10,
  },
  empty: {
    // ...Spacings.margin_top_8,
    ...FlexBoxs.flex,
    //...FlexBoxs.flex_1,
    ...Arranges.center_v_h,
  },
  empty_image: {
    width: '95%',
  },
  empty_view: {
    ...Positions.absolute,
    ...Positions.left,
    ...Positions.right,
    // top: '21.9%',
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    width: '100%',
    height: '100%'
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
};
