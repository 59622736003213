import React, { useCallback, useEffect, useState } from 'react'
import { TermsResult } from '../../api/terms/model'
import CustomBottomSheet from '../common/CustomBottomSheet'
import { TermsLevelCode } from '../../constants/Terms.enum'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow_right_gray.svg'
import { ReactComponent as CheckboxOffIcon } from '../../assets/icons/checkbox_off.svg'
import { ReactComponent as CheckboxOnIcon } from '../../assets/icons/checkbox_on.svg'
import { flex_1 } from '../../assets/styles/flexBoxs'
import CustomCheckBox from '../common/CustomCheckBox'
import classes from './TermsAgreeBottomSheet.module.scss'

type CheckStateItem = {
  termsId: number
  termsAgreeYn: string
  termsLevelCode: string
}

const TermsAgreeBottomSheet = ({
  open,
  contents,
  contents2,
  terms,
  onClose,
  onConfirm, // 이 데이터를 백엔드에 넘겨주면 됨
  onTermsClick,
  onTermsCheck,
}: {
  open: boolean
  contents: string
  contents2?: string
  terms?: TermsResult[]
  onClose: () => void
  onConfirm: (result?: { termsId: number; termsAgreeYn: string }[]) => void
  onTermsClick?: (term: TermsResult) => void
  onTermsCheck?: (index: number, checked: boolean) => void
}) => {
  const [isAgreeAll, setAgreeAll] = useState(false)
  const [canConfirm, setCanConfirm] = useState(false)
  const [checkState, setCheckState] = useState<CheckStateItem[]>()

  const refreshConfirmState = useCallback(() => {
    if (!checkState) {
      setCanConfirm(false)
      setAgreeAll(false)
      return
    }

    const isAgreeAllNew = checkState.every(
      (state) => state.termsAgreeYn === 'Y'
    )
    const canConfirmNew = checkState.every((state) =>
      state.termsLevelCode === TermsLevelCode.MANDATORY
        ? state.termsAgreeYn === 'Y'
        : true
    )

    setCanConfirm(canConfirmNew)
    setAgreeAll(isAgreeAllNew)
  }, [checkState])

  const clickAgreeAll = useCallback(() => {
    if (!checkState) {
      setCanConfirm(false)
      setAgreeAll(false)
      return
    }

    const termsAgreeYn = isAgreeAll ? 'N' : 'Y'
    const checkStateNew: CheckStateItem[] = checkState.flatMap((term) => {
      return { ...term, termsAgreeYn }
    })
    setCheckState(checkStateNew)
    setCanConfirm(!isAgreeAll)
    setAgreeAll(!isAgreeAll)
  }, [checkState, isAgreeAll])

  /* eslint-disable */
  useEffect(() => {
    const state = terms?.flatMap((term) => {
      return {
        termsId: parseInt(term.termsId),
        termsAgreeYn: 'N',
        termsLevelCode: term.termsLevelCode,
      }
    })
    setCheckState(state)
    refreshConfirmState()
  }, [terms])
  /* eslint-enable */

  const renderChildren = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={classes.close_btn} onClick={onClose}>
          <CloseIcon />
        </div>

        <text
          style={{
            marginTop: 40,
            fontSize: 20,
            fontWeight: 'bold',
            textAlign: 'center',
            color: '#222',
            letterSpacing: -0.8,
            marginBottom: 4,
          }}
        >
          {contents}
        </text>
        <text
          style={{
            fontSize: 16,
            letterSpacing: -0.64,
            textAlign: 'center',
            color: '#222',
            marginBottom: 24,
          }}
        >
          {contents2}
        </text>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 14,
            paddingTop: 16,
            paddingBottom: 16,
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 16,
            marginRight: 16,
            borderRadius: 10,
            backgroundColor: '#f5f5f5',
          }}
          onClick={clickAgreeAll}
        >
          <div style={{ marginBottom: -12, marginRight: 4 }}>
            {isAgreeAll ? <CheckboxOnIcon /> : <CheckboxOffIcon />}
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <text
                style={{
                  fontSize: 16,
                  color: '#222',
                  fontWeight: 'bold',
                  letterSpacing: -0.64,
                }}
              >{`모두 동의합니다.`}</text>
              <div style={flex_1}></div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginLeft: 20,
            marginRight: 20,
            marginBottom: 16,
            paddingTop: 16,
            paddingRight: 14,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {terms?.map((term, index) => {
            return (
              <div
                key={term.termsId}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  paddingLeft: 14,
                  marginBottom: 10,
                }}
                onClick={() => {
                  let currentState = false
                  if (checkState) {
                    currentState = checkState[index].termsAgreeYn === 'Y'

                    checkState[index].termsAgreeYn = currentState ? 'N' : 'Y'
                    setCheckState([...checkState])
                    refreshConfirmState()
                  }
                  onTermsCheck?.(index, !currentState)
                }}
              >
                <div style={{ marginBottom: -12, marginRight: 10 }}>
                  {checkState?.[index].termsAgreeYn === 'Y' ? (
                    <CheckboxOnIcon />
                  ) : (
                    <CheckboxOffIcon />
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{ display: 'flex', flex: 1, flexDirection: 'row' }}
                  >
                    <text
                      style={{
                        fontSize: 14,
                        color: '#222',
                        letterSpacing: -0.56,
                        fontWeight: '500',
                      }}
                    >{`${term.termsTitle}`}</text>
                    <text
                      style={{
                        fontSize: 14,
                        letterSpacing: -0.56,
                        fontWeight: '500',
                        color: '#6c2773',
                        marginRight: 10,
                      }}
                    >{`(${
                      term.termsLevelCode === TermsLevelCode.MANDATORY
                        ? '필수'
                        : '선택'
                    })`}</text>
                  </div>
                  <div
                    style={{ marginLeft: 'auto' }}
                    onClick={(event) => {
                      event.stopPropagation()
                      onTermsClick?.(term)
                    }}
                  >
                    <ArrowRightIcon />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <text
          style={{
            marginBottom: 24,
            fontSize: 14,
            color: '#999999',
            textAlign: 'center',
          }}
        >
          {'(필수) 약관의 경우 미동의시 서비스 이용이 불가합니다.'}
        </text>
        <div
          className={
            canConfirm ? classes.confirm_btn : classes.confirm_btn_disable
          }
          style={{ height: 54 }}
          onClick={() => {
            canConfirm && onConfirm?.(checkState)
          }}
        >
          <text
            className={
              canConfirm
                ? classes.confirm_btn_text
                : classes.confirm_btn_disable_text
            }
          >
            {'동의하기'}
          </text>
        </div>
      </div>
    )
  }

  return (
    <CustomBottomSheet onClose={onClose} open={open}>
      {renderChildren()}
    </CustomBottomSheet>
  )
}

export default TermsAgreeBottomSheet
