import PreferenceHeader from "./PreferenceHeader";
import { Preference, PreferenceContent } from "../../../api/preference/model";
import { ReactComponent as CheckIcon } from "../../../assets/icons/yellow_check.svg";
import { observer } from "mobx-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { PreferenceTypeCode } from "../../../constants/Preference.enum";
import { Virtuoso, VirtuosoGrid } from "react-virtuoso";
import { forwardRef, useState } from "react";
import classes from "./SeletablePreference.module.scss";
import clsx from "clsx";

export interface PreferenceProps {
  preference: Preference;
  selectedPreferenceCodes: Set<string>;
  onSelectPrefereceCode: (code: string) => void;
  currentStep: number;
  totalStepCount: number;
}
const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
const SeletablePreference = observer(
  ({
    preference,
    selectedPreferenceCodes,
    onSelectPrefereceCode,
    currentStep,
    totalStepCount,
  }: PreferenceProps) => {
    const MAX_SELECTION = preference?.max ?? 1;
    const [forceUpdate, setForceUpdate] = useState(false);

    const handleSelect = (item: string) => () => {
      setForceUpdate((x) => !x);
      onSelectPrefereceCode(item);
    };

    const renderItem = (index: number, item: PreferenceContent) => {
      return (
        <div className={classes.select_item} key={item.title}>
          <button
            className={classes.image}
            onClick={handleSelect(item.preferenceListCode)}
            disabled={
              selectedPreferenceCodes.size >= MAX_SELECTION &&
              !selectedPreferenceCodes.has(item.preferenceListCode)
            }
            aria-label={`${item.preferenceListCode}`}
          >
            <LazyLoadImage
              width={72}
              height={72}
              src={
                selectedPreferenceCodes.has(item.preferenceListCode) &&
                item.selectedFilePath
                  ? `${PUBLIC_BUCKET_URL}${item.selectedFilePath}`
                  : `${PUBLIC_BUCKET_URL}${item.originalFilePath}`
              }
            />
            {selectedPreferenceCodes.has(item.preferenceListCode) && (
              <div className={classes.selected_inner}>
                <CheckIcon />
              </div>
            )}
          </button>
          {preference.type === PreferenceTypeCode.HOME_APPLIANCE && (
            <span className={classes.desc_text}>{item.description}</span>
          )}
          <span
            className={clsx(
              classes.select_item_text,
              preference.type === PreferenceTypeCode.HOME_APPLIANCE &&
                classes.select_item_text_2
            )}
          >
            #{item.title}
          </span>
        </div>
      );
    };

    return (
      <>
        <div
          // style={{ height: window.innerHeight }}
          className={classes.basic_info}
          aria-label={preference.type}
        >
          <PreferenceHeader
            currentStep={currentStep}
            totalStep={totalStepCount}
            title1={preference?.title || ""}
            maxCount={MAX_SELECTION}
          />
          <div className={classes.select_items_wrap}>
            {preference?.contents.slice().length > 0 &&
              preference?.contents
                .slice()
                .map((item, index) => (
                  <div className={classes.item_wrap}>
                    {renderItem(index, item)}
                  </div>
                ))}
          </div>
        </div>
      </>
    )
  }
);

export default SeletablePreference;
