import React, { useState } from "react";
import { observer } from "mobx-react";

import { TitleHeader } from "../../../components/common";

import { FollowersTab } from "../../../constants/Account";
import { Followers, Followings } from "./";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { deleteFollow } from "../../../api/follow/api";
import { FollowerItem } from "../../../api/follow/model";
import CustomBottomSheet from "../../../components/common/CustomBottomSheet";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { useTranslation } from "react-i18next";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import "./FollowersMain.scss";

const FollowersMain = observer(
  ({
    userId,
    userNickname,
    tabType,
    isMy,
  }: {
    userId: string;
    userNickname: string;
    tabType: FollowersTab;
    isMy: boolean;
  }) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(
      tabType == "FOLLOWING" ? 1 : 0
    );
    const { t } = useTranslation();

    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const [selectedDeleteUser, setDeleteUser] = useState<any>();
    const [deletedUser, setDeletedUser] = useState<any>();
    const [showBottom, setShowBottom] = useState(false);

    const onBottomSheet = (item: any) => {
      setDeleteUser(item);
      setShowBottom(true);
    };
    const onCloseBottomSheet = () => {
      setShowBottom(false);
    };

    const deleteFollower = async () => {
      onCloseBottomSheet();
      if (selectedDeleteUser !== undefined) {
        const res = await deleteFollow(selectedDeleteUser?.memberUuid || "");
        if (res) {
          setDeletedUser(selectedDeleteUser);
          setDeleteUser(null);
        }
      }
    };

    const tabs = [
      {
        name: FollowersTab.FOLLOWERS,
        needAuth: false,
        component: (
          <Followers
            isMy={isMy}
            userId={userId}
            onClickDelete={(item: any) => onBottomSheet(item)}
            deletedUser={deletedUser}
          />
        ),
      },
      {
        name: FollowersTab.FOLLOWINGS,
        needAuth: true,
        component: <Followings isMy={isMy} userId={userId} />,
      },
    ];

    return (
      <>
        <div
          className="followers-main"
          style={{ flex: 1, backgroundColor: "#fff" }}
        >
          <div className="follower_manage">
            <TitleHeader
              title={`${userNickname}`}
              style={{ top: 0, position: "fixed" }}
              isHome
            />
            <div className="tabs">
              {tabs.map((tab: { name: string; needAuth: boolean }, index) => {
                const isFocused = currentTabIndex === index;

                const handleTabBarPress = () => {
                  setCurrentTabIndex(index);
                };

                return tab.needAuth ? (
                  <TouchableWithAuthCheck
                    key={index}
                    className={isFocused ? "tab_active_btn" : "tab_btn"}
                    onPress={handleTabBarPress}
                    aria-label="MyAbList"
                  >
                    <p
                      className={isFocused ? "tab_active_btn_text" : "tab_text"}
                    >
                      {t(`screen.myHome.label.${tab.name.toLowerCase()}`)}
                    </p>
                  </TouchableWithAuthCheck>
                ) : (
                  <button
                    key={index}
                    className={isFocused ? "tab_active_btn" : "tab_btn"}
                    onClick={handleTabBarPress}
                    aria-label="AbList"
                  >
                    <p
                      className={isFocused ? "tab_active_btn_text" : "tab_text"}
                    >
                      {t(`screen.myHome.label.${tab.name.toLowerCase()}`)}
                    </p>
                  </button>
                );
              })}
            </div>
            {tabs[currentTabIndex].component}
          </div>
        </div>
        <CustomBottomSheet
          open={showBottom}
          onClose={onCloseBottomSheet}
          snapHeight={366}
          modalContentStyles={{
            padding: "0",
            height: "auto",
          }}
          // handleComponent={() => <></>}
          // bottomInset={0}
        >
          <div className="followers-main-sheet">
            <div className="close_btn" onClick={onCloseBottomSheet}>
              <CloseIcon />
            </div>

            <div className="delete_image">
              <FastImageWithFallback
                className="profile_image"
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 50,
                  objectFit: "cover",
                }}
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${
                    selectedDeleteUser?.profileUrl || ""
                  }`,
                }}
              />{" "}
            </div>

            <div className="top_text">
              <p className="delete_text_1" aria-label="Delete notice1">
                {t("screen.followers.message.delete1", {
                  nickname: selectedDeleteUser?.nickname,
                })}
              </p>
            </div>

            <div className="bottom_text">
              <p className="delete_text_2" aria-label="Delete notice2">
                {t("screen.followers.message.delete2", {
                  nickname: selectedDeleteUser?.nickname,
                })}
              </p>
            </div>

            <TouchableWithAsyncTask
              className="delete_btn"
              style={{ height: 65 }}
              onPress={deleteFollower}
            >
              <p className="save_btn_text" aria-label="Delete">
                {t("screen.followers.button.doDelete")}
              </p>
            </TouchableWithAsyncTask>
          </div>
        </CustomBottomSheet>
      </>
    );
  }
);

export default FollowersMain;
