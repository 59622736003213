import React, { forwardRef, useEffect, useRef, useState } from 'react'
import TitleHeader from '../../../components/common/TitleHeader'
import { HeaderItem } from '../../../constants/Header'
import { useTranslation } from 'react-i18next'
import LikeStore from '../../../store/LikeStore'
import { observer } from 'mobx-react'
import LikeItem from './LikeItem'
import { LikeTypeCode } from '../../../constants/Like'
import EmptyLikes from './EmptyLikes'
import { handleTopButtonScroll } from '../../../utils/common'
import { Virtuoso } from 'react-virtuoso'
import { useLocation, useParams } from 'react-router-dom'
import classes from './LikeList.module.scss'
import { goBack } from '../../../hooks/navigate/NavigateFunction'
import CustomActivityIndicatorSmall from '../../../components/common/CustomActivityIndicatorSmall'

const LikeList = observer(() => {
  const pageSize = 20
  const location = useLocation()
  const state = location.state
  const params = useParams()

  var feedType = params.feedType || state.feedType
  var feedId = params.feedId || state.feedId

  const { t } = useTranslation()
  const {
    likeList,
    isLoadingMore,
    canLoadMore,
    getLikeList,
    getLikeListMore,
    clear,
  } = LikeStore
  const [showTopButton, setShowTopButton] = useState<boolean>(false)
  const ref = useRef(null)
  const headerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    void getLikeList({
      likeTargetId: feedId || '0',
      likeTypeCode: feedType as LikeTypeCode,
      pageSize: pageSize,
    })
    return () => {
      clear()
    }
  }, [pageSize, getLikeList, clear, feedId, feedType])

  const renderFooter = () => {
    // return <></>
    return <CustomActivityIndicatorSmall isDarkSpinner={true} />
  }

  const clickBack = () => {
    goBack()
  }

  return (
    <div className={classes.like_list_wrapper}>
      <TitleHeader
        wrapperRef={headerRef}
        title={t('screen.like.label.title')}
        rightItem={HeaderItem.NONE}
        isBack={true}
        onClickBack={() => {
          clickBack()
        }}
        style={{
          position: 'fixed',
          display: 'flex',
          top: 0,
          zIndex: 99,
        }}
      />
      <Virtuoso
        className={classes.like_list}
        // style={{
        //   minHeight: '100dvh',
        // }}
        useWindowScroll
        ref={ref}
        aria-label="Like Item list"
        data={likeList || []}
        itemContent={(index, item) => {
          return <LikeItem likeItem={item} index={index} />
        }}
        endReached={() => {
          if (!isLoadingMore) {
            void getLikeListMore({
              likeTargetId: feedId || '0',
              likeTypeCode: feedType as LikeTypeCode,
              pageSize: pageSize,
            })
          }
        }}
        onScroll={(event) =>
          handleTopButtonScroll(
            event,
            () => {
              setShowTopButton(true)
            },
            () => {
              setShowTopButton(false)
            },
            ref
          )
        }
        components={{
          // Header: () => (
          //   <div style={{ height: headerRef.current?.clientHeight ?? 54 }} />
          // ),
          List: forwardRef(
            (
              { style, children, ...props },
              ref: React.LegacyRef<HTMLDivElement> | undefined
            ) => (
              <div
                ref={ref}
                {...props}
                style={{
                  ...style,
                  padding: '0 16px',
                }}
              >
                {children}
              </div>
            )
          ),
          Item: forwardRef(
            (
              { style, children, ...props },
              ref: React.LegacyRef<HTMLDivElement> | undefined
            ) => (
              <div
                ref={ref}
                {...props}
                style={{
                  ...style,
                  margin: 0,
                }}
              >
                {children}
                <div style={{ height: 20, backgroundColor: "transparent" }} />
              </div>
            )
          ),
          EmptyPlaceholder: () => <EmptyLikes />,
          Footer: () => {
            return canLoadMore && isLoadingMore ? (
              renderFooter()
            ) : (
              <div style={{ height: 1 }} />
            )
          },
        }}
      />
      {/* <TopButton showTopButton={showTopButton} ref={ref} /> */}
    </div>
  )
})

export default LikeList
