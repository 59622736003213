import { observer } from "mobx-react";
import { useState, useEffect, useRef } from "react";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import { Styles } from "../../../assets/types/Style";
import { Product } from "../../../api/product/model";
import { File } from "../../../api/file/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import VideoPlayerWithFallback from "../../../components/common/VideoPlayerWithFallback";
import ArticleDetailStore from "../../../store/ArticleDetailStore";

const ArticleDetailContents = observer(() => {
    const {
        user_qna_detail_body,
        image_list_wrap,
        image_item,
        video_item2,
        title_text,
        contents_wrap,
        contents_text,
        home_app_item,
        home_app_first_item,
        home_app_img,
        home_app_rep_img,
        home_app_info,
        home_app_text1,
        home_app_text2,
    } = styles;

    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

    const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";

    const mediaList: File[] = ArticleDetailStore.articleDetail.files as File[];

    const [carouselIdx, setCarouselIdx] = useState(0);
    const [audioMute, setAudioMute] = useState(true);

    // set object-fit for videos
    useEffect(() => {
        const videoTags = document.getElementsByTagName("video");
        if (videoTags && videoTags.length > 0) {
            for (const videoTag of videoTags) {
                if (!videoTag.getAttribute('style')?.includes('object-fit')) {
                    videoTag.setAttribute("style", videoTag.getAttribute('style') + 'object-fit: fill !important');
                }
            }
        }
    });

    const renderItem = ({ item, index }: { item: File; index: number }) => {
        if (item.fileType === "IMAGE") {
            return (
                <FastImageWithFallback
                    style={{ ...image_item}}
                    wrapperStyle={Spacings.padding_top_14}
                    source={{ uri: `${PUBLIC_BUCKET_URL}${item.filePath || ""}` }}
                    fallbackImageUri={FALLBACK_IMAGE_URL}
                />
            );
        } else if (item.fileType === "VIDEO") {
            return (
                <div>
                    <VideoPlayerWithFallback
                        style={{ ...video_item2 }}
                        sourceUri={PUBLIC_BUCKET_URL + encodeURI(item.filePath)}
                        defAudioMute={audioMute}
                        onMute={(mute: boolean) => {
                            setAudioMute(mute);
                        }}
                        disabled={false}
                        carouselIdx={index}
                        thumbnailurl={PUBLIC_BUCKET_URL + encodeURI(item.thumbnailFilePath as string)}
                        keystring={index.toString()}
                        // thumbnail={{ uri: PUBLIC_BUCKET_URL + encodeURI(item.thumbnailFilePath as string) }}
                        // poster={PUBLIC_BUCKET_URL + encodeURI(item.thumbnailFilePath as string)}
                        aria-label={`article detail video content-${carouselIdx}`}
                    />
                </div>
            );
        }
        return <></>;
    };

    const renderProductList = () => {
        return (
            ArticleDetailStore.articleDetail.productList &&
            ArticleDetailStore.articleDetail.productList.length > 0 &&
            ArticleDetailStore.articleDetail.productList.map((product: Product, index: number) => {
                return (
                    !product?.openApiFlag &&
                    product?.productId !== undefined &&
                    product?.productId > 0 && (
                        <button
                            style={{ ...home_app_item, ...home_app_first_item, width: '71.7%', marginBottom: 4 }}
                            key={product.productId}
                            onClick={() => {
                                goTo(`/Product/${product.productId}`);
                            }}
                        >
                            <FastImageWithFallback
                                style={{ ...home_app_img, ...(product?.thumbnailYn === "Y" ? home_app_rep_img : {}) }}
                                source={{ uri: `${PUBLIC_BUCKET_URL}${product.productFiles[0]?.thumbnailFilePath?.replace("THUMBNAIL", "ORIGINAL") || ""}` }}
                                fallbackImageUri={FALLBACK_IMAGE_URL}
                            />
                            <div style={home_app_info}>
                                <p style={home_app_text1}>
                                    [{product.brandNameKor}]{product.productName}
                                </p>
                                <p style={home_app_text2}>{product.productModelName}</p>
                            </div>
                        </button>
                    )
                );
            })
        );
    };

    return (
        <div style={user_qna_detail_body}>
            <p style={title_text}>{ArticleDetailStore.articleDetail.articleTitle}</p>

            <div style={contents_wrap}>
                {ArticleDetailStore.articleDetail.articleTypeCode === "NOTICE" ? (
                    <div
                        style={{ width: '100%' }}
                        dangerouslySetInnerHTML={{
                            __html: ArticleDetailStore.articleDetail.articleContents || "",
                        }}
                    />
                ) : (
                    <p style={contents_text}>{ArticleDetailStore.articleDetail.articleContents}</p>
                )}
            </div>

            {mediaList && mediaList.length > 0 && (
                <div style={image_list_wrap}>
                    {mediaList.map((item: File, index: number) => {
                        return (
                            <div key={item.fileId} style={{ marginBottom: 4 }}>
                                {renderItem({ item, index })}
                            </div>
                        );
                    })}
                </div>
            )}

            {renderProductList()}
        </div>
    );
});

export default ArticleDetailContents;

const styles: Styles = {
    user_qna_detail_body: {},
    image_list_wrap: {
        ...Spacings.margin_bottom_12,
    },
    image_item: {
        width: '100%',
        objectFit: "cover",
        maxHeight: 450
    },
    video_item: {
        ...FlexBoxs.flex,
        ...Arranges.end_h,
        ...Spacings.padding_top_14,
    },
    video_item2: {
        aspectRatio: 1,
        borderRadius: 6
    },
    carousel_pagination_container: {
        ...Positions.absolute,
        ...Positions.bottom,
        ...Positions.left,
        ...Positions.right,
        ...Spacings.padding_bottom_16,
    },
    carousel_pagination_active: {
        ...Spacings.border_radius_5,
        width: 8,
        height: 8,
        backgroundColor: "rgba(255, 255, 255, 0.92)",
    },
    carousel_pagination_inactive: {
        ...Spacings.border_radius_5,
        width: 8,
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.92)",
        opacity: 0.4,
    },

    title_text: {
        ...Spacings.padding_right_37,
        ...Spacings.padding_left_20,
        ...Spacings.margin_top_24,
        ...Texts.contents_text_40,
        ...Texts.font_weight_500,
        "overflow-wrap": "break-word",
    },
    contents_wrap: {
        ...Spacings.margin_top_20,
        ...Spacings.margin_bottom_24,
        ...Spacings.padding_right_left_20,
        "overflow-wrap": "break-word",
    },
    contents_text: {
        ...Texts.contents_text_28,
        ...Texts.font_weight_400,
    },
    home_app_list: {
        ...Spacings.margin_top_14,
    },
    home_app_item: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
    },
    home_app_first_item: {
        ...Spacings.padding_left_16,
    },
    home_app_img: {
        ...Spacings.margin_right_12,
        ...Spacings.border_radius_4,
        borderColor: "rgba(34, 34, 34, 0.1)",
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderStyle: 'solid',
        width: 60,
        height: 60,
    },
    home_app_rep_img: {
        ...Colors.border_color_be69c3,
    },
    home_app_info: {
        width: '100%',
        textAlign: 'start'
    },
    home_app_text1: {
        ...Texts.contents_text_3,
        ...Texts.font_weight_500,
        ...Spacings.margin_bottom_3,
    },
    home_app_text2: {
        ...Texts.contents_text_5,
        ...Colors.font_999,
    },
    tag_list: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...FlexBoxs.wrap,
        ...Spacings.padding_right_left_16,
        ...Spacings.margin_top_12,
    },
    tag_item: {
        ...Spacings.padding_right_left_12,
        ...Spacings.padding_top_bottom_8,
        ...Spacings.margin_right_6,
        ...Spacings.margin_bottom_6,
        ...Spacings.border_radius_32,
        ...Colors.background_f7f4fa,
    },
    tag_text: {
        ...Texts.contents_text_37,
        ...Colors.font_a760b1,
    },

    small_bar: {
        height: 1,
        ...Spacings.margin_left_right_16,
        ...Spacings.margin_top_16,
        ...Colors.background_eeeeee,
    },

    comment_input_wrap: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Spacings.padding_top_bottom_15,
        ...Spacings.padding_left_right_16,
        ...Colors.background_f5f5f5,
    },
    comment_input_inner_wrap: {
        ...Colors.background_fff,
        ...FlexBoxs.flex_1,
        ...Spacings.border_radius_6,
        ...Spacings.padding_top_bottom_6,
        ...Spacings.padding_right_left_12,
        ...Spacings.padding_right_6,
        ...Colors.border_color_fff,
        height: 40,

        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
    },
    input: {
        ...Texts.contents_text_3,
    },
    write_btn: {
        ...FlexBoxs.flex,
        ...Arranges.center_v_h,
        ...Spacings.border_radius_6,
        ...Spacings.padding_6,
        ...Spacings.margin_left_10,
        ...Colors.background_be69c3,
        height: 40,
        width: 78,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: "auto",
    },
    write_btn_text: {
        ...Texts.font_size_14,
        ...Texts.font_weight_500,
        ...Colors.font_fff,
    },
};
