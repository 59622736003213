import { BadgeCode } from "../../constants/Badge.enum";
import { callAPIWithGETMethod, callAPIWithPOSTMethod } from "../common/api";
import { BadgeItem, BadgeRequest } from "./model";

export const getBadges = async (badgeRequest: BadgeRequest, isLoading = true): Promise<BadgeItem[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/badge",
      params: badgeRequest,
    },
    isLoading
  );
  return (response?.successOrNot === "Y" ? response?.data : null) as BadgeItem[];
};

export const obtainBadge = async (badgeCode: BadgeCode, isLoading = true): Promise<BadgeItem> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: `/v1/badge/${badgeCode}`,
    },
    isLoading
  );
  return (response?.successOrNot === "Y" ? response?.data : false) as BadgeItem;
};
