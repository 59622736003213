import { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { CertificationCodeStatus } from '../../constants/Certification.enum'
import { ReactComponent as RightBlack } from '../../assets/icons/triangle_right_black.svg'
import { checkCertification } from '../../api/certification/api'
import EmailAuthStore, { EMPTY_STRING } from '../../store/EmailAuthStore'
import { useTranslation } from 'react-i18next'
import CommonStore from '../../store/CommonStore'
import classes from './EmailAuthSection.module.scss'

export const EmailAuthSection = observer(() => {
  const {
    inputEmailCertificationCode,
    timerStart,
    emailAuthCertificationId,
    emailCertificationCodeStatus,
    sendEmailAuthCode,
    setInputEmailCertificationCode,
    setTimerStart,
    setEmailCertificationCodeStatus,
    setIsEmailAuthSectionShow,
    setIsEmailAuthComplete,
  } = EmailAuthStore

  const { t } = useTranslation()

  const INITIAL_MINUTE_DISPLAY = '3'
  const INITIAL_SECOND_DISPLAY = '00'
  const AUTH_TIME_LIMIT = 180

  const authcodeRef = useRef(null)

  const time = useRef(AUTH_TIME_LIMIT)
  const timerId = useRef<any>()

  const [startTime, setStartTime] = useState<number>(0)
  const [expirationMinDisplay, setExpirationMinDisplay] = useState<string>(
    INITIAL_MINUTE_DISPLAY
  )
  const [expirationSecDisplay, setExpirationSecDisplay] = useState<string>(
    INITIAL_SECOND_DISPLAY
  )

  const checkAuthCode = async (code: string) => {
    const res = await checkCertification(emailAuthCertificationId, code)

    if (!res) {
      setTimerStart(false)
      alert(t('common.error.server'))
      return null
    } else if (res.successOrNot === 'Y' && res.data) {
      setTimerStart(false)
      setIsEmailAuthSectionShow(false)
      setIsEmailAuthComplete(true)

      setTimeout(() => {
        CommonStore.setToastOption({
          show: true,
          message1: t('screen.password.toast.emailAuthSuccess'),
        })
      }, 500)
      return EMPTY_STRING
    } else {
      switch (res.statusCode) {
        case CertificationCodeStatus.EXPIRED_CERTIFICATION_CODE:
          setEmailCertificationCodeStatus(
            CertificationCodeStatus.EXPIRED_CERTIFICATION_CODE
          )
          break
        case CertificationCodeStatus.INVALID_CERTIFICATION_CODE:
          setEmailCertificationCodeStatus(
            CertificationCodeStatus.INVALID_CERTIFICATION_CODE
          )
          break
        case CertificationCodeStatus.NO_CERTIFICATION_REQUEST:
        default:
          setEmailCertificationCodeStatus(
            CertificationCodeStatus.NO_CERTIFICATION_REQUEST
          )
          break
      }
      return res.statusCode
    }
  }

  const renderCheckAuthCode = () => {
    if (emailCertificationCodeStatus.length > 0 || time.current <= 0) {
      let status: 'expired' | 'invalid' | 'retry' = 'retry'

      if (
        emailCertificationCodeStatus ===
          CertificationCodeStatus.EXPIRED_CERTIFICATION_CODE ||
        time.current <= 0
      ) {
        status = 'expired'
      } else if (
        emailCertificationCodeStatus ===
        CertificationCodeStatus.INVALID_CERTIFICATION_CODE
      ) {
        status = 'invalid'
      }

      return (
        <div
          className={classes.auth_code_bottom}
          aria-label={'Check Authentication Code'}
        >
          <span className={classes.auth_code_bottom_text}>
            {t(`screen.password.emailAuth.${status}Code`)}
          </span>
        </div>
      )
    }
  }

  const resendAuthCode = () => {
    setStartTime(Date.now())
    time.current = AUTH_TIME_LIMIT
    CommonStore.setToastOption({
      show: true,
      message1: t('screen.password.toast.sendEmail'),
      message2: t('screen.password.toast.requestAuthCode'),
    })
    void sendEmailAuthCode()
  }

  useEffect(() => {
    time.current <= 0 && setTimerStart(false)
  }, [expirationSecDisplay, setTimerStart])

  useEffect(() => {
    if (timerStart) {
      setExpirationMinDisplay(INITIAL_MINUTE_DISPLAY)
      setExpirationSecDisplay(INITIAL_SECOND_DISPLAY)
      time.current = AUTH_TIME_LIMIT
      setStartTime(Date.now())
    } else {
      timerId.current && clearInterval(Number(timerId.current))
    }
  }, [timerStart])

  useEffect(() => {
    if (timerStart) {
      timerId.current = setInterval(() => {
        const diffSec = Math.floor((Date.now() - startTime) / 1000)
        const extraAuthSec = Math.max(
          0,
          (time.current = AUTH_TIME_LIMIT - diffSec)
        )
        setExpirationMinDisplay(Math.floor(extraAuthSec / 60).toString())
        setExpirationSecDisplay((extraAuthSec % 60).toString())
      }, 1000)
    } else {
      clearInterval(timerId.current as NodeJS.Timeout)
    }

    return () => {
      clearInterval(timerId.current as NodeJS.Timeout)
    }
  }, [startTime, timerStart])

  return (
    <div className={classes.auth_code_wrap}>
      <div className={classes.auth_code_top_text}>
        <span className={classes.auth_code_alert_text}>
          {t('screen.password.emailAuth.requestAuthCode')}
        </span>
      </div>
      <div className={classes.auth_code}>
        <div className={classes.auth_code_top}>
          <input
            // keyboardType="numeric"
            className={classes.auth_code_input}
            autoFocus={true}
            maxLength={6}
            ref={authcodeRef}
            value={inputEmailCertificationCode}
            onChange={(e) => {
              const text = e.target.value
              setInputEmailCertificationCode(text)
              if (text.length === 6) {
                void checkAuthCode(text).then((result) => {
                  if (result === '') {
                    // Keyboard.dismiss();
                  }
                })
              }
            }}
            aria-label={'Email Authentication Code'}
          />
          <span className={classes.timer_text}>
            {expirationMinDisplay}:{expirationSecDisplay.padStart(2, '0')}
          </span>
        </div>
        {renderCheckAuthCode()}
      </div>
      <div className={classes.auth_code_alert}>
        <RightBlack />
        <span className={classes.auth_code_alert_text}>
          {t('screen.password.emailAuth.emailFailTxt')}
        </span>
        <button
          className={classes.resend}
          onClick={() =>
            timerStart ? resendAuthCode() : void sendEmailAuthCode()
          }
        >
          <span className={classes.resend_text}>
            {t('screen.password.emailAuth.reSendEmail')}
          </span>
        </button>
      </div>
    </div>
  )
})

export default EmailAuthSection
