import { observer } from "mobx-react";
import { Styles } from "../../../assets/types/Style";
import { Colors, Texts } from "../../../assets/styles";
import { ProductPopupMenu, OptionType } from "../../../components/common/ProductPopupMenu";
import { SearchProductItem } from "../../../api/brand/model";

export interface BrandCommunityProductOptionBoxProps {
  index: number;
  product: SearchProductItem;
  onAction?: (type: OptionType, product: SearchProductItem, index: number) => void;
  onCheckDisabled?: (type: OptionType, product: SearchProductItem) => boolean | false;
}
const BrandCommunityProductOptionBox = observer(({ index, product, onAction, onCheckDisabled }: BrandCommunityProductOptionBoxProps) => {

  const optionTypesToShow = () => {
    const optionList: OptionType[] = [];

    optionList.push("WRITE_ARTICLE");
    optionList.push("WRITE_POST");
    optionList.push("WRITE_QNA");
    optionList.push("REGIST_MYAPPLIANCE");
    optionList.push("BUY_PRODUCT");

    return optionList;
  };

  const handleAction = (type: OptionType) => {
    onAction && onAction(type, product, index);
  };

  const handleCheck = (type: OptionType) => {
    return onCheckDisabled ? onCheckDisabled(type, product) : undefined;
  };

  return (
    <ProductPopupMenu
      optionTypesToShow={optionTypesToShow()}
      onMenuClick={handleAction}
      checkDisabled={handleCheck}
    />
  );
}
);

export default BrandCommunityProductOptionBox;

const styles: Styles = {
  box_shadow: {
    backgroundColor: "#FFF",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowRadius: 3,
    shadowOpacity: 0.3,
  },
  drop_text: {
    ...Texts.contents_text_5,
  },
  drop_line: {
    ...Colors.background_f9f3fa,
    height: 1,
    width: 70,
  },
  drop_box_shadow: {
    backgroundColor: "#FFF",
    shadowColor: "#000",
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowRadius: 3,
    shadowOpacity: 0.2,
  },
};
