import { t } from "i18next";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PopularQna } from "../../../api/feed/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { ActPageCode, ActSectionCode } from "../../../constants/ActHistory";
import AuthStore from "../../../store/AuthStore";
import { getNumberFormat } from "../../../utils/number";
// import { styles } from "../Main";
import { useTranslation } from "react-i18next";
import MainStore from "../../../store/MainStore";
import QnaCardItem from "../../qna/presenter/QnaCardItem";
import QnaStore from "../../../store/QnaStore";
import "./PopularQnaItem.scss";
import Slider, { Settings } from "react-slick";

const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

const PopularQnaItem = observer(
  ({
    qnaList,
    isMainSection = false,
  }: {
    qnaList: PopularQna[];
    isMainSection?: boolean;
  }) => {
    const [isClickItem, setIsClickItem] = useState(false);
    const { t } = useTranslation();
    const isLoggedIn = !!AuthStore.sessionUser?.memberUuid;

    const { popularQnaList } = QnaStore.qnaMainStore;

    const PURPLE_STYLE_LIST = [
      "qna_purple_wrap1",
      "qna_purple_wrap2",
      "qna_purple_wrap3",
    ];
    const getRandomNumber = () => {
      return Math.floor(Math.random() * 3);
    };

    const settings: Settings = {
      infinite: false,
      arrows: false,
      dots: false,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: true,
      beforeChange: () => {
        setIsClickItem(false);
      },
      afterChange: () => {
        setTimeout(() => {
          setIsClickItem(true);
        }, 100);
      },
    };

    return (
      <div id="PopularQnaItem">
        <div className="title_wrap">
          <div className="popular_qna_title_text">
            {t(`screen.main.label.popularQna`)}
          </div>
          <div className="title_background" />
        </div>
        <div className="popular_qna_list">
          {/* {qnaList?.map((item, index) => (
          <QnaCardItem qna={item} index={index} />
        ))} */}
          <Slider {...settings}>
            {qnaList?.map((item, index) => (
              <QnaCardItem qna={item} index={index} isClickItem={isClickItem} />
            ))}
          </Slider>
        </div>
        {isMainSection && (
          <div className="MainSectionFooter" style={{ height: "30px" }} />
        )}
      </div>
    );
  }
);

export default PopularQnaItem;
