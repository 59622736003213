import { t } from "i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAccountFullFeed } from "../../../api/account/api";
import {
  getFilteredFeedList,
  getPopularFeedDetailList,
  getProductFeedDetailList,
  getRecentFeedDetailList,
  getTagFeedDetailList,
} from "../../../api/feed/api";
import { Post } from "../../../api/feed/model";
import { getMissionPost } from "../../../api/mission/api";
import { getSearchResult } from "../../../api/search/api";
import {
  AccountFeedTypeCode,
  MY_HOME_FEED_COUNT,
} from "../../../constants/Account";
import { FeedTypeCode, FEED_CALL_STATE } from "../../../constants/Feed";
import { UserActTypeCode } from "../../../constants/FeedType.enum";
import AuthStore from "../../../store/AuthStore";
import FeedDetailStore from "../../../store/FeedDetailStore";
import ListStore from "../../../store/ListStore";
import MainStore from "../../../store/MainStore";
import MissionStore from "../../../store/MissionStore";
import MyHomeStore from "../../../store/MyHomeStore";
import PostStore from "../../../store/PostStore";
import SearchStore from "../../../store/SearchStore";
import TagStore from "../../../store/TagStore";
// import CommonResponse from "../../../api/http";
import { MAIN_TAB } from "../../../constants/main";
import { goBack, goTo } from "../../../hooks/navigate/NavigateFunction";
import { getMallPostedPopularProductPostdetail } from "../../../api/mall/api";

export type FeedParentTypeCode =
  | "MAIN_TAB_VERTICAL_INFINITE_POSTS" //NOTE : 메인화면 하단부터 무한 스크롤링 되는 추천 포스트. FeedScreen과는 상관 없음
  | "MAINTAB_POPULAR_POSTS"
  | "POSTTAB_RECENT_POSTS"
  | "POSTTAB_POPULAR_POSTS"
  | "POSTTAB_FOLLOWING_POSTS"
  | "MYHOME_POSTS"
  | "MISSION_POSTS"
  | "PROCUDT_REAL_POSTS"
  | "PRODUCT_USAGE_POSTS"
  | "PRODUCT_QNA_POSTS"
  | "TAG_POSTS"
  | "SEARCH_TOTAL_POSTS"
  | "SEARCH_POSTS"
  | "ETC"
  | "MALL_MAIN_POPULAR_PRODUCT";

export interface FeedExtraData {
  accountScreenOwnerId?: string;
  productId?: number;
  missionId?: number;
  tagId?: number;
  searchParams?: {
    keyword: string;
    tab: string;
    sort: string;
  };
  initPostList?: Post[];
  productName?: string;
  lifecareProductId?: number;
}

const useFeedScreen = (
  feedParentTypeCode: FeedParentTypeCode,
  extraData?: FeedExtraData
) => {
  const { popularPostsList } = MainStore;
  const { postsLists } = PostStore;
  const { getAccountStore } = MyHomeStore;
  const { missionPostList } = MissionStore;
  const { getTagDetailStore } = TagStore;
  const { postResult, totalList } = SearchStore;
  const [feedListStore, setFeedListStore] =
    useState<ListStore<Post & { id?: string }>>();
  const fetchType: "CURSOR" | "OFFSET" = useMemo(() => {
    if (
      PostStore.hasFilterOption &&
      feedParentTypeCode === "POSTTAB_RECENT_POSTS"
    ) {
      return "OFFSET";
    }
    switch (feedParentTypeCode) {
      case "POSTTAB_RECENT_POSTS":
      case "POSTTAB_FOLLOWING_POSTS":
      case "MISSION_POSTS":
      case "MALL_MAIN_POPULAR_PRODUCT":
        return "CURSOR";
      default:
        return "OFFSET";
    }
  }, [feedParentTypeCode]);
  const fetchCursorKey: keyof Post | undefined = useMemo(() => {
    switch (feedParentTypeCode) {
      case "POSTTAB_RECENT_POSTS":
      case "POSTTAB_FOLLOWING_POSTS":
        return "feedId";
      case "MISSION_POSTS":
        return "missionParticipationId";
      case "MALL_MAIN_POPULAR_PRODUCT":
        return "sortOrder";
      default:
        return undefined;
    }
  }, [feedParentTypeCode]);
  const pageSize = useMemo(() => {
    switch (feedParentTypeCode) {
      case "TAG_POSTS":
        return 12;
      case "MYHOME_POSTS":
        return MY_HOME_FEED_COUNT;
      case "SEARCH_TOTAL_POSTS":
        return 6;
      case "MAINTAB_POPULAR_POSTS":
      case "POSTTAB_RECENT_POSTS":
      case "POSTTAB_POPULAR_POSTS":
      case "POSTTAB_FOLLOWING_POSTS":
      case "MISSION_POSTS":
      case "PROCUDT_REAL_POSTS":
      case "PRODUCT_USAGE_POSTS":
      case "PRODUCT_QNA_POSTS":
      case "SEARCH_POSTS":
      case "MALL_MAIN_POPULAR_PRODUCT":
      default:
        return 10;
    }
  }, [feedParentTypeCode]);

  const detailPostListAttr5 = useMemo(() => {
    switch (feedParentTypeCode) {
      case "POSTTAB_RECENT_POSTS":
        return "POST_RECENT_POST";
      case "POSTTAB_POPULAR_POSTS":
        return "POST_POPULAR_POST";
      case "MAINTAB_POPULAR_POSTS":
        return "MAIN_POPULAR_POST";
      case "MYHOME_POSTS":
        //todo: 본인 홈일때와 다른 사람 홈일때 구분
        return "MY_HOME_POST";
      case "SEARCH_TOTAL_POSTS":
        return "SEARCH_POST";
      case "MISSION_POSTS":
        return "MISSION";
      // BOOKMARK 없음
      case "PROCUDT_REAL_POSTS":
        return "PRODUCT_DETAIL_REAL_POST";
      case "PRODUCT_USAGE_POSTS":
        return "PRODUCT_DETAIL_USE_METHOD_POST";
      case "TAG_POSTS":
        return "TAG_GERNERAL";
      // TAG_PRODUCT 없음
      default:
        return feedParentTypeCode;
    }
  }, [feedParentTypeCode]);

  const initialFetchedPosts = useMemo(() => {
    switch (feedParentTypeCode) {
      case "MAINTAB_POPULAR_POSTS":
        return popularPostsList;
      case "POSTTAB_RECENT_POSTS":
      case "POSTTAB_POPULAR_POSTS":
      case "POSTTAB_FOLLOWING_POSTS":
        return postsLists;
      case "MYHOME_POSTS":
        return extraData?.accountScreenOwnerId
          ? ((getAccountStore(extraData.accountScreenOwnerId)?.postList ||
              []) as Post[])
          : [];
      case "MISSION_POSTS":
        return missionPostList;
      case "PROCUDT_REAL_POSTS":
        return [];
      case "MALL_MAIN_POPULAR_PRODUCT":
        return extraData?.initPostList
          ? extraData?.initPostList
          : ([] as Post[]);
      case "PRODUCT_USAGE_POSTS":
        return [];
      case "PRODUCT_QNA_POSTS":
        return [];
      case "TAG_POSTS":
        return extraData?.tagId
          ? ((getTagDetailStore(extraData.tagId)?.postList || []) as Post[])
          : [];
      case "SEARCH_TOTAL_POSTS":
        return totalList.postList;
      case "SEARCH_POSTS":
        return postResult?.list || [];
      default:
        return [];
    }
  }, [
    feedParentTypeCode,
    popularPostsList,
    postsLists,
    extraData,
    getAccountStore,
    getTagDetailStore,
    totalList,
    postResult,
    missionPostList,
  ]);
  const headerTitle = useMemo(() => {
    switch (feedParentTypeCode) {
      case "MAINTAB_POPULAR_POSTS":
        return t(`screen.myHome.label.postPopular`);
      case "POSTTAB_RECENT_POSTS":
        return t(`screen.post.title.recent`);
      case "POSTTAB_POPULAR_POSTS":
        return t(`screen.myHome.label.postPopular`);
      case "POSTTAB_FOLLOWING_POSTS":
        return t(`screen.post.title.following`);
      case "MYHOME_POSTS":
        return t(`screen.myHome.label.post`);
      case "MISSION_POSTS":
        return t("screen.mission.label.challengers");
      case "PROCUDT_REAL_POSTS":
        return t(`screen.post.title.product.realPost`);
      case "MALL_MAIN_POPULAR_PRODUCT":
        return extraData?.productName;
      case "PRODUCT_USAGE_POSTS":
        return t(`screen.post.title.product.useMethodPost`);
      case "PRODUCT_QNA_POSTS":
        return t(`screen.post.title.product.qna`);
      case "TAG_POSTS":
      case "SEARCH_POSTS":
      case "SEARCH_TOTAL_POSTS":
      default:
        return t(`screen.myHome.label.post`);
    }
  }, [feedParentTypeCode, extraData?.productName]);
  const handlePostUpdate = useCallback(
    (feedId: number, actionType: UserActTypeCode) => {
      MainStore.updateFeed(actionType, feedId); //MainStore
      PostStore.updatePostStoreList(actionType, feedId); // PostStore
      FeedDetailStore.updatePostInAllFeedListStore(actionType, feedId); // FeedDetailStore
      MyHomeStore.sync(feedId, AccountFeedTypeCode.POST, actionType);
      TagStore.sync(feedId, FeedTypeCode.POST, actionType);
      SearchStore.syncPost(feedId, actionType);
    },
    []
  );
  const handleGoHome = useCallback(() => {
    goTo("/");
  }, []);
  const handleGoBack = useCallback(() => {
    goBack();
  }, []);
  const handleSearch = useCallback(() => {
    // navigate("SearchScreen");
  }, []);
  const handleQueryMorePosts = useCallback(
    ({
      pageIndex = 0,
      pageSize,
      cursor = null,
    }: {
      pageIndex?: number;
      pageSize: number;
      cursor?: string | null;
    }) => {
      return new Promise<Post[]>((resolve) => {
        switch (feedParentTypeCode) {
          // case "MAINTAB_POPULAR_POSTS": {
          //   return resolve([] as Post[]);
          // }
          case "POSTTAB_RECENT_POSTS":
            //   let res: CommonResponse;
            if (PostStore.hasFilterOption) {
              const filterParam = PostStore.createRecentFilterParam(
                pageIndex,
                pageSize
              );
              getFilteredFeedList(filterParam)
                .then((res) => {
                  return resolve((res.data?.feedFindVOList || []) as Post[]);
                })
                .catch((error) => {
                  return resolve([] as Post[]);
                });
            } else {
              getRecentFeedDetailList(pageSize, cursor, "POST", "N")
                .then((res) => {
                  return resolve((res.data?.feedFindVOList as Post[]) || []);
                })
                .catch((error) => {
                  return resolve([] as Post[]);
                });
            }
            break;
          case "POSTTAB_POPULAR_POSTS":
          case "MAINTAB_POPULAR_POSTS":
            if (PostStore.hasFilterOption) {
              const filterParam = PostStore.createPopularFilterParam(
                pageIndex,
                pageSize
              );
              getFilteredFeedList(filterParam)
                .then((res) => {
                  return resolve((res.data?.feedFindVOList as Post[]) || []);
                })
                .catch((error) => {
                  return resolve([] as Post[]);
                });
            } else {
              getPopularFeedDetailList(pageSize, pageIndex)
                .then((res) => {
                  return resolve((res.data?.feedFindVOList as Post[]) || []);
                })
                .catch((error) => {
                  return resolve([] as Post[]);
                });
            }
            break;
          case "POSTTAB_FOLLOWING_POSTS":
            getRecentFeedDetailList(pageSize, cursor, "POST", "Y")
              .then((res) => {
                return resolve((res.data?.feedFindVOList as Post[]) || []);
              })
              .catch((error) => {
                return resolve([] as Post[]);
              });
            //            return (res.data?.feedFindVOList || []) as Post[];
            break;
          case "MYHOME_POSTS":
            if (!extraData?.accountScreenOwnerId) {
              return resolve([] as Post[]);
            }
            getAccountFullFeed({
              memberUuid: extraData.accountScreenOwnerId,
              blocked: "N",
              pageSize: pageSize,
              pageIndex: pageIndex,
            })
              .then((res) => {
                return resolve((res.list || []) as Post[]);
              })
              .catch((error) => {
                return resolve([] as Post[]);
              });
            // return (res.list || []) as Post[];
            break;
          case "MISSION_POSTS":
            if (!extraData?.missionId) return resolve([] as Post[]);
            getMissionPost(
              extraData.missionId,
              cursor,
              MissionStore.displayType
            )
              .then((res) => {
                return resolve(res.feedFindVOList || []);
              })
              .catch((error) => {
                return resolve([] as Post[]);
              });
            //   return res.feedFindVOList || [];
            break;
          case "PROCUDT_REAL_POSTS":
            if (!extraData?.productId) return resolve([]);
            getProductFeedDetailList(
              0,
              pageSize,
              pageIndex,
              FEED_CALL_STATE.PRODUCT_REALPOST,
              extraData.productId
            )
              .then((res) => {
                return resolve((res.data?.feedFindVOList as Post[]) || []);
              })
              .catch((error) => {
                return resolve([] as Post[]);
              });
            //            return (res.successOrNot == "Y" ? res.data.feedFindVOList : []) as Post[];
            break;
          case "MALL_MAIN_POPULAR_PRODUCT":
            if (!extraData?.productId || cursor === null) return [];
            getMallPostedPopularProductPostdetail(
              pageSize,
              cursor,
              extraData.productId
            )
              .then((res) => {
                return resolve(
                  (res.successOrNot == "Y"
                    ? res.data.feedFindVOList
                    : []) as Post[]
                );
              })
              .catch((error) => {
                return resolve([] as Post[]);
              });
            break;
          case "PRODUCT_USAGE_POSTS":
            if (!extraData?.productId) return resolve([] as Post[]);
            getProductFeedDetailList(
              0,
              pageSize,
              pageIndex,
              FEED_CALL_STATE.PRODUCT_USE_METHOD,
              extraData.productId
            )
              .then((res) => {
                return resolve((res.data?.feedFindVOList as Post[]) || []);
              })
              .catch((error) => {
                return resolve([] as Post[]);
              });
            // return (res.successOrNot == "Y" ? res.data.feedFindVOList : []) as Post[];
            break;
          case "PRODUCT_QNA_POSTS":
            if (!extraData?.productId) return resolve([] as Post[]);
            getProductFeedDetailList(
              0,
              pageSize,
              pageIndex,
              FEED_CALL_STATE.PRODUCT_QNA,
              extraData.productId
            )
              .then((res) => {
                return resolve((res.data?.feedFindVOList as Post[]) || []);
              })
              .catch((error) => {
                return resolve([] as Post[]);
              });
            //  return (res.successOrNot == "Y" ? res.data.feedFindVOList : []) as Post[];
            break;
          case "TAG_POSTS":
            //   if (!extraData?.productId) return resolve([] as Post[]);
            if (!extraData?.tagId) return [];
            getTagFeedDetailList(
              FeedTypeCode.POST,
              pageSize,
              pageIndex,
              extraData.tagId
            )
              .then((res) => {
                return resolve((res.data?.list as Post[]) || []);
              })
              .catch((error) => {
                return resolve([] as Post[]);
              });
            //    return (res.successOrNot == "Y" ? res.data.list : []) as Post[];
            break;
          case "SEARCH_TOTAL_POSTS":
          case "SEARCH_POSTS":
            if (!extraData?.searchParams) {
              return resolve([] as Post[]);
            }
            getSearchResult(
              "post",
              extraData.searchParams.keyword,
              pageSize,
              pageIndex,
              extraData.searchParams.sort,
              AuthStore.sessionUser?.memberUuid === undefined ||
                AuthStore.sessionUser?.memberUuid === null ||
                AuthStore.sessionUser?.memberUuid?.length < 1
                ? AuthStore.tempMember?.tempMemberUUID
                : undefined
            )
              .then((res) => {
                return resolve((res.feedFindVOList as Post[]) || []);
              })
              .catch((error) => {
                return resolve([] as Post[]);
              });
            // return res.feedFindVOList ?? [];
            break;
          default:
            return resolve([] as Post[]);
        }
      });
    },
    [extraData, feedParentTypeCode]
  );
  //  return [] ;
  //    }, [extraData, feedParentTypeCode]);
  //   ,
  //   [extraData, feedParentTypeCode]
  // );
  useEffect(() => {
    const newFeedListStore =
      FeedDetailStore.generateFeedListStore(feedParentTypeCode);
    setFeedListStore(newFeedListStore);
    return () => {
      FeedDetailStore.clearFeedListStoreList(feedParentTypeCode);
    };
  }, [feedParentTypeCode]);
  return {
    fetchType,
    fetchCursorKey,
    // navigation,
    initialFetchedPosts,
    headerTitle,
    feedListStore,
    pageSize,
    detailPostListAttr5,
    handleQueryMorePosts,
    handleGoHome,
    handleGoBack,
    handleSearch,
    handlePostUpdate,
  };
};

export default useFeedScreen;
