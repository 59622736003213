import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Virtuoso } from "react-virtuoso";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../assets/styles";
import CommonStore from "../../store/CommonStore";
import AuthStore from "../../store/AuthStore";
import { CustomActivityIndicator, KeywordText } from "../../components/common";
import CustomBottomSheet from "../../components/common/CustomBottomSheet";
import FlatListWithStickyTabs from "../../components/common/FlatListWithStickyTabs";
import FastImageWithFallback from "../../components/common/FastImageWithFallback";
import PostsListHeader from "../../components/headers/PostsListHeader";
import { AutoCompleteWhat, SearchSortType } from "../../constants/Search";
import { ARTICLE_SEARCH_WORD } from "../../constants/Storage";
import { Category } from "../../constants/Article.enum";
import { getDateStr } from "../../utils/datetime";
import ArticleDisclosureModal from "./presenter/ArticleDisclosureModal";
import { GetArticleListRequest, GetArticleListResponse } from "../../api/brand/model";
import { getBrandAutoComplete, getArticleList } from "../../api/brand/api";
import { SearchText } from "../../service/Search.service";
import { ReactComponent as SearchIcon } from "../../assets/icons/search1.svg";
import { ReactComponent as ClosePurple } from "../../assets/icons/close_purple_background.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as LatestIcon } from "../../assets/icons/clock_purple.svg";
import { ReactComponent as SearchGrayIcon } from "../../assets/icons/search_gray.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_down_small.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/icon_lock_center.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/new_heart_off.svg";
import { ReactComponent as ViewIcon } from "../../assets/icons/icon_view.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/new_comment_purple.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/close_gray.svg";
import { useTranslation } from "react-i18next";
import { Styles } from "../../assets/types/Style";
import { font_weight_400 } from "../../assets/styles/texts";
import { goBack, goTo } from "../../hooks/navigate/NavigateFunction";
import ArticleListStore from "../../store/ArticleListStore";

const BrandCommunityArticleSearchScreen = observer((data: any) => {

  const inputRef = useRef<any>(null);
  const { t } = useTranslation();

  const [toastMessage, setToastMessage] = useState<string>();
  const [keyword, setKeyword] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<Category>(Category.ALL);
  const [sort, setSort] = useState<SearchSortType>(SearchSortType.LATEST);
  const [searchResult, setSearchResult] = useState<GetArticleListResponse | null>(null);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchPageIndex, setSearchPageIndex] = useState<number>(0);

  const [recentKeywordList, setRecentKeywordList] = useState<string[]>([]);
  const [autoCompleteList, setAutoCompleteList] = useState<string[]>([]);
  const [filterRecentList, setFilterRecentList] = useState<string[]>([]);
  const [latestSearchTextList, setLatestSearchTextList] = useState<SearchText | null>(null);

  const [showWord, setShowWord] = useState<boolean>(false);
  const [showResult, setShowResult] = useState<boolean>(false);
  const [showSortBox, setShowSortBox] = useState<boolean>(false);
  const [showDisclosurePopup, setShowDisclosurePopup] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [loadingInList, setLoadingInList] = useState<boolean>(false);

  let { brandCommunityId, corpMemberYn } = ArticleListStore;

  const tabs = {
    category: [
      { code: Category.ALL, text: t("screen.brandCommunity.articleList.filter.category.all") },
      { code: Category.TIP, text: t("screen.brandCommunity.articleList.filter.category.tip") },
      { code: Category.REVIEW, text: t("screen.brandCommunity.articleList.filter.category.review") },
      { code: Category.ASK, text: t("screen.brandCommunity.articleList.filter.category.ask") },
      { code: Category.CHAT, text: t("screen.brandCommunity.articleList.filter.category.chat") },
      { code: Category.DEAL, text: t("screen.brandCommunity.articleList.filter.category.deal") },
      { code: Category.ETC, text: t("screen.brandCommunity.articleList.filter.category.etc") },
    ],
  };

  let width: number;
  const wrapArticleSearchRef = useRef<any>(null);
  useEffect(() => {
    if (wrapArticleSearchRef?.current?.offsetWidth) {
      width = wrapArticleSearchRef.current.offsetWidth;
    }
  }, [wrapArticleSearchRef.current]);

  const ARTICLE_SEARCH_COUNT = 10;
  const userUuid =
    AuthStore.sessionUser?.memberUuid === undefined ||
      AuthStore.sessionUser?.memberUuid === null ||
      AuthStore.sessionUser?.memberUuid?.length < 1
      ? AuthStore.tempMember?.tempMemberUUID
      : AuthStore.sessionUser?.memberUuid;

  const stickyTabsRef = useRef<any>(null);

  const getLastestSearchText = async () => {
    const latestSearchText = await getSearchTextFromStorage();
    setLatestSearchTextList(latestSearchText);
  };

  const setSearchTextInStorage = async (latestText: string): Promise<boolean> => {
    return updateSearchTextFromStorage(latestText);
  };

  const removeSearchTextFromStorage = async (latestText: string): Promise<boolean> => {
    return updateSearchTextFromStorage(latestText, true);
  };

  const getSearchTextFromStorage = async (): Promise<SearchText | null> => {
    try {
      const searchText = localStorage.getItem("article_search_text");
      if (searchText) {
        return JSON.parse(searchText) as SearchText;
      } else {
        return null;
      }
    } catch (error) {
      return null;
      // There was an error on the native side
    }
  };

  const removeAllSearchTextFromStorage = async (): Promise<boolean> => {
    try {
      localStorage.removeItem("article_search_text");
      setLatestSearchTextList(null);
      return true;
    } catch (error) {
      return false;
      // There was an error on the native side
    }
  };

  const updateSearchTextFromStorage = async (latestText: string, isDelete = false): Promise<boolean> => {
    try {
      const savedText: string[] = latestSearchTextList?.text.filter((text: string) => text !== latestText) || [];

      if (!isDelete) {
        savedText.length === 10 && savedText.shift();
        savedText.push(latestText);
      }

      const searchText: SearchText = {
        text: savedText,
      };

      localStorage.setItem("article_search_text", JSON.stringify(searchText));
      setLatestSearchTextList(searchText);
      return true;
    } catch (error) {
      return false;
    }
  };

  const doSearch = async (searchText?: string) => {
    if (searchText && searchText?.length > 0) {
      void setRecentWord(searchText.trim());
      void getSearchResult(searchText, 0);
      searchText.length > 0 && (await setSearchTextInStorage(searchText));
      inputRef.current?.blur();
      setShowWord(false);
      setShowResult(true);
    } else {
      inputRef.current?.focus();
      setShowWord(false);
      setShowResult(false);
    }
  };

  useEffect(() => {
    clear();
    void getLastestSearchText();
    void getRecentWord();
    inputRef.current?.focus();

    return () => {
      clear();
    };
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (keyword && keyword?.length > 0) {
      void getSearchResult(keyword, 0);
      inputRef.current?.blur();
      setShowWord(false);
      setShowResult(true);
    } else {
      inputRef.current?.focus();
      setShowWord(false);
      setShowResult(false);
    }
    // eslint-disable-next-line
  }, [selectedCategory, sort]);

  useEffect(() => {
    if (toastMessage !== undefined && toastMessage.trim().length > 0) {
      CommonStore.setToastOption({
        show: true,
        message1: toastMessage,
      });
      setToastMessage("");
    }
  }, [toastMessage]);

  const clear = () => {
    setToastMessage("");
    setKeyword("");
    setRecentKeywordList([]);
    setAutoCompleteList([]);
    setFilterRecentList([]);
    setSearchResult(null);
    setShowWord(false);
    setShowResult(false);
    setRefreshing(false);
    setLoading(false);
    setSort(SearchSortType.LATEST);
    setShowSortBox(false);
    setTotalCount(0);
    setSearchPageIndex(0);
    setLoadingInList(false);
  };

  const onClickWord = (word: string) => {
    setKeyword(word);
    void setSearchingKeyword(word);
    void setRecentWord(word);
    void getSearchResult(word, 0);
    setShowWord(false);
    setShowResult(true);
  };

  const onSearch = (searchText: string) => {
    setKeyword(searchText);
    void doSearch(searchText);
  };

  const getRecentWord = () => {
    const result = localStorage.getItem(ARTICLE_SEARCH_WORD);
    if (result) {
      setRecentKeywordList(JSON.parse(result) as string[]);
    }
  };

  const setRecentWord = async (word: string) => {
    const alreadyExist = recentKeywordList.findIndex((text: string) => word === text);
    if (alreadyExist > -1) recentKeywordList.splice(alreadyExist, 1);
    if (recentKeywordList.length > 10) {
      recentKeywordList.splice(10, 1);
    }
    recentKeywordList.splice(0, 0, word);
    localStorage.setItem(ARTICLE_SEARCH_WORD, JSON.stringify(recentKeywordList));
  };

  const getSearchResult = (word: string, pageIndex: number) => {
    setSearchPageIndex(pageIndex);

    if (pageIndex === 0) {
      setSearchResult(null);
      setLoading(true);
    }

    const articleRequest = {
      communityId: brandCommunityId || 0,
      query: word,
      category: selectedCategory,
      sort: sort,
      pageIndex: pageIndex,
      pageSize: ARTICLE_SEARCH_COUNT,
    } as GetArticleListRequest;

    getArticleList(articleRequest)
      .then((res) => {
        setLoading(false);
        setRefreshing(false);
        setLoadingInList(false);
        if (pageIndex == 0) setSearchResult(res);
        else {
          const currentResult = searchResult?.list ? searchResult?.list : [];
          currentResult?.push(...res.list);
          res.list = currentResult;
          setSearchResult(res);
          setTotalCount(res.totalCount);
        }
        setTotalCount(res.totalCount || 0);
      })
      .catch((err) => {
        setLoading(false);
        setRefreshing(false);
        setToastMessage(t("common.message.unknownException"));
      });
  };

  const setSearchingKeyword = async (word?: string) => {
    if (word) {
      filteringRecentKeyword(word);
      await getAutoCompleteWord(word);
    }
  };

  const getAutoCompleteWord = async (word: string) => {
    // TODO : DS API 호출시 what타입에 게시글은 없는 상황. 상황보고 추후 대응.
    const result = word.length > 0 ? await getBrandAutoComplete(word, AutoCompleteWhat.DEFAULT, brandCommunityId) : [];
    setAutoCompleteList(result);
  };

  const filteringRecentKeyword = (word: string) => {
    setFilterRecentList(recentKeywordList.filter((text) => text.toLowerCase().indexOf(word.toLowerCase()) > -1) || []);
  };

  const renderLastestSearch = () => {
    if (latestSearchTextList && latestSearchTextList.text.length > 0) {
      return (
        <div style={styles.latest_search}>
          <div style={styles.latest_search_header}>
            <p style={styles.title}>{t("screen.search.label.lastestSearch")}</p>
            <button onClick={() => void removeAllSearchTextFromStorage()}>
              <p style={styles.delete_all_btn_text}>{t("screen.search.label.deleteAll")}</p>
            </button>
          </div>

          <div style={styles.latest_search_list}>
            {latestSearchTextList?.text
              .slice()
              .reverse()
              .map((item: string, index: number) => (
                <div style={styles.latest_search_item} key={index}>
                  <button style={{ display: 'flex' }} onClick={() => void removeSearchTextFromStorage(item)}>
                    <DeleteIcon />
                  </button>
                  <button style={{ width: '100%' }} onClick={() => onSearch(item)}>
                    <p style={styles.latest_search_text}>
                      {item}
                    </p>
                  </button>
                </div>
              ))}
          </div>
        </div>
      );
    } else {
      return (
        <div style={styles.latest_search}>
          <div style={styles.latest_search_header}>
            <p style={styles.title}>{t("screen.search.label.lastestSearch")}</p>
          </div>
          <div style={styles.latest_search_list}>
            <p style={styles.no_latest_search_text}>{t("screen.search.message.noLastestSearchText")}</p>
          </div>
        </div>
      );
    }
  };

  const renderFilterRecent = (word: string, index: number) => {
    return (
      <button
        style={styles.auto_complete_item}
        key={`Recent_${word}_${index.toString()}`}
        onClick={() => onClickWord(word)}
      >
        <LatestIcon />
        <KeywordText
          sentence={word}
          keyword={keyword || ""}
          containerStyle={styles.auto_complete_text}
          matchTextStyle={styles.auto_complete_match_text}
          accessibilityLabel={`Article Recent Keyword item ${index}`}
        />
      </button>
    );
  };

  const renderAutoComplete = (word: string, index: number) => {
    return (
      <button
        style={styles.auto_complete_item}
        key={`Auto_${word}_${index.toString()}`}
        onClick={() => onClickWord(word)}
      >
        <SearchGrayIcon />
        <KeywordText
          sentence={word}
          keyword={keyword || ""}
          containerStyle={styles.auto_complete_text}
          matchTextStyle={styles.auto_complete_match_text}
          accessibilityLabel={`Article Auto Complete Keyword item ${index}`}
        />
      </button>
    );
  };

  const renderSearchWord = () => {
    return (
      <div style={{ ...styles.auto_complete_list, paddingBottom: 100 }}>
        {filterRecentList?.map((item: string, index: number) => renderFilterRecent(item, index))}
        {autoCompleteList?.map((item: string, index: number) => renderAutoComplete(item, index))}
      </div>
    );
  };

  const renderFilter = () => {
    return (
      <>
        <div style={styles.header_wrapper}>
          <div style={styles.header_option}>
            <p style={styles.header_cnt_desc_txt}>
              <span style={styles.total_cnt_text}>{totalCount}</span>
              {t("screen.brandCommunity.label.resultTotalNum")}
            </p>
            {totalCount > 0 && (
              <button
                style={styles.header_sort_btn}
                onClick={() => setShowSortBox(!showSortBox)}
              >
                <p style={styles.header_sort_btn_txt}>
                  {sort === SearchSortType.POPULAR
                    ? t("screen.brandCommunity.sortType.popular")
                    : sort === SearchSortType.ACCURACY
                      ? t("screen.brandCommunity.sortType.accuracy")
                      : t("screen.brandCommunity.sortType.latest")}
                </p>
                <ArrowDown />
              </button>
            )}
          </div>
          {totalCount > 0 && (
            <div style={styles.tab_sub_option}>
              <Virtuoso
                style={{ height: 50, width: '100%', ...styles.tab_item_wrap }}
                id="filter list"
                data={tabs.category}
                horizontalDirection
                itemContent={(index: number, item: any) => (
                  <button
                    style={selectedCategory == item.code ? styles.sub_active_item : styles.sub_item}
                    key={index}
                    onClick={() => setSelectedCategory(item.code)}
                  >
                    <p style={selectedCategory == item.code ? styles.sub_active_txt : styles.sub_txt}>
                      {item.text}
                    </p>
                  </button>
                )}
              />
            </div>
          )}
        </div>
      </>
    );
  };

  const renderLargeArticleInfo = (
    nickname: string,
    commentCount: number,
    viewCount: number,
    likeCount: number,
    createdDateTime: string,
    createMemberUuid: string,
    disclosureYn: string
  ) => {
    return (
      <div style={styles.article_info_wrap}>
        <div style={styles.article_info}>
          <p style={styles.article_item_text3}>{nickname ? nickname : "null"}</p>
          <div style={styles.article_item_bar} />
          {(corpMemberYn === "Y" ||
            disclosureYn !== "N" ||
            (disclosureYn === "N" && createMemberUuid === userUuid)) && (
              <>
                <div style={styles.article_comment}>
                  <CommentIcon style={styles.comment_icon} />
                  <p style={styles.article_item_text5}>{commentCount}</p>
                </div>
                <div style={styles.article_view}>
                  <ViewIcon style={styles.view_icon} />
                  <p style={styles.article_item_text5}>{viewCount}</p>
                </div>
                <div style={styles.like}>
                  <HeartIcon />
                  <p style={{ ...styles.article_item_text5, ...styles.like_text }}>{likeCount}</p>
                </div>
                <div style={styles.article_item_bar} />
              </>
            )}
          <p style={styles.article_item_text5}>{getDateStr(createdDateTime)}</p>
        </div>
      </div>
    );
  };

  const renderSmallArticleInfo = (
    nickname: string,
    commentCount: number,
    viewCount: number,
    likeCount: number,
    createdDateTime: string,
    createMemberUuid: string,
    disclosureYn: string
  ) => {
    return (
      <div style={styles.article_info_wrap}>
        <div style={{ ...styles.article_info, ...styles.article_small_info }}>
          <p style={styles.article_item_text3}>{nickname}</p>
          <div style={styles.small_wrap}>
            <div style={styles.small_wrap_inner}>
              <div style={styles.article_item_bar} />
              {(corpMemberYn === "Y" ||
                disclosureYn !== "N" ||
                (disclosureYn === "N" && createMemberUuid === userUuid)) && (
                  <>
                    <div style={styles.article_comment}>
                      <CommentIcon style={styles.comment_icon} />
                      <p style={styles.article_item_text5}>{commentCount}</p>
                    </div>
                    <div style={styles.article_view}>
                      <ViewIcon style={styles.view_icon} />
                      <p style={styles.article_item_text5}>{viewCount}</p>
                    </div>
                    <div style={styles.like}>
                      <HeartIcon />
                      <p style={{ ...styles.article_item_text5, ...styles.like_text }}>{likeCount}</p>
                    </div>
                    <div style={styles.article_item_bar} />
                  </>
                )}
              <p style={styles.article_item_text5}>{getDateStr(createdDateTime)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <>
        {!loading ? (
          <div style={styles.empty}>
            <div style={styles.empty_wrap}>
              <p style={styles.empty_text}>
                {t("screen.brandCommunity.message.emptyArticleSearchText", { searchText: keyword })}
              </p>
              <button
                style={styles.return_brand_box}
                onClick={() => {
                  goTo('/BrandCommunityHome', { state: { targetBrandId: brandCommunityId.toString() } });
                }}
              >
                <p style={styles.return_brand_text}>{t("screen.brandCommunity.message.returnBrandPage")}</p>
              </button>
            </div>
          </div>
        ) : (
          <div style={{ marginTop: 100, marginBottom: 100 }}>
            <CustomActivityIndicator />
          </div>
        )}
      </>
    )
  }

  const renderSearchResult = () => {
    return (
      <>
        <Virtuoso
          style={{ ...styles.article_list, marginBottom: 150 }}
          id="Article search result list"
          data={searchResult?.list || []}
          useWindowScroll
          itemContent={(index: number, item: any) => {
            return (
              <>
                <button
                  style={{ width: '100%' }}
                  key={index}
                  onClick={() => {
                    corpMemberYn === "Y" ||
                      item.articleDisclosureYn !== "N" ||
                      (item.articleDisclosureYn === "N" && item.createMemberUuid === userUuid)
                      ? moveToDetail(item.articleId, index)
                      : setShowDisclosurePopup(true);
                  }}
                >
                  <div style={styles.article_item}>
                    <div style={styles.article_wrap}>
                      <div style={styles.bx_comm_popular_wrap}>
                        <div style={styles.bx_comm_pp_left}>
                          <div style={styles.article_top}>
                            <div style={styles.article}>
                              <div style={styles.item_title_wrap}>
                                <div style={styles.flag_default}>
                                  <p style={styles.flag_default_text}>{item.articleTypeName}</p>
                                </div>
                                {item.articleDisclosureYn !== "N" ? (
                                  <p style={styles.article_item_text1} dangerouslySetInnerHTML={{ __html: item.articleTitle}}></p>
                                ) : item.articleDisclosureYn === "N" &&
                                  (corpMemberYn === "Y" || item.createMemberUuid === userUuid) ? (
                                  <div style={{ ...styles.article_item_text1, ...styles.text1_lock }}>
                                    <LockIcon style={styles.icon_lock} /> 
                                    <p style={styles.article_item_text1} dangerouslySetInnerHTML={{ __html: item.articleTitle}}></p>
                                  </div>
                                ) : (
                                  <p style={{ ...styles.article_item_text1, ...styles.text1_lock }}>
                                    <LockIcon style={styles.icon_lock} />{" "}
                                    {t("screen.brandCommunity.message.disclosureArticle")}
                                  </p>
                                )}
                              </div>
                              {(corpMemberYn === "Y" ||
                                item.articleDisclosureYn !== "N" ||
                                (item.articleDisclosureYn === "N" && item.createMemberUuid === userUuid)) && (
                                  <>
                                    <p style={styles.article_item_text2} dangerouslySetInnerHTML={{ __html: item.articleContents}}></p>
                                  </>
                                )}
                              {(width && width > 370)
                                ? renderLargeArticleInfo(
                                  item.nickname,
                                  item.articleCommentCount,
                                  item.articleViewCount,
                                  item.articleLikeCount,
                                  item.createdDatetime,
                                  item.createMemberUuid,
                                  item.articleDisclosureYn
                                )
                                : renderSmallArticleInfo(
                                  item.nickname,
                                  item.articleCommentCount,
                                  item.articleViewCount,
                                  item.articleLikeCount,
                                  item.createdDatetime,
                                  item.createMemberUuid,
                                  item.articleDisclosureYn
                                )}
                            </div>
                            {!!item.thumbnailFilePath &&
                              (corpMemberYn === "Y" ||
                                item.articleDisclosureYn !== "N" ||
                                (item.articleDisclosureYn === "N" && item.createMemberUuid === userUuid)) && (
                                <div style={styles.bx_comm_img}>
                                  <FastImageWithFallback
                                    wrapperStyle={{ display: 'flex', justifyContent: 'end', alignItems: 'end', flex: 1 }}
                                    style={styles.article_image}
                                    source={{
                                      uri: `${process.env.REACT_NATIVE_PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
                                    }}
                                  />
                                  <div style={styles.num_comm_img}>
                                    <p style={styles.num_comm_text}>{item.fileCount}</p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </>
            )
          }}
          components={{
            EmptyPlaceholder: () => (<>{renderEmpty()}</>),
            Footer: () => (<>
              {loadingInList ? (
                <div style={{ color: 'grey', marginTop: 100, marginBottom: 100 }}>
                  <CustomActivityIndicator />
                </div>
              ) : (
                <></>
              )}
            </>)
          }}
          endReached={() => {
            if (
              (searchResult?.totalCount || 0) > 0 &&
              searchResult &&
              searchResult.list &&
              searchResult.list.length > 0
            ) {
              const tempStart = searchPageIndex + 1;
              if ((searchResult?.totalCount || 0) > tempStart * ARTICLE_SEARCH_COUNT) {
                setLoadingInList(true);
                if (keyword) void getSearchResult(keyword, tempStart);
                else void getSearchResult("", tempStart);
              }
            }
          }}
        />

        {showDisclosurePopup && (
          <ArticleDisclosureModal
            isVisible={showDisclosurePopup}
            contents1={t("screen.notificationModal.message.disclosureArticleText1")}
            contents2={t("screen.notificationModal.message.disclosureArticleText2")}
            textAlign="center"
            onRequestClose={() => {
              setShowDisclosurePopup(!showDisclosurePopup);
            }}
          />
        )}
      </>
    );
  };

  const renderSearchInput = () => {
    return (
      <>
        <div style={styles.home_app_wrap}>
          <input
            style={styles.home_app}
            placeholder={t("screen.brandCommunity.label.articleSearchPlaceholder")}
            value={keyword}
            type="text"
            onChange={(e) => {
              const text = e.target.value;
              setKeyword(text);
              void setSearchingKeyword(text);
              setShowResult(false);
              setShowWord(text?.length > 0);
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter" && keyword) {
                void doSearch(keyword.trim())
              }
            }}
            ref={inputRef}
          />
          <button
            style={{ display: keyword ? "flex" : "none", ...styles.close_purple }}
            onClick={() => {
              setKeyword("");
              void setSearchingKeyword("");
              setShowWord(false);
              setShowResult(false);
            }}
          >
            <ClosePurple style={{ height: 16, width: 16 }} />
          </button>
          <button
            style={styles.search_icon}
            onClick={() => {
              if(keyword) {
                void doSearch(keyword.trim())
              }}
            }
          >
            <SearchIcon />
          </button>
        </div>
      </>
    );
  };

  const moveToDetail = (articleId: number, articleListIndex: number) => {
    goTo(`/ArticleDetail/${articleId}`, { state: { index: articleListIndex } });
  };

  return (
    <div
      style={{
        ...FlexBoxs.flex_1,
        ...Colors.background_fff,
      }}
      ref={wrapArticleSearchRef}
    >
      <PostsListHeader
        title={t("screen.brandCommunity.label.articleSearch")}
        goBack={goBack}
        wrapStyle={{ backgroundColor: 'white', zIndex: 999 }}
      />
      {renderSearchInput()}
      <FlatListWithStickyTabs
        contentScrollRef={stickyTabsRef}
        stickyItems={
          <>
            {showResult && renderFilter()}
            {!showResult && !showWord && renderLastestSearch()}
          </>
        }
        listFooterComponent={
          <>
            <div style={styles.search}>
              {/** 검색어 미입력 시 전체목록 노출 **/}
              {!showWord && !showResult && <></>}
              {/** 검색어 자동 완성 **/}
              {showWord && renderSearchWord()}
              {/** 검색 결과 **/}
              {showResult && renderSearchResult()}
            </div>
          </>
        }
        topButtonRight={16}
        showScrollToTop={true}
      />
      <CustomBottomSheet
        snapHeight={200}
        open={showSortBox}
        onClose={() => setShowSortBox(false)}
      >
        <div style={styles.sheet}>
          <button
            style={styles.close_btn}
            onClick={() => setShowSortBox(false)}
          >
            <CloseIcon />
          </button>
          <div style={styles.sheet_title_wrap}>
            <p style={styles.sheet_title}>
              {t("screen.brandCommunity.label.sortBy")}
            </p>
          </div>
          <div style={styles.sort_list}>
            <button
              style={styles.sort_item}
              onClick={() => {
                setSort(SearchSortType.LATEST);
                setShowSortBox(false);
              }}
            >
              <p style={{ ...styles.sort_text, ...(sort === SearchSortType.LATEST && styles.sort_active_text) }}>
                {t("screen.brandCommunity.sortType.latest")}
              </p>
            </button>
            <button
              style={styles.sort_item}
              onClick={() => {
                setSort(SearchSortType.POPULAR);
                setShowSortBox(false);
              }}
            >
              <p style={{ ...styles.sort_text, ...(sort === SearchSortType.POPULAR && styles.sort_active_text) }}>
                {t("screen.brandCommunity.sortType.popular")}
              </p>
            </button>
            <button
              style={styles.sort_item}
              onClick={() => {
                setSort(SearchSortType.ACCURACY);
                setShowSortBox(false);
              }}
            >
              <p style={{ ...styles.sort_text, ...(sort === SearchSortType.ACCURACY && styles.sort_active_text) }}>
                {t("screen.brandCommunity.sortType.accuracy")}
              </p>
            </button>
          </div>
        </div>
      </CustomBottomSheet>
    </div>
  );
});

export default BrandCommunityArticleSearchScreen;

export const styles: Styles = {
  search: {
    ...Colors.background_fff,
  },
  home_app: {
    height: 44,
    width: '100%',
    paddingLeft: 24,
    paddingRight: 90,
    borderRadius: 6,
    font_weight_400,
    ...Colors.background_fafafa,
    ...Texts.contents_text_3,
  },
  search_icon: {
    ...FlexBoxs.flex_1,
    ...Positions.absolute,
    right: '9.23%',
    height: 44
  },
  home_app_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    ...Spacings.padding_left_right_16,
  },
  title: {
    ...Texts.contents_text_34,
    ...Spacings.margin_bottom_20,
  },
  close_purple: {
    ...Positions.absolute,
    ...Positions.zIndex_top,
    right: '17.9%',
    height: 44,
    alignItems: "center",
  },

  //header
  header_wrapper: {
    ...Colors.background_fff,
  },
  header_option: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Arranges.between,
    ...Colors.background_fff,
    ...Spacings.padding_left_right_20,
    ...Spacings.padding_top_bottom_16,
  },
  header_cnt_desc_txt: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_400,
    ...Colors.font_999,
    ...Spacings.margin_left_6,
  },
  header_sort_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.start_h,
  },
  header_sort_btn_txt: {
    ...Texts.btn_text_1,
    ...Colors.font_666666,
  },
  tab_item_wrap: {
    ...Spacings.padding_right_left_20,
  },
  tab_sub_option: {
    ...Spacings.padding_top_10,
    ...Spacings.padding_bottom_12,
  },
  sub_item: {
    ...Spacings.padding_top_bottom_10,
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_right_6,
    ...Colors.border_color_d9d9d9,
    ...Colors.background_fff,
    ...Spacings.border_radius_300,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  sub_active_item: {
    ...Spacings.padding_top_bottom_10,
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_right_6,
    ...Spacings.border_radius_300,
    ...Colors.background_be69c3,
    ...Colors.border_color_be69c3,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  sub_txt: {
    ...Texts.contents_text_0,
    ...Colors.font_222,
    ...Texts.font_weight_normal,
  },
  sub_active_txt: {
    ...Texts.contents_text_0,
    ...Colors.font_fff,
    ...Texts.font_weight_bold,
  },

  auto_complete_list: {
    ...Positions.relative,
    ...Spacings.padding_left_right_16,
    ...Colors.background_fff,
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_8,
    zIndex: 100,
  },
  auto_complete_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_right_left_20,
    ...Spacings.padding_top_bottom_11,
    ...Arranges.center_h,
  },
  auto_complete_text: {
    ...Texts.contents_text_3,
    ...font_weight_400,
    ...Spacings.padding_left_6,
  },
  auto_complete_match_text: {
    ...Colors.font_a760b1,
  },
  sheet_title_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 32,
  },
  sheet_title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  sort_list: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Spacings.margin_top_16,
    ...Arranges.center_v_h,
  },
  sort_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_top_bottom_11,
  },
  sort_text: {
    ...Texts.contents_text_38,
    ...Colors.font_222,
  },
  sort_active_text: {
    ...Texts.font_weight_bold,
    ...Colors.font_be69c3,
  },

  // article
  article_list: {
    ...Spacings.margin_top_20,
    ...Spacings.margin_left_right_16,
  },
  article_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_bottom_19,
    ...Spacings.margin_bottom_24,
    ...Colors.border_color_eee,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
  },
  article_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    width: "100%",
  },
  article_info_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_top_8,
  },
  article_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  article_small_info: {
    ...FlexBoxs.flex,
    ...Arranges.start_h,
    ...FlexBoxs.horizon,
    ...FlexBoxs.flex_1,
  },
  small_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    width: "100%",
  },
  small_wrap_inner: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  like: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  like_text: {
    ...Spacings.margin_left_2,
  },
  article_item_text1: {
    ...Texts.contents_text_34,
    ...Texts.font_weight_500,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    lineClamp: '1',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-wrap'
  },
  article_item_text2: {
    textAlign: 'left',
    ...Spacings.margin_top_6,
    ...Texts.contents_text_0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  article_item_text3: {
    ...Texts.contents_text_9,
    ...Texts.font_weight_500,
    ...Colors.font_999,
  },
  article_top: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    width: "100%",
  },
  article: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_right_8,
  },
  bx_comm_img: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end'
  },
  article_image: {
    flex: 1,
    width: 72,
    height: 72,
    ...Spacings.border_radius_6,
  },
  article_comment: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  article_view: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Spacings.margin_left_right_7,
  },
  view_icon: {
    ...Spacings.margin_right_4,
  },
  comment_icon: {
    ...Spacings.margin_right_4,
  },
  article_item_text4: {
    ...Texts.contents_text_9,
    ...Colors.font_be69c3,
  },
  article_item_text5: {
    ...Texts.contents_text_9,
    ...Colors.font_999,
  },
  article_item_bar: {
    ...Spacings.margin_left_8,
    ...Spacings.margin_right_7,
    width: 1,
    height: 14,
    ...Colors.background_eeeeee,
  },
  bx_comm_popular_wrap: {
    ...FlexBoxs.flex,
    flex: 1,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    width: "100%",
  },
  bx_comm_pp_left: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    width: "100%",
  },

  num_comm_img: {
    ...FlexBoxs.flex,
    width: 22,
    height: 22,
    ...Positions.absolute,
    ...Colors.background_666,
    ...Spacings.border_radius_4,
    ...Arranges.center_v_h,
    opacity: 0.8,
    right: 0,
    bottom: 0,
  },
  num_comm_text: {
    ...Texts.contents_text_47,
    ...Colors.font_fff,
  },
  icon_lock: {
    marginBottom: -2,
    ...Spacings.margin_right_4,
  },

  item_title_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },

  flag_default: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "auto",
    ...Colors.background_f5f5f5,
    ...Spacings.border_radius_4,
    ...Spacings.padding_left_right_6,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.margin_right_6,
  },
  flag_default_text: {
    ...Texts.font_size_10,
    ...Colors.font_be69c3,
  },
  empty: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_top_16,
  },
  empty_wrap: {
    ...FlexBoxs.flex,
    marginTop: 70,
    ...FlexBoxs.flex_1,
    ...Arranges.center_v_h,
    ...FlexBoxs.vertical,
  },
  empty_text: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  return_brand_box: {
    display: "flex",
    ...Spacings.padding_top_bottom_12,
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_top_24,
    alignItems: "flex-start",
    gap: 10,
    borderRadius: 300,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#be69c3",
  },
  return_brand_text: {
    ...Colors.font_be69c3,
    textAlign: "center",
    fontSize: 14,
    fontWeight: "500",
    letterSpacing: -0.56,
  },
  latest_search: {
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_bottom_8,
    ...Spacings.margin_top_32,
  },
  latest_search_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  delete_all_btn_text: {
    ...Texts.btn_text_3,
    ...Texts.font_weight_500,
    ...Colors.font_666666,
  },
  latest_search_list: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
  },
  latest_search_item: {
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...FlexBoxs.horizon,
    width: '44%',
    height: 42,
  },
  latest_search_text: {
    ...Texts.contents_text_3,
    ...font_weight_400,
    paddingRight: 12,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    textAlign: 'start',
    paddingBottom: 4,
    paddingLeft: 2,
  },
  no_latest_search_text: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
    ...Colors.font_999,
  },
  sheet: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_16,
    ...Spacings.padding_bottom_19,
    ...Spacings.padding_left_right_20,
  },
  close_btn: {
    ...Positions.absolute,
    zIndex: 999,
    top: 6,
    right: 6,
  },
  total_cnt_text: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
};
