import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ReactComponent as FilterIcon } from '../../../assets/icons/mall/icon_mall_cate.svg'
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/mall/icon_arrow_right.svg'
import {
  CategoryFilterProduct,
  CategoryInfo,
  CategoryScreenType,
} from '../../../api/mallCategory/model'
import classes from './CategoryHeader.module.scss'

const CategoryHeader = observer(
  ({
    renderData,
    goToMallCategoryScreen,
    goToCategoryScreen,
  }: {
    renderData: CategoryFilterProduct
    goToMallCategoryScreen: () => void
    goToCategoryScreen: (
      type: CategoryScreenType,
      categoryId: number,
      categoryName: string
    ) => void
  }) => {
    return (
      <div className={classes.bx_category}>
        <div onClick={goToMallCategoryScreen} className={classes.bx_filter}>
          <FilterIcon />
        </div>
        <ArrowRightIcon />
        <div
          onClick={() =>
            goToCategoryScreen(
              CategoryScreenType.L,
              renderData.largeDisplayCategoryId,
              renderData.largeDisplayCategoryName ?? ''
            )
          }
        >
          <text className={classes.txt_category}>
            {renderData.largeDisplayCategoryName}
          </text>
        </div>

        {renderData.mediumDisplayCategoryId &&
          renderData.mediumDisplayCategoryId !== null && (
            <>
              <ArrowRightIcon />
              <div
                onClick={() =>
                  goToCategoryScreen(
                    CategoryScreenType.M,
                    renderData.mediumDisplayCategoryId || 0,
                    renderData.mediumDisplayCategoryName ?? ''
                  )
                }
              >
                <text
                  className={
                    renderData.smallDisplayCategoryId === null
                      ? classes.txt_category_bold
                      : classes.txt_category
                  }
                >
                  {renderData.mediumDisplayCategoryName}
                </text>
              </div>
            </>
          )}

        {renderData.smallDisplayCategoryId &&
          renderData.smallDisplayCategoryId !== null && (
            <>
              <ArrowRightIcon />
              <div>
                <text className={classes.txt_category_bold}>
                  {renderData.smallDisplayCategoryName}
                </text>
              </div>
            </>
          )}
      </div>
    )
  }
)

export default CategoryHeader
