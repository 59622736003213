import { useMemo } from 'react'
import { TrackFunction } from '../types'
import { Analytics, logEvent } from 'firebase/analytics'
import { analytics } from '../../../utils/remoteConfig'

type UseFirebaseAnalytics = () => {
  analytics: Analytics
  log: TrackFunction
}

export const useFirebaseAnalytics: UseFirebaseAnalytics = () =>
  useMemo(() => {
    const log: TrackFunction = (event, properties) => {
      logEvent(analytics, event, properties)
    }

    return {
      analytics: analytics,
      log,
    }
  }, [])
