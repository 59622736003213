import { useEffect, useState } from "react";
// import { View, Platform, StyleSheet, Text, TouchableOpacity, FlatList, NativeModules } from "react-native";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Assets, Colors, FlexBoxs, Icons, Positions, Spacings, Texts } from "../../../assets/styles";
// import Modal from "react-native-modal";
import Modal from "react-modal";
// import { responsiveWidth } from "react-native-responsive-dimensions";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
// import { getStatusBarHeight } from "react-native-status-bar-height";
import {ReactComponent as Close} from "../../../assets/icons/close.svg";
import { ReactComponent as DarkSpinner } from "../../../assets/animations/darkSpinner.svg";
import { ProductAsCenter } from "../../../api/product/model";
import AsCenter from "./AsCenter";
import { getProductAsCenters } from "../../../api/product/api";
import { useTranslation } from "react-i18next";
import { getDistance } from "../../../utils/geo";
// import { handleTopButtonScroll } from "../../../utils/common";
// import TopButton from "../../../components/common/TopButton";
import { PullToRefresh } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";

const AsCenterModal = ({
  isVisible,
  productId,
  productName,
  onRequestClose,
}: {
  isVisible: boolean;
  productId: number;
  productName: string;
  onRequestClose?: () => void;
}) => {
  const {
    modal,
    header_text,
    modal_body,
    icons,
    modal_header,
    modal_tag_text,
    modal_body_text,
    // modal_list,
    modal_body_title,
    // list_footer,
  } = styles;
  const { t } = useTranslation();
//   const insets = useSafeAreaInsets();
//   const statusBarHeight = getStatusBarHeight();

  const [asCenterList, setAsCenterList] = useState<ProductAsCenter[]>([]);
  const [isLoading, setIsLoading] = useState(false);
//   const [showTopButton, setShowTopButton] = useState<boolean>(false);
//   const ref = useRef(null);

  useEffect(() => {
    const initAsCenters = async () => {
      //const { RNCommon } = NativeModules;
      const res = await getProductAsCenters(productId);      
      const asCenterLists: ProductAsCenter[] = res == null ? [] : res;
    //   RNCommon.getLocation((res: { code: string; msg: string; param: { latitude: string; longitude: string } }) => {
    //     if (res.code == "9999" || (Number(res.param.latitude) == 0 && Number(res.param.longitude) == 0)) {
    //       setAsCenterList(asCenterLists);
    //     } else {
    //       const asCenterListAddedDistance = asCenterLists.map((item) => {
    //         return Object.assign(item, {
    //           distance: getDistance(
    //             Number(res.param.latitude),
    //             Number(res.param.longitude),
    //             Number(item.latitude),
    //             Number(item.longitude)
    //           ),
    //         });
    //       });
    //       const sortedAsCenterList = asCenterListAddedDistance.sort(
    //         (a: ProductAsCenter, b: ProductAsCenter) => a.distance - b.distance
    //       );
    //       setAsCenterList(sortedAsCenterList);
    //     }
    //   });
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            position => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                const asCenterListAddedDistance = asCenterLists.map((item) => {
                    return Object.assign(item, {
                    distance: getDistance(
                        Number(latitude),
                        Number(longitude),
                        Number(item.latitude),
                        Number(item.longitude)
                    ),
                    });
                });
                const sortedAsCenterList = asCenterListAddedDistance.sort(
                    (a: ProductAsCenter, b: ProductAsCenter) => a.distance - b.distance
                );
                setAsCenterList(sortedAsCenterList);
                    }, 
                    () => setAsCenterList(asCenterLists)
                );
      } else {
        setAsCenterList(asCenterLists);
      }
    };
    if (isVisible) {
      void initAsCenters();
    }
    setIsLoading(false);
  }, [isVisible, productId, isLoading]);

  const dismiss = () => {
    onRequestClose && onRequestClose();
  };

  const renderHeader = () => {
    return (
      <div style={modal_body_title}>
        <p style={modal_tag_text}>
          {productName}
          <p style={modal_body_text}>{t(`screen.product.label.asCenter.modalTitle1`)}</p>
        </p>
        <p style={modal_body_text}>{t(`screen.product.label.asCenter.modalTitle2`)}</p>
      </div>
    );
  };

  const renderItem = ({ item, index }: { item: ProductAsCenter; index: number }) => {
    return <AsCenter key={index} item={item} />;
  };

  return (
    <Modal
      isOpen={isVisible}
      // style={{
      //   margin: 0,
      //   top: insets.top,
      //   bottom: insets.bottom,
      //   left: 0,
      //   right: 0,
      // }}
      // hasBackdrop={false}
       onRequestClose={onRequestClose}
    >
      <div style={modal}>
        <div style={modal_header}>
          <p style={header_text}>{t(`screen.product.label.asCenter.modalTitle`)}</p>
          <button style={icons} onClick={dismiss}>
            <Close />
          </button>
        </div>
        <div style={modal_body}>
            <PullToRefresh
                onRefresh={async () => {
                    await sleep(1000);
                    setIsLoading(true);                    
                }}
                renderText={(status) => {
                    return (
                    <div>
                        {status === "refreshing" && (
                        <DarkSpinner style={{ width: 50, height: 50 }} />
                        )}
                    </div>
                    );
                }}
            >
                <div>
                    {renderHeader()}
                    {asCenterList?.map((item: ProductAsCenter, index: number) => renderItem({item, index}))}
                </div>
            </PullToRefresh>
          {/* <FlatList
            ref={ref}
            style={modal_list}
            windowSize={3}
            showsVerticalScrollIndicator={false}
            data={asCenterList}
            ListHeaderComponent={renderHeader}
            renderItem={renderItem}
            refreshing={isLoading}
            onRefresh={() => {
              setIsLoading(true);
            }}
            ListFooterComponent={() => <div style={list_footer} />}
            onScroll={(event) => {
              handleTopButtonScroll(
                event,
                () => {
                  setShowTopButton(true);
                },
                () => {
                  setShowTopButton(false);
                },
                ref
              );
            }}
          /> */}
        </div>
        {/* <TopButton showTopButton={showTopButton} ref={ref} bottomInset={Platform.OS === "ios" ? 150 : 80} /> */}
      </div> 
    </Modal>
  );
};

export default AsCenterModal;

const styles : Styles = {
  bar: {
    height: 6,
    ...Colors.background_f5f5f5,
    ...Spacings.margin_top_40,
  },
  modal: {
    // width: responsiveWidth(100),
    width: '100%',
    ...Colors.background_fff,
    ...Colors.border_color_transparent,
    ...Arranges.margin_center_v_h,
    ...Assets.modal_box_shadow,
    ...Spacings.padding_top_10,
  },
  modal_header: {
    ...FlexBoxs.flex,
    height: 48,
    ...Arranges.center_v_h,
    ...Positions.relative,
  },
  modal_body: {
    ...Spacings.padding_left_right_16_responsive,
  },
  icons: {
    ...Icons.icon_32,
    ...Positions.absolute,
    ...Positions.top_6,
    ...Positions.right_10,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  modal_tag_text: {
    ...FlexBoxs.flex,
    ...Texts.contents_text_2,
    ...Colors.font_a760b1,
  },
  modal_body_text: {
    ...Texts.contents_text_2,
  },
  modal_list: {
    ...Spacings.padding_bottom_100
  },
  modal_body_title: {
    ...Spacings.margin_bottom_40,
    ...Spacings.margin_top_20,
  },
  indicator: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_left_right_7,
  },
  list_footer: {
    ...Spacings.padding_bottom_150
  },
};
