// 네인티브 소스
// import React, { useEffect, useRef, useState } from "react";
// import {
//   View,
//   Text,
//   StyleSheet,
//   Platform,
//   FlatList,
//   TouchableOpacity,
//   ActivityIndicator,
//   RefreshControl,
//   useWindowDimensions,
// } from "react-native";
// import { t } from "i18next";
// import { observer } from "mobx-react";
// import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
// import { getStatusBarHeight } from "react-native-status-bar-height";
// import { responsiveWidth } from "react-native-responsive-dimensions";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
// import { TitleHeader } from "../../../components/common";
// import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
// import Config from "react-native-config";
// import { HeaderItem } from "../../../constants/Header";
// import { useIsFocused } from "@react-navigation/native";
// import { navigate } from "../../../navigation/NavigationFunction";
// import { ContentsNotificationModal, NotificationModal } from "../../../components/modals";
// import DdayText from "./DdayText";
// import dayjs from "dayjs";
// import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
// import MissionStore from "../../../store/MissionStore";
// import { getDateMD } from "../../../utils/datetime";
// import HtmlContentComponent from "./HtmlContentComponent";
// import DetailInfoComponent from "./DetailInfoComponent";
// import { Color } from "../../../assets/styles/variable";
// import ShowWinnerButton from "./ShowWinnerButton";
// import EmptyImage from "../../../assets/images/nodata_search_grid_bg.svg";
// import { MISSION_TYPE, MISSON_STATUS } from "../../../constants/mission";
// import { Styles } from "../../../../types/Style";
// import { ApplyInfo, MissionParticipateRequest } from "../../../api/mission/model";
// import { putParticipantInfo } from "../../../api/mission/api";
// import { Post } from "../../../api/feed/model";
// import { SuccessOrNot } from "../../../constants/Common.enum";
// import { ShareInfo } from "../../../utils/share";
// import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
// import BadgeImg from "../../../assets/icons/badge_img.svg";
// import BadgeVideo from "../../../assets/icons/badge_video.svg";
// import { handleTopButtonScroll } from "../../../utils/common";
// import MissionUserAuthModal from "../common/modal/MissionUserAuthModal";
// import { useTracker } from "../../../hooks/tracker";
// import { AlarmSettingList } from "../../../api/setting/alarm/model";
// import { getAlarmDefaultSetting } from "../../../api/setting/alarm/api";
// import { NotifyModalContext } from "../common/model/NotifyModalContext";
// import UploadStore from "../../../store/upload/UploadStore";
// import NotificationModalWithHideCallback from "../../../components/modals/NotificationModalWithHideCallback";
// import PostWriteStore from "../../../store/PostWriteStore";
// import { VoltPolicyCode } from "../../../constants/Volt.enum";
// import VoltStore from "../../../store/VoltStore";
// import { ShortPop } from "../../volt/presenter";
// import { webview_script_and, webview_script_ios } from "../../../utils/webviewCommon";
// import WebviewWithNavigateButtons from "../../../components/common/WebviewWithNavigateButtons";
// import WebView from "react-native-webview";
// import { webViewActivityIndicator } from "../../../components/common/webview/WebViewActivityIndicator";
// import MissionApplyCompletedPopup from "../common/popup/MissionApplyCompletedPopup";

import "./PictureMission.scss";
import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { observer } from "mobx-react";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { TitleHeader } from "../../../components/common";
import { HeaderItem } from "../../../constants/Header";
// import { ContentsNotificationModal, NotificationModal } from "../../../components/modals";
import DdayText from "./DdayText";
import dayjs from "dayjs";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import MissionStore from "../../../store/MissionStore";
import { getDateMD } from "../../../utils/datetime";
import HtmlContentComponent from "./HtmlContentComponent";
import DetailInfoComponent from "./DetailInfoComponent";
import { Color } from "../../../assets/styles/variable";
import ShowWinnerButton from "./ShowWinnerButton";
import {
  MISSION_SUB_TYPE,
  MISSION_TYPE,
  MISSON_STATUS,
} from "../../../constants/mission";
import {
  ApplyInfo,
  MissionParticipateRequest,
} from "../../../api/mission/model";
import { putParticipantInfo } from "../../../api/mission/api";
import { Post } from "../../../api/feed/model";
import { SuccessOrNot } from "../../../constants/Common.enum";
import { ShareInfo } from "../../../utils/share";
import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
import BadgeImg from "../../../assets/icons/badge_img.svg";
import BadgeVideo from "../../../assets/icons/badge_video.svg";
import { handleTopButtonScroll } from "../../../utils/common";
import MissionUserAuthModal from "../common/modal/MissionUserAuthModal";
// import { useTracker } from "../../../hooks/tracker";
import { AlarmSettingList } from "../../../api/setting/alarm/model";
import { getAlarmDefaultSetting } from "../../../api/setting/alarm/api";
import { NotifyModalContext } from "../common/model/NotifyModalContext";
// import UploadStore from "../../../store/upload/UploadStore";

import NotificationModalWithHideCallback from "../../../components/modals/NotificationModalWithHideCallback";
// import PostWriteStore from "../../../store/PostWriteStore";
import { VoltPolicyCode } from "../../../constants/Volt.enum";
// import VoltStore from "../../../store/VoltStore";
// import { ShortPop } from "../../volt/presenter";
// import { webview_script_and, webview_script_ios } from "../../../utils/webviewCommon";
// import WebviewWithNavigateButtons from "../../../components/common/WebviewWithNavigateButtons";
// import WebView from "react-native-webview";
// import { webViewActivityIndicator } from "../../../components/common/webview/WebViewActivityIndicator";
// import MissionApplyCompletedPopup from "../common/popup/MissionApplyCompletedPopup";

//추가
import { useTranslation } from "react-i18next";
import { Platform, PlatformTypes } from "../../../native/platform";
import { Styles } from "../../../assets/types/Style";
import NotificationModal from "../../../components/modals/NotificationModal";
import ContentsNotificationModal from "../../../components/modals/ContentsNotificationModal";
import {
  webview_script_and,
  webview_script_ios,
} from "../../../utils/webviewCommon";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { goToAppDetail } from "../../../utils/appstore";
import "./CommonMission.scss";
import { postMessageCommon } from "../../../native/WebViewInterface";
import { ActHistoryRequest } from "../../../api/data/model";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import CommonStore from "../../../store/CommonStore";
import { sendActHistory } from "../../../api/data/api";
import { useWindowScroll } from "react-use";

// const PictureMission = () => {
const PictureMission = observer(() => {
  // const insets = useSafeAreaInsets();
  const { t } = useTranslation();
  const now = dayjs();
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const BASIC_PARAM = {
    missionTypeCode: MISSION_TYPE.PICTURE,
  };

  const [popupContents, setPopupContents] = useState(<></>);
  const [pageIndex, setPageIndex] = useState(0);
  const [noticeTitle, setNoticeTitle] = useState("");
  const [noticeMessage, setNoticeMessage] = useState("");
  const [showTopButton, setShowTopButton] = useState<boolean>(false);
  const [showShortModal, setShowShortModal] = useState(false);
  const ref = useRef(null);

  const [webviewOnload, setWebviewOnload] = useState(true);
  const [pictureMissionLoading, setPictureMissionLoading] = useState(true);
  const [registering, setRegistering] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  // const { track } = useTracker();

  const { width, height } = useWindowDimensions();

  const webViewInterfaceFunction = (jsonString: string) => {
    if (!postMessageCommon(jsonString)) {
      // 화면에서 따로 필요한 동작
      setShowModal(true);
    }
  };

  useEffect(() => {
    (window as any).ReactNativeWebView.postMessage = webViewInterfaceFunction;
  }, []);

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {
      function handleResize() {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const [webviewHeight, setWebviewHeight] = useState(height);
  // const wvRef = useRef<WebView>(null);
  // const padding = responsiveWidth(5.1);
  const padding = 5;

  const [notifyContext, setNotifyContext] = useState<NotifyModalContext>({
    type: "",
    isShow: false,
    title: "",
    contents: "",
    needTwoButton: false,
    needParentModalCloseByCallback: false,
    needParentModalOpenByCallback: false,
  });

  // const { userVoltInfo, getUserVoltInfo } = VoltStore;

  const {
    missionDetail,
    postLoading,
    loadMore,
    missionPostData,
    showNotParticipatePopup,
    showCompletePopup,
    showNoticePopup,
    showInfoModal,
    showContentsPopup,
    stopSearchList,
    missionPostList,
    getMissionDetail,
    setLoadMore,
    getMissionPost,
    setShowNotParticipatePopup,
    setShowCompletePopup,
    setShowNoticePopup,
    setShowInfoModal,
    setShowContentsPopup,
    updateParticipantInfo,
  } = MissionStore;

  const { y } = useWindowScroll();

  useEffect(() => {
    setTimeout(() => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
        actPageCode: ActPageCode.MISSION_DETAIL,
        actSourcePageCode: CommonStore.fixedPreviousRouteName,
        attr1: (y === 0).toString(),
      };
      void sendActHistory(actHistory);
    }, 1000);
  }, []);

  // useEffect(() => {
  //   void getUserVoltInfo();
  // }, [getUserVoltInfo]);

  const isPossibleApply = async (): Promise<boolean> => {
    const mission = MissionStore.missionDetail;

    // if (!UploadStore.isDefaultStatus) {
    //   setRegistering(true);
    //   return false;
    // } else {
    //   PostWriteStore.clear();
    // }

    if (!checkMissionPermission()) return false;

    if (now.isAfter(mission.missionEndDate)) {
      setPopupContents(
        <p style={styles.text_modal}>
          {t("screen.mission.message.endMission")}
        </p>
      );
      setShowContentsPopup(true);
      return false;
    }

    if (mission.missionApproveYn == "N") {
      setPopupContents(
        <p style={styles.text_modal}>
          {t("screen.mission.message.endMission")}
        </p>
      );
      setShowContentsPopup(true);
      return false;
    }

    if (missionDetail.participationStatus == MISSON_STATUS.COMPLETED) {
      setNotifyContext({
        isShow: true,
        title: t("screen.mission.label.alarm"),
        contents: t("screen.mission.message.error_dup"),
        textAlign: "center",
      });
      setShowNotParticipatePopup(true);
      return false;
    }

    //마켓팅 수신동의 여부 확인 (설정>알림설정>이메일,문자메시지,앱푸시)
    const alarmList: AlarmSettingList = await getAlarmDefaultSetting();
    if (
      alarmList.emailAllowYn !== "Y" ||
      alarmList.smsAllowYn !== "Y" ||
      alarmList.pushAllowYn !== "Y"
    ) {
      setNotifyContext({
        type: "alarmAllowCheck",
        title: t("screen.mission.label.alarm"),
        contents: t("screen.mission.message.alarmAllowCheck"),
        isShow: true,
        needParentModalOpenByCallback: false,
        needParentModalCloseByCallback: false,
        needTwoButton: true,
        defaultButtonText: t("screen.mission.button.goToUpdate"),
        textAlign: "center",
      });
      setShowNotParticipatePopup(true);
      return false;
    }

    // if (missionDetail.voltPolicyCode === VoltPolicyCode.MISSION_USE) {
    //   await getUserVoltInfo();
    //   if (userVoltInfo && missionDetail.voltAmount && userVoltInfo.voltAmount < missionDetail.voltAmount) {
    //     handleShortModal();
    //     return false;
    //   }
    // }

    return MissionStore.isPossibleMissionApply(missionDetail.missionId)
      .then((isPossibleApply) => {
        if (isPossibleApply) {
          return true;
        }
        setPopupContents(
          <p style={styles.text_modal}>
            {t("screen.mission.message.endMission")}
          </p>
        );
        setShowContentsPopup(true);
        return false;
      })
      .catch(() => {
        setPopupContents(
          <p style={styles.text_modal}>{t("common.error.server")}</p>
        );
        setShowContentsPopup(true);
        return false;
      });
  };

  const checkMissionPermission = () => {
    if (missionDetail.canLevelYn != "Y") {
      setPopupContents(
        <p style={styles.text_modal}>
          {t("screen.mission.label.thisMission")}{" "}
          {t("screen.mission.label.level")}
          <p style={{ color: "#be69c3" }}>
            {missionDetail.targetMemberLevel}
          </p>{" "}
          {t("screen.mission.message.cannotLevel")}
        </p>
      );
      setShowContentsPopup(true);
      return false;
    }

    if (missionDetail.canBadgeYn != "Y") {
      setPopupContents(
        <p style={styles.text_modal}>
          {t("screen.mission.label.thisMission")}{" "}
          <p style={{ color: "#be69c3" }}>{missionDetail.targetMemberBadge}</p>{" "}
          {t("screen.mission.message.cannotBadge")}
        </p>
      );
      setShowContentsPopup(true);
      return false;
    }

    return true;
  };

  const checkMissionCompleted = () => {
    setTimeout(() => {
      !MissionStore.isNoneParticipantInfoType &&
      missionDetail.participantInfo == null
        ? moveToWriteMissionInfo()
        : missionDetail.participationStatus == MISSON_STATUS.COMPLETED
        ? setShowCompletePopup(true)
        : moveToWritePost(BASIC_PARAM);
    }, 100);
  };

  const moveToModifyMissionInfo = () => {
    missionDetail.participantInfo == null
      ? setShowNotParticipatePopup(true)
      : setShowInfoModal(true);
  };

  const moveToWriteMissionInfo = () => {
    setShowNotParticipatePopup(false);
    setTimeout(() => {
      if (!checkMissionPermission()) return;
    }, 100);
    setTimeout(() => setShowInfoModal(true), 100);
  };

  // const onClickDefaultButton = () => {
  //   setShowNotParticipatePopup(false);

  //   if (notifyContext.type && notifyContext.type == "modifyApplyInfo") {
  //     onclickGoMission();
  //   }
  //   if (notifyContext.type && notifyContext.type == "alarmAllowCheck") {
  //     navigate("AlarmScreen");
  //   }
  // };

  const onClickDefaultButton = () => {
    setShowNotParticipatePopup(false);

    if (notifyContext.type && notifyContext.type == "modifyApplyInfo") {
      return onclickGoMission();
    }
    if (notifyContext.type && notifyContext.type == "alarmAllowCheck") {
      return goTo("AlarmScreen");
    }
  };

  // const onClickModifyInfoButton = () => {
  //   if (MissionStore.missionDetail.participantInfo == null) {
  //     setNotifyContext({
  //       type: "modifyApplyInfo",
  //       title: "",
  //       contents: t("screen.mission.message.modifyPrivacyCheck"),
  //       isShow: true,
  //       needParentModalOpenByCallback: false,
  //       needParentModalCloseByCallback: false,
  //       needTwoButton: true,
  //       defaultButtonText: "",
  //     });
  //     MissionStore.setShowNotParticipatePopup(true);
  //   } else {
  //     MissionStore.setShowInfoModal(true);
  //   }
  // };

  const onClickModifyInfoButton = () => {
    if (MissionStore.missionDetail.participantInfo == null) {
      setNotifyContext({
        type: "modifyApplyInfo",
        title: "",
        contents: t("screen.mission.message.modifyPrivacyCheck"),
        isShow: true,
        needParentModalOpenByCallback: false,
        needParentModalCloseByCallback: false,
        needTwoButton: true,
        defaultButtonText: "",
      });
      MissionStore.setShowNotParticipatePopup(true);
    } else {
      MissionStore.setShowInfoModal(true);
    }
  };

  const onclickGoMission = () => {
    switch (Platform.OS) {
      case PlatformTypes.WEB:
      case PlatformTypes.WINDOWS_WEB:
      case PlatformTypes.MACOS_WEB:
        goToAppDetail();
        break;
      default:
        window.open("https://app2.220cordncode.com/IYMzZqsaaLb");
        break;
    }

    // goToAppDetail();
    // track("submit_mission", {
    //   mission_id: missionDetail.missionId,
    //   mission_title: missionDetail.missionTitle,
    //   mission_type: missionDetail.missionTypeCode,
    //   participation_count: missionDetail.participationCount,
    // });

    if (MissionStore.participationLimitCheck()) return;

    void isPossibleApply().then((res) => {
      if (res) {
        checkMissionCompleted();
      }
    });
  };

  const moveToWritePost = (params: MissionParticipateRequest) => {
    // navigate("PostWriteScreen", { missionData: missionDetail, missionParam: params });
    goTo("PostWriteScreen");
  };

  const moveToPostDetail = (index: number) => {
    // navigate("FeedScreen", {
    //   itemIndex: index,
    //   feedParentTypeCode: "MISSION_POSTS",
    //   extraData: { missionId: missionDetail.missionId },
    // });
    goTo("FeedScreen");
  };

  const updateInfo = async (info?: ApplyInfo) => {
    let missionParams: MissionParticipateRequest | undefined;

    if (info) {
      missionParams = {
        missionTypeCode: MISSION_TYPE.PICTURE,
        participantInfo: {
          participantName: info.name,
          participantPhoneNumber: info.mobile,
          participantAddress: info.address,
          participantAddressDetail: info.detailAddress,
          infoAgreeYn: info.infoCollectAgreed ? "Y" : "N",
          provideToThirdAgreeYn: info.provideToThirdAgreed ? "Y" : "N",
        },
      };
    } else if (missionDetail.participantInfo) {
      missionParams = BASIC_PARAM;
    }

    if (missionParams) {
      if (missionDetail.participantInfo == null) {
        // 최초 등록 시
        moveToWritePost(missionParams);
      } else if (missionParams.participantInfo) {
        // 수정시
        const result = await putParticipantInfo(
          missionDetail.missionId,
          missionParams.participantInfo
        );
        if (result.successOrNot === SuccessOrNot.Y) {
          updateParticipantInfo(missionParams.participantInfo);
          setNoticeTitle(t("screen.notificationModal.label.alert"));
          setNoticeMessage(t("screen.mission.message.infoModified"));
        } else {
          setNoticeMessage(t("common.error.server"));
        }
        setShowNoticePopup(true);
      }
    }
  };

  const pictureMissionMainView = () => {
    if (!missionDetail.missionDescHtml) {
      return (
        <div className="image_container">
          <FastImageWithFallback
            // accessibilityLabel="Mission image"
            source={{
              uri: `${PUBLIC_BUCKET_URL}${
                missionDetail.missionDescImage || ""
              }`,
            }}
            className="image"
            // style={[styles.image]}
          />
        </div>
      );
    } else {
      return (
        <div className="mission_desc_html">
          <div
            onClick={() => {
              setShowModal(true);
            }}
          >
            {/* dangerouslySetInnerHTML 속성을 사용하여 HTML 문자열을 변환 */}
            {/* <div
              dangerouslySetInnerHTML={{
                __html: missionDetail.missionDescHtml,
              }}
            /> */}
            <div className="iframe-wrapper">
              <iframe
                srcDoc={missionDetail.missionDescHtml}
                style={{ border: 0, width: "100%", height: "100%" }}
                onLoad={(e: any) => {
                  e.target.style.height =
                    e.target.contentWindow.document.body.scrollHeight +
                    10 +
                    "px";
                }}
                allow="geolocation"
              />
              <div className="overlay" />
            </div>
          </div>
        </div>

        // <WebviewWithNavigateButtons
        //   webViewRef={wvRef}
        //   ref={wvRef}
        //   source={{
        //     html: `<meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0">
        //                 <style>
        //                 body{margin-left:0; margin-right:0;}
        //                 p:not(:has(img)){padding-left:${padding}; padding-right:${padding}; font-size: 14px}
        //                 </style>
        //                 <body>${
        //                   missionDetail.missionDescHtml +
        //                   // (Platform.OS === "ios" ? webview_script_ios : webview_script_and)
        //                   (Platform.OS === "ios_app" ? webview_script_ios : webview_script_and)
        //                 }</body>`,
        //   }}
        //   onSetStateFunction={setWebviewHeight}
        //   onLoad={() => {
        //     wvRef.current?.postMessage("complete");
        //     setTimeout(() => {
        //       setWebviewOnload(false);
        //     }, 100);
        //   }}
        //   scrollEnabled={false}
        //   style={{ opacity: 0.99, minHeight: 1, width: width, height: webviewHeight }}
        // />
      );
    }
  };

  const renderParticipationButton = () => {
    if (
      now.isBefore(missionDetail.missionEndDate) ||
      now.isSame(missionDetail.missionEndDate)
    ) {
      return (
        // <TouchableWithAuthCheck
        <button
          // style={[styles.footer_btn, { height: Platform.OS === "ios" ? insets.bottom + 56 : 56 }]}
          onClick={onclickGoMission}
          className="footer_btn"
          // accessibilityLabel={"Participate in mission"}
        >
          {/* <p style={[styles.footer_btn_text]}>{t("screen.mission.button.goMission")}</p> */}
          <p>{t("screen.mission.button.goToApp")}</p>
          {/* </TouchableWithAuthCheck> */}
        </button>
      );
    } else if (
      now.isAfter(missionDetail.missionEndDate) &&
      missionDetail.lotteryYn == "N"
    ) {
      return (
        <button
          // style={[styles.footer_btn, { height: Platform.OS === "ios" ? insets.bottom + 56 : 56 }]}
          onClick={onclickGoMission}
          className="footer_btn"
          // accessibilityLabel={"Participate in mission"}
        >
          {/* <p style={[styles.footer_btn_text]}>{t("screen.mission.button.goMission")}</p> */}
          <p> {t("screen.mission.button.goToAppResult")}</p>
          {/* </TouchableWithAuthCheck> */}
        </button>
      );
    } else if (
      now.isAfter(missionDetail.lotteryDate) &&
      missionDetail.lotteryYn == "Y"
    ) {
      return (
        // <ShowWinnerButton isDetail={true} missionId={missionDetail.missionId} />
        <button
          // style={[styles.footer_btn, { height: Platform.OS === "ios" ? insets.bottom + 56 : 56 }]}
          onClick={onclickGoMission}
          className="footer_btn"
          // accessibilityLabel={"Participate in mission"}
        >
          {/* <p style={[styles.footer_btn_text]}>{t("screen.mission.button.goMission")}</p> */}
          <p> {t("screen.mission.button.goToAppResult")}</p>
          {/* </TouchableWithAuthCheck> */}
        </button>
      );
    } else {
      return <></>;
    }
  };

  const handleMoreRequest = () => {
    if (!stopSearchList) {
      const cursor =
        missionPostList.length > 0
          ? missionPostList[missionPostList.length - 1].missionParticipationId
          : null;
      setLoadMore(true);
      void getMissionPost(
        missionDetail.missionId,
        cursor || null,
        MissionStore.displayType
      );
    }
  };

  const handleShortModal = () => {
    setShowShortModal(!showShortModal);
  };

  // useEffect(() => {
  //   setWebviewOnload(true);
  //   setPageIndex(0);
  //   MissionStore.setLoadMore(true);
  //   MissionStore.setIsEndFirstPostingLoad(false);
  //   void MissionStore.getMissionPost(
  //     MissionStore.missionDetail.missionId,
  //     null,
  //     MissionStore.displayType
  //   )
  //     .then(() => {
  //       if (!MissionStore.missionDetail.missionDescHtml) {
  //         setWebviewOnload(false);
  //       }
  //     })
  //     .finally(() => {
  //       MissionStore.setIsEndFirstPostingLoad(true);
  //     });
  // }, []);

  const shareInfo: ShareInfo = {
    title: missionDetail.missionTitle,
    screen: ShareScreenCode.MISSION_DETAIL_SCREEN,
    targetId: missionDetail.missionId,
    imageUrl: missionDetail?.thumbnailFilePath
      ? missionDetail.thumbnailFilePath
      : missionDetail.missionDescImage,
    path: `mission/${missionDetail.missionId}`,
  };

  useEffect(() => {
    if (!postLoading && !webviewOnload && pictureMissionLoading) {
      setPictureMissionLoading(false);
    }
  }, [postLoading, webviewOnload, pictureMissionLoading]);

  const pictureMissionTitle = () => {
    return (
      <div style={styles.title_wrap} className="title_wrap">
        <div style={{ flexDirection: "row" }} className="coupon">
          <DdayText />
          {missionDetail.voltPolicyCode == VoltPolicyCode.MISSION_USE && (
            <div style={styles.volt_wrap} className="volt_wrap">
              <p style={styles.volt_text} className="volt_text">
                {t("screen.mission.label.missionApplyUseVolt", {
                  voltAmount: missionDetail.voltAmount || 0,
                })}
              </p>
            </div>
          )}
        </div>
        {/* <p style={styles.title} accessibilityLabel="Mission title"> */}
        <p style={styles.title} className="title">
          {missionDetail.missionTitle}
        </p>
      </div>
    );
  };

  // const pictureMissionFooter = () => {
  //   if (missionDetail.participationNumberDisplayYn === "N") {
  //     return <></>;
  //   }

  // const pictureMissionFooter = () => {
  //   if (missionDetail.participationNumberDisplayYn === "N") {
  //     return <></>;
  //   }

  //   return (
  //     <>
  //       {/* JSX 내용 */}
  //     </>
  //   );
  // };

  const pictureMissionFooter = () => {
    if (missionDetail.participationNumberDisplayYn === "N") {
      return <></>;
    }

    return (
      <>
        <div style={styles.bar} className="bar" />
        <div style={styles.challenger_list} className="challenger_list">
          <div
            style={styles.challenger_title_wrap}
            className="challenger_title_wrap"
          >
            <p
              className="challenger_title_text1"
              // accessibilityLabel="Mission challenger count"
            >
              {t("screen.mission.label.challengers")}
              <p className="challenger_title_text2">
                {missionPostData?.totalParticipantCount || 0}
              </p>
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div style={styles.picture_mission} id="common_mission">
        {missionDetail.missionTypeCode === MISSION_TYPE.PICTURE && (
          <TitleHeader
            title={t("screen.mission.label.pictureMission")}
            rightItem={HeaderItem.SHARE}
            shareInfo={shareInfo}
            isHome
          />
        )}

        {missionDetail.missionTypeCode === MISSION_TYPE.SURVEY && (
          <TitleHeader
            title={t("screen.mission.label.survey")}
            rightItem={HeaderItem.SHARE}
            shareInfo={shareInfo}
            isHome
          />
        )}

        {missionDetail.missionTypeCode === MISSION_TYPE.COMMENT && (
          <TitleHeader
            title={t("screen.mission.label.comment")}
            rightItem={HeaderItem.SHARE}
            shareInfo={shareInfo}
            isHome
          />
        )}

        {missionDetail.missionTypeCode === MISSION_TYPE.COUPON && (
          <TitleHeader
            title={t("screen.mission.label.coupon")}
            rightItem={HeaderItem.SHARE}
            shareInfo={shareInfo}
            isHome
          />
        )}

        {missionDetail.missionTypeCode === MISSION_TYPE.RAFFLE && (
          <TitleHeader
            title={t("screen.mission.label.raffle")}
            rightItem={HeaderItem.SHARE}
            shareInfo={shareInfo}
            isHome
          />
        )}

        {missionDetail.missionTypeCode === MISSION_TYPE.RECOMMENDER && (
          <TitleHeader
            title={t("screen.mission.label.recommender")}
            rightItem={HeaderItem.SHARE}
            shareInfo={shareInfo}
            isHome
          />
        )}

        {missionDetail.missionTypeCode === MISSION_TYPE.STAMP && (
          <TitleHeader
            title={t("screen.mission.label.stamp")}
            rightItem={HeaderItem.SHARE}
            shareInfo={shareInfo}
            isHome
          />
        )}

        {missionDetail.missionTypeCode === MISSION_TYPE.COMMON &&
          missionDetail.missionSubType === MISSION_SUB_TYPE.LOTTERY && (
            <TitleHeader
              title={t("screen.mission.label.randomCoupon")}
              rightItem={HeaderItem.SHARE}
              shareInfo={shareInfo}
              isHome
            />
          )}

        {missionDetail.missionTypeCode === MISSION_TYPE.COMMON &&
          missionDetail.missionSubType !== MISSION_SUB_TYPE.LOTTERY && (
            <TitleHeader
              rightItem={HeaderItem.SHARE}
              shareInfo={shareInfo}
              isHome
            />
          )}

        {MissionStore.isEndFirstPostingLoad && (
          <div className="container">
            <div>
              {pictureMissionTitle()}
              <div style={styles.desc_wrap} className="desc_wrap">
                {pictureMissionMainView()}
              </div>
              <div style={styles.contents_wrap} className="contents_wrap">
                <HtmlContentComponent
                  title={t("screen.mission.label.applyWay")}
                  contents={MissionStore.missionDetail.applyMethod || ""}
                />
                <DetailInfoComponent />
                <HtmlContentComponent
                  title={t("screen.mission.label.precaution")}
                  contents={MissionStore.missionDetail.precaution || ""}
                />
              </div>
            </div>
          </div>
        )}

        {MissionStore.isEndFirstPostingLoad && renderParticipationButton()}
      </div>
      {showModal && (
        <NotificationModal
          isVisible={showModal}
          contents1={t("common.message.useApp")}
          onRequestClose={() => {
            setShowModal(false);
          }}
          defaultButtonText={t("common.label.check")}
        />
      )}
    </>
  );
});

export default PictureMission;

export const styles: Styles = {
  text_modal: {
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Spacings.margin_bottom_12,
    ...Colors.font_222,
  },
  picture_mission: {
    ...FlexBoxs.flex_1,
    // ...Platform.select({
    //   ios: {
    //     marginTop: getStatusBarHeight,
    //   },
    // }),
  },
  title_wrap: {
    ...Spacings.margin_top_20,
    ...Spacings.padding_left_right_20,
  },
  icon_wrap: {
    ...FlexBoxs.horizon,
  },
  expire_icon: {
    ...Arranges.center_v_h,
    ...Colors.background_999999,
    ...Spacings.border_radius_6,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.padding_right_left_8,
  },
  icon_text: {
    ...Texts.contents_text_6,
    ...Colors.font_222,
  },
  title: {
    ...Spacings.margin_top_8,
    ...Spacings.margin_bottom_8,
    ...Texts.contents_text_4,
    ...Texts.font_weight_500,
  },
  image: {
    ...Spacings.margin_bottom_16,
    width: "100%",
    height: undefined,
    aspectRatio: 1,
  },
  desc_wrap: {
    ...Spacings.margin_bottom_20,
  },
  contents_wrap: {
    ...Spacings.padding_bottom_32,
    ...Spacings.padding_left_right_20,
  },
  contents_item: {
    ...Spacings.margin_bottom_24,
  },
  label: {
    ...Texts.contents_text_34,
    ...Spacings.margin_bottom_6,
  },
  contents: {
    ...Texts.contents_text_3,
    lineHeight: 22,
    width: "100%",
  },
  list_contents: {
    display: "flex",
    ...FlexBoxs.horizon,
  },
  dot_wrap: {
    ...Arranges.start_v,
    ...Spacings.padding_top_10,
  },
  dot: {
    width: 3,
    height: 3,
    ...Colors.background_666,
    ...Spacings.border_radius_4,
    ...Spacings.margin_right_4,
  },
  bar: {
    height: 6,
    ...Colors.background_f5f5f5,
  },
  challenger_list: {
    ...Spacings.margin_top_40,
  },
  challenger_title_wrap: {
    ...Spacings.padding_right_left_4,
    ...Spacings.padding_left_right_20,
  },
  challenger_title_text1: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  challenger_title_text2: {
    ...Colors.font_a760b1,
  },
  list_box: {
    ...FlexBoxs.wrap,
    // ...FlexBoxs.flex_1,
    // ...FlexBoxs.horizon,
    // ...Arranges.between,
    ...Spacings.margin_top_12,
    // paddingBottom: 100,
    // ...Spacings.padding_left_right_16,
  },
  list_img_wrap: {
    ...Spacings.margin_bottom_32,
  },
  list_img: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_bottom_10,
    // width: responsiveWidth(44.5),
    // height: responsiveWidth(44.5),
    ...Spacings.border_radius_6,
    ...Spacings.margin_right_8,
  },
  description: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_right_left_4,
    // width: responsiveWidth(44.5),
  },
  des_txt: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    ...Spacings.margin_bottom_3,
  },
  des_id: {
    ...Texts.button_text_9,
    ...Colors.font_999,
  },
  badge_img: {
    ...Positions.absolute,
    top: 8,
    right: 8,
  },
  footer_btn: {
    ...Colors.background_222,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
  },
  footer_btn_text: {
    ...Texts.btn_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  txt_bar: {
    width: 1,
    height: 13,
    ...Colors.background_c4c4c4,
    ...Spacings.margin_left_8,
    ...Spacings.margin_right_8,
  },
  txt_bar_top: {
    marginTop: 4,
  },
  contents_txt_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  contents_txt_wrap_top: {
    ...FlexBoxs.horizon,
  },
  vertical: {
    ...FlexBoxs.vertical,
  },
  modify_btn: {
    width: 96,
    height: 35,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_6,
    ...Spacings.margin_top_10,
    borderColor: "rgba(34,34,34,0.1)",
    borderWidth: 1,
  },
  modify_btn_text: {
    ...Texts.btn_text_3,
    ...Colors.font_666666,
  },
  expire_text: {
    ...Colors.font_fff,
  },
  inactive_btn: {
    ...Colors.background_eeeeee,
  },
  inactive_btn_text: {
    ...Colors.font_be69c3,
  },
  nothing_result_img: {
    ...Arranges.center_v_h,
  },
  empty_wrap: {
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
    position: "absolute",
    alignSelf: "center",
    top: 150,
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  empty_list_box: {
    ...Spacings.padding_left_right_20,
  },

  volt_wrap: {
    ...Arranges.center_h,
    ...Spacings.margin_left_4,
    display: "flex",
    backgroundColor: "#F7F4FA",
    borderRadius: 4,
  },
  volt_text: {
    ...Arranges.center_v_h,
    ...Spacings.border_radius_6,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.padding_right_left_8,
    ...Texts.font_size_12,
    ...Texts.font_letter_spacing_048,
    color: "#BE69C2",
    fontWeight: "700",
  },
};
