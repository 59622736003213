import React, { useState, useEffect } from "react";
// import { LayoutChangeEvent, div, div } from "react-native";
// import Config from "react-native-config";
// import { Rating } from "react-native-ratings";
import { useTranslation } from "react-i18next";
import Rating from "../../rating/Rating";
import "./FeedPostProsAndCons.scss";

const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
const ASSET_STAR_IMAGE = "/ASSETS/star.png";

interface ProsAndConsProps {
  index: number;
  rating?: number;
  advantage?: string;
  disadvantage?: string;
  more: boolean;
  // onLayout?: (event: any) => void;
}
const FeedPostProsAndCons = ({
  // onLayout,
  more,
  index,
  rating,
  advantage,
  disadvantage,
}: ProsAndConsProps) => {
  const { t } = useTranslation();

  const [ratingVal, setRatingVal] = useState(0);

  useEffect(() => {
    rating && setRatingVal(rating);
  }, [rating]);

  return (
    <div
      // onLayout={onLayout}
      id="FeedPostProsAndCons"
      className="pros_and_cons_outer_wrap"
      // accessibilityLabel={`Pros and cons${index}`}
    >
      <div className="pros_and_cons_wrap">
        <div className="rating_wrap">
          <div className="pros_and_cons_title">
            <div className="pros_and_cons_title_text">
              {t("screen.popularPost.label.rating")}
            </div>
          </div>
          <div className="rating_wrapper">
            {/* <Rating
              type="custom"
              ratingCount={5}
              fractions={1}
              jumpValue={0.5}
              startingValue={rating}
              ratingImage={{ uri: `${PUBLIC_BUCKET_URL}${ASSET_STAR_IMAGE}` }}
              ratingColor="#a760b1"
              ratingBackgroundColor="#eee"
              tintColor={"#f8f8f8"}
              imageSize={16}
              className="star}
              readonly
            /> */}

            <Rating
              count={5}
              value={ratingVal}
              edit={false}
              onChange={(value) => setRatingVal(value)}
              className="rating"
            />
          </div>
          <div className="rating_text">{rating ?? 0}</div>
        </div>

        {/* <div style={Object.assign(styles.pros_and_cons, styles.pros)}> */}
        <div className="pros">
          <div className="pros_and_cons_title">
            <div className="pros_and_cons_title_text">
              {t("screen.popularPost.label.advantage_label")}
            </div>
          </div>
          <div
            className="pros_and_cons_text"
            // numberOfLines={!more ? undefined : 1}
          >
            {advantage ?? ""}
          </div>
        </div>

        <div className="pros_and_cons">
          <div className="pros_and_cons_title">
            <div className="pros_and_cons_title_text">
              {t("screen.popularPost.label.disadvantage_label")}
            </div>
          </div>
          <div
            className="pros_and_cons_text"
            // numberOfLines={!more ? undefined : 1}
          >
            {disadvantage ?? ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedPostProsAndCons;
