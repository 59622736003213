import { useEffect } from "react";
import { PostWriteRequest } from "../../api/post/model";
import { getPostDetail } from "../../api/post/api";
import { observer } from "mobx-react";
import PostWrite from "./presenter/PostWrite";
import { useLocation, useNavigationType } from "react-router-dom";
import PostWriteStore, { PostUIContents } from "../../store/PostWriteStore";
import { FileType } from "../../constants/File";
import { FeedMode, POST_BLANK, POST_BLANK_FILE } from "../../constants/Feed";
import { goBack } from "../../hooks/navigate/NavigateFunction";

const PostModifyScreen = observer((data: any) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  useEffect(() => {
    const feedId = location.state?.feedId as number;

    const remakeData = async () => {
      const tempRemakeContents: PostUIContents[] = [];

      const originData = await getPostDetail(feedId);

      if (originData === null) {
        goBack();
      } else {
        originData?.contents?.forEach((item, index) => {
          tempRemakeContents.push({
            id: index,
            contents: {
              feedId: item.feedId || -1,
              feedContentId: item.feedContentId,
              files: item.files,
              feedFileGroupId: item.feedFileGroupId || -1,
              content: item.contents || "",
            },
            modified: false,
          });
        });

        tempRemakeContents.forEach((item, index) => {
          let imageFileLength = 0;
          let videoFileLength = 0;
          item.contents.files.forEach((file) => {
            if (file.fileType === FileType.IMAGE && file.fileSize >= 0)
              imageFileLength = imageFileLength + 1;
            if (file.fileType === FileType.VIDEO && file.fileSize >= 0)
              videoFileLength = videoFileLength + 1;
          });

          if (index === 0) {
            if (imageFileLength + videoFileLength < 10)
              item.contents.files.push(POST_BLANK_FILE); // 사진 추가하는 화면
          } else {
            if (
              (imageFileLength === 0 && videoFileLength < 1) ||
              (videoFileLength === 0 && imageFileLength < 3)
            )
              item.contents.files.push(POST_BLANK_FILE);
          }
        });

        const tempRemakeTags: string[] = [];
        originData?.tagList?.forEach((item) =>
          tempRemakeTags.push(item.tagName)
        );

        const tempRemakeProductIds: number[] = [];
        originData?.productList?.forEach((item) =>
          tempRemakeProductIds.push(item.productId)
        );

        originData?.productList?.forEach((item, index) => {
          PostWriteStore.addProduct({
            productId: item.productId,
            tempProductId: item.tempProductId,
            thumbnailFilePath: item.productFiles[0]?.thumbnailFilePath,
            originalFilePath:
              item.productFiles[0]?.thumbnailFilePath?.replace(
                "THUMBNAIL",
                "ORIGINAL"
              ) || "",
            manufacturerName: item.manufacturerNameKor,
            productModelName: item.productModelName,
            productName: item.productName,
            openApiFlag: item.openApiFlag,
            nproductId: item.nproductId,
          });
        });

        PostWriteStore.contentsTempData = tempRemakeContents;

        const tempWholeData: PostWriteRequest = {
          feedId: originData.feedId,
          title: originData.feedTitle,
          contents: [],
          tagNames: [],
          rating: originData.rating,
          products: [],
          advantage: originData.advantage,
          disadvantage: originData.disadvantage,
        };
        PostWriteStore.wholeTempData = tempWholeData;
        void PostWriteStore.getRecommendTag(tempRemakeProductIds);
        if (tempRemakeTags !== undefined) {
          tempRemakeTags.forEach((tag) => PostWriteStore.addTag(String(tag)));
        }
        PostWriteStore.checkShowRating();
        PostWriteStore.isValidContent();
      }
    };

    if (!feedId || feedId === null || feedId <= 0) goBack();
    else
      (navigationType !== "POP" ||
        JSON.stringify(PostWriteStore.wholeTempData) ===
          JSON.stringify(POST_BLANK)) &&
        remakeData();
  }, [data, location.state?.feedId, navigationType]);

  return (
    <div aria-label="Post write screen">
      <PostWrite mode={FeedMode.MODIFY} />
    </div>
  );
});

export default PostModifyScreen;
