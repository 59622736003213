import { callAPIWithGETMethod } from "../common/api";
import { CommonCode } from "./model";

export const getCommonCode = async (
  groupCode: string,
  isLoading = true
): Promise<CommonCode[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/code/" + groupCode,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" && response?.statusCode === "OK"
      ? response?.data
      : false
  ) as CommonCode[];
};
