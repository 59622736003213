import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Colors } from "../../assets/styles";
import { observer } from "mobx-react";
import { FeedMode } from "../../constants/Feed";
import ArticleWrite from "./presenter/ArticleWrite";
import ArticleWriteStore from "../../store/ArticleWriteStore";
import { NewProduct } from "../../api/product/model";

const ArticleWriteScreen = observer((data: any) => {
    const location = useLocation();
    const params = location.state;

    const communityId = (useParams()?.communityId || params?.communityId) as number;
    const subscriptionYn = useParams()?.subscriptionYn || params?.subscriptionYn;
    const articleDisclosureYn = useParams()?.articleDisclosureYn || params?.articleDisclosureYn;
    const productInfo = (useParams()?.productInfo || params?.productInfo) as NewProduct;

    useEffect(() => {
        productInfo && ArticleWriteStore.addProduct(productInfo);
        articleDisclosureYn && ArticleWriteStore.setArticleDisclosureYn(articleDisclosureYn);
    }, [articleDisclosureYn, productInfo]);

    return (
        <div
            style={{
                flex: 1,
                ...Colors.background_fff,
            }}
        >
            <ArticleWrite mode={FeedMode.WRITE} communityId={communityId} subscriptionYn={subscriptionYn} />
        </div>
    );
});

export default ArticleWriteScreen;
