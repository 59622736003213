import React, { CSSProperties, useCallback, useMemo } from 'react'
import { ReactComponent as ArrowRightBlack } from '../../assets/icons/arrow_right_black.svg'
import { useTranslation } from 'react-i18next'
import AuthStore from '../../store/AuthStore'
import { removeSessionUserFromStorage } from '../../service/Session.service'
import { clearStore } from '../../service/Common.service'
import SettingStore from '../../store/SettingStore'
import { observer } from 'mobx-react'
import CommonStore from '../../store/CommonStore'
import { TitleHeader } from '../../components/common'
import { goTo } from '../../hooks/navigate/NavigateFunction'
import classes from './SettingScreen.module.scss'
import SettingFooter from './presenter/SettingFooter'
import { GroupedVirtuoso } from 'react-virtuoso'
import TouchableWithAuthCheck from '../../components/common/TouchableWithAuthCheck'
import clsx from 'clsx'
import { Item, Section } from './types'

const Setting = observer(() => {
  const { t } = useTranslation()
  const { logout, footerExpand } = SettingStore
  const { sessionUser } = AuthStore

  //   useFocusEffect(
  //     useCallback(() => {
  //       return () => {
  //         settingClear()
  //       }
  //     }, [settingClear])
  //   )

  const onLogout = useMemo(() => {
    return () => {
      void logout()
        .then(removeSessionUserFromStorage)
        .then((result) => {
          if (result) clearStore()
        })
        .finally(() => {
          CommonStore.setToastOption({
            show: true,
            message1: t('screen.setting.label.logoutToast'),
          })
          goTo('/')
        })
    }
  }, [logout, t])

  const MENUS: Section[] = useMemo(
    () => [
      {
        data: [
          {
            accessibilityLabel: 'Member',
            screenName: '/setting/user',
            needLogin: true,
            label: t('screen.setting.label.member'),
          },
          {
            accessibilityLabel: 'Alarm',
            screenName: '/setting/alarm',
            needLogin: true,
            label: t('screen.setting.label.alarm'),
          },
        ],
      },
      {
        data: [
          {
            accessibilityLabel: 'Notice',
            screenName: '/notice',
            label: t('screen.setting.label.notice'),
          },
          {
            accessibilityLabel: 'Faq',
            screenName: '/faq',
            screenParam: { isFromLoginAttraction: false },
            label: t('screen.setting.label.faq'),
          },
          {
            accessibilityLabel: 'Ask',
            screenName: '/qa',
            needLogin: true,
            label: t('screen.setting.label.ask'),
          },
          {
            accessibilityLabel: 'TermsSetting',
            screenName: '/setting/terms',
            label: t('screen.setting.label.terms'),
          },
        ],
      },
      {
        data: sessionUser?.memberUuid
          ? [
              {
                accessibilityLabel: 'Logout',
                label: t('screen.setting.label.logout'),
                onPress: onLogout,
                textStyle: {
                  color: '#999',
                  fontWeight: '500',
                  fontSize: '16',
                  letterSpacing: '-0.64',
                },
              },
            ]
          : [],
      },
    ],
    [onLogout, sessionUser?.memberUuid, t]
  )

  const groupCounts = useMemo(
    () => MENUS.map((menu) => menu.data.length),
    [MENUS]
  )

  const renderSection = useCallback((index: number) => {
    if (index === 0) return <div></div>
    else return <div className={classes.line} />
  }, [])

  const getListItem = useCallback(
    (index: number, groupIndex: number): Item => {
      // index 가 그룹 내의 인덱스가 아니라 데이터 전체의 인덱스로 계산되는것 같음
      var reduceCount = 0
      MENUS.forEach((section, sectionIndex) => {
        if (sectionIndex < groupIndex) {
          reduceCount += section.data.length
        }
      })
      return MENUS[groupIndex].data[index - reduceCount]
    },
    [MENUS]
  )

  const renderItem = useCallback(
    (index: number, groupIndex: number) => {
      const item = getListItem(index, groupIndex)
      if (!item) return <div></div>

      const onClick = () => {
        if (item?.onPress) {
          item.onPress()
        } else {
          item.screenName && goTo(item.screenName, item.screenParam)
        }
      }

      return (
        <div
          className={clsx(classes.option_box, classes.option_box_border)}
          aria-label={item.accessibilityLabel}
        >
          {!item.needLogin ? (
            <button
              className={classes.option}
              onClick={onClick}
              disabled={item.touchDisabled}
            >
              <span style={item.textStyle}>{item.label}</span>
              {item.screenName && <ArrowRightBlack />}
            </button>
          ) : (
            <TouchableWithAuthCheck
              className={classes.option}
              onPress={onClick}
              disabled={item.touchDisabled}
            >
              <span style={item.textStyle}>{item.label}</span>
              {item.screenName && <ArrowRightBlack />}
            </TouchableWithAuthCheck>
          )}
        </div>
      )
    },
    [getListItem]
  )

  return (
    <div className={classes.setting} aria-label="Setting">
      <TitleHeader
        title={t('screen.setting.label.title')}
        isHome={true}
        isBack={true}
      />
      <GroupedVirtuoso
        className={classes.setting_list}
        useWindowScroll
        groupCounts={groupCounts}
        groupContent={renderSection}
        itemContent={renderItem}
        components={{
          Footer: () => renderSection(1),
        }}
      />

      <SettingFooter />
    </div>
  )
})
export default Setting
