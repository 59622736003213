import { CSSProperties, ReactNode, useState } from 'react'
import Modal from 'react-modal'

export interface CustomBottomSheetProps {
  open: boolean
  onClose: () => void
  snapHeight?: number
  children: ReactNode | JSX.Element
  modalContentStyles?: CSSProperties
  afterOpen?: () => void
  afterClose?: () => void
  usePadding?: boolean
  idSheet?: string
}

const CustomBottomSheet = ({
  open,
  onClose,
  snapHeight,
  children,
  modalContentStyles,
  afterOpen,
  afterClose,
  usePadding = true,
  idSheet,
}: CustomBottomSheetProps) => {
  const [isClosing, setIsClosing] = useState(false)

  const onAfterOpen = () => {
    document.body.classList.add('bottom-modal-open')
    afterOpen && afterOpen()
  }

  const closeModal = () => {
    setIsClosing(true)
    setTimeout(() => {
      onClose()
    }, 200)
  }

  const onAfterClose = () => {
    document.body.classList.remove('bottom-modal-open')
    setIsClosing(false)
    afterClose && afterClose()
  }

  return (
    <Modal
      isOpen={open}
      onAfterOpen={onAfterOpen}
      onRequestClose={closeModal}
      onAfterClose={onAfterClose}
      id={idSheet}
      style={{
        content: {
          position: 'fixed',
          bottom: 0,
          left: 'clamp(0px, calc(50% - 225px), 50vw)',
          width: '100%',
          maxWidth: '450px',
          backgroundColor: 'white',
          border: 'none',
          padding: `${usePadding ? '20px' : 0} ? : `,
          boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px 10px 0 0',
          top: 'auto',
          height: snapHeight ? snapHeight : 'unset',
          animation: isClosing
            ? 'bottomSheetAnimationClose 0.3s ease-in'
            : 'bottomSheetAnimation 0.3s ease-out',
          overflow: 'hidden',
          ...modalContentStyles,
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 10000,
          animation: isClosing ? 'overlayAnimationClose 0.3s ease-in' : 'unset',
          maxWidth: 450,
          margin: 'auto',
        },
      }}
      contentLabel="Bottom Sheet Modal"
    >
      {children}
    </Modal>
  )
}

export default CustomBottomSheet
