export const background_fff = {
  backgroundColor: "#fff",
};

export const background_fff6ff = {
  backgroundColor: "#fff6ff",
};

export const background_222 = {
  backgroundColor: "#222",
};

export const background_transparent = {
  backgroundColor: "transparent",
};

export const background_c4c4c4 = {
  backgroundColor: "#c4c4c4",
};

export const background_f7e237 = {
  backgroundColor: "#f7e237",
};

export const background_6c2773 = {
  backgroundColor: "#6c2773",
};

export const background_7b448a = {
  backgroundColor: "#7b448a",
};

export const background_882e91 = {
  backgroundColor: "#882e91",
};

export const background_68276e = {
  backgroundColor: "#68276e",
};

export const background_d90000 = {
  backgroundColor: "#d90000",
};

export const background_d9d9d9 = {
  backgroundColor: "#d9d9d9",
};

export const background_f9f3fa = {
  backgroundColor: "#f9f3fa",
};

export const background_255 = {
  backgroundColor: "rgb(255,255,255)",
};

export const background_f6f4fa = {
  backgroundColor: "#f6f4fa",
};

export const background_eeeeee = {
  backgroundColor: "#eeeeee",
};

export const background_f8f8f8 = {
  backgroundColor: "#f8f8f8",
};

export const background_999999 = {
  backgroundColor: "#999999",
};

export const background_f0dff2 = {
  backgroundColor: "#f0dff2",
};

export const background_fcfafc = {
  backgroundColor: "#fcfafc",
};

export const background_fafafa = {
  backgroundColor: "#fafafa",
};

export const background_fafbfc = {
  backgroundColor: "#fafbfc",
};

export const background_642e6f = {
  backgroundColor: "#642e6f",
};

export const background_c07bca = {
  backgroundColor: "#c07bca",
};

export const background_3b3758 = {
  backgroundColor: "#3b3758",
};

export const background_2d4b57 = {
  backgroundColor: "#2d4b57",
};

export const background_4c73a0 = {
  backgroundColor: "#4c73a0",
};

export const background_7094bd = {
  backgroundColor: "#7094bd",
};

export const background_b59c8f = {
  backgroundColor: "#b59c8f",
};

export const background_3a4f93 = {
  backgroundColor: "#3a4f93",
};

export const background_aa5377 = {
  backgroundColor: "#aa5377",
};

export const background_ac575c = {
  backgroundColor: "#ac575c",
};

export const background_424242 = {
  backgroundColor: "#424242",
};

export const background_682c78 = {
  backgroundColor: "#682c78",
};

export const background_666 = {
  backgroundColor: "#666",
};

export const background_a466a4 = {
  backgroundColor: "#a466a4",
};

export const background_9450d8 = {
  backgroundColor: "#9450d8",
};

export const background_a760b1 = {
  backgroundColor: "#a760b1",
};

export const background_be69c3 = {
  backgroundColor: "#be69c3",
};

export const background_762e8a = {
  backgroundColor: "#762e8a",
};

export const background_672c78 = {
  backgroundColor: "#672c78",
};

export const background_f5f5f5 = {
  backgroundColor: "#f5f5f5",
};

export const background_f7f4fa = {
  backgroundColor: "#f7f4fa",
};

export const background_d3bad6 = {
  backgroundColor: "#d3bad6",
};

export const background_fce74e = {
  backgroundColor: "#fce74e",
};

export const background_854788 = {
  backgroundColor: "#854788",
};

export const background_AB5EAF = {
  backgroundColor: "#AB5EAF",
};

export const background_BE69C3 = {
  backgroundColor: "#BE69C3",
};

export const background_c8c8c8 = {
  backgroundColor: "#c8c8c8",
};

export const border_color_d8b8d8 = {
  borderColor: "#d8b8d8",
};

export const border_color_d9d9d9 = {
  borderColor: "#d9d9d9",
};

export const border_color_be69c3 = {
  borderColor: "#be69c3",
};

export const border_color_6c2773 = {
  borderColor: "#6c2773",
};

export const border_color_c4c4c4 = {
  borderColor: "#c4c4c4",
};

export const border_color_f0dff2 = {
  borderColor: "#f0dff2",
};

export const border_color_d90000 = {
  borderColor: "#d90000",
};

export const border_color_000 = {
  borderColor: "#000",
};

export const border_color_red1 = {
  borderColor: "rgba(192, 0, 85, 0.2)",
};

export const border_color_gray1 = {
  borderColor: "rgba(34, 34, 34, 0.1)",
};

export const border_color_61116812 = {
  borderColor: "#61116812",
};

export const border_color_ffc700 = {
  borderColor: "#ffc700",
};

export const border_color_fce74e = {
  backgroundColor: "#fce74e",
};

export const border_color_a466a4 = {
  borderColor: "#a466a4",
};

export const border_color_eee = {
  borderColor: "#eee",
};

export const border_color_222 = {
  borderColor: "#222",
};

export const border_color_f9f3fa = {
  borderColor: "#f9f3fa",
};

export const border_color_f5f5f5 = {
  borderColor: "#f5f5f5",
};

export const border_color_fff = {
  borderColor: "#fff",
};

export const border_color_999 = {
  borderColor: "#999",
};

export const border_color_a760b1 = {
  borderColor: "#a760b1",
};

export const border_color_ebebeb = {
  borderColor: "#ebebeb",
};

export const border_color_black_015 = {
  borderColor: "rgba(0, 0, 0, 0.15)",
};

export const border_color_transparent = {
  borderColor: "transparent",
};

export const border_t_color_transparent = {
  borderTopColor: "transparent",
};

export const border_b_color_transparent = {
  borderBottomColor: "transparent",
};

export const border_l_color_transparent = {
  borderLeftColor: "transparent",
};

export const border_r_color_transparent = {
  borderRightColor: "transparent",
};

export const font_fff = {
  color: "#fff",
};

export const font_000 = {
  color: "#000",
};

export const font_fcfafc = {
  color: "#fcfafc",
};

export const font_f7e237 = {
  color: "#f7e237",
};

export const font_aaa = {
  color: "#aaa",
};

export const font_666666 = {
  color: "#666666",
};

export const font_6c2773 = {
  color: "#6c2773",
};

export const font_672c78 = {
  color: "#672c78",
};

export const font_222 = {
  color: "#222",
};

export const font_3366bb = {
  color: "#3366bb",
};

export const font_333 = {
  color: "#333",
};

export const font_999 = {
  color: "#999",
};

export const font_a466a4 = {
  color: "#a466a4",
};

export const font_9d65ac = {
  color: "#9d65ac",
};

export const font_d8b8d8 = {
  color: "#d8b8d8",
};

export const font_f0dff2 = {
  color: "#f0dff2",
};

export const font_6a0 = {
  color: "#6a0",
};

export const font_c60053 = {
  color: "#c60053",
};

export const font_c00055 = {
  color: "#c00055",
};

export const font_c4c4c4 = {
  color: "#c4c4c4",
};

export const font_d9d9d9 = {
  color: "#d9d9d9",
};

export const font_a760b1 = {
  color: "#a760b1",
};

export const font_be69c3 = {
  color: "#be69c3",
};
export const font_66aa00 = {
  color: "#66aa00",
};

export const font_b38bbe = {
  color: "#b38bbe",
};

export const font_ea174a = {
  color: "#ea174a",
};

export const font_4c73a0 = {
  color: "#4c73a0",
};

export const font_cdcdcd = {
  color: "#cdcdcd",
};

export const font_d1d1d1 = {
  color: "#d1d1d1",
};

export const font_d3bad6 = {
  color: "#d3bad6",
};

export const two_btn_border_color_a466a4 = {
  borderColor: "#a466a4",
};

export const two_btn_border_color_666666 = {
  borderColor: "#666666",
};
