import { observer } from "mobx-react";
import React from "react";
// import Config from "react-native-config";
import { Post } from "../../../api/feed/model";
// import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import CommonStore from "../../../store/CommonStore";
// import { styles } from "../Main";
import { ReactComponent as BadgeVideo } from "../../../assets/icons/badge_video.svg";
import { ReactComponent as BadgeImg } from "../../../assets/icons/badge_img.svg";
import "./PopularPostItem.scss";

// const PUBLIC_BUCKET_URL: string = Config.REACT_NATIVE_PUBLIC_BUCKET_URL;
const PUBLIC_BUCKET_URL: string = process.env.REACT_APP_PUBLIC_BUCKET_URL || "";
const PopularPostItem = observer(
  ({
    onPress,
    post,
    index,
    mainListIndex,
  }: {
    onPress: (targetIndex: number) => void;
    post: Post;
    index: number;
    mainListIndex: number;
  }) => {
    const feedId: number = post.feedId;
    const videoCount = post.videoCount ? post.videoCount : 0;
    const imageCount = post.imageCount ? post.imageCount : 0;
    const total = videoCount + imageCount;

    return (
      <div
        className={`${
          index === 0 ? "popular_post_first_item" : "popular_post_item"
        }`}
        key={post.feedId}
        onClick={() => {
          onPress(index);

          CommonStore.callActHistory(
            ActHistoryTypeCode.POST_READ,
            (feedId ?? 0).toString(),
            ActPageCode.MAIN,
            ActSectionCode.POPULAR_POST,
            index.toString()
          );
        }}
        aria-label="Each Popular Post"
      >
        <div className="popular_post_img_wrapper">
          <img
            className="popular_post_img"
            src={`${PUBLIC_BUCKET_URL || ""}${post?.thumbnailFilePath || ""}`}
            alt={`${PUBLIC_BUCKET_URL || ""}${post?.thumbnailFilePath || ""}`}
          />

          {total > 1 ? (
            <BadgeImg className="badge_img" />
          ) : total == 1 && videoCount == 1 ? (
            <BadgeVideo className="badge_img" />
          ) : (
            <></>
          )}
        </div>
        <div className="popular_post_description">
          <div
            className="popular_post_text1"
            // numberOfLines={2}
            // ellipsizeMode={"tail"}
          >
            {post.feedTitle}
          </div>
          <div className="popular_post_text2">{post.nickname}</div>
        </div>
      </div>
    );
  }
);

export default PopularPostItem;
