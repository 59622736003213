export enum QaStatusCode {
  ADMIN_DELETE = "ADMIN_DELETE",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  REGISTERED = "REGISTERED",
  USER_DELETE = "USER_DELETE",
}

export enum NotificationParentTypeCode {
  PROMOTION_ALL = "PROMOTION_ALL",
  EMAIL = "EMAIL",
  SMS = "SMS",
  PUSH = "PUSH",
  MEMBER_LIKE_MY_WRITING = "MEMBER_LIKE_MY_WRITING",
  MEMBER_COMMENT_MY_WRITING = "MEMBER_COMMENT_MY_WRITING",
  MEMBER_MENTION_ME = "MEMBER_MENTION_ME",
  NEW_FOLLOWER = "NEW_FOLLOWER",
  NEW_WRITING_FROM_FOLLOWER = "NEW_WRITING_FROM_FOLLOWER",
  MY_MISSION_PARTICIPATION = "MY_MISSION_PARTICIPATION",
  MY_STATUS_CHANGE = "MY_STATUS_CHANGE",
  MY_INQUIRY = "MY_INQUIRY",
  MY_VOLT = "MY_VOLT",
}
