import React, {
  createContext,
  useContext,
  useCallback,
  FC,
  ReactNode,
} from 'react'
import { useTrackHandler } from './UseTrackHandler'
import { EventProperty, TrackFunction } from './types'

interface ITracker {
  track: TrackFunction
  properties?: EventProperty
}

const TrackingContext = createContext<ITracker>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  track: () => {},
})

/*
 * const { track } = useTracker();
 *
 * track("event", { "event_property": "value" });
 *
 */
export const useTracker = () => useContext(TrackingContext)

/*
 * tracking 함수에 들어가는 프로퍼티를 쌓기 위한 프로바이더
 *
 * <TrackingProvider properties={{ page: "home" }}>
 *   <TrackingProvider properties={{ section: "a" }}>
 *      // 최종적으로 track 함수는 { page: "home", section: "a", action: "track" } 를 전송
 *     <Track>{track => track("track", {{ action: "track" })}</Track>
 *   </TrackingProvider>
 * </TrackingProvider>
 *
 */
type ProviderProps = {
  properties?: EventProperty
  children?: ReactNode
}

export const TrackingProvider: FC<ProviderProps> = ({
  properties: contextualProperties,
  children,
}) => {
  const { handle } = useTrackHandler()
  const { properties: inheritedProperties } = useTracker()

  const track: TrackFunction = useCallback(
    (event, properties) => {
      if (handle) {
        handle(event, {
          ...inheritedProperties,
          ...contextualProperties,
          ...properties,
        })
      }
    },
    [handle, inheritedProperties, contextualProperties]
  )

  return (
    <TrackingContext.Provider
      value={{
        track,
        properties: {
          ...inheritedProperties,
          ...contextualProperties,
        },
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}

/*
 * 컴퍼넌트에 공통 트래킹 프로퍼티를 추가
 *
 * const Component = withTrack(Component, { key: "value" });
 *
 */
export const withTrack = (Component: FC, properties: EventProperty) => {
  const TrackedComponent: FC = (props) => {
    const { properties: inheritedProperties } = useTracker()

    return (
      <TrackingProvider
        properties={{
          ...inheritedProperties,
          ...properties,
        }}
      >
        <Component {...props} />
      </TrackingProvider>
    )
  }

  return TrackedComponent
}

/*
 * useTracker 훅을 사용하기 어려운곳에서 사용하기 위한 컴퍼넌트
 *
 * <Track>
 *   {track => (
 *     <button onClick={() => track("click")}>
 *       Click
 *     </button>
 *   )}
 * </Track>
 *
 */
type ConsumerProps = {
  properties?: EventProperty
  children: (track: TrackFunction) => ReactNode
}

export const Track: FC<ConsumerProps> = ({
  properties: contextualProperties,
  children,
}) => {
  const { track, properties: inheritedProperties } = useTracker()

  const overrideTrack: TrackFunction = (event, properties) => {
    track(event, {
      ...inheritedProperties,
      ...contextualProperties,
      ...properties,
    })
  }

  return (
    <TrackingProvider
      properties={{
        ...inheritedProperties,
        ...contextualProperties,
      }}
    >
      {children(overrideTrack)}
    </TrackingProvider>
  )
}
