import { memo } from "react";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { RankingPosterFeed } from "../../../api/member/model";
import { ReactComponent as CheckIcon } from "../../../assets/icons/sighup_user_style_check.svg";
import classes from "./UserStyleFeedItem.module.scss";

export const UserStyleFeedItem = memo(
  ({
    item,
    index,
    like,
  }: {
    item: RankingPosterFeed;
    index: number;
    like: boolean;
  }) => {
    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

    return (
      <>
        <FastImageWithFallback
          className={classes.ranking_poster_feed_img}
          source={{
            uri: `${PUBLIC_BUCKET_URL || ""}${item?.thumbnailFilePath || ""}`,
          }}
          fallbackImageUri={`${PUBLIC_BUCKET_URL || ""}${
            item?.originalFilePath || ""
          }`}
          aria-label="Each UserStyle Post"
        ></FastImageWithFallback>
        {like && (
          <div className={classes.click_ranking_poster_feed_img}>
            <CheckIcon />
          </div>
        )}
      </>
    );
  }
);

UserStyleFeedItem.displayName = "UserStyleFeedItem";
