import { SuccessOrNot } from "../../constants/Common.enum";
import { Service } from "../../constants/Service";
import { callAPIWithGETMethod, callAPIWithPUTMethod } from "../common/api";
import CommonResponse, { getInstance } from "../http";
import { CreateTempMemberRequest, TempMember } from "./model";

export const createTempMemberAndSetPreferences = async (
  body: CreateTempMemberRequest,
  isLoading = true
): Promise<string> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  response = await getInstance(Service.MOGA_BE, isLoading).post(
    "/v1/tempMember",
    body
  );

  return response.successOrNot === "Y"
    ? response.data.tempMemberUUID
    : undefined;
};

export const getTempMember = async (
  tempMemberUuid: string,
  isLoading = true
) => {
  let response: CommonResponse<TempMember> = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  response = await callAPIWithGETMethod(
    {
      url: "/v1/tempMember",
      params: { tempMemberUuid },
    },
    isLoading
  );
  return response?.successOrNot === SuccessOrNot.Y &&
    response?.statusCode === "OK"
    ? response?.data
    : undefined;
};

export const updateTempMember = async (
  tempMember: TempMember,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPUTMethod(
    { url: "/v1/tempMember", body: tempMember },
    isLoading
  );
};
