import React, { useCallback, useRef, useState } from "react";
// import {
//   ScrollView,
//   StyleSheet,
//   TouchableOpacity,
//   View,
//   Text,
//   useWindowDimensions,
//   Dimensions,
//   PixelRatio,
// } from "react-native";
import { Arranges, Assets, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import { Styles } from "../../../assets/types/Style";
// import { responsiveScreenWidth, responsiveWidth } from "react-native-responsive-dimensions";
import { Coupon } from "../../../api/coupon/model";
import { useTranslation } from "react-i18next";
import { handleTopButtonScroll } from "../../../utils/common";
import { styles } from "../../mission/presenter/MissionMain";
import { ReactComponent as ArrowUpIcon } from "../../../assets/icons/arrow_up.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow_down.svg";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import MissionStore from "../../../store/MissionStore";
// import Barcode, { Format } from "@kichiyaki/react-native-barcode-generator";
import { useBarcode } from '@createnextapp/react-barcode';
import { MISSION_RESULT_COUNT, MISSION_TYPE_PATH } from "../../../constants/mission";
import dayjs from "dayjs";
import { CouponType } from "../../../constants/coupon";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import NotificationModalWithHideCallback from "../../../components/modals/NotificationModalWithHideCallback";
import { updateCouponUseYN } from "../../../api/coupon/api";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { useParams } from "react-router-dom";
import Collapsible from "../../../components/common/collapsible/Collapsible";

const CouponDetailNew = ({ couponInfo }: { couponInfo: Coupon }) => {
  const { t } = useTranslation();
  const params = useParams();
  const delSize: string = params?.delSize || '0';
  // const { width } = useWindowDimensions();
  const PUBLIC_BUCKET_URL: string = process.env.REACT_APP_PUBLIC_BUCKET_URL || "";
  const [showCouponUseInfo, setShowCouponUseInfo] = useState<boolean>(false);
  const [showPrecautionInfo, setShowPrecautionInfo] = useState<boolean>(false);
  const [showTopButton, setShowTopButton] = useState<boolean>(false);

  const scrollViewRef = useRef<any>(null);
  // const insets = useSafeAreaInsets();

  // const mainImageHeight = responsiveScreenWidth(90) * 0.614;

  // const [bottomInset, setBottomInset] = useState<number>(insets.bottom / PixelRatio.get());
  const [bottomInset, setBottomInset] = useState<number>(1);
  const [couponUseYN, setCouponUseYN] = useState<string>(couponInfo?.useYn || "N");
  const [showModal, setShowModal] = useState<boolean>(false);

  const couponUseYNUpdate = async () => {
    setShowModal(false);
    const res = await updateCouponUseYN({
      couponId: couponInfo.couponId,
      couponCode: couponInfo.couponCode,
      useYn: couponUseYN === "Y" ? "N" : "Y",
    });
    if (res.successOrNot === "Y") {
      setCouponUseYN(couponUseYN === "Y" ? "N" : "Y");
    }
  };

  const showOngoingMission = () => {
    // popToTop();
    MissionStore.setSelectedTab(MISSION_TYPE_PATH.ONGOING);
    void MissionStore.getMissionList(MISSION_TYPE_PATH.ONGOING, MISSION_RESULT_COUNT, 0);
    goTo("/Mission")
    // navigate("MainTab", { screen: "MissionStack" });
  };

  const expireImage = () => {
    if (!isValidCoupon()) {
      return (
        <>
          <div style={winnerStyle.expire_img}>
            <div style={winnerStyle.expire_circle}>
              <p style={winnerStyle.expire_text}>{t("screen.coupon.label.expired2")}</p>
            </div>
          </div>
        </>
      );
    } else if (couponUseYN === "Y") {
      return (
        <>
          <div style={winnerStyle.expire_img}>
            <div style={winnerStyle.expire_circle}>
              <p style={winnerStyle.expire_text}>{t("screen.coupon.label.usedComplete1")}</p>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };

  const expireDateRange = () => {
    if (couponInfo?.couponStartDate && couponInfo?.couponEndDate) {
      const startDate = String(dayjs(couponInfo?.couponStartDate).format("YYYY-MM-DD"));
      const endDate = String(dayjs(couponInfo?.couponEndDate).format("YYYY-MM-DD"));
      return startDate.concat("~", endDate);
    } else {
      return "";
    }
  };

  const isValidCoupon = () => {
    const now = dayjs();
    return now.isBefore(couponInfo?.couponEndDate);
  };

  const expireDateView = () => {
    if (!isBarcodeCoupon()) {
      return <></>;
    }
    if (!isValidCoupon() || couponUseYN === "Y") {
      return (
        <>
          <div style={winnerStyle.expired_coupon_date_view}>
            <p style={winnerStyle.expired_coupon_date_text}>{expireDateRange()}</p>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div style={winnerStyle.valid_coupon_date_view}>
            <p style={winnerStyle.valid_coupon_date_text}>{expireDateRange()}</p>
          </div>
        </>
      );
    }
  };

  const isBarcodeCoupon = useCallback(() => {
    return couponInfo?.couponType === CouponType.MANUAL_BARCODE;
  }, [couponInfo?.couponType]);

  const barcodeText = useCallback(() => {
    return couponInfo?.couponCode?.replace(/(.{4})/g, "$1 ");
  }, [couponInfo?.couponCode]);

  const { inputRef } = useBarcode({
    value: couponInfo.couponCode,
    options: {
      format: couponInfo.couponBarcodeFormat,
      text: barcodeText(),
      fontSize: 14,
      height: 55,
      width: 1
    }
  });
  
  const WinComponent = () => {
    return (
      <>
        <div style={winnerStyle.winner_view}>
          <div style={{position:'relative'}}>
            <FastImageWithFallback
              source={{ uri: `${PUBLIC_BUCKET_URL}${couponInfo?.couponOriginalPath ?? ""}` }}
              style={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                width: '100%',
              }}
            />
            {expireImage()}
            <div style={winnerStyle.wrap_expire_image}>
              {expireDateView()}
            </div>
          </div>
          <div style={winnerStyle.prize_info_view}>
            <p style={winnerStyle.brand_text1}>{couponInfo?.couponProvider}</p>
            <p style={winnerStyle.prize_nm_text1}>{couponInfo?.couponName}</p>
            {isBarcodeCoupon() && (
                <div style={winnerStyle.barcode}>
                  <svg ref={inputRef} />
                </div> 
            )}
            {!isBarcodeCoupon() && (
              <div style={winnerStyle.non_barcode}>
                <p style={winnerStyle.non_barcode_text}>
                  {t("screen.mission.message.checkPrivacyInfo1")}
                  {t("screen.mission.message.checkPrivacyInfo2")}
                </p>
              </div>
            )}
          </div>
          <div style={winnerStyle.ongoing_mission}>
            <p style={styles.fail_text}>{t("screen.mission.message.tryAnother")}</p>
            <button
              style={styles.other_mission_btn}
              onClick={showOngoingMission}
              // accessibilityLabel={`go to ongoing mission tab`}
            >
              <p style={styles.other_mission_btn_text}>{t("screen.mission.button.showOngoing")}</p>
            </button>
          </div>
        </div>
      </>
    );
  };

  const onClickUseInfoHeader = () => {
    setShowCouponUseInfo(!showCouponUseInfo);
  };

  const onClickPrecautionHeader = () => {
    setShowPrecautionInfo(!showPrecautionInfo);
  };

  return (
    <>
      <div style={{ flex: 1 }}>
        <div
          // style={winnerStyle.scrollView}
          ref={scrollViewRef}
          // accessible={true}
          // accessibilityLabel={"Coupon detail screen"}
          // scrollEventThrottle={16}
          onScroll={(event) =>
            handleTopButtonScroll(
              event,
              () => {
                setShowTopButton(true);
              },
              () => {
                setShowTopButton(false);
              },
              scrollViewRef
            )
          }
        >
          {/* 메인 UI 영역 */}
          <WinComponent />
          {/* 당첨 관련 정보 노출 영역 */}
          {couponInfo?.winInfo !== "" && (
            <div style={winnerStyle.win_info_view}>
              <div
                style={styles.contents_text}
                dangerouslySetInnerHTML={{ __html: `${couponInfo?.winInfo || ""}` }}
              />
            </div>
          )}
          <div style={{ backgroundColor: "#f5f5f5", height: 6 }}></div>
          {/* 사용 안내 */}
          <div style={couponInfo?.couponUseMethod ? {} : { display: "none" }}>
            <button style={winnerStyle.accordion_header} onClick={onClickUseInfoHeader}>
              <div style={winnerStyle.accordion_header_left}>
                <div style={winnerStyle.accordion_header_text_wrap}>
                  <p style={winnerStyle.accordion_header_text}>{t("screen.coupon.label.useGuide")}</p>
                </div>
              </div>
              {showCouponUseInfo ? (
                <div style={winnerStyle.accordion_header_icon_wrap}>
                  <ArrowUpIcon style={winnerStyle.arrow_icon} />
                </div>
              ) : (
                <div style={winnerStyle.accordion_header_icon_wrap}>
                  <ArrowDownIcon style={winnerStyle.arrow_icon} />
                </div>
              )}
            </button>
            <Collapsible collapsed={!showCouponUseInfo}>
              <div style={winnerStyle.accordion_contents}>
                <div style={winnerStyle.accordion_contents_wrap}>
                  <div
                    style={winnerStyle.accordion_contents_text}
                    dangerouslySetInnerHTML={{ __html: `${couponInfo?.couponUseMethod || ""}` }}
                  />
                </div>
              </div>
            </Collapsible>
          </div>
          {/* 유의 사항 */}
          <div style={couponInfo?.couponPrecation ? {} : { display: "none" }}>
            <button style={winnerStyle.accordion_header} onClick={onClickPrecautionHeader}>
              <div style={winnerStyle.accordion_header_left}>
                <div style={winnerStyle.accordion_header_text_wrap}>
                  <p style={winnerStyle.accordion_header_text}>{t("screen.coupon.label.precaution")}</p>
                </div>
              </div>
              {showPrecautionInfo ? (
                <div style={winnerStyle.accordion_header_icon_wrap}>
                  <ArrowUpIcon style={winnerStyle.arrow_icon} />
                </div>
              ) : (
                <div style={winnerStyle.accordion_header_icon_wrap}>
                  <ArrowDownIcon style={winnerStyle.arrow_icon} />
                </div>  
              )}
            </button>
            <Collapsible collapsed={!showPrecautionInfo}>
              <div style={winnerStyle.accordion_contents}>
                <div style={winnerStyle.accordion_contents_wrap}>
                  <div
                    style={winnerStyle.accordion_contents_text}
                    dangerouslySetInnerHTML={{ __html: `${couponInfo?.couponPrecation || ""}` }}
                  />
                </div>
              </div>
            </Collapsible>
          </div>
          <div style={{ marginBottom: 56, height: 56 }}></div>
        </div>
      </div>
      {isValidCoupon() &&
        (couponUseYN === "Y" ? (
          <TouchableWithAuthCheck
            style={{
              position: "absolute",
              backgroundColor: "#222222",
              ...FlexBoxs.flex,
              justifyContent: "center",
              alignItems: "center",
              height: 56,
              bottom: 0,
              width: "100%",
            }}
            onPress={() => setShowModal(true)}
          >
            <p style={{ ...Texts.btn_text_0, ...Texts.font_weight_500, ...Colors.font_fff }}>
              {t("screen.coupon.label.reUse")}
            </p>
          </TouchableWithAuthCheck>
        ) : (
          <TouchableWithAuthCheck
            style={{
              position: "absolute",
              backgroundColor: "#be69c3",
              ...FlexBoxs.flex,
              justifyContent: "center",
              alignItems: "center",
              height: 56,
              bottom: 0,
              width: "100%",
            }}
            onPress={() => setShowModal(true)}
          >
            <p style={{ ...Texts.btn_text_0, ...Texts.font_weight_500, ...Colors.font_fff }}>
              {t("screen.coupon.label.use")}
            </p>
          </TouchableWithAuthCheck>
        ))}

      <NotificationModalWithHideCallback
        isVisible={showModal}
        title={couponUseYN === "Y" ? t("screen.coupon.label.notice2") : t("screen.coupon.label.notice1")}
        contents1={
          couponUseYN === "Y" ? t("screen.coupon.message.unuseContents1") : t("screen.coupon.message.useContents1")
        }
        useTwoButton={true}
        isBlack={false}
        defaultButtonText={t("screen.notificationModal.button.confirm")}
        extraButtonText={t("screen.notificationModal.button.cancel")}
        onClickDefaultButton={() => void couponUseYNUpdate()}
        onClickExtraButton={() => setShowModal(false)}
        onRequestClose={() => setShowModal(false)}
        modalCloseCallback={() => setShowModal(false)}
        textAlign={"center"}
      />
    </>
  );
};
export default CouponDetailNew;

export const winnerStyle: Styles = {
  winner_view: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
    ...Spacings.padding_bottom_40,
    backgroundColor: "#FCFAFC",
    marginLeft: 16,
    marginRight: 16,
    marginTop: 20,
  },
  wrap_expire_image: {
    width: '100%', 
    ...FlexBoxs.flex, 
    ...Arranges.center_v_h, 
    ...Positions.absolute, 
    ...Positions.bottom
  },
  prize_info_view: {
    width: '80%',
    backgroundColor: "#ffffff",
    // shadowColor: "rgba(104, 39, 110, 0.1)",
    boxShadow: '0px 6px 20px 0px #68276E1A',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowRadius: 20,
    shadowOpacity: 1,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
    paddingTop: 38,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  brand_text1: {
    ...Texts.contents_text_21,
    ...Colors.font_666666,
  },
  valid_coupon_date_view: {
    width: 210,
    borderRadius: 40,
    backgroundColor: "#be69c3",
    position: "absolute",
    paddingTop: 6,
    paddingBottom: 8,
    zIndex: 1,
  },
  valid_coupon_date_text: {
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: -0.64,
    textAlign: "center",
    color: "#ffffff",
  },
  expired_coupon_date_view: {
    width: 210,
    borderRadius: 40,
    backgroundColor: "#eeeeee",
    position: "absolute",
    paddingTop: 6,
    paddingBottom: 8,
    zIndex: 1,
  },
  expired_coupon_date_text: {
    fontSize: 16,
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: -0.64,
    textAlign: "center",
    color: "#aaaaaa",
  },
  prize_nm_text1: {
    ...Spacings.margin_right_left_20,
    ...Spacings.margin_top_4,
    ...Texts.contents_text_18_072,
    ...Colors.font_222,
    fontWeight: "900",
  },
  ongoing_mission: {
    width: '79.4%',
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
    paddingTop: 38,
    ...Colors.border_color_eee,
  },
  barcode: { 
    marginTop: 15, 
    marginLeft: 20, 
    marginRight: 20, 
    marginBottom: 30 
  },
  barcode_text: {
    fontSize: 14,
    marginTop: 4,
    letterSpacing: -0.56,
    ...Colors.font_222,
    ...Texts.font_weight_500,
  },
  non_barcode: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    marginTop: 13,
    width: 270,
    marginBottom: 37,
  },
  non_barcode_text: {
    fontSize: 14,
    fontWeight: "normal",
    fontStyle: "normal",
    letterSpacing: -0.56,
    textAlign: "left",
    color: "#666666",
  },
  expire_img: {
    flex: 1,
    ...FlexBoxs.flex,
    justifyContent: "center",
    alignItems: "center",
    ...Positions.fullscreen,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: "rgba(212, 212, 212, 0.8)",
    marginBottom: 4
  },
  expire_circle: {
    width: 120,
    height: 120,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    borderStyle: "solid",
    borderRadius: 100,
    borderWidth: 4,
    borderColor: 'red',
    ...FlexBoxs.flex,
    justifyContent: "center",
    alignItems: "center",
  },
  expire_text: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    fontStyle: "normal",
    color: "#ffffff",
  },
  arrow_icon: {
    ...FlexBoxs.flex_1,
    maxWidth: 20,
    // borderStyle: 'dashed',
    // borderWidth: 1,
    // borderColor: 'gray'
  },
  accordion_contents: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_left_right_20,
    backgroundColor: "#f8f8f8",
    ...Spacings.padding_top_bottom_24,
  },
  accordion_contents_wrap: {
    ...FlexBoxs.flex_1,
  },
  accordion_contents_text: {
    ...FlexBoxs.flex_1,
    ...Texts.contents_text_28,
  },
  accordion: {},
  accordion_header: {
    width: '100%',
    ...FlexBoxs.flex,
    ...Spacings.padding_right_left_16,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Arranges.between,
    ...Spacings.padding_top_bottom_24,
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    ...Colors.border_color_eee,
  },
  accordion_header_left: {
    ...FlexBoxs.flex_1,
    flexGrow: 8
  },
  accordion_header_text_wrap: {
    ...FlexBoxs.flex_1,
  },
  accordion_header_text: {
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: -0.64,
    textAlign: "left",
    color: "#222222",
  },
  accordion_header_icon_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    flexGrow: 1 
  },
  text_icon: {
    ...Spacings.margin_right_6,
  },
  top_btn: {
    ...Positions.absolute,
    right: 24,
  },
  win_info_view: {
    ...Spacings.margin_top_22,
    ...Spacings.margin_right_left_20,
  },
  scrollView: {
    width: '100%',
    ...Positions.fullscreen,
    ...Colors.border_color_transparent,
    ...Arranges.margin_center_v_h,
    ...Assets.modal_box_shadow,
    backgroundColor: "#fff",
  },
};
