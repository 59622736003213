import { styles } from "../BrandCommunityProductListScreen";
import BrandCommunityProductListStore from "../../../store/BrandCommunityProductListStore";
import { ReactComponent as LatestIcon } from "../../../assets/icons/clock_purple.svg";
import { ReactComponent as SearchGrayIcon } from "../../../assets/icons/search_gray.svg";
import { observer } from "mobx-react";
import { KeywordText } from "../../../components/common";

const BrandCommunityProductSearchWord = observer(() => {

  const {
    filterRecentList,
    autoCompleteList,
    keyword,
    setRecentWord,
    getSearchResult,
    setShowWord,
    setShowResult,
    setKeyword,
  } = BrandCommunityProductListStore;

  const onClickWord = (word: string) => {
    setKeyword(word);
    void setRecentWord(word);
    void getSearchResult(word, 0);
    setShowWord(false);
    setShowResult(true);
  };

  const renderFilterRecent = (word: string, index: number) => {
    return (
      <button
        style={styles.auto_complete_item}
        key={`Recent_${word}_${index.toString()}`}
        onClick={() => onClickWord(word)}
      >
        <div style={{ flexShrink: 0, height: 20 }}>
          <LatestIcon />
        </div>
        <KeywordText
          sentence={word}
          keyword={keyword || ""}
          containerStyle={styles.auto_complete_text}
          matchTextStyle={styles.auto_complete_match_text}
          accessibilityLabel={`Product Recent Keyword item ${index}`}
        />
      </button>
    );
  };

  const renderAutoComplete = (word: string, index: number) => {
    return (
      <button
        style={styles.auto_complete_item}
        key={`Auto_${word}_${index.toString()}`}
        onClick={() => onClickWord(word)}
      >
        <div style={{ flexShrink: 0, height: 20 }}>
          <SearchGrayIcon />
        </div>
        <KeywordText
          sentence={word}
          keyword={keyword || ""}
          containerStyle={styles.auto_complete_text}
          matchTextStyle={styles.auto_complete_match_text}
          accessibilityLabel={`Product Auto Complete Keyword item ${index}`}
        />
      </button>
    );
  };

  return (
    <div style={{ ...styles.auto_complete_list, paddingBottom: 100 }}>
      {filterRecentList?.map((item: string, index: number) => renderFilterRecent(item, index))}
      {autoCompleteList?.map((item: string, index: number) => renderAutoComplete(item, index))}
    </div>
  );
});

export default BrandCommunityProductSearchWord;
