import React from "react";
import { ReactComponent as PostWriteIcon } from "../../../assets/icons/post_write.svg";
import { ReactComponent as ABWriteIcon } from "../../../assets/icons/ab_write.svg";
import { ReactComponent as QnAWriteIcon } from "../../../assets/icons/qna_write.svg";
import { FeedTypeCode } from "../../../constants/Feed";
import "./AddModalItem.scss";
import TouchableWithAuthCheck from "../../common/TouchableWithAuthCheck";

const AddModalItem = ({
  onClick,
  actionText,
  infoText,
  isLastChild,
  accessibilityLabel,
  feedTypeCode,
  beforeOnPress,
}: {
  onClick: () => void;
  actionText: string;
  infoText: string;
  isLastChild: boolean;
  accessibilityLabel: string;
  feedTypeCode: FeedTypeCode;
  beforeOnPress: () => void;
}) => {
  return (
    <button id="AddModalItem" onClick={onClick}>
      {/* web 2차 오픈 - 로그인 여부 상관없이 앱 유도팝업 노출*/}
      {/* <TouchableWithAuthCheck
        id="AddModalItem"
        onPress={onClick}
        aria-label={accessibilityLabel}
        beforeOnPress={beforeOnPress}
      > */}
      <div className={`item_wrap ${isLastChild ? "last_item_wrap" : ""}`}>
        {feedTypeCode === FeedTypeCode.POST && (
          <PostWriteIcon
            style={{ width: 48, height: 48 }}
            className="write_icon"
          />
        )}
        {feedTypeCode === FeedTypeCode.AB && (
          <ABWriteIcon
            style={{ width: 48, height: 48 }}
            className="write_icon"
          />
        )}
        {feedTypeCode === FeedTypeCode.QNA && (
          <QnAWriteIcon
            style={{ width: 48, height: 48 }}
            className="write_icon"
          />
        )}
        <div className="item">
          <div className="action_text">{actionText} </div>
          <div className="info_text">{infoText} </div>
        </div>
      </div>
      {/* </TouchableWithAuthCheck> */}
    </button>
  );
};

export default AddModalItem;
