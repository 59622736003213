import React from "react";
import { observer } from "mobx-react";
import { Color } from "../../assets/styles/variable";
import RelatedProductList from "./presenter/RelatedProductList";
import { useLocation, useParams } from "react-router-dom";

const RelatedProductScreen = observer(() => {
  const location = useLocation();
  const params = location?.state;
  const productId = Number(useParams().productId || params?.productId);
  const productName = params?.productName;

  return (
    <div
      style={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Color.WHITE,
      }}
      aria-label={"Related product screen"}
    >
      <RelatedProductList productId={productId} productName={productName} />
    </div>
  );
});

export default RelatedProductScreen;
