import React, { useEffect, useState, useRef } from 'react'
import ProfileHeader from '../../../components/common/ProfileHeader'
import { Styles } from '../../../assets/types/Style'
import SettingStore from '../../../store/SettingStore'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { CustomActivityIndicator } from '../../../components/common'
import { QaStatusCode } from '../../../constants/Settings'
import { ReactComponent as NothingSearchListImage } from '../../../assets/images/nodata_inquery_bg.svg'
import { ReactComponent as IconMall } from '../../../assets/icons/icon_mall_shopping.svg'
import { ReactComponent as IconArrowRight } from '../../../assets/icons/arrow_right_purple.svg'

import { getDateAndTimeFormat } from '../../../utils/datetime'
import { handleTopButtonScroll } from '../../../utils/common'
import { goToLifecareInquiryPage } from '../../../utils/mall'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { goTo } from '../../../hooks/navigate/NavigateFunction'
import classes from './Qa.module.scss'
import clsx from 'clsx'
import { QaInfo } from '../../../api/setting/qa/model'
import { useLocation } from 'react-router-dom'
import CommonStore from '../../../store/CommonStore'

const Qa = observer(() => {
  const {
    getQaList,
    getMoreQaList,
    setIsQaInit,
    isQaInit,
    qaList,
    setIsQaLoadingMore,
    isQaLoadingMore,
    setIsQaEndPage,
    isQaEndPage,
  } = SettingStore

  const { t } = useTranslation()
  const pageSize = 9999
  const [pageIndex, setPageIndex] = useState<number>(0)
  const [qaStatusCode, setQaStatusCode] = useState<string>('ALL')
  const [isLoading, setLoading] = useState<boolean>(true)
  const [itemIndex, setItemIndex] = useState(0)
  const [tabCode, setTabCode] = useState('ALL')
  const flatListRef = useRef<VirtuosoHandle>(null)
  const [showTopButton, setShowTopButton] = useState<boolean>(false)
  const [contentWidth, setContentWidth] = useState<number>(0)

  const getInitQaList = (qaStatusCode: string) => {
    setPageIndex(0)
    setLoading(true)
    setIsQaLoadingMore(true)
    setIsQaEndPage(false)
    void getQaList(pageSize, 0, qaStatusCode).finally(() => {
      setLoading(false)
    })
  }

  /* eslint-disable */
  useEffect(() => {
    setItemIndex(0)
    void getInitQaList(qaStatusCode)
  }, [qaStatusCode])

  useEffect(() => {
    if (isQaInit) {
      selectTab('ALL')
      setItemIndex(0)
      setIsQaInit(false)
      itemIndex > 0 &&
        flatListRef?.current?.scrollToIndex({
          index: 0,
        })
      return
    }

    if (
      itemIndex > 0 &&
      flatListRef?.current &&
      itemIndex <= qaList.length - 1
    ) {
      flatListRef?.current?.scrollToIndex({
        index: itemIndex,
      })
    } else if (itemIndex >= qaList.length - 1) {
      flatListRef?.current?.scrollToIndex(qaList.length - 1)
    }
  }, [])

  const { hideBack }: { hideBack: boolean } = useLocation().state

  const selectTab = (tab: string) => {
    setQaStatusCode(tab)
    setTabCode(tab)
    void getInitQaList(tab)
  }

  const renderEachQa = (index: number, eachQa: QaInfo) => {
    const {
      qaId,
      qaTypeName,
      qaStatusCode,
      qaStatusName,
      qaContent,
      createdDatetime,
    } = eachQa

    return (
      <button
        onClick={() => {
          setItemIndex(index)
          goTo(`/qa/${qaId}`, { state: { tabCode } })
        }}
        className={classes.qna_item_wrap}
      >
        <div
          className={clsx(classes.list, classes.list_border)}
          // TODO: ?
          // onLayout={(event) => {
          //   if (contentWidth == 0) {
          //     setContentWidth(event.nativeEvent.layout.width)
          //   }
          // }}
        >
          <div className={classes.qna_type_wrap}>
            <span className={classes.qna_type}>{qaTypeName}</span>
            <div className={classes.extra} />
          </div>
          <span
            aria-label="QNA List Item Content"
            className={classes.qna_contents}
            // TODO: ?
            // numberOfLines={2}
            // ellipsizeMode="tail"
            // lineBreakMode="tail"
          >
            {qaContent || ''}
          </span>
          <div className={classes.qna_info}>
            <span className={classes.qna_info_date}>
              {getDateAndTimeFormat(createdDatetime || '')}
            </span>
            <span
              className={
                qaStatusCode == QaStatusCode.COMPLETED
                  ? clsx(
                      classes.qna_info_status,
                      classes.qna_info_status_finish
                    )
                  : classes.qna_info_status
              }
            >
              {qaStatusName}
            </span>
          </div>
        </div>
      </button>
    )
  }

  const renderFooter = () => {
    return <CustomActivityIndicator isDarkSpinner={true} />
  }

  const onMallInqueryClick = () => {
    // goToLifecareInquiryPage()
    // TODO: 220몰 구현시 확인
    CommonStore.setShowDownloadAppDrive(true)
  }

  return (
    <div className={classes.qa_list}>
      <div className={classes.header_wrap}>
        <ProfileHeader
          title={t('screen.setting.label.qa')}
          buttonName={t('screen.setting.label.qaWrite')}
          onSkip={() => goTo('/qa/write')}
          hideBack={hideBack}
        />
      </div>

      <div className={classes.bx_mall_link}>
        <button className={classes.bx_in_mall} onClick={onMallInqueryClick}>
          <div className={classes.bx_icon}>
            <IconMall />
          </div>
          <div className={classes.bx_link_text}>
            <div className={classes.bx_label}>
              <span className={classes.txt_label}>
                {t('screen.setting.label.220mallqaishere')}
              </span>
            </div>
            <div className={classes.bx_label}>
              <span className={classes.txt_label_sm}>
                {t('screen.setting.label.220mallqa')}
              </span>
              <IconArrowRight />
            </div>
          </div>
        </button>
      </div>

      <div className={classes.tab_box}>
        <button
          className={
            clsx(classes.tab, qaStatusCode == 'ALL' ? classes.active_tab : '')
          }
          onClick={() => {
            selectTab('ALL')
            setShowTopButton(false)
          }}
          aria-label="allQaList"
        >
          <span
            className={clsx(
              classes.tab_txt,
              qaStatusCode == 'ALL' ? classes.active_tab_txt : ''
            )}
          >
            {t('screen.setting.tab.all')}
          </span>
        </button>
        <button
          className={clsx(
            classes.tab,
            qaStatusCode == QaStatusCode.COMPLETED ? classes.active_tab : ''
          )}
          onClick={() => {
            selectTab('COMPLETED')
            setShowTopButton(false)
          }}
          aria-label="compeletedQaList"
        >
          <span
            className={clsx(
              classes.tab_txt,
              qaStatusCode == QaStatusCode.COMPLETED
                ? classes.active_tab_txt
                : ''
            )}
          >
            {t('screen.setting.tab.replyCompeleted')}
          </span>
        </button>
      </div>
      {isLoading ? (
        <CustomActivityIndicator isDarkSpinner={true} />
      ) : (
        <>
          <Virtuoso
            className={classes.list_wrap}
            ref={flatListRef}
            data={qaList}
            itemContent={renderEachQa}
            useWindowScroll
            components={{
              // Footer: () => (!isQaEndPage ? renderFooter() : <></>),
              EmptyPlaceholder: () => (
                <div className={classes.nothing_search_img}>
                  <NothingSearchListImage />
                  <div className={classes.empty_wrap}>
                    <span className={classes.empty_text}>
                      {qaStatusCode === 'ALL'
                        ? t('screen.setting.message.qaNoData')
                        : t('screen.setting.message.answeredQaNoData')}
                    </span>
                  </div>
                </div>
              ),
            }}
            endReached={() => {
              if (isQaLoadingMore) {
                void getMoreQaList(pageSize, pageIndex + 1, qaStatusCode)
                setPageIndex(pageIndex + 1)
                setIsQaLoadingMore(false) // 중복 호출 방지
              }
            }}
            // onEndReachedThreshold={0.01}
            // onScroll={(event) =>
            //   handleTopButtonScroll(
            //     event,
            //     () => {
            //       setShowTopButton(true)
            //     },
            //     () => {
            //       setShowTopButton(false)
            //     },
            //     flatListRef
            //   )
            // }
          />
          {/* <TopButton showTopButton={showTopButton} ref={flatListRef} /> */}
        </>
      )}
    </div>
  )
})

export default Qa
