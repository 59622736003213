export const POST_TEMP_DATA = "postTempData";
export const PRODUCT_SEARCH_WORD = "productSearchWord";
export const ARTICLE_SEARCH_WORD = "articleSearchWord";
export const PUSH_ALLOW_YN = "pushAllowYn";
export const LAST_LOGIN_TYPE = "lastLoginType";
export const NOTICE_BANNER = "noticeBanner";
export const POPUP_BANNER = "popupBanner";
export const CATEGORY_TOOLTIP = "categoryToolTip";
export const MAIN_TOOLTIP = "MAIN_TOOLTIP";
export const SESSION_USER = "session_user";
export const TEMP_MEMBER = "temp_member";
export const BRAND_COMMUNITY_INTRODUCTION = "brand_community_introduction";
export const MAIN_MALL_NOTICE = "main_mall_notice";
export const REDIRECT_PATH = "REDIRECT_PATH";
export const WEB_UNIQUE_KEY = "WEB_UNIQUE_KEY";
