import React, {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Styles } from "../../assets/types/Style";

import {
  Arranges,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
  Colors,
} from "../../assets/styles";
import { TitleHeader } from "../../components/common";
import { ReactComponent as ArrowRightProfile } from "../../assets/icons/arrow_right_profile.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as PhotoIcon } from "../../assets/icons/icon_photo.svg";
import { ReactComponent as RectMultiIcon2 } from "../../assets/icons/4907_myhome_setting_color.svg";
import { ReactComponent as ArrowRightBlack } from "../../assets/icons/arrow_right_black.svg";
import { ReactComponent as TopIcon } from "../../assets/icons/top_btn.svg";
import { ReactComponent as Area } from "../../assets/icons/area.svg";

import { GenderTypeCode } from "../../constants/CommonCode.enum";
import {
  MemberInfoResponse,
  MemberTagItem,
  NicknameValidationResult,
  UpdateMemberRequest,
  UserInfo,
} from "../../api/member/model";
import CommonResponse from "../../api/http";
import AuthStore from "../../store/AuthStore";
import { validateUrl } from "../../utils/url";
import { validateNicknameFormat } from "../../utils/nickname";
import { validateNickname, updateMember } from "../../api/member/api";
import { useTranslation } from "react-i18next";
import ProfileSelectComponent from "./ProfileSelectComponent";

import { SuccessOrNot } from "../../constants/Common.enum";
import { mergeAndSetSessionUserInStorage } from "../../service/Session.service";
import BirthYearBottomSheet from "../../components/common/BirthYearBottomSheet";
import ProfileStore from "../../store/ProfileStore";
import CustomBottomSheet from "../../components/common/CustomBottomSheet";
// import RemoteConfigStore from "../../store/RemoteConfigStore";

import { interestedTag } from "../../api/tag/api";
import { RecommendedNicknameCode } from "../../constants/CommonCode.enum";
import { useLocation } from "react-router-dom";
import "./ProfileUpdate.scss";
import clsx from "clsx";
import { goBack, goTo } from "../../hooks/navigate/NavigateFunction";
import CommonStore from "../../store/CommonStore";

interface ProfileUpdateProps {
  profileImage: string;
  backgroundColor: string;
  nickname: string;
  introduction: string;
  snsUrl: string;
  gender: string;
  birthYear: string;
  accountInfo: MemberInfoResponse;
  recommendedNicknameYn: string;
}

const EMPTY_STRING = "";
const PUBLIC_PROFILE_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL || "";
const PREFIX_SYSYEM_PROFILE_PATH: string =
  PUBLIC_PROFILE_BUCKET_URL + "/PROFILE/SYSTEM/sample_";

const ProfileUpdate = () => {
  const { sessionUser } = AuthStore;
  const { cameraProfile, clear, uploadFileAndGetFilePath } = ProfileStore;
  const location = useLocation();

  const {
    profileImage,
    backgroundColor,
    nickname,
    introduction,
    snsUrl,
    gender,
    birthYear,
    recommendedNicknameYn,
    accountInfo,
  }: ProfileUpdateProps = location.state;
  const descriptionInputRef = useRef<HTMLTextAreaElement>(null);
  const snsInputRef = useRef<HTMLTextAreaElement>(null);

  const { t } = useTranslation();

  const [selectedProfileImage, setSelectedProfileImage] =
    useState(profileImage);
  const [isProfileImagePopupVisible, setIsProfileImagePopupVisible] =
    useState(false);

  const [selectedBackgroundColor, setSelectedBackgroundColor] =
    useState<string>(backgroundColor);
  const [currentBackgroundColor, setCurrentBackgroundColor] =
    useState<string>(backgroundColor);
  const [showBackgroundColorBottomSheet, setShowBackgrounColorBottomSheet] =
    useState<boolean>(false);

  const [currentNickname, setCurrentNickname] = useState<string>(nickname);
  const [nicknameValidationResult, setNicknameValidationResult] =
    useState<NicknameValidationResult>();
  const [isNicknameValid, setIsNicknameValid] = useState<boolean>(false);
  const [currentIntroduction, setCurrentIntroduction] = useState<string>(
    introduction || ""
  );

  const [currentSnsUrl, setCurrentSnsUrl] = useState<string>(snsUrl || "");
  const [isCurrentSnsUrlValid, setIsCurrentSnsUrlValid] =
    useState<boolean>(false);

  const [currentGender, setCurrentGender] = useState<string>(gender);
  const [showBirthYearBottomSheet, setShowBirthYearBottomSheet] =
    useState<boolean>(false);
  const [currentBirthYear, setCurrentBirthYear] = useState<string>(birthYear);

  // const [bottomInset, setBottomInset] = useState<number>(
  //   insets.bottom / PixelRatio.get()
  // );
  // const bottomHeight = 56 + bottomInset;

  // const statusBarHeight = getStatusBarHeight();
  const [introInputY, setIntroInputY] = useState(0);
  const [introInputY2, setIntroInputY2] = useState(0);
  const scrollRef = useRef<ReactElement>(null);
  // const bottomHeight = 56 + bottomInset;

  const showProfileImagePopup = () => {
    setIsProfileImagePopupVisible(true);
  };

  const hideProfileImagePopup = () => {
    setIsProfileImagePopupVisible(false);
  };

  const handleProfileImageEditClick = () => {
    showProfileImagePopup();
  };

  const handleProfileImageSelect = (uri: string) => () => {
    setSelectedProfileImage(uri);
    hideProfileImagePopup();
  };

  const setMyNickname = async (nickname: string) => {
    setCurrentNickname(nickname);

    let isNicknameValid = false;
    if (validateNicknameFormat(nickname)) {
      const res = await validateNickname(nickname);
      setNicknameValidationResult(res);

      if (res && res.isExist === "N" && !res.hasBadWord) {
        isNicknameValid = true;
      }
    }
    setIsNicknameValid(isNicknameValid);
  };

  const onNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    void setMyNickname(e.target.value);
  };

  const clearNickname = () => {
    setCurrentNickname(EMPTY_STRING);
    setNicknameValidationResult(undefined);
    setIsNicknameValid(false);
  };

  const renderNicknameGuideComponent = (currentNickname: string) => {
    if (nickname !== currentNickname) {
      if (currentNickname.length < 4) {
        return (
          <p className="nik_name nik_name_fail">
            {t("screen.profileUpdate.nickname.guideLabel.inputGuide")}
          </p>
        );
      } else if (currentNickname.length > 15) {
        return (
          <p className="nik_name nik_name_fail">
            {t("screen.profileUpdate.nickname.guideLabel.invalidLength")}
          </p>
        );
      } else if (!validateNicknameFormat(currentNickname)) {
        return (
          <p className="nik_name nik_name_fail">
            {t("screen.profileUpdate.nickname.guideLabel.invalidFormat")}
          </p>
        );
      }

      if (nicknameValidationResult) {
        if (nicknameValidationResult.hasBadWord) {
          return (
            <p className="nik_name nik_name_fail">
              {t("screen.profileUpdate.nickname.guideLabel.hasBadWord")}
            </p>
          );
        }

        if (nicknameValidationResult.isExist === "N") {
          return (
            <p className="nik_name nik_name_success">
              {t("screen.profileUpdate.nickname.guideLabel.validNickname")}
            </p>
          );
        } else {
          return (
            <p className="nik_name nik_name_fail">
              {t(
                "screen.profileUpdate.nickname.guideLabel.nicknameAlreadyInUse"
              )}
            </p>
          );
        }
      }
    }
  };

  const onIntroductionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let introduction = e.target.value;
    const howManyEnters = [...introduction.matchAll(/\n/g)].length;
    const hasMultiEnter = introduction.lastIndexOf("\n\n") >= 0;
    if (introduction.length > 50) {
      return;
    }

    if (hasMultiEnter) {
      introduction = introduction.replace("\n\n", "\n");
    }

    if (howManyEnters >= 5) {
      const idx = introduction.lastIndexOf("\n");
      introduction = introduction.substring(0, idx);
    }

    setCurrentIntroduction(introduction);
  };

  const onSnsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentSnsUrl(e.target.value);
  };

  const renderSnsGuideText = () => {
    if (currentSnsUrl && !isCurrentSnsUrlValid) {
      return (
        <p className="sns_fail">
          {t("screen.profileUpdate.introduction.sns.invalidSnsUrl")}
        </p>
      );
    }
  };

  const onBgBottomSheet = () => {
    //    console.log("setShowBackgrounColorBottomSheet true");
    setShowBackgrounColorBottomSheet(true);
  };

  const onBgCloseBottomSheet = () => {
    //  console.log("setShowBackgrounColorBottomSheet true");
    setShowBackgrounColorBottomSheet(false);
  };

  const firstRowColors = [
    "#68276e",
    "#3b3758",
    "#2d4b57",
    "#4c73a0",
    "#b59c8f",
  ];
  const secondRowColors = [
    "#3a4f93",
    "#aa5377",
    "#ac575c",
    "#424242",
    "transparent",
  ];

  const renderBackgroundColorPopup = () => {
    return (
      <CustomBottomSheet
        open={showBackgroundColorBottomSheet}
        onClose={onBgCloseBottomSheet}
        snapHeight={280}
        modalContentStyles={{ padding: 0, height: "unset" }}
      >
        <div id="change-bgc-bottom-sheet" className="bg_sheet">
          <div
            className="close_btn"
            onClick={() => {
              onBgCloseBottomSheet();
            }}
          >
            <CloseIcon />
          </div>

          <div className="bg_title_wrap">
            <p className="bg_title">
              {t("screen.profileUpdate.backgroundColor.header")}
            </p>
          </div>
          <div
            className="bg_list"
            // style={{ ...bg_list, paddingTop: -bottomInset }
          >
            <div className="bg_list_top">
              {firstRowColors.map((color, index) => (
                <div
                  key={color}
                  className={clsx("bg_item", {
                    active_item: selectedBackgroundColor === color,
                  })}
                  style={{ backgroundColor: color }}
                  onClick={() => {
                    setSelectedBackgroundColor(color);
                  }}
                >
                  <div></div>
                </div>
              ))}
            </div>
            <div className="bg_list_bottom">
              {secondRowColors.map((color, index) => {
                if (index !== secondRowColors.length - 1) {
                  return (
                    <>
                      <div
                        key={color}
                        className={clsx("bg_item", {
                          active_item: selectedBackgroundColor === color,
                        })}
                        style={{ backgroundColor: color }}
                        onClick={() => {
                          setSelectedBackgroundColor(color);
                        }}
                      ></div>
                    </>
                  );
                } else {
                  return (
                    <div
                      className="bg_item"
                      key={color}
                      // style={[
                      //   bg_item,
                      //   last_item,
                      //   { backgroundColor: "transparent" },
                      // ]}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div
            className="bg_save_btn"
            onClick={() => {
              setCurrentBackgroundColor(selectedBackgroundColor);
              onBgCloseBottomSheet();
            }}
          >
            <p className="bg_save_btn_text">
              {t("screen.profileUpdate.backgroundColor.saveButtonLabel")}
            </p>
          </div>
        </div>
      </CustomBottomSheet>
    );
  };

  const updateProfile = async (filePath?: string) => {
    if (sessionUser?.memberUuid) {
      const updateMemberRequest: UpdateMemberRequest = {};
      const updateSession: UserInfo = {};

      if (backgroundColor !== currentBackgroundColor) {
        updateMemberRequest.profileBackgroundColor = currentBackgroundColor;
        updateSession.profileBackgroundColor = currentBackgroundColor;
      }

      if (nickname !== currentNickname && isNicknameValid) {
        updateMemberRequest.nickname = currentNickname;
        updateSession.nickname = updateMemberRequest.nickname;

        if (recommendedNicknameYn === RecommendedNicknameCode.RECOMMENDED) {
          updateMemberRequest.recommendedNicknameYn =
            RecommendedNicknameCode.CHANGED;
        }
      }

      if (introduction !== currentIntroduction) {
        updateMemberRequest.introduce = currentIntroduction;
      }

      if (snsUrl !== currentSnsUrl && isCurrentSnsUrlValid) {
        updateMemberRequest.snsUrl = currentSnsUrl;
      }

      if (gender !== currentGender) {
        updateMemberRequest.genderCode = currentGender;
      }

      if (birthYear !== currentBirthYear) {
        updateMemberRequest.birthYear = currentBirthYear;
      }

      if (profileImage !== selectedProfileImage) {
        const profileUrl =
          filePath ||
          selectedProfileImage.replace(PUBLIC_PROFILE_BUCKET_URL, EMPTY_STRING);
        updateMemberRequest.profileUrl = profileUrl;
        updateSession.profileUrl = profileUrl;
      }

      const response: CommonResponse = await updateMember(updateMemberRequest);

      if (response.successOrNot === SuccessOrNot.Y) {
        await mergeAndSetSessionUserInStorage(updateSession);
      }
      // goBack(); TODO: 띄운거 닫기
      clear();
    }
  };

  const handleEditButtonClick = async () => {
    if (cameraProfile) {
      const filePath = await uploadFileAndGetFilePath(cameraProfile);
      await updateProfile(filePath);
    } else {
      await updateProfile();
    }
    goBack();
  };

  const canEditProfile = () => {
    const prevIntroduction = !introduction ? "" : introduction;
    const prevSnsUrl = !snsUrl ? "" : snsUrl;

    return (
      (profileImage !== selectedProfileImage ||
        backgroundColor !== currentBackgroundColor ||
        nickname !== currentNickname ||
        prevIntroduction !== currentIntroduction ||
        prevSnsUrl != currentSnsUrl ||
        gender !== currentGender ||
        birthYear !== currentBirthYear) &&
      (nickname === currentNickname || isNicknameValid) &&
      (prevSnsUrl === currentSnsUrl || isCurrentSnsUrlValid)
    );
  };

  const handleHeightInput = (ref: RefObject<HTMLTextAreaElement>) => {
    const element = ref.current;
    if (element) {
      element.style.height = "auto";
      element.style.height = element.scrollHeight + "px";
    }
  };

  useEffect(() => {
    if (validateUrl(currentSnsUrl) || currentSnsUrl.length === 0) {
      setIsCurrentSnsUrlValid(true);
    } else {
      setIsCurrentSnsUrlValid(false);
    }
  }, [currentSnsUrl]);

  // const showKeyboardActionName: KeyboardEventName = Platform.select({
  //   android: "keyboardDidShow",
  //   default: "keyboardWillShow",
  // });
  // const hideKeyboardActionName: KeyboardEventName = Platform.select({
  //   android: "keyboardDidHide",
  //   default: "keyboardWillHide",
  // });

  // const showKeyboardSubscription = Keyboard.addListener(
  //   showKeyboardActionName,
  //   () => {
  //     setBottomInset(0);
  //   }
  // );
  // const hideKeyboardSubscription = Keyboard.addListener(
  //   hideKeyboardActionName,
  //   () => {
  //     setBottomInset(insets.bottom / PixelRatio.get());
  //   }
  // );

  // const componentDidUnmount = useCallback(() => {
  //   showKeyboardSubscription.remove();
  //   hideKeyboardSubscription.remove();
  // }, [showKeyboardSubscription, hideKeyboardSubscription]);

  // useEffect(() => {
  //   return componentDidUnmount;
  // }, [componentDidUnmount]);

  const [showTopIcon, setShowTopIcon] = useState(false);
  // const handleTopButtonScroll2 = (
  //   event: any,
  //   showButton: () => void,
  //   hideButton: () => void
  // ) => {
  //   const currentHeightOfScroll = event.nativeEvent.contentOffset.y;
  //   // const { height } = Dimensions.get("screen");
  //   if (currentHeightOfScroll >= height / 3) {
  //     showButton();
  //   } else {
  //     hideButton();
  //   }
  // };

  const [interestedTagList, setInterestedTagList] = useState<MemberTagItem[]>(
    []
  );
  const [isFocused, setIsFocused] = useState<boolean>(false);

  // 첫 렌더링시에만 실행
  useEffect(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    if (isFocused) {
      const getInterestedTagList = async (memberUuid: string) => {
        const response: MemberTagItem[] = await interestedTag(
          memberUuid,
          false
        );
        if (response) setInterestedTagList(response);
      };
      void getInterestedTagList(sessionUser?.memberUuid || "");
    }
    return () => {
      // undefined;
    };
  }, [isFocused, sessionUser?.memberUuid]);

  useEffect(() => {
    if (descriptionInputRef.current) {
      handleHeightInput(descriptionInputRef);
    }
  }, [descriptionInputRef, currentIntroduction]);

  useEffect(() => {
    if (snsInputRef.current) {
      handleHeightInput(snsInputRef);
    }
  }, [snsInputRef, currentSnsUrl]);

  return (
    <div
      className="profile_editor"
      // edges={["top", "left", "right"]}
      aria-label="profile edit"
      id="profile_editor"
    >
      <TitleHeader
        style={{
          top: 0,
          maxWidth: 450,
        }}
        title={t("screen.profileUpdate.header")}
        isHome
      />
      <div
      // ref={scrollRef}
      // scrollIndicatorInsets={{ right: 0.1 }}
      // scrollEventThrottle={0}
      // TODO
      // onScroll={(event) =>
      //   handleTopButtonScroll2(
      //     event,
      //     () => {
      //       setShowTopIcon(true);
      //     },
      //     () => {
      //       setShowTopIcon(false);
      //     }
      //   )
      // }
      >
        <div>
          <div
            className="photo_editor_box"
            style={{
              backgroundColor: currentBackgroundColor,
              paddingTop: 54,
            }}
          >
            <div className="photo_wrap">
              <img className="profile_image" src={selectedProfileImage} />
              <div
                className="ellipse_wrap"
                onClick={() => {
                  handleProfileImageEditClick();
                }}
              >
                <div className="ellipse">
                  <PhotoIcon />
                </div>
              </div>
            </div>

            <div
              className="profile_button"
              onClick={() => {
                onBgBottomSheet();
              }}
            >
              <div className="ellipse2">
                <RectMultiIcon2 />
              </div>
            </div>
          </div>

          <div className="profile_info_box">
            <div className="info_wrap">
              <div className="">
                <p className="title">
                  {t("screen.profileUpdate.nickname.header")}
                </p>
                {recommendedNicknameYn ===
                  RecommendedNicknameCode.RECOMMENDED &&
                  nickname === currentNickname && (
                    <p className="nickname_guide">
                      {t(
                        "screen.profileUpdate.nickname.guideLabel.recommendnicknameGuide"
                      )}
                    </p>
                  )}
              </div>
              <div className="nik_name_box">
                <input
                  className="input nik_name_input"
                  value={currentNickname}
                  // returnKeyType={"next"}
                  // onSubmitEditing={() => {
                  //   descriptionInputRef?.current?.focus();
                  // }}
                  onChange={onNicknameChange}
                  aria-label={`nickname`}
                  maxLength={15}
                ></input>
                {!!currentNickname && (
                  <div className="profile_x" onClick={clearNickname}>
                    <ArrowRightProfile />
                  </div>
                )}
              </div>
              {renderNicknameGuideComponent(currentNickname)}
            </div>

            <div className="info_wrap">
              <p className="title">
                {t("screen.profileUpdate.introduction.header")}
              </p>
              <div
                className="introduce"
                // onLayout={(event) => {
                //   const layout = event.nativeEvent.layout;
                //   setIntroInputY(layout.y);
                // }}
              >
                <textarea
                  ref={descriptionInputRef}
                  className="input introduce_txt_input"
                  value={currentIntroduction}
                  onChange={onIntroductionChange}
                  // multiline={true}
                  rows={1}
                  placeholder={t(
                    "screen.profileUpdate.introduction.description.placeholder"
                  )}
                  // onFocus={(event) => {
                  //   Platform.OS === "ios" &&
                  //     setTimeout(() => {
                  //       scrollRef.current?.scrollTo({
                  //         y: introInputY + bottomHeight,
                  //         animated: true,
                  //       });
                  //     }, 100);
                  // }}
                />
              </div>
              <div
                className="sns"
                // onLayout={(event) => {
                //   const layout = event.nativeEvent.layout;
                //   setIntroInputY2(layout.y);
                // }}
              >
                <textarea
                  // style={[txt_input, introduce_txt_input]}
                  ref={snsInputRef}
                  className="input sns_txt_input"
                  value={currentSnsUrl}
                  onChange={onSnsChange}
                  rows={1}
                  // multiline={true}
                  placeholder={t(
                    "screen.profileUpdate.introduction.sns.placeholder"
                  )}
                  onFocus={(event) => {
                    // TODO
                    // Platform.OS === "ios" &&
                    //   setTimeout(() => {
                    //     scrollRef.current?.scrollTo({
                    //       y: introInputY2 + bottomHeight,
                    //       animated: true,
                    //     });
                    //   }, 100);
                  }}
                />
              </div>
              {renderSnsGuideText()}
            </div>

            {/* TODO : 복구 RemoteConfigStore.show_gender_birthyear === "true" &&  ()*/}
            <>
              <div className="info_wrap">
                <p className="title">
                  {t("screen.profileUpdate.gender.header")}
                </p>
                <div className="gender_box">
                  <div
                    className={clsx(
                      "gender_btn",
                      currentGender === GenderTypeCode.FEMALE
                        ? "gender_btn_selected"
                        : "gender_btn_not_selected"
                    )}
                    // style={[
                    //   gender_btn,
                    //   female,
                    //   currentGender === GenderTypeCode.FEMALE
                    //     ? gender_btn_selected
                    //     : gender_btn_not_selected,
                    // ]}
                    onClick={() => {
                      setCurrentGender(GenderTypeCode.FEMALE);
                    }}
                  >
                    <p
                    // style={[
                    //   currentGender === GenderTypeCode.FEMALE &&
                    //     gender_text_selected,
                    // ]}
                    >
                      {t("screen.profileUpdate.gender.female")}
                    </p>
                  </div>
                  <div
                    // style={[
                    //   gender_btn,
                    //   female,
                    //   currentGender === GenderTypeCode.MALE
                    //     ? gender_btn_selected
                    //     : gender_btn_not_selected,
                    // ]}
                    className={clsx(
                      "gender_btn",
                      currentGender === GenderTypeCode.MALE
                        ? "gender_btn_selected"
                        : "gender_btn_not_selected"
                    )}
                    onClick={() => {
                      setCurrentGender(GenderTypeCode.MALE);
                    }}
                  >
                    <p
                    // style={[
                    //   currentGender === GenderTypeCode.MALE &&
                    //     gender_text_selected,
                    // ]}
                    >
                      {t("screen.profileUpdate.gender.male")}
                    </p>
                  </div>
                  {/* 성별 기타 미노출 */}
                  {/* <div
                        style={[
                          gender_btn,
                          currentGender === GenderTypeCode.NA ? gender_btn_selected : gender_btn_not_selected,
                        ]}
                        onPress={() => {
                          setCurrentGender(GenderTypeCode.NA);
                        }}
                      >
                        <p style={[currentGender === GenderTypeCode.NA && gender_text_selected]}>
                          {t("screen.profileUpdate.gender.etc")}
                        </p>
                      </div> */}
                </div>
              </div>
              <div className="info_wrap" style={{ marginBottom: 0 }}>
                <p className="title">
                  {t("screen.profileUpdate.birthYear.header")}
                </p>
                <div
                  className="birth_year_box"
                  onClick={() => {
                    setShowBirthYearBottomSheet(true);
                  }}
                >
                  <p
                    // style={[txt_input2, born]}
                    className="born_txt"
                  >
                    {currentBirthYear &&
                    currentBirthYear !== null &&
                    !currentBirthYear.includes("NA")
                      ? t("common.label.birthYear", {
                          year: currentBirthYear,
                        })
                      : ""}
                  </p>
                  <Area className="area_icon" />
                </div>
              </div>
            </>

            <div className="option-list-box">
              <div
                // style={[
                //   styles.option_box,
                //   styles.option_box_border,
                //   { marginLeft: -16, marginRight: -16 },
                // ]}
                className="option-box"
                aria-label={""}
              >
                <button
                  className="option"
                  onClick={
                    () => {
                      CommonStore.setShowDownloadAppDrive(true);
                      // navigate("TagManagementScreen");
                    }
                    // item.onPress
                    //   ? item.onPress
                    //   : item.screenName && onPress(item.screenName, (item.screenParam ?? {}) as object)
                  }
                  disabled={false}
                >
                  <p className="option_txt">태그관리</p>
                  <div
                    className="icon_wrap"
                    // style={[footer_btn_wrap, Arranges.center_v_h]}
                  >
                    {interestedTagList.length > 0 && (
                      <p className="option_txt" style={{ color: "#be69c3" }}>
                        {interestedTagList.length}개
                      </p>
                    )}

                    <ArrowRightBlack />
                  </div>
                </button>
              </div>
              <div
                // style={[
                //   styles.option_box,
                //   styles.option_box_border,
                //   { marginLeft: -16, marginRight: -16 },
                // ]}
                className="option-box"
                aria-label={""}
              >
                <button
                  className="option"
                  onClick={
                    () => {
                      CommonStore.setShowDownloadAppDrive(true);
                      //  accountInfo: MemberInfoResponse;
                      // goTo("/Preference");
                    }
                    // item.onPress
                    //   ? item.onPress
                    //   : item.screenName && onPress(item.screenName, (item.screenParam ?? {}) as object)
                  }
                  disabled={false}
                >
                  <p className="option_txt">취향 설정</p>
                  <div
                    className="icon_wrap"
                    // style={[footer_btn_wrap, Arranges.center_v_h]}
                  >
                    <ArrowRightBlack />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* TODO : top button */}
      {/* {showTopIcon && (
          <div
            style={[
              styles.top_btn,
              { bottom: Platform.OS === "ios" ? insets.bottom + 55 : 55 },
            ]}
            onClick={() => {
              scrollRef.current?.scrollTo({
                y: 0,
                animated: true,
              });
            }}
          >
            <TopIcon />
          </div>
        )} */}
      <div className="footer_btn_wrap">
        <button
          // style={[
          //   canEditProfile() ? btn_1_active : btn_1,
          //   { height: bottomHeight },
          // ]}
          className={clsx("btn_1", canEditProfile() && "btn_1_active")}
          onClick={() => void handleEditButtonClick()}
          disabled={!canEditProfile()}
        >
          <p className="btn_1_txt">
            {t("screen.profileUpdate.updateButtonLabel")}
          </p>
          <div />
        </button>
      </div>

      {isProfileImagePopupVisible && (
        <ProfileSelectComponent
          prefixUri={PREFIX_SYSYEM_PROFILE_PATH}
          selectedUri={selectedProfileImage}
          handleSelect={handleProfileImageSelect}
          onClose={hideProfileImagePopup}
        />
      )}

      <BirthYearBottomSheet
        open={showBirthYearBottomSheet}
        selectedYear={currentBirthYear}
        onClose={() => {
          setShowBirthYearBottomSheet(false);
        }}
        onSelect={(year: string) => {
          setCurrentBirthYear(year);
        }}
      />

      {renderBackgroundColorPopup()}
    </div>
  );
};

export default ProfileUpdate;
