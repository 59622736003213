import { action, computed, makeObservable, observable } from "mobx";
import { GetArticleListRequest, ArticleListVO } from "../api/brand/model";
import { getArticleList } from "../api/brand/api";
import { Category, Tab } from "../constants/Article.enum";
import { SearchSortType } from "../constants/Search";
import { t } from "i18next";
import { BannerTypeCodes } from "../constants/Banner.enum";
import { BannerInfo } from "../api/banner/model";
import { getBrandCommunityBannerList } from "../api/banner/api";
import { parseQueryParam } from "../utils/queryParam";

export const PAGE_SIZE = 10;
class ArticleListStore {
  constructor() {
    makeObservable(this);
  }

  @observable _brandCommunityId = 0;
  @observable _brandCommunityName = "";
  @observable _corpMemberYn = "N";
  @observable _brandArticleTopAdBannerList: BannerInfo[] = [];
  @observable _brandArticleListAdBannerList: BannerInfo[] = [];
  @observable _articleList: ArticleListVO[] = [];
  @observable _topFixNoticeList: ArticleListVO[] = [];
  @observable _sort: SearchSortType = SearchSortType.LATEST;
  @observable _showSortBox = false;
  @observable _pageIndex = 0;
  @observable _totalCount = 0;
  @observable _selectedTab: Tab = Tab.ALL;
  @observable _selectedCategory: Category = Category.ALL;
  @observable _listAdPeriod = -1;
  @observable _isLoading = false;
  @observable toastMessage = "";

  @computed get brandCommunityId() {
    return this._brandCommunityId;
  }
  @computed get brandCommunityName() {
    return this._brandCommunityName;
  }
  @computed get corpMemberYn() {
    return this._corpMemberYn;
  }
  @computed get brandArticleTopAdBannerList() {
    return this._brandArticleTopAdBannerList;
  }
  @computed get brandArticleListAdBannerList() {
    return this._brandArticleListAdBannerList;
  }
  @computed get topFixNoticeList() {
    return this._topFixNoticeList;
  }
  @computed get articleList() {
    return this._articleList;
  }
  @computed get sort() {
    return this._sort;
  }
  @computed get showSortBox() {
    return this._showSortBox;
  }
  @computed get pageIndex() {
    return this._pageIndex;
  }
  @computed get totalCount() {
    return this._totalCount;
  }
  @computed get selectedCategory() {
    return this._selectedCategory;
  }
  @computed get selectedTab() {
    return this._selectedTab;
  }
  @computed get listAdPeriod() {
    return this._listAdPeriod;
  }

  @computed get isLoading() {
    return this._isLoading;
  }

  @action clearStore = () => {
    this._selectedTab = Tab.ALL;
    this._selectedCategory = Category.ALL;
    this._sort = SearchSortType.LATEST;
  };

  @action setBrandCommunityId = (brandCommunityId: number) => {
    this._brandCommunityId = brandCommunityId;
  };

  @action setCorpMemberYn = (corpMemberYn: string) => {
    this._corpMemberYn = corpMemberYn;
  };

  @action setBrandCommunityName = (brandCommunityName: string) => {
    this._brandCommunityName = brandCommunityName;
  };

  @action setBrandArticleTopAdBannerList = (brandArticleAdBannerList: BannerInfo[]) => {
    this._brandArticleTopAdBannerList = brandArticleAdBannerList;
  };

  @action setBrandArticleListAdBannerList = (brandArticleAdBannerList: BannerInfo[]) => {
    this._brandArticleListAdBannerList = brandArticleAdBannerList;
  };

  @action setTopFixNoticeList = (topFixNoticeList: ArticleListVO[]) => {
    this._topFixNoticeList = topFixNoticeList;
  };

  @action setArticleList = (articleList: ArticleListVO[]) => {
    this._articleList = articleList;
  };

  @action setToast = (message: string) => {
    this.toastMessage = message;
  };

  @action setSelectedTab = (tab: Tab) => {
    this._selectedTab = tab;
  };

  @action setSelectedCategory = (category: Category) => {
    this._selectedCategory = category;
  };

  @action setListAdPeriod = (period: number) => {
    this._listAdPeriod = period;
  };

  @action setIsLoading = (value: boolean) => {
    this._isLoading = value;
  };

  @action getBrandArticleBannerList = async () => {
    await getBrandCommunityBannerList(
      [BannerTypeCodes.COMMUNITY_ARTICLE_TOP_AD, BannerTypeCodes.COMMUNITY_ARTICLE_LIST_AD].toString()
    ).then((response) => {
      const res = response.map((bannerInfo) => ({
        ...bannerInfo,
        bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
      }));
      this.setBrandArticleTopAdBannerList(
        res.filter((banner) => banner.bannerTypeCode == BannerTypeCodes.COMMUNITY_ARTICLE_TOP_AD)
      );
      this.setBrandArticleListAdBannerList(
        res.filter((banner) => banner.bannerTypeCode == BannerTypeCodes.COMMUNITY_ARTICLE_LIST_AD)
      );
      if (this.brandArticleListAdBannerList.length > 0 && this.brandArticleListAdBannerList[0].bannerRevealPeriod)
        this.setListAdPeriod(this.brandArticleListAdBannerList[0].bannerRevealPeriod);
      else this.setListAdPeriod(5);
    });
  };

  @action initArticleList = async (getArticleRequestParam?: GetArticleListRequest) => {
    const getArticleListRequest: GetArticleListRequest = getArticleRequestParam
      ? getArticleRequestParam
      : {
          communityId: this.brandCommunityId,
          sort: this.sort,
          category: this.selectedCategory,
          tab: this.selectedTab,
        };

    const getNoticeListRequest: GetArticleListRequest = {
      ...getArticleListRequest,
      articleTopFixYn: "Y",
      tab: Tab.NOTICE,
    };

    this.setPageIndex(0);

    const articleShowAll = await getArticleList(getArticleListRequest);
    const noticeShowAll = await getArticleList(getNoticeListRequest);

    if (!articleShowAll.list || !noticeShowAll.list) {
      this.setToast(t("common.error.server"));
    }

    this.setTopFixNoticeList(noticeShowAll.list.slice(0, 2));
    this.setArticleList(articleShowAll.list);
    this.setTotalCount(articleShowAll.totalCount);

    await this.getBrandArticleBannerList();

    for (let i = 0; i <= this.totalCount / PAGE_SIZE && this.articleList.length === 0; i++) {
      await this.getBrandArticleList();
    }
  };

  @action getBrandArticleList = async () => {
    this.setPageIndex(this.pageIndex + 1);
    if (this.pageIndex > this.totalCount / PAGE_SIZE) {
      return;
    }

    const getArticleListRequest: GetArticleListRequest = {
      communityId: this.brandCommunityId,
      sort: this.sort,
      pageSize: PAGE_SIZE,
      pageIndex: this.pageIndex,
      category: this.selectedCategory,
      tab: this.selectedTab,
    };
    return await getArticleList(getArticleListRequest);
  };

  @action getMoreArticleList = async () => {
    if (this.articleList.length === 0) {
      return;
    }

    const articleList = await this.getBrandArticleList();
    if (!articleList) return;
    if (articleList.list.length === 0) {
      await this.getBrandArticleList();
    }
    this.setArticleList(this.articleList.concat(articleList.list));
    this.setTotalCount(articleList.totalCount);
  };

  @action setSort = (sort: SearchSortType) => {
    this._sort = sort;
    this.setShowSortBox(false);
  };

  @action setShowSortBox = (showSortBox: boolean) => {
    this._showSortBox = showSortBox;
  };

  @action setPageIndex = (pageIndex: number) => {
    this._pageIndex = pageIndex;
  };

  @action setTotalCount = (totalCount: number) => {
    this._totalCount = totalCount;
  };

  @action toggleCategory = (category: Category) => {
    this.setSelectedCategory(category);
  };

  @action toggleTab = (tab: Tab) => {
    this.setSelectedTab(tab);

    if (this.selectedTab === Tab.NOTICE) {
      this.setSelectedCategory(Category.ALL);
    }
  };
}
export default new ArticleListStore();
