import { TrackFunction } from '../types'
import { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'

type UseFacebook = () => {
  facebook: typeof ReactPixel
  log: TrackFunction
}

export const useFacebook: UseFacebook = () => {
  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    }
    ReactPixel.init(
      process.env.REACT_APP_FACEBOOK_APP_ID || '',
      undefined,
      options
    )
  }, [])

  const log: TrackFunction = (event, properties) => {
    ReactPixel.trackCustom(event, properties)
  }

  return {
    facebook: ReactPixel,
    log,
  }
}
