import React, { useCallback, useEffect, useRef, useState } from "react";
import { t } from "i18next";

import { FollowersTab, FOLLOWER_LIST_COUNT } from "../../../constants/Account";
import {
  FollowerItem,
  FollowersRequest,
  FollowersResponse,
} from "../../../api/follow/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { follow, getFollowerList, unfollow } from "../../../api/follow/api";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import CommonStore from "../../../store/CommonStore";
import AuthStore from "../../../store/AuthStore";
import { EmptyFollower } from ".";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { UserActTypeCode } from "../../../constants/FeedType.enum";
import FollowerStore from "../../../store/FollowerStore";
import KeywordText from "../../../components/common/KeywordText";
import NickNameSearch from "./NickNameSearch";
import BadgeStore from "../../../store/BadgeStore";
import { BadgeCode } from "../../../constants/Badge.enum";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import "./Followers.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { CustomActivityIndicator } from "../../../components/common";
import { useWindowScroll } from "react-use";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";

const Followers = ({
  isMy,
  userId,
  deletedUser,
  onClickDelete,
}: {
  isMy: boolean;
  userId: string;
  deletedUser: any | null;
  onClickDelete: (item: any) => void;
}) => {
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";
  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { x, y } = useWindowScroll();

  // 첫 렌더링시에만 실행
  useEffect(() => {
    setIsFocused(true);
  }, []);

  const [originFollowerData, setOriginFollowerData] = useState(
    {} as FollowersResponse
  );

  const [followerData, setFollowerData] = useState([] as FollowerItem[]);
  const [keyword, setKeyword] = useState("");
  const [searchStart, setSearchStart] = useState(0);
  const [onSearching, setSearching] = useState(true);
  const [noSearchData, setNoSearchData] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const inputRef = useRef<any>(null);
  const [showNickNameSearch, setShowNickNameSearch] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  // 첫 데이터
  useEffect(() => {
    if (AuthStore.sessionUser?.memberUuid !== undefined && isFocused) {
      const getFollowers = async () => {
        setSearching(true);
        setLoading(true);

        const params: FollowersRequest = {
          searchMemberUuid: userId,
          myMemberUuid: AuthStore.sessionUser?.memberUuid || "",
          type: FollowersTab.FOLLOWERS,
          pageSize: FOLLOWER_LIST_COUNT,
          pageIndex: 0,
          nickname: keyword,
        };
        const res = await getFollowerList(params);
        setFollowerData(res.list);
        setOriginFollowerData(res);
        setSearching(false);
        setLoading(false);
      };

      if (userId) {
        void getFollowers();
      }
    }
  }, [userId, isFocused, keyword]);

  useEffect(() => {
    const bottom =
      window.scrollY !== 0 &&
      Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

    if (bottom && !loading) {
      const start = searchStart + 1;
      if (originFollowerData?.totalCount > start * FOLLOWER_LIST_COUNT) {
        setSearchStart(start);
        void getFollowers(start, keyword);
      }
    }
  }, [y]);

  const onToast = (follow: boolean, item: any) => {
    CommonStore.setToastOption({
      show: true,
      message1: follow
        ? t("screen.myHome.message.followToast", {
            nickname: item.nickname,
          })
        : t("screen.myHome.message.unfollowToast", {
            nickname: item.nickname,
          }),
    });
  };

  const followUser = async (item: any) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.USER_FOLLOWING,
      item.memberUuid,
      ActPageCode.FOLLOWING_MANAGEMENT
    );

    changeFollowStatusInList(item, true);
    const res = await follow(item.memberUuid);
    FollowerStore.handlePostFollowUpdate(
      UserActTypeCode.FOLLOW,
      item.memberUuid
    );
    if (!res) changeFollowStatusInList(item, false);
    else {
      onToast(true, item);
      void BadgeStore.obtainBadge(BadgeCode.FOLLOW);
    }
  };

  const unFollowUser = async (item: any) => {
    //PPIT210117-4818 UNFOLLOW 시 actHistory 처리 추가
    CommonStore.callActHistory(
      ActHistoryTypeCode.USER_FOLLOWING,
      item.memberUuid,
      ActPageCode.FOLLOWING_MANAGEMENT,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      "CANCEL"
    );

    changeFollowStatusInList(item, false);
    const res = await unfollow(item.memberUuid);
    FollowerStore.handlePostFollowUpdate(
      UserActTypeCode.UN_FOLLOW,
      item.memberUuid
    );
    if (!res) changeFollowStatusInList(item, true);
    else onToast(false, item);
  };

  const toUserHome = (item: any) => {
    goTo(`/user/${item.nickname}`, {
      state: {
        targetUserId: item.memberUuid,
        nickname: item.nickname,
        profileUrl: item.profileUrl,
        profileBackgroundColor: item.profileBackgroundColor,
      },
    });
  };

  const followerUser = (item: any) => {
    return (
      <div className="item-wrapper">
        <div className="item-left-wrapper" onClick={() => toUserHome(item)}>
          <FastImageWithFallback
            aria-label="Profile image"
            className="profile-image"
            style={{ display: "flex" }}
            source={{
              uri: `${PUBLIC_BUCKET_URL}${item.profileUrl || ""}`,
            }}
            fallbackImageUri={FALLBACK_IMAGE_URL}
          />
          <div className="info-user-wrapper">
            <div className="info-user-top">
              <KeywordText
                containerStyle={{ display: "flex" }}
                matchTextStyle={{ color: "#be69c3" }}
                sentence={item.nickname}
                keyword={keyword}
                aria-label="Nickname"
              />
              {item.myMemberFollowing === "N" && (
                <TouchableWithAsyncTask
                  className="sub-follow-btn"
                  onPress={async () => await followUser(item)}
                >
                  <p aria-label="Follow">
                    {t("screen.followers.button.follow")}
                  </p>
                </TouchableWithAsyncTask>
              )}
            </div>

            <div className="info-user-bottom">
              <p className="tags-text" aria-label="Tags">
                {item.interestedTags}
              </p>
            </div>
          </div>
        </div>
        <button
          className="item-right-wrapper un-follow-btn"
          onClick={() => {
            inputRef.current?.blur();
            onClickDelete(item);
          }}
        >
          <p aria-label="Delete">{t("screen.followers.button.delete")}</p>
        </button>
      </div>
    );
  };

  const followingUser = (item: any) => {
    const myAccount = item.memberUuid === AuthStore.sessionUser?.memberUuid;
    return (
      <div className="item-wrapper">
        <div className="item-left-wrapper" onClick={() => toUserHome(item)}>
          <FastImageWithFallback
            aria-label="Profile image"
            className="profile-image"
            style={{ display: "flex" }}
            source={{
              uri: `${PUBLIC_BUCKET_URL}${item.profileUrl || ""}`,
            }}
            fallbackImageUri={FALLBACK_IMAGE_URL}
          />
          <div className="info-user-wrapper">
            <div className="info-user-top">
              <KeywordText
                containerStyle={{ display: "flex" }}
                matchTextStyle={{ color: "#be69c3" }}
                sentence={"@" + item.nickname}
                keyword={keyword}
                aria-label="Nickname"
              />
            </div>
            <div className="info-user-bottom">
              <p className="tags-text" aria-label="Tags">
                {item.interestedTags}
              </p>
            </div>
          </div>
        </div>
        {!myAccount &&
          (item.myMemberFollowing === "Y" ? (
            <TouchableWithAsyncTask
              className="item-right-wrapper un-follow-btn"
              onPress={async () => {
                await unFollowUser(item);
              }}
            >
              <p aria-label="Following">
                {t("screen.followers.button.follwing")}
              </p>
            </TouchableWithAsyncTask>
          ) : (
            <TouchableWithAsyncTask
              className="item-right-wrapper follow-btn"
              onPress={async () => {
                await followUser(item);
              }}
            >
              <p aria-label="Follow">{t("screen.followers.button.follow")}</p>
            </TouchableWithAsyncTask>
          ))}
      </div>
    );
  };

  const changeFollowStatusInList = (item: any, isFollowed: boolean) => {
    const tempItem = item;
    tempItem.myMemberFollowing = isFollowed ? "Y" : "N";

    setFollowerData((prevData) => {
      const updatedData = [...prevData];
      if (item.index >= 0 && item.index < updatedData.length) {
        updatedData[item.index] = tempItem;
      }
      return updatedData;
    });
  };

  const filteringData = (text: string) => {
    setKeyword(text);
    setSearchStart(0);
    void getFollowers(0, text);
  };

  const getFollowers = async (startIndex: number, nickname?: string) => {
    setSearching(true);
    setLoading(true);
    if (startIndex == 0) {
      setFollowerData([]);
    }
    setNoSearchData(nickname !== undefined && nickname.length > 0);

    const params: FollowersRequest = {
      searchMemberUuid: userId,
      myMemberUuid: AuthStore.sessionUser?.memberUuid || "",
      type: FollowersTab.FOLLOWERS,
      pageSize: FOLLOWER_LIST_COUNT,
      pageIndex: startIndex,
      nickname,
    };
    const res = await getFollowerList(params);
    if (startIndex > 0)
      setFollowerData((prevArray) => [...new Set([...prevArray, ...res.list])]);
    else setFollowerData(res.list);

    setOriginFollowerData(res);
    setSearching(false);
    setLoading(false);
  };

  useEffect(() => {
    if (deletedUser !== null && deletedUser !== undefined) {
      setFollowerData((prevData) => {
        return prevData.filter(
          (follower) => follower.memberUuid !== deletedUser?.memberUuid
        );
      });
    }
  }, [deletedUser]);

  // 스크롤시 닉네임 검색 헤더 처리
  // const translation = useRef(new Animated.Value(0)).current;
  // const maxHeight = translation.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: [-100, 0], // <-- value that larger than your content's height
  // });

  // useEffect(() => {
  //   Animated.timing(translation, {
  //     toValue: showNickNameSearch ? 1 : 0,
  //     duration: 150,
  //     useNativeDriver: false,
  //   }).start();
  // }, [showNickNameSearch, translation]);

  // const shared = useSharedValue(0);
  // const isScrolling = useSharedValue(false);

  return (
    <div className="follower-list-wrapper">
      <div
        className="followerList"
        aria-label="Follower list"
        style={{ height: "100%" }}
      >
        <NickNameSearch keyword={keyword} filteringData={filteringData} />
        {followerData.length > 0 ? (
          followerData.map((item: any, index: number) =>
            isMy ? followerUser(item) : followingUser(item)
          )
        ) : (
          <EmptyFollower
            type={FollowersTab.FOLLOWERS}
            isSearchEmpty={noSearchData}
            onSearching={onSearching}
          />
        )}
        <div style={{ height: 60, backgroundColor: "transparent" }} />
      </div>
      {/* <Virtuoso
          className="followerList"
          aria-label="Follower list"
          data={followerData}
          itemContent={(index, item) => {
            return isMy ? followerUser(item) : followingUser(item);
          }}
          style={{ height: "100%" }}
          useWindowScroll
          increaseViewportBy={window.innerHeight}
          endReached={() => {
            const start = searchStart + 1;
            if (originFollowerData?.totalCount > start * FOLLOWER_LIST_COUNT) {
              setSearchStart(start);
              void getFollowers(start, keyword);
            }
          }}
          components={{
            Header: () => (
              <NickNameSearch keyword={keyword} filteringData={filteringData} />
            ),
            EmptyPlaceholder: () => (
              <EmptyFollower
                type={FollowersTab.FOLLOWERS}
                isSearchEmpty={noSearchData}
                onSearching={onSearching}
              />
            ),
            ScrollSeekPlaceholder: () => (
              <CustomActivityIndicator isDarkSpinner={true} />
            ),
            Footer: () => (
              <div style={{ height: 60, backgroundColor: "transparent" }} />
            ),
          }}
        /> */}
    </div>
  );
};

export default Followers;
