import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react";
import TitleHeader from "../../../components/common/TitleHeader";
import { HeaderItem } from "../../../constants/Header";
import { ReactComponent as HeartOffIcon } from "../../../assets/icons/heart_off.svg";
import { ReactComponent as HeartOnIcon } from "../../../assets/icons/heart_on.svg";
import { ReactComponent as ChatIcon } from "../../../assets/icons/chat.svg";
import { ReactComponent as BookmarkOffIcon } from "../../../assets/icons/bookmark_off.svg";
import { ReactComponent as BookmarkOnIcon } from "../../../assets/icons/bookmark_on.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import PostUserInfo from "./PostUserInfo";
import PostContent from "./PostContent";
import PostDetailStore from "../../../store/PostDetailStore";
import BookmarkStore from "../../../store/BookmarkStore";
import LikeStore from "../../../store/LikeStore";
import { CommentTypeCode } from "../../../constants/Comment";
import AuthStore from "../../../store/AuthStore";
import FeedStore from "../../../store/FeedStore";
import { useTranslation } from "react-i18next";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import MainStore from "../../../store/MainStore";
import { UserActTypeCode } from "../../../constants/FeedType.enum";
// import PostModifyStore from "../../../store/PostModifyStore";
import { NotificationModal } from "../../../components/modals";
// import { onShare, onShareWithBranch, ShareInfo } from "../../../utils/share";
import ReportStore from "../../../store/ReportStore";
// import { ReportTargetTypeCode } from "../../../constants/Report";
import { LikeTypeCode } from "../../../constants/Like";
import { ReactComponent as NothingSearchGridImage } from "../../../assets/images/nodata_search_grid_bg.svg";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { ActHistoryRequest } from "../../../api/data/model";
import { sendActHistory } from "../../../api/data/api";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
// import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
import { handleTopButtonScroll } from "../../../utils/common";
import useFeedScreen from "../../feed/hook/UseFeedScreen";
import { FeedTypeCode } from "../../../constants/Feed";
// import { goToLifecareProductPage } from "../../../utils/mall";
import { Virtuoso } from "react-virtuoso";
import CustomActivityIndicator from "../../../components/common/CustomActivityIndicator";
import PostTag from "./PostTag";
import "./PostDetail.scss";
import JoinOverlapComponent from "../../../components/common/JoinOverlapComponent";
import { useTracker } from "../../../hooks/tracker";
import CommonStore from "../../../store/CommonStore";

import {
  canGoBack,
  goBack,
  goTo,
} from "../../../hooks/navigate/NavigateFunction";
import { getSessionUserFromStorage } from "../../../service/Session.service";
import { ShareInfo, onShare } from "../../../utils/share";
import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
import { ReportTargetTypeCode } from "../../../constants/Report";
import { useWindowScroll } from "react-use";

const PostDetail = observer(
  ({
    isLoading,
    onLike,
    onBookmark,
    lifecareProductId,
    onDelete,
  }: {
    isLoading: boolean;
    onLike?: (like: string) => void;
    onBookmark?: (bookmark: string) => void;
    lifecareProductId?: number;
    onDelete?: (feedId: number) => void;
  }) => {
    const [selfWidth, setSelfWidth] = useState(window.innerWidth);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const isAuthenticated = useRef<boolean>(false);
    const { t } = useTranslation();

    const {
      postContent,
      postDetailResult,
      bookmarkYn,
      likeYn,
      likeCount,
      setBookmarkYn,
      setLikeYn,
      increaseLikeCount,
      decreaseLikeCount,
      postIndex,
      listIndex,
      popPostFromMainAndFeed,
      loading,
      hasError,
    } = PostDetailStore;
    const { contents } = postContent;
    const { openReportBottomSheet } = ReportStore;
    const { createMemberUuid, feedId } = postDetailResult;
    const { saveBookmark, cancelBookmark } = BookmarkStore;
    const { saveLike, cancelLike } = LikeStore;
    const { sessionUser } = AuthStore;
    const [toastMessage, setToast] = useState("");
    const { updateFeedList, popPostFromFeed } = FeedStore;
    const { updateMainList, popPostFromMain } = MainStore;
    const { handlePostUpdate } = useFeedScreen("ETC");

    const { track } = useTracker();
    // const config = useRemoteConfig();

    const { y } = useWindowScroll();

    useEffect(() => {
      setTimeout(() => {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
          actPageCode: ActPageCode.POST_DETAIL,
          actSourcePageCode: CommonStore.fixedPreviousRouteName,
          attr1: (y === 0).toString(),
        };
        void sendActHistory(actHistory);
      }, 1000);
    }, []);

    useEffect(() => {
      getSessionUserFromStorage().then((res) => {
        isAuthenticated.current = res ? true : false;
      });
    }, [isAuthenticated]);

    useEffect(() => {
      function handleResize() {
        setSelfWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    //PPIT210117-4818 취소 차리 시 attr6에 CANCEL 반영
    const callActHistoryByHistoryType = (
      historyType: string,
      attr6?: string
    ) => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: historyType,
        actHistoryTargetId: feedId.toString(),
        actPageCode: ActPageCode.POST_DETAIL,
        actSectionCode: undefined,
        attr1: (listIndex || postIndex).toString(),
        attr2: postDetailResult.likeCount.toString(),
        attr3: postDetailResult.commentCount.toString(),
      };
      if (attr6) {
        actHistory.attr6 = attr6;
      }
      void sendActHistory(actHistory);
    };

    const onLikeButtonPress = async () => {
      if (likeYn === "Y") {
        await cancelLike(
          feedId,
          "POST",
          () => {
            setLikeYn("N");
            decreaseLikeCount();

            if (onLike) {
              onLike("N");
            }

            //PPIT210117-4818 LIKE 취소 시 actHistory 처리 추가
            callActHistoryByHistoryType(ActHistoryTypeCode.LIKE, "CANCEL");
            listIndex
              ? void updateMainList(
                  UserActTypeCode.CANCEL_LIKE,
                  postIndex,
                  listIndex
                )
              : void updateFeedList(UserActTypeCode.CANCEL_LIKE, postIndex);
          },
          () => {
            // fail
          }
        );
      } else {
        await saveLike(
          feedId,
          "POST",
          () => {
            setLikeYn("Y");
            increaseLikeCount();

            if (onLike) {
              onLike("Y");
            }
            callActHistoryByHistoryType(ActHistoryTypeCode.LIKE);
            track("click_like_button", {
              content_id: feedId,
              content_title: postDetailResult.feedTitle,
              content_type: FeedTypeCode.POST,
              like_count: postDetailResult.likeCount
                ? postDetailResult.likeCount
                : 0,
              comment_count: postDetailResult.commentCount
                ? postDetailResult.commentCount
                : 0,
            });
            listIndex
              ? void updateMainList(
                  UserActTypeCode.SAVE_LIKE,
                  postIndex,
                  listIndex
                )
              : void updateFeedList(UserActTypeCode.SAVE_LIKE, postIndex);
          },
          () => {
            // fail
          }
        );
      }
    };

    const onBookmarkButtonPress = async () => {
      if (bookmarkYn == "Y") {
        await cancelBookmark(
          feedId,
          "POST",
          () => {
            setBookmarkYn("N");

            if (onBookmark) {
              onBookmark("N");
            }
            //PPIT210117-4818 BOOKMARK 취소 시 actHistory 처리 추가
            callActHistoryByHistoryType(ActHistoryTypeCode.BOOKMARK, "CANCEL");
            listIndex
              ? void updateMainList(
                  UserActTypeCode.CANCEL_BOOKMARK,
                  postIndex,
                  listIndex
                )
              : void updateFeedList(UserActTypeCode.CANCEL_BOOKMARK, postIndex);
          },
          () => {
            // fail
          }
        );
      } else {
        await saveBookmark(
          feedId,
          "POST",
          () => {
            setBookmarkYn("Y");

            if (onBookmark) {
              onBookmark("Y");
            }
            callActHistoryByHistoryType(ActHistoryTypeCode.BOOKMARK);
            track("click_bookmark", {
              content_id: feedId,
              content_title: postDetailResult.feedTitle,
              content_type: FeedTypeCode.POST,
              like_count: postDetailResult.likeCount
                ? postDetailResult.likeCount
                : 0,
              comment_count: postDetailResult.commentCount
                ? postDetailResult.commentCount
                : 0,
            });
            listIndex
              ? void updateMainList(
                  UserActTypeCode.SAVE_BOOKMARK,
                  postIndex,
                  listIndex
                )
              : void updateFeedList(UserActTypeCode.SAVE_BOOKMARK, postIndex);
          },
          () => {
            // fail
          }
        );
      }
    };

    const onCommentButtonPress = () => {
      goTo(`/${CommentTypeCode.POST}/${feedId}/comment`, {
        state: {
          feedId: feedId ?? 0,
          feedType: CommentTypeCode.POST,
          feedAuthorId: createMemberUuid,
        },
      });
      // push("CommentScreen", {
      //   feedId: feedId,
      //   feedType: CommentTypeCode.POST,
      //   feedAuthorId: feedAuthorId,
      //   postIndex: postIndex,
      //   listIndex: listIndex,
      // });
    };

    const onLikeListButtonPress = () => {
      goTo("/LikeScreen", {
        state: {
          feedId: feedId ?? 0,
          feedType: LikeTypeCode.POST,
        },
      });
    };

    // TODO : SET TOAST MESSAGE
    // useEffect(() => {
    //   if (toastMessage !== undefined && toastMessage?.trim().length > 0) {
    //     Toast.show({
    //       type: "tomatoToast",
    //       autoHide: true,
    //       text1: toastMessage + " ",
    //       onHide: () => setToast(""),
    //     });
    //   }
    // }, [toastMessage]);

    const PostComponent = useCallback(
      (item: any, index: number, visible: boolean, authenticated: boolean) => {
        return (
          <div>
            <div className="post-body">
              {index === 0 && (
                <div className="title-text" aria-label={`Title${postIndex}`}>
                  {postDetailResult?.feedTitle}
                </div>
              )}
              {index === 0 || authenticated ? (
                <PostContent
                  item={item}
                  index={index}
                  products={postContent.products}
                  advantage={postContent.advantage}
                  disadvantage={postContent.disadvantage}
                  rating={postContent.rating}
                  selfWidth={selfWidth}
                  autoplay={true}
                  visible={visible}
                />
              ) : (
                <JoinOverlapComponent>
                  <PostContent
                    item={item}
                    index={index}
                    products={postContent.products}
                    advantage={postContent.advantage}
                    disadvantage={postContent.disadvantage}
                    rating={postContent.rating}
                    selfWidth={selfWidth}
                    autoplay={true}
                    visible={visible}
                  />
                </JoinOverlapComponent>
              )}

              {index === postContent.contents.length - 1 &&
                isAuthenticated.current && <PostTag feedId={feedId} />}
            </div>
          </div>
        );
      },
      [
        feedId,
        selfWidth,
        postDetailResult,
        postContent,
        postIndex,
        isAuthenticated,
      ]
    );

    const ErrorComponent = useCallback(() => {
      return (
        <div className="nothing_search_img">
          <NothingSearchGridImage />
          <div className="empty_wrap">
            <div className="empty_txt">
              {t("screen.post.message.NoPostdata")}
            </div>
          </div>
        </div>
      );
    }, [t]);

    const HeaderComponent = useMemo(() => {
      return (
        <div className="post-detail-header">
          <TitleHeader
            isHome
            isBack={true}
            title={t("screen.post.title.postDetail")}
            rightItem={HeaderItem.MORE}
            needAuth
            moreOptionMenuList={
              createMemberUuid === sessionUser?.memberUuid
                ? ["DELETE", "MODIFY"]
                : ["REPORT"]
            }
            onClickMore={(type) => {
              switch (type) {
                case "DELETE":
                  CommonStore.setShowDownloadAppDrive(true);
                  // setDeletePopup(true);
                  break;
                case "MODIFY":
                  // goTo("/post/modify", {
                  //   state: { feedId: postDetailResult.feedId },
                  // });
                  CommonStore.setShowDownloadAppDrive(true);
                  break;
                case "REPORT":
                  openReportBottomSheet(
                    {
                      reportTargetId: feedId.toString(),
                      reportTargetTypeCode: ReportTargetTypeCode.POST,
                    },
                    () => {
                      void popPostFromMainAndFeed(feedId);
                      handlePostUpdate(
                        postDetailResult.feedId,
                        UserActTypeCode.REPORT
                      );
                      if (canGoBack()) {
                        goBack();
                      }
                    }
                  );
                  break;
                default:
                  break;
              }
            }}
            onClickBack={() => {
              goBack();
            }}
          />
        </div>
      );
    }, [
      postDetailResult,
      sessionUser,
      goBack,
      popPostFromMainAndFeed,
      handlePostUpdate,
    ]);

    const FooterComponent = () => {
      return lifecareProductId ? (
        <TouchableWithAuthCheck
          className="footer_btn"
          beforeOnPress={() => CommonStore.setShowDownloadAppDrive(true)}
          // onPress={() => goToLifecareProductPage(lifecareProductId)}
        >
          <div className="footer_btn_text">
            {t("screen.mall.button.buying")}
          </div>
        </TouchableWithAuthCheck>
      ) : (
        <div className="action_wrap">
          <div className="action_wrap_padding">
            <div className="action_left">
              <TouchableWithAuthCheck
                onPress={() => onLikeListButtonPress()}
                aria-label="Like count"
              >
                <div className="like_text">
                  {t(`screen.post.label.like`)}{" "}
                  <div className="number_text">{likeCount}</div>
                </div>
              </TouchableWithAuthCheck>
              <div
                onClick={() => onCommentButtonPress()}
                aria-label="Comment count"
              >
                <div className="action_text">
                  {t(`screen.post.label.comment`)}{" "}
                  <div className="number_text">
                    {postDetailResult.commentCount}
                  </div>
                </div>
              </div>
            </div>
            <div className="action_right">
              <TouchableWithAsyncTask
                aria-label="Like button"
                className="action_icon_btn"
                onPress={async () => {
                  await onLikeButtonPress();
                }}
                authCheckProps={{ enabled: true }}
              >
                {likeYn == "Y" ? <HeartOnIcon /> : <HeartOffIcon />}
              </TouchableWithAsyncTask>
              <div
                aria-label="Comment button"
                className="action_icon_btn"
                onClick={() => {
                  onCommentButtonPress();
                }}
              >
                <ChatIcon />
              </div>
              <TouchableWithAsyncTask
                aria-label="Bookmark button"
                className="action_icon_btn"
                onPress={async () => {
                  await onBookmarkButtonPress();
                }}
                authCheckProps={{ enabled: true }}
              >
                {bookmarkYn == "Y" ? <BookmarkOnIcon /> : <BookmarkOffIcon />}
              </TouchableWithAsyncTask>
              <button
                aria-label="Share button"
                onClick={() => {
                  const actHistoryData = {
                    actHistoryTypeCode: ActHistoryTypeCode.POST_SHARE,
                    actPageCode: ActPageCode.POST_DETAIL,
                    feedId: (postDetailResult.feedId ?? 0).toString(),
                    likeCount: (postDetailResult.likeCount ?? 0).toString(),
                    commentCount: (
                      postDetailResult.commentCount ?? 0
                    ).toString(),
                  };
                  const shareInfo: ShareInfo = {
                    title: postDetailResult?.feedTitle,
                    descriptionText: postDetailResult?.contents[0].contents,
                    imageUrl:
                      postDetailResult?.contents[0]?.files[0].thumbnailFilePath,
                    screen: ShareScreenCode.POST_DETAIL_SCREEN,
                    targetId: postDetailResult?.feedId,
                    path: `posts/feed/${postDetailResult?.feedId}`,
                  };
                  void onShare(shareInfo, actHistoryData);

                  track("click_share_button", {
                    content_id: postDetailResult.feedId.toString(),
                    content_title: postDetailResult.feedTitle,
                    content_type: FeedTypeCode.POST,
                    like_count: postDetailResult.likeCount || 0,
                    comment_count: postDetailResult.commentCount || 0,
                  });
                }}
              >
                <ShareIcon />
              </button>
            </div>
          </div>
        </div>
      );
    };

    return (
      <>
        <div className="post-detail-screen" aria-label="Post detail">
          {HeaderComponent}
          {!isLoading &&
            !loading &&
            (hasError ? (
              <ErrorComponent />
            ) : (
              <>
                <div
                  className="post-detail-screen"
                  style={{
                    flex: 1,
                    pointerEvents: isAuthenticated.current ? "auto" : "none",
                  }}
                >
                  <PostUserInfo />
                  <Virtuoso
                    aria-label={`Post detail list ${feedId}`}
                    computeItemKey={(index) => {
                      return `post-content-${index}`;
                    }}
                    data={
                      isAuthenticated.current
                        ? contents.slice()
                        : contents.slice(0, 2)
                    }
                    itemContent={(index: number, item: any) => {
                      return PostComponent(
                        item,
                        index,
                        true,
                        isAuthenticated.current
                      );
                    }}
                    useWindowScroll
                  />
                </div>
              </>
            ))}

          {!isLoading && !loading && !hasError && isAuthenticated.current && (
            <FooterComponent />
          )}
        </div>
        {isLoading && <CustomActivityIndicator />}

        {showDeletePopup && (
          <NotificationModal
            isVisible={showDeletePopup}
            contents1={t("screen.notificationModal.message.deleteMessage")}
            useTwoButton={true}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.OK")}
            extraButtonText={t("screen.notificationModal.button.cancel")}
            onClickDefaultButton={() => {
              // setDeletePopup(false);
              // void PostModifyStore.deletePost(postDetailResult.feedId);
              // handlePostUpdate(postDetailResult.feedId, UserActTypeCode.DELETE);
              // listIndex && listIndex >= 0
              //   ? void popPostFromMain(postIndex, listIndex)
              //   : void popPostFromFeed(postIndex);
              // if (onDelete) {
              //   onDelete(feedId);
              // }
            }}
            onClickExtraButton={() => setDeletePopup(false)}
            onRequestClose={() => setDeletePopup(false)}
          />
        )}
      </>
    );
  }
);

export default PostDetail;
