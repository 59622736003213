import { useTranslation } from "react-i18next";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search1.svg";
import React, { useEffect, useRef } from "react";
import "./NickNameSearch.scss";

const NickNameSearch = ({
  keyword,
  filteringData,
}: {
  keyword: string;
  filteringData: (text: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return (
    <div
      className="nickname-search-wrapper"
      onClick={() => inputRef.current?.focus()}
    >
      <input
        className="nickname-search-input"
        aria-label="Input Nickname"
        ref={inputRef}
        placeholder={t("screen.followers.message.placeHolder")}
        // placeholderTextColor={"#999"}
        value={keyword}
        onKeyDown={(event) => {
          if (event.key === "Enter" && event.nativeEvent.isComposing) {
            void filteringData(keyword);
          }
        }}
        onChange={(event) => {
          void filteringData(event.target.value);
        }}
      />
      <div className="search-icon" onClick={() => filteringData(keyword)}>
        <SearchIcon />
      </div>
    </div>
  );
};

export default NickNameSearch;
