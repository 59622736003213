import TermsAgree from "../../components/common/TermsAgree";
import { TitleHeader } from "../../components/common";
import { useTranslation } from "react-i18next";
import TermsStore from "../../store/TermsStore";
import SocialMemberStore, {
  AddSocialMemberStoreRequest,
} from "../../store/SocialMemberStore";
import { AllowTypeCode } from "../../constants/CommonCode.enum";
import { observer } from "mobx-react";
import { SocialTypeCode } from "../../constants/Common.enum";
import { deHyphenatePhoneNumber } from "../../utils/phoneNumber";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel.svg";
import { ReactComponent as PassIcon } from "../../assets/icons/pass.svg";
import PhoneAuthStore from "../../store/PhoneAuthStore";
import PhoneAuthSection from "../../components/common/PhoneAuthSection";
import { ReactComponent as TriangleRightBlackIcon } from "../../assets/icons/triangle_right_black.svg";
import { ReactComponent as TriangleRightRedIcon } from "../../assets/icons/triangle_right_red.svg";
import { ReactComponent as TriangleRightGreen } from "../../assets/icons/triangle_right_green.svg";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import { Platform, PlatformTypes } from "../../native/platform";
import { useLocation } from "react-router-dom";
import classes from "./InfoAddAgree.module.scss";
import clsx from "clsx";
import { useRef } from "react";
export interface InfoAddAgreeInterface {
  email: string;
  accessToken: string;
  nonce?: string;
  socialTypeCode: SocialTypeCode;
}

const InfoAddAgree = observer(() => {
  const headerRef = useRef<HTMLDivElement>(null);
  const {
    phoneNumber,
    isPhoneNumberValid,
    isPhoneAuthSectionShow,
    isPhoneNumberSectionComplete,
    resetPhoneAuthSection,
    sendAuthPhone,
    handleChangePhoneNumber,
    resetAuthLogic,
  } = PhoneAuthStore;

  const { email, accessToken, nonce, socialTypeCode }: InfoAddAgreeInterface =
    useLocation().state || {};

  const isPhoneNumberRequired =
    socialTypeCode === SocialTypeCode.APPLE ||
    socialTypeCode === SocialTypeCode.GOOGLE ||
    socialTypeCode === SocialTypeCode.KAKAO;
  const { termsAgree, isRequiredTermsAgree } = TermsStore;
  const { addSocialMember } = SocialMemberStore;

  const { t } = useTranslation();

  useEffectOnlyOnce(() => {
    resetPhoneAuthSection();
    resetAuthLogic();
  });

  const handleJoinBtnClick = async () => {
    const terms = termsAgree.map(({ termsId, termsAgreeYn }) => ({
      termsId,
      termsAgreeYn: termsAgreeYn ? AllowTypeCode.Y : AllowTypeCode.N,
    }));

    const addSocialMemberStoreRequest: AddSocialMemberStoreRequest = {
      socialTypeCode,
      accessToken,
      nonce,
      phoneNumber: deHyphenatePhoneNumber(phoneNumber),
      phoneConfirmFlag: isPhoneNumberSectionComplete,
      terms,
    };

    await addSocialMember(addSocialMemberStoreRequest);
  };

  const isAllRequiredComplete =
    isRequiredTermsAgree &&
    (!isPhoneNumberRequired || isPhoneNumberSectionComplete);

  const renderEnterPhoneNumberGuide = () => {
    if (phoneNumber) {
      return <></>;
    } else {
      return (
        <div className={classes.alert} aria-label={"phone number guide"}>
          <TriangleRightBlackIcon />
          <span className={classes.phone_alert_text}>
            {t("screen.join.joinPhone.enterPhoneNumberGuideOne")}
            <span className={classes.bold_text}>
              {t("screen.join.joinPhone.enterPhoneNumberGuideTwo")}
            </span>
            {t("screen.join.joinPhone.enterPhoneNumberGuideThree")}
          </span>
        </div>
      );
    }
  };

  const renderEnterValidPhoneNumberGuide = () => {
    if (phoneNumber && !isPhoneNumberValid) {
      return (
        <div className={classes.alert} aria-label={"invalid phone number"}>
          <TriangleRightRedIcon />
          <span className={classes.phone_not_valid}>
            {t("screen.join.joinPhone.invalidPhoneNumberGuide")}
          </span>
        </div>
      );
    } else if (isPhoneNumberValid) {
      return (
        <div className={classes.alert} aria-label={"invalid phone number"}>
          <TriangleRightGreen />
          <span className={classes.phone_success_valid}>
            {t("screen.join.joinPhone.successPhoneNumberGuide")}
          </span>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <div className={classes.join_email} aria-label={"join with email"}>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div className={classes.header} aria-label={"join with email header"}>
            <TitleHeader
              wrapperRef={headerRef}
              title={t("screen.join.infoAddAgree.header")}
              isBack={true}
            />
          </div>

          <div className={classes.container} style={{ paddingTop: headerRef.current?.clientHeight || 54 }}>
            <div className={classes.contents_box}>
              <div
                className={classes.step_list}
                aria-label={"join with email contents"}
              >
                <div
                  className={classes.step_header}
                  aria-label={"email section header"}
                >
                  <div className={classes.step_header_btn}>
                    <span className={classes.step_header_active_text}>
                      {email}
                    </span>
                    <PassIcon />
                  </div>
                </div>

                {isPhoneNumberRequired && (
                  <>
                    <div
                      className={classes.step_header}
                      aria-label={"phone number section header"}
                    >
                      <button className={clsx(classes.step_header_btn)}>
                        <span className={clsx(classes.step_header_active_text)}>
                          {t("screen.join.joinPhone.header")}
                        </span>
                      </button>
                    </div>
                    <div className={classes.step_body}>
                      <div
                        className={clsx(
                          classes.phone_input_wrap,
                          classes.phone_input_wrap_focus
                        )}
                      >
                        <input
                          className={classes.phone_input}
                          autoFocus={true}
                          placeholder={t(
                            "screen.join.joinPhone.phoneNumberInputPlaceholder"
                          )}
                          // keyboardType={"numeric"}
                          value={phoneNumber}
                          onChange={(x) =>
                            handleChangePhoneNumber(x.target.value)
                          }
                          aria-label={"phone number input"}
                          maxLength={13}
                        />
                        {!!phoneNumber && (
                          <button
                            className={classes.cancel_btn}
                            onClick={resetPhoneAuthSection}
                          >
                            <CancelIcon />
                          </button>
                        )}

                        <button
                          className={clsx(
                            classes.auth_btn,
                            isPhoneNumberValid &&
                              !isPhoneAuthSectionShow &&
                              !isPhoneNumberSectionComplete &&
                              classes.auth_btn_active
                          )}
                          onClick={() => {
                            const authType: "phone" | "sms" =
                              Platform.OS === PlatformTypes.IOS_APP
                                ? "sms"
                                : "phone";
                            void sendAuthPhone(authType);
                          }}
                          disabled={
                            !isPhoneNumberValid ||
                            isPhoneAuthSectionShow ||
                            isPhoneNumberSectionComplete
                          }
                          aria-label={"send authentication phone"}
                        >
                          <span
                            className={clsx(
                              classes.auth_btn_text,
                              isPhoneNumberValid &&
                                !isPhoneAuthSectionShow &&
                                !isPhoneNumberSectionComplete &&
                                classes.auth_btn_text_active
                            )}
                          >
                            {t(
                              "screen.join.joinPhone.authenticateButtonPlaceholderFirst"
                            )}
                          </span>
                        </button>
                      </div>
                      <div className={classes.alert_wrap}>
                        {renderEnterPhoneNumberGuide()}
                        {renderEnterValidPhoneNumberGuide()}
                      </div>
                      {isPhoneAuthSectionShow && <PhoneAuthSection />}
                    </div>
                  </>
                )}

                <div
                  className={clsx(
                    classes.step_header,
                    classes.term_agree_header
                  )}
                  aria-label={"terms agree section header"}
                >
                  <div className={classes.step_header_btn}>
                    <span
                      className={clsx(
                        classes.step_header_text,
                        classes.step_header_active_text
                      )}
                    >
                      {t("screen.join.joinTermsAgree.header")}
                    </span>
                  </div>
                </div>
                <div className={classes.step_body_term}>
                  <TermsAgree isMarginTop />
                </div>
              </div>
            </div>
            <div className={classes.space_bottom} />
            <div className={classes.footer}>
              <button
                disabled={!isAllRequiredComplete}
                className={clsx(
                  classes.btn_1,
                  isAllRequiredComplete ? classes.active : classes.in_active
                )}
                onClick={() => {
                  void handleJoinBtnClick();
                }}
                aria-label={"Socail Member Sign In Button"}
              >
                <span
                  className={clsx(
                    classes.btn_1_txt,
                    isAllRequiredComplete
                      ? classes.txt_active
                      : classes.txt_in_active
                  )}
                >
                  {t("screen.join.footer")}
                </span>
                {/* <div style={{ height: bottomInset }} /> */}
                <div />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default InfoAddAgree;
