import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/close_purple_background.svg";
import { observer } from "mobx-react";
import PostWriteStore from "../../../store/PostWriteStore";
import { TagListItem } from "../../../api/tag/model";
import KeywordText from "../../../components/common/KeywordText";
import "./PostWriteTag.scss";
import clsx from "clsx";

const PostWriteTag = observer(() => {
  const { t } = useTranslation();
  const {
    tagSearchResult,
    isVisibleTagList,
    tempTagList,
    recommendTags,
    checkValidWord,
    searchTag,
    addTag,
    deleteTag,
    setVisibleTagList,
    setTagSearchResult,
  } = PostWriteStore;
  const { missionData } = PostWriteStore;
  const [keyword, setKeyword] = useState("");
  const tagDivRaf = useRef<any>(null);
  const tagRaf = useRef<any>(null);
  const [searchStart, setSearchStart] = useState(0);
  const RECOMMEND_TAG_LENGTH = 5;

  const onPressTag = (name: string) => {
    addTag(name.toLowerCase());
    setKeyword("");
    setTagSearchResult({ totalCount: 0, list: [] }, 0);
  };

  const onSubmitAction = () => {
    onPressTag(keyword);
  };

  const renderTagList = (item: TagListItem) => {
    return (
      <>
        <div className="auto_complete_item">
          <button
            onClick={() => {
              onPressTag(item.tagName);
            }}
            aria-label="Add tag has keyword"
          >
            <KeywordText
              sentence={`#${item.tagName}`}
              keyword={keyword}
              aria-label="Tag name"
              matchTextStyle={{
                fontSize: 13,
                letterSpacing: -0.52,
                color: "#BE69C3",
              }}
            />
          </button>
        </div>
      </>
    );
  };

  const handleChangeText = (text: string) => {
    text = text.replace(/#/g, "");
    const trimText = text.trim();
    let tempStart = 0;
    if (trimText !== keyword) {
      setKeyword(trimText);
    } else {
      tempStart = searchStart + 1;
    }
    setSearchStart(tempStart);
    void searchTag(trimText, tempStart);
  };

  return (
    <div id="post_write_tag" ref={tagDivRaf}>
      <div className="bar" />

      <div className="tag_wrap">
        <div className="tag_input_wrap">
          <div className="tag_input_inner">
            <span className="tag_title_text_2">
              {t("screen.post.label.tag")}
            </span>
            <input
              aria-label="Input tag"
              ref={tagRaf}
              className="tag_input"
              placeholder={t("screen.post.label.tagPlaceHolder")}
              onChange={(e) => {
                setVisibleTagList(true);
                handleChangeText(e.target.value);
              }}
              value={keyword}
              onFocus={() => {
                setVisibleTagList(true);

                tagDivRaf?.current?.scrollIntoView({
                  behavior: "smooth",
                });
              }}
              onBlur={() => {}}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  void checkValidWord(
                    keyword,
                    tagRaf.current,
                    "tag",
                    onSubmitAction
                  );
                }
              }}
            />
          </div>
        </div>
        {isVisibleTagList &&
          tagSearchResult !== null &&
          tagSearchResult !== undefined &&
          tagSearchResult.list.length > 0 && (
            <div className="auto_complete_list">
              {tagSearchResult.list.map((item) => {
                return (
                  <div aria-label="Post Tag list" className="post-tag-list">
                    {renderTagList(item)}
                  </div>
                );
              })}
            </div>
          )}

        <div
          className={clsx(
            "tag_list_wrap",
            tempTagList?.length < 1 ? "no_tag_list_wrap" : ""
          )}
        >
          {tempTagList?.map((item, index) =>
            item === missionData?.missionPicture?.missionTagName ? (
              <div className="tag_item" key={index} aria-label="Delete tag">
                <span className="tag_item_text" aria-label={`Taged${index}`}>
                  #{item}
                </span>
              </div>
            ) : (
              <button
                className="tag_item"
                key={index}
                onClick={() => deleteTag(item)}
                aria-label="Delete tag"
              >
                <span className="tag_item_text" aria-label={`Taged${index}`}>
                  #{item}
                </span>
                <DeleteIcon />
              </button>
            )
          )}
        </div>

        <div className="recommend_tag_wrap">
          <div className="recommend_tag_list_wrap">
            {recommendTags.map((recommend, index) => {
              if (index < RECOMMEND_TAG_LENGTH) {
                return (
                  <button
                    key={index}
                    className="recommend_tag_item"
                    aria-label={`Add recommend tag${index}`}
                    onClick={() => addTag(recommend.tagName)}
                  >
                    <span className="recommend_tag_item_text">
                      #{recommend.tagName}
                    </span>
                  </button>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PostWriteTag;
