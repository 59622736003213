import { observer } from 'mobx-react'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/mall/icon_arrow_down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/mall/icon_arrow_up.svg'
import FilterList from '../presenter/FilterList'
import { Attribute_vlues, Attributes } from '../../../api/mallCategory/model'
import classes from './FilterSticky.module.scss'
import SelectFilterList from './SelectFilterList'

const FilterSticky = observer(
  ({
    showFilterList,
    setShowFilterList,
    showFilterToggleBtn,
    resetFilter,
    renderData,
    selectFilter,
    isFilterChange,
    selectFilterList,
  }: {
    showFilterList: boolean
    setShowFilterList: (x: boolean) => void
    showFilterToggleBtn: boolean
    resetFilter: () => void
    renderData: Attributes[]
    selectFilter: (key: number, name: string, item: Attribute_vlues) => void
    isFilterChange: boolean
    selectFilterList?: Attribute_vlues[]
  }) => {
    return (
      <div className={classes.bx_filter_wrap}>
        <div style={showFilterList ? {} : { display: 'none' }}>
          <FilterList
            renderData={renderData}
            selectFilter={selectFilter}
            isFilterChange={isFilterChange}
          />
          <SelectFilterList selectFilterList={selectFilterList} selectFilter={selectFilter} resetFilter={resetFilter} />
        </div>
        {
          showFilterToggleBtn
          // true
          ? (
          <div className={classes.bx_folded_bg}>
            <div
              className={classes.bx_gradient}
            />
            <div
              className={classes.btn_folded}
              onClick={() => setShowFilterList(!showFilterList)}
            >
              {showFilterList ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    )
  }
)

export default FilterSticky
