import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { UserActTypeCode } from '../constants/FeedType.enum'
import MainStore from './MainStore'
import { MainItem, PopularRanking, Post } from '../api/feed/model'
import MyHomeStore from './MyHomeStore'
import AuthStore from './AuthStore'
import PostStore from './PostStore'
import FeedDetailStore from './FeedDetailStore'
import ListStore from './ListStore'

class FollowerStore {
  constructor() {
    makeObservable(this)
  }

  @observable errorMessage?: string

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    )
  }

  @action setError = (message?: string) => {
    this.errorMessage = message
  }

  @action clear = () => {
    this.errorMessage = ''
  }

  @action handlePostFollowUpdate = (
    actionType: UserActTypeCode,
    memberUuid: string
  ) => {
    /* eslint-disable */
    const followUpdate = (dataList: any[]) => {
      dataList.forEach(
        (list: { createMemberUuid: string; followCount: number }) => {
          if (memberUuid === list.createMemberUuid) {
            if (actionType === UserActTypeCode.FOLLOW) {
              list.followCount = 1
            } else {
              list.followCount = 0
            }
          }
          return { ...list }
        }
      )
    }

    const mainList: MainItem[] = JSON.parse(JSON.stringify(MainStore.mainList))
    mainList
      .filter((x) => x.sort === 'MAIN_POST' || x.sort === 'MAIN_POST_SINGLE')
      .forEach((item) => {
        followUpdate(item.data)
      })
    MainStore.setMainList(mainList)

    const popularPostsList: Post[] = JSON.parse(
      JSON.stringify(MainStore.popularPostsList)
    )
    followUpdate(popularPostsList)
    MainStore.setPopularPostsList(popularPostsList)

    const postLists: Post[] = JSON.parse(JSON.stringify(PostStore.postsLists))
    followUpdate(postLists)
    PostStore.setPostLists(postLists)

    FeedDetailStore.feedStoreList.forEach((feedListStore, key) => {
      const feedList: ListStore<Post> = feedListStore
      followUpdate(feedList.list.slice())
      FeedDetailStore.setFeedListStoreList(key, feedList)

      const headBuffer = [...feedListStore.headBuffer]
      followUpdate(headBuffer)
      feedListStore.setHeadBuffer(headBuffer)

      const tailBuffer = [...feedListStore.tailBuffer]
      followUpdate(tailBuffer)
      feedListStore.setTailBuffer(tailBuffer)
    })

    // 타유저 마이홈 포스트 리스트
    const accountStore = MyHomeStore.getAccountStore(memberUuid)
    if (
      accountStore &&
      accountStore.postList &&
      accountStore.userId !== AuthStore.sessionUser?.memberUuid
    ) {
      const accountPostList = accountStore.postList
      followUpdate(accountPostList)
      accountStore.setPostList(accountPostList)
    }
  }
  /* eslint-enable */
}

export default new FollowerStore()
