import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ReactComponent as CommentIcon } from "../../../assets/icons/new_comment_gray.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/new_heart_off.svg";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { getDateStr } from "../../../utils/datetime";
import { observer } from "mobx-react";
import { autorun } from "mobx";
import ArticleListStore from "../../../store/ArticleListStore";
import { ReactComponent as LockIcon } from "../../../assets/icons/icon_lock_center.svg";
import { ReactComponent as ViewIcon } from "../../../assets/icons/icon_view.svg";
import { Category, Tab } from "../../../constants/Article.enum";
import AuthStore from "../../../store/AuthStore";
import ArticleDisclosureModal from "./ArticleDisclosureModal";
import UploadStore from "../../../store/upload/UploadStore";
import { UploadStatus } from "../../../constants/upload/UploadStatus";
import CommonBanner from "../../../components/common/CommonBanner";
import { ArticleListVO } from "../../../api/brand/model";
import { Virtuoso } from "react-virtuoso";
import CustomActivityIndicator from "../../../components/common/CustomActivityIndicatorSmall";
import CommonStore from "../../../store/CommonStore";

const ArticleList = observer(() => {
  const {
    notice_list,
    article_list,
    article_item,
    article_ad_item,
    article_info_wrap,
    article_info,
    article_small_info,
    small_wrap,
    small_wrap_inner,
    article_top,
    article,
    article_image,
    article_comment,
    article_view,
    like,
    like_text,
    article_item_text1,
    article_item_text1_closure,
    article_item_text2,
    article_item_text3,
    article_item_text5,
    article_item_bar,
    comment_icon,
    view_icon,
    empty,
    empty_wrap,
    empty_text,
    notice_item,
    top_flag_notice_text,
    top_notice_title,
    date_text,
    flag_notice_text,
    flag_default_text,
    item_title_wrap,
    icon_lock,
    bx_comm_img,
    num_comm_img,
    num_comm_text,
    touch_wrap,
    no_border,
    notice_badge_back,
    notice_badge_text,
    default_badge_text,
    default_badge_back
  } = styles;

  const { t } = useTranslation();

  const { sessionUser, tempMember } = AuthStore;

  const { status } = UploadStore;

  const {
    brandCommunityId,
    sort,
    selectedCategory,
    selectedTab,
    toastMessage,
    topFixNoticeList,
    brandArticleTopAdBannerList,
    brandArticleListAdBannerList,
    articleList,
    listAdPeriod,
    corpMemberYn,
    isLoading,
  } = ArticleListStore;

  let width: number;
  const location = useLocation();

  const [isShowDisclosurePopup, setIsShowDisclosurePopup] = useState<boolean>(false);
  const wrapArticleListRef = useRef<any>(null);

  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    // 화면 포커스 여부 감지
    setIsFocused(true);

    // 컴포넌트 언마운트 시 포커스 여부 갱신
    return () => {
      setIsFocused(false);
    };
  }, [location]);

  useEffect(() => {
    if (wrapArticleListRef?.current?.offsetWidth) {
      width = wrapArticleListRef?.current?.offsetWidth;
    }
  }, [wrapArticleListRef.current]);

  const loginUserUuid = String(
    sessionUser?.memberUuid === undefined || sessionUser?.memberUuid === null || sessionUser?.memberUuid?.length < 1
      ? tempMember?.tempMemberUUID
      : sessionUser?.memberUuid
  );

  const [initialRender, setInitialRender] = useState(false);

  useEffect(() => {
    ArticleListStore.clearStore();
    setInitialRender(true)
  }, []);

  useEffect(() => {
    const { setIsLoading, initArticleList } = ArticleListStore;
    if (initialRender) {
      autorun(async () => {
        setIsLoading(true);
        await initArticleList({
          communityId: brandCommunityId,
          sort: sort,
          category: selectedCategory,
          tab: selectedTab,
        });
        setIsLoading(false);
      });
    }
  }, [selectedCategory, selectedTab, sort, brandCommunityId, initialRender]);

  useEffect(() => {
    if (toastMessage !== undefined && toastMessage.trim().length > 0) {
      CommonStore.setToastOption({
        show: true,
        message1: toastMessage,
      });
      ArticleListStore.setToast("")
    }
  }, [toastMessage]);

  useEffect(() => {
    const { setIsLoading, initArticleList } = ArticleListStore;

    if (isFocused && UploadStatus.COMPLETE === UploadStore.status) {
      autorun(async () => {
        setIsLoading(true);
        await initArticleList();
        setIsLoading(false);
      });
    }
  }, [status, isFocused]);

  const moveToDetail = (articleId: number, articleListIndex: number) => {
    // TODO : 필요시 ActHistory 적용
    // void sendActHistory({
    //   actHistoryTypeCode: ActHistoryTypeCode.ARTICLE_READ,
    //   actHistoryTargetId: `${articleId}`,
    //   actPageCode: ActPageCode.QNA_TOTAL,
    //   attr1: `${articleListIndex}`,
    //   attr2: [...ArticleListStore.selectedCategory].toString(),
    //   // attr3: ArticleListStore.waitingAnswerOnly ? "true" : "false",
    //   attr4: `${ArticleListStore.sort}`,
    // });
    goTo(`/ArticleDetail/${articleId}`, { state: { index: articleListIndex } });
  };

  const renderLargeArticleInfo = (
    nickname: string,
    commentCount: number,
    viewCount: number,
    likeCount: number,
    createdDateTime: string,
    createMemberUuid: string,
    disclosureYn: string
  ) => {
    return (
      <div style={article_info_wrap}>
        <div style={article_info}>
          <p style={article_item_text3}>
            {nickname && (nickname.length > 10 ? nickname.substring(0, 8).concat("...") : nickname)}
          </p>
          <div style={article_item_bar} />
          {(corpMemberYn === "Y" ||
            disclosureYn !== "N" ||
            (disclosureYn === "N" && createMemberUuid === loginUserUuid)) && (
              <>
                <div style={article_comment}>
                  <CommentIcon style={comment_icon} />
                  <p style={article_item_text5}>{commentCount}</p>
                </div>
                <div style={article_view}>
                  <ViewIcon style={view_icon} />
                  <p style={article_item_text5}>{viewCount}</p>
                </div>
                <div style={like}>
                  <HeartIcon />
                  <p style={{ ...article_item_text5, ...like_text }}>{likeCount}</p>
                </div>
                <div style={article_item_bar} />
              </>
            )}
          <p style={article_item_text5}>{getDateStr(createdDateTime)}</p>
        </div>
      </div>
    );
  };

  const renderSmallArticleInfo = (
    nickname: string,
    commentCount: number,
    viewCount: number,
    likeCount: number,
    createdDateTime: string,
    createMemberUuid: string,
    disclosureYn: string
  ) => {
    return (
      <div style={article_info_wrap}>
        <div style={{ ...article_info, ...article_small_info }}>
          <p style={article_item_text3}>
            {nickname && (nickname.length > 10 ? nickname.substring(0, 8).concat("...") : nickname)}
          </p>
          <div style={small_wrap}>
            <div style={small_wrap_inner}>
              <div style={article_item_bar} />
              {(corpMemberYn === "Y" ||
                disclosureYn !== "N" ||
                (disclosureYn === "N" && createMemberUuid === loginUserUuid)) && (
                  <>
                    <div style={article_comment}>
                      <CommentIcon style={comment_icon} />
                      <p style={article_item_text5}>{commentCount}</p>
                    </div>
                    <div style={article_view}>
                      <ViewIcon style={view_icon} />
                      <p style={article_item_text5}>{viewCount}</p>
                    </div>
                    <div style={like}>
                      <HeartIcon />
                      <p style={{ ...article_item_text5, ...like_text }}>{likeCount}</p>
                    </div>
                    <div style={article_item_bar} />
                  </>
                )}
              <p style={{ ...article_item_text5, ...date_text }}>{getDateStr(createdDateTime)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTopFixNotice = () => {
    return (
      <Virtuoso
        style={notice_list}
        useWindowScroll
        initialItemCount={2}
        data={topFixNoticeList}
        itemContent={(index: number, item: ArticleListVO) => {
          return (
            <div
              key={index}
              onClick={() => {
                moveToDetail(item.articleId, index);
              }}
            >
              <div style={notice_item}>
                <div style={{ position: "relative", width: '100%' }}>
                  <div style={notice_badge_back}>
                    <p style={notice_badge_text}>{item?.articleTypeName}</p>
                  </div>
                  <p style={top_notice_title}>
                    <span style={top_flag_notice_text}>{t("screen.brandCommunity.articleList.tabtype.notice")}</span>
                    <span style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%'}}>{item?.articleTitle}</span>
                  </p>
                </div>
              </div>
            </div>
          );
        }}
      />
    );
  };

  const renderArticle = () => {
    return (
      <div ref={wrapArticleListRef}>
        <Virtuoso
          style={article_list}
          useWindowScroll
          data={articleList}
          itemContent={(index: number, item: ArticleListVO) => {
            return (
              <div>
                <div
                  key={index}
                  onClick={() => {
                    corpMemberYn === "Y" ||
                      item.articleDisclosureYn !== "N" ||
                      (item.articleDisclosureYn === "N" && item.createMemberUuid === loginUserUuid)
                      ? moveToDetail(item.articleId, index)
                      : setIsShowDisclosurePopup(true);
                  }}
                  style={touch_wrap}
                >
                  <div
                    style={{
                      ...article_item,
                      ...(index > 0 && index % listAdPeriod == 0 &&
                        (brandArticleListAdBannerList.length > Math.floor(index / listAdPeriod) * 5)
                        ?
                        no_border
                        : {}),
                    }}
                  >
                    <div style={article_top}>
                      <div style={article}>
                        <div style={item_title_wrap}>
                          {item.articleDisclosureYn !== "N" ? (
                            <div style={{ position: "relative", width: '100%' }}>
                              <div style={{ ...(item.articleTypeCode === "NOTICE" ? notice_badge_back : default_badge_back) }}>
                                <p style={item.articleTypeCode === "NOTICE" ? notice_badge_text : default_badge_text}>
                                  {item?.articleTypeName}
                                </p>
                              </div>
                              <div style={article_item_text1}>
                                <span
                                  style={{
                                    ...(item.articleTypeCode === "NOTICE" ? flag_notice_text : flag_default_text),
                                    marginRight: 14, width: 26, overflow: "hidden", flexShrink: 0
                                  }}
                                >
                                  {item?.articleTypeName}
                                </span>
                                <p style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%'}} 
                                  dangerouslySetInnerHTML={{
                                  __html: item.articleTitle || "",
                                  }}></p>
                              </div>
                              {item.articleTypeCode === "NOTICE" ? (
                                <p style={article_item_text2}
                                  dangerouslySetInnerHTML={{
                                  __html: item.articleContents || "",
                                  }}
                                ></p>
                              ) : (
                                <p style={article_item_text2} dangerouslySetInnerHTML={{
                                  __html: item.articleContents || "",
                                  }}></p>
                              )}
                            </div>
                          ) : item.articleDisclosureYn === "N" &&
                            (corpMemberYn === "Y" || item.createMemberUuid === loginUserUuid) ? (
                            <div style={{ position: "relative", width: '100%' }}>
                              <div style={{ ...(item.articleTypeCode === "NOTICE" ? notice_badge_back : default_badge_back) }}>
                                <p style={item.articleTypeCode === "NOTICE" ? notice_badge_text : default_badge_text}>
                                  {item?.articleTypeName}
                                </p>
                              </div>
                              <div style={article_item_text1}>
                                <span
                                  style={{
                                    ...(item.articleTypeCode === "NOTICE" ? flag_notice_text : flag_default_text),
                                    marginRight: 14, maxWidth: 26, overflow: "hidden"
                                  }}>
                                  {item?.articleTypeName}
                                </span>
                                <LockIcon style={icon_lock} />
                                <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }} 
                                  dangerouslySetInnerHTML={{
                                  __html: item.articleTitle || "",
                                  }}></p>
                              </div>
                              {item.articleTypeCode === "NOTICE" ? (
                                <p style={article_item_text2}
                                  dangerouslySetInnerHTML={{
                                  __html: item.articleContents || "",
                                  }}
                                ></p>
                              ) : (
                                <p style={article_item_text2} dangerouslySetInnerHTML={{
                                  __html: item.articleContents || "",
                                  }}></p>
                              )}
                            </div>
                          ) : (
                            <div style={{ position: "relative", width: '100%' }}>
                              <div style={{ ...(item.articleTypeCode === "NOTICE" ? notice_badge_back : default_badge_back) }}>
                                <p style={item.articleTypeCode === "NOTICE" ? notice_badge_text : default_badge_text}>
                                  {item?.articleTypeName}
                                </p>
                              </div>
                              <p style={article_item_text1_closure}>
                                <span style={{
                                  ...(item.articleTypeCode === "NOTICE" ? flag_notice_text : flag_default_text),
                                  marginRight: 14, width: 27
                                }}>
                                  {item?.articleTypeName}
                                </span>
                                <LockIcon style={icon_lock} />
                                <div style={{ width: 2 }} />
                                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{t("screen.brandCommunity.message.disclosureArticle")}</span>
                              </p>
                            </div>
                          )}
                        </div>

                        {(width && width > 370)
                          ? renderLargeArticleInfo(
                            item.nickname,
                            item.articleCommentCount,
                            item.articleViewCount,
                            item.articleLikeCount,
                            item.createdDatetime,
                            item.createMemberUuid,
                            item.articleDisclosureYn
                          )
                          : renderSmallArticleInfo(
                            item.nickname,
                            item.articleCommentCount,
                            item.articleViewCount,
                            item.articleLikeCount,
                            item.createdDatetime,
                            item.createMemberUuid,
                            item.articleDisclosureYn
                          )}

                      </div>
                      {!!item.thumbnailFilePath &&
                        (corpMemberYn === "Y" ||
                          item.articleDisclosureYn !== "N" ||
                          (item.articleDisclosureYn === "N" && item.createMemberUuid === loginUserUuid)) && (
                          <div style={bx_comm_img}>
                            <FastImageWithFallback
                              style={article_image}
                              source={{
                                uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
                              }}
                            />
                            <div style={num_comm_img}>
                              <p style={num_comm_text}>{item.fileCount}</p>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                {
                  ((articleList.length <= listAdPeriod && articleList.length - 1 == index) ||
                    (articleList.length >= listAdPeriod && index % listAdPeriod == listAdPeriod - 1)) &&
                  brandArticleListAdBannerList.length > Math.floor(index / listAdPeriod) * 5 && (
                    <div style={article_ad_item}>
                      <CommonBanner
                        bannerList={brandArticleListAdBannerList.slice(
                          Math.floor(index / listAdPeriod) * 5,
                          Math.floor(index / listAdPeriod) * 5 + 5 <= brandArticleListAdBannerList.length
                            ? Math.floor(index / listAdPeriod) * 5 + 5
                            : brandArticleListAdBannerList.length
                        )}
                        bannerSizeStyle={{ width: '100%', height: 84 }}
                        showTitleIndicator
                        otherCustomCarouselSettings={{
                          nextArrow: <></>,
                          prevArrow: <></>,
                        }}
                      />
                    </div>
                  )
                }
              </div >
            );
          }}
          endReached={() => {
            void ArticleListStore.getMoreArticleList();
          }}
        />
      </div>
    );
  };

  const renderNoArticle = () => {
    return (
      <div style={empty}>
        <div style={empty_wrap}>
          <p style={empty_text}>
            {selectedCategory === Category.ALL
              ? t("screen.brandCommunity.message.registFirstArticle")
              : t("screen.brandCommunity.message.emptyArticleListText", {
                category: t(
                  "screen.brandCommunity.articleList.filter.category.".concat(
                    selectedCategory.toString().toLowerCase()
                  )
                ),
              })}
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      {brandArticleTopAdBannerList && brandArticleTopAdBannerList.length > 0 && (
        <CommonBanner
          bannerList={brandArticleTopAdBannerList}
          bannerSizeStyle={{ width: '100%', height: 84 }}
          showTitleIndicator
          showTextIndicator
          textIndexStyle={{ color: "#fff", fontSize: 12, fontWeight: "500" }}
          otherCustomCarouselSettings={{
            nextArrow: <></>,
            prevArrow: <></>,
          }}
        />
      )}
      {isLoading ? (
        <></>
      ) : ArticleListStore.selectedTab !== Tab.MY && topFixNoticeList && topFixNoticeList.length > 0 ? (
        renderTopFixNotice()
      ) : (
        <></>
      )}

      {isLoading ? (
        <div id="loading" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CustomActivityIndicator />
        </div>
      ) : articleList && articleList.length === 0 ? (
        renderNoArticle()
      ) : (
        renderArticle()
      )}

      {isShowDisclosurePopup && (
        <ArticleDisclosureModal
          isVisible={isShowDisclosurePopup}
          contents1={t("screen.notificationModal.message.disclosureArticleText1")}
          contents2={t("screen.notificationModal.message.disclosureArticleText2")}
          textAlign="center"
          onRequestClose={() => {
            setIsShowDisclosurePopup(!isShowDisclosurePopup);
          }}
        />
      )}
    </>
  );
});

const styles: Styles = {
  touch_wrap: {
    ...Spacings.padding_left_right_16,
  },
  ad_banner: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_top_bottom_16,
    ...Spacings.padding_left_right_20,
    ...Colors.background_f5f5f5,
    ...Arranges.center_h,
  },
  ad_banner_text: {
    paddingLeft: 10,
  },
  ad_banner_title: {
    fontSize: 16,
    color: "#222",
    fontWeight: "500",
  },
  ad_banner_sub: {
    fontSize: 14,
    color: "#666",
  },
  notice_base_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_bottom_16,
    ...Spacings.margin_bottom_16,
    ...Colors.border_color_eee,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid'
  },
  notice_base_item_text1: {
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
    ...Texts.contents_text_34,
    ...Texts.font_weight_500,
    ...FlexBoxs.flex,
    ...Arranges.center_v,
  },

  notice_list: {
    ...Spacings.margin_left_right_16,
  },
  notice_item: {
    ...FlexBoxs.flex,
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    ...Arranges.center_h,
    ...FlexBoxs.horizon,
    ...Spacings.padding_top_14,
    ...Spacings.padding_bottom_12,
    ...Colors.border_color_eee,
  },
  notice_item_text1: {
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Colors.font_222,
    ...Texts.font_weight_700,
  },

  top_notice_title: {
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Colors.font_222,
    ...Texts.font_weight_700,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    display: "flex",
  },

  top_flag_notice: {
    marginBottom: -4,
    height: 22,
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    ...Spacings.border_radius_4,
    ...Spacings.padding_left_right_8,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.margin_right_6,
    ...Colors.background_f7e237,
  },
  flag_notice: {
    ...Spacings.border_radius_4,
    ...Spacings.padding_left_right_6,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.margin_right_6,
    ...Colors.background_f7e237,
  },
  flag_default: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "auto",
    ...Colors.background_f5f5f5,
    ...Spacings.border_radius_4,
    ...Spacings.padding_left_right_6,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.margin_right_6,
  },

  top_flag_notice_text: {
    opacity: 0,
    color: "rgba(0,0,0,0)",
    ...Texts.font_size_16,
    ...Texts.font_weight_500,
    ...Texts.font_letter_spacing_064,
    ...Spacings.margin_right_14,
    width: 26,
    display: "inline-block",
    flexShrink: 0,
  },
  flag_notice_text: {
    opacity: 0,
    color: "rgba(0,0,0,0)",
    ...Texts.font_size_16,
    ...Texts.font_weight_500,
    ...Texts.font_letter_spacing_064,
  },
  flag_default_text: {
    ...Texts.font_size_16,
    ...Texts.font_weight_500,
    ...Texts.font_letter_spacing_064,
    ...Colors.font_be69c3,
  },
  default_badge_back: {
    position: "absolute",
    top: 0,
    left: 0,
    minWidth: 34,
    ...FlexBoxs.flex,
    alignItems: "center",
    justifyContent: "center",
    height: 22,
    borderRadius: 4,
    backgroundColor: "#F5F5F5",
    marginBottom: -1,
  },
  notice_badge_back: {
    position: "absolute",
    top: 0,
    left: 0,
    minWidth: 34,
    ...FlexBoxs.flex,
    alignItems: "center",
    justifyContent: "center",
    height: 22,
    borderRadius: 4,
    backgroundColor: "#F7E237",
    marginBottom: -1,
  },
  notice_badge_text: {
    fontSize: 10,
    fontWeight: "700",
  },
  default_badge_text: {
    fontSize: 10,
    fontWeight: "700",
    ...Colors.font_be69c3,
  },

  article_list: {},
  no_border: {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  article_item: {
    borderTopWidth: 1,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    paddingBottom: 11,
    ...FlexBoxs.flex,
    ...Arranges.start_h,
    ...FlexBoxs.horizon,
    ...Spacings.padding_top_8,
    ...Colors.border_color_eee,
    height: 108,
  },
  article_ad_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Colors.border_color_eee,
    paddingTop: 4,
  },
  article_wrap: {
    ...FlexBoxs.flex_1,
  },
  article_info_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_top_8,
  },
  article_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  article_small_info: {
    ...FlexBoxs.flex,
    ...Arranges.end_h,
    ...FlexBoxs.horizon,
    ...FlexBoxs.flex_1,
  },
  small_wrap: {
    ...FlexBoxs.flex,
    ...Spacings.margin_top_4,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  small_wrap_inner: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  like: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  like_text: {
    ...Spacings.margin_left_2,
  },
  article_item_text1: {
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Colors.font_222,
    ...Texts.font_weight_500,
    overflow: 'hidden',
    whiteSpace: "nowrap",
    maxWidth: '100%',
    ...FlexBoxs.flex,
    alignItems: "center",
    paddingRight: 2,
  },
  article_item_text1_closure: {
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Colors.font_999,
    ...Texts.font_weight_500,
    ...FlexBoxs.flex,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    ...FlexBoxs.flex,
    alignItems: "center",
    paddingRight: 2,
  },
  article_item_text2: {
    ...Spacings.margin_top_6,
    ...Texts.contents_text_3,
    ...Texts.font_weight_400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  article_item_text3: {
    ...Texts.contents_text_9,
    ...Texts.font_weight_500,
    ...Colors.font_999,
  },
  article: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_right_8,
    ...FlexBoxs.flex,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  bx_comm_img: {
    width: 72,
    height: 72,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    overflow: "hidden",
    marginTop: 9,
    marginLeft: 25,
    ...Positions.relative,
    ...Spacings.border_radius_4,
    ...Colors.background_f5f5f5,
  },
  article_image: {
    width: 72,
    height: 72,
    ...Spacings.border_radius_6,
    objectFit: "cover",
    objectPosition: "center"
  },
  article_comment: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  article_view: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Spacings.margin_left_right_7,
  },
  view_icon: {
    ...Spacings.margin_right_4,
  },
  comment_icon: {
    ...Spacings.margin_right_4,
  },
  article_item_text4: {
    ...Texts.contents_text_9,
    ...Colors.font_be69c3,
  },
  article_item_text5: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Texts.contents_text_9,
    ...Colors.font_999,
  },
  article_item_bar: {
    ...Spacings.margin_left_8,
    ...Spacings.margin_right_7,
    width: 1,
    height: 14,
    ...Colors.background_eeeeee,
  },

  bx_comm_popular_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  bx_comm_pp: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
  },
  article_top: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    width: '100%',
    height: "100%",
  },

  num_comm_img: {
    width: 22,
    height: 22,
    ...Positions.absolute,
    ...Colors.background_666,
    ...Spacings.border_radius_4,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    opacity: 0.8,
    right: 0,
    bottom: 0,
  },
  num_comm_text: {
    ...Texts.contents_text_47,
    ...Colors.font_fff,
  },
  icon_lock: {
    marginBottom: 0,
    ...Spacings.margin_right_2,
    flexShrink: 0,
  },

  item_title_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.start_h,
    ...Spacings.padding_top_8,
    flexGrow: 1,
  },

  tab_item_wrap: {
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_bottom_8,
  },
  tab_item: {
    height: 34,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.padding_left_right_20,
    ...Spacings.border_radius_300,
    ...Spacings.margin_right_6,
    ...Colors.border_color_eee,
    ...Colors.background_fff,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid'
  },
  tab_active_item: {
    ...Colors.background_222,
  },
  tab_txt: {
    ...Texts.contents_text_41,
    ...Colors.font_666666,
  },
  tab_active_txt: {
    ...Colors.font_fff,
  },
  header_option: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Arranges.between,
    ...Colors.background_f5f5f5,
    ...Spacings.padding_left_right_20,
    ...Spacings.padding_top_bottom_16,
  },
  header_check_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  header_check_btn_txt: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    ...Spacings.margin_left_6,
  },
  header_sort_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  header_sort_btn_txt: {
    ...Texts.btn_text_1,
    ...Colors.font_666666,
    ...Spacings.margin_right_6,
  },
  empty: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_top_16,
  },
  empty_wrap: {
    marginTop: 70,
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  empty_text: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_666666,
  },
  write_article_btn: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.margin_top_14,
    ...Spacings.border_radius_300,
    ...Colors.border_color_be69c3,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    height: 44,
    width: 120,
  },
  write_article_btn_text: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
  tag_option: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Colors.background_f5f5f5,
    ...Spacings.padding_left_20,
    marginBottom: 1,
  },
  tag_label_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    zIndex: 100,
  },
  tag_label: {
    ...Spacings.padding_top_bottom_8,
    ...Texts.contents_text_41,
    ...Texts.font_weight_bold,
    ...Colors.font_222,
  },
  gradient: {
    ...Positions.absolute,
    ...Positions.top,
    ...Positions.bottom,
    right: -16,
    width: 16,
  },
};

export default ArticleList;
