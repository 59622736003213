import { SuccessOrNot } from "../../constants/Common.enum";
import { CouponSortType, CouponStatus, CouponType } from "../../constants/coupon";
import { callAPIWithGETMethod, callAPIWithPUTMethod } from "../common/api";
import CommonResponse from "../http";
import { CouponResponse, CouponUseYNRequest } from "./model";

export const getMyCouponList = async (
  pageSize: number,
  pageIndex: number,
  couponStatus: CouponStatus,
  sort: CouponSortType,
  isLoading = true
): Promise<CouponResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/coupons?&pageSize=${pageSize}&pageIndex=${pageIndex}&couponStatus=${couponStatus}&sort=${sort}`,
    },
    isLoading
  );

  return (response?.successOrNot === "Y" ? response?.data : null) as CouponResponse;
};

export const updateCouponUseYN = async (body: CouponUseYNRequest, isLoading = true): Promise<CommonResponse> => {
  return await callAPIWithPUTMethod(
    {
      url: `/v1/coupons`,
      body: body,
    },
    isLoading
  );
};
