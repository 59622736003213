import { useEffect, useRef } from 'react'
import { TrackFunction } from '../types'
import mixpanel from 'mixpanel-browser'
import { sleep } from '../../../utils/common'
import { WEB_UNIQUE_KEY } from '../../../constants/Storage'

type UseMixpanel = (token: string | undefined) => {
  mixPanel?: typeof mixpanel
  log: TrackFunction
}

export const useMixpanel: UseMixpanel = (token) => {
  var loaded = useRef<boolean>(false)

  useEffect(() => {
    const initialize = async () => {
      if (token) {
        mixpanel.init(token, {
          debug: false,
          autotrack: true,
          track_pageview: true,
          ignore_dnt: true,
          loaded: (mixpanel) => {
            if (mixpanel) loaded.current = true
          },
        })
      }
    }
    void initialize()
  }, [token])

  const waitForMixpanel = () => {
    return new Promise(async (resolve, reject) => {
      const maxRetries = 15

      for (let i = 0; i < maxRetries; i++) {
        if (loaded) {
          return resolve(true)
        }

        await sleep(500)
      }

      return reject(false)
    })
  }

  return {
    mixPanel: mixpanel,
    log: (event, properties) => {
      waitForMixpanel().then(() => {
        mixpanel.track(event, {...properties, webUniqueKey: localStorage.getItem(WEB_UNIQUE_KEY)})
      })
    },
  }
}
