import { observer } from "mobx-react";
import React, { useEffect } from "react";
import LikeList from "./presenter/LikeList";
import "./LikeScreen.scss";

const LikeScreen = observer(() => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      id="LikeScreen"
      // edges={["top", "left", "right"]}
      aria-label="Like Screen"
    >
      <LikeList />
    </div>
  );
});

export default LikeScreen;
