import { callAPIWithPOSTMethod } from "../common/api";
import CommonResponse from "../http";
import { SendCertificationResult } from "./model";

export const sendCertification = async (
  address: string,
  type = "email",
  isLoading = true
): Promise<SendCertificationResult> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/certification/request/" + type,
      body: {
        address,
      },
    },
    isLoading
  );

  return (
    response.successOrNot === "Y" ? response.data : null
  ) as SendCertificationResult;
};

export const checkCertification = async (
  certificationId: string,
  certificationCode: string,
  certificationType?: string,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/certification/check",
      body: {
        certificationId,
        certificationCode,
        certificationType,
      },
    },
    isLoading
  );
};
