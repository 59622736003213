import "./QnaListScreen.scss";

// import { div } from "@gorhom/bottom-sheet";
import { observer } from "mobx-react";
import React, { useEffect, useRef } from "react";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../assets/styles";

import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as ArrowRightPurple } from "../../assets/icons/arrow_right_purple3.svg";

import { SearchSortType } from "../../constants/Search";
import QnaStore from "../../store/QnaStore";

// import CustomBottomSheet from "../../components/common/CustomBottomSheet";
import QnaListFilter from "./presenter/QnaListFilter";
import QnaList from "./presenter/QnaList";
import BasicHeader from "../../components/headers/BasicHeader";
// import ListWithStickyTabs from "../../components/common/ListWithStickyTabs";
import { useTranslation } from "react-i18next";
import ListWithStickyTabs from "../../components/common/ListWithStickyTabs";
import CustomBottomSheet from "../../components/common/CustomBottomSheet";
import CommonStore from "../../store/CommonStore";
import { useWindowScroll } from "react-use";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import TouchableWithAuthCheck from "../../components/common/TouchableWithAuthCheck";

const QnaListScreen = observer((data: any) => {
  const { qnaListStore } = QnaStore;
  const stickyTabsRef = useRef(null);
  const { t } = useTranslation();

  const { y } = useWindowScroll();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    const bottom =
      window.scrollY !== 0 &&
      Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

    if (bottom) {
    }
    if (bottom && !qnaListStore.loading) {
      qnaListStore.getMoreQnaList();
    }
  }, [y]);

  return (
    <div className="qna_home" id="QnaListScreen" aria-label={"Qna list screen"}>
      <BasicHeader
        title={t(`screen.qnaList.title`)}
        isHome
        onSearch={() => {
          CommonStore.setShowDownloadAppDrive(true);
        }}
      />
      <ListWithStickyTabs
        contentScrollRef={stickyTabsRef}
        scrollableHeader={
          <TouchableWithAuthCheck
            className="go_question"
            onPress={() => {
              // goTo("/qna/write");
              CommonStore.setShowDownloadAppDrive(true);
            }}
            aria-label="Default Qna write button on list"
          >
            <p className="go_question_txt1">
              {t(`screen.qnaList.question.text1`)}
            </p>
            <div className="go_question_txt_wrap">
              <p className="go_question_txt2">
                {t(`screen.qnaList.question.text2`)}
              </p>
              <ArrowRightPurple />
            </div>
          </TouchableWithAuthCheck>
        }
        stickyItems={<QnaListFilter />}
        listRenderComponent={<QnaList />}
        showScrollToTop={true}
      />
      <CustomBottomSheet
        snapHeight={200}
        open={qnaListStore.showSortBox}
        onClose={() => qnaListStore.setShowSortBox(false)}
      >
        <div className="sheet">
          <div
            className="close_btn"
            onClick={() => qnaListStore.setShowSortBox(false)}
            aria-label="Qna list sort close"
          >
            <CloseIcon />
          </div>
          <div className="sheet_title_wrap">
            <p className="sheet_title" aria-label="Sorting">
              {t("screen.search.label.sort")}
            </p>
          </div>

          <div className="sort_list">
            <div
              className="sort_item"
              onClick={() => qnaListStore.setSort(SearchSortType.LATEST)}
              aria-label="Qna list sort latest"
            >
              <p
                className={
                  qnaListStore.sort === SearchSortType.LATEST
                    ? "sort_active_text"
                    : "sort_text"
                }
              >
                {t("screen.search.label.latest")}
              </p>
            </div>
            <div
              className="sort_item"
              onClick={() => qnaListStore.setSort(SearchSortType.POPULAR)}
              aria-label="Qna list sort popular"
            >
              <p
                className={
                  qnaListStore.sort === SearchSortType.POPULAR
                    ? "sort_active_text"
                    : "sort_text"
                }
              >
                {t("screen.search.label.popular")}
              </p>
            </div>
          </div>
        </div>
      </CustomBottomSheet>
    </div>
  );
});

export default QnaListScreen;
