import { observer } from "mobx-react";
import { CommentListItem } from "../../../api/comment/model";
import { ReactComponent as DimImage } from "../../../assets/icons/image_add_dim_comment.svg";
import AuthStore from "../../../store/AuthStore";
import { Virtuoso } from "react-virtuoso";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Styles } from "../../../assets/types/Style";
import {
  FlexBoxs,
  Arranges,
  Spacings,
  Colors,
  Texts,
} from "../../../assets/styles";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CommonStore from "../../../store/CommonStore";
import { ReactComponent as UpTag } from "../../../assets/icons/up_product_label.svg";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";

export interface CommentItemProductListProps {
  comment: CommentListItem;
}

const CommentItemProductList = observer(
  ({ comment }: CommentItemProductListProps) => {
    const { sessionUser } = AuthStore;
    const show_up_product_tag = getStringRemoteConfig("show_up_product_tag");

    const isCommentOwner =
      comment?.memberUuid !== undefined &&
      sessionUser?.memberUuid !== undefined &&
      comment?.memberUuid === sessionUser?.memberUuid;
    return (
      <>
        {comment.productList && comment.productList.length > 0 ? (
          <>
            <Virtuoso
              // bounces={false}
              data={comment.productList}
              className="comment_item_product_list"
              style={{ height: 83 * comment.productList.length }}
              // keyExtractor={(item, index) => `comment_item_product_${index}`}
              itemContent={(index, item) => {
                if (
                  !item?.openApiFlag &&
                  item?.productId !== undefined &&
                  item?.productId > 0
                ) {
                  return (
                    <div style={styles.product_item} className="product_item">
                      <button
                        style={styles.home_app_item}
                        className="home_app_item"
                        key={item.productId}
                        onClick={() => {
                          goTo(`/Product/${item.productId}`);
                        }}
                      >
                        <FastImageWithFallback
                          style={styles.home_app_img}
                          className="home_app_img"
                          source={{
                            uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                              item.productFiles[0]?.thumbnailFilePath?.replace(
                                "THUMBNAIL",
                                "ORIGINAL"
                              ) || ""
                            }`,
                          }}
                          fallbackImageUri={
                            process.env.REACT_APP_PUBLIC_BUCKET_URL +
                            "/ASSETS/fall_back_image.png"
                          }
                        />
                        <div className="home_app_info">
                          <p
                            className="home_app_text1"
                            // numberOfLines={1}
                          >
                            {item.productName}
                          </p>
                          <p
                            className="home_app_text2"
                            // numberOfLines={1}
                          >
                            {item.brandNameKor + " | " + item.productModelName}
                          </p>
                          {show_up_product_tag === "true" &&
                            item.upYn &&
                            item.upYn == "Y" && (
                              <div style={{ marginTop: 4 }}>
                                <UpTag />
                              </div>
                            )}
                        </div>
                      </button>
                    </div>
                  );
                } else if (item?.openApiFlag && isCommentOwner) {
                  return (
                    <div style={styles.product_item} className="product_item">
                      <div
                        style={styles.home_app_item}
                        className="home_app_item"
                        key={item.productId}
                      >
                        <FastImageWithFallback
                          style={styles.home_app_img}
                          className="home_app_img"
                          source={{
                            uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                              item.productFiles[0]?.thumbnailFilePath?.replace(
                                "THUMBNAIL",
                                "ORIGINAL"
                              ) || ""
                            }`,
                          }}
                          fallbackImageUri={
                            process.env.REACT_APP_PUBLIC_BUCKET_URL +
                            "/ASSETS/fall_back_image.png"
                          }
                        >
                          <DimImage style={styles.dim_image} />
                        </FastImageWithFallback>
                        <div className="home_app_info">
                          <p
                            className="home_app_text1"
                            // numberOfLines={1}
                          >
                            {item.productName}
                          </p>
                          <p
                            className="home_app_text2"
                            // numberOfLines={1}
                          >
                            {item.brandNameKor + " | " + item.productModelName}
                          </p>
                          {show_up_product_tag === "true" &&
                            item.upYn &&
                            item.upYn == "Y" && (
                              <div style={{ marginTop: 4 }}>
                                <UpTag />
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                } else return <></>;
              }}
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
);

const styles: Styles = {
  product_item: {
    backgroundColor: "#F7F4FA",
    borderRadius: 6,
    marginBottom: 4,
  },
  home_app_item: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.padding_top_bottom_10,
    paddingLeft: 10,
    paddingRight: 20,
    width: "71.7%",
  },

  home_app_img: {
    ...Spacings.margin_right_12,
    ...Spacings.border_radius_6,
    borderColor: "rgba(34, 34, 34, 0.1)",
    borderWidth: 1,
    width: 60,
    height: 60,
  },
  dim_image: {
    ...Spacings.border_radius_6,
  },
  home_app_rep_img: {
    ...Colors.border_color_be69c3,
  },
  home_app_info: {
    width: "53.3%",
  },
  home_app_text1: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    ...Spacings.margin_bottom_3,
  },
  home_app_text2: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
  },
  on_sale_tag_text: {
    color: "#BE69C2",
    fontSize: 10,
    fontWeight: "700",
    letterSpacing: -0.4,
    lineHeight: 14,
    paddingLeft: 1,
    paddingRight: 6,
    paddingVertical: 1,
    textAlign: "right",
  },
};

export default CommentItemProductList;
