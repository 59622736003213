import AuthStore from '../../store/AuthStore'
import {
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
} from '../common/api'
import CommonResponse from '../http'
import { DisplayProduct } from '../mall/model'
import {
  CategoryFilterProduct,
  CategoryInfo,
  FilterProductRequest,
  LifecarePopularProductRequest,
} from './model'

// 대 카테고리 목록 조회
export const getLargeCategoryInfo = async (
  isLoading = true
): Promise<CategoryInfo[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/mall/display-category/0`,
    },
    isLoading
  )

  return (
    response?.successOrNot === 'Y' ? response?.data : null
  ) as CategoryInfo[]
}

// 카테고리 인기제품 목록 조회
export const getLifecarePopularProduct = async (
  params: LifecarePopularProductRequest,
  isLoading = true
): Promise<DisplayProduct[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/mall/lifecare-popular-product`,
      params: params,
    },
    isLoading
  )

  return (
    response?.successOrNot === 'Y' ? response?.data : null
  ) as DisplayProduct[]
}

// 카테고리 필터 제품 목록 조회
export const mallFilterProduct = async (
  params: FilterProductRequest,
  isLoading = true
): Promise<CategoryFilterProduct> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: '/v1/mall/filter-product',
      body: params,
    },
    isLoading
  )
  return (
    response?.successOrNot === 'Y' ? response?.data : null
  ) as CategoryFilterProduct
}
