import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/5218_close.svg";
import { ReactComponent as WelcomeIcon } from "../../../assets/icons/5218_welcome.svg";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import "./MainTutorial.scss";
import WriteButtonStore from "../../../store/WriteButtonStore";
import { Platform, PlatformTypes } from "../../../native/platform";

const MainTutorial = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const close = async () => {
    onClose();
    WriteButtonStore.setShowWriteButton(true);
    void localStorage.setItem("isShownMainTutorial", "false");
  };

  useEffect(() => {
    WriteButtonStore.setShowWriteButton(false);
  }, []);

  return (
    (show && (
      <div
        id="MainTutorial"
        style={
          Platform.OS === PlatformTypes.ANDROID_WEB ||
          Platform.OS === PlatformTypes.IOS_WEB
            ? { top: "50px" }
            : {}
        }
        onClick={() => {
          close();
        }}
        onTouchEnd={() => {
          close();
        }}
      >
        <div className="close_icon_wrapper">
          <div
            aria-label="Tutorial Close"
            onClick={() => {
              close();
            }}
          >
            <CloseIcon />
          </div>
        </div>
        <div className="welcome_icon_container">
          <WelcomeIcon />
        </div>
        <div className="text_area_1_container">
          <div style={{ color: "#f7de2a" }}>
            {t("screen.main.tutorial.postAndShare1")}
          </div>
          {t("screen.main.tutorial.postAndShare2")}
        </div>
        <div className="text_area_1_container">
          <div style={{ color: "#f7de2a" }}>
            {t("screen.main.tutorial.postAndShare3")}
          </div>
          {t("screen.main.tutorial.postAndShare4")}
        </div>
        <div className="touch_icon_container">
          <Lottie
            className="touch_icon"
            animationData={require("../../../assets/animations/5218_icon.json")}
            autoPlay
            loop
          />
        </div>
        <div className="swipeUpWrap">
          <div className="swipeUpText">{t("screen.main.tutorial.swipeUp")}</div>
          <div className="aiText">{t("screen.main.tutorial.aiSummary")}</div>
        </div>
      </div>
    )) || <div></div>
  );
};

export default MainTutorial;
