import { t } from "i18next";
import { action, computed, makeObservable, observable } from "mobx";
import {
  deleteAb,
  getAbDetail,
  addAbVote,
  deleteAbVote,
  getAbDetailResponse,
} from "../api/ab/api";
import { AbDetailResult, AbImageSection, AbFile } from "../api/ab/model";
import { AbTypeCode } from "../constants/ab";
import AbListStore from "./AbListStore";
import CommonStore from "./CommonStore";
import { ErrorCode } from "../constants/Error";

const INITIAL_VALUE = {
  _abDetail: {
    feedId: 0,
    abFlag: null,
    feedTitle: "",
    feedEndDate: "",
    feedContentId: 0,
    contents: "",
    adesc: "",
    bdesc: "",
    feedFileGroupId: 0,
    afile: {} as AbFile,
    bfile: {} as AbFile,
    createdDatetime: "",
  },
  _imageSection: { filePath: "", ab: "", desc: "" },
};
class AbStore {
  constructor() {
    makeObservable(this);
  }
  @observable _abDetail: AbDetailResult = INITIAL_VALUE._abDetail;
  @observable _aContent: AbImageSection = INITIAL_VALUE._imageSection;
  @observable _bContent: AbImageSection = INITIAL_VALUE._imageSection;
  @observable _aVoteCount = 0;
  @observable _bVoteCount = 0;
  @observable _isShowBlockedModal = false;

  @action retrieveAbDetail = async (
    feedId: number
  ): Promise<AbDetailResult> => {
    try {
      const response = await getAbDetailResponse(feedId);
      const res = (
        response?.successOrNot === "Y" ? response?.data : null
      ) as AbDetailResult;

      if (
        response.successOrNot == "N" &&
        response.statusCode == ErrorCode.FEED_ID_NOT_EXIST.code
      ) {
        return this.abDetail;
      }

      if (response.successOrNot == "N") {
        CommonStore.setToastOption({
          show: true,
          autoHide: true,
          type: "tomatoToast",
          message1: t("common.message.unknownException"),
          // bottomOffset: 30,
        });
        return this.abDetail;
      }

      this.setAbDetail(res);

      const a: AbImageSection = {
        thumbnailFilePath: res.afile?.thumbnailFilePath,
        filePath: res.afilePath,
        ab: "A",
        desc: res.adesc || "",
        isMyPick: res.abFlag === "A",
        isWinner:
          (res.avoteCount || 0) + (res.bvoteCount || 0) > 0 &&
          (res.avoteCount || 0) > (res.bvoteCount || 0),
      };
      const b: AbImageSection = {
        thumbnailFilePath: res.bfile?.thumbnailFilePath,
        filePath: res.bfilePath,
        ab: "B",
        desc: res.bdesc || "",
        isMyPick: res.abFlag === "B",
        isWinner:
          (res.avoteCount || 0) + (res.bvoteCount || 0) > 0 &&
          (res.avoteCount || 0) <= (res.bvoteCount || 0),
      };

      this.setAContent(a);
      this.setBContent(b);
      this.setAVoteCount(res.avoteCount || 0);
      this.setBVoteCount(res.bvoteCount || 0);

      return this.abDetail;
    } catch {
      this.setIsShowBlockedModal(true);
      return this.abDetail;
    }
  };

  @action deleteAb = async (feedId: number) => {
    await deleteAb([feedId]);
  };

  @action addAbVote = async (feedId: number, abFlag: string) => {
    const res = await addAbVote(feedId, abFlag);
    if (res) {
      this.setAVoteCount(res.avoteCount);
      this.setBVoteCount(res.bvoteCount);
      this.setAbDetail({
        ...this._abDetail,
        avoteCount: res.avoteCount,
        bvoteCount: res.bvoteCount,
      });

      AbListStore.updateAbVoteResultTemporaily(res, abFlag as AbTypeCode);
      AbListStore.updatePopularAbVoteResultTemporaily(
        res,
        abFlag as AbTypeCode
      );
      AbListStore.updateMyAbVoteResultTemporaily(res, abFlag as AbTypeCode);
    }

    this.setAContent({
      ...this.aContent,
      isMyPick: abFlag === "A",
      isWinner: res.avoteCount > res.bvoteCount,
    });
    this.setBContent({
      ...this.bContent,
      isMyPick: abFlag === "B",
      isWinner: res.avoteCount <= res.bvoteCount,
    });
    this.setAbDetail({ ...this.abDetail, voted: "Y" });
  };

  @action deleteAbVote = async (feedId: number) => {
    const res = await deleteAbVote(feedId);
    if (res) {
      this.setAVoteCount(res.avoteCount);
      this.setBVoteCount(res.bvoteCount);
      this.setAbDetail({
        ...this._abDetail,
        avoteCount: res.avoteCount,
        bvoteCount: res.bvoteCount,
      });

      AbListStore.updateAbVoteResultTemporaily(res, null);
      AbListStore.updatePopularAbVoteResultTemporaily(res, null);
      AbListStore.updateMyAbVoteResultTemporaily(res, null);
    }
  };

  @action setAbDetail = (abDetail: AbDetailResult) => {
    this._abDetail = abDetail;
  };

  @action setAContent = (content: AbImageSection) => {
    this._aContent = content;
  };

  @action setBContent = (content: AbImageSection) => {
    this._bContent = content;
  };

  @action setAVoteCount = (vote: number) => {
    this._aVoteCount = vote;
  };

  @action setBVoteCount = (vote: number) => {
    this._bVoteCount = vote;
  };

  @action setIsShowBlockedModal = (show: boolean) => {
    this._isShowBlockedModal = show;
  };

  @action clearAbDetail = () => {
    this.setAbDetail(INITIAL_VALUE._abDetail);
    this.setAContent(INITIAL_VALUE._imageSection);
    this.setBContent(INITIAL_VALUE._imageSection);
    this.setAVoteCount(0);
    this.setBVoteCount(0);
    this.setIsShowBlockedModal(false);
  };

  @computed get abDetail() {
    return this._abDetail;
  }

  @computed get aContent() {
    return this._aContent;
  }

  @computed get bContent() {
    return this._bContent;
  }

  @computed get aVoteCount() {
    return this._aVoteCount;
  }

  @computed get bVoteCount() {
    return this._bVoteCount;
  }

  @computed get isShowBlockedModal() {
    return this._isShowBlockedModal;
  }
}

export default new AbStore();
