import React from "react";
import Modal from "react-modal";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { Styles } from "../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Spacings, Texts, Positions, Icons, Assets } from "../../assets/styles";

interface Props {
  title?: string;
  contentsHTML?: () => string | JSX.Element | undefined; // 타입 변경
  contents1: string;
  contents2?: string;
  defaultButtonText?: string;
  extraButtonText?: string;
  isVisible: boolean;
  isBlack?: boolean;
  useTwoButton?: boolean;
  onRequestClose?: () => void;
  onClickDefaultButton?: () => void;
  onClickExtraButton?: () => void;
  modalCloseCallback?: () => void;
  textAlign?: "auto" | "left" | "right" | "center" | "justify";
  modalWidth?: number;
  children?: React.ReactNode;
  // handleClose: () => void; // handleClose prop 추가
  // handleOnCloseCallback: () => void;
}

const NotificationModalWithHideCallback: React.FC<Props> = ({
  title,
  contentsHTML,
  contents1,
  contents2,
  defaultButtonText,
  extraButtonText,
  isVisible,
  isBlack,
  useTwoButton,
  onRequestClose,
  onClickDefaultButton,
  onClickExtraButton,
  modalCloseCallback,
  textAlign,
  modalWidth,
  children
}) => {
  const { t } = useTranslation();
  const {
    modal_wrap,
    notification_modal,
    header_text,
    modal_body,
    icons,
    modal_header,
    button,
    modal_footer,
    footer_button_text,
    text_0,
    text_1,
    modal_footer_two,
    button_two,
    button_left,
    button_right,
    footer_button_gray_text,
  } = styles;
  
  const dismiss = () => {
    if (onRequestClose !== undefined) onRequestClose();
  };

  const onDefaultButtonClicked = () => {
    if (onClickDefaultButton !== undefined) onClickDefaultButton();
    else dismiss();
  };

  const onExtraButtonClicked = () => {
    if (onClickExtraButton !== undefined) onClickExtraButton();
  };

  const onModalHideCallback = () => {
    if (modalCloseCallback !== undefined) modalCloseCallback();
  };

  return (
    <div style={modal_wrap}>
      <Modal
        isOpen={isVisible} // 변경된 prop: isVisible 대신 isOpen 사용
        onRequestClose={dismiss} // 변경된 prop: onBackButtonPress 대신 onRequestClose 사용
        onAfterClose={onModalHideCallback} // 변경된 이벤트: onModalHide 대신 onAfterClose 사용
        contentLabel="Notification Modal"
        id="contents-notification-modal"
        style={{
          content: {
            width: "100%",
            height: "100%",
            display: `flex`,
            background: 'transparent',
            inset: 0,
            zIndex: 999
          },
        }}
      >
        <div style={{...notification_modal, ...(modalWidth ? {width: modalWidth} : {}) }}>
          <div style={modal_header}>
            <h2 style={header_text} aria-label="Title">
                {title && title !== undefined ? title : t("screen.notificationModal.label.alert")}
            </h2>
            <button style={icons} onClick={dismiss} aria-label="Close">
              <CloseIcon />
            </button>
          </div>
          {contents1.length !== 0 && (
            <div style={modal_body}>
              <p style={{...text_0 }}
                aria-label="Contents1"
                dangerouslySetInnerHTML={{ __html: contents1.replaceAll("\n", "<br />") }}
              />
              {contents2 && contents2 !== undefined && (
                <p style={{...text_1 }}
                  aria-label="Contents2"
                  dangerouslySetInnerHTML={{ __html: contents2.replaceAll("\n", "<br />") }}
                />
              )}
            </div>
          )}
          {contentsHTML && (
            <div style={styles.modal_body}>
              <div style={{...styles.text_0}} aria-label="contentsHTML">
                {contentsHTML()}
              </div>
            </div>
          )}
          {children && <>{children}</>}
          {useTwoButton === true ? (
            <div style={modal_footer_two}>
              <button
                style={{...button_two, ...button_left, ...(isBlack ? styles.black : styles.gray)}}
                onClick={onExtraButtonClicked}
                aria-label="Left button"
              >
                <span style={{...footer_button_text, ...(!isBlack ? footer_button_gray_text : {})}}>
                  {extraButtonText ? extraButtonText : t("screen.notificationModal.button.close")}
                </span>
              </button>
              <button
                style={{...button_two, ...button_right, ...styles.black}}
                onClick={onDefaultButtonClicked}
                aria-label="Right button"
              >
                <span style={footer_button_text}>
                  {defaultButtonText ? defaultButtonText : t("screen.notificationModal.button.close")}
                </span>
              </button>
            </div>
          ) : (
            <div style={modal_footer}>
              <button
                style={{...button, ...styles.black}}
                onClick={onDefaultButtonClicked}
                aria-label="Close button"
              >
                <span style={footer_button_text}>
                  {defaultButtonText ? defaultButtonText : t("screen.notificationModal.button.close")}
                </span>
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};


const styles : Styles = {
  modal_wrap: {
    zIndex: '999'
  },
  notification_modal: {
    ...Colors.border_color_transparent,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.margin_center_v_h,
    ...Assets.modal_box_shadow,
    width: 'fit-content',
    height: 'fit-content',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(255, 255, 255)',
    borderRadius: 14

  },
  modal_header: {
    // ...FlexBoxs.flex,
    // ...Arranges.center_v_h,
    ...Positions.relative,
    ...Colors.background_fff,
    ...Spacings.border_top_left_radius_12,
    ...Spacings.border_top_right_radius_12,
    height: 64,
    textAlign: 'center',
    paddingLeft: 40,
    paddingRight: 40,
  },
  modal_body: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_20,
    ...Colors.background_fff,
  },
  icons: {
    ...Icons.icon_32,
    ...Positions.absolute,
    ...Positions.top_6,
    ...Positions.right_10,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  text_0: {
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Spacings.margin_bottom_24,
    ...Colors.font_222,
    textAlign: 'center'
  },
  text_1: {
    width: 170,
    ...Texts.font_size_14,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.margin_bottom_26,
    ...Arranges.text_align_center,
    ...Texts.font_letter_spacing_056,
    ...Colors.font_222,
    textAlign: 'center'
  },
  modal_footer: {
    height: 48,
    ...FlexBoxs.flex,
  },
  modal_footer_two: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    height: 48,
  },
  button: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
  },
  purple: {
    ...Colors.background_6c2773,
  },
  black: {
    ...Colors.background_222,
  },
  footer_button_text: {
    ...Texts.button_text_3,
  },
  button_line_black: {
    ...Colors.two_btn_border_color_666666,
    width: 1,
    borderWidth: 0.5,
  },
  button_left: {
    ...Spacings.border_bottom_left_radius_12,
    ...Colors.two_btn_border_color_666666,
    borderRightWidth: 0.5,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
  },
  button_right: {
    ...Spacings.border_bottom_right_radius_12,
  },
  button_two: {
    ...Colors.background_6c2773,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  gray: {
    ...Colors.background_eeeeee,
  },
  footer_button_gray_text: {
    ...Colors.font_666666,
  },
};

export default NotificationModalWithHideCallback;