import { ReactComponent as Camera } from "../../assets/icons/camera_white.svg";
import ProfileHeader from "../../components/common/ProfileHeader";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileSelectComponent from "./ProfileSelectComponent";
import AuthStore from "../../store/AuthStore";
import MemberStore from "../../store/MemberStore";
import { observer } from "mobx-react";
import {
  mergeAndSetTempMemberInStorage,
  removeTempMemberFromStorage,
} from "../../service/Preference.service";
import { mergeAndSetSessionUserInStorage } from "../../service/Session.service";
import ProfileStore from "../../store/ProfileStore";
import { ImageForUI } from "../../utils/album";
import { useTracker } from "../../hooks/tracker";
import { memberCpaLog } from "../../api/member/api";
import { LogCpaRequest } from "../../api/member/model";
import BadgeStore from "../../store/BadgeStore";
import { useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import classes from "./ProfileSetting.module.scss";
import { goRedirect } from "../../hooks/navigate/NavigateFunction";

interface ProfileSettingProps {
  nickname?: string;
  hideBack?: boolean;
}

const ProfileSetting = observer(() => {
  const location = useLocation();

  const { saveMemberProfile } = MemberStore;
  const { tempMember, sessionUser } = AuthStore;
  const { cameraProfile, clear, uploadFileAndGetFilePath } = ProfileStore;

  const { hideBack }: ProfileSettingProps = {
    hideBack: (location.state && location.state.hideBack) || true,
  };

  const PUBLIC_PROFILE_BUCKET_URL = String(
    process.env.REACT_APP_PUBLIC_BUCKET_URL
  );
  const PREFIX_SYSYEM_PROFILE_PATH: string =
    PUBLIC_PROFILE_BUCKET_URL + "/PROFILE/SYSTEM/sample_";
  const DEFAULT_SELECTED_PRIFILE = `${PREFIX_SYSYEM_PROFILE_PATH}1.png`;
  // const insets = useSafeAreaInsets();
  const { t } = useTranslation();
  const { track } = useTracker();

  const [profilePopup, setProfilePopup] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(
    DEFAULT_SELECTED_PRIFILE
  );

  const onPopupOpen = () => {
    setProfilePopup(true);
  };

  const onPopupClose = () => {
    setProfilePopup(false);
  };

  const handleSelect = (uri: string) => () => {
    setSelectedProfile(uri);
    onPopupClose();
  };

  const goToMain = () => {
    // navigate("/", { replace: true });
    goRedirect();
  };

  const handleSaveProfileSettingDone = async () => {
    const isMember = !!sessionUser?.memberUuid;
    const isTempMember = !!tempMember?.tempMemberUUID;
    const selectedProfileUrl = selectedProfile.replace(
      PUBLIC_PROFILE_BUCKET_URL,
      ""
    );

    if (isMember && sessionUser?.profileUrl) {
      clear();
      goToMain();
      return;
    }

    void (await sendCpaInfo());

    if (cameraProfile && isMember)
      await uploadToS3AndSaveProfileForMember(cameraProfile);
    else if (!cameraProfile && isMember)
      await saveProfileForMember(selectedProfileUrl);
    else if (cameraProfile && isTempMember)
      await saveProfileForTempMember(undefined, cameraProfile);
    else if (!cameraProfile && isTempMember)
      await saveProfileForTempMember(selectedProfileUrl);

    clear();
    goToMain();
  };

  const handleProfileSettingSkip = async () => {
    const isMember = !!sessionUser?.memberUuid;
    const isTempMember = !!tempMember?.tempMemberUUID;
    const defaultProfileUrl = DEFAULT_SELECTED_PRIFILE.replace(
      PUBLIC_PROFILE_BUCKET_URL,
      ""
    );

    void (await sendCpaInfo());

    if (isMember) {
      await saveProfileForMember(defaultProfileUrl);
    } else if (isTempMember) {
      await saveProfileForTempMember(defaultProfileUrl);
    }
    goToMain();
  };

  const sendCpaInfo = async () => {
    const refferingParams = MemberStore.getCpaInfo();
    if (refferingParams) {
      const channel = refferingParams["~channel"] as string;
      const $3p = refferingParams["$3p"] as string;
      const campaign = refferingParams["~campaign"] as string;
      const request: LogCpaRequest = {
        campaign,
        channel,
        $3p,
      };
      await memberCpaLog(request);
    }
  };

  const uploadToS3AndSaveProfileForMember = async (image: ImageForUI) => {
    const filePath = await uploadFileAndGetFilePath(image);
    await saveProfileForMember(filePath);
  };

  const saveProfileForMember = async (profileUrl: string) => {
    //logging : 최종적으로 회원가입을 완료하기 위해 해당 함수를 실행해야함. AuthStore.loginType 으로 회원가입 타입을 확인한다.
    BadgeStore.setFollowBadgeCache("complete_signup");
    track("complete_signup", {
      method: AuthStore.loginType,
    });

    await saveMemberProfile(profileUrl);
    await mergeAndSetSessionUserInStorage({ profileUrl: profileUrl });
    await removeTempMemberFromStorage();
  };

  const saveProfileForTempMember = async (
    profileUrl?: string,
    profileImageFromGallary?: ImageForUI
  ) => {
    await mergeAndSetTempMemberInStorage({
      profileUrl: profileUrl,
      profileImageFromGallary: profileImageFromGallary,
    });
  };

  return (
    <>
      <div className={classes.space} />
      <div className={classes.join_email}>
        <div className={classes.container}>
          <div className={classes.header} aria-label={"Profile header"}>
            <ProfileHeader
              title={t("screen.profile.header.title")}
              hideBack={hideBack}
            />
          </div>
          <div className={classes.contents_box}>
            <div className={classes.body}>
              <span className={classes.title_text} aria-label={"Profile Title"}>
                {t("screen.profile.title")}
              </span>
              <div className={classes.intro}>
                <span
                  className={classes.intro_text}
                  aria-label={"Profile Description1"}
                >
                  {t("screen.profile.introDescription1")}
                </span>
                <span
                  className={classes.intro_text}
                  aria-label={"Profile Description2"}
                >
                  {t("screen.profile.introDescription2")}
                </span>
              </div>

              <LazyLoadImage
                className={classes.profile_image}
                src={selectedProfile}
              />

              <button
                className={classes.profile_select_btn}
                onClick={onPopupOpen}
                aria-label={"Profile Setting Popup"}
              >
                <Camera className={classes.profile_select_image} />
                <span className={classes.profile_select_text}>
                  {t("screen.profile.profileSelect")}
                </span>
              </button>

              <button
                className={classes.skip_btn}
                onClick={() => void handleProfileSettingSkip()}
              >
                <span className={classes.skip_txt}>
                  {t("screen.profile.header.skip")}
                </span>
              </button>
            </div>
            <div className={classes.footer}>
              <button
                className={classes.btn_1}
                onClick={() => void handleSaveProfileSettingDone()}
                aria-label={"Start Moga"}
              >
                <span className={classes.btn_1_txt}>
                  {t("screen.profile.startMoga")}
                </span>
              </button>
            </div>
          </div>
        </div>
        {profilePopup && (
          <ProfileSelectComponent
            prefixUri={PREFIX_SYSYEM_PROFILE_PATH}
            selectedUri={selectedProfile}
            handleSelect={handleSelect}
            onClose={onPopupClose}
          />
        )}
      </div>
    </>
  );
});

export default ProfileSetting;
