import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import AuthStore from "../../../store/AuthStore";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
  Icons,
} from "../../../assets/styles";
import {
  checkValidTagNickname,
  getCloseTagPosition,
  getSearchTagIndicator,
  createTagInfoStr,
  getValidTagNickname,
  getTagNicknameFromTagUserInfo,
  getUuidFromTagUserInfo,
  replaceTagUserInfoToTagNickname,
} from "../../../utils/comment";
import "./CommentFooter.scss";
import CommentStore from "../../../store/CommentStore";
import { CommentUserListItem } from "../../../api/comment/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { CommentTypeCode } from "../../../constants/Comment";
import { bottomOffset } from "../../../utils/common";
import CommonStore from "../../../store/CommonStore";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/comment_footer_search.svg";
import { ReactComponent as SubmitIcon } from "../../../assets/icons/comment_footer_submit.svg";
import { FeedMode, FeedTypeCode } from "../../../constants/Feed";
import { NewProduct } from "../../../api/product/model";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/close_purple_background_32.svg";
import { ReactComponent as DimImage } from "../../../assets/icons/image_add_dim_comment.svg";
import { Platform } from "../../../native/platform";
import { track } from "../../../hooks/tracker/TrackFunction";
import { Styles } from "../../../assets/types/Style";
import CustomBottomSheet, {
  CustomBottomSheetProps,
} from "../../../components/common/CustomBottomSheet";
import { Virtuoso } from "react-virtuoso";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
import ArticleDetailStore from "../../../store/ArticleDetailStore";
import { showBottomToast } from "../../../utils/Toast";
import CustomParsedText, {
  ParsedTextToText,
} from "../../../components/common/CustomParsedText";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import MainStore from "../../../store/MainStore";

const CommentFooter = observer(function CommentFooter({
  disabled,
  commentTypeCode,
}: {
  disabled?: boolean;
  commentTypeCode?: CommentTypeCode;
}) {
  const {
    foundNickname,
    authorNicknameList,
    findNickname,
    clearTagUserInfo,
    deleteTagUserInfo,
    setTagUserInfo,
    setTextInputIndex,
    textInputIndex,
    tagUserInfo,
    commentInEdit,
    setCommentInEdit,
    searchTextLength,
    clear,
    setSearchTextLength,
    updateComment,
    createComment,
    addAuthorNicknameList,
    tempProductList,
  } = CommentStore;

  const { sessionUser } = AuthStore;

  const { t } = useTranslation();
  // const sheetRef = useRef<CustomBottomSheetProps>(null)
  const keyboardRef = useRef<HTMLInputElement>(null);
  // const snapPoints = useMemo(
  // () => [-999, Platform.OS === 'ios' ? 430 : 380],
  // )
  const SPACE_STR = " ";
  const TAG_SEARCH_STR = "@";
  const EMPTY_STR = "";
  const [isFindNickname, setIsFindNickname] = useState(false);
  const [visibleBottomSheet, setVisibleBottomSheet] = useState(false);
  const [textInputPos, setTextInputPos] = useState(-1);
  const [tempNickname, setTempNickname] = useState(EMPTY_STR);
  const [isEnableApply, setIsEnableApply] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  // useFocusEffect(
  //   useCallback(() => {
  //     void checkShowTooltip();
  //     return () => {
  //       setVisibleBottomSheet(false);
  //     };
  //   }, [])
  // );
  const [lastTagUserInfo, setLastTagUserInfo] = useState(String);
  const [lastTagUserNickname, setLastTagUserNickname] = useState(String);
  const [clearInputValue, setClearInputValue] = useState(false);

  useEffect(() => {
    if (visibleBottomSheet) {
      onBottomSheet();
    } else {
      onCloseBottomSheet();
    }
  }, [visibleBottomSheet]);

  useEffect(() => {
    return () => {
      setCommentInEdit({contents: ""})
    }
  }, [])

  useEffect(() => {
    setVisibleBottomSheet(foundNickname.length > 0);
  }, [foundNickname]);

  useEffect(() => {
    if ((commentInEdit?.contents || EMPTY_STR).length > 0) {
      keyboardRef.current?.focus();
    }
  }, [commentInEdit]);

  const checkShowTooltip = () => {
    const result = window.localStorage.getItem("SHOW_TOOLTIP_COMMENTFOOTER");
    if (result !== "SUCCESS") {
      setShowTooltip(true);
    }
  };

  const closeTooltip = () => {
    setShowTooltip(false);
    window.localStorage.setItem("SHOW_TOOLTIP_COMMENTFOOTER", "SUCCESS");
  };

  const productCallBack = (selectedProduct: NewProduct) => {
    CommentStore.addProduct(selectedProduct);
  };

  const productListCallBack = (selectedMyProduct: NewProduct) => {
    CommentStore.addProduct(selectedMyProduct);
  };

  const moveToProductSearch = () => {
    void closeTooltip();
    CommonStore.setShowDownloadAppDrive(true);
    // if (CommentStore.tempProductList.length < 5) {
    //   ProductSearchAddStore.setFeedMode(FeedMode.COMMENT_QNA);
    //   goTo("ProductSearchScreen", {
    //     state: {
    //       feedTypeCode: FeedTypeCode.COMMENT,
    //       onClose: (product: NewProduct) => productCallBack(product),
    //       addProductList: (product: NewProduct) => productListCallBack(product),
    //       screenFrom: FeedTypeCode.COMMENT,
    //     },
    //   });
    // } else
    //   showBottomToast(
    //     t("screen.feedProduct.message.max5Count"),
    //     bottomOffset()
    //   );
  };

  const onBottomSheet = () => {
    // sheetRef.current?.expand()
  };

  const onCloseBottomSheet = () => {
    // sheetRef.current?.collapse()
  };

  // const renderBackdrop = (props: any) => {
  //   return (
  //     <BottomSheetBackdrop
  //       {...props}
  //       pressBehavior={0}
  //       disappearsOnIndex={0}
  //       appearsOnIndex={1}
  //       opacity={0.7}
  //     />
  //   )
  // }

  // const setLayoutSize = (layout: LayoutRectangle) => {
  //   setCommentHeight(layout.height)
  // }

  const onPressApply = async () => {
    setClearInputValue(true);
    let originalComment = commentInEdit?.contents || "";

    // tagUserInfo 없으면 (답글달기로 댓글 생성한 경우)
    if (tagUserInfo.length == 0) {
      let nickname = originalComment.split(" ")[0]?.slice(1);
      const result = await findNickname(nickname, true);
      if (result != null) {
        const tagUserInfo = result.filter(
          (userInfo) => userInfo.nickname === nickname.toLowerCase()
        );
        if (tagUserInfo.length > 0) {
          const userInfo = createTagInfoStr(
            tagUserInfo[0].nickname,
            tagUserInfo[0].memberUuid
          );
          setTagUserInfo(nickname, userInfo);
          originalComment = replaceRenderToTagInfo(
            originalComment,
            nickname,
            userInfo
          );
          setCommentInEdit({ ...commentInEdit, contents: originalComment });
        }
      }
    }

    // nickname, uuid 같이 보내주도록 수정
    tagUserInfo.forEach((userInfo) => {
      originalComment = replaceRenderToTagInfo(
        originalComment,
        userInfo.nickname,
        userInfo.userInfo
      );
    });

    if (lastTagUserNickname !== EMPTY_STR) {
      originalComment = replaceRenderToTagInfo(
        originalComment,
        lastTagUserNickname,
        lastTagUserInfo
      );
    }

    setCommentInEdit({ ...commentInEdit, contents: originalComment });

    const check = async (): Promise<boolean> => {
      if (commentTypeCode === CommentTypeCode.ARTICLE) {
        const res = await ArticleDetailStore.getArticleDetail(
          ArticleDetailStore.articleDetail.articleId
        );
        if (
          res &&
          ArticleDetailStore.articleDetail.articleCanCommentYn === "Y"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };

    setVisibleBottomSheet(false);
    if (isEnableApply) {
      setIsEnableApply(false);

      await check().then(async (result) => {
        if (!result) {
          showBottomToast("지금은 댓글을 등록할 수 없어요.", bottomOffset());
        } else {
          if (
            authorNicknameList?.filter((author) => {
              return author.nickname === sessionUser?.nickname;
            }).length === 0
          ) {
            const author: CommentUserListItem = {
              memberUuid: sessionUser?.memberUuid || EMPTY_STR,
              nickname: sessionUser?.nickname || EMPTY_STR,
              profileUrl: sessionUser?.profileUrl || EMPTY_STR,
            };

            author.nickname !== EMPTY_STR && addAuthorNicknameList(author);
          }
          clearTagUserInfo();

          if (
            commentInEdit?.contents &&
            commentInEdit?.contents.trim()?.length > 0
          ) {
            if (tempNickname.length > 0) {
              await searchNickname(
                commentInEdit?.contents,
                tempNickname.substr(1),
                true
              );
            }

            if (commentInEdit?.commentId) {
              await updateComment();
            } else {
              await createComment();
            }
          }
          // Keyboard.dismiss()
          setIsEnableApply(true);

          const { commentTarget } = CommentStore;
          if (
            commentTarget &&
            commentTarget.commentTargetId &&
            commentTarget.commentTypeCode
          ) {
            track("complete_comment", {
              content_id: commentTarget.commentTargetId.toString(),
              content_type: commentTarget.commentTypeCode,
            });
          }
        }
      });
    }
    setClearInputValue(false);
  };

  const replaceRenderToTagInfo = (
    str: string,
    find: string,
    replace: string
  ) => {
    if (!str.includes(find)) {
      deleteTagUserInfo(find);
    }
    return str.replace(new RegExp(TAG_SEARCH_STR + find, "g"), replace);
  };

  const searchNickname = async (
    comment: string,
    nickname: string,
    isLastUpdate = false
  ): Promise<boolean> => {
    let updateResult = false;

    const result = await findNickname(nickname, isLastUpdate);
    if (result != null) {
      const tagUserInfo = result.filter(
        (userInfo) => userInfo.nickname === nickname.toLowerCase()
      );
      if (tagUserInfo.length > 0) {
        const userInfo = createTagInfoStr(
          tagUserInfo[0].nickname,
          tagUserInfo[0].memberUuid
        );
        setTagUserInfo(nickname, userInfo);
        comment = replaceRenderToTagInfo(comment, nickname, userInfo);
        setCommentInEdit({ ...commentInEdit, contents: comment });

        updateResult = true;
      }
    }

    return updateResult;
  };

  const getTagUserInfo = (nickname: string, uuid: string) => {
    const userInfo = createTagInfoStr(nickname, uuid);
    setTagUserInfo(nickname, userInfo);

    return userInfo;
  };

  const onChangeComment = async (text: string) => {
    if (!clearInputValue)
      setCommentInEdit({ ...commentInEdit, contents: text });
    
    const tagIndicator = getSearchTagIndicator();
    let originalComment = text;
    let tagIndex = getCloseTagPosition(text, textInputPos);

    const isFirstSearch = text.length === 1 && text === TAG_SEARCH_STR;

    setTempNickname(EMPTY_STR);
    if (tagIndex !== -1 || isFirstSearch) {
      let nickname = text.slice(tagIndex, textInputPos);
      setSearchTextLength(nickname.length);

      if (checkValidTagNickname(nickname.substr(1))) {
        nickname = getValidTagNickname(nickname.substr(1)) || nickname;
        const tag = tagUserInfo.filter(
          (userInfo) => userInfo.nickname === nickname
        );
        if (tag.length > 0) {
          const userInfo = tag[0].userInfo;
          setLastTagUserInfo(userInfo);
          setLastTagUserNickname(nickname);
          tagIndex = -1;
        } else {
          const searchResult = await searchNickname(
            originalComment,
            nickname,
            true
          );
          if (searchResult) {
            tagIndex = -1;
          }
        }
        setIsFindNickname(false);
        setVisibleBottomSheet(false);
      } else {
        const isFindMember = nickname !== TAG_SEARCH_STR && !isFirstSearch;

        if (isFindMember) {
          setTempNickname(nickname);
          void findNickname(nickname.substring(1));
        } else if (authorNicknameList && authorNicknameList?.length > 0) {
          setVisibleBottomSheet(true);
        }
        setIsFindNickname(isFindMember);
      }

      if (tagIndex !== -1) {
        originalComment =
          text.substring(0, tagIndex) +
          tagIndicator +
          text.substr(tagIndex + 1);
      }
    } else {
      setVisibleBottomSheet(false);
    }

    setCommentInEdit({ ...commentInEdit, contents: originalComment });
    tagUserInfo.forEach((userInfo) => {
      userInfo.nickname = userInfo.nickname.toLowerCase();
    });
  };

  const TooltipProductSearch = () => {
    return showTooltip ? (
      <div
        style={{
          position: "absolute",
          ...Arranges.center_v_h,
          bottom: 70,
          left: 12,
          zIndex: 9999,
        }}
      >
        <button
          onClick={() => {
            void closeTooltip();
          }}
        >
          <div
            style={{
              bottom: -7,
              left: 15,
              position: "absolute",
              width: 15,
              height: 17,
              // transform: [{ rotate: '45deg' }],
              transform: "45deg",
              borderRadius: 3,
              backgroundColor: "#222222",
            }}
          />
          <div
            style={{
              flexDirection: "row",
              backgroundColor: "#222222",
              ...Arranges.center_v_h,
              marginTop: -5,
              ...Spacings.padding_top_bottom_4,
              ...Spacings.border_radius_6,
              width: 210,
              height: 36,
            }}
          >
            <span
              style={{
                textAlign: "center",
                ...Texts.contents_text_5,
                ...Texts.font_weight_500,
                ...Colors.font_fff,
                lineHeight: 16,
              }}
            >
              {"가전을 검색해서 댓글에 추가할 수 있어요."}
            </span>
          </div>
        </button>
      </div>
    ) : (
      <></>
    );
  };

  const selectedProductList = useCallback(() => {
    return tempProductList.length > 0 ? (
      <div
        // onLayout={(event) => {
        //   setLayoutSize(event.nativeEvent.layout)
        // }}
        style={{
          ...styles.footer_wrap,
          paddingBottom: 10,
          flexDirection: "row",
        }}
      >
        <div>
          <span
            style={{
              color: "#222",
              fontSize: 12,
              fontWeight: 500,
              letterSpacing: -0.48,
            }}
          >
            {"선택한 제품"}
            <span
              style={{
                color: "#999",
                fontSize: 12,
                fontWeight: 400,
                letterSpacing: -0.48,
              }}
            >
              {" 최대 5개"}
            </span>
          </span>
        </div>
        <Virtuoso
          // horizontal
          // bounces={false}
          // keyExtractor={(item, index) => `comment_product_${index}`}
          data={tempProductList}
          itemContent={(index, item) => {
            const imgPath = item.product.originalFilePath;
            return (
              <div style={{ marginTop: 15, marginRight: 15 }}>
                <button
                  style={{
                    position: "absolute",
                    right: -15,
                    top: -14,
                    zIndex: 1000,
                    flex: 1,
                  }}
                  onClick={() => CommentStore.deleteProduct(index)}
                >
                  <DeleteIcon />
                </button>

                <div style={styles.home_app_item} key={`Product item ${index}`}>
                  {!item.product?.openApiFlag &&
                  item.product?.productId !== undefined &&
                  item.product?.productId > 0 ? (
                    <div style={styles.info_wrap}>
                      <FastImageWithFallback
                        style={styles.home_app_image}
                        source={{
                          uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                            item.product?.originalFilePath || ""
                          }`,
                        }}
                      />
                    </div>
                  ) : (
                    <div style={styles.info_wrap}>
                      <FastImageWithFallback
                        style={styles.home_app_image}
                        source={{
                          uri: imgPath?.includes("https:")
                            ? item.product.originalFilePath || ""
                            : `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                                item.product?.originalFilePath || ""
                              }`,
                        }}
                        fallbackImageUri={imgPath}
                      >
                        <DimImage style={styles.dim_image} />
                      </FastImageWithFallback>
                    </div>
                  )}
                </div>
              </div>
            );
          }}
        />
      </div>
    ) : (
      <></>
    );
  }, [tempProductList]);

  return (
    <>
      {disabled ? (
        <div
          // onLayout={(event) => {
          //   setLayoutSize(event.nativeEvent.layout)
          // }}
          style={styles.footer_wrap}
          className="comment_footer_wrap disabled"
        >
          <input
            disabled
            contentEditable={false}
            ref={keyboardRef}
            aria-label="Comment input area"
            className="comment_input"
            style={styles.comment_input}
            placeholder={t("지금은 댓글을 등록할 수 없어요.")}
            // placeholderTextColor={"#999"}
          />
          <TouchableWithAsyncTask
            aria-label="Apply comment button"
            style={styles.comment_enter_wrap}
            className="comment_enter_wrap"
            disabled={true}
          >
            <span style={styles.comment_enter_text}>
              {t("screen.comment.button.send")}
            </span>
          </TouchableWithAsyncTask>
        </div>
      ) : (
        <>
          {selectedProductList()}
          <div
            // onLayout={(event) => {
            //   setLayoutSize(event.nativeEvent.layout)
            // }}
            style={styles.footer_wrap}
            className="comment_footer_wrap"
          >
            {commentTypeCode === CommentTypeCode.QNA && (
              <>
                <TooltipProductSearch />
                <button
                  style={{
                    // paddingBottom: Platform.OS === 'ios' ? 12 : 8,
                    paddingBottom: 8,
                    ...Arranges.end_v,
                    ...Spacings.padding_right_12,
                  }}
                  className="comment_search_button"
                  onClick={moveToProductSearch}
                >
                  <SearchIcon />
                </button>
              </>
            )}
            <div className="comment_input_wrap">
              <input
                ref={keyboardRef}
                aria-label="Comment input area"
                style={styles.comment_input}
                className="comment_input"
                type="text"
                value={clearInputValue ? "" : commentInEdit?.contents}
                placeholder={
                  sessionUser?.memberUuid
                    ? CommentStore?.commentTarget?.commentTypeCode === "QNA"
                      ? t("screen.comment.button.qnaPlaceHolder")
                      : t("screen.comment.button.loginPlaceHolder")
                    : t("screen.comment.button.guestPlaceHolder")
                }
                onClick={() => {
                  if (sessionUser?.memberUuid === undefined)
                    MainStore.setShowJoinModal(true);
                }}
                multiple={true}
                onChange={(event) => {
                  void onChangeComment(event.target.value);
                }}
                onInput={() => {
                  setVisibleBottomSheet(false);
                }}
                onFocus={closeTooltip}
              />
            </div>

            {commentTypeCode === CommentTypeCode.QNA ? (
              <div
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <TouchableWithAsyncTask
                  aria-label="Apply comment button"
                  className="comment_enter_wrap"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    width: 40,
                    height: 40,
                    ...Spacings.border_radius_4,
                    ...((commentInEdit?.contents || "").trim()?.length > 0
                      ? { ...Colors.background_be69c3 }
                      : { ...Colors.background_c8c8c8 }),
                  }}
                  disabled={
                    (commentInEdit?.contents || "").trim()?.length === 0
                  }
                  onPress={onPressApply}
                  authCheckProps={{ enabled: true }}
                >
                  <SubmitIcon />
                </TouchableWithAsyncTask>
              </div>
            ) : (
              <TouchableWithAsyncTask
                aria-label="Apply comment button"
                className="comment_enter_wrap"
                style={
                  (commentInEdit?.contents || "").trim()?.length > 0
                    ? styles.comment_enter_wrap_focused
                    : styles.comment_enter_wrap
                }
                disabled={(commentInEdit?.contents || "").trim()?.length === 0}
                onPress={onPressApply}
                authCheckProps={{ enabled: true }}
              >
                <span
                  style={
                    (commentInEdit?.contents || "").trim()?.length > 0
                      ? styles.comment_enter_text_focused
                      : styles.comment_enter_text
                  }
                >
                  {t("screen.comment.button.send")}
                </span>
              </TouchableWithAsyncTask>
            )}
          </div>
        </>
      )}
      <CustomBottomSheet
        open={visibleBottomSheet}
        onClose={() => {
          setVisibleBottomSheet(false);
        }}
        snapHeight={
          (isFindNickname ? foundNickname : authorNicknameList || []).length *
            79 +
          32
        }
        modalContentStyles={{
          maxHeight: 378,
        }}
      >
        <div className="bottom_sheet_view">
          <button
            style={styles.bottom_sheet_close}
            className="bottom_sheet_close"
            onClick={() => setVisibleBottomSheet(false)}
          >
            <CloseIcon
              aria-label="Close comment user list"
              className="close_comment_user_list"
            />
          </button>
        </div>
        <Virtuoso
          aria-label="User list"
          className="comment_user_list"
          // showsHorizontalScrollIndicator={false}
          data={isFindNickname ? foundNickname : authorNicknameList || []}
          // keyboardShouldPersistTaps={'always'}
          itemContent={(index, item) => (
            <div className="container_user_item">
              <button
                onClick={() => {
                  const pendingComment = commentInEdit?.contents || "";
                  const prevComment = pendingComment.substring(
                    0,
                    textInputIndex
                  );
                  const tagUserInfoComment = getTagUserInfo(
                    item.nickname,
                    item.memberUuid
                  );
                  const nextComment = pendingComment.substring(
                    textInputIndex + searchTextLength
                  );
                  const spaceStr =
                    nextComment.substring(0, 1) === SPACE_STR ? "" : SPACE_STR;

                  setCommentInEdit({
                    ...commentInEdit,
                    // contents:
                    //   prevComment + tagUserInfoComment + spaceStr + nextComment,
                    contents: `@${item.nickname} `,
                  });
                  setVisibleBottomSheet(false);
                }}
                aria-label="Comment user"
                className="comment_user_item"
              >
                <FastImageWithFallback
                  aria-label="Profile Image"
                  className="comment_user_image"
                  source={{
                    uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                      item.profileUrl || ""
                    }`,
                  }}
                  style={styles.image}
                />
                <span
                  style={styles.bottom_sheet_item_text}
                  className="bottom_sheet_item_text"
                >
                  {TAG_SEARCH_STR + item.nickname}
                </span>
              </button>
            </div>
          )}
          atBottomThreshold={0.8}
        />
      </CustomBottomSheet>
    </>
  );
});

export default CommentFooter;

const styles: Styles = {
  comments_wrap: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_left_top_bottom_20,
    paddingBottom: 0,
    paddingTop: 0,
  },
  container: {
    backgroundColor: "#f0dff2",
  },
  scroll: {},
  contents: {
    paddingStart: 23,
    paddingEnd: 20,
  },
  screen_wrap: {
    ...FlexBoxs.flex_1,
    ...Colors.background_fff,
  },
  image: {
    width: 42,
    height: 42,
    ...Spacings.border_radius_100,
  },
  header: {
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_bottom_11,
    ...Colors.background_fff,
  },
  left: {
    width: 78,
    ...FlexBoxs.horizon,
  },
  center: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  right: {
    width: 78,
    ...FlexBoxs.horizon,
    ...Arranges.end_v,
  },
  icons: {
    ...Icons.icon_32,
    ...Spacings.margin_right_6,
  },
  title_txt: {
    ...Texts.header_text_0,
  },
  count: {
    ...Texts.header_text_1,
  },
  empty_wrap: {
    ...FlexBoxs.flex_1,
    ...Arranges.center_v_h,
  },
  empty_text: {
    ...Texts.contents_text_0,
  },
  footer_wrap: {
    position: "fixed",
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_12,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Colors.background_f5f5f5,
    ...Colors.border_color_f0dff2,
    // paddingBottom: Platform.OS === 'ios' ? 19 : 11,
    paddingBottom: 11,
    zIndex: 1000,
  },
  comment_input: {
    ...Colors.background_fff,
    ...Spacings.border_radius_4,
    ...Spacings.margin_right_8,
    ...Spacings.padding_right_left_12,
    ...FlexBoxs.flex_1,
    ...Texts.button_text_4,
    // paddingTop: Platform.OS === 'ios' ? 12 : 8,
    // paddingBottom: Platform.OS === 'ios' ? 12 : 8,
    paddingTop: 8,
    paddingBottom: 8,
    maxHeight: 100,
  },
  comment_enter_wrap: {
    ...Arranges.center_v_h,
    ...Colors.background_c8c8c8,
    ...Spacings.border_radius_4,
    width: 72,
  },
  comment_enter_wrap_focused: {
    ...Arranges.center_v_h,
    ...Colors.background_be69c3,
    ...Spacings.border_radius_4,
    width: 72,
  },
  comment_enter_text: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  comment_enter_text_focused: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  comment_input_focused: {
    ...Colors.border_color_6c2773,
  },
  bottom_sheet_view: {
    ...Spacings.padding_h_default,
    ...Spacings.padding_top_16,
  },
  bottom_sheet_scroll_view: {
    ...Spacings.padding_h_default,
    ...Spacings.padding_top_8,
  },
  bottom_sheet_item: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.padding_bottom_10,
    ...Spacings.margin_bottom_10,
  },
  bottom_sheet_item_text: {
    ...Spacings.margin_left_9,
    ...Texts.contents_text_3,
  },
  bottom_sheet_close: {
    ...Arranges.end_h,
  },
  bottom_sheet_list: {
    height: "100%",
  },
  title_border: {
    android: {
      elevation: 6,
    },
    // ...Platform.select({
    //   ios: {
    //     shadowColor: '#000000',
    //     shadowOffset: { width: 1, height: 3 },
    //     shadowOpacity: 0.15,
    //   },
    //   android: {
    //     elevation: 6,
    //   },
    // }),
  },
  divider: {
    borderBottomWidth: 1,
    borderColor: "#2222221a",
  },
  home_app_item: {
    ...FlexBoxs.horizon,
  },
  home_app_image: {
    width: 70,
    height: 70,
    ...Colors.background_fff,
    ...Spacings.border_radius_6,
    borderWidth: 1,
    borderColor: "rgba(34, 34, 34, 0.1)",
  },
  info_wrap: {
    ...FlexBoxs.horizon,
  },
  dim_image: {
    ...Spacings.border_radius_6,
  },
};
