import React, { useEffect, useState } from "react";
import "./BookmarkQna.scss";

import { observer } from "mobx-react";
import { BookmarkFeedItem } from "../../../api/bookmark/model";
import BookmarkStore from "../../../store/BookmarkStore";
import { FeedTypeCode } from "../../../constants/Feed";

import { ReactComponent as CheckOffIcon } from "../../../assets/icons/checkbox_big_off_transparant.svg";
import { ReactComponent as CheckOnIcon } from "../../../assets/icons/checkbox_big_on.svg";

import { getQnaDetail } from "../../../api/qna/api";
import CommonStore from "../../../store/CommonStore";
import { useTranslation } from "react-i18next";
import { sendActHistory } from "../../../api/data/api";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Styles } from "../../../assets/types/Style";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const BookmarkQna = observer(
  ({ data, listIndex }: { data: BookmarkFeedItem; listIndex: number }) => {
    const {
      bookmarkFeeds,
      selectedBookmarkFeeds,
      isTotalChecked,
      isEdit,
      setIsTotalChecked,
      selectBookmarkFeed,
      currentTab,
      updateWithPoppedPosts,
      updateWithNewPost,
    } = BookmarkStore;

    const { setLoading } = CommonStore;
    const { t } = useTranslation();
    const widthApp = CommonStore.widthApp;

    const [isSelected, setIsSelected] = useState(false);

    const postTotalChecked = isTotalChecked.get(currentTab);
    const postEdit = isEdit.get(currentTab);
    const selectedPosts = selectedBookmarkFeeds.get(currentTab);

    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const toggleIsSelected = () => {
      setIsSelected(!isSelected);
    };

    const handleSelect = (bookmarkTargetId: number) => () => {
      toggleIsSelected();
      selectBookmarkFeed(currentTab, bookmarkTargetId);

      const lengthOfBookmarkFeeds: number =
        bookmarkFeeds.get(currentTab)?.length || 0;
      const lengthOfSelectedBookmarkFeeds: number =
        selectedBookmarkFeeds.get(currentTab)?.size || 0;
      setIsTotalChecked(
        currentTab,
        lengthOfBookmarkFeeds === lengthOfSelectedBookmarkFeeds ? true : false
      );
    };

    const callActHistory = (
      sectionCode: string,
      feedId: number,
      index: number
    ) => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.QNA_READ,
        actHistoryTargetId: feedId.toString(),
        actPageCode: ActPageCode.BOOKMARK,
        actSectionCode: sectionCode,
        attr1: index.toString(),
      };
      void sendActHistory(actHistory);
    };

    const moveToPostDetail = (feedId: number) => {
      if (feedId !== undefined && feedId >= 0) {
        callActHistory(ActSectionCode.QNA, feedId, listIndex);
      }

      goTo(`/Qna/${feedId}`, {
        state: {
          feedId: feedId,
          index: listIndex,
        },
      });
    };

    const normalGrid = (item: BookmarkFeedItem) => {
      return (
        <div className="qna_item_container" aria-label="Qna item">
          {isEdit.get(FeedTypeCode.QNA) ? (
            <div className="qna_item">
              <FastImageWithFallback
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
                }}
                className="qna_image"
                style={{
                  width: `calc(${widthApp / 2}px - 20px)`,
                  height: `calc(${widthApp / 2}px - 20px)`,
                }}
                key={item.feedId}
              />
              <div
                className={
                  item && item.thumbnailFilePath
                    ? "qna_wrap"
                    : item.backColorIndex
                    ? "qna_purple_wrap"
                    : "qna_light_purple_wrap"
                }
              >
                <button
                  className="check_btn"
                  onClick={handleSelect(item.feedId)}
                  aria-label="Fill in or out checkbox"
                >
                  {isSelected ? <CheckOnIcon /> : <CheckOffIcon />}
                </button>
                <div className="qna_top">
                  <p
                    className="qna_top_text"
                    // numberOfLines={1}
                  >
                    {item.nickname || ""}
                  </p>
                  <div className="title_wrap">
                    <p
                      className="title_text"
                      // numberOfLines={2}
                    >
                      {item.feedTitle || ""}
                    </p>
                  </div>
                </div>
                <div className="qna_bottom">
                  <p className="qna_bottom_text">
                    {t("screen.bookmark.label.comment")} {item.commentCount}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <button
              key={data.feedId}
              onClick={() => moveToPostDetail(item.feedId)}
              aria-label="Click QnA item"
              className="qna_item"
            >
              <FastImageWithFallback
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
                }}
                className="qna_image"
                key={item.feedId}
                style={{
                  width: `calc(${widthApp / 2}px - 20px)`,
                  height: `calc(${widthApp / 2}px - 20px)`,
                }}
              />
              <div
                className={
                  item && item.thumbnailFilePath
                    ? "qna_wrap"
                    : item.backColorIndex
                    ? "qna_purple_wrap"
                    : "qna_light_purple_wrap"
                }
              >
                <div className="qna_top">
                  <p
                    className="qna_top_text"
                    // numberOfLines={1}
                  >
                    {item.nickname || ""}
                  </p>
                  <div className="title_wrap">
                    <p
                      className="title_text"
                      // numberOfLines={2}
                    >
                      {item.feedTitle || ""}
                    </p>
                  </div>
                </div>
                <div className="qna_bottom">
                  <p className="qna_bottom_text">
                    {t("screen.bookmark.label.comment")} {item.commentCount}
                  </p>
                </div>
              </div>
            </button>
          )}
        </div>
      );
    };

    useEffect(() => {
      setIsSelected(false);
    }, [postEdit]);

    useEffect(() => {
      if (isTotalChecked.get(currentTab)) {
        setIsSelected(true);
      }
    }, [postTotalChecked, isTotalChecked, currentTab]);

    useEffect(() => {
      const selectedBookmarkPosts = selectedBookmarkFeeds.get(currentTab);
      selectedBookmarkPosts !== undefined &&
        setIsSelected(selectedBookmarkPosts.has(data.feedId));
    }, [
      selectedBookmarkFeeds,
      selectedPosts,
      postTotalChecked,
      data.feedId,
      currentTab,
    ]);

    return <div
      style={{
        width: `calc(${widthApp / 2}px - 20px)`,
        height: `calc(${widthApp / 2}px - 20px)`,
      }}
      id="bookmark_qna_grid"
    >{normalGrid(data)}</div>;
  }
);

export default BookmarkQna;
