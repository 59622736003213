import React, { useRef, useState } from "react";
import {
  Arranges,
  FlexBoxs,
  Icons,
  Texts,
  Colors,
  Spacings,
} from "../../assets/styles";
// import { Menu, MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";
// import { Platform, StyleSheet, View } from "react-native";
import { ReactComponent as MoreIcon } from "../../assets/icons/more.svg";
import { ReactComponent as MoreWhiteIcon } from "../../assets/icons/more_white.svg";
import { useTranslation } from "react-i18next";
// import { TouchableOpacity } from "react-native-gesture-handler";
// import { navigate } from "../../navigation/NavigationFunction";
import { getSessionUserFromStorage } from "../../service/Session.service";
import { contents_text_3 } from "../../assets/styles/texts";
import { Styles } from "../../assets/types/Style";
import DropdownMenuModal from "./DropdownMenuModal";
import MainStore from "../../store/MainStore";
import CommonStore from "../../store/CommonStore";
import { useLockBodyScroll } from "react-use";

export type OptionType =
  | "DELETE"
  | "MODIFY"
  | "REPORT"
  | "BEST_PICK"
  | "TAG"
  | "BOOKMARK"
  | "COUPON"
  | "ALARM"
  | "CANCEL";
export type BackColorType = "LIGHT" | "DARK";

interface ContentsPopupMenuProps {
  backColor?: BackColorType;
  optionTypesToShow: OptionType[];
  onMenuClick: (type: OptionType) => void;
  needAuth?: boolean;
}

export const ContentsPopupMenu = ({
  backColor = "LIGHT",
  optionTypesToShow,
  onMenuClick,
  needAuth = false,
}: ContentsPopupMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const targetRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useLockBodyScroll(isOpen);

  const handleOpen = () => {
    if (needAuth) {
      getSessionUserFromStorage()
        .then((res) => {
          if (res?.nickname) {
            setIsOpen(true);
          } else {
            MainStore.setShowJoinModal(true);
          }
        })
        .catch(() => {
          MainStore.setShowJoinModal(true);
        });
    } else {
      setIsOpen(true);
    }
  };

  const handleHide = () => {
    setIsOpen(false);
  };

  const renderOptions = (index: number, option: any) => {
    const drawDropLine = index > 0 && index < optionTypesToShow.length;

    return (
      <div
        className={option}
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          borderTop: drawDropLine ? "1px solid #f9f3fa" : "none",
        }}
        key={`menu-item-${index}`}
        aria-label={`popup menu for ${option}`}
        onClick={() => {
          onMenuClick(option as OptionType);
          handleHide();
        }}
      >
        {/* {drawDropLine && <div style={styles.drop_line} />} */}
        <div style={styles.drop_text}>
          {t(`common.header.${option.toLowerCase()}`)}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        ref={targetRef}
        style={{
          height: "100%",
        }}
        onClick={handleOpen}
      >
        <div
          style={{
            marginLeft: 12,
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
          aria-label="popup menu opener"
        >
          {backColor === "DARK" ? <MoreWhiteIcon /> : <MoreIcon />}
        </div>
      </div>
      <DropdownMenuModal
        isOpen={isOpen}
        onRequestClose={handleHide}
        data={optionTypesToShow}
        renderItem={renderOptions}
        targetRef={targetRef}
      />
    </>

    // <Menu ref={ref} onBackdropPress={handleHide} onClose={handleHide} onSelect={handleHide}>
    //   <TouchableOpacity onPress={handleOpen}>
    //     <View style={styles.more_icon} accessibilityLabel="popup menu opener">
    //       {backColor === "DARK" ? <MoreWhiteIcon /> : <MoreIcon />}
    //     </View>
    //   </TouchableOpacity>
    //   <MenuTrigger />
    //   <MenuOptions
    //     customStyles={{
    //       optionsContainer: {
    //         ...styles.drop_box_shadow,
    //         marginTop: 10,
    //       },
    //     }}
    //     optionsContainerStyle={[styles.drop_btn]}
    //   >
    //     {optionTypesToShow.map((option, index) => {
    //       const drawDropLine = index > 0 && index < optionTypesToShow.length;

    //       return (
    //         <View key={`menu-item-${index}`} accessibilityLabel={`popup menu for ${option}`}>
    //           {drawDropLine && <View style={styles.drop_line} />}
    //           <MenuOption
    //             style={styles.drop_text}
    //             onSelect={() => onMenuClick(option)}
    //             text={t(`common.header.${option.toLowerCase()}`)}
    //           />
    //         </View>
    //       );
    //     })}
    //   </MenuOptions>
    // </Menu>
  );
};

const styles: Styles = {
  drop_btn: {
    ...Colors.background_fff,
    ...Icons.icon_border_default,
    ...Colors.border_color_f9f3fa,
    paddingHorizontal: 10,
    paddingVertical: 10,
    width: 190,
    borderRadius: 4,
  },
  multi_drop_btn: {
    ...FlexBoxs.flex_1,
    ...Arranges.center_v_h,
    height: 28,
  },
  drop_text: {
    ...Texts.contents_text_3,
    paddingVertical: 10,
  },
  drop_line: {
    ...Colors.background_f9f3fa,
    height: 1,
    marginVertical: 2,
  },
  drop_box_shadow: {
    backgroundColor: "#FFF",
    shadowColor: "#000",
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowRadius: 3,
    shadowOpacity: 0.2,
    // ...Platform.select({
    //   android: {
    //     elevation: 5,
    //   },
    // }),
  },
  more_icon: {
    ...Spacings.margin_left_12,
  },
};
