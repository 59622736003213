import {
  getMagazineDetail,
  getPopularFeedDetailList,
  getPopularQnaList,
  getPopularRanking,
  getPopularTagList,
  getRecommendedFeedList,
} from "../api/feed/api";
import { MainItem, PopularRanking, Post } from "../api/feed/model";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { getMemberInfo } from "../api/member/api";
import { MemberInfoResponse, MemberTagItem } from "../api/member/model";
import { getBannerList } from "../api/banner/api";
import { BannerTypeCodes } from "../constants/Banner.enum";
import { BannerInfo } from "../api/banner/model";
import { parseQueryParam } from "../utils/queryParam";
import { MainFeedTypeCode, UserActTypeCode } from "../constants/FeedType.enum";
// import { CommonReport } from "./ReportStore";
// import { saveReport } from "../api/report/api";
import CommonStore from "./CommonStore";
import { t } from "i18next";
import { ShowReportModalType } from "../constants/Common.enum";
// import Config from "react-native-config";
import { trackUserProperties } from "../api/track/api";
import AuthStore from "./AuthStore";
import { BrandLineUpSortType } from "../api/brand/model";
import { getBrandCommunityLineUp, getPopularArticle } from "../api/brand/api";
import { getTodayPopularProduct } from "../api/mall/api";
import { MAIN_TAB } from "../constants/main";
// import RemoteConfigStore from "./RemoteConfigStore";

const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
export const INIT_NUM_OF_POSTS = 4;
export const INIT_LONG_NUM_OF_POSTS = 10;
const INIT_CURRENT_LIST_NUMBER = 0;
const INIT_FEED_LIST_NUMBER = 2;

class MainStore {
  constructor() {
    makeObservable(this);
  }

  @observable _loading = true;

  @observable _isLoadingMore = false;

  @observable _isRefreshing = false;

  @observable _canLoadMore = true;

  @observable _mainList: MainItem[] = [];

  @observable _popularPostsList: Post[] = [];

  @observable _currentNumberOfList = INIT_CURRENT_LIST_NUMBER;

  @observable _feedNumberOfList = INIT_FEED_LIST_NUMBER;

  @observable _showModal: ShowReportModalType = ShowReportModalType.NONE;

  @observable _showJoinModal = false;

  @observable _afterReportHandler: (() => void) | null = null;

  @observable _showBottomSheet = false;

  @observable _showTextBannerModal = false;

  @observable _popupBannerList: BannerInfo[] = [];

  @observable _textBanner: BannerInfo | null | undefined = null;

  @observable _initLoaded = false;

  @observable profileToolTipFlag = false;

  @observable _postMap = new Map<string, number>();

  @observable _loggingCompleteSet = new Set<string>();

  @observable _showMemberJoinVoltSavePop = false;

  @observable _todayProductList: MainItem | null = null;

  @observable _mainTabScroll = new Map<string, number>();

  @observable _currentScrollY = 0;

  @observable _confirmMainPopupList = false;

  @observable _remoteConfigActivate = false;

  @computed get remoteConfigActivate() {
    return this._remoteConfigActivate;
  }

  @computed get confirmMainPopupList() {
    return this._confirmMainPopupList;
  }

  @computed get currentScrollY() {
    return this._currentScrollY;
  }

  @computed get mainTabScroll() {
    return this._mainTabScroll;
  }

  @computed get showMemberJoinVoltSavePop() {
    return this._showMemberJoinVoltSavePop;
  }

  @computed get loading() {
    return this._loading;
  }

  @computed get showModal() {
    return this._showModal;
  }

  @computed get showJoinModal() {
    return this._showJoinModal;
  }

  @computed get AfterReportHandlerMainStore() {
    return this._afterReportHandler;
  }

  @computed get isLoadingMore() {
    return this._isLoadingMore;
  }

  @computed get isRefreshing() {
    return this._isRefreshing;
  }

  @computed get canLoadMore() {
    return this._canLoadMore;
  }

  @computed get mainList() {
    return this._mainList;
  }

  @computed get currentNumberOfList() {
    return this._currentNumberOfList;
  }

  @computed get feedNumberOfList() {
    return this._feedNumberOfList;
  }

  @computed get showBottomSheet() {
    return this._showBottomSheet;
  }

  @computed get popupBannerList() {
    return this._popupBannerList;
  }

  @computed get textBanner() {
    return this._textBanner;
  }

  @computed get showTextBannerModal() {
    return this._showTextBannerModal;
  }

  @computed get popularPostsList() {
    return this._popularPostsList;
  }

  @computed get initLoaded() {
    return this._initLoaded;
  }

  @computed get postMap() {
    return this._postMap;
  }

  @computed get loggingCompleteSet() {
    return this._loggingCompleteSet;
  }

  @computed get todayProductList() {
    return this._todayProductList;
  }

  @action setRemoteConfigActivate = (param: boolean) => {
    this._remoteConfigActivate = param;
  };

  @action setConfirmMainPopupList = (param: boolean) => {
    this._confirmMainPopupList = param;
  };

  @action setCurrentScrollY = (y: number) => {
    this._currentScrollY = y;
  };

  @action setMainTabScroll = (param: string) => {
    const key = param === "/" ? "/main" : param;
    if (
      key === "/main" ||
      key === "/posts" ||
      key === "/qna" ||
      key === "/mission"
    ) {
      this._mainTabScroll.set(key, this._currentScrollY);
      this.setCurrentScrollY(0);
    }
  };

  @action setTodayProductList = (x: MainItem) => {
    this._todayProductList = x;
  };

  @action setShowMemberJoinVoltSavePop = (x: boolean) => {
    this._showMemberJoinVoltSavePop = x;
  };

  @action setLoading = (loading: boolean) => {
    this._loading = loading;
  };

  @action setInitLoaded = (initLoad: boolean) => {
    this._initLoaded = initLoad;
  };

  @action setIsLoadingMore = (isLoadingMore: boolean) => {
    this._isLoadingMore = isLoadingMore;
  };

  @action setIsRefreshing = (isRefreshing: boolean) => {
    this._isRefreshing = isRefreshing;
  };

  @action setShowModal = (showModal: ShowReportModalType) => {
    this._showModal = showModal;
  };

  @action setShowJoinModal = (showJoinModal: boolean) => {
    if (showJoinModal) {
      document.body.classList.add("join-modal-open");
    } else {
      document.body.classList.remove("join-modal-open");
    }
    this._showJoinModal = showJoinModal;
  };

  @action setCanLoadMore = (canLoadMore: boolean) => {
    this._canLoadMore = canLoadMore;
  };

  @action setMainList = (list: MainItem[]) => {
    this._mainList = list;
  };

  @action setFeedNumberOfList = (number: number) => {
    this._feedNumberOfList = number;
  };

  @action setCurrentNumberOfList = (currentNumberOfList: number) => {
    this._currentNumberOfList = currentNumberOfList;
  };

  @action setShowBottomSheet = (show: boolean) => {
    this._showBottomSheet = show;
  };

  @action setPopupBannerList = (bannerList: BannerInfo[]) => {
    this._popupBannerList = bannerList;
  };

  @action setTextBanner = (banner: BannerInfo) => {
    this._textBanner = banner;
  };

  @action setShowTextBannerModal = (show: boolean) => {
    this._showTextBannerModal = show;
  };

  @action setPopularPostsList = (postsList: Post[]) => {
    this._popularPostsList = postsList;
  };

  @action popPostFromMain = (postIndex: number, mainListIndex: number) => {
    this._mainList[mainListIndex].data.splice(postIndex, 1);
  };

  @action popQnaFromMain = (qnaIndex: number, mainListIndex: number) => {
    const updateList = this._mainList[mainListIndex].data.splice(qnaIndex, 1);
    const mainElem = this._mainList[mainListIndex];
    this.updateListByIndex(mainListIndex, { ...mainElem, updateList });
  };

  @action updateListByIndex = (mainListIndex: number, list: any) => {
    this._mainList[mainListIndex] = list;
  };

  @action setMainPost = (
    post: Post,
    mainListIndex: number,
    postIndex: number
  ) => {
    const formerList = this._mainList[mainListIndex].data;
    formerList[postIndex] = post;
    this.updateListByIndex(mainListIndex, {
      ...this._mainList[mainListIndex],
      data: formerList,
    });
  };

  @action setMainMagazine = (
    magazine: any,
    mainListIndex: number,
    postIndex: number
  ) => {
    const formerList = this._mainList[mainListIndex].data;
    formerList[postIndex] = magazine;
    this.updateListByIndex(mainListIndex, {
      sort: MainFeedTypeCode.MAGAZINE,
      data: formerList,
    });
  };

  @action updatePostByReport = (
    mainListIndex: number,
    postIndex: number,
    callState: string | undefined
  ) => {
    const formerList = this._mainList[mainListIndex].data;
    formerList.splice(postIndex, 1);
    this.updateListByIndex(mainListIndex, {
      sort: MainFeedTypeCode.POPULAR_POST,
      data: formerList,
    });
  };

  @action popFromMainList = (
    feedId: number,
    mainFeedTypeCodes: MainFeedTypeCode[]
  ) => {
    this._mainList.forEach((mainItem: MainItem, index: number) => {
      mainItem.sort &&
        mainFeedTypeCodes.includes(mainItem.sort) &&
        mainItem.data.forEach((item: any, postIndex: number) => {
          if (item.feedId === feedId) {
            const updateList = this._mainList[index].data.splice(postIndex, 1);
            const mainElem = this._mainList[index];
            this.updateListByIndex(index, { ...mainElem, updateList });
            if (mainItem.sort === MainFeedTypeCode.POPULAR_POST) {
              this._popularPostsList.splice(postIndex, 1);
            }
          }
        });
    });
  };

  @action popFromMainListwithMemberUuid = (memberUuid: string) => {
    this._mainList.forEach((elem: any, index: number) => {
      if (
        elem.sort === MainFeedTypeCode.POPULAR_POST ||
        elem.sort === MainFeedTypeCode.MAIN_POST
      ) {
        const updatedMainElemData = elem.data.filter(
          (post: Post) => post.createMemberUuid !== memberUuid
        );
        const mainElem = this._mainList[index];
        this.updateListByIndex(index, {
          ...mainElem,
          ...{ data: updatedMainElemData },
        });
      }
    });
  };

  @action popFromMainPopularListwithMemberUuid = (memberUuid: string) => {
    this._popularPostsList = this._popularPostsList.filter(
      (feed) => feed.createMemberUuid !== memberUuid
    );
  };

  @action updateMainList = (
    actType: UserActTypeCode,
    postIndex: number,
    mainListIndex: number,
    // feedId: number,
    extraData?: any
  ) => {
    let updatedPost: Post;
    const targetFeed = this._mainList[mainListIndex].data[postIndex];

    switch (actType) {
      case UserActTypeCode.CANCEL_LIKE:
        if (targetFeed.likeYn === "Y") {
          const formerCount: number = targetFeed.likeCount;
          updatedPost = {
            ...targetFeed,
            likeYn: "N",
            likeCount: formerCount - 1,
          };
          this.setMainPost(updatedPost, mainListIndex, postIndex);
        }
        break;
      case UserActTypeCode.SAVE_LIKE:
        if (targetFeed.likeYn === "N") {
          const formerCount: number = targetFeed.likeCount;
          updatedPost = {
            ...targetFeed,
            likeYn: "Y",
            likeCount: formerCount + 1,
          };
          this.setMainPost(updatedPost, mainListIndex, postIndex);
        }
        break;
      case UserActTypeCode.CANCEL_BOOKMARK:
        if (targetFeed.bookmarkYn === "Y") {
          updatedPost = {
            ...targetFeed,
            bookmarkYn: "N",
          };
          this.setMainPost(updatedPost, mainListIndex, postIndex);
        }
        break;
      case UserActTypeCode.SAVE_BOOKMARK:
        if (targetFeed.bookmarkYn === "N") {
          updatedPost = {
            ...targetFeed,
            bookmarkYn: "Y",
          };
          this.setMainPost(updatedPost, mainListIndex, postIndex);
        }
        break;
      case UserActTypeCode.UPDATE_COMMENT:
        if (extraData != undefined) {
          updatedPost = {
            ...targetFeed,
            commentCount: extraData.commentCount,
          };
          this.setMainPost(updatedPost, mainListIndex, postIndex);
        }
        break;
      case UserActTypeCode.MODIFY:
        if (extraData != undefined) {
          updatedPost = extraData;
          this.setMainPost(updatedPost, mainListIndex, postIndex);
        }
        break;
    }
  };

  @action updateMainMagazines = (
    order: string,
    postIndex: number,
    mainListIndex: number,
    likeCount?: any
  ) => {
    let updatedMagazine: any;

    switch (order) {
      case UserActTypeCode.CANCEL_LIKE:
        if (this._mainList[mainListIndex].data[postIndex].likeYn === "Y") {
          const formerCount: number =
            this._mainList[mainListIndex].data[postIndex].likeCount;
          updatedMagazine = {
            ...this._mainList[mainListIndex].data[postIndex],
            likeYn: "N",
            likeCount: formerCount - 1,
          };
          this.setMainMagazine(updatedMagazine, mainListIndex, postIndex);
        }
        break;
      case UserActTypeCode.SAVE_LIKE:
        if (this._mainList[mainListIndex].data[postIndex].likeYn === "N") {
          const formerCount: number =
            this._mainList[mainListIndex].data[postIndex].likeCount;
          updatedMagazine = {
            ...this._mainList[mainListIndex].data[postIndex],
            likeYn: "Y",
            likeCount: formerCount + 1,
          };
          this.setMainMagazine(updatedMagazine, mainListIndex, postIndex);
        }
        break;
      case UserActTypeCode.CANCEL_BOOKMARK:
        if (this._mainList[mainListIndex].data[postIndex].bookmarkYn === "Y") {
          updatedMagazine = {
            ...this._mainList[mainListIndex].data[postIndex],
            bookmarkYn: "N",
          };
          this.setMainMagazine(updatedMagazine, mainListIndex, postIndex);
        }
        break;
      case UserActTypeCode.SAVE_BOOKMARK:
        if (this._mainList[mainListIndex].data[postIndex].bookmarkYn === "N") {
          updatedMagazine = {
            ...this._mainList[mainListIndex].data[postIndex],
            bookmarkYn: "Y",
          };
          this.setMainMagazine(updatedMagazine, mainListIndex, postIndex);
        }
        break;
      case UserActTypeCode.MODIFY:
        if (likeCount) {
          updatedMagazine = {
            ...this._mainList[mainListIndex].data[postIndex],
            ...likeCount,
          };
          this.setMainMagazine(updatedMagazine, mainListIndex, postIndex);
        }
        break;
    }
  };

  /*
  NOTE: 메인 리스트 종류 및 순서
  1. 메인 배너
  2. 마이 & 퀵 링크 (예정)
  3. 인기 포스트 리스트 - 가로 배치
  4. 추천 포스트 리스트 - 세로 배치 4개 (0 page)
  5. 매거진 - 랜덤 1개 배치
  ________________________________ (해당 리스트까지 PreRenderMainStore 에서 호출)
  6. 오늘의 220 몰 제품
  7. 오늘의 브랜드
  8. 추천 포스트 리스트 - 세로 배치 4개 (1 page)
  9. 오늘의 인기 게시글
  10. 추천 포스트 리스트 - 세로 배치 4개 (2 page)
  11. 추천 포스트 리스트 - 세로 배치 4개 (3 page)
  12. 인기 QnA 목록 - 가로 배치 10개
  13. 추천 포스트 리스트 - 세로 배치 4개 (4 page)
  ________________________________ (해당 리스트까지 getSecondMain 에서 호출)
  14. 인기 랭킹 목록 - 가로 배치 12개
  15. 추천 포스트 리스트 - 세로 배치 8개
  ________________________________ (해당 리스트까지 getThirdMain에서 호출)
  이후. 추천 포스트 리스트 - 세로 배치 10개 무한 반복
  ________________________________ (해당 리스트까지 getFourthMain에서 호출)
  */

  @action refreshPopularTag = async () => {
    const index = this._mainList.findIndex(
      ({
        sort,
        data,
      }: {
        sort: MainFeedTypeCode | undefined | null;
        data: any;
      }) => sort === MainFeedTypeCode.POPULAR_TAG
    );

    if (index === -1) {
      return;
    }

    const popularTagListRes = await getPopularTagList(10, 0);

    if (popularTagListRes?.successOrNot === "Y") {
      this._mainList[index] = {
        sort: MainFeedTypeCode.POPULAR_TAG,
        data: popularTagListRes.data ?? [],
      };
    } else {
      CommonStore.setToastOption({
        show: true,
        message1: t("common.message.networkError"),
        // bottomOffset: 30,
      });
    }
  };

  postPush(mainItems: MainItem[], list: Post[], code: MainFeedTypeCode) {
    list.forEach((item, index) => {
      const mainItem = {} as MainItem;
      mainItem.sort = code;
      mainItem.data = [item];
      mainItems.push(mainItem);

      if (
        !this._postMap.has(String(item.feedId)) &&
        (code === MainFeedTypeCode.MAIN_POST ||
          code === MainFeedTypeCode.MAIN_POST_SINGLE)
      ) {
        const orderNum =
          this._postMap.size == 0 ? 0 : Math.max(...this._postMap.values()) + 1;
        this._postMap.set(String(item.feedId), orderNum);
      }
    });
  }

  @action getFirstMain = async () => {
    this._initLoaded = true;
    this.setIsLoadingMore(true);
    this.clearMain();

    const mainItems: MainItem[] = [];

    const [popularPost, recommendPost, magazine, tagList] = await Promise.all([
      getPopularFeedDetailList(10, 0),
      getRecommendedFeedList(INIT_NUM_OF_POSTS, 0),
      getMagazineDetail(1, 0),
      getPopularTagList(10, 0),
    ]);

    this._mainList = [];

    if (popularPost?.successOrNot == "Y") {
      const mainItem = {} as MainItem;
      const popularPosts = popularPost.data?.feedFindVOList as Post[];
      mainItem.sort = MainFeedTypeCode.POPULAR_POST;
      mainItem.data = popularPosts ?? [];

      this.postPush(
        mainItems,
        popularPost.data?.feedFindVOList as Post[],
        MainFeedTypeCode.POPULAR_POST
      );
      (popularPost.data?.feedFindVOList as Post[]).forEach((item, index) => {
        const mainItem = {} as MainItem;
        mainItem.sort = MainFeedTypeCode.POPULAR_POST;
        mainItem.data = [item];
        mainItems.push(mainItem);
      });
    }

    if (recommendPost?.successOrNot == "Y") {
      this.postPush(
        mainItems,
        recommendPost.data?.feedFindVOList as Post[],
        MainFeedTypeCode.MAIN_POST
      );
      (recommendPost.data?.feedFindVOList as Post[]).forEach((item, index) => {
        const mainItem = {} as MainItem;
        mainItem.sort = MainFeedTypeCode.MAIN_POST;
        mainItem.data = [item];
        mainItems.push(mainItem);
      });
    }

    if (magazine?.successOrNot == "Y") {
      const mainItem = {} as MainItem;
      mainItem.sort = MainFeedTypeCode.MAGAZINE;
      mainItem.data = magazine.data?.feedFindVOList ?? [];
      mainItems.push(mainItem);
    }

    if (tagList?.successOrNot == "Y") {
      const mainItem = {} as MainItem;
      mainItem.sort = MainFeedTypeCode.POPULAR_TAG;
      mainItem.data = tagList.data ?? [];
      mainItems.push(mainItem);
    }

    this.setMainList(mainItems);
    this.setCurrentNumberOfList(this._currentNumberOfList + 1);
    this.setIsLoadingMore(false);
    this.setIsRefreshing(false);
    this._initLoaded = false;
  };

  @action getSecondMain = async () => {
    const memberUuid = AuthStore.sessionUser?.memberUuid
      ? AuthStore.sessionUser?.memberUuid
      : AuthStore.tempMember?.tempMemberUUID
      ? AuthStore.tempMember?.tempMemberUUID
      : "";

    this.setIsLoadingMore(true);

    const mainItems: MainItem[] = [];
    let isNetworkErrorExist = false;

    const [
      todayBrand,
      recommendPostFirst,
      todayPopularArticle,
      recommendPostSecond,
      todayMallProduct,
      recommendPostThird,
      popularQna,
      recommendPostLast,
    ] = await Promise.all([
      // RemoteConfigStore.show_brand_page === "true"
      //   ? getBrandCommunityLineUp(memberUuid, BrandLineUpSortType.POPULAR, 5, 0)
      //   : null,
      null,
      getRecommendedFeedList(INIT_NUM_OF_POSTS, 1),
      // RemoteConfigStore.show_brand_page === "true"
      //   ? getPopularArticle(memberUuid, 0, 5)
      //   : null,
      null,
      getRecommendedFeedList(INIT_NUM_OF_POSTS, 2),
      getTodayPopularProduct(10, 0),
      getRecommendedFeedList(INIT_NUM_OF_POSTS, 3),
      getPopularQnaList(10, 0),
      getRecommendedFeedList(INIT_NUM_OF_POSTS, 4),
    ]);

    if (todayMallProduct !== null) {
      const mainItem = {} as MainItem;
      mainItem.sort = MainFeedTypeCode.TODAY_MALL_PRODUCT;
      mainItem.data = todayMallProduct;
      mainItems.push(mainItem);
    } else {
      isNetworkErrorExist = true;
    }

    // if (todayBrand !== null && todayBrand.length > 0) {
    //   const mainItem = {} as MainItem;
    //   mainItem.sort = MainFeedTypeCode.TODAY_BRAND;
    //   mainItem.data = todayBrand;
    //   mainItems.push(mainItem);
    // }

    if (recommendPostFirst?.successOrNot === "Y") {
      this.postPush(
        mainItems,
        recommendPostFirst.data?.feedFindVOList as Post[],
        MainFeedTypeCode.MAIN_POST
      );
    } else {
      isNetworkErrorExist = true;
    }

    // if (todayPopularArticle !== null && todayPopularArticle.length > 0) {
    //   const mainItem = {} as MainItem;
    //   mainItem.sort = MainFeedTypeCode.TODAY_ARTICLE;
    //   mainItem.data = todayPopularArticle;
    //   mainItems.push(mainItem);
    // }

    if (recommendPostSecond?.successOrNot === "Y") {
      this.postPush(
        mainItems,
        recommendPostSecond.data?.feedFindVOList as Post[],
        MainFeedTypeCode.MAIN_POST
      );
    } else {
      isNetworkErrorExist = true;
    }

    if (recommendPostThird?.successOrNot === "Y") {
      this.postPush(
        mainItems,
        recommendPostThird.data?.feedFindVOList as Post[],
        MainFeedTypeCode.MAIN_POST
      );
    } else {
      isNetworkErrorExist = true;
    }

    if (popularQna?.successOrNot === "Y") {
      const mainItem = {} as MainItem;
      mainItem.sort = MainFeedTypeCode.POPULAR_QNA;
      mainItem.data = popularQna.data;
      mainItems.push(mainItem);
    } else {
      isNetworkErrorExist = true;
    }

    if (recommendPostLast?.successOrNot === "Y") {
      const addedSecondList: Post[] = recommendPostLast.data
        ?.feedFindVOList as Post[];
      const mainItem = {} as MainItem;
      mainItem.sort = MainFeedTypeCode.MAIN_POST;
      mainItem.data = addedSecondList;
      mainItems.push(mainItem);

      this.postPush(
        mainItems,
        recommendPostLast.data?.feedFindVOList as Post[],
        MainFeedTypeCode.MAIN_POST
      );
    }

    this.mainList.push(...mainItems);

    isNetworkErrorExist &&
      CommonStore.setToastOption({
        show: true,
        message1: t("common.message.networkError"),
        // bottomOffset: 30,
      });

    this.setCurrentNumberOfList(this._currentNumberOfList + 1);
    this.setIsLoadingMore(false);
  };

  @action getThirdMain = async () => {
    this.setIsLoadingMore(true);

    let isNetworkErrorExist = false;

    const [popularRanksRes, recommandedFeedRes] = await Promise.all([
      await getPopularRanking(12, 0),
      // PreRenderMainStore, getSecondMain 에서 추천 포스트 4개씩 5번 호출 (총20개) 아래 추천 호스트 호출 index 2 부터 시작
      getRecommendedFeedList(INIT_LONG_NUM_OF_POSTS, INIT_FEED_LIST_NUMBER),
    ]);

    const popluarRankGrid: PopularRanking[][] = [];

    for (let i = 0; i < 4; i++) {
      popluarRankGrid.push(
        popularRanksRes.data?.list.slice(i * 3, (i + 1) * 3) || []
      );
    }

    //NOTE : INIT_LONG_NUM_OF_POSTS개씩 paging 하면 위의 인기포스트 INIT_NUM_OF_POSTS개 * 3개의 section과 겹치는 항목이 생기므로 slice
    const sliceIndex = INIT_NUM_OF_POSTS * 3 - INIT_LONG_NUM_OF_POSTS;

    if (popularRanksRes?.successOrNot === "Y") {
      const mainItem = {} as MainItem;
      mainItem.sort = MainFeedTypeCode.POPULAR_RANK;
      mainItem.data = popluarRankGrid;
      this.mainList.push(mainItem);
    } else {
      isNetworkErrorExist = true;
    }

    if (recommandedFeedRes?.successOrNot === "Y") {
      const recommandedFeeds: Post[] = recommandedFeedRes.data
        ?.feedFindVOList as Post[];
      this.mainList.push(
        ...recommandedFeeds
          .slice(sliceIndex > 0 ? sliceIndex : 0)
          .map((post) => {
            this.setPostMap(post);
            return {
              sort: MainFeedTypeCode.MAIN_POST_SINGLE,
              data: [post],
            } as MainItem;
          })
      );
    } else {
      isNetworkErrorExist = true;
    }

    isNetworkErrorExist &&
      CommonStore.setToastOption({
        show: true,
        message1: t("common.message.networkError"),
        // bottomOffset: 30,
      });

    this.setCurrentNumberOfList(this._currentNumberOfList + 1);
    this.setFeedNumberOfList(this._feedNumberOfList + 1);
    this.setIsLoadingMore(false);
  };

  @action getFourthMain = async () => {
    this.setIsLoadingMore(true);

    const recommandedFeedRes = await getRecommendedFeedList(
      INIT_LONG_NUM_OF_POSTS,
      this.feedNumberOfList
    );

    runInAction(() => {
      if (recommandedFeedRes?.successOrNot === "Y") {
        const recommandedFeeds: Post[] = recommandedFeedRes.data
          ?.feedFindVOList as Post[];

        if (recommandedFeeds?.length < INIT_LONG_NUM_OF_POSTS) {
          this.setCanLoadMore(false);
          this.setIsLoadingMore(false);
        } else {
          this.setFeedNumberOfList(this._feedNumberOfList + 1);
          this.setIsLoadingMore(false);
        }

        this.mainList.push(
          ...recommandedFeeds.map((post) => {
            this.setPostMap(post);
            return {
              sort: MainFeedTypeCode.MAIN_POST_SINGLE,
              data: [post],
            } as MainItem;
          })
        );
      } else {
        CommonStore.setToastOption({
          show: true,
          message1: t("common.message.networkError"),
          // bottomOffset: 30,
        });
      }
    });
  };

  @action getMoreMain = async () => {
    switch (this._currentNumberOfList) {
      case 1:
        await this.getSecondMain();
        break;
      case 2:
        await this.getThirdMain();
        break;
      default:
        await this.getFourthMain();
        break;
    }
  };

  @action getTodayMallProduct = async () => {
    const mainItem = {} as MainItem;
    let isNetworkErrorExist = false;

    const [todayMallProduct] = await Promise.all([
      getTodayPopularProduct(10, 0),
    ]);

    if (todayMallProduct !== null) {
      mainItem.sort = MainFeedTypeCode.TODAY_MALL_PRODUCT;
      mainItem.data = todayMallProduct;
    } else {
      isNetworkErrorExist = true;
    }

    isNetworkErrorExist &&
      CommonStore.setToastOption({
        show: true,
        message1: t("common.message.networkError"),
        // bottomOffset: 30,
      });

    this.setTodayProductList(mainItem);
  };

  @observable targetUserUuid = "";
  @observable interestedTagList: MemberTagItem[] | null | undefined = null;
  @observable mainBannerList: BannerInfo[] | null | undefined = null;

  @action setMainBannerList = (
    mainBannerList: BannerInfo[] | null | undefined
  ) => {
    this.mainBannerList = mainBannerList;
  };

  @observable _bannerImageList: { url: string }[] = [];

  @computed get bannerImageList() {
    return this._bannerImageList;
  }

  @action setBannerImageList = (bannerImageList: { url: string }[]) => {
    this._bannerImageList = bannerImageList;
  };

  @observable _mainPopupBannerIndex = 0;

  @computed get mainPopupBannerIndex() {
    return this._mainPopupBannerIndex;
  }

  @action setMainPopupBannerIndex = (mainPopupBannerIndex: number) => {
    this._mainPopupBannerIndex = mainPopupBannerIndex;
  };

  @action getMainBannerList = async () => {
    let response: BannerInfo[] = await getBannerList(
      BannerTypeCodes.MAIN_BANNER
    );

    response = response.map((bannerInfo) => ({
      ...bannerInfo,
      bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
    }));

    runInAction(() => {
      this.mainBannerList = response;

      const filteredBannerImageList: { url: string }[] = response.map(
        (bannerInfo) => ({
          url: `${PUBLIC_BUCKET_URL}${bannerInfo.filePath}`,
        })
      );

      this.setBannerImageList(filteredBannerImageList || []);
    });
  };

  @action initMainBannerList = async () => {
    if (this.mainBannerList == null) {
      let response: BannerInfo[] = await getBannerList(
        BannerTypeCodes.MAIN_BANNER
      );

      response = response.map((bannerInfo) => ({
        ...bannerInfo,
        bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
      }));
      this.mainBannerList = response;
    }
  };

  @action setAfterReportHandlerMainStore = (handler: (() => void) | null) => {
    this._afterReportHandler = handler;
  };

  @action setUserId = async (id: string) => {
    this.targetUserUuid = id;

    const response: MemberInfoResponse = await getMemberInfo(id);

    runInAction(() => {
      if (response && response.interestedTagList)
        this.interestedTagList = response.interestedTagList;
    });
  };

  @action setInterestedTagList = (interestedTag: MemberTagItem[]) => {
    this.interestedTagList = interestedTag;
  };

  @action addInterestedTag = (interestedTag: MemberTagItem) => {
    if (this.interestedTagList?.length == 0 || this.interestedTagList == null) {
      this.setInterestedTagList([]);
    }

    this.interestedTagList?.push(interestedTag);
  };

  @action removeInterestedTag = (interestedTag: MemberTagItem) => {
    if (this.interestedTagList != null) {
      const index = this.interestedTagList.findIndex(
        (x) => x.tagId === interestedTag.tagId
      );

      if (index != -1) {
        this.interestedTagList.splice(index, 1);
      }
    }
  };

  // @action
  // reportPostinMain = async (commonReport: CommonReport) => {
  //   if (commonReport.reportTargetId) {
  //     const res = await saveReport(commonReport);
  //     if (res.successOrNot === "Y") {
  //       this._afterReportHandler && this._afterReportHandler();
  //     } else {
  //       return CommonStore.setToastOption({
  //         show: true,
  //         message1: t("screen.notificationModal.message.reportFail"),
  //         // bottomOffset: 30,
  //       });
  //     }
  //     return res;
  //   }
  // };

  @action updateMainCarouselIndex = (
    mainListIndex: number,
    postIndex: number,
    index: number
  ) => {
    let updatedPost: Post;

    if (this._mainList[mainListIndex].data[postIndex]) {
      updatedPost = {
        ...this._mainList[mainListIndex].data[postIndex],
        currentCarouIndex: index,
      };
      this.setMainPost(updatedPost, mainListIndex, postIndex);
    }
  };

  @action clearMain = () => {
    this._loading = true;
    this._isLoadingMore = false;
    this._canLoadMore = true;
    this._currentNumberOfList = INIT_CURRENT_LIST_NUMBER;
    this._feedNumberOfList = INIT_FEED_LIST_NUMBER; // NOTE: 추천피드 리스트 호출용 Index
    this.targetUserUuid = "";
    this._afterReportHandler = null;
    this._bannerImageList = [];
    this._postMap.clear();
    this._loggingCompleteSet.clear();
    this._mainTabScroll.clear();
  };

  @action updateMainListFeed = async () => {
    const index = this._mainList.findIndex(
      ({
        sort,
        data,
      }: {
        sort: MainFeedTypeCode | undefined | null;
        data: any;
      }) => sort === MainFeedTypeCode.POPULAR_QNA
    );

    if (index === -1) {
      return;
    }

    const popluarQnaListRes = await getPopularQnaList(10, 0);

    this._mainList[index] = {
      sort: MainFeedTypeCode.POPULAR_QNA,
      data: popluarQnaListRes.data,
    };
  };

  @action getBannerContents = async (type: string) => {
    const res = await getBannerList(type);
    if (res) {
      if (type === BannerTypeCodes.MAIN_POP_UP_BANNER) {
        this.setPopupBannerList(res);
      } else if (type === BannerTypeCodes.MAIN_POP_UP_NOTIFICATION_BANNER) {
        this.setTextBanner(res[0]);
      } else {
        //
      }
    }
  };

  @action findMatchedPostIndex = (feedId: number) => {
    return this._popularPostsList.findIndex((x) => x.feedId === feedId);
  };

  @action setPostWithFeedId = (newPost: Post, feedId: number) => {
    const matchedIndex = this.findMatchedPostIndex(feedId);

    if (matchedIndex != -1) {
      this._popularPostsList[matchedIndex] = newPost;
    }
  };

  @action setPostWithFeedIdAndType = (
    newPost: Post,
    feedId: number,
    mainFeedTypeCodes: MainFeedTypeCode[]
  ) => {
    this._mainList.forEach((mainItem: MainItem, index: number) => {
      mainItem.sort &&
        mainFeedTypeCodes.includes(mainItem.sort) &&
        mainItem.data.forEach((item: any, postIndex: number) => {
          if (item.feedId === feedId) {
            this._mainList[index].data[postIndex] = newPost;
            if (mainItem.sort === MainFeedTypeCode.POPULAR_POST) {
              this.setPostWithFeedId(newPost, feedId);
            }
          }
        });
    });
  };

  @action setMainPopularPost = (post: Post, index: number) => {
    this._popularPostsList[index] = post;
  };

  @action updateFeed = (
    action: UserActTypeCode,
    feedId: number,
    extraData?: any
  ) => {
    this.updateMainPopularList(action, feedId, extraData);

    this.mainList.forEach((list, index) => {
      const postIndex: number = list.data.findIndex(
        (x: any) => x.feedId == feedId
      );

      if (postIndex != -1) {
        this.updateMainList(action, postIndex, index, extraData);
      }
    });
  };

  @action updateMainPopularList = (
    action: UserActTypeCode,
    feedId: number,
    extraData?: any
  ) => {
    const matchedIndex = this.findMatchedPostIndex(feedId);

    if (matchedIndex === -1) return;

    switch (action) {
      case UserActTypeCode.CANCEL_LIKE:
        if (this._popularPostsList[matchedIndex]) {
          const formerCount: number =
            this._popularPostsList[matchedIndex].likeCount;
          const updatedPost = {
            ...this._popularPostsList[matchedIndex],
            likeYn: "N",
            likeCount: formerCount - 1,
          };
          this.setMainPopularPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SAVE_LIKE:
        if (this._popularPostsList[matchedIndex]) {
          const formerCount: number =
            this._popularPostsList[matchedIndex].likeCount;
          const updatedPost = {
            ...this._popularPostsList[matchedIndex],
            likeYn: "Y",
            likeCount: formerCount + 1,
          };
          this.setMainPopularPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.CANCEL_BOOKMARK:
        if (this._popularPostsList[matchedIndex]) {
          const updatedPost = {
            ...this._popularPostsList[matchedIndex],
            bookmarkYn: "N",
          };
          this.setMainPopularPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SAVE_BOOKMARK:
        if (this._popularPostsList[matchedIndex]) {
          const updatedPost = {
            ...this._popularPostsList[matchedIndex],
            bookmarkYn: "Y",
          };
          this.setMainPopularPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SHOW_MORE:
        if (
          this._popularPostsList[matchedIndex] &&
          this._popularPostsList[matchedIndex].showMore === true
        ) {
          const updatedPost = {
            ...this._popularPostsList[matchedIndex],
            showMore: false,
          };
          this.setMainPopularPost(updatedPost, matchedIndex);
        } else {
          const updatedPost = {
            ...this._popularPostsList[matchedIndex],
            showMore: true,
          };
          this.setMainPopularPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.OPEN_MODAL:
        if (this._popularPostsList[matchedIndex]) {
          const updatedPost = {
            ...this._popularPostsList[matchedIndex],
            showDeletePopup: true,
          };
          this.setMainPopularPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.CLOSE_MODAL:
        if (this._popularPostsList[matchedIndex]) {
          const updatedPost = {
            ...this._popularPostsList[matchedIndex],
            showDeletePopup: false,
          };
          this.setMainPopularPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.POP:
      case UserActTypeCode.REPORT:
      case UserActTypeCode.DELETE:
        if (this._popularPostsList[matchedIndex]) {
          this._popularPostsList.splice(matchedIndex, 1);
        }
        break;
      case UserActTypeCode.UPDATE_COMMENT:
        if (this._popularPostsList[matchedIndex]) {
          const updatedPost = {
            ...this._popularPostsList[matchedIndex],
            commentCount: extraData.commentCount,
          };
          this.setMainPopularPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.MODIFY:
        if (this._popularPostsList[matchedIndex] && extraData != undefined) {
          const updatedPost = extraData as Post;
          this.setMainPopularPost(updatedPost, matchedIndex);
        }
        break;
    }
  };

  @action setProfileToolTipFlag = (flag: boolean) => {
    this.profileToolTipFlag = flag;
  };

  @action trackAllUserProperties = () => {
    const memberUuid = AuthStore.sessionUser?.memberUuid;
    if (memberUuid) {
      void trackUserProperties(memberUuid, "ALL_USER_PROPERTIES");
    }
  };

  setPostMap(post: Post) {
    if (!this._postMap.has(String(post.feedId))) {
      const orderNum =
        this._postMap.size == 0 ? 0 : Math.max(...this._postMap.values()) + 1;
      this._postMap.set(String(post.feedId), orderNum);
    }
  }
}

export default new MainStore();
