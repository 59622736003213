import { DisplayProduct } from "../mall/model";

export enum SortType {
  LATEST = "dateDESC", //최신순
  POPULAR = "popularityDESC", //인기순
  ACCURACY = "accuracyDESC", // 관련순
  SALES = "salesCountDESC", // 관련순
}

export interface CategoryInfo {
  displayCategoryId: number;
  displayCategoryName: string;
  displayImageUrl: string;
  targetLink: string;
  parentDisplayCategoryId: string;
  sortOrder: number;
}

export interface LifecarePopularProductRequest {
  displayCategoryId: number;
  cursor: number;
  pageSize: number;
}

export interface SearchAttributes {
  [key: number]: number[];
}
export interface FilterProductRequest {
  memberUuid: string;
  sort: string;
  pageIndex: number;
  pageSize: number;
  displayCategoryId: number;
  searchAttributes: SearchAttributes;
}

export interface Attribute_vlues {
  parentId?: number;
  attributeValueId: number;
  attributeName?: string;
  attributeValueName: string;
  check?: boolean;
}
export interface Attributes {
  attributeId: number;
  attributeName: string;
  attributeValueList: Attribute_vlues[];
}

export interface CategoryFilterProduct {
  largeDisplayCategoryId: number;
  largeDisplayCategoryName: string;
  mediumDisplayCategoryId?: number | null;
  mediumDisplayCategoryName?: string | null;
  smallDisplayCategoryId?: number | null;
  smallDisplayCategoryName?: string | null;
  childDisplayCategoryIds?: CategoryInfo[];
  attributes?: Attributes[];
  productList: DisplayProduct[];
  totalProductCount: number | null;
}

export enum CategoryScreenType {
  S = "SMALL",
  M = "MEDIUM",
  L = "LARGE",
}
