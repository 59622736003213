import React, {
  ReactNode,
  RefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import Search from "./presenter/Search";
import SearchResult from "./presenter/SearchResult";
import { NotificationModal } from "../../components/modals";
import { observer } from "mobx-react";
import SearchStore from "../../store/SearchStore";
import CommonStore from "../../store/CommonStore";
import { ReactComponent as SearchIcon } from "../../assets/icons/search1.svg";
import { ReactComponent as ClosePurple } from "../../assets/icons/close_purple_background.svg";
import { ReactComponent as LatestIcon } from "../../assets/icons/clock_purple.svg";
import { ReactComponent as SearchGrayIcon } from "../../assets/icons/search_gray.svg";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import {
  SEARCH_RESULT_COUNT,
  SearchSortType,
  SearchTabMenu,
} from "../../constants/Search";
import { ReactComponent as AngleIcon } from "../../assets/icons/new_back_arrow.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/new_home.svg";
import { Platform, PlatformTypes } from "../../native/platform";
import { useTranslation } from "react-i18next";
import "./SearchResultLayout.scss";
import { goBack, goTo } from "../../hooks/navigate/NavigateFunction";
import { useLocation, useParams } from "react-router-dom";
import CustomBottomSheet from "../../components/common/CustomBottomSheet";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

export interface SearchResultLayoutProps {
  children: ReactNode;
  tabType: string;
}

const useSafeAreaInsets = () => ({ top: 0, bottom: 0 });

const SearchResultLayout = observer(
  ({ children, tabType }: SearchResultLayoutProps) => {
    const {
      hasError,
      errorMessage,
      toastMessage,
      searchFocus,
      searchText,
      latestSearchTextList,
      autoCompleteResult,
      initTabName,
      getSelectedSearchTab,
      showSortBox,
      setError,
      setSearchText,
      setSearchFocus,
      setChangedText,
      getAutoComplete,
      getSuggestionSearch,
      getRankingSearch,
      doSearch,
      getLastestSearchText,
      clear,
      setInitTabName,
      setSelectedTab,
      needToSearch,
      getSortType,
      getSearchResult,
      setShowSortBox,
      setSortType,
    } = SearchStore;
    const insets = useSafeAreaInsets();

    const EMPTY_STR = "";
    const searchRef: React.RefObject<any> = useRef(null);

    const { t } = useTranslation();
    const location = useLocation();

    const useParamKeyword = useParams().keyword || "";
    const localStateKeyword =
      location && location.state && location.state.keyword
        ? location.state.keyword
        : null;

    const [initTab, setInitTab] = useState(tabType);

    const [keyword, setKeyword] = useState(
      useParamKeyword && useParamKeyword !== null
        ? useParamKeyword
        : localStateKeyword && localStateKeyword !== null
        ? localStateKeyword
        : ""
    );

    const tabNameList = [
      SearchTabMenu.Total,
      SearchTabMenu.Post,
      SearchTabMenu.Product,
      SearchTabMenu.Poster,
      SearchTabMenu.Brand,
      SearchTabMenu.BrandArticle,
      SearchTabMenu.Qna,
      SearchTabMenu.Tag,
    ];

    const { pathname } = useLocation();

    useEffectOnlyOnce(() => {
      const tempPathname = pathname.toLowerCase();

      let scrollValue = 0;

      const tempSearchResultTabScroll = SearchStore.searchResultTabScroll;
      if (tempSearchResultTabScroll.has(tempPathname)) {
        scrollValue = tempSearchResultTabScroll.get(tempPathname) || 0;
      }

      setTimeout(() => {
        window.scrollTo(0, scrollValue);
      }, 300);
    });

    useEffect(() => {
      if (!searchText || searchText.length === 0) {
        setSearchFocus(true);
        void getLastestSearchText();
        void getSuggestionSearch();
        void getRankingSearch();
      }
    }, [
      // isFocused,
      getLastestSearchText,
      getSuggestionSearch,
      getRankingSearch,
      setSearchFocus,
      searchText,
    ]);

    useEffect(() => {
      if (toastMessage !== undefined && toastMessage.trim().length > 0) {
        CommonStore.setToastOption({
          show: true,
          message1: toastMessage,
        });
        SearchStore.setToast(EMPTY_STR);
      }
    }, [toastMessage, insets]);

    useEffect(() => {
      const ref = tabRefs[tabNameList.indexOf(initTab as SearchTabMenu)];
      if (ref.current) {
        ref.current.scrollIntoView({
          behavior: "auto",
          inline: "center",
        });
      }

      if (keyword && keyword.length > 0) {
        void onSearch(keyword as string, initTab);
      } else if (searchText) {
        void onSearch(searchText as string, initTab);
      } else {
        setSearchFocus(true);
        searchRef.current?.focus();
        clear();
        goTo("/Search");
      }
    }, []);

    const matchSearchTextInAutoCompleteText = (
      searchText: string,
      autoCompleteText: string
    ) => {
      let matchText = "";
      for (let index = 0; index < searchText.length; index++) {
        if (
          searchText.charAt(index).toUpperCase() ===
          autoCompleteText.charAt(index).toUpperCase()
        ) {
          matchText += searchText.charAt(index);
        }
      }
      return matchText;
    };

    const renderAutoComplete = () => {
      let searchedText: string[] = [];
      let autoCompleteText: string[] = [];
      if (latestSearchTextList && latestSearchTextList.text.length > 0) {
        const upperLatestSearchTextList = latestSearchTextList.text.map(
          (text) => text.toUpperCase()
        );
        searchedText =
          (autoCompleteResult &&
            autoCompleteResult.length > 0 &&
            autoCompleteResult.filter((item) =>
              upperLatestSearchTextList.includes(item.toUpperCase())
            )) ||
          [];

        autoCompleteText =
          (autoCompleteResult &&
            autoCompleteResult.length > 0 &&
            autoCompleteResult.filter(
              (item) => !upperLatestSearchTextList.includes(item.toUpperCase())
            )) ||
          [];
      } else {
        autoCompleteText = autoCompleteResult;
      }

      return (
        <div>
          <div className="auto_complete_list">
            {searchedText
              .slice()
              .reverse()
              .map((item, index) => {
                const matchText = matchSearchTextInAutoCompleteText(
                  searchText,
                  item
                );
                return (
                  <div
                    className="auto_complete_item"
                    key={index}
                    onClick={async () => await onSearch(item)}
                  >
                    <LatestIcon />
                    <div className="auto_complete_text">
                      {/* <span className="auto_complete_match_text">
                      {item.slice(0, matchText.length)}
                    </span> */}

                      <span style={{ color: "#a760b1" }}>
                        {item.slice(0, matchText.length)}
                      </span>
                      {item.slice(matchText.length)}
                    </div>
                  </div>
                );
              })}
            {autoCompleteText.map((item, index) => {
              const matchText = matchSearchTextInAutoCompleteText(
                searchText,
                item
              );
              return (
                <div
                  className="auto_complete_item"
                  key={index}
                  onClick={async () => await onSearch(item)}
                >
                  <SearchGrayIcon />
                  <p className="auto_complete_text">
                    <span className="auto_complete_match_text">
                      {item.slice(0, matchText.length)}
                    </span>
                    <span>{item.slice(matchText.length)}</span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      );
    };

    const onChangeText = (text: string) => {
      setInitTabName(SearchTabMenu.Total);

      setChangedText(true);
      void getAutoComplete(text);
      setSearchFocus(true);
      setSearchText(text);

      if (text === EMPTY_STR) {
        clear();
        goTo("/Search");
      }
    };

    const onSearch = async (
      text: string,
      goToTab: string = SearchTabMenu.Total
    ) => {
      if (text.length > 0) {
        setSearchFocus(false);
        setSearchText(text);
        setSelectedTab(goToTab as SearchTabMenu);
        searchRef.current?.blur();

        if (needToSearch(goToTab) || text !== keyword) {
          goToTab === SearchTabMenu.Total
            ? void doSearch(text)
            : void getSearchResult(
                text,
                goToTab,
                getSortType(goToTab),
                SEARCH_RESULT_COUNT,
                0
              );
        }
        if (text !== keyword || initTab !== goToTab) {
          goTo(`/Search/${text}/${goToTab}`);
        }
      } else {
        const msg = t("screen.search.message.noSearchText");
        SearchStore.setToast(msg);
      }
    };

    const handleBack = () => {
      onChangeText(EMPTY_STR);
    };

    const tabRefs = useRef<RefObject<HTMLDivElement>[]>(
      Array.from({ length: tabNameList.length }, () => createRef())
    ).current;

    const onChangeTab = (tabName: SearchTabMenu) => {
      const isActive = getSelectedSearchTab === tabName;
      if (!isActive) {
        onSearch(searchText, tabName);
      }
    };

    const scrollViewRef = useRef<any>(null);

    return (
      <div id="SearchResultLayout" className="search">
        <div className="search_header">
          <div className="search_back" onClick={handleBack}>
            <AngleIcon />
          </div>
          <div className="nick_name_wrap">
            <div className="input_wrap">
              <input
                autoFocus={true}
                className="nick_name"
                placeholder={t("screen.search.message.searchPlaceholder")}
                autoCapitalize="none"
                ref={searchRef}
                value={searchText}
                type="text"
                onChange={(event) => {
                  onChangeText(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    void onSearch(searchText);
                  }
                }}
              />
              <div
                className={`close_purple ${
                  !(searchText.length > 0) && "hidden"
                }`}
              >
                <div
                  onClick={() => {
                    onChangeText(EMPTY_STR);
                    searchRef.current?.focus();
                  }}
                >
                  <ClosePurple />
                </div>
              </div>
            </div>

            <div className="search_icon">
              <div onClick={() => void onSearch(searchText)}>
                <SearchIcon width={30} height={30} />
              </div>
            </div>
          </div>
          <div
            className="search_home"
            onClick={() => {
              clear();
              goTo("/Main");
            }}
          >
            <HomeIcon />
          </div>
        </div>

        {SearchStore.searchFocus ? (
          SearchStore.searchText.length > 0 ? (
            renderAutoComplete()
          ) : (
            <Search onSearch={onSearch} />
          )
        ) : (
          <div id="SearchResult">
            <div id="SearchTabBar" ref={scrollViewRef}>
              <div
                className="search_tab"
                onTouchStart={() => setShowSortBox(false)}
                // onLayout={handleTabWrapperLayout}
              >
                {tabNameList &&
                  tabNameList.length > 0 &&
                  tabNameList.map((item: SearchTabMenu, index: number) => {
                    if (
                      item !== SearchTabMenu.Brand &&
                      item !== SearchTabMenu.BrandArticle
                    ) {
                      const ref = tabRefs[index];
                      const isActive = getSelectedSearchTab === item;

                      return (
                        <div
                          className={`search_txt_wrap search_txt_wrap${
                            isActive ? "_active" : ""
                          }`}
                          key={item}
                          ref={ref}
                          onClick={() => onChangeTab(item)}
                          aria-label={t(`screen.search.button.${String(item)}`)}
                        >
                          <div
                            className={`search_txt${isActive ? "_active" : ""}`}
                          >
                            {t(`screen.search.button.${String(item)}`)}
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>

            {children}

            <CustomBottomSheet
              snapHeight={236}
              open={showSortBox}
              onClose={() => setShowSortBox(false)}
            >
              <div className="sheet">
                <div
                  className="close_btn"
                  onClick={() => setShowSortBox(false)}
                >
                  <CloseIcon />
                </div>
                <div className="sheet_title_wrap">
                  <div className="sheet_title">
                    {t("screen.search.label.sort")}
                  </div>
                </div>

                <div className="sort_list">
                  <div
                    className="sort_item"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setSortType(SearchSortType.LATEST);
                    }}
                  >
                    <div
                      className={`${
                        getSortType() === SearchSortType.LATEST
                          ? "sort_active_text"
                          : "sort_text"
                      }`}
                    >
                      {t("screen.search.label.latest")}
                    </div>
                  </div>
                  <div
                    className="sort_item"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setSortType(SearchSortType.POPULAR);
                    }}
                  >
                    <div
                      className={`${
                        getSortType() === SearchSortType.POPULAR
                          ? "sort_active_text"
                          : "sort_text"
                      }`}
                    >
                      {t("screen.search.label.popular")}
                    </div>
                  </div>
                  <div
                    className="sort_item"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setSortType(SearchSortType.ACCURACY);
                    }}
                  >
                    <div
                      className={`${
                        getSortType() === SearchSortType.ACCURACY
                          ? "sort_active_text"
                          : "sort_text"
                      }`}
                    >
                      {t("screen.search.label.accuracy")}
                    </div>
                  </div>
                </div>
              </div>
            </CustomBottomSheet>
          </div>
        )}

        {hasError && (
          <NotificationModal
            isVisible={hasError}
            contents1={errorMessage!}
            onRequestClose={() => {
              setError(EMPTY_STR);
            }}
          />
        )}
      </div>
    );
  }
);

export default SearchResultLayout;
