import { observer } from "mobx-react";
import React from "react";
import BadgeList from "./BadgeList";

const BadgeScreen = observer(() => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
      }}
      arai-label="Badge Screen"
    >
      <BadgeList />
    </div>
  );
});

export default BadgeScreen;
