import "./QnaListItem.scss";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
// import { Text, StyleSheet, View, Platform } from "react-native";
import { Styles } from "../../../assets/types/Style";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { ReactComponent as CommentIcon } from "../../../assets/icons/new_comment_purple.svg";
import { ReactComponent as QuestionIcon } from "../../../assets/icons/new_question_active.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/new_heart_off.svg";

import clsx from 'clsx'
// import { responsiveScreenWidth } from "react-native-responsive-dimensions";
import { getDateStr } from "../../../utils/datetime";
import { getNumberFormat } from "../../../utils/number";
import { Qna } from "../../../api/qna/model";
// import Config from "react-native-config";
// import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
// import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { ActPageCode, ActSectionCode } from "../../../constants/ActHistory";
import { useTranslation } from "react-i18next";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const QnaListItem = observer((data: any) => {
  const {
    feedId,
    feedTitle,
    contents,
    nickname,
    files,
    commentCount,
    likeCount,
    createdDatetime,
  } = data.qna as Qna;

  const REACT_APP_PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

  const { t } = useTranslation();

  const [isSmall, setIsSmall] = useState(true);
  // useEffect(() => {
  //   const width = responsiveScreenWidth(100);

  //   if (width > 370) {
  //     setIsSmall(false);
  //   }
  // }, [data]);

  const moveQnaDetail = (feedId: number) => {
    goTo(`/QnA/${feedId}`, {
      state: {
        feedId: feedId,
        actData: {
          actPageCode: ActPageCode.QNA,
          actSectionCode: ActSectionCode.RECENT_QNA,
        },
        index: +data.index,
      },
    });
  };

  const renderLargeQnaInfo = () => {
    return (
      <div
        className="qna_info_wrap"
        aria-label={`Qna Main Recently qna item ${feedId}`}
      >
        <div className="qna_info">
          <p className="qna_item_text3">{nickname}</p>
          <div className="qna_item_bar" />
          <div className="qna_comment">
            <CommentIcon />
            <p className="qna_item_text4">{getNumberFormat(commentCount)}</p>
          </div>
          <div className="qna_item_bar" />
          <div className="like">
            <HeartIcon />
            <p className="qna_item_text5">{getNumberFormat(likeCount)}</p>
          </div>
          <div className="qna_item_bar" />
          <p className="qna_item_text5">{getDateStr(createdDatetime)}</p>
        </div>

        <div>
          <p
            className="reply_btn_text"
            aria-label={`reply${data?.index as string}`}
          >
            {t(`screen.qna.button.reply`)}
          </p>
        </div>
      </div>
    );
  };

  const renderSmallQnaInfo = () => {
    return (
      <div className="qna_info_wrap">
        <div className="qna_info">
          <p className="qna_item_text3">{nickname}</p>
          <div className="small_wrap">
            <div className="small_wrap_inner">
              <div className="qna_item_bar" />
              <div className="qna_comment">
                <CommentIcon />
                <p className="qna_item_text4">
                  {getNumberFormat(commentCount)}
                </p>
              </div>
              <div className="qna_item_bar" />
              <div className="like">
                <HeartIcon />
                <p className="qna_item_text5">{getNumberFormat(likeCount)}</p>
              </div>
              <div className="qna_item_bar" />
              <p className="qna_item_text5">{getDateStr(createdDatetime)}</p>
            </div>
            <div>
              <p
                className="reply_btn_text"
                aria-label={`reply${data?.index as string}`}
              >
                {t(`screen.qna.button.reply`)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    // TouchableWithAuthCheck
    <button
      className="qna_item"
      onClick={() => {
        moveQnaDetail(feedId);
      }}
      aria-label={`qna list item${data?.index as string}`}
    >
      <div>
        <QuestionIcon />
      </div>
      <div className="qna_wrap">
        <div className={clsx('qna_top', { has_thumbnail: files != undefined && files.length > 0 })}>
          <div className="qna">
            <p
              className="qna_item_text1"
              // numberOfLines={2}
              aria-label={`qna title${data?.index as string}`}
            >
              {feedTitle}
            </p>
            <p
              className="qna_item_text2"
              // numberOfLines={1}
            >
              {contents}
            </p>
          </div>
          {files != undefined && files.length > 0 && (
            <div>
              <img
                src={`${REACT_APP_PUBLIC_BUCKET_URL}${
                  files[0].thumbnailFilePath ?? ""
                }`}
                alt={files[0].thumbnailFilePath}
                // fallbackImageUri={`${REACT_APP_PUBLIC_BUCKET_URL}${
                //   files[0].filePath ?? ""
                // }`}
                className="qna_image"
              />
            </div>
          )}
        </div>
        {isSmall ? renderSmallQnaInfo() : renderLargeQnaInfo()}
      </div>
    </button>
  );
});

export default QnaListItem;
