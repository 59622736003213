import { observer } from "mobx-react";
import PreferenceStore from "../../../store/PreferenceStore";
import { PreferenceTypeCode } from "../../../constants/Preference.enum";
import SeletablePreference from "./SeletablePreference";

const RoomScalePreference = observer(() => {
  const {
    roomScalePreference,
    selectedRoomScalePreferenceListCodes,
    selectRoomScalePreferenceListCodes,
    preferenceSteps,
  } = PreferenceStore;

  const handleSelect = (item: string) => {
    selectRoomScalePreferenceListCodes(item);
  };

  const currentStep =
    preferenceSteps.findIndex(
      (item) => item === PreferenceTypeCode.ROOM_SCALE
    ) + 1;
  const totalStepCount = preferenceSteps.length;

  return roomScalePreference ? (
    <SeletablePreference
      preference={roomScalePreference}
      selectedPreferenceCodes={selectedRoomScalePreferenceListCodes}
      onSelectPrefereceCode={handleSelect}
      currentStep={currentStep}
      totalStepCount={totalStepCount}
    />
  ) : (
    <></>
  );
});

export default RoomScalePreference;
