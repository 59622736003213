import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PassIcon from "../../assets/icons/password_check_large_active.svg";
import TriangleRightBlackIcon from "../../assets/icons/triangle_right_black.svg";
import TriangleRightRed from "../../assets/icons/triangle_right_red.svg";
import TriangleRightGreen from "../../assets/icons/triangle_right_green.svg";
import TriangleRightRedIcon from "../../assets/icons/triangle_right_red.svg";
import PasswordConditionValidIcon from "../../assets/icons/password_check_valid.svg";
import PasswordConditionInvalidIcon from "../../assets/icons/password_check_invalid.svg";
import PasswordConditionInactiveIcon from "../../assets/icons/password_check_inactive.svg";
import CancelIcon from "../../assets/icons/cancel.svg";
import PwShowPurpleIcon from "../../assets/icons/pw_show_purple.svg";
import PwHideBlackIcon from "../../assets/icons/pw_hide_black.svg";
import { validateEmailAddress } from "../../utils/email";
import {
  passwordCondition1,
  passwordCondition2,
  passwordCondition3,
  validatePassword,
} from "../../utils/password";
import {
  AddMemberRequest,
  EmailValidationResult,
} from "../../api/member/model";
import {
  createMember,
  emailLogin,
  updateMember,
  validateEmail,
} from "../../api/member/api";
import {
  sendCertification,
  checkCertification,
} from "../../api/certification/api";
import { SendCertificationResult } from "../../api/certification/model";
import CommonResponse from "../../api/http";
import TermsAgree from "../../components/common/TermsAgree";
import TermsStore from "../../store/TermsStore";
import { AllowTypeCode } from "../../constants/CommonCode.enum";
import {
  JoinStatusCode,
  JoinWithEmailSections,
} from "../../constants/JoinWithEmail.enum";
import { CertificationCodeStatus } from "../../constants/Certification.enum";
import {
  deHyphenatePhoneNumber,
  hyphenatePhoneNumber,
} from "../../utils/phoneNumber";
import {
  mergeAndSetSessionUserInStorage,
  setSessionUserInStorage,
} from "../../service/Session.service";
import { observer } from "mobx-react";
import {
  getTempMemberFromStorage,
  removeTempMemberFromStorage,
} from "../../service/Preference.service";
import { SocialTypeCode, SuccessOrNot } from "../../constants/Common.enum";
import ProfileStore from "../../store/ProfileStore";
import CommonStore from "../../store/CommonStore";
import { LAST_LOGIN_TYPE } from "../../constants/Storage";
import PhoneAuthSection from "../../components/common/PhoneAuthSection";
import PhoneAuthStore from "../../store/PhoneAuthStore";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import RecommenderStore from "../../store/RecommenderStore";
import MainStore from "../../store/MainStore";
import { getStringRemoteConfig } from "../../utils/remoteConfig";
import { TitleHeader } from "../../components/common";
import classes from "./JoinWithEmailScreen.module.scss";
import clsx from "clsx";
import Accordion, {
  IAccordionSection,
} from "../../components/common/collapsible/Accordion";
import { Platform, PlatformTypes } from "../../native/platform";
import { goTo } from "../../hooks/navigate/NavigateFunction";

// const StatusBarHeight = getStatusBarHeight();

const JoinWithEmailScreen = observer(() => {
  // const isPlatformIOS = Platform.OS === "ios";
  const headerRef = useRef<HTMLDivElement | null>(null);
  const autoCompleteDomainArray = [
    "@naver.com",
    "@gmail.com",
    "@hanmail.net",
    "@nate.com",
    "@kakao.com",
  ];
  const EMPTY_STRING = "";
  const INITIAL_MINUTE_DISPLAY = "3";
  const INITIAL_SECOND_DISPLAY = "00";
  const AUTH_TIME_LIMIT = 180;

  const { setLoading } = CommonStore;
  // const insets = useSafeAreaInsets();
  // const bottomInset = insets.bottom / PixelRatio.get();
  const emailInputRef = useRef<HTMLDivElement>(null);
  const authSectionRef = useRef<any>(null);
  const phoneNumberInputRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);
  const time = useRef(AUTH_TIME_LIMIT);
  const timerId = useRef<any>();

  const [activeSection, setActiveSection] = useState<JoinWithEmailSections[]>([
    JoinWithEmailSections.EMAIL,
  ]);

  const [email, setEmail] = useState<string>(EMPTY_STRING);
  const [isAutoComplete, setIsAutoComplete] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [emailValidationResult, setEmailValidationResult] =
    useState<EmailValidationResult>();
  const [isAuthSectionShow, setIsAuthSectionShow] = useState<boolean>(false);
  const [expirationMinDisplay, setExpirationMinDisplay] = useState<string>(
    INITIAL_MINUTE_DISPLAY
  );
  const [expirationSecDisplay, setExpirationSecDisplay] = useState<string>(
    INITIAL_SECOND_DISPLAY
  );
  const [certificationId, setCertificationId] = useState<string>(EMPTY_STRING);
  const [inputCertificationCode, setInputCertificationCode] =
    useState<string>(EMPTY_STRING);
  const [certificationCodeStatus, setCertificationCodeStatus] =
    useState<string>(EMPTY_STRING);

  const [isEmailSectionComplete, setIsEmailSectionComplete] =
    useState<boolean>(false);

  const [timerStart, setTimerStart] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<number>(0);
  const [invalidCertificationCount, setInvalidCertificationCount] =
    useState<number>(0);

  const { t } = useTranslation();

  const onToastAuthEmailSent = () => {
    CommonStore.setToastOption({
      show: true,
      message1: t("screen.join.toast.authEmailSentMessageFirst"),
      message2: t("screen.join.toast.authEmailSentMessageSecond"),
    });
  };

  const onToastEmailConfirmed = () => {
    CommonStore.setToastOption({
      show: true,
      message1: t("screen.join.toast.emailConfirmedMessage"),
    });
  };

  const onToastCertificationExpired = () => {
    CommonStore.setToastOption({
      show: true,
      message1: t("screen.join.toast.certificationExpired"),
    });
  };

  useEffect(() => {
    if (timerStart) {
      timerId.current = setInterval(() => {
        const diffSec = Math.floor((Date.now() - startTime) / 1000);
        const extraAuthSec = Math.max(
          0,
          (time.current = AUTH_TIME_LIMIT - diffSec)
        );
        setExpirationMinDisplay(Math.floor(extraAuthSec / 60).toString());
        setExpirationSecDisplay((extraAuthSec % 60).toString());
      }, 1000);
    } else {
      clearInterval(timerId.current as NodeJS.Timeout);
    }

    return () => {
      clearInterval(timerId.current as NodeJS.Timeout);
    };
  }, [timerStart, startTime]);

  const stopTimer = () => {
    setTimerStart(false);
    timerId.current && clearInterval(Number(timerId.current));
  };

  const initTimer = () => {
    stopTimer();
    setExpirationMinDisplay(INITIAL_MINUTE_DISPLAY);
    setExpirationSecDisplay(INITIAL_SECOND_DISPLAY);
    time.current = AUTH_TIME_LIMIT;
  };

  const startTimer = () => {
    initTimer();

    setStartTime(Date.now());
    setTimerStart(true);
  };

  const resetAuthLogic = () => {
    setInputCertificationCode(EMPTY_STRING);
    setCertificationCodeStatus(EMPTY_STRING);
    setIsAuthSectionShow(false);
    initTimer();
  };

  const handleEmailChange = (inputEmail: string) => {
    if (isAuthSectionShow) {
      resetAuthLogic();
    }

    setEmail(inputEmail);
  };

  const clearEmail = () => {
    if (isAuthSectionShow) {
      resetAuthLogic();
    }

    setEmail(EMPTY_STRING);
  };

  const checkIfEmailDuplicate = async (email: string) => {
    const response: EmailValidationResult = await validateEmail(email);

    setEmailValidationResult(response);
  };

  const sendAuthEmail = async (email: string) => {
    setInvalidCertificationCount(0);
    setInputCertificationCode(EMPTY_STRING);
    setCertificationCodeStatus(EMPTY_STRING);
    setIsAuthSectionShow(true);
    startTimer();
    onToastAuthEmailSent();

    if (authSectionRef && authSectionRef.current) {
      authSectionRef.current.focus();
    }

    const response: SendCertificationResult = await sendCertification(email);

    if (response) {
      setCertificationId(response.certificationId || EMPTY_STRING);
    } else {
      stopTimer();
    }
  };

  const resendAuthEmail = async (email: string) => {
    setInvalidCertificationCount(0);
    setInputCertificationCode(EMPTY_STRING);
    setCertificationCodeStatus(EMPTY_STRING);
    setIsAuthSectionShow(true);

    setStartTime(Date.now());
    time.current = AUTH_TIME_LIMIT;

    onToastAuthEmailSent();

    if (authSectionRef && authSectionRef.current) {
      authSectionRef.current.focus();
    }

    const response: SendCertificationResult = await sendCertification(email);

    if (response) {
      setCertificationId(response.certificationId || EMPTY_STRING);
    } else {
      stopTimer();
    }
  };

  const checkCertCode = async (
    certificationId: string,
    certificationCode: string
  ) => {
    const response = await checkCertification(
      certificationId,
      certificationCode
    );

    if (response) {
      if (response.successOrNot === SuccessOrNot.Y && response.data) {
        setIsEmailSectionComplete(true);
        resetAuthLogic();
        onToastEmailConfirmed();
        return "";
      } else {
        switch (response.statusCode) {
          case CertificationCodeStatus.EXPIRED_CERTIFICATION_CODE:
            setCertificationCodeStatus(
              CertificationCodeStatus.EXPIRED_CERTIFICATION_CODE
            );
            break;

          case CertificationCodeStatus.INVALID_CERTIFICATION_CODE:
            setCertificationCodeStatus(
              CertificationCodeStatus.INVALID_CERTIFICATION_CODE
            );
            break;

          case CertificationCodeStatus.NO_CERTIFICATION_REQUEST:
            setCertificationCodeStatus(
              CertificationCodeStatus.NO_CERTIFICATION_REQUEST
            );
            break;

          default:
            setCertificationCodeStatus(
              CertificationCodeStatus.NO_CERTIFICATION_REQUEST
            );
            break;
        }
        return certificationCodeStatus;
      }
    }
    return null;
  };

  const canSendAuthEmail = () => {
    return (
      isEmailValid &&
      emailValidationResult &&
      emailValidationResult.isExist === "N" &&
      !isAuthSectionShow
    );
  };

  const handleConfirmAuthCode = () => {
    void checkCertCode(certificationId, inputCertificationCode);
  };

  const onCompletedSection = (section: JoinWithEmailSections) => {
    activeSection.includes(section)
      ? setActiveSection([])
      : setActiveSection([section]);
  };

  const initializeEmailSection = () => {
    setEmail(EMPTY_STRING);
    setIsAutoComplete(false);
    setIsEmailValid(false);
    setEmailValidationResult(undefined);
    setCertificationId(EMPTY_STRING);
    setIsEmailSectionComplete(false);

    resetAuthLogic();
  };

  useEffect(() => {
    setIsEmailSectionComplete(false);
    if (email && email.includes("@")) {
      if (validateEmailAddress(email)) {
        setIsEmailValid(true);
        void checkIfEmailDuplicate(email);
      } else {
        setIsEmailValid(false);
        setEmailValidationResult(undefined);
      }
    } else {
      setIsEmailValid(false);
      setEmailValidationResult(undefined);
    }
  }, [email]);

  useEffect(() => {
    time.current <= 0 && setTimerStart(false);
  }, [expirationSecDisplay]);

  const renderEmailHeader = () => {
    return (
      <div className={classes.step_header} aria-label={"email section header"}>
        {isEmailSectionComplete ? (
          <button
            className={classes.step_header_btn}
            onClick={() => void onCompletedSection(JoinWithEmailSections.EMAIL)}
          >
            <span
              className={clsx([
                classes.step_header_text,
                classes.step_header_active_text,
              ])}
            >
              {email}
            </span>

            <img src={PassIcon} alt="PassIcon" />
          </button>
        ) : (
          <button
            className={classes.step_header_btn}
            onClick={() => setActiveSection([JoinWithEmailSections.EMAIL])}
          >
            <span
              className={clsx([
                classes.step_header_text,
                activeSection[0] === 0
                  ? classes.step_header_active_text
                  : classes.step_header_in_active_text,
              ])}
            >
              {t("screen.join.joinWithEmail.header")}
            </span>
          </button>
        )}
      </div>
    );
  };

  const renderEmailAutoComplete = (email: string) => {
    if (email && !email.includes("@")) {
      return (
        <div
          className={classes.auto_complete_list}
          style={{
            top:
              (emailInputRef?.current?.offsetTop ?? 0) +
              (emailInputRef?.current?.offsetHeight ?? 0),
          }}
          aria-label={"email autocomplete"}
        >
          {autoCompleteDomainArray.map((domain, index) => {
            const autoCompleteEmail: string = email.concat(domain);

            return (
              <div
                key={index}
                className={clsx(
                  classes.auto_complete_item,
                  index === autoCompleteDomainArray.length - 1 &&
                    classes.auto_complete_last_item
                )}
                onClick={() => handleEmailChange(autoCompleteEmail)}
              >
                <span className={classes.auto_complete_text}>
                  {autoCompleteEmail}
                </span>
              </div>
            );
          })}
        </div>
      );
    } else {
      setIsAutoComplete(false);
    }
  };

  const clearEmailButton = () => {
    if (email) {
      return (
        <button
          className={classes.cancel_btn}
          onClick={clearEmail}
          aria-label={"clear email"}
        >
          <img src={CancelIcon} alt="CancelIcon" />
        </button>
      );
    } else {
      return <></>;
    }
  };

  const renderEmailValidationText = (email: string) => {
    if (email && email.includes("@") && !isEmailValid) {
      return (
        <div className={classes.alert_wrap} aria-label={"invalid email"}>
          <div className={classes.alert}>
            <img src={TriangleRightRed} alt="TriangleRightRed" />
            <span className={classes.email_not_valid}>
              {t("screen.join.joinWithEmail.invalidEmailPlaceholder")}
            </span>
          </div>
        </div>
      );
    } else if (emailValidationResult) {
      if (emailValidationResult.isExist === "N") {
        return (
          <div className={classes.alert_wrap} aria-label={"email can be used"}>
            <div className={classes.alert}>
              <img src={TriangleRightGreen} alt="TriangleRightGreen" />
              <span className={classes.email_valid}>
                {t("screen.join.joinWithEmail.canUseEmailPlaceholder")}
              </span>
            </div>
          </div>
        );
      } else {
        return (
          <div className={classes.alert_wrap} aria-label={"duplicate email"}>
            <div className={classes.alert}>
              <img src={TriangleRightRed} alt="" />
              <span className={classes.email_not_valid}>
                {t("screen.join.joinWithEmail.duplicateEmailPlaceholder")}
              </span>
            </div>
          </div>
        );
      }
    } else {
      return <div className={classes.alert_wrap}></div>;
    }
  };

  const renderAuthenticationResponse = () => {
    if (certificationCodeStatus || time.current <= 0) {
      return (
        <div
          className={classes.auth_code_bottom}
          aria-label={"email authentication result"}
        >
          {certificationCodeStatus ===
            CertificationCodeStatus.EXPIRED_CERTIFICATION_CODE ||
          time.current <= 0 ? (
            <span className={classes.auth_code_bottom_text}>
              {t("screen.join.joinWithEmail.authenticationCodeOutdated")}
            </span>
          ) : certificationCodeStatus ===
            CertificationCodeStatus.INVALID_CERTIFICATION_CODE ? (
            <span className={classes.auth_code_bottom_text}>
              {t("screen.join.joinWithEmail.invalidAuthenticationCode")}
            </span>
          ) : (
            <span className={classes.auth_code_bottom_text}>
              {t("screen.join.joinWithEmail.resendAuthenticationCode")}
            </span>
          )}
        </div>
      );
    }
  };

  const renderEmailAuthSection = () => {
    if (isAuthSectionShow) {
      return (
        <div className={classes.auth_code_wrap}>
          <div className={classes.auth_code}>
            <div className={classes.auth_code_top}>
              <input
                ref={authSectionRef}
                className={classes.auth_code_input}
                value={inputCertificationCode}
                autoFocus={true}
                type="text"
                inputMode="numeric"
                maxLength={6}
                onChange={(event) => {
                  const text = event.target.value;
                  setInputCertificationCode(text);
                  if (text.length === 6) {
                    if (invalidCertificationCount === 4) {
                      CommonStore.setNotificationModalProps({
                        isVisible: true,
                        contents1: t(
                          "screen.join.joinWithEmail.invalidCertificationCount"
                        ),
                        onClickDefaultButton: () => {
                          CommonStore.resetNotificationModalProps();
                        },
                      });
                    } else {
                      void checkCertCode(certificationId, text).then(
                        (result) => {
                          if (result === "") {
                            passwordRef.current.focus();
                          } else if (
                            result ===
                            CertificationCodeStatus.INVALID_CERTIFICATION_CODE
                          ) {
                            setInvalidCertificationCount((x) => x + 1);
                          }
                        }
                      );
                    }
                  }
                }}
                aria-label={"email authentication input"}
                autoComplete={"off"}
              />
              <span className={classes.timer_text} aria-label={"timer"}>
                {expirationMinDisplay}:{expirationSecDisplay.padStart(2, "0")}
              </span>
            </div>
            {renderAuthenticationResponse()}
          </div>
          <div className={classes.auth_code_alert}>
            <img src={TriangleRightBlackIcon} alt="TriangleRightBlackIcon" />
            <span className={classes.auth_code_alert_text}>
              {t(
                "screen.join.joinWithEmail.authenticationCodeInputPlaceholder"
              )}
            </span>
            <button
              className={classes.resend}
              onClick={() => {
                timerStart
                  ? void resendAuthEmail(email)
                  : void sendAuthEmail(email);
              }}
              aria-label={"resend authentication email"}
            >
              <span className={classes.resend_text}>
                {t("screen.join.joinWithEmail.resendAuthenticationEmail")}
              </span>
            </button>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderEmail = () => {
    return (
      <div className={classes.step_body}>
        <div
          className={clsx(classes.email_wrap, classes.email_wrap_active)}
          ref={emailInputRef}
        >
          <input
            autoFocus={true}
            className={classes.email_input}
            placeholder={t("screen.join.joinWithEmail.emailInputPlaceholder")}
            // placeholderTextColor={"#999"}
            autoCapitalize="none"
            type="email"
            value={email}
            onChange={(event) => {
              setIsAutoComplete(true);
              handleEmailChange(event.target.value);
            }}
            onKeyDown={(event) => {
              if (
                event.key === "Enter" &&
                authSectionRef &&
                authSectionRef.current
              ) {
                authSectionRef.current.focus();
              }
            }}
            aria-label={"email input"}
          />

          {clearEmailButton()}
          <button
            className={clsx(
              classes.auth_btn,
              canSendAuthEmail() &&
                !isEmailSectionComplete &&
                classes.auth_btn_active
            )}
            onClick={() => {
              void sendAuthEmail(email);
            }}
            disabled={!canSendAuthEmail() || isEmailSectionComplete}
            aria-label={"send authentication email"}
          >
            <span
              className={clsx(
                classes.auth_btn_text,
                canSendAuthEmail() &&
                  !isEmailSectionComplete &&
                  classes.auth_btn_text_active
              )}
            >
              {isEmailSectionComplete
                ? t(
                    "screen.join.joinWithEmail.authenticateButtonPlaceholderThird"
                  )
                : isAuthSectionShow
                ? t(
                    "screen.join.joinWithEmail.authenticateButtonPlaceholderSecond"
                  )
                : t(
                    "screen.join.joinWithEmail.authenticateButtonPlaceholderFirst"
                  )}
            </span>
          </button>
        </div>
        {renderEmailValidationText(email)}
        {renderEmailAuthSection()}
      </div>
    );
  };

  const [password, setPassword] = useState<string>(EMPTY_STRING);
  const [passwordFocus, setPasswordFocus] = useState<boolean>(false);
  const [passwordHide, setPasswordHide] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const [showPasswordConfirm, setShowPasswordConfirm] =
    useState<boolean>(false);
  const [passwordConfirm, setPasswordConfirm] = useState<string>(EMPTY_STRING);
  const [passwordConfirmFocus, setPasswordConfirmFocus] =
    useState<boolean>(false);
  const [passwordConfirmHide, setPasswordConfirmHide] = useState<boolean>(true);

  const [isPasswordSectionComplete, setIsPasswordSectionComplete] =
    useState<boolean>(false);

  const handlePasswordChange = (text: string) => {
    setPassword(text);
    setIsPasswordValid(validatePassword(text));
  };

  const handlePasswordConfirmChange = (text: string) => {
    setPasswordConfirm(text);
    isPasswordSectionComplete && setIsPasswordSectionComplete(false);
    if (isPasswordValid && text === password) {
      setIsPasswordSectionComplete(true);
    }
  };

  const handlePasswordFocus = (isFocus: boolean) => {
    setPasswordFocus(isFocus);
  };

  const handlePasswordConfirmFocus = (isFocus: boolean) => {
    setPasswordConfirmFocus(isFocus);
  };

  const handleShowPasswordButtonPress = () => {
    setPasswordHide(!passwordHide);
  };

  const handleShowPasswordConfirmButtonPress = () => {
    setPasswordConfirmHide(!passwordConfirmHide);
  };

  const initializePasswordSection = () => {
    setPassword(EMPTY_STRING);
    setPasswordFocus(false);
    setPasswordHide(true);
    setIsPasswordValid(false);
    setShowPasswordConfirm(false);
    setPasswordConfirm(EMPTY_STRING);
    setPasswordConfirmFocus(false);
    setPasswordConfirmHide(true);
    setIsPasswordSectionComplete(false);
  };

  const handleOpenPasswordSection = () => {
    isEmailSectionComplete &&
      setActiveSection([JoinWithEmailSections.PASSWORD]);
  };

  useEffect(() => {
    if (password && isPasswordValid) {
      setShowPasswordConfirm(true);
    } else {
      setShowPasswordConfirm(false);
      setPasswordConfirm(EMPTY_STRING);
      setPasswordConfirmFocus(false);
      setPasswordConfirmHide(true);
    }
    isPasswordValid && password === passwordConfirm
      ? setIsPasswordSectionComplete(true)
      : setIsPasswordSectionComplete(false);
  }, [password, passwordConfirm, isPasswordValid]);

  const renderPasswordHeader = () => {
    return (
      <div
        className={classes.step_header}
        aria-label={"password section header"}
      >
        {isPasswordSectionComplete ? (
          <button
            className={classes.step_header_btn}
            onClick={() =>
              void onCompletedSection(JoinWithEmailSections.PASSWORD)
            }
          >
            <input
              // secureTextEntry={true}
              className={clsx([
                classes.step_header_text,
                classes.step_header_active_text,
                classes.pw_secure_header_text,
              ])}
              value={password}
              type="password"
              readOnly={true}
            />

            <img src={PassIcon} alt="PassIcon" />
          </button>
        ) : (
          <button
            className={classes.step_header_btn}
            onClick={handleOpenPasswordSection}
          >
            <span
              className={clsx([
                classes.step_header_text,
                activeSection[0] === 1
                  ? classes.step_header_active_text
                  : classes.step_header_in_active_text,
              ])}
            >
              {t("screen.join.joinPassword.header")}
            </span>
          </button>
        )}
      </div>
    );
  };

  const renderPasswordGuide = (password: string, isPasswordValid: boolean) => {
    if (!isPasswordValid) {
      const condition1 = passwordCondition1(password);
      const condition2 = passwordCondition2(password);
      const condition3 = passwordCondition3(password);
      return (
        <div className={classes.alert_wrap} aria-label={"invalid password"}>
          <div className={classes.alert}>
            {condition1 ? (
              <img
                src={PasswordConditionValidIcon}
                alt="PasswordConditionValidIcon"
              />
            ) : (
              <img
                src={PasswordConditionInactiveIcon}
                alt="PasswordConditionInactiveIcon"
              />
            )}
            <span
              className={condition1 ? classes.pw_valid : classes.pw_not_active}
            >
              {t("screen.password.passwordAuth.invalidLength")}
            </span>
          </div>

          <div className={classes.alert}>
            {condition2 ? (
              <img
                src={PasswordConditionValidIcon}
                alt="PasswordConditionValidIcon"
              />
            ) : (
              <img
                src={PasswordConditionInactiveIcon}
                alt="PasswordConditionInactiveIcon"
              />
            )}
            <span
              className={condition2 ? classes.pw_valid : classes.pw_not_active}
            >
              {t("screen.password.passwordAuth.invalidFormatChar")}
            </span>
          </div>
          {!condition3 && (
            <div className={classes.alert}>
              <img
                src={PasswordConditionInvalidIcon}
                alt="PasswordConditionInvalidIcon"
              />
              <span className={classes.pw_not_valid}>
                {t("screen.password.passwordAuth.invalidBackSlash")}
              </span>
            </div>
          )}
        </div>
      );
    } else {
      return <div className={classes.alert_wrap}></div>;
    }
  };

  const renderPasswordConfirm = (
    showPasswordConfirm: boolean,
    passwordConfirm: string,
    passwordConfirmHide: boolean
  ) => {
    if (showPasswordConfirm) {
      return (
        <div
          className={clsx([
            classes.pw_input_wrap,
            classes.pw2_input_wrap,
            passwordConfirmFocus && classes.pw_input_wrap_focus,
          ])}
        >
          <input
            className={classes.pw_input}
            placeholder={t(
              "screen.join.joinPassword.passwordConfirmInputPlaceholder"
            )}
            value={passwordConfirm}
            onChange={(event) =>
              handlePasswordConfirmChange(event.target.value)
            }
            type={passwordConfirmHide ? "password" : "text"}
            onFocus={() => {
              handlePasswordConfirmFocus(true);
            }}
            onBlur={() => {
              handlePasswordConfirmFocus(false);
            }}
            aria-label={"password confirm input"}
            name="notsearch_password"
            autoComplete={"off"}
          />
          <button
            className={classes.toggle_btn}
            onClick={handleShowPasswordConfirmButtonPress}
            aria-label={"show password confirm"}
          >
            {!passwordConfirm ? (
              <></>
            ) : passwordConfirmHide ? (
              <img src={PwHideBlackIcon} alt="PwHideBlackIcon" />
            ) : (
              <img src={PwShowPurpleIcon} alt="PwShowPurpleIcon" />
            )}
          </button>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderPasswordConfirmGuide = (
    showPasswordConfirm: boolean,
    password: string,
    passwordConfirm: string
  ) => {
    if (
      showPasswordConfirm &&
      passwordConfirm &&
      password !== passwordConfirm
    ) {
      return (
        <div
          className={classes.alert_wrap}
          aria-label={"password confirm guide"}
        >
          <div className={classes.alert}>
            <img src={PasswordConditionInvalidIcon} alt="" />
            <span className={classes.pw_not_valid}>
              {t("screen.join.joinPassword.invalidPasswordConfirmGuide")}
            </span>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderPassword = () => {
    return (
      <div className={classes.step_body}>
        <div
          className={clsx([
            classes.pw_input_wrap,
            passwordFocus && classes.pw_input_wrap_focus,
          ])}
        >
          <input
            ref={passwordRef}
            className={classes.pw_input}
            placeholder={t("screen.join.joinPassword.passwordInputPlaceholder")}
            value={password}
            type={passwordHide ? "password" : "text"}
            onChange={(event) => {
              handlePasswordChange(event.target.value);
            }}
            onFocus={() => {
              handlePasswordFocus(true);
            }}
            onBlur={() => {
              handlePasswordFocus(false);
            }}
            aria-label={"password input"}
            name="notsearch_password"
            autoComplete={"off"}
          />
          <button
            className={classes.toggle_btn}
            onClick={handleShowPasswordButtonPress}
            aria-label={"show password"}
          >
            {!password ? (
              <></>
            ) : passwordHide ? (
              <img src={PwHideBlackIcon} alt="PwHideBlackIcon" />
            ) : (
              <img src={PwShowPurpleIcon} alt="PwShowPurpleIcon" />
            )}
          </button>
        </div>

        {renderPasswordGuide(password, isPasswordValid)}
        {renderPasswordConfirm(
          showPasswordConfirm,
          passwordConfirm,
          passwordConfirmHide
        )}
        {renderPasswordConfirmGuide(
          showPasswordConfirm,
          password,
          passwordConfirm
        )}
      </div>
    );
  };

  const {
    phoneNumber,
    isPhoneNumberValid,
    isPhoneAuthSectionShow,
    isPhoneNumberSectionComplete,
    resetPhoneAuthSection,
    sendAuthPhone,
    handleChangePhoneNumber,
  } = PhoneAuthStore;

  useEffectOnlyOnce(() => {
    resetPhoneAuthSection();
    resetAuthLogic();
  });

  const handleOpenPhoneSection = () => {
    isEmailSectionComplete &&
      isPasswordSectionComplete &&
      setActiveSection([JoinWithEmailSections.PHONE_NUMBER]);
  };

  const renderPhoneHeader = () => {
    return (
      <div
        className={classes.step_header}
        aria-label={"phone number section header"}
      >
        {isPhoneNumberSectionComplete ? (
          <button
            className={classes.step_header_btn}
            onClick={() =>
              void onCompletedSection(JoinWithEmailSections.PHONE_NUMBER)
            }
          >
            <span
              className={clsx([
                classes.step_header_text,
                classes.step_header_active_text,
              ])}
            >
              {hyphenatePhoneNumber(phoneNumber)}
            </span>
            <img src={PassIcon} alt="" />
          </button>
        ) : (
          <button
            className={classes.step_header_btn}
            onClick={handleOpenPhoneSection}
          >
            <span
              className={clsx([
                classes.step_header_text,
                activeSection[0] === 2
                  ? classes.step_header_active_text
                  : classes.step_header_in_active_text,
              ])}
            >
              {t("screen.join.joinPhone.header")}
            </span>
          </button>
        )}
      </div>
    );
  };

  const renderEnterPhoneNumberGuide = () => {
    if (phoneNumber) {
      return <></>;
    } else {
      return (
        <div className={classes.alert} aria-label={"phone number guide"}>
          <img src={TriangleRightBlackIcon} alt="" />
          <span className={classes.phone_alert_text}>
            {t("screen.join.joinPhone.enterPhoneNumberGuideOne")}
            <span className={classes.bold_text}>
              {t("screen.join.joinPhone.enterPhoneNumberGuideTwo")}
            </span>
            {t("screen.join.joinPhone.enterPhoneNumberGuideThree")}
          </span>
        </div>
      );
    }
  };

  const renderEnterValidPhoneNumberGuide = () => {
    if (phoneNumber && !isPhoneNumberValid) {
      return (
        <div className={classes.alert} aria-label={"invalid phone number"}>
          <img src={TriangleRightRedIcon} alt="TriangleRightRedIcon" />
          <span className={classes.phone_not_valid}>
            {t("screen.join.joinPhone.invalidPhoneNumberGuide")}
          </span>
        </div>
      );
    } else if (isPhoneNumberValid) {
      return (
        <div className={classes.alert} aria-label={"invalid phone number"}>
          <img src={TriangleRightGreen} alt="TriangleRightGreen" />
          <span className={classes.phone_success_valid}>
            {t("screen.join.joinPhone.successPhoneNumberGuide")}
          </span>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderPhone = () => {
    return (
      <div className={classes.step_body}>
        <div
          className={clsx([
            classes.phone_input_wrap,
            classes.phone_input_wrap_focus,
          ])}
        >
          <input
            className={classes.phone_input}
            placeholder={t("screen.join.joinPhone.phoneNumberInputPlaceholder")}
            type="tel"
            value={phoneNumber}
            inputMode="numeric"
            onChange={(event) => {
              handleChangePhoneNumber(event.target.value);
            }}
            aria-label={"phone number input"}
            ref={phoneNumberInputRef}
            maxLength={13}
          />
          {!!phoneNumber && (
            <button
              className={classes.cancel_btn}
              onClick={resetPhoneAuthSection}
            >
              <img src={CancelIcon} alt="CancelIcon" />
            </button>
          )}

          <button
            className={clsx([
              classes.auth_btn,
              isPhoneNumberValid &&
                !isPhoneAuthSectionShow &&
                !isPhoneNumberSectionComplete &&
                classes.auth_btn_active,
            ])}
            onClick={() => {
              // TODO test 코드
              const authType: "phone" | "sms" =
                Platform.OS === PlatformTypes.IOS_APP ? "sms" : "phone";
              void sendAuthPhone(authType);
            }}
            disabled={
              !isPhoneNumberValid ||
              isPhoneAuthSectionShow ||
              isPhoneNumberSectionComplete
            }
            aria-label={"send authentication phone"}
          >
            <span
              className={clsx([
                classes.auth_btn_text,
                isPhoneNumberValid &&
                  !isPhoneAuthSectionShow &&
                  !isPhoneNumberSectionComplete &&
                  classes.auth_btn_text_active,
              ])}
            >
              {t("screen.join.joinPhone.authenticateButtonPlaceholderFirst")}
            </span>
          </button>
        </div>
        <div className={classes.alert_wrap}>
          {renderEnterPhoneNumberGuide()}
          {renderEnterValidPhoneNumberGuide()}
        </div>
        {isPhoneAuthSectionShow && <PhoneAuthSection />}
      </div>
    );
  };

  const { termsAgree, isRequiredTermsAgree, setAllTermsAgreeN } = TermsStore;

  const handleOpenTermAgreeSection = () => {
    isEmailSectionComplete &&
      isPasswordSectionComplete &&
      isPhoneNumberSectionComplete &&
      setActiveSection([JoinWithEmailSections.TERMS_AGREE]);
  };

  useEffect(() => {
    if (!isEmailSectionComplete)
      setActiveSection([JoinWithEmailSections.EMAIL]);
    else if (!isPasswordSectionComplete)
      setActiveSection([JoinWithEmailSections.PASSWORD]);
    else if (!isPhoneNumberSectionComplete) {
      setActiveSection([JoinWithEmailSections.PHONE_NUMBER]);
      phoneNumberInputRef &&
        phoneNumberInputRef.current &&
        phoneNumberInputRef.current.focus();
    } else setActiveSection([JoinWithEmailSections.TERMS_AGREE]);
  }, [
    isEmailSectionComplete,
    isPasswordSectionComplete,
    isPhoneNumberSectionComplete,
    isRequiredTermsAgree,
  ]);

  const renderAgreeHeader = () => {
    return (
      <div
        className={classes.step_header}
        aria-label={"terms agree section header"}
      >
        {isRequiredTermsAgree ? (
          <button
            className={classes.step_header_btn}
            onClick={() =>
              void onCompletedSection(JoinWithEmailSections.TERMS_AGREE)
            }
          >
            <span
              className={clsx([
                classes.step_header_text,
                classes.step_header_active_text,
              ])}
            >
              {t("screen.join.joinTermsAgree.header")}
            </span>
            <img src={PassIcon} alt="" />
          </button>
        ) : (
          <button
            className={classes.step_header_btn}
            onClick={handleOpenTermAgreeSection}
          >
            <span
              className={clsx([
                classes.step_header_text,
                activeSection[0] === 3
                  ? classes.step_header_active_text
                  : classes.step_header_in_active_text,
              ])}
            >
              {t("screen.join.joinTermsAgree.header")}
            </span>
          </button>
        )}
      </div>
    );
  };

  const renderAgree = () => {
    return (
      <div className={classes.step_body_term}>
        <TermsAgree />
      </div>
    );
  };

  const sections: IAccordionSection[] = [
    {
      title: renderEmailHeader(),
      content: renderEmail(),
    },
    {
      title: renderPasswordHeader(),
      content: renderPassword(),
    },
    {
      title: renderPhoneHeader(),
      content: renderPhone(),
    },
    {
      title: renderAgreeHeader(),
      content: renderAgree(),
    },
  ];

  const renderHeader = (section: IAccordionSection) => {
    return <div className={classes.step_item}>{section.title}</div>;
  };

  const renderContent = (section: IAccordionSection) => {
    return section.content;
  };

  const updateSections = (activeSections: any) => {
    setIsAutoComplete(false);
  };

  const allInformationFilled = () => {
    return (
      isEmailSectionComplete &&
      isPasswordSectionComplete &&
      isPhoneNumberSectionComplete &&
      isRequiredTermsAgree
    );
  };

  const initializeAllSections = () => {
    initializeEmailSection();
    initializePasswordSection();
    resetPhoneAuthSection();
    setActiveSection([JoinWithEmailSections.EMAIL]);
    setAllTermsAgreeN();
  };

  const handleJoinBtnClick = async () => {
    setLoading(true);
    void RecommenderStore.recommenderExist();

    const tempMember = await getTempMemberFromStorage();
    const createMemberRequest: AddMemberRequest = {
      emailAddress: email,
      certificationId: certificationId,
      password: password,
      phoneNumber: deHyphenatePhoneNumber(phoneNumber),
      terms: termsAgree.map(({ termsId, termsAgreeYn }) => ({
        termsId,
        termsAgreeYn: termsAgreeYn ? AllowTypeCode.Y : AllowTypeCode.N,
      })),
      tempMemberUuid: tempMember?.tempMemberUUID,
    };

    const response: CommonResponse = await createMember(createMemberRequest);

    if (response?.successOrNot === SuccessOrNot.Y) {
      if (response.data?.voltMessage === "Success") {
        MainStore.setShowMemberJoinVoltSavePop(true);
      }
      const emailLoginResponse = await emailLogin(email, password);
      const userInfo = {
        ...emailLoginResponse.data,
        emailAddress: email,
        loginType: SocialTypeCode.EMAIL,
      };

      if (userInfo) {
        const sessionUser = { ...userInfo };

        await setSessionUserInStorage(sessionUser);
        await localStorage.setItem(LAST_LOGIN_TYPE, SocialTypeCode.EMAIL);
        const goToNextStep = async () => {
          if (userInfo.nickname) {
            //NOTE : 둘러보기 후 회원으로 전환됨
            if (tempMember?.profileUrl) {
              //NOTE : 둘러보기 때 프로필 설정을 마쳤음
              const res = await updateMember({
                profileUrl: tempMember.profileUrl,
              });
              if (res.successOrNot === SuccessOrNot.Y) {
                await mergeAndSetSessionUserInStorage({
                  profileUrl: tempMember.profileUrl,
                });
              }

              if (
                RecommenderStore.recommenderYn === "Y" ||
                getStringRemoteConfig("show_recommend_input") === "true"
              ) {
                // reset("RecommenderScreen");
              } else {
                // reset("MainTab", { screen: "HomeStack" });
              }
            } else if (tempMember?.profileImageFromGallary) {
              //NOTE : 둘러보기 때 갤러리 사진을 선택하여 프로필 설정을 마쳤음
              const filePath = await ProfileStore.uploadFileAndGetFilePath(
                tempMember?.profileImageFromGallary
              );
              const res = await updateMember({ profileUrl: filePath });

              if (res.successOrNot === SuccessOrNot.Y) {
                await mergeAndSetSessionUserInStorage({ profileUrl: filePath });
              }
              if (
                RecommenderStore.recommenderYn === "Y" ||
                getStringRemoteConfig("show_recommend_input") === "true"
              ) {
                // reset("RecommenderScreen");
              } else {
                // reset("MainTab", { screen: "HomeStack" });
              }
            } else {
              //NOTE : 둘러보기 때 프로필 설정을 하지 않음
              if (
                RecommenderStore.recommenderYn === "Y" ||
                getStringRemoteConfig("show_recommend_input") === "true"
              ) {
                // reset("RecommenderScreen", { nextScreen: "ProfileSetting" });
              } else {
                goTo("/ProfileSetting");
              }
            }
            await removeTempMemberFromStorage();
          } else {
            //NOTE : 둘러보기 없이 새로 가입함
            // reset("/PreferenceScreen");
            goTo("/PreferenceScreen", { replace: true });
          }
        };
        setLoading(false);
        void goToNextStep();
      } else {
        alert(t("screen.login.message.loginFail"));
      }
    } else {
      if (response?.statusCode === JoinStatusCode.EXPIRATION_CERTIFICATION) {
        onToastCertificationExpired();
      }
      initializeAllSections();
      setLoading(false);
    }
  };

  const renderJoinWithEmail = () => {
    return (
      <>
        <div className={classes.join_email} aria-label={"join with email"}>
          <div className={classes.header} aria-label={"join with email header"}>
            <TitleHeader
              wrapperRef={headerRef}
              title={t("screen.join.header")}
              isBack={true}
            />
          </div>
          <div className={classes.contents_box}>
            <div
              className={classes.step_list}
              aria-label={"join with email contents"}
            >
              <Accordion
                sections={sections}
                activeSections={activeSection}
                renderHeader={renderHeader}
                renderContent={renderContent}
                onChange={updateSections}
                underlayColor={"#fff"}
              />
            </div>
          </div>
          <div className={classes.space_bottom} />

          <div className={classes.footer}>
            <button
              className={clsx([
                classes.btn_1,
                allInformationFilled() ? classes.active : classes.in_active,
              ])}
              onClick={() => {
                void handleJoinBtnClick();
              }}
              disabled={!allInformationFilled()}
              aria-label={"join with current info"}
            >
              <span
                className={clsx([
                  classes.btn_1_txt,
                  allInformationFilled()
                    ? classes.txt_active
                    : classes.txt_in_active,
                ])}
              >
                {t("screen.join.footer")}
              </span>
            </button>
          </div>
        </div>

        {isAutoComplete && renderEmailAutoComplete(email)}
      </>
    );
  };

  return renderJoinWithEmail();
});

export default JoinWithEmailScreen;
