import { useEffect, useState } from "react";
import PostWriteStore, { PostLocalSave } from "../../store/PostWriteStore";
import { observer } from "mobx-react";
import AuthStore from "../../store/AuthStore";
import MyHomeStore from "../../store/MyHomeStore";
import { Mission, MissionParticipateRequest } from "../../api/mission/model";
import { useTracker } from "../../hooks/tracker";
import { NewProduct } from "../../api/product/model";
import { useLocation, useNavigationType } from "react-router-dom";
import PostWrite from "./presenter/PostWrite";
import { FeedMode, POST_BLANK } from "../../constants/Feed";

const PostWriteScreen = observer(() => {
  const { sessionUser } = AuthStore;
  const userId = String(sessionUser?.memberUuid);

  const { getAccountStore } = MyHomeStore;
  const accountStore = getAccountStore(userId);
  const [tracker] = useState(useTracker());
  const location = useLocation();
  const navigationType = useNavigationType();
  const [mode, setMode] = useState<FeedMode>(FeedMode.WRITE);
  useEffect(() => {
    tracker.track("create_post", {});
  }, [tracker]);

  useEffect(() => {
    const init = () => {
      const {
        setWholeTempData,
        getRecommendTag,
        setMissionData,
        addTag,
        addProduct,
      } = PostWriteStore;

      const params = location.state;
      const tempSavedData: PostLocalSave = params?.data;
      const tags = params?.tags as string[];
      const productInfo: NewProduct = params?.productInfo;

      setWholeTempData(tempSavedData);

      console.log("==params-missionData", params?.missionData);
      console.log("==params-missionParam", params?.missionParam);

      if (params?.missionData && params?.missionParam) {
        setMode(FeedMode.MISSION_POST_WRITE);
        setMissionData(
          params?.missionData as Mission,
          params?.missionParam as MissionParticipateRequest
        );
      }

      const tempProductIds: number[] = [];
      tempSavedData?.products?.forEach(
        (item) =>
          item?.product?.productId &&
          !item?.product?.openApiFlag &&
          tempProductIds.push(item.product.productId)
      );
      void getRecommendTag(tempProductIds);

      if (tags !== undefined) {
        tags.forEach((tag) => addTag(String(tag)));
      }

      if (params?.productInfo) {
        addProduct(productInfo);
      }
    };

    (navigationType !== "POP" ||
      JSON.stringify(PostWriteStore.wholeTempData) ===
        JSON.stringify(POST_BLANK)) &&
      init();
  }, [location.state, accountStore, navigationType]);

  return (
    <div aria-label="Post write screen">
      <PostWrite mode={mode} />
    </div>
  );
});

export default PostWriteScreen;
