import { ReactComponent as ImgNoti } from '../../assets/images/img_noti.svg'
import { observer } from 'mobx-react'
import classes from './LifecareErrorModal.module.scss'
import ContentsNotificationModal, {
  ContentsNotificationModalProps,
} from '../../components/modals/ContentsNotificationModal'
import { Trans } from 'react-i18next'

export interface LifecareErrorModalProps
  extends ContentsNotificationModalProps {
  onRequestClose?: () => void
  contentTitle?: string
  content?: string
}

const LifecareErrorModal = observer((props: LifecareErrorModalProps) => {
  return (
    <ContentsNotificationModal {...props} isVisible={true}>
      <div className={classes.modal_page_view}>
        <ImgNoti />
        <text className={classes.txt_page_title}>{props.contentTitle}</text>
        <span className={classes.txt_page_content}>{props.content}</span>
      </div>
    </ContentsNotificationModal>
  )
})

export default LifecareErrorModal
