import { observer } from "mobx-react";
import React, { useState, useRef, useEffect, useCallback } from "react";
import "./QnaDetailContents.scss";

import { Product } from "../../../api/product/model";
import { Tag } from "../../../api/tag/model";
import { File, toHeight } from "../../../api/file/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
// import CustomInView from "../../../components/common/CustomInView";
import QnaDetailStore from "../../../store/QnaDetailStore";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import VideoPlayerWithFallback from "../../../components/common/VideoPlayerWithFallback";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import CustomCarousel from "../../../components/common/CustomCarousel";
import { ReactComponent as MallTag } from "../../../assets/icons/mall_sale_tag.svg";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { ReactComponent as UpTag } from "../../../assets/icons/up_product_label.svg";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";

const QnaDetailContents = observer(
  ({
    selfWidth,
    feedId,
    qnaDetailStore,
  }: {
    selfWidth: number;
    feedId: number;
    qnaDetailStore: QnaDetailStore;
  }) => {
    const { t } = useTranslation();
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    const show_up_product_tag = getStringRemoteConfig("show_up_product_tag");

    const cardSlidersettings = {
      className: "center",
      infinite: false,
      centerPadding: "60px",
      swipeToSlide: true,
      arrows: false,
      dots: false,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: true,
    };

    const FALLBACK_IMAGE_URL =
      PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";

    const { qnaDetail, tagList, productList } = qnaDetailStore;

    const mediaList: File[] = qnaDetail.files as File[];

    const videoRef: any[10] = useRef([]);

    const [sliderIdx, setSliderIdx] = useState(0);

    // const isFocused = useIsFocused();

    // useEffect(() => {
    //   if (!isFocused) {
    //     videoRef.current.forEach((video: VideoPlayer) => {
    //       video?.pause();
    //     });
    //   }
    // }, [isFocused, videoRef]);

    const [audioMute, setAudioMute] = useState(true);

    const toHeightContent = useCallback(
      (item: File) => {
        let h = selfWidth;
        if (item) {
          h = toHeight(item.fileResolution ?? "", selfWidth);
        }
        return h;
      },
      [selfWidth]
    );

    const [contentHeight, setContentHeight] = useState(
      mediaList.length == 0 ? selfWidth : toHeightContent(mediaList[0])
    );

    useEffect(() => {
      const h = toHeightContent(mediaList[0]);
      if (mediaList.length > 0) setContentHeight(h);
    }, [mediaList, toHeightContent]);

    const renderTagList = () => {
      return (
        tagList.length > 0 && (
          <div className="tag_list" aria-label={"Qna detail tag list"}>
            {tagList.map((tag: Tag) => {
              return (
                <TouchableWithAuthCheck
                  className="tag_item"
                  key={tag.tagId}
                  onPress={() => {
                    CommonStore.callActHistory(
                      ActHistoryTypeCode.TAG_CLICK,
                      (tag.tagId ?? 0).toString(),
                      ActPageCode.QNA_DETAIL,
                      undefined,
                      (qnaDetail.feedId ?? 0).toString()
                    );

                    goTo(`/tag/${tag.tagId}`, {
                      state: { tagId: tag.tagId },
                    });
                  }}
                >
                  <p className="tag_text">#{tag.tagName}</p>
                </TouchableWithAuthCheck>
              );
            })}
          </div>
        )
      );
    };
    let defh = selfWidth;
    if (mediaList.length > 0) {
      defh = toHeightContent(mediaList[0]);
    }
    const renderProductList = () => {
      return (
        productList.length > 0 && (
          <div className="home_app_list" aria-label={"Qna detail product list"}>
            <div className="slider-container">
              <Slider {...cardSlidersettings}>
                {productList.map((product: Product, index: number) => {
                  return (
                    !product?.openApiFlag &&
                    product?.productId !== undefined &&
                    product?.productId > 0 && (
                      <div
                        className="home_app_item"
                        // className="[
                        //   home_app_item,
                        //   home_app_first_item,
                        //   { width: responsiveScreenWidth(71.7) },
                        // ]}
                        key={product.productId}
                        onClick={() => {
                          CommonStore.callActHistory(
                            ActHistoryTypeCode.PRODUCT_READ,
                            product.productId.toString(),
                            ActPageCode.QNA_DETAIL,
                            undefined,
                            index.toString()
                          );
                          goTo(`/Product/${product.productId}`);
                        }}
                      >
                        <FastImageWithFallback
                          className="home_app_img"
                          // className="[
                          //   home_app_img,
                          //   product?.thumbnailYn === "Y" && home_app_rep_img,
                          // ]}
                          source={{
                            uri: `${PUBLIC_BUCKET_URL}${
                              product.productFiles[0]?.thumbnailFilePath?.replace(
                                "THUMBNAIL",
                                "ORIGINAL"
                              ) || ""
                            }`,
                          }}
                          fallbackImageUri={FALLBACK_IMAGE_URL}
                        />
                        <div className="home_app_info">
                          <p className="home_app_text1">
                            [{product.brandNameKor}]{product.productName}
                          </p>
                          <p className="home_app_text2">
                            {product.productModelName}
                          </p>
                          <div style={{ display: "flex" }}>
                            {show_up_product_tag === "true" &&
                              product.upYn &&
                              product.upYn == "Y" && (
                                <div style={{ marginTop: 4, marginRight: 4 }}>
                                  <UpTag />
                                </div>
                              )}
                            <div className="on_sale_tag_wrap">
                              <div className="mall_tag">
                                <MallTag />
                              </div>
                              <p className="on_sale_tag_text">
                                {t("screen.main.label.onSale")}
                              </p>
                            </div>
                            {product.lifecareProductId &&
                              product.lifecareProductId !== null && (
                                <div className="on_sale_tag_wrap">
                                  <div className="mall_tag">
                                    <MallTag />
                                  </div>
                                  <p className="on_sale_tag_text">
                                    {t("screen.main.label.onSale")}
                                  </p>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}
              </Slider>
            </div>
          </div>
        )
      );
    };

    // Slider setting
    const settings = {
      dots: mediaList && mediaList.length > 1,
      infinite: false,
      arrows: false,
    };

    return (
      <div className="user_qna_detail_body" aria-label={"Qna detail content"}>
        <p className="title_text" aria-label={"Qna detail title"}>
          {qnaDetail.feedTitle}
        </p>
        <div className="small_bar" />
        <div className="contents_wrap" aria-label={"Qna detail text"}>
          <p className="contents_text">{qnaDetail.contents}</p>
        </div>

        {renderTagList()}
        {selfWidth > 0 && mediaList && mediaList.length > 0 && (
          <div className="qna_detail_slider_wrap">
            <Slider
              afterChange={(currentIdx) => setSliderIdx(currentIdx)}
              {...settings}
            >
              {mediaList &&
                mediaList.map((item, index) => {
                  const filePath: string = item?.filePath;
                  const thumbnailPath: any = item?.thumbnailFilePath;

                  const sourceUri: string = PUBLIC_BUCKET_URL + filePath;
                  const thumbnailUri: string =
                    PUBLIC_BUCKET_URL + thumbnailPath;
                  return (
                    <div key={index} className="qna_detail_slider_item">
                      {item?.fileType === "VIDEO" ? (
                        <VideoPlayerWithFallback
                          sourceUri={encodeURI(sourceUri)}
                          defAudioMute={audioMute}
                          onMute={(mute: boolean) => {
                            setAudioMute(mute);
                          }}
                          disabled={!(index === sliderIdx)}
                          //resizeOk={true}
                          carouselIdx={sliderIdx}
                          thumbnailurl={encodeURI(thumbnailUri)}
                          keystring={feedId.toString()}
                          aria-label={`post detail video content-${sliderIdx}`}
                        />
                      ) : (
                        <div key={index}>
                          <FastImageWithFallback
                            className="image_item"
                            index={index}
                            carouselIdx={sliderIdx}
                            source={{
                              uri:
                                PUBLIC_BUCKET_URL +
                                encodeURI(item.thumbnailFilePath as string),
                            }}
                            fallbackImageUri={
                              PUBLIC_BUCKET_URL +
                              encodeURI(item.thumbnailFilePath as string)
                            }
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
            </Slider>
            {/* <CustomCarousel
              data={mediaList}
              renderItem={renderItem}
              onSnapToItem={(idx) => {
                setCarouselIdx(idx);
              }}
              showDotIndicator
            /> */}
          </div>
        )}
        {renderProductList()}
      </div>
    );
  }
);

export default QnaDetailContents;
