export const validateNicknameFormat = (nickname: string): boolean => {
  const regExp = /^[a-z0-9._]{0,15}$/g;

  return regExp.test(nickname);
};

export const validateRecommenderNicknameFormat = (
  nickname: string
): boolean => {
  const regExp = /^[aA-zZ0-9._]{0,15}$/g;

  return regExp.test(nickname) && nickname.length >= 4;
};
