import { BannerLinkInfo } from "../api/banner/model";
import { LinkType } from "../constants/Banner.enum";

export const queryParamFromBannerLinkInfo = (linkInfo: BannerLinkInfo) => {
  return `linkType=${linkInfo.linkType}&contentType=${
    linkInfo.contentType
  }&contentId=${linkInfo.contentId || 0}`;
};

export const parseQueryParam = (queryParams: string): BannerLinkInfo => {
  const bannerLinkInfo: BannerLinkInfo = {
    linkType: "",
    contentType: "",
    contentId: 0,
    screen: "",
  };

  if (queryParams) {
    const params: string[] = queryParams.split("&");

    const linkType: string = params[0].split("=")[1];
    const isAppInternal: boolean = params[1].split("=")[0] === "contentType";

    bannerLinkInfo.linkType = linkType;

    if (linkType === LinkType.INTERNAL && isAppInternal) {
      bannerLinkInfo.contentType = params[1].split("=")[1];
      bannerLinkInfo.contentId = Number(params[2].split("=")[1]);
    } else {
      //친구 초대 화면 강제 네비게이션 처리를 위해 예외처리 추가함.
      //어드민에서 등록시 외부 URL / NAVIGATION&contentType=FRIEND_RECOMMEND 와 같은 형태로 등록해야함.
      const linkUrl = params[1].split("=")[1];
      if (
        params.length >= 3 &&
        linkUrl.includes("NAVIGATION") &&
        params[2].split("=").length >= 2
      ) {
        bannerLinkInfo.contentType = params[2].split("=")[1];
        bannerLinkInfo.linkUrl = linkUrl;
      } else {
        bannerLinkInfo.linkUrl = linkUrl;
      }
    }
  }

  return bannerLinkInfo;
};
