// import { ActivityIndicator, Platform, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { ReactComponent as NothingSearchGridImage } from "../../../../assets/images/nodata_search_grid_bg.svg";
// import React, { useCallback } from "react";
import { Arranges, Colors, FlexBoxs, Texts } from "../../../../assets/styles";
import { ReactComponent as Arrow } from "../../../../assets/icons/arrow_right_purple3.svg";
import PostRecommendUser from "../following/PostRecommendUser";
// import { navigate } from "../../../../navigation/NavigationFunction";
import { useTranslation } from "react-i18next";
import { Styles } from "../../../../assets/types/Style";
import classes from "./PostEmpty.module.scss";
import { getSessionUserFromStorage } from "../../../../service/Session.service";
import JoinOverlapComponent from "../../../../components/common/JoinOverlapComponent";
import { useEffect, useRef } from "react";
import { goTo } from "../../../../hooks/navigate/NavigateFunction";
import CommonStore from "../../../../store/CommonStore";

const PostEmpty = ({
  tabIdx,
  userId,
  isLoading,
}: {
  tabIdx: number;
  userId: string;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const isAuthenticated = useRef<boolean>(false);

  useEffect(() => {
    getSessionUserFromStorage().then((res) => {
      isAuthenticated.current = res !== null ? true : false;
    });
  }, [isAuthenticated]);

  return (
    <div className={classes.nothing_search_img}>
      <NothingSearchGridImage />
      {tabIdx == 2 ? (
        <>
          <div
            style={{ display: isLoading ? "none" : "flex" }}
            className={classes.empty_wrap}
          >
            {isAuthenticated.current ? (
              <>
                <span className={classes.empty_txt}>
                  {t("screen.post.message.emptyFollow1")}
                  {"\n"}
                  {t("screen.post.message.emptyFollow2")}
                </span>
                <PostRecommendUser userId={userId} />
              </>
            ) : (
              <JoinOverlapComponent>
                <div style={{ width: "100%", height: "380px" }} />
              </JoinOverlapComponent>
            )}
          </div>
        </>
      ) : (
        <>
          <div
            style={{ display: isLoading ? "none" : "flex" }}
            className={classes.empty_wrap}
          >
            <span className={classes.empty_search}>
              {t("screen.post.message.emptyPost")}
            </span>
            <div
              className={classes.empty_btn_wrap}
              onClick={() => {
                goTo("/Search");
              }}
            >
              <span className={classes.empty_btn_text}>검색하기</span>
              <Arrow />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PostEmpty;
