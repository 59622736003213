import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { TitleHeader } from '../../../../components/common'
import AlarmItem from './AlarmItem'
import { NotificationParentTypeCode } from '../../../../constants/Settings'
import SettingStore from '../../../../store/SettingStore'
import AuthStore from '../../../../store/AuthStore'
import TermsStore from '../../../../store/TermsStore'
import { TermsTypeCode } from '../../../../constants/Terms.enum'
import classes from './AlarmSetting.module.scss'
import TermsOfUseModal, {
  TermsDetailProps,
} from '../../../../components/modals/TermsOfUseModal'
import { flex_1 } from '../../../../assets/styles/flexBoxs'

const AlarmSetting = observer(() => {
  const { t } = useTranslation()
  const { alarmList } = SettingStore

  const getYn = (yn: string) => {
    return yn === 'Y' ? true : false
  }

  const getPromotionAll = () => {
    return (
      alarmList.emailAllowYn === 'Y' ||
      alarmList.smsAllowYn === 'Y' ||
      alarmList.pushAllowYn === 'Y'
    )
  }

  const defaultTermsDetailProps = {
    show: false,
    termsId: '',
    termsTypeCode: '',
    onClose: () => {},
  }

  const [termsDetailProps, setTermsDetailProps] = useState<TermsDetailProps>(
    defaultTermsDetailProps
  )

  const onCloseTermsOfUseModal = () => {
    setTermsDetailProps(defaultTermsDetailProps)
  }

  return AuthStore.sessionUser?.memberUuid ? (
    <div style={flex_1}>
      <TitleHeader title={t('screen.setting.label.alarm')} />
      <div className={classes.wrapper}>
        <div className={classes.benefit_alarm}>
          <AlarmItem
            alarmTitle={t('screen.setting.label.promotionAlarm')}
            code={NotificationParentTypeCode.PROMOTION_ALL}
            ynValue={getPromotionAll()}
            last={true}
            alarmTitleStyle={{
              fontSize: 16,
              fontWeight: 500,
              color: '#222',
              letterSpacing: '-0.72',
            }}
          />
          <div className={classes.info}>
            <span className={classes.alarm_setting_text2}>
              {t('screen.setting.message.benefitAlarmInfo')}
            </span>
          </div>
          <div className={classes.info_alert}>
            <span className={classes.alarm_setting_text3}>
              {t('screen.setting.message.promotionInfo1')}
              <span
                className={classes.alarm_setting_text4}
                onClick={() => {
                  const term = TermsStore.lastTerms.find(
                    (x) => x.termsTypeCode === TermsTypeCode.PROMOTION_EMAIL
                  )
                  term &&
                    setTermsDetailProps({
                      show: true,
                      termsId: term.termsId,
                      termsTypeCode: term.termsTypeCode,
                      onClose: () => onCloseTermsOfUseModal(),
                    })
                }}
              >
                {t('screen.setting.message.promotionInfo2')}
              </span>
              {t('screen.setting.message.promotionInfo3')}
            </span>
          </div>
          <AlarmItem
            alarmTitle={t('screen.setting.label.email')}
            code={NotificationParentTypeCode.EMAIL}
            ynValue={getYn(alarmList.emailAllowYn)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.sms')}
            code={NotificationParentTypeCode.SMS}
            ynValue={getYn(alarmList.smsAllowYn)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.push')}
            code={NotificationParentTypeCode.PUSH}
            ynValue={getYn(alarmList.pushAllowYn)}
            last={true}
          />
        </div>

        <div className={classes.bar} />

        <div className={classes.action_alarm}>
          <div className={classes.title}>
            <span className={classes.alarm_setting_text1}>
              {t('screen.setting.label.actionAlarm')}
            </span>
          </div>
          <div className={classes.info}>
            <span className={classes.alarm_setting_text2}>
              {t('screen.setting.message.actionAlarmInfo')}
            </span>
          </div>
          <AlarmItem
            alarmTitle={t('screen.setting.label.likePost')}
            code={NotificationParentTypeCode.MEMBER_LIKE_MY_WRITING}
            ynValue={getYn(alarmList.memberLikeMyWriting)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.commentPost')}
            code={NotificationParentTypeCode.MEMBER_COMMENT_MY_WRITING}
            ynValue={getYn(alarmList.memberCommentMyWriting)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.mention')}
            code={NotificationParentTypeCode.MEMBER_MENTION_ME}
            ynValue={getYn(alarmList.memberMentionMe)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.newFollower')}
            code={NotificationParentTypeCode.NEW_FOLLOWER}
            ynValue={getYn(alarmList.newFollower)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.newPostFromFollower')}
            code={NotificationParentTypeCode.NEW_WRITING_FROM_FOLLOWER}
            ynValue={getYn(alarmList.newWritingFromFollower)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.voltGainAndSpend')}
            code={NotificationParentTypeCode.MY_VOLT}
            ynValue={getYn(alarmList.myVolt)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.mission')}
            code={NotificationParentTypeCode.MY_MISSION_PARTICIPATION}
            ynValue={getYn(alarmList.myMissionParticipation)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.statusChange')}
            code={NotificationParentTypeCode.MY_STATUS_CHANGE}
            ynValue={getYn(alarmList.myStatusChange)}
          />
          <AlarmItem
            alarmTitle={t('screen.setting.label.myInquire')}
            code={NotificationParentTypeCode.MY_INQUIRY}
            ynValue={getYn(alarmList.myInquiry)}
            last={true}
          />
        </div>
      </div>

      <TermsOfUseModal termsDetailProps={termsDetailProps} />
    </div>
  ) : (
    <>
      <TitleHeader title={t('screen.setting.label.alarm')} />
      <div>
        <div className={classes.benefit_alarm}>
          <div className={classes.title}>
            <span className={classes.alarm_setting_text1}>
              {t('screen.setting.label.benefitAlarm')}
            </span>
          </div>
          <div className={classes.info}>
            <span className={classes.alarm_setting_text2}>
              {t('screen.setting.message.benefitAlarmInfo')}
            </span>
          </div>

          <AlarmItem
            alarmTitle={t('screen.setting.label.push')}
            code={NotificationParentTypeCode.PUSH}
            ynValue={getYn(alarmList.pushAllowYn)}
            last={false}
          />
        </div>
      </div>
    </>
  )
})

export default AlarmSetting
