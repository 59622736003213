import { ReactComponent as ImgNoti } from '../../assets/images/img_noti.svg'
import { observer } from 'mobx-react'
import classes from './LifecareMaintenanceModal.module.scss'
import ContentsNotificationModal, {
  ContentsNotificationModalProps,
} from '../../components/modals/ContentsNotificationModal'

const LifecareMaintenanceModal = observer(
  (props: ContentsNotificationModalProps) => {
    return (
      <ContentsNotificationModal {...props}>
        <div className={classes.centered_view}>
          <div className={classes.modal_view}>
            <div className={classes.modal_content_wrap}>
              <ImgNoti />
              <text className={classes.txt_content_title}>
                서비스 점검 중 입니다.
              </text>
              <text className={classes.txt_content}>
                더 나은 서비스 제공을 위해{'\n'}서비스 점검 중으로{'\n'}일부
                서비스를 사용할 수 없습니다.{'\n'}잠시 후에 다시 시도해주세요.
              </text>
            </div>
          </div>
        </div>
      </ContentsNotificationModal>
    )
  }
)

export default LifecareMaintenanceModal
