import { observer } from "mobx-react";
import React from "react";
import { styles } from "../TagScreen";
import RelatedProductItem from "./RelatedProductItem";
import { RelatedProduct } from "../../../api/product/model";
import { useTranslation } from "react-i18next";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import Slider, { Settings } from "react-slick";
import "./RelatedProductSlide.scss";

const RelatedProductSlide = observer(
  ({
    productId,
    productName,
    list,
    totalCount,
  }: {
    productId: number;
    productName: string;
    list: RelatedProduct[];
    totalCount: number;
  }) => {
    const { t } = useTranslation();
    const settings: Settings = {
      infinite: false,
      arrows: false,
      dots: false,
      variableWidth: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      focusOnSelect: true,
    };

    return (
      <div id="relation_product_slide">
        <div className="relation_product_header">
          <div className="relation_product_header_text1">
            {t("screen.tag.label.relatedProduct")}{" "}
            <span className="relation_product_header_text2">
              {totalCount ?? 0}
            </span>
          </div>
          <div
            onClick={() => {
              goTo(`/products/${productId}/relates`, {
                state: { productId: productId, productName: productName },
              });
            }}
          >
            <div className="relation_product_header_text3" style={styles.relation_product_header_text3}>
              {t("screen.tag.button.more")}
            </div>
          </div>
        </div>
        <Slider {...settings}>
          {list &&
            list.length > 0 &&
            list.map((item, index) => {
              return (
                <RelatedProductItem item={item} index={index} key={index} />
              );
            })}
        </Slider>
      </div>
    );
  }
);

export default RelatedProductSlide;
