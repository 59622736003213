import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { SafeAreaView, StatusBar, StyleSheet, View } from "react-native";
import { observer } from "mobx-react";
import MyAppliancesMain from "./presenter/MyAppliancesMain";
import MyAppliancesStore from "../../store/MyAppliancesStore";
//import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Arranges, Colors, FlexBoxs } from "../../assets/styles";
//import { Color } from "../../assets/styles/variable";
import { Styles } from "../../assets/types/Style";

const MyAppliancesScreen = observer((data: any) => {
  const location = useLocation();
  const params = location.state;
  const memberUuid: string = params?.targetMemberUuid || "";
  const nickname: string = params?.nickname || "";

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    MyAppliancesStore.clear();
    setIsLoading(true);
    MyAppliancesStore.setTargetMemberUuid(memberUuid);
    void MyAppliancesStore.initMyProduct().then(() => {
      setIsLoading(false);
    });
  }, [memberUuid]);

  return (
    <>
      {/* <StatusBar backgroundColor={Color.WHITE} barStyle="dark-content" /> */}
      <div
        style={{
          ...styles.header,
          //, paddingTop: useSafeAreaInsets().top
        }}
      />
      <MyAppliancesMain
        targetMemberUuid={memberUuid}
        nickname={nickname ?? ""}
        isLoading={isLoading}
      />
    </>
  );
});

export default MyAppliancesScreen;

const styles: Styles = {
  header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.between,
    ...Colors.background_fff,
  },
  wrapper: {
    ...Colors.background_fff,
    // flex: 1,
  },
};
