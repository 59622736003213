import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Colors, Texts } from "../../../assets/styles";
import { observer } from "mobx-react";
import MainStore from "../../../store/MainStore";
import { ContentType, LinkType } from "../../../constants/Banner.enum";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import { BannerInfo, BannerLinkInfo } from "../../../api/banner/model";
// import { openInAppBrowser } from "../../../utils/common";
import { useTracker } from "../../../hooks/tracker";
// import { useRemoteConfigValue } from "../../../hooks/UseRemoteConfig";
import AuthStore from "../../../store/AuthStore";
// import { goToLifecareProductPage } from "../../../utils/mall";
import "./MainBanner.scss";
import PostDetailStore from "../../../store/PostDetailStore";
import CommonStore from "../../../store/CommonStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import Slider from "react-slick";
import clsx from "clsx";
import { ReactComponent as Magazine } from "../../../assets/icons/main_banner_badge_magazine.svg";
import { ReactComponent as Mission } from "../../../assets/icons/main_banner_badge_mission.svg";
import { ReactComponent as Post } from "../../../assets/icons/main_banner_badge_post.svg";
import { ReactComponent as Qna } from "../../../assets/icons/main_banner_badge_qna.svg";
import { ReactComponent as Ab } from "../../../assets/icons/main_banner_badge_ab.svg";
import { ReactComponent as Myhome } from "../../../assets/icons/main_banner_badge_myhome.svg";
import { ReactComponent as Product } from "../../../assets/icons/main_banner_badge_product.svg";
import { ReactComponent as Ranking } from "../../../assets/icons/main_banner_badge_ranking.svg";
import { ReactComponent as Brand } from "../../../assets/icons/banner_badge_brand page.svg";
import { ReactComponent as Mall } from "../../../assets/icons/banner_badge_220mall.svg";

export interface MainBannerProps {
  bannerRef: any;
}

const MainBanner = observer(({ bannerRef }: MainBannerProps) => {
  const [index, setIndex] = useState<number>(0);
  const [isClickItem, setIsClickItem] = useState(true);
  const { carousel_idx, carousel_idx_txt } = styles;

  const { mainBannerList } = MainStore;

  const { track } = useTracker();

  const callActHistory = (
    sectionCode: string,
    feedId: number,
    index: number,
    contentType: ContentType
  ) => {
    let actHistory: ActHistoryRequest = {
      actHistoryTypeCode: "",
      actHistoryTargetId: feedId.toString(),
      actPageCode: ActPageCode.MAIN,
      actSectionCode: sectionCode,
      attr1: index.toString(),
    };

    switch (contentType) {
      case ContentType.POST:
        actHistory = {
          ...actHistory,
          actHistoryTypeCode: ActHistoryTypeCode.POST_READ,
        };
        break;
      case ContentType.QNA:
        actHistory = {
          ...actHistory,
          actHistoryTypeCode: ActHistoryTypeCode.QNA_READ,
        };
        break;
      case ContentType.AB:
        actHistory = {
          ...actHistory,
          actHistoryTypeCode: ActHistoryTypeCode.AB_READ,
        };
        break;
      case ContentType.MISSION:
        actHistory = {
          ...actHistory,
          actHistoryTypeCode: ActHistoryTypeCode.MISSION_READ,
        };
        break;
      case ContentType.MAGAZINE:
        actHistory = {
          ...actHistory,
          actHistoryTypeCode: ActHistoryTypeCode.MAGAZINE_READ,
        };
        break;
      default:
        break;
    }

    if (actHistory.actHistoryTypeCode !== "") {
      void sendActHistory(actHistory);
    }
  };

  const handleBannerPress = useCallback(
    (index: number, bannerLinkInfo: BannerLinkInfo) => {
      /* eslint-disable */
      const { contentId, contentType, linkType, linkUrl } = bannerLinkInfo;

      const banner = MainStore.mainBannerList
        ? MainStore.mainBannerList[index]
        : ({} as BannerInfo);

      if (
        (linkType === LinkType.INTERNAL && contentType) ||
        linkUrl == "NAVIGATION"
      ) {
        if (contentId !== undefined && contentId >= 0) {
          callActHistory(
            ActSectionCode.BANNER,
            contentId,
            index,
            contentType as ContentType
          );
        }

        const needLogin = AuthStore.sessionUser?.memberUuid === undefined;

        switch (contentType) {
          case ContentType.POST:
            // navigate(`/posts/feed/${contentId}`);
            goTo(`/posts/feed/${contentId}`);
            break;
          case ContentType.MAGAZINE:
            goTo(`/magazine/${contentId}`);
            break;
          case ContentType.QNA:
            goTo("/QnA");
            break;
          case ContentType.MISSION:
            goTo("/MissionDetail", {
              state: {
                missionId: contentId,
              },
            });
            break;
          case ContentType.AB:
            goTo("/ABList");
            break;
          case ContentType.PRODUCT:
            goTo(`/Product/${contentId}`);
            break;
          case ContentType.MY_HOME:
            needLogin
              ? MainStore.setShowJoinModal(true)
              : goTo("/myhome", {
                  state: {
                    targetUserId: AuthStore.sessionUser?.memberUuid,
                    nickname: AuthStore.sessionUser?.nickname,
                    profileUrl: AuthStore.sessionUser?.profileUrl,
                    profileBackgroundColor:
                      AuthStore.sessionUser?.profileBackgroundColor,
                  },
                });
            break;
          case ContentType.MAGAZINE_HOME:
            goTo("/magazine");
            break;
          case ContentType.RANKING:
            CommonStore.setShowDownloadAppDrive(true);
            // navigate("RankingScreen");
            break;
          case ContentType.MISSION_HOME:
            goTo("/Mission");
            break;
          case ContentType.QNA_HOME:
            goTo("/QnA");
            break;
          case ContentType.QNA_LIST:
            goTo("/QnAList");
            break;
          case ContentType.AB_LIST:
            goTo("/ABList");
            break;
          case ContentType.POST_HOME:
            goTo("/posts");
            break;
          case ContentType.SETTING:
            // needLogin ? navigate("JoinModal") : navigate("Setting");
            CommonStore.setShowDownloadAppDrive(true);
            break;
          case ContentType.NOTICE:
            // navigate("Notice");
            CommonStore.setShowDownloadAppDrive(true);
            break;
          case ContentType.FRIEND_RECOMMEND:
            // navigate("FriendRecommendScreen");
            CommonStore.setShowDownloadAppDrive(true);
            break;
          case ContentType.BRAND_HOME:
            // navigate("MainTab", {
            //   state: {
            //     screen: "BrandCommunityHome",
            //     params: { targetBrandId: contentId?.toString() },
            //   },
            // });
            CommonStore.setShowDownloadAppDrive(true);
            break;
          case ContentType.BRAND_COMMUNITY_MAIN:
            // navigate("MainTab", {
            //   state: {
            //     screen: "BrandCommunityMain",
            //   },
            // });
            CommonStore.setShowDownloadAppDrive(true);
            break;
          case ContentType.BRAND_COMMUNITY_POST:
            // navigate("ArticleDetailScreen", {
            //   state: { articleId: contentId },
            // });
            CommonStore.setShowDownloadAppDrive(true);
            break;
          case ContentType.MALL_DISPLAY_PAGE:
            // navigate("MainTab", {
            //   state: {
            //     screen: "MallMainScreen",
            //   },
            // });
            CommonStore.setShowDownloadAppDrive(true);
            break;
          case ContentType.MALL_PRODUCT:
            // goToLifecareProductPage(contentId || 0);
            CommonStore.setShowDownloadAppDrive(true);
            break;
          case ContentType.MALL_CATEGORY:
            // navigate("MainTab", {
            //   state: {
            //     screen: "LargeCategoryScreen",
            //     params: { categoryId: contentId },
            //   },
            // });
            CommonStore.setShowDownloadAppDrive(true);
            break;
          default:
            break;
        }

        const banner_title = banner.bannerMainTitle
          ? banner.bannerMainTitle
          : banner.bannerTitle;

        track("click_main_banner", {
          banner_id: banner.bannerId || null,
          banner_title: banner_title,
          banner_order: index + 1,
          banner_type: contentType.toLowerCase(),
        });
      } else {
        // void openInAppBrowser(linkUrl || "");
      }
      /* eslint-enable */
    },
    [track]
  );

  const zerosWithDataSize = useMemo(() => {
    return new Array(mainBannerList?.length || 0).fill(0);
  }, [mainBannerList?.length]);

  const handleAfterChange = (currentSlide: number) => {
    setIndex(currentSlide);
    setTimeout(() => {
      setIsClickItem(true);
    }, 100);
  };

  const bannerFlag = (item: any) => {
    const contentType = item.bannerLinkInfo?.contentType;

    switch (contentType) {
      case ContentType.POST:
      case ContentType.POST_HOME:
        return <Post />;
      case ContentType.MAGAZINE:
      case ContentType.MAGAZINE_HOME:
        return <Magazine />;
      case ContentType.MISSION:
      case ContentType.MISSION_HOME:
        return <Mission />;
      case ContentType.QNA:
      case ContentType.QNA_HOME:
      case ContentType.QNA_LIST:
        return <Qna />;
      case ContentType.AB:
      case ContentType.AB_LIST:
        return <Ab />;
      case ContentType.MY_HOME:
        return <Myhome />;
      case ContentType.PRODUCT:
        return <Product />;
      case ContentType.RANKING:
        return <Ranking />;
      case ContentType.BRAND_HOME:
      case ContentType.BRAND_COMMUNITY_MAIN:
      case ContentType.BRAND_COMMUNITY_POST:
        return <Brand />;
      case ContentType.MALL_DISPLAY_PAGE:
      case ContentType.MALL_PRODUCT:
      case ContentType.MALL_CATEGORY:
        return <Mall />;
      default:
        return <></>;
    }
  };

  const settings = {
    infinite: false,
    arrows: false,
    dots: false,
    // variableWidth: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    focusOnSelect: true,
    beforeChange: () => {
      setIsClickItem(false);
    },
    afterChange: handleAfterChange,
  };

  return (
    <div id="MainBanner" ref={bannerRef}>
      {mainBannerList && mainBannerList.length > 0 && (
        <div>
          <div id="MainBannerCarousel">
            {(mainBannerList || []).length > 1 && (
              <div className="text_idx_container" style={carousel_idx}>
                <div className="text_idx" style={carousel_idx_txt}>{`${
                  index + 1
                }/${mainBannerList?.length || 0}`}</div>
              </div>
            )}

            <Slider {...settings}>
              {mainBannerList &&
                mainBannerList.map((item: any, index: number) => {
                  return (
                    <div
                      id="MainBannerImage"
                      onClick={
                        isClickItem
                          ? () => {
                              handleBannerPress(index, item.bannerLinkInfo);
                            }
                          : () => {}
                      }
                    >
                      <div
                        className="image_wrapper"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <img
                          className="image"
                          src={
                            process.env.REACT_APP_PUBLIC_BUCKET_URL +
                            item.filePath
                          }
                          alt={"banner filePath"}
                        />
                      </div>
                      <div>
                        <div className="top_img_text_wrap">
                          {bannerFlag(item)}
                          <div className="title">
                            <div className="title_text">
                              {item.bannerMainTitle ?? ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>

            {/* {(mainBannerList || []).length > 1 && (
              <div className="dot_indicator">
                {zerosWithDataSize.map((_item, idx) => {
                  const isFocused = idx === index;
                  return (
                    <div
                      className={clsx(
                        "dot",
                        isFocused ? "dot_active" : "dot_inactive"
                      )}
                      key={`carousel-dot-${idx}`}
                    />
                  );
                })}
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
});

export default MainBanner;

const styles: { [key: string]: CSSProperties } = {
  carousel_idx: {
    display: "flex",
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#22222266",
    opacity: 1,
    height: 28,
    padding: "0 10px",
    borderRadius: 14,
    zIndex: 10,
    top: 16,
    right: 16,
  },
  carousel_idx_txt: {
    ...Texts.font_size_12,
    ...Colors.font_fff,
    ...Texts.font_letter_spacing_04,
    ...Texts.font_weight_500,
  },
};
