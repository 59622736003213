import { observer } from 'mobx-react'
import { Colors } from '../../../assets/styles'
import { useEffectOnlyOnce } from '../../../hooks/UseEffectOnlyOnce'
import SettingStore from '../../../store/SettingStore'
import AlarmSetting from './presenter/AlarmSetting'
import AuthStore from '../../../store/AuthStore'
import TermsStore from '../../../store/TermsStore'
import "./AlarmScreen.scss"

const AlarmScreen = observer(() => {
  const { getAlarmSetting, clearAlarmSetting, getTempMemberAlarmSetting } =
    SettingStore

  useEffectOnlyOnce(() => {
    if (AuthStore.sessionUser?.memberUuid) {
      void getAlarmSetting()
      void TermsStore.initLastTerms()
    } else {
      void getTempMemberAlarmSetting()
    }
  }, clearAlarmSetting)
  return (
    <div
      id="alarm-setting-screen"
      aria-label="Alarm setting screen"
    >
      <AlarmSetting />
    </div>
  )
})

export default AlarmScreen
