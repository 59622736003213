import { t } from "i18next";
import React from "react";
import { getDDay, isPast } from "../../../utils/datetime";

import "./AbEndDateBadge.scss";

const AbEndDateBadge = ({ endDate }: { endDate: string }) => {
  const expired = isPast(endDate);
  const dDay = getDDay(endDate);

  const badgeLabel = expired
    ? t("screen.ab.label.expired")
    : dDay > 0
    ? t("screen.ab.label.dMinus", { dayCount: dDay })
    : t("screen.ab.label.dDay");

  return (
    <div className={expired ? "expire" : "date_wrap"}>
      <p aria-label="Ab vote end date" className="date_wrap_text">
        {badgeLabel}
      </p>
    </div>
  );
};

export default AbEndDateBadge;
