import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spacings, Colors } from "../../../assets/styles";
import { TitleHeader } from "../../../components/common";
import { HeaderItem } from "../../../constants/Header";
import { goBack, goTo } from "../../../hooks/navigate/NavigateFunction";
import { Styles } from "../../../assets/types/Style";
import AuthStore from "../../../store/AuthStore";
import { NotificationModal } from "../../../components/modals";
import { useTranslation } from "react-i18next";
import ReportStore from "../../../store/ReportStore";
import { ReportTargetTypeCode } from "../../../constants/Report";
import { CommentTypeCode } from "../../../constants/Comment";
// import { VideoProvider } from "../../../components/common/VideoHandlerContext";
import DetailReply from "../../comment/presenter/DetailReply";
import ArticleDetailFooter from "./ArticleDetailFooter";
import ArticleDetailProfile from "./ArticleDetailProfile";
import { observer } from "mobx-react";
import ArticleDetailContents from "./ArticleDetailContents";
import CommentFooter from "../../comment/presenter/CommentFooter";
import ArticleDetailStore from "../../../store/ArticleDetailStore";
import { NotifyModalContext } from "../../mission/common/model/NotifyModalContext";
import { brandSubscribe } from "../../../api/brand/api";
import { Virtuoso } from "react-virtuoso";

const ArticleDetail = observer(({ articleId }: { articleId: number }) => {

  const location = useLocation();
  const { t } = useTranslation();
  const { qna_wrap, user_qna_detail_wrap, on_layout_width } = styles;
  const { sessionUser } = AuthStore;
  const { openReportBottomSheet } = ReportStore;

  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    // 화면 포커스 여부 감지
    setIsFocused(true);

    // 컴포넌트 언마운트 시 포커스 여부 갱신
    return () => {
      setIsFocused(false);
    };
  }, [location]);

  const [notifyModalContext, setNotifyModalContext] = useState<NotifyModalContext>({ isShow: false });

  useEffect(() => {
    if (
      isFocused &&
      ArticleDetailStore.articleDetail.subscriptionYn === "N" &&
      ArticleDetailStore.articleDetail.corpMemberYn === "N"
    )
      setNotifyModalContext({
        isShow: true,
        title: t("screen.article.label.subscription"),
        contents: t("screen.article.message.subscriptionNotice"),
        textAlign: "center",
        needTwoButton: true,
        defaultButtonText: t("screen.article.button.subscription"),
        onClickDefaultButton: () => {
          setNotifyModalContext({ isShow: false });
          void brandSubscribe(ArticleDetailStore.articleDetail.communityId);
        },
        onClickExtraButton: () => {
          setNotifyModalContext({ isShow: false });
          goBack();
        },
      });
  }, [isFocused, t]);

  const handleHeaderOptionClick = (type: string) => {
    switch (type) {
      case "DELETE":
        setNotifyModalContext({
          isShow: true,
          contents: t("screen.notificationModal.message.deleteMessage"),
          needTwoButton: true,
          onClickDefaultButton: () => {
            setNotifyModalContext({ isShow: false });
            const communityId = ArticleDetailStore.articleDetail.communityId;
            const articleId = ArticleDetailStore.articleDetail.articleId;
            void ArticleDetailStore.deleteArticle(articleId, communityId).then((res) => {
              if (res) {
                ArticleDetailStore.setIsDeleted(true);
                goBack();
              }
            });
          },
          onClickExtraButton: () => {
            setNotifyModalContext({ isShow: false });
          },
        });
        break;
      case "MODIFY":
        goTo(`/ArticleModify/${articleId}`);
        break;
      case "REPORT":
        openReportBottomSheet(
          {
            reportTargetId: String(articleId),
            reportTargetTypeCode: ReportTargetTypeCode.ARTICLE,
          },
          () => {
            goBack();
          }
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div style={{ flex: 1 }}>
        <TitleHeader
          title={t("screen.article.title.detail")}
          isBack={true}
          rightItem={HeaderItem.MORE}
          moreOptionMenuList={
            ArticleDetailStore.articleDetail?.createMemberUuid === sessionUser?.memberUuid
              ? ["DELETE", "MODIFY"]
              : ["REPORT"]
          }
          onClickMore={handleHeaderOptionClick}
          onClickBack={goBack}
        />
        <Virtuoso
          style={{}} //  
          useWindowScroll
          data={undefined}
          itemContent={(_) => {
            return (
              <></>
            );
          }}
          components={{
            Header: () => (
              // <VideoProvider
              //   ref2={ref}
              //   items={[
              //     { index: 0, visible: true },
              //     { index: 1, visible: true },
              //     { index: 2, visible: true },
              //     { index: 3, visible: true },
              //     { index: 4, visible: true },
              //     { index: 5, visible: true },
              //     { index: 6, visible: true },
              //     { index: 7, visible: true },
              //     { index: 8, visible: true },
              //     { index: 9, visible: true },
              //   ]}
              //   disabled={!isFocused}
              // >
              <div
                style={qna_wrap}
              >
                <div style={user_qna_detail_wrap}>
                  <ArticleDetailProfile />
                  <ArticleDetailContents />
                  <ArticleDetailFooter />
                  <div style={styles.line_thick}></div>
                  <DetailReply
                    feedId={ArticleDetailStore.articleDetail.articleId}
                    detailStore={ArticleDetailStore.articleDetail}
                    commentTypeCode={CommentTypeCode.ARTICLE}
                  />
                </div>
              </div>
              // </VideoProvider>
            )
          }}
        />
        {
          <CommentFooter
            commentTypeCode={CommentTypeCode.ARTICLE}
            disabled={ArticleDetailStore.articleDetail.articleCanCommentYn !== "Y"}
          />
        }
      </div>

      {notifyModalContext.isShow && (
        <NotificationModal
          isVisible={notifyModalContext.isShow}
          title={notifyModalContext.title || undefined}
          contents1={notifyModalContext.contents || ""}
          useTwoButton={notifyModalContext.needTwoButton || undefined}
          isBlack={false}
          defaultButtonText={
            notifyModalContext.defaultButtonText
              ? notifyModalContext.defaultButtonText
              : t("screen.notificationModal.button.OK")
          }
          extraButtonText={
            notifyModalContext.extraButtonText
              ? notifyModalContext.extraButtonText
              : t("screen.notificationModal.button.cancel")
          }
          onClickDefaultButton={notifyModalContext.onClickDefaultButton}
          onClickExtraButton={notifyModalContext.onClickExtraButton}
          onRequestClose={notifyModalContext.onClickExtraButton}
          textAlign={notifyModalContext.textAlign || undefined}
        />
      )}
    </>
  );
});

export default ArticleDetail;

const styles: Styles = {
  qna_wrap: {
    ...Colors.background_255,
  },
  user_qna_detail_wrap: {
    ...Spacings.padding_top_20,
    ...Spacings.margin_bottom_14,
  },
  on_layout_width: {
    flex: 1
  },
  line_thick: {
    marginTop: 20,
    height: 10,
    backgroundColor: "#eee",
  },
};
