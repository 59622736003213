export enum HeaderItem {
  NONE = "NONE",
  CLOSE = "CLOSE",
  MENU = "MENU",
  WHITE_MENU = "WHITE_MENU",
  SAVE_OFF = "SAVE",
  SAVE_ON = "SAVE_OFF",
  APPLY_ON = "APPLY_ON",
  APPLY_OFF = "APPLY_OFF",
  MORE = "MORE",
  SHARE = "SHARE",
  MY_COUPON_BOOKMARK = "COUPON_BOOKMARK",
  WRITE = "WRITE",
  CART = "CART",
  REGISTER_ON = "REGISTER_ON",
  REGISTER_OFF = "REGISTER_OFF",
  CUSTOM = "CUSTOM",
}
