import React, { CSSProperties, useEffect, useState } from "react";

const KeywordText = ({
  sentence,
  keyword,
  accessibilityLabel,
  containerStyle,
  matchTextStyle,
}: {
  sentence: string;
  keyword: string;
  accessibilityLabel?: string;
  containerStyle?: CSSProperties;
  matchTextStyle?: CSSProperties;
}) => {
  const render = () => {
    const trimedSentence = sentence?.trim();
    const trimedKeyword = keyword?.trim();

    if (trimedSentence?.length > 0 && trimedKeyword?.length > 0) {
      const keywordIndex = trimedSentence
        .toLowerCase()
        .indexOf(trimedKeyword.toLowerCase());
      const hasKeyword = keywordIndex > -1;
      const keywordInList = trimedSentence.substring(
        keywordIndex,
        keywordIndex + trimedKeyword.length
      );
      const keywordSection = trimedSentence.split(keywordInList);

      if (hasKeyword) {
        const before = keywordSection[0] + keywordInList;
        return (
          <>
            <p
              style={
                containerStyle
                  ? containerStyle
                  : {
                      fontSize: 13,
                      letterSpacing: -0.52,
                      color: "#222",
                      paddingLeft: 6,
                    }
              }
              className="keyword-text-wrapper"
              aria-label={accessibilityLabel || "Keyword text"}
            >
              {keywordSection[0]}
              <span
                style={
                  matchTextStyle
                    ? matchTextStyle
                    : {
                        fontSize: 13,
                        letterSpacing: -0.52,
                        color: "#a760b1",
                        paddingLeft: 6,
                      }
                }
              >
                {keywordInList}
              </span>
              {trimedSentence.replace(before, "")}
            </p>
          </>
        );
      } else
        return (
          <p
            style={
              containerStyle
                ? containerStyle
                : {
                    fontSize: 13,
                    letterSpacing: -0.52,
                    color: "#222",
                    paddingLeft: 6,
                  }
            }
          >
            {trimedSentence}
          </p>
        );
    } else
      return (
        <p
          style={
            containerStyle
              ? containerStyle
              : {
                  fontSize: 13,
                  letterSpacing: -0.52,
                  color: "#222",
                  paddingLeft: 6,
                }
          }
        >
          {trimedSentence}
        </p>
      );
  };

  return render();
};

export default KeywordText;
