import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TitleHeader } from "../../components/common";
import { findEmail } from "../../api/member/api";
import TriangleRightBlack from "../../assets/icons/triangle_right_black.svg";
import TriangleRightRed from "../../assets/icons/triangle_right_red.svg";
import CommonStore from "../../store/CommonStore";
import classes from "./FindEmail.module.scss";
import clsx from "clsx";
import { goTo } from "../../hooks/navigate/NavigateFunction";

const { setLoading } = CommonStore;

const FindEmail = () => {
  const { t } = useTranslation();
  const [maskedEmailAddress, setMaskedEmailAddress] = useState<string>("");
  const [nickname, setNickname] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");

  const onNicknameChange = (nickname: string) => {
    setNickname(nickname);
  };

  const checkNicknameFormat = (nickname: string) => {
    if (nickname.length < 4) {
      setAlertMessage(t("screen.findEmail.message.invalidLength"));
      return false;
    }
    return true;
  };

  const resetEmailAddress = () => {
    setMaskedEmailAddress("");
    setNickname("");
  };

  const onClickFindEmail = async (nickname: string) => {
    if (checkNicknameFormat(nickname)) {
      setLoading(true);
      const res = await findEmail(nickname);

      if (res.successOrNot === "Y") {
        if (res.data.maskedEmailAddress != null) {
          setMaskedEmailAddress(res.data.maskedEmailAddress as string);
        } else {
          setAlertMessage(t("screen.findEmail.message.notFoundEamil"));
        }
      } else {
        setAlertMessage(t("common.message.networkError"));
      }
      setLoading(false);
    }
  };

  return (
    <div className={classes.find_email}>
      <TitleHeader title={t("screen.findEmail.label.title")} />

      <div className={classes.contents_box}>
        {maskedEmailAddress.length === 0 ? (
          <>
            <div className={classes.step_list}>
              <div className={classes.step_item}>
                <div className={classes.step_header}>
                  <span
                    className={classes.step_header_text}
                    aria-label={"Title"}
                  >
                    {t("screen.findEmail.label.findEmailByNickname")}
                  </span>
                </div>
                <div className={classes.step_body}>
                  <div className={classes.input_wrap}>
                    <input
                      className={classes.input}
                      aria-label={"Nickname"}
                      placeholder={t("screen.findEmail.label.nicknameInput")}
                      value={nickname}
                      onChange={(e) => onNicknameChange(e.target.value)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && nickname.length > 0) {
                          void onClickFindEmail(nickname);
                        }
                      }}
                    />
                  </div>

                  <div className={classes.alert_wrap}>
                    {alertMessage.length > 0 && (
                      <div className={classes.alert}>
                        <img src={TriangleRightRed} alt={TriangleRightRed} />
                        <span
                          className={classes.not_valid}
                          aria-label={"Alert"}
                        >
                          {alertMessage}
                        </span>
                      </div>
                    )}
                    <div className={classes.alert}>
                      <img src={TriangleRightBlack} alt={TriangleRightBlack} />
                      <span className={classes.valid} aria-label={"Info"}>
                        {t("screen.findEmail.message.info")}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.footer}>
              <button
                className={
                  nickname.length === 0
                    ? clsx([classes.btn_1, classes.in_active])
                    : clsx([classes.btn_1, classes.active])
                }
                aria-label={"Find email"}
                disabled={nickname.length === 0}
                onClick={() => {
                  void onClickFindEmail(nickname);
                }}
              >
                <span
                  className={
                    nickname.length === 0
                      ? clsx([classes.btn_1_txt, classes.txt_in_active])
                      : clsx([classes.btn_1_txt, classes.txt_active])
                  }
                >
                  {t("screen.findEmail.button.findEmail")}
                </span>

                <div className={classes.active} />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className={classes.step_list}>
              <div className={classes.step_item}>
                <div className={classes.search_done_body}>
                  <div className={classes.search_done}>
                    <div
                      aria-label={"Masked email address"}
                      className={classes.email_text}
                    >
                      {maskedEmailAddress}
                    </div>
                  </div>
                </div>
                <div className={classes.alert_wrap}>
                  <div className={classes.alert}>
                    <img src={TriangleRightBlack} alt={TriangleRightBlack} />
                    <span className={classes.valid}>
                      {t("screen.findEmail.message.info")}
                    </span>
                  </div>
                </div>
                <div className={classes.other_email_wrap}>
                  <button
                    className={classes.other_email_btn}
                    onClick={resetEmailAddress}
                    aria-label={"Find email by other nicknames"}
                  >
                    <span className={classes.other_email_text}>
                      {t("screen.findEmail.button.findEmailByOther")}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className={classes.footer}>
              <button
                className={
                  nickname.length === 0
                    ? clsx([classes.btn_1, classes.in_active])
                    : clsx([classes.btn_1, classes.active])
                }
                aria-label={"Find email"}
                disabled={nickname.length === 0}
                onClick={() => {
                  goTo("/LoginPresenter");
                }}
              >
                <span
                  className={
                    nickname.length === 0
                      ? clsx([classes.btn_1_txt, classes.txt_in_active])
                      : clsx([classes.btn_1_txt, classes.txt_active])
                  }
                >
                  {t("screen.findEmail.button.login")}
                </span>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FindEmail;
