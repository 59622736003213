import { observer } from 'mobx-react'
import CustomBottomSheet from './CustomBottomSheet'
import { ReactComponent as AppIcon } from '../../assets/icons/icon_app_install_popup_220.svg'
import { goToAppDetail } from '../../utils/appstore'
import { useTranslation } from 'react-i18next'
import style from './DownloadAppDriveBottomSheet.module.scss'
import { Platform, PlatformTypes } from '../../native/platform'

export const DownloadAppDriveBottomSheet = observer(
  ({ show, onClose }: { show: boolean; onClose?: () => void }) => {
    const { t } = useTranslation()

    const closeBottomSheet = () => {
      onClose && onClose()
    }

    return (
      <div
        id="DownloadAppDriveBottomSheet"
        className={style.download_app_drive_bottom_sheet}
      >
        <CustomBottomSheet
          open={show}
          onClose={closeBottomSheet}
          snapHeight={344}
        >
          <div className={style.container}>
            <AppIcon />
            <div className={style.message}>
              <span className={style.message1}>
                {t('common.appDrive.message1')}
              </span>
              {t('common.appDrive.message2')}
            </div>
            <button
              onClick={() => {
                closeBottomSheet()

                switch (Platform.OS) {
                  case PlatformTypes.WEB:
                  case PlatformTypes.WINDOWS_WEB:
                  case PlatformTypes.MACOS_WEB:
                    goToAppDetail()
                    break
                  default:
                    window.open('https://app2.220cordncode.com/IYMzZqsaaLb')
                    break
                }
              }}
              className={style.download_button}
            >
              {t('common.appDrive.positiveButton')}
            </button>
            <button className={style.cancel_button} onClick={closeBottomSheet}>
              {t('common.appDrive.negativeButton')}
            </button>
          </div>
        </CustomBottomSheet>
      </div>
    )
  }
)
