export enum MainFeedTypeCode {
  POPULAR_POST = "POPULAR_POST",
  MAIN_POST = "MAIN_POST",
  MAIN_POST_SINGLE = "MAIN_POST_SINGLE", //NOTE : 메인화면 하단부터 무한 스크롤링 되는 추천 포스트 각각
  MAGAZINE = "MAGAZINE",
  POPULAR_TAG = "POPULAR_TAG",
  POPULAR_QNA = "POPULAR_QNA",
  POPULAR_RANK = "POPULAR_RANK",
  TODAY_BRAND = "TODAY_BRAND",
  TODAY_ARTICLE = "TODAY_ARTICLE",
  TODAY_MALL_PRODUCT = "TODAY_MALL_PRODUCT",
}

export enum UserActTypeCode {
  CANCEL_LIKE = "CANCEL_LIKE",
  SAVE_LIKE = "SAVE_LIKE",
  CANCEL_BOOKMARK = "CANCEL_BOOKMARK",
  SAVE_BOOKMARK = "SAVE_BOOKMARK",
  SHOW_MORE = "SHOW_MORE",
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
  UPDATE_COMMENT = "UPDATE_COMMENT",
  CREATE = "CREATE",
  DELETE = "DELETE",
  MODIFY = "MODIFY",
  REPORT = "REPORT",
  POP = "POP",
  FOLLOW = "FOLLOW",
  UN_FOLLOW = "UN_FOLLOW",
}
