import Collapsible from "./Collapsible";

export interface IAccordionSection {
  title: any;
  content: any;
  type?: any;
}

interface AccordionProps {
  sections: IAccordionSection[];
  activeSections: (string | number)[];
  renderHeader: (
    content: IAccordionSection,
    index: string | number,
    isActive: boolean,
    sections: IAccordionSection[]
  ) => JSX.Element;
  renderContent: (
    content: IAccordionSection,
    index: string | number,
    isActive: boolean,
    sections: IAccordionSection[]
  ) => JSX.Element;
  onChange?: (updatedSections: (string | number)[]) => void;
  underlayColor?: string;
  expandFromBottom?: boolean;
  expandMultiple?: boolean;
}

const Accordion = ({
  sections = [],
  activeSections = [],
  renderHeader,
  renderContent,
  onChange,
  underlayColor,
  expandFromBottom,
  expandMultiple = false,
}: AccordionProps) => {
  const toggleSection = (section: string | number) => {
    let updatedSections = [];

    if (activeSections.includes(section)) {
      updatedSections = activeSections.filter((a) => a !== section);
    } else if (expandMultiple) {
      updatedSections = [...activeSections, section];
    } else {
      updatedSections = [section];
    }

    if (onChange) {
      onChange(updatedSections);
    }
  };

  const renderCollapsible = (
    section: IAccordionSection,
    key: string | number
  ) => (
    <Collapsible collapsed={!activeSections.includes(key)}>
      {renderContent(section, key, activeSections.includes(key), sections)}
    </Collapsible>
  );

  const renderContainer = (
    section: IAccordionSection,
    key: string | number
  ) => {
    return (
      <div key={key}>
        {expandFromBottom && renderCollapsible(section, key)}

        <div
          onClick={() => toggleSection(key)}
          style={{ backgroundColor: underlayColor }}
        >
          {renderHeader(section, key, activeSections.includes(key), sections)}
        </div>

        {!expandFromBottom && renderCollapsible(section, key)}
      </div>
    );
  };

  return (
    <div>
      {sections.map((section, index) => {
        const key = index;
        return renderContainer(section, key);
      })}
    </div>
  );
};

export default Accordion;