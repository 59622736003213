import dayjs from "dayjs";
import { action, computed, makeObservable, observable } from "mobx";
import {
  getNotCheckedNotificationCount,
  getNotificationList,
  updateNotificationCheckYnToY,
} from "../api/notification/api";
import { Notification } from "../api/notification/model";
import {
  handleNativeNotififactionPressed,
  NotificationCore,
} from "../service/Notification.service";
import { trackPushClickEvent } from "../api/track/api";
import { PushClickEvent } from "../api/track/model";
// import { Platform } from "react-native";
import AuthStore from "./AuthStore";
import { Platform } from "../native/platform";

const EXPIRE_TIME = 10 * 60 * 1000; // 10분

export enum NOTIFICATION_TAP_TYPE {
  ALL = "NOTIFICATION_ALL",
  BENEFIT = "NOTIFICATION_BENEFIT",
}

class NotificationStore {
  constructor() {
    makeObservable(this);
  }
  @observable _initialNotificaiton: NotificationCore | null = null;
  //NOTE : 알림화면 내 모든 팔로잉/팔로우 버튼 표기 sync를 위해 알림 발송인 중 내가 팔로잉 하고 있는 사람의 uuid를 담아둡니다.
  @observable _followingUuids: Set<string> = new Set();

  @observable _notifications: Notification[] = [];
  @observable _notCheckedCount = 0;
  @observable _totalCount = 0;
  @observable _pageIndex = 0;
  @observable _loadingStatus:
    | "INITIAL"
    | "REFRESHING"
    | "LOADING"
    | "LOADED"
    | "ALL_LOADED" = "INITIAL";
  @observable _tabType: NOTIFICATION_TAP_TYPE = NOTIFICATION_TAP_TYPE.ALL;

  @computed get notCheckedCount() {
    return this._notCheckedCount;
  }
  @computed get loadingStatus() {
    return this._loadingStatus;
  }

  @computed get notifications() {
    return this._notifications;
  }

  @computed get initialNotificaiton() {
    return this._initialNotificaiton;
  }

  @computed get followingUuids() {
    return this._followingUuids;
  }

  @computed get tabType() {
    return this._tabType;
  }

  @action setTabType = (type: NOTIFICATION_TAP_TYPE) => {
    this._tabType = type;
  };

  @action setInitialNotificaiton = (notification: NotificationCore | null) => {
    if (notification)
      notification.expireDateTimeStr = dayjs()
        .add(EXPIRE_TIME, "millisecond")
        .toString();
    this._initialNotificaiton = JSON.parse(JSON.stringify(notification));
  };

  @action setNotifications = (notifications: Notification[]) => {
    this._notifications = notifications;
  };

  @action setNotCheckedCount = (count: number) => {
    this._notCheckedCount = count;
  };

  @action setLoadingStatus = (
    status:
      | "INITIAL"
      | "REFRESHING"
      | "LOADING"
      | "LOADED"
      | "ALL_LOADED" = "INITIAL"
  ) => {
    this._loadingStatus = status;
  };

  @action updateCheckedYnAndSyncNotCheckedCount = async (ids: number[]) => {
    if (ids.length > 0) {
      await updateNotificationCheckYnToY(ids);
    }
    await this.syncNotCheckedCount();
  };

  @action syncNotCheckedCount = async () => {
    if (
      AuthStore.sessionUser &&
      AuthStore.sessionUser.hasOwnProperty("nickname")
    ) {
      const notCheckedNotificationCount =
        await getNotCheckedNotificationCount();
      this.setNotCheckedCount(notCheckedNotificationCount);
    } else {
      this.setNotCheckedCount(0);
    }
  };
  @action setPageIndex = (index: number) => {
    this._pageIndex = index;
  };

  @action pullToRefreshNotifications = () => {
    this.setLoadingStatus("REFRESHING");
    this._pageIndex = 0;
    void this.retrieveNotifications();
  };

  @action retrieveNotifications = async () => {
    if (
      this._loadingStatus === "REFRESHING" ||
      this._loadingStatus === "INITIAL" ||
      this._loadingStatus === "LOADED"
    ) {
      this.setLoadingStatus("LOADING");

      const res = await getNotificationList(
        this._pageIndex,
        this.tabType !== NOTIFICATION_TAP_TYPE.ALL
      );
      this._totalCount = res?.totalCount || 0;

      res?.list.forEach((notification) => {
        notification.requestorFollowingYn === "Y" &&
          this.addUuidToFollowingUuids(notification.requestorUuid);
      });

      if (this._pageIndex === 0) {
        this.setNotifications(res?.list || []);
      } else {
        this.setNotifications([...this._notifications, ...(res?.list || [])]);
      }

      // 5987 요구사항 변경. 해당 알림 클릭했을때만 읽음으로 처리
      // void this.updateCheckedYnAndSyncNotCheckedCount((res?.list || []).map((item) => item.notificationId));

      if (this._notifications.length >= this._totalCount) {
        this.setLoadingStatus("ALL_LOADED");
      } else {
        this.setLoadingStatus("LOADED");
      }

      this._pageIndex += 1;
    }
  };

  @action navigateByInitialNotification = () => {
    // 2023.02.13 푸쉬 클릭 로그 저장.
    this.trackPushClickEvent(this.initialNotificaiton);

    this.initialNotificaiton?.pushType &&
      handleNativeNotififactionPressed({
        pushType: this.initialNotificaiton.pushType,
        link: this.initialNotificaiton.link,
        expireDateTimeStr: this.initialNotificaiton.expireDateTimeStr,
      });
    this.setInitialNotificaiton(null);
  };

  @action addUuidToFollowingUuids = (uuid: string) => {
    const nextSet = new Set(this._followingUuids);
    nextSet.add(uuid);
    this._followingUuids = nextSet;
  };

  @action removeUuidFromFollowingUuids = (uuid: string) => {
    const nextSet = new Set(this._followingUuids);
    nextSet.delete(uuid);
    this._followingUuids = nextSet;
  };

  @action trackPushClickEvent = (notification: NotificationCore | null) => {
    if (!notification) {
      return;
    }

    const pushClickEvent = {} as PushClickEvent;
    pushClickEvent.sendType = notification.sendType ?? "";
    pushClickEvent.notificationTypeCode = notification.pushType ?? "";
    pushClickEvent.pushMasterIdx = parseInt(notification.pushMasterIdx ?? "0");
    pushClickEvent.memberId = parseInt(notification.memberId ?? "0");
    pushClickEvent.memberUuid = AuthStore.sessionUser?.memberUuid
      ? AuthStore.sessionUser?.memberUuid
      : AuthStore.tempMember?.tempMemberUUID
      ? AuthStore.tempMember?.tempMemberUUID
      : "";
    pushClickEvent.pushSendChannel = notification.channel ?? "";
    pushClickEvent.pushTitle = notification.title ?? "";
    pushClickEvent.pushMessage = notification.body ?? "";
    pushClickEvent.deepLink = notification.link ?? "";
    pushClickEvent.deviceOs = Platform.OS;
    pushClickEvent.userType = AuthStore.sessionUser?.memberUuid
      ? "MEMBER"
      : "NONMEMBER";

    void trackPushClickEvent(pushClickEvent);
  };

  @action clear = () => {
    this.setTabType(NOTIFICATION_TAP_TYPE.ALL);
    this.setLoadingStatus("LOADED");
    this.setNotifications([]);
    this.setInitialNotificaiton(null);
    this._followingUuids = new Set();
    this._pageIndex = 0;
    this._totalCount = 0;
  };
}

export default new NotificationStore();
