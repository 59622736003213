import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/ko";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.locale("ko");
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale("ko", {
  relativeTime: {
    future: "%s 후",
    past: "%s 전",
    s: "몇 초",
    m: "1분",
    mm: "%d분",
    h: "1시간",
    hh: "%d시간",
    d: "1일",
    dd: "%d일",
    M: "한 달",
    MM: "%d달",
    y: "일 년",
    yy: "%d년",
  },
});

/**
 * [컨텐츠 공통]
 *
 * 같은 해이면 M월 D일
 *
 * 다른 해이면 YYYY년 M월 D일
 *
 * 7일전까지는 -일전
 *
 * 하루내는 -시간전-분전
 */
export const getDateStr = (dateTime?: string) => {
  const origin = dayjs(dateTime, "YYYY-MM-DD hh:mm:ss");
  const today = dayjs();
  const diff = today.diff(origin, "days");

  if (diff < 6) return origin.fromNow();
  else {
    if (origin.format("YYYY") === today.format("YYYY")) {
      return origin.format("M월 D일");
    } else {
      return origin.format("YYYY년 M월 D일");
    }
  }
};
/**
 * [단축 컨텐츠] (ex. 게시글)
 *
 * 같은 해이면 M.DD
 *
 * 다른 해이면 YY.M.DD
 *
 * 7일전까지는 -일전
 *
 * 하루내는 -시간전-분전
 */
export const getCompactDateStr = (dateTime?: string) => {
  const origin = dayjs(dateTime, "YYYY-MM-DD hh:mm:ss");
  const today = dayjs();
  const diff = today.diff(origin, "days");

  if (diff < 6) return origin.fromNow();
  else {
    if (origin.format("YYYY") === today.format("YYYY")) {
      return origin.format("M.DD");
    } else {
      return origin.format("YY.M.DD");
    }
  }
};
/**
 * [양식] YY.MM.DD
 *
 * [사용처] 검색어 기준일자
 */
export const getDatetimeStr = (dateTime?: string) => {
  const origin = dayjs(dateTime, "YYYY-MM-DD hh:mm:ss");
  return origin.format("YY.MM.DD");
};

export const getDatetimeFullYearStr = (dateTime?: string) => {
  const origin = dayjs(dateTime, "YYYY-MM-DD hh:mm:ss");
  return origin.format("YYYY.M.D");
};

export const getDateHourMinuteStr = (dateTime?: string) => {
  const origin = dayjs(dateTime, "YYYY-MM-DD hh:mm:ss");
  return origin.format("YY.MM.DD hh:mm");
};

export const in60Days = (dateTime?: Dayjs) => {
  const origin = dayjs(dateTime);
  const diff = dayjs().diff(origin, "days");
  return diff < 60;
};

/**
 * 현재 시간
 *
 * [양식] YYYY년 MM월 DD일
 *
 * [사용처] Toast ex) YYYY년 MM월 DD일 탈퇴되었습니다.
 */
export const getCurrentDate = () => {
  return dayjs().format("YYYY년 MM월 DD일");
};

/**
 * Pattern: yyyy년 MM월 dd일
 *
 * [사용처] 공지사항, 설정 등
 */
export const getDateFormatKo = (dateTime?: string) => {
  const origin = dayjs(dateTime, "YYYY-MM-DD hh:mm:ss");
  return origin.format("YYYY년 MM월 DD일");
};

/**
 * 현재일과 기준일(dateTime)의 차이
 */
export const getDdayFromToday = (dateTime?: string) => {
  const origin = dayjs(dateTime, "YYYY-MM-DD hh:mm:ss");
  const today = dayjs();
  return today.diff(origin, "days");
};

/**
 * [양식] YYYY년 MM월 DD일 HH:mm
 *
 * [사용처] 설정 - 1:1 문의
 */
export const getDateAndTimeFormat = (dateTime?: string) => {
  const origin = dayjs(dateTime, "YYYY-MM-DD HH:mm:ss");
  return origin.format("YYYY년 MM월 DD일 HH:mm");
};

export const getDDay = (target: string) => {
  const dDay = dayjs(target, "YYYY-MM-DD");
  const today = dayjs().format("YYYY-MM-DD");
  return dDay.diff(today, "days");
};

export const isPast = (target: string) => {
  const targetDay = dayjs(target, "YYYY-MM-DD hh:mm:ss");
  const today = dayjs();

  return targetDay.diff(today) <= 0;
};

export const LocaleConfiKo = {
  monthNames: [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ],
  monthNamesShort: [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ],
  dayNames: ["일", "월", "화", "수", "목", "금", "토"],
  dayNamesShort: ["일", "월", "화", "수", "목", "금", "토"],
  today: "오늘",
};
/**
 * [양식] MM월 DD일(요일)
 *
 * [사용처] 미션 기간 정보
 */
export const getDateMMDDddd = (date: string) => {
  const origin = dayjs(date, "YYYY-MM-DD HH:mm:ss");
  return origin.format("MM월 DD일(ddd)");
};

export const getDateMMDD = (date?: string) => {
  const origin = dayjs(date, "YYYY-MM-DD HH:mm:ss");
  return origin.format("MM월 DD일");
};

export const getDateYYYYMMDD = (date: string) => {
  const origin = dayjs(date, "YYYY-MM-DD HH:mm:ss");
  return origin.format("YYYY.MM.DD");
};

export const getDateYYMMDD = (date: string) => {
  const origin = dayjs(date, "YYYY-MM-DD HH:mm:ss");
  return origin.format("YY.MM.DD");
};

export const getDateMD = (date?: string) => {
  const origin = dayjs(date, "YYYY-MM-DD HH:mm:ss");
  return origin.format("M월 D일");
};

export const onChangeTextForDateYYMMDD = (input: string) => {
  let formattedText = input.replace(/(\d{4})(?=\d)/g, "$1.");
  formattedText = formattedText.replace(/(\d{6})(?=\d)/g, "$1.");
  formattedText = formattedText.replace(/(\d{8})(?=\d)/g, "$1.");
  return formattedText;
};

export const onChangeTextForDateYYYYMMDD = (input: string) => {
  const regex = /^(\d{4})(\d{2})(\d{2})$/;
  return input.replace(regex, "$1-$2-$3");
};

export const isValidDate = (input: string) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!input.match(regEx)) {
    return false;
  }
  const d = new Date(input);
  const dNum = d.getTime();
  if (!dNum && dNum !== 0) {
    return false;
  }
  return d.toISOString().slice(0, 10) === input;
};
