import { observer } from "mobx-react";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import SocialMemberStore from "../../store/SocialMemberStore";
import { SignUpCode } from "../../constants/SignUp.enum";
import { goTo } from "../../hooks/navigate/NavigateFunction";

const Auth = observer(() => {
  const [serchParams] = useSearchParams();

  const { signInWithGoogle, signInWithNaver, signInWithKakao } =
    SocialMemberStore;

  useEffect(() => {
    // 소셜 로그인 콜백
    const code = serchParams.get("code") || undefined;
    const state = serchParams.get("state") || undefined;
    const signupcode = localStorage.getItem("SIGNUPCODE");
    if (code && signupcode) {
      localStorage.removeItem("SIGNUPCODE");
      switch (signupcode) {
        case SignUpCode.NAVER:
          signInWithNaver(code, state);
          break;
        case SignUpCode.GOOGLE:
          signInWithGoogle(code);
          break;
        case SignUpCode.KAKAO:
          signInWithKakao(code);
          break;
      }
    } else {
      goTo("/");
    }
  }, [serchParams, signInWithGoogle, signInWithKakao, signInWithNaver]);

  return <></>;
});

export default Auth;
