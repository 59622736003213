import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import SearchStore from "../../../store/SearchStore";
import { SearchTabMenu, SEARCH_RESULT_COUNT } from "../../../constants/Search";
import SearchCountAndSort from "./SearchCountAndSort";
import SearchEmpty from "./SearchEmpty";
import { SearchItem } from "../../../api/search/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import CommonStore from "../../../store/CommonStore";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { PullToRefresh } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import { ReactComponent as DarkSpinner } from "../../../assets/animations/darkSpinner.svg";
import { useWindowScroll } from "react-use";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import "./SearchPoster.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { useLocation } from "react-router-dom";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import SearchResultLayout from "../SearchResultLayout";

const SearchPoster = observer(() => {
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";
  const {
    searchText,
    posterResult,
    getLoadingInList,
    loadingInList,
    posterSortAndIndex,
    refreshing,
    getSearchResult,
    setRefreshing,
  } = SearchStore;

  const { x, y } = useWindowScroll();

  const [afterMount, setAfterMount] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setAfterMount(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (afterMount) {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

      if (bottom) {
        if ((posterResult?.totalCount || 0) > 0 && !loadingInList) {
          const tempStart = posterSortAndIndex?.lastIndex + 1;
          if (
            (posterResult?.totalCount || 0) >
            tempStart * SEARCH_RESULT_COUNT
          ) {
            void getSearchResult(
              searchText,
              SearchTabMenu.Poster,
              posterSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              tempStart
            );
          }
        }
      }
    }
  }, [y]);

  const moveToUserHome = (item: SearchItem, index: number) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.USER_CLICK,
      item.memberUuid || "",
      ActPageCode.SEARCH,
      ActSectionCode.SEARCH_POSTER,
      index.toString(),
      searchText,
      posterSortAndIndex.sort
    );

    goTo(`/user/${item.nickname}`, {
      state: {
        targetUserId: item.memberUuid,
        nickname: item.nickname,
        profileUrl: item.profileUrl,
        profileBackgroundColor: item.profileBackgroundColor,
      },
    });
  };

  return (
    <SearchResultLayout tabType={SearchTabMenu.Poster}>
      <div id="SearchPoster" className="tab_content" aria-label="Search poster">
        <PullToRefresh
          onRefresh={async () => {
            await sleep(1000);
            setRefreshing(true);
            void getSearchResult(
              searchText,
              SearchTabMenu.Poster,
              posterSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              0
            );
          }}
          renderText={(status) => {
            return (
              <div>
                {status === "refreshing" && (
                  <DarkSpinner style={{ width: 50, height: 50 }} />
                )}
              </div>
            );
          }}
        >
          <SearchCountAndSort
            type={SearchTabMenu.Poster}
            sort={posterSortAndIndex.sort}
            count={posterResult?.totalCount || 0}
          />
          <div className="scroll_wrap poster_list">
            {posterResult?.list && posterResult?.list.length > 0 ? (
              posterResult?.list.map((item: any, index: number) => {
                return (
                  <TouchableWithAuthCheck
                    className="poster_item"
                    onPress={() => moveToUserHome(item, index)}
                    aria-label={`Search poster ${index}`}
                  >
                    <FastImageWithFallback
                      className="poster_profile_image"
                      source={{
                        uri: `${PUBLIC_BUCKET_URL}${item.profileUrl || ""}`,
                      }}
                      fallbackImageUri={FALLBACK_IMAGE_URL}
                    />
                    <div className="poster_profile_text">{item.nickname}</div>
                  </TouchableWithAuthCheck>
                );
              })
            ) : (
              <SearchEmpty isGrid={false} />
            )}
          </div>

          {getLoadingInList ? <CustomActivityIndicatorSmall /> : <></>}
        </PullToRefresh>
      </div>
    </SearchResultLayout>
  );
});

export default SearchPoster;
