import { FeedTypePath } from "../../constants/Feed";
import { callAPIWithPOSTMethod, callAPIWithDELETEMethod, callAPIWithGETMethod } from "../common/api";
import CommonResponse from "../http";
import { BookmarkRequest, BookmarkFeedItem } from "./model";

export const saveBookmark = async (
  bookmarkTargetId: number,
  bookmarkTypeCode: string,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/bookmark",
      body: {
        bookmarkTargetId,
        bookmarkTypeCode,
      },
    },
    isLoading
  );

  return response?.successOrNot === "Y";
};

export const cancelBookmark = async (
  bookmarkTargetId: number,
  bookmarkTypeCode: string,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/bookmark",
      body: {
        bookmarkTargetId,
        bookmarkTypeCode,
      },
    },
    isLoading
  );

  return response?.successOrNot === "Y";
};

export const getBookmarkFeeds = async (
  feedTypePath: FeedTypePath,
  requestItem: BookmarkRequest,
  pageSize: number,
  pageIndex: number,
  isLoading = true
): Promise<BookmarkFeedItem[]> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  response = await callAPIWithGETMethod(
    {
      url: `/v1/bookmark/${feedTypePath}?pageSize=${pageSize}&pageIndex=${pageIndex}`,
      params: requestItem,
    },
    isLoading
  );
  return (response?.successOrNot === "Y" && response?.statusCode === "OK" ? response?.data : []) as BookmarkFeedItem[];
};

export const deleteBookmarks = async (
  bookmarkTypeCode: string,
  bookmarkTargetIdList: number[],
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/bookmarks",
      body: {
        bookmarkTypeCode,
        bookmarkTargetIdList,
      },
    },
    isLoading
  );

  return response?.successOrNot === "Y";
};
