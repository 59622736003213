import "./BookmarkFeed.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Arranges,
  Assets,
  Colors,
  FlexBoxs,
  Icons,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { ReactComponent as CheckOffIcon } from "../../../assets/icons/checkbox_big_off.svg";
import { ReactComponent as CheckOnIcon } from "../../../assets/icons/checkbox_big_on.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

import { deleteBookmarks } from "../../../api/bookmark/api";
import { FeedTypeCode } from "../../../constants/Feed";
import { BookmarkFeedItem } from "../../../api/bookmark/model";
import { replaceKoreanEndWordByTarget } from "../../../utils/locale";

import BookmarkPost from "./BookmarkPost";
import BookmarkQna from "./BookmarkQna";
import BookmarkAB from "./BookmarkAB";
import BookmarkProduct from "./BookmarkProduct";
// import BookmarkArticle from "./BookmarkArticle";
import BookmarkStore from "../../../store/BookmarkStore";
import { observer } from "mobx-react";

import { useTranslation } from "react-i18next";
import CommonStore from "../../../store/CommonStore";
import AuthStore from "../../../store/AuthStore";
import { handleTopButtonScroll } from "../../../utils/common";
import { NotificationModal } from "../../../components/modals";
import { CustomActivityIndicator } from "../../../components/common";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";
// import ArticleDisclosureModal from "../../brand/presenter/ArticleDisclosureModal";

const BookmarkFeed = observer(
  ({ feedTypeCode }: { feedTypeCode: FeedTypeCode }) => {
    const {
      bookmarkFeeds,
      selectedBookmarkFeeds,
      isTotalChecked,
      isEdit,
      initBookmarkPosts,
      setIsTotalChecked,
      setIsEdit,
      clearSelectedBookmarkFeeds,
      selectBookmarkFeeds,
      currentTab,
      updateWithMultiplePoppedPosts,

      setShowTopButton,
    } = BookmarkStore;

    const { setLoading, setToastOption } = CommonStore;
    const { sessionUser, tempMember } = AuthStore;

    const { t } = useTranslation();
    const [feedType, setFeedType] = useState("");
    const [isModal, setIsModal] = useState(false);
    const [isShowDisclosurePopup, setIsShowDisclosurePopup] = useState(false);
    const postRef = useRef(null);
    const qnaRef = useRef(null);
    const abRef = useRef(null);
    const productRef = useRef(null);
    const articleRef = useRef(null);

    const handleDelete = (feedTypeCode: FeedTypeCode) => {
      const deleteFeeds = selectedBookmarkFeeds.get(feedTypeCode);
      if (deleteFeeds !== undefined) {
        setLoading(true);
        deleteBookmarks(feedTypeCode, Array.from(deleteFeeds))
          .then(() => {
            updateWithMultiplePoppedPosts(Array.from(deleteFeeds));
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
      setIsEdit(feedTypeCode, false);
      setIsModal(false);
    };

    const toggleEdit = () => {
      setIsEdit(feedTypeCode, !isEdit.get(feedTypeCode));
    };

    const handleEditCancle = () => {
      toggleEdit();
      clearSelectedBookmarkFeeds(feedTypeCode);
      setIsTotalChecked(feedTypeCode, false);
    };

    const handleTotalChecked = () => {
      toggleTotalChecked();
      const allPosts: number[] = [];
      bookmarkFeeds.get(feedTypeCode)?.forEach((data) => {
        if (feedTypeCode == FeedTypeCode.PRODUCT) {
          data.productId && allPosts.push(data.productId);
        } else {
          allPosts.push(data.feedId);
        }
      });
      selectBookmarkFeeds(feedTypeCode, allPosts);
    };

    const handleTotalUnchecked = () => {
      toggleTotalChecked();
      clearSelectedBookmarkFeeds(feedTypeCode);
    };

    const toggleTotalChecked = () => {
      setIsTotalChecked(feedTypeCode, !isTotalChecked.get(feedTypeCode));
    };

    const handleDisclosureArticleClick = () => {
      setIsShowDisclosurePopup(true);
    };

    useEffect(() => {
      switch (feedTypeCode) {
        case FeedTypeCode.POST:
          setFeedType(t("screen.bookmark.label.post"));
          break;
        case FeedTypeCode.QNA:
          setFeedType(t("screen.bookmark.label.qna"));
          break;
        case FeedTypeCode.AB:
          setFeedType(t("screen.bookmark.label.ab"));
          break;
        case FeedTypeCode.PRODUCT:
          setFeedType(t("screen.bookmark.label.product"));
          break;
        case FeedTypeCode.ARTICLE:
          setFeedType(t("screen.bookmark.label.brandPage"));
          break;
        default:
          setFeedType(t("screen.bookmark.label.post"));
          break;
      }
    }, [feedTypeCode, t, initBookmarkPosts]);

    const renderContentsHeader = () => {
      if (
        bookmarkFeeds.get(feedTypeCode) == undefined ||
        bookmarkFeeds.get(feedTypeCode)?.length == 0
      ) {
        return (
          <div className="header">
            <div className="header_left">
              <div className="header_text_wrap">
                <p className="header_text">
                  {t("screen.bookmark.button.total")}
                  <span className="header_text_count">0</span>
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="header">
            <div className="header_left">
              {!isEdit.get(feedTypeCode) ? (
                <div className="header_text_wrap">
                  <p className="header_text">
                    {t("screen.bookmark.button.total")}
                    <span className="header_text_count">
                      {bookmarkFeeds.get(feedTypeCode)?.length}
                    </span>
                  </p>
                </div>
              ) : (
                <>
                  {isTotalChecked.get(feedTypeCode) ? (
                    <button
                      className="header_check_btn"
                      onClick={handleTotalUnchecked}
                    >
                      <CheckOnIcon />
                    </button>
                  ) : (
                    <button
                      className="header_check_btn"
                      onClick={handleTotalChecked}
                    >
                      <CheckOffIcon />
                    </button>
                  )}

                  <div className="header_text_wrap">
                    <p className="header_text">
                      {t("screen.bookmark.button.totalSelect")}
                      <span className="header_text_count">
                        {selectedBookmarkFeeds.get(feedTypeCode)?.size}
                      </span>
                      /{bookmarkFeeds.get(feedTypeCode)?.length}
                    </p>
                  </div>
                </>
              )}
            </div>
            {isEdit.get(feedTypeCode) ? (
              <button className="header_btn" onClick={handleEditCancle}>
                <p>{t("screen.bookmark.button.cancel")}</p>
              </button>
            ) : (
              <button className="header_btn" onClick={toggleEdit}>
                <p>{t("screen.bookmark.button.edit")}</p>
              </button>
            )}
          </div>
        );
      }
    };

    const renderListItem = (item: BookmarkFeedItem, index: number) => {
      switch (feedTypeCode) {
        case FeedTypeCode.POST:
          return <BookmarkPost data={item} listIndex={index} />;
        case FeedTypeCode.QNA:
          return <BookmarkQna data={item} listIndex={index} />;
        case FeedTypeCode.AB:
          return <BookmarkAB data={item} listIndex={index} />;
        case FeedTypeCode.PRODUCT:
          return <BookmarkProduct data={item} />;
        // case FeedTypeCode.ARTICLE:
        //   return (
        //     <BookmarkArticle
        //       data={item}
        //       listIndex={index}
        //       disclosureArticleCallback={handleDisclosureArticleClick}
        //     />
        //   );
        default:
          return <></>;
      }
    };

    const renderEmptyImage = () => {
      switch (feedTypeCode) {
        case FeedTypeCode.POST:
          return (
            <img
              src={require("../../../assets/images/empty_post_list.png")}
              alt={"empty_post_list"}
              style={{ width: "100%" }}
            />
          );
        case FeedTypeCode.QNA:
        case FeedTypeCode.AB:
        case FeedTypeCode.PRODUCT:
        case FeedTypeCode.ARTICLE:
          return (
            <>
              <img
                src={require("../../../assets/images/empty_ab_list.png")}
                style={{ width: "100%" }}
              />
              <div
                style={{
                  background:
                    "linear-gradient(rgba(255, 255, 255, 0), #ffffff)",
                  width: "100%",
                  height: "100vh",
                }}
              />
            </>
          );
      }
    };

    const RenderModalComment = () => {
      const deleteCount = selectedBookmarkFeeds.get(feedTypeCode)?.size;
      if (deleteCount !== undefined && deleteCount == 1) {
        return t("screen.bookmark.message.deleteOne");
      } else {
        return (
          t("screen.bookmark.message.selectedfeeds", {
            deleteCount: deleteCount,
          }) +
          "\n" +
          t("screen.bookmark.message.delete")
        );
      }
    };

    const onScrollHandler = (e: any) => {
      handleTopButtonScroll(
        e,
        () => {
          setShowTopButton(true);
        },
        () => {
          setShowTopButton(false);
        },
        currentTab === FeedTypeCode.POST
          ? postRef
          : currentTab === FeedTypeCode.QNA
          ? qnaRef
          : currentTab === FeedTypeCode.AB
          ? abRef
          : currentTab === FeedTypeCode.PRODUCT
          ? productRef
          : currentTab === FeedTypeCode.ARTICLE
          ? articleRef
          : null
      );
    };

    return (
      <>
        <div id="bookmark_feed">
          {renderContentsHeader()}
          {bookmarkFeeds.get(feedTypeCode)?.length == 0 ? (
            <div className="empty">
              <div style={{ flex: 1 }}>
                {/* {renderEmptyImage()} */}
                <div className="empty_ab">
                  <p>
                    {feedTypeCode === FeedTypeCode.ARTICLE
                      ? replaceKoreanEndWordByTarget(
                          t("screen.bookmark.message.empty", {
                            feedType: t("screen.bookmark.label.article"),
                          })
                            .replace("&amp;", "&")
                            .replace("&#x2F;", "/"),
                          t("screen.bookmark.label.article")
                        )
                      : replaceKoreanEndWordByTarget(
                          t("screen.bookmark.message.empty", {
                            feedType: feedType,
                          })
                            .replace("&amp;", "&")
                            .replace("&#x2F;", "/"),
                          feedType
                        )}
                  </p>
                  {feedTypeCode !== FeedTypeCode.PRODUCT && (
                    <button
                      className="empty_ab_btn"
                      onClick={() => {
                        switch (feedTypeCode) {
                          case FeedTypeCode.POST:
                            goTo("/posts");
                            break;
                          case FeedTypeCode.QNA:
                            goTo("/qna");
                            break;
                          case FeedTypeCode.AB:
                            goTo("/abList");
                            break;
                          case FeedTypeCode.ARTICLE:
                            // navigate("BrandCommunityMain");
                            break;
                        }
                      }}
                    >
                      <p className="empty_ab_btn_text">
                        {t("screen.bookmark.message.recommend", {
                          feedType: feedType,
                        })
                          .replace("&amp;", "&")
                          .replace("&#x2F;", "/")}
                      </p>
                    </button>
                  )}
                </div>
              </div>
            </div>
          ) : currentTab === FeedTypeCode.POST ? (
            <div ref={postRef} aria-label="Bookmark feed scroll">
              <div className="post_grid_wrap">
                {bookmarkFeeds
                  .get(feedTypeCode)
                  ?.slice()
                  .map((item, index) => renderListItem(item, index))}
              </div>
            </div>
          ) : currentTab === FeedTypeCode.QNA ? (
            <div ref={qnaRef} aria-label="Bookmark feed scroll">
              <div className="qna_grid_wrap">
                {bookmarkFeeds
                  .get(feedTypeCode)
                  ?.slice()
                  .map((item, index) => renderListItem(item, index))}
              </div>
            </div>
          ) : currentTab === FeedTypeCode.AB ? (
            <div ref={abRef} aria-label="Bookmark feed scroll">
              <div className="ab_list_wrap">
                {bookmarkFeeds
                  .get(feedTypeCode)
                  ?.slice()
                  .map((item, index) => renderListItem(item, index))}
              </div>
            </div>
          ) : currentTab === FeedTypeCode.PRODUCT ? (
            <div ref={productRef} aria-label="Bookmark feed scroll">
              <div className="product_grid_wrap">
                {bookmarkFeeds
                  .get(feedTypeCode)
                  ?.slice()
                  .map((item, index) => renderListItem(item, index))}
              </div>
            </div>
          ) : currentTab === FeedTypeCode.ARTICLE ? (
            <div ref={postRef} aria-label="Bookmark feed scroll">
              <div className="article_grid_wrap">
                {bookmarkFeeds
                  .get(feedTypeCode)
                  ?.slice()
                  .map((item, index) => renderListItem(item, index))}
              </div>
            </div>
          ) : null}
        </div>

        {isEdit.get(feedTypeCode) && (
          <button
            // style={
            //   selectedBookmarkFeeds.get(feedTypeCode) == undefined ||
            //   selectedBookmarkFeeds.get(feedTypeCode)?.size == 0
            //     ? [footer_btn_in_active, { height: 56 }]
            //     : [footer_btn, { height: 56 }]
            // }
            onClick={() => setIsModal(true)}
            disabled={
              selectedBookmarkFeeds.get(feedTypeCode) == undefined ||
              selectedBookmarkFeeds.get(feedTypeCode)?.size == 0
            }
            aria-label="Click delete button"
            className="footer_btn"
          >
            <p
            // style={
            //   selectedBookmarkFeeds.get(feedTypeCode) == undefined ||
            //   selectedBookmarkFeeds.get(feedTypeCode)?.size == 0
            //     ? footer_btn_text_in_active
            //     : footer_btn_text
            // }
            >
              {t("screen.bookmark.label.delete")}
            </p>
          </button>
        )}

        {/* [1622] 공통 팝업 */}
        {isModal && (
          <NotificationModal
            isVisible={isModal}
            contents1={RenderModalComment()}
            useTwoButton={true}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.OK")}
            extraButtonText={t("screen.notificationModal.button.cancel")}
            onClickDefaultButton={() => handleDelete(feedTypeCode)}
            onClickExtraButton={() => setIsModal(false)}
            onRequestClose={() => setIsModal(false)}
          />
        )}
        {/* 브랜드 */}
        {/* {isShowDisclosurePopup && (
          <ArticleDisclosureModal
            isVisible={isShowDisclosurePopup}
            contents1={t(
              "screen.notificationModal.message.disclosureArticleText1"
            )}
            contents2={t(
              "screen.notificationModal.message.disclosureArticleText2"
            )}
            textAlign="center"
            onRequestClose={() => {
              setIsShowDisclosurePopup(false);
            }}
          />
        )} */}
      </>
    );
  }
);
export default BookmarkFeed;
