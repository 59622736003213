import { memo, useRef } from "react"
import useDragScrollWithMouse from "../../../hooks/useDragScrollWithMouse"
import classes from './FilterList.module.scss'
import clsx from "clsx"
import { ReactComponent as DeleteIcon } from '../../../assets/icons/mall/icon_filter_del.svg'
import { Attribute_vlues, Attributes } from "../../../api/mallCategory/model"

const FilterItem = ({
    att,
    selectFilter
  }:
  {
    att: Attributes,
    selectFilter: (key: number, name: string, item: Attribute_vlues) => void
  }) => {

  const listTabsRef = useRef<HTMLDivElement>(null)
  const isDragging = useDragScrollWithMouse(listTabsRef)

  return (
    <div
      ref={listTabsRef} className={classes.bx_flat_wrap}
      aria-label={"category best"}>
      {att.attributeValueList.map((item: Attribute_vlues, index: number) => <div
        className={classes.btn_toggle}
        key={index}
        onClick={() => {
          !isDragging && selectFilter(att.attributeId, att.attributeName, item)
        }}
      >
        <span
          className={clsx(
            classes.txt_toggle,
            item.check !== undefined &&
            item.check &&
            classes.txt_toggle_on
          )}
        >
          {item.attributeValueName}
        </span>
        {item.check !== undefined && item.check && (
          <DeleteIcon />
        )}
      </div>)}
    </div>

  )
}

export default memo(FilterItem)