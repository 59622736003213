import { action, computed, makeObservable, observable } from "mobx";
import { saveReport } from "../api/report/api";
// import {
//   canGoBack,
//   goBack,
//   navigate,
//   popToTop,
// } from "../navigation/NavigationFunction";
import CommonStore from "./CommonStore";
import { t } from "i18next";
import { ReportBottomSheetProps } from "../components/common/ReportBottomSheet";
import { ReportConfirmPopupProps } from "../components/common/ReportConfirmPopup";

export interface CommonReport {
  parentCommentId?: number;
  reportTargetId?: string;
  reportTargetTypeCode?: string;
  reportReasons?: string[];
}

const VOID_FUNC = () => {
  //void
};
class ReportStore {
  constructor() {
    makeObservable(this);
  }

  @observable _reportBottomSheetProps: ReportBottomSheetProps = {
    open: false,
    onClose: VOID_FUNC,
    onConfirm: VOID_FUNC,
  };
  @observable _reportConfirmPopupProps: ReportConfirmPopupProps = {
    open: false,
    onClose: VOID_FUNC,
  };

  // @observable _backEventSubscription: NativeEventSubscription | null = null;

  @computed get reportBottomSheetProps() {
    return this._reportBottomSheetProps;
  }

  @computed get ReportConfirmPopupProps() {
    return this._reportConfirmPopupProps;
  }

  @action setBackEventSubscription = (action: () => void) => {
    this.clearBackEventSubsription();
    // this._backEventSubscription = BackHandler.addEventListener(
    //   "hardwareBackPress",
    //   () => {
    //     action();
    //     this.clearBackEventSubsription();
    //     return true;
    //   }
    // );
  };

  @action clearBackEventSubsription = () => {
    // this._backEventSubscription?.remove();
    // this._backEventSubscription = null;
  };

  @action setReportBottomSheetProps = (props: ReportBottomSheetProps) => {
    this._reportBottomSheetProps = props;
  };

  @action clearReportBottomSheetProps = () => {
    this._reportBottomSheetProps = {
      open: false,
      onClose: VOID_FUNC,
      onConfirm: VOID_FUNC,
    };
  };

  @action setReportConfirmPopupProps = (props: ReportConfirmPopupProps) => {
    this._reportConfirmPopupProps = props;
  };

  @action clearReportConfirmPopupProps = () => {
    this._reportConfirmPopupProps = {
      open: false,
      onClose: VOID_FUNC,
    };
  };

  @action clearReportStore = () => {
    this.clearReportBottomSheetProps();
    this.clearReportConfirmPopupProps();
  };

  @action reportCompletePoptoTop = () => {
    // popToTop();
  };

  @action openReportBottomSheet = (
    target: CommonReport,
    afterReportSuccess?: () => void
  ) => {
    if (target.reportTargetId) {
      this.setBackEventSubscription(() => this.clearReportBottomSheetProps());
      this.setReportBottomSheetProps({
        open: true,
        onConfirm: (reasonCodes: Set<string>) => {
          this.clearReportBottomSheetProps();
          void this.report(
            { ...target, reportReasons: [...reasonCodes] },
            afterReportSuccess
          );
        },
        onClose: () => {
          this.clearReportBottomSheetProps();
        },
      });
    }
  };

  @action openReportConfirmPopup = () => {
    this.setBackEventSubscription(() => this.clearReportConfirmPopupProps());
    this.setReportConfirmPopupProps({
      open: true,
      onClose: () => {
        this.clearReportConfirmPopupProps();
      },
    });
  };

  @action report = async (
    commonReport: CommonReport,
    afterReportSuccess?: () => void
  ) => {
    if (commonReport.reportTargetId) {
      const res = await saveReport(commonReport);
      if (res.successOrNot === "Y") {
        afterReportSuccess && afterReportSuccess();
        this.openReportConfirmPopup();
      } else {
        return CommonStore.setToastOption({
          show: true,
          message1: t("screen.notificationModal.message.reportFail"),
          // bottomOffset: 30,
        });
      }
    }
  };
}
export default new ReportStore();
