import { useRef, useState } from "react";
import { useLockBodyScroll } from "react-use";
import { Icons, Texts, Colors } from "../../assets/styles";
import { ReactComponent as MoreIcon } from "../../assets/icons/more.svg";
import { ReactComponent as MoreWhiteIcon } from "../../assets/icons/more_white.svg";
import { useTranslation } from "react-i18next";
import { getSessionUserFromStorage } from "../../service/Session.service";
import { Styles } from "../../assets/types/Style";
import DropdownMenuModal from "./DropdownMenuModal";
import MainStore from "../../store/MainStore";

export type OptionType = "WRITE_ARTICLE" | "WRITE_POST" | "WRITE_QNA" | "REGIST_MYAPPLIANCE" | "BUY_PRODUCT";
export type BackColorType = "LIGHT" | "DARK";

interface ProductPopupMenuProps {
  backColor?: BackColorType;
  optionTypesToShow: OptionType[];
  onMenuClick: (type: OptionType) => void;
  needAuth?: boolean;
  disabled?: boolean;
  checkDisabled: (type: OptionType) => boolean | undefined;
}

export const ProductPopupMenu = ({
  backColor = "LIGHT",
  optionTypesToShow,
  onMenuClick,
  needAuth = false,
  checkDisabled,
}: ProductPopupMenuProps): JSX.Element => {

  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useLockBodyScroll(isOpen);

  const handleOpen = () => {
    if (needAuth) {
      getSessionUserFromStorage()
        .then((res) => {
          if (res?.nickname) {
            setIsOpen(true);
          } else {
            MainStore.setShowJoinModal(true);
          }
        })
        .catch(() => {
          MainStore.setShowJoinModal(true);
        });
    } else {
      setIsOpen(true);
    }
  };

  const handleHide = () => {
    setIsOpen(false);
  };

  const renderOptions = (index: number, option: any) => {
    const drawDropLine = index > 0 && index < optionTypesToShow.length;
    const isDisable = checkDisabled(option);

    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          alignItems: "center",
          borderTop: drawDropLine ? "1px solid #f9f3fa" : "none",
        }}
        key={`menu-item-${index}`}
        aria-label={`popup menu for ${option}`}
        onClick={() => {
          if(isDisable) return;
          onMenuClick(option as OptionType);
          handleHide();
        }}
      >
        <div style={{...styles.drop_text, ...(isDisable ? {color: '#929292', fontWeight: 400}: {})}}>
          {t(`common.header.${option.toLowerCase()}`)}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        ref={ref}
        style={{
          height: "100%",
        }}
        onClick={handleOpen}
      >
        <div
          style={{
            marginLeft: 12,
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
          aria-label="popup menu opener"
        >
          {backColor === "DARK" ? <MoreWhiteIcon /> : <MoreIcon />}
        </div>
      </div>
      <DropdownMenuModal
        isOpen={isOpen}
        onRequestClose={handleHide}
        data={optionTypesToShow}
        renderItem={renderOptions}
        targetRef={ref}
      />
    </>
  );
};

const styles: Styles = {
  drop_btn: {
    ...Colors.background_fff,
    ...Icons.icon_border_default,
    ...Colors.border_color_f9f3fa,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    width: 190,
    borderRadius: 4,
  },
  drop_text: {
    ...Texts.contents_text_3,
    paddingTop: 10,
    paddingBottom: 10,
  },
  drop_line: {
    ...Colors.background_f9f3fa,
    height: 1,
    marginTop: 2,
    marginBottom: 2
  },
  drop_box_shadow: {
    backgroundColor: "#FFF",
    shadowColor: "#000",
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowRadius: 3,
    shadowOpacity: 0.2,
  },
  more_icon: {
  },
};