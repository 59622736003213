export enum FooterMode {
  FILTER_LIST,
  ADJUST_LIST,
}

export type FilterTypeScreenProps = {
  thumbnailImage: string
  originalImage: string
}

export type FilterResult = {
  thumbnailImage: string
  originalImage: string
}
