import { action, computed, makeObservable, observable } from "mobx";
import {
  getAbListWithPagination,
  getPopularAbListWithPagination,
} from "../api/ab/api";
import {
  AbDetail,
  AbSearchCondition,
  AbRequest,
  AbVoteResult,
} from "../api/ab/model";
import { AbTypeCode } from "../constants/ab";
// import { getSessionUserFromStorage } from "../service/Session.service";

export type LoadingStatus = "REFRESHING" | "LOADING" | "LOADED" | "ALL_LOADED";
export const PAGE_SIZE = 10;
class AbStore {
  constructor() {
    makeObservable(this);
  }

  @observable _activePopularAbs: AbDetail[] = [];
  @observable _expiredPopularAbs: AbDetail[] = [];
  @observable _Abs: AbDetail[] = [];
  @observable _myAbs: AbDetail[] = [];

  @observable _AbLoadingStatus: LoadingStatus = "LOADED";
  @observable _myAbLoadingStatus: LoadingStatus = "LOADED";

  @observable _extraDataforAbListView = new Object();
  @observable _extraDataforMyAbListView = new Object();

  @computed get activePopularAbs() {
    return this._activePopularAbs;
  }
  @computed get expiredPopularAbs() {
    return this._expiredPopularAbs;
  }

  @computed get Abs() {
    return this._Abs;
  }

  @computed get myAbs() {
    return this._myAbs;
  }

  @computed get AbLoadingStatus() {
    return this._AbLoadingStatus;
  }

  @computed get myAbLoadingStatus() {
    return this._myAbLoadingStatus;
  }

  @computed get extraDataforAbListView() {
    return this._extraDataforAbListView;
  }

  @computed get extraDataforMyAbListView() {
    return this._extraDataforMyAbListView;
  }

  @action setActivePopularAbs = (abs: AbDetail[]) => {
    this._activePopularAbs = abs;
  };

  @action setExpiredPopularAbs = (abs: AbDetail[]) => {
    this._expiredPopularAbs = abs;
  };

  @action setAbs = (abs: AbDetail[]) => {
    this._Abs = abs;
  };

  @action setMyAbs = (abs: AbDetail[]) => {
    this._myAbs = abs;
  };

  @action setAbLoadingStatus = (status: LoadingStatus) => {
    this._AbLoadingStatus = status;
  };

  @action setMyAbLoadingStatus = (status: LoadingStatus) => {
    this._myAbLoadingStatus = status;
  };

  @action setNewExtraDataforAbListView = () => {
    this._extraDataforAbListView = new Object();
  };

  @action setNewExtraDataforMyAbListView = () => {
    this._extraDataforMyAbListView = new Object();
  };

  @action popAB = (feedId: number) => {
    this._activePopularAbs.forEach((elem, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      elem.feedId === feedId ? this._activePopularAbs.splice(index, 1) : "";
    });

    this._expiredPopularAbs.forEach((elem, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      elem.feedId === feedId ? this._expiredPopularAbs.splice(index, 1) : "";
    });

    this._Abs.forEach((elem, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      elem.feedId === feedId ? this._Abs.splice(index, 1) : "";
    });

    this._myAbs.forEach((elem, index) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      elem.feedId === feedId ? this._myAbs.splice(index, 1) : "";
    });
  };

  @action retrieveExpiredPopularAbList = async () => {
    const res = await getPopularAbListWithPagination({
      endYn: "Y",
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    });
    if (res && res.successOrNot === "Y" && res.data && res.data.list) {
      this.setExpiredPopularAbs(res.data.list);
    } else {
      this.setExpiredPopularAbs([]);
    }
  };

  @action retrieveActivePopularAbList = async () => {
    const res = await getPopularAbListWithPagination({
      endYn: "N",
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    });
    if (res && res.successOrNot === "Y" && res.data && res.data.list) {
      this.setActivePopularAbs(res.data.list);
    } else {
      this.setActivePopularAbs([]);
    }
  };

  @action retrieveAbList = async (condition: AbSearchCondition) => {
    this.setAbLoadingStatus("LOADING");

    // getSessionUserFromStorage 붙이는 작업 필요
    // const sessionUser = await getSessionUserFromStorage();
    const sessionUser = {
      nickname: "dulu",
    };
    const request: AbRequest = {
      votedYn: sessionUser?.nickname ? "N" : "NA",
      sortType: condition.sortType || "Latest",
      pageIndex: condition.pageIndex,
      pageSize: condition.pageSize,
      endYn: condition.sortType === "Latest" ? undefined : condition.endYn,
    };

    const res = await getAbListWithPagination(request);

    if (
      res &&
      res.successOrNot === "Y" &&
      res.data &&
      res.data.list &&
      res.data.list.length > 0
    ) {
      if (request.pageIndex === 0) {
        this.setAbs(res.data.list);
      } else {
        this.setAbs([...this.Abs, ...res.data.list]);
      }
      this.setAbLoadingStatus("LOADED");
    } else {
      this.setAbLoadingStatus("ALL_LOADED");
    }
  };

  @action retrieveMyAbList = async (condition: AbSearchCondition) => {
    this.setMyAbLoadingStatus("LOADING");
    const request: AbRequest = {
      votedYn: "Y",
      pageIndex: condition.pageIndex || 0,
      pageSize: PAGE_SIZE,
    };
    const res = await getAbListWithPagination(request);

    if (
      res &&
      res.successOrNot === "Y" &&
      res.data &&
      res.data.list &&
      res.data.list.length > 0
    ) {
      if (request.pageIndex === 0) {
        this.setMyAbs(res.data.list);
      } else {
        this.setMyAbs([...this.myAbs, ...res.data.list]);
      }
      this.setMyAbLoadingStatus("LOADED");
    } else {
      this.setMyAbLoadingStatus("ALL_LOADED");
    }
  };

  @action updatePopularAbVoteResultTemporaily = (
    res: AbVoteResult,
    myAbFlag: AbTypeCode | null
  ) => {
    const index = this.activePopularAbs.findIndex(
      (ab) => ab.feedId === res.voteTargetId
    );
    if (index >= 0) {
      const prevAb = this.activePopularAbs[index];
      const nextAb = {
        ...prevAb,
        avoteCount: res.avoteCount,
        bvoteCount: res.bvoteCount,
        myAbFlag: myAbFlag,
      };
      this._activePopularAbs.splice(index, 1, nextAb);
      this.setNewExtraDataforAbListView();
    }
  };

  @action updateAbVoteResultTemporaily = (
    res: AbVoteResult,
    myAbFlag: AbTypeCode | null
  ) => {
    const index = this.Abs.findIndex((ab) => ab.feedId === res.voteTargetId);
    if (index >= 0) {
      const prevAb = this.Abs[index];
      const nextAb = {
        ...prevAb,
        avoteCount: res.avoteCount,
        bvoteCount: res.bvoteCount,
        myAbFlag: myAbFlag,
      };
      this._Abs.splice(index, 1, nextAb);
      this.setNewExtraDataforAbListView();
    }
  };

  @action updateMyAbVoteResultTemporaily = (
    res: AbVoteResult,
    myAbFlag: AbTypeCode | null
  ) => {
    const index = this.myAbs.findIndex((ab) => ab.feedId === res.voteTargetId);
    if (index >= 0) {
      const prevAb = this.myAbs[index];
      const nextAb = {
        ...prevAb,
        avoteCount: res.avoteCount,
        bvoteCount: res.bvoteCount,
        myAbFlag: myAbFlag,
      };
      this._myAbs.splice(index, 1, nextAb);
      this.setNewExtraDataforMyAbListView();
    }
  };

  @action clear = () => {
    this._expiredPopularAbs = [];
    this._Abs = [];
    this._myAbs = [];
    this._AbLoadingStatus = "LOADED";
    this._myAbLoadingStatus = "LOADED";
  };
}

export default new AbStore();
