import { observer } from "mobx-react";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { BrandRecommendResponse } from "../../../api/brand/model";
import { Styles } from "../../../assets/types/Style";
import { useTranslation } from "react-i18next";

const PUBLIC_BUCKET_URL: string = process.env.REACT_APP_PUBLIC_BUCKET_URL || "";

const BrandRecommendItem = observer(
  ({
    onPress,
    actionBrandSubscribe,
    item,
    index,
  }: {
    onPress: (communityId: number) => void;
    actionBrandSubscribe: (comunityId: number) => void;
    item: BrandRecommendResponse;
    index: number;
  }) => {

    const { t } = useTranslation();

    return (
      <div style={{ paddingRight: 8, ...(index === 0 ? { paddingLeft: 16 } : {}) }}>
        <div style={styles.popular_post_item}>
          <button
            style={{ width: '100%' }}
            key={item.communityId}
            onClick={() => {
              onPress(item.communityId);
            }}
          >
            <div style={styles.popular_img_wrap}>
              <FastImageWithFallback
                style={styles.popular_post_img}
                source={{ uri: `${PUBLIC_BUCKET_URL}${item?.brandHomeBannerImagePath || ""}` }}
              />
            </div>
            <div style={styles.bx_img_wrap}>
              <FastImageWithFallback
                style={styles.img_logo}
                source={{ uri: `${PUBLIC_BUCKET_URL}${item?.communityLogoUrl || ""}` }}
              />
            </div>
            <div style={styles.popular_post_description}>
              <p style={styles.popular_post_text1}>
                {item.communityName}
              </p>
              <p style={styles.popular_post_text2}>
                {item.communityIntroduce}
              </p>
            </div>
          </button>

          <div style={styles.bx_brand_btn}>
            {item.subscriptionYn === "Y" ? (
              <div style={styles.brand_btn_st2}>
                <p style={styles.brand_btn_st2_text}>{t("screen.brandCommunity.label.subscribing")}</p>
              </div>
            ) : (
              <TouchableWithAuthCheck style={styles.brand_btn_st1} onPress={() => actionBrandSubscribe(item.communityId)}>
                <p style={styles.brand_btn_st1_text}>{t("screen.brandCommunity.button.subscribe")}</p>
              </TouchableWithAuthCheck>
            )}
          </div>
        </div>
      </div>
    );
  }
);
export default BrandRecommendItem;

const styles: Styles = {
  popular_post_item: {
    position: "relative",
    borderBottomWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: 'solid',
    borderColor: "rgba(34,34,34,0.1)",
    borderRadius: 6,
    overflow: "hidden",
    width: 205,
  },
  popular_post_first_item: {
    ...Spacings.padding_left_16,
  },
  popular_img_wrap: {
    position: "relative",
    width: '100%',
    height: 69,
    overflow: "hidden",
  },
  popular_post_img: {
    ...FlexBoxs.flex,
    position: "absolute",
    ...FlexBoxs.flex_1,
    ...Spacings.margin_bottom_10,
    height: 100,
    width: '100%',
  },
  badge_img: {
    ...Positions.absolute,
    top: 8,
    right: 8,
  },
  bx_img_wrap: {
    position: "absolute",
    top: 47,
    right: 16,
    width: 44,
    height: 44,
    borderBottomWidth: 2,
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderStyle: 'solid',
    borderColor: "#fff",
    borderRadius: 300,
    overflow: "hidden",
  },
  img_logo: {
    width: 40,
    height: 40,
    resizeMode: "contain",
  },
  popular_post_description: {
    ...FlexBoxs.flex_1,
    paddingTop: 24,
    paddingLeft: 16,
    paddingRight: 16,
    textAlign: 'start'
  },
  popular_post_text1: {
    fontSize: 16,
    letterSpacing: -0.4,
    marginBottom: 4,
    ...Texts.font_weight_500,
    overflow: 'hidden', 
    whiteSpace: 'nowrap', 
    textOverflow: 'ellipsis', 
    maxWidth: '100%'
  },
  popular_post_text2: {
    ...Texts.contents_text_0,
    minHeight: 40,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    lineClamp: '2',
    WebkitBoxOrient: 'vertical',
    whiteSpace: 'pre-wrap'
  },
  bx_brand_btn: {
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 14,
  },
  brand_btn_st1: {
    ...FlexBoxs.flex,
    height: 32,
    width: 60,
    borderRadius: 4,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Colors.background_be69c3,
  },
  brand_btn_st1_text: {
    fontSize: 14,
    fontWeight: "500",
    ...Colors.font_fff,
    letterSpacing: -0.56,
  },
  brand_btn_st2: {
    ...FlexBoxs.flex,
    height: 32,
    width: 60,
    borderRadius: 4,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Colors.background_fff,
  },
  brand_btn_st2_text: {
    ...Colors.font_999,
  },
};
