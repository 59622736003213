import { TitleHeader } from '../../components/common'
import { HeaderItem } from '../../constants/Header'
import classes from './FilterTypeScreen.module.scss'
import { track } from '../../hooks/tracker/TrackFunction'
import useImage from 'use-image'
import { goBack } from '../../hooks/navigate/NavigateFunction'
import Konva from 'konva'
import { Layer, Stage, Image as KonvaImage } from 'react-konva'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AdjustItem, Adjusts, FilterItem, Filters } from './filters'
import Slider from '@mui/material/Slider'
import { Filter } from 'konva/lib/Node'
import { FilterResult, FilterTypeScreenProps, FooterMode } from './types'
import FilterItemComponent from './FilterItemComponent'
import { useTranslation } from 'react-i18next'
import { ReactComponent as FilterOffIcon } from '../../assets/filter/filter_off_32x32.svg'
import { ReactComponent as FilterOnIcon } from '../../assets/filter/filter_on_32x32.svg'
import { ReactComponent as AdjustOffIcon } from '../../assets/filter/adjust_off_32x32.svg'
import { ReactComponent as AdjustOnIcon } from '../../assets/filter/adjust_on_32x32.svg'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import CommonStore from '../../store/CommonStore'
import useDragScrollWithMouse from '../../hooks/useDragScrollWithMouse'
import eventBus from '../../utils/eventbus'
import CustomActivityIndicator from '../../components/common/CustomActivityIndicatorSmall'

const FilterTypeScreen = observer(() => {
  const footerRef = useRef<HTMLDivElement>(null)
  const adjustPanelRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  const { widthApp } = CommonStore
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })
  const [spaceHeight, setSpaceHeight] = useState<number | undefined>(0)

  const { t } = useTranslation()
  const { thumbnailImage, originalImage }: FilterTypeScreenProps =
    useLocation().state || {}
  const [image] = useImage(thumbnailImage ?? '', 'anonymous')
  const imageRef = useRef<Konva.Image>(null)
  const stageRef = useRef<Konva.Stage>(null)

  const [filters, setFilters] = useState<Filter[]>([]) // 현재 적용중인 "UI상의 필터" (로직상 한개만 적용됨)
  const [tempAdjustItem, setTempAdjustItem] = useState<AdjustItem>() // 현재 편집중인 조정 정보 (조정 끝나면 undefined 로 설정)
  const [adjustItems, setAdjustItems] = useState<AdjustItem[]>([]) // 현재 적용중인 조정 정보들

  const [footerMode, setFooterMode] = useState<FooterMode>()
  const [loading, setLoading] = useState<boolean>(false)

  const isDragList = useDragScrollWithMouse(
    listRef,
    footerMode === undefined || tempAdjustItem
      ? undefined
      : `.${
          footerMode === FooterMode.ADJUST_LIST
            ? classes.adjust_list
            : classes.filter_list
        }`
  )

  const resultImageData = () => {
    return new Promise<FilterResult>((resolve, reject) => {
      const imageObj = new Image()
      imageObj.src = originalImage
      imageObj.onload = () => {
        const konvaImage = new Konva.Image({
          image: imageObj,
        })
        const adjustFilters = adjustItems.map(
          (adjust) => adjust.filter || ((_: ImageData) => {})
        )
        apply(konvaImage, [...filters, ...adjustFilters], adjustItems)

        // TODO: 이 밑에 두줄이 너무 느리다
        const thumbnailImage = imageRef.current?.toDataURL() || ''
        const originalImage = konvaImage.toDataURL()

        resolve({
          thumbnailImage,
          originalImage,
        })
      }
    })
  }

  const apply = useCallback(
    (image: Konva.Image, filters: Filter[], adjustItems: AdjustItem[]) => {
      image.filters([...filters])
      adjustItems.forEach((item) => {
        item.valueFunction(image, item.currentValue)
      })
      image.cache()
    },
    []
  )

  useEffect(() => {
    var copyAdjusts: AdjustItem[] = [...adjustItems]
    if (tempAdjustItem) copyAdjusts.push(tempAdjustItem)
    const applyAdjusts = removeAdjustDuplicates(copyAdjusts)

    var adjustFilters: Filter[] = adjustItems
      .filter((item) => {
        return item.filter !== undefined
      })
      .flatMap((item): Filter => {
        return item.filter || ((_) => {})
      })
    if (tempAdjustItem) {
      var tempFilter: Filter = tempAdjustItem?.filter || ((_) => {})
      adjustFilters.push(tempFilter)
    }
    var adjustFilterSet = new Set(adjustFilters)
    var applyFilters = [...filters, ...adjustFilterSet]

    if (imageRef.current) {
      apply(imageRef.current, applyFilters, applyAdjusts)
    }
  }, [tempAdjustItem, adjustItems, filters, apply])

  useEffect(() => {
    if (!image) return

    // 스테이지 크기 가져오기
    const stageWidth = widthApp - 90
    const stageHeight = window.innerHeight

    // 이미지의 원본 크기 가져오기
    const originalWidth = image.width
    const originalHeight = image.height

    // 비율 유지하며 이미지 크기 조정
    const scale = Math.min(
      stageWidth / originalWidth,
      stageHeight / originalHeight
    )
    const newWidth = originalWidth * scale
    const newHeight = originalHeight * scale

    setDimensions({
      width: newWidth,
      height: newHeight,
    })
  }, [image, widthApp])

  useEffect(() => {
    setSpaceHeight(
      tempAdjustItem
        ? adjustPanelRef.current?.clientHeight
        : footerRef.current?.clientHeight
    )
  }, [tempAdjustItem, footerMode])

  function setFilter(filter: Filter) {
    setFilters([filter])
  }

  function clearFilter() {
    setFilters([])
    setAdjustItems([])
    setTempAdjustItem(undefined)
  }

  function addAdjust(item: AdjustItem) {
    adjustItems.push(item)
    const newAdjustItems = removeAdjustDuplicates(adjustItems)
    setAdjustItems(newAdjustItems)
  }

  function removeAdjustDuplicates(array: AdjustItem[]): AdjustItem[] {
    const uniqueKeys = new Set()
    return array.filter((item) => {
      const keyValue = item.title
      if (uniqueKeys.has(keyValue)) {
        return false
      } else {
        uniqueKeys.add(keyValue)
        return true
      }
    })
  }

  const onFilteredImage = (result: FilterResult) => {
    eventBus.publish('onFilteredImage', result)
  }

  const renderImageStage = () => {
    return (
      <div
        className={classes.image_stage_wrap}
        style={{ paddingBottom: spaceHeight }}
      >
        <Stage
          className={classes.image_stage}
          width={dimensions.width}
          height={dimensions.height}
          ref={stageRef}
        >
          <Layer>
            <KonvaImage
              image={image}
              ref={imageRef}
              width={dimensions.width}
              height={dimensions.height}
            />
          </Layer>
        </Stage>
      </div>
    )
  }

  const renderFilterList = () => {
    return (
      <div ref={listRef} className={classes.filter_list}>
        {Filters.map(renderFilterItem)}
      </div>
    )
  }

  const renderFilterItem = (item: FilterItem) => {
    return (
      <div
        onClick={(event) => {
          !isDragList && setFilter(item.filter)
        }}
        className={classes.filter_item_wrap}
      >
        <FilterItemComponent image={image} filterItem={item} />
        <span className={classes.filter_title}>{item.title}</span>
      </div>
    )
  }

  const renderAdjustList = () => {
    return (
      <div ref={listRef} className={classes.adjust_list}>
        {Adjusts.map((adjust) => {
          const renderItem = adjustItems.find((item) => {
            return adjust.title === item.title
          })
          return renderAdjustItem(renderItem || adjust)
        })}
      </div>
    )
  }

  const renderAdjustItem = (item: AdjustItem) => {
    var applied = false
    if (
      adjustItems.find((adjust) => {
        return adjust.title === item.title
      })
    ) {
      applied = true
    }

    return (
      <div
        className={classes.adjust_item}
        onClick={() => !isDragList && setTempAdjustItem(item)}
      >
        {applied ? item.iconOnComponent : item.iconOffComponent}
        <span className={classes.adjust_item_text}>{item.title}</span>
      </div>
    )
  }

  const renderAdjustPanel = () => {
    if (!tempAdjustItem) return <></>

    const marks = [
      {
        value: tempAdjustItem.minmax.min,
        label: `${tempAdjustItem.minmax.min}`,
      },
      {
        value: tempAdjustItem.initialValue,
        label: `${tempAdjustItem.initialValue}`,
      },
      {
        value: tempAdjustItem.minmax.max,
        label: `${tempAdjustItem.minmax.max}`,
      },
    ]

    return (
      <div ref={adjustPanelRef} className={classes.adjust_panel_wrap}>
        <div className={classes.adjust_slider_wrap}>
          <Slider
            className={classes.adjust_slider}
            step={tempAdjustItem.minmax.step}
            min={tempAdjustItem.minmax.min}
            max={tempAdjustItem.minmax.max}
            marks={marks}
            defaultValue={tempAdjustItem.initialValue}
            value={tempAdjustItem.currentValue}
            valueLabelDisplay="on"
            classes={{
              root: classes.slider_root,
              rail: classes.slider_rail,
              thumb: classes.slider_thumb,
              mark: clsx(
                classes.slider_mark,
                [tempAdjustItem.minmax.min, tempAdjustItem.minmax.max].includes(
                  0
                ) && classes.slider_mark_without_label
              ),
              markLabel: classes.slider_mark_label,
            }}
            onChange={(event, value) => {
              var applyValue: number
              if (!Array.isArray(value)) {
                applyValue = value
              } else {
                applyValue = value[0]
              }
              setTempAdjustItem({
                ...tempAdjustItem,
                currentValue: applyValue,
              })
            }}
          />
        </div>
        <div className={classes.adjust_panel_footer}>
          <button
            onClick={() => {
              setTempAdjustItem(undefined)
            }}
            className={clsx(classes.cancel_btn, classes.adjust_btn)}
          >
            {t('screen.imagefilter.cancel')}
          </button>
          <button
            onClick={() => {
              addAdjust(tempAdjustItem)
              setTempAdjustItem(undefined)
            }}
            className={clsx(classes.apply_btn, classes.adjust_btn)}
          >
            {t('screen.imagefilter.apply')}
          </button>
        </div>
      </div>
    )
  }

  const renderFooter = () => {
    const isActiveMode = (mode: number) => mode === footerMode
    return (
      <div ref={footerRef} className={classes.footer}>
        {footerMode === FooterMode.FILTER_LIST && renderFilterList()}
        {footerMode === FooterMode.ADJUST_LIST && renderAdjustList()}
        <div className={classes.btn_mode_wrap}>
          <button
            className={clsx(
              classes.btn_mode,
              isActiveMode(FooterMode.FILTER_LIST) && classes.active_mode
            )}
            onClick={() => setFooterMode(FooterMode.FILTER_LIST)}
          >
            {isActiveMode(FooterMode.FILTER_LIST) ? (
              <FilterOnIcon />
            ) : (
              <FilterOffIcon />
            )}
            {t('screen.imagefilter.filter')}
          </button>
          <button
            className={clsx(
              classes.btn_mode,
              isActiveMode(FooterMode.ADJUST_LIST) && classes.active_mode
            )}
            onClick={() => setFooterMode(FooterMode.ADJUST_LIST)}
          >
            {isActiveMode(FooterMode.ADJUST_LIST) ? (
              <AdjustOnIcon />
            ) : (
              <AdjustOffIcon />
            )}
            {t('screen.imagefilter.adjust')}
          </button>
          {/* <button onClick={clearFilter}>초기화(테스트용)</button> */}
        </div>
      </div>
    )
  }

  const renderLoading = () => {
    return <CustomActivityIndicator isDarkSpinner={true} />
  }

  return (
    <div className={classes.wrap}>
      <TitleHeader
        title={t('screen.imagefilter.title')}
        rightItem={HeaderItem.SAVE_OFF}
        buttonText={t('screen.imagefilter.done')}
        onClickSaveInactivated={() => {
          track('complete_imagefilter_button', {})

          setLoading(true)
          void resultImageData()
            .then(onFilteredImage)
            .finally(() => {
              setLoading(false)
              goBack()
            })
        }}
      />

      {renderImageStage()}
      {tempAdjustItem && renderAdjustPanel()}
      {!tempAdjustItem && renderFooter()}
      {loading && renderLoading()}
    </div>
  )
})

export default FilterTypeScreen
