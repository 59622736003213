import React, { useEffect } from "react";
import { QnaWrite } from "./presenter";
import { useTranslation } from "react-i18next";
import { Colors } from "../../assets/styles";

import { observer } from "mobx-react";
import QnaWriteStore from "../../store/QnaWriteStore";
import { FeedMode } from "../../constants/Feed";
import QnaStore from "../../store/QnaStore";
import { goBack } from "../../hooks/navigate/NavigateFunction";
import { useLocation, useNavigationType } from "react-router-dom";

const QnaModifyScreen = observer((data: any) => {
  const { t } = useTranslation();
  const { successRegister } = QnaWriteStore;
  const params = useLocation().state;
  const feedId = params.feedId;
  const { wholeData, clear, getDetailInfo } = QnaWriteStore;
  const navigationType = useNavigationType();

  useEffect(() => {
    // 다른 화면에서 진입했을 때만 초기화 후 데이터 불러옴
    if (navigationType !== "POP" || wholeData.title === "") {
      clear();
      void getDetailInfo(feedId);
    }
  }, []);

  useEffect(() => {
    const backWithInit = () => {
      QnaWriteStore.successInit();
      goBack();
    };

    if (successRegister) {
      backWithInit();
    }
  }, [successRegister, data]);

  return (
    <div
      style={{
        flex: 1,
        ...Colors.background_fff,
      }}
      aria-label="Qna Modify screen"
    >
      <QnaWrite mode={FeedMode.MODIFY} />
    </div>
  );
});

export default QnaModifyScreen;
