import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { QaInfo } from '../api/setting/qa/model'
import { NoticeInfo, WithdrawalReason } from '../api/setting/notice/model'
import { getQas, getDetail, deleteQaDetail } from '../api/setting/qa/api'
import { getNotices, getNoticeInfo } from '../api/setting/notice/api'
import { getCommonCode } from '../api/commonCode/api'
import { CommonCode } from '../api/commonCode/model'
import { getFilePreSignedUrl } from '../api/file/api'
import { FileUrlRequest } from '../api/file/model'
import { UploadFile } from '../api/file/model'
import { logout, updateMemberPromotionAll } from '../api/member/api'
import { UserInfo } from '../api/member/model'
import {
  getSessionUserFromStorage,
  setSessionUserInStorage,
} from '../service/Session.service'
import AuthStore from './AuthStore'
import { UserInformationUpdate } from '../api/member/model'
import { getUserUpdateDataFromDB, updateMember } from '../api/member/api'
import CommonResponse from '../api/http'
import { WithdrawTypeCode } from '../constants/Withdrawal.enum'
import { createInquiry } from '../api/setting/api'
import { AddQaRequest } from '../api/setting/model'
import { CommonGroupCode } from '../constants/CommonCode.enum'
import { QATypeCode } from '../constants/Setting'
import {
  AlbumResponse,
  callNativeSelectOnePicture,
  ImageForUI,
} from '../utils/album'
import CommonStore from './CommonStore'
// import { Buffer } from "buffer";
import { BucketTypeCode } from '../constants/File'
import { getAlarmDefaultSetting, modifyAlarm } from '../api/setting/alarm/api'
import { AlarmSettingList, ModifyAlarm } from '../api/setting/alarm/model'
import { NotificationParentTypeCode } from '../constants/Settings'
import { getDateFormatKo } from '../utils/datetime'
import { getTempMember, updateTempMember } from '../api/tempMember/api'
import { t } from 'i18next'
import { native } from '../native/native'

const EMPTY_STRING = ''
const BLANK_FILE = {
  originalFileName: EMPTY_STRING,
  fileName: EMPTY_STRING,
  filePath: EMPTY_STRING,
  thumbnailFilePath: EMPTY_STRING,
  fileSize: 0,
  fileType: EMPTY_STRING,
  fileExtension: EMPTY_STRING,
  thumbnailYn: EMPTY_STRING,
}

const INITIAL_VALUE = {
  _qaDetail: { qaId: 0 },
  _alarmSettingList: {
    pushAllowYn: '',
    smsAllowYn: '',
    emailAllowYn: '',
    memberLikeMyWriting: '',
    memberCommentMyWriting: '',
    memberMentionMe: '',
    newFollower: '',
    newWritingFromFollower: '',
    myMissionParticipation: '',
    myStatusChange: '',
    myInquiry: '',
    myVolt: '',
  },
}

class SettingStore {
  @observable _selectedReasons: Set<WithdrawTypeCode> = new Set()
  @observable _certificationId = ''
  @observable errorMessage?: string
  // @observable t: TFunction = Function;

  @observable _noticeList: NoticeInfo[] = []
  @observable _isNoticeLoadingMore = true
  @observable _isNoticeEndPage = false
  @observable _noticeDetail: NoticeInfo = {
    noticeId: 0,
    noticeTitle: '',
    noticeContent: '',
    viewCount: 0,
    showYn: '',
    createdDatetime: '',
  }
  @observable _noticeWebviewContent = ''
  @observable _qaList: QaInfo[] = []
  @observable _qaDetail: QaInfo = INITIAL_VALUE._qaDetail
  @observable _qaAnswerWebviewContent = ''
  @observable _isQaLoadingMore = true
  @observable _isQaEndPage = false
  @observable _isQaInit = false

  @observable inquiryComplete = false
  @observable inquiryType: CommonCode[] = []
  @observable inquiryWriteFile: UploadFile = BLANK_FILE
  @observable inquiryWriteFileName = EMPTY_STRING

  @observable _alarmList: AlarmSettingList = INITIAL_VALUE._alarmSettingList

  @observable _withdrawReasons: WithdrawalReason[] = []
  @observable _etcDesc = ''
  @observable _withdrawAgree = ''

  @observable _footerExpand = false

  constructor() {
    makeObservable(this)
  }

  // 1:1 문의 목록 조회
  @action getQaList = async (
    pageSize: number,
    pageIndex: number,
    qaStatusCode: string
  ) => {
    const res = await getQas(pageSize, pageIndex, qaStatusCode)
    if (res.successOrNot === 'Y') {
      const qaList: QaInfo[] = res.data.list
      this.setQaList(qaList)

      if (qaList.length === 0) {
        this.setIsQaEndPage(true)
      }
    }
  }

  // 1:1 문의 상세 조회
  @action getQaDetail = (qaId: number) => {
    const header = `<!DOCTYPE html>
    <head>
    <meta charset="UTF-8" >
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <style>
    </style>
    </head>
    <body style="height:auto; background-color:#fafafa;"><div id="context" style="display:flex; flex:1; flex-direction:column; height:100%;word-break:break-all;">`
    const end = `</div></body></html>`
    getDetail(qaId)
      .then((res) => {
        if (res.successOrNot === 'Y') {
          const qaDetail: QaInfo = res.data
          const answer = qaDetail.qaAnswer
          this.setQaAnswerWebviewContent(
            `${header}${answer?.replace(/\r\n|\n|\r/gm, '<br>') || ''}${end}`
          )
          this.setQaDetail(qaDetail)
        }
      })
      .catch(() => {
        this.setError(t('common.error.server'))
      })
  }

  // 1:1 문의하기 취소/삭제
  @action postRemoveQaDetail = (
    qaId: number,
    successAction: () => void,
    failAction: () => void
  ) => {
    deleteQaDetail(qaId)
      .then((res) => {
        if (res) {
          CommonStore.setToastOption({
            show: true,
            message1: t('screen.setting.inquiryWrite.delete'),
            // bottomOffset: 30,
          })
          successAction()
        } else {
          failAction()
        }
      })
      .catch(() => {
        failAction()
      })
  }

  @action getMoreQaList = async (
    pageSize: number,
    pageIndex: number,
    qaStatusCode: string
  ) => {
    const res = await getQas(pageSize, pageIndex, qaStatusCode)
    const formerQaLength = this._qaList.length

    if (res.successOrNot === 'Y') {
      const newQaLists: QaInfo[] = res.data.list
      this._qaList.push(...newQaLists)
      if (newQaLists.length > 0) {
        this.setIsQaLoadingMore(true)
      } else {
        this.setIsQaEndPage(true)
      }
    } else {
      // setAlertMessage(t("common.message.networkError"));
    }
  }

  @action clearQaList = () => {
    this.errorMessage = ''
    this.setQaList([])
    this.setIsQaLoadingMore(true)
    this.setIsQaEndPage(false)
  }

  @action clearQaDetail = () => {
    this.errorMessage = ''
    this.setQaDetail(INITIAL_VALUE._qaDetail)
    this.setQaAnswerWebviewContent('')
  }

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    )
  }

  @computed get isUploadFile() {
    return this.inquiryWriteFile.filePath.length > 0
  }

  @action setError = (message?: string) => {
    this.errorMessage = message
  }

  @action clearInquiryWrite = () => {
    this.errorMessage = EMPTY_STRING
    this.inquiryComplete = false
    this.inquiryType = []
    this.inquiryWriteFile = BLANK_FILE
    this.inquiryWriteFileName = EMPTY_STRING
  }

  @action getCommonCode = async (groupCode: string) => {
    const response = await getCommonCode(groupCode)
    if (response && groupCode === CommonGroupCode.QA_TYPE_CODE) {
      this.setInquiryType(response)
    }
  }

  @action setInquiryType = (inquiryType: CommonCode[]) => {
    this.inquiryType = inquiryType
  }

  @action setInquiryWriteFile = (file: ImageForUI) => {
    this.inquiryWriteFile = {
      originalFileName: file.fileName.split('.')[0],
      fileName: file.fileName.split('.')[0],
      filePath: file.base64,
      thumbnailFilePath: file.fileUri,
      fileSize: Number(file.fileSize),
      fileType: file?.type?.toLocaleUpperCase() || 'IMAGE',
      fileExtension: file.fileName.split('.')[1],
      thumbnailYn: 'N',
    }
  }
  @action setInquiryComplete = (isComplete: boolean) => {
    this.inquiryComplete = isComplete
  }

  @action setInquiryWriteFileName = (fileName: string) => {
    this.inquiryWriteFileName = fileName
  }

  @action deleteInquiryWriteFile = () => {
    this.inquiryWriteFile = BLANK_FILE
    this.inquiryWriteFileName = EMPTY_STRING
  }

  @action showNativeAlbum = async () => {
    // const res = (await callNativeSelectOnePicture("SETTING")) as AlbumResponse;
    // const file: ImageForUI = res.param.image;
    // this.setInquiryWriteFileName(file.fileName);
    // this.setInquiryWriteFile(file);
  }

  @action inquirySubmit = async (type: string, content: string) => {
    if (this.isUploadFile) {
      const fileName =
        this.inquiryWriteFile.fileName +
        '.' +
        this.inquiryWriteFile.fileExtension
      const urlRequest: FileUrlRequest = {
        bucketTypeCode: BucketTypeCode.PUBLIC,
        uploadTypeCode: QATypeCode.QA,
        fileNamesWithExtension: [fileName],
        generateThumbnailYN: 'N',
      }

      const res = await getFilePreSignedUrl(urlRequest)
      runInAction(() => {
        // if (res != null && res.length == 1) {
        //   this.uploadFile(res[0].presignedUrl, res[0].filePath)
        //     .then((res) => {
        //       if (res?.ok) {
        //         void this.saveInquiry(type, content);
        //       } else {
        //         this.setError(t("screen.setting.inquiryWrite.fileUploadFail"));
        //       }
        //     })
        //     .catch((reason) => {
        //       this.setError(t("common.error.server"));
        //     });
        // } else {
        //   this.setError(t("common.error.server"));
        // }
      })
    } else {
      void this.saveInquiry(type, content)
    }
  }

  @action uploadFile = async (presignedUrl: string, filePath: string) => {
    // const contentType = `${this.inquiryWriteFile.fileType.toLocaleLowerCase()}/${
    //   this.inquiryWriteFile.fileExtension
    // }`;
    // const buffer = Buffer.from(this.inquiryWriteFile.filePath, "base64");
    // this.inquiryWriteFile.filePath = filePath;
    // this.inquiryWriteFile.thumbnailFilePath = EMPTY_STRING;
    // return fetch(presignedUrl, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": `${contentType}; charset=utf-8`,
    //   },
    //   body: buffer,
    // });
  }

  @action saveInquiry = async (type: string, content: string) => {
    const addQaRequest: AddQaRequest = {
      qaTypeCode: type,
      qaContent: content,
      file: this.isUploadFile ? this.inquiryWriteFile : undefined,
    }

    const res = await createInquiry(addQaRequest)
    if (res) {
      if (res.successOrNot === 'Y' && res.statusCode === 'OK') {
        this.setInquiryComplete(true)
      } else {
        this.setError(t('common.error.server'))
      }
    }
  }

  // 공지사항 목록 조회
  @action getNoticeList = async (pageSize: number, pageIndex: number) => {
    await getNotices(pageSize, pageIndex)
      .then((res) => {
        if (res.successOrNot === 'Y') {
          const noticeList: NoticeInfo[] = res.data.list
          this.setNoticeList(noticeList)
        }
      })
      .catch(() => {
        this.setError(t('common.error.server'))
      })
  }

  @action getMoreNoticeList = async (pageSize: number, pageIndex: number) => {
    const res = await getNotices(pageSize, pageIndex)
    const formerNoticeLength = this._noticeList.length

    if (res.successOrNot === 'Y') {
      const newNoticeLists: NoticeInfo[] = res.data.list
      this._noticeList.push(...newNoticeLists)
      if (newNoticeLists.length > 0) {
        this.setIsNoticeLoadingMore(true)
      } else {
        this.setIsNoticeEndPage(true)
      }
    } else {
      this.setError(t('common.error.server'))
    }
  }

  @action getNoticeDetail = async (id: number | string) => {
    const header = `<!DOCTYPE html>
    <head>
    <script>
    </script>
      <meta charset="UTF-8" >
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
      </style>
    </head>
    <body style=""><div id="context">`
    const end = `</div></body></html>`
    await getNoticeInfo(id)
      .then((res) => {
        if (res.successOrNot === 'Y') {
          const data: NoticeInfo = res.data
          this.setNoticeWebviewContent(
            `${header} ${data.noticeContent}  ${end}`
          )
          this.setNoticeDetail(data)
        }
      })
      .catch(() => {
        this.setError(t('common.error.server'))
      })
  }

  @action getAlarmSetting = async () => {
    await getAlarmDefaultSetting()
      .then((res) => {
        if (res != null) {
          const alarmList: AlarmSettingList = res
          this.setAlarmList(alarmList)
        }
      })
      .catch(() => {
        this.setError(t('common.error.server'))
      })
  }

  @action getTempMemberAlarmSetting = async () => {
    await getTempMember(AuthStore.tempMember?.tempMemberUUID || '')
      .then((res) => {
        if (res) {
          this.setAlarmList({
            ...this.alarmList,
            pushAllowYn: res.pushAllowYn || 'N',
          })
        }
      })
      .catch(() => {
        this.setError(t('common.error.server'))
      })
  }

  @action updateAlarmSetting = async (alarmCode: string, ynValue: string) => {
    const onUpdateOpenToast = () => {
      const date = getDateFormatKo()
      const notificationType = t(
        `screen.setting.label.updateAlramToast.${alarmCode}`
      )
      const isAgree = t(`screen.setting.label.updateAlramToast.${ynValue}`)

      CommonStore.setToastOption({
        show: true,
        message1: `${t('screen.setting.label.updateAlramToast.message1', {
          date,
        })}`,
        message2:
          alarmCode === NotificationParentTypeCode.PROMOTION_ALL
            ? `${t('screen.setting.label.updateAlramToast.message3', {
                isAgree,
              })}`
            : `${t('screen.setting.label.updateAlramToast.message2', {
                notificationType,
                isAgree,
              })}`,
      })
    }

    if (AuthStore.sessionUser?.memberUuid) {
      switch (alarmCode) {
        case NotificationParentTypeCode.PROMOTION_ALL:
          await updateMemberPromotionAll(ynValue)
          onUpdateOpenToast()
          break
        case NotificationParentTypeCode.EMAIL:
          await updateMember({ emailAllowYn: ynValue })
          onUpdateOpenToast()
          break
        case NotificationParentTypeCode.SMS:
          await updateMember({ smsAllowYn: ynValue })
          onUpdateOpenToast()
          break
        case NotificationParentTypeCode.PUSH:
          await updateMember({ pushAllowYn: ynValue })
          onUpdateOpenToast()
          break
        case NotificationParentTypeCode.MEMBER_LIKE_MY_WRITING:
        case NotificationParentTypeCode.MEMBER_COMMENT_MY_WRITING:
        case NotificationParentTypeCode.MEMBER_MENTION_ME:
        case NotificationParentTypeCode.NEW_FOLLOWER:
        case NotificationParentTypeCode.NEW_WRITING_FROM_FOLLOWER:
        case NotificationParentTypeCode.MY_MISSION_PARTICIPATION:
        case NotificationParentTypeCode.MY_STATUS_CHANGE:
        case NotificationParentTypeCode.MY_INQUIRY:
        case NotificationParentTypeCode.MY_VOLT:
          await modifyAlarm({
            notificationAllowYn: ynValue,
            notificationAllowCode: alarmCode,
          } as ModifyAlarm)
          break
        default:
          break
      }
      void this.getAlarmSetting()
    } else {
      const token = await native.getPushToken()
      const request = {
        tempMemberUuid: AuthStore.tempMember?.tempMemberUUID,
        tempMemberId: AuthStore.tempMember?.tempMemberId,
        pushAllowYn: ynValue,
        devicePushToken: token,
      }
      await updateTempMember(request)
      onUpdateOpenToast()
      void this.getTempMemberAlarmSetting()
    }
  }

  @action clearAlarmSetting = () => {
    this.errorMessage = ''
    this.setAlarmList(INITIAL_VALUE._alarmSettingList)
  }

  @action setNoticeList = (noticeList: NoticeInfo[]) => {
    this._noticeList = noticeList
  }

  @action setIsNoticeLoadingMore = (loading: boolean) => {
    this._isNoticeLoadingMore = loading
  }

  @action setIsNoticeEndPage = (isEndPage: boolean) => {
    this._isNoticeEndPage = isEndPage
  }

  @action setNoticeDetail = (noticeDetail: NoticeInfo) => {
    this._noticeDetail = noticeDetail
  }

  @action setNoticeWebviewContent = (noticeWebviewContent: string) => {
    this._noticeWebviewContent = noticeWebviewContent
  }

  @action setQaList = (qaList: QaInfo[]) => {
    this._qaList = qaList
  }

  @action setQaDetail = (qaDetail: QaInfo) => {
    this._qaDetail = qaDetail
  }

  @action setQaAnswerWebviewContent = (qaAnswerWebviewContent: string) => {
    this._qaAnswerWebviewContent = qaAnswerWebviewContent
  }

  @action setIsQaLoadingMore = (loading: boolean) => {
    this._isQaLoadingMore = loading
  }

  @action setIsQaEndPage = (isEndPage: boolean) => {
    this._isQaEndPage = isEndPage
  }

  @action setIsQaInit = (isQaInit: boolean) => {
    this._isQaInit = isQaInit
  }

  @action setAlarmList = (alarmItems: AlarmSettingList) => {
    this._alarmList = alarmItems
  }

  @action clearNoticeList = () => {
    this.errorMessage = ''
    this._isNoticeLoadingMore = true
    this._isNoticeEndPage = false
    this._noticeList = []
  }

  @action clearNoticeDetail = () => {
    this.errorMessage = ''
    this._noticeWebviewContent = ''
    this._noticeDetail = {
      noticeId: 0,
      noticeTitle: '',
      noticeContent: '',
      viewCount: 0,
      showYn: '',
      createdDatetime: '',
    }
  }
  @computed get qaList() {
    return this._qaList
  }

  @computed get isQaLoadingMore() {
    return this._isQaLoadingMore
  }

  @computed get isQaEndPage() {
    return this._isQaEndPage
  }

  @computed get isQaInit() {
    return this._isQaInit
  }

  @computed get qaDetail() {
    return this._qaDetail
  }

  @computed get qaAnswerWebviewContent() {
    return this._qaAnswerWebviewContent
  }

  @computed get noticeList() {
    return this._noticeList
  }

  @computed get noticeDetail() {
    return this._noticeDetail
  }

  @computed get noticeWebviewContent() {
    return this._noticeWebviewContent
  }

  @computed get isNoticeLoadingMore() {
    return this._isNoticeLoadingMore
  }

  @computed get isNoticeEndPage() {
    return this._isNoticeEndPage
  }

  @action logout = async () => {
    const sessionUser = (await getSessionUserFromStorage()) as UserInfo

    if (sessionUser?.sessionId && sessionUser?.idToken) {
      void (await logout(sessionUser.idToken, sessionUser.sessionId))
      await setSessionUserInStorage({})

      // if (Platform.OS === "ios") {
      //   PushNotificationIOS.removeAllDeliveredNotifications();
      // } else {
      //   PushNotification.removeAllDeliveredNotifications();
      // }
    }
  }

  @computed get selectedReasons() {
    return this._selectedReasons
  }

  @action setSelectedReasons = (reasons: Set<WithdrawTypeCode>) => {
    this._selectedReasons = reasons
  }

  @computed get certificationId() {
    return this._certificationId
  }

  @computed get alarmList() {
    return this._alarmList
  }

  @action getAccountInfo = async () => {
    if (!AuthStore.sessionUser?.memberUuid) return
    const result: CommonResponse = await getUserUpdateDataFromDB(
      AuthStore.sessionUser?.memberUuid
    )
    const data: UserInformationUpdate = {
      memberName: result.data?.memberName,
      emailAddress: result.data?.emailAddress,
      phoneNumber: result.data?.phoneNumber,
      socialPlatformCode: result.data?.socialPlatformCode,
      phoneNumberConfirmDatetime: result.data?.phoneNumberConfirmDatetime,
    }
    return data
  }
  @action toggleReason = (reason: WithdrawTypeCode) => {
    if (this.selectedReasons.has(reason)) {
      this._selectedReasons.delete(reason)
      return
    }
    this._selectedReasons.add(reason)
  }

  @action clearReasons = () => {
    this.selectedReasons.clear()
  }

  @action changePhoneNumber = async (phoneNumber: string) => {
    return await updateMember({ phoneNumber })
  }

  @computed get withdrawReasons() {
    return this._withdrawReasons
  }

  @action setWithdrawReasons = (reasons: WithdrawalReason[]) => {
    this._withdrawReasons = reasons
  }

  @computed get etcDesc() {
    return this._etcDesc
  }

  @computed get withdrawAgree() {
    return this._withdrawAgree
  }

  @action setEtcDesc = (desc: string) => {
    this._etcDesc = desc
  }

  @action setWithdrawAgree = (agree: string) => {
    this._withdrawAgree = agree
  }

  @action clearWithdrawReason = () => {
    this.setWithdrawReasons([])
    this.setSelectedReasons(new Set())
    this.setEtcDesc('')
    this.setWithdrawAgree('')
  }

  @computed get footerExpand() {
    return this._footerExpand
  }

  @action setFooterExpand = (expand: boolean) => {
    this._footerExpand = expand
  }

  @action settingClear = () => {
    this._footerExpand = false
  }
}

export default new SettingStore()
