import React, { useEffect } from "react";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/close_gray.svg";
import { observer } from "mobx-react";
import SearchStore from "../../../store/SearchStore";
import { getDatetimeStr } from "../../../utils/datetime";
import CommonStore from "../../../store/CommonStore";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
  ActHistoryTargetId,
} from "../../../constants/ActHistory";
import AuthStore from "../../../store/AuthStore";
import TodayProduct from "../../main/presenter/TodayProduct";
import MainStore from "../../../store/MainStore";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import { ReactComponent as BtnMore } from "../../../assets/icons/icon_more_arrow.svg";
import PostStore from "../../../store/PostStore";
import { FilterGroupName } from "../../../constants/post/Filter";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/search_category.svg";
import { ReactComponent as LifeStyleIcon } from "../../../assets/icons/search_lifestyle.svg";
import { ReactComponent as SpaceIcon } from "../../../assets/icons/search_space.svg";
import { ReactComponent as ThemeIcon } from "../../../assets/icons/search_theme.svg";
import { ReactComponent as SearchRankingUp } from "../../../assets/icons/search_ranking_up.svg";
import { ReactComponent as SearchRankingDown } from "../../../assets/icons/search_ranking_down.svg";
import { RankingChangeCode } from "../../../constants/Search";
import { useTranslation } from "react-i18next";
import "./Search.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import Slider, { Settings } from "react-slick";
import { useWindowScroll } from "react-use";
import { ActHistoryRequest } from "../../../api/data/model";
import { sendActHistory } from "../../../api/data/api";

export interface SearchProps {
  onSearch: (text: string, type?: string) => void;
}

const Search = observer(({ onSearch }: SearchProps) => {
  const {
    latestSearchTextList,
    suggestionSearchResult,
    rankingSearchResult,
    setSearchText,
    setSearchFocus,
    removeSearchTextFromStorage,
    removeAllSearchTextFromStorage,
  } = SearchStore;

  const { getTodayMallProduct, todayProductList } = MainStore;

  const { t } = useTranslation();

  const { sessionUser, tempMember } = AuthStore;
  const nickname = sessionUser?.nickname
    ? sessionUser?.nickname
    : tempMember?.nickname;

  const onPressSimilar = (filterType: string) => {
    goTo("/posts");

    PostStore.postFilterReset();
    PostStore.setPostFilterInfo(true, filterType);
  };

  const { y } = useWindowScroll();

  useEffect(() => {
    setTimeout(() => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
        actPageCode: ActPageCode.SEARCH,
        actSourcePageCode: CommonStore.fixedPreviousRouteName,
        attr1: (y === 0).toString(),
      };
      void sendActHistory(actHistory);
    }, 1000);
  }, []);

  useEffectOnlyOnce(() => {
    void getTodayMallProduct();
  });

  const renderLastestSearch = () => {
    if (latestSearchTextList && latestSearchTextList.text.length > 0) {
      return (
        <div className="latest_search">
          <div className="latest_search_header">
            <div className="title">
              {t("screen.search.label.lastestSearch")}
            </div>
            <div
              className="delete_all_btn_text"
              onClick={() => void removeAllSearchTextFromStorage()}
            >
              {t("screen.search.label.deleteAll")}
            </div>
          </div>

          <div className="latest_search_list">
            {latestSearchTextList?.text
              .slice()
              .reverse()
              .map((item, index) => (
                <div className="latest_search_item" key={index}>
                  <div
                    className="delete_icon"
                    onClick={() => void removeSearchTextFromStorage(item)}
                  >
                    <DeleteIcon />
                  </div>
                  <div
                    onClick={() => {
                      onSearch(item);
                    }}
                  >
                    <div className="latest_search_text">{item}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      );
    }
  };

  const renderRecommendSearch = () => {
    const settings: Settings = {
      arrows: false,
      infinite: false,
      dots: false,
      slidesToScroll: 1,
      slidesToShow: 2,
      variableWidth: true,
    };
    return (
      <div className="key_word_wrap">
        <div className="title_recommend">
          {t("screen.search.label.recommendSearch")}
        </div>
        <div className="recommend_nickname_wrap">
          {t("screen.search.label.recommendSearchNickname", {
            nickname: nickname || "GUEST",
          })}
        </div>
        <div className="recommend_tag_wrap">
          <div className="recommend_tag_list_wrap">
            <Slider {...settings}>
              {suggestionSearchResult?.list?.map((item, index) => (
                <div
                  className="recommend_tag_item"
                  key={index}
                  onClick={() => {
                    CommonStore.callActHistory(
                      ActHistoryTypeCode.SEARCH_KEYWORD_CLICK,
                      `${item}`,
                      ActPageCode.SEARCH,
                      ActSectionCode.RECOMMEND_TAG
                    );
                    onSearch(item);
                  }}
                >
                  <div className="recommend_tag_item_text">{item}</div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  };

  const renderRankingSearch = () => {
    const rankColumnCount =
      (rankingSearchResult && rankingSearchResult?.totalCount / 2) || 5;
    return (
      <>
        <div className="ranking_keyword_wrap">
          <div className="title">
            {t("screen.search.label.popularSearchRank")}
          </div>
          <div className="ranking_date">
            {getDatetimeStr(rankingSearchResult?.createdDatetime)}{" "}
            {t("screen.search.label.standard")}
          </div>
        </div>
        <div className="ranking_box">
          <div className="first_ranking">
            {rankingSearchResult?.list
              .slice(0, rankColumnCount)
              .map((item, index) => (
                <div
                  className="rank"
                  key={index}
                  onClick={() => {
                    CommonStore.callActHistory(
                      ActHistoryTypeCode.SEARCH_KEYWORD_CLICK,
                      `${item.keyword}`,
                      ActPageCode.SEARCH,
                      ActSectionCode.POPULAR_TAG
                    );
                    onSearch(item.keyword);
                  }}
                >
                  <div className="rank_keyword_wrap">
                    <div className="rank_number">{item.ranking}</div>
                    <div className="rank_txt">{item.keyword}</div>
                  </div>
                  <div className="rank_code">
                    <div className="rank_code_size">
                      {item.rankingChangeCode === RankingChangeCode.UP && (
                        <div className="rank_code_up_down">
                          <SearchRankingUp />
                        </div>
                      )}
                      {item.rankingChangeCode === RankingChangeCode.DOWN && (
                        <div className="rank_code_up_down">
                          <SearchRankingDown />
                        </div>
                      )}
                      {item.rankingChangeCode === RankingChangeCode.STAY && (
                        <div className="rank_code_stay" />
                      )}
                      {item.rankingChangeCode === RankingChangeCode.NEW && (
                        <div className="rank_code_new">{"new"}</div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <div className="second_ranking">
            {rankingSearchResult?.list
              .slice(rankColumnCount, rankingSearchResult.totalCount)
              .map((item, index) => (
                <div
                  className="rank"
                  key={index}
                  onClick={() => onSearch(item.keyword)}
                >
                  <div className="rank_keyword_wrap">
                    <div className="rank_number">{item.ranking}</div>
                    <div className="rank_txt">{item.keyword}</div>
                  </div>
                  <div className="rank_code">
                    <div className="rank_code_size">
                      {item.rankingChangeCode === RankingChangeCode.UP && (
                        <div className="rank_code_up_down">
                          <SearchRankingUp />
                        </div>
                      )}
                      {item.rankingChangeCode === RankingChangeCode.DOWN && (
                        <div className="rank_code_up_down">
                          <SearchRankingDown />
                        </div>
                      )}
                      {item.rankingChangeCode === RankingChangeCode.STAY && (
                        <div className="rank_code_stay" />
                      )}
                      {item.rankingChangeCode === RankingChangeCode.NEW && (
                        <div className="rank_code_new">{"new"}</div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </>
    );
  };

  const renderSimilarPost = () => {
    return (
      <>
        <div className="similar_wrap">
          <div className="title_recommend">
            {t("screen.search.label.similarSearchTitle")}
          </div>
        </div>
        <div className="similar_tag_wrap">
          <div className="similar_tag_list_wrap">
            <div
              className="similar_border_item"
              onClick={() => {
                onPressSimilar(FilterGroupName.LIFE_STYLE);
              }}
            >
              <div className="similar_border_inner_wrap">
                <LifeStyleIcon />
                <div className="similar_tag_item_text_wrap">
                  <div className="similar_tag_item_text">
                    {t("screen.search.label.similarSearchItem1")}
                  </div>
                </div>
                <BtnMore />
              </div>
            </div>
            <div
              className="similar_border_item"
              onClick={() => {
                onPressSimilar(FilterGroupName.SPACE);
              }}
            >
              <div className="similar_border_inner_wrap">
                <SpaceIcon />
                <div className="similar_tag_item_text_wrap">
                  <div className="similar_tag_item_text">
                    {t("screen.search.label.similarSearchItem2")}
                  </div>
                </div>
                <BtnMore />
              </div>
            </div>
          </div>
          <div className="similar_tag_list_wrap">
            <div
              className="similar_border_item"
              onClick={() => {
                onPressSimilar(FilterGroupName.CATEGORY1);
              }}
            >
              <div className="similar_border_inner_wrap">
                <CategoryIcon />
                <div className="similar_tag_item_text_wrap">
                  <div className="similar_tag_item_text">
                    {t("screen.search.label.similarSearchItem3")}
                  </div>
                </div>
                <BtnMore />
              </div>
            </div>
            <div
              className="similar_border_item"
              onClick={() => {
                onPressSimilar(FilterGroupName.THEME);
              }}
            >
              <div className="similar_border_inner_wrap">
                <ThemeIcon />
                <div className="similar_tag_item_text_wrap">
                  <div className="similar_tag_item_text">
                    {t("screen.search.label.similarSearchItem4")}
                  </div>
                </div>
                <BtnMore />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      id="Search"
      // onScrollBeginDrag={() => {
      //   Keyboard.dismiss();
      // }}
    >
      {renderLastestSearch()}
      {renderRecommendSearch()}
      {renderRankingSearch()}
      <TodayProduct renderData={todayProductList?.data} searchTitle={true} />
      {renderSimilarPost()}
    </div>
  );
});
export default Search;
