import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ReactComponent as IconVoltSumm } from "../../assets/icons/icon_summ_volt.svg";
import { ReactComponent as IconVoltDis } from "../../assets/icons/icon_dis_volt.svg";
import { ReactComponent as IconInfo } from "../../assets/icons/icon_info_circle_sm.svg";
import { ReactComponent as ArrowTooltip } from "../../assets/images/tooltip_arrow_bottom.svg";
import { ReactComponent as IconClose } from "../../assets/icons/ic_tooltip_close.svg";
import { ReactComponent as BtnOpen } from "../../assets/icons/btn_open_arrow.svg";
import dayjs from "dayjs";
import VoltStore from "../../store/VoltStore";
import { useLocation } from "react-router-dom";
import { EventTypeCode } from "../../constants/Volt.enum";
import { TitleHeader } from "../../components/common";
import FlatListWithStickyTabs from "../../components/common/FlatListWithStickyTabs";
import { useTranslation } from "react-i18next";
import { formatVoltAmount } from "../../utils/number";
import { Virtuoso } from "react-virtuoso";
import { UserVoltHistory } from "../../api/volt/model";
import BenefitPop from "./presenter/BenefitPop";
import { useTracker } from "../../hooks/tracker";
import AuthStore from "../../store/AuthStore";
import "./VoltInfoScreen.scss";
import clsx from "clsx";
// import { useTracker } from "../../hooks/tracker";

const VoltInfoScreen = observer(() => {
  const { state } = useLocation();
  const { t } = useTranslation();

  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const ASSET_IMAGE_VOLT = "/ASSETS/bg_banner_reward.png";
  const popupOpen: boolean = state?.popupOpen || false;

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [toggle, setToggle] = useState<boolean>(false);
  const [infoModal, setInfoModal] = useState<boolean>(popupOpen ?? false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tracker] = useState(useTracker());

  const tabData = [
    { tabName: t("screen.volt.label.total") },
    { tabName: t("screen.volt.label.accumulate") },
    { tabName: t("screen.volt.label.use") },
    { tabName: t("screen.volt.label.dueToExpire") },
  ];

  useEffect(() => {
    tracker.track("view_my_volt");
    VoltStore.clear();
  }, [tracker]);

  // useEffect(() => {
  //   !isLoading &&
  //     VoltStore.userVoltHistory.length == 0 &&
  //     void getInitVoltHistory(currentTab);
  // }, [isLoading]);

  useEffect(() => {
    void getInitVoltHistory(currentTab);
  }, [AuthStore.sessionUser?.memberUuid]);

  const changeTab = (tabIndex: number) => {
    setCurrentTab(tabIndex);
    void getInitVoltHistory(tabIndex);
  };

  const getInitVoltHistory = useCallback(
    async (tabindex: number) => {
      setIsLoading(true);
      switch (tabindex) {
        case 0:
          await VoltStore.getUserVoltHistory();
          break;
        case 1:
          await VoltStore.getUserVoltHistory(EventTypeCode.ACCUM);
          break;
        case 2:
          await VoltStore.getUserVoltHistory(EventTypeCode.USE);
          break;
        case 3:
          await VoltStore.getUserVoltInfo();
          break;
      }

      setIsLoading(false);
    },
    [VoltStore.getUserVoltHistory]
  );

  const getMoreHistory = () => {
    if (!isLoading) {
      switch (currentTab) {
        case 0:
          void VoltStore.getMoreUserVoltHistory();
          break;
        case 1:
          void VoltStore.getMoreUserVoltHistory(EventTypeCode.ACCUM);
          break;
        case 2:
          void VoltStore.getMoreUserVoltHistory(EventTypeCode.USE);
          break;
        case 3:
          void VoltStore.getUserVoltInfo();
          break;
      }
    }
  };

  const toggleModal = () => {
    if (infoModal === undefined) {
      setInfoModal(false);
    } else {
      setInfoModal(!infoModal);
    }
  };

  const renderVoltInfo = () => {
    return (
      <div className="bx_summary">
        <div className="bx_total">
          <div className="in_txt_box">
            <IconVoltSumm />
            <div className="txt_summ_label">
              {t("screen.volt.label.totalVolt")}{" "}
            </div>
          </div>
          <div className="txt_summ_value">
            {formatVoltAmount(VoltStore.userVoltInfo?.voltAmount)}
          </div>
        </div>
        <div className="bx_total bx_total_2">
          <div className="in_txt_box">
            <IconVoltDis />
            <div className="txt_dis_label">
              {t("screen.volt.label.dueToExpireVolt")}{" "}
            </div>
            <div className="bx_info_wrap">
              <div onClick={() => setToggle(!toggle)}>
                <IconInfo />
              </div>
              <div className={`bx_tooltip_wrap ${toggle ? "show" : "hide"}`}>
                <div className="img_close" onClick={() => setToggle(false)}>
                  <IconClose />
                </div>
                <div className="txt_tooltip">
                  {t("screen.volt.label.expireTooltip")}
                </div>
                <ArrowTooltip className="img_arrow" />
              </div>
            </div>
          </div>
          <div className="txt_dis_value">
            {VoltStore.userVoltInfo?.voltScheduledExpiryAmount || 0}
          </div>
        </div>
      </div>
    );
  };

  const renderVoltPolicyBanner = () => {
    return (
      <div
        className="banner-info"
        onClick={() => {
          toggleModal();
        }}
      >
        <div className="bx_banner_info">
          <div className="bx_div">
            <div className="txt_info">
              {t("screen.volt.label.voltInfoMessage")}
            </div>
            <div className="bx_link_open">
              <div className="txt_link txt_info">
                {t("screen.volt.label.voltInfo")}
              </div>
              <BtnOpen />
            </div>
          </div>
          <div className="bx_img">
            <img
              className="img_style"
              src={`${PUBLIC_BUCKET_URL}${ASSET_IMAGE_VOLT}`}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderStickyTabs = () => {
    return (
      <div className="bx_tab_wrap">
        {tabData &&
          tabData.map((item: any, index: number) => {
            return (
              <div key={index} onClick={() => changeTab(index)}>
                <div
                  className={`tab btn_tab ${
                    index === currentTab ? "active btn_tab_active" : ""
                  }`}
                >
                  <div
                    className={`tab-text txt_tab ${
                      index === currentTab ? "active txt_tab_active" : ""
                    }`}
                  >
                    {item.tabName}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  };
  const renderVoltHistory = () => {
    return (
      <div className="bx_list_wrap">
        <div className="bx_list_info">
          <div className="li_list_wrap">
            <div className="li_dot"></div>
            <div className="txt_list_info">
              {t("screen.volt.label.periodMessage")}
            </div>
          </div>
        </div>
        {VoltStore.userVoltHistory && VoltStore.userVoltHistory.length > 0 && (
          // TODO 사용취소 적립취소 Case 적용시 참고
          <>
            <Virtuoso
              data={VoltStore.userVoltHistory}
              context={{ count: VoltStore.userVoltHistory.length }}
              components={{
                Item: forwardRef(
                  (
                    { style, children, ...props },
                    ref: React.LegacyRef<HTMLDivElement> | undefined
                  ) => (
                    <div
                      ref={ref}
                      {...props}
                      className={clsx(
                        props.context?.count === props["data-index"] + 1
                          ? "last"
                          : "",
                        "volt_history_item_wrap"
                      )}
                      style={{
                        ...style,
                        margin: 0,
                      }}
                    >
                      {children}
                    </div>
                  )
                ),
              }}
              itemContent={(index: number, item: UserVoltHistory) => (
                <div className="volt-history-item list_item" key={index}>
                  <div className="list_item_align">
                    <div className="txt_content">{item.policyCodeName}</div>
                    <div
                      className={`txt_value ${
                        item.eventTypeCode === EventTypeCode.USE ||
                        item.eventTypeCode === EventTypeCode.ACCUM_CANCEL
                          ? "txt_value_minus"
                          : ""
                      }`}
                    >
                      {item.eventTypeCode === EventTypeCode.ACCUM ||
                      item.eventTypeCode === EventTypeCode.USE_CANCEL
                        ? `+${item.voltAmount.toString()}`
                        : item.voltAmount}
                      <div className="txt_unit">
                        {" ".concat(t("screen.volt.label.volt"))}{" "}
                      </div>
                    </div>
                  </div>
                  <div className="list_item_align">
                    <div className="txt_date">
                      {item.eventOccuredDate.replace(/-/gi, ".")}
                    </div>
                    {item.eventTypeCode === EventTypeCode.ACCUM && (
                      <div className="txt_date_val">
                        {item.expiredDate &&
                          item.expiredDate !== "" &&
                          t("screen.volt.label.validDate")
                            .concat(" ~ ")
                            .concat(item.expiredDate.replace(/-/gi, "."))}
                      </div>
                    )}
                  </div>
                </div>
              )}
              useWindowScroll
              endReached={getMoreHistory}
            />
          </>
        )}
      </div>
    );
  };

  const renderDueToExpireVolt = () => {
    return (
      <div className="bx_list_wrap">
        <div className="bx_list_info">
          <div className="li_list_wrap">
            <div className="li_dot"></div>
            <div className="txt_list_info">
              {t("screen.volt.label.dueToExpireInfo1")}
            </div>
          </div>
          <div className="li_list_wrap">
            <div className="li_dot"></div>
            <div className="txt_list_info">
              {t("screen.volt.label.dueToExpireInfo2")}
            </div>
          </div>
        </div>
        <div className="list_item_expired">
          <div className="list_item_align">
            <div className="txt_content">
              {String(dayjs().format(t("screen.volt.label.dateFormatyyyym")))
                .concat(" ")
                .concat(t("screen.volt.label.dueToExpire"))}
            </div>
            <div className="txt_value_expired">
              {VoltStore.userVoltInfo?.voltScheduledExpiryAmount}
              <div className="txt_unit">
                {" ".concat(t("screen.volt.label.volt"))}
              </div>
            </div>
          </div>
        </div>
        <div className="list_item_expired">
          <div className="list_item_align">
            <div className="txt_content">
              {String(
                dayjs()
                  .add(1, "month")
                  .format(t("screen.volt.label.dateFormatyyyym"))
              )
                .concat(" ")
                .concat(t("screen.volt.label.dueToExpire"))}
            </div>
            <div className="txt_value_expired">
              {VoltStore.userVoltInfo?.voltSecondScheduledExpiryAmount}
              <div className="txt_unit">
                {" ".concat(t("screen.volt.label.volt"))}
              </div>
            </div>
          </div>
        </div>
        <div className="list_item_expired">
          <div className="list_item_align">
            <div className="txt_content">
              {String(
                dayjs()
                  .add(2, "month")
                  .format(t("screen.volt.label.dateFormatyyyym"))
              )
                .concat(" ")
                .concat(t("screen.volt.label.dueToExpire"))}
            </div>
            <div className="txt_value_expired">
              {VoltStore.userVoltInfo?.voltThirdScheduledExpiryAmount}
              <div className="txt_unit">
                {" ".concat(t("screen.volt.label.volt"))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div aria-label="Member Volt History" id="volt-info-screen">
      <TitleHeader
        title={t("screen.volt.label.myVoltHistory")}
        isHome={true}
        isBack={true}
      />
      {renderVoltInfo()}
      {renderVoltPolicyBanner()}
      {renderStickyTabs()}
      {currentTab == 3 ? renderDueToExpireVolt() : renderVoltHistory()}

      {/* <FlatListWithStickyTabs
        contentScrollRef={stickyTabsRef}
        scrollableHeader={
          <>
            {renderVoltInfo()}
            {renderVoltPolicyBanner()}
          </>
        }
        stickyItems={
          <div style={styles.bx_tab_wrap}>
            {tabData &&
              tabData.map((item: any, index: number) => {
                return (
                  <div key={index} onClick={() => changeTab(index)}>
                    <div
                      style={{
                        ...styles.btn_tab,
                        ...{ marginLeft: index === 0 ? 26 : 10 },
                        ...(index === currentTab ? styles.btn_tab_active : {}),
                      }}
                    >
                      <div
                        style={{
                          ...styles.txt_tab,
                          ...(index === currentTab
                            ? styles.txt_tab_active
                            : {}),
                        }}
                      >
                        {item.tabName}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        }
        listFooterComponent={
          currentTab == 3 ? renderDueToExpireVolt() : renderVoltHistory()
        }
        topButtonRight={16}
        showScrollToTop={true}
      /> */}
      <BenefitPop closeBtnClick={toggleModal} modalShow={infoModal} />
    </div>
  );
});

export default VoltInfoScreen;
