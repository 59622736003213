import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { observer } from 'mobx-react'
import { ReactComponent as BookmarkOff } from '../../../assets/icons/icon_bookmark_off.svg'
import { ReactComponent as BookmarkON } from '../../../assets/icons/icon_bookmark_on.svg'
import { ReactComponent as IconStarOff } from '../../../assets/icons/icon_star_off.svg'
import { ReactComponent as IconStarOn } from '../../../assets/icons/icon_star_on.svg'
import FastImageWithFallback from '../../../components/common/FastImageWithFallback'
import { getFormatprice } from '../../../utils/mall'
import { DisplayProduct } from '../../../api/mall/model'
import { useTranslation } from 'react-i18next'
import classes from './ProductItem.module.scss'
import CommonStore from '../../../store/CommonStore'
import clsx from 'clsx'

const ProductItem = observer(
  ({
    item,
    index,
    goToProduct,
    handleBookmark,
    listKey,
    horizontal,
  }: {
    item: DisplayProduct
    index: number
    goToProduct: (item: any) => void
    handleBookmark: (
      bookmarkYn: string,
      targetId: number,
      listKey?: string
    ) => void
    listKey?: string
    horizontal?: boolean
  }) => {
    const { t } = useTranslation()
    const { widthApp } = CommonStore;
    const widthItem = useMemo(() => {
      return (widthApp - 8 - 16 * 2) / 2;
    }, [widthApp]);
    const renderAverageStar = (val: number) => {
      switch (val) {
        case 1:
          return (
            <>
              <IconStarOn />
              <IconStarOff />
              <IconStarOff />
              <IconStarOff />
              <IconStarOff />
            </>
          )
        case 2:
          return (
            <>
              <IconStarOn />
              <IconStarOn />
              <IconStarOff />
              <IconStarOff />
              <IconStarOff />
            </>
          )
        case 3:
          return (
            <>
              <IconStarOn />
              <IconStarOn />
              <IconStarOn />
              <IconStarOff />
              <IconStarOff />
            </>
          )
        case 4:
          return (
            <>
              <IconStarOn />
              <IconStarOn />
              <IconStarOn />
              <IconStarOn />
              <IconStarOff />
            </>
          )
        case 5:
          return (
            <>
              <IconStarOn />
              <IconStarOn />
              <IconStarOn />
              <IconStarOn />
              <IconStarOn />
            </>
          )
        default:
          return (
            <>
              <IconStarOff />
              <IconStarOff />
              <IconStarOff />
              <IconStarOff />
              <IconStarOff />
            </>
          )
      }
    }

    return (
      <>
        <div
          className={clsx(
            horizontal ? classes.bx_pp_item_horizontal : classes.bx_item_wrap,
            classes.bx_item_wrap_common,
          )}
          key={`${listKey ? listKey : 'ProductItem'}_${index}`}
          aria-label={`product item${index}`}
        >
          <div className={classes.btn_bookmark}>
            {item.bookmarkYn === 'Y' ? (
              <div
                onClick={() =>
                  void handleBookmark('N', item.productId, listKey)
                }
              >
                <BookmarkON />
              </div>
            ) : (
              <div
                onClick={() =>
                  void handleBookmark('Y', item.productId, listKey)
                }
              >
                <BookmarkOff />
              </div>
            )}
          </div>
          <div onClick={() => goToProduct(item)} style={{ width: widthItem }}>
            {listKey === 'Pick01' ||
            listKey === 'Pick02' ||
            listKey === 'Pick03' ||
            listKey === 'Pick04' ? (
              <FastImageWithFallback
                className={classes.bx_img}
                wrapperStyle={{ display: 'flex' }}
                style={{
                  width: widthItem,
                  height: widthItem,
                }}
                source={{
                  uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                    item?.thumbnailFilePath || ''
                  }`,
                }}
              />
            ) : (
              <FastImageWithFallback
                className={classes.bx_img}
                wrapperStyle={{ display: 'flex' }}
                style={{
                  width: widthItem,
                  height: widthItem,
                }}
                source={{
                  uri: `${process.env.REACT_APP_LIFECARE_THUMBNAIL_IMG_URL}${
                    item?.mainImagePath || ''
                  }`,
                }}
              />
            )}

            <div className={classes.bx_info}>
              <span className={classes.info_pd_manufacturer}>
                {item.brandName}
              </span>
              <span
                className={classes.info_pd_name}
                // numberOfLines={2}
              >
                {item.productName}
              </span>
              {item.discountRate > 0 ? (
                <>
                  <div className={classes.bx_info_price}>
                    <span className={classes.txt_info_percent}>
                      {item.discountRate}%
                    </span>
                    <span className={classes.txt_info_price}>
                      {getFormatprice(item.salesPrice)}
                      <span className={classes.txt_price_unit}>
                        {t('screen.mall.label.won')}
                      </span>
                    </span>
                  </div>
                  <span className={classes.txt_info_before_price}>
                    {getFormatprice(item.consumerPrice)}
                    {t('screen.mall.label.won')}
                  </span>
                </>
              ) : (
                <>
                  <div className={classes.bx_info_price}>
                    <span className={classes.txt_info_price}>
                      {getFormatprice(item.salesPrice)}
                      <span className={classes.txt_price_unit}>
                        {t('screen.mall.label.won')}
                      </span>
                    </span>
                  </div>
                </>
              )}

              <div className={classes.bx_star_icon}>
                {renderAverageStar(item.averageStar)}
                <span className={classes.txt_review}>
                  {t('screen.mall.label.review')}{' '}
                  <span className={classes.txt_review_num}>
                    {item.reviewTotCnt || 0}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
)
export default ProductItem
