export enum JoinWithEmailSections {
  EMAIL,
  PASSWORD,
  PHONE_NUMBER,
  TERMS_AGREE,
}

export enum JoinStatusCode {
  EXPIRATION_CERTIFICATION = "EXPIRATION_CERTIFICATION",
}
