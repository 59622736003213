import CommonStore from "../store/CommonStore";

export const showBottomToast = (message: string, bottomOffset?: number) => {
  CommonStore.setToastOption({
    show: true,
    message1: message,
  });
};

export const showBottomToastTwoText = (
  text1: string,
  text2: string,
  bottomOffset?: number
) => {
  CommonStore.setToastOption({
    show: true,
    message1: text1,
    message2: text2,
  });
};
