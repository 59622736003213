// import { Dimensions, Linking, Platform, UIManager } from "react-native";
// import InAppBrowser from "react-native-inappbrowser-reborn";
import i18n from 'i18next'
import {
  AgeGroupCode,
  CategoryTypeCode,
  GenderTypeCode,
} from '../constants/CommonCode.enum'
import CommonStore from '../store/CommonStore'
// import { navigationRef } from "../navigation/NavigationFunction";

export const openInAppBrowser = async (url: string) => {
  window.open(url, '_blank')
}

/**
 * 닉네임 앞 2글자 제외 마스킹 처리
 * @param str 닉네임
 * @returns
 */
export const maskingStr = (str: string) => {
  let maskedStr = ''
  if (str != null) {
    if (str.length < 3) {
      maskedStr =
        str.substring(0, 1) + str.substring(1).replace(/[0-9a-zA-Z]/g, '*')
    } else {
      maskedStr =
        str.substring(0, 2) + str.substring(2).replace(/[0-9a-zA-Z]/g, '*')
    }
  }
  return maskedStr
}
export const getCategoryTypeCodes = () =>
  [
    CategoryTypeCode.ALL,
    CategoryTypeCode.SOUND,
    CategoryTypeCode.SEASON,
    CategoryTypeCode.VIDEO,
    CategoryTypeCode.COOK,
    CategoryTypeCode.HOUSEWORK,
    CategoryTypeCode.BEAUTY,
    // CategoryTypeCode.HYGIENE,
    // CategoryTypeCode.CLOTHES,
    CategoryTypeCode.HEALTH,
    CategoryTypeCode.DIGITAL,
  ].map((code, index) => {
    return {
      code: code,
      value: i18n.t(`screen.qnaList.filter.category.${code.toLowerCase()}`),
      id: index + 1,
    }
  })

export const getAgeGroupCodes = () =>
  [
    AgeGroupCode.ALL,
    AgeGroupCode.TWENTIES,
    AgeGroupCode.THIRTIES,
    AgeGroupCode.FORTIES,
    AgeGroupCode.FIFTIES,
  ].map((code, index) => {
    return {
      code: code,
      value: i18n.t(`screen.ranking.filterBox.${code.toLowerCase()}`),
      id: index + 1,
    }
  })

export const getGenderCodes = () =>
  [GenderTypeCode.ALL, GenderTypeCode.MALE, GenderTypeCode.FEMALE].map(
    (code, index) => {
      return {
        code: code,
        value: i18n.t(`screen.ranking.filterBox.${code.toLowerCase()}`),
        id: index + 1,
      }
    }
  )

export const handleTopButtonScroll = (
  y: any,
  showButton: () => void,
  hideButton: () => void,
  ref?: any
) => {
  // const beforeHeightOfScroll = CommonStore.beforeHeightOfScroll;
  // const currentHeightOfScroll = y;
  // CommonStore.beforeHeightOfScroll = currentHeightOfScroll;
  // CommonStore.topButtonOption.ref = ref;
  // const { height } = Dimensions.get("screen");
  // if (currentHeightOfScroll >= height / 2 && beforeHeightOfScroll > currentHeightOfScroll) {
  //   CommonStore.setShowTopButtonOption(true);
  //   showButton();
  //   CommonStore.startTopButtonTimer(() => {
  //     CommonStore.setShowTopButtonOption(false);
  //     hideButton();
  //   });
  // } else {
  //   CommonStore.setShowTopButtonOption(false);
  //   hideButton();
  // }
}

export const generateUniqueKey = () =>
  `_${Math.random().toString(36).substr(2, 9)}`

const measureNode = (node: number) => {
  /*
      🐛 BUG - In the current React Native version (v. 0.61.4)
      the x and y values are wrong on Android devices. So, I have decided to
      use pageX and pageY (which are relative to the device's screen) and then
      implement my own method for getting relative-to-parent positions.

      https://github.com/facebook/react-native/issues/29526
    */
  return new Promise((resolve, reject) => {
    // UIManager.measure(node, (x, y, width, height, pageX, pageY) => {
    //   resolve({ x, y, width, height, pageX, pageY });
    // });
  })
}
export const getRelativeToParentPosition = async (
  childNode: number,
  parentNode: number
) => {
  // Get the relative-to-screen position of the child node
  //  const { pageX: childX, pageY: childY } = await measureNode(childNode);
  const aaa = (await measureNode(childNode)) as {
    x: number
    y: number
    width: number
    height: number
    pageX: number
    pageY: number
  }

  // Get the relative-to-screen position of the parent node
  const bbb = (await measureNode(parentNode)) as {
    x: number
    y: number
    width: number
    height: number
    pageX: number
    pageY: number
  }

  /*
      Substract those values to get the relative-to-parent position.
      Pd: The child's position will be greater than the parent's one,
      just because the chlid is inside the parent.
    */

  return {
    x: bbb.pageX - aaa.pageX,
    y: bbb.pageY - aaa.pageY,
    height: bbb.height,
    parentHeight: aaa.height,
  }
}

export const sleep = (time: number) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const bottomOffset = () => {
  return 0
  // const currentRouteName = navigationRef.current?.getCurrentRoute()?.name;
  // const isTabBarDisplay =
  //   currentRouteName === "HomeScreen" ||
  // currentRouteName == "PostScreen" ||
  //   currentRouteName == "QnaScreen" ||
  //   currentRouteName == "MissionScreen";
  // const tapBarHeight = 60;
  // const iosSafeArea = 30;
  // const keyboardHeight = CommonStore.toastOption.keyboardHeight ?? 0;
  // const heightKeyboardShow = Platform.OS === "ios" ? keyboardHeight + 40 : 40;
  // const heightKeyboardHide = isTabBarDisplay
  //   ? Platform.OS === "ios"
  //     ? tapBarHeight + iosSafeArea + 20
  //     : tapBarHeight + 20
  //   : 50;
  // return keyboardHeight > 0 ? heightKeyboardShow : heightKeyboardHide;
}
