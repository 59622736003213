import React, { useState, useRef } from "react";
import TitleHeader from "../../../components/common/TitleHeader";
import { ReactComponent as QuestionIcon } from "../../../assets/icons/question.svg";
import { ReactComponent as ActiveQuestionIcon } from "../../../assets/icons/question_active.svg";
import { ReactComponent as ArrowUpIcon } from "../../../assets/icons/arrow_up_purple.svg";
import { ReactComponent as AnswerIcon } from "../../../assets/icons/answer.svg";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow_down.svg";
import { useTranslation } from "react-i18next";
import {
  CodeResponse,
  GetFaqResponse,
  GetFaqsRequest,
} from "../../../api/setting/faq/model";
import { getFaqs, getFaqTypeCodes } from "../../../api/setting/faq/api";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import AuthStore from "../../../store/AuthStore";
import { HeaderItem } from "../../../constants/Header";
import { track } from "../../../hooks/tracker/TrackFunction";
import { Virtuoso } from "react-virtuoso";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import { useLocation } from "react-router-dom";
import classes from "./FAQ.module.scss";
import clsx from "clsx";

interface FaqItemProps {
  section: GetFaqResponse;
  isActive: boolean;
  onHeaderClick: () => void;
}
const FaqItem = ({
  section,
  isActive,
  onHeaderClick,
}: FaqItemProps): JSX.Element => {
  const handleHeaderClick = () => {
    onHeaderClick();
  };

  const source = {
    html: section.faqAnswer,
  };

  return (
    <>
      <button className={classes.accordion_header} onClick={handleHeaderClick}>
        <div className={classes.accordion_header_left}>
          {isActive ? (
            <ActiveQuestionIcon className={classes.text_icon} />
          ) : (
            <QuestionIcon className={classes.text_icon} />
          )}
          <div className={classes.accordion_header_text_wrap}>
            <span
              className={clsx(
                classes.accordion_header_text,
                isActive
                  ? classes.accordion_header_active_text
                  : classes.accordion_header_inactive_text
              )}
            >
              {section.faqQuestion}
            </span>
          </div>
        </div>
        {isActive ? (
          <ArrowUpIcon className={classes.arrow_icon} />
        ) : (
          <ArrowDownIcon className={classes.arrow_icon} />
        )}
      </button>

      {
        <div
          className={clsx(
            classes.accordion_contents,
            isActive && classes.accordion_contents_active
          )}
        >
          <AnswerIcon className={classes.text_icon} />
          <div className={classes.accordion_contents_wrap}>
            <div dangerouslySetInnerHTML={{ __html: source.html }} />
          </div>
        </div>
      }
    </>
  );
};

const MemoisedFaqItem = React.memo(FaqItem, (prev, next) => {
  return (
    prev.isActive === next.isActive &&
    prev.section.faqQuestion === next.section.faqQuestion
  );
});

MemoisedFaqItem.displayName = "MemoisedFaqItem";

export default function FAQ(): JSX.Element {
  const { sessionUser, tempMember } = AuthStore;
  const isFromLoginAttraction = useLocation().state?.isFromLoginAttraction;
  const memberUuid = sessionUser?.memberUuid
    ? sessionUser?.memberUuid
    : tempMember?.tempMemberUUID;

  const [isLoading, setIsLoading] = useState(false);
  const [faqTypeList, setFaqTypeList] = useState<CodeResponse[]>([]);
  const [faqList, setFaqList] = useState<GetFaqResponse[]>([]);
  const [selectedFaqTypeCode, setSelectedFaqTypeCode] = useState<string>("");
  const [activeSectionIdx, setActiveSectionIdx] = useState<number | null>(null);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [isFaqListEnded, setFaqListEnded] = useState<boolean>(false);
  const pageSize = 10;
  const FIRST_PAGE = 0;
  const { t } = useTranslation();

  const ref = useRef(null);

  const getCodeList = async () => {
    const faqTypeCodes = await getFaqTypeCodes();

    faqTypeCodes.unshift({ code: "", codeName: t("screen.setting.label.all") });
    setFaqTypeList(faqTypeCodes);
  };

  const getInitFaqList = (faqTypeCode: string) => {
    track("view_faq_list", {
      faqTypeCode,
    });

    setIsLoading(true);
    const getFaqsRequest: GetFaqsRequest = {
      faqTypeCode,
      pageIndex: FIRST_PAGE,
      pageSize,
    };

    void getFaqs(getFaqsRequest)
      .then((faqs) => {
        if (faqs.list && faqs.list.length > 0) {
          setPageIndex(FIRST_PAGE + 1);
          faqs.list.length < pageSize && setFaqListEnded(true);
        } else {
          setFaqListEnded(true);
        }
        setFaqList([...faqs.list]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onEndFaqListReached = () => {
    if (isLoading || isFaqListEnded) {
      return;
    }

    setIsLoading(true);

    const getFaqsRequest: GetFaqsRequest = {
      faqTypeCode: selectedFaqTypeCode,
      pageIndex: pageIndex,
      pageSize,
    };

    setPageIndex(pageIndex + 1);
    void getFaqs(getFaqsRequest)
      .then((faqs) => {
        faqs.list.length < 10 && setFaqListEnded(true);
        setFaqList([...faqList, ...faqs.list]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOnTabPress = (code: string) => {
    setActiveSectionIdx(null);
    setSelectedFaqTypeCode(code);
    setFaqListEnded(false);
    setPageIndex(FIRST_PAGE);
    void getInitFaqList(code);
  };

  const renderFaq = (index: number, item: GetFaqResponse) => {
    const faq = item;

    return (
      <MemoisedFaqItem
        key={faq.faqId}
        section={faq}
        isActive={activeSectionIdx === index}
        onHeaderClick={() => {
          track("click_faq_detail", {
            faq_title: faq.faqQuestion,
            faq_type: selectedFaqTypeCode,
          });

          if (activeSectionIdx === index) {
            setActiveSectionIdx(null);
          } else {
            setActiveSectionIdx(index);
          }
        }}
      />
    );
  };

  useEffectOnlyOnce(() => {
    void getCodeList();
    void getInitFaqList(selectedFaqTypeCode);
  });

  return (
    <div aria-label={"FAQ Screen"}>
      <TitleHeader
        title={t("screen.setting.label.faq")}
        rightItem={
          isFromLoginAttraction
            ? HeaderItem.CLOSE
            : memberUuid
            ? HeaderItem.NONE
            : HeaderItem.CLOSE
        }
        isBack={isFromLoginAttraction ? false : memberUuid ? true : false}
      />

      <div className={classes.tab}>
        <div className={classes.tab_list}>
          {faqTypeList.map((eachCode: CodeResponse) => (
            <button
              className={clsx(
                classes.tab_item,
                selectedFaqTypeCode === eachCode.code
                  ? classes.tab_active
                  : classes.tab_inactive
              )}
              key={eachCode.code}
              onClick={() => {
                void handleOnTabPress(eachCode.code);
              }}
              aria-label={`${
                eachCode.codeName ? eachCode.codeName : "All"
              } Tab`}
            >
              <span
                className={clsx(
                  classes.tab_text,
                  selectedFaqTypeCode === eachCode.code
                    ? classes.tab_active_text
                    : classes.tab_inactive_text
                )}
              >
                {eachCode.codeName}
              </span>
            </button>
          ))}
        </div>
      </div>
      <Virtuoso
        ref={ref}
        aria-label={"FAQ List"}
        className={classes.accordion}
        data={faqList}
        totalCount={faqList.length}
        itemContent={renderFaq}
        useWindowScroll
        endReached={() => {
          void onEndFaqListReached();
        }}
        components={{
          Footer: () =>
            isFaqListEnded ? (
              <div style={{ height: 60, backgroundColor: "transparent" }} />
            ) : (
              <CustomActivityIndicatorSmall />
            ),
        }}
      />
    </div>
  );
}
