import { action, computed, makeObservable, observable } from "mobx";
import { UserActTypeCode } from "../constants/FeedType.enum";
import { goBack } from "../hooks/navigate/NavigateFunction";

class ListStore<T> {
  constructor({
    _keyForIdentify,
    _updater,
  }: {
    _keyForIdentify: keyof T;
    _updater: (action: UserActTypeCode, prev: T) => { next: T };
  }) {
    this.keyForIdentify = _keyForIdentify;
    this.updater = _updater;
    makeObservable(this);
  }

  keyForIdentify;

  @observable _list: T[] = [];

  @observable _headBuffer: T[] = [];

  @observable _headItemIndex: number | null = null;

  @observable _tailBuffer: T[] = [];

  @observable _tailItemIndex: number | null = null;

  @computed get list() {
    return this._list;
  }

  @computed get headBuffer() {
    return this._headBuffer;
  }

  @computed get headItemIndex() {
    return this._headItemIndex;
  }

  @computed get tailBuffer() {
    return this._tailBuffer;
  }

  @computed get tailItemIndex() {
    return this._tailItemIndex;
  }

  @action clear = () => {
    this._list = [];
    this._headBuffer = [];
    this._tailBuffer = [];
    this._headItemIndex = null;
    this._tailItemIndex = null;
  };

  @action setListItem = (item: T, index: number) => {
    this._list.splice(index, 1, item);
  };

  @action setList = (list: T[]) => {
    this._list = list;
  };

  @action setHeadBuffer = (headBuffer: T[]) => {
    this._headBuffer = headBuffer;
  };

  @action setHeadItemIndex = (index: number) => {
    this._headItemIndex = index;
  };

  @action setTailBuffer = (tailBuffer: T[]) => {
    this._tailBuffer = tailBuffer;
  };

  @action setTailItemIndex = (index: number) => {
    this._tailItemIndex = index;
  };

  @action prependList = (list: T[]) => {
    this._list = list.concat(this._list);
  };

  @action appendList = (list: T[], removeDummy = false) => {
    const _removeDummy =
      Object.keys(this._list[this._list.length - 1] as Object).length === 0 &&
      removeDummy;
    this._list = _removeDummy
      ? this._list.slice(0, this._list.length - 1).concat(list)
      : this._list.concat(list);
  };

  @action popItemFromList = (index: number) => {
    if (this._list.length > 0) {
      this._list.splice(index, 1);
      if (this._list.length == 1) {
        goBack();
      }
    }
  };

  @action updater;

  @action updateList = (action: UserActTypeCode, key: any) => {
    const matchedListIndex = this._list.findIndex(
      (item) => item[this.keyForIdentify] === key
    );
    const matchedHeadBufferIndex = this._headBuffer.findIndex(
      (item) => item[this.keyForIdentify] === key
    );
    const matchedTailBufferIndex = this._tailBuffer.findIndex(
      (item) => item[this.keyForIdentify] === key
    );

    this.update(matchedListIndex, action);
    this.update(matchedHeadBufferIndex, action);
    this.update(matchedTailBufferIndex, action);
  };

  @action update = (index: number, action: UserActTypeCode) => {
    if (index != -1) {
      if (
        action == UserActTypeCode.REPORT ||
        action == UserActTypeCode.DELETE
      ) {
        this.popItemFromList(index);
      } else {
        const { next } = this.updater(action, this._list[index]);

        this.setListItem(next, index);
      }
    }
  };
}

export default ListStore;
