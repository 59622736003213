import { memo, useCallback } from "react";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import MemberStore from "../../../store/MemberStore";
import {
  RankingPosterFeed,
  RankingPosterResponse,
} from "../../../api/member/model";
import { UserStyleFeedItem } from "./UserStyleFeedItem";
import { countFollow } from "../../../utils/numberCount";
import { useTranslation } from "react-i18next";
import { ReactComponent as CheckIcon } from "../../../assets/icons/sighup_user_style_check.svg";
import classes from "./UserStyleItem.module.scss";
import clsx from "clsx";

export const UserStyleItem = memo(
  ({
    item,
    index,
    follow,
  }: {
    item: RankingPosterResponse;
    index: number;
    follow: boolean;
  }) => {
    const { t } = useTranslation();

    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

    const nickname = item.nickname ? item.nickname : "";
    const memberId = item.memberId ? item.memberId : 0;
    const profileUrl = item.profileUrl;
    const isTeam220 = item.sortOrder === 0;
    const followerCnt = item.followerCnt;
    const feedCnt = item.feedCnt;

    const createIntroductionText = () => {
      if (isTeam220) {
        return t("screen.preference.userStyle.team220_dsc");
      }

      let text = "#" + item.roomScale.trim();
      if (item.tags) {
        item.tags?.slice(0, 2).forEach((tag) => {
          text = text + " #" + tag;
        });
      }
      return text;
    };

    const introductionText = createIntroductionText();

    const renderItem = useCallback((item: RankingPosterFeed, index: number) => {
      return (
        <div className={classes.feed_item_wrapper}>
          <UserStyleFeedItem
            item={item}
            index={index}
            like={MemberStore.likeSet.has(
              String(item.feedId) + ":" + String(item.memberId)
            )}
          />
        </div>
      );
    }, []);

    return (
      <>
        <div className={classes.user_style_item_wrapper}>
          <div className={classes.user_style_item_container}>
            <div className={classes.post_header}>
              <div className={classes.post_header_left}>
                <FastImageWithFallback
                  className={classes.user_img}
                  source={{ uri: `${PUBLIC_BUCKET_URL}${profileUrl || ""}` }}
                />
                <div
                  className={clsx(
                    classes.info_wrap,
                    isTeam220
                      ? classes.info_wrap_220team
                      : classes.info_wrap_normal
                  )}
                >
                  <div className={classes.info_wrap_overview}>
                    <span
                      className={classes.info_text1}
                      // numberOfLines={1}
                      // ellipsizeMode={"tail"}
                    >
                      {nickname.length > 9
                        ? nickname.substring(0, 9) + "..."
                        : nickname}
                    </span>
                    <span className={classes.info_text2}>
                      {"팔로워 " +
                        countFollow(followerCnt) +
                        " | " +
                        "게시글 " +
                        countFollow(feedCnt)}
                    </span>
                  </div>
                  <span
                    className={classes.info_text3}
                    // numberOfLines={1}
                  >
                    {introductionText}
                  </span>
                </div>
                <div
                  className={classes.follow_btn_wrap}
                  style={{
                    display: isTeam220 ? "none" : "flex",
                  }}
                >
                  <button
                    className={clsx(
                      classes.follow_btn,
                      follow ? classes.follow_btn_off : classes.follow_btn_on
                    )}
                    onClick={() => {
                      MemberStore.addFollowSet(memberId);
                    }}
                  >
                    {!follow && (
                      <span
                        className={clsx(
                          classes.follow_text,
                          classes.follow_text_off
                        )}
                      >
                        {"팔로우"}
                      </span>
                    )}
                    {follow && (
                      <CheckIcon
                        className={classes.follow_icon}
                        style={{ flex: 1, zIndex: 1, marginBottom: 5 }}
                      />
                    )}
                    {follow && (
                      <span
                        className={clsx(
                          classes.follow_text,
                          classes.follow_text_on
                        )}
                      >
                        {"팔로우"}
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* <FlatList
              data={item.feeds?.slice(0, 4)}
              style={{ marginTop: 12 }}
              keyExtractor={(item, index) => `${item.feedId}`}
              horizontal
              renderItem={renderItem}
              initialNumToRender={5}
              windowSize={3}
              bounces={false}
            /> */}
            <div className={classes.image_wrapper}>
              {item.feeds
                ?.slice(0, 4)
                .map((item, index) => renderItem(item, index))}
            </div>
          </div>
        </div>
      </>
    );
  }
);

UserStyleItem.displayName = "UserStyleItem";
