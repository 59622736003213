import { action, computed, makeObservable, observable, runInAction } from "mobx";
import { PRODUCT_SEARCH_WORD } from "../constants/Storage";
import { t } from "i18next";
import { PRODUCT_SEARCH_COUNT } from "../constants/Product";
import { getBrandAutoComplete, getBrandProductSearchResult } from "../api/brand/api";
import { SearchProductResultResponse } from "../api/brand/model";
import AuthStore from "./AuthStore";
import { AutoCompleteWhat, SearchSortType, SearchTabMenu } from "../constants/Search";

class BrandCommunityProductListStore {
  constructor() {
    makeObservable(this);
  }

  @observable errorMessage?: string;
  @observable toastMessage?: string;
  @observable brandCommunityId?: number = 0;
  @observable brandCommunityName?: string = "";
  @observable keyword? = "";
  @observable recentKeywordList: string[] = [];
  @observable filterRecentList: string[] = [];
  @observable autoCompleteList: string[] = [];
  @observable searchResult: SearchProductResultResponse | null = null;
  @observable showWord = false;
  @observable showResult = false;
  @observable refreshing = false;
  @observable loading = false;
  @observable searchPageIndex = 0;
  @observable loadingInList = false;
  @observable sort: SearchSortType = SearchSortType.POPULAR;
  @observable totalCount = 0;
  @observable showSortBox = false;
  @observable brandCommunitySubscriptionYn = "N";

  @computed get hasError() {
    return this.errorMessage !== undefined && this.errorMessage.length > 0;
  }

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action setToast = (message?: string) => {
    this.toastMessage = message;
  };

  @action clear = () => {
    this.errorMessage = "";
    this.toastMessage = "";
    this.keyword = "";
    this.recentKeywordList = [];
    this.autoCompleteList = [];
    this.filterRecentList = [];
    this.searchResult = null;
    this.showWord = false;
    this.showResult = false;
    this.refreshing = false;
    this.loading = false;
    this.sort = SearchSortType.POPULAR;
    this.showSortBox = false;
    this.totalCount = 0;
    this.searchPageIndex = 0;
    this.loadingInList = false;
  };

  @action setBrandCommunitySubscriptionYn = (val: string) => {
    this.brandCommunitySubscriptionYn = val;
  };

  @action setShowWord = (isVisible: boolean) => {
    this.showWord = isVisible;
  };

  @action setShowResult = (isVisible: boolean) => {
    this.showResult = isVisible;
  };

  @action setBrandCommunityId = (id: number) => {
    this.brandCommunityId = id;
  };

  @action setBrandCommunityName = (word: string) => {
    this.brandCommunityName = word;
  };

  @action setShowSortBox = (isVisible: boolean) => {
    this.showSortBox = isVisible;
  };

  @action setRefreshing = (ing: boolean) => {
    this.refreshing = ing;
  };

  @action setLoading = (ing: boolean) => {
    this.loading = ing;
  };

  @action setLoadingInList = (ing: boolean) => {
    this.loadingInList = ing;
  };

  @action setKeyword = (word?: string) => {
    this.keyword = word;
    if (word) {
      this.filteringRecentKeyword(word);
      void this.getAutoCompleteWord(word);
    }
  };

  @action setTotalCount = (count: number) => {
    this.totalCount = count;
  };

  @action setSort = (sortType: SearchSortType) => {
    this.sort = sortType;
    this.setShowSortBox(false);
  };

  @action getRecentWord = () => {
    let result = localStorage.getItem(PRODUCT_SEARCH_WORD);
    if (result) {
      this.recentKeywordList = JSON.parse(result) as string[];
    }
  };

  @action setRecentWord = async (word: string) => {
    if (!word) return;
    const alreadyExist = this.recentKeywordList.findIndex((text: string) => word === text);
    if (alreadyExist > -1) this.recentKeywordList.splice(alreadyExist, 1);
    if (this.recentKeywordList.length > 5) {
      this.recentKeywordList.splice(5, this.recentKeywordList.length);
    }
    this.recentKeywordList.splice(0, 0, word);
    localStorage.setItem(PRODUCT_SEARCH_WORD, JSON.stringify(this.recentKeywordList));
  };

  @action filteringRecentKeyword = (word: string) => {
    this.filterRecentList =
      this.recentKeywordList.filter((text) => text.toLowerCase().indexOf(word.toLowerCase()) > -1) || [];
  };

  @action getAutoCompleteWord = async (word: string) => {
    const result =
      word.length > 0 ? await getBrandAutoComplete(word, AutoCompleteWhat.PRODUCT, this.brandCommunityId) : [];
    this.setAutoCompleteWord(result);
  };

  @action setAutoCompleteWord = (result: string[]) => {
    this.autoCompleteList = result;
  };

  @action getSearchResult = (word: string, pageIndex: number) => {
    this.searchPageIndex = pageIndex;
    this.setLoading(true);
    if (pageIndex === 0) this.searchResult = null;

    getBrandProductSearchResult(
      SearchTabMenu.Product,
      this.brandCommunityId || 0,
      word,
      PRODUCT_SEARCH_COUNT,
      pageIndex,
      this.sort,
      AuthStore.sessionUser?.memberUuid === undefined ||
        AuthStore.sessionUser?.memberUuid === null ||
        AuthStore.sessionUser?.memberUuid?.length < 1
        ? AuthStore.tempMember?.tempMemberUUID
        : undefined,
      SearchTabMenu.Product === "product"
    )
      .then((res) => {
        runInAction(() => {
          this.setLoading(false);
          this.setRefreshing(false);
          this.setLoadingInList(false);
          this.setSearchResult(res);
          this.setTotalCount(res.totalCount || 0);
        });
      })
      .catch((err) => {
        this.setLoading(false);
        this.setRefreshing(false);
        this.setToast(t("common.message.unknownException"));
      });
  };

  @action setSearchResult = (res: SearchProductResultResponse) => {
    if (this.searchPageIndex == 0) this.searchResult = res;
    else this.searchResult?.list?.push(...res.list!);
  };
}

export default new BrandCommunityProductListStore();
