import React, { forwardRef, useEffect, useState } from "react";
import "./AccountAB.scss";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import { AccountFeedItem } from "../../../api/account/model";
import EmptyFeed from "./EmptyFeed";
import { AccountFeedTypeCode } from "../../../constants/Account";

import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import MyHomeStore from "../../../store/MyHomeStore";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import AuthStore from "../../../store/AuthStore";

import { AbTypeCode } from "../../../constants/ab";
import { Virtuoso } from "react-virtuoso";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import clsx from "clsx";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const AccountAB = observer(
  ({
    isMy,
    userId,
    dataRefresh,
    endReached,
  }: {
    isMy: boolean;
    userId: string;
    dataRefresh?: () => void;
    endReached: () => void;
  }) => {
    const { t } = useTranslation();

    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const { getAccountStore } = MyHomeStore;
    const { sessionUser } = AuthStore;
    const accountStore = getAccountStore(userId);

    const moveToDetail = (feedId: number, index: number) => {
      goTo(`/AB/${feedId}`, {
        state: {
          feedId: feedId,
          userId: userId,
          index: index,
        },
      });

      if (userId !== sessionUser?.memberUuid) {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.AB_READ,
          actHistoryTargetId: feedId.toString(),
          actPageCode: ActPageCode.OTHER_USER_HOME,
          actSectionCode: ActSectionCode.AB,
          attr1: userId,
        };
        void sendActHistory(actHistory);
      }
    };

    const renderItem = (index: number, item: AccountFeedItem) => {
      const isOver = item.processStatus === "DONE";

      return (
        <div className="ab_item" aria-label={`Account AB List Item ${index}`}>
          <div
            className="ab_item_header"
            aria-label={`Account AB List Item ${index} Header`}
          >
            <div className="ab_item_header_left">
              <p
                className="ab_item_header_text"
                aria-label={`Account AB List Item ${index} Header Voter`}
              >
                {t(`screen.myHome.label.attend`)} {item.voterCount}
              </p>
            </div>
            <div
              className={clsx("ab_item_header_btn", {
                terminated_btn: isOver,
                progress_btn: !isOver,
              })}
              aria-label={`Account AB List Item ${index} ProcessStatus`}
            >
              <p
                className={clsx("ab_item_header_btn_text", {
                  terminated_btn_text: isOver,
                  progress_btn_text: !isOver,
                })}
              >
                {isOver
                  ? t(`screen.myHome.label.done`)
                  : t(`screen.myHome.label.processing`)}
              </p>
            </div>
          </div>
          <div
            onClick={() => moveToDetail(item.feedId, index)}
            aria-label={`Account AB List Item ${index} Title`}
          >
            <p className="ab_main_text">{item.feedTitle}</p>
          </div>
          <div className="ab_select_wrap">
            <div
              className="ab"
              onClick={() => moveToDetail(item.feedId, index)}
              aria-label={`Account AB List Item ${index} A Button`}
            >
              <FastImageWithFallback
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${
                    item.athumbnailFilePath != undefined
                      ? item.athumbnailFilePath
                      : ""
                  }`,
                }}
                className="select_image"
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                  item.aoriginalFilePath || ""
                }`}
                renderFallbackNode={({ children, style }) => {
                  return <div>{children}</div>;
                }}
              />
              <div
                className={clsx("inner", {
                  selected: item.abFlag === AbTypeCode.A,
                })}
              >
                <div />
                <p className="ab_text1">{t(`screen.myHome.label.a`)}</p>
                <p
                  className="ab_text2"
                  aria-label={`Account AB List Item ${index} A Desc`}
                >
                  {item.adesc}
                </p>
              </div>
            </div>
            <div
              className="ab"
              onClick={() => moveToDetail(item.feedId, index)}
              aria-label={`Account AB List Item ${index} B Button`}
            >
              <FastImageWithFallback
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${
                    item.bthumbnailFilePath != undefined
                      ? item.bthumbnailFilePath
                      : ""
                  }`,
                }}
                className="select_image"
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                  item.boriginalFilePath || ""
                }`}
                renderFallbackNode={({ children, style }) => {
                  return <div>{children}</div>;
                }}
              />
              <div
                className={clsx("inner", {
                  selected: item.abFlag === AbTypeCode.B,
                })}
              >
                <div />
                <p className="ab_text1">{t(`screen.myHome.label.b`)}</p>
                <p
                  className="ab_text2"
                  aria-label={`Account AB List Item ${index} B Desc`}
                >
                  {item.bdesc}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    };
    return (
      <>
        {accountStore?.abList.length > 0 ? (
          <div className="account_ab_list_wrapper">
            <Virtuoso
              className="ab_list"
              data={accountStore?.abList.slice()}
              itemContent={renderItem}
              useWindowScroll
              endReached={() => {
                if (accountStore?.abCanLoadMore) {
                  accountStore?.getAccountFeed(
                    accountStore?.abPageIndex + 1,
                    AccountFeedTypeCode.AB
                  );
                  accountStore?.setAbPageIndex(accountStore?.abPageIndex + 1);
                }
              }}
              components={{
                Item: forwardRef(
                  (
                    { style, children, ...props },
                    ref: React.LegacyRef<HTMLDivElement> | undefined
                  ) => (
                    <div
                      ref={ref}
                      {...props}
                      style={{
                        ...style,
                        margin: 0,
                      }}
                    >
                      {children}
                      <div
                        style={{ height: 35, backgroundColor: "transparent" }}
                      />
                    </div>
                  )
                ),
              }}
            />
          </div>
        ) : (
          <EmptyFeed
            isMy={isMy}
            type={AccountFeedTypeCode.AB}
            dataRefresh={() => {
              dataRefresh && dataRefresh();
            }}
          />
        )}
        {/* <FlatList
          windowSize={3}
          style={abList.length > 0 && styles.ab_list}
          data={abList.slice()}
          listKey={"account_ab"}
          renderItem={renderItem}
          keyExtractor={(item, index) => "account_ab_" + item.feedId.toString()}
          showsVerticalScrollIndicator={false}
          scrollEnabled={false}
          ListEmptyComponent={
            loading === undefined || loading ? (
              <></>
            ) : (
              <EmptyFeed
                isMy={isMy}
                type={AccountFeedTypeCode.AB}
                dataRefresh={() => {
                  dataRefresh && dataRefresh();
                }}
              />
            )
          }
          aria-label={`Account AB List`}
        /> */}
      </>
    );
  }
);

export default AccountAB;
