export const validateEmailAddress = (emailAddress: string): boolean => {
  const regExp =
    /^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,3}$/g;

  return regExp.test(emailAddress);
};

export const withdrawalEmailMasking = (emailAddress: string) => {
  const originStr = emailAddress;
  const emailStr = originStr.match(
    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
  );
  let strLength;

  if (!originStr || !emailStr) {
    return originStr;
  } else {
    strLength = String(emailStr.toString().split("@")[0].length - 3);

    return originStr
      .toString()
      .replace(new RegExp(".(?=.{0," + strLength + "}@)", "g"), "*");
  }
};
