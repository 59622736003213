import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Arranges, Colors, FlexBoxs, Spacings, Texts } from "../../../assets/styles";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Styles } from "../../../assets/types/Style";
import { FeedItem } from "../../../api/brand/model";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { Virtuoso } from "react-virtuoso";

const BrandStoryQna = observer(({ renderData, listKey, onEndReached }: { renderData: FeedItem[]; listKey: string, onEndReached: any }) => {
  const { t } = useTranslation();

  const PURPLE_STYLE_LIST = [styles.qna_purple_wrap1, styles.qna_purple_wrap2];
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

  const data = Array.from({ length: Math.ceil(renderData.length / 2) }, (_, i) =>
    renderData.slice(i * 2, i * 2 + 2)
  );

  const handleQnaPress = (feedId: number, index: number) => {
    goTo(`/QnA/${feedId}`, {
      state: {
        callState: ""
      }
    });
  };

  // const getRandomNumber = () => {
  //   return Math.floor(Math.random() * 2);
  // };

  const renderContent = (item: FeedItem) => {
    return (
      <div style={{ ...styles.qna_wrap, ...(item.thumbnailFilePath != "" ? styles.qna_shadow : {}) }}>
        <div style={styles.qna_top}>
          <p style={styles.title_text} >
            {item.feedTitle}
          </p>
        </div>
        {/* 댓글 카운트 */}
        <div style={styles.qna_bottom}>
          <p style={styles.qna_bottom_text}>
            {t(`screen.qna.label.comment`)} {item.commentCount ? item.commentCount : 0}
          </p>
        </div>
      </div>
    );
  };

  const renderQna = (index: number, item: FeedItem) => {
    return (
      <div style={styles.list_item}>
        <TouchableWithAuthCheck
          style={styles.qna_item}
          onPress={() => handleQnaPress(item.feedId, index)}
        >
          {item.thumbnailFilePath ? (
            <div style={{ position: 'relative', height: "100%" }}>
              <FastImageWithFallback
                source={{ uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}` }}
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${item?.originalFilePath || ""}`}
                style={styles.qna_image}
                wrapperStyle={{height: "100%"}}
              />
              <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                {renderContent(item)}
              </div>
            </div>
          ) : (
            <div style={{ ...styles.qna_image, ...styles.qna_purple_wrap2 , ...Spacings.border_radius_8, position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
                {renderContent(item)}
              </div>
            </div>
          )}
        </TouchableWithAuthCheck>
        <div style={styles.bx_info}>
          <p style={styles.txt_nickname}>{item.nickname}</p>
          {item.subscribeYn === "Y" && (
            <span style={styles.flag_participating}>{t("screen.brandCommunity.label.subscribing")}</span>
          )}
        </div>
      </div>
    );
  };

  return (
    <div style={styles.tab_content}>
      {data?.length > 0 && (
        <Virtuoso
          style={styles.scroll_wrap}
          useWindowScroll
          data={data}
          itemContent={(index, arrItem: any) => {
            return (
              <div key={index} style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <div style={{ flexGrow: 1, paddingLeft: 16, paddingRight: 4, flex: 1 }}>
                  {renderQna(index * 2, arrItem[0])}
                </div>
                <div style={{ flexGrow: 1, paddingRight: 16, paddingLeft: 4, flex: 1 }}>
                  {arrItem.length > 1 && renderQna(index * 2 + 1, arrItem[1])}
                </div>
              </div>
            );
          }}
          endReached={() => {
            if (renderData && renderData.length == 0) return;
            void onEndReached();
          }}
        />
      )}
    </div>
  );
});

export default BrandStoryQna;

const styles: Styles = {
  scroll_wrap: {
    ...Colors.background_fff,
  },
  list_item: {
    ...FlexBoxs.flex,
    flex: 1,
    ...FlexBoxs.vertical,
    marginBottom: 32,
  },
  bx_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    flex: 1,
    alignItems: "center",
  },
  flag_participating: {
    fontSize: 10,
    color: "#BE69C2",
    backgroundColor: "#F7F4FA",
    borderRadius: 2,
    paddingVertical: 2,
    paddingHorizontal: 8,
    letterSpacing: -0.4,
    marginLeft: 3,
    overflow: "hidden",
    padding: '0px 5px'
  },
  txt_nickname: {
    fontSize: 12,
    color: "#999",
    letterSpacing: -0.48,
  },
  qna_list: {
    ...Spacings.padding_right_left_16,
  },
  qna_item: {
    width: '100%',
    ...Spacings.margin_bottom_8,
    flex: 1,
    aspectRatio: 1
  },
  qna_image: {
    width: '100%',
    height: '100%',
    ...Spacings.border_radius_8,
    objectFit: "cover",
  },
  qna_wrap: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.between,
    ...Spacings.border_radius_8,
    ...Spacings.padding_top_bottom_16,
    ...Spacings.padding_right_left_16,
    width: '100%',
    height: '100%',
  },
  qna_shadow: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  qna_purple_wrap1: {
    ...Colors.background_6c2773,
  },
  qna_purple_wrap2: {
    ...Colors.background_a760b1,
  },
  qna_top: {
  },
  qna_top_text: {
    width: '25%',
    ...Texts.contents_text_5,
    ...Colors.font_fff,
  },
  title_wrap: {
    ...Spacings.margin_top_10,
  },
  title_text: {
    ...Texts.contents_text_22,
    ...Texts.font_weight_500,
    textAlign: 'start',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    lineClamp: '2',
    WebkitBoxOrient: 'vertical'
  },
  qna_bottom: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.end_h,
  },
  qna_bottom_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
};
