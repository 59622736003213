import { action, computed, makeObservable, observable } from "mobx";
import { GetQnaListRequest, Qna, QnaListVO } from "../api/qna/model";
import { getQnaList } from "../api/qna/api";
import { Category, Topic } from "../constants/Qna.enum";
import { SearchSortType } from "../constants/Search";
import { t } from "i18next";

export const PAGE_SIZE = 10;
class QnaListStore {
  constructor() {
    makeObservable(this);
  }
  @observable _loading = false;

  @observable _qnaList: QnaListVO[] = [];
  @observable _waitingAnswerOnly = false;
  @observable _sort: SearchSortType = SearchSortType.LATEST;
  @observable _showSortBox = false;
  @observable _pageIndex = 0;
  @observable _totalCount = 0;
  @observable _selectedCategories: Set<Category> = new Set();
  @observable _selectedTopics: Set<Topic> = new Set();
  @observable toastMessage = "";

  @computed get qnaList() {
    return this._qnaList;
  }
  @computed get waitingAnswerOnly() {
    return this._waitingAnswerOnly;
  }
  @computed get sort() {
    return this._sort;
  }
  @computed get showSortBox() {
    return this._showSortBox;
  }
  @computed get pageIndex() {
    return this._pageIndex;
  }
  @computed get totalCount() {
    return this._totalCount;
  }
  @computed get selectedCategories() {
    return this._selectedCategories;
  }
  @computed get selectedTopics() {
    return this._selectedTopics;
  }

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @computed get loading() {
    return this._loading;
  }

  @action clearStore = () => {
    this.setWaitingAnswerOnly(false);
    this._selectedCategories.clear();
    this._selectedTopics.clear();
  };

  @action setQnaList = (qnaList: QnaListVO[]) => {
    this._qnaList = qnaList;
  };

  @action setToast = (message: string) => {
    this.toastMessage = message;
  };

  @action initQnaList = async (getQnaListRequest: GetQnaListRequest) => {
    this.setPageIndex(0);
    const qnaShowAll = await getQnaList(getQnaListRequest);
    if (!qnaShowAll.list) {
      this.setToast(t("common.error.server"));
    }
    this.setQnaList(qnaShowAll.list);
    this.setTotalCount(qnaShowAll.totalCount);

    for (
      let i = 0;
      i <= this.totalCount / PAGE_SIZE && this.qnaList.length === 0;
      i++
    ) {
      await this.getQnaList();
    }
  };

  @action getQnaList = async () => {
    this.setPageIndex(this.pageIndex + 1);
    if (this.pageIndex > this.totalCount / PAGE_SIZE) {
      return;
    }

    const getQnaListRequest: GetQnaListRequest = {
      sort: this.sort,
      waitingAnswerOnly: this.waitingAnswerOnly,
      pageSize: PAGE_SIZE,
      pageIndex: this.pageIndex,
      topics: [...this.selectedTopics],
      categories: [...this.selectedCategories],
    };

    return await getQnaList(getQnaListRequest);
  };

  @action getMoreQnaList = async () => {
    this.setLoading(true);

    if (this.qnaList.length === 0) {
      return;
    }

    const qnaList = await this.getQnaList();
    if (!qnaList) return;
    if (qnaList.list.length === 0) {
      await this.getQnaList();
    }
    this.setQnaList(this.qnaList.concat(qnaList.list));

    this.setTotalCount(qnaList.totalCount);
    this.setLoading(false);
  };

  @action refresh = async () => {
    await this.initQnaList({
      sort: this.sort,
      waitingAnswerOnly: this.waitingAnswerOnly,
      topics: [...this.selectedTopics],
      categories: [...this.selectedCategories],
    });
  };

  @action updateQna = (modifiedInfo: Qna) => {
    if (this.waitingAnswerOnly && modifiedInfo.commentCount > 0) {
      this.removeQna(modifiedInfo.feedId);
      return;
    }

    const modifiedList: QnaListVO[] = this.qnaList.map((qna) => {
      if (qna.feedId !== modifiedInfo?.feedId) {
        return qna;
      }
      return {
        feedId: modifiedInfo.feedId ?? qna.feedId,
        feedTitle: modifiedInfo.feedTitle ?? qna.feedTitle,
        createdDatetime: modifiedInfo.createdDatetime ?? qna.createdDatetime,
        likeCount: modifiedInfo.likeCount ?? qna.likeCount,
        nickname: modifiedInfo.nickname ?? qna.nickname,
        contents: modifiedInfo.contents ?? qna.contents,
        commentCount: modifiedInfo.commentCount ?? qna.commentCount,
        thumbnailFilePath: qna.thumbnailFilePath ?? qna.thumbnailFilePath,
      };
    });

    this.setQnaList(modifiedList);
  };

  @action removeQna = (feedId: number) => {
    this.setQnaList(this.qnaList.filter((qna) => qna.feedId !== feedId));
  };

  @action setWaitingAnswerOnly = (waitingAnswerOnly: boolean) => {
    this._waitingAnswerOnly = waitingAnswerOnly;
  };

  @action setSort = (sort: SearchSortType) => {
    this._sort = sort;
    this.setShowSortBox(false);
  };

  @action setShowSortBox = (showSortBox: boolean) => {
    this._showSortBox = showSortBox;
  };

  @action setPageIndex = (pageIndex: number) => {
    this._pageIndex = pageIndex;
  };

  @action setTotalCount = (totalCount: number) => {
    this._totalCount = totalCount;
  };

  @action toggleCategory = (category: Category) => {
    if (category === Category.ALL) {
      this._selectedCategories.clear();
      return;
    }

    this.selectedCategories.has(category)
      ? this._selectedCategories.delete(category)
      : this._selectedCategories.add(category);
  };

  @action toggleTopic = (topic: Topic) => {
    if (topic === Topic.ALL) {
      this._selectedTopics.clear();
      return;
    }

    this.selectedTopics.has(topic)
      ? this._selectedTopics.delete(topic)
      : this._selectedTopics.add(topic);
  };
}
export default QnaListStore;
