import React, { forwardRef } from 'react'
import { observer } from 'mobx-react'
import ProductItem from './ProductItem'
import { DisplayProduct } from '../../../api/mall/model'
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso'
import { useTranslation } from 'react-i18next'
import classes from './ProductList.module.scss'

const ProductList = observer(
  ({
    renderData,
    title,
    goToProduct,
    handleBookmark,
    handleMore,
    moreBtnLabel,
    listKey,
    dataChangeToggle,
  }: {
    renderData: DisplayProduct[]
    title?: string
    goToProduct: (item: any) => void
    handleBookmark: (
      bookmarkYn: string,
      targetId: number,
      listKey?: string
    ) => void
    handleMore?: () => void
    moreBtnLabel?: string
    listKey?: string
    dataChangeToggle?: boolean
  }) => {
    const { t } = useTranslation()

    return (
      <>
        {title && (
          <div className={classes.bx_title}>
            <div style={{ position: 'relative' }}>
              <div className={classes.bg_title}></div>
              <span className={classes.txt_title}>{title}</span>
            </div>
          </div>
        )}

        <div className={classes.flat_box}>
          <VirtuosoGrid
            data={renderData}
            // numColumns={2}
            // listKey={listKey}
            // keyExtractor={(item, index) =>
            //   `${listKey ? listKey : 'ProductList'}_${index.toString()}`
            // }
            // extraData={dataChangeToggle}
            useWindowScroll
            itemContent={(index, item) => (
              <ProductItem
                item={item}
                index={index}
                goToProduct={goToProduct}
                handleBookmark={handleBookmark}
                listKey={listKey}
              />
            )}
            components={{
              List: forwardRef(
                (
                  { children, style, ...props },
                  ref: React.LegacyRef<HTMLDivElement> | undefined
                ) => (
                  <div
                    ref={ref}
                    {...props}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      ...style,
                    }}
                  >
                    {children}
                  </div>
                )
              ),
              Item: ({ children, ...props }) => (
                <div
                  {...props}
                  style={{ ...props.style, width: "calc(50% - 4px)" }}
                  className="qna_item"
                >
                  {children}
                </div>
              ),
            }}
          />
        </div>

        {handleMore && (
          <div className={classes.bx_btn_more}>
            <div
              className={classes.btn_more}
              onClick={() => handleMore()}
              aria-label={'btn more'}
            >
              <text className={classes.txt_btn_more}>
                {moreBtnLabel ? moreBtnLabel : t('screen.mall.button.more')}
              </text>
            </div>
          </div>
        )}
      </>
    )
  }
)
export default ProductList
