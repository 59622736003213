import { observer } from 'mobx-react'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import CustomBottomSheet from '../../../components/common/CustomBottomSheet'

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow_down_small.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { SortType } from '../../../api/mallCategory/model'
import { DisplayProduct } from '../../../api/mall/model'
import ProductItem from '../../mall/presenter/ProductItem'
import { useTranslation } from 'react-i18next'
import { Virtuoso, VirtuosoGrid } from 'react-virtuoso'
import { CustomActivityIndicator } from '../../../components/common'
import clsx from 'clsx'
import classes from './ProductList.module.scss'

const ProductList = ({
  sortType,
  changeSortType,
  renderData,
  onPress,
  totalProductCount,
  productLoading,
  listKey,
  dataChangeToggle,
  handleBookmark,
  endReached,
}: {
  sortType: SortType
  changeSortType: (sortType: SortType) => void
  renderData?: DisplayProduct[]
  onPress: (item: any) => void
  totalProductCount: number
  productLoading: boolean
  listKey: string
  dataChangeToggle?: boolean
  handleBookmark: (
    bookmarkYn: string,
    targetId: number,
    listKey?: string
  ) => void
  endReached?: (index: number) => void
}) => {
  const [showSortBox, setShowSortBox] = useState<boolean>(false)
  const { t } = useTranslation()
  return (
    <>
      <div className={classes.line_row_10}></div>
      <div className={classes.bx_sort}>
        <text className={classes.txt_sort}>
          <text className={classes.txt_sort_num}>{totalProductCount}</text>개의
          관련 상품이 있습니다.
        </text>
        <div
          className={classes.header_sort_btn}
          onClick={() => {
            setShowSortBox(true)
          }}
        >
          <text className={classes.header_sort_btn_txt}>
            {sortType === SortType.LATEST
              ? t('screen.brandCommunity.sortType.latest')
              : sortType === SortType.POPULAR
              ? t('screen.brandCommunity.sortType.popular')
              : t('screen.brandCommunity.sortType.sales')}
          </text>
          <ArrowDown />
        </div>
      </div>

      {renderData && renderData !== null && (
        <VirtuosoGrid
          className={classes.bx_pp_item_wrap}
          // numColumns={2}
          data={renderData}
          // extraData={dataChangeToggle}
          useWindowScroll
          computeItemKey={(index, item) => `${listKey}_${item.productId}`}
          itemContent={(index, item) => {
            return (
              <ProductItem
                item={item}
                index={index}
                goToProduct={onPress}
                handleBookmark={handleBookmark}
                listKey={listKey}
              />
            )
          }}
          endReached={endReached}
          components={{
            List: forwardRef(
              (
                { children, style, ...props },
                ref: React.LegacyRef<HTMLDivElement> | undefined
              ) => (
                <div
                  ref={ref}
                  {...props}
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    columnGap: 8,
                    ...style,
                  }}
                >
                  {children}
                </div>
              )
            ),
            Item: ({ children, ...props }) => (
              <div
                {...props}
                style={{ ...props.style, width: 'calc(50% - 4px)' }}
                className="qna_item"
              >
                {children}
              </div>
            ),
            Footer: () => {
              return productLoading ? (
                <>
                  <CustomActivityIndicator isDarkSpinner={true} />
                </>
              ) : (
                <></>
              )
            },
          }}
        />
      )}

      <CustomBottomSheet
        snapHeight={235}
        open={showSortBox}
        // handleComponent={() => <></>}
        onClose={() => setShowSortBox(false)}
      >
        <div className={classes.sheet} aria-label="Sort option div">
          <div
            className={classes.close_btn}
            onClick={() => setShowSortBox(false)}
            aria-label="Sort close"
          >
            <CloseIcon />
          </div>
          <div className={classes.sheet_title_wrap}>
            <text className={classes.sheet_title} aria-label="Sorting">
              {t('screen.search.label.sort')}
            </text>
          </div>

          <div className={classes.sort_list}>
            <div
              className={classes.sort_item}
              onClick={() => {
                setShowSortBox(false)
                changeSortType(SortType.POPULAR)
              }}
              aria-label="Sort popular"
            >
              <text
                className={clsx(
                  classes.sort_text,
                  sortType === SortType.POPULAR && classes.sort_active_text
                )}
              >
                {t('screen.brandCommunity.sortType.popular')}
              </text>
            </div>
            <div
              className={classes.sort_item}
              onClick={() => {
                setShowSortBox(false)
                changeSortType(SortType.SALES)
              }}
              aria-label="Sort latest"
            >
              <text
                className={clsx(
                  classes.sort_text,
                  sortType === SortType.SALES && classes.sort_active_text
                )}
              >
                {t('screen.brandCommunity.sortType.sales')}
              </text>
            </div>
            <div
              className={classes.sort_item}
              onClick={() => {
                setShowSortBox(false)
                changeSortType(SortType.LATEST)
              }}
              aria-label="Sort latest"
            >
              <text
                className={clsx(
                  classes.sort_text,
                  sortType === SortType.LATEST && classes.sort_active_text
                )}
              >
                {t('screen.brandCommunity.sortType.latest')}
              </text>
            </div>
          </div>
        </div>
      </CustomBottomSheet>
    </>
  )
}

export default ProductList
