import React from "react";
import { observer } from "mobx-react";
import { Colors } from "../../assets/styles";
import { FollowersMain } from "./presenter";
import AuthStore from "../../store/AuthStore";
import { useLocation } from "react-router-dom";

const FollowersScreen = observer(() => {
  const location = useLocation();
  const params = location.state;
  const userId = params.targetUserId;
  const userNickname = params.targetUserNickname;
  const tabType = params.type; // 팔로워 인지 팔로잉인지
  const isMy = userId === AuthStore.sessionUser?.memberUuid;

  return (
    <div
      style={{
        flex: 1,
        ...Colors.background_fff,
      }}
      aria-label="Follow screen"
    >
      <FollowersMain
        userId={userId}
        userNickname={userNickname}
        tabType={tabType}
        isMy={isMy}
      />
    </div>
  );
});

export default FollowersScreen;
