import "./QnaInterestingCard.scss";
import { observer } from "mobx-react";
import React from "react";
import { ReactComponent as QuestionIcon } from "../../../assets/icons/new_question_active.svg";

import { getNumberFormat } from "../../../utils/number";
import { Qna } from "../../../api/qna/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";

import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { ActPageCode, ActSectionCode } from "../../../constants/ActHistory";

import { useTranslation } from "react-i18next";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const QnaInterestingCard = observer(
  ({ item, index }: { item: Qna; index: number }) => {
    const { t } = useTranslation();
    const PUBLIC_PROFILE_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    const moveQnaDetail = (feedId: number) => {
      goTo(`/QnA/${feedId}`, {
        state: {
          feedId: feedId,
          actData: {
            actPageCode: ActPageCode.QNA,
            actSectionCode: ActSectionCode.RECENT_QNA,
          },
          index: index,
        },
      });
    };

    const userHomeParam = {
      targetUserId: item.memberUuid,
      nickname: item.nickname,
      profileUrl: item.profileUrl,
      profileBackgroundColor: item.profileBackgroundColor,
    };

    return (
      <div aria-label={`Qna Main interested qna item ${index}`}>
        <div className="interesting_question_card">
          <div className="interesting_question_card_header">
            <div className="interesting_question_card_header_left">
              {/* [PPIT210117 - 4726] Q&A 카드 터치영역 수정 */}
              <TouchableWithAuthCheck
                onPress={() => {
                  goTo(`/user/${userHomeParam.nickname}`, {
                    state: { userHomeParam },
                  });
                }}
              >
                <FastImageWithFallback
                  source={{
                    uri: `${PUBLIC_PROFILE_BUCKET_URL}${item.profileUrl ?? ""}`,
                  }}
                  className="profile_image"
                />
              </TouchableWithAuthCheck>

              <div className="interesting_question_card_info">
                {/* [PPIT210117 - 4726] Q&A 카드 터치영역 수정 */}
                <TouchableWithAuthCheck
                  onPress={() => {
                    goTo(`/user/${userHomeParam.nickname}`, {
                      state: { userHomeParam },
                    });
                  }}
                >
                  <p className="interesting_question_card_text1">
                    {item.nickname}
                  </p>
                </TouchableWithAuthCheck>
                <div className="text_bar" />
                <p className="interesting_question_card_text2">
                  {t(`screen.qna.label.attend`)}{" "}
                  {getNumberFormat(item.commentCount)}
                </p>
              </div>
            </div>
            <TouchableWithAuthCheck
              className="answer_btn"
              onPress={() => {
                moveQnaDetail(item.feedId);
              }}
              aria-label={`Qna Main interested qna item ${index} move detail button`}
            >
              <p className="answer_btn_text">{t(`screen.qna.button.reply`)}</p>
            </TouchableWithAuthCheck>
          </div>
          {/* [PPIT210117 - 4726] Q&A 카드 터치영역 수정 */}
          <TouchableWithAuthCheck
            onPress={() => {
              moveQnaDetail(item.feedId);
            }}
            className="interesting_question_card_content_wrapper"
          >
            <div className="interesting_question_card_content">
              <div>
                <QuestionIcon />
              </div>
              <div className="interesting_question_card_content_right">
                <p className="interesting_question_card_text2">
                  {item.feedTitle}
                </p>
                <p className="interesting_question_card_text3">
                  {item.contents}
                </p>
              </div>
            </div>
          </TouchableWithAuthCheck>
        </div>
      </div>
    );
  }
);

export default QnaInterestingCard;
