import "./BookmarkPost.scss";

import React, { useEffect, useState } from "react";
import { ReactComponent as VideoIcon } from "../../../assets/icons/grid_video.svg";
import { ReactComponent as MultiImageIcon } from "../../../assets/icons/grid_multi.svg";
import { ReactComponent as CheckOnIcon } from "../../../assets/icons/checkbox_big_on.svg";

import { observer } from "mobx-react";
import { BookmarkFeedItem } from "../../../api/bookmark/model";
import BookmarkStore from "../../../store/BookmarkStore";
import { FeedTypeCode } from "../../../constants/Feed";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const BookmarkPost = observer(
  ({ data, listIndex }: { data: BookmarkFeedItem; listIndex: number }) => {
    const {
      bookmarkFeeds,
      selectedBookmarkFeeds,
      isTotalChecked,
      isEdit,
      setIsTotalChecked,
      selectBookmarkFeed,
      currentTab,
    } = BookmarkStore;
    const { setLoading } = CommonStore;

    const [isSelected, setIsSelected] = useState(false);
    const widthApp = CommonStore.widthApp;

    const postTotalChecked = isTotalChecked.get(currentTab);
    const postEdit = isEdit.get(currentTab);
    const selectedPosts = selectedBookmarkFeeds.get(currentTab);

    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const toggleIsSelected = () => {
      setIsSelected(!isSelected);
    };

    const handleSelect = (bookmarkTargetId: number) => () => {
      toggleIsSelected();
      selectBookmarkFeed(currentTab, bookmarkTargetId);

      const lengthOfBookmarkFeeds: number =
        bookmarkFeeds.get(currentTab)?.length || 0;
      const lengthOfSelectedBookmarkFeeds: number =
        selectedBookmarkFeeds.get(currentTab)?.size || 0;
      setIsTotalChecked(
        currentTab,
        lengthOfBookmarkFeeds === lengthOfSelectedBookmarkFeeds ? true : false
      );
    };

    const callActHistory = (
      sectionCode: string,
      feedId: number,
      index: number
    ) => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.POST_READ,
        actHistoryTargetId: feedId.toString(),
        actPageCode: ActPageCode.BOOKMARK,
        actSectionCode: sectionCode,
        attr1: index.toString(),
      };
      void sendActHistory(actHistory);
    };

    const moveToPostDetail = (feedId: number) => {
      if (feedId !== undefined && feedId >= 0) {
        callActHistory(ActSectionCode.POST, feedId, listIndex);
      }

      goTo(`/posts/feed/${feedId}`, {
        state: {
          feedId: feedId,
          postIndex: listIndex,
          listIndex,
        },
      });
    };

    const addContentsIcon = (item: BookmarkFeedItem) => {
      const imageCount =
        (item.imageCount !== undefined && item.imageCount) || 0;
      const videoCount =
        (item.videoCount !== undefined && item.videoCount) || 0;
      return (
        <>
          {imageCount + videoCount > 1 ? (
            <div className="icon" aria-label="Multi contents">
              <MultiImageIcon />
            </div>
          ) : (
            <>
              {imageCount == 0 && videoCount > 0 && (
                <div className="icon" aria-label="Video">
                  <VideoIcon />
                </div>
              )}
            </>
          )}
        </>
      );
    };

    const normalGrid = (item: BookmarkFeedItem) => {
      return (
        <>
          {isEdit.get(FeedTypeCode.POST) ? (
            isSelected ? (
              <button
                className="post_item selected"
                key={data.feedId}
                onClick={handleSelect(data.feedId)}
                aria-label="Click post"
                style={{
                  width: `calc(${widthApp / 3}px - 2px)`,
                  height: `calc(${widthApp / 3}px - 2px)`,
                }}
              >
                <div className="post_image_wrap">
                  <FastImageWithFallback
                    source={{
                      uri: `${PUBLIC_BUCKET_URL}${data.thumbnailFilePath || ""}`,
                    }}
                    fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                      data.thumbnailFilePath?.replace("THUMBNAIL", "ORIGINAL") ||
                      ""
                    }`}
                    className="post_image"
                  />
                </div>
                <div className="icon"><CheckOnIcon className="select_icon" /></div>
                <div className="selected_post_wrap" />
              </button>
            ) : (
              <button
                className="post_item"
                key={data.feedId}
                onClick={handleSelect(data.feedId)}
                aria-label="Click post"
                style={{
                  width: `calc(${widthApp / 3}px - 2px)`,
                  height: `calc(${widthApp / 3}px - 2px)`,
                }}
              >
                <div
                  className="post_image_wrap"
                >
                  <FastImageWithFallback
                    source={{
                      uri: `${PUBLIC_BUCKET_URL}${data.thumbnailFilePath || ""}`,
                    }}
                    fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                      data.thumbnailFilePath?.replace("THUMBNAIL", "ORIGINAL") ||
                      ""
                    }`}
                    className="post_image"
                  />
                </div>
                {addContentsIcon(item)}
                <div className="post_wrap" />
              </button>
            )
          ) : (
            <button
              className="post_item"
              key={data.feedId}
              onClick={() => moveToPostDetail(data.feedId)}
              aria-label="Click post"
              style={{
                width: `calc(${widthApp / 3}px - 2px)`,
                height: `calc(${widthApp / 3}px - 2px)`,
              }}
            >
              <div className="post_image_wrap">
                <FastImageWithFallback
                  source={{
                    uri: `${PUBLIC_BUCKET_URL}${data.thumbnailFilePath || ""}`,
                  }}
                  fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                    data.thumbnailFilePath?.replace("THUMBNAIL", "ORIGINAL") || ""
                  }`}
                  className="post_image"
                />
              </div>
              {addContentsIcon(item)}
              <div className="post_wrap" />
            </button>
          )}
        </>
      );
    };

    useEffect(() => {
      setIsSelected(false);
    }, [postEdit]);

    useEffect(() => {
      if (isTotalChecked.get(currentTab)) {
        setIsSelected(true);
      }
    }, [postTotalChecked, isTotalChecked, currentTab]);

    useEffect(() => {
      const selectedBookmarkPosts = selectedBookmarkFeeds.get(currentTab);
      selectedBookmarkPosts !== undefined &&
        setIsSelected(selectedBookmarkPosts.has(data.feedId));
    }, [
      selectedBookmarkFeeds,
      selectedPosts,
      postTotalChecked,
      data.feedId,
      currentTab,
    ]);

    return <div
      id="bookmark_post_grid"
        style={{
        width: `calc(${widthApp / 3}px - 2px)`,
        height: `calc(${widthApp / 3}px - 2px)`,
      }}
    >
      {normalGrid(data)}
    </div>;
  }
);

export default BookmarkPost;
