import { FeedTypeCode } from "../../constants/Feed";
import { callAPIWithDELETEMethod, callAPIWithGETMethod, callAPIWithPOSTMethod } from "../common/api";
import CommonResponse from "../http";
import {
  InterestedTagRequest,
  TagDetail,
  TagFeedRequest,
  TagFeedResponse,
  TagSearchRequest,
  TagSearchResponse,
} from "./model";

export const searchTag = async (tagItem: TagSearchRequest, isLoading = true): Promise<TagSearchResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/search/tag",
      params: tagItem,
    },
    isLoading
  );

  return (response?.successOrNot === "Y" ? response?.data : null) as TagSearchResponse;
};

export const interestedTag = async (memberUuid: string, isLoading = true) => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/interestedTag",
      params: { memberUuid },
    },
    isLoading
  );

  return response?.successOrNot === "Y" ? response?.data : null;
};

export const deleteInterestedTag = async (
  memberUuid: string,
  tagId: number | null,
  isLoading = true
): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/interestedTag",
      body: { memberUuid, tagId },
    },
    isLoading
  );

  return response?.successOrNot === "Y" && response?.statusCode === "OK" ? true : false;
};

export const addInterestedTag = async (
  memberUuid: string,
  tagId: number | null,
  searchKeyWord: string,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/interestedTag",
      body: { memberUuid, tagId, searchKeyWord },
    },
    isLoading
  );
};

export const searchInterestedTag = async (
  memberUuid: string,
  searchKeyWord: string,
  pageIndex: number,
  pageSize: number,
  isLoading = true
) => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/search/interestedTag",
      params: { memberUuid, searchKeyWord, pageIndex, pageSize },
    },
    isLoading
  );
  return (response?.successOrNot === "Y" ? response?.data : null) as TagSearchResponse;
};

export const findTagDetail = async (tagId: number, isLoading = true) => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/tag/${tagId}`,
    },
    isLoading
  );

  return (response?.successOrNot === "Y" ? response?.data : null) as TagDetail;
};
