import { useTranslation } from "react-i18next";
import { Arranges, Colors, FlexBoxs, Spacings, Texts } from "../../../assets/styles";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/close_purple_background.svg";
import { ReactComponent as PlusPurple } from "../../../assets/icons/plus_purple.svg";
import { Styles } from "../../../assets/types/Style";
import { observer } from "mobx-react";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { NewProduct } from "../../../api/product/model";
import ArticleWriteStore from "../../../store/ArticleWriteStore";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { ReactComponent as DimImage } from "../../../assets/icons/image_add_dim.svg";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
import { FeedMode, FeedTypeCode } from "../../../constants/Feed";
import { Virtuoso } from "react-virtuoso";

const ArticleWriteProduct = observer(
    ({ mode }: { mode: FeedMode }) => {
        const { t } = useTranslation();
        const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

        const { tempProductList, productUpdated, addProduct, setRepresentProduct, deleteProduct, setToast } = ArticleWriteStore;

        const moveToProductSearch = () => {
            if (tempProductList.length < 5) {
                ProductSearchAddStore.setFeedMode(mode === FeedMode.MODIFY ? FeedMode.ARTICLE_MODIFY : FeedMode.ARTICLE_WRITE);
                goTo("/Product/Search", {
                    state: {
                        feedTypeCode: FeedTypeCode.ARTICLE,
                        screenFrom: "article",
                    }
                });
            } else setToast(t("screen.feedProduct.message.max5Count"));
        };

        return (
            <>
                <div style={styles.home_app_wrap}>
                    <Virtuoso
                        style={{ ...styles.home_app_list_wrap }}
                        useWindowScroll
                        data={tempProductList}
                        itemContent={(index: number, item: any) => {
                            return (
                                <div style={styles.home_app_item} key={`Product item ${index}`}>
                                    {!item.product?.openApiFlag && item.product?.productId !== undefined && item.product?.productId > 0 ? (
                                        <div style={styles.info_wrap}>
                                            <FastImageWithFallback
                                                style={styles.home_app_image}
                                                source={{ uri: `${PUBLIC_BUCKET_URL}${item.product?.originalFilePath || ""}` }}
                                            />
                                            <div style={styles.info}>
                                                <p style={{ ...styles.info_text_1, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%' }}>
                                                    [{item.product?.manufacturerName}] {item.product?.productName}
                                                </p>
                                                <p style={styles.info_text_2}>{item.product?.productModelName}</p>
                                                <button
                                                    style={{ ...styles.rep_info, ...(item.isRepresent ? styles.rep_info_active : styles.rep_info_inactive) }}
                                                    onClick={() => setRepresentProduct(index)}
                                                >
                                                    <p
                                                        style={{
                                                            ...styles.rep_info_text,
                                                            ...(item.isRepresent ? styles.rep_info_active_text : styles.rep_info_inactive_text)
                                                        }}
                                                    >
                                                        {t("screen.feedProduct.button.represent")}
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={styles.info_wrap}>
                                            <div style={{ position: 'relative', width: 60, height: 60 }}>
                                                <FastImageWithFallback
                                                    style={styles.home_app_image}
                                                    source={{ uri: `${PUBLIC_BUCKET_URL}${item.product?.originalFilePath || ""}` }}
                                                />
                                                <DimImage style={styles.dim_image} />
                                            </div>
                                            <div style={styles.info}>
                                                <p style={{ ...styles.info_text_1, overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '2', lineClamp: '2', WebkitBoxOrient: 'vertical', whiteSpace: 'pre-wrap' }}>
                                                    [{item.product?.manufacturerName}] {item.product?.productName}
                                                </p>
                                                <p style={styles.info_text_2}>{item.product?.productModelName}</p>
                                                <p style={styles.alert_text}>{t("screen.feedProduct.label.willApprove")}</p>
                                            </div>
                                        </div>
                                    )}

                                    <button onClick={() => deleteProduct(index)}>
                                        <DeleteIcon />
                                    </button>
                                </div>
                            );
                        }}
                    />
                    <button style={styles.add_home_app_button} onClick={moveToProductSearch}>
                        <PlusPurple />
                        <p style={styles.add_home_app_button_text}>{t("screen.post.button.addHa")}</p>
                    </button>
                </div>
            </>
        );
    }
);

export default ArticleWriteProduct;

const styles: Styles = {
    line_thick: {
        ...Colors.background_f5f5f5,
        height: 6,
    },
    request_bar: {
        height: 1,
        ...Spacings.margin_top_19,
        ...Spacings.margin_bottom_20,
        ...Spacings.margin_left_right_16,
        ...Colors.background_eeeeee,
    },
    home_app_wrap: {
        ...Spacings.padding_right_left_16,
    },
    home_app_list_wrap: {
        ...Spacings.margin_bottom_10,
        paddingTop: 5,
    },
    no_home_app_list_wrap: {
        marginBottom: 0,
    },
    home_app_item: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.between,
        ...Arranges.center_h,
        ...Colors.background_fafafa,
        ...Spacings.padding_top_bottom_10,
        ...Spacings.padding_left_10,
        ...Spacings.padding_right_18,
    },
    info_wrap: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...FlexBoxs.flex_1,
    },
    home_app_image: {
        ...FlexBoxs.flex,
        ...FlexBoxs.flex_1,
        width: 60,
        height: 60,
        ...Colors.background_fff,
        ...Spacings.border_radius_6,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: "rgba(34, 34, 34, 0.1)",
    },
    info: {
        ...FlexBoxs.flex,
        ...FlexBoxs.vertical,
        ...Arranges.center_v,
        ...Spacings.margin_left_10,
        ...FlexBoxs.flex_1,
    },
    info_text_1: {
        ...FlexBoxs.flex_1,
        ...Texts.contents_text_31,
        ...Texts.font_weight_500,
        ...Colors.font_222,
    },
    info_text_2: {
        ...FlexBoxs.flex_1,
        ...Texts.contents_text_5,
        ...Colors.font_999,
    },
    rep_info: {
        ...FlexBoxs.flex,
        ...Spacings.border_radius_4,
        ...Arranges.center_v_h,
        width: 46,
        height: 20,
    },
    rep_info_active: {
        ...Colors.background_fce74e,
    },
    rep_info_inactive: {
        ...Colors.background_fff,
    },
    rep_info_text: {
        ...Texts.button_text_9,
        ...Texts.font_weight_500,
    },
    rep_info_active_text: {
        ...Colors.font_222,
    },
    rep_info_inactive_text: {
        ...Colors.font_999,
    },
    add_home_app_button: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_v_h,
        ...Colors.background_f7f4fa,
        ...Spacings.border_radius_4,
        height: 53,
        width: '100%'
    },
    add_home_app_button_text: {
        ...Spacings.margin_left_4,
        ...Texts.button_text_1,
        ...Colors.font_be69c3,
        ...Texts.font_weight_500,
    },
    alert_text: {
        ...Texts.contents_text_5,
        ...Colors.font_c00055,
    },
    dim_image: {
        ...Spacings.border_radius_6,
        position: 'absolute',
        top: 0,
        left: 0
    },
};
