export const validatePhoneNumber = (phoneNumber: string): boolean => {
  const regExp = /^\d{0,11}$/g;

  return regExp.test(phoneNumber);
};

export const validateHypenPhoneNumber = (phoneNumber: string): boolean => {
  const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

  return regPhone.test(phoneNumber);
};

/* eslint-disable */
export const replacePhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length < 13) {
    return phoneNumber
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,3})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");
  } else {
    return phoneNumber
      .replace(/[^0-9]/g, "")
      .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
      .replace(/(\-{1,2})$/g, "");
  }
};

export const validatePhoneNumberWithHyphen = (phoneNumber: string): boolean => {
  const regExp = /^\d{3}-\d{4}-\d{4}$/g;

  return regExp.test(phoneNumber);
};

export const hyphenatePhoneNumber = (phoneNumber: string): string => {
  let hyphenated = deHyphenatePhoneNumber(phoneNumber);

  if (hyphenated.length >= 8) {
    hyphenated = `${hyphenated.substring(0, 3)}-${hyphenated.substring(3, 7)}-${hyphenated.substring(7, 11)}`;
  } else if (hyphenated.length >= 4) {
    hyphenated = `${hyphenated.substring(0, 3)}-${hyphenated.substring(3, 7)}`;
  }

  return hyphenated;
};

export const deHyphenatePhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/-/g, "");
};
