import { callAPIWithGETMethod } from "../common/api";
import { TermsResult, TermsVersion } from "./model";

export const getTermsbyTermsId = async (
  termsId: string,
  isLoading = true
): Promise<TermsResult> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/terms/" + termsId,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as TermsResult;
};

export const getTermsVersions = async (
  termsTypeCode: string,
  isLoading = true
): Promise<TermsVersion[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/terms/versions?termsTypeCode=" + termsTypeCode,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data.termsVersions : null
  ) as TermsVersion[];
};

export const getTermsCompany = async (
  isLoading = true
): Promise<TermsResult> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/terms/company",
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as TermsResult;
};

export const getLastTerms = async (
  isLoading = true
): Promise<TermsResult[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/terms",
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : []
  ) as TermsResult[];
};

export const getLastTermsDetail = async (
  termsTypeCode: string,
  isLoading = true
): Promise<TermsResult> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/terms/last?termsTypeCode=" + termsTypeCode,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as TermsResult;
};
