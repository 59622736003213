export enum TermsTypeCode {
  SERVICE = "SERVICE",
  PRIVACY = "PRIVACY",
  PRIVACY_DETAIL = "PRIVACY_DETAIL",
  COMPANY = "COMPANY",
  AGE = "AGE",
  PROMOTION = "PROMOTION",
  PROMOTION_SMS = "PROMOTION_SMS",
  PROMOTION_EMAIL = "PROMOTION_EMAIL",
  PROMOTION_PUSH = "PROMOTION_PUSH",
  user_age_check = "AGE",
  ECOMMERCE_SERVICE = "ECOMMERCE_SERVICE",
  ECOMMERCE_PRIVACY = "ECOMMERCE_PRIVACY",
  ECOMMERCE_PRIVACY_220 = "220_ECOMMERCE_PRIVACY",
}

export enum KakaoTermTypeCode {
  SERVICE = "SERVICE",
  PRIVACY = "PRIVACY",
  AGE = "user_age_check",
  PROMOTION_SMS = "PROMOTION_SMS",
  PROMOTION_EMAIL = "PROMOTION_EMAIL",
}

export enum TermsLevelCode {
  MANDATORY = "MANDATORY",
  OPTIONAL = "OPTIONAL",
}
