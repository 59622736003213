import { SuccessOrNot } from "../../../constants/Common.enum";
import { callAPIWithGETMethod } from "../../common/api";
import { PaginationResponse } from "../../common/model";
import { CodeResponse, GetFaqResponse, GetFaqsRequest } from "./model";

export const getFaqs = async (
  { faqTypeCode, pageIndex, pageSize }: GetFaqsRequest,
  isLoading = true
): Promise<PaginationResponse<GetFaqResponse>> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/faqs?pageSize=${pageSize}&pageIndex=${pageIndex}&faqTypeCode=${faqTypeCode}`,
    },
    isLoading
  );

  return (response.successOrNot === SuccessOrNot.Y ? response.data : null) as PaginationResponse<GetFaqResponse>;
};

export const getFaqTypeCodes = async (isLoading = true): Promise<CodeResponse[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/code/faq_type_code",
    },
    isLoading
  );

  return (response.successOrNot === SuccessOrNot.Y ? response.data : null) as CodeResponse[];
};
