export enum NotificationTypeCode {
  QA_ANSWER_COMPLETED = "QA_ANSWER_COMPLETED",
  MISSION_RESULT = "MISSION_RESULT",
  MEMBER_JOIN = "MEMBER_JOIN",
  MEMBER_REPORT_MY_COMMENT = "MEMBER_REPORT_MY_COMMENT",
  MEMBER_REPORT_MY_POST = "MEMBER_REPORT_MY_POST",
  HALT_APP_QUALIFICATION = "HALT_APP_QUALIFICATION",
  MEMBER_LIKE_MY_POST = "MEMBER_LIKE_MY_POST",
  MEMBER_LIKE_MY_QNA = "MEMBER_LIKE_MY_QNA",
  MEMBER_LIKE_MY_AB = "MEMBER_LIKE_MY_AB",
  MEMBER_LIKE_MY_COMMENT = "MEMBER_LIKE_MY_COMMENT",
  MEMBER_LIKE_MY_MAGAZINE = "MEMBER_LIKE_MY_MAGAZINE",
  MEMBER_COMMENT_MY_POST = "MEMBER_COMMENT_MY_POST",
  MEMBER_COMMENT_MY_QNA = "MEMBER_COMMENT_MY_QNA",
  MEMBER_COMMENT_MY_AB = "MEMBER_COMMENT_MY_AB",
  MEMBER_COMMENT_MY_MAGAZINE = "MEMBER_COMMENT_MY_MAGAZINE",
  MEMBER_MENTION_ME_POST_COMMENT = "MEMBER_MENTION_ME_POST_COMMENT",
  MEMBER_MENTION_ME_QNA_COMMENT = "MEMBER_MENTION_ME_QNA_COMMENT",
  MEMBER_MENTION_ME_AB_COMMENT = "MEMBER_MENTION_ME_AB_COMMENT",
  MEMBER_MENTION_ME_MAGAZINE_COMMENT = "MEMBER_MENTION_ME_MAGAZINE_COMMENT",
  MEMBER_MENTION_ME_MISSION_COMMENT = "MEMBER_MENTION_ME_MISSION_COMMENT",
  MEMBER_FOLLOW_ME = "MEMBER_FOLLOW_ME",
  PARTICIPATION_POST_BEST_PICK = "PARTICIPATION_POST_BEST_PICK",
  PARTICIPATION_QNA_BEST_PICK = "PARTICIPATION_QNA_BEST_PICK",
  PARTICIPATION_AB_BEST_PICK = "PARTICIPATION_AB_BEST_PICK",
  FOLLOWING_MEMBER_NEW_POST = "FOLLOWING_MEMBER_NEW_POST",
  FOLLOWING_MEMBER_NEW_QNA = "FOLLOWING_MEMBER_NEW_QNA",
  FOLLOWING_MEMBER_NEW_AB = "FOLLOWING_MEMBER_NEW_AB",
  FOLLOWING_MEMBER_NEW_MAGAZINE = "FOLLOWING_MEMBER_NEW_MAGAZINE",
  MEMBER_REQUEST_REVIEW_QNA = "MEMBER_REQUEST_REVIEW_QNA",
  MEMBER_REQUEST_REVIEW_AB = "MEMBER_REQUEST_REVIEW_AB",
  MEMBER_OBTAIN_BADGE = "MEMBER_OBTAIN_BADGE",
  VOLT_SCHEDULED_EXPIRY = "VOLT_SCHEDULED_EXPIRY",
  MEMBER_LEVEL_UPGRADE = "MEMBER_LEVEL_UPGRADE",
  MISSION_COUPON_EXPIRATION = "MISSION_COUPON_EXPIRATION",
  MISSION_END_MY_AB = "MISSION_END_MY_AB",
  POST_TAB = "POST_TAB",
  MALL_TAB = "MALL_TAB",
  HOME_TAB = "HOME_TAB",
  PRODUCT_REJECT = "PRODUCT_REJECT",
  PRODUCT_EXIST = "PRODUCT_EXIST",
  PRODUCT_COMPLETED = "PRODUCT_COMPLETED",
  MISSION_LIST = "MISSION_LIST",
  NON_MEMBER_HOME = "NON_MEMBER_HOME",
  NON_MEMBER_SIGN_UP = "NON_MEMBER_SIGN_UP",
  MISSION_END_PARTICIPANT_AB = "MISSION_END_PARTICIPANT_AB",
}
