import { Styles } from "../../assets/types/Style";
import { useEffect, useState } from "react";
import { Arranges, Colors, Positions, Spacings } from "../../assets/styles";
import "./NotificationScreen.scss";
import { TitleHeader } from "../../components/common";
import { observer } from "mobx-react-lite";
import NotificationStore, {
  NOTIFICATION_TAP_TYPE,
} from "../../store/NotificationStore";
import { HeaderItem } from "../../constants/Header";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
// import PushNotification from "react-native-push-notification";
// import PushNotificationIOS from "@react-native-community/push-notification-ios";
import { useTranslation } from "react-i18next";
import { notificationsAllCheck } from "../../api/notification/api";
import { runInAction } from "mobx";
import NotificationList from "./presenter/NotificationList";

const NotificationScreen = observer(() => {
  const { t } = useTranslation();
  const {
    notifications,
    pullToRefreshNotifications,
    notCheckedCount,
    tabType,
    setTabType,
    setNotifications,
    setNotCheckedCount,
  } = NotificationStore;

  const [headerRightItem, setHeaderRightItem] = useState<
    HeaderItem.CUSTOM | HeaderItem.NONE
  >(notCheckedCount > 0 ? HeaderItem.CUSTOM : HeaderItem.NONE);

  const tabs = [
    {
      name: NOTIFICATION_TAP_TYPE.ALL,
    },
    {
      name: NOTIFICATION_TAP_TYPE.BENEFIT,
    },
  ];

  useEffect(() => {
    const { pullToRefreshNotifications, clear, syncNotCheckedCount } =
      NotificationStore;
    void pullToRefreshNotifications();
    void syncNotCheckedCount();
    return () => {
      clear();
    };
  }, []);

  useEffect(() => {
    if (notCheckedCount > 0) {
      setHeaderRightItem(HeaderItem.CUSTOM);
    } else {
      setHeaderRightItem(HeaderItem.NONE);
    }
  }, [notCheckedCount]);

  const onClickHeaderRight = () => {
    // 알림 모두 읽음 처리
    setNotCheckedCount(0);
    void notificationsAllCheck();
    runInAction(() => {
      setNotifications(
        notifications.map((x) => {
          x.checkedYn = "Y";
          return { ...x };
        })
      );
    });
  };

  const customRightItem = () => {
    return (
      <div>
        <button
          style={{
            ...Spacings.border_radius_100,
            ...Spacings.padding_right_left_12,
            ...Colors.border_color_999,
            ...Arranges.center_v_h,
          }}
          onClick={onClickHeaderRight}
          aria-label="Save"
          className="header_right_btn"
        >
          <text style={{ ...Colors.font_999, fontSize: 12 }}>
            {t("common.header.readAll")}
          </text>
        </button>
      </div>
    );
  };

  return (
    <>
      <div aria-label="Notification" className="notification-screen">
        <div className="header_wrap">
          <TitleHeader
            title={t("screen.notificationScreen.label.title")}
            isBack={true}
            rightItem={headerRightItem}
            customRightItem={customRightItem()}
          />
        </div>

        <div className="tabs">
          {tabs.map((tab: { name: NOTIFICATION_TAP_TYPE }, index) => {
            const isFocused = (x: NOTIFICATION_TAP_TYPE) => {
              return tabType === x;
            };

            const onchangeTab = (tabType: NOTIFICATION_TAP_TYPE) => {
              setTabType(tabType);
              void pullToRefreshNotifications();
            };

            return (
              <div
                key={index}
                className={isFocused(tab.name) ? "tab_active_btn" : "tab_btn"}
                onClick={() => onchangeTab(tab.name)}
              >
                <p
                  className={
                    isFocused(tab.name) ? "tab_active_btn_text" : "tab_text"
                  }
                >
                  {t(
                    `screen.notificationScreen.label.${tab.name.toLowerCase()}`
                  )}
                </p>
              </div>
            );
          })}
        </div>
        <NotificationList />
      </div>
    </>
  );
});

export const styles: Styles = {
  modal_wrap: {
    ...Positions.fullscreen,
    flex: 1,
    ...Colors.background_fff,
  },
};

export default NotificationScreen;
