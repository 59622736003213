import { action, computed, makeObservable, observable } from "mobx";
import { getTagFeedDetailList } from "../api/feed/api";
import { Post } from "../api/feed/model";
import {
  addInterestedTag,
  deleteInterestedTag,
  findTagDetail,
} from "../api/tag/api";
import { Tag, TagDetail, TagFeedItem } from "../api/tag/model";
import { FeedTypeCode } from "../constants/Feed";
import { UserActTypeCode } from "../constants/FeedType.enum";

const INIT_TAG_DETAIL: TagDetail = {
  tagId: -1,
  tagName: "",
  useYn: "",
  count: 0,
  interestedTagYn: "",
  interestedTagList: [],
  productId: -1,
  relatedProductCount: -1,
  relatedProductList: [],
  createDatetime: "",
  updatedDatetime: "",
};

class TagDetailStore {
  constructor() {
    makeObservable(this);
  }
  @observable _tagId = -1;

  @observable _loading = false;
  @observable _feedLoading = false;

  @observable _tagDetail: TagDetail = INIT_TAG_DETAIL;
  @observable _postList: TagFeedItem[] = [];
  @observable _qnaList: TagFeedItem[] = [];
  @observable _abList: TagFeedItem[] = [];

  @observable _postPageIndex = 0;
  @observable _qnaPageIndex = 0;
  @observable _abPageIndex = 0;

  @observable _postCanLoadMore = true;
  @observable _qnaCanLoadMore = true;
  @observable _abCanLoadMore = true;

  @observable _postScrollPosition = 0;
  @observable _qnaScrollPosition = 0;
  @observable _abScrollPosition = 0;

  @observable errorMessage?: string;
  @observable showReportPopup = false;

  @observable _feedTypeCode: FeedTypeCode = FeedTypeCode.POST;


  @computed get postList() {
    return this._postList;
  }

  @computed get qnaList() {
    return this._qnaList;
  }

  @computed get abList() {
    return this._abList;
  }

  @computed get postPageIndex() {
    return this._postPageIndex;
  }

  @computed get qnaPageIndex() {
    return this._qnaPageIndex;
  }

  @computed get abPageIndex() {
    return this._abPageIndex;
  }

  @computed get postCanLoadMore() {
    return this._postCanLoadMore;
  }
  @computed get qnaCanLoadMore() {
    return this._qnaCanLoadMore;
  }

  @computed get abCanLoadMore() {
    return this._abCanLoadMore;
  }

  @computed get tagId() {
    return this._tagId;
  }

  @computed get tagDetail() {
    return this._tagDetail;
  }

  @computed get loading() {
    return this._loading;
  }

  @computed get feedLoading() {
    return this._feedLoading;
  }

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    );
  }

  @computed get isShowReport() {
    return this.showReportPopup;
  }

  @computed get feedTypeCode() {
    return this._feedTypeCode;
  }

  @action getScrollValue = () => {
    switch (this._feedTypeCode) {
      case FeedTypeCode.POST:
        return this._postScrollPosition;
      case FeedTypeCode.QNA:
        return this._qnaScrollPosition;
      case FeedTypeCode.AB:
        return this._abScrollPosition;
      default:
        return 0;
    }
  };

  @action setTagId = (tagId: number) => {
    this._tagId = tagId;
  };

  @action setTagDetail = (tagDetail: TagDetail) => {
    this._tagDetail = tagDetail;
  };

  @action setPostList = (list: TagFeedItem[]) => {
    this._postList = list;
  };

  @action setQnaList = (list: TagFeedItem[]) => {
    this._qnaList = list;
  };

  @action setAbList = (list: TagFeedItem[]) => {
    this._abList = list;
  };

  @action addPostList = (list: TagFeedItem[]) => {
    this._postList = [...this._postList, ...list];
  };

  @action addQnaList = (list: TagFeedItem[]) => {
    this._qnaList = [...this._qnaList, ...list];
  };

  @action addAbList = (list: TagFeedItem[]) => {
    this._abList = [...this._abList, ...list];
  };

  @action addList = (list: TagFeedItem[], feedType: FeedTypeCode) => {
    switch (feedType) {
      case FeedTypeCode.POST:
        this.addPostList(list);
        break;
      case FeedTypeCode.QNA:
        this.addQnaList(list);
        break;
      case FeedTypeCode.AB:
        this.addAbList(list);
        break;
    }
  };

  @action setPostPageIndex = (index: number) => {
    this._postPageIndex = index;
  };

  @action setQnaPageIndex = (index: number) => {
    this._qnaPageIndex = index;
  };

  @action setAbPageIndex = (index: number) => {
    this._abPageIndex = index;
  };

  @action setPostCanLoadMore = (canLoadMore: boolean) => {
    this._postCanLoadMore = canLoadMore;
  };

  @action setQnaCanLoadMore = (canLoadMore: boolean) => {
    this._qnaCanLoadMore = canLoadMore;
  };

  @action setAbCanLoadMore = (canLoadMore: boolean) => {
    this._abCanLoadMore = canLoadMore;
  };

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action setFeedLoading = (isLoading: boolean) => {
    this._feedLoading = isLoading;
  };

  @action setPageIndex = (start: number, feedType: FeedTypeCode) => {
    switch (feedType) {
      case FeedTypeCode.POST:
        this.setPostPageIndex(start);
        break;
      case FeedTypeCode.QNA:
        this.setQnaPageIndex(start);
        break;
      case FeedTypeCode.AB:
        this.setAbPageIndex(start);
        break;
    }
  };

  @action setCanLoadMore = (canLoadMore: boolean, feedType: FeedTypeCode) => {
    switch (feedType) {
      case FeedTypeCode.POST:
        this.setPostCanLoadMore(canLoadMore);
        break;
      case FeedTypeCode.QNA:
        this.setQnaCanLoadMore(canLoadMore);
        break;
      case FeedTypeCode.AB:
        this.setAbCanLoadMore(canLoadMore);
        break;
    }
  };

  @action getTagDetail = async (tagId: number) => {
    this.setLoading(true);

    const res = await findTagDetail(tagId);

    this.setTagDetail(res);

    this.setLoading(false);
  };

  @action getFeedList = async (
    start: number,
    type: FeedTypeCode,
    tagId: number
  ) => {
    this.setFeedLoading(true);

    const pageSize = 12;

    if (start == 0) {
      if (type === FeedTypeCode.POST) {
        this.setPostList([]);
      } else if (type === FeedTypeCode.QNA) {
        this.setQnaList([]);
      } else if (type === FeedTypeCode.AB) {
        this.setAbList([]);
      }
    }

    const response = await getTagFeedDetailList(type, pageSize, start, tagId);

    if (response.data?.list.length < pageSize) {
      this.setCanLoadMore(false, type);
    }

    this.setPageIndex(start, type);
    response.data?.list &&
      this.addList(response.data?.list as TagFeedItem[], type);
    this.setFeedLoading(false);
  };

  @action getMoreFeedList = async (type: FeedTypeCode, tagId: number) => {
    this.setFeedLoading(true);

    const pageSize = 12;

    const pageIndex =
      type === FeedTypeCode.POST
        ? this.postPageIndex
        : type === FeedTypeCode.QNA
        ? this.qnaPageIndex
        : this.abPageIndex;

    const response = await getTagFeedDetailList(
      type,
      pageSize,
      pageIndex + 1,
      tagId
    );

    if (response.data?.list.length < pageSize) {
      this.setCanLoadMore(false, type);
    }

    this.setPageIndex(pageIndex + 1, type);
    response.data?.list &&
      this.addList(response.data.list as TagFeedItem[], type);
    this.setFeedLoading(false);
  };

  @action getFeedFromList = (type: FeedTypeCode, feedId: number) => {
    if (type === FeedTypeCode.POST) {
      return this.postList.filter((x) => x.feedId === feedId)[0];
    } else if (type === FeedTypeCode.QNA) {
      return this.qnaList.filter((x) => x.feedId === feedId)[0];
    } else if (type === FeedTypeCode.AB) {
      return this.abList.filter((x) => x.feedId === feedId)[0];
    }
  };

  @action removeFeedFromList = (type: FeedTypeCode, feedId: number) => {
    if (type === FeedTypeCode.POST) {
      this._postList = this._postList.filter((x) => x.feedId !== feedId);
    } else if (type === FeedTypeCode.QNA) {
      this._qnaList = this._qnaList.filter((x) => x.feedId !== feedId);
    } else if (type === FeedTypeCode.AB) {
      this._abList = this._abList.filter((x) => x.feedId != feedId);
    }
  };

  @action updatePost = (feedId: number, post: TagFeedItem) => {
    const index = this._postList.findIndex((x) => x.feedId == feedId);

    if (index != -1) {
      this._postList.splice(index, 1, post);
    }
  };

  @action updateLikeAndBookmark = (
    feedId: number,
    actionType: UserActTypeCode
  ) => {
    const index = this._postList.findIndex((x) => x.feedId == feedId);

    if (index != -1) {
      const post = this._postList[index];
      const newPost = { ...post };

      switch (actionType) {
        case UserActTypeCode.SAVE_LIKE:
          newPost.likeYn = "Y";
          newPost.likeCount = (newPost.likeCount ?? 0) + 1;

          break;
        case UserActTypeCode.CANCEL_LIKE:
          newPost.likeYn = "N";
          newPost.likeCount = (newPost.likeCount ?? 1) - 1;

          break;
        case UserActTypeCode.SAVE_BOOKMARK:
          newPost.bookmarkYn = "Y";

          break;
        case UserActTypeCode.CANCEL_BOOKMARK:
          newPost.bookmarkYn = "N";

          break;
      }
      this._postList.splice(index, 1, newPost);
    }
  };

  @action updateCommentCount = (feedId: number, data: Post) => {
    const index = this._postList.findIndex((x) => x.feedId === feedId);

    if (index != -1) {
      const feed = this._postList[index];
      feed.commentCount = data.commentCount;

      this._postList.splice(index, 1, feed);
    }
  };

  @action updateQna = (feedId: number, qna: any) => {
    const index = this._qnaList.findIndex((x) => x.feedId === feedId);

    if (index != -1) {
      const feed = this._qnaList[index];
      feed.feedTitle = qna.feedTitle;
      feed.thumbnailFilePath = qna.thumbnailFilePath;
      feed.originalFilePath = qna.filePath;
      feed.commentCount = qna.commentCount;

      this._qnaList.splice(index, 1, feed);
    }
  };

  @action updateAb = (feedId: number, ab: any) => {
    const abIdx = this._abList.findIndex((x) => x.feedId === feedId);

    if (abIdx !== -1) {
      const abDetail = this._abList[abIdx];

      abDetail.feedTitle = ab.feedTitle;
      abDetail.adesc = ab.adesc;
      abDetail.bdesc = ab.bdesc;
      abDetail.athumbnailFilePath = ab.athumbnailFilePath;
      abDetail.bthumbnailFilePath = ab.bthumbnailFilePath;
      abDetail.myAbFlag = ab.myAbFlag;

      this._abList.splice(abIdx, 1, abDetail);
    }
  };

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action clearList = () => {
    this.setPostList([]);
    this.setQnaList([]);
    this.setAbList([]);
    this.setPostPageIndex(0);
    this.setQnaPageIndex(0);
    this.setAbPageIndex(0);
  };

  @action clearData = () => {
    this.clearList();
    this.setScrollValue(0);
    this.setFeedTypeCode(FeedTypeCode.POST);
  }

  @action clear = () => {
    this.errorMessage = "";
    this.showReportPopup = false;

    this._tagId = -1;
    this._tagDetail = INIT_TAG_DETAIL;
    this._loading = false;
    this._feedLoading = false;

    this._postList = [];
    this._qnaList = [];
    this._abList = [];

    this._postPageIndex = 0;
    this._qnaPageIndex = 0;
    this._abPageIndex = 0;

    this._postCanLoadMore = true;
    this._qnaCanLoadMore = true;
    this._abCanLoadMore = true;
  };

  @action removeFeed = (feedId: number, feedType: FeedTypeCode) => {
    feedId = Number(feedId);

    switch (feedType) {
      case FeedTypeCode.POST: {
        const postIndex = this._postList.findIndex((x) => x.feedId === feedId);

        if (postIndex !== -1) {
          this._postList = this._postList.filter((x) => x.feedId !== feedId);
          this._tagDetail.count -= 1;
        }
        break;
      }
      case FeedTypeCode.QNA: {
        const qnaIndex = this._qnaList.findIndex((x) => x.feedId === feedId);

        if (qnaIndex !== -1) {
          this._qnaList = this._qnaList.filter((x) => x.feedId !== feedId);
          this._tagDetail.count -= 1;
        }
        break;
      }
      case FeedTypeCode.AB: {
        const abIndex = this._abList.findIndex((x) => x.feedId === feedId);

        if (abIndex !== -1) {
          this._abList = this._abList.filter((x) => x.feedId !== feedId);
          this._tagDetail.count -= 1;
        }
        break;
      }
    }
  };

  @action addInterestedTag = async (memberUuid: string, tag: Tag) => {
    if (this._tagDetail.interestedTagList.length > 20) {
      // 20개 초과
      return;
    }

    await addInterestedTag(memberUuid, tag.tagId, tag.tagName);

    this.addInterestedTagList(tag);
  };

  @action removeInterestedTag = async (memberUuid: string, tagId: number) => {
    const index = this._tagDetail.interestedTagList.findIndex(
      (x) => x.tagId === tagId
    );

    if (index != -1) {
      await deleteInterestedTag(memberUuid, tagId);

      this.removeInterestedTagList(tagId);
    }
  };

  @action addInterestedTagList = (tag: Tag) => {
    this._tagDetail.interestedTagList.push(tag);
    this._tagDetail.interestedTagYn = "Y";
  };

  @action removeInterestedTagList = (tagId: number) => {
    const index = this._tagDetail.interestedTagList.findIndex(
      (x) => x.tagId === tagId
    );
    this._tagDetail.interestedTagList.splice(index, 1);
    this._tagDetail.interestedTagYn = "N";
  };

  @action setFeedTypeCode = (feedTypeCode: FeedTypeCode) => {
    this._feedTypeCode = feedTypeCode;
  };

  @action setScrollValue = (scrollPosition: number) => {
    switch (this._feedTypeCode) {
      case FeedTypeCode.POST:
        this._postScrollPosition = scrollPosition;
        break;
      case FeedTypeCode.QNA:
        this._qnaScrollPosition = scrollPosition;
        break;
      case FeedTypeCode.AB:
        this._abScrollPosition = scrollPosition;
        break;
      default:
        break;
    }
  };
}

export default TagDetailStore;
