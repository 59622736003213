import React, { useEffect, useState } from "react";
import { Spacings } from "../../assets/styles";
import CheckboxOffIcon from "../../assets/icons/checkbox_off.svg";
import CheckboxOnIcon from "../../assets/icons/checkbox_on.svg";
import ArrowRightIcon from "../../assets/icons/arrow_right_gray.svg";
import TermsStore from "../../store/TermsStore";
import { observer } from "mobx-react";
import { TermsResult } from "../../api/terms/model";
import { TermsLevelCode, TermsTypeCode } from "../../constants/Terms.enum";
import classes from "./TermsAgree.module.scss";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import TermsOfUseModal, { TermsDetailProps } from "../modals/TermsOfUseModal";

export interface TermsAgreeInterface {
  isMarginTop?: boolean;
}

const TermsAgree = observer(
  ({ isMarginTop }: TermsAgreeInterface): JSX.Element => {
    const {
      initLastTerms,
      lastTerms,
      termsAgree,
      pushTermsAgree,
      clearTermsAgree,
      toggleTermsAgreeYn,
      togglePromotionTermsAgreeYn,
      toggleAllterms,
    } = TermsStore;

    const { t } = useTranslation();
    const defaultTermsDetailProps = {
      show: false,
      termsId: "",
      termsTypeCode: "",
      onClose: () => {},
    };
    const [termsDetailProps, setTermsDetailProps] = useState<TermsDetailProps>(
      defaultTermsDetailProps
    );

    useEffect(() => {
      if (lastTerms) {
        clearTermsAgree();
        lastTerms.forEach(
          ({ termsId, termsTypeCode, termsTitle, termsLevelCode }) => {
            pushTermsAgree({
              termsId,
              termsTypeCode,
              termsTitle,
              termsLevelCode,
              termsAgreeYn: false,
            });
          }
        );
      }
    }, [clearTermsAgree, lastTerms, pushTermsAgree]);

    useEffect(() => {
      void initLastTerms();
    }, [initLastTerms]);

    const isAllTermsAgree =
      termsAgree &&
      termsAgree.length > 0 &&
      termsAgree.every(({ termsAgreeYn }) => termsAgreeYn);

    const isRequiredTerm = (termsLevelCode: string) =>
      termsLevelCode === TermsLevelCode.MANDATORY;

    const isTermsAgreeYn = (termsId: string) =>
      termsAgree.find((term) => term.termsId === termsId)?.termsAgreeYn;

    const renderTerm = (term: TermsResult) => {
      return (
        <div
          className={classes.ck_touch_wrap}
          key={
            term.termsTypeCode === TermsTypeCode.AGE
              ? term.termsId
              : `detail_${term.termsId}`
          }
        >
          <button
            className={classes.ck_touch}
            onClick={() => toggleTermsAgreeYn(term.termsId)}
            aria-label={term.termsTypeCode}
            key={term.termsId}
          >
            {isTermsAgreeYn(term.termsId) ? (
              <img src={CheckboxOnIcon} alt="CheckboxOnIcon" />
            ) : (
              <img src={CheckboxOffIcon} alt="CheckboxOffIcon" />
            )}
            <span className={clsx([classes.ck_txt_mm, classes.ck_txt])}>
              {term.termsTitle}
              <span
                className={
                  isRequiredTerm(term.termsLevelCode)
                    ? classes.required
                    : classes.options
                }
              >
                (
                {t(
                  `screen.join.termsAgree.${
                    isRequiredTerm(term.termsLevelCode) ? "required" : "choice"
                  }`
                )}
                )
              </span>
            </span>
          </button>
          {/* {renderTerm(term)} */}
          {term.termsTypeCode !== TermsTypeCode.AGE ? (
            <button
              className={classes.view_btn}
              onClick={() => {
                setTermsDetailProps({
                  show: true,
                  termsId: term.termsId,
                  termsTypeCode: term.termsTypeCode,
                  onClose: () => {
                    setTermsDetailProps(defaultTermsDetailProps);
                  },
                });
              }}
              aria-label={term.termsTypeCode}
            >
              <img src={ArrowRightIcon} alt="" />
            </button>
          ) : (
            <></>
          )}
        </div>
      );
    };

    const renderPromotionDetailTerm = React.useMemo(() => {
      const term = lastTerms.find(
        (term) => term.termsTypeCode === TermsTypeCode.PROMOTION_EMAIL
      ) as TermsResult;
      if (!term) return;

      return (
        <button
          className={classes.view_btn}
          onClick={() => {
            setTermsDetailProps({
              show: true,
              termsId: term.termsId,
              termsTypeCode: term.termsTypeCode,
              onClose: () => {
                setTermsDetailProps(defaultTermsDetailProps);
              },
            });
          }}
          aria-label={term.termsTypeCode}
        >
          <img src={ArrowRightIcon} alt="ArrowRightIcon" />
        </button>
      );
    }, [lastTerms]);

    const renderPromotionTerm = (terms: TermsResult[]) => {
      return (
        <div className={classes.ck_touch_wrap}>
          <button
            className={classes.ck_touch}
            onClick={() => togglePromotionTermsAgreeYn()}
            aria-label={"Promotion Agree"}
          >
            {termsAgree.some(
              ({ termsTypeCode, termsAgreeYn }) =>
                termsTypeCode.includes(TermsTypeCode.PROMOTION) && termsAgreeYn
            ) ? (
              <img src={CheckboxOnIcon} alt="CheckboxOnIcon" />
            ) : (
              <img src={CheckboxOffIcon} alt="CheckboxOffIcon" />
            )}
            <span className={clsx([classes.ck_txt_mm, classes.ck_txt])}>
              {
                terms.find(
                  (term) => term.termsTypeCode === TermsTypeCode.PROMOTION_EMAIL
                )?.termsTitle
              }
              <span className={classes.required}>
                ({t("screen.join.termsAgree.choice")})
              </span>
            </span>
          </button>
          {renderPromotionDetailTerm}
        </div>
      );
    };

    const renderTermList = (terms: TermsResult[]) => {
      return (
        <div className={classes.ck_box}>
          {terms.map((term) => {
            switch (term.termsTypeCode) {
              case TermsTypeCode.AGE:
              case TermsTypeCode.SERVICE:
              case TermsTypeCode.PRIVACY:
                return renderTerm(term);
            }
          })}
          {renderPromotionTerm(terms)}
        </div>
      );
    };

    return (
      <>
        <div
          className={classes.all_ck_box}
          style={isMarginTop ? Spacings.margin_top_16 : {}}
          aria-label={"Terms Agree"}
        >
          <button
            className={classes.all_ck}
            onClick={() => toggleAllterms()}
            aria-label={"All Terms Agree"}
          >
            {isAllTermsAgree ? (
              <img src={CheckboxOnIcon} alt="CheckboxOnIcon" />
            ) : (
              <img src={CheckboxOffIcon} alt="CheckboxOffIcon" />
            )}
            <span className={clsx([classes.all_ck_txt, classes.ck_txt_mm])}>
              {t("screen.join.termsAgree.allTermsAgree")}
            </span>
          </button>
        </div>
        {renderTermList(lastTerms)}
        <TermsOfUseModal termsDetailProps={termsDetailProps} />
      </>
    );
  }
);

export default TermsAgree;
