import React from "react";
import "./AbMyChoice.scss";

import { AbTypeCode } from "../../../constants/ab";
import AuthStore from "../../../store/AuthStore";
import { useTranslation } from "react-i18next";

const AbMyChoice = ({ choice }: { choice: AbTypeCode }) => {
  const sessionUser = AuthStore.sessionUser;
  const { t } = useTranslation();

  return (
    <div className="wrapper">
      <p className="text">
        {t("screen.ab.message.myChoice", {
          nickname: sessionUser?.nickname || "",
        })}
        <span className="choice_text">{choice}</span>
      </p>
    </div>
  );
};

export default AbMyChoice;
