import React, { useState } from "react";
import { ReactComponent as HeartOffIcon } from "../../../assets/icons/heart_off.svg";
import { ReactComponent as HeartOnIcon } from "../../../assets/icons/heart_on.svg";
import { ReactComponent as ChatIcon } from "../../../assets/icons/chat.svg";
import { ReactComponent as BookmarkOffIcon } from "../../../assets/icons/bookmark_off.svg";
import { ReactComponent as BookmarkOnIcon } from "../../../assets/icons/bookmark_on.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { getDateStr } from "../../../utils/datetime";
import LikeStore from "../../../store/LikeStore";
import MainStore from "../../../store/MainStore";
import { UserActTypeCode } from "../../../constants/FeedType.enum";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { LikeTypeCode } from "../../../constants/Like";
import { CommentTypeCode } from "../../../constants/Comment";
import { getMagazineByFeedId } from "../../../api/magazine/api";
// import { onShare, onShareWithBranch, ShareInfo } from "../../../utils/share";
import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
// import { useTracker } from "../../../hooks/tracker";
import { FeedTypeCode } from "../../../constants/Feed";
import { useTranslation } from "react-i18next";
import "./MainMagazine.scss";
import CommonStore from "../../../store/CommonStore";
import AuthStore from "../../../store/AuthStore";
import CommentStore from "../../../store/CommentStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { ShareInfo, onShare } from "../../../utils/share";
import { track } from "../../../hooks/tracker/TrackFunction";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";

export const MainMagazine = ({
  magazine,
  // selfWidth,
  mainListIndex,
  isMainSection = false,
}: {
  magazine: any;
  // selfWidth: number;
  mainListIndex: number;
  isMainSection?: boolean;
}) => {
  const { t } = useTranslation();

  const { saveLike, cancelLike } = LikeStore;
  const { updateMainMagazines } = MainStore;
  const [showFullText, setShowFullText] = useState<boolean>(false);
  // const { track } = useTracker();
  // const config = useRemoteConfig();

  const nickname: string = magazine?.nickname;
  const feedStartDate: string = magazine?.feedStartDate;
  const likeCount: string = magazine?.likeCount;
  const commentCount: string = magazine?.commentCount;
  const feedTitle: string = magazine?.feedTitle;
  const previewContents: string = magazine?.previewContents;
  const feedId: number = magazine?.feedId;
  const likeYn: string = magazine?.likeYn;
  const thumbnailFilePath: string = magazine?.fileList[0]?.thumbnailFilePath;
  const originalFilePath: string = magazine?.fileList[0]?.originalFilePath;
  const profileUrl: string = magazine?.profileUrl;
  const profileBackgroundColor: string = magazine?.profileBackgroundColor;

  const POST_INDEX = 0;
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const thumbnailUrl: string = thumbnailFilePath
    ? `${PUBLIC_BUCKET_URL}${thumbnailFilePath}`
    : "";
  const originalUrl: string = thumbnailFilePath
    ? `${PUBLIC_BUCKET_URL}${originalFilePath}`
    : "";

  const onLikeButtonPress = () => {
    if (likeYn === "Y") {
      void cancelLike(
        feedId,
        LikeTypeCode.MAGAZINE,
        () => {
          // update
          void updateMainMagazines(
            UserActTypeCode.CANCEL_LIKE,
            POST_INDEX,
            mainListIndex
          );
        },
        () => {
          // fail
        }
      );
    } else {
      void saveLike(
        feedId,
        LikeTypeCode.MAGAZINE,
        () => {
          // update
          void updateMainMagazines(
            UserActTypeCode.SAVE_LIKE,
            POST_INDEX,
            mainListIndex
          );
          track("click_like_button", {
            content_id: feedId,
            content_title: feedTitle,
            content_type: LikeTypeCode.MAGAZINE,
            like_count: likeCount ? likeCount : 0,
            comment_count: commentCount ? commentCount : 0,
          });
        },
        () => {
          // fail
        }
      );
    }
  };

  const onLikeListButtonPress = () => {
    goTo("/LikeScreen", {
      state: {
        feedId: feedId ?? 0,
        feedType: CommentTypeCode.MAGAZINE,
      },
    });
  };

  const onCommentButtonPress = async (feedId: number) => {
    goTo(`/${CommentTypeCode.MAGAZINE}/${feedId}/comment`, {
      state: {
        feedId: feedId ?? 0,
        feedType: CommentTypeCode.MAGAZINE,
        feedAuthorId: magazine.createMemberUuid,
        postIndex: POST_INDEX,
      },
    });
  };

  const onMoveToMagazineDetailButtonPress = (feedId: number) => {
    goTo(`/magazine/${feedId}`);
  };

  return (
    <div id="MainMagazine">
      <div>
        <div className="magazine_header">
          <div
            onClick={() => {
              goTo(`/user/${nickname}`, {
                state: {
                  targetUserId: magazine.createMemberUuid,
                  nickname: nickname,
                  profileUrl: profileUrl,
                  profileBackgroundColor: profileBackgroundColor,
                },
              });
            }}
          >
            <div className="magazine_header_left">
              <FastImageWithFallback
                className="user_img"
                source={{ uri: PUBLIC_BUCKET_URL + profileUrl }}
              />
              <div className="info_wrap">
                <div className="info_text1">{nickname}</div>
                <div className="info_text2">{getDateStr(feedStartDate)}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="magazine_body">
          {/* <div className="image_wrap" collapsable={false}> */}
          <div className="image_wrap">
            <div
              // accessibilityLabel="go to magazine"
              onClick={() => onMoveToMagazineDetailButtonPress(feedId)}
              className="image_wrap_inner"
            >
              <FastImageWithFallback
                // className="[
                //   magazine_image,
                //   { width: selfWidth, height: selfWidth, borderRadius: 6 },
                // ]""
                className="magazine_image"
                source={{ uri: thumbnailUrl }}
              />
            </div>
          </div>

          <div className="action_wrap">
            <div className="action_left">
              <div
                onClick={onLikeButtonPress}
                // accessibilityLabel="Like post"
                className="action_icon_btn"
              >
                <TouchableWithAuthCheck onPress={onLikeButtonPress}>
                  {likeYn === "Y" ? <HeartOnIcon /> : <HeartOffIcon />}
                </TouchableWithAuthCheck>
              </div>
              <div
                className="action_icon_btn"
                onClick={() => {
                  onCommentButtonPress(feedId);
                }}
              >
                <ChatIcon />
              </div>
              {/* <div className="action_icon_btn} onClick={onOpenToast}>
                <BookmarkOffIcon />
              </div> */}
              <div
                className="action_icon_btn"
                onClick={() => {
                  const shareInfo: ShareInfo = {
                    title: feedTitle,
                    descriptionText: previewContents,
                    imageUrl: thumbnailFilePath,
                    screen: ShareScreenCode.MAGAZINE_DETAIL_SCREEN,
                    targetId: feedId,
                    path: `magazine/${feedId}`,
                  };
                  void onShare(shareInfo);

                  track("click_share_button", {
                    content_id: feedId,
                    content_title: feedTitle,
                    content_type: LikeTypeCode.MAGAZINE,
                    like_count: likeCount ? likeCount : 0,
                    comment_count: commentCount ? commentCount : 0,
                  });
                }}
              >
                <ShareIcon />
              </div>
            </div>
            <div className="action_right">
              <div onClick={onLikeListButtonPress}>
                <div className="action_text like_text">
                  {t(`screen.magazine.label.like`)}{" "}
                  <div className="number_text">{likeCount}</div>
                </div>
              </div>
              <div
                onClick={() => {
                  onCommentButtonPress(feedId);
                }}
              >
                <div className="action_text">
                  {t(`screen.magazine.label.comment`)}{" "}
                  <div className="number_text">{commentCount}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="title_text_magazine">{feedTitle}</div>

          <div className="contents_wrap">
            {showFullText ? (
              <div
                className="contents_text"
                // numberOfLines={0}
                // ellipsizeMode={"tail"}
              >
                {previewContents}
              </div>
            ) : (
              <div
                onClick={() => {
                  setShowFullText(true);
                }}
              >
                <div
                  className="contents_text"
                  // numberOfLines={2}
                  // ellipsizeMode={"tail"}
                >
                  {previewContents && previewContents != null
                    ? previewContents
                        .replace(/(?:\r\n|\r|\n)/g, "")
                        .substring(0, 44)
                        .concat("...")
                    : ""}
                </div>
              </div>
            )}
            <div
              className="more_text"
              style={{ display: !showFullText ? "flex" : "none" }}
              onClick={() => {
                setShowFullText(true);
              }}
            >
              <div
                className="more_text"
                style={{ display: !showFullText ? "flex" : "none" }}
              >
                {"more"}
              </div>
            </div>
          </div>
        </div>
        <div className="magazine_detail_btn_wrap">
          <div
            // accessibilityLabel="Move to detailed post"
            className="magazine_detail_btn"
            onClick={() => onMoveToMagazineDetailButtonPress(feedId)}
          >
            <div className="magazine_detail_btn_text">
              {t("screen.magazine.magazineMore")}
            </div>
          </div>
        </div>
      </div>

      {isMainSection && (
        <div className="MainSectionFooter" style={{ height: "60px" }} />
      )}
    </div>
  );
};

export default MainMagazine;
