import classes from "./Toast.module.scss";
import { useEffect, useState } from "react";
import CommonStore from "../../store/CommonStore";
import clsx from "clsx";
import { observer } from "mobx-react";

const Toast = observer(() => {
  const [toastAnimation, setToastAnimation] = useState<any>(
    classes.openAnimation
  );

  useEffect(() => {
    let timer2: NodeJS.Timeout | undefined;
    let timer = setTimeout(() => {
      setToastAnimation(classes.closeAnimation);
      timer2 = setTimeout(() => {
        CommonStore.clearToastOption();
        setToastAnimation(classes.openAnimation);
      }, 500);
    }, 2000);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  const onClickToast = () => {
    CommonStore.toastOption.onPress && CommonStore.toastOption.onPress();
  };

  return (
    <div
      id="toast_alert_system"
      className={clsx(classes.toast_alert, toastAnimation)}
      onClick={onClickToast}
    >
      <div className={classes.toast_div}>
        <div>{CommonStore.toastOption.message1}</div>
        {CommonStore.toastOption.message2 && (
          <div>{CommonStore.toastOption.message2}</div>
        )}
      </div>
    </div>
  );
});

export default Toast;
