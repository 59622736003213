import Modal from "react-modal";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { NOTICE_BANNER } from "../../../constants/Storage";
import MainStore from "../../../store/MainStore";
import "./MainTextBanner.scss";

const MainTextBanner = observer(
  ({
    onModalHide,
    onCloseAction,
  }: {
    onModalHide: () => void | undefined;
    onCloseAction: () => void;
  }) => {
    const { textBanner, showTextBannerModal, setShowTextBannerModal } =
      MainStore;
    const { t } = useTranslation();

    const notShowToday = async () => {
      const today = dayjs().format("YYYYMMDD");
      const noticeItemStr = JSON.stringify({ yn: "N", lastDay: today });
      await localStorage.setItem(NOTICE_BANNER, noticeItemStr);
      onClose();
    };

    const onClose = () => {
      setShowTextBannerModal(false);
      onCloseAction();
    };

    return (
      <Modal
        isOpen={showTextBannerModal}
        // accessibilityLabel="notice popup"
        id="MainTextBanner"
        style={{
          content: {
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            maxWidth: "450px",
            width: "100%",
            border: "none",
            padding: "0",
            backgroundColor: "transparent",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 10000,
            maxWidth: 450,
            margin: "auto",
          },
        }}
        onRequestClose={() => {
          onModalHide && onModalHide()
        }}
      >
        <div className="banner_modal">
          <div className="header">
            <div className="header_text">{textBanner?.bannerTitle}</div>
          </div>

          <div className="body">
            {/* <RenderHTML
              contentWidth={width}
              source={{ html: textBanner?.bannerDetail || "" }}
              defaultTextProps={{ allowFontScaling: false }}
            /> */}

            <div
              dangerouslySetInnerHTML={{
                __html: textBanner?.bannerDetail || "",
              }}
            />
          </div>

          <div className="footer">
            <div
              onClick={() => {
                void notShowToday()
              }}
              // accessibilityLabel="not show today"
            >
              <div className="footer_text1">
                {t("screen.main.button.notShowToday")}
              </div>
            </div>
            <div
              onClick={onClose}
              // accessibilityLabel="close"
            >
              <div className="footer_text2">
                {t("screen.main.button.close")}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
);

export default MainTextBanner;
