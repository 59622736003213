import classes from "./PreferenceHeader.module.scss";

export interface PreferenceHeaderProps {
  totalStep: number;
  currentStep: number;
  title1: string;
  title2?: string;
  maxCount?: number;
}

const PreferenceHeader = ({
  totalStep,
  currentStep,
  title1,
  title2,
  maxCount,
}: PreferenceHeaderProps) => {
  return (
    <div className={classes.header}>
      {currentStep !== totalStep && (
        <div
          className={classes.page_number_text}
          aria-label={`step number label ${currentStep}`}
        >
          {`${currentStep}/${totalStep}`}
        </div>
      )}

      <div className={classes.header_wrap} aria-label={`title`}>
        <div className={classes.header_title_wrap}>
          <div className={classes.header_title}>{title1}</div>
          {title2 && <div className={classes.header_title}>{title2}</div>}
        </div>
        {maxCount && (
          <div className={classes.max_text}>{`최대 ${maxCount}개`}</div>
        )}
      </div>
    </div>
  );
};

export default PreferenceHeader;
