import { MemberStateCode, StatusCode } from "../constants/Common.enum";

export const isNotNormalMember = (
  statusCode: MemberStateCode | StatusCode | undefined
) => {
  if (statusCode) {
    const notNormalMemberStatusCode = [
      StatusCode.DORMANT,
      StatusCode.HALT,
      StatusCode.WITHDRAWAL,
      StatusCode.FORCED_WITHDRAWAL,
      MemberStateCode.HALT,
    ];

    return notNormalMemberStatusCode.includes(statusCode);
  } else {
    return false;
  }
};
