import { callAPIWithGETMethod } from "../common/api";
import { BannerInfo, BannerLinkInfo } from "./model";
import AuthStore from "../../store/AuthStore";
// import remoteConfig from "@react-native-firebase/remote-config";
import { getValue } from "firebase/remote-config";
import { BannerTypeCodes } from "../../constants/Banner.enum";
import { queryParamFromBannerLinkInfo } from "../../utils/queryParam";
import MemberStore from "../../store/MemberStore";

export const getBannerList = async (
  bannerTypeCodes: string,
  isLoading = true
): Promise<Array<BannerInfo>> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/banners",
      params: {
        bannerTypeCodes,
      },
    },
    isLoading
  );

  const res = (
    response.successOrNot === "Y" ? response.data : null
  ) as Array<BannerInfo>;

  // 임시코드
  if (bannerTypeCodes === BannerTypeCodes.MAIN_POP_UP_BANNER) {
    const cpaBannerInfo = getCpaBannerInfo();
    const welcomeBannerInfo = getWelcomeBannerInfo();
    if (welcomeBannerInfo) {
      res.unshift(welcomeBannerInfo);
    }
    if (cpaBannerInfo) {
      res.unshift(cpaBannerInfo);
    }
  }

  return res;
};

export const getSplash = async () => {
  return await callAPIWithGETMethod({ url: "/v1/splash" }, true);
};

export const getBrandCommunityBannerList = async (
  bannerTypeCodes: string,
  brandCommunityId?: number,
  corporateMemberId?: number,
  isLoading = true
): Promise<Array<BannerInfo>> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/brand-community/banners",
      params: {
        bannerTypeCodes: bannerTypeCodes,
        brandCommunityId: brandCommunityId,
        corporateMemberId: corporateMemberId,
      },
    },
    isLoading
  );

  return (
    response.successOrNot === "Y" ? response.data : null
  ) as Array<BannerInfo>;
};

const getCpaBannerInfo = (): BannerInfo | undefined => {
  // const cpaInfo = MemberStore.getCpaInfo();
  // const isCpaMember = cpaInfo && cpaInfo["$3p"];
  // if (!isCpaMember) {
  //   return undefined;
  // }

  // const startDateString = remoteConfig().getString("cpa_start_date"); // 해당 일자 이후에 가입한 회원을 cpa 회원으로 판단
  // const endDateString = remoteConfig().getString("cpa_end_date"); // 해당 기간까지 가입한 회원을 cpa 회원으로 판단
  // const createDateString = AuthStore.sessionUser?.createdDateTime || ""; // 해당 회원 가입일자
  // if (
  //   isNaN(Date.parse(startDateString)) ||
  //   isNaN(Date.parse(endDateString)) ||
  //   isNaN(Date.parse(createDateString))
  // ) {
  //   // 설정된 날짜가 유효하지 않은 형식인 경우
  //   return undefined;
  // }

  // const cpaStartDate = new Date(startDateString);
  // const cpaEndDate = new Date(endDateString);
  // const createDate = new Date(createDateString);
  // const currentDate = new Date();

  // const isNewMember = createDate >= cpaStartDate;
  // const isWelcomePeriod = currentDate < cpaEndDate;

  // if (isNewMember && isWelcomePeriod) {
  //   const linkInfoString = remoteConfig().getString("cpa_banner_linkinfo");
  //   const bannerImageUrl = remoteConfig().getString("cpa_banner_image");
  //   const linkInfo: BannerLinkInfo = JSON.parse(linkInfoString);
  //   const welcomeBannerInfo: BannerInfo = {
  //     bannerId: 0,
  //     bannerTypeCode: BannerTypeCodes.MAIN_POP_UP_BANNER,
  //     bannerTitle: "cpa 회원가입 환영",
  //     bannerMainTitle: "",
  //     bannerSubTitle1: "",
  //     bannerSubTitle2: "",
  //     bannerSubTitle3: "",
  //     bannerBackGroundColor: "",
  //     bannerTextColor: "",
  //     bannerFileGroupId: 0,
  //     filePath: bannerImageUrl,
  //     bannerLinkParameter: queryParamFromBannerLinkInfo(linkInfo),
  //     bannerLinkInfo: linkInfo,
  //     sortOrder: "",
  //     bannerStartDate: "",
  //     bannerEndDate: "",
  //     createdDatetime: "",
  //     createMemberId: 0,
  //     updatedDatetime: "",
  //     updateMemberId: "",
  //   };
  //   return welcomeBannerInfo;
  // }

  return undefined;
};

const getWelcomeBannerInfo = (): BannerInfo | undefined => {
  // const startDateString = remoteConfig().getString("welcome_start_date"); // 해당 일자 이후에 가입한 회원을 신규회원으로 판단
  // const endDateString = remoteConfig().getString("welcome_end_date"); // 해당 기간까지만 신규회원 팝업 표시
  // const createDateString = AuthStore.sessionUser?.createdDateTime || ""; // 해당 회원 가입일자
  // if (
  //   isNaN(Date.parse(startDateString)) ||
  //   isNaN(Date.parse(endDateString)) ||
  //   isNaN(Date.parse(createDateString))
  // ) {
  //   // 설정된 날짜가 유효하지 않은 형식인 경우
  //   return undefined;
  // }

  // const welcomeStartDate = new Date(startDateString);
  // const bannerEndDate = new Date(endDateString);
  // const createDate = new Date(createDateString);
  // const currentDate = new Date();

  // const isNewMember = createDate >= welcomeStartDate;
  // const isWelcomePeriod = currentDate < bannerEndDate;

  // if (isNewMember && isWelcomePeriod) {
  //   const linkInfoString = remoteConfig().getString("welcome_banner_linkinfo");
  //   const bannerImageUrl = remoteConfig().getString("welcome_banner_image");
  //   const linkInfo: BannerLinkInfo = JSON.parse(linkInfoString);
  //   const welcomeBannerInfo: BannerInfo = {
  //     bannerId: 0,
  //     bannerTypeCode: BannerTypeCodes.MAIN_POP_UP_BANNER,
  //     bannerTitle: "회원가입 환영",
  //     bannerMainTitle: "",
  //     bannerSubTitle1: "",
  //     bannerSubTitle2: "",
  //     bannerSubTitle3: "",
  //     bannerBackGroundColor: "",
  //     bannerTextColor: "",
  //     bannerFileGroupId: 0,
  //     filePath: bannerImageUrl,
  //     bannerLinkParameter: queryParamFromBannerLinkInfo(linkInfo),
  //     bannerLinkInfo: linkInfo,
  //     sortOrder: "",
  //     bannerStartDate: "",
  //     bannerEndDate: "",
  //     createdDatetime: "",
  //     createMemberId: 0,
  //     updatedDatetime: "",
  //     updateMemberId: "",
  //   };
  //   return welcomeBannerInfo;
  // }

  return undefined;
};
