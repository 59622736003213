import React from "react";
import './HtmlContentComponent.scss'
export interface MissionDetailContentProps {
  title?: string;
  contents: string;
}

// 네이티브 소스
// const HtmlContentComponent = ({ title, contents }: MissionDetailContentProps) => {
//   const { width } = useWindowDimensions();
//   return (
//     <View style={styles.contents_item}>
//       {title && contents.length > 0 && <Text style={styles.label}>{title}</Text>}
//       <RenderHTML
//         contentWidth={width}
//         baseStyle={htmlStyle.content_style}
//         source={{ html: contents }}
//         defaultTextProps={{ allowFontScaling: false }}
//       />
//     </View>
//   );
// };

const HtmlContentComponent = ({ title, contents }: MissionDetailContentProps) => {
  const width = window.innerWidth;
  return (
    <div className="contents-item">
      {title && contents.length > 0 && <h2 className="label">{title}</h2>}
      <div
        className="html-content"
        style={{
          ...htmlStyle.contentStyle,
        }}
        dangerouslySetInnerHTML={{ __html: contents }}
      />
    </div>
  );
};

const htmlStyle = {
  contentStyle: {
    flex: 1,
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: "normal",
    letterSpacing: "0.056em",
    color: "#222",
  },
};

export default HtmlContentComponent;