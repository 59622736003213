import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import "./SimilarUserList.scss";
import { SimilarUserItem } from "../../../api/account/model";
import { getSimilarUsers } from "../../../api/account/api";
import CommonStore from "../../../store/CommonStore";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { MemberInfoResponse } from "../../../api/member/model";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import Slider from "react-slick";

const SimilarUserList = observer(
  ({ accountInfo }: { accountInfo: MemberInfoResponse }) => {
    const { widthApp } = CommonStore
    const { t } = useTranslation();
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    const [similarUsers, setSimilarUsers] = useState([] as SimilarUserItem[]);
    const [isLoading, setIsLoading] = useState(true);
    const cardSlidersettings = {
      infinite: false,
      swipeToSlide: true,
      arrows: false,
      dots: false,
      variableWidth: true,
      slidesToShow: Math.floor(widthApp / 80),
      slidesToScroll: 1,
      focusOnSelect: true,
    };

    const moveToAccount = (
      uuid: string,
      index: number,
      nickname: string,
      profileUrl: string,
      profileBackgroundColor: string
    ) => {
      CommonStore.callActHistory(
        ActHistoryTypeCode.USER_CLICK,
        uuid,
        ActPageCode.MY_HOME,
        ActSectionCode.SIMILAR_USER,
        index.toString()
      );
      goTo(`/user/${nickname}`, {
        state: {
          targetUserId: uuid,
          nickname: nickname,
          profileUrl: profileUrl,
          profileBackgroundColor: profileBackgroundColor,
        },
      });
    };

    useEffect(() => {
      const getSimilarUser = async () => {
        const res = await getSimilarUsers({
          memberUuid: accountInfo.memberUuid,
        });
        if (res !== null) setSimilarUsers(res.similarMemberList);
      };
      void getSimilarUser().finally(() => setIsLoading(false));
      return () => setSimilarUsers([]);
    }, [accountInfo]);

    const renderRecommendUsers = (item: {
      item: SimilarUserItem;
      index: number;
    }) => {
      return (
        <div className="recommend_user_item">
          <div
            onClick={() =>
              moveToAccount(
                item.item.memberUuid,
                item.index,
                item.item.nickname,
                item.item.profileUrl,
                item.item.profileBackgroundColor
              )
            }
            aria-label={`Recommend user${item.index}`}
            className="recommend_user_image_wrapper"
          >
            <FastImageWithFallback
              className="recommend_user_image"
              style={{ display: "flex" }}
              source={
                item.item.profileUrl !== undefined &&
                item.item.profileUrl !== null
                  ? {
                      uri: `${PUBLIC_BUCKET_URL}${item.item.profileUrl}`,
                    }
                  : require("../../../assets/images/fall_back_image.png")
              }
            />
          </div>
          <p
            className="recommend_user_text2"
            // numberOfLines={1}
            arai-label="User nickname"
          >
            {item.item.nickname}
          </p>
        </div>
      );
    };

    return (
      <div className="recommend_users">
        <p className="recommend_users_text1">
          {t("screen.myHome.label.similarUser")}
        </p>
        {similarUsers && similarUsers.length > 0 && !isLoading ? (
          <div className="recommend_users_wrapper">
            <div className="slider-container">
              <Slider {...cardSlidersettings}>
                {similarUsers.map((item, index) => {
                  return renderRecommendUsers({ item, index });
                })}
              </Slider>
            </div>
          </div>
        ) : (
          <div style={{ height: 90 }}></div>
        )}
      </div>
    );
  }
);

export default SimilarUserList;
