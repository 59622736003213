import React, { useState } from 'react'
import { ReactComponent as CheckOffIcon } from '../../assets/icons/checkbox_off.svg'
import { ReactComponent as CheckOnIcon } from '../../assets/icons/checkbox_on.svg'
import classes from './CustomCheckBox.module.scss'

const CustomCheckBox = ({
  label, // checkbox label
  defaultSelected, // 최초 상태
  onChangeCheck, // checked change eventz
  numberOfChecked,
  wrapperStyle,
  labelStyle,
  iconProps
}: {
  label?: string
  defaultSelected?: boolean
  onChangeCheck?: ((selected: boolean) => void) | undefined
  numberOfChecked?: number
  wrapperStyle?: React.CSSProperties
  labelStyle?: React.CSSProperties
  iconProps?: React.SVGProps<SVGSVGElement>
}) => {
  const [isSelected, setSelected] = useState(
    defaultSelected === undefined ? false : defaultSelected
  )

  return (
    <>
      <div className={classes.item} style={wrapperStyle}>
        <button
          className={classes.item_wrap}
          onClick={() => {
            if (!(numberOfChecked === 3 && !isSelected)) {
              setSelected(!isSelected)
              if (onChangeCheck !== undefined && onChangeCheck !== null)
                onChangeCheck(isSelected)
            }
          }}
          aria-label="Checkbox"
        >
          {isSelected ? <CheckOnIcon {...iconProps}/> : <CheckOffIcon {...iconProps}/>}
          <span style={labelStyle} className={classes.contents_text} aria-label="Label">
            {label}
          </span>
        </button>
      </div>
    </>
  )
}

export default CustomCheckBox
