import { background_d90000, border_color_d90000 } from "./colors";

export const icon_32 = {
  width: 32,
  height: 32,
};

export const icon_24 = {
  width: 24,
  height: 24,
};

export const icon_12 = {
  width: 12,
  height: 12,
};

export const icon_42 = {
  width: 42,
  height: 42,
};

export const icon_border_default = {
  borderWidth: 1,
};

export const icon_border_radius_25 = {
  borderRadius: 25,
};

export const text_icon_0 = {
  ...icon_12,
  ...icon_border_default,
  ...icon_border_radius_25,
  ...border_color_d90000,
  ...background_d90000,
};
