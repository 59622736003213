import { useCallback, useEffect, useRef, useState } from "react";
import { TitleHeader } from "../../../components/common";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { HeaderItem } from "../../../constants/Header";
import PostWriteStore, { PostLocalSave } from "../../../store/PostWriteStore";
import { POST_TEMP_DATA } from "../../../constants/Storage";
import { NotificationModal } from "../../../components/modals";
import { ReactComponent as AddIcon } from "../../../assets/icons/plus_purple.svg";
import dayjs from "dayjs";
import { FeedMode, POST_BLANK } from "../../../constants/Feed";
import UploadStore from "../../../store/upload/UploadStore";
import { goBack, goTo } from "../../../hooks/navigate/NavigateFunction";
import MaxLengthTextInput from "../../../components/common/MaxLengthTextInput";
import PostWriteContents from "./PostWriteContents";
import PostWriteTag from "./PostWriteTag";
import PostRating from "./PostRating";
import PostProduct from "./PostProduct";
import { delIndexedDB, setIndexedDB } from "../../../utils/IndexedDB";
import "./PostWrite.scss";
import MissionStore from "../../../store/MissionStore";
import { BlockerFunction, useBlocker } from "react-router";

const PostWrite = observer(({ mode }: { mode: FeedMode }) => {
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [showConfirmPopup, setConfirmPopup] = useState(false);
  const [tempSaveFlag, setTempSaveFlag] = useState(true);
  const [registering, setRegistering] = useState<boolean>(false);

  const { t } = useTranslation();
  const tagVwRef = useRef<any>(null);
  const scrollRef = useRef<any>(null);
  const headerRef = useRef<any>(null);
  const wrapRef = useRef<any>(null);
  const assContentsRef = useRef<any>(null);
  let whiteListRoutes = ["/Product/Search", "/"];

  let checkBlocking = useCallback<BlockerFunction>(({currentLocation, nextLocation}) => {
    let checkValid = whiteListRoutes.includes(nextLocation.pathname)
    return currentLocation.pathname !== nextLocation.pathname && !checkValid}, [])

  let shouldBlock = useBlocker(checkBlocking);

  const {
    lastFocus,
    contentsTempData,
    wholeTempData,
    isValid,
    isChanged,
    errorMessage,
    hasError,
    tempProductList,
    addContents,
    setError,
    setTitle,
    showValidInfo,
    clearFocus,
    clear,
    missonSubmit,
    missionData,
    missionMessageTitle,
    missionMessage,
    setMissionMessage,
    setMissionMessageTitle,
  } = PostWriteStore;

  const initAndBack = async () => {
    shouldBlock.proceed?.();
    clearFocus();
    setTempSaveFlag(false);
    delIndexedDB(POST_TEMP_DATA)
      // .then(() => goBack())
      .catch(() => {
        setTempSaveFlag(true);
      });
  };

  const saveAndBack = async () => {
    shouldBlock.proceed?.()
    const data: PostLocalSave = {
      wholeTempData: wholeTempData,
      date: dayjs(),
      products: tempProductList,
    };
    await setIndexedDB(POST_TEMP_DATA, JSON.stringify(data));
    // goBack();
  };

  const back = () => {
    if (
      wholeTempData != null &&
      JSON.stringify(wholeTempData) !== JSON.stringify(POST_BLANK)
    ) {
      setShowSavePopup(true);
    } else {
      initAndBack();
    }
    // return true;
  };

  const save = () => {
    setConfirmPopup(false);
    shouldBlock.proceed?.();
    PostWriteStore.asyncPosting(mode);
    if (mode === FeedMode.MODIFY) {
      goBack();
    }
    if (mode === FeedMode.WRITE) {
      goTo("/", { replace: true });
    }
  };

  useEffect(() => {
    if (shouldBlock.state === "blocked") {
      back()
    }
  }, [shouldBlock.state, showConfirmPopup, showSavePopup])

  useEffect(() => {
    return () => {
      shouldBlock.reset?.()
    }
  }, [])

  useEffect(() => {
    const saveWholeTempData = async () => {
      if (
        tempSaveFlag &&
        wholeTempData != null &&
        JSON.stringify(wholeTempData) !== JSON.stringify(POST_BLANK)
      ) {
        const data: PostLocalSave = {
          wholeTempData: wholeTempData,
          date: dayjs(),
          products: tempProductList,
        };
        await setIndexedDB(POST_TEMP_DATA, JSON.stringify(data));
      }
    };
    saveWholeTempData().catch((reason) => reason);
  }, [isChanged, wholeTempData, tempProductList, tempSaveFlag]);

  return (
    <>
      <div id="post_write_wrap" className="screen_wrap" ref={wrapRef}>
        <TitleHeader
          wrapperRef={headerRef}
          title={
            mode === FeedMode.MODIFY
              ? t("screen.post.title.modify")
              : t("screen.post.title.write")
          }
          isBack={true}
          rightItem={HeaderItem.NONE}
          onClickBack={goBack}
        />
        <div className="content_wrap_inside">
          <div className="title">
            <MaxLengthTextInput
              className="title_input_text"
              aria-label="Post title"
              placeholder={t("screen.post.label.titlePlaceHolder")}
              maxLength={50}
              value={wholeTempData?.title}
              onChangeText={(text: string) => {
                setTitle(text);
              }}
            />
          </div>
        </div>
        <div aria-label="Post contents list" className="post_contents_list">
          {contentsTempData.map((item, index) => {
            return (
              <div className="post_contents_wrap">
                <PostWriteContents
                  key={index}
                  data={item}
                  contentsIndex={index}
                />
              </div>
            );
          })}
        </div>
        <>
          <div className="add_contents_wrap" ref={assContentsRef}>
            <button
              className="add_home_app_button"
              onClick={() => {
                addContents();
                PostWriteStore.contentsTempData.length < 11 &&
                  assContentsRef?.current?.scrollIntoView({
                    behavior: "smooth",
                  });
              }}
            >
              <AddIcon />
              <span className="add_home_app_button_text">
                {t("screen.post.button.addContents")}
              </span>
            </button>

            <span className="add_contents_text2">
              {t("screen.post.label.notice")}
            </span>
          </div>
          <div ref={tagVwRef} className="post_tag">
            <PostWriteTag />
          </div>
          <div className="contents">
            <PostRating />
          </div>
          <PostProduct parentRef={scrollRef} />
        </>
        <div className="footer">
          <button
            aria-label="Register button"
            className={isValid ? "active btn_1" : "in_active btn_1"}
            onClick={() => {
              if (!UploadStore.isDefaultStatus) {
                setRegistering(true);
                return;
              }
              if (isValid) {
                setConfirmPopup(true);
              } else {
                showValidInfo();
              }
            }}
          >
            <span
              className={
                isValid ? "txt_active btn_1_txt" : "txt_in_active btn_1_txt"
              }
            >
              {mode === FeedMode.MODIFY
                ? t("screen.post.button.modify")
                : t("screen.post.button.register")}
            </span>
          </button>
        </div>

        <div className={isValid ? "active" : "in_active"} />
      </div>

      {hasError && (
        <NotificationModal
          isVisible={hasError}
          contents1={errorMessage!}
          onRequestClose={() => {
            if (errorMessage === t("screen.post.message.validError")) {
              setTimeout(() => lastFocus?.focus(), 300);
            }
            setError("");
            mode === FeedMode.MISSION_POST_WRITE && goBack();
          }}
        />
      )}

      {showSavePopup &&
        (mode === FeedMode.MODIFY ? (
          <NotificationModal
            isVisible={showSavePopup}
            contents1={t("screen.post.message.modifing")}
            useTwoButton={true}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.OK")}
            extraButtonText={t("screen.notificationModal.button.cancel")}
            onClickDefaultButton={() => {
              setShowSavePopup(false);
              clear();
              goBack();
            }}
            onClickExtraButton={() => setShowSavePopup(false)}
            onRequestClose={() => setShowSavePopup(false)}
          />
        ) : (
          <NotificationModal
            isVisible={showSavePopup}
            title={t("screen.post.label.tempSave")}
            contents1={t("screen.post.message.tempSave")}
            useTwoButton={true}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.OK")}
            extraButtonText={t("screen.notificationModal.button.cancel")}
            onClickDefaultButton={() => {
              setShowSavePopup(false);
              saveAndBack()
            }}
            onClickExtraButton={() => {
              setShowSavePopup(false)
              initAndBack()
            }}
            onRequestClose={() => {
              shouldBlock.reset?.(); 
              setShowSavePopup(false)
            }}
          />
        ))}

      {showConfirmPopup &&
        (mode === FeedMode.MODIFY ? (
          <NotificationModal
            isVisible={showConfirmPopup}
            contents1={t("screen.post.message.modifyConfirm")}
            useTwoButton={true}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.OK")}
            extraButtonText={t("screen.notificationModal.button.cancel")}
            onClickDefaultButton={save}
            onClickExtraButton={() => setConfirmPopup(false)}
            onRequestClose={() => setConfirmPopup(false)}
          />
        ) : (
          <NotificationModal
            isVisible={showConfirmPopup}
            contents1={t("screen.post.message.registerConfirm")}
            useTwoButton={true}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.OK")}
            extraButtonText={t("screen.notificationModal.button.cancel")}
            onClickDefaultButton={save}
            onClickExtraButton={() => setConfirmPopup(false)}
            onRequestClose={() => setConfirmPopup(false)}
          />
        ))}

      {registering && (
        <NotificationModal
          isVisible={registering}
          contents1={t("screen.post.message.registering")}
          useTwoButton={false}
          isBlack={false}
          defaultButtonText={t("screen.notificationModal.button.confirm")}
          onClickDefaultButton={() => setRegistering(false)}
          onClickExtraButton={() => setRegistering(false)}
          onRequestClose={() => setRegistering(false)}
          modalCloseCallback={() => setRegistering(false)}
          textAlign={"center"}
        />
      )}
      {missonSubmit && missionData && (
        <NotificationModal
          isVisible={missonSubmit}
          title={missionMessageTitle}
          contents1={missionMessage || ""}
          isBlack={true}
          defaultButtonText={t("screen.post.button.confirm")}
          onRequestClose={() => {
            setMissionMessage("");
            setMissionMessageTitle("");
            MissionStore.setIsEndFirstPostingLoad(false);
            MissionStore.getMissionPost(
              MissionStore.missionDetail.missionId,
              null
            ).finally(() => {
              MissionStore.setIsEndFirstPostingLoad(true);
              goBack();
            });
          }}
        />
      )}
    </>
  );
});

export default PostWrite;
