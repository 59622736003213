import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EmptyComment } from "../../../assets/icons/img_reply_nodata.svg";
import { FlexBoxs, Spacings, Texts } from "../../../assets/styles";
import { Styles } from "../../../assets/types/Style";
import './CommentEmpty.scss';
import { flex } from "../../../assets/styles/flexBoxs";

const CommentEmpty = () => {
  const { t } = useTranslation();

  return (
    <div className="noting_comments_wrapper">
      <EmptyComment />
      <div className="noting_comments">
        <div className="noting_comments_box">
          <span
            className="noting_comments_txt"
            aria-label="Comment Empty Label"
          >
            {t("screen.comment.label.empty")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CommentEmpty;
