import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  MemberInfoResponse,
  MemberTagItem,
  SimilarFollowerItem,
} from "../../../api/member/model";

import "./AccountHeader.scss";
import {
  ProfileColor,
  ProfileColorList,
} from "../../../constants/ProfileColor";
import { countFollow } from "../../../utils/numberCount";
// import { navigate, push } from "../../../navigation/NavigationFunction";
import { follow, unfollow } from "../../../api/follow/api";
import { FollowersTab } from "../../../constants/Account";
import CommonStore from "../../../store/CommonStore";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import CustomParsedText from "../../../components/common/CustomParsedText";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { ReactComponent as PencilIcon } from "../../../assets/icons/icon_pencil.svg";
import { ReactComponent as IconVolt } from "../../../assets/icons/icon_my_volt.svg";
import { ReactComponent as IconCommunity } from "../../../assets/icons/icon_my_community.svg";

import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import BadgeStore from "../../../store/BadgeStore";
import { RoleTypeCode } from "../../../constants/CommonCode.enum";
import { useTracker } from "../../../hooks/tracker";
import { UserActTypeCode } from "../../../constants/FeedType.enum";
import FollowerStore from "../../../store/FollowerStore";
import { getSimilarFollowers } from "../../../api/member/api";
import AuthStore from "../../../store/AuthStore";
import { CommaAddInTextInput, formatVoltAmount } from "../../../utils/number";
import RemoteConfigStore from "../../../store/RemoteConfigStore";
import { BadgeCode } from "../../../constants/Badge.enum";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProfileUpdate from "../../profile/ProfileUpdate";
import clsx from "clsx";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";

const AccountHeader = observer(
  ({
    isMy,
    nickname,
    profileUrl,
    profileBackgroundColor,
    accountInfo,
    isLoading,
  }: {
    isMy: boolean;
    nickname?: string;
    profileUrl?: string;
    profileBackgroundColor?: string;
    accountInfo: MemberInfoResponse;
    isLoading?: boolean;
  }) => {
    const { t } = useTranslation();
    const [colorInfo, setColorInfo] = useState(ProfileColor.PURPLE);
    const [isFollow, setIsFollow] = useState<boolean>();
    const [followerCount, setFollowerCount] = useState(
      accountInfo?.followerCount || 0
    );
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
    const { setToastOption } = CommonStore;
    const OFFICIAL_BADGE_URI = PUBLIC_BUCKET_URL + "/ASSETS/badge_official.png";
    const [similarFollowerList, setSimilarFollowerList] =
      useState<SimilarFollowerItem[]>();
    const [similarFollowerTotalCount, setSimilarFollowerTotalCount] =
      useState<number>();

    const { track } = useTracker();

    const renderTag = (item: { item: MemberTagItem; index: number }) => {
      return (
        <TouchableWithAuthCheck
          className="tag_item_wrap"
          onPress={() => {
            CommonStore.callActHistory(
              ActHistoryTypeCode.TAG_CLICK,
              (item.item.tagId ?? 0).toString(),
              ActPageCode.MY_HOME,
              ActSectionCode.MY_TAGS
            );
            moveToTagSearch(item.item);
          }}
        >
          {item.index + 1 !== accountInfo?.interestedTagList?.length ? (
            <div className="tag_item" key={item.index} aria-label="Tag item">
              <p className="tag_text">#{item.item.tagName}</p>
            </div>
          ) : (
            <div className="tag_item" key={item.index} aria-label="Tag item">
              <p className="tag_text">#{item.item.tagName}</p>
            </div>
          )}
        </TouchableWithAuthCheck>
      );
    };

    const renderFollowerInfo = () => {
      if (!similarFollowerList || similarFollowerList.length == 0) return <></>;

      if (similarFollowerList.length == 1) {
        // 한명만 있을때
        return (
          <p className="txt_follow_info">
            <span className="txt_follow_info_bold">
              {similarFollowerList[0]?.nickname}
            </span>
            님이 팔로우 중입니다.
          </p>
        );
      } else if (similarFollowerList.length == 2) {
        // 2명 있을때
        return (
          <p className="txt_follow_info">
            <span className="txt_follow_info_bold">
              {similarFollowerList[0]?.nickname}
            </span>
            님,{" "}
            <span className="txt_follow_info_bold">
              {similarFollowerList[1]?.nickname}
            </span>
            님이 팔로우 중입니다.
          </p>
        );
      } else {
        // 3명 이상 있을때
        return (
          <p className="txt_follow_info">
            <span className="txt_follow_info_bold">
              {similarFollowerList[0]?.nickname}
            </span>
            님,{" "}
            <span className="txt_follow_info_bold">
              {similarFollowerList[1]?.nickname}
            </span>
            님 외{" "}
            <span className="txt_follow_info_bold">
              {CommaAddInTextInput(
                ((similarFollowerTotalCount || 0) - 2)?.toString() || ""
              )}
            </span>
            명이 팔로우 중입니다.
          </p>
        );
      }
    };

    const renderFollowerList = () => {
      return (
        <TouchableWithAuthCheck onPress={moveToFollower}>
          <div style={styles.bx_follow_list}>
            <div
              className={clsx(
                "bx_profile_img",
                `bx_profile_img_${similarFollowerList?.length}_people`
              )}
            >
              {similarFollowerList?.flatMap((item, index) => {
                return (
                  <div className={clsx("img_profile", "img_profile_" + index)}>
                    <LazyLoadImage
                      className="in_img_profile"
                      src={`${PUBLIC_BUCKET_URL}${item.profileUrl}`}
                    />
                  </div>
                );
              })}
            </div>
            {renderFollowerInfo()}
          </div>
        </TouchableWithAuthCheck>
      );
    };

    const moveToTagSearch = (item: MemberTagItem) => {
      goTo(`/tag/${item.tagId}`, {
        state: { tagId: item.tagId },
      });
    };

    const moveToBadge = () => {
      if (isMy) goTo("/Badge", { state: { accountInfo: accountInfo } });
    };

    const moveToProfile = () => {
      if (isMy)
        goTo("/ProfileUpdate", {
          state: {
            accountInfo: accountInfo,
            profileImage: `${PUBLIC_BUCKET_URL}${
              accountInfo?.profileUrl || ""
              }`,
            backgroundColor: accountInfo?.profileBackgroundColor,
            nickname: accountInfo?.nickname,
            introduction: accountInfo?.introduce,
            snsUrl: accountInfo?.snsUrl,
            gender: accountInfo?.genderCode,
            birthYear: accountInfo?.birthYear,
            recommendedNicknameYn: accountInfo?.recommendedNicknameYn,
          },
        });
    };

    const moveToFollower = () => {
      if (accountInfo !== undefined && accountInfo !== null) {
        goTo("/follow", {
          state: {
            targetUserId: accountInfo.memberUuid,
            targetUserNickname: accountInfo.nickname,
            type: FollowersTab.FOLLOWERS,
          },
        });
      }
    };

    const moveToFollowing = () => {
      if (accountInfo !== undefined && accountInfo !== null) {
        goTo("/follow", {
          state: {
            targetUserId: accountInfo.memberUuid,
            targetUserNickname: accountInfo.nickname,
            type: FollowersTab.FOLLOWINGS,
          },
        });
      }
    };

    const moveToMyAppliancesScreen = () => {
      if (accountInfo !== undefined && accountInfo !== null) {
        goTo("/MyAppliance", {
          state: {
            targetMemberUuid: accountInfo.memberUuid,
            nickname: accountInfo.nickname,
          }
        });
      }
    };

    const moveToSnsUrl = () => {
      // todo
    };

    const doUnFollow = async () => {
      if (accountInfo?.memberUuid !== undefined) {
        //PPIT210117-4818 UNFOLLOW 시 actHistory 처리 추가
        CommonStore.callActHistory(
          ActHistoryTypeCode.USER_FOLLOWING,
          accountInfo?.memberUuid,
          ActPageCode.OTHER_USER_HOME,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          "CANCEL"
        );
        setIsFollow(false);
        setFollowerCount(followerCount - 1);
        const res = await unfollow(accountInfo?.memberUuid);
        FollowerStore.handlePostFollowUpdate(
          UserActTypeCode.UN_FOLLOW,
          accountInfo?.memberUuid
        );
        if (!res) {
          setIsFollow(true);
          setFollowerCount(followerCount + 1);
        } else {
          onToast(false);
        }
      }
    };

    const doFollow = async () => {
      if (accountInfo?.memberUuid !== undefined) {
        CommonStore.callActHistory(
          ActHistoryTypeCode.USER_FOLLOWING,
          accountInfo?.memberUuid,
          ActPageCode.OTHER_USER_HOME
        );
        setIsFollow(true);
        setFollowerCount(followerCount + 1);
        const res = await follow(accountInfo?.memberUuid);
        FollowerStore.handlePostFollowUpdate(
          UserActTypeCode.FOLLOW,
          accountInfo?.memberUuid
        );
        if (!res) {
          setIsFollow(false);
          setFollowerCount(followerCount - 1);
        } else {
          onToast(true);
          void BadgeStore.obtainBadge(BadgeCode.FOLLOW);
        }
      }

      track("click_follow_button", {
        tag: accountInfo.interestedTagList?.map((t) => t.tagName),
      });
    };

    const onToast = (follow: boolean) => {
      setToastOption({
        show: true,
        message1: follow
          ? t("screen.myHome.message.followToast", {
            nickname: accountInfo?.nickname,
          })
          : t("screen.myHome.message.unfollowToast", {
            nickname: accountInfo?.nickname,
          }),
        // bottomOffset: 30,
      });
    };

    // TODO
    // function isCommunityFound() {
    //   if (RemoteConfigStore.show_brand_page === "false") return false;
    //   else {
    //     return (accountInfo.communityCount || 0) > 0;
    //   }
    // }

    const renderBasicFollowButtonArea = () => {
      return (
        <>
          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            {isLoading && (
              <TouchableWithAsyncTask
                style={styles.follow_btn1}
                // TODO
                // onPress={doFollow}
                authCheckProps={{ enabled: true }}
              >
                <p
                  className="follow_btn_text1"
                  style={{ color: currentBackgroundColor }}
                  aria-label="Follow"
                >
                  {t("screen.myHome.label.followRequesting")}
                </p>
              </TouchableWithAsyncTask>
            )}
            {!isLoading && isFollow != undefined && isFollow && (
              <>
                {/* 팔로우 후 */}
                <TouchableWithAsyncTask
                  style={styles.follow_btn2}
                  onPress={doUnFollow}
                  authCheckProps={{ enabled: true }}
                >
                  <p className="follow_btn_text2" aria-label="Followed">
                    {t("screen.myHome.label.following")}
                  </p>
                </TouchableWithAsyncTask>
              </>
            )}
            {!isLoading && isFollow != undefined && !isFollow && (
              <>
                {/* 팔로우 전 */}
                <TouchableWithAsyncTask
                  style={styles.follow_btn1}
                  onPress={doFollow}
                  authCheckProps={{ enabled: true }}
                >
                  <p
                    className="follow_btn_text1"
                    style={{ color: currentBackgroundColor }}
                    aria-label="Follow"
                  >
                    {t("screen.followers.button.follow")}
                  </p>
                </TouchableWithAsyncTask>
              </>
            )}
          </div>
        </>
      );
    };

    function moveToCommunity() {
      // TODO
      // if (isCommunityFound()) {
      //   navigate("MainTab", {
      //     screen: "BrandCommunityHome",
      //     params: { targetBrandId: `${accountInfo.communityId || 0}` },
      //   });
      // }
    }

    const renderCorporateCommunityButtonArea = () => {
      return (
        <>
          <div className="follow_btn2" onClick={moveToCommunity}>
            <p className="follow_btn_text2" aria-label="Followed">
              {t("screen.myHome.label.community")}
            </p>
          </div>
        </>
      );
    };

    // TODO
    const renderCorporateFollowButtonArea = () => {
      // TODO: 팔로우랑 팔로잉 버튼 스타일이 달라야 함
      return (
        <div className="bx_add_btn">
          <div className="bx_com_btn">
            <div className="bx_touch_btn" onClick={moveToCommunity}>
              <p className="txt_touch_border">
                {t("screen.myHome.label.community")}
              </p>
              {/* <BtnArrowDown /> */}
            </div>
          </div>
          <div className="bx_com_btn">
            {isLoading ? (
              <TouchableWithAsyncTask
              // style={[styles.bx_touch_btn, styles.btn_full]}
              >
                <p className="txt_touch_full">
                  {t("screen.myHome.label.followRequesting")}
                </p>
              </TouchableWithAsyncTask>
            ) : (
              <TouchableWithAsyncTask
                // style={[styles.bx_touch_btn, styles.btn_full]}
                onPress={
                  isLoading ? undefined : isFollow ? doUnFollow : doFollow
                }
              >
                <p className="txt_touch_full">
                  {t(
                    isFollow
                      ? "screen.myHome.label.following"
                      : "screen.followers.button.follow"
                  )}
                </p>
              </TouchableWithAsyncTask>
            )}
          </div>
        </div>
      );
    };

    useEffect(() => {
      setIsFollow(accountInfo?.followingYN === "Y");
      setFollowerCount(accountInfo?.followerCount || 0);
      const color = ProfileColorList.find(
        (item) => accountInfo?.profileBackgroundColor?.toLowerCase() == item.hex
      );
      setColorInfo(color || ProfileColor.COLOR10);

      if (accountInfo.roleTypeCode == RoleTypeCode.CORPORATE) {
        void getSimilarFollowers({
          loginMemberUuid: AuthStore.sessionUser?.memberUuid || "",
          corpMemberUuid: accountInfo.memberUuid,
          maxMemberCount: 3,
        }).then((response) => {
          if (response?.successOrNot == "Y") {
            setSimilarFollowerList(response.data?.similarFollowerList);
            setSimilarFollowerTotalCount(response.data?.totalCount);
          }
        });
      }
    }, [accountInfo]);

    const currentBackgroundColor =
      (profileBackgroundColor
        ? profileBackgroundColor
        : accountInfo?.profileBackgroundColor) || "#672C78";

    const renderProfileTop = useMemo(() => (
      <>
        <div className="profile_info_top">
          <div className="profile_text_1_wrapper">
            <p className="profile_text_1" aria-label="Nickname">
              {nickname ? nickname : accountInfo?.nickname}
            </p>
          </div>
          <TouchableWithAuthCheck onPress={moveToBadge}>
            <div className="badge-list" aria-label="Badge list">
              <div className="badge-item">
                <img
                  className="profile_btn"
                      src={`${PUBLIC_BUCKET_URL}${
                        accountInfo?.levelInfo?.levelIconUrl || ""
                    }`}
                  alt={accountInfo?.levelInfo?.levelIconUrl}
                />
                {accountInfo?.obtainedBadgeList?.map((item) => {
                  return (
                    <div className="badge-item-inner" key={item.badgeId}>
                      <img
                        className="profile_btn"
                            src={`${PUBLIC_BUCKET_URL}${
                              item.badgeIconUrl || ""
                          }`}
                        alt={item.badgeIconUrl}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </TouchableWithAuthCheck>
        </div>
      </>
    ), [accountInfo])

    const renderProfileBottom = useMemo(() => (
      <>
        <div style={styles.profile_info_bottom}>
          <div style={styles.info_bottom_item} aria-label="Feed count">
            <p className="profile_text_2">
              {t("screen.myHome.label.feed")}
            </p>
            {!isLoading && (
              <p className="profile_text_3">
                {countFollow(accountInfo?.createdFeedCount ?? 0) ?? "0"}
              </p>
            )}
          </div>
          <TouchableWithAuthCheck onPress={moveToFollower}>
            <div style={styles.info_bottom_item} aria-label="Follower count">
              <p className="profile_text_2">
                {t("screen.myHome.label.follower")}
              </p>
              {!isLoading && (
                <p className="profile_text_3">
                  {countFollow(followerCount)}
                </p>
              )}
            </div>
          </TouchableWithAuthCheck>
          <TouchableWithAuthCheck onPress={moveToFollowing}>
            <div style={styles.info_bottom_item} aria-label="Following count">
              <p className="profile_text_2">
                {t("screen.myHome.label.following")}
              </p>
              {!isLoading && (
                <p className="profile_text_3">
                  {countFollow(accountInfo?.followingCount ?? 0) || "0"}
                </p>
              )}
            </div>
          </TouchableWithAuthCheck>
          <TouchableWithAuthCheck onPress={moveToMyAppliancesScreen}>
            <div
              style={styles.info_bottom_item}
              aria-label="MyAppliances count"
            >
              <p className="profile_text_2">
                {t("screen.myHome.label.myproduct")}
              </p>
              {!isLoading && (
                <p className="profile_text_3">
                  {countFollow(accountInfo?.productCount ?? 0) || "0"}
                </p>
              )}
            </div>
          </TouchableWithAuthCheck>
        </div>
      </>
    ), [accountInfo]);

    const renderTagList = useMemo(() => (
      <>
        <div className="tag-list-wrapper" style={{ marginLeft: 16, marginRight: 16 }}>
          {isLoading && (
            <div className="tag">
              <div className="tag_item" aria-label="Tag item">
                <p className="tag_text"> </p>
              </div>
            </div>
          )}
          {!isLoading &&
            accountInfo != null &&
            accountInfo != undefined &&
            accountInfo?.interestedTagList != undefined &&
            accountInfo?.interestedTagList?.length > 0 && (
              <>
                {accountInfo?.interestedTagList.map((item, index) =>
                  renderTag({ item, index })
                )}
              </>
            )}
        </div>
      </>
    ), [accountInfo])

    return (
      <div
        style={{ ...styles.profile, backgroundColor: currentBackgroundColor }}
      >
        <div style={styles.profile_info_wrap}>
          <div
            className="profile_image_container"
            onClick={moveToProfile}
            aria-label="Profile image"
          >
            <div className="corp_badge">
              {accountInfo?.roleTypeCode === RoleTypeCode.CORPORATE && (
                // TODO: CORPORATE일때 back ground img가 되도록 처리
                <img
                  src={
                    accountInfo?.roleTypeCode === RoleTypeCode.CORPORATE
                      ? OFFICIAL_BADGE_URI
                      : ""
                  }
                  className="corp_badge_img"
                  alt=""
                />
              )}
              <FastImageWithFallback
                style={{ display: "flex" }}
                className="profile_image"
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${(profileUrl ? profileUrl : accountInfo?.profileUrl) || ""
                    }`,
                }}
              />
            </div>
            {isMy && (
              <div className="edit_icon_wrap">
                <PencilIcon />
              </div>
            )}
          </div>
          <div className="profile_info">
            {renderProfileTop}
            {renderProfileBottom}
          </div>
        </div>
        <div
          style={{ paddingLeft: 16, paddingRight: 16 }}
          className={clsx({
            greeting_text: true,
            has_introduce_or_sns: accountInfo?.introduce || accountInfo?.snsUrl,
          })}
        >
          {accountInfo !== undefined &&
            accountInfo?.introduce !== undefined &&
            accountInfo?.introduce?.length > 0 && (
              <p aria-label="Introduce" className="profile_text_4">
                {accountInfo?.introduce}
              </p>
            )}
          {accountInfo !== undefined &&
            accountInfo?.snsUrl !== undefined &&
            accountInfo?.snsUrl?.length > 0 && (
              <div onClick={moveToSnsUrl} className="profile_text_5">
                <CustomParsedText
                  aria-label="Sns url"
                  urlStyle={{ color: "#fff" }}
                >
                  {accountInfo?.snsUrl}
                </CustomParsedText>
              </div>
            )}
        </div>
        {renderTagList}
        {accountInfo.roleTypeCode == RoleTypeCode.CORPORATE && (
          <div style={styles.bx_add_follow}>
            {
              !isMy && renderBasicFollowButtonArea()
              // TODO
              // isCommunityFound()
              //   ? renderCorporateFollowButtonArea()
              //   : renderBasicFollowButtonArea()
            }
            {/* {isMy && isCommunityFound() && renderCorporateCommunityButtonArea()} */}
            {(similarFollowerList?.length || 0) > 0 && renderFollowerList()}
          </div>
        )}

        {/* TODO: 배경색이랑 맞게 변경 */}
        {/* 법인이 아닌 타 유저 일때만 보이기 */}
        {/* TODO: 브랜드 페이지 노출할 때 */}
        {
          !isMy && accountInfo.roleTypeCode != RoleTypeCode.CORPORATE && (
            (getStringRemoteConfig('show_brand_page') === "true" ? (
              <>
                {renderBasicFollowButtonArea()}
                <div style={styles.bx_row_half}>
                  <div style={{ ...styles.bx_col, ...styles.bx_col_first }}>
                    <div style={styles.bx_label}>
                      <IconVolt />
                      <p style={styles.txt_label}>
                        {t("screen.volt.label.volt")}
                      </p>
                    </div>
                    <div style={styles.bx_col_info}>
                      <p style={styles.txt_info_value}>
                        {formatVoltAmount(accountInfo.voltAmount)}
                      </p>
                    </div>
                  </div>
                  <div style={styles.bx_col}>
                    <div style={styles.bx_label}>
                      <IconCommunity />
                      <p style={styles.txt_label}>
                        {t("screen.myHome.label.community")}
                      </p>
                    </div>
                    <div style={styles.bx_col_info}>
                      <p style={styles.txt_info_value}>
                        {accountInfo.communityCount || "0"}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )
              : (
                <>
                  <div className="bx_add_volt" style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <IconVolt />
                    <p className="txt_add_volt">
                      {CommaAddInTextInput(
                        accountInfo.voltAmount?.toString() || "0"
                      )}
                      볼트를 모았어요.
                    </p>
                  </div>
                  {renderBasicFollowButtonArea()}
                </>
              )
            ))
        }
      </div>
    );
  }
);

export default AccountHeader;

const styles: Styles = {
  url: { color: "white" },
  backdrop: {
    ...Colors.background_222,
    ...Positions.fullscreen,
    opacity: 0.5,
    zIndex: 9,
  },
  profile: {
    ...Spacings.padding_top_12,
    paddingBottom: 24,
    ...Colors.background_68276e,
    overflowX: "hidden",
  },
  profile_info_wrap: {
    display: "flex",
    ...FlexBoxs.horizon,
    ...Spacings.padding_left_right_16,
    alignItems: "center",
  },
  profile_image: {
    width: 78,
    height: 78,
    borderRadius: 39,
  },
  corp_badge: {
    ...Arranges.center_v_h,
    width: 83,
    height: 83,
  },
  profile_info: {
    ...Spacings.margin_right_left_20,
    ...FlexBoxs.flex_1,
  },
  profile_info_top: {
    ...FlexBoxs.horizon,
    ...Spacings.margin_bottom_10,
    ...Arranges.center_h,
  },
  profile_text_1: {
    ...Texts.contents_text_32,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
    ...FlexBoxs.flex_1,
  },
  profile_btn: {
    ...Spacings.margin_left_4,
    ...Spacings.border_radius_100,
    ...Arranges.center_v_h,
    width: 18,
    height: 18,
    alignSelf: "center",
  },
  profile_btn1: {
    ...Positions.absolute,
    ...Positions.left,
    top: -2.5,
    width: 0,
    height: 0,
    borderLeftWidth: 5,
    borderLeftColor: "transparent",
    borderRightWidth: 5,
    borderRightColor: "transparent",
    borderBottomWidth: 2.89,
    borderBottomColor: "#68276e",
  },
  profile_btn2: {
    width: 10,
    height: 8,
    backgroundColor: "#68276e",
  },
  profile_btn3: {
    ...Positions.absolute,
    ...Positions.left,
    bottom: -2.5,
    width: 0,
    height: 0,
    borderLeftWidth: 5,
    borderLeftColor: "transparent",
    borderRightWidth: 5,
    borderRightColor: "transparent",
    borderTopWidth: 2.89,
    borderTopColor: "#68276e",
  },
  profile_info_bottom: {
    display: "flex",
    flexDirection: "row",
    columnGap: 28,
  },
  profile_level_text: {
    ...Texts.contents_text_5,
    ...Colors.font_fff,
    opacity: 0.6,
  },
  info_bottom_item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: 2,
  },
  profile_text_2: {
    ...Texts.contents_text_5,
    ...Colors.font_fff,
    opacity: 0.6,
  },
  profile_text_3: {
    ...Texts.contents_text_1,
    ...Texts.font_weight_600,
    ...Colors.font_fff,
  },
  greeting_text: {
    ...Spacings.margin_top_20,
    ...Spacings.padding_left_4,
  },
  profile_text_4: {
    ...Texts.contents_text_31,
    ...Colors.font_fff,
  },
  profile_text_5: {
    ...Texts.contents_text_5,
    ...Texts.text_underline,
    ...Colors.font_fff,
    ...Spacings.margin_top_2,
    opacity: 0.6,
    textDecorationLine: "underline",
  },
  tag: {
    ...Spacings.margin_top_24,
    ...Spacings.padding_left_4,
  },
  tag_item: {
    ...Spacings.margin_right_10,
  },
  tag_text: {
    ...Texts.contents_text_25,
  },
  gradient: {
    ...Positions.absolute,
    ...Positions.right,
    // ...Positions.bottom_24,
    ...Positions.top_24,
    width: 22,
    bottom: 0,
  },

  recommend_users: {
    ...Spacings.margin_top_20,
    ...Spacings.padding_left_16_responsive,
  },
  recommend_user_image: {
    width: 64,
    height: 64,
  },
  recommend_users_text1: {
    ...Spacings.margin_bottom_10,
    ...Texts.contents_text_28,
    ...Texts.font_weight_500,
    ...Colors.font_333,
  },
  recommend_user_item: {
    ...Spacings.margin_right_16,
  },
  recommend_user_text2: {
    ...Texts.contents_text_5,
    ...Arranges.text_align_center,
    ...Spacings.margin_top_4,
    width: 56,
  },

  follow_btn1: {
    ...Colors.background_fff,
    ...Spacings.padding_top_bottom_13,
    ...Spacings.margin_top_24,
    ...Spacings.border_radius_8,
    ...Arranges.center_v_h,
    width: "100%",
    display: "flex",
  },
  follow_btn_text1: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
  follow_btn2: {
    ...Spacings.padding_top_bottom_13,
    ...Spacings.margin_top_24,
    ...Spacings.border_radius_8,
    ...Arranges.center_v_h,
    ...Colors.border_color_fff,
    borderStyle: "solid",
    borderWidth: 1,
    width: "100%",
    display: "flex",

  },
  follow_btn_text2: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  edit_icon_wrap: {
    ...Positions.absolute,
    ...Positions.right,
    ...Positions.bottom,
    ...Colors.background_fff,
    ...Spacings.border_radius_10,
    ...Arranges.center_v_h,
    width: 22,
    height: 22,
  },
  edit_icon: {},

  // 볼트, 커뮤니티 추가
  bx_row_half: {
    display: "flex",
    flexDirection: "row",
    // paddingTop: 16,
    marginHorizontal: -16,
    marginBottom: -20,
    marginTop: 28,
    paddingHorizontal: 2,
    backgroundColor: "rgba(34,34,34,0.2)",
  },
  bx_col: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "50%",
    paddingLeft: 20,
    paddingRight: 18,
    paddingVertical: 20,
    flexWrap: "wrap",
    paddingTop: 20,
    paddingBottom: 20,
  },
  bx_label: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bx_col_first: {
    borderRightWidth: 1,
    borderColor: "rgba(255,255,255,0.1)",
  },
  bx_col_info: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  txt_label: {
    fontSize: 14,
    color: "rgba(255,255,255,0.6)",
    letterSpacing: -0.48,
    marginLeft: 8,
    // marginBottom: 8,
  },
  txt_info_value: {
    fontSize: 20,
    color: "#fff",
    fontWeight: "600",
    letterSpacing: -0.6,
  },

  //법인 팔로우 추가
  bx_add_follow: {
    marginTop: 28,
    paddingLeft: 16,
    paddingRight: 16,
  },
  bx_add_btn: {
    flexDirection: "row",
    marginHorizontal: -5,
  },
  bx_com_btn: {
    paddingHorizontal: 5,
    width: "50%",
  },
  bx_touch_btn: {
    flexDirection: "row",
    height: 46,
    borderWidth: 1,
    borderColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
  },
  btn_full: {
    backgroundColor: "#fff",
  },
  txt_touch_border: {
    fontSize: 14,
    fontWeight: "500",
    letterSpacing: -0.56,
    color: "#fff",
  },
  txt_touch_full: {
    fontSize: 14,
    fontWeight: "500",
    letterSpacing: -0.56,
    color: "#672C78",
  },
  bx_follow_list: {
    flexDirection: "row",
    marginTop: 20,
  },
  bx_profile_img: {
    flexDirection: "row",
    marginRight: 8,
    paddingLeft: 8,
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "auto",
  },
  img_profile: {
    position: "relative",
    width: 24,
    height: 24,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: "#fff",
    backgroundColor: "#ddd",
    marginLeft: -8,
    overflow: "hidden",
  },
  in_img_profile: {
    width: "100%",
    height: "100%",
    borderRadius: 24,
    resizeMode: "cover",
  },
  txt_follow_info: {
    fontSize: 14,
    color: "#fff",
    fontWeight: "400",
    letterSpacing: -0.4,
    lineHeight: 20.27,
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
  },
  txt_follow_info_bold: {
    fontWeight: "700",
  },
  bx_add_volt: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  txt_add_volt: {
    fontSize: 14,
    fontWeight: "500",
    marginLeft: 6,
    color: "#fff",
  },
};