import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as DeleteIcon } from "../../../assets/icons/close_purple_background.svg";

import { FeedMode, FEED_TAG_SEARCH_COUNT } from "../../../constants/Feed";
import QnaWriteStore from "../../../store/QnaWriteStore";
import { TagListItem } from "../../../api/tag/model";
import KeywordText from "../../../components/common/KeywordText";
import "./QnaWriteTag.scss";

const QnaWriteTag = observer(
  ({
    onIsVisibleTagList,
    mode,
    onTagInputFocus,
  }: {
    mode: FeedMode;
    onTagInputFocus?: (() => void) | undefined;
    onIsVisibleTagList?: ((isVisible: boolean) => void) | undefined;
  }) => {
    const { t } = useTranslation();
    const {
      tagSearchResult,
      tagUpdated,
      isVisibleTagList,
      tagList,
      recommendTags,
      deleteTag,
      checkValidWord,
      searchTag,
      addTag,
      setVisibleTagList,
      setTagSearchResult,
    } = QnaWriteStore; //FeedMode.MODIFY

    const [keyword, setKeyword] = useState("");
    const tagRef = useRef<HTMLInputElement>(null);
    const [searchStart, setSearchStart] = useState(0);
    const RECOMMEND_TAG_LENGTH = 5;

    const onClickTag = (name: string) => {
      addTag(name.toLowerCase());
      setKeyword("");
      setVisibleTagList(false);
      setTagSearchResult({ totalCount: 0, list: [] }, 0);
    };
    const [onVisibleState, setOnVisibleState] = useState(false);
    useEffect(() => {
      if (
        !onVisibleState &&
        tagSearchResult != null &&
        tagSearchResult.list.length > 0
      ) {
        setOnVisibleState(true);
        //        console.log("useEffect setOnVisibleState true:");
        tagSearchResult != null &&
          onIsVisibleTagList &&
          onIsVisibleTagList(isVisibleTagList);
      } else if (
        onVisibleState &&
        (tagSearchResult === null ||
          (tagSearchResult && tagSearchResult.list.length === 0))
      ) {
        setOnVisibleState(false);
      }
    }, [tagSearchResult, onIsVisibleTagList, isVisibleTagList, onVisibleState]);
    const onSubmitAction = () => {
      onClickTag(keyword);
    };

    const handleTextChange = (text: string) => {
      text = text.replace(/#/g, "");

      const trimText = text.trim();
      setKeyword(trimText);
      let tempStart = 0;
      if (trimText === keyword) {
        tempStart = searchStart + 1;
      }
      if (trimText !== undefined && trimText !== null && trimText?.length > 0)
        void searchTag(trimText, tempStart);
      else void setTagSearchResult(null, 0);
    };

    return (
      <div id="qna_write_tag_section">
        <div className="tag_wrap">
          <div
            className="tag_input_wrap"
            onClick={() => tagRef.current?.focus()}
          >
            <div className="tag_input_inner">
              <p className="tag_title_text_2" aria-label="Tag">
                {t("screen.qnaWrite.label.tag")}
              </p>
              <input
                aria-label="Tag search"
                className="tag_input"
                ref={tagRef}
                placeholder={t("screen.qnaWrite.placeHolder.tag")}
                onChange={(event) => {
                  setVisibleTagList(true);
                  handleTextChange(event.target.value);
                }}
                value={keyword}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  setVisibleTagList(true);
                  if (e.key === "Enter" && e.nativeEvent.isComposing === false)
                    void checkValidWord(keyword, onSubmitAction);
                }}
                onFocus={() => {
                  onTagInputFocus && onTagInputFocus();
                  setVisibleTagList(true);
                }}
              />
            </div>
          </div>
          {isVisibleTagList &&
            tagSearchResult !== null &&
            tagSearchResult !== undefined &&
            tagSearchResult.list.length > 0 && (
              <div className="auto_complete_list" aria-label="Qna Tag list">
                {tagSearchResult?.list.map((item, index) => (
                  <div className="auto_complete_item" key={index.toString()}>
                    <div
                      onClick={() => {
                        onClickTag(item.tagName);
                      }}
                      aria-label="Add tag has keyword"
                    >
                      <KeywordText
                        matchTextStyle={{
                          fontSize: 13,
                          letterSpacing: -0.52,
                          color: "#a760b1",
                        }}
                        sentence={`#${item.tagName}`}
                        keyword={keyword}
                        aria-label="Tag name"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}

          <div className="tag_list_wrap">
            {tagList?.map((item, index) => (
              <div
                className="tag_item"
                key={index}
                onClick={() => deleteTag(item.contents)}
                aria-label="Delete tag"
              >
                <p className="tag_item_text" aria-label={`Taged${index}`}>
                  #{item.contents}
                </p>
                <DeleteIcon />
              </div>
            ))}
          </div>

          <div className="recommend_tag_wrap">
            <div className="recommend_tag_list_wrap">
              {recommendTags.map((recommend, index) => {
                if (index < RECOMMEND_TAG_LENGTH) {
                  return (
                    <div
                      key={index}
                      className="recommend_tag_item"
                      aria-label={`Add recommend tag${index}`}
                      onClick={() => addTag(recommend.tagName)}
                    >
                      <p className="recommend_tag_item_text">
                        #{recommend.tagName}
                      </p>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
        <div className="line_thick" />
      </div>
    );
  }
);

export default QnaWriteTag;
