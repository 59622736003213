import {
  Arranges,
  Assets,
  Colors,
  FlexBoxs,
  Icons,
  Positions,
  Spacings,
  Texts,
} from "../../assets/styles";
import { observer } from "mobx-react";
import TagMain from "./presenter/TagMain";
import { useLocation, useParams } from "react-router-dom";
import { Styles } from "../../assets/types/Style";
import "./TagScreen.scss";

const TagScreen = observer((data: any) => {
  const location = useLocation();
  const params = location.state;

  const tagId: number = params?.tagId || useParams().tagId;

  return (
    <div id="tag-screen" aria-label="tag home">
      <TagMain tagId={tagId} />
    </div>
  );
});

export default TagScreen;

export const styles: Styles = {
  tag: {
    ...FlexBoxs.flex_1,
    ...Colors.background_255,
  },
  contents_header: {
    ...Colors.background_255,
    ...Spacings.padding_right_left_16_responsive,
  },
  contents_header_text_area: {
    display: "flex",
    flexDirection: "column",
    ...Arranges.center_v_h,
    ...Spacings.margin_top_10,
  },
  contents_header_text1: {
    ...Texts.contents_text_0,
  },
  contents_header_text2: {
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  contents_header_text3: {
    ...Texts.contents_text_18,
    ...Texts.font_weight_bold,
    ...Spacings.margin_top_4,
    lineHeight: 3.5,
  },
  add_tag_btn: {
    display: "flex",
    ...FlexBoxs.horizon,
    ...Spacings.margin_top_20,
    ...Arranges.center_v_h,
    ...Colors.background_f7f4fa,
    height: 53,
  },
  add_tag_btn_text: {
    flex: 0.9,
    ...FlexBoxs.wrap,
    ...Spacings.margin_left_4,
    ...Texts.button_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
    display: "-webkit-box",
    WebkitLineClamp: 2,
  },
  delete_tag_btn: {
    display: "flex",
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Spacings.margin_top_20,
    ...Spacings.border_radius_4,
    ...Colors.border_color_a760b1,
    borderWidth: 1,
    height: 53,
  },
  delete_tag_btn_text: {
    flex: 0.9,
    ...FlexBoxs.wrap,
    ...Spacings.margin_left_4,
    ...Texts.button_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
    display: "-webkit-box",
    WebkitLineClamp: 2,
  },
  relation_product: {
    ...Colors.background_255,
    paddingTop: 32,
  },
  relation_product_header: {
    display: "flex",
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_right_left_16_responsive,
  },
  relation_product_header_text1: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  relation_product_header_text2: {
    ...Colors.font_a760b1,
  },
  relation_product_header_text3: {
    ...Texts.button_text_9,
    ...Texts.font_weight_500,
    ...Colors.font_666666,
  },
  relation_product_list: {
    ...Spacings.margin_top_16,
  },
  relation_product_first_item: {
    ...Spacings.margin_left_16_responsive,
  },
  relation_product_item: {
    width: 175,
    ...Spacings.margin_right_8,
  },
  relation_product_image: {
    width: 175,
    height: 175,
    ...Spacings.border_radius_8,
    ...Spacings.margin_bottom_8,
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.1)",
  },
  relation_product_info: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  relation_product_item_text1: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    display: "-webkit-box",
    WebkitLineClamp: 2,
  },
  relation_product_item_text2: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
  },
  bar: {
    height: 10,
    width: 1,
    ...Colors.background_999999,
    ...Spacings.margin_right_left_6,
  },

  menu_tab: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.padding_right_left_20_responsive,
    ...Spacings.margin_top_40,
  },
  menu: {
    ...Spacings.margin_right_18,
    ...Arranges.center_v_h,
    height: 29,
  },
  menu_text: {
    ...Texts.contents_text_2,
    ...Texts.font_weight_500,
    opacity: 0.4,
  },
  menu_active_text: {
    ...Texts.contents_text_2,
  },

  contents_wrap: {
    ...Spacings.margin_top_16,
  },

  toast: {
    ...Spacings.border_radius_10,
    ...Spacings.padding_right_left_20,
    ...Spacings.padding_top_bottom_20,
    ...Arranges.center_v_h,
    width: "90%",
    backgroundColor: "black",
  },
  toast_text: {
    ...Texts.contents_text_22,
  },
  all_view_modal: {
    width: "100vw",
    ...Positions.fullscreen,
    ...Colors.background_fff,
    ...Colors.border_color_transparent,
    ...Arranges.margin_center_v_h,
    ...Assets.modal_box_shadow,
    ...Spacings.padding_top_10,
  },
  modal_header: {
    height: 48,
    ...Arranges.center_v_h,
    ...Positions.relative,
  },
  modal_body: {
    ...Spacings.padding_left_right_16_responsive,
    ...Spacings.padding_top_20,
  },
  icons: {
    ...Icons.icon_32,
    ...Positions.absolute,
    ...Positions.top_6,
    ...Positions.right_10,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  modal_tag_text: {
    ...Texts.contents_text_2,
    ...Colors.font_a760b1,
  },
  modal_body_text: {
    ...Texts.contents_text_2,
  },
  modal_list: {
    paddingBottom: 100,
  },
  modal_body_title: {
    ...Spacings.margin_bottom_40,
  },
  row: {
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.margin_bottom_8,
  },
  last_row: {
    marginBottom: 120,
  },

  alert_modal: {
    width: "82vw",
    ...Colors.background_fff,
    ...Spacings.border_top_left_radius_12,
    ...Spacings.border_top_right_radius_12,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
    ...Colors.border_color_transparent,
    ...Arranges.margin_center_v_h,
    ...Assets.modal_box_shadow,
  },
  alert_modal_header: {
    ...Arranges.center_v_h,
    ...Positions.relative,
    height: 64,
  },
  alert_modal_body: {
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
    ...Spacings.padding_bottom_24,
    ...Spacings.padding_right_left_16,
  },
  alert_header_text: {
    ...Texts.modal_text_0,
  },
  contents_text: {
    ...Texts.contents_text_3,
  },
  alert_modal_footer: {
    ...FlexBoxs.horizon,
    height: 48,
  },
  separate: {
    ...Colors.two_btn_border_color_666666,
    borderWidth: 0.5,
    width: 1,
  },
  left_btn: {
    ...Spacings.border_bottom_left_radius_12,
  },
  right_btn: {
    ...Spacings.border_bottom_right_radius_12,
  },
  button: {
    ...Colors.background_222,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  footer_button_text: {
    ...Texts.button_text_3,
    ...Colors.font_fff,
  },
  tabTextContainerStyle: {
    ...Colors.background_transparent,
  },
  tabTextStyle: {
    ...Texts.contents_text_2,
    opacity: 0.4,
    lineHeight: 3,
    textAlignVertical: "bottom",
    ...Spacings.padding_right_16,
    ...Spacings.padding_top_bottom_12,
  },
  tabTextActiveStyle: {
    ...Texts.contents_text_2,
    lineHeight: 3,
    textAlignVertical: "bottom",
    ...Spacings.padding_right_16,
    ...Spacings.padding_top_bottom_12,
  },
  tabWrapperStyle: {
    ...Spacings.padding_top_bottom_10,
  },
  tabsContainerStyle: {
    flexDirection: "row",
    ...Spacings.padding_left_right_16_responsive,
  },
  tabTextContainerActiveStyle: {},
};
