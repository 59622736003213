import { action, computed, makeObservable, observable } from "mobx";

class RemoteConfigStore {
  constructor() {
    makeObservable(this);
  }

  @observable config: any = {};
  @observable show_recommend_input: any;
  @observable show_gender_birthyear: any;
  @observable show_withdrawagree_gift: any;
  @observable show_brand_community_introduction: any;
  @observable show_brand_page: any;
  @observable main_mall_notice: any;
  @observable show_mall_tutorial: any;
  @observable show_up_product_tag: any;
  @observable up_product_magazine_feedId: any;
  @observable show_up_product_quick_menu: any;

  @action getConfig = (key: string, defaultValue: any) => {
    if (!this.config) return defaultValue;

    switch (typeof defaultValue) {
      case "number":
        return this.config[key]?.asNumber() || defaultValue;
      case "boolean":
        return this.config[key]?.asBoolean() || defaultValue;
      case "string":
        return this.config[key]?.asString() || defaultValue;
    }
  };

  @action setUseRemoteConfigs = (config: any) => {
    this.config = config;
    this.show_recommend_input = this.getConfig("show_recommend_input", "false");
    this.show_gender_birthyear = this.getConfig(
      "show_gender_birthyear",
      "true"
    );
    this.show_withdrawagree_gift = this.getConfig(
      "show_withdrawagree_gift",
      "false"
    );
    this.show_brand_community_introduction = this.getConfig(
      "show_brand_community_introduction",
      "false"
    );
    this.show_brand_page = this.getConfig("show_brand_page", "false");
    this.main_mall_notice = this.getConfig("main_mall_notice", "{}");
    this.show_mall_tutorial = this.getConfig("show_mall_tutorial", "true");
    this.show_up_product_tag = this.getConfig("show_up_product_tag", "false");
    this.up_product_magazine_feedId = this.getConfig(
      "up_product_magazine_feedId",
      0
    );
    this.show_up_product_quick_menu = this.getConfig(
      "show_up_product_quick_menu",
      "false"
    );
  };
}

export default new RemoteConfigStore();
