import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { AbTypeCode } from "../../../constants/ab";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import AbEndDateBadge from "../../ab/presenter/AbEndDateBadge";
import { getDateStr, isPast } from "../../../utils/datetime";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { Styles } from "../../../assets/types/Style";
import { Arranges, FlexBoxs, Positions, Spacings, Texts, Colors, Assets } from "../../../assets/styles";
import { FeedItem } from "../../../api/brand/model";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { Virtuoso } from "react-virtuoso";

const BrandStoryAB = observer(({ renderData, listKey, onEndReached }: { renderData: FeedItem[]; listKey: string, onEndReached: any }) => {

  const { t } = useTranslation();
  type AbStatus = "NOT_VOTED" | "ALREADY_VOTED" | "CURRENTLY_VOTED" | "EXPIRED";

  const AbItem = ({ index, ab }: { index: number; ab: FeedItem }) => {
    const status = (ab: FeedItem) => {
      const expired = isPast(ab.feedEndDate || "");
      const alreadVoted = ab.abFlag !== null;
      const currentlyVoted = ab.abFlag !== null && !alreadVoted;
      let status = "NOT_VOTED";
      if (expired) {
        status = "EXPIRED";
      } else if (alreadVoted) {
        status = "ALREAD_VOTED";
      } else if (currentlyVoted) {
        status = "CURRENTLY_VOTED";
      }
      return status as AbStatus;
    };

    const handleNativageToDetailScreen = () => {
      goTo(`/AB/${ab.feedId}`, { state: { index: index } });
    };

    return (
      <TouchableWithAuthCheck
        style={{ width: '100%' }}
        onPress={handleNativageToDetailScreen}
      >
        <div style={{ ...styles.item, ...(index === 0 ? { paddingTop: 0 } : {}) }}>
          <div style={styles.item_header}>
            <div style={styles.item_header_left}>
              {
                ab.feedEndDate &&
                <div style={{ paddingRight: 10 }}>
                  <AbEndDateBadge endDate={ab.feedEndDate} />
                </div>
              }
              <p style={styles.item_header_text1}>
                {t("screen.ab.label.voteCount", { count: ab.voterCount ? Number(ab.voterCount) : 0 })}
              </p>
            </div>
            <div style={styles.item_header_right}>
              <div>
                <p style={styles.item_header_text2}>
                  {ab.nickname}
                </p>
              </div>
              {ab.subscribeYn === "Y" && (
                <div style={styles.flag_participating}>
                  <p style={styles.flag_participating_txt}>{t("screen.brandCommunity.label.subscribing")}</p>
                </div>
              )}
              <div style={styles.item_text_bar} />
              <p style={styles.item_header_text1}>{getDateStr(ab.createdDatetime)}</p>
            </div>
          </div>
          <div style={styles.item_body}>
            <div onClick={handleNativageToDetailScreen}>
              <p style={{ 
                  ...styles.item_body_text1, 
                  ...{overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '1', lineClamp: '1', WebkitBoxOrient: 'vertical'} 
                  }}
                >
                {ab.feedTitle}
              </p>
            </div>
            <div onClick={handleNativageToDetailScreen}>
              <p style={{ 
                ...styles.item_body_text2, 
                ...{overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '2', lineClamp: '2', WebkitBoxOrient: 'vertical'}
                }}
              >
                {ab.feedContents}
              </p>
            </div>
            <div style={styles.select_wrap}>
              {[AbTypeCode.A, AbTypeCode.B].map((option) => {
                const resultOpened = status(ab) !== "NOT_VOTED";
                const optionIsChoosen = ab.abFlag && ab.abFlag === option;
                const isOptionA = option === AbTypeCode.A;
                const label = isOptionA ? ab.adesc : ab.bdesc;
                const filePath = isOptionA ? ab.afilePath : ab.bfilePath;
                const thumbnailFilePath = isOptionA ? ab.athumbnailFilePath : ab.bthumbnailFilePath;

                const stylesByPosition = {
                  wrapper: isOptionA ? styles.select_left_text_btn : styles.select_right_text_btn,
                  dimmed: isOptionA ? styles.select_left_inner : styles.select_right_inner,
                  backgroundColor: isOptionA ? { backgroundColor: "#fcfafc" } : { backgroundColor: "#fafbfc" },
                  text_wrap: isOptionA ? styles.a_wrap : styles.b_wrap,
                  text: isOptionA ? styles.select_a_text : styles.select_b_text,
                  color: isOptionA ? { color: "#c07bca" } : { color: "#7094bd" },
                };

                return (
                  <div key={`${ab.feedId || ""}-${option || ""}`} style={{ flex: 1, ...(isOptionA ? { paddingRight: 2 } : { paddingLeft: 2 }) }}>
                    <div style={{ position: 'relative', display: 'flex', aspectRatio: 1 }}>
                      <FastImageWithFallback
                        source={{ uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${thumbnailFilePath || ""}` }}
                        fallbackImageUri={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${filePath || ""}`}
                        style={{
                          ...stylesByPosition.wrapper,
                          width: '100%',
                          height: '100%',
                          objectFit: "cover",
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: 0, left: 0, right: 0, bottom: 0,
                          ...styles.select_text_btn,
                          ...((optionIsChoosen || resultOpened)
                            ? { backgroundColor: "#f5f5f5" }
                            : stylesByPosition.backgroundColor),
                          backgroundColor: '#22222266'
                        }}
                      >
                        {optionIsChoosen ? (
                          <>
                            <div style={{ ...stylesByPosition.dimmed, ...styles.selected_inner, opacity: 1 }} />
                            <CheckIcon />
                            <p style={styles.selected_text}>{label}</p>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                ...styles.ab_wrap,
                                ...((optionIsChoosen || resultOpened)
                                  ? styles.not_selected_wrap
                                  : stylesByPosition.text_wrap),
                              }}
                            >
                              <p style={styles.select_text}>{option}</p>
                            </div>
                            <div style={styles.select_text_wrap}>
                              <p
                                style={{
                                  ...stylesByPosition.text,
                                  ...((optionIsChoosen || resultOpened) ? { color: "#999999" } : stylesByPosition.color),
                                }}
                              >
                                {label}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    {/* <div style={{ position: 'relative', display: 'flex' }}>
                      <div style={{ ...stylesByPosition.dimmed, ...(optionIsChoosen ? styles.selected_inner : {}) }} />
                      {optionIsChoosen ? <CheckIcon /> : <p style={styles.select_text}>{option}</p>}
                      <p style={styles.selected_text}>{label}</p>
                    </div> */}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </TouchableWithAuthCheck>
    );
  };
  return (
    <div>
      {renderData?.length > 0 && (
        <Virtuoso
          style={styles.contents}
          useWindowScroll
          data={renderData}
          itemContent={(index: number, item: FeedItem) => {
            return (
              <AbItem
                index={index}
                ab={item}
                key={item.feedId}
              />
            );
          }}
          endReached={() => {
            if (renderData && renderData.length == 0) return;
            void onEndReached();
          }}
        />)}
    </div>
  );
});

export default BrandStoryAB;

export const styles: Styles = {
  title_background: {
    ...Spacings.border_radius_6,
    ...Colors.background_f7e237,
    ...Positions.absolute,
    ...Positions.bottom,
    left: -4,
    right: -4,
    height: 14,
  },
  ab_all_view: {
    ...FlexBoxs.flex_1,
    ...Colors.background_fff,
  },
  contents: {
    ...Colors.background_fff,
  },
  my_Ab_List: {
    ...Spacings.padding_top_26,
  },
  tabs: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  tab_btn: {
    height: 55,
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Colors.border_color_eee,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: 'solid'
  },
  tab_text: {
    ...Texts.button_text_6,
    ...Texts.font_weight_normal,
    ...Colors.font_999,
  },
  tab_active_btn: {
    ...Colors.border_color_222,
  },
  tab_active_btn_text: {
    ...Texts.button_text_6,
    ...Texts.font_weight_500,
  },

  ab_horizontal_list_wrap: {
    ...Spacings.margin_top_32,
  },
  ab_horizontal_list_title: {
    ...Spacings.padding_left_right_20,
  },
  ab_horizontal_list_title_text: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_000,
  },
  ab_horizontal_list: {
    ...Spacings.margin_top_16,
    paddingTop: 5,
  },
  first_item: {
    ...Spacings.margin_left_16,
  },
  ab_horizontal_item: {
    ...FlexBoxs.flex,
    ...Arranges.between,
    ...Spacings.margin_right_16,
    ...Spacings.margin_bottom_10,
    ...Spacings.border_radius_6,
    width: '80%',
  },
  ab_horizontal_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_top_26,
    ...Spacings.padding_left_16,
    ...Spacings.padding_right_5,
    ...Spacings.border_top_left_right_radius_6,
  },
  ab_horizontal_left: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  profile_image: {
    width: 24,
    height: 24,
    ...Spacings.border_radius_32,
    ...Spacings.margin_right_6,
  },
  ab_horizontal_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  ab_horizontal_header_text: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
  },
  text_bar: {
    height: 15,
    width: 1,
    ...Colors.background_d9d9d9,
    ...Spacings.margin_right_left_9,
  },
  d_day: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Colors.background_a760b1,
    ...Spacings.border_radius_4,
    width: 42,
    height: 25,
  },
  date_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_bold,
    ...Colors.font_fff,
  },
  expire: {
    ...Colors.background_999999,
  },
  ab_horizontal_contents: {
    ...Spacings.margin_top_10,
    ...Spacings.padding_right_left_20,
  },
  ab_horizontal_contents_text: {
    ...Texts.contents_text_28,
    ...Texts.font_weight_500,
  },

  ab_horizontal_select: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.margin_top_10,
  },
  ab_horizontal_image: {
    aspectRatio: 1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },

  left_inner: {
    ...Positions.fullscreen,
    backgroundColor: "rgba(34, 34, 34, 0.4)",
    ...Spacings.border_bottom_left_radius_6,
  },
  right_inner: {
    ...Positions.fullscreen,
    backgroundColor: "rgba(34, 34, 34, 0.4)",
    ...Spacings.border_bottom_right_radius_6,
  },
  selected_inner: {
    ...Colors.background_c07bca,
    opacity: 0.75,
  },
  ab_horizontal_select_text1: {
    ...Texts.contents_text_39,
    ...Texts.font_weight_bold,
    ...Colors.font_fff,
  },
  ab_horizontal_select_text2: {
    ...Spacings.margin_top_7,
    ...Texts.contents_text_25,
    ...Texts.font_weight_500,
  },
  ab_horizontal_select_text3: {
    ...Spacings.margin_top_10,
    ...Texts.contents_text_25,
    ...Texts.font_weight_500,
  },
  ab_horizontal_select_text4: {
    ...Spacings.margin_top_25,
    ...Texts.contents_text_32,
    ...Colors.font_fff,
    ...Texts.font_weight_600,
  },
  bar: {
    height: 6,
    ...Colors.background_f5f5f5,
    ...Spacings.margin_top_40,
    ...Spacings.margin_bottom_14,
  },
  sort_btn_wrap: {
    ...Spacings.padding_top_26,
    ...Spacings.padding_right_left_16,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  sort_btn: {
    width: 78,
    height: 34,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_300,
    ...Spacings.margin_right_6,
    ...Colors.border_color_d9d9d9,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  sort_active_btn: {
    ...Colors.background_222,
    ...Colors.border_color_222,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  sort_btn_text: {
    ...Texts.contents_text_41,
    ...Colors.font_222,
  },
  sort_btn_active_text: {
    ...Colors.font_fff,
  },
  item: {
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_32,
    ...Spacings.padding_bottom_32,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    ...Colors.border_color_eee,
  },
  last_item: {
    borderBottomWidth: 0,
  },
  item_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  item_header_left: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  date_wrap: {
    height: 25,
    ...Spacings.padding_left_right_6,
    ...Spacings.border_radius_4,
    ...Spacings.margin_right_10,
    ...Colors.background_a760b1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  date_wrap_text: {
    ...Texts.contents_text_26,
    ...Colors.font_fff,
  },
  item_header_text1: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
  },
  item_header_right: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    alignItems: "center",
  },

  item_text_bar: {
    width: 1,
    height: 12,
    ...Colors.background_eeeeee,
    ...Spacings.margin_right_left_6,
  },
  item_body: {
    ...Spacings.margin_top_12,
  },
  item_body_text1: {
    ...Spacings.padding_right_left_4,
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    textAlign: 'start'
  },
  item_body_text2: {
    ...Spacings.padding_right_left_4,
    ...Spacings.margin_top_8,
    ...Texts.contents_text_0,
    textAlign: 'start'
  },
  select_wrap: {
    ...Spacings.margin_top_20,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  select_btn: {},
  select_image: {
    width: '45.6%',
    height: '45.6%',
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  select_right_inner: {
    ...Positions.fullscreen,
    backgroundColor: "rgba(34, 34, 34, 0.4)",
    ...Spacings.border_bottom_right_radius_6,
    ...Spacings.border_top_right_radius_6,
  },
  select_left_inner: {
    ...Positions.fullscreen,
    backgroundColor: "rgba(34, 34, 34, 0.4)",
    ...Spacings.border_bottom_left_radius_6,
    ...Spacings.border_top_left_radius_6,
  },
  select_text_btn: {
    width: '100%',
    height: '100%',
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
  },
  select_left_text_btn: {
    ...Spacings.border_bottom_left_radius_6,
    ...Spacings.border_top_left_radius_6,
  },
  select_right_text_btn: {
    ...Spacings.border_bottom_right_radius_6,
    ...Spacings.border_top_right_radius_6,
  },
  ab_wrap: {
    width: 40,
    height: 40,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_6,
    ...Spacings.margin_bottom_10,
  },
  a_wrap: {
    ...Colors.background_c07bca,
  },
  b_wrap: {
    ...Colors.background_7094bd,
  },
  select_text: {
    ...Texts.contents_text_39,
    ...Texts.font_weight_bold,
    ...Colors.font_fff,
  },
  select_text_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  select_a_text: {
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },
  select_b_text: {
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_4c73a0,
  },
  select_text_active_btn: {
    ...Colors.background_c07bca,
  },
  selected_text: {
    ...Spacings.margin_top_10,
    ...Colors.font_fff,
  },
  not_selected_wrap: {
    ...Colors.background_c4c4c4,
  },
  not_selected_text: {
    ...Colors.font_999,
  },
  result_wrap: {
    ...Spacings.margin_top_10,
    ...Spacings.margin_bottom_30,
  },
  result: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    height: 40,
    ...Spacings.border_radius_6,
    overflow: "hidden",
  },
  win: {
    ...Colors.background_f0dff2,
  },
  win_text: {
    ...Texts.contents_text_40,
    ...Texts.font_weight_bold,
    ...Colors.font_a760b1,
  },
  lose: {
    ...Colors.background_f5f5f5,
  },
  lose_text: {
    ...Texts.contents_text_40,
    ...Texts.font_weight_bold,
    ...Colors.font_999,
  },
  result_left: {
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    ...Spacings.border_top_left_radius_6,
    ...Spacings.border_bottom_left_radius_6,
  },
  result_left_text: {
    ...Positions.absolute,
    ...Positions.left,
    ...Spacings.margin_left_10,
    ...Colors.background_transparent,
    zIndex: 2,
  },
  result_right: {
    ...FlexBoxs.flex,
    ...Arranges.end_h,
    ...Arranges.center_v,
    ...Spacings.border_top_right_radius_6,
    ...Spacings.border_bottom_right_radius_6,
  },
  result_right_text: {
    ...Positions.absolute,
    ...Positions.right,
    ...Spacings.margin_right_10,
    ...Colors.background_transparent,
    zIndex: 2,
  },
  result_count: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.margin_top_7,
  },
  result_count_right_icon: {
    ...Spacings.margin_right_2,
  },
  result_count_left_icon: {
    ...Spacings.margin_right_2,
  },
  result_count_left: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  result_count_win_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },
  result_count_right: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  result_count_lose_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_666666,
  },
  nothing_search_img: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    paddingTop: 100,
  },
  empty_wrap: {
    ...Positions.fullscreen,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  go_write_btn_wrap: {
    height: 53,
    borderRadius: 6,
    ...Spacings.margin_left_right_16,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_top_bottom_20,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#be69c3",
  },
  go_write_btn_wrap_txt1: {
    ...Texts.contents_text_38,
    ...Colors.font_be69c3,
    fontWeight: "500",
  },
  go_write_btn_wrap_txt2: {
    ...Texts.btn_text_2,
    ...Colors.font_be69c3,
    fontWeight: "500",
  },
  go_write_btn_wrap_txt_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  item_header_text2: {
    ...Texts.contents_text_5,
    ...Colors.font_666666,
  },

  flag_participating: {
    height: 18,
    backgroundColor: "#F7F4FA",
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    borderRadius: 2,
    paddingVertical: 2,
    paddingHorizontal: 8,
    marginLeft: 3,
    overflow: "hidden",
    padding: '0px 5px'
  },
  flag_participating_txt: {
    fontSize: 10,
    color: "#BE69C2",
    letterSpacing: -0.4,
  },
  //마이홈

  home_ab: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_top_14,
  },

  empty: {
    ...FlexBoxs.flex_1,
  },
  empty_inner: {
    ...Positions.fullscreen,
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  empty_icon: {
    marginLeft: 20,
  },
  empty_btn: {
    ...Spacings.margin_top_14,
    ...Spacings.border_radius_300,
    ...Spacings.padding_top_bottom_12,
    ...Spacings.padding_left_right_20,
    ...Colors.border_color_a760b1,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  empty_btn_text: {
    ...Texts.button_text_4,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },

  ab_list: {
    ...Spacings.padding_left_right_20,
  },
  ab_item: {
    ...Spacings.padding_bottom_32,
    ...Spacings.margin_bottom_35,
    ...Colors.border_color_f5f5f5,
    borderBottomWidth: 6,
  },
  ab_item_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  ab_item_header_left: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  ab_item_header_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_999,
  },
  ab_item_header_btn: {
    ...Spacings.border_radius_4,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    width: 43,
    height: 22,
  },
  progress_btn: {
    ...Colors.border_color_a760b1,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  progress_btn_text: {
    ...Colors.font_a760b1,
  },
  terminated_btn: {
    ...Colors.background_c4c4c4,
  },
  terminated_btn_text: {
    ...Colors.font_fff,
  },
  ab_item_header_btn_text: {
    ...Texts.contents_text_47,
  },
  ab_main_text: {
    ...Spacings.margin_top_10,
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  ab_select_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.margin_top_24,
  },
  ab: {},
  inner: {
    ...Spacings.border_radius_8,
    ...Positions.fullscreen,
    ...Colors.background_222,
    opacity: 0.5,
  },
  ab_text1: {
    ...Texts.contents_text_33,
    ...Colors.font_fff,
    ...Spacings.margin_right_10,
  },
  ab_text2: {
    width: '31.5%',
    ...Texts.contents_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  ab_terminated: {
    width: '44.1%',
    height: '8%',
    ...Spacings.border_radius_8,
    ...Spacings.padding_right_left_14,
    ...Spacings.padding_top_bottom_14,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  selected: {
    ...Colors.background_a760b1,
  },
  not_selected: {
    ...Colors.background_666,
  },
};
