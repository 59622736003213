import { ImageForUI } from "../../utils/album";

export interface FileUrlRequest {
  bucketTypeCode: string;
  uploadTypeCode: string;
  fileNamesWithExtension: string[];
  generateThumbnailYN: string;
}

export interface FileUrlResponse {
  presignedUrl: string;
  filePath: string;
  fileThumbnailPath?: string;
}

export interface PreSignedUrlRequestForAllPath {
  bucketTypeCode: string;
  uploadTypeCode: string;
  originalFileName: string;
  thumbnailFileName: string;
}

export interface PreSignedUrlResponseForAllPath {
  originalPreSignedUrl: string;
  thumbnailPreSignedUrl: string;
  originalFilePath: string;
  thumbnailFilePath?: string;
}

export interface File {
  fileId?: number;
  originalFileName: string;
  fileName: string;
  filePath: string;
  thumbnailFilePath?: string;
  fileSize: number;
  fileType: string;
  fileExtension: string;
  thumbnailYn: string; //"N"
  bucketTypeCode?: string;
  uploadTypeCode?: string;
  fileResolution?: string;
}
export const toRatio = (ratio: string) => {
  if (ratio !== undefined) {
    const strR = ratio.split("x");
    if (strR.length === 2) {
      const firstW = Number(strR[0]);
      const firstH = Number(strR[1]);

      return firstW / firstH;
    }
  }
  return 1;
};
export const toHeight = (ratio: string, width: number) => {
  let defh = width;
  if (ratio !== undefined) {
    const strR = ratio.split("x");
    if (strR.length === 2) {
      const firstW = Number(strR[0]);
      const firstH = Number(strR[1]);

      defh = (firstH / firstW) * width;
    }
  }
  return defh;
};

export const toRatioSizeFile = (
  ratioString: string
): { ratio: number; ratioPercentString: string } | undefined => {
  if (ratioString) {
    const strR = ratioString.split("x");
    if (strR.length === 2) {
      const firstW = Number(strR[0]);
      const firstH = Number(strR[1]);
      const ratio = firstH / firstW;
      return {
        ratio,
        ratioPercentString: `${ratio * 100}%`,
      };
    }
  }
  return undefined;
};

export interface QaFileResponse {
  fileName: string;
  filePath: string;
  fileExtension: string;
}
export interface UploadFile {
  fileId?: number;
  originalFileName: string;
  fileName: string;
  filePath: string;
  imageLocalFilePath?: string;
  videoLocalFilePath?: string;
  thumbnailFilePath?: string;
  thumbnailTempPath?: string;
  imageOriginLocalFilePath?: string;
  // 이미지 압축파일이 떨궈지는 곳
  imageTempPath?: string;
  // 압축파일이 떨궈지는 곳
  videoTempPath?: string;
  fileResolution?: string;
  fileSize: number;
  fileType: string;
  fileExtension: string;
  thumbnailYn: string;
  bucketTypeCode?: string;
  uploadTypeCode?: string;
  modified?: boolean;
  attr1?: string;
  isCompressed?: boolean;
}
