import { forwardRef, useCallback, useRef } from "react";
import PreferenceStore from "../../../store/PreferenceStore";
import { observer } from "mobx-react";
import { PreferenceTypeCode } from "../../../constants/Preference.enum";
import PreferenceHeader from "./PreferenceHeader";
import MemberStore from "../../../store/MemberStore";
import { RankingPosterResponse } from "../../../api/member/model";
import { useTranslation } from "react-i18next";
import { UserStyleItem } from "./UserStyleItem";
import { Virtuoso } from "react-virtuoso";

const UserStylePreference = observer(() => {
  const { preferenceSteps } = PreferenceStore;

  const { userStyleRankingPosters, followSet } = MemberStore;
  const currentStep =
    preferenceSteps.findIndex(
      (item) => item === PreferenceTypeCode.USER_STYLE
    ) + 1;
  const totalStepCount = preferenceSteps.length;

  const ref = useRef(null);
  const { t } = useTranslation();

  const renderItem = useCallback(
    (index: number, item: RankingPosterResponse) => (
      <UserStyleItem
        item={item}
        index={index}
        follow={followSet.has(item.memberId)}
      />
    ),
    [followSet]
  );
  return (
    <>
      <div style={{ overflowY: "hidden" }}>
        {/* <FlatList
          ref={ref}
          keyExtractor={(item, index) => `${item.memberId}`}
          ListHeaderComponent={
            <PreferenceHeader
              currentStep={currentStep}
              totalStep={totalStepCount}
              title1={t("screen.preference.userStyle.title")}
            />
          }
          ListHeaderComponentStyle={{ ...Spacings.margin_bottom_40 }}
          accessibilityLabel={PreferenceTypeCode.USER_STYLE}
          data={userStyleRankingPosters.slice()}
          ListFooterComponent={<View style={[{ height: 60 }]} />}

          showsVerticalScrollIndicator={false}
          renderItem={renderItem}
          initialNumToRender={5}
          windowSize={3}
        /> */}
        <Virtuoso
          ref={ref}
          aria-label={PreferenceTypeCode.USER_STYLE}
          data={userStyleRankingPosters.slice()}
          context={{ count: userStyleRankingPosters.length }}
          itemContent={renderItem}
          useWindowScroll
          style={{ height: "100%" }}
          components={{
            Header: () => (
              <>
                <PreferenceHeader
                  currentStep={currentStep}
                  totalStep={totalStepCount}
                  title1={t("screen.preference.userStyle.title")}
                />
                <div style={{ marginBottom: 45 }} />
              </>
            ),
            Footer: () => <div style={{ height: 80 }} />,
            Item: forwardRef(
              (
                { style, children, ...props },
                ref: React.LegacyRef<HTMLDivElement> | undefined
              ) => {
                const space =
                  props.context?.count === props["data-index"] + 1 ? 12 : 24
                return (
                  <div
                    ref={ref}
                    {...props}
                    style={{
                      ...style,
                      margin: 0,
                    }}
                  >
                    {children}
                    <div
                      style={{
                        height: space,
                        backgroundColor: "transparent",
                      }}
                    />
                  </div>
                )
              }
            ),
          }}
        />
      </div>
    </>
  );
});

export default UserStylePreference;
