import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { ReactComponent as ActiveQuestionIcon } from "../../../assets/icons/question_active.svg";
import SearchCountAndSort from "./SearchCountAndSort";
import { SearchTabMenu, SEARCH_RESULT_COUNT } from "../../../constants/Search";
import SearchStore from "../../../store/SearchStore";
import SearchEmpty from "./SearchEmpty";
import { getDateStr } from "../../../utils/datetime";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import CommonStore from "../../../store/CommonStore";
import { SearchItem } from "../../../api/search/model";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { FEED_CALL_STATE } from "../../../constants/Feed";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { PullToRefresh } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import { ReactComponent as DarkSpinner } from "../../../assets/animations/darkSpinner.svg";
import { useWindowScroll } from "react-use";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import "./SearchQna.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { useLocation } from "react-router-dom";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import SearchResultLayout from "../SearchResultLayout";

const SearchQna = observer(() => {
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const {
    searchText,
    qnaResult,
    loadingInList,
    qnaSortAndIndex,
    getLoadingInList,
    refreshing,
    getSearchResult,
    setRefreshing,
    reportQnaCount,
  } = SearchStore;

  const { x, y } = useWindowScroll();

  const { t } = useTranslation();

  const [afterMount, setAfterMount] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setAfterMount(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (afterMount) {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

      if (bottom) {
        if ((qnaResult?.totalCount || 0) > 0 && !loadingInList) {
          const tempStart = qnaSortAndIndex.lastIndex + 1;
          if ((qnaResult?.totalCount || 0) > tempStart * SEARCH_RESULT_COUNT) {
            void getSearchResult(
              searchText,
              SearchTabMenu.Qna,
              qnaSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              tempStart
            );
          }
        }
      }
    }
  }, [y]);

  const moveToQnaDetail = (item: SearchItem, index: number) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.QNA_READ,
      (item.feedId ?? 0).toString(),
      ActPageCode.SEARCH_QNA,
      undefined,
      index?.toString(),
      searchText,
      SearchStore.getSortType()
    );

    goTo(`/QnA/${item.feedId}`, {
      state: {
        feedId: item.feedId,
        callState: FEED_CALL_STATE.SEARCH_QNA,
      },
    });
  };

  return (
    <SearchResultLayout tabType={SearchTabMenu.Qna}>
      <div id="SearchQna" className="tab_content" aria-label="Search qna">
        <PullToRefresh
          onRefresh={async () => {
            await sleep(1000);
            setRefreshing(true);
            void getSearchResult(
              searchText,
              SearchTabMenu.Qna,
              qnaSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              0
            );
          }}
          renderText={(status) => {
            return (
              <div>
                {status === "refreshing" && (
                  <DarkSpinner style={{ width: 50, height: 50 }} />
                )}
              </div>
            );
          }}
        >
          <SearchCountAndSort
            type={SearchTabMenu.Qna}
            sort={qnaSortAndIndex.sort}
            count={
              qnaResult?.totalCount != undefined
                ? qnaResult?.totalCount - reportQnaCount
                : 0
            }
          />

          <div className="scroll_wrap qna_list_wrap">
            {qnaResult?.list?.slice() && qnaResult?.list?.slice().length > 0 ? (
              qnaResult?.list?.slice().map((item: any, index: number) => {
                return (
                  <div
                    className="qna_item_wrap qna_item_wrap_border"
                    style={{ paddingTop: 16, width: "100%" }}
                    onClick={() => moveToQnaDetail(item, index)}
                    aria-label={`Go to QnaDetailScreen ${index}`}
                  >
                    <>
                      <div className="qna_item">
                        <ActiveQuestionIcon />
                        <div className="qna_txt_wrap">
                          <div className="qna_text1">{item.feedTitle}</div>
                          <div
                            className={clsx(
                              "qna_text2",
                              item.thumbnailFilePath !== undefined &&
                                item?.thumbnailFilePath?.length > 0 &&
                                "qna_text2_with_img"
                            )}
                          >
                            {item.contents}
                          </div>
                        </div>
                        {item.thumbnailFilePath !== undefined &&
                          item.thumbnailFilePath.length > 0 && (
                            <FastImageWithFallback
                              className="qna_img"
                              source={{
                                uri: `${PUBLIC_BUCKET_URL}${
                                  item.thumbnailFilePath || ""
                                }`,
                              }}
                            />
                          )}
                      </div>
                      <div className="qna_info">
                        <div className="qna_info_first">
                          <TouchableWithAuthCheck
                            onPress={() => {
                              goTo(`/user/${item.nickname}`, {
                                state: {
                                  targetUserId: item.memberUuid,
                                  nickname: item.nickname,
                                  profileUrl: item.profileUrl,
                                  profileBackgroundColor:
                                    item.profileBackgroundColor,
                                },
                              });
                            }}
                            aria-label={`Go to UserHomeScreen ${index}`}
                          >
                            <div className="info_txt1">{item.nickname}</div>
                          </TouchableWithAuthCheck>
                          <div className="qna_bar" />
                          <div className="info_txt2">
                            {getDateStr(item.createdDatetime)}
                          </div>
                        </div>
                        <div className="qna_info_second">
                          <div className="info_txt2">
                            {t("screen.search.label.reply")}
                            {item.commentCount}
                          </div>
                          <div className="qna_bar" />
                          <div className="info_txt2">
                            {t("screen.search.label.like")}
                            {item.likeCount}
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                );
              })
            ) : (
              <SearchEmpty isGrid={false} />
            )}
          </div>

          {getLoadingInList ? <CustomActivityIndicatorSmall /> : <></>}
        </PullToRefresh>
      </div>
    </SearchResultLayout>
  );
});

export default SearchQna;
