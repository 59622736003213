import { observer } from "mobx-react";
import { useEffect } from "react";
import { ReactComponent as TabPlus } from "../../assets/icons/icon_add_plus.svg";
import { ReactComponent as FailOpen } from "../../assets/icons/upload/post_fail_open.svg";
import UploadStore from "../../store/upload/UploadStore";
import WriteButtonStore from "../../store/WriteButtonStore";
import "./WriteButton.scss";
import WriteButtonBottomSheet from "./WriteButtonBottomSheet";
import Lottie from "lottie-react";
import { NotificationModal } from "../modals";

const WriteButton = observer(() => {
  useEffect(() => {
    UploadStore.isDefaultStatus &&
      WriteButtonStore.setNotifyContext({
        type: "",
        isShow: false,
        title: "",
        contents: "",
      });
  }, [UploadStore.isDefaultStatus]);

  return (
    <div id="WriteButton">
      <div
        className="post_plus"
        aria-label="plus"
        onClick={() => WriteButtonStore.ClickWriteButton()}
      >
        <div
          className={UploadStore.isFail ? "circle fail" : "circle"}
          style={{
            bottom: WriteButtonStore.bottom,
          }}
        >
          {UploadStore.isDefaultStatus && <TabPlus />}
          {UploadStore.isUploading && (
            <Lottie
              animationData={require("../../assets/lottie/uploading.json")}
              loop={true}
            />
          )}
          {UploadStore.isFail && <FailOpen />}
        </div>
      </div>

      {WriteButtonStore.notifyContext.isShow && (
        <NotificationModal
          isVisible={WriteButtonStore.notifyContext.isShow}
          title={WriteButtonStore.notifyContext.title}
          contents1={
            WriteButtonStore.notifyContext.contents
              ? WriteButtonStore.notifyContext.contents
              : ""
          }
          isBlack={false}
          useTwoButton={WriteButtonStore.notifyContext.needTwoButton}
          defaultButtonText={WriteButtonStore.notifyContext.defaultButtonText}
          extraButtonText={WriteButtonStore.notifyContext.extraButtonText}
          onClickDefaultButton={
            WriteButtonStore.notifyContext.onClickDefaultButton
          }
          onClickExtraButton={WriteButtonStore.notifyContext.onClickExtraButton}
          onRequestClose={() =>
            WriteButtonStore.setNotifyContext({
              ...WriteButtonStore.notifyContext,
              isShow: false,
            })
          }
          modalCloseCallback={() =>
            WriteButtonStore.setNotifyContext({
              ...WriteButtonStore.notifyContext,
              isShow: false,
            })
          }
          textAlign={WriteButtonStore.notifyContext.textAlign}
        />
      )}
      <WriteButtonBottomSheet
        show={WriteButtonStore.showWriteButtonBottomSheet}
        onClose={() => WriteButtonStore.setShowWriteButtonBottomSheet(false)}
      />
    </div>
  );
});

export default WriteButton;
