import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import MissionStore from "../../../store/MissionStore";
import { getDDay } from "../../../utils/datetime";
import { styles } from "../MissionDetailScreen";


const DdayText = () => {
  const { missionDetail } = MissionStore;
  const { t } = useTranslation();

  const renderDay = () => {
    const now = dayjs();

    if (now.isAfter(missionDetail.missionEndDate)) {
      return (
        <div style={styles.expire_icon}>
          <p style={styles.icon_text}>{t("screen.mission.label.endMission")}</p>
        </div>
      );
    } else {
      const dday = getDDay(missionDetail.missionEndDate || "");
      if (dday == 0) {
        return (
          <div style={styles.d_day_icon}>
            <p style={styles.icon_text}>{t("screen.mission.label.endToday")}</p>
          </div>
        );
      } else if (dday > 0 && dday <= 7) {
        return (
          <div style={styles.d_day_icon}>
            <p style={styles.icon_text}>D - {getDDay(missionDetail.missionEndDate || "")}</p>
          </div>
        );
      } else {
        return <></>;
      }
    }
  };

  return <div style={styles.icon_wrap}>{renderDay()}</div>;
};

export default DdayText;

