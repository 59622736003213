import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { ReactComponent as HeartOffIcon } from '../../../assets/icons/heart_off.svg'
import { ReactComponent as HeartOnIcon } from '../../../assets/icons/heart_on.svg'
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg'
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat.svg'
import LikeStore from '../../../store/LikeStore'
import { useTranslation } from 'react-i18next'
import { CommentTypeCode } from '../../../constants/Comment'
import { LikeTypeCode } from '../../../constants/Like'
import TouchableWithAuthCheck from '../../../components/common/TouchableWithAuthCheck'
import { ActHistoryTypeCode, ActPageCode } from '../../../constants/ActHistory'
import { onShare, ShareInfo } from '../../../utils/share'
import { Magazine } from '../../../api/magazine/model'
import CommonStore from '../../../store/CommonStore'
import { ShareScreenCode } from '../../../constants/ShareScreen.enum'
import { track } from '../../../hooks/tracker/TrackFunction'
import { useLocation } from 'react-router-dom'
import classes from './MagazineDetailFooter.module.scss'
import { goTo } from '../../../hooks/navigate/NavigateFunction'
import { FeedTypeCode } from '../../../constants/Feed'

const MagazineDetailFooter = observer(
  ({ magazine }: { magazine: Magazine | undefined }) => {
    const { t } = useTranslation()

    const { saveLike, cancelLike } = LikeStore
    const [likeYn, setLikeYn] = useState<string>('N')
    const [likeCount, setLikeCount] = useState<number>(0)
    const [commentCount, setCommentCount] = useState<number>(0)
    const location = useLocation()

    useEffect(() => {
      if (magazine) {
        setLikeYn(magazine?.likeYn || 'N')
        setLikeCount(magazine?.likeCount ?? 0)
        setCommentCount(magazine?.commentCount ?? 0)
      }
    }, [magazine])

    const onLikeButtonPress = async () => {
      if (likeYn === 'Y') {
        await cancelLike(
          magazine?.feedId ?? 0,
          'MAGAZINE',
          () => {
            setLikeYn('N')
            setLikeCount((prev) => prev - 1)
            //PPIT210117-4818 LIKE 취소 차리 시 attr6에 CANCEL 반영
            CommonStore.callActHistory(
              ActHistoryTypeCode.LIKE,
              (magazine?.feedId ?? 0).toString(),
              ActPageCode.MAGAZINE_DETAIL,
              undefined,
              undefined,
              likeCount.toString(),
              commentCount.toString(),
              undefined,
              undefined,
              'CANCEL'
            )
          },
          () => {
            // fail
          }
        )
      } else {
        await saveLike(
          magazine?.feedId ?? 0,
          'MAGAZINE',
          () => {
            setLikeYn('Y')
            setLikeCount((prev) => prev + 1)

            track('click_like_button', {
              content_id: magazine?.feedId,
              content_title: magazine?.feedTitle,
              content_type: LikeTypeCode.MAGAZINE,
              like_count: magazine?.likeCount ? magazine?.likeCount : 0,
              comment_count: magazine?.commentCount
                ? magazine?.commentCount
                : 0,
            })

            CommonStore.callActHistory(
              ActHistoryTypeCode.LIKE,
              (magazine?.feedId ?? 0).toString(),
              ActPageCode.MAGAZINE_DETAIL,
              undefined,
              undefined,
              likeCount.toString(),
              commentCount.toString()
            )
          },
          () => {
            // fail
          }
        )
      }
    }

    const onCommentButtonPress = () => {
      goTo(`/${CommentTypeCode.MAGAZINE}/${magazine?.feedId}/comment`, {
        state: {
          feedId: magazine?.feedId ?? 0,
          feedType: CommentTypeCode.MAGAZINE,
          feedAuthorId: magazine?.memberUuid,
        },
      })
      // navigate('/CommentScreen', {
      //   state: {
      //     feedId: magazine?.feedId ?? 0,
      //     feedType: CommentTypeCode.MAGAZINE,
      //     feedAuthorId: magazine?.memberUuid,
      // 여기로 함수를 넘기면 에러가 발생함
      // onLeave: async () => {
      //   const updateMainMagazine = await getMagazineByFeedId(
      //     magazine?.feedId ?? 0
      //   )
      //   setCommentCount(updateMainMagazine.magazine?.commentCount)
      // },
      //   },
      // })
    }

    const onLikeListButtonPress = () => {
      goTo(`/${FeedTypeCode.MAGAZINE}/${magazine?.feedId}/like`)
    }

    return (
      <div
        className={classes.action_wrap}
        aria-label={'Magazine detail footer'}
      >
        <div style={{ width: '100%' }}>
          <div className={classes.gradient} />
        </div>
        <div className={classes.action_wrap_padding}>
          <div className={classes.action_left}>
            <button
              onClick={() => {
                onLikeListButtonPress()
              }}
            >
              {/* <div className={classes.[action_text, like_text]}> */}
              <div className={classes.action_text}>
                {t(`screen.magazine.label.like`)}{' '}
                <div className={classes.number_text}>
                  {likeCount.toString()}
                </div>
              </div>
            </button>

            <button onClick={onCommentButtonPress}>
              <div className={classes.action_text}>
                {t(`screen.magazine.label.comment`)}{' '}
                <div className={classes.number_text}>{commentCount}</div>
              </div>
            </button>
          </div>
          <div className={classes.action_right}>
            <TouchableWithAuthCheck
              className={classes.action_icon_btn}
              aria-label={'Magazine detail like button'}
              onPress={onLikeButtonPress}
            >
              {likeYn === 'Y' ? <HeartOnIcon /> : <HeartOffIcon />}
            </TouchableWithAuthCheck>
            <button
              aria-label="Magazine detail comment button"
              className={classes.action_icon_btn}
              onClick={onCommentButtonPress}
            >
              <ChatIcon />
            </button>
            <button
              className={classes.action_icon_btn}
              aria-label={'Magazine detail share button'}
              onClick={() => {
                const actHistoryData = {
                  actHistoryTypeCode: ActHistoryTypeCode.POST_SHARE,
                  actPageCode: ActPageCode.MAGAZINE_DETAIL,
                  feedId: (magazine?.feedId ?? 0).toString(),
                  likeCount: (magazine?.likeCount ?? 0).toString(),
                  commentCount: (magazine?.commentCount ?? 0).toString(),
                }
                const shareInfo: ShareInfo = {
                  title: magazine?.feedTitle,
                  descriptionText: magazine?.previewContents,
                  imageUrl: magazine?.thumbnailFilePath,
                  screen: ShareScreenCode.MAGAZINE_DETAIL_SCREEN,
                  targetId: magazine?.feedId,
                  path: `magazine/${magazine?.feedId}`,
                }
                void onShare(shareInfo, actHistoryData)

                track('click_share_button', {
                  content_id: magazine?.feedId,
                  content_title: magazine?.feedTitle,
                  content_type: LikeTypeCode.MAGAZINE,
                  like_count: magazine?.likeCount ? magazine?.likeCount : 0,
                  comment_count: magazine?.commentCount
                    ? magazine?.commentCount
                    : 0,
                })
              }}
            >
              <ShareIcon />
            </button>
          </div>
        </div>
        {/* <div className={classes.{ height: Platform.OS === 'ios' ? insets.bottom : 0 }} /> */}
      </div>
    )
  }
)

export default MagazineDetailFooter
