// 네이티브 소스
// import React from "react";
// import { observer } from "mobx-react";
// import { View, Text, TouchableOpacity, ImageBackground, ActivityIndicator, FlatList } from "react-native";
// import MissionStore from "../../../store/MissionStore";
// import MissionItem from "./MissionItem";
// import { useTranslation } from "react-i18next";
// import { MISSION_RESULT_COUNT, MISSION_TYPE_PATH } from "../../../constants/mission";
// import { Mission } from "../../../api/mission/model";
// import { styles } from "./MissionMain";

import React, { forwardRef, useEffect, useState } from "react";
import { observer } from "mobx-react";
import MissionStore from "../../../store/MissionStore";
import MissionItem from "./MissionItem";
import { useTranslation } from "react-i18next";
import {
  MISSION_RESULT_COUNT,
  MISSION_TYPE_PATH,
} from "../../../constants/mission";
import { Mission } from "../../../api/mission/model";
import { styles } from "./MissionMain";
import { Virtuoso } from "react-virtuoso";
import { ReactComponent as DarkSpinner } from "../../../assets/animations/darkSpinner.svg";

const MissionMainList = observer(
  ({ missionList, onMore }: { missionList: Mission[]; onMore: () => void }) => {
    const { t } = useTranslation();
    const {
      selectedTab,
      loading,
      isEndPage,
      getMissionList,
      setSelectedTab,
      setLoadMore,
      loadMore,
      getMoreMissionList,
    } = MissionStore;


    // 네이티브 소스
    // const renderEmpty = () => {
    //   return (
    //     <ImageBackground source={require("../../../assets/images/nodata_mission.png")} style={styles.empty_icon}>
    //       <View style={styles.empty}>
    //         <View style={styles.empty_text_wrap}>
    //           {selectedTab == MISSION_TYPE_PATH.ONGOING ? (
    //             <Text>{t("screen.mission.message.noOngoing")}</Text>
    //           ) : selectedTab == "end" ? (
    //             <Text>{t("screen.mission.message.noEnd")}</Text>
    //           ) : (
    //             <>
    //               <Text style={styles.empty_text}>{t("screen.mission.message.noParticipate1")}</Text>
    //               <Text style={styles.empty_text}>{t("screen.mission.message.noParticipate2")}</Text>
    //               <Text style={styles.empty_text}>{t("screen.mission.message.noParticipate3")}</Text>
    //             </>
    //           )}
    //         </View>
    //         {selectedTab !== MISSION_TYPE_PATH.ONGOING && (
    //           <TouchableOpacity
    //             style={styles.event_view_btn}
    //             onPress={() => {
    //               setSelectedTab(MISSION_TYPE_PATH.ONGOING);
    //               void getMissionList(MISSION_TYPE_PATH.ONGOING, MISSION_RESULT_COUNT, 0);
    //             }}
    //           >
    //             <Text style={styles.event_view_btn_text}>{t("screen.mission.button.showOngoing")}</Text>
    //           </TouchableOpacity>
    //         )}
    //       </View>
    //     </ImageBackground>
    //   );
    // };
    const renderEmpty = () => {
      return (
        <div className="empty-icon">
          <div className="empty">
            <div className="empty_text_wrap">
              {selectedTab == MISSION_TYPE_PATH.ONGOING ? (
                <p>{t("screen.mission.message.noOngoing")}</p>
              ) : selectedTab == "end" ? (
                <p>{t("screen.mission.message.noEnd")}</p>
              ) : (
                <>
                  <p>{t("screen.mission.message.noParticipate1")}</p>
                  <p>{t("screen.mission.message.noParticipate2")}</p>
                  <p>{t("screen.mission.message.noParticipate3")}</p>
                </>
              )}
            </div>
            {selectedTab !== MISSION_TYPE_PATH.ONGOING && (
              <button
                className="event_view_btn"
                onClick={() => {
                  setSelectedTab(MISSION_TYPE_PATH.ONGOING);
                  void getMissionList(
                    MISSION_TYPE_PATH.ONGOING,
                    MISSION_RESULT_COUNT,
                    0
                  );
                }}
              >
                <p className="event_view_btn_text">
                  {t("screen.mission.button.showOngoing")}
                </p>
              </button>
            )}
          </div>
        </div>
      );
    };

    // 네이티브 소스
    //   return (
    //     <View style={styles.mission}>
    //       {loading ? (
    //         <ActivityIndicator size={"large"} style={styles.loader} />
    //       ) : (
    //         <FlatList
    //           style={[styles.mission_list]}
    //           windowSize={3}
    //           scrollEnabled={false}
    //           showsVerticalScrollIndicator={false}
    //           data={missionList}
    //           keyExtractor={(item) => item.missionId.toString()}
    //           renderItem={({ item, index }) => {
    //             return <MissionItem listIndex={index} mission={item} />;
    //           }}
    //           ListFooterComponent={() =>
    //             loading ? <ActivityIndicator size={"large"} style={styles.more_loader}></ActivityIndicator> : <></>
    //           }
    //           ListEmptyComponent={() => renderEmpty()}
    //         />
    //       )}
    //     </View>
    //   );
    // });



    return (
      <div style={styles.mission}>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DarkSpinner
              style={{
                width: 100,
                height: 100,
                margin: "100px 0px",
              }}
            />
          </div>
        ) : (
          <Virtuoso
            className={[selectedTab, "mission-list"].join(" ")}
            data={missionList}
            endReached={() => {
              if (!loading && loadMore) {
                setLoadMore(false);
                onMore()
              }
            }} // 끝에 다다르면 불러올 함수
            itemContent={(index, item) => (
              <MissionItem
                key={item.missionId.toString()}
                listIndex={index}
                mission={item}
              />
            )}
            useWindowScroll
            components={{
              EmptyPlaceholder: () => renderEmpty(),
              // Footer: () => (
              //   <div style={{ height: 60, backgroundColor: "transparent" }} />
              // ),
              Item: forwardRef(
                (
                  { style, children, ...props },
                  ref: React.LegacyRef<HTMLDivElement> | undefined
                ) => (
                  <div
                    ref={ref}
                    {...props}
                    style={{
                      ...style,
                      margin: 0,
                    }}
                  >
                    {children}
                    <div
                      style={{ height: 35, backgroundColor: "transparent" }}
                    />
                  </div>
                )
              ),
            }}
          />
          // <div className="mission-list">
          //   {missionList.length > 0 ? (
          // missionList.map((mission, index) => (
          //   <MissionItem key={mission.missionId.toString()} listIndex={index} mission={mission} />
          // ))
          //   ) : (
          //     renderEmpty()
          //   )}
          // </div>
        )}
      </div>
    );
  }
);

export default MissionMainList;
