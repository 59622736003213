import { callAPIWithGETMethod, callAPIWithPUTMethod } from "../common/api";
import { Notification } from "./model";

const PAGE_SIZE = 10;
export const getNotificationList = async (
  pageIndex: number,
  BenefitFlag = false, // true 혜택만 조회 / false 전체 조회
  isLoading = true
): Promise<{ list: Notification[]; totalCount: number } | null> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/notifications" + `?pageIndex=${pageIndex}&pageSize=${PAGE_SIZE}&BenefitFlag=${BenefitFlag}`,
    },
    isLoading
  );

  return response?.successOrNot === "Y" && response?.statusCode === "OK" ? response?.data : null;
};

export const updateNotificationCheckYnToY = async (notificationIds: number[], isLoading = true): Promise<boolean> => {
  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/notifications/check" + `?notificationIds=${notificationIds.join(",")}`,
    },
    isLoading
  );

  return response?.successOrNot === "Y" && response?.statusCode === "OK";
};

export const getNotCheckedNotificationCount = async (isLoading = true): Promise<number> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/notification/notCheckedCount",
    },
    isLoading
  );

  return response?.successOrNot === "Y" && response?.statusCode === "OK" ? response?.data : 0;
};

export const notificationsAllCheck = async (isLoading = true): Promise<boolean> => {
  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/notifications/allCheck",
      body: {},
    },
    isLoading
  );

  return response?.successOrNot === "Y" && response?.statusCode === "OK";
};
