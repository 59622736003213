import React from 'react'

import { Styles } from '../../assets/types/Style'
import { useTranslation } from 'react-i18next'
import { getDateFormatKo } from '../../utils/datetime'
import AuthStore from '../../store/AuthStore'
import dayjs from 'dayjs'
import { WithdrawalMemberInfoRequest } from '../../api/member/model'
import { withdrawalMemberInfo } from '../../api/member/api'
import Modal from 'react-modal'
import classes from './WithdrawAgreeConfirmModal.module.scss'
import clsx from 'clsx'

export interface WithdrawAgreeConfirmModalInterface {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  onAgreeBtnPress: () => void
  onDisagreeBtnPress: () => void
  onCloseCallback?: () => void
}

export default function WithdrawAgreeConfirmModal({
  isVisible,
  setIsVisible,
  onAgreeBtnPress,
  onDisagreeBtnPress,
  onCloseCallback,
}: WithdrawAgreeConfirmModalInterface): JSX.Element {
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL)
  const COFFEE_IMAGE = '/ASSETS/WithdrawAgree_coffee.png'

  const endDay = dayjs().add(30, 'day')
  const formatEndDay = getDateFormatKo(endDay.toString())
  const prizePaymentDate = endDay.add(1, 'M').format('YYYY년 MM월 15일')

  const withdrawalMemberInfoRequest: WithdrawalMemberInfoRequest = {
    memberUuid: AuthStore.sessionUser?.memberUuid || '',
    calDate: endDay.add(1, 'M').format('YYYY-MM-15 HH:mm:ss'),
    widthdrawYn: '',
  }

  const { t } = useTranslation()

  const onModalHide = () => {
    onCloseCallback && onCloseCallback()
  }

  return (
    <Modal
      className={classes.modal_wrap}
      isOpen={isVisible}
      onRequestClose={onModalHide}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 10000,
          maxWidth: 450,
          margin: "auto",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          backgroundColor: "#fff",
          position: "relative",
          borderRadius: 12,
          outline: "none",
          width: "90%",
          border: "none",
          padding: "0",
          inset: "unset"
        }
      }}
    >
      <div
        className={classes.marketing_agree_modal}
        aria-label={'WithdrawAgreConfirmeModal'}
      >
        <div className={classes.modal_header}>
          <span className={classes.header_text}>
            {t('screen.setting.message.withDrawlAgree.modalTitle')}
          </span>
        </div>
        <div className={classes.modal_body}>
          <button className={classes.icons}>
            <img
              src={`${PUBLIC_BUCKET_URL}${COFFEE_IMAGE}`}
              className={classes.img}
              alt=""
            ></img>
          </button>

          <span className={classes.text_0_bold}>
            <span style={{ color: '#be69c3' }}>
              {AuthStore.sessionUser?.nickname}
            </span>
            {t('screen.setting.message.withDrawlAgree.modaltext01')}
          </span>
          <span className={classes.text_0_bold}>
            {t('screen.setting.message.withDrawlAgree.modaltext02')}
            {'\n'}
          </span>

          <span className={classes.text_0}>
            {t('screen.setting.message.withDrawlAgree.modaltext03')}
          </span>
          <span className={classes.text_0}>
            {t('screen.setting.message.withDrawlAgree.modaltext04')}
            {'\n'}
          </span>
          <span className={classes.text_0_bold}>
            {t('screen.setting.message.withDrawlAgree.modaltext05')}
          </span>
          <span className={classes.text_0}>
            {t('screen.setting.message.withDrawlAgree.modaltext06')}
            {'\n'}
          </span>
          <span className={classes.text_0_bold}>
            {t('screen.setting.message.withDrawlAgree.modaltext07')}
          </span>
          <span className={classes.text_0}>
            {prizePaymentDate}
            {'\n'}
          </span>
        </div>
        <div className={classes.modal_body1}>
          <span className={clsx(classes.text_0, { color: '#999999' })}>
            {t('screen.setting.message.withDrawlAgree.modaltext08')}
          </span>
          <span className={clsx(classes.text_0, { color: '#999999' })}>
            {t('screen.setting.message.withDrawlAgree.modaltext09')}
          </span>
          <span className={clsx(classes.text_0, { color: '#999999' })}>
            {t('screen.setting.message.withDrawlAgree.modaltext10')}
          </span>
        </div>
        <div className={classes.modal_footer}>
          <button
            className={classes.button}
            onClick={() => {
              setIsVisible(false)
              void withdrawalMemberInfo({
                ...withdrawalMemberInfoRequest,
                widthdrawYn: 'N',
              })
              onAgreeBtnPress()
            }}
            aria-label={'WithdrawAgreConfirmeModal Agree'}
          >
            <span className={classes.footer_button_text}>
              {t('screen.setting.message.withDrawlAgree.modalButton01')}
            </span>
          </button>
          <button
            className={classes.later_receive}
            onClick={() => {
              setIsVisible(false)
              void withdrawalMemberInfo({
                ...withdrawalMemberInfoRequest,
                widthdrawYn: 'Y',
              })
              onDisagreeBtnPress()
            }}
            aria-label={'WithdrawAgreConfirmeModal DisAgree'}
          >
            <span className={classes.later_receive_text}>
              {t('screen.setting.message.withDrawlAgree.modalButton02')}
            </span>
          </button>
        </div>
      </div>
    </Modal>
  )
}
