import { action, computed, makeObservable, observable } from "mobx";
import { CommentListRequestResult } from "../api/comment/model";
import { Product } from "../api/product/model";
import { getQnaDetail } from "../api/qna/api";
import { Qna } from "../api/qna/model";
import { Tag } from "../api/tag/model";
// import VideoStore from "./VideoStore";

class QnaDetailStore {
  constructor() {
    makeObservable(this);
  }

  // @observable _videoStore: VideoStore = new VideoStore();

  @observable _qnaListIndex = 0;

  @observable _qnaDetail: Qna = {
    feedId: -1,
    feedTitle: "",
    likeCount: 0,
    likeYn: "N",
    bookmarkYn: "N",
    feedContentId: -1,
    contents: "",
    feedFileGroupId: -1,
    files: [],
    productList: [],
    tagList: [],
    reviewRequest: [],
    comments: {
      commentCount: 0,
    },
    commentCount: 0,
    memberName: "",
    nickname: "",
    profileUrl: "",
    profileBackgroundColor: "",
    createdDatetime: "",
    createMemberUuid: "",
    updatedDatetime: "",
  };

  @observable _tagList: Tag[] = [];

  @observable _productList: Product[] = [];

  @observable loading = false;

  @observable _isDeleted = false;

  @observable _errorStatus = "";

  // @computed get videoStore() {
  //   return this._videoStore;
  // }

  @computed get qnaListIndex() {
    return this._qnaListIndex;
  }

  @computed get qnaDetail() {
    return this._qnaDetail;
  }

  @computed get tagList() {
    return this._tagList;
  }

  @computed get productList() {
    return this._productList;
  }

  @computed get isDeleted() {
    return this._isDeleted;
  }

  @computed get errorStatus() {
    return this._errorStatus;
  }

  @action clearQnaDetailStore = () => {
    this._qnaDetail = {
      feedId: -1,
      feedTitle: "",
      likeCount: 0,
      likeYn: "N",
      bookmarkYn: "N",
      feedContentId: -1,
      contents: "",
      feedFileGroupId: -1,
      files: [],
      productList: [],
      tagList: [],
      comments: {
        commentCount: 0,
      },
      reviewRequest: [],
      commentCount: 0,
      memberName: "",
      nickname: "",
      profileUrl: "",
      profileBackgroundColor: "",
      createdDatetime: "",
      createMemberUuid: "",
      updatedDatetime: "",
    };

    this._tagList = [];

    this._productList = [];

    this._qnaListIndex = 0;
    // this._videoStore = new VideoStore();
  };

  @action setLoading = (isLoading: boolean) => {
    this.loading = isLoading;
  };

  @action setIsDeleted = (isDeleted: boolean) => {
    this._isDeleted = isDeleted;
  };

  @action setErrorStatus = (statusCode: string) => {
    this._errorStatus = statusCode;
  };

  @action getQnaDetail = async (feedId: number, failAction?: (message?: string) => void) => {
    this.clearQnaDetailStore();
    this.setLoading(true);

    const res = await getQnaDetail(feedId);

    if (typeof res === "string") {
      this.setErrorStatus(res);
      failAction && failAction(res);
      return;
    }
    const data: Qna = res;

    this.setQnaDetail(data);
    this.setTagList(data.tagList);
    this.setProductList(data.productList);
    this.setLoading(false);
  };

  @action setQnaDetail = (qnaDetail: Qna) => {
    this._qnaDetail = qnaDetail;
  };

  @action updateCommentCount = (count: any) => {
    this._qnaDetail.commentCount = count;
  };

  @action setTagList = (tagList: any) => {
    this._tagList = tagList;
  };

  @action setProductList = (productList: any) => {
    this._productList = productList;
  };

  @action setQnaListIndex = (index: number) => {
    this._qnaListIndex = index;
  };

  @action saveQnaLike = () => {
    this._qnaDetail.likeYn = "Y";
    this._qnaDetail.likeCount += 1;
  };
  @action cancelQnaLike = () => {
    this._qnaDetail.likeYn = "N";
    this._qnaDetail.likeCount -= 1;
  };

  @action setBookmarkYn = (bookmarkYn: string) => {
    this._qnaDetail.bookmarkYn = bookmarkYn;
  };
}

export default QnaDetailStore;
