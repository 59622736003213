import { observer } from "mobx-react";
import React from "react";
// import { sendActHistory } from "../../../api/data/api";
// import { ActHistoryRequest } from "../../../api/data/model";
import { RelatedProduct } from "../../../api/product/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
// import {
//   ActHistoryTypeCode,
//   ActPageCode,
//   ActSectionCode,
// } from "../../../constants/ActHistory";
// import { goTo } from "../../../hooks/navigate/NavigateFunction";
import "./RelatedProductItem.scss";
import clsx from "clsx";
import CommonStore from "../../../store/CommonStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const RelatedProductItem = observer(
  ({ item, index }: { item: RelatedProduct; index: number }) => {
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    return (
      <div
        className={clsx("related-product-item")}
        // style={{
        //   ...styles.relation_product_item,
        //   ...(!index && styles.relation_product_first_item),
        // }}
        onClick={() => {
          goTo(`/Product/${item.productId}`);
          // const actHistory: ActHistoryRequest = {
          //   actHistoryTypeCode: ActHistoryTypeCode.PRODUCT_READ,
          //   actHistoryTargetId: item.productId.toString(),
          //   actPageCode: ActPageCode.TAG,
          //   actSectionCode: ActSectionCode.RELATED_PRODUCT,
          //   attr1: index.toString(),
          // };
          // void sendActHistory(actHistory);
        }}
      >
        <FastImageWithFallback
          // style={styles.relation_product_image}
          className="relation_product_image"
          source={{
            uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
          }}
          fallbackImageUri={`${PUBLIC_BUCKET_URL}${
            item.originalFilePath || ""
          }`}
        />
        <div className="relation_product_item_text1">{item.productName}</div>
        <div className="relation_product_info">
          <span className="brand_name relation_product_item_text2">
            {item.brandName}
          </span>
          <div className="bar" />
          <span className="model_name relation_product_item_text2">
            {item.productModelName}
          </span>
        </div>
      </div>
    );
  }
);

export default RelatedProductItem;
