import React, { memo, ReactNode } from "react";
import "./AbListItem.scss";
import { AbDetail, AbVoteResult, SortType } from "../../../api/ab/model";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { AbTypeCode } from "../../../constants/ab";
import { getDateStr } from "../../../utils/datetime";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { useTranslation } from "react-i18next";
// import { responsiveScreenHeight } from "react-native-responsive-dimensions";
import AbEndDateBadge from "./AbEndDateBadge";
import useAb from "../hook/UseAb";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import { track } from "../../../hooks/tracker/TrackFunction";
import AbPercentBar from "./AbPercentBar";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

export interface AbItemProps {
  index: number;
  ab: AbDetail;
  onVoteSuccess?: (res: AbVoteResult, type: AbTypeCode) => void;
  orderedBy?: SortType;
}

const AbItemWrapper = ({
  resultOpened,
  children,
  onPress,
}: {
  resultOpened: boolean;
  children: ReactNode;
  onPress: () => void;
}) => {
  return resultOpened ? (
    <div onClick={onPress}>{children}</div>
  ) : (
    <div>{children}</div>
  );
};

const AbItem = ({ index, ab, onVoteSuccess, orderedBy }: AbItemProps) => {
  const {
    totalVoteCount,
    aVoteCount,
    status,
    winner,
    myChoice,
    handleAbPress,
    resultOpened,
  } = useAb(ab, onVoteSuccess);
  const { t } = useTranslation();
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

  const handleAuthorPress = () => {
    goTo(`/user/${ab.nickname}`, {
      state: {
        targetUserId: ab.createMemberUuid,
        nickname: ab.nickname,
        profileUrl: ab.profileUrl,
        profileBackgroundColor: ab.profileBackgroundColor,
      },
    });
  };

  const handleNativageToDetailScreen = () => {
    goTo(`/AB/${ab.feedId}`, {
      state: { feedId: ab.feedId, index: index },
    });
    if (orderedBy) {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.AB_READ,
        actHistoryTargetId: ab.feedId.toString(),
        actPageCode: ActPageCode.AB_TOTAL,
        actSectionCode: ActSectionCode.AB,
        attr1: index.toString(),
        attr2: orderedBy,
        attr3: totalVoteCount.toString(),
      };
      void sendActHistory(actHistory);
    }
  };

  return (
    <AbItemWrapper
      resultOpened={resultOpened}
      onPress={handleNativageToDetailScreen}
    >
      <div className="item_ab_bottom">
        <div className="item_header" aria-label={`Ab list container ${index}`}>
          <div className="item_header_left">
            <AbEndDateBadge endDate={ab.feedEndDate} />
            <p aria-label="Ab vote total count" className="item_header_text1">
              {t("screen.ab.label.voteCount", { count: totalVoteCount })}
            </p>
          </div>
          <div className="item_header_right">
            <div onClick={handleAuthorPress}>
              <p
                aria-label="Ab writer's nickname"
                className="item_header_text2"
              >
                {ab.nickname}
              </p>
            </div>
            <div className="item_text_bar" />
            <p className="item_header_text1">
              {getDateStr(ab.createdDatetime)}
            </p>
          </div>
        </div>
        <div className="item_body">
          <div onClick={handleNativageToDetailScreen}>
            <p className="item_body_text1" aria-label={`Ab list title${index}`}>
              {ab.feedTitle}
            </p>
          </div>
          <div onClick={handleNativageToDetailScreen} aria-label="Ab contents">
            <p
              className="item_body_text2"
              // numberOfLines={2}
              // ellipsizeMode="tail"
            >
              {ab.feedContents}
            </p>
          </div>
          <div className="select_wrap">
            {[AbTypeCode.A, AbTypeCode.B].map((option) => {
              const optionIsChoosen = myChoice === option;
              const isOptionA = option === AbTypeCode.A;
              const label = isOptionA ? ab.adesc : ab.bdesc;
              const filePath = isOptionA ? ab.afilePath : ab.bfilePath;
              const thumbnailFilePath = isOptionA
                ? ab.athumbnailFilePath
                : ab.bthumbnailFilePath;

              const stylesByPosition = {
                wrapper: isOptionA
                  ? { borderBottomLeftRadius: 6, borderTopLeftRadius: 6 }
                  : { borderBottomRightRadius: 6, borderTopRightRadius: 6 },
                dimmed: isOptionA
                  ? {
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                    }
                  : {
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                    },
                backgroundColor: isOptionA
                  ? { backgroundColor: "#fcfafc" }
                  : { backgroundColor: "#fafbfc" },
                text_wrap: isOptionA
                  ? { backgroundColor: "#c07bca" }
                  : { backgroundColor: "#7094bd" },
                text: isOptionA ? { fontSize: 14 } : { fontSize: 14 },
                color: isOptionA ? { color: "#c07bca" } : { color: "#7094bd" },
              };

              return (
                <TouchableWithAuthCheck
                  className={[
                    isOptionA
                      ? "left_horizontal_image"
                      : "right_horizontal_image",
                    resultOpened ? "result_opened" : "result_not_opened",
                    optionIsChoosen ? "selected" : "not_selected",
                    thumbnailFilePath ? "has_thumbnail" : "has_no_thumbnail",
                  ].join(" ")}
                  style={{ flex: 1, aspectRatio: 1, position: "relative" }}
                  key={`${ab.feedId || ""}-${option || ""}`}
                  aria-label={`${option || ""} vote button`}
                  disabled={resultOpened}
                  onPress={() => {
                    void handleAbPress(option).then(() => {
                      track("click_ab_card", {
                        content_id: ab.feedId,
                        content_title: ab.feedTitle,
                        content_order: index,
                      });
                    });
                  }}
                >
                  <FastImageWithFallback
                    aria-label={`${option || ""} image`}
                    source={{
                      uri: `${PUBLIC_BUCKET_URL}${thumbnailFilePath || ""}`,
                    }}
                    fallbackImageUri={`${PUBLIC_BUCKET_URL}${filePath || ""}`}
                    className="horizontal_image"
                    renderFallbackNode={({ children, style }) => {
                      return (
                        <div
                          style={
                            optionIsChoosen || resultOpened
                              ? { backgroundColor: "#f5f5f5" }
                              : stylesByPosition.backgroundColor
                          }
                          className="ab_image_wrap"
                        >
                          {optionIsChoosen ? (
                            <div
                              style={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                              }}
                            >
                              <div />
                              <CheckIcon />
                              <p className="selected_text">{label}</p>
                            </div>
                          ) : (
                            <>
                              <div
                                className="ab_wrap"
                                style={
                                  optionIsChoosen || resultOpened
                                    ? { backgroundColor: "#c4c4c4" }
                                    : {
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }
                                }
                              ></div>
                            </>
                          )}
                        </div>
                      );
                    }}
                  />
                  <div
                    style={
                      (stylesByPosition.dimmed,
                      optionIsChoosen
                        ? { backgroundColor: "#c07bca", opacity: 0.5 }
                        : {})
                    }
                  />
                  <div className="select_text_wrap">
                    {optionIsChoosen ? (
                      <CheckIcon />
                    ) : (
                      <p className="select_text">{option}</p>
                    )}
                    <p className="selected_text">{label}</p>
                  </div>
                </TouchableWithAuthCheck>
              );
            })}
          </div>
          {resultOpened && totalVoteCount > 0 && (
            <AbPercentBar
              aVoteCount={aVoteCount}
              bVoteCount={totalVoteCount - aVoteCount}
              myChoice={myChoice as AbTypeCode}
              optionToBeColored={
                (status === "EXPIRED" ? winner : myChoice) as AbTypeCode
              }
            />
          )}
        </div>
      </div>
    </AbItemWrapper>
  );
};

const memoisedAbListItem = memo(
  AbItem,
  (prev, next) =>
    prev.ab.feedId === next.ab.feedId &&
    prev.ab.feedEndDate === next.ab.feedEndDate &&
    prev.ab.avoteCount === next.ab.avoteCount &&
    prev.ab.bvoteCount === next.ab.bvoteCount &&
    prev.ab.myAbFlag === next.ab.myAbFlag
);
memoisedAbListItem.displayName = "memoisedAbListItem";
export default memoisedAbListItem;
