import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
// import { StyleSheet, div, Text, div, Image, Platform, StatusBar } from "react-native";
// import { Positions, Arranges, FlexBoxs, Icons, Spacings, Texts, Colors, Assets } from "../../assets/styles";
// import { navigate, push } from "../../navigation/NavigationFunction";
import { observer } from "mobx-react";
import AuthStore from "../../store/AuthStore";
// import Config from "react-native-config";
// import { responsiveWidth } from "react-native-responsive-dimensions";
// import { Styles } from "../../assets/types/Style";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { ReactComponent as Alert } from "../../assets/icons/alert.svg";
import { ReactComponent as AlertOn } from "../../assets/icons/alarm_on.svg";
// import Cart from "../../assets/icons/icon_cart_white.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/img_main_logo.svg";
import { ReactComponent as MallLogoIcon } from "../../assets/images/logo_mall.svg";
import { ReactComponent as BrandLogoIcon } from "../../assets/images/logo_brand_page.svg";
// import { useIsFocused } from "@react-navigation/native";
// import BadgeStore from "../../store/BadgeStore";
// import FastImage from "react-native-fast-image";
// import TabStore from "../../store/TabStore";
import { useTracker } from "../../hooks/tracker";
import CommonStore from "../../store/CommonStore";
import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
} from "../../constants/ActHistory";
import "./MainHeader.scss";
import MainStore from "../../store/MainStore";
import { useLocation } from "react-router-dom";
import RightNav from "./RightNav";
import NotificationStore from "../../store/NotificationStore";
import TouchableWithAuthCheck from "./TouchableWithAuthCheck";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import BadgeStore from "../../store/BadgeStore";
import { IsMember, SearchType } from "../../constants/Badge.enum";

export interface MainHeaderProps {
  onAlarm?: () => void;
  onSearch?: () => void;
  onDrawerMenu?: () => void;
  onCart?: () => void;
}

const MainHeader = observer(
  ({ onAlarm, onSearch, onDrawerMenu, onCart }: MainHeaderProps) => {
    const [showRightNav, setShowRightNav] = useState<boolean>(false);
    const [filePath, setFilePath] = useState<String>("");
    const { sessionUser, showProfileBanner, showProfileBannerBrandPage } =
      AuthStore;
    const { notCheckedCount } = NotificationStore;
    const { badgeList, badgeLevelInfo } = BadgeStore;
    // const { currentTab } = TabStore;
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const { pathname } = useLocation();

    // const isFocused = useIsFocused();

    const { track } = useTracker();

    useEffect(() => {
      window.scrollTo(0, 0);
      const { syncNotCheckedCount } = NotificationStore;
      void syncNotCheckedCount();
    }, [AuthStore.sessionUser]);

    const PUBLIC_PROFILE_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const showAlarmPopup = () => {
      if (onAlarm !== undefined) {
        onAlarm();
      }
    };

    const moveToMyHome = () => {
      CommonStore.callActHistory(
        ActHistoryTypeCode.PAGE_CLICK,
        ActHistoryTargetId.MY_HOME,
        ActPageCode.TOP_GNB
      );
      // sessionUser?.memberUuid ? push("MyHomeScreen") : navigate("LoginScreen");
      goTo("/myhome", {
        state: {
          targetUserId: sessionUser?.memberUuid,
          nickname: sessionUser?.nickname,
          profileUrl: sessionUser?.profileUrl,
          profileBackgroundColor: sessionUser?.profileBackgroundColor,
        },
      });
    };

    const moveToSearch = () => {
      if (onSearch) {
        onSearch();
      } else {
        CommonStore.callActHistory(
          ActHistoryTypeCode.PAGE_CLICK,
          ActHistoryTargetId.SEARCH,
          ActPageCode.TOP_GNB
        );
        track("click_search_button", {});
        goTo("/Search");
      }
    };

    const initBadges = useCallback(() => {
      void BadgeStore.getMemberBadges({
        searchType: SearchType.ALL,
        isMember: IsMember.Y,
      });
    }, [BadgeStore.getMemberBadges]);

    useEffect(() => {
      initBadges();
    }, [AuthStore.sessionUser]);

    // const showDrawerMenu = () => {
    //   if (onDrawerMenu) {
    //     onDrawerMenu();
    //   }
    // };

    // const StatusBarByFocused = useCallback(() => {
    //   if (isFocused) {
    //     return <StatusBar backgroundColor={"#682c78"} barStyle={"light-content"} />;
    //   } else {
    //     return <></>;
    //   }
    // }, [isFocused]);

    // const paddingBottomCheck = () => {
    //   return (
    //     sessionUser?.profileUrl === undefined ||
    //     (CommonStore.currentRouteName === "HomeScreen" && !showProfileBanner) ||
    //     CommonStore.currentRouteName === "MallMainScreen"
    //   );
    // };

    useEffect(() => {
      setFilePath(pathname.toLowerCase());
    }, [pathname]);

    const renderLeft = () => {
      if (sessionUser?.profileUrl === undefined) {
        return (
          <div className="mainLogo">
            <LogoIcon />
          </div>
        );
      } else if (
        (filePath === "/" || filePath === "/main") &&
        !showProfileBanner
      ) {
        return (
          <div className="mainLogo">
            <LogoIcon />
          </div>
        );
      } else if (filePath === "/mallmainscreen") {
        return <MallLogoIcon />;
      } else if (
        filePath === "/brandcommunitymain" &&
        !showProfileBannerBrandPage
      ) {
        return (
          <div style={{ marginBottom: -1 }}>
            <BrandLogoIcon />
          </div>
        );
      } else {
        return (
          <>
            <div
              className="icons profile_photo"
              onClick={moveToMyHome}
              aria-label="Profile"
            >
              <img
                className="profile_image"
                src={`${PUBLIC_PROFILE_BUCKET_URL}${sessionUser?.profileUrl}`}
                alt={`${PUBLIC_PROFILE_BUCKET_URL}${sessionUser?.profileUrl}`}
              />
              <div className="profile_btn" onClick={moveToMyHome}>
                <img
                  className="profile_badge_btn"
                  // source={{
                  //   uri: `${PUBLIC_BUCKET_URL}${
                  //     badgeLevelInfo?.levelIconUrl || ""
                  //   }`,
                  // }}
                  src={`${PUBLIC_BUCKET_URL}${
                    badgeLevelInfo?.levelIconUrl || ""
                  }`}
                  alt={`${PUBLIC_BUCKET_URL}${
                    badgeLevelInfo?.levelIconUrl || ""
                  }`}
                />
              </div>
            </div>
            <div
              className="profile_nickname_badge"
              onClick={moveToMyHome}
              aria-label="Member Nickname"
            >
              <div className="id_text">{sessionUser?.nickname}</div>
            </div>
          </>
        );
      }
    };

    const showDrawerMenu = () => {
      setShowRightNav(true);
    };

    return (
      <>
        <div id="MainHeader" className="common-header-system">
          <div className="header_main">
            {/* <StatusBarByFocused /> */}
            <div className="header_spacing">
              <div className="left">{renderLeft()}</div>

              <div className="right">
                <div className="alert">
                  <TouchableWithAuthCheck
                    className="icons alert"
                    onPress={showAlarmPopup}
                  >
                    {notCheckedCount > 0 ? <AlertOn /> : <Alert />}
                  </TouchableWithAuthCheck>
                </div>
                <button className="icons search" onClick={moveToSearch}>
                  <Search />
                </button>
                <button className="icons menu" onClick={showDrawerMenu}>
                  <Menu />
                </button>
              </div>
            </div>
          </div>
        </div>
        <RightNav
          open={showRightNav}
          onClose={() => {
            setShowRightNav(false);
          }}
        />
      </>
    );
  }
);
export default MainHeader;
