import React, { useState } from "react";
import { observer } from "mobx-react";

import { t } from "i18next";
import ProductSearchAddStore from "../../../store/ProductSearchAddStore";
import { ReactComponent as LatestIcon } from "../../../assets/icons/clock_purple.svg";
import { ReactComponent as SearchGrayIcon } from "../../../assets/icons/search_gray.svg";

import { KeywordText } from "../..//../components/common";
import { SearchSortType } from "../../../constants/Search";
import "./ProductSearchWord.scss";

const ProductSearchWord = observer(
  ({ clickCallback }: { clickCallback?: (() => void) | undefined }) => {
    const {
      filterRecentList,
      autoCompleteList,
      keyword,
      setRecentWord,
      getSearchResult,
      setShowWord,
      setShowResult,
      setKeyword,
    } = ProductSearchAddStore;

    const onClickWord = (word: string) => {
      if (clickCallback) {
        clickCallback();
      }
      setKeyword(word);
      void setRecentWord(word);
      void getSearchResult(word, 0, SearchSortType.POPULAR);
      setShowWord(false);
      setShowResult(true);
    };

    const renderFilterRecent = (word: string, index: number) => {
      return (
        <div
          className="auto_complete_item"
          key={`Recent_${word}_${index.toString()}`}
          onClick={() => onClickWord(word)}
        >
          <LatestIcon />
          <KeywordText
            sentence={word}
            keyword={keyword || ""}
            containerStyle={{
              fontSize: 14,
              color: "#222",
              letterSpacing: -0.56,
              fontWeight: "500",
              marginLeft: 6,
            }}
            matchTextStyle={{ color: "#a760b1" }}
            accessibilityLabel={`Product Recent Keyword item ${index}`}
          />
        </div>
      );
    };

    const renderAutoComplete = (word: string, index: number) => {
      return (
        <div
          className="auto_complete_item"
          key={`Auto_${word}_${index.toString()}`}
          onClick={() => onClickWord(word)}
        >
          <SearchGrayIcon />
          <KeywordText
            sentence={word}
            keyword={keyword || ""}
            containerStyle={{
              fontSize: 14,
              color: "#222",
              letterSpacing: -0.56,
              fontWeight: "500",
              marginLeft: 6,
            }}
            matchTextStyle={{ color: "#a760b1" }}
            accessibilityLabel={`Product Auto Complete Keyword item ${index}`}
          />
        </div>
      );
    };

    return (
      <div
        className="auto_complete_list_product_search_word"
        // contentContainerStyle={{ paddingBottom: 100 }}
        // showsVerticalScrollIndicator={false}
        // alwaysBounceVertical={false}
        // scrollToOverflowEnabled={true}
        // overScrollMode={"never"}
        // keyboardShouldPersistTaps={"never"}
        // keyboardDismissMode={"on-drag"}
      >
        {filterRecentList?.map((item: string, index: number) =>
          renderFilterRecent(item, index)
        )}
        {autoCompleteList?.map((item: string, index: number) =>
          renderAutoComplete(item, index)
        )}
      </div>
    );
  }
);

export default ProductSearchWord;
