import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import SearchStore from "../../../store/SearchStore";
import { SearchTabMenu, SEARCH_RESULT_COUNT } from "../../../constants/Search";
import SearchCountAndSort from "./SearchCountAndSort";
import SearchEmpty from "./SearchEmpty";
import { SearchItem } from "../../../api/search/model";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { FEED_CALL_STATE } from "../../../constants/Feed";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import AuthStore from "../../../store/AuthStore";
import CommonStore from "../../../store/CommonStore";
import { PullToRefresh } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import { ReactComponent as DarkSpinner } from "../../../assets/animations/darkSpinner.svg";
import { useWindowScroll } from "react-use";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import "./SearchPost.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { useLocation } from "react-router-dom";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import SearchResultLayout from "../SearchResultLayout";

const SearchPost = observer(() => {
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const { sessionUser, tempMember } = AuthStore;
  const {
    reportPostCount,
    searchText,
    postResult,
    getLoadingInList,
    postSortAndIndex,
    refreshing,
    selectedSearchTab,
    posterSortAndIndex,
    getSearchResult,
    setRefreshing,
  } = SearchStore;

  const { x, y } = useWindowScroll();

  const [afterMount, setAfterMount] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setAfterMount(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (afterMount) {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

      if (bottom) {
        if ((postResult?.totalCount || 0) > 0 && !getLoadingInList) {
          const tempStart = postSortAndIndex?.lastIndex + 1;
          if ((postResult?.totalCount || 0) > tempStart * SEARCH_RESULT_COUNT) {
            void getSearchResult(
              searchText,
              SearchTabMenu.Post,
              postSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              tempStart
            );
          }
        }
      }
    }
  }, [y]);

  const moveToPostDetail = (item: SearchItem | any, index: number) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.POST_READ,
      sessionUser?.memberUuid || tempMember?.tempMemberUUID || "",
      ActPageCode.SEARCH_POST,
      undefined,
      index?.toString(),
      searchText,
      SearchStore.getSortType()
    );

    goTo("/FeedScreen", {
      state: {
        itemIndex: index,
        feedParentTypeCode: "SEARCH_POSTS",
        extraData: {
          searchParams: {
            keyword: searchText,
            tab: selectedSearchTab,
            sort: posterSortAndIndex.sort,
          },
        },
      },
    });
  };

  return (
    <SearchResultLayout tabType={SearchTabMenu.Post}>
      <div id="SearchPost" className="tab_content" aria-label="Search post">
        <PullToRefresh
          onRefresh={async () => {
            await sleep(1000);
            setRefreshing(true);
            void getSearchResult(
              searchText,
              SearchTabMenu.Post,
              postSortAndIndex.sort,
              SEARCH_RESULT_COUNT,
              0
            );
          }}
          renderText={(status) => {
            return (
              <div>
                {status === "refreshing" && (
                  <DarkSpinner style={{ width: 50, height: 50 }} />
                )}
              </div>
            );
          }}
        >
          <SearchCountAndSort
            type={SearchTabMenu.Post}
            sort={postSortAndIndex.sort}
            count={
              postResult?.totalCount != undefined
                ? postResult?.totalCount - reportPostCount
                : 0
            }
          />
          <div className="scroll_wrap product_wrap">
            {postResult?.list?.slice() &&
            postResult?.list?.slice().length > 0 ? (
              postResult?.list?.slice().map((item: any, index: number) => {
                return (
                  <div className="row">
                    <div
                      className="relation_product_item"
                      onClick={() => moveToPostDetail(item, index)}
                      aria-label={`Search Post ${index}`}
                    >
                      <FastImageWithFallback
                        wrapperClassName="relation_product_image_wrapper"
                        className="relation_product_image"
                        source={{
                          uri: `${PUBLIC_BUCKET_URL}${
                            item.thumbnailFilePath || ""
                          }`,
                        }}
                        fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                          item.thumbnailFilePath?.replace(
                            "THUMBNAIL",
                            "ORIGINAL"
                          ) || ""
                        }`}
                      />
                      <div className="relation_product_item_text1">
                        {item.feedTitle}
                      </div>
                      <div className="relation_product_info">
                        <div className="relation_product_item_text2">
                          {item.nickname}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <SearchEmpty isGrid={true} />
            )}
          </div>

          {/* {getLoadingInList ? <CustomActivityIndicatorSmall /> : <></>} */}
        </PullToRefresh>
      </div>
    </SearchResultLayout>
  );
});

export default SearchPost;
