import { Styles } from "../../../assets/types/Style";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { Arranges, Colors, FlexBoxs, Spacings, Texts } from "../../../assets/styles";
import { getDateStr } from "../../../utils/datetime";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import ArticleDetailStore from "../../../store/ArticleDetailStore";

const ArticleDetailProfile = observer(() => {
    const {
        user_qna_detail_header,
        user_qna_detail_header_left,
        user_img,
        info_wrap,
        info_inner_wrap,
        info_text1,
        info_text2,
        text_bar,
    } = styles;

    const { t } = useTranslation();

    const PUBLIC_PROFILE_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

    return (
        <>
            <div style={styles.flag_notice}>
                <p
                    style={
                        ArticleDetailStore.articleDetail.articleTypeCode === "NOTICE"
                            ? styles.flag_notice_text
                            : styles.flag_notice_text1
                    }
                >
                    {ArticleDetailStore.articleDetail.articleTypeName}
                </p>
            </div>

            <div style={user_qna_detail_header} >
                <button
                    style={user_qna_detail_header_left}
                    onClick={() => {                       
                       goTo(`/user/${ArticleDetailStore.articleDetail.nickname}`, { state: { targetUserId: ArticleDetailStore.articleDetail.createMemberUuid } });
                    }}
                >
                    {ArticleDetailStore.articleDetail.profileUrl != "" && (
                        <FastImageWithFallback
                            source={{ uri: `${PUBLIC_PROFILE_BUCKET_URL}${ArticleDetailStore.articleDetail.profileUrl ?? ""}` }}
                            style={user_img}
                        />
                    )}
                    <div style={info_wrap}>
                        <p style={info_text1}>{ArticleDetailStore.articleDetail.nickname}</p>
                        <div style={info_inner_wrap}>
                            <p style={info_text2}>{getDateStr(ArticleDetailStore.articleDetail.createdDatetime)}</p>
                            <div style={text_bar} />
                            <p style={info_text2}>
                                {t("screen.article.label.inquiry")} {ArticleDetailStore.articleDetail.articleViewCount}
                            </p>
                        </div>
                    </div>
                </button>
            </div>
        </>
    );
});

export default ArticleDetailProfile;

const styles: Styles = {
    flag_notice: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Spacings.padding_right_left_20,
        ...Spacings.margin_bottom_12,
    },
    flag_notice_text: {
        ...Texts.font_size_10,
        ...Texts.font_weight_bold,
        ...Colors.font_222,
        ...Colors.background_f7e237,
        ...Spacings.border_radius_4,
        ...Spacings.padding_6,
        overflow: "hidden",
    },
    flag_notice_text1: {
        ...Texts.font_size_10,
        ...Texts.font_weight_bold,
        ...Colors.font_be69c3,
        ...Colors.background_f5f5f5,
        ...Spacings.border_radius_4,
        ...Spacings.padding_6,
        overflow: "hidden",
    },
    user_qna_detail_header: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.between,
        ...Arranges.center_h,
        ...Spacings.padding_right_left_16,
    },
    user_qna_detail_header_left: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_v_h,
    },
    user_img: {
        width: 48,
        height: 48,
        ...Spacings.border_radius_100,
    },
    info_wrap: {
        ...Spacings.margin_left_right_10,
    },
    info_inner_wrap: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_h,
    },
    info_text1: {
        ...Texts.contents_text_34,
        marginBottom: 2,
        textAlign: "start"
    },
    info_text2: {
        ...Texts.contents_text_9,
    },
    text_bar: {
        height: 12,
        width: 1,
        ...Colors.background_eeeeee,
        ...Spacings.margin_right_left_5,
    },
};
