import { callAPIWithPUTMethod, callAPIWithGETMethod } from "../../common/api";
import CommonResponse from "../../http";
import { ModifyAlarm } from "./model";

export const modifyAlarm = async (
  alarmItem: ModifyAlarm,
  isLoading = true
): Promise<CommonResponse> => {
  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/setting/alarm",
      body: alarmItem,
    },
    isLoading
  );

  return response?.successOrNot === "Y" && response?.statusCode === "OK"
    ? response?.data
    : null;
};

export const getAlarmDefaultSetting = async (isLoading = true) => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/setting/alarm",
    },
    isLoading
  );

  return response?.successOrNot === "Y" ? response?.data : null;
};
