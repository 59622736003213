import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as FollowerListImage } from "../../../assets/icons/follower_list.svg";
import { FollowersTab } from "../../../constants/Account";
import "./EmptyFollower.scss";

const EmptyFollower = ({
  type,
  isSearchEmpty,
  onSearching,
}: {
  type: FollowersTab;
  isSearchEmpty?: boolean;
  onSearching: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="empty-follower-wrapper">
        <FollowerListImage style={{ width: "100%" }} />
        <div>
          <div className="empty-text-wrapper">
            <p className="empty-text" aria-label="Empty follower">
              {onSearching === false &&
                (isSearchEmpty !== undefined && isSearchEmpty === true
                  ? t("screen.followers.message.emptySearch")
                  : type == FollowersTab.FOLLOWERS
                  ? t("screen.followers.message.emptyFollower")
                  : t("screen.followers.message.emptyFollowing"))}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyFollower;
