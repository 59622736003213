import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import {
  getAutoComplete,
  getRankingSearch,
  getSearchResult,
  getSuggestionSearch,
  getTypoCorrection,
} from "../api/search/api";
import {
  BrandCommunityArticleSearchItem,
  BrandCommunitySearchItem,
  PopularSearchResponse,
  RankingSearchResponse,
  SearchItem,
  SearchResultResponse,
  SuggestionSearchResponse,
  TypoCorrectionResponse,
} from "../api/search/model";
import { t } from "i18next";
import {
  BLANK_TOTAL_RESULT,
  SearchSortType,
  SearchTabMenu,
  SEARCH_RESULT_COUNT,
} from "../constants/Search";
import {
  getSearchTextFromStorage,
  removeAllSearchTextFromStorage,
  removeSearchTextFromStorage,
  SearchText,
  setSearchTextInStorage,
} from "../service/Search.service";
import AuthStore from "./AuthStore";
import CommonStore from "./CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../constants/ActHistory";
import { Post } from "../api/feed/model";
import { UserActTypeCode } from "../constants/FeedType.enum";
import { getStringRemoteConfig } from "../utils/remoteConfig";

interface SortAndIndex {
  sort: string;
  lastIndex: number;
}

interface TotalData {
  postList: Post[];
  postCount: number;
  productList: SearchItem[];
  productCount: number;
  brandCommunityList: BrandCommunitySearchItem[];
  brandCommunityCount: number;
  brandCommunityArticleList: BrandCommunityArticleSearchItem[];
  brandCommunityArticleCount: number;
  qnaList: SearchItem[];
  qnaCount: number;
  posterList: SearchItem[];
  posterCount: number;
  tagList: SearchItem[];
  tagCount: number;
}

const DEFAULT_SORT_INDEX = {
  sort: SearchSortType.LATEST,
  lastIndex: 0,
};

const POPULAR_SORT_INDEX = {
  sort: SearchSortType.POPULAR,
  lastIndex: 0,
};

class SearchStore {
  constructor() {
    makeObservable(this);
  }

  @observable errorMessage?: string;
  @observable toastMessage?: string;
  @observable searchText = "";
  @observable searchFocus = false;
  @observable latestSearchTextList: SearchText | null = null;
  @observable autoCompleteResult: string[] = [];
  @observable typoCorrectionResult: TypoCorrectionResponse | null = null;
  @observable typoCorrectionYn = false;
  @observable suggestionSearchResult: SuggestionSearchResponse | null = null;
  @observable rankingSearchResult: RankingSearchResponse | null = null;
  @observable selectedSearchTab: SearchTabMenu = SearchTabMenu.Total;
  @observable
  brandCommunityResult: SearchResultResponse<BrandCommunitySearchItem> | null =
    null;
  @observable
  brandCommunityArticleResult: SearchResultResponse<BrandCommunityArticleSearchItem> | null =
    null;
  @observable productResult: SearchResultResponse<SearchItem> | null = null;
  @observable postResult: SearchResultResponse<Post> | null = null;
  @observable _qnaResult: SearchResultResponse<SearchItem> | null = null;
  @observable posterResult: SearchResultResponse<SearchItem> | null = null;
  @observable tagResult: SearchResultResponse<SearchItem> | null = null;
  @observable brandCommunitySortAndIndex: SortAndIndex = DEFAULT_SORT_INDEX;
  @observable brandCommunityArticleSortAndIndex: SortAndIndex =
    DEFAULT_SORT_INDEX;
  @observable productSortAndIndex: SortAndIndex = POPULAR_SORT_INDEX;
  @observable posterSortAndIndex: SortAndIndex = DEFAULT_SORT_INDEX;
  @observable postSortAndIndex: SortAndIndex = DEFAULT_SORT_INDEX;
  @observable qnaSortAndIndex: SortAndIndex = DEFAULT_SORT_INDEX;
  @observable tagSortAndIndex: SortAndIndex = DEFAULT_SORT_INDEX;
  @observable totalSortAndIndex: SortAndIndex = DEFAULT_SORT_INDEX;
  @observable totalList: TotalData = BLANK_TOTAL_RESULT;
  @observable showSortBox = false;
  @observable loadingInList = false;
  @observable totalCount = 0;
  @observable refreshing = false;
  @observable changedText = false;
  @observable _reportPostCount = 0;
  @observable _reportQnaCount = 0;
  @observable initTabName = SearchTabMenu.Total;
  @observable _showPostList = false;
  @observable _selectedPostIndex = 0;
  @observable _searchResultTabScroll = new Map<string, number>();
  @observable _currentScrollY = 0;

  @computed get currentScrollY() {
    return this._currentScrollY;
  }

  @action setCurrentScrollY = (y: number) => {
    this._currentScrollY = y;
  };

  @computed get searchResultTabScroll() {
    return this._searchResultTabScroll;
  }

  @action setSearchResultTabScroll = (param: string) => {
    const paramArry = param.split("/");

    const path = paramArry.length > 0 ? paramArry[1] : "";
    if (path === "search") {
      const tabName = paramArry.length > 2 ? paramArry[3] : "";
      if (
        tabName === "total" ||
        tabName === "post" ||
        tabName === "product" ||
        tabName === "poster" ||
        tabName === "qna" ||
        tabName === "tag"
      ) {
        this._searchResultTabScroll.set(param, this._currentScrollY);
        this.setCurrentScrollY(0);
      }
    }
  };

  @computed get getSelectedSearchTab() {
    return this.selectedSearchTab;
  }

  @computed get showPostList() {
    return this._showPostList;
  }

  @action setShowPostList = (isShown: boolean) => {
    this._showPostList = isShown;
  };

  @computed get selectedPostIndex() {
    return this._selectedPostIndex;
  }

  @action setSelectedPostIndex = (selectedPostindex: number) => {
    this._selectedPostIndex = selectedPostindex;
  };

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    );
  }

  @computed get reportPostCount() {
    return this._reportPostCount;
  }

  @action setReportPostCount = (count: number) => {
    this._reportPostCount = count;
  };

  @computed get reportQnaCount() {
    return this._reportQnaCount;
  }

  @action setReportQnaCount = (count: number) => {
    this._reportQnaCount = count;
  };

  @action setInitTabName = (tab: SearchTabMenu) => {
    this.initTabName = tab;
  };

  @action getSortType = (type?: string) => {
    switch (type || this.selectedSearchTab) {
      case SearchTabMenu.Total:
        return this.totalSortAndIndex.sort || SearchSortType.LATEST;
      case SearchTabMenu.Product:
        return this.productSortAndIndex.sort || SearchSortType.POPULAR;
      case SearchTabMenu.Post:
        return this.postSortAndIndex.sort || SearchSortType.LATEST;
      case SearchTabMenu.Brand:
        return this.brandCommunitySortAndIndex.sort || SearchSortType.LATEST;
      case SearchTabMenu.BrandArticle:
        return (
          this.brandCommunityArticleSortAndIndex.sort || SearchSortType.LATEST
        );
      case SearchTabMenu.Qna:
        return this.qnaSortAndIndex.sort || SearchSortType.LATEST;
      case SearchTabMenu.Poster:
        return this.posterSortAndIndex.sort || SearchSortType.LATEST;
      case SearchTabMenu.Tag:
        return this.tagSortAndIndex.sort || SearchSortType.LATEST;
      default:
        return SearchSortType.LATEST;
    }
  };

  @computed get getTempMemberUuid() {
    return AuthStore.sessionUser?.memberUuid === undefined ||
      AuthStore.sessionUser?.memberUuid === null ||
      AuthStore.sessionUser?.memberUuid?.length < 1
      ? AuthStore.tempMember?.tempMemberUUID
      : undefined;
  }

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action setToast = (message?: string) => {
    this.toastMessage = message;
  };

  @computed get getLoadingInList() {
    return this.loadingInList;
  }

  @action setLoadingInList = (visible: boolean) => {
    this.loadingInList = visible;
  };

  @action clearTypoCorrection = () => {
    this.typoCorrectionResult = null;
    this.typoCorrectionYn = false;
  };

  @action clear = () => {
    this.errorMessage = "";
    this.toastMessage = "";
    this.searchText = "";
    this.latestSearchTextList = null;
    this.autoCompleteResult = [];
    this.typoCorrectionResult = null;
    this.typoCorrectionYn = false;
    this.suggestionSearchResult = null;
    this.rankingSearchResult = null;
    this.brandCommunityResult = null;
    this.brandCommunityArticleResult = null;
    this.productResult = null;
    this.postResult = null;
    this._qnaResult = null;
    this.posterResult = null;
    this.tagResult = null;
    this.showSortBox = false;
    this.loadingInList = false;
    this.brandCommunitySortAndIndex = DEFAULT_SORT_INDEX;
    this.brandCommunityArticleSortAndIndex = DEFAULT_SORT_INDEX;
    this.productSortAndIndex = POPULAR_SORT_INDEX;
    this.postSortAndIndex = DEFAULT_SORT_INDEX;
    this.qnaSortAndIndex = DEFAULT_SORT_INDEX;
    this.posterSortAndIndex = DEFAULT_SORT_INDEX;
    this.tagSortAndIndex = DEFAULT_SORT_INDEX;
    this.totalSortAndIndex = DEFAULT_SORT_INDEX;
    this.totalList = BLANK_TOTAL_RESULT;
    this.totalCount = 0;
    this.refreshing = false;
    this.changedText = false;
    this._reportPostCount = 0;
    this._reportQnaCount = 0;
    this.setInitTabName(SearchTabMenu.Total);
    this._showPostList = false;
    this._selectedPostIndex = 0;
    this.searchFocus = false;
    this.selectedSearchTab = SearchTabMenu.Total;
    this._searchResultTabScroll.clear();
  };

  @action setRefreshing = (value: boolean) => {
    this.refreshing = value;
  };

  @action setSelectedTab = (tab: SearchTabMenu) => {
    this.selectedSearchTab = tab;
  };

  @action setTotalCount = () => {
    if (this.totalList) {
      this.totalCount =
        this.totalList.productCount +
        this.totalList.postCount +
        this.totalList.brandCommunityCount +
        this.totalList.brandCommunityArticleCount +
        this.totalList.qnaCount +
        this.totalList.posterCount +
        this.totalList.tagCount;
    } else {
      this.totalCount = 0;
    }
  };

  @action setSearchResult = (
    response: SearchResultResponse<any>,
    type: string
  ) => {
    if (this.selectedSearchTab === SearchTabMenu.Total) {
      switch (type) {
        case SearchTabMenu.Product:
          this.totalList.productList = [];
          this.totalList.productList = response.list || [];
          this.totalList.productCount = response.totalCount;
          break;
        case SearchTabMenu.Post:
          this.totalList.postList = [];
          this.totalList.postList = response.feedFindVOList || [];
          this.totalList.postCount = response.totalCount;
          break;
        case SearchTabMenu.Brand:
          this.totalList.brandCommunityList = [];
          this.totalList.brandCommunityList =
            (response.list as unknown as BrandCommunitySearchItem[]) || [];
          this.totalList.brandCommunityCount = response.totalCount;
          break;
        case SearchTabMenu.BrandArticle:
          this.totalList.brandCommunityArticleList = [];
          this.totalList.brandCommunityArticleList =
            (response.list as unknown as BrandCommunityArticleSearchItem[]) ||
            [];
          this.totalList.brandCommunityArticleCount = response.totalCount;
          break;
        case SearchTabMenu.Qna:
          this.totalList.qnaList = [];
          this.totalList.qnaList = response.list || [];
          this.totalList.qnaCount = response.totalCount;
          break;
        case SearchTabMenu.Poster:
          this.totalList.posterList = [];
          this.totalList.posterList = response.list || [];
          this.totalList.posterCount = response.totalCount;
          break;
        case SearchTabMenu.Tag:
          this.totalList.tagList = [];
          this.totalList.tagList = response.list || [];
          this.totalList.tagCount = response.totalCount;
          break;
        default:
          break;
      }
      this.setTotalCount();
    } else {
      switch (type) {
        case SearchTabMenu.Product:
          if (this.productSortAndIndex.lastIndex === 0) {
            this.productResult = response;
          } else this.productResult?.list?.push(...(response.list || []));
          break;
        case SearchTabMenu.Post:
          if (this.postSortAndIndex.lastIndex === 0) {
            this.postResult = {
              totalCount: response.totalCount,
              list: response.feedFindVOList,
            };
          } else
            this.postResult?.list?.push(...(response.feedFindVOList || []));
          break;
        case SearchTabMenu.Brand:
          if (this.brandCommunitySortAndIndex.lastIndex === 0)
            this.brandCommunityResult =
              response as unknown as SearchResultResponse<BrandCommunitySearchItem>;
          else
            this.brandCommunityResult?.list?.push(
              ...((response.list as unknown as BrandCommunitySearchItem[]) ||
                [])
            );
          break;
        case SearchTabMenu.BrandArticle:
          if (this.brandCommunityArticleSortAndIndex.lastIndex === 0)
            this.brandCommunityArticleResult =
              response as unknown as SearchResultResponse<BrandCommunityArticleSearchItem>;
          else
            this.brandCommunityArticleResult?.list?.push(
              ...((response.list as unknown as BrandCommunityArticleSearchItem[]) ||
                [])
            );
          break;
        case SearchTabMenu.Qna:
          if (this.qnaSortAndIndex.lastIndex === 0) this._qnaResult = response;
          else this._qnaResult?.list?.push(...(response.list || []));
          break;
        case SearchTabMenu.Poster:
          if (this.posterSortAndIndex.lastIndex === 0)
            this.posterResult = response;
          else this.posterResult?.list?.push(...(response.list || []));
          break;
        case SearchTabMenu.Tag:
          if (this.tagSortAndIndex.lastIndex === 0) this.tagResult = response;
          else this.tagResult?.list?.push(...(response.list || []));
          break;
        default:
          break;
      }
    }
    if (response == null) this.setToast(t("common.message.unknownException"));
  };

  @action setShowSortBox = (visibility: boolean) => {
    this.showSortBox = visibility;
  };

  @action setSortType = (sort: string) => {
    this.showSortBox = false;
    if (this.selectedSearchTab === SearchTabMenu.Total) {
      this.setSortAndIndex(sort, 0, SearchTabMenu.Total);
      this.refreshTotal();
    } else {
      this.clearEachTabResult(this.selectedSearchTab);
      void this.getSearchResult(
        this.searchText,
        this.selectedSearchTab,
        sort,
        SEARCH_RESULT_COUNT,
        0
      );
    }
  };

  @action setAutoCompleteResult = (list: string[]) => {
    this.autoCompleteResult = list;
  };

  @action setTypoCorrectionResult = (list: TypoCorrectionResponse) => {
    this.typoCorrectionResult = list;
  };

  @action setTypoCorrectionYn = (list: boolean) => {
    this.typoCorrectionYn = list;
  };

  @action setSuggestionSearchResult = (list: SuggestionSearchResponse) => {
    this.suggestionSearchResult = list;
  };

  @action setRankingSearchResult = (list: RankingSearchResponse) => {
    this.rankingSearchResult = list;
  };

  @action clearEachTabResult = (type: string) => {
    switch (type) {
      case SearchTabMenu.Product:
        this.productResult = null;
        break;
      case SearchTabMenu.Post:
        this.postResult = null;
        break;
      case SearchTabMenu.Brand:
        this.brandCommunityResult = null;
        break;
      case SearchTabMenu.BrandArticle:
        this.brandCommunityArticleResult = null;
        break;
      case SearchTabMenu.Qna:
        this._qnaResult = null;
        break;
      case SearchTabMenu.Poster:
        this.posterResult = null;
        break;
      case SearchTabMenu.Tag:
        this.tagResult = null;
        break;
      case SearchTabMenu.Total:
        this.totalList = BLANK_TOTAL_RESULT;
        this.productResult = null;
        this.postResult = null;
        this.brandCommunityResult = null;
        this.brandCommunityArticleResult = null;
        this._qnaResult = null;
        this.posterResult = null;
        this.tagResult = null;
        break;
      default:
        break;
    }
  };

  @action doSearch = async (keyword: string) => {
    this.clearTypoCorrection();
    await this.getTypoCorrection(keyword);

    if (this.changedText) {
      this.setSelectedTab(SearchTabMenu.Total);
      // this.setSelectedTab(this.initTabName);
    }
    this.setChangedText(false);
    this.clearEachTabResult(this.selectedSearchTab);
    keyword.length > 0 && (await setSearchTextInStorage(keyword));

    if (this.selectedSearchTab == SearchTabMenu.Total) {
      this.refreshTotal();
      // 행동로그 통합 검색 키워드/tagId (tagId 정보 없음)
      CommonStore.callActHistory(
        ActHistoryTypeCode.SEARCH,
        `${this.searchText}`,
        ActPageCode.SEARCH_TOTAL
      );
    } else {
      void this.getSearchResult(
        this.searchText,
        this.selectedSearchTab,
        this.getSortType(),
        SEARCH_RESULT_COUNT,
        0
      );
    }
  };

  @action setSortAndIndex = (sort: string, index: number, tab: string) => {
    switch (tab) {
      case SearchTabMenu.Total:
        this.totalSortAndIndex.sort = sort;
        this.totalSortAndIndex.lastIndex = index;
        break;
      case SearchTabMenu.Product:
        this.productSortAndIndex.sort = sort;
        this.productSortAndIndex.lastIndex = index;
        break;
      case SearchTabMenu.Post:
        this.postSortAndIndex.sort = sort;
        this.postSortAndIndex.lastIndex = index;
        break;
      case SearchTabMenu.Brand:
        this.brandCommunitySortAndIndex.sort = sort;
        this.brandCommunitySortAndIndex.lastIndex = index;
        break;
      case SearchTabMenu.BrandArticle:
        this.brandCommunityArticleSortAndIndex.sort = sort;
        this.brandCommunityArticleSortAndIndex.lastIndex = index;
        break;
      case SearchTabMenu.Qna:
        this.qnaSortAndIndex.sort = sort;
        this.qnaSortAndIndex.lastIndex = index;
        break;
      case SearchTabMenu.Poster:
        this.posterSortAndIndex.sort = SearchSortType.LATEST;
        this.posterSortAndIndex.lastIndex = index;
        break;
      case SearchTabMenu.Tag:
        this.tagSortAndIndex.sort = SearchSortType.LATEST;
        this.tagSortAndIndex.lastIndex = index;
        break;
      default:
        break;
    }
  };

  @action getSearchResult = (
    keyword: string,
    tab?: string,
    sort?: string,
    pageSize?: number,
    pageIndex?: number
  ) => {
    this.setLoadingInList(true);
    if (tab === undefined) tab = this.selectedSearchTab;
    if (sort === undefined) sort = SearchSortType.LATEST;
    if (pageSize === undefined) pageSize = 20;
    if (pageIndex === undefined) pageIndex = 0;

    if (this.selectedSearchTab !== SearchTabMenu.Total) {
      this.setSortAndIndex(sort, pageIndex, tab);
    }

    if (this.selectedSearchTab === SearchTabMenu.Post) {
      this._reportPostCount = 0;
    } else if (this.selectedSearchTab === SearchTabMenu.Qna) {
      this._reportQnaCount = 0;
    }

    if (this.typoCorrectionYn && this.typoCorrectionResult) {
      keyword = this.typoCorrectionResult.suggestedKeyword;
    }

    getSearchResult(
      tab,
      keyword,
      pageSize,
      pageIndex,
      sort,
      AuthStore.sessionUser?.memberUuid === undefined ||
        AuthStore.sessionUser?.memberUuid === null ||
        AuthStore.sessionUser?.memberUuid?.length < 1
        ? AuthStore.tempMember?.tempMemberUUID
        : undefined
    )
      .then((res) => {
        runInAction(() => {
          this.setLoadingInList(false);
          this.setRefreshing(false);
          this.setSearchResult(res, tab || this.selectedSearchTab);
        });
      })
      .catch((err) => {
        this.setLoadingInList(false);
        this.setRefreshing(false);
        this.setToast(t("common.message.unknownException"));
      });
  };

  @action getAutoComplete = async (query: string) => {
    const result = query.length > 0 ? await getAutoComplete(query) : [];
    this.setAutoCompleteResult(result);
  };

  @action getSuggestionSearch = async () => {
    const result = await getSuggestionSearch(this.getTempMemberUuid);
    this.setSuggestionSearchResult(result);
  };

  @action getRankingSearch = async () => {
    const result = await getRankingSearch(this.getTempMemberUuid);
    this.setRankingSearchResult(result);
  };

  @action getTypoCorrection = async (query: string) => {
    const result = await getTypoCorrection(query);
    this.setTypoCorrectionResult(result);

    if (result.originKeyword != result.suggestedKeyword) {
      this.setTypoCorrectionYn(true);
    } else {
      this.setTypoCorrectionYn(false);
    }
  };

  @action setSearchTextInStorage = async (searchText: string) => {
    await setSearchTextInStorage(searchText);
  };

  @action setLastestSearchText = (searchText: SearchText | null) => {
    this.latestSearchTextList = searchText;
  };

  @action setSearchFocus = (focus: boolean) => {
    this.searchFocus = focus;
  };

  @action setSearchText = (searchText: string) => {
    this.searchText = searchText;
  };

  @action getLastestSearchText = async () => {
    const latestSearchText = await getSearchTextFromStorage();
    this.setLastestSearchText(latestSearchText);
  };

  @action removeSearchTextFromStorage = async (searchText: string) => {
    await removeSearchTextFromStorage(searchText);
  };

  @action removeAllSearchTextFromStorage = async () => {
    await removeAllSearchTextFromStorage();
  };
  @computed get qnaResult() {
    return this._qnaResult;
  }

  @action refreshTotal = () => {
    this.totalCount = 0;
    void this.getSearchResult(
      this.searchText,
      SearchTabMenu.Product,
      this.totalSortAndIndex.sort,
      10,
      0
    );
    void this.getSearchResult(
      this.searchText,
      SearchTabMenu.Post,
      this.totalSortAndIndex.sort,
      6,
      0
    );
    if (getStringRemoteConfig("show_brand_page") === "true") {
      void this.getSearchResult(
        this.searchText,
        SearchTabMenu.Brand,
        this.totalSortAndIndex.sort,
        9,
        0
      );
      void this.getSearchResult(
        this.searchText,
        SearchTabMenu.BrandArticle,
        this.totalSortAndIndex.sort,
        3,
        0
      );
    }
    void this.getSearchResult(
      this.searchText,
      SearchTabMenu.Qna,
      this.totalSortAndIndex.sort,
      3,
      0
    );
    void this.getSearchResult(
      this.searchText,
      SearchTabMenu.Poster,
      SearchSortType.LATEST,
      10,
      0
    );
    void this.getSearchResult(
      this.searchText,
      SearchTabMenu.Tag,
      SearchSortType.LATEST,
      10,
      0
    );
  };

  @action needToSearch = (type: string) => {
    switch (type) {
      case SearchTabMenu.Product:
        return (
          this.productResult == null ||
          this.totalList.productCount !== (this.productResult?.totalCount || 0)
        );
      case SearchTabMenu.Post:
        return (
          this.postResult == null ||
          this.totalList.postCount !== (this.postResult?.totalCount || 0)
        );
      case SearchTabMenu.Brand:
        return (
          this.brandCommunityResult == null ||
          this.totalList.brandCommunityCount !==
            (this.brandCommunityResult?.totalCount || 0)
        );
      case SearchTabMenu.BrandArticle:
        return (
          this.brandCommunityArticleResult == null ||
          this.totalList.brandCommunityArticleCount !==
            (this.brandCommunityArticleResult?.totalCount || 0)
        );
      case SearchTabMenu.Qna:
        return (
          this.qnaResult == null ||
          this.totalList.qnaCount !== (this.qnaResult?.totalCount || 0)
        );
      case SearchTabMenu.Poster:
        return (
          this.posterResult == null ||
          this.totalList.posterCount !== (this.posterResult?.totalCount || 0)
        );
      case SearchTabMenu.Tag:
        return (
          this.tagResult == null ||
          this.totalList.tagCount !== (this.tagResult?.totalCount || 0)
        );
      case SearchTabMenu.Total:
        return this.totalCount == 0;
      default:
        return true;
    }
  };

  @action setChangedText = (isChanged: boolean) => {
    this.changedText = isChanged;
  };

  @action popQnaFromSearch = (feedId: number) => {
    let updatedResult = this._qnaResult?.list?.filter(
      (x) => x.feedId !== feedId
    );
    if (updatedResult !== undefined && this._qnaResult) {
      this._qnaResult.list = updatedResult;
    }
    updatedResult = this.totalList.qnaList.filter((x) => x.feedId !== feedId);
    if (updatedResult !== undefined && this.totalList.qnaList) {
      this.totalList.qnaList = updatedResult;
      this.totalList.qnaCount = this.totalList.qnaCount - this.reportQnaCount;
      this.totalCount = this.totalCount - this.reportQnaCount;
    }
  };

  @action popPostFromSearch = (feedId: number) => {
    const updatedResult = this.postResult?.list?.filter(
      (x) => x.feedId !== feedId
    );
    if (updatedResult !== undefined && this.postResult) {
      this.postResult.list = updatedResult;
    }
    const updatedResultTotal = this.totalList.postList.filter(
      (x) => x.feedId !== feedId
    );
    if (updatedResultTotal !== undefined && this.totalList.postList) {
      this.totalList.postList = updatedResultTotal;
      this.totalList.postCount =
        this.totalList.postCount - this.reportPostCount;
      this.totalCount = this.totalCount - this.reportPostCount;
    }
  };

  @action updatePost = (feedId: number, post: Post) => {
    const totalListIndex = this.totalList.postList.findIndex(
      (x) => x.feedId == feedId
    );
    const postListIndex =
      this.postResult?.list?.findIndex((x) => x.feedId == feedId) ?? -1;

    if (totalListIndex != -1) {
      this.totalList &&
        this.totalList.postList &&
        this.totalList.postList.forEach((pitem: any, index: number) => {
          if (index === totalListIndex) {
            pitem = { ...post };
          }
        });

      //  this.totalList.postList.splice(totalListIndex, 1, post);
    }

    if (postListIndex !== -1 && this.postResult !== undefined) {
      this.postResult &&
        this.postResult.list &&
        this.postResult.list.forEach((pitem: any, index: number) => {
          if (index === postListIndex) {
            pitem = { ...post };
          }
        });

      //  this.postResult.list?.splice(postListIndex, 1, post);
    }
  };

  @action updateCommentCount = (feedId: number, post: Post) => {
    const totalListIndex = this.totalList.postList.findIndex(
      (x) => x.feedId === feedId
    );
    const postListIndex =
      this.postResult?.list?.findIndex((x) => x.feedId == feedId) ?? -1;

    if (totalListIndex !== -1) {
      this.totalList &&
        this.totalList.postList &&
        this.totalList.postList.forEach((pitem: any, index: number) => {
          if (index === totalListIndex) {
            pitem = { ...pitem, commentCount: post.commentCount };
            //  pitem.commentCount = post.commentCount;
          }
        });

      // const newTotalListPost = {
      //   ...this.totalList.postList[totalListIndex],
      //   commentCount: post.commentCount,
      // };
      // this.totalList.postList.splice(totalListIndex, 1, newTotalListPost);
    }

    if (postListIndex !== -1 && this.postResult !== undefined) {
      this.postResult &&
        this.postResult.list &&
        this.postResult.list.forEach((pitem: any, index: number) => {
          if (index === postListIndex) {
            pitem = { ...pitem, commentCount: post.commentCount };
            //            pitem.commentCount = post.commentCount;
          }
        });

      // const newPostListPost = {
      //   ...this.totalList.postList[postListIndex],
      //   commentCount: post.commentCount,
      // };
      // this.postResult.list?.splice(postListIndex, 1, newPostListPost);
    }
  };

  @action updateLikeAndBookmark = (
    feedId: number,
    actionType: UserActTypeCode
  ) => {
    const totalListIndex = this.totalList.postList.findIndex(
      (x) => x.feedId === feedId
    );
    const postListIndex =
      this.postResult?.list?.findIndex((x) => x.feedId === feedId) ?? -1;

    if (totalListIndex !== -1 && this.totalList && this.totalList.postList) {
      let postobj = this.totalList.postList[totalListIndex];
      if (
        UserActTypeCode.CANCEL_LIKE === actionType &&
        postobj.likeYn !== "N"
      ) {
        postobj = {
          ...postobj,
          likeCount: (postobj.likeCount ?? 1) - 1,
          likeYn: "N",
        };
      } else if (
        UserActTypeCode.SAVE_LIKE === actionType &&
        postobj.likeYn !== "Y"
      ) {
        postobj = {
          ...postobj,
          likeCount: (postobj.likeCount ?? 0) + 1,
          likeYn: "Y",
        };
      } else if (UserActTypeCode.CANCEL_BOOKMARK === actionType) {
        postobj = { ...postobj, bookmarkYn: "N" };
      } else if (UserActTypeCode.SAVE_BOOKMARK === actionType) {
        postobj = { ...postobj, bookmarkYn: "Y" };
      }

      this.totalList.postList[totalListIndex] = { ...postobj };
      /*
      const newTotalListPost = {
        ...this.totalList.postList[totalListIndex],
      };

      switch (actionType) {
        case UserActTypeCode.SAVE_LIKE:
          newTotalListPost.likeYn = "Y";
          newTotalListPost.likeCount = (newTotalListPost.likeCount ?? 0) + 1;

          break;
        case UserActTypeCode.CANCEL_LIKE:
          newTotalListPost.likeYn = "N";
          newTotalListPost.likeCount = (newTotalListPost.likeCount ?? 1) - 1;

          break;
        case UserActTypeCode.SAVE_BOOKMARK:
          newTotalListPost.bookmarkYn = "Y";

          break;
        case UserActTypeCode.CANCEL_BOOKMARK:
          newTotalListPost.bookmarkYn = "N";

          break;
      }

      this.totalList.postList.splice(totalListIndex, 1, newTotalListPost);
*/
    }

    if (postListIndex !== -1 && this.postResult && this.postResult.list) {
      let postobj = this.postResult.list[postListIndex];
      if (
        UserActTypeCode.CANCEL_LIKE === actionType &&
        postobj.likeYn !== "N"
      ) {
        postobj = {
          ...postobj,
          likeCount: ((postobj.likeCount as number) ?? 1) - 1,
          likeYn: "N",
        };
      } else if (
        UserActTypeCode.SAVE_LIKE === actionType &&
        postobj.likeYn !== "Y"
      ) {
        postobj = {
          ...postobj,
          likeCount: ((postobj.likeCount as number) ?? 0) + 1,
          likeYn: "Y",
        };
      } else if (UserActTypeCode.CANCEL_BOOKMARK === actionType) {
        postobj = { ...postobj, bookmarkYn: "N" };
      } else if (UserActTypeCode.SAVE_BOOKMARK === actionType) {
        postobj = { ...postobj, bookmarkYn: "Y" };
      }

      this.postResult.list[postListIndex] = { ...postobj };
      /*
      const newPostListPost = {
        ...this.totalList.postList[postListIndex],
      };
      switch (actionType) {
        case UserActTypeCode.SAVE_LIKE:
          newPostListPost.likeYn = "Y";
          newPostListPost.likeCount = (newPostListPost.likeCount ?? 0) + 1;

          break;
        case UserActTypeCode.CANCEL_LIKE:
          newPostListPost.likeYn = "N";
          newPostListPost.likeCount = (newPostListPost.likeCount ?? 1) - 1;

          break;
        case UserActTypeCode.SAVE_BOOKMARK:
          newPostListPost.bookmarkYn = "Y";

          break;
        case UserActTypeCode.CANCEL_BOOKMARK:
          newPostListPost.bookmarkYn = "N";

          break;
      }

      this.postResult.list?.splice(postListIndex, 1, newPostListPost);
      */
    }
  };

  @action updateBrandFollowYn = (
    communityId: number,
    subscriptionYn: "Y" | "N"
  ) => {
    const _index = this.brandCommunityResult?.list?.findIndex(
      (item) => item.communityId === communityId
    );

    if (_index !== undefined && _index >= 0) {
      const _brand = this.brandCommunityResult?.list?.[_index];
      _brand &&
        this.brandCommunityResult?.list?.splice(_index, 1, {
          ..._brand,
          subscriptionYn,
          communitySubscriberCount:
            +_brand.communitySubscriberCount +
            (subscriptionYn === "Y" ? 1 : -1),
        });
    }
  };

  @action syncPost = (feedId: number, action: UserActTypeCode, post?: Post) => {
    switch (action) {
      case UserActTypeCode.MODIFY:
        if (post !== undefined) {
          this.updatePost(feedId, post);
        }
        break;
      case UserActTypeCode.DELETE:
        this.popPostFromSearch(feedId);
        break;
      case UserActTypeCode.UPDATE_COMMENT:
        if (post !== undefined) {
          this.updateCommentCount(feedId, post);
        }
        break;
      case UserActTypeCode.SAVE_LIKE:
      case UserActTypeCode.CANCEL_LIKE:
      case UserActTypeCode.SAVE_BOOKMARK:
      case UserActTypeCode.CANCEL_BOOKMARK:
        this.updateLikeAndBookmark(feedId, action);
        break;
    }
  };
}

export default new SearchStore();
