import { action, computed, makeObservable, observable } from 'mobx'

interface VideoContentType {
  contentIdx: number
  mediaIdx: number
  video: { [key: string]: any }
}

class VideoStore {
  constructor() {
    makeObservable(this)
  }

  @observable _videoContentList: VideoContentType[] = []
  // @observable _video: VideoPlayer | null = null;
  @observable _video: any | null = null
  @observable _autoPlay = true

  @computed get videoContentList() {
    return this._videoContentList
  }

  @computed get video() {
    return this._video
  }

  @computed get autoPlay() {
    return this._autoPlay
  }

  @action videoStoreClear = () => {
    this._videoContentList = []
    this._video = null
    this._autoPlay = true
  }

  // @action addVideoContentList = (contentIdx: number, mediaIdx: number, video: VideoPlayer) => {
  @action addVideoContentList = (
    contentIdx: number,
    mediaIdx: number,
    video: any
  ) => {
    const index = this._videoContentList.findIndex(
      (data: VideoContentType) =>
        data.contentIdx === contentIdx && data.mediaIdx === mediaIdx
    )

    if (index !== -1) this._videoContentList.splice(index, 1)

    video &&
      video !== undefined &&
      this._videoContentList.push({
        contentIdx: contentIdx,
        mediaIdx: mediaIdx,
        video: video,
      })
  }

  @action getVideoContent = (contentIdx: number, mediaIdx: number) => {
    const videoContent = this._videoContentList.filter(
      (data: VideoContentType) =>
        data.contentIdx === contentIdx && data.mediaIdx === mediaIdx
    )

    return videoContent.length > 0 ? videoContent[0] : undefined
  }

  @action removeVideoContent = (contentIdx: number, mediaIdx: number) => {
    const content: VideoContentType | undefined = this.getVideoContent(
      contentIdx,
      mediaIdx
    )

    if (content !== undefined)
      this._videoContentList.splice(this._videoContentList.indexOf(content), 1)
  }

  @action clearVideoContentList = () => {
    this._videoContentList = []
  }

  // @action setVideo = (newVideo: VideoPlayer | null) => {
  @action setVideo = (newVideo: any | null) => {
    this._video = newVideo
  }

  @action setAutoPlay = (autoPlay: boolean) => {
    this._autoPlay = autoPlay
  }

  // @action toggleVideoPlayer = (isView: boolean, video: VideoPlayer) => {
  @action toggleVideoPlayer = (isView: boolean, video: any) => {
    if (isView) {
      video?.resume()
    } else {
      video?.pause()
    }
  }

  @action pauseVideo = () => {
    this._video?.pause()
  }

  @action pauseAllVideo = () => {
    this._videoContentList?.forEach((videoContent: VideoContentType) => {
      videoContent?.video?.pause()
    })
  }

  @action resumeVideo = () => {
    this._video?.resume()
  }
}

export default VideoStore
