import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react";

import MainStore from "../../store/MainStore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PreRenderMainStore from "../../store/main/PreRenderMainStore";
import { getDateStr, getDdayFromToday, isPast } from "../../utils/datetime";
import {
  MainItem,
  PopularQna,
  PopularRanking,
  PopularTag,
  Post,
} from "../../api/feed/model";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import Rating from "../rating/Rating";
import MainBanner from "../main/presenter/MainBanner";
import MainProfile from "../main/presenter/MainProfile";
import MainTextBanner from "../main/presenter/MainTextBanner";
import MainSectionItem from "../main/presenter/MainSectionItem";
import AuthStore from "../../store/AuthStore";
import MarketingAgreeModal from "../../components/modals/MarketingAgreeModal";
import { getAlarmDefaultSetting } from "../../api/setting/alarm/api";
import { AllowTypeCode, SignUpPath } from "../../constants/CommonCode.enum";
import { updateMember, webIssueCoupon } from "../../api/member/api";
import {
  NOTICE_BANNER,
  POPUP_BANNER,
  PUSH_ALLOW_YN,
  MAIN_MALL_NOTICE,
  REDIRECT_PATH,
} from "../../constants/Storage";
import { AlarmSettingList } from "../../api/setting/alarm/model";
import { getSessionUserFromStorage } from "../../service/Session.service";
import { BadgeCode, IsMember, SearchType } from "../../constants/Badge.enum";
import { BannerTypeCodes } from "../../constants/Banner.enum";
import dayjs from "dayjs";
import BadgeStore from "../../store/BadgeStore";
import { handleTopButtonScroll } from "../../utils/common";
import RecommenderStore from "../../store/RecommenderStore";
import { useTracker } from "../../hooks/tracker";
import PopularPost from "../main/popularPost/PopularPost";
import { MainFeedTypeCode } from "../../constants/FeedType.enum";
import { getTempMemberFromStorage } from "../../service/Preference.service";
import { getTempMember, updateTempMember } from "../../api/tempMember/api";
import { TempMember } from "../../api/tempMember/model";
import { sendActHistory } from "../../api/data/api";
import { ActHistoryRequest } from "../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../constants/ActHistory";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import { track } from "../../hooks/tracker/TrackFunction";
import RemoteConfigStore from "../../store/RemoteConfigStore";
import CommonStore from "../../store/CommonStore";
import { ReactComponent as Spinner } from "../../assets/animations/spinner.svg";
import { useWindowScroll } from "react-use";
import { PullToRefresh, List } from "antd-mobile";
import { sleep } from "antd-mobile/es/utils/sleep";
import { PullStatus } from "antd-mobile/es/components/pull-to-refresh";
import { ReactComponent as DarkSpinner } from "../../assets/animations/darkSpinner.svg";
import MainLayout from "../main/MainLayout";
import MainPopupBanner from "../main/presenter/MainPopupBanner";
import VoltModal from "../main/presenter/VoltModal";
import SearchStore from "../../store/SearchStore";
import "./HomeScreen.scss";
import { ReactComponent as MainProfileAI } from "../../assets/icons/main/profile_title.svg";
import { useLocation } from "react-router-dom";
import { native } from "../../native/native";
import { getStringRemoteConfig } from "../../utils/remoteConfig";
import MainTutorial from "../main/presenter/MainTutorial";
import { Platform, PlatformTypes } from "../../native/platform";
import WebJoinMemberCouponModal from "../main/presenter/WebJoinMemberCouponModal";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import { goToAppDetail } from "../../utils/appstore";

enum MainPopupType {
  TUTORIAL = "TUTORIAL",
  PUSH_ALLOW = "PUSH_ALLOW",
  TEXT_NOTICE = "TEXT_NOTICE",
  BOTTOM_BANNER = "BOTTOM_BANNER",
  MEMBER_JOIN_VOLT_SAVE = "MEMBER_JOIN_VOLT_SAVE",
  MAIN_MALL_NOTICE = "MAIN_MALL_NOTICE",
  WEB_JOIN_MEMBER_COUPON = "WEB_JOIN_MEMBER_COUPON",
}

const HomeScreen = observer(() => {
  const {
    mainList,
    isLoadingMore,
    canLoadMore,
    setIsLoadingMore,
    getMoreMain,
  } = MainStore;

  const { sessionUser, showProfileBanner, setShowProfileBanner, tempMember } =
    AuthStore;
  const [isModalVisible, setModalVisible] = useState(false); // 푸시 동의 팝업 노출 제어
  const [showMemberJoinVoltSaveModal, setShowMemberJoinVoltSaveModal] =
    useState(false);
  const [showMainMallNotice, setShowMainMallNotice] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isShowTutorial, setIsShowTutorial] = useState<boolean>(false);
  const [showWebJoinMemberCouponModal, setShowWebJoinMemberCouponModal] =
    useState(false);
  const [tracker] = useState(useTracker());

  const { x, y } = useWindowScroll();

  const bannerRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const defaultMainPopupList = [
    { type: MainPopupType.TUTORIAL, isShow: false },
    { type: MainPopupType.PUSH_ALLOW, isShow: false },
    { type: MainPopupType.TEXT_NOTICE, isShow: false },
    { type: MainPopupType.MAIN_MALL_NOTICE, isShow: false },
    { type: MainPopupType.BOTTOM_BANNER, isShow: false },
    { type: MainPopupType.MEMBER_JOIN_VOLT_SAVE, isShow: false },
    { type: MainPopupType.WEB_JOIN_MEMBER_COUPON, isShow: false },
  ];
  const [mainPopupList, setMainPopupList] =
    useState<any[]>(defaultMainPopupList);

  useEffect(() => {
    let redirect;
    try {
      redirect = JSON.parse(localStorage.getItem(REDIRECT_PATH) || "{}");
    } catch (error) {
      localStorage.removeItem(REDIRECT_PATH);
    }

    if (redirect && redirect.showMainJoinModal) {
      MainStore.setShowJoinModal(true);
    } else if (
      !!!CommonStore.loading &&
      !!!MainStore.confirmMainPopupList &&
      (location.pathname === "/" || location.pathname === "/main")
    ) {
      void closeAllMainPopup();
      void settingMainPopupList();
    }
    return () => {
      // 화면 이동 전 모든 팝업 닫기
      void closeAllMainPopup();
    };
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
        actPageCode: ActPageCode.MAIN,
        actSourcePageCode: CommonStore.fixedPreviousRouteName,
        attr1: (y === 0).toString(),
      };
      void sendActHistory(actHistory);
    }, 1000);
  }, []);

  const changeMainPopupList = (type: MainPopupType, val: boolean) => {
    const temp = mainPopupList;
    temp.forEach((x) => {
      if (x.type === type) x.isShow = val;
    });
    setMainPopupList(temp);
  };

  const settingMainPopupList = useCallback(async () => {
    const pushAllowYnCheck = async () => {
      const sessionUser = await getSessionUserFromStorage();
      if (sessionUser?.memberUuid) {
        const storagePushAllowYn = (await localStorage.getItem(
          `${PUSH_ALLOW_YN}_${sessionUser.memberUuid}`
        )) as AllowTypeCode;
        if (!storagePushAllowYn) {
          const { pushAllowYn } =
            (await getAlarmDefaultSetting()) as AlarmSettingList;
          if (pushAllowYn === AllowTypeCode.Y) {
            await localStorage.setItem(
              `${PUSH_ALLOW_YN}_${sessionUser.memberUuid}`,
              pushAllowYn
            );
          } else if (!pushAllowYn || pushAllowYn === AllowTypeCode.N) {
            changeMainPopupList(MainPopupType.PUSH_ALLOW, true);
          }
        }
      } else {
        const tempSessionUser = await getTempMemberFromStorage();
        if (tempSessionUser?.tempMemberUUID) {
          const storagePushAllowYn = (await localStorage.getItem(
            `${PUSH_ALLOW_YN}_${tempSessionUser.tempMemberUUID}`
          )) as AllowTypeCode;
          if (!storagePushAllowYn) {
            const { pushAllowYn } = (await getTempMember(
              tempSessionUser.tempMemberUUID
            )) as TempMember;
            if (pushAllowYn === AllowTypeCode.Y) {
              await localStorage.setItem(
                `${PUSH_ALLOW_YN}_${tempSessionUser.tempMemberUUID}`,
                pushAllowYn
              );
            } else if (!pushAllowYn || pushAllowYn === AllowTypeCode.N) {
              changeMainPopupList(MainPopupType.PUSH_ALLOW, true);
            }
          }
        }
      }
    };
    const textNoticeCheck = async () => {
      const today = dayjs().format("YYYYMMDD");

      const getBannerContents = () => {
        void MainStore.getBannerContents(
          BannerTypeCodes.MAIN_POP_UP_NOTIFICATION_BANNER
        ).then(() => {
          if (MainStore.textBanner) {
            changeMainPopupList(MainPopupType.TEXT_NOTICE, true);
          }
        });
      };

      const noticeBannerStr = await localStorage.getItem(NOTICE_BANNER);
      if (noticeBannerStr) {
        const noticeBanner = JSON.parse(noticeBannerStr);
        const showNoticeBannerYn = noticeBanner?.yn;
        const showNoticeBannerLastDay = noticeBanner?.lastDay;
        if (showNoticeBannerYn == "N") {
          if (today !== showNoticeBannerLastDay) {
            // 마지막으로 '안보기' 한 날짜가 오늘이 아니라면
            const noticeItemStr = JSON.stringify({ yn: "Y", lastDay: today });
            void localStorage.setItem(NOTICE_BANNER, noticeItemStr);
            getBannerContents();
          }
        } else {
          getBannerContents();
        }
      } else {
        getBannerContents();
      }
    };
    const mainMallNoticeCheck = async () => {
      const temp: string = RemoteConfigStore.main_mall_notice;
      if (temp) {
        const dataList: { isShow: string; date: string; contents: string } =
          JSON.parse(temp ?? {});
        const memberUuid = AuthStore.sessionUser?.memberUuid;
        const createdDateTime = dayjs(AuthStore?.sessionUser?.createdDateTime);
        const date = dayjs(dataList.date);

        if (
          dataList.isShow === "true" &&
          memberUuid &&
          createdDateTime.isBefore(date)
        ) {
          const result = await localStorage.getItem(
            `${MAIN_MALL_NOTICE}_${memberUuid}`
          );
          if (result !== "Y") {
            changeMainPopupList(MainPopupType.MAIN_MALL_NOTICE, true);
          }
        }
      }
    };
    const bottomBannerCheck = async () => {
      const today = dayjs().format("YYYYMMDD");

      const getBannerContents = () => {
        void MainStore.getBannerContents(
          BannerTypeCodes.MAIN_POP_UP_BANNER
        ).then(() => {
          if (MainStore.popupBannerList?.length > 0) {
            changeMainPopupList(MainPopupType.BOTTOM_BANNER, true);
          }
        });
      };

      const popupBannerStr = await localStorage.getItem(POPUP_BANNER);
      if (popupBannerStr) {
        const popupBanner = JSON.parse(popupBannerStr);
        const showPopupBannerYn = popupBanner?.yn;
        const showPopupBannerLastDay = popupBanner?.lastDay;
        if (showPopupBannerYn == "N") {
          if (today !== showPopupBannerLastDay) {
            // 마지막으로 '안보기' 한 날짜가 오늘이 아니라면
            const popupItemStr = JSON.stringify({ yn: "Y", lastDay: today });
            void localStorage.setItem(
              POPUP_BANNER,
              JSON.stringify(popupItemStr)
            );
            getBannerContents();
          }
        } else {
          getBannerContents();
        }
      } else {
        getBannerContents();
      }
    };
    const tutorialCheck = async () => {
      /*  [6396]web main tutorial 제거 */
      if (Platform.isApp) {
        const isShownTutorial = await localStorage.getItem(
          "isShownMainTutorial"
        );
        if (!isShownTutorial) {
          changeMainPopupList(MainPopupType.TUTORIAL, true);
        }
      }
    };
    const webJoinMemberCouponCheck = async () => {
      if (Platform.isApp) {
        const webJoinMemberCoupon = await localStorage.getItem(
          "webJoinMemberCoupon"
        );
        if (
          webJoinMemberCoupon !== `SUCCESS_${AuthStore.sessionUser?.memberUuid}`
        ) {
          const couponId = getStringRemoteConfig("web_join_member_coupon_id");
          const res = await webIssueCoupon(couponId);
          if (res !== null) {
            const { signUpPath, issueCouponFlag, couponFlag } = res;
            (signUpPath === SignUpPath.MOBILE_WEB ||
              signUpPath === SignUpPath.WEB) &&
              couponFlag &&
              issueCouponFlag &&
              changeMainPopupList(MainPopupType.WEB_JOIN_MEMBER_COUPON, true);
          }
        }
      } else {
        const couponId = getStringRemoteConfig("web_join_member_coupon_id");
        const res = await webIssueCoupon(couponId);
        if (res !== null) {
          const { signUpPath, issueCouponFlag, couponFlag } = res;
          if (AuthStore.sessionUser?.nickname) {
            (signUpPath === SignUpPath.MOBILE_WEB ||
              signUpPath === SignUpPath.WEB) &&
            couponFlag
              ? changeMainPopupList(MainPopupType.WEB_JOIN_MEMBER_COUPON, true)
              : changeMainPopupList(
                  MainPopupType.WEB_JOIN_MEMBER_COUPON,
                  false
                );
          } else {
            couponFlag
              ? changeMainPopupList(MainPopupType.WEB_JOIN_MEMBER_COUPON, true)
              : changeMainPopupList(
                  MainPopupType.WEB_JOIN_MEMBER_COUPON,
                  false
                );
          }
        }
      }
    };
    void closeAllMainPopup();
    await Promise.all([
      pushAllowYnCheck(),
      textNoticeCheck(),
      mainMallNoticeCheck(),
      bottomBannerCheck(),
      tutorialCheck(),
      webJoinMemberCouponCheck(),
    ]).finally(() => {
      if (MainStore.showMemberJoinVoltSavePop) {
        changeMainPopupList(MainPopupType.MEMBER_JOIN_VOLT_SAVE, true);
        MainStore.setShowMemberJoinVoltSavePop(false);
      }

      setTimeout(() => {
        handleMainPopup();
        MainStore.setConfirmMainPopupList(true);
      }, 1000);
    });
  }, []);

  const closeAllMainPopup = () => {
    setMainPopupList(defaultMainPopupList);
    setModalVisible(false);
    setShowWebJoinMemberCouponModal(false);
    setShowMemberJoinVoltSaveModal(false);
    setIsShowTutorial(false);
    MainStore.setShowBottomSheet(false);
    MainStore.setShowTextBannerModal(false);
    setShowMainMallNotice(false);
  };

  const handleMainPopup = () => {
    if (mainPopupList.find((x) => x.type === MainPopupType.PUSH_ALLOW).isShow) {
      setModalVisible(true);
    } else if (
      mainPopupList.find((x) => x.type === MainPopupType.WEB_JOIN_MEMBER_COUPON)
        .isShow
    ) {
      setShowWebJoinMemberCouponModal(true);
    } else if (
      mainPopupList.find((x) => x.type === MainPopupType.MEMBER_JOIN_VOLT_SAVE)
        .isShow
    ) {
      setShowMemberJoinVoltSaveModal(true);
    } else if (
      mainPopupList.find((x) => x.type === MainPopupType.TUTORIAL).isShow
    ) {
      setIsShowTutorial(true);
    } else if (
      mainPopupList.find((x) => x.type === MainPopupType.BOTTOM_BANNER).isShow
    ) {
      MainStore.setShowBottomSheet(true);
    } else if (
      mainPopupList.find((x) => x.type === MainPopupType.TEXT_NOTICE).isShow
    ) {
      MainStore.setShowTextBannerModal(true);
    } else if (
      mainPopupList.find((x) => x.type === MainPopupType.MAIN_MALL_NOTICE)
        .isShow
    ) {
      setShowMainMallNotice(true);
    }
  };

  const updateMemberPushAllowYn = async (pushAllowYn: AllowTypeCode) => {
    const sessionUser = await getSessionUserFromStorage();
    if (sessionUser?.memberUuid) {
      await updateMember({ pushAllowYn });
      await localStorage.setItem(
        `${PUSH_ALLOW_YN}_${sessionUser.memberUuid}`,
        pushAllowYn
      );
    } else {
      const tempSessionUser = await getTempMemberFromStorage();
      const token = await native.getPushToken();
      if (tempSessionUser?.tempMemberUUID) {
        const request = {
          tempMemberUuid: tempSessionUser.tempMemberUUID,
          tempMemberId: tempSessionUser.tempMemberId,
          pushAllowYn: pushAllowYn,
          devicePushToken: token,
        };
        await updateTempMember(request);
        await localStorage.setItem(
          `${PUSH_ALLOW_YN}_${tempSessionUser.tempMemberUUID}`,
          pushAllowYn
        );
      }
    }
  };

  const initBadges = useCallback(() => {
    void BadgeStore.getMemberBadges({
      searchType: SearchType.ALL,
      isMember: IsMember.Y,
    });
  }, []);

  const initMainByApiCall = useCallback((memberUuid: string | undefined) => {
    void Promise.all([
      PreRenderMainStore.initFlatListData(),
      PreRenderMainStore.initMain(memberUuid),
    ])
      .then((result) => {
        const mainItems = result[0];
        MainStore.setMainList(mainItems ?? []);
        MainStore.setCurrentNumberOfList(1);
      })
      .finally(() => {
        PreRenderMainStore.clear();
      });
  }, []);

  useEffect(() => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      if (!isLoadingMore) {
        setIsLoadingMore(true);
        void getMoreMain();
      }
    }
  }, [y]);

  const onRefresh = () => {
    MainStore.setIsRefreshing(true);

    initMainByApiCall(sessionUser?.memberUuid ?? "");

    MainStore.setIsLoadingMore(false);
    MainStore.setInitLoaded(false);
    MainStore.setIsRefreshing(false);
    MainStore.postMap.clear();
    MainStore.loggingCompleteSet.clear();
    MainStore.setConfirmMainPopupList(false);
    tracker.track("view_home", {});
  };

  useEffect(() => {
    const heightOfMainBanner: number = bannerRef.current?.offsetHeight || 0;
    const heightOfMainProfile: number = profileRef.current?.offsetHeight || 0;
    const currentHeightOfScroll = y;

    if (showProfileBanner && currentHeightOfScroll < heightOfMainBanner) {
      setShowProfileBanner(false);
    }

    if (
      !showProfileBanner &&
      currentHeightOfScroll > heightOfMainBanner + heightOfMainProfile
    ) {
      setShowProfileBanner(true);
    }

    // 툴팁 노출 제어
    CommonStore.disabledMainTooltip();
  }, [y]);

  const renderListHeader = useMemo(() => {
    return (
      <div className="renderListHeader">
        <MainBanner bannerRef={bannerRef} />
        <MainProfile isLoggedIn={isLoggedIn} profileRef={profileRef} />
        <PopularPost />
      </div>
    );
  }, [isLoggedIn]);

  useEffect(() => {
    initBadges();
    void MainStore.trackAllUserProperties();
  }, [initBadges]);

  useEffect(() => {
    if (sessionUser?.memberUuid) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [sessionUser?.memberUuid]);

  useEffect(() => {
    if (mainList.length === 0) {
      initMainByApiCall("");
      MainStore.setIsLoadingMore(false);
    }
  }, [initMainByApiCall]);
  /* eslint-enable */

  return (
    <MainLayout>
      <div id="HomeScreen">
        <PullToRefresh
          onRefresh={async () => {
            await sleep(1000);
            onRefresh();
          }}
          renderText={(status) => {
            return (
              <div>
                {status === "refreshing" && (
                  <DarkSpinner style={{ width: 50, height: 50 }} />
                )}
              </div>
            );
          }}
        >
          <div className="main">
            <div className="body">
              <div className="contents_wrap">
                {renderListHeader}
                <div key={"MAIN_POST_AI"}>
                  <div className="bg_popular_post">
                    <div className="img_popular_post">
                      <MainProfileAI width={83} />
                    </div>
                  </div>
                </div>
                {mainList &&
                  mainList.map((item: any, index: number) => {
                    return (
                      <div
                        className={"rowRenderer " + item.sort}
                        style={{ display: !item ? "none" : "" }}
                      >
                        <MainSectionItem
                          item={item}
                          index={index}
                          visible={true}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </PullToRefresh>
      </div>

      {/* 팝업 */}
      {isModalVisible && (
        <MarketingAgreeModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          onAgreeBtnPress={() => {
            tracker.track("click_push_allow_button", {});
            void updateMemberPushAllowYn(AllowTypeCode.Y);
            changeMainPopupList(MainPopupType.PUSH_ALLOW, false);
            handleMainPopup();
          }}
          onDisagreeBtnPress={() => {
            void updateMemberPushAllowYn(AllowTypeCode.N);
            changeMainPopupList(MainPopupType.PUSH_ALLOW, false);
            handleMainPopup();
          }}
          onCloseCallback={() => {
            handleMainPopup();
          }}
        />
      )}

      {showMemberJoinVoltSaveModal && (
        <VoltModal
          showMemberJoinVoltSaveModal={showMemberJoinVoltSaveModal}
          onClose={() => {
            setShowMemberJoinVoltSaveModal(false);
            changeMainPopupList(MainPopupType.MEMBER_JOIN_VOLT_SAVE, false);
            handleMainPopup();
          }}
        />
      )}

      {showWebJoinMemberCouponModal && (
        <WebJoinMemberCouponModal
          show={showWebJoinMemberCouponModal}
          imgPath={
            Platform.isApp
              ? "/MAIN/webJoinMemberCoupon_app.png"
              : AuthStore.sessionUser?.nickname
              ? "/MAIN/webJoinMemberCoupon_member.png"
              : "/MAIN/webJoinMemberCoupon_noMember.png"
          }
          onClick={() => {
            if (Platform.isApp) {
              localStorage.setItem(
                "webJoinMemberCoupon",
                `SUCCESS_${AuthStore.sessionUser?.memberUuid}`
              );
              setShowWebJoinMemberCouponModal(false);
              changeMainPopupList(MainPopupType.WEB_JOIN_MEMBER_COUPON, false);
              goTo("/coupon");
            } else {
              if (AuthStore.sessionUser?.nickname) {
                switch (Platform.OS) {
                  case PlatformTypes.WEB:
                  case PlatformTypes.WINDOWS_WEB:
                  case PlatformTypes.MACOS_WEB:
                    goToAppDetail();
                    break;
                  default:
                    window.open("https://app2.220cordncode.com/IYMzZqsaaLb");
                    break;
                }
              } else {
                setShowWebJoinMemberCouponModal(false);
                changeMainPopupList(
                  MainPopupType.WEB_JOIN_MEMBER_COUPON,
                  false
                );
                goTo("/LoginPresenter");
              }
            }
          }}
          onClose={() => {
            if (Platform.isApp) {
              localStorage.setItem(
                "webJoinMemberCoupon",
                `SUCCESS_${AuthStore.sessionUser?.memberUuid}`
              );
              setShowWebJoinMemberCouponModal(false);
              changeMainPopupList(MainPopupType.WEB_JOIN_MEMBER_COUPON, false);
              handleMainPopup();
            } else {
              setShowWebJoinMemberCouponModal(false);
              changeMainPopupList(MainPopupType.WEB_JOIN_MEMBER_COUPON, false);
              handleMainPopup();
            }
          }}
        />
      )}

      {isShowTutorial && (
        <MainTutorial
          show={isShowTutorial}
          onClose={() => {
            setIsShowTutorial(false);
            changeMainPopupList(MainPopupType.TUTORIAL, false);
            handleMainPopup();
            CommonStore.setIsShowMainTooltip1(true);
          }}
        />
      )}

      {MainStore.showBottomSheet && (
        <MainPopupBanner
          onCloseAction={() => {
            MainStore.setShowBottomSheet(false);
            changeMainPopupList(MainPopupType.BOTTOM_BANNER, false);
            handleMainPopup();
          }}
        />
      )}

      <MainTextBanner
        onCloseAction={() => {
          changeMainPopupList(MainPopupType.TEXT_NOTICE, false);
        }}
        onModalHide={() => {
          handleMainPopup();
        }}
      />
      {/*
      {showMainMallNotice && (
        <MainMallNotice
          isVisible={showMainMallNotice}
          onclose={() => {
            setShowMainMallNotice(false);
            changeMainPopupList(MainPopupType.MAIN_MALL_NOTICE, false);
          }}
          onModalHide={() => {
            handleMainPopup();
          }}
        />
      )} */}
    </MainLayout>
  );
});

export default HomeScreen;
