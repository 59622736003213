import React, { useCallback, useState } from "react";
import TouchableWithAuthCheck from "./TouchableWithAuthCheck";

interface TouchableWithAsyncTaskProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onPress?: () => Promise<any>;
  onTaskSuccess?: () => void;
  onTaskFail?: (error: any) => void;
  authCheckProps?: {
    enabled: boolean;
    authScreenType?: "MODAL" | "SCREEN";
  };
}

const TouchableWithAsyncTask: React.FC<TouchableWithAsyncTaskProps> = ({
  onPress,
  onTaskSuccess,
  onTaskFail,
  authCheckProps,
  ...props
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);

  const doAsyncTask = useCallback(() => {
    setDisabled(true);
    onPress &&
      onPress()
        .then(() => {
          onTaskSuccess && onTaskSuccess();
        })
        .catch((error) => {
          onTaskFail && onTaskFail(error);
        })
        .finally(() => {
          setDisabled(false);
        });
  }, [onPress, onTaskSuccess, onTaskFail]);

  return authCheckProps?.enabled ? (
    <TouchableWithAuthCheck
      disabled={disabled || props.disabled}
      onPress={doAsyncTask}
      authScreenType={authCheckProps.authScreenType}
      {...props}
    >
      {props.children}
    </TouchableWithAuthCheck>
  ) : (
    <button
      disabled={disabled || props.disabled}
      onClick={doAsyncTask}
      {...props}
    >
      {props.children}
    </button>
  );
};

export default TouchableWithAsyncTask;
