import { useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReportReason } from "../../api/report/model";
import { ReportTypeCode } from "../../constants/Report";
import { ReactComponent as CheckOffIcon } from "../../assets/icons/checkbox_big_off.svg";
import { ReactComponent as CheckOnIcon } from "../../assets/icons/checkbox_big_on.svg";
import { observer } from "mobx-react";
import CustomBottomSheet from "./CustomBottomSheet";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import './ReportBottomSheet.scss';
import clsx from "clsx";

export interface ReportBottomSheetProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (reasonCodes: Set<string>) => void;
}

const ReportBottomSheet = observer(
  ({ open, onClose, onConfirm }: ReportBottomSheetProps) => {

    const { t } = useTranslation();

    const [selectedReasonCodes, setSelectedReasonCodes] = useState<Set<string>>(
      new Set()
    );
    // const insets = useSafeAreaInsets();

    //TODO : 공통코드에서 불러와야함
    const reasonList: ReportReason[] = [
      {
        code: ReportTypeCode.SPAM,
        value: t(`screen.reportModal.contents.reason1`),
        id: 1,
      },
      {
        code: ReportTypeCode.DISLIKE,
        value: t("screen.reportModal.contents.reason2"),
        id: 2,
      },
      {
        code: ReportTypeCode.SEXUAL_CONTENTS,
        value: t("screen.reportModal.contents.reason3"),
        id: 3,
      },
      {
        code: ReportTypeCode.FRAUDULENT,
        value: t("screen.reportModal.contents.reason4"),
        id: 4,
      },
      {
        code: ReportTypeCode.HATE_SPEECH,
        value: t("screen.reportModal.contents.reason5"),
        id: 5,
      },
      {
        code: ReportTypeCode.VIOLENT,
        value: t("screen.reportModal.contents.reason6"),
        id: 6,
      },
      {
        code: ReportTypeCode.ILLEGAL,
        value: t("screen.reportModal.contents.reason7"),
        id: 7,
      },
      {
        code: ReportTypeCode.BULLYING,
        value: t("screen.reportModal.contents.reason8"),
        id: 8,
      },
      {
        code: ReportTypeCode.ETC,
        value: t("screen.reportModal.contents.reason9"),
        id: 9,
      },
    ];

    const clearSelectedReasonCodes = () => {
      setSelectedReasonCodes(new Set());
    };

    const toggleSelectedReasonCodes = (reasonCode: string) => {
      setSelectedReasonCodes((prev) => {
        const next = new Set<string>(prev);
        next.has(reasonCode) ? next.delete(reasonCode) : next.add(reasonCode);
        return next;
      });
    };

    const handleBottomSheetClose = () => {
      onClose && onClose();
      clearSelectedReasonCodes();
    };

    const RenderType = (value: string, id: number) => {
      const reason = reasonList.find((x) => x.id === id) ?? null;
      const selected = selectedReasonCodes.has(reason?.code as ReportTypeCode);
      const iconToggle = () => {
        if (!reason?.code) {
          return;
        }
        toggleSelectedReasonCodes(reason.code);
      };
      return (
        <div className="reason_item">
          <button
            className="reason_btn"
            disabled={selectedReasonCodes.size >= 3 && !selected}
            onClick={() => iconToggle()}
          >
            {selected ? <CheckOnIcon /> : <CheckOffIcon />}
            <span className="reason_text">{value}</span>
          </button>
        </div>
      );
    };

    return (
      <CustomBottomSheet
        snapHeight={530}
        open={open}
        onClose={handleBottomSheetClose}
      >
        <div id="reportSheet" className="reportSheet">
          <button
            className="close_btn"
            onClick={handleBottomSheetClose}
            aria-label="Close Button For Report Reason Modal"
            // hitSlop={{ top: 32, bottom: 200, left: 32, right: 32 }} -> increase touchable area
          >
            <CloseIcon />
          </button>
          <div className="title_wrap">
            <div className="title" aria-label="Title For Report Reason Modal">
              {t("screen.reportModal.title")}
            </div>
          </div>
          <div className="warning_wrap">
            <div className="warning_text">
              {t("screen.setting.label.memberInfo.warning_text")}
            </div>
          </div>
          {/* <FlatList
            showsVerticalScrollIndicator={false}
            data={reasonList.map((x) => ({ value: x.value, id: x.id }))}
            renderItem={RenderType}
            style={[
              reason_list,
              { marginBottom: Platform.OS === "ios" ? insets.bottom : 11 },
            ]}
            accessibilityLabel="Report Reasons"
          /> */}
          <Virtuoso
            data={reasonList.map((x) => ({ value: x.value, id: x.id }))}
            itemContent={(_, item) => RenderType(item.value, item.id)}
            style={{ marginBottom: 65 }}
            className="reason_list"
          />
          <button
            className={clsx("confirm_btn", selectedReasonCodes.size > 0 && "confirm_active_btn")}
            onClick={() => onConfirm(selectedReasonCodes)}
            aria-label="Confirm Withdraw Reason Button"
            disabled={selectedReasonCodes.size == 0}
          >
            <div className="confirm_btn_text">{t("screen.reportModal.report")}</div>
          </button>
        </div>
      </CustomBottomSheet>
    );
  }
);

export default ReportBottomSheet;
