import { action, computed, makeObservable, observable } from 'mobx'
import {
  AddMemberRequest,
  RankingPosterResponse,
  RankingPosterStyle,
  SocialLoginRequest,
  UserInfo,
} from '../api/member/model'
import {
  createMember,
  deleteEmailMember,
  deleteSocialMember,
  emailLogin,
  getMemberState,
  getRankingPosters,
  saveUserStylePoster,
  socialLogin,
  updateMember,
  withdraw,
} from '../api/member/api'
import { WithdrawTypeCode } from '../constants/Withdrawal.enum'
import {
  SocialTypeCode,
  StatusCode,
  SuccessOrNot,
} from '../constants/Common.enum'
// import { BranchParams } from "react-native-branch";
import { FeedTypeCode } from '../constants/Feed'
import { SessionData } from 'branch-sdk'
import CommonStore from './CommonStore'
import { t } from 'i18next'
import { getDatetimeFullYearStr } from '../utils/datetime'
import { removeTempMemberFromStorage } from '../service/Preference.service'
import { setSessionUserInStorage } from '../service/Session.service'
import { withdrawalEmailMasking } from '../utils/email'
import { naverUnlink } from '../api/naverLogin/api'
import { goTo } from '../hooks/navigate/NavigateFunction'
import { kakaoUnlink } from '../api/kakaoLogin/api'

export interface NotNormalMemberLogin {
  loginEmailAddress: string
  password?: string
  socialLoginRequest?: SocialLoginRequest
}
class MemberStore {
  // @observable _cpaInfo: SessionData | undefined
  @observable _cpaInfo: any | undefined

  constructor() {
    makeObservable(this)
  }

  @action getCpaInfo() {
    return this._cpaInfo
  }

  @action setCpaInfo(cpaInfo: SessionData) {
    this._cpaInfo = cpaInfo
  }

  @observable _userStyleRankingPosters: RankingPosterResponse[] = []
  @observable _followSet: Set<number> = new Set()
  @observable _likeSet: Set<string> = new Set()

  @computed get userStyleRankingPosters() {
    return this._userStyleRankingPosters
  }

  @action addMember = async (createMemberRequest: AddMemberRequest) => {
    try {
      await createMember(createMemberRequest)
    } catch (e) {
      alert(t('common.error.server'))
    }
  }

  @action saveMemberProfile = async (profileUrl: string) => {
    await updateMember({
      profileUrl: profileUrl,
    })
  }

  @action tryWithdrawal = async (
    withdrawReasonCode: Set<WithdrawTypeCode>,
    withdrawAgreeYn: string,
    etcDesc?: string
  ) => {
    return await withdraw({
      withdrawReasonCode,
      withdrawAgreeYn,
      etcDesc,
    })
  }

  @action notNormalMemberLogin = async ({
    loginEmailAddress,
    password,
    socialLoginRequest,
  }: NotNormalMemberLogin) => {
    const {
      memberUuid,
      emailAddress,
      memberStateCode,
      nickname,
      withdrawAgreeDatetime,
      separateDatetime,
      createdDatetime,
    } = await getMemberState(loginEmailAddress)
    switch (memberStateCode) {
      case StatusCode.FORCED_WITHDRAWAL:
        CommonStore.setNotificationModalProps({
          isVisible: true,
          title: t('screen.notificationModal.label.alert'),
          contents1: `${t('screen.loginForcedWithdrawalModal.contents1', {
            nickname,
          })}
          ${t('screen.loginForcedWithdrawalModal.contents2')}
          ${t('screen.loginForcedWithdrawalModal.contents3', {
            withdrawAgreeDatetime: getDatetimeFullYearStr(
              withdrawAgreeDatetime
            ),
          })}`,
          defaultButtonText: t('common.label.check'),
          onRequestClose: () => {
            CommonStore.resetNotificationModalProps()
          },
        })
        break
      case StatusCode.HALT:
        CommonStore.setNotificationModalProps({
          isVisible: true,
          modalWidth: 300,
          title: t('screen.notificationModal.label.alert'),
          contents1: `${t('screen.loginHaltModal.contents1', { nickname })}
          ${t('screen.loginHaltModal.contents2')}
          ${t('screen.loginHaltModal.contents3')}`,
          defaultButtonText: t('common.label.check'),
          onRequestClose: async () => {
            CommonStore.resetNotificationModalProps()
            CommonStore.setLoading(true)
            if (emailAddress && password) {
              const emailLoginResponse = await emailLogin(
                emailAddress,
                password
              )
              const userInfo = { ...emailLoginResponse.data, emailAddress }

              if (emailLoginResponse.successOrNot === SuccessOrNot.Y) {
                void (await removeTempMemberFromStorage())
                void (await setSessionUserInStorage({ ...userInfo }))
              }
            } else if (socialLoginRequest) {
              const { successOrNot, data } = await socialLogin(
                socialLoginRequest
              )

              if (successOrNot === SuccessOrNot.Y) {
                const user: UserInfo = { ...data }
                await removeTempMemberFromStorage()
                await setSessionUserInStorage(user)
              }
            }

            CommonStore.setLoading(false)
          },
        })
        break
      case StatusCode.WITHDRAWAL:
        CommonStore.setNotificationModalProps({
          isVisible: true,
          modalWidth: 300,
          title: t('screen.notificationModal.label.alert'),
          contents1: `${t('screen.loginWithdrawalModal.contents1', {
            emailAddress: withdrawalEmailMasking(emailAddress),
          })}
          ${t('screen.loginWithdrawalModal.contents2')}
          ${t('screen.loginWithdrawalModal.contents3')}`,
          useTwoButton: true,
          defaultButtonText: t('screen.notificationModal.button.yes'),
          extraButtonText: t('screen.notificationModal.button.no'),
          onRequestClose: () => {
            CommonStore.resetNotificationModalProps()
          },
          onClickDefaultButton: async () => {
            CommonStore.resetNotificationModalProps()
            goTo('/MemberRecovery', {
              state: {
                memberUuid,
                emailAddress,
                emailLoginRequest: { emailAddress, password },
                socialLoginRequest,
              },
            })
          },
          onClickExtraButton: async () => {
            CommonStore.resetNotificationModalProps()
            let successOrNot
            if (socialLoginRequest) {
              successOrNot = await deleteSocialMember(socialLoginRequest)
              if (successOrNot === SuccessOrNot.Y) {
                switch (socialLoginRequest.socialTypeCode) {
                  case SocialTypeCode.KAKAO:
                    void kakaoUnlink(socialLoginRequest.accessToken)
                    break
                  case SocialTypeCode.NAVER:
                    void naverUnlink()
                    break
                }
              }
            } else if (emailAddress && password) {
              const emailLoginRequest = { emailAddress, password }
              successOrNot = await deleteEmailMember(emailLoginRequest)
            }

            goTo('/', { replace: true })
          },
        })
        break

      case StatusCode.DORMANT:
        goTo('/DormantMember', {
          state: {
            emailLoginRequest: { emailAddress, password },
            socialLoginRequest,
            createdDatetime,
            separateDatetime,
          },
        })

        break
    }
  }

  @action setUserStyleRankingPosters = (
    rankingPosterResponse: RankingPosterResponse[]
  ) => {
    this._userStyleRankingPosters = rankingPosterResponse
  }

  @action getRankingPosters = async () => {
    const response = await getRankingPosters()
    this.setUserStyleRankingPosters(response)
  }

  @action saveRankingPosterStyle = async () => {
    const request = {
      feedTypeCode: FeedTypeCode.POST,
      followers: [...this.followSet],
    } as RankingPosterStyle
    await saveUserStylePoster(request)
  }

  @computed get followSet() {
    return this._followSet
  }

  @computed get likeSet() {
    return this._likeSet
  }

  @action addFollowSet = (memberId: number) => {
    const newFollowSet = new Set(this.followSet)
    if (this.followSet.has(memberId)) {
      newFollowSet.delete(memberId)
      this._followSet = newFollowSet
    } else {
      newFollowSet.add(memberId)
      this._followSet = newFollowSet
    }
  }

  @action addFollowMDefault = (memberIds: number[]) => {
    this._followSet = new Set(memberIds)
  }

  @action addLikeSet = (feedIdWithMemberId: string) => {
    const newLikeSet = new Set(this.likeSet)
    if (this.likeSet.has(feedIdWithMemberId)) {
      newLikeSet.delete(feedIdWithMemberId)
      this._likeSet = newLikeSet
    } else {
      newLikeSet.add(feedIdWithMemberId)
      this._likeSet = newLikeSet
    }
  }

  @action clear = () => {
    this.followSet.clear()
    this.likeSet.clear()
    this.setUserStyleRankingPosters([])
  }
}

export default new MemberStore()
