//modal component
import React from 'react'
import { observer } from 'mobx-react'
import { ReactComponent as CloseIcon } from '../../assets/icons/close_white.svg'
import { Spacings, Arranges } from '../../assets/styles'
import { Trans, useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import classes from './LifecareRealNameModal.module.scss'

const LifecareRealNameModal = observer(
  (props: {
    isVisible: boolean
    onRequestClose?: () => void
    onClick?: () => void
  }) => {
    const { t } = useTranslation()
    return (
      <Modal
        {...props}
        isOpen={props.isVisible}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            maxWidth: 450,
            margin: 'auto',
            zIndex: 100000,
          },
          content: {
            padding: 0,
            border: 'none',
            borderRadius: '12px',
            top: '50%',
            left: '50%',
            backgroundColor: 'unset',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            overflow: 'hidden',
          },
        }}
      >
        <div className={classes.modal_wrap}>
          <div
            className={classes.modal_header}
            onClick={() => {
              props.onRequestClose?.()
            }}
          >
            <CloseIcon />
          </div>
          <div className={classes.modal_content}>
            <text
              style={{
                color: '#be69ce',
                fontSize: 16,
                fontWeight: 700,
                letterSpacing: -0.48,
                marginBottom: 6,
              }}
            >
              {t('screen.mall.label.wait')}
            </text>
            <text
              style={{
                color: '#222',
                fontSize: 18,
                fontWeight: 700,
                letterSpacing: -0.72,
                marginBottom: 12,
              }}
            >
              {t('screen.mall.label.realnameTitle')}
            </text>
            <text
              style={{
                marginBottom: 32,
                color: '#222',
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: -0.56,
                textAlign: 'center',
              }}
            >
              <Trans>{t('screen.mall.label.realnameContent')}</Trans>
            </text>
            <div
              className={classes.button}
              onClick={() => {
                props.onClick?.()
              }}
            >
              <span
                style={{
                  color: 'white',
                  fontSize: 14,
                  fontWeight: 700,
                  letterSpacing: -0.56,
                  textAlign: 'center',
                }}
              >
                {t('screen.mall.label.realnameButton')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
)

export default LifecareRealNameModal
