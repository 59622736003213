import { action, computed, makeObservable, observable } from "mobx";
import { Qna } from "../api/qna/model";
import { UserActTypeCode } from "../constants/FeedType.enum";
import QnaMainStore from "./QnaMainStore";
import QnaDetailStore from "./QnaDetailStore";
import { deleteQna } from "../api/qna/api";
import QnaListStore from "./QnaListStore";

export const PAGE_SIZE = 10;
class QnaStore {
  constructor() {
    makeObservable(this);
  }
  @observable _qnaMainStore: QnaMainStore = new QnaMainStore();
  @observable _qnaDetailStoreList: QnaDetailStore[] = [];
  @observable _qnaListStore: QnaListStore = new QnaListStore();

  @computed get qnaListStore() {
    return this._qnaListStore;
  }

  @computed get qnaMainStore() {
    return this._qnaMainStore;
  }

  @computed get qnaDetailStoreList() {
    return this._qnaDetailStoreList;
  }

  @action getQnaDetailStore = (feedId: number) => {
    const index = this.qnaDetailStoreList.findIndex(
      (x) => x.qnaDetail.feedId === feedId
    );

    return this.qnaDetailStoreList[index];
  };

  @action initQnaDetailStore = async (feedId: number) => {
    const qnaDetailStore = new QnaDetailStore();

    await qnaDetailStore.getQnaDetail(feedId);

    this._qnaDetailStoreList.push(qnaDetailStore);

    return qnaDetailStore;
  };

  @action removeQnaDetailStore = (feedId: number) => {
    this._qnaDetailStoreList = this.qnaDetailStoreList.filter(
      (x) => x.qnaDetail.feedId !== feedId
    );
  };

  @action syncQna = (
    feedId: number | undefined,
    qna: Qna | undefined,
    action: UserActTypeCode
  ) => {
    switch (action) {
      case UserActTypeCode.CREATE:
        void this._qnaMainStore.getRecentlyQnaList();
        void this._qnaListStore.refresh();
        break;
      case UserActTypeCode.MODIFY:
        if (qna != undefined && feedId != undefined) {
          if (qna.files && qna.files.length > 0) {
            qna.thumbnailFilePath = qna.files[0].thumbnailFilePath;
            qna.filePath = qna.files[0].filePath;
          }

          if (qna != undefined && feedId != undefined) {
            this._qnaMainStore.updateQna(feedId, qna);

            void this._qnaListStore.updateQna(qna);
          }

          this.updateQnaDetailStore(feedId, qna);
        }
        break;

      case UserActTypeCode.REPORT:
      case UserActTypeCode.DELETE:
        if (feedId != undefined) {
          this._qnaMainStore.removeQna(feedId);
          this._qnaListStore.removeQna(feedId);

          this.removeQnaDetailStore(feedId);

          void this._qnaMainStore.getRecentlyQnaList();
        }

        break;
    }
  };

  @action syncAb = (
    feedId: number | undefined,
    ab: any,
    action: UserActTypeCode
  ) => {
    switch (action) {
      case UserActTypeCode.CREATE:
      case UserActTypeCode.DELETE:
      case UserActTypeCode.REPORT:
        void this._qnaMainStore.getpopularAbList();
        break;

      case UserActTypeCode.MODIFY:
        if (feedId !== undefined && ab !== undefined) {
          this._qnaMainStore.updateAb(feedId, ab);
        }
        break;
    }
  };

  @action updateQnaDetailStore = (feedId: number, qna: Qna) => {
    const index = this._qnaDetailStoreList.findIndex(
      (x) => x.qnaDetail.feedId === feedId
    );

    if (index !== -1) {
      this._qnaDetailStoreList[index].setQnaDetail(qna);
    }
  };

  @action deleteQna = async (feedId: number) => {
    return await deleteQna([feedId]);
  };

  @action clear = () => {
    this._qnaMainStore.clear();
    this._qnaDetailStoreList = [];
  };
}
export default new QnaStore();
