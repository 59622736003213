import React, { useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import { emailLogin } from "../../api/member/api";
import { setSessionUserInStorage } from "../../service/Session.service";
import PwShow from "../../assets/icons/pw_show.svg";
import PwHideBlack from "../../assets/icons/pw_hide_black.svg";
import BtnSnsLoginKakao from "../../assets/icons/btn_sns_login_kakao.svg";
import BtnSnsLoginNaver from "../../assets/icons/btn_sns_login_Naver.svg";
import BtnSnsLoginGoogle from "../../assets/icons/btn_sns_login_google.svg";
import BtnSnsLoginApple from "../../assets/icons/new_btn_sns_login_apple.svg";
import CommonStore from "../../store/CommonStore";
import { removeTempMemberFromStorage } from "../../service/Preference.service";
import {
  MemberStateCode,
  SocialTypeCode,
  StatusCode,
  SuccessOrNot,
} from "../../constants/Common.enum";
import SocialMemberStore from "../../store/SocialMemberStore";
import { LAST_LOGIN_TYPE } from "../../constants/Storage";
import NotificationModal from "../../components/modals/NotificationModal";
import { isNotNormalMember } from "../../utils/login";
import MemberStore from "../../store/MemberStore";
import { UserInfo } from "../../api/member/model";
import classes from "./LoginPresenter.module.scss";
import clsx from "clsx";
import { Platform, PlatformTypes } from "../../native/platform";
import { SignUpCode } from "../../constants/SignUp.enum";
import { TitleHeader } from "../../components/common";
import { goRedirect, goTo } from "../../hooks/navigate/NavigateFunction";

const LoginPresenter = () => {
  const { t } = useTranslation();
  // const isPlaformIOS = Platform.OS === "ios";
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { setLoading } = CommonStore;
  const [loginFailMessage, setLoginFailMessage] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showAutoCompleteList, setShowAutoCompleteList] =
    useState<boolean>(false);
  const [secureTextEntry, setSecureTextEntry] = useState<boolean>(true);
  const [showPasswordWrongModal, setShowPasswordWrongModal] =
    useState<boolean>(false);
  const refInputPassword = useRef<any>(null);
  const {
    signInWithApple,
    signInWithGoogle,
    signInWithNaver,
    signInWithKakao,
  } = SocialMemberStore;
  const { notNormalMemberLogin } = MemberStore;
  const EMAIL_DOMAIN_LIST = [
    "@naver.com",
    "@gmail.com",
    "@hanmail.net",
    "@nate.com",
    "@kakao.com",
  ];

  const getLoginFailMessage = (statusCode: StatusCode) => {
    let failMessage;

    switch (statusCode) {
      case StatusCode.INVALID_EMAIL_FORMAT:
      case StatusCode.INCORRECT_USERNAME_OR_PASSWORD:
        failMessage = t("screen.login.message.invalidLoginFormat");
        break;
      case StatusCode.WITHDRAWAL:
      case StatusCode.HALT:
      case StatusCode.DORMANT:
        failMessage = t("screen.login.message.withDrawl");
        break;
      case StatusCode.UNKNOWN_ERROR:
        failMessage = t("screen.login.message.unknownError");
        break;
      default:
        failMessage = t("screen.login.message.unknownError");
        break;
    }

    return failMessage;
  };

  const onEmailAddressChange = (emailAddress: string) => {
    const trimedEmail = emailAddress.trim();

    setEmailAddress(trimedEmail);
    if (showAutoCompleteList && trimedEmail.includes("@")) {
      setShowAutoCompleteList(false);
    } else if (!showAutoCompleteList && !trimedEmail.includes("@")) {
      setShowAutoCompleteList(true);
    }
  };
  const onPasswordChange = (password: string) => {
    setPassword(password);
  };
  const toggleSecureTextEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };

  const handleEmailInputFocus = () => setIsFocused(true);
  const handleEmailInputBlur = () => setIsFocused(false);

  const onClickAutoCompleteItem = (suffix: string) => {
    setEmailAddress((email) => email + suffix);
    setShowAutoCompleteList(false);
    refInputPassword.current.focus();
  };

  const onClickLoginButton = async () => {
    // Keyboard.dismiss();
    setLoading(true);
    const emailLoginResponse = await emailLogin(emailAddress, password);
    const userInfo = { ...emailLoginResponse.data, emailAddress: emailAddress };

    if (
      emailLoginResponse.data &&
      emailLoginResponse.successOrNot === SuccessOrNot.Y &&
      emailLoginResponse.statusCode === StatusCode.LONG_TERM_UNUSED
    ) {
      const user: UserInfo = { ...emailLoginResponse.data };
      user.isLongTermUnused = true;

      await removeTempMemberFromStorage();
      await setSessionUserInStorage(user);
      await localStorage.setItem(LAST_LOGIN_TYPE, SocialTypeCode.EMAIL);

      goTo("/LongTermUnusedScreen", {
        state: {
          socialTypeCode: SocialTypeCode.EMAIL,
          email: emailAddress,
        },
      });

      return;
    }

    if (
      emailLoginResponse.successOrNot === SuccessOrNot.Y &&
      emailLoginResponse.data?.memberStateCode === MemberStateCode.NORMAL
    ) {
      void (await removeTempMemberFromStorage());
      void (await setSessionUserInStorage({ ...userInfo }));
      await localStorage.setItem(LAST_LOGIN_TYPE, SocialTypeCode.EMAIL);

      if (userInfo) {
        if (!userInfo.nickname) goTo("/PreferenceScreen", { replace: true });
        if (
          Platform.isApp &&
          userInfo.homeAppliance === null &&
          userInfo.houseMate === null &&
          userInfo.roomScale === null
        )
          goTo("/PreferenceScreen", { replace: true });
        else if (!userInfo.profileUrl)
          goTo("/ProfileSetting", {
            replace: true,
            state: { hideBack: true },
          });
        else if (userInfo.nickname && userInfo.profileUrl) {
          CommonStore.restMainAfterLogin(SocialTypeCode.EMAIL);
          goRedirect();
        }
      }
    } else if (
      isNotNormalMember(emailLoginResponse.data?.memberStateCode) ||
      isNotNormalMember(emailLoginResponse.statusCode as StatusCode)
    ) {
      await notNormalMemberLogin({ loginEmailAddress: emailAddress, password });
    } else {
      const { statusCode } = emailLoginResponse;

      if (statusCode === StatusCode.LOCK) {
        setShowPasswordWrongModal(true);
      } else {
        setLoginFailMessage(getLoginFailMessage(statusCode as StatusCode));
      }
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const clickSignUpBtn = (sort: string) => {
    localStorage.setItem("SIGNUPCODE", sort);
    switch (sort) {
      case SignUpCode.NAVER:
        void signInWithNaver();
        break;
      case SignUpCode.GOOGLE:
        void signInWithGoogle();
        break;
      case SignUpCode.KAKAO:
        void signInWithKakao();
        break;
    }
  };

  return (
    <>
      <div className={classes.login} aria-label={"Login presenter"}>
        <div className={classes.header}>
          <TitleHeader
            title={t("screen.login.label.headerTitle")}
            isBack={true}
          />
        </div>
        <div className={classes.contents_box}>
          <div className={classes.title} aria-label="Title">
            <div className={classes.title_text}>
              {t("screen.login.label.titleFirst")}
            </div>
            <div className={classes.title_text}>
              {t("screen.login.label.titleSecond")}
            </div>
          </div>
          <p className={classes.input_title}>이메일 아이디</p>
          <div className={clsx(classes.input_wrap, classes.email_input_wrap)}>
            <input
              className={classes.input}
              aria-label="Email address"
              value={emailAddress}
              placeholder={t("screen.login.label.emailInput")}
              // placeholderTextColor={"#999"}
              onChange={(event) => onEmailAddressChange(event.target.value)}
              onFocus={handleEmailInputFocus}
              // onBlur={handleEmailInputBlur}
              autoCapitalize={"none"}
              autoComplete={"off"}
              // autoCorrect={false}
              type="text"
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  refInputPassword.current.focus();
                }
              }}
            />
            {isFocused && showAutoCompleteList && emailAddress.length !== 0 && (
              <div className={classes.auto_complete_list}>
                {EMAIL_DOMAIN_LIST.map((item, index) => {
                  if (index < EMAIL_DOMAIN_LIST.length - 1) {
                    return (
                      <div
                        key={item}
                        className={classes.auto_complete_item}
                        onClick={(e) => {
                          onClickAutoCompleteItem(item);
                        }}
                      >
                        <div className={classes.auto_complete_text}>
                          {emailAddress}
                          {item}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={item}
                        className={clsx(
                          classes.auto_complete_item,
                          classes.auto_complete_last_item
                        )}
                        onClick={(e) => {
                          onClickAutoCompleteItem(item);
                        }}
                      >
                        <div className={classes.auto_complete_text}>
                          {emailAddress}
                          {item}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>

          <p className={classes.input_title}>비밀번호</p>
          <div className={classes.input_box}>
            <div className={clsx(classes.input_wrap, classes.pw_input_wrap)}>
              <input
                className={classes.input}
                value={password}
                aria-label="Password"
                placeholder={t("screen.login.label.passwordInput")}
                // placeholderTextColor={"#999"}
                onChange={(event) => onPasswordChange(event.target.value)}
                type={secureTextEntry ? "password" : "text"}
                ref={refInputPassword}
                onKeyDown={(event) => {
                  if (
                    event.key === "Enter" &&
                    emailAddress.length !== 0 &&
                    password.length !== 0
                  ) {
                    onClickLoginButton();
                  }
                }}
              />
              {secureTextEntry ? (
                <img
                  src={PwHideBlack}
                  alt="PwHideBlack"
                  onClick={toggleSecureTextEntry}
                />
              ) : (
                <img
                  src={PwShow}
                  alt="PwShow"
                  onClick={toggleSecureTextEntry}
                />
              )}
            </div>
            {loginFailMessage.length > 0 && (
              <div className={classes.alert_wrap}>
                <div className={classes.alert}>
                  <div className={classes.not_authorized}>
                    {loginFailMessage}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={classes.btn_wrap}>
            <button
              aria-label={"Login"}
              className={clsx(
                classes.btn_1,
                emailAddress.length === 0 || password.length === 0
                  ? classes.in_active
                  : classes.active
              )}
              disabled={emailAddress.length === 0 || password.length === 0}
              onClick={() => {
                void onClickLoginButton();
              }}
            >
              <p
                className={clsx(
                  classes.btn_1_txt,
                  emailAddress.length === 0 || password.length === 0
                    ? classes.txt_in_active
                    : classes.txt_active
                )}
              >
                {t("screen.login.button.login")}
              </p>
            </button>
          </div>

          <div className={classes.sns}>
            <div
              className={classes.sns_img}
              aria-label={"Login with kakao"}
              onClick={() => {
                clickSignUpBtn(SignUpCode.KAKAO);
              }}
            >
              <img src={BtnSnsLoginKakao} alt="BtnSnsLoginKakao" />
            </div>
            <div
              className={classes.sns_img}
              aria-label={"Login with naver"}
              onClick={() => clickSignUpBtn(SignUpCode.NAVER)}
            >
              <img src={BtnSnsLoginNaver} alt="BtnSnsLoginNaver" />
            </div>
            <div
              className={classes.sns_img}
              aria-label={"Login with google"}
              onClick={() => clickSignUpBtn(SignUpCode.GOOGLE)}
            >
              <img src={BtnSnsLoginGoogle} alt="BtnSnsLoginGoogle" />
            </div>
            {Platform.OS === PlatformTypes.IOS_APP && (
              <div
                className={classes.sns_img}
                aria-label={"Login with apple"}
                onClick={() => void signInWithApple()}
              >
                <img src={BtnSnsLoginApple} alt="BtnSnsLoginApple" />
              </div>
            )}
          </div>

          <div className={classes.etc_area}>
            <div
              aria-label={"Find email"}
              onClick={() => {
                goTo("/FindEmail");
              }}
            >
              <p className={classes.etc_text}>
                {t("screen.login.label.findEmail")}
              </p>
            </div>
            <div className={classes.etc_area_line} />
            <div
              aria-label={"Reset password"}
              onClick={() => {
                goTo("/PasswordReset");
              }}
            >
              <p className={classes.etc_text}>
                {t("screen.login.label.resetPassword")}
              </p>
            </div>
            <div className={classes.etc_area_line} />
            <div
              aria-label={"Sign up"}
              onClick={() => {
                goTo("/JoinWithEmailScreen");
              }}
            >
              <p className={classes.etc_text}>
                {t("screen.login.label.signUpEmail")}
              </p>
            </div>
          </div>
        </div>
      </div>

      {showPasswordWrongModal && (
        <NotificationModal
          isVisible={showPasswordWrongModal}
          contents1={t("common.message.lock")}
          onRequestClose={() => {
            setShowPasswordWrongModal(false);
          }}
          onClickDefaultButton={() => {
            setShowPasswordWrongModal(false);
            goTo("/PasswordReset");
          }}
          defaultButtonText={t("common.label.check")}
          textAlign="center"
        />
      )}
    </>
  );
};

export default LoginPresenter;
