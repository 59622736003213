export enum WithdrawTypeCode {
  NOT_USEFUL = "NOT_USEFUL",
  TO_USE_ANOTHER_ACCOUNT = "TO_USE_ANOTHER_ACCOUNT",
  TOO_MANY_ERRORS = "TOO_MANY_ERRORS",
  TO_USE_THE_OTHER_SERVICE = "TO_USE_THE_OTHER_SERVICE",
  NOT_ENOUGH_INFO = "NOT_ENOUGH_INFO",
  RARELY_USED = "RARELY_USED",
  INCORRECT_INFO = "INCORRECT_INFO",
  FORCED_WITHDRAWAL = "FORCED_WITHDRAWAL",
  ETC = "ETC",
}

export enum SnapPoint {
  BOTTOM = 0,
  REASON_SHEET_HEIGHT = 494,
  AGREE_SHEET_HEIGHT = 451,
}
