// import { navigate } from "../navigation/NavigationFunction";
import { goTo } from '../hooks/navigate/NavigateFunction'
import { track } from '../hooks/tracker/TrackFunction'

export const LIFECARE_MAIN_URL_PREFIX = '/'
export const LIFECARE_PRODUCT_URL_PREFIX = '/products/detail/'
export const LIFECARE_CART_URL_PREFIX = '/cart'
export const LIFECARE_SESSION_EXPIRED_URL_PREFIX = '/auth/login'
export const LIFECARE_AUTH_GATE_URL_PREFIX = '/auth/ttzgate'
export const LIFECARE_INQUERY_URL_PREFIX = '/customer/oneToOne'
export const LIFECARE_ORDER_LIST_URL_PREFIX =
  '/mypage/orderManage/list?tabActive=total&sd=&ed='
export const LIFECARE_ORDER_COMPLETE_URL_PREFIX = '/order/complete/'
export const LIFECARE_ORDER_COMPLETE_DETAIL_URL_REGEXP =
  /\/mypage\/orderManage\/detail\/[0-9]+\?beforPath=complete&orderNo=[0-9]+/g
export const LIFECARE_ORDER_DETAIL_URL_PREFIX = '/mypage/orderManage/detail/'
export const LIFECARE_ORDER_PURCHASE_URL_PREFIX = '/order#beforePage=complete'
export const LIFECARE_MYPAGE_URL_PREFIX = '/mypage'
export const LIFECARE_SEARCH_URL_PREFIX = '/search'
export const LIFECARE_SEARCH_RESULT_URL_PREFIX =
  '/search/searchResult?searchGa=true&keyword='
export const LIFECARE_SHOW_REAL_NAME_URL_PREFIX = '/auth/cert/ttz/nice'
export const LIFECARE_CERT_URL_PREFIX = '/callback/certWebView'
export const NICE_BLANK_PAGE =
  'https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb'

export const getFormatprice = (item: number) => {
  return item?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || item
}

export function goToMallmain() {
  goTo('/mall')
}

export const goToLifecare = (lifecareProductUrl: string) => {
  goTo('/lifecare', {
    state: {
      mode: 'redirect',
      redirectInfo: {
        url: lifecareProductUrl,
      },
    },
  })
}

// 상품상세
export const goToLifecareProductPage = (lifecareProductId: number) => {
  const lifecareProductUrl = `${LIFECARE_PRODUCT_URL_PREFIX}${lifecareProductId}`
  goToLifecare(lifecareProductUrl)
}

// 메인
export const goToLifecareMain = () => {
  const lifecareProductUrl = LIFECARE_MAIN_URL_PREFIX

  goToLifecare(lifecareProductUrl)
}

// 장바구니
export const goToLifecareCartPage = () => {
  const lifecareProductUrl = LIFECARE_CART_URL_PREFIX

  goToLifecare(lifecareProductUrl)
}

// 마이페이지
export const goToLifecareMyPage = () => {
  const lifecareProductUrl = LIFECARE_MYPAGE_URL_PREFIX
  track('click_mall_orderdetails', {})
  goToLifecare(lifecareProductUrl)
}

// 전체주문내역
export const goToLifecareOrderListPage = () => {
  const lifecareProductUrl = LIFECARE_ORDER_LIST_URL_PREFIX

  goToLifecare(lifecareProductUrl)
}

// 검색 (키워드)
export const goToLifecareSearchResultPage = (keyword: string) => {
  const lifecareProductUrl = `${LIFECARE_SEARCH_RESULT_URL_PREFIX}${keyword}`

  goToLifecare(lifecareProductUrl)
}

// 검색
export const goToLifecareSearchPage = () => {
  goToLifecare(LIFECARE_SEARCH_URL_PREFIX)
}

// 1:1 문의하기
export const goToLifecareInquiryPage = () => {
  const lifecareProductUrl = LIFECARE_INQUERY_URL_PREFIX

  goToLifecare(lifecareProductUrl)
}
