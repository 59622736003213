import React, { CSSProperties, useCallback, useState } from "react";
import { observer } from "mobx-react";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";
import { useTranslation } from "react-i18next";
import CustomParsedText from "../../../components/common/CustomParsedText";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { FileType } from "../../../constants/File";
import VideoPlayerWithFallback from "../../../components/common/VideoPlayerWithFallback";
import { Product } from "../../../api/product/model";
import CommonStore from "../../../store/CommonStore";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { Styles } from "../../../assets/types/Style";
import { File, toHeight, toRatioSizeFile } from "../../../api/file/model";
import { ReactComponent as MallTag } from "../../../assets/icons/mall_sale_tag.svg";
import Rating from "../../rating/Rating";
import Slider, { Settings } from "react-slick";
import "./PostContent.scss";
import clsx from "clsx";
import { ReactComponent as UpTag } from "../../../assets/icons/up_product_label.svg";
import { getStringRemoteConfig } from "../../../utils/remoteConfig";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

interface PostContentProps {
  selfWidth: number;
  autoplay: boolean;
  item: any;
  index: number;
  products: any[];
  advantage: string;
  disadvantage: string;
  rating: number;
  visible: boolean;
}

const PostContent = observer(
  ({
    visible,
    selfWidth,
    item,
    index,
    products,
    advantage,
    disadvantage,
    rating,
  }: PostContentProps) => {
    const { widthApp } = CommonStore;
    const [carouselIdx, setCarouselIdx] = useState(0);
    const [isClickHomeItem, setIsClickHomeItem] = useState(true);
    const { t } = useTranslation();
    const show_up_product_tag = getStringRemoteConfig("show_up_product_tag");

    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
    const settingHomeAppList: Settings = {
      infinite: false,
      arrows: false,
      dots: false,
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      focusOnSelect: true,
      beforeChange: () => {
        setIsClickHomeItem(false);
      },
      afterChange: () => {
        setTimeout(() => {
          setIsClickHomeItem(true);
        }, 100);
      },
    };

    const renderProduct = (productList: Product[]) => {
      const widthPercent = 100;
      const carouselOffsetPercent = 4.1;
      const gap = 0;
      const selfWidth = `${widthPercent}% - ${carouselOffsetPercent * 2}% - ${
        gap * 2
      }px`;
      const itemWidth = selfWidth + "+20px";
      const viewPL =
        productList && productList.length > 0
          ? productList?.filter((product) => !product.openApiFlag)
          : [];

      return (
        <div
          // style={styles.home_app_list}
          className="home_app_list"
          aria-label="Item list"
        >
          <Slider {...settingHomeAppList}>
            {viewPL.map((product, i) => {
              return (
                !product.openApiFlag && (
                  <div
                    key={product.productId.toString()}
                    className="home_app_item_wrapper"
                    style={{
                      width:
                        viewPL?.length === 1 || i === viewPL?.length - 1
                          ? widthApp - 10
                          : widthApp * 0.8,
                    }}
                  >
                    <div
                      key={product.productId.toString()}
                      style={{
                        marginLeft: 16,
                        marginRight: i === viewPL.length - 1 ? 6 : 0,
                      }}
                    >
                      <button
                        key={product.productId.toString()}
                        className="home_app_item"
                        onClick={() => {
                          CommonStore.callActHistory(
                            ActHistoryTypeCode.PRODUCT_READ,
                            product.productId.toString(),
                            ActPageCode.POST_DETAIL,
                            undefined,
                            i.toString()
                          );
                          goTo(`/Product/${product.productId}`);
                        }}
                      >
                        <FastImageWithFallback
                          style={{ display: "flex" }}
                          className={clsx(
                            "home_app_img",
                            i === 0 && "home_app_rep_img"
                          )}
                          source={{
                            uri: `${PUBLIC_BUCKET_URL}${
                              product.productFiles[0]?.thumbnailFilePath?.replace(
                                "ORIGINAL",
                                "THUMBNAIL"
                              ) || ""
                            }`,
                          }}
                        />
                        <div className="home_app_text_wrap">
                          <div className="home_app_text1">
                            {`[${product.brandNameKor}] ${product.productName}`}
                          </div>
                          <div className="home_app_text2">
                            {product.productModelName}
                          </div>
                          <div style={{ display: "flex" }}>
                            {show_up_product_tag === "true" &&
                              product.upYn &&
                              product.upYn == "Y" && (
                                <div
                                  className="on_sale_tag_wrap"
                                  style={{ marginRight: 4 }}
                                >
                                  <UpTag />
                                </div>
                              )}
                            {product.lifecareProductId &&
                              product.lifecareProductId !== null && (
                                <div className="on_sale_tag_wrap">
                                  <div className="mall_tag">
                                    <MallTag />
                                  </div>
                                  <div className="on_sale_tag_text">
                                    {t("screen.main.label.onSale")}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                )
              );
            })}
          </Slider>
        </div>
      );
    };

    const renderRating = (rating: number) => {
      return (
        <div className="rating_wrap" aria-label="rating">
          <div className="pros_and_cons_title">
            <div className="pros_and_cons_title_text">
              {t(`screen.post.label.rating`)}
            </div>
          </div>
          <div className="rating_star">
            <Rating
              style={styles.rating_rating}
              count={5}
              value={rating}
              edit={false}
              className="rating"
            />
          </div>
          <div className="rating_text">{rating}</div>
        </div>
      );
    };

    const renderAdvantage = (advantage: string, disadvantage: string) => {
      return (
        <div aria-label="advantage and disadvantage">
          <div className={"pros"}>
            <div className={"pros_and_cons_title"}>
              <div className={"pros_and_cons_title_text"}>
                {t(`screen.post.label.pros`)}
              </div>
            </div>
            <div className={"pros_and_cons_text"}>{advantage}</div>
          </div>
          <div className={"pros_and_cons"}>
            <div className={"pros_and_cons_title"}>
              <div className={"pros_and_cons_title_text"}>
                {t(`screen.post.label.cons`)}
              </div>
            </div>
            <div className={"pros_and_cons_text"}>{disadvantage}</div>
          </div>
        </div>
      );
    };

    const renderFitstOptionBlock = (
      rating: number,
      advantage: string,
      disadvantage: string
    ) => {
      return (
        <>
          {products.length > 0 && renderProduct(products as Product[])}

          {rating && advantage && disadvantage ? (
            <div className="pros_and_cons_wrap">
              <div className="pros_and_cons_inner_wrap">
                {rating && renderRating(rating)}
                {advantage &&
                  disadvantage &&
                  renderAdvantage(advantage, disadvantage)}
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      );
    };

    const toHeightContent = useCallback(
      (items: File[]) => {
        const itemtype = items;
        let h = selfWidth;
        if (itemtype.length > 0) {
          const f = itemtype[0];
          h = toHeight(f.fileResolution ?? "", h);
        }
        return h;
      },
      [selfWidth]
    );

    const getRatioStyle = useCallback(
      (items: File[]): CSSProperties => {
        const DEFAULT_RATIO_STRING = "100%";
        let result = DEFAULT_RATIO_STRING;
        if (items.length > 0) {
          const firstFile = items.find((file) => file.fileResolution);
          const ratio = toRatioSizeFile(firstFile?.fileResolution || "");
          if (ratio) {
            result = ratio.ratioPercentString;
          }
        }
        return { paddingBottom: result, position: "relative", height: "unset" };
      },
      [selfWidth]
    );

    const [audioMute, setAudioMute] = useState(true);
    const renderImageItem = (
      contentIdx: number,
      mediaIdx: number,
      item: {
        fileType: string;
        uri: string;
        thumbnailFilePath: string;
        height: number;
        ratioStyle: CSSProperties;
      }
    ) => {
      if (item?.fileType == FileType.VIDEO) {
        return (
          <VideoPlayerWithFallback
            wrapperVideoStyle={{ ...item.ratioStyle }}
            sourceUri={encodeURI(item.uri)}
            defAudioMute={audioMute}
            onMute={(mute: boolean) => {
              setAudioMute(mute);
            }}
            disabled={!(visible && mediaIdx == carouselIdx)}
            carouselIdx={index}
            thumbnailurl={encodeURI(item.thumbnailFilePath)}
            keystring={index.toString()}
            key={`post-content-media-${index}`}
            aria-label={`post detail video content${contentIdx}-${mediaIdx}`}
          />
        );
      } else {
        return (
          <div className="image-wrapper">
            <FastImageWithFallback
              wrapperStyle={{ ...item.ratioStyle }}
              className="image-item"
              source={{ uri: item.uri }}
              key={`post-content-media-${index}`}
            />
          </div>
        );
      }
    };

    const renderMediaSection = (files: File[], i: number) => {
      const mediaList: any[] = [];
      const height = toHeightContent(files);
      const ratioStyle = getRatioStyle(files);
      files?.map((file: any, i: number) => {
        const filePath: string = file?.filePath;
        const thumbnailFilePath: string = file?.thumbnailFilePath;
        mediaList.push({
          uri: `${PUBLIC_BUCKET_URL}${filePath || ""}`,
          thumbnailFilePath: `${PUBLIC_BUCKET_URL}${thumbnailFilePath || ""}`,
          fileType: file.fileType,
          height: height,
          ratioStyle: ratioStyle,
        });
      });

      const handleSnapToItem = (idx: number) => {
        setCarouselIdx(idx);
      };

      const sliderSettings: Settings = {
        dots: mediaList && mediaList.length > 1,
        infinite: false,
        arrows: false,
        className: "media-carousel",
        dotsClass: "slick-dots media-dots-pagination",
        afterChange: (currentIdx: number) => handleSnapToItem(currentIdx),
        customPaging: (i: number) => {
          return (
            <div
              className={
                carouselIdx === i
                  ? "carousel_pagination_active"
                  : "carousel_pagination_inactive"
              }
            />
          );
        },
      };

      return (
        <div className="media-section">
          {selfWidth > 0 && (
            <Slider {...sliderSettings}>
              {mediaList &&
                mediaList.map((item, index) => renderImageItem(i, index, item))}
            </Slider>
          )}
        </div>
      );
    };

    return (
      <div className="post-content-wrapper" aria-label="post content">
        <div className="post-content-container" key={index}>
          {renderMediaSection(item.files as File[], index)}

          {index == 0 &&
            renderFitstOptionBlock(rating, advantage, disadvantage)}

          <div className="contents_wrap">
            <CustomParsedText style={styles.contents_text}>
              {item.contents}
            </CustomParsedText>
          </div>
        </div>
      </div>
    );
  }
);

export default PostContent;

const styles: Styles = {
  mage_list_wrap: {
    ...Spacings.margin_top_12,
  },
  carousel_idx: {
    ...Positions.absolute,
    ...Arranges.center_v_h,
    ...Colors.background_255,
    ...Spacings.border_radius_28,
    opacity: 0.8,
    width: 44,
    height: 38,
    zIndex: 10,
    top: 14,
    right: 14,
  },
  carousel_idx_text: {
    ...Texts.contents_text_9,
    ...Texts.font_weight_500,
  },
  carousel_pagination_container: {
    ...Positions.absolute,
    ...Positions.bottom,
    ...Positions.left,
    ...Positions.right,
    ...Spacings.padding_bottom_16,
  },
  carousel_pagination_active: {
    ...Spacings.border_radius_5,
    width: 8,
    height: 8,
    backgroundColor: "rgba(255, 255, 255, 0.92)",
  },
  carousel_pagination_inactive: {
    ...Spacings.border_radius_5,
    width: 8,
    height: 8,
    backgroundColor: "rgba(0, 0, 0, 0.92)",
    opacity: 0.4,
  },
  image_item: {
    ...Arranges.end_h,
    ...Spacings.padding_right_left_14,
    ...Spacings.padding_top_14,
  },
  video_item: {
    ...Spacings.padding_right_left_14,
    ...Spacings.padding_top_14,
  },
  magazine_image_item: {
    ...Arranges.end_h,
    ...Spacings.padding_bottom_30,
  },
  magazine_btn: {
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Colors.background_642e6f,
    ...Spacings.padding_top_bottom_12,
    ...Spacings.border_top_left_radius_25,
    ...Spacings.border_bottom_left_radius_25,
    width: 144,
    opacity: 0.8,
  },
  magazine_btn_text: {
    ...Spacings.margin_left_6,
    ...Texts.contents_text_25,
    ...Texts.font_weight_500,
  },
  home_app_list: {
    ...Spacings.margin_top_14,
    display: "flex",
    overflowX: "scroll",
    scrollbarWidth: "none",
  },
  home_app_item: {
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    display: "flex",
    whiteSpace: "nowrap",
  },
  home_app_first_item: {
    ...Spacings.padding_left_16_responsive,
  },
  home_app_img: {
    ...Spacings.margin_right_12,
    ...Spacings.border_radius_4,
    borderColor: "rgba(34, 34, 34, 0.1)",
    borderWidth: 1,
    width: 60,
    height: 60,
  },
  home_app_rep_img: {
    ...Colors.border_color_be69c3,
  },
  home_app_info: {
    width: 280,
  },
  home_app_text1: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    ...Spacings.margin_bottom_3,
  },
  home_app_text2: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
  },
  pros_and_cons_wrap: {
    ...Spacings.padding_right_left_16_responsive,
    ...Spacings.margin_top_14,
  },
  pros_and_cons_inner_wrap: {
    ...Colors.background_f8f8f8,
    ...Spacings.border_radius_6,
    ...Spacings.padding_18,
  },
  rating_wrap: {
    display: "flex",
    ...FlexBoxs.horizon,
    ...Spacings.margin_bottom_10,
  },
  rating_star: {
    ...Arranges.center_v_h,
    ...Spacings.margin_right_6,
  },
  rating_rating: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  star: {},
  rating_text: {
    ...Texts.contents_text_36,
    ...Colors.font_a760b1,
  },
  pros_and_cons: {
    display: "flex",
    ...FlexBoxs.horizon,
  },
  pros: {
    display: "flex",
    ...Spacings.margin_bottom_11,
  },
  pros_and_cons_title: {
    ...Colors.background_fff,
    ...Spacings.border_radius_70,
    ...Spacings.padding_right_left_12,
    ...Spacings.margin_right_8,
    ...Arranges.center_v_h,
    height: 25,
  },
  pros_and_cons_title_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },
  pros_and_cons_text: {
    ...FlexBoxs.flex_1,
    ...Texts.contents_text_31,
    ...Colors.font_222,
  },
  contents_wrap: {
    ...Spacings.margin_top_20,
    ...Spacings.margin_top_bottom_20,
    ...Spacings.padding_right_left_20_responsive,
  },
  contents_text: {
    ...Texts.contents_text_28,
    lineHeight: 26,
  },
  single_img: {},
  video_player: {},
  link_text: {
    ...Colors.font_3366bb,
  },
  on_sale_tag_wrap: {
    width: "auto",
    backgroundColor: "#F0DFF2",
    borderRadius: 4,
    alignSelf: "flex-start",
    height: 17,
    marginTop: 4,
    marginRight: 4,
    marginBottom: 2,
    flexDirection: "row",
    ...Arranges.center_v_h,
  },
  on_sale_tag_text: {
    color: "#BE69C2",
    fontSize: 10,
    fontWeight: "700",
    letterSpacing: -0.4,
    lineHeight: 14,
    paddingLeft: 1,
    paddingRight: 6,
    paddingVertical: 1,
    textAlign: "right",
  },
  mall_tag: {
    paddingLeft: 6,
    paddingVertical: 3,
  },
};
