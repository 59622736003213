import { GetKakaoServiceTermsResponse } from "./model";
import axios from "axios";
import { t } from "i18next";
import CommonStore from "../../store/CommonStore";

const REDIRECT_PATH = "/auth";

export const kakaoAuthorize = () => {
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_REST_API_KEY}&redirect_uri=${window.location.origin}${REDIRECT_PATH}&response_type=code`;
  window.location.href = kakaoURL;
};

export const getKakaoToken = async (code: string) => {
  try {
    const res = await axios({
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      url: "https://kauth.kakao.com/oauth/token",
      data: {
        grant_type: "authorization_code",
        client_id: process.env.REACT_APP_KAKAO_REST_API_KEY,
        redirect_uri: `${window.location.origin}${REDIRECT_PATH}`,
        code: code,
      },
    });
    return res.data.access_token;
  } catch (err) {
    return undefined;
  }
};

export const getKakaoUser = async (accessToken: string) => {
  try {
    const res = await axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
      url: "https://kapi.kakao.com/v2/user/me",
    });

    return res.data.kakao_account;
  } catch (err) {
    CommonStore.setToastOption({
      show: true,
      message1: t("screen.social.kakaoError1"),
      message2: t("screen.social.kakaoError2"),
    });
  }
};

export const getKakaoServiceTerms = async (
  accessToken: string
): Promise<GetKakaoServiceTermsResponse> => {
  let res;
  try {
    res = await axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      url: "https://kapi.kakao.com/v2/user/service_terms",
    });
  } catch (err) {
    return { id: 0, service_terms: [] };
  }
  return res?.data;
};

export const kakaoUnlink = async (accessToken: string) => {
  let res;
  try {
    res = await axios({
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      url: "https://kapi.kakao.com/v1/user/unlink",
    });
  } catch (err) {
    return err;
  }
  return res?.data;
};
