import { action, computed, makeObservable, observable } from "mobx";
// import { buildUnavailableHoursBlocks } from "react-native-calendars/src/timeline/Packer";
import { Post } from "../api/feed/model";
import { Qna } from "../api/qna/model";
import { AccountFeedTypeCode } from "../constants/Account";
import { UserActTypeCode } from "../constants/FeedType.enum";
import AccountStore from "./AccountStore";

class MyHomeStore {
  constructor() {
    makeObservable(this);
  }

  @observable _accountStoreList: AccountStore[] = [];

  @observable errorMessage?: string;
  @observable showReportPopup = false;
  @observable _previousScreen = "";
  @observable _selectedFeedId = -1;
  @observable _updatedPost?: Post;

  @computed get accountStoreList() {
    return this._accountStoreList;
  }

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    );
  }

  @computed get isShowReport() {
    return this.showReportPopup;
  }

  @computed get updatedPost() {
    return this._updatedPost;
  }

  @computed get selectedFeedId() {
    return this._selectedFeedId;
  }

  @action initAccountStore = async (userId: string) => {
    const isExist = this._accountStoreList.filter((x) => x.userId === userId);

    if (isExist.length === 0) {
      const accountStore = new AccountStore();

      accountStore.setUserId(userId);

      await accountStore.getAccountFeed(0, AccountFeedTypeCode.POST);

      this.addAccountStore(accountStore);

      return accountStore;
    }

    return isExist[0];
  };

  @action addAccountStore = (accountStore: AccountStore) => {
    this._accountStoreList.push(accountStore);
  };

  @action removeAccountStore = (userId: string) => {
    this._accountStoreList = this._accountStoreList.filter(
      (x) => x.userId !== userId
    );
  };

  @action getAccountStore = (userId: string) => {
    return this._accountStoreList.filter((x) => x.userId === userId)[0];
  };

  @action setError = (message?: string) => {
    this.errorMessage = message;
  };

  @action clear = () => {
    this._accountStoreList.forEach((accountList, i) => {
      accountList.clear();
    });
  };

  @action clearAccountStore = (userId: string) => {
    const accountStoreList = this.accountStoreList.filter(
      (x) => x.userId === userId
    );

    if (accountStoreList.length > 0) {
      accountStoreList[0].clear();
    }
  };

  @action sync = (
    feedId: number,
    feedType: AccountFeedTypeCode,
    actType: UserActTypeCode,
    extraData?: any
  ) => {
    feedId = Number(feedId)

    switch (actType) {
      case UserActTypeCode.CREATE:
        this._accountStoreList.forEach((accountList, i) => {
          void accountList.getAccountFeed(0, feedType);
          accountList.setPageIndex(0, feedType);
        });
        break;
      case UserActTypeCode.MODIFY:
        break;
      case UserActTypeCode.REPORT:
      case UserActTypeCode.DELETE:
        this._accountStoreList.forEach((accountList, i) => {
          accountList.removeFeed(feedId, feedType);
        });
        break;
      case UserActTypeCode.SAVE_LIKE:
      case UserActTypeCode.CANCEL_LIKE:
      case UserActTypeCode.SAVE_BOOKMARK:
      case UserActTypeCode.CANCEL_BOOKMARK:
        this._accountStoreList.forEach((accountList, i) => {
          accountList.updateLikeAndBookmark(feedId, actType);
        });
        break;
      case UserActTypeCode.UPDATE_COMMENT:
        this._accountStoreList.forEach((accountList, i) => {
          accountList.updateCommentCount(feedId, extraData as Post);
        });
        break;
    }
  };

  @action updatePost = (feedId: number, post: Post) => {
    this._accountStoreList.forEach((accountList, i) => {
      accountList.updatePost(feedId, post);
    });
  };

  @action updateQna = (feedId: number, qnaDetail: Qna) => {
    this._accountStoreList.forEach((accountList, i) => {
      accountList.updateQna(feedId, qnaDetail);
    });
  };

  @action setUpdatedPost = (updatedPost?: Post) => {
    this._updatedPost = updatedPost;
  };

  @action setSelectedFeedId = (feedId: number) => {
    this._selectedFeedId = feedId;
  };
}

export default new MyHomeStore();
