import { Styles } from "../../../assets/types/Style";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../../assets/styles";
import NotificationItem from "./../presenter/NotificationItem";
import { observer } from "mobx-react-lite";
import NotificationStore from "../../../store/NotificationStore";
import { ReactComponent as EmptyAlarm } from "../../../assets/icons/empty_alarm.svg";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import { text } from "stream/consumers";
import styles from "./NotificationList.module.scss";

const NotificationList = observer(() => {
  const { t } = useTranslation();
  const {
    retrieveNotifications,
    notifications,
    loadingStatus,
    setLoadingStatus,
    pullToRefreshNotifications,
    tabType,
    syncNotCheckedCount,
  } = NotificationStore;

  // TODO YY
  // useEffect(() => {
  //   flatListRef?.current?.scrollToOffset({ animated: true, offset: 0 });
  // }, [tabType]);

  const handleFlatListEndReached = () => {
    void retrieveNotifications();
  };

  return (
    <>
      <Virtuoso
        style={stylesObject.alarm_list}
        data={notifications}
        totalCount={notifications.length}
        useWindowScroll
        itemContent={(index, item) => {
          return (
            <NotificationItem
              key={item.notificationId.toString()}
              index={index}
              notification={item}
            />
          );
        }}
        endReached={() => {
          void handleFlatListEndReached();
        }}
        components={{
          Header: () => <></>,
          EmptyPlaceholder: () =>
            loadingStatus === "ALL_LOADED" ? (
              <div className={styles.empty_alarm_wrap}>
                <EmptyAlarm />
                <div className={styles.empty_alarm} aria-label="empty alram">
                  <div className={styles.empty_alarm_box}>
                    <span className={styles.empty_alarm_txt}>
                      {t("screen.notificationScreen.message.empty")}
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            ),
          Footer: () => {
            if (loadingStatus === "LOADING")
              return <CustomActivityIndicatorSmall />;
            else if (loadingStatus === "ALL_LOADED" && notifications.length > 0)
              return (
                <div className={styles.last_alarm_item}>
                  <div className={styles.confirm_all}>
                    <span className={styles.confirm_all_text}>
                      {t("screen.notificationScreen.message.confirm_all")}
                    </span>
                  </div>
                </div>
              );
            else
              return (
                <>
                  {/* <div style={{ height: 60, backgroundColor: "transparent" }} /> */}
                </>
              );
          },
        }}
      />
    </>
  );
});

export default NotificationList;

export const stylesObject: Styles = {
  alarm_list: {
    ...Colors.background_fff,
    overflowY: "unset",
  },
};
