import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_sort.svg";
import { observer } from "mobx-react";
import SearchStore from "../../../store/SearchStore";
import { SearchSortType, SearchTabMenu } from "../../../constants/Search";
import { getNumberFormat } from "../../../utils/number";
import { useTranslation } from "react-i18next";
import "./SearchCountAndSort.scss";

const SearchCountAndSort = observer(
  ({
    type,
    sort,
    count,
  }: {
    type: SearchTabMenu;
    sort: string;
    count: number;
  }) => {
    const [_type, setType] = useState(type);
    const [_sort, setSort] = useState(sort);
    const [_count, setCount] = useState(count);

    const { t } = useTranslation();
    const { setShowSortBox } = SearchStore;

    useEffect(() => {
      setType(type);
    }, [type]);

    useEffect(() => {
      setSort(sort);
    }, [sort]);

    useEffect(() => {
      setCount(count);
    }, [count]);

    return (
      <div id="SearchCountAndSort">
        {SearchStore.typoCorrectionYn && (
          <div className="typo_correction_wrap">
            <p className="typo_correction_text_wrap">
              <span className="typo_correction_text">
                {t(`screen.search.message.typoCorrection1`)}
              </span>
              <span className="typo_correction_text">
                <span className="typo_correction_text_color">
                  {SearchStore.typoCorrectionResult?.suggestedKeyword}
                </span>
              </span>
              <span className="typo_correction_text">
                {t(`screen.search.message.typoCorrection2`)}
              </span>
            </p>
          </div>
        )}
        <div className="search_result_info">
          <div className="search_info">
            <div
              className="info_number"
              // aria-label="Searched count"
            >
              {getNumberFormat(_count)}
            </div>
            <div className="info_txt">
              {t(`screen.search.message.${_type}Result`)}
            </div>
          </div>

          {_count > 0 &&
            _type !== SearchTabMenu.Poster &&
            _type !== SearchTabMenu.Tag &&
            _type !== SearchTabMenu.Total && (
              <div
                className="search_sort"
                onClick={() => {
                  setShowSortBox(true);
                  // Keyboard.dismiss();
                }}
                aria-label="Search sort"
              >
                <div className="search_sort_txt">
                  {_sort === SearchSortType.LATEST
                    ? t("screen.search.label.latest")
                    : _sort === SearchSortType.POPULAR
                    ? t("screen.search.label.popular")
                    : t("screen.search.label.accuracy")}
                </div>
                <ArrowDown />
              </div>
            )}
        </div>
      </div>
    );
  }
);
export default SearchCountAndSort;
