import { TrackFunction } from "../types";
import { trackDsSyncEventProperties } from "../../../api/track/api";
import { WEB_UNIQUE_KEY } from "../../../constants/Storage";

type Use220DataScience = () => {
  log: TrackFunction;
};

export const use220DataScience: Use220DataScience = () => {
  const log: TrackFunction = (event, properties) => {
    if (properties) {
      void trackDsSyncEventProperties("MIXPANEL_DS_SYNC", event, {...properties, webUniqueKey : localStorage.getItem(WEB_UNIQUE_KEY)});
    }
  };

  return {
    log,
  };
};
