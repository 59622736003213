import { useTranslation } from "react-i18next";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import { observer } from "mobx-react";
import { countFollow } from "../../../utils/numberCount";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { ReactComponent as IconShop } from "../../../assets/icons/icon_shop.svg";
import { ReactComponent as IconMall } from "../../../assets/icons/icon_mall.svg";
import { BrandCommunityHomeInfoResponse } from "../../../api/brand/model";
import { Styles } from "../../../assets/types/Style";
import { ReactComponent as IconMsgEdit } from "../../../assets/icons/icon_message_edit.svg";
import { ReactComponent as IconCheck } from "../../../assets/icons/icon_join_check.svg";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { goToLifecareSearchResultPage } from "../../../utils/mall";

const BrandHomeHeader = observer(
  ({
    renderData,
    actionBrandSubscribe,
    goToInAppBrowser,
    goToBrandCommunityProductListScreen,
    goToBrandCommunityArticleListScreen,
  }: {
    renderData: BrandCommunityHomeInfoResponse;
    actionBrandSubscribe: (communityId: number) => void;
    goToInAppBrowser: (url: string) => void;
    goToBrandCommunityProductListScreen: (homeInfo: BrandCommunityHomeInfoResponse) => void;
    goToBrandCommunityArticleListScreen: (homeInfo: BrandCommunityHomeInfoResponse) => void;
  }) => {
    const { t } = useTranslation();
    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
    // const OFFICIAL_BADGE_URI = PUBLIC_BUCKET_URL + "/ASSETS/badge_official.png";

    const renderBtn = () => {
      if (
        renderData.communityShopLink &&
        renderData.communityShopLink !== null &&
        renderData.communityShopLink?.length > 0 &&
        renderData.communityMallKeyword &&
        renderData.communityMallKeyword !== null &&
        renderData.communityMallKeyword.length > 0
      ) {
        return (
          <>
            <TouchableWithAuthCheck
              style={styles.w_half}
              onPress={() => {
                goToInAppBrowser(renderData.communityShopLink || "");
              }}
            >
              <div style={styles.btn_add_icon}>
                <IconShop />
                <p style={styles.btn_add_icon_text}>{t("screen.brandCommunity.button.shop")}</p>
              </div>
            </TouchableWithAuthCheck>

            <TouchableWithAuthCheck
              style={styles.w_half}
              onPress={() => {
                goToLifecareSearchResultPage(renderData.communityMallKeyword || "");
              }}
            >
              <div style={styles.btn_add_icon}>
                <IconMall />
                <p style={styles.btn_add_icon_text}>{t("screen.brandCommunity.button.220mall")}</p>
              </div>
            </TouchableWithAuthCheck>
          </>
        );
      } else if (
        renderData.communityShopLink &&
        renderData.communityShopLink !== null &&
        renderData.communityShopLink?.length > 0
      ) {
        return (
          <TouchableWithAuthCheck
            style={styles.w_full}
            onPress={() => {
              goToInAppBrowser(renderData.communityShopLink || "");
            }}
          >
            <div style={styles.btn_add_icon}>
              <IconShop />
              <p style={styles.btn_add_icon_text}>{t("screen.brandCommunity.button.shop")}</p>
            </div>
          </TouchableWithAuthCheck>
        );
      } else if (
        renderData.communityMallKeyword &&
        renderData.communityMallKeyword !== null &&
        renderData.communityMallKeyword.length > 0
      ) {
        return (
          <TouchableWithAuthCheck
            style={styles.w_full}
            onPress={() => {
              goToLifecareSearchResultPage(renderData.communityMallKeyword || "");
            }}
          >
            <div style={styles.btn_add_icon}>
              <IconMall />
              <p style={styles.btn_add_icon_text}>{t("screen.brandCommunity.button.220mall")}</p>
            </div>
          </TouchableWithAuthCheck>
        );
      } else {
        return <></>;
      }
    };

    return renderData.communityId ? (
      <div style={styles.profile}>
        <div style={styles.profile_info_wrap}>
          <div>
            {/* <FastImageWithFallback
                style={styles.corp_badge}
                source={{
                  uri:
                    renderData.communityLogoUrl === undefined && renderData.communityLogoUrl === null
                      ? OFFICIAL_BADGE_URI
                      : "",
                }}
              /> */}
            <FastImageWithFallback
              style={styles.profile_image}
              source={{ uri: `${PUBLIC_BUCKET_URL}${renderData.communityLogoUrl || ""}` }}
            />

            {/* 프로필 수정 아이콘 추후 개발*/}
            {/* {renderData.corpMemberYn && renderData.corpMemberYn === "Y" && (
              <View style={styles.edit_icon_wrap}>
                <PencilIcon style={styles.edit_icon} />
              </View>
            )} */}
          </div>

          <div style={styles.profile_info}>
            <div style={styles.profile_info_bottom}>
              <div style={styles.info_bottom_item}>
                <p style={styles.profile_text_3}>{countFollow(renderData?.communitySubscriberCount)}</p>
                <p style={styles.profile_text_2}>{t("screen.brandCommunity.label.subscriber")}</p>
              </div>
              <TouchableWithAuthCheck onPress={() => goToBrandCommunityArticleListScreen(renderData)}>
                <div style={styles.info_bottom_item}>
                  <p style={styles.profile_text_3}>{countFollow(renderData.communityArticleCount)}</p>
                  <p style={styles.profile_text_2}>{t("screen.brandCommunity.label.article")}</p>
                </div>
              </TouchableWithAuthCheck>
              <TouchableWithAuthCheck onPress={() => goToBrandCommunityProductListScreen(renderData)}>
                <div style={styles.info_bottom_item}>
                  <p style={styles.profile_text_3}>{countFollow(renderData.communityProductCount)}</p>
                  <p style={styles.profile_text_2}>{t("screen.brandCommunity.label.product")}</p>
                </div>
              </TouchableWithAuthCheck>
              <TouchableWithAuthCheck
                style={styles.info_bottom_item}
                onPress={() => {
                  goTo("/ArticleWrite", {
                    state: {
                      communityId: renderData.communityId,
                      subscriptionYn: renderData.subscriptionYn,
                      articleDisclosureYn: "N",
                    }
                  });
                }}
              >
                <IconMsgEdit style={{height: 30}}/>
                <p style={styles.profile_text_2}>{t("screen.brandCommunity.button.inquiry")}</p>
              </TouchableWithAuthCheck>
            </div>
          </div>
        </div>

        <div style={styles.bx_join_wrap}>
          <div style={styles.bx_text_join}>
            <div style={styles.bx_brand_profile}>
              <p style={styles.profile_text_1}>
                {renderData?.communityName}
              </p>
              {renderData.subscriptionYn === "Y" && (
                <div style={styles.icon_check_whether}>
                  <IconCheck />
                  <p style={styles.txt_check_whether}>{t("screen.brandCommunity.label.subscribing")}</p>
                </div>
              )}
            </div>
            <p style={styles.txt_greeting}
            >
              {renderData.communityIntroduce}
            </p>
          </div>

          {renderData.subscriptionYn !== "Y" && (
            <TouchableWithAuthCheck
              style={styles.bx_btn_join}
              onPress={() => actionBrandSubscribe(renderData.communityId)}
            >
              <p style={styles.txt_btn_join}>{t("screen.brandCommunity.button.participate")}</p>
            </TouchableWithAuthCheck>
          )}
        </div>

        <div style={styles.btn_wrapper}>{renderBtn()}</div>
      </div>
    ) : (
      <></>
    );
  }
);

export default BrandHomeHeader;

const styles: Styles = {
  url: { color: "white" },
  backdrop: {
    ...Colors.background_222,
    ...Positions.fullscreen,
    opacity: 0.5,
    zIndex: 9,
  },
  profile: {
    paddingTop: 18,
    ...Spacings.padding_right_left_16,
    ...Colors.background_fff,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: 'solid',
    borderBottomColor: "#eee",
  },
  profile_info_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    alignItems: "center",
  },
  profile_image: {
    width: 64,
    height: 64,
    borderRadius: 39,
  },
  corp_badge: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    width: 64,
    height: 64,
  },
  profile_info: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_left_16,
  },
  profile_info_top: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.margin_bottom_10,
    ...Arranges.center_h,
    ...Arranges.between,
  },
  btn_write: {
    borderWidth: 1,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_top_bottom_6,
    ...Spacings.padding_left_right_14,
    ...Arranges.center_v_h,
    ...Colors.border_color_be69c3,
    ...Spacings.border_radius_100,
  },
  btn_write_text: {
    ...Colors.font_be69c3,
    ...Texts.font_weight_500,
    ...Spacings.margin_left_4,
  },
  bx_join_wrap: {
    ...FlexBoxs.flex,
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 14,
    alignItems: "center",
  },
  bx_text_join: {
    marginLeft: 10,
    marginRight: 20,
    flexShrink: 1,
    flexGrow: 1,
  },
  bx_brand_profile: {
    ...FlexBoxs.flex,
    flexDirection: "row",
    alignItems: "center",
  },
  icon_check_whether: {
    ...FlexBoxs.flex,
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 8,
    flexShrink: 0,
  },
  txt_check_whether: {
    fontSize: 12,
    color: "#BE69C2",
  },
  bx_btn_join: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: "#BE69C2",
    height: 32,
    paddingHorizontal: 8,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    borderRadius: 60,
    flexShrink: 0,
    flexGrow: 0,
    marginLeft: 8,
    padding: '0px 10px'
  },
  txt_btn_join: {
    fontSize: 14,
    color: "#BE69C2",
    fontWeight: "500",
  },
  profile_text_1: {
    ...Texts.font_weight_500,
    fontSize: 14,
    color: "#222",
    letterSpacing: -0.56,
    overflow: 'hidden', 
    display: '-webkit-box', 
    WebkitLineClamp: '1', 
    lineClamp: '1', 
    WebkitBoxOrient: 'vertical'
  },
  profile_btn: {
    ...Spacings.margin_left_4,
    ...Spacings.border_radius_100,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    width: 18,
    height: 18,
    alignSelf: "center",
  },
  profile_btn1: {
    ...Positions.absolute,
    ...Positions.left,
    top: -2.5,
    width: 0,
    height: 0,
    borderLeftWidth: 5,
    borderLeftColor: "transparent",
    borderRightWidth: 5,
    borderRightColor: "transparent",
    borderBottomWidth: 2.89,
    borderBottomColor: "#68276e",
  },
  profile_btn2: {
    width: 10,
    height: 8,
    backgroundColor: "#68276e",
  },
  profile_btn3: {
    ...Positions.absolute,
    ...Positions.left,
    bottom: -2.5,
    width: 0,
    height: 0,
    borderLeftWidth: 5,
    borderLeftColor: "transparent",
    borderRightWidth: 5,
    borderRightColor: "transparent",
    borderTopWidth: 2.89,
    borderTopColor: "#68276e",
  },
  profile_info_bottom: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    paddingHorizontal: 16,
    alignItems: "flex-start",
    justifyContent: "space-around",
  },
  profile_level_text: {
    ...Texts.contents_text_5,
    ...Colors.font_fff,
    opacity: 0.6,
  },
  info_bottom_item: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.vertical,
  },
  profile_text_2: {
    ...Colors.font_666666,
    fontSize: 14,
    letterSpacing: -0.56,
  },
  profile_text_3: {
    ...Texts.contents_text_1,
    ...Texts.font_weight_600,
    ...Colors.font_222,
  },
  txt_greeting: {
    fontSize: 14,
    color: "#222",
    fontWeight: "400",
    letterSpacing: -0.56,
    overflow: 'hidden', 
    display: '-webkit-box', 
    WebkitLineClamp: '2', 
    lineClamp: '2', 
    WebkitBoxOrient: 'vertical'
  },
  profile_text_4: {
    ...Texts.contents_text_31,
    ...Colors.font_222,
  },
  profile_text_5: {
    ...Texts.contents_text_5,
    ...Texts.text_underline,
    ...Colors.font_fff,
    ...Spacings.margin_top_2,
    opacity: 0.6,
    textDecorationLine: "underline",
  },
  btn_wrapper: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    justifyContent: 'space-between',
    ...Spacings.margin_bottom_20,
    ...Spacings.margin_top_16,
    flexWrap: "wrap",
  },
  w_half: {
    width: "49%",
    paddingHorizontal: 5,
  },
  w_full: {
    width: "100%",
    paddingHorizontal: 5,
    ...Spacings.margin_top_8,
  },
  btn_add_icon: {
    height: 40,
    ...FlexBoxs.flex_1,
    ...Texts.button_text_4,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...Colors.background_f5f5f5,
    ...Spacings.border_radius_6,
  },
  btn_add_icon_text: {
    ...Spacings.margin_left_6,
  },
  tag: {
    ...Spacings.margin_top_24,
    ...Spacings.padding_left_4,
  },
  tag_item: {
    ...Spacings.margin_right_10,
  },
  tag_text: {
    ...Texts.contents_text_25,
  },
  gradient: {
    ...Positions.absolute,
    ...Positions.right,
    ...Positions.top_24,
    width: 22,
    bottom: 0,
  },

  recommend_users: {
    ...Spacings.margin_top_20,
    ...Spacings.padding_left_16,
  },
  recommend_user_image: {
    width: 64,
    height: 64,
  },
  recommend_users_text1: {
    ...Spacings.margin_bottom_10,
    ...Texts.contents_text_28,
    ...Texts.font_weight_500,
    ...Colors.font_333,
  },
  recommend_user_item: {
    ...Spacings.margin_right_16,
  },
  recommend_user_text2: {
    ...Texts.contents_text_5,
    ...Arranges.text_align_center,
    ...Spacings.margin_top_4,
    width: 56,
  },

  follow_btn1: {
    backgroundColor: "#be69c2",
    ...Spacings.padding_top_bottom_13,
    ...Spacings.border_radius_6,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  follow_btn_text1: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  follow_btn2: {
    ...Spacings.padding_top_bottom_13,
    ...Spacings.border_radius_8,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Colors.border_color_fff,
    borderWidth: 1,
  },
  follow_btn_text2: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  edit_icon_wrap: {
    right: 0,
    bottom: 0,
    ...Positions.absolute,
    ...Colors.background_fff,
    borderRadius: "50%",
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    width: 28,
    height: 28,
    boxShadow: "0 4px 4px rgba(0,0,0,0.1)",
  },
  edit_icon: {
    height: 20,
    width: 20,
  },
};
