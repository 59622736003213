import React, { forwardRef, useState } from "react";

import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../../assets/styles";
import MyHomeStore from "../../../store/MyHomeStore";
import MissionItem from "../../mission/presenter/MissionItem";
import { Virtuoso } from "react-virtuoso";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import "./AccountMission.scss";
import CommonStore from "../../../store/CommonStore";

const AccountMission = observer(
  ({
    isMy,
    userId,
    dataRefresh,
    endReached,
  }: {
    isMy: boolean;
    userId: string;
    dataRefresh?: () => void;
    endReached: () => void;
  }) => {
    const widthApp = CommonStore.widthApp;
    const { t } = useTranslation();
    const { getAccountStore } = MyHomeStore;
    const accountStore = getAccountStore(userId);
    const { missionList, loading } = getAccountStore(userId);

    return (
      <>
        {missionList.length > 0 ? (
          <div className="account_mission_list_wrapper">
            <Virtuoso
              className="account_mission_list mission-list"
              data={missionList}
              context={{ count: missionList.length }}
              itemContent={(index, item) => {
                return <MissionItem listIndex={index} mission={item} />;
              }}
              endReached={() => {
                void endReached();
              }}
              useWindowScroll
              components={{
                Footer: () =>
                  loading === undefined || loading ? (
                    <CustomActivityIndicatorSmall isDarkSpinner={true} />
                  ) : (
                    <div
                      style={{ height: 1, backgroundColor: "transparent" }}
                    />
                  ),
                Item: forwardRef(
                  (
                    { style, children, ...props },
                    ref: React.LegacyRef<HTMLDivElement> | undefined
                  ) => {
                    const space =
                      props.context?.count === props["data-index"] + 1 ? 0 : 35;
                    return (
                      <div
                        ref={ref}
                        {...props}
                        style={{
                          ...style,
                          margin: 0,
                        }}
                      >
                        {children}
                        <div
                          style={{
                            height: space,
                            backgroundColor: "transparent",
                          }}
                        />
                      </div>
                    );
                  }
                ),
              }}
            />
          </div>
        ) : (
          <div className="empty_data_wrapper">
            <img
              className="empty_data"
              src={require("../../../assets/images/empty_post_list.png")}
              alt="empty_post_list"
              width={widthApp - 56}
            />
            <p className="empty_text">{t("screen.myHome.message.noMission")}</p>
          </div>
        )}
      </>
    );
  }
);

export default AccountMission;
