import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
} from "../../../assets/styles";

import { observer } from "mobx-react";
// import { PostLocalSave } from "../../../store/PostWriteStore";
// import UploadStore from "../../../store/upload/UploadStore";

import { FEED_CALL_STATE } from "../../../constants/Feed";
import { POST_TEMP_DATA } from "../../../constants/Storage";
import { AccountFeedTypeCode } from "../../../constants/Account";

import NotificationModalWithHideCallback from "../../../components/modals/NotificationModalWithHideCallback";

import { NotificationModal } from "../../../components/modals";
// import { cache } from "../../../utils/cache";
import { in60Days } from "../../../utils/datetime";
import { ReactComponent as EmptyQna } from "../../../assets/images/EmptyQna.svg";
import { ReactComponent as EmptyPost } from "../../../assets/images/EmptyPost.svg";
import { ReactComponent as EmptyAb } from "../../../assets/images/EmptyAb.svg";
import "./EmptyFeed.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CommonStore from "../../../store/CommonStore";

const EmptyFeed = observer(
  ({
    isMy,
    type,
    dataRefresh,
  }: {
    isMy: boolean;
    type: AccountFeedTypeCode;
    dataRefresh: () => void;
  }) => {
    const { t } = useTranslation();

    const [feedType, setFeedType] = useState("");
    const [showPostPopup, setShowPostPopup] = useState(false);
    // const [postData, setPostData] = useState({} as PostLocalSave | null);
    const [registering, setRegistering] = useState<boolean>(false);

    // TODO : 기존 작성 중인 포스트 있는지 확인하고 작성하러가기
    // const checkSavedPostThenMove = () => {
    //   setPostData(null);
    //   cache
    //     .get(POST_TEMP_DATA)
    //     .then((result: string) => {
    //       if (result) {
    //         const storedData: PostLocalSave = JSON.parse(result);
    //         const postTempDate = storedData.date;
    //         if (
    //           postTempDate != null &&
    //           postTempDate !== undefined &&
    //           in60Days(postTempDate)
    //         ) {
    //           setPostData(storedData);
    //           moveToWritePost(storedData);
    //         } else {
    //           moveToWritePost();
    //         }
    //       } else {
    //         moveToWritePost();
    //       }
    //     })
    //     .catch((reason: any) => reason);
    // };

    // TODO
    // const moveToWritePost = (data?: PostLocalSave) => {
    //   data
    //     ? setShowPostPopup(true)
    //     : navigate("/PostWriteScreen", {
    //         state: {
    //           data: null,
    //           onLeave: (result: boolean) => {
    //             if (result) dataRefresh();
    //           },
    //           callState: FEED_CALL_STATE.MY_HOME,
    //         },
    //       });
    // };

    const moveToWrite = () => {
      switch (type) {
        // TODO : 포스트 작성하러 가기
        case AccountFeedTypeCode.POST:
          CommonStore.setShowDownloadAppDrive(true);
          //   if (!UploadStore.isDefaultStatus) {
          //     setRegistering(true);
          //     return;
          //   }
          //   checkSavedPostThenMove();
          break;
        case AccountFeedTypeCode.QNA:
          CommonStore.setShowDownloadAppDrive(true);
          // navigate("QnaWriteScreen", {
          //   state: {
          //     onLeave: (result: boolean) => {
          //       if (result) dataRefresh();
          //     },
          //   },
          // });
          break;
        case AccountFeedTypeCode.AB:
          CommonStore.setShowDownloadAppDrive(true);
          // navigate("AbWriteScreen", {
          //   state: {
          //     onLeave: (result: boolean) => {
          //       if (result) dataRefresh();
          //     },
          //   },
          // });
          break;
        default:
          break;
      }
    };

    useEffect(() => {
      switch (type) {
        case AccountFeedTypeCode.POST:
          setFeedType(t("screen.myHome.label.post"));
          break;
        case AccountFeedTypeCode.QNA:
          setFeedType(t("screen.myHome.label.qna"));
          break;
        case AccountFeedTypeCode.AB:
          setFeedType(t("screen.myHome.label.ab"));
          break;
        default:
          setFeedType(t("screen.myHome.label.post"));
          break;
      }
    }, [type, t]);

    const emptyDataBackgroundSource = useMemo(() => {
      switch (type) {
        case AccountFeedTypeCode.QNA:
          return <EmptyQna />;
        case AccountFeedTypeCode.AB:
          return <EmptyAb />;
        case AccountFeedTypeCode.POST:
          return <EmptyPost />;
        default:
          return <EmptyPost />;
      }
    }, [type]);

    return (
      <div id="empty-feed-account-screen" className="empty_poster">
        {emptyDataBackgroundSource}
        <div className="empty">
          {isMy ? (
            <>
              <div className="my_empty_text_wrap">
                {type === AccountFeedTypeCode.POST && (
                  <p className="my_empty_text" arai-label="Creat first one">
                    {t("screen.myHome.message.emptyPost")}
                  </p>
                )}
                {type === AccountFeedTypeCode.QNA && (
                  <p className="my_empty_text" arai-label="Creat first one">
                    <p className="my_empty_text_bold">
                      {t("screen.myHome.message.emptyQna1")}
                    </p>
                    {t("screen.myHome.message.emptyQna2")}
                  </p>
                )}
                {type === AccountFeedTypeCode.AB && (
                  <p className="my_empty_text" arai-label="Creat first one">
                    {t("screen.myHome.message.emptyAB")}
                  </p>
                )}
              </div>
              <div className="empty_btn" onClick={moveToWrite}>
                {type === AccountFeedTypeCode.POST && (
                  <p className="empty_btn_text" arai-label="Create">
                    {t("screen.myHome.button.emptyPost")}
                  </p>
                )}
                {type === AccountFeedTypeCode.QNA && (
                  <p className="empty_btn_text" arai-label="Create">
                    {t("screen.myHome.button.emptyQna")}
                  </p>
                )}
                {type === AccountFeedTypeCode.AB && (
                  <p className="empty_btn_text" arai-label="Create">
                    {t("screen.myHome.button.emptyAB")}
                  </p>
                )}
              </div>
            </>
          ) : (
            <div className=" empty_cor">
              <p className="empty_text" arai-label="No feed data">
                {t("screen.myHome.message.noFeed", { feedType: feedType })
                  .replace("&amp;", "&")
                  .replace("&#x2F;", "/")}
              </p>
            </div>
          )}
        </div>
        {showPostPopup && (
          <NotificationModal
            isVisible={showPostPopup}
            title={t("screen.post.title.loadPost")}
            contents1={t("screen.post.message.loadTempSaved")}
            useTwoButton={true}
            isBlack={true}
            defaultButtonText={t("screen.post.button.newOne")}
            extraButtonText={t("screen.post.button.load")}
            onClickDefaultButton={() => {
              goTo("PostWriteScreen", { state: { data: null } });
              setShowPostPopup(false);
            }}
            // TODO : post 작성 연결
            // onClickExtraButton={() => {
            //   navigate("PostWriteScreen", { state: { data: postData } });
            //   setShowPostPopup(false);
            // }}
            onRequestClose={() => {
              setShowPostPopup(false);
            }}
          />
        )}

        {registering && (
          <NotificationModalWithHideCallback
            isVisible={registering}
            contents1={t("screen.post.message.registering")}
            useTwoButton={false}
            isBlack={false}
            defaultButtonText={t("screen.notificationModal.button.confirm")}
            onClickDefaultButton={() => setRegistering(false)}
            onClickExtraButton={() => setRegistering(false)}
            onRequestClose={() => setRegistering(false)}
            modalCloseCallback={() => setRegistering(false)}
            textAlign={"center"}
          />
        )}
      </div>
    );
  }
);

export default EmptyFeed;
