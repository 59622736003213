import branch from 'branch-sdk'
import { EventProperty } from '../types'
//import moment from "moment";

// TODO: 3P 정보 들어왔을때 기록해야 함
// export const useBranchDeeplink = () => {
//   // function handleParams(params: SessionData | undefined) {
//   function handleParams(params: any | undefined) {
//     if (!params) {
//       return
//     }

//     if (params.$3p) {
//       // cpa 정보 저장
//       void MemberStore.setCpaInfo(params)
//     }

//     if (params.screen) {
//       let url = ''
//       Object.keys(params).forEach((key, index) => {
//         if (
//           !(key.startsWith('~') || key.startsWith('$') || key.startsWith('+'))
//         ) {
//           url = url + '&' + key + '=' + String(params[key]?.toString())
//         }
//       })
//       /* eslint-disable */
//       if (params.nickname) {
//         void RecommenderStore.setRecommenderNickname(params.nickname.toString())
//       }
//       /* eslint-enable */
//       NotificationStore.setInitialNotificaiton({
//         link: url,
//         pushType: String(params.screen),
//         sendType: 'REALTIME',
//         title: 'BRANCH DEEPLINK',
//       })
//     }
//   }

//   const urlHandler = (event: { url: string }) => {
//     if (event.url.concat('app2.220cordncode.com')) {
//       branch.openURL(event.url)
//     }
//   }

//   useEffect(() => {
//     let eventUnsubscribe: EmitterSubscription
//     if (Platform.OS === 'ios') {
//       eventUnsubscribe = Linking.addEventListener('url', urlHandler)
//       void Linking.getInitialURL().then((url) => {
//         // debug 일때는 null 이 들어온다고 한다
//         if (url?.concat('app2.220cordncode.com')) {
//           branch.openURL(url)
//         }
//       })
//     }
//     /* eslint-disable */
//     const unsubscribe = branch.subscribe({
//       onOpenComplete: ({ error, params, uri }) => {
//         if (error) {
//           return
//         }

//         if (
//           /*params && "+rn_cached_initial_event" in params &&*/ Platform.OS ===
//           'ios'
//         ) {
//           cache
//             .get('branch init')
//             .then((data) => {
//               if (data === undefined) {
//                 void branch
//                   .getFirstReferringParams()
//                   .then((firstRefferingParam) => {
//                     console.log(
//                       `[branch.io] getFirstReferringParams : ${JSON.stringify(
//                         firstRefferingParam,
//                         null
//                       )}`
//                     )

//                     if (!firstRefferingParam['+clicked_branch_link']) {
//                       if (firstRefferingParam['+non_branch_link']) {
//                         console.log('non_branch_link: ' + uri)
//                         // Route based on non-Branch links
//                         return
//                       }
//                     }

//                     // const Date = moment.unix(firstRefferingParam["+click_timestamp"]!!);

//                     handleParams(firstRefferingParam)
//                   })

//                 void branch
//                   .getLatestReferringParams()
//                   .then((firstRefferingParam) => {
//                     console.log(
//                       `[branch.io] getLatestReferringParams : ${JSON.stringify(
//                         firstRefferingParam,
//                         null
//                       )}`
//                     )

//                     if (!firstRefferingParam['+clicked_branch_link']) {
//                       if (firstRefferingParam['+non_branch_link']) {
//                         console.log('non_branch_link: ' + uri)
//                         // Route based on non-Branch links
//                         return
//                       }
//                     }
//                     handleParams(firstRefferingParam)
//                   })

//                 cache
//                   .set('branch init', 'true')
//                   .then(() => undefined)
//                   .catch((reason) => undefined)
//                 branch.skipCachedEvents()
//               } else {
//                 void branch
//                   .getLatestReferringParams()
//                   .then((firstRefferingParam) => {
//                     console.log(
//                       `[branch.io] getLatestReferringParams : ${JSON.stringify(
//                         firstRefferingParam,
//                         null
//                       )}`
//                     )

//                     if (!firstRefferingParam['+clicked_branch_link']) {
//                       if (firstRefferingParam['+non_branch_link']) {
//                         console.log('non_branch_link: ' + uri)
//                         // Route based on non-Branch links
//                         return
//                       }
//                     }
//                     handleParams(firstRefferingParam)
//                   })
//               }
//             })
//             .catch((reason) => {})
//         } else {
//           handleParams(params)
//         }
//       },
//     })
//     /* eslint-enable */

//     return () => {
//       eventUnsubscribe?.remove()
//       unsubscribe()
//     }
//   }, [])
// }

export function logBranch(eventName: string, mergedProperties: EventProperty) {
  branch.logEvent(eventName, mergedProperties)
}
