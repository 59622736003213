import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import BookmarkMain from "./presenter/BookmarkMain";
import BookmarkStore from "../../store/BookmarkStore";
import { FeedTypeCode } from "../../constants/Feed";
import { useLocation, useNavigationType, useParams } from "react-router-dom";
import { useWindowScroll } from "react-use";
import { isEmpty } from "lodash";
// import RemoteConfigStore from "../../store/RemoteConfigStore";

const BookmarkScreen = observer((data: any) => {
  const navigationType = useNavigationType();
  const state = useLocation().state; // navigate state

  const {
    bookmarkFeeds,
    currentTab,
    setCurrentTab,
    clearBookmarkStore,
    initBookmarkPosts,
  } = BookmarkStore;

  useEffect(() => {
    // const { show_brand_page } = RemoteConfigStore;

    if (isEmpty(bookmarkFeeds)) {
      void initBookmarkPosts(FeedTypeCode.POST);
      void initBookmarkPosts(FeedTypeCode.QNA);
      void initBookmarkPosts(FeedTypeCode.AB);
      void initBookmarkPosts(FeedTypeCode.PRODUCT);
    }
    // if (show_brand_page === "true")
    //   void initBookmarkPosts(FeedTypeCode.ARTICLE);

    if (navigationType !== "POP") {
      // 처음 들어오면 tab 포스트로 초기화
      setCurrentTab(FeedTypeCode.POST);
      // init bookmark posts
      void initBookmarkPosts(FeedTypeCode.POST);
      void initBookmarkPosts(FeedTypeCode.QNA);
      void initBookmarkPosts(FeedTypeCode.AB);
      void initBookmarkPosts(FeedTypeCode.PRODUCT);
    } else {
      setCurrentTab(currentTab);
    }
    return () => {
      // clearBookmarkStore();
    };
  }, []);

  return (
    <>
      <div
        style={{
          flex: 0,
          backgroundColor: "#fff",
        }}
      />
      <div
        style={{ flex: 1, backgroundColor: "#fff" }}
        aria-label="Bookmark screen"
      >
        <BookmarkMain />
      </div>
      <div
        style={{
          flex: 0,
          backgroundColor: "#fff",
        }}
      />
    </>
  );
});

export default BookmarkScreen;
