// 모음으로 끝나는지 여부 확인
export const endsWithVowel = (string: string) => {
  const charAt = string.charCodeAt(string.length - 1);
  return (charAt - 0xac00) % 28 > 0;
};

export const replaceKoreanEndWordByTarget = (
  string: string,
  target: string
) => {
  return string.replace("이/가", endsWithVowel(target) ? "이" : "가");
};
