import { observer } from "mobx-react-lite";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import { ReactComponent as ImageAi } from "../../../assets/icons/img_ai_lobot.svg";
import { BrandLineUpSortType, BrandListTabType, BrandRecommendResponse } from "../../../api/brand/model";
import { ReactComponent as IconMoreArrow } from "../../../assets/icons/icon_more_arrow.svg";
import { useTranslation } from "react-i18next";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { Virtuoso } from "react-virtuoso";
import { Styles } from "../../../assets/types/Style";
import BrandRecommendItem from "./BrandRecommendItem";

const BrandRecommend = observer(
  ({
    renderData,
    goToBrandHome,
    actionBrandSubscribe,
  }: {
    renderData: BrandRecommendResponse[] | [];
    goToBrandHome: (comunityId: number) => void;
    actionBrandSubscribe: (comunityId: number) => void;
  }) => {

    const { t } = useTranslation();

    return (
      <>
        <div style={styles.bx_in_title}>
          <div style={styles.title_text}>
            <div style={styles.bg_title} />
            <ImageAi />
            <p style={styles.title_text_st1}>{t("screen.brandCommunity.label.recommendBrand")}</p>
          </div>
          <button
            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            onClick={() =>
              goTo("/BrandCommunityList", {
                state:
                {
                  brandListType: BrandListTabType.TOTAL,
                  sortType: BrandLineUpSortType.ACCURACY
                },
              })
            }
          >
            <p style={styles.title_more}>{t("screen.brandCommunity.button.more")}</p>
            <IconMoreArrow />
          </button>
        </div>
        <div style={styles.popular_post}>
          <div style={styles.popular_post_list}>
            {renderData?.length > 0 && (
              <Virtuoso
                style={{ height: 230, overflowY: "hidden" }}
                horizontalDirection
                data={renderData}
                initialItemCount={renderData.length}
                itemContent={(index, item: any) => {
                  return (
                    <>
                      <BrandRecommendItem
                        key={`popularPost${item.communityId}`}
                        onPress={goToBrandHome}
                        item={item}
                        index={index}
                        actionBrandSubscribe={actionBrandSubscribe}
                      />
                    </>
                  );
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  }
);

export default BrandRecommend;

const styles: Styles = {
  popular_post: {
    ...Spacings.margin_bottom_12,
  },
  bg_title: {
    height: 8,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#F7E237",
    zIndex: 1,
  },
  bx_in_title: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_top_30,
  },
  title_text: {
    ...FlexBoxs.flex,
    position: "relative",
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  title_text_st1: {
    fontSize: 18,
    fontWeight: "500",
    color: "#222",
    letterSpacing: -0.72,
    zIndex: 1,
  },
  title_more: {
    ...Texts.contents_text_0,
    ...Colors.font_999,
  },
  title_text_st2: {
    fontSize: 18,
    fontWeight: "500",
    color: "#CA57DB",
  },
  popular_post_title_text: {
    ...Texts.contents_text_33,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    zIndex: 10,
  },
  title_background: {
    ...Spacings.border_radius_6,
    ...Colors.background_f7e237,
    ...Positions.absolute,
    ...Positions.bottom,
    left: -4,
    right: -4,
    height: 14,
  },
  popular_post_list: {
    ...Spacings.margin_top_14,
  },
};
