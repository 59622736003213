import { observer } from "mobx-react";
import React, { useState } from "react";
import { ReactComponent as PlusPurpleIcon } from "../../../assets/icons/plus_purple.svg";
import { ReactComponent as MinusPurpleIcon } from "../../../assets/icons/minus_purple.svg";
import RelatedProductSlide from "./RelatedProductSlide";
import { Tag, TagDetail } from "../../../api/tag/model";
import { getNumberFormatKM } from "../../../utils/number";
import TagStore from "../../../store/TagStore";
import AuthStore from "../../../store/AuthStore";
import { NotificationModal } from "../../../components/modals";
import { useTranslation } from "react-i18next";
import { ActHistoryRequest } from "../../../api/data/model";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import MainStore from "../../../store/MainStore";
import { MemberTagItem } from "../../../api/member/model";
import { showBottomToast } from "../../../utils/Toast";
import { bottomOffset } from "../../../utils/common";
import CommonStore from "../../../store/CommonStore";
import "./TagMainForeground.scss";

const TagMainForeground = observer(
  ({ tagDetail }: { tagDetail: TagDetail }) => {
    const { t } = useTranslation();
    const { sessionUser } = AuthStore;
    const memberUuid = String(sessionUser?.memberUuid);
    const [showOverPopup, setShowOverPopup] = useState(false);
    const tag: Tag = {
      tagId: tagDetail.tagId,
      tagName: tagDetail.tagName,
    };

    const onAddToast = async () => {
      const tagDetailStore = TagStore.getTagDetailStore(tagDetail.tagId);

      if (tagDetailStore != undefined) {
        if (tagDetail.interestedTagList.length >= 20) {
          setShowOverPopup(true);
        } else {
          await tagDetailStore.addInterestedTag(memberUuid, tag);

          MainStore.addInterestedTag(tag as MemberTagItem);

          const actHistory: ActHistoryRequest = {
            actHistoryTypeCode: ActHistoryTypeCode.TAG_FOLLOW,
            actHistoryTargetId: tagDetail.tagId.toString(),
            actPageCode: ActPageCode.TAG,
            attr1: tagDetail.count.toString(),
          };
          void sendActHistory(actHistory);

          showBottomToast(
            t("screen.tag.message.addInterestTag", {
              tagName: tagDetail.tagName,
            }),
            bottomOffset()
          );
        }
      }
    };

    const onDeleteToast = async () => {
      const tagDetailStore = TagStore.getTagDetailStore(tagDetail.tagId);

      if (tagDetailStore != undefined) {
        if (tagDetail.interestedTagList.length == 1) {
          showBottomToast(
            t("screen.tagManagement.toast.cannotDelete"),
            bottomOffset()
          );
        } else {
          await tagDetailStore.removeInterestedTag(memberUuid, tagDetail.tagId);

          // MainProfile의 태그리스트 업데이트
          MainStore.removeInterestedTag(tag as MemberTagItem);

          //PPIT210117-4818 TAG_FOLLOW 삭제 시 actHistory 처리 추가
          const actHistory: ActHistoryRequest = {
            actHistoryTypeCode: ActHistoryTypeCode.TAG_FOLLOW,
            actHistoryTargetId: tagDetail.tagId.toString(),
            actPageCode: ActPageCode.TAG,
            attr1: tagDetail.count.toString(),
            attr6: "CANCEL",
          };
          void sendActHistory(actHistory);

          showBottomToast(
            t("screen.tag.message.deletedInterestTag", {
              tagName: tagDetail.tagName,
            }),
            bottomOffset()
          );
        }
      }
    };

    return (
      tagDetail && (
        <div id="tag-main-foreground">
          <div className="contents_header">
            <div className="contents_header_text_area">
              <div className="contents_header_text1">
                <span className="contents_header_text2">
                  {getNumberFormatKM(tagDetail.count)}
                </span>{" "}
                {t("screen.tag.subTitle")}
              </div>
              <div
                className="contents_header_text3"
              >{`#${tagDetail.tagName}`}</div>
            </div>
            {tagDetail.interestedTagYn === "N" ? (
              <div
                className="tag_btn add"
                onClick={() => {
                  void onAddToast();
                }}
              >
                <PlusPurpleIcon />
                <span className="add_tag_btn_text">
                  {t("screen.tag.message.addInterestTag", {
                    tagName: tagDetail.tagName,
                  })}
                </span>
              </div>
            ) : (
              <div
                className="tag_btn delete"
                onClick={() => {
                  void onDeleteToast();
                }}
              >
                <MinusPurpleIcon />
                <span className="delete_tag_btn_text">
                  {t("screen.tag.message.deletedInterestTag", {
                    tagName: tagDetail.tagName,
                  })}
                </span>
              </div>
            )}
          </div>
          {tagDetail.relatedProductList?.length > 0 && (
            <div className="related-product-slide-wrapper">
              <RelatedProductSlide
                list={tagDetail.relatedProductList}
                totalCount={tagDetail.relatedProductCount}
                productId={tagDetail.productId}
                productName={tagDetail.tagName}
              />
            </div>
          )}
          {showOverPopup && (
            <NotificationModal
              isVisible={showOverPopup}
              useTwoButton={true}
              isBlack={true}
              contents1={t("screen.tag.message.overInterestTag")}
              defaultButtonText={t("screen.notificationModal.button.confirm")}
              extraButtonText={t("screen.notificationModal.button.cancel")}
              textAlign="center"
              onClickDefaultButton={() => {
                // goTo("TagManagementScreen");
                setShowOverPopup(false);
                CommonStore.setShowDownloadAppDrive(true);
              }}
              onClickExtraButton={() => {
                setShowOverPopup(false);
              }}
              onRequestClose={() => {
                setShowOverPopup(false);
              }}
            />
          )}
        </div>
      )
    );
  }
);

export default TagMainForeground;
