import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NotificationModal } from "../../components/modals";
import { goBack } from "../../hooks/navigate/NavigateFunction";
import { TitleHeader } from "../../components/common";
import ArticleDetail from "./presenter/ArticleDetail";
import ArticleDetailStore from "../../store/ArticleDetailStore";
import ArticleDisclosureModal from "../brand/presenter/ArticleDisclosureModal";
import UploadStore from "../../store/upload/UploadStore";
import CommonStore from "../../store/CommonStore";
import AuthStore from "../../store/AuthStore";

const ArticleDetailScreen = observer(function ArticleDetailScreen(data: any) {
  const location = useLocation();
  const params = location.state;
  const articleId = ((useParams().articleId) || (params.feedId as number)) as number;

  const { t } = useTranslation();

  const [isInit, setInit] = useState(false);
  const [isShowBlockedModal, setIsShowBlockedModal] = useState(false);
  const [isShowDisclosurePopup, setIsShowDisclosurePopup] = useState<boolean>(false);

  const init = () => {
    CommonStore.setLoading(true);
    ArticleDetailStore.clearArticleDetailStore();
    void ArticleDetailStore.getArticleDetail(articleId as number)
      .then((res) => {
        if (!res) {
          setIsShowBlockedModal(true);
        } else if (
          ArticleDetailStore.articleDetail.corpMemberYn === "N" &&
          ArticleDetailStore.articleDetail.articleDisclosureYn === "N" &&
          ArticleDetailStore.articleDetail.createMemberUuid !== AuthStore.sessionUser?.memberUuid
        ) {
          setIsShowDisclosurePopup(true);
        }
      })
      .finally(() => {
        CommonStore.setLoading(false);
      });
  };

  /* eslint-disable */
  useEffect(() => {
    void init();
    setInit(true);
  }, [articleId, params]);

  useEffect(() => {
    isInit && UploadStore.isDefaultStatus && void init();
  }, [UploadStore.status]);
  /* eslint-enable */

  return (
    <div style={{ flex: 1, backgroundColor: "white" }}>
      {!CommonStore.loading && !isShowDisclosurePopup && !isShowBlockedModal && (
        <>
          <ArticleDetail articleId={articleId} />
        </>
      )}

      {(isShowDisclosurePopup || isShowBlockedModal) && (
        <div>
          <TitleHeader title={t("screen.article.title.detail")} isBack={true} isHome />

          {isShowBlockedModal && (
            <NotificationModal
              isVisible={isShowBlockedModal}
              contents1={t("common.message.blockedOrDeleted")}
              onRequestClose={() => {
                setIsShowBlockedModal(false);
                goBack();
              }}
              defaultButtonText={t("common.label.check")}
            />
          )}

          {isShowDisclosurePopup && (
            <ArticleDisclosureModal
              isVisible={isShowDisclosurePopup}
              contents1={t("screen.notificationModal.message.disclosureArticleText1")}
              contents2={t("screen.notificationModal.message.disclosureArticleText2")}
              textAlign="center"
              onRequestClose={() => {
                setIsShowDisclosurePopup(false);
                goBack();
              }}
            />
          )}
        </div>
      )}
    </div>
  );
});

export default ArticleDetailScreen;
