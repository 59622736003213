import { action, computed, makeObservable, observable } from "mobx";
import { recommenderExist, recommenderValid } from "../api/mission/api";
import { validateRecommenderNicknameFormat } from "../utils/nickname";

class RecommenderStore {
  @observable _recommenderNickname = "";
  @observable _recommenderValidStatusCode = "";
  @observable _isRecommenderNicknameValid = false;
  @observable _isRecommenderNicknameComplete = true;
  @observable _recommenderYn = "N";

  constructor() {
    makeObservable(this);
  }

  @computed get recommenderNickname() {
    return this._recommenderNickname;
  }
  @computed get isRecommenderNicknameValid() {
    return this._isRecommenderNicknameValid;
  }
  @computed get isRecommenderNicknameComplete() {
    return this._isRecommenderNicknameComplete;
  }
  @computed get recommenderValidStatusCode() {
    return this._recommenderValidStatusCode;
  }
  @computed get recommenderYn() {
    return this._recommenderYn;
  }

  @action setRecommenderYn = (YN: string) => {
    this._recommenderYn = YN;
  };
  @action setIsRecommenderNicknameComplete = (
    isRecommenderNicknameComplete: boolean
  ) => {
    this._isRecommenderNicknameComplete = isRecommenderNicknameComplete;
  };

  @action setIsRecommenderNicknameValid = (
    isRecommenderNicknameValid: boolean
  ) => {
    this._isRecommenderNicknameValid = isRecommenderNicknameValid;
  };

  @action setRecommenderNickname = (nickname: string) => {
    this._recommenderNickname = nickname;
    const isNicknameValid = validateRecommenderNicknameFormat(nickname);
    this.setIsRecommenderNicknameValid(isNicknameValid);
    this.setIsRecommenderNicknameComplete(
      nickname.length == 0 || isNicknameValid ? true : false
    );
    this.setRecommenderValidStatusCode("");
  };

  @action resetRecommenderNickname = () => {
    void this.setRecommenderNickname("");
  };

  @action setRecommenderValidStatusCode = (statusCode: string) => {
    this._recommenderValidStatusCode = statusCode;
  };

  @action chackRecommenderValid = async () => {
    const res = await recommenderValid(this._recommenderNickname);
    if (res && res.successOrNot === "Y" && res.data) {
      this.setIsRecommenderNicknameComplete(true);
    } else {
      this.setIsRecommenderNicknameComplete(false);
      this.setRecommenderValidStatusCode(res.statusCode);
    }
  };

  @action recommenderExist = async () => {
    const res = await recommenderExist();
    if (res && res.successOrNot === "Y") {
      this._recommenderYn = res.data.recommenderYn;
    } else {
      this._recommenderYn = "N";
    }
  };
}

export default new RecommenderStore();
