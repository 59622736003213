import { observer } from 'mobx-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { HeaderItem } from '../../../constants/Header'
import TitleHeader from '../../../components/common/TitleHeader'
import CategoryHeader from './CategoryHeader'
import CategoryList from './CategoryList'
import FilterList from './FilterList'
import FilterSticky from './FilterSticky'
import ProductList from './ProductList'
import {
  Attribute_vlues,
  Attributes,
  CategoryFilterProduct,
  CategoryScreenType,
  FilterProductRequest,
  LifecarePopularProductRequest,
  SearchAttributes,
  SortType,
} from '../../../api/mallCategory/model'
import {
  getLifecarePopularProduct,
  mallFilterProduct,
} from '../../../api/mallCategory/api'
import AuthStore from '../../../store/AuthStore'
import { DisplayProduct } from '../../../api/mall/model'
import BookmarkStore from '../../../store/BookmarkStore'
import { FeedTypeCode } from '../../../constants/Feed'
import {
  goToLifecareCartPage,
  goToLifecareProductPage,
  goToLifecareSearchPage,
  goToLifecareSearchResultPage,
} from '../../../utils/mall'
import CommonStore from '../../../store/CommonStore'
import TouchableWithAuthCheck from '../../../components/common/TouchableWithAuthCheck'
import { track } from '../../../hooks/tracker/TrackFunction'
import { ActHistoryRequest } from '../../../api/data/model'
import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from '../../../constants/ActHistory'
import { sendActHistory } from '../../../api/data/api'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MallStore from '../../../store/MallStore'
import ListWithStickyTabs from '../../../components/common/ListWithStickyTabs'
import { VirtuosoHandle } from 'react-virtuoso'
import classes from './MallCategoryForm.module.scss'
import SelectFilterList from './SelectFilterList'
import { useDebounce, useWindowScroll } from 'react-use'
import MainTabNavigator from '../../../components/common/MainTabNavigator'

const MallCategoryForm = observer(({ caregoryId }: { caregoryId: number }) => {
  const filterListRef = useRef<HTMLDivElement>(null)
  const [isFirstInit, setFirstInit] = useState(true)
  const stickyFilterRef = useRef<VirtuosoHandle>(null)
  const [productLoading, setProductLoading] = useState(false)

  const [showFilterList, setShowFilterList] = useState(false)
  const [showFilterToggleBtn, setShowFilterToggleBtn] = useState(false)

  const [popularityProductList, setPopularityProductList] = useState<
    DisplayProduct[]
  >([])
  const { y } = useWindowScroll()

  useDebounce(
    () => {
      const positionFilterList =
        filterListRef.current?.getBoundingClientRect().bottom
      if (positionFilterList === undefined) return
      if (positionFilterList < 60) {
        setShowFilterToggleBtn(true)
      } else {
        setShowFilterToggleBtn(false)
        setShowFilterList(false)
      }
    },
    100,
    [y]
  )

  const [sortType, setSortType] = useState<SortType>(SortType.POPULAR)
  const [productPageIndex, setProductPageIndex] = useState<number>(0)
  const [productPageSize, setProductPageSize] = useState<number>(10)
  const [searchAttributes, setSearchAttributes] = useState<SearchAttributes>({})
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [categoryInfo, setCategoryInfo] = useState<CategoryFilterProduct>()
  const [attributes, setAttributes] = useState<Attributes[]>()
  const [productList, setProductList] = useState<DisplayProduct[]>([])
  const [currentCategory, setCurrentCategory] = useState<CategoryScreenType>(
    CategoryScreenType.L
  )
  const [isFilterChange, setIsFilterChange] = useState(false)
  const [selectFilterList, setSelectFilterList] = useState<Attribute_vlues[]>()
  const [totalProductCount, setTotalProductCount] = useState<number>(0)

  const [dataChangeToggle, setDataChangeToggle] = useState<boolean>(false)
  const { saveBookmark, cancelBookmark } = BookmarkStore

  const getParsedCategoryHeader = () => {
    let categoryStr = categoryInfo?.largeDisplayCategoryName.toString()
    if (!categoryStr) {
      return
    }
    if (categoryInfo?.mediumDisplayCategoryName) {
      categoryStr = `${categoryStr}>${categoryInfo.mediumDisplayCategoryName}`
    }
    if (categoryInfo?.smallDisplayCategoryName) {
      categoryStr = `${categoryStr}>${categoryInfo.smallDisplayCategoryName}`
    }
    return categoryStr
  }

  const goToMallCategoryScreen = () => {
    navigate('/mall')
  }

  const goToCategoryScreen = (
    type: CategoryScreenType,
    caregoryId: number,
    categoryName: string
  ) => {
    CommonStore.setLoading(true)
    let categoryStr = categoryInfo?.largeDisplayCategoryName.toString() ?? ''
    if (type === CategoryScreenType.L) {
      init()
      navigate(`/mall/category/large/${caregoryId}`)
    }
    if (type === CategoryScreenType.M) {
      categoryStr = `${categoryStr}>${categoryName}`
      navigate(`/mall/category/medium/${caregoryId}`)
    }
    if (type === CategoryScreenType.S) {
      categoryStr = `${categoryStr}>${
        categoryInfo?.mediumDisplayCategoryName ?? ''
      }>${categoryName}`
      navigate(`/mall/category/small/${caregoryId}`)
    }

    const log: ActHistoryRequest = {
      actHistoryTypeCode: ActHistoryTypeCode.PAGE_CLICK,
      actHistoryTargetId: ActHistoryTargetId.MALL_CATEGORY,
      actPageCode: ActPageCode.MALL_CATEGORY_DETAIL,
      actSectionCode: ActSectionCode.CATEGORY,
      attr1: '',
      attr2: categoryStr,
    }
    void sendActHistory(log)
  }

  const goToProductDetail = (item: DisplayProduct) => {
    track('click_mall_category', {
      product_id: item.productId,
      product_name: item.productName,
      category_name: categoryInfo?.largeDisplayCategoryName ?? '',
    })

    let sort = ''
    if (sortType === SortType.LATEST) {
      sort = '최신순'
    }
    if (sortType === SortType.SALES) {
      sort = '판매량순'
    }
    if (sortType === SortType.POPULAR) {
      sort = '인기순'
    }

    const filterJson: any = {}
    selectFilterList?.forEach((data) => {
      const type = data.attributeName
      if (type) {
        if (!filterJson[type]) {
          filterJson[type] = [data.attributeValueName]
        } else {
          filterJson[type].push(data.attributeValueName)
        }
      }
    })

    const log: ActHistoryRequest = {
      actHistoryTypeCode: ActHistoryTypeCode.MALL_PRODUCT_READ,
      actHistoryTargetId: item.lifecareProductId.toString(),
      actPageCode: ActPageCode.MALL_MAIN,
      actSectionCode: ActSectionCode.CATEGORY,
      attr1: item.productId.toString(),
      attr2: item.sortOrder?.toString() ?? '',
      attr3: item.consumerPrice?.toString(),
      attr4: item.salesPrice?.toString(),
      attr5: item.discountRate?.toString(),
      attr6: item.averageStar?.toString(),
      attr7: item.reviewTotCnt?.toString(),
      attr8: getParsedCategoryHeader(),
      attr9: sort,
      attr10: filterJson,
    }

    void sendActHistory(log)

    goToLifecareProductPage(item.lifecareProductId)
  }

  const goToTopFilter = (index: number) => {
    // TODO
    const positionFilterList =
      filterListRef.current?.getBoundingClientRect().bottom || 0
    showFilterList && positionFilterList < 60 && setShowFilterList(false)
    setTimeout(() => {
      stickyFilterRef?.current?.scrollToIndex(index)
    }, 500)
  }

  const resetFilter = () => {
    setSelectFilterList([])
    attributes?.forEach((items) => {
      items.attributeValueList.forEach((item) => {
        item.check = false
      })
    })
    setSearchAttributes({})
    setProductPageIndex(0)

    setIsFilterChange(!isFilterChange)
  }

  const selectFilter = (key: number, name: string, item: Attribute_vlues) => {
    goToTopFilter(1)

    // 선택한 필터 api 호출 param
    const temp = searchAttributes
    if (temp[key]) {
      const index = temp[key].findIndex((x) => x === item.attributeValueId)
      index > -1
        ? temp[key].splice(index, 1)
        : temp[key].push(item.attributeValueId)
    } else {
      temp[key] = [item.attributeValueId]
    }
    setSearchAttributes(temp)

    // 선택한 필터 표시
    const att = attributes?.filter((x) => x.attributeId === key)
    if (att && att?.length > 0) {
      const res = att[0].attributeValueList?.filter(
        (x) => x.attributeValueId === item.attributeValueId
      )
      if (res[0].check) res[0].check = !res[0].check
      else res[0]['check'] = true
    }

    // 선택한 필터 스티키 리스트
    const findeIndex = selectFilterList?.findIndex((findItem) => {
      return findItem.attributeValueId === item.attributeValueId
    })
    if (findeIndex !== undefined && findeIndex > -1) {
      const selectFilterListTemp = selectFilterList
      selectFilterListTemp?.splice(findeIndex, 1)
      setSelectFilterList(selectFilterListTemp)
    } else {
      setSelectFilterList((x) => {
        return x
          ? [...x, { parentId: key, attributeName: name, ...item }]
          : [{ parentId: key, attributeName: name, ...item }]
      })
    }
    setProductPageIndex(0)
    setIsFilterChange((x) => !x)
  }

  // [5239] 인기제품 영역 미노출
  const getPopularityProduct = async () => {
    const params: LifecarePopularProductRequest = {
      displayCategoryId: caregoryId,
      cursor: 0,
      pageSize: 10,
    }
    const res = await getLifecarePopularProduct(params)
    setPopularityProductList(res)
  }

  const getFilterProduct = async (first?: boolean) => {
    const params: FilterProductRequest = {
      memberUuid: AuthStore.sessionUser?.memberUuid
        ? AuthStore.sessionUser?.memberUuid
        : AuthStore.tempMember?.tempMemberUUID || '',
      sort: sortType,
      pageIndex: productPageIndex,
      pageSize: productPageSize,
      displayCategoryId: caregoryId,
      searchAttributes: searchAttributes,
    }
    const res = await mallFilterProduct(params)
    if (res != null) {
      res.totalProductCount &&
        res.totalProductCount !== null &&
        setTotalProductCount(res.totalProductCount)

      first && res.attributes && setAttributes(res.attributes)

      if (productPageIndex === 0) {
        if (res.productList !== null) {
          const productList = res.productList.map((item, index) => {
            return { ...item, sortOrder: index + 1 }
          })
          setProductList(productList)
        } else {
          setProductList([])
        }
      } else {
        if (res.productList !== null) {
          const count = productList.length
          const pagedProductList = res.productList.map((item, index) => {
            return { ...item, sortOrder: count + index + 1 }
          })
          setProductList([...productList, ...pagedProductList])
        }
      }

      setCategoryInfo(res)

      if (res.smallDisplayCategoryId && res.smallDisplayCategoryId !== null) {
        setCurrentCategory(CategoryScreenType.S)
      } else if (
        res.mediumDisplayCategoryId &&
        res.mediumDisplayCategoryId !== null
      ) {
        setCurrentCategory(CategoryScreenType.M)
      } else {
        setCurrentCategory(CategoryScreenType.L)
      }
    }

    setProductPageIndex(productPageIndex + 1)
    setProductLoading(false)
    CommonStore.setLoading(false)
  }

  const handleBookmark = async (
    bookmarkYn: string,
    targetId: number,
    listKey?: string
  ) => {
    if (bookmarkYn === 'N') {
      await cancelBookmark(
        targetId,
        FeedTypeCode.PRODUCT,
        () => {
          if (listKey === 'PopularityProductCarousel') {
            const temp = popularityProductList
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setPopularityProductList(temp)
          }
          if (listKey === 'MallCategoryProductList' && productList) {
            const temp = productList
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'N'
            })
            setProductList(temp)
          }
        },
        () => {
          // fail
        }
      )
    } else {
      await saveBookmark(
        targetId,
        FeedTypeCode.PRODUCT,
        () => {
          if (listKey === 'PopularityProductCarousel') {
            const temp = popularityProductList
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'Y'
            })
            setPopularityProductList(temp)
          }
          if (listKey === 'MallCategoryProductList' && productList) {
            const temp = productList
            temp.forEach((item, index) => {
              if (targetId === item.productId) item.bookmarkYn = 'Y'
            })
            setProductList(temp)
          }
        },
        () => {
          // fail
        }
      )
    }
    setDataChangeToggle((x) => !x)
  }

  const init = () => {
    CommonStore.setLoading(true)
    setFirstInit(true)
    goToTopFilter(0)
    resetFilter()
  }

  /* eslint-disable */
  useEffect(() => {
    CommonStore.setLoading(false)
    if (MallStore.isMallCateforyFilterReset) {
      // 동일한 카테고리로 재진입시 필터 초기화 처리
      init()
      MallStore.setIsMallCateforyFilterReset(false)
    }
  }, [])

  useEffect(() => {
    init()
  }, [caregoryId])

  useEffect(() => {
    if (isFirstInit) {
      void getFilterProduct(true)
      setFirstInit(false)
    } else {
      void getFilterProduct()
    }
  }, [isFilterChange])
  /* eslint-enable */

  const renderTitle = useCallback(() => {
    if (currentCategory === CategoryScreenType.S)
      return categoryInfo?.smallDisplayCategoryName || ''
    else if (currentCategory === CategoryScreenType.M)
      return categoryInfo?.mediumDisplayCategoryName || ''
    else return categoryInfo?.largeDisplayCategoryName || ''
  }, [categoryInfo, currentCategory])

  const onEndReached = async () => {
    if (
      !productLoading &&
      productList &&
      totalProductCount !== productList?.length
    ) {
      setProductLoading(true)
      await getFilterProduct()
    }
  }

  return !CommonStore.loading && categoryInfo ? (
    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
      <TitleHeader
        title={renderTitle()}
        hasSearch
        onClickSearch={() => goToLifecareSearchPage()}
        rightItem={HeaderItem.CART}
        onClickCart={() => {
          goToLifecareCartPage()
        }}
      />
      <ListWithStickyTabs
        aria-label="mallcategory listwithstickytabs"
        isShowSpacer={false}
        contentScrollRef={stickyFilterRef}
        scrollableHeader={
          <>
            <CategoryHeader
              renderData={categoryInfo}
              goToMallCategoryScreen={goToMallCategoryScreen}
              goToCategoryScreen={goToCategoryScreen}
            />
            {currentCategory !== CategoryScreenType.S &&
              categoryInfo.childDisplayCategoryIds &&
              categoryInfo.childDisplayCategoryIds?.length > 0 && (
                <CategoryList
                  currentCategory={currentCategory}
                  renderData={categoryInfo.childDisplayCategoryIds}
                  goToCategoryScreen={goToCategoryScreen}
                />
              )}
            <div className={classes.line_row}></div>

            {/* [5239] 인기제품 영역 미노출  */}
            {/* {popularityProductList.length > 0 && (
              <PopularityProductCarousel
                renderData={popularityProductList}
                onPress={goToProductDetail}
                listKey={"PopularityProductCarousel"}
                handleBookmark={(bookmarkYn: string, targetId: number, listKey?: string) =>
                  void handleBookmark(bookmarkYn, targetId, listKey)
                }
                dataChangeToggle={dataChangeToggle}
              />
            )} */}
            {/* <View className={classes.line_row_10}></View> */}

            {
              <div ref={filterListRef}>
                {!showFilterList &&
                  attributes &&
                  attributes.length > 0 &&
                  currentCategory !== CategoryScreenType.L && (
                    <div>
                      <FilterList
                        renderData={attributes}
                        selectFilter={selectFilter}
                        isFilterChange={isFilterChange}
                      />
                      <SelectFilterList
                        selectFilterList={selectFilterList}
                        selectFilter={selectFilter}
                        resetFilter={resetFilter}
                      />
                    </div>
                  )}
              </div>
            }
          </>
        }
        stickyItems={
          attributes &&
          attributes.length > 0 &&
          currentCategory !== CategoryScreenType.L ? (
            <FilterSticky
              showFilterList={showFilterList}
              setShowFilterList={(x: boolean) => setShowFilterList(x)}
              showFilterToggleBtn={showFilterToggleBtn}
              resetFilter={() => {
                goToTopFilter(1)
                resetFilter()
              }}
              renderData={attributes}
              selectFilter={selectFilter}
              isFilterChange={isFilterChange}
              selectFilterList={selectFilterList}
            />
          ) : (
            <></>
          )
        }
        showScrollToTop={true}
        onScroll={(e) => {
          const position = e.currentTarget.scrollHeight
          const half = window.innerHeight / 2

          if (position > half) {
            setShowFilterToggleBtn(true)
          } else {
            setShowFilterToggleBtn(false)
          }
        }}
      />
      <div
        aria-label="mallcategoryform footer"
        className={classes.mall_category_form_footer}
      >
        {productList && productList !== null && productList?.length > 0 ? (
          <ProductList
            sortType={sortType}
            changeSortType={(x) => {
              setProductPageIndex(0)
              setSortType(x)
              setIsFilterChange((x) => !x)
            }}
            renderData={productList}
            onPress={goToProductDetail}
            totalProductCount={totalProductCount}
            productLoading={productLoading}
            listKey={'MallCategoryProductList'}
            endReached={() => void onEndReached()}
            handleBookmark={(
              bookmarkYn: string,
              targetId: number,
              listKey?: string
            ) => void handleBookmark(bookmarkYn, targetId, listKey)}
            dataChangeToggle={dataChangeToggle}
          />
        ) : (
          <div>
            <div className={classes.line_row_10} />
            <div className={classes.bx_pd_none_wrap}>
              <text className={classes.txt_pd_none}>
                {t('screen.mall.message.noSearchResult')}
              </text>
              <TouchableWithAuthCheck
                onPress={() => goToLifecareSearchResultPage(renderTitle())}
              >
                <div className={classes.bx_btn_more}>
                  <text className={classes.txt_btn_more}>
                    {t('screen.mall.button.goToMoreProduct')}
                  </text>
                </div>
              </TouchableWithAuthCheck>
            </div>
          </div>
        )}
      </div>
      <MainTabNavigator />
    </div>
  ) : (
    <></>
  )
})

export default MallCategoryForm
