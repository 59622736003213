import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  Arranges,
  FlexBoxs,
  Positions,
  Spacings,
  Texts,
  Colors,
} from "../../../assets/styles";
import { Styles } from "../../../assets/types/Style";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import AuthStore from "../../../store/AuthStore";
import { TagFeedItem } from "../../../api/tag/model";
import EmptyFeed from "./EmptyFeed";
import { FeedTypeCode } from "../../../constants/Feed";
import {
  border_bottom_left_radius_6,
  border_bottom_right_radius_6,
  border_top_left_radius_6,
  border_top_right_radius_6,
} from "../../../assets/styles/spacings";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CustomActivityIndicator from "../../../components/common/CustomActivityIndicatorSmall";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";
import { absolute } from "../../../assets/styles/positions";
import TagDetailStore from "../../../store/TagDetailStore";
import "./TagAbComponent.scss";
import clsx from "clsx";

const TagAbComponent = observer(
  ({
    dataRefresh,
    abList,
    tagName,
    isProduct,
    loading,
  }: {
    dataRefresh?: () => void;
    abList: TagFeedItem[];
    abPageIndex: number;
    tagName: string;
    isProduct: boolean;
    loading: boolean;
  }) => {
    const { t } = useTranslation();
    const { sessionUser } = AuthStore;
    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

    const moveToDetail = (feedId: number, index: number, item: TagFeedItem) => {
      goTo(`/AB/${feedId}`, {
        state: {
          feedId: feedId,
          userId: sessionUser?.memberUuid,
          index: index,
        },
      });

      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.AB_READ,
        actHistoryTargetId: feedId.toString(),
        actPageCode: isProduct
          ? ActPageCode.TAG_PRODUCT
          : ActPageCode.TAG_GENERAL,
        actSectionCode: ActSectionCode.AB,
        attr1: index.toString(),
        attr2: item.processStatus,
        attr3: item.voterCount?.toString(),
      };
      void sendActHistory(actHistory);
    };

    const renderItem = ({
      item,
      index,
    }: {
      item: TagFeedItem;
      index: number;
    }) => {
      const isOver = item.processStatus === "PROCESSING";

      return (
        <div className="ab_item" aria-label={`Tag AB List Item ${index}`}>
          <div
            className="ab_item_header"
            aria-label={`Tag AB List Item ${index} Header`}
          >
            <div className="ab_item_header_left">
              <span
                className="ab_item_header_text"
                aria-label={`Tag AB List Item ${index} Header Nickname`}
              >
                {item.nickname}
              </span>
              <div className="text_bar"/>
              <span
                className="ab_item_header_text"
                aria-label={`Tag AB List Item ${index} Header Voter`}
              >
                {t(`screen.myHome.label.attend`)} {item.voterCount}
              </span>
            </div>
            <div
              className={clsx("ab_item_header_btn", isOver ? "progress_btn" : "terminated_btn")}
              aria-label={`Tag AB List Item ${index} ProcessStatus`}
            >
              <span
                className={clsx(
                  "ab_item_header_btn_text",
                  isOver ? "progress_btn_text" : "terminated_btn_text"
                )}
              >
                {isOver
                  ? t(`screen.myHome.label.processing`)
                  : t(`screen.myHome.label.done`)}
              </span>
            </div>
          </div>
          <div
            onClick={() => moveToDetail(item.feedId, index, item)}
            aria-label={`Tag AB List Item ${index} Title`}
          >
            <span className="ab_main_text">{item.feedTitle}</span>
          </div>
          <div className="ab_select_wrap">
            <div
            
              className="ab"
              onClick={() => moveToDetail(item.feedId, index, item)}
              aria-label={`Tag AB List Item ${index} A Button`}
            >
              <FastImageWithFallback
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${
                    item.athumbnailFilePath != undefined
                      ? item.athumbnailFilePath
                      : ""
                  }`,
                }}
                wrapperClassName="select_image_wrapper"
                className={clsx("select_image", "select_left_text_btn", "select_left_inner")}
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                  item.aoriginalFilePath || ""
                }`}
                renderFallbackNode={({ children }) => {
                  return <div className="select_image">{children}</div>;
                }}
              />
              <div className={clsx(
                "inner_wrapper left",
                item.myAbFlag === "A" && "selected"
              )}>
                <div
                  // style={{
                  //   ...styles.inner,
                  //   ...border_bottom_left_radius_6,
                  //   ...border_top_left_radius_6,
                  //   ...(item.myAbFlag === "A" && styles.selected),
                  // }}
                  className={clsx("inner", "left", item.myAbFlag === "A" && "selected")}
                />
                <span className="ab_text1">
                  {t(`screen.myHome.label.a`)}
                </span>
                <span
                
                  className="ab_text2"
                  aria-label={`Tag AB List Item ${index} A Desc`}
                >
                  {item.adesc}
                </span>
              </div>
            </div>
            <div
            
              className="ab"
              onClick={() => {
                moveToDetail(item.feedId, index, item);
              }}
              aria-label={`Tag AB List Item ${index} B Button`}
            >
              <FastImageWithFallback
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${
                    item.bthumbnailFilePath != undefined
                      ? item.bthumbnailFilePath
                      : ""
                  }`,
                }}
                // style={{
                //   ...styles.select_image,
                //   ...styles.select_right_text_btn,
                //   ...styles.select_right_inner,
                // }}
                wrapperClassName="select_image_wrapper"
                className={clsx("select_image", "select_right_text_btn", "select_right_inner")}
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                  item.boriginalFilePath || ""
                }`}
                renderFallbackNode={({ children, style }) => {
                  return <div className="select_image">{children}</div>;
                }}
              />
              <div className={clsx(
                "inner_wrapper right",
                item.myAbFlag === "B" && "selected"
              )}>
                <div
                  // style={{
                  //   ...styles.inner,
                  //   ...border_top_right_radius_6,
                  //   ...border_bottom_right_radius_6,
                  //   ...(item.myAbFlag === "B" && styles.selected),
                  // }}
                  className={clsx("inner", "right", item.myAbFlag === "B" && "selected")}
                />
                <span className="ab_text1">
                  {t(`screen.myHome.label.b`)}
                </span>
                <span
                
                  className="ab_text2"
                  aria-label={`Tag AB List Item ${index} B Desc`}
                >
                  {item.bdesc}
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="tag-ab-component">
        <div
          aria-label={`Tag AB List`}
          // style={{ ...(abList.length > 0 && styles.ab_list) }}
        >
          {abList && abList.length > 0 ? (
            abList.map((item, index) => {
              return renderItem({ item, index });
            })
          ) : loading ? (
            <></>
          ) : (
            <EmptyFeed
              tagName={tagName}
              type={FeedTypeCode.AB}
              dataRefresh={() => {
                dataRefresh && dataRefresh();
              }}
            />
          )}
        </div>
        {loading && <CustomActivityIndicatorSmall />}
      </div>
    );
  }
);

export default TagAbComponent;

const styles: Styles = {
  tag_ab: {
    ...Spacings.padding_top_10,
    ...Spacings.padding_right_left_16_responsive,
    ...Colors.background_255,
  },
  empty: {},
  empty_image: {
    width: "100%",
  },
  empty_alert: {
    ...Positions.fullscreen,
    ...Arranges.center_v_h,
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  empty_btn: {
    display: "flex",
    ...Arranges.center_v_h,
    ...Spacings.margin_top_14,
    ...Spacings.border_radius_300,
    ...Colors.border_color_a760b1,
    borderWidth: 1,
    width: 157,
    height: 44,
  },
  empty_btn_text: {
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_a760b1,
  },

  ab_list: {},
  ab_item: {
    ...Spacings.padding_bottom_32,
    ...Spacings.margin_bottom_35,
    ...Colors.border_color_f5f5f5,
    borderBottomWidth: 6,
  },
  last_item: {
    borderBottomWidth: 0,
  },
  ab_item_header: {
    display: "flex",
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  ab_item_header_left: {
    display: "flex",
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  ab_item_header_text: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_999,
  },
  text_bar: {
    width: 1,
    height: 12,
    ...Spacings.margin_right_left_5,
    ...Colors.background_eeeeee,
  },
  ab_item_header_btn: {
    display: "flex",
    ...Spacings.border_radius_4,
    ...Arranges.center_v_h,
    width: 43,
    height: 22,
  },
  progress_btn: {
    ...Colors.background_f7e237,
  },
  progress_btn_text: {
    ...Colors.font_222,
  },
  terminated_btn: {
    ...Colors.background_c4c4c4,
  },
  terminated_btn_text: {
    ...Colors.font_fff,
  },
  ab_item_header_btn_text: {
    ...Texts.contents_text_47,
  },
  ab_main_text: {
    ...Spacings.margin_top_10,
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_222,
  },
  ab_select_wrap: {
    display: "flex",
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.margin_top_24,
  },
  ab: {
    position: "relative",
    width: 205,
    height: 205,
  },
  select_image: {
    display: "flex",
    width: 205,
    height: 205,
    ...Arranges.center_v_h,
  },
  inner_wrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    ...Colors.background_222,
    opacity: 0.5,
  },
  inner: {
    // ...Positions.fullscreen,
  },
  ab_text1: {
    ...Texts.contents_text_39,
    ...Colors.font_fff,
    fontWeight: "bold",
  },
  ab_text2: {
    ...Texts.contents_text_31,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
    ...Spacings.margin_top_10,
    display: "-webkit-box",
    WebkitLineClamp: 2,
  },
  selected: {
    ...Colors.background_a760b1,
  },
  not_selected: {
    ...Colors.background_666,
  },
  select_right_inner: {
    backgroundColor: "rgba(34, 34, 34, 0.4)",
    ...Spacings.border_bottom_right_radius_6,
    ...Spacings.border_top_right_radius_6,
  },
  select_left_inner: {
    backgroundColor: "rgba(34, 34, 34, 0.4)",
    ...Spacings.border_bottom_left_radius_6,
    ...Spacings.border_top_left_radius_6,
  },
  select_left_text_btn: {
    ...Spacings.border_bottom_left_radius_6,
    ...Spacings.border_top_left_radius_6,
  },
  select_right_text_btn: {
    ...Spacings.border_bottom_right_radius_6,
    ...Spacings.border_top_right_radius_6,
  },
};
