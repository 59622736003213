import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import EmptyFeed from "./EmptyFeed";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../../assets/styles";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { FeedTypeCode } from "../../../constants/Feed";
import {
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { TagFeedItem } from "../../../api/tag/model";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { Styles } from "../../../assets/types/Style";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import { absolute } from "../../../assets/styles/positions";
import "./TagQnaComponent.scss";
import CommonStore from "../../../store/CommonStore";
import clsx from "clsx";

const TagQnaComponent = observer(
  ({
    dataRefresh,
    qnaList,
    tagName,
    isProduct,
    loading,
  }: {
    dataRefresh?: () => void;
    qnaList: TagFeedItem[];
    tagName: string;
    isProduct: boolean;
    loading: boolean;
  }) => {
    const { widthApp } = CommonStore;
    const { t } = useTranslation();

    const PURPLE_STYLE_LIST = [
      {
        backgroundColor: "#6c2773",
      },
      {
        backgroundColor: "#a760b1",
      }
    ];
    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);

    const handleQnaPress = (
      feedId: number,
      index: number,
    ) => {
      goTo(`/QnA/${feedId}`, {
        state: {
          feedId: feedId,
          index: index,
          actData: {
            actPageCode: isProduct
              ? ActPageCode.TAG_PRODUCT
              : ActPageCode.TAG_GENERAL,
            actSectionCode: ActSectionCode.QNA,
          },
        },
      });
    };

    const getRandomNumber = () => {
      return Math.floor(Math.random() * 2);
    };

    const renderContent = (item: TagFeedItem) => {
      return (
        <div
          className={clsx(
            "qna-content-wrap",
            item.thumbnailFilePath && "qna-overlay"
          )}
          aria-label={`Tag Qna List Item ${item.feedId} Content`}
        >
          <div
            className="qna-top"
            aria-label={`Tag Qna List Item ${item.feedId} Content Nickname`}
          >
            <span className="nickname">{item.nickname}</span>
            <div
              className="title"
              aria-label={`Tag Qna List Item ${item.feedId} Content Title`}
            >
              <span className="title-text">{item.feedTitle}</span>
            </div>
          </div>
          <div
            className="qna-bottom"
            aria-label={`Tag Qna List Item ${item.feedId} Content CommentCount`}
          >
            <span className="qna-bottom-text">
              {t(`screen.qna.label.comment`)}{" "}
              {item.commentCount ? item.commentCount : 0}
            </span>
          </div>
        </div>
      );
    };

    const renderQna = ({
      item,
      index,
    }: {
      item: TagFeedItem;
      index: number;
    }) => {
      const widthItem = (widthApp - 16 * 2 - 8) / 2 
      return (
        <div
          style={{ width: widthItem, height: widthItem }}
          className="qna-item"
          onClick={() => {
            handleQnaPress(item.feedId, index);
          }}
          aria-label={`Tag Qna List Item ${item.feedId}`}
        >
          {item.thumbnailFilePath ? (
            <div className="qna-item-image">
              <FastImageWithFallback
                wrapperClassName="qna-image-inner"
                style={{ height: widthItem, width: widthItem }}
                className="qna-image"
                source={{
                  uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
                }}
                fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                  item.originalFilePath || ""
                }`}
                renderFallbackNode={({ children }) => {
                  return (
                    <div
                      style={{
                        ...PURPLE_STYLE_LIST[getRandomNumber()],
                      }}
                      className="qna_image"
                    >
                      {children}
                    </div>
                  );
                }}
              />
              {renderContent(item)}
            </div>
          ) : (
            <div
              style={{
                width: widthItem,
                height: widthItem,
                ...PURPLE_STYLE_LIST[getRandomNumber()],
              }}
              className="qna-item-color"
            >
              {renderContent(item)}
            </div>
          )}
        </div>
      );
    };

    return (
      <>
        <div
          className="tag-qna-component"
          aria-label={`Tag Qna List`}
        >
          {qnaList && qnaList.length > 0 ? (
            qnaList.map((item, index) => {
              return renderQna({ item, index });
            })
          ) : loading ? (
            <></>
          ) : (
            <EmptyFeed
              tagName={tagName}
              type={FeedTypeCode.QNA}
              dataRefresh={() => {
                dataRefresh && dataRefresh();
              }}
            />
          )}
        </div>
        {loading && <CustomActivityIndicatorSmall />}
      </>
    );
  }
);

export default TagQnaComponent;
