import { observer } from "mobx-react-lite";
import { ReactComponent as CloseIconWhite } from "../../../assets/icons/close_white.svg";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import Modal from "react-modal";
import "./WebJoinMemberCouponModal.scss";
import dayjs from "dayjs";

const WebJoinMemberCouponModal = observer(
  ({
    show,
    imgPath,
    onClick,
    onClose,
  }: {
    show: boolean;
    imgPath: string;
    onClick: () => void;
    onClose: () => void;
  }) => {
    const PUBLIC_BUCKET_URL: string =
      process.env.REACT_APP_PUBLIC_BUCKET_URL || "";

    const modalStyle: Modal.Styles = {
      content: {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: "clamp(0px, calc(50% - 225px), 50vw)",
        maxWidth: "450px",
        border: "none",
        padding: "0",
        backgroundColor: "transparent",
        width: "100%",
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 10000,
        maxWidth: 450,
        margin: "auto",
      },
    };

    return (
      <>
        <Modal id="VoltModal" isOpen={show} style={modalStyle}>
          <div className="centered_view">
            <div className="modal_view">
              <div className="btn_close" onClick={onClose}>
                <CloseIconWhite />
              </div>
              <div onClick={onClick}>
                <FastImageWithFallback
                  style={{ width: 260, height: 306, display: "flex" }}
                  source={{
                    uri: `${PUBLIC_BUCKET_URL}${imgPath}?${dayjs().format(
                      "YYYYMMDDHH"
                    )}`,
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
);
export default WebJoinMemberCouponModal;
