import React, { useMemo, useState } from "react";
import "./BadgeList.scss";
import TitleHeader from "../../components/common/TitleHeader";
import { useTranslation } from "react-i18next";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import { getBadges } from "../../api/badge/api";
import { BadgeItem } from "../../api/badge/model";
import { IsMember, SearchType } from "../../constants/Badge.enum";
import { observer } from "mobx-react";
import BadgeStore from "../../store/BadgeStore";

import WriteButtonStore from "../../store/WriteButtonStore";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BadgeBottomSheet from "../../components/common/BadgeBottomSheet";

const BadgeList = observer((): JSX.Element => {
  const [allBagdes, setAllBadges] = useState<BadgeItem[]>([]);
  const [badgeCount, setBadgeCount] = useState(0);
  const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;
  const { t } = useTranslation();
  const { badgeLevelInfo, getBadgeLevelInfo } = BadgeStore;
  const [destBadgeItem, setDestBadgeItem] = useState<{
    show: boolean;
    item: BadgeItem;
  }>();
  const onBottomSheet = (badge: BadgeItem) => {
    setDestBadgeItem({ show: true, item: badge });
    WriteButtonStore.setShowWriteButton(false);
  };

  const getAllBadges = async () => {
    const dbAllBadge = await getBadges({
      searchType: SearchType.ALL,
      isMember: IsMember.N,
    });
    const membersAllBadge = await getBadges({
      searchType: SearchType.ALL,
      isMember: IsMember.Y,
    });
    dbAllBadge.forEach((cur) => {
      membersAllBadge.forEach((badge) => {
        if (badge.badgeId === cur.badgeId) cur.ishave = true;
      });
    });

    setAllBadges(dbAllBadge);
    setBadgeCount(membersAllBadge.length);
  };

  useEffectOnlyOnce(() => {
    void getBadgeLevelInfo();
    void getAllBadges();
  });

  const viewabilityConfig = useMemo(() => {
    return {
      waitForInteraction: true,
      viewAreaCoveragePercentThreshold: 50,
    };
  }, []);

  return (
    <div id="badge_list">
      <TitleHeader title={t("screen.badge.label.title")} isHome />
      <div className="my_active_badge">
        <div className="wrap">
          <div className="my_icon_wrap">
            <LazyLoadImage
              src={`${PUBLIC_BUCKET_URL}${badgeLevelInfo.levelIconUrl || ""}`}
              className="my_icon"
            />
          </div>
          <p className="my_level_text1">
            {t("screen.badge.label.levelDot", {
              level: `${Number(badgeLevelInfo?.level)}`,
            })}
            <p className="my_level_text2">{badgeLevelInfo.levelCodeName}</p>
          </p>
          <div className="my_status">
            <p className="my_status_text1">
              {t("screen.badge.label.badge")}
              <p className="my_status_text2">
                &nbsp;{t("screen.badge.label.count", { count: badgeCount })}
                &nbsp;
              </p>
              {t("screen.badge.label.complete")}
            </p>
          </div>
        </div>
      </div>
      <div className="list">
        {allBagdes.map((item, index) => (
          <div
            key={item.badgeId}
            onClick={() => item.ishave && void onBottomSheet(item)}
            className="item"
            style={item.ishave ? { cursor: "pointer" } : {}}
          >
            <div className="icon_wrap">
              <LazyLoadImage
                className="icon"
                src={
                  item.ishave
                    ? `${PUBLIC_BUCKET_URL}/BADGE/${
                        item.badgeCode || ""
                      }_ON.png`
                    : `${PUBLIC_BUCKET_URL}/BADGE/${
                        item.badgeCode || ""
                      }_OFF.png`
                }
              />
            </div>
            <div className="label">
              <p className={item.ishave ? "label_on_text" : "label_text"}>
                {item.ishave
                  ? t("screen.badge.label.get", {
                      badge: `${item.badgeName}`,
                    })
                  : item.badgeName}
              </p>
            </div>
          </div>
        ))}
      </div>

      {destBadgeItem && destBadgeItem?.show && (
        <BadgeBottomSheet
          open={destBadgeItem.show}
          badgeItem={destBadgeItem.item}
          onClose={() => {
            setDestBadgeItem(undefined);
            WriteButtonStore.setShowWriteButton(true);
          }}
        ></BadgeBottomSheet>
      )}
    </div>
  );
});

export default BadgeList;
