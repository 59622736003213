import TitleHeader from "../../components/common/TitleHeader";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
} from "../../assets/styles";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { EmailLoginRequest, SocialLoginRequest } from "../../api/member/model";
import { observer } from "mobx-react";
import { getDateFormatKo } from "../../utils/datetime";
import { restoreEmailMember, restoreSocialMember } from "../../api/member/api";
import CommonStore from "../../store/CommonStore";
import { useTranslation } from "react-i18next";
import { SuccessOrNot } from "../../constants/Common.enum";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import { useLocation } from "react-router-dom";
import classes from "./DormantMember.module.scss";
import clsx from "clsx";
import { CSSProperties } from "react";

export interface DormantMemberInterface {
  emailLoginRequest?: EmailLoginRequest;
  socialLoginRequest?: SocialLoginRequest;
  separateDatetime: string;
  createdDatetime: string;
}

const DormantMember = observer((): JSX.Element => {
  const {
    dormant_member,
    contents_wrap,
    contents,
    title_text1,
    title_text2,
    box,
    top_wrap,
    bar,
    bottom_wrap,
    top_item,
    circle,
    top_item_text1,
    separate,
    top_item_text2,
    info_icon_wrap,
    bottom_text,
    info_text1,
    info_text2,
  } = styles;

  const {
    emailLoginRequest,
    socialLoginRequest,
    separateDatetime,
    createdDatetime,
  }: DormantMemberInterface = useLocation().state;

  const { t } = useTranslation();

  const onConfirmBtnPress = async () => {
    let successOrNot;

    if (socialLoginRequest) {
      successOrNot = await restoreSocialMember(socialLoginRequest);
    } else if (emailLoginRequest) {
      successOrNot = await restoreEmailMember(emailLoginRequest);
    }

    if (successOrNot === SuccessOrNot.Y) {
      goTo("/", { replace: true });
      CommonStore.setToastOption({
        show: true,
        message1: t("screen.DormantMember.toast"),
      });
    } else {
      CommonStore.setToastOption({
        show: true,
        message1: t("screen.DormantMember.toastFail"),
      });
    }
  };

  return (
    <div style={dormant_member}>
      <TitleHeader title={t("screen.DormantMember.title")} />
      <div style={contents_wrap} aria-label={"Dormant Member Screen"}>
        <div style={contents}>
          <span style={title_text1}>
            {t("screen.DormantMember.body.contents1")}
            <span style={title_text2}>
              {t("screen.DormantMember.body.contents2")}
            </span>
            {t("screen.DormantMember.body.contents3")}
          </span>
          <div style={box}>
            <div style={top_wrap}>
              <div style={top_item}>
                <div style={circle} />
                <span style={top_item_text1}>
                  {t("screen.DormantMember.body.signUpDate")}
                </span>
                <div style={separate} />
                <span style={top_item_text2}>
                  {getDateFormatKo(createdDatetime)}
                </span>
              </div>
              <div style={top_item}>
                <div style={circle} />
                <span style={top_item_text1}>
                  {t("screen.DormantMember.body.DormantDate")}
                </span>
                <div style={separate} />
                <span style={top_item_text2}>
                  {getDateFormatKo(separateDatetime)}
                </span>
              </div>
            </div>
            <div style={bar} />
            <div style={bottom_wrap}>
              <div style={info_icon_wrap}>
                <InfoIcon />
              </div>
              <div style={{ marginLeft: 4 }}>
                <span style={bottom_text}>
                  {t("screen.DormantMember.body.contents4")}
                </span>
              </div>
            </div>
          </div>
          <span style={info_text1}>
            <span style={info_text2}>
              {t("screen.DormantMember.body.contents5")}
            </span>
            {t("screen.DormantMember.body.contents6")}
          </span>
        </div>
      </div>
      <div className={classes.footer}>
        <button
          className={clsx([classes.btn_1, classes.active])}
          onClick={onConfirmBtnPress}
          aria-label={"Agree"}
        >
          <span className={clsx([classes.btn_1_txt, classes.txt_active])}>
            {t("screen.DormantMember.footer")}
          </span>
        </button>
      </div>
    </div>
  );
});

export default DormantMember;

const styles: { [key: string]: CSSProperties } = {
  dormant_member: {
    ...FlexBoxs.flex_1,
    backgroundColor: "#ffffff",
  },
  contents_wrap: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_top_40,
    ...Arranges.between,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 76,
  },
  contents: {},
  title_text1: {
    ...Texts.contents_text_32,
    ...Colors.font_222,
  },
  title_text2: {
    ...Texts.font_weight_bold,
    ...Colors.font_be69c3,
  },
  box: {
    ...Colors.background_fcfafc,
    ...Spacings.padding_top_40,
    ...Spacings.padding_bottom_30,
    ...Spacings.padding_right_left_20,
    ...Spacings.margin_top_32,
    ...Spacings.margin_bottom_24,
  },
  top_wrap: {},
  bar: {
    ...Spacings.margin_top_22,
    ...Spacings.margin_bottom_24,
    ...Colors.background_eeeeee,
    height: 1,
  },
  bottom_wrap: {
    ...Spacings.padding_right_12,
    display: "flex",
  },
  top_item: {
    display: "flex",
    ...Arranges.center_h,
    ...Spacings.margin_bottom_7,
  },
  circle: {
    width: 3,
    height: 3,
    ...Colors.background_666,
    ...Spacings.margin_right_6,
  },
  top_item_text1: {
    ...Texts.contents_text_38,
    ...Colors.font_222,
  },
  separate: {
    width: 1,
    height: 13,
    ...Colors.background_c4c4c4,
    ...Spacings.margin_left_right_7,
  },
  top_item_text2: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_900,
    ...Colors.font_222,
  },
  info_icon_wrap: {
    paddingTop: 4,
  },
  bottom_text: {
    ...Texts.contents_text_0,
  },
  info_text1: {
    ...Texts.contents_text_28,
  },
  info_text2: {
    ...Texts.font_weight_bold,
    ...Colors.font_672c78,
  },
  agree_btn: {
    height: 56,
    ...Arranges.center_v_h,
    ...Colors.background_222,
  },
  agree_btn_text: {
    ...Texts.btn_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
};
