import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {useState } from "react";
import { useParams } from "react-router-dom";
// import { SafeAreaView, useSafeAreaInsets } from "react-native-safe-area-context";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../assets/styles";
// import { Alert, PixelRatio, StatusBar, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { TitleHeader } from "../../components/common";
import { HeaderItem } from "../../constants/Header";
// import { popTo } from "../../navigation/NavigationFunction";
import { goBack } from "../../hooks/navigate/NavigateFunction";
import { Styles } from "../../assets/types/Style";
import MyAppliancesStore from "../../store/MyAppliancesStore";
// import { Color } from "../../assets/styles/variable";
import { ProductDeleteCode } from "../../constants/CommonCode.enum";
import CustomActivityIndicator from "../../components/common/CustomActivityIndicator";
import { ReactComponent as FirstDelIconOn} from "../../assets/icons/myappl/5209_delete_A(off).svg";
import { ReactComponent as FirstDelIconOff} from "../../assets/icons/myappl/5209_delete_A(on).svg";
import { ReactComponent as SecondDelIconOn} from "../../assets/icons/myappl/5209_delete_B(off).svg";
import { ReactComponent as SecondDelIconOff} from "../../assets/icons/myappl/5209_delete_B(on).svg";
import { showBottomToast } from "../../utils/Toast";
// import { responsiveWidth } from "react-native-responsive-dimensions";

const MyApplianceDeleteScreen = observer((data: any) => {
  const params = useParams();
  const delSize: string = params?.delSize || '0';

  const { t } = useTranslation();
  // const insets = useSafeAreaInsets();
  // const calHeight = insets.bottom / PixelRatio.get();

  const [isFirstReasonChecked, setIsFirstReasonChecked] = useState(false);
  const [isSecondReasonChecked, setIsSecondReasonChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const FirstReason = MyAppliancesStore.deleteReasonMap.get(ProductDeleteCode.NOT_USED) ?? "더 이상 사용하지 않아요.";
  const SecondReason = MyAppliancesStore.deleteReasonMap.get(ProductDeleteCode.INCORRECT_REGIST) ?? "잘못 등록한 가전이에요.";

  const getReason = () => {
    if (isFirstReasonChecked && !isSecondReasonChecked) {
      return ProductDeleteCode.NOT_USED;
    }
    if (!isFirstReasonChecked && isSecondReasonChecked) {
      return ProductDeleteCode.INCORRECT_REGIST;
    }
    return "";
  };

  return (
    <>
      {/* <StatusBar backgroundColor={Color.WHITE} barStyle="dark-content" /> */}
      <div style={styles.view_header} />
      <div style={{...styles.wrap, backgroundColor: "#fff", minHeight: '100vh' }}>
        <TitleHeader
          title={t("내 가전 삭제")}
          rightItem={HeaderItem.CLOSE}
          // onClickClose={() => {
            // popTo(1);
          // }}
          isBack={false}
        />
        <div>
          <div style={styles.header}>
            {t("screen.productAdd.message.total_txt1")}
            <span style={{ color: "#be69c3" }}>{delSize}</span>
            {t("screen.productAdd.message.total_txt2")}
          </div>
          <p style={styles.header}>{"삭제하시겠어요?"}</p>
        </div>
        <div style={{ marginTop: 20, marginBottom: 40 }}>
          <p style={styles.guide_txt}
            dangerouslySetInnerHTML={{ __html: (t("screen.productAdd.message.guide_text")).replaceAll("\n", "<br />") }}
          />
        </div>
        <div>
          <button
            style={{ width: '100%', padding: '0px 16px' }}
            onClick={() => {
              if (isFirstReasonChecked) {
                setIsFirstReasonChecked(false);
              } else {
                setIsFirstReasonChecked(true);
                setIsSecondReasonChecked(false);
              }
            }}
          >
            <div
              style={{
                ...styles.reason_view,
                ...(isFirstReasonChecked && !isSecondReasonChecked ? { backgroundColor: "#be69c2" } : {}),
              }}
            >
              <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                {isFirstReasonChecked && !isSecondReasonChecked ? <FirstDelIconOff /> : <FirstDelIconOn />}
                <p
                  style={{
                    ...styles.reason_text_view,
                    ...(isFirstReasonChecked && !isSecondReasonChecked ? { color: "#ffffff" } : {}),
                  }}
                >
                  {FirstReason}
                </p>
              </div>
            </div>
          </button>
          <button
            style={{  width: '100%', marginTop: 8, marginBottom: 40, padding: '0px 16px' }}
            onClick={() => {
              if (isSecondReasonChecked) {
                setIsSecondReasonChecked(false);
              } else {
                setIsFirstReasonChecked(false);
                setIsSecondReasonChecked(true);
              }
            }}
          >
            <div
              style={{
                ...styles.reason_view,
                ...(!isFirstReasonChecked && isSecondReasonChecked ? { backgroundColor: "#be69c2" } : {}),
              }}
            >
              <div style={{ display: 'flex', flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                {!isFirstReasonChecked && isSecondReasonChecked ? <SecondDelIconOff /> : <SecondDelIconOn />}
                <p
                  style={{
                    ...styles.reason_text_view,
                    ...(!isFirstReasonChecked && isSecondReasonChecked ? { color: "#ffffff" } : {}),
                  }}
                >
                  {SecondReason}
                </p>
              </div>
            </div>
          </button>
        </div>
        <div style={styles.div_area}></div>
        <div style={{ marginLeft: 16, marginRight: 16, marginTop: 40, paddingBottom: 80 }}>
          <div style={styles.notification_view}>
            <div style={{ marginLeft: 16 }}>
              <p style={styles.notification_text_1}>{"잠깐!"}</p>
            </div>
            <div style={{ marginTop: 10, marginLeft: 16, marginRight: 16 }}>
              <p style={styles.notification_text}>{t("screen.productAdd.message.delete_notice1")}</p>
              <p style={styles.notification_text}>{t("screen.productAdd.message.delete_notice2")}</p>
            </div>
          </div>
        </div>

        <div style={styles.footer}>
          <button
            style={{
              width: '100%',
              ...styles.btn_1,
              ...(getReason() !== "" ? styles.active : styles.in_active),
              height: 56
            }}
            onClick={() => {
              if (getReason() == "") {
                showBottomToast(t("screen.productAdd.message.inValidDelete"));
                return;
              }
              setIsLoading(true);
              void MyAppliancesStore.deleteMyProduct(getReason())
                .then(async () => {
                  await MyAppliancesStore.initMyProduct();
                })
                .finally(() => {
                  setIsLoading(false);
                  goBack();
                });
            }}
            // accessibilityLabel="Click button"
          >
            <p
              style={{
                ...styles.footer_btn_text,
                ...(MyAppliancesStore.isValid && getReason() !== "" ? styles.txt_active : styles.txt_in_active),
              }}
            >
              {t("screen.productAdd.button.delete2")}
            </p>
          </button>
        </div>
      </div>
      {isLoading && <CustomActivityIndicator />}
    </>
  );
});

export default MyApplianceDeleteScreen;

const styles : Styles = {
  view_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Colors.background_fff,
  },
  wrap: {
    ...FlexBoxs.flex_1,
  },
  header: {
    marginTop: 16,
    fontSize: 24,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: -0.96,
    textAlign: "center",
    color: "#222222",
  },
  guide_txt: {
    fontSize: 16,
    fontWeight: "normal",
    fontStyle: "normal",
    // lineHeight: 26,
    // letterSpacing: -0.64,
    textAlign: "center",
    color: "#666666",
    paddingLeft: 16,
    paddingRight: 16
  },
  reason_view: {
    // width: responsiveWidth(90),
    // width: '90%',
    height: 100,
    borderRadius: 8,
    ...Colors.background_f6f4fa,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  reason_text_view: {
    fontSize: 18,
    fontWeight: "500",
    fontStyle: "normal",
    letterSpacing: -0.72,
    textAlign: "left",
    color: "#be69c2",
    marginLeft: 16,
  },
  div_area: {
    height: 6,
    ...Colors.background_f5f5f5
  },
  notification_view: {
    // justifyContent: "center",
    // width: responsiveWidth(90),
    // width: '90%',
    // height: 114,
    paddingTop: 16,
    paddingBottom: 16,
    borderRadius: 4,
    ...Colors.background_fafafa
  },
  notification_text_1: {
    fontSize: 14,
    fontWeight: "bold",
    fontStyle: "normal",
    letterSpacing: -0.56,
    textAlign: "left",
    color: "#be69c2",
  },
  notification_text: {
    fontSize: 12,
    fontWeight: "normal",
    fontStyle: "normal",
    // lineHeight: 20,
    // letterSpacing: -0.48,
    textAlign: "left",
    color: "#222222",
  },
  footer: {
    ...FlexBoxs.flex_1,
    justifyContent: "flex-end",
    width: '100%',
    maxWidth: '450px',
    position: 'fixed',
    ...Positions.bottom,
    zIndex: '100',
  },
  footer_btn: {
    ...Colors.background_222,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  footer_btn_text: {
    ...Texts.btn_text_0,
    ...Texts.font_weight_500,
    ...Colors.font_fff,
  },
  txt_active: {
    ...Colors.font_fff,
  },
  txt_in_active: {
    ...Colors.font_999,
  },
  in_active: {
    ...Colors.background_eeeeee,
    ...Colors.border_color_d9d9d9,
  },
  active: {
    ...Colors.background_222,
  },
  btn_1: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
};
