import { ReactElement, useEffect, useRef, useState } from "react";
import { ReactComponent as HeartOnIcon } from "../../../assets/icons/heart_on.svg";
import { ReactComponent as HeartOffIcon } from "../../../assets/icons/heart_off.svg";
import { ReactComponent as ChatIcon } from "../../../assets/icons/chat.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { observer } from "mobx-react";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import MagazineStore from "../../../store/MagazineStore";
import { sendActHistory } from "../../../api/data/api";
import { getDateStr } from "../../../utils/datetime";
import { Magazine } from "../../../api/magazine/model";
import { cancelLike, saveLike } from "../../../api/like/api";
import { LikeTypeCode } from "../../../constants/Like";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import BasicHeader from "../../../components/headers/BasicHeader";
import { FeedTypeCode, SORT } from "../../../constants/Feed";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import { track } from "../../../hooks/tracker/TrackFunction";
import { ShareInfo, onShare } from "../../../utils/share";
import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
import classes from "../MagazineMain.module.scss";
import { cancelBookmark, saveBookmark } from "../../../api/bookmark/api";
import { CommentTypeCode } from "../../../constants/Comment";
import { useTranslation } from "react-i18next";
import CustomActivityIndicator from "../../../components/common/CustomActivityIndicator";
import { useLocation } from "react-router-dom";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Virtuoso } from "react-virtuoso";
import { Styles } from "../../../assets/types/Style";
import { Colors, Spacings, Texts } from "../../../assets/styles";
import ListWithStickyTabs from "../../../components/common/ListWithStickyTabs";
import { TabInterface } from "../../../components/common/Tabs";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import clsx from "clsx";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CommonStore from "../../../store/CommonStore";
import { useWindowScroll } from "react-use";

const MagazineMain = observer(() => {
  const [sort, setSort] = useState<string>(SORT.DATE);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const [pageIndexLatest, setPageIndexLatest] = useState<number>(0);
  const [pageIndexPopular, setPageIndexPopular] = useState<number>(0);
  const { t } = useTranslation();
  const location = useLocation();
  const { y } = useWindowScroll();

  useEffect(() => {
    setTimeout(() => {
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.PAGE_VIEW,
        actPageCode: ActPageCode.MAGAZINE,
        actSourcePageCode: CommonStore.fixedPreviousRouteName,
        attr1: (y === 0).toString(),
      };
      void sendActHistory(actHistory);
    }, 1000);
  }, []);

  useEffectOnlyOnce(() => {
    void initList();
  });

  const initList = async () => {
    MagazineStore.initLatestList();
    MagazineStore.initPopularList();
    setPageIndexLatest(1);
    setPageIndexPopular(1);
  };

  const getMoreList = async () => {
    if (sort === SORT.DATE) {
      if (MagazineStore.isLatestLoading || MagazineStore.isLatestMoreLoading) {
        return;
      }
      await MagazineStore.getMoreLatestList({
        pageIndex: pageIndexLatest,
        sort,
      });
      setPageIndexLatest(pageIndexLatest + 1);
    }
    if (sort === SORT.POPULARITY) {
      if (
        MagazineStore.isPopularLoading ||
        MagazineStore.isPopularMoreLoading
      ) {
        return;
      }
      await MagazineStore.getMorePopularList({
        pageIndex: pageIndexPopular,
        sort,
      });
      setPageIndexPopular(pageIndexPopular + 1);
    }
  };

  const renderHeader = () => {
    return (
      <div
        className={classes.header_wrapper}
        aria-label={"Magazine list title"}
      >
        <BasicHeader
          title={t(`screen.magazine.title`)}
          isHome
          onSearch={() => CommonStore.setShowDownloadAppDrive(true)}
        />
      </div>
    );
  };

  const renderForeground = () => {
    return (
      <div id="magazine-list-header" className={classes.magazine_header}>
        <div
          className={classes.magazine_header_text1}
          aria-label={"Magazine list header text1"}
        >
          {t(`screen.magazine.title`)}
        </div>
        <div
          className={classes.magazine_header_text2}
          aria-label={"Magazine list header text2"}
        >
          {t(`screen.magazine.headerText`)}
        </div>
      </div>
    );
  };

  const onLikeButtonPress = async (item: Magazine) => {
    var promise: Promise<void>;
    if (item.likeYn === "Y") {
      promise = cancelLike(item.feedId, FeedTypeCode.MAGAZINE).then(
        (result) => {
          if (result) {
            item.likeYn = "N";
            item.likeCount = item.likeCount - 1;
          }
        }
      );
    } else {
      promise = saveLike(item.feedId, FeedTypeCode.MAGAZINE).then((result) => {
        track("click_like_button", {
          content_id: item.feedId,
          content_title: item.feedTitle,
          content_type: LikeTypeCode.MAGAZINE,
          like_count: item.likeCount ? item.likeCount : 0,
          comment_count: item.commentCount ? item.commentCount : 0,
        });

        if (result) {
          item.likeYn = "Y";
          item.likeCount = item.likeCount + 1;
        }
      });
    }

    promise?.finally(() => {
      MagazineStore.updateItem(item);
    });
  };

  const onBookmarkButtonPress = (item: Magazine) => {
    if (item.bookmarkYn === "Y") {
      void cancelBookmark(item.feedId, FeedTypeCode.MAGAZINE);
      item.bookmarkYn = "N";
    } else {
      void saveBookmark(item.feedId, FeedTypeCode.MAGAZINE);
      item.bookmarkYn = "Y";
    }
    MagazineStore.updateItem(item);
  };

  const onLikeListButtonPress = (feedId: number) => {
    let params = {
      feedId: feedId,
      feedType: LikeTypeCode.MAGAZINE,
    };
    goTo("/LikeScreen", { state: params });
  };

  const onCommentButtonPress = (item: Magazine) => {
    let params = {
      feedId: item.feedId,
      feedType: CommentTypeCode.MAGAZINE,
      feedAuthorId: item.memberUuid,
      item: item,
      // 여기에 함수를 넘기면 오류가 발생함 -> /comment screen 에서 받아서 직적 해당 함수 호출하도록 변경함
      // afterUpdate: async () => {
      //   const updatedMagazine = await getMagazineByFeedId(item.feedId)
      //   MagazineStore.updateItem({
      //     ...item,
      //     commentCount: updatedMagazine?.magazine?.commentCount,
      //   })
      // },
    };
    goTo(`/${CommentTypeCode.MAGAZINE}/${item.feedId}/comment`, {
      state: params,
    });
  };

  const moveToDetail = (item: Magazine, index: number) => {
    let params = {
      // 여기에 함수를 넘기면 오류가 발생함
      // onLeave: async () => {
      //   const updatedMagazine = await getMagazineByFeedId(item.feedId)
      //   MagazineStore.updateItem({
      //     ...item,
      //     ...updatedMagazine?.magazine,
      //   })
      // },
    };
    goTo(`/magazine/${item.feedId}`, { state: params });
    const actHistory: ActHistoryRequest = {
      actHistoryTypeCode: ActHistoryTypeCode.MAGAZINE_READ,
      actHistoryTargetId: item.feedId.toString(),
      actPageCode: ActPageCode.MAGAZINE,
      actSectionCode: ActSectionCode.MAGAZINE,
      attr1: index.toString(),
      attr2: item.likeCount.toString(),
      attr3: item.commentCount.toString(),
    };
    void sendActHistory(actHistory);
  };

  const renderItem = (index: number, item: Magazine) => {
    if (!item) {
      return <></>;
    }

    return (
      <div style={{ marginBottom: 56 }} className={classes.magazine_item}>
        <TouchableWithAuthCheck
          onPress={() => {
            let params = {
              targetUserId: item.memberUuid,
              nickname: item.nickname,
              profileUrl: item.profileUrl,
              profileBackgroundColor: item.profileBackgroundColor,
            };
            goTo(`/user/${item.nickname}`, { state: params });
          }}
        >
          <div className={classes.magazine_item_header}>
            <FastImageWithFallback
              className={classes.user_img}
              source={{
                uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                  item.profileUrl || ""
                }`,
              }}
            />
            <div className={classes.info_wrap}>
              <div className={classes.info_text1}>{item.nickname}</div>
              <div className={classes.info_text2}>
                {getDateStr(item.createdDatetime)}
              </div>
            </div>
          </div>
        </TouchableWithAuthCheck>
        <div className={classes.magazine_item_body}>
          <div className={classes.magazine_image_wrapper}>
            <button
              className={classes.magazine_image_container}
              onClick={() => moveToDetail(item, index)}
            >
              <FastImageWithFallback
                className={classes.magazine_image}
                source={{
                  uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                    item.thumbnailFilePath || ""
                  }`,
                }}
                fallbackImageUri={`${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                  item.originalFilePath || ""
                }`}
                aria-label={`Magazine image ${index}`}
              />
            </button>
            <button
              className={classes.magazine_btn}
              onClick={() => moveToDetail(item, index)}
            >
              <span
                className={classes.magazine_btn_text}
                aria-label={`Move to magazine detail${index}`}
              >
                {t(`screen.magazine.magazineDetailBtn`)}
              </span>
            </button>
          </div>
          <div className={classes.action_wrap}>
            <div className={classes.action_left}>
              <TouchableWithAuthCheck
                className={classes.action_icon_btn}
                onPress={() => onLikeButtonPress(item)}
                aria-label={`like button${index} on magazine list`}
              >
                {item.likeYn === "Y" ? <HeartOnIcon /> : <HeartOffIcon />}
              </TouchableWithAuthCheck>
              <button
                className={classes.action_icon_btn}
                onClick={() => onCommentButtonPress(item)}
                aria-label={`Move to comment icon${index} on magazine list`}
              >
                <ChatIcon />
              </button>
              <button
                className={classes.action_icon_btn}
                onClick={() => {
                  const shareInfo: ShareInfo = {
                    title: item.feedTitle,
                    descriptionText: item.previewContents,
                    imageUrl: item.thumbnailFilePath,
                    screen: ShareScreenCode.MAGAZINE_DETAIL_SCREEN,
                    targetId: item.feedId,
                    path: `magazine/${item.feedId}`,
                  };

                  void onShare(shareInfo);

                  track("click_share_button", {
                    content_id: item.feedId,
                    content_title: item.feedTitle,
                    content_type: LikeTypeCode.MAGAZINE,
                    like_count: item.likeCount ? item.likeCount : 0,
                    comment_count: item.commentCount ? item.commentCount : 0,
                  });
                }}
              >
                <ShareIcon />
              </button>
            </div>

            <div className={classes.action_right}>
              <button
                onClick={() => onLikeListButtonPress(item.feedId)}
                aria-label={`Move to like text${index} on magazine list`}
              >
                <div className={classes.like_text}>
                  {t("screen.magazine.label.like")}
                  <div className={classes.number_text}>{item.likeCount}</div>
                </div>
              </button>
              <button
                onClick={() => onCommentButtonPress(item)}
                aria-label={`Move to comment text${index} on magazine list`}
              >
                <div className={classes.action_text}>
                  {t("screen.magazine.label.comment")}
                  <div className={classes.number_text}>{item.commentCount}</div>
                </div>
              </button>
            </div>
          </div>
          <button
            className={classes.magazine_review}
            onClick={() => moveToDetail(item, index)}
          >
            <div className={classes.title_text}>{item.feedTitle}</div>
            {item.previewContents && (
              <div
                className={classes.contents_text}
                // numberOfLines={2}
                // ellipsizeMode={'tail'}
              >
                {item.previewContents}
              </div>
            )}
          </button>
        </div>
      </div>
    );
  };

  const renderContent = (key: string, data: Magazine[]) => {
    let isLoading = false;
    if (key === SORT.DATE) {
      isLoading = MagazineStore.isLatestLoading;
    } else if (key === SORT.POPULARITY) {
      isLoading = MagazineStore.isPopularLoading;
    }
    return isLoading ? (
      <></>
    ) : (
      <div className={classes.magazine}>
        <Virtuoso
          className={classes.magazine_list}
          data={data}
          itemContent={renderItem}
          initialItemCount={5}
          useWindowScroll
          endReached={() => void getMoreList()}
        />
      </div>
    );
  };

  const onRefresh = () => {
    if (sort === SORT.DATE) {
      void MagazineStore.initLatestList();
      setPageIndexLatest(1);
    }
    if (sort === SORT.POPULARITY) {
      void MagazineStore.initPopularList();
      setPageIndexPopular(1);
    }
  };

  const tabInfo: TabInterface[] = [
    {
      key: SORT.DATE,
      title: t(`screen.magazine.sort.date`),
      content: renderContent(SORT.DATE, MagazineStore.latestList),
      accessibilityLabel: "date",
    },
    {
      key: SORT.POPULARITY,
      title: t(`screen.magazine.sort.popularity`),
      content: renderContent(SORT.POPULARITY, MagazineStore.popularList),
      accessibilityLabel: "popularity",
    },
  ];

  const stickyTabsRef = useRef<HTMLDivElement>(null);

  const renderStickyTabs = () => {
    return (
      <div
        className={clsx("magazine-list-screen", classes.sticky_tabs)}
        ref={stickyTabsRef}
      >
        <div className={classes.sticky_tabs_content}>
          {tabInfo.map((tab) => (
            <div
              key={tab.key}
              className={clsx(
                classes.sticky_tab_text,
                sort === tab.key && classes.sticky_tab_text_active
              )}
              onClick={() => {
                setSort(tab.key);
              }}
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.magazine_wrapper}>
      {renderHeader()}
      <ListWithStickyTabs
        contentScrollRef={stickyTabsRef}
        // onRefresh={onRefresh}
        // stickyTabs={tabInfo}
        stickyTabsCustom={renderStickyTabs()}
        listRenderComponent={
          tabInfo.find((tab) => tab.key === sort)?.content as ReactElement
        }
        scrollableHeader={renderForeground()}
        useWindowScroll
        endReached={() => void getMoreList()}
        onChangeTab={() => {
          sort === SORT.POPULARITY
            ? setSort(SORT.DATE)
            : setSort(SORT.POPULARITY);
        }}
        activeTabKey={sort}
        showScrollToTop={true}
      />
    </div>
  );
});

export default MagazineMain;

const tabStyles: Styles = {
  tabTextContainerStyle: {
    ...Colors.background_transparent,
  },
  tabTextStyle: {
    ...Texts.contents_text_2,
    opacity: 0.4,
    ...Spacings.padding_right_16,
    ...Spacings.padding_top_bottom_12,
  },
  tabTextActiveStyle: {
    ...Texts.contents_text_2,
    opacity: 1,
    ...Spacings.padding_right_16,
    ...Spacings.padding_top_bottom_12,
  },
  tabWrapperStyle: {
    ...Spacings.padding_top_bottom_10,
  },
  tabsContainerStyle: {
    width: "100%",
    ...Spacings.padding_right_left_16_responsive,
  },
  tabTextContainerActiveStyle: {},
};
