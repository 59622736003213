import React, { CSSProperties, useState } from 'react'
import SettingStore from '../../../../store/SettingStore'
import { observer } from 'mobx-react'
import { useTracker } from '../../../../hooks/tracker'
import { NotificationParentTypeCode } from '../../../../constants/Settings'
import Switch from 'react-switch'
import clsx from 'clsx'
import classes from './AlarmItem.module.scss'

export interface AlarmItemProps {
  alarmTitle: string
  last?: boolean
  ynValue: boolean
  code: string
  alarmTitleStyle?: CSSProperties
}

const AlarmItem = observer(
  ({ alarmTitle, last, code, ynValue, alarmTitleStyle }: AlarmItemProps) => {
    const { updateAlarmSetting } = SettingStore
    const [tracker] = useState(useTracker())

    const toggleAlarm = () => {
      if (code === NotificationParentTypeCode.PUSH && !ynValue) {
        tracker.track('click_push_allow_button', {})
      }
      void updateAlarmSetting(code, ynValue ? 'N' : 'Y')
    }

    return (
      <div className={clsx(classes.item, last ? classes.last_item : {})}>
        <span
          style={
            alarmTitleStyle
              ? alarmTitleStyle
              : {
                  fontSize: 16,
                  letterSpacing: -0.64,
                  color: '#222',
                  fontWeight: 500,
                }
          }
        >
          {alarmTitle}
        </span>
        <Switch
          onChange={toggleAlarm}
          checkedIcon={false}
          uncheckedIcon={false}
          width={40}
          height={24}
          activeBoxShadow="unset"
          aria-label={'Switch ' + alarmTitle}
          offColor="#d9d9d9"
          onColor="#be69c3"
          onHandleColor="#ffffff"
          offHandleColor="#ffffff"
          checked={ynValue}
        />
      </div>
    )
  }
)

export default AlarmItem
