import { PaginationResponse } from "../common/model";
import CommonResponse from "../http";
import {
  ProductInfo,
  ProductAsCenter,
  RelatedProduct,
  NewProduct,
  TempProduct,
  MyProductRequest,
  GetMyProductRequest,
  MyProductDeleteRequest,
} from "./model";
import { SuccessOrNot } from "../../constants/Common.enum";
import { PRODUCT_SEARCH_COUNT } from "../../constants/Product";
import {
  callAPIWithDELETEMethod,
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
  callAPIWithPUTMethod,
} from "../common/api";
import { SearchItem } from "../search/model";

// 제품정보 상세조회
export const getProductDetail = async (
  productId: number,
  isLoading = true
): Promise<ProductInfo> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/product/${productId}`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as ProductInfo;
};

// AS 센터 조회
export const getProductAsCenters = async (
  productId: number,
  isLoading = true
): Promise<ProductAsCenter[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/product/ascenters?&productId=${productId}`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as ProductAsCenter[];
};

// 연관제품 조회
export const getRelatedProductList = async (
  productId: number,
  pageSize: number,
  pageIndex: number,
  isLoading = true
): Promise<RelatedProduct[]> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/product/relations?pageSize=${pageSize}&pageIndex=${pageIndex}&productId=${productId}`,
    },
    isLoading
  );

  return (
    response?.successOrNot === "Y" ? response?.data : null
  ) as RelatedProduct[];
};

export const getProductSearch = async (
  keyword: string,
  pageIndex: number = PRODUCT_SEARCH_COUNT,
  pageSize = 0,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: `/v1/${keyword}`,
    },
    isLoading
  );
};

export const getMyProductDetail = async (
  productId: number,
  productFlag: boolean,
  isLoading = true
): Promise<TempProduct> => {
  const productFlagStr = productFlag ? "true" : "false";
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/product/myProductDetail?productId=${productId}&productFlag=${productFlagStr}`,
    },
    isLoading
  );
  return response?.successOrNot === SuccessOrNot.Y &&
    response?.statusCode === "OK"
    ? response.data
    : null;
};

export const createProduct = async (
  product: TempProduct,
  isLoading = true
): Promise<NewProduct> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/tempProduct",
      body: product,
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as NewProduct;
};

export const createNProduct = async (
  product: SearchItem,
  isLoading = true
): Promise<NewProduct> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/nTempProduct",
      body: product,
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as NewProduct;
};

export const createMyProduct = async (
  product: TempProduct,
  isLoading = true
): Promise<CommonResponse<any>> => {
  return await callAPIWithPUTMethod(
    {
      url: "/v1/product/addProduct",
      body: product,
    },
    isLoading
  );
};

export const updateProduct = async (
  product: TempProduct,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPUTMethod(
    {
      url: "/v1/product/myProduct",
      body: product,
    },
    isLoading
  );
};

// 내 가전 리스트 조회
export const getMyProduct = async (
  getMyProductRequest: GetMyProductRequest,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithGETMethod(
    {
      url: "/v1/product/myProduct",
      params: getMyProductRequest,
    },
    isLoading
  );
};

// 내 가전 등록
export const setMyProduct = async (
  myProductRequest: MyProductRequest[],
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/product/myProduct",
      body: { products: myProductRequest },
    },
    isLoading
  );
};

// 내 가전 삭제
export const deleteMyProduct = async (
  myProductRequest: MyProductRequest[],
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithDELETEMethod(
    {
      url: "/v1/product/myProduct",
      body: { products: myProductRequest },
    },
    isLoading
  );
};

// 내 가전 삭제
export const deleteMyProductWithReason = async (
  delRequest: MyProductDeleteRequest,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithDELETEMethod(
    {
      url: "/v1/product/myProduct",
      body: delRequest,
    },
    isLoading
  );
};
