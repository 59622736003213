import { action, computed, makeObservable, observable } from 'mobx'
import { sendCertification } from '../api/certification/api'
import { t } from 'i18next'
import CommonStore from './CommonStore'

export const EMPTY_STRING = ''

class EmailAuthStore {
  constructor() {
    makeObservable(this)
  }

  @observable _email = EMPTY_STRING
  @observable _isEmailValid = false
  @observable _isEmailAuthSectionShow = false
  @observable _inputEmailCertificationCode = EMPTY_STRING
  @observable _emailCertificationCodeStatus = EMPTY_STRING
  @observable _isEmailAuthComplete = false
  @observable _timerStart = false
  @observable _emailAuthCertificationId = EMPTY_STRING

  @action setEmail = (email: string) => {
    this._email = email
  }
  @computed get email() {
    return this._email
  }

  @action setIsEmailValid = (data: boolean) => {
    this._isEmailValid = data
  }
  @computed get isEmailValid() {
    return this._isEmailValid
  }

  @action setIsEmailAuthSectionShow = (data: boolean) => {
    this._isEmailAuthSectionShow = data
  }
  @computed get isEmailAuthSectionShow() {
    return this._isEmailAuthSectionShow
  }

  @action setInputEmailCertificationCode = (code: string) => {
    this._inputEmailCertificationCode = code
  }
  @computed get inputEmailCertificationCode() {
    return this._inputEmailCertificationCode
  }

  @action setEmailCertificationCodeStatus = (status: string) => {
    this._emailCertificationCodeStatus = status
  }
  @computed get emailCertificationCodeStatus() {
    return this._emailCertificationCodeStatus
  }

  @action setIsEmailAuthComplete = (data: boolean) => {
    this._isEmailAuthComplete = data
  }
  @computed get isEmailAuthComplete() {
    return this._isEmailAuthComplete
  }

  @action setTimerStart = (data: boolean) => {
    this._timerStart = data
  }
  @computed get timerStart() {
    return this._timerStart
  }

  @computed get emailAuthCertificationId() {
    return this._emailAuthCertificationId
  }
  @action setEmailAuthCertificationId = (data: string) => {
    this._emailAuthCertificationId = data
  }

  @action sendEmailAuthCode = async () => {
    this.setTimerStart(false)
    this.setInputEmailCertificationCode(EMPTY_STRING)
    this.setEmailAuthCertificationId(EMPTY_STRING)
    this.setEmailCertificationCodeStatus(EMPTY_STRING)

    CommonStore.setToastOption({
      show: true,
      message1: t('screen.password.toast.sendEmail'),
      message2: t('screen.password.toast.requestAuthCode'),
    })

    const res = await sendCertification(this.email)

    if (res.certificationId) {
      this.setEmailAuthCertificationId(res.certificationId)
      this.setTimerStart(true)
      this.setIsEmailAuthSectionShow(true)
    } else {
      this.setTimerStart(false)
      this.setIsEmailAuthSectionShow(false)
      alert(t('common.error.server'))
    }
  }

  @action clear = () => {
    this._email = EMPTY_STRING
    this._isEmailValid = false
    this._isEmailAuthSectionShow = false
    this._inputEmailCertificationCode = EMPTY_STRING
    this._emailCertificationCodeStatus = EMPTY_STRING
    this._isEmailAuthComplete = false
    this._timerStart = false
    this._emailAuthCertificationId = EMPTY_STRING
  }
}

export default new EmailAuthStore()
