import {
  getMagazineDetail,
  getPopularFeedDetailList,
  getPopularTagList,
  getRecommendedFeedList,
} from "../../api/feed/api";
import { MainItem, Post } from "../../api/feed/model";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { getMemberInfo } from "../../api/member/api";
import { MemberInfoResponse, MemberTagItem } from "../../api/member/model";
import { getBannerList } from "../../api/banner/api";
import { BannerTypeCodes } from "../../constants/Banner.enum";
import { BannerInfo } from "../../api/banner/model";
import { parseQueryParam } from "../../utils/queryParam";
import { MainFeedTypeCode } from "../../constants/FeedType.enum";
import MainStore from "../MainStore";
// import PostStore from "../PostStore";
// import TagManagementStore from "../TagManagementStore";

export const INIT_NUM_OF_POSTS = 4;

/**
 *  메인화면 진입전 렌더링 속도 개선을 위해
 *  로그인 API 호출 후 바로 메인 화면에 노출되어야 하는 데이터를 스토어에 저장
 *  MainStore 의 필드에 바로 셋팅하지 않고 PreRenderMainStore 를 통해 사전 작업을 완료하고 셋업처리
 */
class PreRenderMainStore {
  constructor() {
    makeObservable(this);
  }

  @observable _app_init_checker = false;
  @observable _initInterestedTagList: MemberTagItem[] | null | undefined = null;
  @observable _initMainBannerList: BannerInfo[] | null | undefined = null;
  @observable _initMainList: MainItem[] = [];
  @observable _popularPostsList: Post[] = [];

  @action setInitMainList = (initMainList: MainItem[]) => {
    this._initMainList = initMainList;
  };

  @computed get getPopularPosts() {
    return this._popularPostsList;
  }

  @computed get getInitInterestedTagList() {
    return this._initInterestedTagList;
  }

  @computed get getInitMainBannerList() {
    return this._initMainBannerList;
  }

  @computed get getFirstMainList() {
    return this._initMainList;
  }

  @computed get appInitChecker() {
    return this._app_init_checker;
  }

  @action initPopularPosts = async () => {
    const response = await getPopularFeedDetailList(10, 0);
    if (response?.successOrNot == "Y") {
      this._popularPostsList = response.data?.feedFindVOList as Post[];
    }
  };

  @action initMain = async (memberUuid: string | undefined) => {
    MainStore.setIsLoadingMore(true);
    MainStore.setCurrentNumberOfList(0);
    MainStore.setInitLoaded(true);

    const asyncArr = [];
    if (memberUuid) {
      // asyncArr.push(TagManagementStore.getInterestedTagList(memberUuid));
    }
    asyncArr.push(this.initMainBannerList());
    asyncArr.push(this.initPopularPosts());
    // asyncArr.push(PostStore.getFeedFilter());
    await Promise.all(asyncArr);

    //새로고침시 맨 앞으로 이동시키기 위해 클린
    MainStore.setPopularPostsList([]);
    MainStore.setMainBannerList([]);
    //상단 배너
    MainStore.setMainBannerList(this.getInitMainBannerList ?? []);
    //프로필 관심 태그
    // MainStore.setInterestedTagList(TagManagementStore.interestedTags ?? []);
    // 인기 포스트
    MainStore.setPopularPostsList(this.getPopularPosts ?? []);

    MainStore.setIsLoadingMore(false);
    this.setAppInitChecker();

    return true;
  };

  @action initMainBannerList = async () => {
    let response: BannerInfo[] = await getBannerList(
      BannerTypeCodes.MAIN_BANNER
    );

    response = response.map((bannerInfo) => ({
      ...bannerInfo,
      bannerLinkInfo: parseQueryParam(bannerInfo.bannerLinkParameter),
    }));
    this._initMainBannerList = response;
  };

  @action initFlatListData = async () => {
    const mainItems: MainItem[] = [];

    const [recommendPost, magazine] = await Promise.all([
      getRecommendedFeedList(INIT_NUM_OF_POSTS, 0),
      getMagazineDetail(1, 0),
    ]);

    if (recommendPost?.successOrNot == "Y") {
      (recommendPost.data?.feedFindVOList as Post[]).forEach((item, index) => {
        const mainItem = {} as MainItem;
        mainItem.sort = MainFeedTypeCode.MAIN_POST;
        mainItem.data = [item];
        mainItems.push(mainItem);

        if (!MainStore.postMap.has(String(item.feedId))) {
          const orderNum =
            MainStore.postMap.size == 0
              ? 0
              : Math.max(...MainStore.postMap.values()) + 1;
          MainStore.postMap.set(String(item.feedId), orderNum);
        }
      });
    }

    if (magazine?.successOrNot == "Y") {
      const mainItem = {} as MainItem;
      mainItem.sort = MainFeedTypeCode.MAGAZINE;
      mainItem.data = magazine.data?.feedFindVOList ?? [];
      mainItems.push(mainItem);
    }

    return mainItems;
  };

  @action clear() {
    this.setInitMainList([]);
    this._popularPostsList = [];
    this._initInterestedTagList = [];
  }

  @action setAppInitChecker() {
    this._app_init_checker = true;
  }
}

export default new PreRenderMainStore();
