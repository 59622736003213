export enum ReportTypeCode {
  SPAM = "SPAM",
  DISLIKE = "DISLIKE",
  SEXUAL_CONTENTS = "SEXUAL_CONTENTS",
  FRAUDULENT = "FRAUDULENT",
  HATE_SPEECH = "HATE_SPEECH",
  VIOLENT = "VIOLENT",
  ILLEGAL = "ILLEGAL",
  BULLYING = "BULLYING",
  ETC = "ETC",
}

export enum ReportTargetTypeCode {
  MEMBER = "MEMBER",
  POST = "POST",
  QNA = "QNA",
  AB = "AB",
  COMMENT = "COMMENT",
  ARTICLE = "ARTICLE",
}
