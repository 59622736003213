import React, { useEffect, useState } from "react";
import "./BookmarkAB.scss";

import { observer } from "mobx-react";
import { BookmarkFeedItem } from "../../../api/bookmark/model";
import BookmarkStore from "../../../store/BookmarkStore";
import { FeedTypeCode } from "../../../constants/Feed";

import { ReactComponent as CheckOffIcon } from "../../../assets/icons/checkbox_big_off.svg";
import { ReactComponent as CheckOnIcon } from "../../../assets/icons/checkbox_big_on.svg";

import { getDdayFromToday, isPast } from "../../../utils/datetime";
import { getAbDetail } from "../../../api/ab/api";
import { AbDetailResult } from "../../../api/ab/model";
import CommonStore from "../../../store/CommonStore";
import { useTranslation } from "react-i18next";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { ActHistoryRequest } from "../../../api/data/model";
import { sendActHistory } from "../../../api/data/api";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const BookmarkAB = observer(
  ({ data, listIndex }: { data: BookmarkFeedItem; listIndex: number }) => {
    const {
      bookmarkFeeds,
      selectedBookmarkFeeds,
      isTotalChecked,
      isEdit,
      setIsTotalChecked,
      selectBookmarkFeed,
      currentTab,
      updateWithNewPost,
      updateWithPoppedPosts,
    } = BookmarkStore;
    const { setLoading, setToastOption } = CommonStore;
    const { t } = useTranslation();

    const [isSelected, setIsSelected] = useState(false);

    const postTotalChecked = isTotalChecked.get(currentTab);
    const postEdit = isEdit.get(currentTab);
    const selectedPosts = selectedBookmarkFeeds.get(currentTab);

    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    const toggleIsSelected = () => {
      setIsSelected(!isSelected);
    };

    const handleSelect = (bookmarkTargetId: number) => () => {
      toggleIsSelected();
      selectBookmarkFeed(currentTab, bookmarkTargetId);

      const lengthOfBookmarkFeeds: number =
        bookmarkFeeds.get(currentTab)?.length || 0;
      const lengthOfSelectedBookmarkFeeds: number =
        selectedBookmarkFeeds.get(currentTab)?.size || 0;
      setIsTotalChecked(
        currentTab,
        lengthOfBookmarkFeeds === lengthOfSelectedBookmarkFeeds ? true : false
      );
    };

    const callActHistory = async (
      sectionCode: string,
      feedId: number,
      index: number
    ) => {
      const abDetail: AbDetailResult = await getAbDetail(feedId);
      const actHistory: ActHistoryRequest = {
        actHistoryTypeCode: ActHistoryTypeCode.AB_READ,
        actHistoryTargetId: feedId.toString(),
        actPageCode: ActPageCode.BOOKMARK,
        actSectionCode: sectionCode,
        attr1: index.toString(),
        attr2: isPast(abDetail.feedEndDate) ? "Y" : "N",
        attr3: (
          (abDetail.avoteCount ?? 0) + (abDetail.bvoteCount ?? 0)
        ).toString(),
      };
      void sendActHistory(actHistory);
    };

    const moveToPostDetail = (feedId: number) => {
      if (feedId !== undefined && feedId >= 0) {
        void callActHistory(ActSectionCode.AB, feedId, listIndex);
      }

      goTo(`/AB/${feedId}`, {
        state: {
          feedId: feedId,
          // onLeave: async () => {
          //   setLoading(true);
          //   const updatedFeed: AbDetailResult = await getAbDetail(feedId);

          //   if (updatedFeed && updatedFeed.bookmarkYn === "Y") {
          //     const totalVoteCount: number =
          //       (updatedFeed.avoteCount as number) ||
          //       0 + (updatedFeed.bvoteCount as number) ||
          //       0;
          //     updateWithNewPost(listIndex, totalVoteCount);
          //   } else if (updatedFeed && updatedFeed.bookmarkYn === "N") {
          //     updateWithPoppedPosts(listIndex);
          //   } else {
          //     updateWithPoppedPosts(listIndex);
          //   }
          //   setLoading(false);
          // },
        },
      });
    };

    const renderProgressStatusBtn = () => {
      const dday = Number(getDdayFromToday(data.feedEndDate));

      return dday < 0 ? (
        <div className="ab_in_progress">
          <p>{t("screen.bookmark.label.inProgress")}</p>
        </div>
      ) : (
        <div className="ab_expire">
          <p>{t("screen.bookmark.label.done")}</p>
        </div>
      );
    };

    const renderRightImages = (item: BookmarkFeedItem) => {
      return (
        <div className="ab_item_right">
          {item && item.athumbnailFilePath ? (
            <>
              <div className="a_image">
                <FastImageWithFallback
                  className="ab_image_wrap"
                  source={{
                    uri: `${PUBLIC_BUCKET_URL}${item.athumbnailFilePath}`,
                  }}
                />
                <p className="ab_image_text">A</p>
              </div>
            </>
          ) : (
            <div className="a_image_wrap">
              <div className="a_image_back">
                <p className="ab_image_text">A</p>
              </div>
            </div>
          )}

          {item && item.bthumbnailFilePath ? (
            <>
              <div className="b_image">
                <FastImageWithFallback
                  className="b_image_wrap_is_path"
                  source={{
                    uri: `${PUBLIC_BUCKET_URL}${item.bthumbnailFilePath}`,
                  }}
                />
                <p className="ab_image_text">B</p>
              </div>
            </>
          ) : (
            <div className="b_image_wrap">
              <div
                className={
                  item.backColorIndex
                    ? "b_image_back_purple"
                    : "b_image_back_light_purple"
                }
              >
                <p className="ab_image_text">B</p>
              </div>
            </div>
          )}
        </div>
      );
    };

    const normalGrid = (item: BookmarkFeedItem) => {
      return isEdit.get(FeedTypeCode.AB) ? (
        <div className="ab_item_wrap">
          <div className="ab_item" aria-label="AorB item">
            <div className="ab_item_left">
              <button
                className="ab_check_btn"
                onClick={handleSelect(item.feedId)}
                aria-label="Fill in or out checkbox"
              >
                {isSelected ? <CheckOnIcon /> : <CheckOffIcon />}
              </button>
              <div className="ab_info_wrap">
                {renderProgressStatusBtn()}
                <p
                  className="ab_title_text"
                  // numberOfLines={1}
                >
                  {item.feedTitle}
                </p>
                <div className="ab_info">
                  <p className="ab_info_text">{item.nickname}</p>
                  <p className="ab_info_bar">|</p>
                  <p className="ab_info_text">
                    {t("screen.bookmark.label.involve")} {item.voterCount}
                  </p>
                </div>
              </div>
            </div>
            {renderRightImages(item)}
          </div>
        </div>
      ) : (
        <button
          onClick={() => moveToPostDetail(item.feedId)}
          aria-label="Click AorB item"
          className="ab_item_wrap"
        >
          <div className="ab_item">
            <div className="ab_item_left">
              <div className="ab_info_wrap">
                {renderProgressStatusBtn()}
                <p
                  className="ab_title_text"
                  // numberOfLines={1}
                >
                  {item.feedTitle}
                </p>
                <div className="ab_info">
                  <p className="ab_info_text">{data.nickname}</p>
                  <p className="ab_info_bar">|</p>
                  <p className="ab_info_text">
                    {t("screen.bookmark.label.involve")} {data.voterCount}
                  </p>
                </div>
              </div>
            </div>
            {renderRightImages(item)}
          </div>
        </button>
      );
    };

    useEffect(() => {
      setIsSelected(false);
    }, [postEdit]);

    useEffect(() => {
      if (isTotalChecked.get(currentTab)) {
        setIsSelected(true);
      }
    }, [postTotalChecked, isTotalChecked, currentTab]);

    useEffect(() => {
      const selectedBookmarkPosts = selectedBookmarkFeeds.get(currentTab);
      selectedBookmarkPosts !== undefined &&
        setIsSelected(selectedBookmarkPosts.has(data.feedId));
    }, [
      selectedBookmarkFeeds,
      selectedPosts,
      postTotalChecked,
      data.feedId,
      currentTab,
    ]);

    return <div id="bookmark_ab_list">{normalGrid(data)}</div>;
  }
);

export default BookmarkAB;
