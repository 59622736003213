import { RefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PostWriteStore from "../../../store/PostWriteStore";
import { observer } from "mobx-react";
import { MaxLengthTextInput, StarRating } from "../../../components/common";
import "./PostRating.scss";
import CommonStore from "../../../store/CommonStore";

const PostRating = observer(() => {
  const { t } = useTranslation();
  const {
    wholeTempData,
    isShowRating,
    setAdvantage,
    setDisadvantage,
    setRatingPoint,
  } = PostWriteStore;

  const { widthApp } = CommonStore;

  const startWidth = 56 * 5;
  const xPadding = 16 * 2;
  const marginLeft = (widthApp - startWidth - xPadding) / 2;

  const prosRef = useRef<any>(null);
  const consRef = useRef<any>(null);

  useEffect(() => {
    if (prosRef.current) {
      handleHeightInput(prosRef);
    }
  }, [prosRef, wholeTempData.advantage]);

  useEffect(() => {
    if (consRef.current) {
      handleHeightInput(consRef);
    }
  }, [consRef, wholeTempData.disadvantage]);

  const handleHeightInput = (ref: RefObject<HTMLTextAreaElement>) => {
    const element = ref.current;
    if (element) {
      const minHeight = 28;
      element.style.height = "auto";
      element.style.height =
        (element.scrollHeight < minHeight ? minHeight : element.scrollHeight) +
        "px";
    }
  };

  return isShowRating ? (
    <div id="post_rating">
      <div className="bar" />

      <div className="satisfaction_wrap">
        <div className="satisfaction_title">
          <span
            className="satisfaction_title_text_2"
            aria-label="Rating guide1"
          >
            {t("screen.post.label.satisfied")}
          </span>
        </div>

        <div
          className="star_count_wrap"
          style={{
            marginLeft: marginLeft,
          }}
        >
          <StarRating
            defaultPoint={wholeTempData.rating}
            startXPoistion={16 + marginLeft}
            onRating={(point: number) => setRatingPoint(point)}
          />
        </div>

        <div className="pros_and_cons_wrap">
          <button
            className="pros_and_cons_input_wrap"
            onClick={() => prosRef.current?.focus()}
          >
            <div className="pros_and_cons_input_inner">
              <span className="pros_and_cons_text" aria-label="Advantage label">
                {t("screen.post.label.pros")}
              </span>
            </div>
            <MaxLengthTextInput
              aria-label="Input pros."
              type="textarea"
              ref={prosRef}
              className="pros_and_cons_input"
              placeholder={t("screen.post.label.inputPros")}
              placeholderTextColor={"#999"}
              value={wholeTempData?.advantage}
              maxLength={50}
              multiline={true}
              rows={1}
              onChangeText={(text: string) => setAdvantage(text)}
              onFocus={() => {}}
            />
          </button>
        </div>
        <div className="pros_and_cons_wrap">
          <button
            className="pros_and_cons_input_wrap"
            onClick={() => consRef.current?.focus()}
          >
            <div className="pros_and_cons_input_inner">
              <span
                className="pros_and_cons_text"
                aria-label="Disadvantage label"
              >
                {t("screen.post.label.cons")}
              </span>
            </div>
            <MaxLengthTextInput
              aria-label="Input cons."
              type="textarea"
              ref={consRef}
              className="pros_and_cons_input"
              placeholder={t("screen.post.label.inputCons")}
              placeholderTextColor={"#999"}
              value={wholeTempData?.disadvantage}
              rows={1}
              maxLength={50}
              multiline={true}
              onChangeText={(text: string) => setDisadvantage(text)}
            />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
});

export default PostRating;
