import Modal from "react-modal";
import { Arranges, Colors, FlexBoxs, Spacings, Texts, Positions, Icons, Assets } from "../../../assets/styles";
import { ReactComponent as LockIcon } from "../../../assets/icons/icon_lock.svg";
import { useTranslation } from "react-i18next";
import { Styles } from "../../../assets/types/Style";

const ArticleDisclosureModal = ({
  contents1, // 내용1
  contents2, // 내용2
  defaultButtonText, // 기본 버튼 텍스트
  isVisible, // 팝업 표시 여부
  onRequestClose, // 팝업 닫힌 후 본 페이지에서 해야할 동작
  onClickDefaultButton, // 기본 닫기 버튼 클릭 이벤트 (2개 이상일때는 오른쪽)
  textAlign,
  ref,
}: {
  contents1: string;
  contents2?: string;
  defaultButtonText?: string;
  isVisible: boolean;
  onRequestClose?: (() => void) | undefined;
  onClickDefaultButton?: (() => void) | undefined;
  textAlign?: any;
  ref?: any | undefined;
}) => {
  const { t } = useTranslation();
  const {
    modal_wrap,
    notification_modal,
    modal_body,
    icons,
    button,
    modal_footer,
    footer_button_text,
    text_0,
    text_1,
  } = styles;

  const dismiss = () => {
    if (onRequestClose !== undefined) onRequestClose();
  };

  const onDefaultButtonClicked = () => {
    if (onClickDefaultButton !== undefined) onClickDefaultButton();
    else dismiss();
  };

  return (
    <div style={modal_wrap}>
      <Modal
        isOpen={isVisible}
        onRequestClose={dismiss}
        contentLabel="Article Disclosure Modal"
        id="article-disclosure-modal"
        style={{
          content: {
            width: "100%",
            height: "100%",
            display: `flex`,
            background: 'rgba(34, 34, 34, 0.7)',
            inset: 0,
            zIndex: 999
          },
        }}
        ref={ref}
      >
        <div style={notification_modal}>
          <div style={modal_body}>
            <p style={{ ...text_0, ...(textAlign ? { textAlign: textAlign } : {}) }}>
              <LockIcon style={icons} /> {contents1}
            </p>
            {contents2 && contents2 !== undefined && (
              <p style={{ ...text_1, ...(textAlign ? { textAlign: textAlign } : {}) }}>
                {contents2}
              </p>
            )}
          </div>

          <div style={modal_footer}>
            <div
              style={{ ...button, ...styles.white }}
              onClick={onDefaultButtonClicked}
            >
              <p style={footer_button_text}>
                {defaultButtonText && defaultButtonText !== undefined
                  ? defaultButtonText
                  : t("screen.notificationModal.button.close")}
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ArticleDisclosureModal;

const styles: Styles = {
  modal_wrap: {
    zIndex: '999'
  },
  notification_modal: {
    ...Colors.border_color_transparent,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.margin_center_v_h,
    width: 260,
    ...Spacings.border_top_left_radius_12,
    ...Spacings.border_top_right_radius_12,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
    ...Colors.border_color_transparent,
    ...Assets.modal_box_shadow,
    height: 'fit-content',
    background: 'white'
  },
  modal_header: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Positions.relative,
    ...Colors.background_fff,
    ...Spacings.border_top_left_radius_12,
    ...Spacings.border_top_right_radius_12,
    height: 64,
  },
  modal_body: {
    ...Spacings.border_top_left_radius_12,
    ...Spacings.border_top_right_radius_12,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_20,
    ...Colors.background_fff,
    ...Spacings.padding_top_30,
  },
  icons: {
    ...Icons.icon_32,
    ...Positions.relative,
    ...Positions.top_6,
    ...Positions.right_10,
    marginBottom: -2,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  text_0: {
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Spacings.margin_bottom_16,
    ...Colors.font_222,
  },
  text_1: {
    width: 250,
    ...Texts.font_size_14,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.margin_bottom_35,
    ...Spacings.padding_left_30,
    ...Spacings.padding_right_30,
    ...Arranges.text_align_center,
    ...Texts.font_letter_spacing_056,
    ...Colors.font_222,
  },
  modal_footer: {
    height: 48,
  },
  modal_footer_two: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    height: 48,
  },
  button: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
  },
  purple: {
    ...Colors.background_6c2773,
  },
  black: {
    ...Colors.background_222,
  },
  white: {
    ...Colors.background_fff,
  },
  footer_button_text: {
    ...Texts.button_text_4,
    ...Texts.font_size_16,
  },

  button_line_black: {
    ...Colors.two_btn_border_color_666666,
    width: 1,
    borderWidth: 0.5,
  },
  button_left: {
    ...Spacings.border_bottom_left_radius_12,
    ...Colors.two_btn_border_color_666666,
    borderRightWidth: 0.5,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderStyle: 'solid'
  },
  button_right: {
    ...Spacings.border_bottom_right_radius_12,
  },
  button_two: {
    ...Colors.background_6c2773,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  gray: {
    ...Colors.background_eeeeee,
  },
  footer_button_gray_text: {
    ...Colors.font_666666,
  },
};
