interface CollapsibleProps {
  collapsed?: boolean;
  renderChildrenCollapsed?: boolean;
  children: any;
}

const Collapsible = ({
  collapsed = true,
  renderChildrenCollapsed = true,
  children,
}: CollapsibleProps) => {
  const shouldRenderChildren = renderChildrenCollapsed || !collapsed;

  return (
    <div
      style={{
        transition: "0.2s ease-in-out",
        overflow: "hidden",
        height: !collapsed ? "auto" : 0,
      }}
    >
      {shouldRenderChildren && children}
    </div>
  );
};

export default Collapsible;
