import emojiRegex from "emoji-regex";
import { forwardRef, LegacyRef, useEffect, useState } from "react";
import { useRef } from "react";

const MaxLengthTextInput = (
  {
    maxLength,
    style,
    accessibilityLabel,
    placeholder,
    placeholderTextColor,
    multiline,
    value,
    onChangeText,
    type,
    disabled,
    ...props
  }: any & { type?: "input" | "textarea" },
  ref?: LegacyRef<any>
) => {
  const [_inputValue, setInputValue] = useState(value);
  const [_inputLength, setInputLength] = useState(maxLength || 0);
  const inputRef = useRef<any>(null);
  const calcLength = (text: string) => {
    if (maxLength) setInputLength(maxLength);

    const regex = emojiRegex();
    let stringText = text;
    let lastIndex = maxLength || 0;
    let mixedCount = text.length;
    let pureString = text;
    let emojiCount = 0;

    for (const match of text.matchAll(regex)) {
      const emoji = match[0];
      const emojiIndex = stringText.indexOf(emoji);

      emojiCount = emojiCount + 1;
      pureString = stringText.slice(0, emojiIndex);
      stringText = stringText.replace(emoji, "");

      mixedCount = pureString?.length + emojiCount;
      lastIndex = emojiCount * 2 + pureString?.length;

      if (mixedCount <= (maxLength || 0) + 1) {
        setInputLength(lastIndex);
      } else {
        break;
      }
    }

    if (mixedCount <= (maxLength || 0) + 1) {
      setInputValue(text);
      if (onChangeText !== undefined) onChangeText(text);
    } else {
      const slicedText = text.slice(0, lastIndex);
      setInputValue(slicedText);
      if (onChangeText !== undefined) onChangeText(slicedText);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  if (type === "textarea") {
    return (
      <textarea
        style={style}
        aria-label={accessibilityLabel}
        placeholder={placeholder}
        placeholderTextColor={placeholderTextColor}
        multiline={multiline}
        value={_inputValue}
        ref={ref || inputRef}
        onBlur={() => {
          const subMaxLength =
            _inputLength > (maxLength || 0) ? _inputLength - 1 : maxLength
          const subStr =
            _inputValue?.slice(0, subMaxLength) || _inputValue || ""
          setInputValue(subStr)
          if (onChangeText !== undefined) onChangeText(subStr)
        }}
        onChange={(e) => {
          calcLength(e.target.value)
        }}
        scrollEnabled={false}
        {...props}
      />
    )
  }

  return (
    <input
      style={style}
      aria-label={accessibilityLabel}
      placeholder={placeholder}
      placeholderTextColor={placeholderTextColor}
      multiline={multiline}
      disabled={disabled}
      value={_inputValue}
      ref={ref || inputRef}
      onBlur={() => {
        const subMaxLength =
          _inputLength > (maxLength || 0) ? _inputLength - 1 : maxLength;
        const subStr = _inputValue?.slice(0, subMaxLength) || _inputValue || "";
        setInputValue(subStr);
        if (onChangeText !== undefined) onChangeText(subStr);
      }}
      onChange={(e) => {
        calcLength(e.target.value);
      }}
      scrollEnabled={false}
      {...props}
    />
  );
};

export default forwardRef(MaxLengthTextInput);
