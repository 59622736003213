import React, { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as VideoIcon } from "../../../assets/icons/badge_video.svg";
import { ReactComponent as MultiImageIcon } from "../../../assets/icons/multi_img.svg";
import { ReactComponent as ActiveQuestionIcon } from "../../../assets/icons/question_active.svg";
import SearchCountAndSort from "./SearchCountAndSort";
import SearchEmpty from "./SearchEmpty";
import { observer } from "mobx-react";
import SearchStore from "../../../store/SearchStore";
import { SearchTabMenu, SEARCH_RESULT_COUNT } from "../../../constants/Search";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import {
  BrandCommunityArticleSearchItem,
  BrandCommunitySearchItem,
  SearchItem,
} from "../../../api/search/model";
import { getDateStr, getCompactDateStr } from "../../../utils/datetime";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import CommonStore from "../../../store/CommonStore";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { ReactComponent as MallTag } from "../../../assets/icons/mall_sale_tag.svg";
import { ReactComponent as CommentIcon } from "../../../assets/icons/new_comment_gray.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/new_heart_off.svg";
import { ReactComponent as ViewIcon } from "../../../assets/icons/icon_view.svg";
import RemoteConfigStore from "../../../store/RemoteConfigStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Slider, { Settings } from "react-slick";
import { sleep } from "antd-mobile/es/utils/sleep";
import { ReactComponent as DarkSpinner } from "../../../assets/animations/darkSpinner.svg";
import { PullToRefresh } from "antd-mobile";
import { getSessionUserFromStorage } from "../../../service/Session.service";
import "./SearchTotal.scss";
import MainStore from "../../../store/MainStore";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import { useLocation } from "react-router-dom";
import SearchResultLayout from "../SearchResultLayout";
import AuthStore from "../../../store/AuthStore";

const SearchTotal = observer((route: any) => {
  const { widthApp } = CommonStore;
  const navigation = route?.navigation;
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";
  const {
    searchText,
    totalList,
    totalCount,
    totalSortAndIndex,
    refreshing,
    selectedSearchTab,
    posterSortAndIndex,
    getSelectedSearchTab,
    setRefreshing,
    getSearchResult,
    setSelectedTab,
    needToSearch,
    refreshTotal,
    getSortType,
    setSearchFocus,
    setSearchText,
  } = SearchStore;
  const ref = useRef<any>(null);
  const [isClickItem, setIsClickItem] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const moveTab = (tabName: SearchTabMenu) => {
    const isActive = getSelectedSearchTab === tabName;
    if (!isActive) {
      onSearch(searchText, tabName);
    }
  };

  const onSearch = async (
    text: string,
    goToTab: string = SearchTabMenu.Total
  ) => {
    if (text.length > 0) {
      setSearchFocus(false);
      setSearchText(text);
      setSelectedTab(goToTab as SearchTabMenu);

      if (needToSearch(goToTab)) {
        void getSearchResult(
          text,
          goToTab,
          getSortType(goToTab),
          SEARCH_RESULT_COUNT,
          0
        );
      }
      goTo(`/Search/${text}/${goToTab}`);
    } else {
      const msg = t("screen.search.message.noSearchText");
      SearchStore.setToast(msg);
    }
  };

  const moveToPostDetail = (item: any, index: number) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.POST_READ,
      (item?.feedId ?? 0).toString(),
      ActPageCode.SEARCH_TOTAL,
      ActSectionCode.POST,
      (index ?? 0).toString(),
      searchText
    );

    goTo("/FeedScreen", {
      state: {
        itemIndex: index,
        feedParentTypeCode: "SEARCH_TOTAL_POSTS",
        extraData: {
          searchParams: {
            keyword: searchText,
            tab: selectedSearchTab,
            sort: posterSortAndIndex.sort,
          },
        },
      },
    });
  };

  const moveToProductDetail = (item: SearchItem, index: number) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.PRODUCT_READ,
      (item.productId ?? 0).toString(),
      ActPageCode.SEARCH_TOTAL,
      ActSectionCode.PRODUCT,
      index?.toString(),
      searchText
    );
    goTo(`/Product/${item.productId}`);
  };

  const moveToQnaDetail = (item: SearchItem, index: number) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.POST_READ,
      (item.feedId ?? 0).toString(),
      ActPageCode.SEARCH_TOTAL,
      ActSectionCode.QNA,
      index?.toString(),
      searchText
    );

    goTo(`/QnA/${item.feedId}`, {
      state: {
        feedId: item.feedId,
      },
    });
  };

  const moveToUserHome = (item: SearchItem, index: number) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.USER_CLICK,
      item.memberUuid || "",
      ActPageCode.SEARCH,
      ActSectionCode.SEARCH_TOTAL,
      index.toString(),
      searchText
    );

    getSessionUserFromStorage()
      .then((res) => {
        if (res) {
          goTo(`/user/${item.nickname}`, {
            state: {
              targetUserId: item.memberUuid,
              nickname: item.nickname,
              profileUrl: item.profileUrl,
              profileBackgroundColor: item.profileBackgroundColor,
            },
          });
        } else {
          MainStore.setShowJoinModal(true);
        }
      })
      .catch(() => {
        MainStore.setShowJoinModal(true);
      });
  };

  const moveToTagDetail = (item: SearchItem) => {
    CommonStore.callActHistory(
      ActHistoryTypeCode.TAG_CLICK,
      (item.tagId ?? 0).toString(),
      ActPageCode.SEARCH_TOTAL,
      ActSectionCode.TAG,
      item.tagId?.toString(),
      searchText
    );

    if (!AuthStore.sessionUser?.memberUuid) {
      MainStore.setShowJoinModal(true);
    } else {
      goTo(`/tag/${item.tagId}`, {
        state: { tagId: item.tagId },
      });
    }
  };

  const handleBrandPress = useCallback(
    (item: BrandCommunitySearchItem, index: number) => () => {
      // navigate("MainTab", {
      //   screen: "BrandCommunityHome",
      //   params: { targetBrandId: item.communityId.toString() },
      // });
    },
    []
  );

  const handleArticlePress = useCallback(
    (item: BrandCommunityArticleSearchItem, index: number) => () => {
      // push("ArticleDetailScreen", { articleId: item.articleId, index });
    },
    []
  );

  const addIcon = (item?: any) => {
    if (item === undefined) return <></>;
    return (
      <div className="image_icon">
        {(item.videoCount || 0) + (item.imageCount || 0) > 1 ? (
          <MultiImageIcon />
        ) : (item.videoCount || 0) === 1 ? (
          <VideoIcon />
        ) : (
          <></>
        )}
      </div>
    );
  };

  const normalGrid = (item: SearchItem, index: number) => {
    return (
      <div
        className={clsx(
          "data_item_wrap",
          `data_item_${index + 1} ${
            totalList.postList.length === 6 ? "group_full" : ""
          }`
        )}
        onClick={() => moveToPostDetail(item, index)}
        key={item.feedId?.toString()}
        // aria-label={`Post search item ${index}`}
      >
        <FastImageWithFallback
          wrapperClassName="image_wrap_inner"
          source={{
            uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
          }}
          fallbackImageUri={`${PUBLIC_BUCKET_URL}${
            item.thumbnailFilePath?.replace("THUMBNAIL", "ORIGINAL") || ""
          }`}
        />
        {addIcon(item)}
      </div>
    );
  };

  const renderPostList = () => {
    return (
      totalList.postList?.length > 0 && (
        <>
          <div
            className={`section_title_wrap section_title_wrap_ex`}
            aria-label="Post result"
          >
            <div className="section_title">
              {t("screen.search.button.post")}
            </div>
            <div className="section_number">{totalList.postCount}</div>
          </div>

          <div className="post_list_wrap">
            {totalList.postList?.map((item: any, index: number) =>
              normalGrid(item, index)
            )}
          </div>
          <div className="add_detail_btn_wrap">
            <div
              className="add_detail_btn"
              onClick={() => moveTab(SearchTabMenu.Post)}
              aria-label="Move to post tab"
            >
              <div className="add_detail_btn_text">
                {t("screen.search.button.more")}
              </div>
            </div>
          </div>
        </>
      )
    );
  };

  const renderProductList = () => {
    const settings: Settings = {
      dots: false,
      infinite: false,
      arrows: false,
      slidesToScroll: 2,
      slidesToShow: 2,
      variableWidth: true,
      focusOnSelect: true,
      beforeChange: () => {
        setIsClickItem(false);
      },
      afterChange: () => {
        setTimeout(() => {
          setIsClickItem(true);
        }, 100);
      },
    };
    return (
      totalList?.productList?.length > 0 && (
        <div className="product_list_wrap">
          <div className="section_title_wrap" aria-label="Product result">
            <div className="section_title">
              {t("screen.search.button.product")}
            </div>
            <div className="section_number">{totalList.productCount}</div>
          </div>

          <Slider {...settings}>
            {totalList.productList.map((item: any, index: number) => {
              return (
                <div
                  style={{
                    width: index === 0 ? widthApp / 2 - 8 : widthApp / 2 - 20,
                    padding: "0 4px",
                  }}
                  className={`relation_product_item relation_product_item_horizontal ${
                    index === 0 ? "relation_product_first_item" : ""
                  }`}
                  onClick={
                    isClickItem
                      ? () => moveToProductDetail(item, index)
                      : () => {}
                  }
                >
                  <FastImageWithFallback
                    wrapperClassName="relation_product_image_inner"
                    className="relation_product_image"
                    aria-label={`Product image ${index}`}
                    source={{
                      uri: `${PUBLIC_BUCKET_URL}${
                        item.thumbnailFilePath || ""
                      }`,
                    }}
                    fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                      item.thumbnailFilePath?.replace(
                        "THUMBNAIL",
                        "ORIGINAL"
                      ) || ""
                    }`}
                  />
                  <div
                    className="relation_product_item_text1"
                    aria-label={`Product name ${index}`}
                  >
                    {item.productName}
                  </div>
                  <div className="relation_product_info">
                    <div
                      className="relation_product_item_text2"
                      aria-label={`Product brand name ${index}`}
                    >
                      {item.brandNameKor}
                    </div>
                    <div className="bar">
                      <div></div>
                    </div>
                    <div
                      className="relation_product_item_text2"
                      aria-label={`Product model name ${index}`}
                    >
                      {item.productModelName}
                    </div>
                  </div>
                  {item.lifecareProductId && item.lifecareProductId !== null ? (
                    <div className="on_sale_tag_wrap">
                      <div className="mall_tag">
                        <MallTag />
                      </div>
                      <div className="on_sale_tag_text">
                        {t("screen.main.label.onSale")}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </Slider>

          <div className="add_detail_btn_wrap">
            <div
              className="add_detail_btn"
              onClick={() => moveTab(SearchTabMenu.Product)}
              aria-label="Move to product tab"
            >
              <div className="add_detail_btn_text">
                {t("screen.search.button.more")}
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderBrandCommunityList = () => {
    const settings: Settings = {
      dots: false,
      infinite: false,
      arrows: false,
      slidesToScroll: 2,
      slidesToShow: 2,
      variableWidth: true,
    };
    return (
      RemoteConfigStore.show_brand_page === "true" &&
      totalList.brandCommunityList?.length > 0 && (
        <>
          <div
            className="section_title_wrap"
            aria-label="BrandCommunity result"
          >
            <div className="section_title">
              {t("screen.search.button.brand-community")}
            </div>
            <div className="section_number">
              {totalList.brandCommunityCount}
            </div>
          </div>
          {totalList.brandCommunityList &&
            totalList.brandCommunityList.length > 0 &&
            totalList.brandCommunityList.map((item: any, index: number) => {
              return (
                <div
                  className="comm_item"
                  style={index === 0 ? { marginLeft: 16 } : {}}
                  onClick={handleBrandPress(item, index)}
                >
                  <FastImageWithFallback
                    className="bx_brand_logo"
                    // resizeMode="cover"
                    source={{
                      uri: `${PUBLIC_BUCKET_URL}${item.communityLogoUrl ?? ""}`,
                    }}
                    fallbackImageUri={FALLBACK_IMAGE_URL}
                  />
                  <div className="txt_brand_name">{item.communityName}</div>
                </div>
              );
            })}

          <div className="add_detail_btn_wrap">
            <div
              className="add_detail_btn"
              onClick={() => moveTab(SearchTabMenu.Brand)}
              aria-label="Move to Brand Community tab"
            >
              <div className="add_detail_btn_text">
                {t("screen.search.button.more")}
              </div>
            </div>
          </div>
        </>
      )
    );
  };

  const renderBrandCommunityArticleList = () => {
    const settings: Settings = {
      dots: false,
      infinite: false,
      arrows: false,
      slidesToScroll: 2,
      slidesToShow: 2,
      variableWidth: true,
    };
    return (
      RemoteConfigStore.show_brand_page === "true" &&
      totalList.brandCommunityArticleList?.length > 0 && (
        <>
          <div
            className="section_title_wrap"
            aria-label="BrandCommunityArticle result"
          >
            <div className="section_title">
              {t("screen.search.button.brand-community-article")}
            </div>
            <div className="section_number">
              {totalList.brandCommunityArticleCount}
            </div>
          </div>

          {totalList.brandCommunityArticleList &&
            totalList.brandCommunityArticleList.length > 0 &&
            totalList.brandCommunityArticleList.map(
              (item: any, index: number) => {
                const isEnd =
                  index === totalList.brandCommunityArticleList.length - 1;
                return (
                  <div
                    className="list_wrap"
                    style={
                      index === 0
                        ? { paddingTop: 0 }
                        : isEnd
                        ? { borderBottomWidth: 0 }
                        : {}
                    }
                    onClick={handleArticlePress(item, index)}
                  >
                    <div className="bx_list_contents">
                      <div className="bx_list_title">
                        <div style={{ position: "relative" }}>
                          <div
                            className={`${
                              item.articleTypeName ===
                              t(
                                "screen.brandCommunity.articleList.tabtype.notice"
                              )
                                ? "notice_badge_back"
                                : ""
                            }`}
                          >
                            {item.articleTypeName ===
                              t(
                                "screen.brandCommunity.articleList.tabtype.notice"
                              ) && (
                              <div className="notice_badge_text">
                                {item.articleTypeName}
                              </div>
                            )}
                          </div>
                          <div className="txt_list_title">
                            <div
                              className="list_flag_txt"
                              style={
                                item.articleTypeName ===
                                t(
                                  "screen.brandCommunity.articleList.tabtype.notice"
                                )
                                  ? {
                                      opacity: 0,
                                      color: "rgba(0,0,0,0)",
                                    }
                                  : {}
                              }
                            >
                              {item.articleTypeName}
                            </div>
                            <div
                              style={
                                item.articleTypeName ===
                                t(
                                  "screen.brandCommunity.articleList.tabtype.notice"
                                )
                                  ? { width: 14 }
                                  : { width: 8 }
                              }
                            />
                            {item.articleTitle}
                          </div>
                        </div>
                      </div>
                      <div className="bx_list_info">
                        <div className="txt_info_name">
                          {item.communityName &&
                            (item.communityName.length > 10
                              ? item.communityName.substring(0, 8).concat("...")
                              : item.communityName)}
                        </div>
                        <div className="line_vertical" />
                        <div className="bx_list_num">
                          <div className="bx_list_num_item">
                            <CommentIcon />
                            <div className="txt_list_num_item">
                              {item.articleCommentCount}
                            </div>
                          </div>
                          <div className="bx_list_num_item">
                            <ViewIcon />
                            <div className="txt_list_num_item">
                              {item.articleViewCount}
                            </div>
                          </div>
                          <div className="bx_list_num_item">
                            <HeartIcon />
                            <div className="txt_list_num_item">
                              {item.articleLikeCount}
                            </div>
                          </div>
                        </div>
                        <div className="line_vertical" />
                        <div className="bx_list_num">
                          <div className="bx_list_num_item">
                            <div className="txt_list_num_item">
                              {getCompactDateStr(item.createdDatetime)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="list_img_wrap"
                      style={
                        item.thumbnailFilePath == null
                          ? {
                              display: "none",
                            }
                          : {}
                      }
                    >
                      <div className="bx_pic_num">
                        <div className="txt_pic_num">{item.fileCount}</div>
                      </div>
                      <FastImageWithFallback
                        className="list_img"
                        aria-label={`Article image ${index}`}
                        source={{
                          uri: `${PUBLIC_BUCKET_URL}${
                            item.thumbnailFilePath || ""
                          }`,
                        }}
                        fallbackImageUri={FALLBACK_IMAGE_URL}
                      />
                    </div>
                  </div>
                );
              }
            )}

          <div className="add_detail_btn_wrap">
            <div
              className="add_detail_btn"
              onClick={() => moveTab(SearchTabMenu.BrandArticle)}
              aria-label="Move to Brand Community Article tab"
            >
              <div className="add_detail_btn_text">
                {t("screen.search.button.more")}
              </div>
            </div>
          </div>
        </>
      )
    );
  };

  const renderQnaList = () => {
    return (
      totalList.qnaList?.length > 0 && (
        <>
          <div className="section_title_wrap" aria-label="Qna result">
            <div className="section_title">{t("screen.search.button.qna")}</div>
            <div className="section_number">{totalList.qnaCount}</div>
          </div>

          <div className="qna_wrap qna_list_wrap">
            {totalList.qnaList?.map((item: SearchItem, index: number) => (
              <div
                style={{ width: "100%" }}
                onClick={() => moveToQnaDetail(item, index)}
                key={index.toString()}
              >
                <>
                  <div className="qna_item_wrap">
                    <div className="qna_item">
                      <ActiveQuestionIcon />
                      <div className="qna_txt_wrap">
                        <div className="qna_text1">{item.feedTitle}</div>
                        <div
                          className={clsx(
                            "qna_text2",
                            item.thumbnailFilePath !== undefined &&
                              item?.thumbnailFilePath?.length > 0 &&
                              "qna_text2_with_img"
                          )}
                        >
                          {item.contents}
                        </div>
                      </div>
                      {item.thumbnailFilePath !== undefined &&
                        item.thumbnailFilePath.length > 0 && (
                          <FastImageWithFallback
                            className="qna_img"
                            aria-label={`Qna image ${index}`}
                            source={{
                              uri: `${PUBLIC_BUCKET_URL}${
                                item.thumbnailFilePath || ""
                              }`,
                            }}
                            fallbackImageUri={`${PUBLIC_BUCKET_URL}${
                              item.thumbnailFilePath?.replace(
                                "THUMBNAIL",
                                "ORIGINAL"
                              ) || ""
                            }`}
                          />
                        )}
                    </div>
                    <div className="qna_info">
                      <div className="qna_info_first">
                        <TouchableWithAuthCheck
                          onPress={() => moveToUserHome(item, index)}
                          aria-label={`Go to UserHomeScreen ${index}`}
                        >
                          <div
                            className="info_txt1"
                            aria-label={`Qna nickname ${index}`}
                          >
                            {item.nickname}
                          </div>
                        </TouchableWithAuthCheck>
                        <div className="qna_bar" />
                        <div
                          className="info_txt2"
                          aria-label={`Qna datetime ${index}`}
                        >
                          {getDateStr(item.createdDatetime)}
                        </div>
                      </div>
                      <div className="qna_info_second">
                        <div
                          className="info_txt2"
                          aria-label={`Qna reply ${index}`}
                        >
                          {t("screen.search.label.reply")} {item.commentCount}
                        </div>
                        <div className="qna_bar" />
                        <div
                          className="info_txt2"
                          aria-label={`Qna like ${index}`}
                        >
                          {t("screen.search.label.like")} {item.likeCount}
                        </div>
                      </div>
                    </div>
                  </div>
                  {index !== totalList.qnaList.length - 1 && (
                    <div className="qna_item_wrap_padding qna_item_wrap_divider" />
                  )}
                </>
              </div>
            ))}
          </div>

          <div className="add_detail_btn_wrap">
            <div
              className="add_detail_btn"
              onClick={() => moveTab(SearchTabMenu.Qna)}
              aria-label="Move to qna tab"
            >
              <div className="add_detail_btn_text">
                {t("screen.search.button.more")}
              </div>
            </div>
          </div>
        </>
      )
    );
  };

  const renderPosterList = () => {
    const settings: Settings = {
      dots: false,
      infinite: false,
      arrows: false,
      slidesToScroll: 3,
      slidesToShow: Math.floor(widthApp / 80),
      variableWidth: true,
      focusOnSelect: true,
      beforeChange: () => {
        setIsClickItem(false);
      },
      afterChange: () => {
        setTimeout(() => {
          setIsClickItem(true);
        }, 100);
      },
    };

    return (
      totalList.posterList?.length > 0 && (
        <div className="poster_list_wrap">
          <div className="section_title_wrap" aria-label="Poster result">
            <div className="section_title">
              {t("screen.search.button.poster")}
            </div>
            <div className="section_number">{totalList.posterCount}</div>
          </div>

          <Slider {...settings}>
            {totalList.posterList &&
              totalList.posterList.length > 0 &&
              totalList.posterList.map((item: any, index: number) => {
                return (
                  <div
                    onClick={
                      isClickItem ? () => moveToUserHome(item, index) : () => {}
                    }
                  >
                    <div
                      className={`recommend_user_item ${
                        index === 0 ? "first_recommend_user_item" : ""
                      }`}
                    >
                      <FastImageWithFallback
                        className="recommend_user_image"
                        source={{
                          uri: `${PUBLIC_BUCKET_URL}${item.profileUrl || ""}`,
                        }}
                        fallbackImageUri={FALLBACK_IMAGE_URL}
                        aria-label={`Poster image ${index}`}
                      />
                      <div
                        className="recommend_user_text2"
                        aria-label={`Poster nickname ${index}`}
                      >
                        {item.nickname}
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>

          <div className="add_detail_btn_wrap">
            <div
              className="add_detail_btn"
              onClick={() => moveTab(SearchTabMenu.Poster)}
              aria-label="Move to poster tab"
            >
              <div className="add_detail_btn_text">
                {t("screen.search.button.more")}
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const renderTagList = () => {
    return (
      totalList.tagList?.length > 0 && (
        <>
          <div className="section_title_wrap" aria-label="Tag result">
            <div className="section_title">{t("screen.search.button.tag")}</div>
            <div className="section_number">{totalList.tagCount}</div>
          </div>

          <div className="recommend_noting_tag_wrap">
            <div className="recommend_noting_tag_list_wrap">
              {totalList.tagList?.map((item: SearchItem, index: number) => (
                <div
                  onClick={() => moveToTagDetail(item)}
                  aria-label={`Tag item ${index}`}
                  key={index.toString()}
                >
                  <div className="recommend_tag_item_bottom">
                    <div className="recommend_tag_item_text">
                      #{item.tagName}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="add_detail_btn_wrap">
            <div
              className="add_detail_btn"
              onClick={() => moveTab(SearchTabMenu.Tag)}
              aria-label="Move to tag tab"
            >
              <div className="add_detail_btn_text">
                {t("screen.search.button.more")}
              </div>
            </div>
          </div>
        </>
      )
    );
  };

  return (
    <SearchResultLayout tabType={SearchTabMenu.Total}>
      <div className="tab_content" aria-label="Search total">
        <PullToRefresh
          onRefresh={async () => {
            await sleep(1000);
            setRefreshing(true);
            refreshTotal();
          }}
          renderText={(status) => {
            return (
              <div>
                {status === "refreshing" && (
                  <DarkSpinner style={{ width: 50, height: 50 }} />
                )}
              </div>
            );
          }}
        >
          <SearchCountAndSort
            type={SearchTabMenu.Total}
            sort={totalSortAndIndex.sort}
            count={totalCount}
          />
          <div className="scroll_wrap" ref={ref}>
            {totalCount === 0 ? (
              <SearchEmpty isGrid={true} />
            ) : (
              <>
                {renderPostList()}
                {renderProductList()}
                {/* {renderBrandCommunityList} */}
                {/* {renderBrandCommunityArticleList} */}
                {renderQnaList()}
                {renderPosterList()}
                {renderTagList()}
              </>
            )}
          </div>
        </PullToRefresh>
      </div>
    </SearchResultLayout>
  );
});

export default SearchTotal;
