import React, { useRef, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
  Icons,
  Positions,
} from "../../../assets/styles";
import { MemberItem } from "../../../api/member/model";

import QnaWriteStore from "../../../store/QnaWriteStore";
import { ReactComponent as ClosePurple } from "../../../assets/icons/close_purple_background.svg";
import { ReactComponent as AddPurple } from "../../../assets/icons/add_purple.svg";
import KeywordText from "../../../components/common/KeywordText";
import { FeedMode } from "../../../constants/Feed";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./QnaWriteUsers.scss";

const QnaWriteUsers = observer(
  ({
    onTagInputFocus,
    onIsVisibleTagList,
  }: {
    onTagInputFocus?: (() => void) | undefined;
    onIsVisibleTagList?: ((isVisible: boolean) => void) | undefined;
  }) => {
    const {
      userUpdated,
      userList,
      userSearchResult,
      isVisibleUserList,
      recommendUsers,
      tempProductList,
      productUpdated,
      setVisibleUserList,
      searchUsers,
      addUser,
      deleteUser,
      setUserSearchResult,
      searchUsersAndAdd,
    } = QnaWriteStore;

    const { t } = useTranslation();
    const keyboardRef = useRef<HTMLInputElement>(null);
    const [keyword, setKeyword] = useState("");
    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    useEffect(() => {
      void QnaWriteStore.getRecommendUser();
    }, [productUpdated]);

    const [onVisibleState, setOnVisibleState] = useState(false);
    useEffect(() => {
      if (
        !onVisibleState &&
        userSearchResult != null &&
        userSearchResult.length > 0
      ) {
        setOnVisibleState(true);
        //        console.log("useEffect setOnVisibleState true:");
        userSearchResult != null &&
          onIsVisibleTagList &&
          onIsVisibleTagList(isVisibleUserList);
      } else if (
        onVisibleState &&
        (userSearchResult === null ||
          (userSearchResult && userSearchResult.length === 0))
      ) {
        setOnVisibleState(false);
      }
    }, [
      userSearchResult,
      onIsVisibleTagList,
      isVisibleUserList,
      onVisibleState,
    ]);
    return (
      <div id="qna_request_user">
        <div className="reply_request">
          <div
            className="reply_input_wrap"
            onClick={() => keyboardRef.current?.focus()}
            // activeOpacity={1}
          >
            <div className="reply_input_inner">
              <p className="reply_title_text_2" aria-label="Answerer">
                {t("screen.qnaWrite.label.answerer")}
              </p>
              <input
                aria-label="User search"
                className="reply_input"
                ref={keyboardRef}
                placeholder={t("screen.qnaWrite.placeHolder.inputNickname")}
                onFocus={() => {
                  onTagInputFocus && onTagInputFocus();
                  setVisibleUserList(true);
                }}
                value={keyword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setVisibleUserList(true);
                  const trimedText = e.target.value.trim();
                  setKeyword(trimedText);
                  if (
                    trimedText !== undefined &&
                    trimedText !== null &&
                    trimedText?.length > 0
                  )
                    void searchUsers(trimedText.toLowerCase());
                  else setUserSearchResult(null);
                }}
                // TODO : submit 후 로직 복구
                // onSubmitEditing={(e) => {
                //   const nickname = e.nativeEvent.text.trim();
                //   if (
                //     nickname === null ||
                //     nickname === undefined ||
                //     nickname?.length < 1
                //   )
                //     return;
                //   void searchUsersAndAdd(nickname.toLowerCase());
                //   keyboardRef.current?.clear();
                // }}
              />
            </div>
          </div>
          {isVisibleUserList &&
            userSearchResult !== null &&
            userSearchResult !== undefined &&
            userSearchResult?.length > 0 && (
              <div className="auto_complete_list" aria-label="Qna user list">
                {userSearchResult.map((item, index) => (
                  <div
                    className="auto_complete_item"
                    onClick={() => {
                      addUser(item);
                      setUserSearchResult(null);
                      setKeyword("");
                    }}
                    key={`${item.memberUuid}_${index}`}
                  >
                    <LazyLoadImage
                      aria-label="User image"
                      src={`${PUBLIC_BUCKET_URL}${item.profileUrl || ""}`}
                      className="auto_complete_img"
                    />
                    <div className="nickname-wrap">
                      <KeywordText
                        containerStyle={{
                          fontSize: 13,
                          letterSpacing: -0.52,
                          color: "#222",
                        }}
                        sentence={item.nickname}
                        keyword={keyword}
                        aria-label="User name"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
        </div>

        {/* selected user */}
        <div className="selected_user" aria-label="Selected answerer">
          {userList.map((item, index) => (
            <div
              onClick={() => deleteUser(index)}
              className="selected_user_item"
              key={`${item.contents.memberUuid}_${index}`}
            >
              <div className="image_wrap" aria-label={`selectedUser${index}`}>
                {item.modified === true && (
                  <div className="close_btn icon_img">
                    <ClosePurple />
                  </div>
                )}
                <LazyLoadImage
                  src={`${PUBLIC_BUCKET_URL}${item.contents.profileUrl || ""}`}
                  className="auto_complete_img"
                />
                <p className="nickname">{item.contents.nickname}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="request_bar" />

        {/* recommended user */}
        <div className="recommended_user" aria-label="Recommend answerer">
          {recommendUsers.map((item, index) => (
            <div
              onClick={() => addUser(item)}
              key={`${item.memberUuid}_${index}`}
              className="recommended_user_item"
            >
              <div className="image_wrap" aria-label={`recommendedUser${index}`}>
                <div className="icon_img">
                  <AddPurple />
                </div>
                <LazyLoadImage
                  src={`${PUBLIC_BUCKET_URL}${item.profileUrl || ""}`}
                  className="auto_complete_img"
                />
                <p className="nickname">{item.nickname}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export default QnaWriteUsers;
