import { observer } from "mobx-react";
import PreferenceMain from "./presenter/PreferenceMain";
// import PreferenceMainV2 from "./v2/PreferenceMainV2";
import { getStringRemoteConfig } from "../../utils/remoteConfig";

const PreferenceScreen = observer(() => {
  const joinStyle = getStringRemoteConfig("joinStyle");

  return (
    <PreferenceMain />
    // <>
    //   {joinStyle.toUpperCase() === "A".toUpperCase() ? (
    //     <PreferenceMain />
    //   ) : (
    //     <PreferenceMainV2 />
    //   )}
    // </>
  );
});

export default PreferenceScreen;
