import { observer } from 'mobx-react'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/mall/icon_filter_del.svg'
import { Attribute_vlues, Attributes } from '../../../api/mallCategory/model'
import { Virtuoso } from 'react-virtuoso'
import clsx from 'clsx'
import classes from './FilterList.module.scss'
import useDragScrollWithMouse from '../../../hooks/useDragScrollWithMouse'
import { useRef } from 'react'
import FilterItem from './FilterItem'
import CommonStore from '../../../store/CommonStore'

const FilterList = observer(
  ({
    renderData,
    selectFilter,
    isFilterChange,
  }: {
    renderData: Attributes[]
    selectFilter: (key: number, name: string, item: Attribute_vlues) => void
    isFilterChange: boolean
  }) => {
    
    const widthApp = CommonStore.widthApp;

    return (
      <div style={{ width: widthApp}}>
        {/* [5239] 타이틀 미노출  */}
        {/* <div className={classes.bx_filter_title}>
          <FindFillterIcon />
          <text className={classes.txt_filter_title}>필터로 원하는 상품 찾기</text>
        </div> */}

        {renderData &&
          renderData.map((att, index) => {
            return att.attributeValueList.length > 0 ? (
              <div className={classes.bx_filter_select} key={index}>
                <div className={classes.bx_filter_label}>
                  <span className={classes.txt_filter_label}>
                    {att.attributeName}
                  </span>
                </div>
                <FilterItem att={att} selectFilter={selectFilter} />
                {/* <Virtuoso
                  horizontalDirection
                  className={classes.bx_filter_list}
                  // showsHorizontalScrollIndicator={false}0

                  data={att.attributeValueList}
                  computeItemKey={(index, item) =>
                    `FilterList_${item.attributeValueId}_${item.attributeValueId}`
                  }
                  // extraData={isFilterChange}
                  itemContent={(index, item) => {
                    return (
                      <div
                        className={classes.btn_toggle}
                        key={index}
                        onClick={() => {
                          selectFilter(att.attributeId, att.attributeName, item)
                        }}
                      >
                        <text
                          className={clsx(
                            classes.txt_toggle,
                            item.check !== undefined &&
                              item.check &&
                              classes.txt_toggle_on
                          )}
                        >
                          {item.attributeValueName}
                        </text>
                        {item.check !== undefined && item.check && (
                          <DeleteIcon />
                        )}
                      </div>
                    )
                  }}
                /> */}
              </div>
            ) : (
              <></>
            )
          })}
      </div>
    )
  }
)

export default FilterList
