import { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Styles } from "../../assets/types/Style";
import { Arranges, Assets, Colors, FlexBoxs, Icons, Positions, Spacings, Texts } from "../../assets/styles";
import AuthStore from "../../store/AuthStore";
import { BRAND_COMMUNITY_INTRODUCTION } from "../../constants/Storage";
import { ReactComponent as BtnClose } from "../../assets/icons/btn_close_32.svg";
import { ReactComponent as DotLine } from "../../assets/icons/onboarding_line_dot.svg";
import Modal from 'react-modal';
import FastImageWithFallback from "../../components/common/FastImageWithFallback";
import { Platform, PlatformTypes } from "../../native/platform";

const BrandCommunityIntroduction = observer(
  ({ show, onClose, onModalShow }: { show: boolean; onClose: () => void; onModalShow?: () => void }) => {

    const { t } = useTranslation();

    const isAppInstallBanner = useCallback(() => {
      return (
        Platform.OS === PlatformTypes.ANDROID_WEB ||
        Platform.OS === PlatformTypes.IOS_WEB
      );
    }, []);

    return (
      <Modal
        isOpen={show}
        onAfterOpen={onModalShow}
        id="brand-community-introduction-modal"
        style={{
          content: {
            width: "100%",
            // height: "100%",
            ...FlexBoxs.flex,
            ...FlexBoxs.flex_1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            inset: 0,
            zIndex: 999,
           marginTop: isAppInstallBanner() ? 50 : 0
          },
        }}
      >
        <div style={styles.withdraw_reason_select_wrap}>
          <div style={styles.btn_close}>
            <div onClick={() => onClose()}>
              <BtnClose />
            </div>
          </div>
          <p style={styles.withdraw_label_title}>{t("브랜드 커뮤니티")}</p>
          <div style={styles.withdrwa_text_wrap}>
            <p style={styles.withdraw_info_text}>나의 취향과 비슷한 사람들과 함께{"\n"}이야기를 나눠요~</p>
          </div>

          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <div style={styles.brand_pop_content}>
              <div style={styles.content_text_box}>
                <div>
                  <FastImageWithFallback
                    style={styles.in_img}
                    source={{
                      uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/BRAND/onboarding/onboarding_img1.png`,
                    }}
                  />
                </div>
                <div style={styles.text_wrap}>
                  <p style={styles.text_wrap_p}>
                    <span style={styles.content_txt_b}>좋아하는 브랜드</span>를 찾아<br/>
                    <span style={styles.content_txt_b}>참여</span>해 보세요.
                  </p>
                </div>
              </div>
              <div style={styles.bx_dot_line}>
                <DotLine />
              </div>

              <div style={styles.content_text_box}>
                <div>
                  <FastImageWithFallback
                    style={styles.in_img}
                    source={{
                      uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/BRAND/onboarding/onboarding_img2.png`,
                    }}
                  />
                </div>
                <div style={styles.text_wrap}>
                  <p style={styles.text_wrap_p}>
                    내 선호 브랜드에 대해<br/>
                    <span style={styles.content_txt_b}>이야기하고 공유</span>해 보세요.
                  </p>
                </div>
              </div>
              <div style={styles.bx_dot_line}>
                <DotLine />
              </div>

              <div style={{ ...styles.content_text_box, ...styles.content_text_box_last }}>
                <div>
                  <FastImageWithFallback
                    style={styles.in_img}
                    source={{
                      uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/BRAND/onboarding/onboarding_img3.png`,
                    }}
                  />
                </div>
                <div style={styles.text_wrap}>
                  <p style={styles.text_wrap_p}>
                    브랜드에 대한 <span style={styles.content_txt_b}>모든 정보를<br/>확인</span>해보세요.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={styles.bx_modal_footer}>
            <div
              style={styles.confirm_btn}
              onClick={() => {
                if (AuthStore.sessionUser?.memberUuid) {
                  void localStorage.setItem(
                    `${BRAND_COMMUNITY_INTRODUCTION}_${AuthStore.sessionUser?.memberUuid}`,
                    "Y"
                  );
                } else {
                  void localStorage.setItem(
                    `${BRAND_COMMUNITY_INTRODUCTION}_${AuthStore.tempMember?.tempMemberUUID || ""}`,
                    "Y"
                  );
                }
                onClose();
              }}
            >
              <p style={styles.confirm_btn_text}>{t("다시 보지 않기")}</p>
            </div>

            <div
              style={{ ...styles.confirm_btn, ...styles.active }}
              onClick={() => {
                onClose();
              }}
            >
              <p style={{ ...styles.confirm_btn_text, ...styles.btn_text_active }}>{t("입장하기")}</p>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default BrandCommunityIntroduction;

const styles: Styles = {
  withdraw_reason: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: "rgba(34,34,34,0.4)",
    alignItems: "center",
    justifyContent: "center",
  },
  withdraw: {
    ...FlexBoxs.flex,
    ...FlexBoxs.flex_1,
    justifyContent: "center",
  },
  withdraw_reason_select_wrap: {
    position: "relative",
    backgroundColor: "#fff",
    borderRadius: 12,
    paddingTop: 16,
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_h,
    overflow: "hidden",
    width: 'fit-content',
    height: 'fit-content',
  },
  btn_close: {
    position: "absolute",
    top: 6,
    right: 6,
  },
  withdrwa_text_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v,
    width: 220
  },
  withdraw_reason_select_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },

  withdraw_label_title: {
    ...Texts.font_size_18,
    ...Texts.font_weight_bold,
    ...Colors.font_222,
    ...Spacings.margin_bottom_16,
    letterSpacing: -0.4,
  },
  withdraw_info_text: {
    ...Texts.font_weight_normal,
    color: "#222",
    fontWeight: "400",
    letterSpacing: -0.4,
    ...Texts.font_size_16,
    textAlign: "center",
  },
  withdraw_info_text_bold: {
    ...Texts.header_text_0,
  },
  brand_pop_content: {
    width: '100%',
    backgroundColor: "#FCF7FF",
    ...Spacings.margin_top_12,
    ...Spacings.border_radius_12,
    paddingTop: 34,
    paddingBottom: 34,
    paddingLeft: 18,
    paddingRight: 18,
    marginBottom: 26,
  },
  bx_dot_line: {
    overflow: "hidden",
  },
  content_text_box: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.padding_top_bottom_8,
    overflow: "hidden",
  },
  content_text_box_last: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  in_img: {
    width: 72.67,
    height: 72,
    ...FlexBoxs.flex,
    justifyContent: "center",
    alignItems: "center",
  },
  text_wrap: {
    ...Spacings.padding_left_20,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
  },
  text_wrap_p: {
    fontSize: 15,
    color: "#A760B1",
    letterSpacing: -0.4,
  },
  content_txt_b: {
    ...Texts.font_weight_bold,
  },
  reason_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  waring_text: {
    ...Spacings.margin_left_6,
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_ea174a,
  },
  etc_wrap: {
    ...Spacings.padding_left_right_20,
  },
  etc: {
    ...Spacings.padding_top_bottom_20,
    ...Spacings.padding_left_right_20,
    ...Colors.background_fafafa,
  },
  etc_input: {
    ...Texts.contents_text_43,
    ...Colors.font_222,
    paddingTop: 0,
    paddingBottom: 0
  },

  bar: {
    ...Colors.background_f5f5f5,
    ...Spacings.margin_top_bottom_40,
    height: 6,
  },
  footer: {},
  withdraw_label_text_wrap: {
    ...Spacings.padding_left_right_20,
  },
  note_btn_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  note_btn: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Colors.background_f6f4fa,
    ...Spacings.border_radius_4,
    ...Spacings.margin_top_16,
    width: '91.7%',
    height: 53,
  },
  note_btn_text: {
    ...Texts.button_text_3,
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
  agree_wrap: {
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_top_16,
    ...Arranges.start_h,
  },
  agree_text2: {
    ...Texts.contents_text_5,
    ...Texts.font_weight_500,
    ...Colors.font_ea174a,
    ...Spacings.margin_top_5,
    ...Spacings.margin_left_32,
    ...Spacings.margin_bottom_32,
  },
  bx_modal_footer: {
    ...FlexBoxs.flex,
    flexDirection: "row",
    width: '100%'
  },
  confirm_btn: {
    ...Colors.background_eeeeee,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    width: "50%",
    height: 54,
  },
  active: {
    ...Colors.background_682c78,
  },
  confirm_btn_text: {
    ...Texts.contents_text_34,
    ...Texts.font_weight_500,
    ...Colors.font_666666,
  },
  btn_text_active: {
    ...Colors.font_fff,
    ...Texts.font_weight_bold,
  },
  alert_modal: {
    width: '66.6%',
    ...Colors.background_fff,
    ...Spacings.border_top_left_radius_12,
    ...Spacings.border_top_right_radius_12,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
    ...Colors.border_color_transparent,
    ...Arranges.margin_center_v_h,
    ...Assets.modal_box_shadow,
  },
  modal_header: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Positions.relative,
    height: 64,
  },
  modal_body: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
    ...Spacings.padding_bottom_24,
  },
  icons: {
    ...Icons.icon_32,
    ...Positions.absolute,
    ...Positions.top_6,
    ...Positions.right_10,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  modal_footer: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    height: 48,
  },
  separate: {
    ...Colors.two_btn_border_color_666666,
    borderBottomWidth: 0.5,
    borderTopWidth: 0.5,
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    borderStyle: 'solid',
    width: 1,
  },
  left_btn: {
    ...Spacings.border_bottom_left_radius_12,
    ...Colors.background_eeeeee,
  },
  left_btn_text: {
    ...Colors.font_666666,
  },
  right_btn: {
    ...Spacings.border_bottom_right_radius_12,
  },
  button: {
    ...Colors.background_222,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  footer_button_text: {
    ...Texts.button_text_3,
    ...Colors.font_fff,
  },
  contents_text: {
    ...Texts.contents_text_31,
    ...Colors.font_222,
  },
  toast: {
    ...Spacings.border_radius_10,
    ...Spacings.padding_right_left_20,
    ...Spacings.padding_top_bottom_20,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    width: "90%",
    backgroundColor: "black",
  },
  toast_text: {
    ...Texts.contents_text_22,
  },

  note: {
    paddingBottom: 20,
    ...Spacings.padding_right_left_16,
    ...Spacings.border_radius_4,
  },
  note_contents: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.margin_top_2,
  },
  dot: {
    width: 3,
    height: 3,
    ...Colors.background_666,
    ...Spacings.border_radius_4,
    ...Spacings.margin_right_5,
    ...Spacings.margin_top_6,
  },
  note_contents_text1: {
    ...Texts.contents_text_9,
  },
  note_contents_text2: {
    ...Texts.font_weight_bold,
    ...Colors.font_222,
  },
  note_contents_text3: {
    ...Texts.font_weight_bold,
    ...Colors.font_be69c3,
  },
};
