import React, { useEffect, useState } from "react";
// import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";

import "./AbListScreen.scss";
import AbListView from "./presenter/AbListView";
import MyAbListView from "./presenter/MyAbListView";
import BasicHeader from "../../components/headers/BasicHeader";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowRightPurple } from "../../assets/icons/arrow_right_purple3.svg";
import { truncate } from "fs";
import TouchableWithAuthCheck from "../../components/common/TouchableWithAuthCheck";
import CommonStore from "../../store/CommonStore";

export enum AbListScreenTabType {
  AbListTab = "AbListTab",
  myAbListTab = "myAbListTab",
}

const AbListScreen = (data: any) => {
  const { t } = useTranslation();

  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const tabs = [
    {
      name: AbListScreenTabType.AbListTab,
      needAuth: false,
      component: <AbListView />,
    },
    {
      name: AbListScreenTabType.myAbListTab,
      needAuth: true,
      component: <MyAbListView />,
    },
  ];

  return (
    <div className="ab_all_view" aria-label={"AB main screen"}>
      <div className="sticky_container">
        <BasicHeader
          title={t("screen.ab.title.header")}
          isHome
          onSearch={() => {
            CommonStore.setShowDownloadAppDrive(true);
          }}
        />
        <button
          className="go_write_btn_wrap"
          onClick={() => {
            CommonStore.setShowDownloadAppDrive(true);
          }}
        >
          <p className="go_write_btn_wrap_txt1">
            {t(`screen.ab.message.question`)}
          </p>
          <div className="go_write_btn_wrap_txt_wrap">
            <p className="go_write_btn_wrap_txt2">
              {t(`screen.ab.button.goWrite`)}
            </p>
            <ArrowRightPurple />
          </div>
        </button>
        <div className="tabs">
          {tabs.map((tab: { name: string; needAuth: boolean }, index) => {
            const isFocused = currentTabIndex === index;

            const handleTabBarPress = () => {
              setCurrentTabIndex(index);
            };

            return tab.needAuth ? (
              <TouchableWithAuthCheck
                key={index}
                className={isFocused ? "tab_active_btn" : "tab_btn"}
                onPress={handleTabBarPress}
                aria-label="MyAbList"
              >
                <p className={isFocused ? "tab_active_btn_text" : "tab_text"}>
                  {t(`screen.ab.button.${tab.name}`)}
                </p>
              </TouchableWithAuthCheck>
            ) : (
              <button
                key={index}
                className={isFocused ? "tab_active_btn" : "tab_btn"}
                onClick={handleTabBarPress}
                aria-label="AbList"
              >
                <p className={isFocused ? "tab_active_btn_text" : "tab_text"}>
                  {t(`screen.ab.button.${tab.name}`)}
                </p>
              </button>
            );
          })}
        </div>
      </div>
      {tabs[currentTabIndex].component}
    </div>
  );
};
export default AbListScreen;
