import "./App.css";
import { Navigate, redirect, useLocation } from "react-router-dom";
import { fetchAndSetRemoteConfig } from "./utils/remoteConfig";
import CommonStore from "./store/CommonStore";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { observer } from "mobx-react";
import { REDIRECT_PATH, SESSION_USER } from "./constants/Storage";
import AuthStore from "./store/AuthStore";
import CustomActivityIndicator from "./components/common/CustomActivityIndicator";
import NotificationModal from "./components/modals/NotificationModal";
import Toast from "./components/common/Toast";
import MainStore from "./store/MainStore";
import JoinModal from "./components/modals/JoinModal";
import branch from "branch-sdk";
import { DownloadAppDriveBottomSheet } from "./components/common/DownloadAppDriveBottomSheet";
import { TrackingHandlerProvider, TrackingProvider } from "./hooks/tracker";
import { trackerRef } from "./hooks/tracker/TrackFunction";
import { initKakao } from "./types/kakao";
import { ShareBottomSheet } from "./components/common/ShareBottomSheet";
import { RouterInfo } from "./Router";
import { NavigationProvider } from "./hooks/navigate/NavigationContext";
import { setWebUniqueKey } from "./utils/webUniqueKey";
import { getMemberInfo } from "./api/member/api";
import { useWindowScroll } from "react-use";
import CommonTopButton from "./components/common/CommonTopButton";
import AppInstallTopBanner from "./components/common/AppInstallTopBanner";
import WriteButtonStore, {
  INITIAL_WRITE_BUTTON_BOTTOM,
} from "./store/WriteButtonStore";
import WriteButton from "./components/common/WriteButton";
import AppInstallSideBanner from "./components/common/AppInstallSideBanner";
import TabStore from "./store/TabStore";
import { Platform, PlatformTypes } from "./native/platform";
import ReportStore from "./store/ReportStore";
import ReportBottomSheet from "./components/common/ReportBottomSheet";
import ReportConfirmPopup from "./components/common/ReportConfirmPopup";
import SearchStore from "./store/SearchStore";

const ASYNC_FUNC_BEFORE_WEB_INIT: Promise<any>[] = [
  setWebUniqueKey,
  fetchAndSetRemoteConfig,
];

interface AppProp {
  children: React.ReactNode;
  routeName: RouterInfo["routeName"];
  options?: RouterInfo["options"];
}

const App = observer(({ children, routeName, options }: AppProp) => {
  const location = useLocation();
  const [showTopButton, setShowTopButton] = useState<boolean>(false);
  const [lastScrollY, setlastScrollY] = useState<number>(0);
  const [finishInit, setFinishInit] = useState<boolean>(false);

  const { x, y } = useWindowScroll();

  const user = localStorage.getItem(SESSION_USER);
  const { reportBottomSheetProps, ReportConfirmPopupProps } = ReportStore;

  const isLoginSession = useCallback(() => {
    return user && user !== null
      ? JSON.parse(user).nickname && JSON.parse(user).nickname.length > 0
      : false;
  }, [user]);

  useEffect(() => {
    if (isLoginSession()) {
      getMemberInfo(JSON.parse(user || "{}").memberUuid).then((x: any) => {
        AuthStore.setSessionUser(x);
      });
    } else {
      if (options?.nonLoginAccess) {
        AuthStore.setSessionUser(JSON.parse(user || "{}"));
      } else {
        localStorage.removeItem(SESSION_USER);
        AuthStore.setSessionUser(null);
      }
    }
    // 메인 팝업 확인 여부
    MainStore.setConfirmMainPopupList(false);
    branch.init(process.env.REACT_APP_BRANCH_KEY || "", {}, undefined);
    initKakao(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY || "");
  }, [isLoginSession, user, options?.nonLoginAccess]);

  useEffect(() => {
    function getWidthRootElement() {
      const rootElement = document.getElementById("root");
      if (rootElement) {
        return rootElement.offsetWidth;
      }
      return 450;
    }
    function getOffsetLeftRootElement() {
      const rootElement = document.getElementById("root");
      if (rootElement) {
        return rootElement.offsetLeft;
      }
      return 0;
    }
    const width = getWidthRootElement();
    CommonStore.setWidthApp(width);

    const offsetLEft = getOffsetLeftRootElement();
    CommonStore.setOffsetLeftApp(offsetLEft);

    function handleResize() {
      const width = getWidthRootElement();
      CommonStore.setWidthApp(width);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // 공통 버튼 위치 세팅
    if (options?.hasBottomBar) {
      TabStore.setTabFocused(true);
    } else {
      TabStore.setTabFocused(false);
    }

    if (options?.showWriteBtn) {
      WriteButtonStore.setShowWriteButton(true);
    } else {
      WriteButtonStore.setShowWriteButton(false);
    }

    WriteButtonStore.setBottom(
      TabStore.tabFocused
        ? Platform.OS === PlatformTypes.IOS_WEB ||
          Platform.OS === PlatformTypes.IOS_APP
          ? INITIAL_WRITE_BUTTON_BOTTOM + 70
          : INITIAL_WRITE_BUTTON_BOTTOM + 40
        : INITIAL_WRITE_BUTTON_BOTTOM
    );
  }, [options?.showWriteBtn, options?.hasBottomBar]);

  const initBeforeFirstRender = () => {
    Promise.all(ASYNC_FUNC_BEFORE_WEB_INIT).finally(() => {
      setFinishInit(true);
    });
  };

  const render = () => {
    if (
      (options?.loginAccess && !isLoginSession()) ||
      (options?.nonLoginAccess && isLoginSession())
    ) {
      options?.loginAccess &&
        localStorage.setItem(
          REDIRECT_PATH,
          JSON.stringify({ path: location.pathname, showMainJoinModal: true })
        );
      return <Navigate to="/" />;
    }

    CommonStore.setFixedCurrentRouteName(routeName || "");
    return children;
  };

  let isMouseDown = false;

  const sliderEventHandlers = [
    {
      event: "mousedown",
      handler: () => {
        isMouseDown = true;
      },
    },
    {
      event: "mousemove",
      handler: () => {
        if (isMouseDown) {
          blockWhileDragging(true);
        } else {
          blockWhileDragging(false);
        }
      },
    },
    {
      event: "mouseup",
      handler: () => {
        isMouseDown = false;
        blockWhileDragging(false);
      },
    },
  ];

  useEffect(() => {
    sliderEventHandlers.forEach(({ event, handler }) => {
      window.addEventListener(event, handler);
    });

    return () => {
      sliderEventHandlers.forEach(({ event, handler }) => {
        window.removeEventListener(event, handler);
      });
    };
  }, []);

  useLayoutEffect(() => {
    initBeforeFirstRender();
  }, []);

  const blockWhileDragging = (isDragging: boolean) => {
    let slides = document.getElementsByClassName("slick-slide");

    if (isDragging) {
      for (let i = 0; i < slides.length; i++) {
        slides[i].classList.add("is-dragging"); // is-dragging 클래스 추가
      }
    } else {
      for (let i = 0; i < slides.length; i++) {
        slides[i].classList.remove("is-dragging"); // is-dragging 클래스 제거
      }
    }
  };

  useEffect(() => {
    MainStore.setCurrentScrollY(y);
    SearchStore.setCurrentScrollY(y);

    const height = document.documentElement.clientHeight;

    if (y >= height / 2 && lastScrollY > y) {
      setShowTopButton(true);
      CommonStore.startTopButtonTimer(() => {
        setShowTopButton(false);
      });
    } else {
      setShowTopButton(false);
    }
    setlastScrollY(y);
  }, [y]);

  return (
    <NavigationProvider>
      <TrackingHandlerProvider trackerRef={trackerRef}>
        <TrackingProvider>
          <div>
            <AppInstallTopBanner />
            <AppInstallSideBanner />
            {finishInit && render()}
            {CommonStore.loading && <CustomActivityIndicator />}
            <NotificationModal
              isVisible={CommonStore.notificationModalProps.isVisible}
              modalWidth={CommonStore.notificationModalProps.modalWidth}
              title={CommonStore.notificationModalProps.title}
              contents1={CommonStore.notificationModalProps.contents1}
              contents2={CommonStore.notificationModalProps.contents2}
              defaultButtonText={
                CommonStore.notificationModalProps.defaultButtonText
              }
              extraButtonText={
                CommonStore.notificationModalProps.extraButtonText
              }
              useTwoButton={CommonStore.notificationModalProps.useTwoButton}
              onRequestClose={CommonStore.notificationModalProps.onRequestClose}
              onClickDefaultButton={
                CommonStore.notificationModalProps.onClickDefaultButton
              }
              onClickExtraButton={
                CommonStore.notificationModalProps.onClickExtraButton
              }
              modalCloseCallback={
                CommonStore.notificationModalProps.modalCloseCallback
              }
            />
            {CommonStore.toastOption.show && <Toast />}
            {MainStore.showJoinModal && <JoinModal />}
            {showTopButton && <CommonTopButton />}
            {WriteButtonStore.showWriteButton && <WriteButton />}
            {reportBottomSheetProps.open && (
              <ReportBottomSheet {...reportBottomSheetProps} />
            )}
            {ReportConfirmPopupProps.open && (
              <ReportConfirmPopup {...ReportConfirmPopupProps} />
            )}
            <DownloadAppDriveBottomSheet
              show={CommonStore.isShowDownloadAppDrive}
              onClose={() => CommonStore.setShowDownloadAppDrive(false)}
            />
            <ShareBottomSheet
              show={CommonStore.isShowShareBottomSheet}
              onClose={() => CommonStore.setShowShareBottomSheet(false)}
            />
          </div>
        </TrackingProvider>
      </TrackingHandlerProvider>
    </NavigationProvider>
  );
});

export default App;
