// import { Image, StyleSheet, Text, TouchableOpacity, View, BackHandler } from "react-native";
// import Modal from "react-native-modal";
import {
  Arranges,
  Colors,
  FlexBoxs,
  Spacings,
  Texts,
  Positions,
  Icons,
  Assets,
} from "../../assets/styles";
// import { responsiveWidth } from "react-native-responsive-dimensions";
import { useTranslation } from "react-i18next";
import {ReactComponent as CloseIcon} from "../../assets/icons/close.svg";
import { Styles } from "../../assets/types/Style";
import Modal from "react-modal";

const MyApplianceDeleteModal = ({
  title, // 타이틀
  contents1, // 내용1
  contents2, // 내용2
  defaultButtonText, // 기본 버튼 텍스트
  extraButtonText, // 2개 이상일때 왼쪽 버튼 텍스트
  isVisible, // 팝업 표시 여부
  isBlack, // 블랙 컬러의 버튼
  useTwoButton,
  onRequestClose, // 팝업 닫힌 후 본 페이지에서 해야할 동작
  onClickDefaultButton, // 기본 닫기 버튼 클릭 이벤트 (2개 이상일때는 오른쪽)
  onClickExtraButton, // 2개 버튼 팝업에서 왼쪽 버튼 클릭 이벤트
  textAlign,
  ref,
}: {
  title?: string;
  contents1: string;
  contents2?: string;
  defaultButtonText?: string;
  extraButtonText?: string;
  isVisible: boolean;
  isBlack?: boolean;
  useTwoButton?: boolean;
  onRequestClose?: (() => void) | undefined;
  onClickDefaultButton?: (() => void) | undefined;
  onClickExtraButton?: (() => void) | undefined;
  textAlign?: "auto" | "left" | "right" | "center" | "justify";
  ref?: any | undefined;
}) => {
  const { t } = useTranslation();
  const {
    modal_wrap,
    notification_modal,
    header_text,
    modal_body,
    icons,
    modal_header,
    button,
    modal_footer,
    footer_button_text,
    // text_0,
    text_1,
    modal_footer_two,
    button_two,
    button_left,
    button_right,
    footer_button_gray_text,
  } = styles;

  const dismiss = () => {
    if (onRequestClose !== undefined) onRequestClose();
  };

  const onDefaultButtonClicked = () => {
    if (onClickDefaultButton !== undefined) onClickDefaultButton();
    else dismiss();
  };

  const onExtraButtonClicked = () => {
    if (onClickExtraButton !== undefined) onClickExtraButton();
  };

  return (
    <>
      <div style={modal_wrap}>
        <Modal isOpen={isVisible} onRequestClose={dismiss} ref={ref}>
        <div style={notification_modal}>
            <div style={modal_header}>
              <p style={header_text}
                // accessibilityLabel="Title"
              >
                {title && title !== undefined ? title : t("screen.notificationModal.label.alert")}
              </p>
              <button style={icons} onClick={dismiss}
                // accessibilityLabel="Close"
              >
                <CloseIcon />
              </button>
            </div>
            <div style={modal_body}>
              <p style={{ ...text_1, ...{ textAlign: textAlign as any } }}
                // accessibilityLabel="Contents1"
              >
                {contents1}
              </p>
              {contents2 && contents2 !== undefined && (
                <p style={{ ...text_1, ...{ textAlign: textAlign as any } }}
                  // accessibilityLabel="Contents2"
                >
                  {contents2}
                </p>
              )}
            </div>

            {useTwoButton === true ? (
              <div style={modal_footer_two}>
                <button
                  style={{
                    ...button_two,
                    ...button_left,
                    ...(isBlack ? styles.black : styles.gray),
                  }}
                  onClick={onExtraButtonClicked}
                  // accessibilityLabel="Left button"
                >
                  <p
                    style={{
                      ...footer_button_text,
                      ...(!isBlack && footer_button_gray_text),
                    }}
                  >
                    {extraButtonText && extraButtonText !== undefined
                      ? extraButtonText
                      : t("screen.notificationModal.button.close")}
                  </p>
                </button>
                <button
                  style={{ ...button_two, ...button_right, ...styles.black }}
                  onClick={onDefaultButtonClicked}
                  // accessibilityLabel="Right button"
                >
                  <p style={footer_button_text}>
                    {defaultButtonText && defaultButtonText !== undefined
                      ? defaultButtonText
                      : t("screen.notificationModal.button.close")}
                  </p>
                </button>
              </div>
            ) : (
              <div style={modal_footer}>
                <button
                  style={{ ...button, ...styles.black }}
                  onClick={onDefaultButtonClicked}
                  // accessibilityLabel="Close button"
                >
                  <p style={footer_button_text}>
                    {defaultButtonText && defaultButtonText !== undefined
                      ? defaultButtonText
                      : t("screen.notificationModal.button.close")}
                  </p>
                </button>
              </div>
            )}
          </div>
        </Modal>
      </div>
  </>
  );
};

export default MyApplianceDeleteModal;

const styles: Styles = {
  modal_wrap: {
    ...Positions.fullscreen,
  },
  notification_modal: {
    //[1622] 공통 팝업
    // width: responsiveWidth(82),
    width: 260,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
    ...Colors.border_color_transparent,
    ...FlexBoxs.flex,
    ...Arranges.margin_center_v_h,
    ...Assets.modal_box_shadow,
  },
  modal_header: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Positions.relative,
    ...Colors.background_fff,
    ...Spacings.border_top_left_radius_12,
    ...Spacings.border_top_right_radius_12,
    height: 64,
  },
  modal_body: {
    ...FlexBoxs.flex,
    ...FlexBoxs.vertical,
    ...Arranges.center_h,
    ...Spacings.padding_left_right_20,
    ...Colors.background_fff,
  },
  icons: {
    ...Icons.icon_32,
    ...Positions.absolute,
    ...Positions.top_6,
    ...Positions.right_10,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  text_0: {
    ...Texts.font_size_16,
    ...Texts.font_letter_spacing_064,
    ...Spacings.margin_bottom_24,
    ...Colors.font_222,
  },
  text_1: {
    width: 250,
    ...Texts.font_size_14,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.margin_bottom_26,
    ...Arranges.text_align_center,
    ...Texts.font_letter_spacing_056,
    ...Colors.font_222,
  },
  modal_footer: {
    height: 48,
  },
  modal_footer_two: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    height: 48,
  },
  button: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
  },
  purple: {
    ...Colors.background_6c2773,
  },
  black: {
    ...Colors.background_222,
  },
  footer_button_text: {
    ...Texts.button_text_3,
  },
  button_line_black: {
    ...Colors.two_btn_border_color_666666,
    width: 1,
    borderWidth: 0.5,
  },
  button_left: {
    ...Spacings.border_bottom_left_radius_12,
    ...Colors.two_btn_border_color_666666,
    borderRightWidth: 0.5,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
  },
  button_right: {
    ...Spacings.border_bottom_right_radius_12,
  },
  button_two: {
    ...Colors.background_6c2773,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  gray: {
    ...Colors.background_eeeeee,
  },
  footer_button_gray_text: {
    ...Colors.font_666666,
  },
};
