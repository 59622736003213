import { observer } from 'mobx-react'
import { useCallback, useEffect, useState, useRef, RefObject } from 'react'
import PostWriteStore, { PostUIContents } from '../../../store/PostWriteStore'
import { useTranslation } from 'react-i18next'
import { toHeight } from '../../../api/file/model'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close_white_background.svg'
import { ReactComponent as FileAddIcon } from '../../../assets/icons/file_add.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/close_purple.svg'
import { ReactComponent as PhotoIcon } from '../../../assets/icons/photo.svg'
import { ReactComponent as VideoIcon } from '../../../assets/icons/video.svg'
import { ReactComponent as IconImgeffect } from '../../../assets/filter/icon_imgeffect.svg'
import { FileType } from '../../../constants/File'
import { MaxLengthTextInput } from '../../../components/common'
import { useTracker } from '../../../hooks/tracker'
import MissionStore from '../../../store/MissionStore'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ReactDragListView, { DragListViewProps } from 'react-drag-listview'
import CommonStore from '../../../store/CommonStore'
import './PostWriteContents.scss'
import clsx from 'clsx'
import { ReactComponent as DragAreaIcon } from '../../../assets/icons/drag-area.svg'
import useDragScrollWithMouse from '../../../hooks/useDragScrollWithMouse'
import UploadStore from '../../../store/upload/UploadStore'
import { goTo } from '../../../hooks/navigate/NavigateFunction'
import eventBus from '../../../utils/eventbus'
import { FilterResult } from '../../filter/types'

const PostWriteContents = observer(
  ({
    data,
    contentsIndex,
    height,
  }: {
    data: PostUIContents
    contentsIndex: number
    height?: number
  }) => {
    const listImageRef = useRef<any>(null)
    const uniqueContentsClassName = `section_${contentsIndex}`
    const isScrolling = useDragScrollWithMouse(
      listImageRef,
      `.draggable-container.${uniqueContentsClassName} > div[role='presentation']`,
      '.drag-area'
    )
    const { t } = useTranslation()
    const { track } = useTracker()
    const { widthApp } = CommonStore

    const carouselWidthHeight = widthApp - 120
    const {
      contentsTempData,
      fileUpdated,
      finishFileUpdate,
      finishFileUpdateIndex,
      setFinishFileUpdate,
      setFinishFileUpdateIndex,
      removeContents,
      deleteFile,
      setRepresentation,
      setContentContents,
      moveItem,
      updateFilterFile2,
    } = PostWriteStore

    const toHeightContent = useCallback((item: PostUIContents) => {
      const itemtype = item
      let h = carouselWidthHeight
      if (itemtype.contents.files.length > 1) {
        h = toHeight(itemtype.contents.files[0].fileResolution ?? '', h)
      }
      return h
    }, [])
    const [alphaData, setAlphaData] = useState(
      data.contents.files.map((route, i) => {
        return { ...route }
      })
    )

    const textSectionRef = useRef<any>(null)
    const fileInputRef = useRef<any>(null)

    useEffect(() => {
      if (textSectionRef.current) {
        handleHeightInput(textSectionRef)
      }
    }, [textSectionRef, data])

    const handleHeightInput = (ref: RefObject<HTMLTextAreaElement>) => {
      const element = ref.current
      if (element) {
        element.style.height = 'auto'
        element.style.height = element.scrollHeight + 'px'
      }
    }

    useEffect(() => {
      const h = toHeightContent(data)
      const minHeight = 200
      if (h < minHeight) {
        setContentHeight(minHeight)
      } else {
        setContentHeight(h)
      }

      setAlphaData(
        data.contents.files.map((route, i) => {
          return { ...route }
        })
      )
    }, [data, fileUpdated, toHeightContent])

    useEffect(() => {
      if (finishFileUpdate && finishFileUpdateIndex >= 0) {
        setFinishFileUpdate(false)
        setFinishFileUpdateIndex(-1)
        if (
          textSectionRef &&
          textSectionRef.current &&
          contentsIndex === finishFileUpdateIndex
        ) {
          textSectionRef.current.focus()
        }
      }
    }, [
      contentsIndex,
      finishFileUpdate,
      finishFileUpdateIndex,
      setFinishFileUpdate,
      setFinishFileUpdateIndex,
    ])

    const [contentHeight, setContentHeight] = useState(
      height ? height : toHeightContent(data)
    )

    const removeitem = useCallback(
      (contentsIndex2: number, fileIndex: number) => {
        const arr = deleteFile(contentsIndex2, fileIndex)
        setAlphaData([...arr])
      },
      [deleteFile]
    )

    const renderItem = ({ item, index, isActive }: any) => {
      if (index === data.contents.files?.length - 1 && item.fileSize < 0)
        return (
          <div
            className="draggable-item"
            style={{
              width: carouselWidthHeight,
              aspectRatio: carouselWidthHeight / contentHeight,
            }}
          >
            <button
              aria-label={`show album${contentsIndex}`}
              className="image_add_wrap"
              style={{ height: contentHeight }}
              onClick={() => !isScrolling && fileInputRef.current.click()}
            >
              <div className="image_add">
                <FileAddIcon className="plus_image" />
                {data.contents.files?.length < 2 && (
                  <>
                    <div className="photo_video_wrap">
                      <PhotoIcon className="photo_icon" />
                      <VideoIcon />
                    </div>
                    <span className="empty_text">
                      {contentsIndex === 0
                        ? t('screen.post.label.max10Upload')
                        : t('screen.post.label.max3Upload')}
                    </span>
                  </>
                )}
              </div>
            </button>
            <input
              onChange={(e) => {
                const inputFiles = e.target.files
                if (inputFiles && inputFiles.length > 0) {
                  CommonStore.setLoading(false)
                  UploadStore.checkFileExtension(inputFiles)
                    .then(() => UploadStore.checkFileSize(inputFiles))
                    .then(() =>
                      PostWriteStore.checkFileCount(inputFiles, contentsIndex)
                    )
                    .then(() => PostWriteStore.dataSetting(inputFiles))
                    .then((files) => PostWriteStore.resizeThumbImg(files))
                    .then((files) =>
                      PostWriteStore.contentsTempDataSet(files, contentsIndex)
                    )
                    .then(() => CommonStore.setLoading(false))
                    .catch((e: Error) => {
                      CommonStore.setLoading(false)
                      if (e.message === 'checkFileExtension') {
                        CommonStore.setToastOption({
                          show: true,
                          message1: t('common.message.checkFileExtension'),
                        })
                      } else if (e.message === 'checkFileSize') {
                        CommonStore.setToastOption({
                          show: true,
                          message1: t('common.message.checkFileSize'),
                        })
                      } else if (e.message === 'checkFileCount') {
                        const { maxImageCount, maxVideoCount } = e.cause as {
                          maxImageCount: number
                          maxVideoCount: number
                        }
                        CommonStore.setToastOption({
                          show: true,
                          message1: t('common.message.checkFileCount', {
                            maxImageCount,
                            maxVideoCount,
                          }),
                        })
                      }
                    })
                }
              }}
              ref={fileInputRef}
              type="file"
              id="file"
              accept="image/*, video/*"
              style={{ display: 'none' }}
              multiple
            />
          </div>
        )
      else {
        const isRepresent = item.thumbnailYn === 'Y'
        const filePath =
          String(item.thumbnailFilePath).indexOf('/THUMBNAIL/FEED/') > -1
            ? `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                (item.thumbnailFilePath as string) || ''
              }`
            : item.thumbnailTempPath

        return (
          <div
            className="draggable-item-image-wrap"
            style={
              index === 0
                ? { marginLeft: (widthApp - carouselWidthHeight) / 2 }
                : {}
            }
          >
            <button disabled={isActive} className="draggable-item-image">
              <LazyLoadImage
                className="draggable-image"
                style={{
                  height: contentHeight,
                  aspectRatio: carouselWidthHeight / contentHeight,
                }}
                visibleByDefault
                src={filePath}
                aria-label={`media content${contentsIndex}-${index}`}
              />
              <div className="image_info_wrap">
                {contentsIndex === 0 && (
                  <button
                    aria-label="Set Represent file"
                    className="represent_file"
                    onClick={() => {
                      setRepresentation(index)
                    }}
                  >
                    <div
                      className={
                        isRepresent ? 'represent_image' : 'represent_image_off'
                      }
                    >
                      <span
                        className={
                          isRepresent ? 'represent_text' : 'represent_text_off'
                        }
                      >
                        {t('screen.post.label.represent')}
                      </span>
                    </div>
                  </button>
                )}
              </div>
              <div className="image_filter_wrap">
                {item.imageOriginLocalFilePath !== '' && (
                  <button
                    aria-label="Set Represent file"
                    onClick={() => {
                      const array = data.contents.files
                        .filter(
                          (item, index2) =>
                            item.fileType === FileType.IMAGE &&
                            item.fileId === undefined &&
                            index2 === index
                        )
                        .map((item, index) => {
                          return {
                            index: index,
                            fileUri: item.thumbnailTempPath ?? '',
                            fileOriginUri: item.imageOriginLocalFilePath ?? '',
                          }
                        })
                      track('click_imagefilter_button', {})

                      goTo('/filter', {
                        state: {
                          thumbnailImage: item.thumbnailTempPath,
                          originalImage: item.imageOriginLocalFilePath,
                        },
                      })
                      const callback = (result: FilterResult) => {
                        let updateArr: {
                          fileIndex: number
                          uri: string
                          urithumbnail: string
                        }[] = []
                        updateArr = [
                          ...updateArr,
                          {
                            fileIndex: index,
                            uri: result.originalImage,
                            urithumbnail: result.thumbnailImage,
                          },
                        ]
                        updateFilterFile2(contentsIndex, updateArr)

                        eventBus.unsubscribe('onFilteredImage', callback)
                      }
                      eventBus.subscribe('onFilteredImage', callback)

                      // navigate('FilterTypeScreen', {
                      //   imagesArray: [
                      //     {
                      //       index: index,
                      //       fileUri: item.thumbnailTempPath ?? '',
                      //       fileOriginUri: item.imageOriginLocalFilePath ?? '',
                      //     },
                      //   ],
                      //   fileUri: item.thumbnailTempPath ?? '',
                      //   fileOriginUri: item.imageOriginLocalFilePath ?? '',
                      //   index: 0,
                      //   ratioW: carouselWidthHeight,
                      //   ratioH: contentHeight,
                      //   contentsIndex: contentsIndex,
                      //   complete: async (items: any[]) => {
                      //     let updateArr: {
                      //       fileIndex: number
                      //       uri: string
                      //       urithumbnail: string
                      //     }[] = []
                      //     const resizethumbnail = async (
                      //       p: string
                      //     ): Promise<string> => {
                      //       const r = await ImageResizer.createResizedImage(
                      //         p,
                      //         720,
                      //         720,
                      //         'JPEG',
                      //         50
                      //       )
                      //       return r.uri
                      //     }

                      //     const cp = async (
                      //       o: string,
                      //       p: string,
                      //       idx: number,
                      //       cparr: any[],
                      //       items: any[]
                      //     ): Promise<any[]> => {
                      //       const ffilenm = o.split('/').pop() || ''

                      //       if (Platform.OS === 'android') {
                      //         const b = await resizethumbnail(p)
                      //         cparr = [
                      //           ...cparr,
                      //           { fileIndex: idx, uri: p, urithumbnail: b },
                      //         ]
                      //         return cparr
                      //       }
                      //       const imgpath = `${
                      //         Dirs.CacheDir
                      //       }/${ffilenm}${new Date().toISOString()}.jpg`

                      //       void FileSystem.cp(p, imgpath)

                      //       const b = await resizethumbnail(p)
                      //       cparr = [
                      //         ...cparr,
                      //         { fileIndex: idx, uri: imgpath, urithumbnail: b },
                      //       ]
                      //       return cparr
                      //     }
                      //     for (const element of items) {
                      //       if (element.filterComponentIdx === 0) {
                      //         const b = await resizethumbnail(
                      //           item.imageOriginLocalFilePath as string
                      //         )
                      //         updateArr = [
                      //           ...updateArr,
                      //           {
                      //             fileIndex: element.index,
                      //             uri: item.imageOriginLocalFilePath,
                      //             urithumbnail: b,
                      //           },
                      //         ]
                      //       } else if (element.saveAsTemp.length > 0) {
                      //         updateArr = await cp(
                      //           element.fileOriginUri as string,
                      //           element.saveAsTemp as string,
                      //           element.index as number,
                      //           updateArr,
                      //           items
                      //         )
                      //       } else if (element.saveAs.length > 0) {
                      //         updateArr = await cp(
                      //           element.fileOriginUri as string,
                      //           element.saveAs as string,
                      //           element.index as number,
                      //           updateArr,
                      //           items
                      //         )
                      //       }
                      //     }

                      //     updateFilterFile2(contentsIndex, updateArr)
                      //   },
                      // })
                    }}
                  >
                    <IconImgeffect />
                  </button>
                )}
              </div>
              {alphaData.length > 2 && (
                <div className="drag-area">
                  <DragAreaIcon className="drag-area-icon" />
                </div>
              )}
              <div className="image_delete_wrap">
                <button
                  aria-label="Delete file"
                  style={{ alignContent: 'flex-end' }}
                  onClick={() => {
                    if (alphaData.length <= 2)
                      setContentHeight(carouselWidthHeight)
                    removeitem(contentsIndex, index)
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            </button>
          </div>
        )
      }
    }

    const dragProps: DragListViewProps = {
      onDragEnd(fromIndex: number, toIndex: number) {
        const item = moveItem(contentsIndex, fromIndex, toIndex)
        setAlphaData([...item])
      },
      nodeSelector: '.draggable-slick-wrapper',
      ignoreSelector: '.draggable-slick-wrapper.last',
      handleSelector: '.draggable-slick-wrapper.draggable .drag-area',
      scrollSpeed: 5,
      lineClassName: 'line-drag-list-view',
    }

    return (
      <div id="post_write_content">
        <div className="content_wrap_image">
          {contentsIndex !== 0 && (
            <>
              <div className="bar" />
              <div className="content_wrap">
                <div className="delete_button_wrap">
                  <button
                    className="delete_button"
                    onClick={() => removeContents(contentsIndex)}
                    aria-label="Delete contents"
                  >
                    <DeleteIcon />
                    <span className="delete_button_text">
                      {t('screen.post.button.deleteContents')}
                    </span>
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="image_list_wrap" ref={listImageRef}>
            <div
              className={clsx(
                'draggable-container',
                uniqueContentsClassName,
                alphaData.length <= 1 && 'empty'
              )}
            >
              <ReactDragListView.DragColumn {...dragProps}>
                {alphaData.map((item, index) => {
                  return (
                    <div
                      className={clsx(
                        'draggable-slick-wrapper',
                        index === alphaData.length - 1 || alphaData.length === 2
                          ? 'last'
                          : 'draggable'
                      )}
                      style={{ flexShrink: 0 }}
                    >
                      <div className="draggable-slick">
                        {renderItem({ item: item, index: index })}
                      </div>
                    </div>
                  )
                })}
              </ReactDragListView.DragColumn>
            </div>
          </div>
          <div className="contents">
            <MaxLengthTextInput
              type="textarea"
              aria-label={`Input content${contentsIndex}`}
              className="contents_input_text"
              ref={textSectionRef}
              placeholder={
                MissionStore.missionDetail.missionId
                  ? t('screen.post.label.missionContentsPlaceHolder')
                  : t('screen.post.label.contentsPlaceHolder')
              }
              placeholderTextColor={'#999'}
              multiline={true}
              textAlignVertical="top"
              maxLength={2000}
              value={contentsTempData[contentsIndex].contents?.content}
              onChangeText={(text: string) => {
                setContentContents(contentsIndex, text)
              }}
            />
          </div>
        </div>
      </div>
    )
  }
)

export default PostWriteContents
