import {
  callAPIWithGETMethod,
  callAPIWithPOSTMethod,
  callAPIWithPUTMethod,
} from '../../common/api'
import CommonResponse from '../../http'

export const getNoticeInfo = async (
  noticeId: number | string,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: 'N',
    statusCode: '',
    data: {},
  }
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/notice/${noticeId}`,
      },
      isLoading
    )
  } catch (error) {
    response.data = error
  }
  return response
}

export const getNotices = async (
  pageSize: number,
  pageIndex: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: 'N',
    statusCode: '',
    data: {},
  }
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/notices?pageSize=${pageSize}&pageIndex=${pageIndex}`,
      },
      isLoading
    )
  } catch (error) {
    response.data = error
  }

  return response
}
