import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommentStore, { CommentInEdit } from "../../../store/CommentStore";
import { CommentListItem } from "../../../api/comment/model";
import { ReactComponent as HeartOffIcon } from "../../../assets/icons/heart_off.svg";
import { ReactComponent as HeartOnIcon } from "../../../assets/icons/heart_on.svg";
import { ReactComponent as ReplyClose } from "../../../assets/icons/icon_close_Reply.svg";
import { ReactComponent as ReplyOpen } from "../../../assets/icons/icon_open_Reply.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/btn_del.svg";
import { CommentStatusCode } from "../../../constants/Comment";
import { getDateStr } from "../../../utils/datetime";
import CommentOptionBox from "./CommentOptionBox";
import { createTagInfoStr } from "../../../utils/comment";
import { OptionType } from "../../../components/common/ContentsPopupMenu";
import { observer } from "mobx-react";
import ReportStore from "../../../store/ReportStore";
import { AllowTypeCode } from "../../../constants/CommonCode.enum";
import { ReportTargetTypeCode } from "../../../constants/Report";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { LikeTypeCode } from "../../../constants/Like";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import "./CommentItem.scss";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { ActHistoryRequest } from "../../../api/data/model";
import { sendActHistory } from "../../../api/data/api";
import AuthStore from "../../../store/AuthStore";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { ProductForWrite } from "../../../store/ProductSearchAddStore";
import { runInAction } from "mobx";
import { Virtuoso } from "react-virtuoso";
import FadeInView from "../../../components/common/FadeInView";
import SubCommentItem from "./SubCommentItem";
import CommentItemProductList from "./CommentItemProductList";
import CommentBlock from "./CommentBlock";
import CustomParsedText from "../../../components/common/CustomParsedText";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

export interface CommentItemProps {
  index: number;
  isQnaDetailReply?: boolean;
  isAbDetailReply?: boolean;
  showReply?: boolean;
  showOptionBox?: boolean;
  showDeleteBtn?: boolean;
  comment: CommentListItem;
  onPressAddReplyBtn?: (contents: CommentInEdit) => void;
  onChildCommentShown?: () => void;
  onBestPicked?: () => void;
  onAddReplyBtn?: () => void;
  onModifyReplyBtn?: () => void;
  onDeleteReplyBtn?: () => void;
}

const CommentItem = observer(function CommentItem({
  index,
  isQnaDetailReply,
  isAbDetailReply,
  showReply,
  showOptionBox,
  showDeleteBtn,
  comment,
  onPressAddReplyBtn,
  onChildCommentShown,
  onBestPicked,
  onAddReplyBtn,
  onModifyReplyBtn,
  onDeleteReplyBtn,
}: CommentItemProps) {
  const { t } = useTranslation();
  const {
    commentTarget,
    cancelLikeComment,
    likeComment,
    setDeleteTargetCommentInfo,
    setCommentInEdit,
    setBestPick,
    setAfterDeleteHandler,
    setCommentUpdateType,
    syncCommentStatusAfterReporting,
  } = CommentStore;
  const { openReportBottomSheet } = ReportStore;

  const { sessionUser } = AuthStore;

  const isCommentOwner =
    comment?.memberUuid !== undefined &&
    sessionUser?.memberUuid !== undefined &&
    comment?.memberUuid === sessionUser?.memberUuid;

  const [showSubList, setShowSubList] = useState<boolean>(true);
  const [doILike, setDoILike] = useState<boolean>(comment?.likeYn === "Y");
  const [commentStatusCode, setCommentStatusCode] = useState<CommentStatusCode>(
    comment.commentStatusCode as CommentStatusCode
  );

  const [reportYn, setReportYn] = useState<AllowTypeCode>(
    comment.reportYn as AllowTypeCode
  );

  const [memberReportYn, setMemberReportYn] = useState<AllowTypeCode>(
    comment.memberReportYn as AllowTypeCode
  );

  //PPIT210117-4818 LIKE 취소 시 attr6 CANCEL 반영
  const callLikeActHistory = (attr6?: string) => {
    const actHistory: ActHistoryRequest = {
      actHistoryTypeCode: ActHistoryTypeCode.LIKE,
      actHistoryTargetId: comment.commentId.toString(),
      actPageCode: ActPageCode.DETAIL_COMMENT_LIST,
      actSectionCode: ActSectionCode.COMMENT,
      attr1: (commentTarget?.commentTargetId ?? 0).toString(),
      attr2: comment.likeCount ? comment.likeCount.toString() : "",
      attr3: comment.replyCount ? comment.replyCount.toString() : "",
      attr4: (comment.bestPickYn || "N").toString(),
    };
    if (isQnaDetailReply) {
      actHistory.actPageCode = ActPageCode.QNA_DETAIL;
    }
    if (isAbDetailReply) {
      actHistory.actPageCode = ActPageCode.AB_DETAIL;
    }
    if (attr6) {
      actHistory.attr6 = attr6;
    }
    void sendActHistory(actHistory);
  };

  const setDoILikeAsync = async (like: boolean) => {
    const toggleSucceed = like
      ? await likeComment(comment?.commentId)
      : await cancelLikeComment(comment?.commentId);

    if (!toggleSucceed) {
      setDoILike(!like);
    }
  };

  const handleMenuAction = (type: OptionType) => {
    switch (type) {
      case "DELETE":
        onDeleteReplyBtn && onDeleteReplyBtn();
        setAfterDeleteHandler(() => {
          setCommentStatusCode(CommentStatusCode.USER_DELETE);
        });
        setDeleteTargetCommentInfo({ commentId: comment.commentId });
        break;
      case "MODIFY":
        onModifyReplyBtn && onModifyReplyBtn();
        setCommentUpdateType({
          contents: comment.contents || "",
          parentCommentId: comment.parentCommentId,
          commentId: comment.commentId,
        });
        setCommentInEdit({
          contents: comment.contents || "",
          parentCommentId: comment.parentCommentId,
          commentId: comment.commentId,
          originalMentionList: comment.mentionList,
        });
        if (comment.productList && comment.productList.length > 0) {
          runInAction(() => {
            const productList: ProductForWrite[] = [];
            comment.productList?.forEach((x) => {
              productList.push({
                isRepresent: false,
                product: {
                  ...x,
                  manufacturerName: x.manufacturerNameKor,
                  originalFilePath: x.productFiles[0].filePath,
                },
              });
            });
            CommentStore.tempProductList = productList;
          });
        }
        break;
      case "REPORT":
        openReportBottomSheet(
          {
            reportTargetId: String(comment.commentId),
            parentCommentId: comment.parentCommentId,
            reportTargetTypeCode: ReportTargetTypeCode.COMMENT,
          },
          () => {
            setMemberReportYn(AllowTypeCode.Y);
            void syncCommentStatusAfterReporting({
              reportTargetId: String(comment.commentId),
              parentCommentId: comment.parentCommentId,
              reportTargetTypeCode: ReportTargetTypeCode.COMMENT,
            });
          }
        );
        break;
      case "BEST_PICK":
        void setBestPick(comment.commentId);
        onBestPicked && onBestPicked();
        break;
    }
  };

  const toggleShowChildCommentList = (isShowSubList: boolean) => {
    setShowSubList(isShowSubList);
    isShowSubList && onChildCommentShown && onChildCommentShown();
  };

  const getLikeCount = () => {
    let count = comment.likeCount || 0;
    if (comment?.likeYn === "Y" && !doILike) {
      count -= 1;
    } else if (comment.likeYn === "N" && doILike) {
      count += 1;
    }
    return count.toString();
  };

  const onLikeListButtonPress = () => {
    goTo("/LikeScreen", {
      state: {
        feedId: comment?.commentId,
        feedType: LikeTypeCode.COMMENT,
      },
    });
  };

  useEffect(() => {
    setDoILike(comment.likeYn === "Y" ? true : false);
  }, [comment?.likeYn]);

  useEffect(() => {
    comment.reportYn && setReportYn(comment.reportYn as AllowTypeCode);
  }, [comment.reportYn]);

  useEffect(() => {
    comment.memberReportYn &&
      setMemberReportYn(comment.memberReportYn as AllowTypeCode);
  }, [comment.memberReportYn]);

  useEffect(() => {
    comment.commentStatusCode &&
      setCommentStatusCode(comment.commentStatusCode as CommentStatusCode);
  }, [comment.commentStatusCode]);

  const toUserHome = () => {
    goTo(`/user/${comment.nickname}`, {
      state: {
        targetUserId: comment.memberUuid,
        nickname: comment.nickname,
        profileUrl: comment.profileUrl,
        profileBackgroundColor: comment.profileBackgroundColor,
      },
    });
  };

  return (
    <>
      {(commentStatusCode === CommentStatusCode.ADMIN_DELETE ||
        commentStatusCode === CommentStatusCode.USER_DELETE ||
        reportYn === AllowTypeCode.Y ||
        memberReportYn === AllowTypeCode.Y ||
        !comment?.memberUuid) &&
        !(
          commentStatusCode === CommentStatusCode.USER_DELETE &&
          comment.childCommentMemberList?.length === 0
        ) && (
          <>
            <CommentBlock
              statusCode={commentStatusCode}
              reportYn={reportYn}
              memberReportYn={memberReportYn}
              unknownMember={!comment?.memberUuid}
            />

            <div>
              <Virtuoso
                // scrollEnabled={false}
                // windowSize={3}
                aria-label="Reply list"
                // showsHorizontalScrollIndicator={false}
                data={comment.childCommentMemberList}
                itemContent={(index, item) => (
                  <SubCommentItem
                    index={index}
                    comment={item}
                    onPressAddReplyBtn={onPressAddReplyBtn}
                  />
                )}
              />
            </div>
          </>
        )}
      {commentStatusCode === CommentStatusCode.REGISTERED &&
        reportYn !== AllowTypeCode.Y &&
        memberReportYn !== AllowTypeCode.Y &&
        comment?.memberUuid && (
          <div className="comment_item">
            <FadeInView>
              <div
                className={`${
                  comment?.bestPickYn === "Y" && !showSubList
                    ? "best_comment"
                    : "normal_comment"
                }`}
                aria-label="comment container"
              >
                <div className="header">
                  <div className="left">
                    <div className="user_image">
                      <TouchableWithAuthCheck onPress={toUserHome}>
                        <FastImageWithFallback
                          aria-label="Profile Image"
                          source={{
                            uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${
                              comment?.profileUrl || ""
                            }`,
                          }}
                          className="image"
                        />
                      </TouchableWithAuthCheck>
                    </div>
                    <div className="info">
                      <TouchableWithAuthCheck onPress={toUserHome}>
                        <span
                          className="id_text"
                          aria-label={`Nickname${index}`}
                        >
                          {comment?.nickname}
                        </span>
                      </TouchableWithAuthCheck>
                      {comment?.bestPickYn === "Y" && (
                        <div className="best_answer_wrap">
                          <div className="best_answer">
                            <span
                              className="best_answer_text"
                              aria-label="Best answer label"
                            >
                              Best Pick
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="right">
                    <TouchableWithAsyncTask
                      aria-label="Like icon"
                      onPress={async () => {
                        if (!doILike) {
                          callLikeActHistory();
                        } else {
                          //PPIT210117-4818 LIKE 취소 시 attr6 CANCEL 반영
                          callLikeActHistory("CANCEL");
                        }
                        setDoILike(!doILike);
                        await setDoILikeAsync(!doILike);
                      }}
                      authCheckProps={{
                        enabled: true,
                        authScreenType: "MODAL",
                      }}
                    >
                      {doILike ? <HeartOnIcon /> : <HeartOffIcon />}
                    </TouchableWithAsyncTask>
                    {showOptionBox !== undefined && !showOptionBox ? (
                      <></>
                    ) : (
                      <CommentOptionBox
                        comment={comment}
                        onAction={handleMenuAction}
                      />
                    )}
                  </div>
                </div>
                <div className="comment_body_wrap">
                  <div className="body">
                    <div className="comment_wrap">
                      {comment && (
                        <CustomParsedText
                          pressEnabled={true}
                          aria-label={`Comment${index}`}
                        >
                          {comment?.contents || ""}
                        </CustomParsedText>
                      )}
                    </div>
                  </div>

                  <CommentItemProductList comment={comment} />

                  <div className="comment_footer">
                    <div className="left">
                      <div className="inner_left">
                        <span className="info_text" aria-label="Datetime">
                          {getDateStr(comment?.updatedDatetime)}
                        </span>

                        <div className="count_wrap">
                          {showReply !== undefined && !showReply ? (
                            <></>
                          ) : (comment?.childCommentMemberList?.length || 0) >
                            0 ? (
                            <button
                              onClick={() =>
                                toggleShowChildCommentList(!showSubList)
                              }
                              className="reply_wrap"
                              aria-label={`view reply${index}`}
                            >
                              <span
                                className="reply_text"
                                aria-label="Reply label"
                              >
                                {t("screen.comment.label.reply")}
                              </span>
                              <span
                                className="reply_count"
                                aria-label="Reply count"
                              >
                                {comment?.childCommentMemberList?.length?.toString()}
                              </span>
                              {showSubList ? <ReplyClose /> : <ReplyOpen />}
                            </button>
                          ) : (
                            <div className="reply_wrap">
                              <span
                                className="reply_text"
                                aria-label="Reply label"
                              >
                                {t("screen.comment.label.reply")}
                              </span>
                              <span
                                className="reply_count"
                                aria-label="Reply count"
                              >
                                {comment?.childCommentMemberList?.length?.toString()}
                              </span>
                            </div>
                          )}
                        </div>

                        <button
                          onClick={() => onLikeListButtonPress()}
                          className="count_wrap"
                        >
                          <span className="info_text" aria-label="Like">
                            {t("screen.comment.label.like")}
                          </span>
                          <span className="count_text" aria-label="Like count">
                            {getLikeCount()}
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="right">
                      {showReply != undefined && !showReply ? (
                        <></>
                      ) : (
                        <TouchableWithAuthCheck
                          onPress={() => {
                            onAddReplyBtn && onAddReplyBtn();
                            comment.childCommentMemberList.length > 0 &&
                              toggleShowChildCommentList(true);
                            setCommentInEdit({
                              // contents:
                              //   createTagInfoStr(
                              //     comment.nickname,
                              //     comment.memberUuid
                              //   ) + " ",
                              contents: `@${comment.nickname} `,
                              parentCommentId: comment?.commentId,
                            });
                          }}
                          aria-label={`Add reply${index}`}
                        >
                          <span className="action_text">
                            {t("screen.comment.button.reply")}
                          </span>
                        </TouchableWithAuthCheck>
                      )}
                      {isCommentOwner &&
                      showDeleteBtn !== undefined &&
                      showDeleteBtn ? (
                        <button
                          onClick={() => {
                            handleMenuAction("DELETE");
                          }}
                          className="delete_btn"
                        >
                          <DeleteIcon />
                          <span>{t("screen.comment.button.delete2")}</span>
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </FadeInView>
            {showSubList && (
              <FadeInView>
                {comment.childCommentMemberList.map((item, index) => {
                  return (
                    <div className="button_sub_comment_item">
                      <SubCommentItem
                        index={index}
                        comment={item}
                        onPressAddReplyBtn={onPressAddReplyBtn}
                        onAddReplyBtn={onAddReplyBtn}
                        onModifyReplyBtn={onModifyReplyBtn}
                        onDeleteReplyBtn={onDeleteReplyBtn}
                        showReply={showReply}
                      />
                    </div>
                  );
                })}
              </FadeInView>
            )}
          </div>
        )}
    </>
  );
});

export default CommentItem;
