import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Arranges, Colors, FlexBoxs, Spacings, Texts, Positions } from "../../../assets/styles";
import BrandStoryPost from "./BrandStoryPost";
import BrandStoryQna from "./BrandStoryQna";
import BrandStoryAB from "./BrandStoryAB";
import { FeedItem, TabTypeCode } from "../../../api/brand/model";
import { Styles } from "../../../assets/types/Style";
import { BannerInfo } from "../../../api/banner/model";
import CommonBanner from "../../../components/common/CommonBanner";

export interface Tab {
  key: TabTypeCode;
  title?: string;
  accessibilityLabel?: string;
  content?: ReactElement | ((isActive: boolean) => ReactElement);
  content01?: ReactElement | ((isActive: boolean) => ReactElement);
}

const BrandStoryList = ({
  currentTab,
  changeTab,
  feedList,
  feedList01,
  bannerList,
  onEndReached
}: {
  currentTab: TabTypeCode;
  changeTab: (tab: TabTypeCode) => void;
  feedList: FeedItem[];
  feedList01: FeedItem[];
  bannerList: BannerInfo[];
  onEndReached: any;
}) => {

  const { t } = useTranslation();

  const tabList: Tab[] = [
    {
      key: TabTypeCode.POST,
      title: t(`screen.myHome.label.post`),
      content: <BrandStoryPost renderData={feedList} listKey={"BrandStoryPost"} onEndReached={onEndReached} />,
      content01: <BrandStoryPost renderData={feedList01} listKey={"BrandStoryPost01"} onEndReached={onEndReached} />,
      accessibilityLabel: "post",
    },
    {
      key: TabTypeCode.QNA,
      title: t(`screen.myHome.label.qna`),
      content: <BrandStoryQna renderData={feedList} listKey={"BrandStoryQna"} onEndReached={onEndReached} />,
      content01: <BrandStoryQna renderData={feedList01} listKey={"BrandStoryQna01"} onEndReached={onEndReached} />,
      accessibilityLabel: "qna",
    },
    {
      key: TabTypeCode.AB,
      title: t(`screen.myHome.label.ab`),
      content: <BrandStoryAB renderData={feedList} listKey={"BrandStoryAB"} onEndReached={onEndReached} />,
      content01: <BrandStoryAB renderData={feedList01} listKey={"BrandStoryAB01"} onEndReached={onEndReached} />,
      accessibilityLabel: "ab",
    },
  ];
  return (
    <>
      <div style={styles.bx_in_title}>
        <div style={styles.title_text}>
          <div style={styles.bg_title} />
          <p style={styles.title_text_st1}>{t("screen.brandCommunity.label.brandStory")}</p>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: "row", ...styles?.tabsContainerStyle }}>
        {tabList?.map(tab => {
          return (
            <div
              key={tab.key}
              style={styles?.tabWrapperStyle}
              onClick={() => {
                changeTab(tab.key);
              }}
            >
              <div style={currentTab === tab.key ? styles.tabTextContainerActiveStyle : styles.tabTextContainerStyle}>
                <p style={currentTab === tab.key ? styles.tabTextActiveStyle : styles.tabTextStyle}>
                  {tab.title}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {tabList.find((item) => item.key === currentTab)?.content}
      {bannerList?.length > 0 && (
        <CommonBanner
          bannerList={bannerList}
          bannerSizeStyle={{ width: '100%', height: 120 }}
          otherCustomCarouselSettings={{
            nextArrow: <></>,
            prevArrow: <></>,
          }}
        />
      )}
      <div style={{ height: 25 }}></div>
      {feedList01.length > 0 && tabList.find((item) => item.key === currentTab)?.content01}
    </>
  );
};

export default BrandStoryList;

const styles: Styles = {
  bg_title: {
    height: 8,
    ...Positions.absolute,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#F7E237",
  },
  bx_in_title: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.margin_bottom_6,
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_top_10,
  },
  title_text: {
    ...Positions.relative,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  title_text_st1: {
    fontSize: 18,
    fontWeight: "500",
    color: "#222",
    letterSpacing: -0.72,
    zIndex: 1,
  },
  tabTextContainerStyle: {
    ...Colors.background_transparent,
    ...Spacings.margin_bottom_10,
  },
  tabTextStyle: {
    borderWidth: 1,
    borderStyle: 'solid',
    ...Texts.contents_text_8,
    ...Spacings.padding_left_right_20,
    ...Spacings.padding_top_bottom_10,
    ...Spacings.margin_right_6,
    ...Colors.font_222,
    ...Colors.border_color_d9d9d9,
    ...Colors.background_fff,
    overflow: "hidden",
    borderRadius: 18,
  },
  tabTextActiveStyle: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 18,
    ...Texts.contents_text_8,
    ...Texts.font_weight_bold,
    ...Spacings.padding_left_right_20,
    ...Spacings.padding_top_bottom_10,
    ...Spacings.margin_right_6,
    ...Colors.font_fff,
    ...Colors.border_color_222,
    backgroundColor: "#222",
    overflow: "hidden",
  },
  tabWrapperStyle: {
    ...Spacings.padding_top_bottom_10,
  },
  tabsContainerStyle: {
    width: "100%",
    ...Spacings.padding_left_right_14,
  },
  tabTextContainerActiveStyle: {},
};
