import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { t } from 'i18next'
import { checkBadWord } from '../api/badWord/api'
import { getFilePreSignedUrl } from '../api/file/api'
import {
  FileUrlRequest,
  FileUrlResponse,
  toHeight,
  UploadFile,
} from '../api/file/model'
import { createPost, deletePost, getRecommendTag } from '../api/post/api'
import {
  FeedWriteProduct,
  PostContents,
  PostWriteRequest,
  PostWriteResponse,
  RecommendTagResponse,
} from '../api/post/model'
import { NewProduct } from '../api/product/model'
import { searchTag } from '../api/tag/api'
import { TagSearchRequest, TagSearchResponse } from '../api/tag/model'
import {
  FEED_TAG_SEARCH_COUNT,
  FeedMode,
  FeedTypeCode,
  POST_BLANK_FILE,
  POST_MODIFY_BLANK,
  POST_MODIFY_BLANK_CONTENTS,
  UploadTypeCode,
} from '../constants/Feed'
import CommonStore from './CommonStore'
import { ErrorCode } from '../constants/Error'
import { AlbumMulitResponse, callNativeAlbum, ImageForUI } from '../utils/album'
import { Buffer } from 'buffer'
import { SuccessOrNot } from '../constants/Common.enum'
import { BucketTypeCode, FileType } from '../constants/File'
import { ProductForWrite } from './ProductSearchAddStore'
import UploadStore from './upload/UploadStore'
import { UploadStatus } from '../constants/upload/UploadStatus'
import { showBottomToast } from '../utils/Toast'
import FeedDetailStore from './FeedDetailStore'
import { UserActTypeCode } from '../constants/FeedType.enum'
import PostStore from './PostStore'
import MyHomeStore from './MyHomeStore'
import SearchStore from './SearchStore'
import TagStore from './TagStore'
import MainStore from './MainStore'
import { AccountFeedTypeCode } from '../constants/Account'
import { CustomError } from '../utils/customError'
import { bottomOffset, sleep } from '../utils/common'
import PostDetailStore from './PostDetailStore'
import BookmarkStore from './BookmarkStore'
import {
  convertPostDetailToPost,
  convertPostToTagFeedItem,
} from '../utils/convert'
import { goBack } from '../hooks/navigate/NavigateFunction'
export interface PostModifyUIFile {
  fileId?: number
  originalFileName: string
  fileName: string
  filePath: string
  imageLocalFilePath?: string
  videoLocalFilePath?: string
  thumbnailFilePath?: string
  thumbnailTempPath?: string
  imageOriginLocalFilePath?: string
  fileResolution?: string
  // 이미지 압축파일이 떨궈지는 경로
  imageTempPath?: string
  // 압축파일이 생성되는 경로
  videoTempPath?: string

  fileSize: number
  fileType: string
  fileExtension: string
  thumbnailYn: string
  bucketTypeCode?: string
  uploadTypeCode?: string
  modified?: boolean
}
export const toPostModifyUIFileHeight = (
  file: PostModifyUIFile,
  width: number
) => {
  let defh = width

  if (file.fileResolution !== null && file.fileResolution !== undefined) {
    defh = toHeight(file.fileResolution ?? '', width)
  }
  return defh
}
export interface PostModifyUIContentsItem {
  feedId: number
  feedContentId?: number
  bucketTypeCode?: string
  uploadTypeCode?: string
  files: PostModifyUIFile[]
  feedFileGroupId?: number
  contents: string
}

export interface PostModifyUIContents {
  id: number
  contents: PostModifyUIContentsItem
  modified?: boolean
}

export interface PostModifyUI {
  feedId: number
  title: string
  contents: PostModifyUIContents[]
  products?: FeedWriteProduct[]
  tags: string[]
  rating?: number
  advantage?: string
  disadvantage?: string
}

class PostModifyStore {
  @observable errorMessage?: string
  @observable toastMessage?: string

  @observable hasTemp = false
  @observable wholeTempData: PostModifyUI = POST_MODIFY_BLANK
  @observable contentsTempData: PostModifyUIContents[] = [
    { id: 0, contents: POST_MODIFY_BLANK_CONTENTS },
  ]
  @observable tempUpdated = false
  @observable isShowRating = false
  @observable tagSearchResult?: TagSearchResponse | null
  @observable tempTagList: string[] = []
  @observable tagUpdated = false
  @observable isVisibleTagList = false
  @observable isValidContents = false
  @observable isChanged = false
  @observable lastFocus: any
  @observable recommendTags: RecommendTagResponse[] = []
  @observable allContentValid = true
  @observable allFilesValid = true
  @observable successRegister = false
  @observable tempProductList: ProductForWrite[] = []
  @observable productUpdated = false
  //@observable videoCompressing = false;
  @observable isPostSaveIng = false
  @observable isPostRegistError = false
  @observable wholeDataForSaveCall: PostModifyUI = POST_MODIFY_BLANK

  constructor() {
    makeObservable(this)
  }

  @computed get hasError() {
    return (
      this.errorMessage !== null &&
      this.errorMessage !== undefined &&
      this.errorMessage.length > 0
    )
  }

  @computed get isValid() {
    let checkingValid = false

    if (
      this.wholeTempData == null ||
      JSON.stringify(this.wholeTempData) === JSON.stringify(POST_MODIFY_BLANK)
    )
      return false

    checkingValid =
      this.wholeTempData?.title?.trim()?.length > 0 &&
      this.tempTagList?.length > 0 &&
      this.isValidContents

    if (this.contentsTempData.length > 0) {
      const advantage = (this.wholeTempData?.advantage?.trim()?.length || 0) > 0
      const disadvatage =
        (this.wholeTempData?.disadvantage?.trim()?.length || 0) > 0
      const rating = (this.wholeTempData?.rating || 0) > 0
      checkingValid =
        checkingValid &&
        advantage === disadvatage &&
        advantage === rating &&
        disadvatage === rating
    }

    //checkingValid = checkingValid && !this.videoCompressing;

    return checkingValid
  }

  @action showValidInfo = () => {
    if (this.wholeTempData?.title?.trim()?.length < 1) {
      showBottomToast(t('screen.post.message.inputTitle'), bottomOffset())
      return
    }
    if (this.allFilesValid === false) {
      showBottomToast(t('screen.post.message.inputFile'), bottomOffset())
      return
    }
    if (this.allContentValid === false) {
      showBottomToast(t('screen.post.message.inputContent'), bottomOffset())
      return
    }
    if (this.tempTagList?.length < 1) {
      showBottomToast(t('screen.post.message.inputTag'), bottomOffset())
      return
    }

    if (
      (this.contentsTempData.length > 0 &&
        this.wholeTempData?.advantage &&
        this.wholeTempData?.advantage?.trim()?.length > 0) ||
      (this.wholeTempData?.disadvantage &&
        this.wholeTempData?.disadvantage?.trim()?.length > 0) ||
      (this.wholeTempData?.rating && this.wholeTempData?.rating > 0)
    ) {
      // 단락 둘 이상일 때 등록 조건.
      if (
        !this.wholeTempData?.advantage ||
        this.wholeTempData?.advantage?.trim()?.length < 1
      ) {
        showBottomToast(t('screen.post.message.inputAdvantage'), bottomOffset())
        return
      }
      if (
        !this.wholeTempData?.disadvantage ||
        this.wholeTempData?.disadvantage?.trim()?.length < 1
      ) {
        showBottomToast(
          t('screen.post.message.inputDisadvatage'),
          bottomOffset()
        )
        return
      }
      if (!this.wholeTempData?.rating || this.wholeTempData?.rating === 0) {
        showBottomToast(t('screen.post.message.inputRating'), bottomOffset())
        return
      }
    }
  }

  @action setError = (message?: string) => {
    this.errorMessage = message
  }

  @action setToast = (message?: string) => {
    this.toastMessage = message
  }

  @action clear = () => {
    this.errorMessage = ''
    this.toastMessage = ''
    this.successRegister = false
    this.wholeTempData = POST_MODIFY_BLANK
    this.contentsTempData = [{ id: 0, contents: POST_MODIFY_BLANK_CONTENTS }]
    this.tempTagList = []
    // this.lastUploadIndex = 0;
    // this.callIndex = 0;
    this.allContentValid = true
    this.allFilesValid = true
    // this.uploadFileClear();
    this.wholeDataForSaveCall = POST_MODIFY_BLANK
    this.tagSearchResult = null
    this.tempProductList = []
    this.change()
  }

  @action change = () => {
    this.isChanged = !this.isChanged
  }

  @action dataUnion = () => {
    // 각각의 리스트의 데이터를 wholeTempData에 합체
    this.wholeTempData.contents = []
    this.contentsTempData.forEach((item) =>
      this.wholeTempData.contents.push({
        id: this.wholeTempData.contents.length,
        contents: item.contents,
      })
    )

    this.wholeTempData.products = []
    this.tempProductList?.forEach((item) =>
      item.product.openApiFlag && item.product.nproductId === undefined
        ? item.product.myProductId && item.product.myProductId != null
          ? this.wholeTempData.products?.push({
              id: item.product.myProductId,
              thumbnailYn: item.isRepresent ? 'Y' : 'N',
              tempProductYn:
                item.product?.openApiFlag || item.product?.tempProductId
                  ? 'Y'
                  : 'N',
              product_from: item.product.from ?? '',
            })
          : this.wholeTempData.products?.push({
              id: -1,
              openApiFlag: true,
              productId: item.product?.productId,
              productName: item.product?.productName,
              brandNameKor: item.product?.brandName,
              productModelName: item.product?.productModelName,
              lprice: item.product?.lprice,
              hprice: item.product?.hprice,
              thumbnailFilePath: item.product?.thumbnailFilePath,
              category1: item.product?.category1,
              category2: item.product?.category2,
              category3: item.product?.category3,
              productUrl: item.product?.productUrl,
            })
        : this.wholeTempData.products?.push({
            id: item.product?.productId || item.product?.tempProductId || -1,
            thumbnailYn: item.isRepresent ? 'Y' : 'N',
            tempProductYn:
              item.product?.openApiFlag || item.product?.tempProductId
                ? 'Y'
                : 'N',
          })
    )

    this.wholeTempData.tags = this.tempTagList
  }

  @action isValidContent = () => {
    this.allContentValid = true
    this.allFilesValid = true
    let tempValid = true
    this.contentsTempData?.forEach((_, index: number) => {
      const fileCheck =
        this.contentsTempData[index].contents?.files.findIndex(
          (item) => item.fileName.trim()?.length > 0
        ) > -1
      const contentCheck =
        (this.contentsTempData[index].contents?.contents || '').trim()?.length >
        0
      tempValid = tempValid && fileCheck && contentCheck
      if (fileCheck === false) this.allFilesValid = false
      if (contentCheck === false) this.allContentValid = false
    })
    this.isValidContents = tempValid
    this.dataUnion()
  }

  @action setWholeTempData = (data?: PostWriteRequest | null | any) => {
    if (data === null || data === undefined) this.clear()
    else {
      this.wholeTempData = data
      this.contentsTempData = []

      this.wholeTempData.contents.forEach((item, index) => {
        let imageFileLength = 0
        let videoFileLength = 0
        item.contents.files.forEach((file) => {
          if (file.fileType === FileType.IMAGE && file.fileSize >= 0)
            imageFileLength = imageFileLength + 1
          if (file.fileType === FileType.VIDEO && file.fileSize >= 0)
            videoFileLength = videoFileLength + 1
        })

        if (index === 0) {
          if (imageFileLength + videoFileLength < 10)
            item.contents.files.push(POST_BLANK_FILE) // 사진 추가하는 화면
        } else {
          if (
            (imageFileLength === 0 && videoFileLength < 1) ||
            (videoFileLength === 0 && imageFileLength < 3)
          )
            item.contents.files.push(POST_BLANK_FILE)
        }

        this.contentsTempData.push(item)
      })
      this.tempTagList = this.wholeTempData.tags
    }
    this.checkShowRating()
    this.isValidContent()
    this.tempUpdated = !this.tempUpdated
  }

  @observable fileUpdated = false
  @observable finishFileUpdate = false
  @observable finishFileUpdateIndex = -1

  @action setFinishFileUpdate = (finishFileUpdate: boolean) => {
    this.finishFileUpdate = finishFileUpdate
  }

  @action setFinishFileUpdateIndex = (contentsIndex: number) => {
    this.finishFileUpdateIndex = contentsIndex
  }

  @action checkAddedFiles = (contentsIndex: number) => {
    let imageFileLength = 0
    let videoFileLength = 0
    this.contentsTempData[contentsIndex].contents.files.forEach((item) => {
      if (item.fileType === FileType.IMAGE && item.fileSize >= 0)
        imageFileLength = imageFileLength + 1
      if (item.fileType === FileType.VIDEO && item.fileSize >= 0)
        videoFileLength = videoFileLength + 1
    })

    if (contentsIndex === 0) {
      if (imageFileLength + videoFileLength < 10)
        this.contentsTempData[contentsIndex].contents.files.push(
          POST_BLANK_FILE
        ) // 사진 추가하는 화면
    } else {
      if (
        (imageFileLength === 0 && videoFileLength < 1) ||
        (videoFileLength === 0 && imageFileLength < 3)
      ) {
        this.contentsTempData[contentsIndex].contents.files.push(
          POST_BLANK_FILE
        )
      }
    }

    if (
      contentsIndex === 0 &&
      this.contentsTempData[0].contents.files.findIndex(
        (i) => i.thumbnailYn === 'Y'
      ) < 0
    )
      this.contentsTempData[0].contents.files[0].thumbnailYn = 'Y'

    this.fileUpdated = !this.fileUpdated
    this.finishFileUpdate = true
    this.finishFileUpdateIndex = contentsIndex
    this.isValidContent()
    this.change()
  }

  @action deleteFile = (contentsIndex: number, fileIndex: number) => {
    if (contentsIndex === 0) {
      this.contentsTempData[0]?.contents?.files.splice(fileIndex, 1)
      if (this.contentsTempData[0]?.contents?.files.length > 0)
        this.setRepresentation(0)
    } else {
      this.contentsTempData[contentsIndex]?.contents?.files.splice(fileIndex, 1)
    }
    this.updateItem(contentsIndex)

    return this.contentsTempData[contentsIndex]?.contents?.files
  }

  @action moveItem = (
    // items: DragEndParams<PostModifyUIFile>,
    items: any,
    contentsIndex: number
  ) => {
    if (
      items.from >
      this.contentsTempData[contentsIndex]?.contents?.files.length - 2
    )
      return this.contentsTempData[contentsIndex]?.contents?.files
    if (
      items.to >
      this.contentsTempData[contentsIndex]?.contents?.files.length - 2
    )
      return this.contentsTempData[contentsIndex]?.contents?.files
    if (contentsIndex === 0) {
      const d = this.contentsTempData[0]?.contents?.files[items.from]
      this.contentsTempData[0]?.contents?.files.splice(items.from, 1)
      this.contentsTempData[0]?.contents?.files.splice(items.to, 0, d)
      if (this.contentsTempData[0]?.contents?.files.length > 0)
        this.setRepresentation(0)
    } else {
      const d =
        this.contentsTempData[contentsIndex]?.contents?.files[items.from]
      this.contentsTempData[contentsIndex]?.contents?.files.splice(
        items.from,
        1
      )
      this.contentsTempData[contentsIndex]?.contents?.files.splice(
        items.to,
        0,
        d
      )
      //      this.contentsTempData[contentsIndex]?.contents?.files.splice(fileIndex, 1);
    }

    this.updateItem(contentsIndex)

    return this.contentsTempData[contentsIndex]?.contents?.files
  }

  updateItem = (contentsIndex: number) => {
    let imageFileLength = 0
    let videoFileLength = 0
    let notExistEmptyPathFile = true
    this.contentsTempData[contentsIndex]?.contents?.files.forEach((item) => {
      if (item.fileType === FileType.IMAGE && item.fileSize >= 0)
        imageFileLength = imageFileLength + 1
      if (item.fileType === FileType.VIDEO && item.fileSize >= 0)
        videoFileLength = videoFileLength + 1
      if (item.filePath === '') notExistEmptyPathFile = false
    })

    if (contentsIndex === 0) {
      if (imageFileLength + videoFileLength < 10 && notExistEmptyPathFile)
        this.contentsTempData[contentsIndex].contents.files.push(
          POST_BLANK_FILE
        ) // 사진 추가하는 화면
    } else {
      if (
        (imageFileLength === 0 &&
          videoFileLength < 1 &&
          notExistEmptyPathFile) ||
        (videoFileLength === 0 && imageFileLength < 3 && notExistEmptyPathFile)
      )
        this.contentsTempData[contentsIndex].contents.files.push(
          POST_BLANK_FILE
        )
    }

    this.fileUpdated = !this.fileUpdated
    this.finishFileUpdate = true
    this.finishFileUpdateIndex = contentsIndex
    this.isValidContent()
    this.change()
  }

  @action checkShowRating = () => {
    this.isShowRating = this.contentsTempData?.length > 0
    this.tempUpdated = !this.tempUpdated
  }

  @action addContents = () => {
    if (this.contentsTempData.length < 10) {
      this.contentsTempData.push({
        id: this.contentsTempData[this.contentsTempData.length - 1].id + 1,
        contents: POST_MODIFY_BLANK_CONTENTS,
        modified: true,
      })
      this.tempUpdated = !this.tempUpdated
      this.isValidContent()
    } else {
      showBottomToast(t('screen.post.message.maxContentes'), bottomOffset())
    }
    this.checkShowRating()
    this.change()
  }

  @action removeContents = (index: number) => {
    this.contentsTempData.splice(index, 1)
    this.tempUpdated = !this.tempUpdated
    //4920 컨텐츠 삭제 시 장단점이 1개였을떄도 노출되도록 로직변경 떄문에 해당 내용 주석처리
    if (this.contentsTempData.length < 1) {
      delete this.wholeTempData?.disadvantage
      delete this.wholeTempData?.advantage
      delete this.wholeTempData?.rating
    }

    this.checkShowRating()
    this.isValidContent()
    this.change()
  }

  @action updateFilterFile2 = (
    contentsIndex: number,
    fileInfo: { fileIndex: number; uri: string; urithumbnail: string }[]
  ) => {
    fileInfo.forEach((fileitem) => {
      const item =
        this.contentsTempData[contentsIndex]?.contents?.files[
          fileitem.fileIndex
        ]

      item.thumbnailTempPath = fileitem.urithumbnail
      item.imageLocalFilePath = fileitem.uri

      this.contentsTempData[contentsIndex]?.contents?.files.splice(
        fileitem.fileIndex,
        1
      )
      this.contentsTempData[contentsIndex]?.contents?.files.splice(
        fileitem.fileIndex,
        0,
        item
      )
    })

    this.fileUpdated = !this.fileUpdated
    this.finishFileUpdate = true
    this.finishFileUpdateIndex = contentsIndex
    this.change()
  }

  @action setRepresentation = (index: number) => {
    this.contentsTempData[0].contents.files.forEach(
      (item) => (item.thumbnailYn = 'N')
    )
    this.contentsTempData[0].contents.files[index].thumbnailYn = 'Y'
    this.fileUpdated = !this.fileUpdated
    this.finishFileUpdate = true
    this.finishFileUpdateIndex = 0
    this.change()
  }

  @action setContentContents = (contentsIndex: number, contents: string) => {
    this.contentsTempData[contentsIndex].contents.contents = contents
    this.tempUpdated = !this.tempUpdated
    this.isValidContent()
    this.change()
  }

  @action setTitle = (title: string) => {
    this.wholeTempData.title = title
    this.change()
  }

  @action setAdvantage = (advantage: string) => {
    this.wholeTempData.advantage = advantage
    this.change()
  }

  @action setDisadvantage = (disadvantage: string) => {
    this.wholeTempData.disadvantage = disadvantage
    this.change()
  }

  @action setRatingPoint = (point: number) => {
    this.wholeTempData.rating = point
    this.tempUpdated = !this.tempUpdated
    this.change()
  }

  @action searchTag = async (word: string, startNumber: number) => {
    if (startNumber === 0) {
      this.tagSearchResult = null
    }
    const requestItem: TagSearchRequest = {
      query: word,
      synonymYn: 'Y',
      pageSize: FEED_TAG_SEARCH_COUNT,
      pageIndex: startNumber,
    }
    const res = await searchTag(requestItem)
    this.setTagSearchResult(res, startNumber)
  }

  @action setTagSearchResult = (
    response: TagSearchResponse | null,
    startNumber: number
  ) => {
    if (startNumber === 0) {
      this.tagSearchResult = response
    } else response?.list && this.tagSearchResult?.list.push(...response?.list)
    this.tagUpdated = !this.tagUpdated
  }

  @action checkValidWord = async (
    word: string,
    focus: any,
    inputName: string,
    successAction?: () => void
  ) => {
    if (word.trim().length < 1) return
    this.lastFocus = focus
    const res = await checkBadWord(word)
    if (res != null && res !== undefined) {
      if (res?.hasBadWord === 'N') {
        if (successAction !== undefined) successAction()
      } else {
        showBottomToast(t('screen.post.message.validError'), bottomOffset())
      }
    } else {
      showBottomToast(t('common.error.server'), bottomOffset())
    }
  }

  @action addTag = (tag: string) => {
    if (tag.trim()?.length < 1) return
    if (this.tempTagList.length > 9) {
      showBottomToast(t('screen.post.message.maxTag'), bottomOffset())
      return
    }
    const duplication = this.tempTagList.findIndex((text) => text === tag)
    if (duplication < 0) this.tempTagList.push(tag)
    this.setVisibleTagList(false)
    this.dataUnion()
    this.change()
  }

  @action deleteTag = (tag: string) => {
    const targetIndex = this.tempTagList.findIndex((text) => text === tag)
    this.tempTagList.splice(targetIndex, 1)
    this.dataUnion()
    this.change()
  }

  @action setVisibleTagList = (isVisible: boolean) => {
    this.isVisibleTagList = isVisible
  }

  @action setProductList = (list: ProductForWrite[]) => {
    this.tempProductList = list
  }

  @action addProduct = (item: NewProduct) => {
    const duplication = this.tempProductList.findIndex(
      (info) =>
        item.productId ===
        (info.product.productId || info.product.tempProductId)
    )
    if (duplication < 0) {
      this.tempProductList.push({ product: item, isRepresent: false })
      this.setAutoRepresent()
    } else {
      showBottomToast(
        t('screen.feedProduct.message.alreadyAdd'),
        bottomOffset()
      )
    }

    const tempProductIds: number[] = []
    this.tempProductList?.forEach(
      (item) =>
        item?.product?.productId &&
        !item?.product?.openApiFlag &&
        tempProductIds.push(item.product.productId)
    )
    void this.getRecommendTag(tempProductIds)
  }

  @action setAutoRepresent = () => {
    if (this.tempProductList && this.tempProductList.length > 0) {
      const representIndex = this.tempProductList.findIndex(
        (item) => item.isRepresent && item.product.productId
      )
      if (representIndex < 0) {
        const willBeRepresentIndex = this.tempProductList.findIndex(
          (item) => item.product.productId
        )
        if (willBeRepresentIndex > -1)
          this.tempProductList[willBeRepresentIndex].isRepresent = true
      }
    }
    this.dataUnion()
    this.productUpdated = !this.productUpdated
  }

  @action deleteProduct = (index: number) => {
    this.tempProductList.splice(index, 1)
    this.setAutoRepresent()

    const tempProductIds: number[] = []
    this.tempProductList?.forEach(
      (item) =>
        item?.product?.productId &&
        !item?.product?.openApiFlag &&
        tempProductIds.push(item.product.productId)
    )
    void this.getRecommendTag(tempProductIds)
  }

  @action setRepresentProduct = (index: number) => {
    this.tempProductList.forEach((item) => (item.isRepresent = false))
    this.tempProductList[index].isRepresent = true
    this.dataUnion()
    this.productUpdated = !this.productUpdated
  }

  @action deletePost = async (feedId: number) => {
    const res = await deletePost([feedId])
    if (res) goBack();
  }

  @action deletePostAtList = async (feedId: number) => {
    return await deletePost([feedId])
  }

  @action updatePost = (successAction?: () => void) => {
    const apiData = this.createRequest()

    createPost(apiData)
      .then((res) => {
        this.wholeDataForSaveCall = POST_MODIFY_BLANK
        CommonStore.setLoading(false)
        this.setIsPostSaveIng(false)
        if (res.successOrNot == SuccessOrNot.Y && res.statusCode == 'OK') {
          runInAction(() => {
            const data: PostWriteResponse = res.data as PostWriteResponse
            if (data?.feedId != undefined && data?.feedId > 0) {
              if (successAction) successAction()
              this.successRegister = true
              CommonStore.setToastOption({
                show: true,
                message1: t('screen.post.message.modified'),
                // bottomOffset: 30,
              })
            }
          })
        } else {
          runInAction(() => {
            if (res.statusCode === ErrorCode.HAS_BAD_WORD.code) {
              this.setError(t(ErrorCode.HAS_BAD_WORD.message))
            } else if (res.statusCode === ErrorCode.HAS_BLOCKED_TAG.code) {
              if (res?.blockedTagNames !== undefined) {
                const tagString = res?.blockedTagNames
                  ?.replace('[', '')
                  .replace(']', '')
                this.setError(
                  t(ErrorCode.HAS_BLOCKED_TAG.message, { tag: tagString })
                )
              }
            } else if (res.statusCode === ErrorCode.PARAM_LENGTH_ERR.code) {
              if (res.lengthErrorParameter === 'advantage') {
                this.setError(t('common.error.lengthLimit.advantage'))
              } else if (res.lengthErrorParameter === 'disadvantage') {
                this.setError(t('common.error.lengthLimit.disadvantage'))
              } else if (res.lengthErrorParameter === 'title') {
                this.setError(t('common.error.lengthLimit.title'))
              } else {
                this.setError(t('common.error.lengthLimit.default'))
              }
            } else {
              this.setError(t('common.error.server'))
            }
          })
        }
      })
      .catch(() => {
        this.wholeDataForSaveCall = POST_MODIFY_BLANK
        CommonStore.setLoading(false)
        this.setIsPostSaveIng(false)
      })
  }

  @action callUpdatePostApi = () => {
    const apiRequest = this.createRequest()

    return createPost(apiRequest)
      .then((res) => {
        this.wholeDataForSaveCall = POST_MODIFY_BLANK
        this.setIsPostSaveIng(false)
        return res
      })
      .then((res) => {
        if (res.successOrNot === SuccessOrNot.Y && res.statusCode === 'OK') {
          return res
        } else {
          throw new Error('fail')
        }
      })
      .then((res) => {
        const data: PostWriteResponse = res.data as PostWriteResponse
        if (data?.feedId !== undefined && data?.feedId > 0) {
          return data.feedId
        } else {
          throw new Error('fail')
        }
      })
      .catch((e) => {
        if (e.name === 'fail') UploadStore.fail()
        return -1
      })
  }

  @action createRequest = () => {
    const apiData: PostWriteRequest = {
      feedId: this.wholeDataForSaveCall.feedId,
      title: this.wholeDataForSaveCall.title,
      contents: [],
      products: this.wholeDataForSaveCall.products,
      tagNames: this.wholeDataForSaveCall.tags,
      rating: this.wholeDataForSaveCall.rating,
      advantage: this.wholeDataForSaveCall.advantage,
      disadvantage: this.wholeDataForSaveCall.disadvantage,
    }

    this.wholeDataForSaveCall.contents.forEach((item, index) => {
      const contentItem: PostContents = {
        feedId: item.contents.feedId,
        bucketTypeCode: item.contents.bucketTypeCode || BucketTypeCode.PUBLIC,
        uploadTypeCode: item.contents.uploadTypeCode || UploadTypeCode.POST,
        files: [],
        content: item.contents.contents,
      }

      if (
        item.modified === undefined ||
        item.modified === null ||
        item.modified === false
      ) {
        contentItem.feedContentId = item.contents.feedContentId
        contentItem.feedFileGroupId = item.contents.feedFileGroupId
      }

      item.contents.files.forEach((file) => {
        contentItem.files.push({
          fileId: file.fileId,
          fileResolution: file.fileResolution,
          originalFileName: file.originalFileName,
          fileName: file.fileName,
          filePath: file.filePath,
          thumbnailFilePath: file.thumbnailFilePath,
          fileSize: file.fileSize,
          fileType: file.fileType,
          fileExtension: file.fileExtension,
          thumbnailYn: file.thumbnailYn,
          bucketTypeCode: file.bucketTypeCode || BucketTypeCode.PRIVATE,
          uploadTypeCode: file.uploadTypeCode || UploadTypeCode.POST,
        })
      })

      apiData.contents.push(contentItem)
    })

    return apiData
  }

  // [PPIT210117 - 4815] 포스트 저장 로직 변경
  @action setIsPostSaveIng = (isPostSaveIng: boolean) => {
    this.isPostSaveIng = isPostSaveIng
  }

  // [PPIT210117 - 4815] 포스트 저장 로직 변경
  @action setIsPostRegistError = (isPostRegistError: boolean) => {
    this.isPostRegistError = isPostRegistError
  }

  @action uploadFile = async (
    file: UploadFile,
    preSignedUrl: string,
    isVideo: boolean
  ) => {
    // TODO: FS 구현하면 주석해제
    // const filePath = isVideo
    //   ? await fs.readFile(file.thumbnailFilePath || '', 'base64')
    //   : file.filePath
    const filePath = file.filePath
    const spOriginalFileName = file.originalFileName.split('.')
    const videoSide =
      spOriginalFileName[spOriginalFileName.length - 1]
        .toLowerCase()
        .indexOf('mp4') > -1 ||
      spOriginalFileName[spOriginalFileName.length - 1]
        .toLowerCase()
        .indexOf('mov') > -1
    const buffer = Buffer.from(filePath, 'base64')

    let contentType = `${file.fileType.toLocaleLowerCase()}/${
      file.fileExtension
    }`
    if (videoSide && !isVideo)
      contentType = `${FileType.IMAGE.toLocaleLowerCase()}/jpg`

    return fetch(preSignedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': `${contentType}; charset=utf-8`,
      },
      body: buffer,
    })
  }

  // [PPIT210117 - 4815] 포스트 저장 로직 변경
  @action getPreSignedUrl = async (urlRequest: FileUrlRequest) => {
    const res = await getFilePreSignedUrl(urlRequest)
    let returnData: FileUrlResponse = {
      presignedUrl: '',
      filePath: '',
      fileThumbnailPath: '',
    }
    if (res != null && res.length > 0) {
      returnData = {
        presignedUrl: res[0].presignedUrl,
        filePath: res[0].filePath,
        fileThumbnailPath: res[0].fileThumbnailPath,
      }
    }
    return returnData
  }

  // [PPIT210117 - 4815] 포스트 저장 로직 변경
  @action postRegist = async (successAction?: () => void) => {
    this.setIsPostRegistError(false)
    if (!this.isPostSaveIng) {
      this.setIsPostSaveIng(true)

      this.wholeDataForSaveCall = JSON.parse(JSON.stringify(this.wholeTempData))
      await Promise.all(
        this.wholeTempData.contents.map(async (content, contentIndex) => {
          await Promise.all(
            content.contents.files.map(async (file, fileIndex) => {
              if (
                file.filePath.trim().length > 0 &&
                file.fileSize >= 0 &&
                file.modified === true
              ) {
                const isVideo = file.fileType.toUpperCase() === FileType.VIDEO
                //image upload
                const imageFileName =
                  file.fileName +
                  (isVideo ? '_thumb.jpg' : '.' + file.fileExtension)
                const urlRequest: FileUrlRequest = {
                  bucketTypeCode: BucketTypeCode.PUBLIC,
                  uploadTypeCode: UploadTypeCode.POST,
                  fileNamesWithExtension: [imageFileName],
                  generateThumbnailYN: 'Y',
                }

                if (!this.isPostRegistError) {
                  const filePreSignedUrl: FileUrlResponse =
                    await this.getPreSignedUrl(urlRequest)
                  if (
                    !this.isPostRegistError &&
                    filePreSignedUrl.presignedUrl !== ''
                  ) {
                    const result = await this.uploadFile(
                      this.wholeTempData.contents[contentIndex].contents.files[
                        fileIndex
                      ],
                      filePreSignedUrl.presignedUrl,
                      false
                    )
                    if (result?.ok) {
                      if (isVideo) {
                        this.wholeDataForSaveCall.contents[
                          contentIndex
                        ].contents.files[fileIndex].thumbnailFilePath =
                          filePreSignedUrl.fileThumbnailPath
                      } else {
                        this.wholeDataForSaveCall.contents[
                          contentIndex
                        ].contents.files[fileIndex].filePath =
                          filePreSignedUrl.filePath
                        this.wholeDataForSaveCall.contents[
                          contentIndex
                        ].contents.files[fileIndex].thumbnailFilePath =
                          filePreSignedUrl.fileThumbnailPath
                      }
                    } else {
                      this.setIsPostRegistError(true)
                      this.setError(t('common.error.server'))
                      CommonStore.setLoading(false)
                      this.setIsPostSaveIng(false)
                    }
                  } else {
                    this.setIsPostRegistError(true)
                    this.setError(t('common.error.server'))
                    CommonStore.setLoading(false)
                    this.setIsPostSaveIng(false)
                  }

                  if (!this.isPostRegistError && isVideo) {
                    // video upload
                    const videoFileName =
                      file.fileName + '.' + file.fileExtension
                    const videoUrlRequest: FileUrlRequest = {
                      bucketTypeCode: BucketTypeCode.PUBLIC,
                      uploadTypeCode: UploadTypeCode.POST,
                      fileNamesWithExtension: [videoFileName],
                      generateThumbnailYN: 'Y',
                    }

                    const filePreSignedUrl: FileUrlResponse =
                      await this.getPreSignedUrl(videoUrlRequest)
                    if (
                      !this.isPostRegistError &&
                      filePreSignedUrl.presignedUrl !== ''
                    ) {
                      const result = await this.uploadFile(
                        this.wholeTempData.contents[contentIndex].contents
                          .files[fileIndex],
                        filePreSignedUrl.presignedUrl,
                        true
                      )
                      if (result?.ok) {
                        this.wholeDataForSaveCall.contents[
                          contentIndex
                        ].contents.files[fileIndex].filePath =
                          filePreSignedUrl.filePath
                      } else {
                        this.setIsPostRegistError(true)
                        this.setError(t('common.error.server'))
                        CommonStore.setLoading(false)
                        this.setIsPostSaveIng(false)
                      }
                    } else {
                      this.setIsPostRegistError(true)
                      this.setError(t('common.error.server'))
                      CommonStore.setLoading(false)
                      this.setIsPostSaveIng(false)
                    }
                  }
                }
              }
            })
          )
        })
      )

      if (!this.isPostRegistError) {
        void this.updatePost(successAction)
      }
    }
  }

  @action getRecommendTag = async (productIds?: number[]) => {
    const res = await getRecommendTag(productIds)
    runInAction(() => {
      if (res != null) {
        this.recommendTags = res
      } else {
        showBottomToast(t('common.error.server'), bottomOffset())
      }
    })
  }

  @action showNativeAlbum = async (contentIndex: number) => {
    CommonStore.setLoading(true)

    let imageFileLength = 0
    let videoFileLength = 0
    this.contentsTempData[contentIndex].contents.files.forEach((item) => {
      if (item.fileType === FileType.IMAGE && item.fileSize >= 0)
        imageFileLength = imageFileLength + 1
      if (item.fileType === FileType.VIDEO && item.fileSize >= 0)
        videoFileLength = videoFileLength + 1
    })

    const itemtype = this.contentsTempData[contentIndex]
    let w = 0
    let h = 0
    if (itemtype.contents.files.length > 0) {
      const f = itemtype.contents.files[0]

      //const arrf = f.fileName.split("___");
      const arrf = f.fileResolution
      if (f != null && f.fileResolution != null) {
        const strR = f.fileResolution.split('x')
        if (strR.length === 2) {
          const firstW = Number(strR[0])
          const firstH = Number(strR[1])
          w = firstW
          h = firstH
        }
      }
    }

    //this.videoCompressing = true;
    const maxImageCount =
      contentIndex === 0
        ? 6 - imageFileLength
        : videoFileLength > 0
        ? 0
        : 3 - imageFileLength < 0
        ? 0
        : 3 - imageFileLength
    const maxVideoCount =
      contentIndex === 0
        ? 4 - videoFileLength
        : imageFileLength > 0
        ? 0
        : 1 - videoFileLength < 0
        ? 0
        : 1 - videoFileLength
    const isAndType = contentIndex === 0 ? false : true

    // 동영상 압축 로직 안타도록 수정
    let res = (await callNativeAlbum(
      maxImageCount,
      maxVideoCount,
      isAndType,
      'POST',
      false,
      w,
      h
    )) as AlbumMulitResponse

    // this.videoCompressing = false;

    runInAction(() => {
      const resizeThumb = async (
        r: AlbumMulitResponse
      ): Promise<AlbumMulitResponse> => {
        for (const element of r.param.list) {
          if (element.type.toLocaleUpperCase() === 'IMAGE') {
            // const r = await ImageResizer.createResizedImage(
            //   element.fileUri,
            //   720,
            //   720,
            //   'JPEG',
            //   50
            // )
            const r = { uri: '' } // TODO: not implemented
            // const a = await RNFS.stat(element.thumbnailImagepath);
            // const b = await RNFS.stat(r.uri);
            // console.log(
            //   "resize>filesz(from:" +
            //     a.size +
            //     ",to:" +
            //     b.size +
            //     "),fromuri:" +
            //     element.thumbnailImagepath +
            //     ",to>" +
            //     r.uri
            // );
            element.thumbnailImagepath = r.uri
          }
        }
        return r
      }
      resizeThumb(res)
        .then((res2) => {
          res = res2

          const files: ImageForUI[] = res.param.list
          if (files.length > 0) {
            const blankIndex = this.contentsTempData[
              contentIndex
            ].contents.files.findIndex((i) => i.fileSize < 0)
            if (blankIndex > -1)
              this.contentsTempData[contentIndex].contents.files.splice(
                blankIndex,
                1
              )

            files.forEach((item) => {
              if (
                this.contentsTempData[contentIndex].contents.files.length < 10
              ) {
                const sp = item.fileName.split('.')
                this.contentsTempData[contentIndex].contents.files.push({
                  originalFileName: item.fileName,
                  fileName: sp[0],
                  filePath: item.base64,
                  imageLocalFilePath:
                    item.type.toLocaleUpperCase() === 'IMAGE'
                      ? item.fileUri
                      : '',
                  videoLocalFilePath:
                    item.type.toLocaleUpperCase() === 'VIDEO'
                      ? item.fileUri
                      : '',
                  imageOriginLocalFilePath:
                    item.type.toLocaleUpperCase() === 'IMAGE'
                      ? item.fileUri
                      : '',

                  thumbnailTempPath: item.thumbnailImagepath,
                  fileResolution: item.resThumbX + 'x' + item.resThumbY,
                  fileSize: Number(item.fileSize),
                  fileType: item.type.toLocaleUpperCase(),
                  fileExtension: sp[sp.length - 1],
                  thumbnailYn: 'N',
                  modified: true,
                })
              }
            })

            this.checkAddedFiles(contentIndex)
          }
          CommonStore.setLoading(false)
        })
        // `unlink` will throw an error, if the item to unlink does not exist
        .catch((err: any) => {
          CommonStore.setLoading(false)
          //       console.log(err.message);
        })
    })
  }

  @action uploadFilesToS3 = async () => {
    if (UploadStore.isStop)
      throw CustomError(
        UploadStatus.STOP,
        'file:PostModifyStore.ts,func:uploadFilesToS3'
      )

    UploadStore.uploading()
    if (this.isPostSaveIng) {
      const files = []
      //업로드 필요한 파일데이터 신규 배열 저장.
      for (const content of this.wholeTempData.contents) {
        files.push(...content.contents.files)
      }
      //업로드 처리 3개씩
      const resultQueue = await UploadStore.putFileToS3ByQueue(
        files,
        UploadTypeCode.POST,
        FeedMode.MODIFY
      )
      //유효성 체크
      UploadStore.validateQueue(resultQueue)
      //최종 데이터 병합
      this.mergeData(resultQueue)

      this.wholeDataForSaveCall = JSON.parse(JSON.stringify(this.wholeTempData))
    }
  }

  // 포스팅전 데이터 처리
  @action compressFiles = async (mode: FeedMode) => {
    this.preProcessWholeData()

    const compressFiles = []
    for (const content of this.wholeTempData.contents) {
      compressFiles.push(...content.contents.files)
    }
    const resultQueue = await UploadStore.compressByQueue(compressFiles, mode)
    //유효성 체크
    UploadStore.validateQueue(resultQueue)
    //최종 데이터 병합
    this.mergeData(resultQueue)
  }

  @action postingError = () => {
    this.setIsPostSaveIng(false)
    UploadStore.fail()
  }

  @action asyncUpdate = () => {
    const backGroundPostingTimer = setTimeout(() => {
      this.setIsPostSaveIng(true)

      UploadStore.start(FeedTypeCode.POST, this.wholeTempData.title, true)
      UploadStore.setAbortController(new AbortController())

      void this.showStartToast()
        .then(() => sleep(200))
        .then(() => this.compressFiles(FeedMode.MODIFY))
        .then(() => UploadStore.clearAbortController())
        .then(this.uploadFilesToS3) // s3 업로드
        .then(this.updatePosts) // 백엔드 업데이트
        .then(this.reset)
        .then(() => UploadStore.complete()) // 완료 처리
        .then(this.showCompleteToast) // 완료 토스트 팝업 노출
        .then(this.clear)
        .catch((e) => {
          if (e.name === UploadStatus.STOP || e.name === 'abortVideo') {
            UploadStore.clear()
          } else {
            UploadStore.fail()
          }
        })
        .finally(() => {
          this.setIsPostSaveIng(false)
          clearTimeout(backGroundPostingTimer)
        })
    }, 500)
  }

  preProcessWholeData = () => {
    this.wholeTempData.contents.forEach((item) => {
      item.contents.files = item.contents.files.filter(
        (x) => x.filePath.trim().length > 1
      )
    })
  }

  updatePosts = async () => {
    if (UploadStore.isStop) {
      throw CustomError(
        UploadStatus.STOP,
        'file:PostModifyStore.ts,func:updatePosts STOP'
      )
    }
    if (this.isPostRegistError) {
      throw CustomError(
        UploadStatus.FAIL,
        'file:PostModifyStore.ts,func:updatePosts FAIL'
      )
    }

    const feedId = await this.callUpdatePostApi()

    if (UploadStore.isFail || this.isPostRegistError) {
      throw CustomError(
        UploadStatus.FAIL,
        'file:PostModifyStore.ts,func:updatePosts ERROR'
      )
    }

    return feedId
  }

  showStartToast = () => {
    return new Promise((resolve, reject) => {
      if (!UploadStore.isDefaultStatus) {
        showBottomToast(t('screen.post.message.starting'), bottomOffset())
        resolve('success')
      } else {
        reject(
          CustomError(
            UploadStatus.FAIL,
            'file:PostModifyStore.ts,func:showStartToast'
          )
        )
      }
    })
  }

  showCompleteToast = () => {
    return new Promise(() => {
      if (UploadStore.status === UploadStatus.COMPLETE) {
        showBottomToast(t('screen.post.message.modified'), bottomOffset())
      }
    })
  }

  reset = (feedId: number) => {
    void PostDetailStore.getPostDetail(feedId).then((res) => {
      const convertPost = convertPostDetailToPost(res)

      FeedDetailStore.updateFeedList(feedId, convertPost)
      FeedDetailStore.updatePostInAllFeedListStore(
        UserActTypeCode.MODIFY,
        feedId,
        convertPost
      )
      PostStore.updatePost(feedId, convertPost)
      MyHomeStore.updatePost(feedId, convertPost)
      SearchStore.updatePost(feedId, convertPost)
      TagStore.updatePost(feedId, convertPostToTagFeedItem(res))
      BookmarkStore.setPostWithFeedId(convertPost, feedId)
      MainStore.updateFeed(UserActTypeCode.MODIFY, feedId, convertPost)
    })
    MyHomeStore.sync(feedId, AccountFeedTypeCode.POST, UserActTypeCode.MODIFY)
  }

  mergeData = (resultQueue: UploadFile[]) => {
    //최종 데이터 병합
    for (const content of this.wholeTempData.contents) {
      content.contents.files.forEach((prevFile) => {
        resultQueue.forEach((compressFile) => {
          if (
            prevFile.fileType === FileType.IMAGE &&
            prevFile.imageLocalFilePath === compressFile.imageLocalFilePath
          ) {
            prevFile = compressFile
          }
          if (
            prevFile.fileType === FileType.VIDEO &&
            prevFile.videoLocalFilePath === compressFile.videoLocalFilePath
          ) {
            prevFile = compressFile
          }
        })
      })
    }
  }
}

export default new PostModifyStore()
