import { action, makeObservable, observable } from "mobx";
import { getFilePreSignedUrl } from "../api/file/api";
import { FileUrlRequest } from "../api/file/model";
import { ImageForUI } from "../utils/album";
import { Buffer } from "buffer";
import { BucketTypeCode, FileType } from "../constants/File";

class ProfileStore {
  constructor() {
    makeObservable(this);
  }

  @observable cameraProfile: ImageForUI | null = null;

  @action clear = () => {
    this.cameraProfile = null;
  };

  @action setCameraProfile = (cameraProfile: ImageForUI) => {
    this.cameraProfile = cameraProfile;
  };

  @action uploadFileAndGetFilePath = async (image: ImageForUI) => {
    let filePath = "";

    const urlRequest: FileUrlRequest = {
      bucketTypeCode: BucketTypeCode.PUBLIC,
      uploadTypeCode: "PROFILE",
      fileNamesWithExtension: [image.fileName],
      generateThumbnailYN: "N",
    };

    const [fileUrlResponse] = await getFilePreSignedUrl(urlRequest);

    // if (fileUrlResponse) {
    //   const [, fileExtension] = image.fileName.split(".");
    //   const contentType = `${(image.type || FileType.IMAGE).toLocaleLowerCase()}/${fileExtension}`;
    //   const buffer = Buffer.from(image.base64, "base64");

    //   const uploadResponse = await fetch(fileUrlResponse.presignedUrl, {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": `${contentType}; charset=utf-8`,
    //     },
    //     body: buffer,
    //   });

    //   filePath = uploadResponse.ok ? fileUrlResponse.filePath : "";
    // }

    return filePath;
  };
}

export default new ProfileStore();
