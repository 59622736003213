import { observer } from "mobx-react";
import { Virtuoso } from "react-virtuoso";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./AbListView.scss";

import {
  AbDetail,
  AbSearchCondition,
  AbVoteResult,
  SortType,
} from "../../../api/ab/model";
import { AbTypeCode } from "../../../constants/ab";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import AbStore, { PAGE_SIZE } from "../../../store/AbListStore";
import AbItem from "./AbListItem";
import AbListFilter from "./AbListFilter";
import HorizontalAbList from "./HorizontalAbList";
import { ActSectionCode } from "../../../constants/ActHistory";
// import TopButton from "../../../components/common/TopButton";
import { handleTopButtonScroll } from "../../../utils/common";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";

const AbListView = observer(() => {
  const {
    activePopularAbs,
    expiredPopularAbs,
    Abs,
    retrieveActivePopularAbList,
    retrieveExpiredPopularAbList,
    retrieveAbList,
    AbLoadingStatus,
    setAbLoadingStatus,
    updateAbVoteResultTemporaily,
    updatePopularAbVoteResultTemporaily,
    extraDataforAbListView,
    setNewExtraDataforAbListView,
    clear,
  } = AbStore;
  const [searchCondition, setSearchCondition] = useState<AbSearchCondition>({
    endYn: "N",
    pageIndex: 0,
    sortType: "Latest",
    pageSize: PAGE_SIZE,
  });

  const [showTopButton, setShowTopButton] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setNewExtraDataforAbListView();
  }, []);

  const ref = useRef<typeof Virtuoso>(null);

  const handleFilterChange = (sortType: SortType) => {
    setAbLoadingStatus("LOADED");
    setSearchCondition({
      pageIndex: 0,
      sortType,
      pageSize: PAGE_SIZE,
      endYn: "N",
    });
  };

  const handleRefresh = () => {
    setAbLoadingStatus("REFRESHING");
    setSearchCondition((prev) => {
      return { ...prev, pageIndex: 0 };
    });
    void retrieveActivePopularAbList();
    void retrieveExpiredPopularAbList();
  };

  const handleFlatListEndReached = () => {
    if (AbLoadingStatus === "LOADED") {
      setSearchCondition((prev) => {
        return { ...prev, pageIndex: prev.pageIndex + 1 };
      });
    }
  };

  const handleVotePopularAbSuccess = (
    res: AbVoteResult,
    myAbFlag: AbTypeCode
  ) => {
    updatePopularAbVoteResultTemporaily(res, myAbFlag);
  };
  const handleVoteAbSuccess = (res: AbVoteResult, myAbFlag: AbTypeCode) => {
    updateAbVoteResultTemporaily(res, myAbFlag);
  };

  useEffect(() => {
    void retrieveAbList(searchCondition);
  }, [retrieveAbList, searchCondition]);

  useEffectOnlyOnce(
    () => {
      void retrieveActivePopularAbList();
      void retrieveExpiredPopularAbList();
    },
    () => {
      clear();
    }
  );

  return (
    <div id="ab_list_view">
      <>
        {activePopularAbs.length > 0 && (
          <>
            <div className="ab_horizontal_list_title">
              <div>
                <div className="title_background title_1" />
                <p className="ab_horizontal_list_title_text">
                  {t("screen.ab.label.activePopular")}
                </p>
              </div>
            </div>
            <HorizontalAbList
              abs={activePopularAbs}
              actSectionCode={ActSectionCode.POPULAR_AB}
              onVoteSuccess={handleVotePopularAbSuccess}
            />
          </>
        )}
        {expiredPopularAbs.length > 0 && (
          <>
            <div className="ab_horizontal_list_title">
              <div>
                <div className="title_background title_2" />
                <p className="ab_horizontal_list_title_text">
                  {t("screen.ab.label.expiredPopular")}
                </p>
              </div>
            </div>
            <HorizontalAbList
              abs={expiredPopularAbs}
              actSectionCode={ActSectionCode.EXPIRED_AB}
            />
          </>
        )}
        {(activePopularAbs.length > 0 || expiredPopularAbs.length > 0) && (
          <div className="bar" />
        )}
        <AbListFilter
          value={searchCondition.sortType}
          onChange={handleFilterChange}
        />
      </>
      {Abs.length > 0 ? (
        <div className="contents">
          {Abs.map((ab: any, colIndex: number) => {
            return (
              <AbItem
                index={colIndex}
                ab={ab}
                key={ab.feedId}
                onVoteSuccess={handleVoteAbSuccess}
                orderedBy={searchCondition.sortType}
              />
            );
          })}
          <InfiniteScrollObserver onObserver={handleFlatListEndReached} />
          <div style={{ height: 60, backgroundColor: "transparent" }} />
        </div>
      ) : (
        <div className="nothing_search_img">
          <div className="empty_wrap">
            <p className="empty_text">{t("screen.ab.label.emptySearch")}</p>
          </div>
        </div>
      )}

      {/* <Virtuoso
        className="contents"
        data={Abs}
        itemContent={(index, item) => {
          return (
            <AbItem
              index={index}
              ab={item}
              key={item.feedId}
              onVoteSuccess={handleVoteAbSuccess}
              orderedBy={searchCondition.sortType}
            />
          );
        }}
        useWindowScroll
        endReached={handleFlatListEndReached}
        components={{
          Header: () => (
            <>
              {activePopularAbs.length > 0 && (
                <>
                  <div className="ab_horizontal_list_title">
                    <div>
                      <div className="title_background title_1" />
                      <p className="ab_horizontal_list_title_text">
                        {t("screen.ab.label.activePopular")}
                      </p>
                    </div>
                  </div>
                  <HorizontalAbList
                    abs={activePopularAbs}
                    actSectionCode={ActSectionCode.POPULAR_AB}
                    onVoteSuccess={handleVotePopularAbSuccess}
                  />
                </>
              )}
              {expiredPopularAbs.length > 0 && (
                <>
                  <div className="ab_horizontal_list_title">
                    <div>
                      <div className="title_background title_2" />
                      <p className="ab_horizontal_list_title_text">
                        {t("screen.ab.label.expiredPopular")}
                      </p>
                    </div>
                  </div>
                  <HorizontalAbList
                    abs={expiredPopularAbs}
                    actSectionCode={ActSectionCode.EXPIRED_AB}
                  />
                </>
              )}
              {(activePopularAbs.length > 0 ||
                expiredPopularAbs.length > 0) && <div className="bar" />}
              <AbListFilter
                value={searchCondition.sortType}
                onChange={handleFilterChange}
              />
            </>
          ),
          EmptyPlaceholder: () => (
            <div className="nothing_search_img">
              <div className="empty_wrap">
                <p className="empty_text">{t("screen.ab.label.emptySearch")}</p>
              </div>
            </div>
          ),
          Footer: () => (
            <div style={{ height: 60, backgroundColor: "transparent" }} />
          ),
        }}
      /> */}

      {/* <FlatList
        windowSize={3}
        showsVerticalScrollIndicator={false}
        ref={ref}
        style={styles.contents}
        data={Abs}
        extraData={extraDataforAbListView}
        keyExtractor={(item, index) => `${(item as AbDetail).feedId}_${index}`}
        ListHeaderComponent={
          <>
            {activePopularAbs.length > 0 && (
              <HorizontalAbList
                title={t("screen.ab.label.activePopular")}
                abs={activePopularAbs}
                actSectionCode={ActSectionCode.POPULAR_AB}
                onVoteSuccess={handleVotePopularAbSuccess}
              />
            )}
            {expiredPopularAbs.length > 0 && (
              <HorizontalAbList
                title={t("screen.ab.label.expiredPopular")}
                abs={expiredPopularAbs}
                actSectionCode={ActSectionCode.EXPIRED_AB}
              />
            )}
            {(activePopularAbs.length > 0 || expiredPopularAbs.length > 0) && (
              <View style={styles.bar} />
            )}
            <AbListFilter
              value={searchCondition.sortType}
              onChange={handleFilterChange}
            />
          </>
        }
        renderItem={({ item, index }) => {
          return (
            <AbItem
              index={index}
              ab={item}
              key={item.feedId}
              onVoteSuccess={handleVoteAbSuccess}
              orderedBy={searchCondition.sortType}
            />
          );
        }}
        ListEmptyComponent={() => (
          <View style={styles.nothing_search_img}>
            <View style={styles.empty_wrap}>
              <Text style={styles.empty_text}>
                {t("screen.ab.label.emptySearch")}
              </Text>
            </View>
          </View>
        )}
        onEndReachedThreshold={0.5}
        onEndReached={handleFlatListEndReached}
        refreshing={AbLoadingStatus === "REFRESHING"}
        onRefresh={handleRefresh}
        onScroll={(event) =>
          handleTopButtonScroll(
            event,
            () => {
              setShowTopButton(true);
            },
            () => {
              setShowTopButton(false);
            },
            ref
          )
        }
      /> */}
    </div>
  );
});

export default AbListView;
