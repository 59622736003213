/**
 * 네이티브에서 알림 수신시 호출되는 콜백
 * @param params
 */
export function receiveNotification(params: any) {
  // console.log(`receiveNotification : ${JSON.stringify(params)}`);
}
/**
 * 네이티브에서 알림 클릭시 호출되는 콜백
 * @param params
 */
export function clickNotification(params: any) {
  // console.log(`clickNotification : ${JSON.stringify(params)}`);
}
