import { useState } from 'react'
import ProfileHeader from '../../../components/common/ProfileHeader'
import { ReactComponent as ImgDmqaNodata } from '../../../assets/icons/img_dmqa_nodata.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import Modal from 'react-modal'
import { ReactComponent as CloseWhiteIcon } from '../../../assets/icons/close_white.svg'
import SettingStore from '../../../store/SettingStore'
import { useEffectOnlyOnce } from '../../../hooks/UseEffectOnlyOnce'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { getDateAndTimeFormat } from '../../../utils/datetime'
import { QaStatusCode } from '../../../constants/Settings'
import { goBack } from '../../../hooks/navigate/NavigateFunction'
import classes from './QaDetail.module.scss'
import clsx from 'clsx'
import { useLocation, useParams } from 'react-router-dom'

const QaDetail = observer((data: any) => {
  const [isImageModal, setIsImageModal] = useState(false)
  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const {
    getQaList,
    getQaDetail,
    postRemoveQaDetail,
    qaDetail,
    qaAnswerWebviewContent,
    clearQaDetail,
  } = SettingStore
  const [webviewHeight, setWebviewHeight] = useState(0)
  const { t } = useTranslation()
  const { qaId, tabCode } = useParams() || useLocation().state

  useEffectOnlyOnce(
    () => {
      void getQaDetail(Number(qaId))
    },
    () => {
      clearQaDetail()
      // void FastImage.clearMemoryCache()
    }
  )

  // const onWebdivMessage = (e: { nativeEvent: { data: string } }) => {
  //   setWebviewHeight(Number(e.nativeEvent.data))
  // }

  const renderAnswer = () => {
    return qaDetail.qaStatusCode !== QaStatusCode.COMPLETED ? (
      <div className={classes.detail_response_box} aria-label="NoContent">
        <span className={classes.response_title}>
          {qaDetail.qaStatusCodeName}
        </span>
        <ImgDmqaNodata />
        <div className={classes.response_des}>
          <span className={classes.des_txt}>
            {t('screen.setting.message.qaAnsWait1')}
          </span>
          <span className={classes.des_txt}>
            {t('screen.setting.message.qaAnsWait2')}
          </span>
        </div>
      </div>
    ) : (
      <div className={classes.detail_response_box} aria-label="AnswerContent">
        <span className={classes.response_title_finish}>
          {qaDetail.qaStatusCodeName}
        </span>
        <div
          dangerouslySetInnerHTML={{ __html: qaAnswerWebviewContent }}
          className={clsx(classes.response_finish, {
            height: webviewHeight + 20,
          })}
          // onMessage={onWebdivMessage}
          // injectedJavaScript="window.ReactNativeWebdiv.postMessage(window.document.getElementById('context').scrollHeight)"
        />
        <span className={classes.response_date}>
          {getDateAndTimeFormat(qaDetail.answerUpdatedDateTime)}
        </span>
      </div>
    )
  }

  const renderHeader = () => {
    return (
      <div className={classes.header_wrap}>
        {qaDetail.qaStatusCode !== QaStatusCode.COMPLETED ? (
          <ProfileHeader
            title={t('screen.setting.label.qa')}
            buttonName={t('screen.setting.label.qaCancel')}
            onSkip={() => void openConfirmModal()}
          />
        ) : (
          <ProfileHeader
            title={t('screen.setting.label.qa')}
            buttonName={t('screen.setting.label.qaDelete')}
            onSkip={() => void openConfirmModal()}
            beforeBack={() => void reGetQaList()}
            disabled={qaDetail.qaAdminFlag === 'Y' ? true : false}
          />
        )}
      </div>
    )
  }

  const renderModalText = () => {
    return qaDetail.qaStatusCode !== QaStatusCode.COMPLETED ? (
      <div className={classes.modal_body}>
        <span className={classes.contents_text}>
          {t('screen.setting.message.qaModalCancelText1')}
        </span>
        <span className={classes.contents_text}>
          {t('screen.setting.message.qaModalCancelText2')}
        </span>
      </div>
    ) : (
      <div className={classes.modal_body}>
        <span className={classes.contents_text}>
          {t('screen.setting.message.qaModalDeletespan')}
        </span>
      </div>
    )
  }

  const reGetQaList = async () => {
    tabCode && (await getQaList(9999, 0, tabCode))
  }

  const handleQaDelete = (qaId: number) => {
    void postRemoveQaDetail(
      qaId,
      () => {
        void reGetQaList()
        goBack()
      },
      () => {
        // fail
      }
    )
    setIsConfirmModal(false)
  }

  const openConfirmModal = () => {
    setIsConfirmModal(true)
  }

  const getImageUri = () => {
    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL)
    const filePath = qaDetail.uploadImageFile?.filePath
    return `${PUBLIC_BUCKET_URL}${filePath || ''}`
  }

  return (
    <div className={classes.qna_detail}>
      {renderHeader()}
      <div className={classes.detail_box}>
        <div className={classes.detail_contents_box}>
          <button
            disabled={qaDetail.qaAdminFlag === 'Y'}
            className={classes.detail_type_wrap}
            onClick={() => setIsConfirmModal(true)}
            aria-label="Image Popup"
          >
            <span className={classes.detail_type}>
              {qaDetail.qaTypeCodeName}
            </span>
          </button>
          <span className={classes.detail_date}>
            {getDateAndTimeFormat(qaDetail.createdDatetime)}
          </span>
          <span className={classes.detail_contents}>{qaDetail.qaContent}</span>
          {qaDetail.uploadImageFile && (
            <>
              <button onClick={() => setIsImageModal(true)}>
                <div className={classes.img_box}>
                  <div className={classes.img_wrap}>
                    <img
                      className={classes.qna_image_small}
                      src={getImageUri()}
                      alt=""
                    />

                    <span className={classes.img_txt}>
                      {qaDetail.uploadImageFile?.fileName}.
                      {qaDetail.uploadImageFile?.fileExtension}
                    </span>
                  </div>
                  <div className={classes.extra} />
                </div>
              </button>
            </>
          )}
        </div>
        {renderAnswer()}
      </div>
      <Modal
        isOpen={isImageModal}
        className={classes.modal}
        onRequestClose={() => setIsImageModal(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10000,
            maxWidth: 450,
            margin: 'auto',
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'absolute',
            outline: 'none',
            border: 'none',
            padding: '0',
            inset: '0',
          },
        }}
      >
        <div className={classes.alert_modal}>
          <div className={classes.confirm_modal_header}>
            <button
              className={classes.modal_icons}
              onClick={() => setIsImageModal(false)}
            >
              <CloseWhiteIcon />
            </button>
          </div>
          <div className={classes.confirm_modal_body}>
            <img className={classes.qna_image} alt="" src={getImageUri()} />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isConfirmModal}
        className={classes.modal}
        onRequestClose={() => setIsConfirmModal(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10000,
            maxWidth: 450,
            margin: 'auto',
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            position: 'absolute',
            outline: 'none',
            border: 'none',
            padding: '0',
            inset: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
        aria-label="Delete or Cancel"
      >
        <div className={classes.report_modal}>
          <div className={classes.header}>
            <span className={classes.header_text}>
              {t('screen.setting.label.qaModalTitle')}
            </span>
            <button
              className={classes.icons}
              onClick={() => setIsConfirmModal(false)}
            >
              <CloseIcon />
            </button>
          </div>
          {renderModalText()}
          <div className={classes.modal_footer}>
            <button
              className={clsx(classes.button, classes.left_btn)}
              onClick={() => setIsConfirmModal(false)}
            >
              <span
                className={clsx(
                  classes.footer_button_text,
                  classes.left_footer_txt
                )}
              >
                {t('screen.setting.label.qaModalCancel')}
              </span>
            </button>
            {/* <div className={classes.separate} /> */}
            <button
              className={clsx(classes.button, classes.right_btn)}
              onClick={() => handleQaDelete(qaDetail.qaId)}
              aria-label="Right button"
            >
              <span className={classes.footer_button_text}>
                {t('screen.setting.label.qaModalConfirm')}
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
})

export default QaDetail
