import React from "react";
import { action, computed, makeObservable, observable } from "mobx";
import { MISSION_INFO_TYPE, MISSION_RESULT_COUNT, MISSION_TYPE_PATH } from "../constants/mission";
import {
  getMissionDetail,
  getMissionList,
  getMissionWinnerInfo,
  postMissionParticipate,
  putParticipantInfo,
  getMissionPost,
  updateShareCount,
  getStampMissions,
} from "../api/mission/api";
import {
  ApplyInfo,
  Mission,
  MissionParticipateRequest,
  MissionPostResponse,
  MissionShareRequest,
  MissionWinnerInfo,
  ParticipantInfo,
  stampMissionInfo,
} from "../api/mission/model";
import { UserActTypeCode } from "../constants/FeedType.enum";
import { Post } from "../api/feed/model";
// import BadgeStore from "./BadgeStore";
import { BadgeCode } from "../constants/Badge.enum";
import { t } from "i18next";
import CommonResponse from "../api/http";
import dayjs from "dayjs";
import { NotifyModalContext } from "../screens/mission/common/model/NotifyModalContext";
import { getDateMD } from "../utils/datetime";
import { CouponType } from "../constants/coupon";
// import { Format } from "@kichiyaki/react-native-barcode-generator";
// import Barcode from 'react-barcode';
import { ActSectionCode } from "../constants/ActHistory";

class MissionStore {
  constructor() {
    makeObservable(this);
  }

  @observable _loading = true;
  @observable _postLoading = false;
  @observable _loadMore = true;
  @observable _isEndFirstPostingLoad = true;
  @observable _isEndPage = false;
  @observable _selectedTab = MISSION_TYPE_PATH.ONGOING;
  @observable _missionList: Mission[] = [];
  @observable _missionDetail: Mission = { missionId: 0, missionTypeCode: "", canLevelYn: "N", canBadgeYn: "N" };
  @observable _missionWinnerInfo: MissionWinnerInfo = { winYn: "", missionTitle: "" };
  @observable _missionPostData: MissionPostResponse | null = null;
  @observable _missionPostList: Post[] = [];

  @observable _showNotParticipatePopup = false;
  @observable _showCompletePopup = false;
  @observable _showNoticePopup = false;
  @observable _showInfoModal = false;
  @observable _showContentsPopup = false;

  @observable _stopSearchList = false;
  @observable _stampInfo: stampMissionInfo = {
    visitCnt: 0, //방문 기록 카운트
    morePostFlag: false, //포스트 추가작성 여부
    magazineFlag: false, //매거진 좋아요 or 댓글 여부"
    qnaFlag: false, //QnA 작성 or 답변 달기
    abFlag: false, //"A/B 작성 or 투표참여 여부
    followFlag: false, //친구 3명 팔로우 여부"
    postFlag: false, //포스트작성여부
    myHomeFlag: false, //마이홈 꾸미기 여부(자기소개 or 프로필사진 or SNS) 여부
    adminFollowFlag: false, //220 cord&code 게정 팔로우 여부
  };

  @observable _contentClick = false;
  @observable _needContentClick = false;
  @observable _showParticipationLimitPopup = false;
  
  @computed get stampInfo() {
    return this._stampInfo;
  }
  @computed get showNotParticipatePopup() {
    return this._showNotParticipatePopup;
  }

  @computed get showCompletePopup() {
    return this._showCompletePopup;
  }

  @computed get showNoticePopup() {
    return this._showNoticePopup;
  }

  @computed get showInfoModal() {
    return this._showInfoModal;
  }

  @computed get showContentsPopup() {
    return this._showContentsPopup;
  }

  @computed get loading() {
    return this._loading;
  }

  @computed get postLoading() {
    return this._postLoading;
  }

  @computed get selectedTab() {
    return this._selectedTab;
  }

  @computed get missionList() {
    return this._missionList;
  }

  @computed get loadMore() {
    return this._loadMore;
  }

  @computed get isEndFirstPostingLoad() {
    return this._isEndFirstPostingLoad;
  }

  @computed get isEndPage() {
    return this._isEndPage;
  }

  @computed get missionDetail() {
    return this._missionDetail;
  }

  @computed get missionPostData() {
    return this._missionPostData;
  }

  @computed get stopSearchList() {
    return this._stopSearchList;
  }

  @computed get missionWinnerInfo() {
    return this._missionWinnerInfo;
  }

  @computed get missionPostList() {
    return this._missionPostList;
  }

  @action setStampInfo = (stampInfo: stampMissionInfo) => {
    this._stampInfo = stampInfo;
  };
  @action setSelectedTab = (tab: MISSION_TYPE_PATH) => {
    this._selectedTab = tab;
  };

  @action setLoading = (isLoading: boolean) => {
    this._loading = isLoading;
  };

  @action setIsEndFirstPostingLoad = (isEndFirstPostingLoad: boolean) => {
    this._isEndFirstPostingLoad = isEndFirstPostingLoad;
  };

  @action setPostLoading = (isLoading: boolean) => {
    this._postLoading = isLoading;
  };

  @action setMissionList = (missions: Mission[]) => {
    this._missionList = missions;
  };

  @action setLoadMore = (more: boolean) => {
    this._loadMore = more;
  };

  @action setIsEndPage = (isEndPage: boolean) => {
    this._isEndPage = isEndPage;
  };

  @action setMissionDetail = (mission: Mission) => {
    this._missionDetail = mission;
  };

  @action setMissionWinnerInfo = (info: MissionWinnerInfo) => {
    this._missionWinnerInfo = info;
  };

  @action setParticipantInfo = (info: ParticipantInfo) => {
    this._missionDetail.participantInfo = info;
  };

  @action setContentClick = (flag: boolean) => {
    this._contentClick = flag;
  };

  @action setNeedContentClick = (flag: boolean) => {
    this._needContentClick = flag;
  };

  @computed get isContentClickCheckMission() {
    return this._needContentClick && !this._contentClick;
  }

  @action getMissionList = async (missionTypePath: MISSION_TYPE_PATH, pageSize: number, pageIndex: number) => {
    this.setLoading(true);
    this.setSelectedTab(missionTypePath);

    const res = await getMissionList(missionTypePath, pageSize, pageIndex);
    if (res.successOrNot == "Y") {
      const list: Mission[] = res.data.list;
      this.setMissionList(list);

      if (list.length === 0) {
        this.setIsEndPage(true);
      }
    }
    this.setLoading(false);
  };

  @action getMoreMissionList = async (missionTypePath: string, pageSize: number, pageIndex: number) => {
    const res = await getMissionList(missionTypePath, pageSize, pageIndex);

    if (res.successOrNot == "Y") {
      const list: Mission[] = res.data.list;

      this._missionList.push(...list);
      if (list.length > 0) {
        this.setLoadMore(true);

        if (pageSize > list.length) {
          this.setIsEndPage(true);
        }
      } else {
        this.setLoadMore(false);
        this.setIsEndPage(true);
      }
    }
  };

  @action getMissionDetail = async (missionId: number) => {
    const res = await getMissionDetail(missionId);
    if (res.successOrNot == "Y") {
      const mission: Mission = res.data;
      this.setMissionDetail(mission);
      if (res.data.missionTypeCode == "STAMP") {
        await this.getStampMissions(missionId);
      }
    }
    this.setLoading(false);
  };

  @action isPossibleMissionApply = async (missionId: number): Promise<boolean> => {
    const response = await getMissionDetail(missionId);

    if (response.successOrNot == "Y") {
      const mission: Mission = response.data;
      const now = dayjs();

      if (now.isAfter(mission.missionEndDate)) {
        return false;
      }

      if (mission.missionApproveYn == "N") {
        return false;
      }
    } else {
      return false;
    }

    return true;
  };

  @action getMissionDetailForValid = async (missionId: number): Promise<CommonResponse> => {
    return await getMissionDetail(missionId);
  };

  @action postMissionParticipate = async (
    missionId: number,
    missionTypCode: string,
    participantInfo?: ParticipantInfo | null,
    missionTargetId?: number
  ) => {
    const res = await postMissionParticipate(missionId, {
      missionTypeCode: missionTypCode,
      missionTargetId: missionTargetId,
      participantInfo: participantInfo,
    } as MissionParticipateRequest);

    if (res.successOrNot == "Y") {
      this._missionDetail.participationCount = res.data.participationCount;
      if (participantInfo != null) {
        this.setParticipantInfo(participantInfo);
      }
      // await BadgeStore.obtainBadge(BadgeCode.MISSION);
    }
    return res;
  };

  @action putParticipantInfo = async (missionId: number, participantInfo: ParticipantInfo) => {
    const res = await putParticipantInfo(missionId, participantInfo);
    this.setParticipantInfo(participantInfo);
    return res;
  };

  @action getMissionWinnerInfo = async (missionId: number) => {
    const res = await getMissionWinnerInfo(missionId);
    if (res.successOrNot == "Y") {
      const info: MissionWinnerInfo = res.data;
      this.setMissionWinnerInfo(info);
    }
  };

  @action clearMissionList = () => {
    this.setLoading(true);
    this.setLoadMore(true);
    this.setIsEndPage(false);
    this.setSelectedTab(MISSION_TYPE_PATH.ONGOING);
    this.setMissionList([]);
  };

  @action clearMssionDetail = () => {
    this.setMissionDetail({ missionId: 0, missionTypeCode: "", canLevelYn: "N", canBadgeYn: "N" } as Mission);
    this.setMissionPostData(null);
    this._showCompletePopup = false;
    this._showContentsPopup = false;
    this._showInfoModal = false;
    this._showNotParticipatePopup = false;
    this._showNoticePopup = false;
    this._stopSearchList = false;
    this.setContentClick(false);
    this.setNeedContentClick(false);
  };

  @action addAdditionalsWithList = (needToBeAdded: Post[] | undefined) => {
    if (needToBeAdded) {
      for (let postIndex = 0; postIndex < needToBeAdded.length; postIndex++) {
        if (needToBeAdded[postIndex]) {
          needToBeAdded[postIndex].showMore = true;
          needToBeAdded[postIndex].currentCarouIndex = 0;
        }
      }
    }
  };

  @action checkBadge = (): boolean => {
    return this.missionDetail.canBadgeYn == "Y";
  };

  @action checkLevel = (): boolean => {
    return this.missionDetail.canLevelYn == "Y";
  };

  @action hasParticipantInfo = (): boolean => {
    return this.missionDetail.participantInfo != null;
  };

  @action setMissionPostData = (mission: MissionPostResponse | null, cursor: string | null = null) => {
    if (cursor === null) {
      this.setStopSearchList(false);
      this._missionPostData = mission;

      this.addAdditionalsWithList(mission?.feedFindVOList);

      this._missionPostList = mission?.feedFindVOList || [];
    } else {
      this._missionPostList.push(...(mission?.feedFindVOList || []));
    }

    this.setLoadMore(false);
    if (
      mission?.feedFindVOList &&
      (mission?.feedFindVOList.length < MISSION_RESULT_COUNT || mission?.feedFindVOList.length === 0)
    ) {
      this.setStopSearchList(true);
    }
  };

  @action getMissionPost = async (missionId: number, cursor: string | null, viewType?: string) => {
    if (!this.postLoading) {
      this.setPostLoading(true);

      const response = await getMissionPost(missionId, cursor, viewType);
      this.setMissionPostData(response, cursor);

      this.setPostLoading(false);
    }
  };

  @action setShowNotParticipatePopup = (visible: boolean) => {
    this._showContentsPopup = false;
    this._showInfoModal = false;
    this._showCompletePopup = false;
    this._showNoticePopup = false;

    this._showNotParticipatePopup = visible;
  };
  @action setShowCompletePopup = (visible: boolean) => {
    this._showContentsPopup = false;
    this._showInfoModal = false;
    this._showNotParticipatePopup = false;
    this._showNoticePopup = false;

    this._showCompletePopup = visible;
  };
  @action setShowNoticePopup = (visible: boolean) => {
    this._showContentsPopup = false;
    this._showInfoModal = false;
    this._showCompletePopup = false;
    this._showNotParticipatePopup = false;

    this._showNoticePopup = visible;
  };

  @action setShowInfoModal = (visible: boolean) => {
    this._showContentsPopup = false;
    this._showNotParticipatePopup = false;
    this._showCompletePopup = false;
    this._showNoticePopup = false;

    this._showInfoModal = visible;
  };

  @action setShowContentsPopup = (visible: boolean) => {
    this._showNotParticipatePopup = false;
    this._showInfoModal = false;
    this._showCompletePopup = false;
    this._showNoticePopup = false;

    this._showContentsPopup = visible;
  };

  @action updateParticipantInfo = (info: ParticipantInfo) => {
    this._missionDetail.participantInfo = info;
  };

  @action setStopSearchList = (stop: boolean) => {
    this._stopSearchList = stop;
  };

  @action clearMissionWinnerInfo = () => {
    this.setMissionWinnerInfo({ missionTitle: "", winYn: "" });
  };

  @action findMatchedPostIndex = (feedId: number) => {
    if (this._missionPostList) {
      return this._missionPostList.findIndex((x) => x.feedId === feedId);
    }
    return -1;
  };

  @action setPost = (post: Post, index: number) => {
    if (this._missionPostList) {
      this._missionPostList[index] = post;
    }
  };

  @action setPostWithFeedId = (newPost: Post, feedId: number) => {
    if (this._missionPostList) {
      const matchedIndex = this.findMatchedPostIndex(feedId);

      if (matchedIndex != -1) {
        this._missionPostList[matchedIndex] = newPost;
      }
    }
  };

  @action deletePostWithFeedId = (feedId: number) => {
    if (this._missionPostList) {
      const matchedIndex = this.findMatchedPostIndex(feedId);

      if (matchedIndex != -1) {
        this._missionPostList.splice(matchedIndex, 1);
      }
    }
  };

  @action updatePostStoreList = (action: UserActTypeCode, feedId: number) => {
    const matchedIndex = this.findMatchedPostIndex(feedId);

    if (matchedIndex == -1) {
      return;
    }

    switch (action) {
      case UserActTypeCode.CANCEL_LIKE:
        if (this._missionPostList[matchedIndex]) {
          const formerCount: number = this._missionPostList[matchedIndex].likeCount;
          const updatedPost = {
            ...this._missionPostList[matchedIndex],
            likeYn: "N",
            likeCount: formerCount - 1,
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SAVE_LIKE:
        if (this._missionPostList[matchedIndex]) {
          const formerCount: number = this._missionPostList[matchedIndex].likeCount;
          const updatedPost = {
            ...this._missionPostList[matchedIndex],
            likeYn: "Y",
            likeCount: formerCount + 1,
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.CANCEL_BOOKMARK:
        if (this._missionPostList[matchedIndex]) {
          const updatedPost = {
            ...this._missionPostList[matchedIndex],
            bookmarkYn: "N",
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SAVE_BOOKMARK:
        if (this._missionPostList[matchedIndex]) {
          const updatedPost = {
            ...this._missionPostList[matchedIndex],
            bookmarkYn: "Y",
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.SHOW_MORE:
        if (this._missionPostList[matchedIndex]) {
          if (this._missionPostList[matchedIndex].showMore === true) {
            const updatedPost = {
              ...this._missionPostList[matchedIndex],
              showMore: false,
            };
            this.setPost(updatedPost, matchedIndex);
          } else {
            const updatedPost = {
              ...this._missionPostList[matchedIndex],
              showMore: true,
            };
            this.setPost(updatedPost, matchedIndex);
          }
        }
        break;
      case UserActTypeCode.OPEN_MODAL:
        if (this._missionPostList[matchedIndex]) {
          const updatedPost = {
            ...this._missionPostList[matchedIndex],
            showDeletePopup: true,
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.CLOSE_MODAL:
        if (this._missionPostList[matchedIndex]) {
          const updatedPost = {
            ...this._missionPostList[matchedIndex],
            showDeletePopup: false,
          };
          this.setPost(updatedPost, matchedIndex);
        }
        break;
      case UserActTypeCode.POP:
        if (this._missionPostList[matchedIndex]) {
          this._missionPostList.splice(matchedIndex, 1);
        }
        break;
    }
  };

  @action updateClickCount = (missionShareRequest: MissionShareRequest) => {
    void updateShareCount(missionShareRequest);
  };

  getParsedParticipantInfo = (applyInfo: ApplyInfo | undefined): ParticipantInfo => {
    if (!applyInfo) {
      return {};
    }

    return {
      participantName: applyInfo.name,
      participantPhoneNumber: applyInfo.mobile,
      participantAddress: applyInfo.address,
      participantAddressDetail: applyInfo.detailAddress,
      infoAgreeYn: applyInfo.infoCollectAgreed ? "Y" : "N",
      provideToThirdAgreeYn: applyInfo.provideToThirdAgreed ? "Y" : "N",
    } as ParticipantInfo;
  };

  getNoticeModalTextInfo = (type: string, parentModalOpenOrClose?: string): NotifyModalContext => {
    const noticeContext: NotifyModalContext = {
      isShow: true,
      title: "",
      contents: "",
      needParentModalCloseByCallback: false,
      needParentModalOpenByCallback: false,
    };

    if (parentModalOpenOrClose && parentModalOpenOrClose == "open") {
      noticeContext.needParentModalOpenByCallback = true;
    }

    if (parentModalOpenOrClose && parentModalOpenOrClose == "close") {
      noticeContext.needParentModalCloseByCallback = true;
    }

    //응모 완료
    if (type == "complete") {
      noticeContext.title = "";
      noticeContext.contents = t("screen.mission.message.complete", {
        date: getDateMD(this.missionDetail.lotteryDate || ""),
      });
      return noticeContext;
    }
    //수정 완료
    if (type == "infoModified") {
      noticeContext.contents = t("screen.mission.message.infoModified");
      return noticeContext;
    }
    //등록 실패
    if (type == "error_register") {
      noticeContext.title = t("screen.mission.label.recommender");
      noticeContext.contents = t("screen.mission.message.error_register");
    }
    //이미 응모한 경우
    if (type == "error_dup") {
      noticeContext.title = "";
      noticeContext.contents = t("screen.mission.message.error_dup");
    }
    if (type == "error_dup_phone") {
      noticeContext.title = "";
      noticeContext.contents = t("screen.mission.message.error_dup_phone");
    }
    if (type == "modifyPrivacyCheck") {
      noticeContext.title = t("screen.notificationModal.label.alert");
      noticeContext.contents = t("screen.mission.message.modifyPrivacyCheck");
    }
    if (type == "endMission") {
      noticeContext.title = t("screen.notificationModal.label.alert");
      noticeContext.contents = t("screen.mission.message.endMission");
    }
    return noticeContext;
  };

  getApplyInfo = () => {
    const applyInfo = {} as ApplyInfo;

    if (this.missionDetail.participantInfo != null) {
      applyInfo.name = this.missionDetail.participantInfo.participantName;
      applyInfo.mobile = this.missionDetail.participantInfo.participantPhoneNumber;
      applyInfo.address = this.missionDetail.participantInfo.participantAddress;
      applyInfo.detailAddress = this.missionDetail.participantInfo.participantAddressDetail;
      applyInfo.infoCollectAgreed = this.missionDetail.participantInfo.infoAgreeYn == "Y";
      applyInfo.provideToThirdAgreed = this.missionDetail.participantInfo.provideToThirdAgreeYn == "Y";
    }

    return applyInfo;
  };

  @action getStampMissions = async (missionId: number) => {
    this.setLoading(true);
    const res = await getStampMissions(missionId);
    if (res.successOrNot == "Y") {
      const stampInfo: stampMissionInfo =
        res.data !== null
          ? res.data
          : {
              visitCnt: 0, //방문 기록 카운트
              morePostFlag: false, //포스트 추가작성 여부
              magazineFlag: false, //매거진 좋아요 or 댓글 여부"
              qnaFlag: false, //QnA 작성 or 답변 달기
              abFlag: false, //"A/B 작성 or 투표참여 여부
              followFlag: false, //친구 3명 팔로우 여부"
              postFlag: false, //포스트작성여부
              myHomeFlag: false, //마이홈 꾸미기 여부(자기소개 or 프로필사진 or SNS) 여부
              adminFollowFlag: false, //220 cord&code 게정 팔로우 여부
            };
      this.setStampInfo(stampInfo);
    }
    this.setLoading(false);
  };

  @computed get expireDateRange() {
    if (this.missionWinnerInfo.couponStartDate && this.missionWinnerInfo.couponEndDate) {
      const startDate = String(dayjs(this.missionWinnerInfo.couponStartDate).format("YYYY-MM-DD"));
      const endDate = String(dayjs(this.missionWinnerInfo.couponEndDate).format("YYYY-MM-DD"));
      return startDate.concat("~", endDate);
    } else {
      return "";
    }
  }

  @computed get isValidCoupon() {
    const now = dayjs();
    return now.isBefore(this.missionWinnerInfo.couponEndDate);
  }

  @computed get couponProvider() {
    return this.missionWinnerInfo.couponProvider ?? "";
  }

  @computed get prizeName() {
    return this.missionWinnerInfo.prizeName ?? "";
  }

  @computed get isBarcodePrize() {
    return this.missionWinnerInfo.couponType === CouponType.MANUAL_BARCODE;
  }

  @computed get isNoneParticipantInfoType() {
    return this.missionDetail.participantInfoTypeList && this.missionDetail.participantInfoTypeList !== null
      ? this.missionDetail.participantInfoTypeList.findIndex((x) => x === MISSION_INFO_TYPE.NONE) > -1
      : this.missionDetail.participantInfoType == MISSION_INFO_TYPE.NONE;
  }

  @computed get needShowPrivateInfoBtn() {
    const now = dayjs();
    const canModifyDate = dayjs(this.missionDetail.lotteryDate).add(7, "days");

    return now.isBefore(canModifyDate) && !this.isNoneParticipantInfoType;
  }

  @computed get barcodeValue() {
    if (this.missionWinnerInfo.couponCode) {
      return this.missionWinnerInfo.couponCode;
    } else {
      return "00000000";
    }
  }

  @computed get barcodeText() {
    if (this.missionWinnerInfo.couponCode && this.missionWinnerInfo.couponCode.length > 4) {
      return this.missionWinnerInfo.couponCode?.replace(/(.{4})/g, "$1 ");
    } else {
      return "쿠폰 번호가 없습니다.";
    }
  }

  // @computed get couponBarcodeFormat(): Format {
  //   return (this.missionWinnerInfo.couponBarcodeFormat as Format) ?? ("CODE128" as Format);
  // }

  @computed get isNewWinnerView() {
    // 낙첨 화면은 신규화면으로 이동
    if (!this.missionWinnerInfo.winYn || this.missionWinnerInfo.winYn === "N") {
      return true;
    }

    // 쿠폰아이디가 존재하면 신규화면으로 이동
    return !!this.missionWinnerInfo.couponId;
  }

  @computed get displayType() {
    if (this.missionDetail.participationContentDisplayYn == "Y") {
      return "ALL";
    } else {
      return "MY";
    }
  }

  missionActSectionCode = () => {
    let actSectionCode;
    if (this.selectedTab === MISSION_TYPE_PATH.ONGOING) {
      actSectionCode = ActSectionCode.MISSION_IN_PROGRESS;
    }
    if (this.selectedTab === MISSION_TYPE_PATH.END) {
      actSectionCode = ActSectionCode.MISSION_END;
    }
    if (this.selectedTab === MISSION_TYPE_PATH.PARTICIPATE) {
      actSectionCode = ActSectionCode.MISSION_PARTICIPATED;
    }
    return actSectionCode;
  };

  @computed get showParticipationLimitPopup() {
    return this._showParticipationLimitPopup;
  }

  @action setShowParticipationLimitPopup = (x: boolean) => {
    this._showParticipationLimitPopup = x;
  };

  @action participationLimitCheck = () => {
    const { missionTargetMember, missionTargetMemberYn, missionExposureType } = this.missionDetail;
    let result = false; // true 응모 제한

    if (missionTargetMemberYn === "N" || missionTargetMemberYn == null) {
      result = false;
    } else if (missionTargetMemberYn === "Y" && missionTargetMember) {
      result = false;
    } else {
      result = true;
    }

    this.setShowParticipationLimitPopup(result);
    return result;
  };
}

export default new MissionStore();