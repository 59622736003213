import { ReactComponent as TempratureOn } from '../../assets/filter/adjust_temperature_on_100x100.svg'
import { ReactComponent as SharpnessOn } from '../../assets/filter/adjust_sharpness_on_100x100.svg'
import { ReactComponent as SaturateOn } from '../../assets/filter/adjust_saturate_on_100x100.svg'
import { ReactComponent as ContrastOn } from '../../assets/filter/adjust_contrast_on_100x100.svg'
import { ReactComponent as BrightnessOn } from '../../assets/filter/adjust_brightness_on_100x100.svg'
import { ReactComponent as TempratureOff } from '../../assets/filter/adjust_temperature_off_100x100.svg'
import { ReactComponent as SharpnessOff } from '../../assets/filter/adjust_sharpness_off_100x100.svg'
import { ReactComponent as SaturateOff } from '../../assets/filter/adjust_saturate_off_100x100.svg'
import { ReactComponent as ContrastOff } from '../../assets/filter/adjust_contrast_off_100x100.svg'
import { ReactComponent as BrightnessOff } from '../../assets/filter/adjust_brightness_off_100x100.svg'
import { Filter } from 'konva/lib/Node'
import Konva from 'konva'
import { Image } from 'konva/lib/shapes/Image'
import { CustomFilters } from './customfilters'
import { t } from 'i18next'

export type FilterItem = {
  readonly title: string
  readonly filter: Filter
}

export type AdjustItem = {
  readonly title: string
  readonly filter?: Filter
  readonly valueFunction: (image: Konva.Image, value: number) => void
  readonly minmax: { min: number; max: number; step: number }
  readonly initialValue: number
  currentValue: number
  readonly iconOnComponent: JSX.Element
  readonly iconOffComponent: JSX.Element
}

export const Filters: FilterItem[] = [
  // Konva Filters
  {
    title: 'Origin',
    filter: (imageData) => {},
  },
  {
    title: 'GrayScale',
    filter: Konva.Filters.Grayscale,
  },
  {
    title: 'Invert',
    filter: Konva.Filters.Invert,
  },
  {
    title: 'Posterize',
    filter: Konva.Filters.Posterize,
  },
  {
    title: 'Sepia',
    filter: Konva.Filters.Sepia,
  },
  {
    title: 'Solarize',
    filter: Konva.Filters.Solarize,
  },
  // Custom Filters (기존 moga-fe 에 있던 것들)
  {
    title: 'Maven',
    filter: CustomFilters.Maven,
  },
  {
    title: 'Mayfair',
    filter: CustomFilters.MayFair,
  },
  {
    title: 'Moon',
    filter: CustomFilters.Moon,
  },
  {
    title: 'Nashville',
    filter: CustomFilters.Nashville,
  },
  {
    title: 'Perpetua',
    filter: CustomFilters.Perpetua,
  },
  {
    title: 'Reyes',
    filter: CustomFilters.Reyes,
  },
  {
    title: 'Rise',
    filter: CustomFilters.Rise,
  },
  {
    title: 'Slumber',
    filter: CustomFilters.Slumber,
  },
  {
    title: 'Stinson',
    filter: CustomFilters.Stinson,
  },
  {
    title: 'Brooklyn',
    filter: CustomFilters.Brooklyn,
  },
  {
    title: 'Earlybird',
    filter: CustomFilters.Earlybird,
  },
  {
    title: 'Clarendon',
    filter: CustomFilters.Clarendon,
  },
  {
    title: 'Gingham',
    filter: CustomFilters.Gingham,
  },
  {
    title: 'Hudson',
    filter: CustomFilters.Hudson,
  },
  {
    title: 'Inkwell',
    filter: CustomFilters.Inkwell,
  },
  {
    title: 'Kelvin',
    filter: CustomFilters.Kelvin,
  },
  {
    title: 'Lark',
    filter: CustomFilters.Lark,
  },
  {
    title: 'Lofi',
    filter: CustomFilters.Lofi,
  },
  {
    title: 'Toaster',
    filter: CustomFilters.Toaster,
  },
  {
    title: 'Valencia',
    filter: CustomFilters.Valencia,
  },
  {
    title: 'Walden',
    filter: CustomFilters.Walden,
  },
  {
    title: 'Willow',
    filter: CustomFilters.Willow,
  },
  {
    title: 'Xpro2',
    filter: CustomFilters.XPro2,
  },
  {
    title: 'Aden',
    filter: CustomFilters.Aden,
  },
  {
    title: '1977',
    filter: CustomFilters._1977,
  },
  {
    title: 'Brannan',
    filter: CustomFilters.Brannan,
  },
]

export const Adjusts: AdjustItem[] = [
  {
    title: t('screen.imagefilter.brightness'),
    filter: Konva.Filters.Brighten,
    valueFunction: (image, value) => image.brightness(value),
    minmax: {
      min: -1,
      max: 1,
      step: 0.05,
    },
    initialValue: 0,
    currentValue: 0,
    iconOnComponent: <BrightnessOn />,
    iconOffComponent: <BrightnessOff />,
  },
  {
    title: t('screen.imagefilter.contrast'),
    filter: Konva.Filters.Contrast,
    valueFunction: (image, value) => image.contrast(value),
    minmax: {
      min: -100,
      max: 100,
      step: 1,
    },
    initialValue: 0,
    currentValue: 0,
    iconOnComponent: <ContrastOn />,
    iconOffComponent: <ContrastOff />,
  },
  {
    title: t('screen.imagefilter.temperature'),
    filter: Konva.Filters.HSL,
    valueFunction: (image: Image, value: number) => {
      image.hue(value)
    },
    minmax: {
      min: -100,
      max: 100,
      step: 1,
    },
    initialValue: 0,
    currentValue: 0,
    iconOnComponent: <TempratureOn />,
    iconOffComponent: <TempratureOff />,
  },
  {
    title: t('screen.imagefilter.saturation'),
    filter: Konva.Filters.HSL,
    valueFunction: (image: Image, value: number) => image.saturation(value), // TODO: 왜 0이어도 이미지가 바뀌지?
    minmax: {
      min: -2,
      max: 10,
      step: 0.5,
    },
    initialValue: 0,
    currentValue: 0,
    iconOnComponent: <SaturateOn />,
    iconOffComponent: <SaturateOff />,
  },
]
