import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MainHeader from "../../components/common/MainHeader";
import MainTabNavigator from "../../components/common/MainTabNavigator";
import { observer } from "mobx-react";
import "./MainLayout.scss";
import MainStore from "../../store/MainStore";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PreRenderMainStore from "../../store/main/PreRenderMainStore";
import { getDateStr, getDdayFromToday, isPast } from "../../utils/datetime";
import {
  MainItem,
  PopularQna,
  PopularRanking,
  PopularTag,
  Post,
} from "../../api/feed/model";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";
import Rating from "../rating/Rating";
import MainBanner from "./presenter/MainBanner";
// import MainMallNotice from "./presenter/MainMallNotice";
import MainPopupBanner from "./presenter/MainPopupBanner";
import MainProfile from "./presenter/MainProfile";
// import MainTextBanner from "./presenter/MainTextBanner";
import MainSectionItem from "./presenter/MainSectionItem";
import AuthStore from "../../store/AuthStore";
// import MarketingAgreeModal from "../../components/modals/MarketingAgreeModal";
import { getAlarmDefaultSetting } from "../../api/setting/alarm/api";
import { AllowTypeCode } from "../../constants/CommonCode.enum";
// import { sendNonIdentifyInfo, updateMember } from "../../api/member/api";
import {
  NOTICE_BANNER,
  POPUP_BANNER,
  PUSH_ALLOW_YN,
  MAIN_MALL_NOTICE,
} from "../../constants/Storage";
import { AlarmSettingList } from "../../api/setting/alarm/model";
import { getSessionUserFromStorage } from "../../service/Session.service";
// import { BadgeCode, IsMember, SearchType } from "../../constants/Badge.enum";
import { BannerTypeCodes } from "../../constants/Banner.enum";
import dayjs from "dayjs";
// import BadgeStore from "../../store/BadgeStore";
// import { MainMallNotice, MainPopupBanner, MainTextBanner } from "./presenter";
import { handleTopButtonScroll } from "../../utils/common";
// import NotificationStore from "../../store/NotificationStore";
import RecommenderStore from "../../store/RecommenderStore";
// import { useTracker } from "../../hooks/tracker";
import PopularPost from "./popularPost/PopularPost";
import { MainFeedTypeCode } from "../../constants/FeedType.enum";
// import {
//   MainListVisiblePost,
//   VideoProvider,
// } from "../../components/common/VideoHandlerContext";
import { getTempMemberFromStorage } from "../../service/Preference.service";
import { getTempMember, updateTempMember } from "../../api/tempMember/api";
import { TempMember } from "../../api/tempMember/model";
// import { getDevicePushToken } from "../../native/deviceToken/native";
import { sendActHistory } from "../../api/data/api";
import { ActHistoryRequest } from "../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../constants/ActHistory";
// import VoltModal from "./presenter/VoltModal";
import { useEffectOnlyOnce } from "../../hooks/UseEffectOnlyOnce";
import { track } from "../../hooks/tracker/TrackFunction";
// import RemoteConfigStore from "../../store/RemoteConfigStore";
// import { cache } from "../../utils/cache";
import { useLocation, useNavigate } from "react-router-dom";
import { goTo } from "../../hooks/navigate/NavigateFunction";

// const { RNCommon } = NativeModules;

export interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout = observer(({ children }: MainLayoutProps) => {
  const { pathname } = useLocation();
  // const { initialNotificaiton } = NotificationStore;

  const [isModalVisible, setModalVisible] = useState(false); // 푸시 동의 팝업 노출 제어
  const [showMemberJoinVoltSaveModal, setShowMemberJoinVoltSaveModal] =
    useState(false);
  const [showMainMallNotice, setShowMainMallNotice] = useState(false);

  // const [tracker] = useState(useTracker());

  const navigateNotificationScreen = () => {
    goTo("/NotificationScreen");
  };

  const navigateSearchScreen = () => {
    goTo("/Search");
  };

  useEffectOnlyOnce(() => {
    const tempPathname =
      pathname.toLowerCase() === "/" ? "/main" : pathname.toLowerCase();

    let scrollValue = 0;

    const tempMainTabScroll = MainStore.mainTabScroll;

    if (tempMainTabScroll.has(tempPathname)) {
      scrollValue = tempMainTabScroll.get(tempPathname) || 0;
    }

    const countdown = setInterval(() => {
      window.scrollTo(0, scrollValue);

      clearInterval(countdown);
    }, 300);
  });

  return (
    <div id="MainLayout">
      <MainHeader
        onAlarm={navigateNotificationScreen}
        onSearch={navigateSearchScreen}
      />
      <div className="main_body" style={{ paddingBottom: 20 }}>
        <div className="main_body_item">{children}</div>
      </div>
      <MainTabNavigator />
    </div>
  );
});

export default MainLayout;
