import { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { CategoryInfo } from '../../../api/mallCategory/model'
import { Virtuoso } from 'react-virtuoso'
import classes from './MallMainCategoryBestTab.module.scss'
import clsx from 'clsx'
import useDragScrollWithMouse from '../../../hooks/useDragScrollWithMouse'

const MallMainCategoryBestTab = observer(
  ({
    renderData,
    currentTab,
    onChange,
  }: {
    renderData: CategoryInfo[]
    currentTab?: CategoryInfo
    onChange: (item: CategoryInfo, index: number) => void
  }) => {
    const listTabsRef = useRef<HTMLDivElement>(null)
    const isDragging = useDragScrollWithMouse(listTabsRef)
    const [viewData, setViewData] = useState<CategoryInfo[]>([])

    useEffect(() => {
      const temp = renderData.filter((x) => {
        return (
          x.displayCategoryName.indexOf('쿠폰') === -1 &&
          x.displayCategoryName.indexOf('렌탈') === -1
        )
      })
      setViewData(temp)
    }, [renderData])

    const renderItem = (item: CategoryInfo, index: number) => {
      return (
        <div key={item.displayCategoryId}>
          <div
            key={index}
            className={
              currentTab &&
              item.displayCategoryId === currentTab.displayCategoryId
                ? clsx(classes.btn_item_wrap, classes.btn_active)
                : classes.btn_item_wrap
            }
            onClick={() => !isDragging && onChange(item, index)}
          >
            <span
              className={
                currentTab &&
                item.displayCategoryId === currentTab.displayCategoryId
                  ? clsx(classes.btn_item_text, classes.btn_text_active)
                  : classes.btn_item_text
              }
            >
              {item.displayCategoryName}
            </span>
          </div>
        </div>
      );
    };

    return viewData && viewData.length > 0 ? (
      <div ref={listTabsRef} className={classes.bx_flat_wrap} aria-label={"category best"}>
        {viewData.map((item, index) => renderItem(item, index))}
        {/* <Virtuoso
          // horizontalDirection
          className={classes.flat_box}
          data={viewData}
          computeItemKey={(index, item) => `MallMainCategoryBestTab_${index}`}
          itemContent={(index, item) => (
            <div style={index === 0 ? { marginLeft: 16 } : { marginLeft: 0 }}>
              <div
                key={index}
                className={
                  currentTab &&
                  item.displayCategoryId === currentTab.displayCategoryId
                    ? clsx(classes.btn_item_wrap, classes.btn_active)
                    : classes.btn_item_wrap
                }
                onClick={() => onChange(item, index)}
              >
                <text
                  className={
                    currentTab &&
                    item.displayCategoryId === currentTab.displayCategoryId
                      ? clsx(classes.btn_item_text, classes.btn_text_active)
                      : classes.btn_item_text
                  }
                >
                  {item.displayCategoryName}
                </text>
              </div>
            </div>
          )}
        /> */}
      </div>
    ) : (
      <></>
    );
  }
)
export default MallMainCategoryBestTab
