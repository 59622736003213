import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import classes from './NotificationModal.module.scss'
import clsx from 'clsx'
import { ReactNode } from 'react'

export interface BasicModalProps {
  isVisible: boolean
  onRequestClose?: (() => void) | undefined
  modalCloseCallback?: (() => void) | undefined
  ref?: any | undefined
  modalWidth?: number
  children?: ReactNode
}

const BasicModal = ({
  isVisible, // 팝업 표시 여부
  onRequestClose, // 팝업 닫힌 후 본 페이지에서 해야할 동작
  modalCloseCallback, // 모달이 화면에서 사라질 경우 호출되는 콜백 메소드.
  ref,
  modalWidth,
  children,
}: BasicModalProps) => {
  const { t } = useTranslation()

  const dismiss = () => {
    if (onRequestClose !== undefined) onRequestClose()
  }

  const onModalHideCallback = () => {
    document.body.classList.remove('modal-open')
    if (modalCloseCallback !== undefined) modalCloseCallback()
  }

  const onAfterOpen = () => {
    document.body.classList.add('modal-open')
  }

  return (
    <Modal
      ref={ref}
      isOpen={isVisible}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          maxWidth: 450,
          margin: 'auto',
          zIndex: 100000,
        },
        content: {
          padding: 0,
          border: 'none',
          borderRadius: '12px',
          top: '50%',
          left: '50%',
          backgroundColor: 'unset',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
        },
      }}
      onAfterOpen={onAfterOpen}
      onRequestClose={dismiss}
      onAfterClose={onModalHideCallback}
    >
      <div
        className={classes.notification_modal}
        style={modalWidth ? { width: modalWidth } : undefined}
      >
        {children && <>{children}</>}
      </div>
    </Modal>
  )
}

export default BasicModal
