export enum LikeTypeCode {
  POST = "POST",
  MISSION = "MISSION",
  MAGAZINE = "MAGAZINE",
  QNA = "QNA",
  AB = "AB",
  COMMENT = "COMMENT",
  PRODUCT = "PRODUCT",
  ARTICLE = "ARTICLE",
}
