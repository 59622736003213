import React, { useCallback, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Color } from '../../assets/styles/variable'
import { MagazineDetail as MagazineDetailModel } from '../../api/magazine/model'
import { getMagazineByFeedId } from '../../api/magazine/api'
import CommonStore from '../../store/CommonStore'
import { t } from 'i18next'
import { useEffectOnlyOnce } from '../../hooks/UseEffectOnlyOnce'
import { setPostViewCountUp } from '../../api/post/api'
import { CommentTypeCode } from '../../constants/Comment'
import { useLocation, useParams } from 'react-router-dom'
import MagazineDetail from './presenter/MagazineDetail'
import { goTo } from '../../hooks/navigate/NavigateFunction'

const MagazineDetailScreen = observer(() => {
  const location = useLocation()
  const state = location.state
  const params = useParams()
  const feedId = params.feedId || state.feedId
  const isShowComment: boolean = (state?.isShowComment || false) as boolean
  // const isScreenFocused = useIsFocused()
  const [magazineDetail, setMagazineDetail] = useState<MagazineDetailModel>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffectOnlyOnce(
    () => {
      void setPostViewCountUp(feedId ?? '0')
    },
    () => {
      // void FastImage.clearMemoryCache()
    }
  )

  const moveToComment = useCallback(() => {
    goTo(`${CommentTypeCode.MAGAZINE}/${feedId}/comment`)
  }, [feedId])

  useEffect(() => {
    if (isShowComment) {
      moveToComment()
    }
  }, [isShowComment, moveToComment])

  useEffect(() => {
    const initMagazineDetail = async () => {
      setIsLoading(true)
      void getMagazineByFeedId(feedId)
        .then((result) => {
          setMagazineDetail(result)
        })
        .catch(() => {
          CommonStore.setToastOption({
            show: true,
            autoHide: true,
            message1: t('common.message.unknownException'),
            durationTime: 2000,
          })
        })
        .finally(() => setIsLoading(false))
    }
    window.scrollTo(0, 0)
    void initMagazineDetail()
  }, [feedId])

  return (
    <div
      style={{ flex: 1, backgroundColor: Color.WHITE }}
      // accessible={true}
      aria-label={'Magazine Detail Screen'}
    >
      <MagazineDetail
        magazineDetail={magazineDetail}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    </div>
  )
})

export default MagazineDetailScreen
