import { observer } from "mobx-react";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import { FeedItem } from "../../../api/brand/model";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { ReactComponent as BadgeImg } from "../../../assets/icons/badge_img.svg";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";

const BrandStoryPost = observer(({ renderData, listKey, onEndReached }: { renderData: FeedItem[]; listKey: string; onEndReached: any }) => {

  const { t } = useTranslation();

  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";

  const moveToProductDetail = (item: FeedItem) => {
    goTo(`/posts/feed/${item.feedId}`, {
      state: {
        postIndex: 0,
        listIndex: 0,
      }
    });
  };

  const data = Array.from({ length: Math.ceil(renderData.length / 2) }, (_, i) =>
    renderData.slice(i * 2, i * 2 + 2)
  );

  const renderItem = (index: number, item: FeedItem) => {
    return (
      <div>
        <div style={styles.row}>
          <TouchableWithAuthCheck
            style={{ ...styles.relation_product_item, position: 'relative' }}
            onPress={() => moveToProductDetail(item)}
          >
            <FastImageWithFallback
              source={{
                uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
              }}
              style={styles.relation_product_image}
              fallbackImageUri={FALLBACK_IMAGE_URL}
            />
            {item.imageCount + item.videoCount > 1 && <BadgeImg style={styles.badge_img} />}

            <p style={{
              ...styles.relation_product_item_text1,
              ...{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '2', lineClamp: '2', WebkitBoxOrient: 'vertical' }
            }}
            >
              {item.feedTitle}
            </p>
            <div style={styles.relation_product_info}>
              <p style={{
                ...styles.relation_product_item_text2,
                ...{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '1', lineClamp: '1', WebkitBoxOrient: 'vertical' }
              }}
              >
                {item.nickname}
              </p>

              {item.subscribeYn === "Y" && (
                <p style={styles.flag_participating}>{t("screen.brandCommunity.label.subscribing")}</p>
              )}
            </div>
          </TouchableWithAuthCheck>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.tab_content}>
      {data?.length > 0 && (
        <Virtuoso
          style={styles.scroll_wrap}
          useWindowScroll
          data={data}
          itemContent={(index, arrItem: any) => {
            return (
              <div key={index} style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <div style={{ flexGrow: 1, paddingLeft: 16, paddingRight: 4, flex: 1 }}>
                  {renderItem(index * 2, arrItem[0])}
                </div>
                <div style={{ flexGrow: 1, paddingRight: 16, paddingLeft: 4, flex: 1 }}>
                  {arrItem.length > 1 && renderItem(index * 2 + 1, arrItem[1])}
                </div>
              </div>
            );
          }}
          endReached={() => {
            if (renderData && renderData.length == 0) return;
            void onEndReached();
          }}
        />)}
    </div>
  );
});

export default BrandStoryPost;

export const styles: Styles = {
  flag_participating: {
    fontSize: 10,
    color: "#BE69C2",
    backgroundColor: "#F7F4FA",
    borderRadius: 2,
    paddingVertical: 2,
    paddingHorizontal: 8,
    letterSpacing: -0.4,
    marginLeft: 3,
    overflow: "hidden",
    padding: '0px 5px'
  },
  search: {
    ...FlexBoxs.flex_1,
  },
  tab_content: {
    ...Colors.background_fff,
  },
  nick_name: {
    height: 64,
    paddingLeft: 24,
    ...Colors.background_fafafa,
    ...Texts.button_text_10,
    borderRadius: 6,
  },
  search_icon: {
    ...FlexBoxs.flex_1,
    ...Positions.absolute,
    right: 9.23,
  },
  nick_name_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_top_20,
  },
  title: {
    ...Texts.contents_text_34,
    ...Spacings.margin_bottom_20,
  },
  key_word_wrap: {
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_top_32,
  },

  recommend_tag_wrap: {
    ...Spacings.margin_bottom_40,
  },
  recommend_tag_list_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
  },
  recommend_tag_item: {
    ...Colors.background_f7f4fa,
    ...Spacings.padding_left_right_16,
    ...Spacings.border_radius_300,
    ...Spacings.margin_right_6,
    ...Spacings.margin_bottom_8,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.padding_top_bottom_10,
  },
  recommend_tag_item_text: {
    ...Texts.contents_text_41,
    ...Colors.font_a760b1,
  },
  ranking_keyword_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_bottom_20,
  },
  ranking: {
    ...Texts.contents_text_38,
    ...Colors.font_222,
    fontWeight: "500",
  },
  ranking_date: {
    ...Texts.btn_text_1,
    ...Colors.font_999,
    fontWeight: "500",
  },
  ranking_box: {
    ...Spacings.padding_left_right_20,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  first_ranking: {
    ...FlexBoxs.flex_1,
  },
  second_ranking: {
    ...FlexBoxs.flex_1,
  },
  rank: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.margin_bottom_26,
  },
  rank_txt: {
    ...Texts.btn_text_1,
    ...Colors.font_222,
  },
  rank_txt_wrap: {
    ...Spacings.padding_left_10,
  },
  rank_number: {
    ...Texts.btn_text_1,
    ...Colors.font_a760b1,
    fontWeight: "bold",
  },
  close_purple: {
    ...Positions.absolute,
    right: '17.9%',
  },
  search_result_wrap: {
    ...Spacings.margin_top_10,
  },
  search_tab: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: 'solid',
    borderBottomColor: "#eee",
    height: 56,
  },
  search_txt_wrap: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  search_txt_wrap_active: {
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: 'solid',
    borderBottomColor: "#222",
  },
  search_txt: {
    ...Texts.contents_text_38,
    ...Colors.font_999,
  },
  search_txt_active: {
    ...Texts.contents_text_38,
    ...Colors.font_222,
    fontWeight: "500",
  },
  search_result_info: {
    ...Spacings.margin_top_16,
    ...Spacings.margin_bottom_25,
    ...Spacings.padding_left_right_20,
    ...FlexBoxs.flex,
    ...Arranges.between,
    ...FlexBoxs.horizon,
  },
  search_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  info_number: {
    ...Texts.button_text_10,
    ...Colors.font_a760b1,
  },
  info_txt: {
    ...Texts.button_text_10,
    ...Colors.font_999,
  },
  nothing_search_img: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  search_sort: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  search_sort_txt: {
    ...Texts.contents_text_0,
    ...Spacings.margin_right_6,
  },

  scroll_wrap: {
    ...Colors.background_fff,
  },
  row: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_bottom_30,
  },
  badge_img: {
    ...Positions.absolute,
    top: 8,
    right: 8,
  },
  badge_ad: {
    ...Positions.absolute,
    ...Texts.contents_text_47,
    ...Spacings.padding_top_bottom_4,
    ...Colors.font_be69c3,
    ...Colors.background_fff,
    ...Colors.border_color_be69c3,
    ...Spacings.border_radius_4,
    overflow: "hidden",
    borderWidth: 1,
    borderStyle: 'solid',
    paddingHorizontal: 8,
    top: 8,
    right: 8,
  },
  last_row: {
    marginBottom: 120,
  },
  relation_product_item: {
    width: '100%',
  },
  relation_product_item_horizontal: {
    ...Spacings.margin_right_8,
  },
  relation_product_image: {
    width: '100%',
    height: '100%',
    ...Spacings.border_radius_8,
    ...Spacings.margin_bottom_8,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: "rgba(0, 0, 0, 0.1)",
    aspectRatio: 1,
    objectFit: "cover",
  },
  relation_product_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  relation_product_item_text1: {
    ...Texts.contents_text_3,
    ...Texts.font_weight_500,
    ...Spacings.margin_bottom_4,
    textAlign: 'start'
  },
  relation_product_item_text2: {
    ...Texts.contents_text_5,
    ...Colors.font_999,
  },
  relation_product_item_text3: {
    ...Texts.contents_text_5,
    color: "#682c78",
  },
  relation_flag: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 2,
    ...Texts.font_size_10,
    ...Texts.font_weight_500,
    ...Texts.line_height_14,
    ...Colors.font_be69c3,
    ...Colors.background_f7f4fa,
    ...Spacings.margin_left_3,
    ...Texts.font_letter_spacing_04,
  },
  relation_product_first_item: {
    ...Spacings.margin_left_16,
  },
  bar: {
    ...Spacings.margin_left_right_10,
    ...Colors.background_d9d9d9,
    width: 1,
    height: 10,
  },

  data_list: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
    ...Arranges.between,
  },
  data_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  data_reverse_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.reverse_horizon,
    ...Arranges.between,
  },
  data_item: {
    width: '32.82%',
    height: '32.82%',
  },
  data_big_item: {
    width: '66%',
    height: '66%',
  },
  data_margin_right: {
    marginRight: 0.7,
  },
  data_margin_bottom: {
    marginBottom: 0.7,
  },
  image_icon: {
    ...Positions.absolute,
    top: 2,
    right: 2,
  },
  add_detail_btn_wrap: {
    ...Spacings.padding_right_left_16,
  },
  add_detail_btn: {
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...Spacings.margin_top_16,
    ...Spacings.border_radius_4,
    ...Spacings.padding_top_bottom_15,
    ...Colors.border_color_eee,
    borderWidth: 1,
    borderStyle: 'solid'
  },
  add_detail_btn_text: {
    ...Texts.button_text_10,
    ...Colors.font_222,
    fontWeight: "500",
  },
  section_title_wrap: {
    ...FlexBoxs.horizon,
    ...Spacings.margin_left_20,
    ...Spacings.margin_top_40,
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...Spacings.margin_bottom_14,
  },
  section_title_wrap_ex: {
    ...Spacings.margin_top_10,
  },
  section_title: {
    ...Texts.contents_text_33,
    ...Spacings.margin_right_4,
    ...Colors.font_222,
  },
  section_number: {
    ...Texts.btn_text_0,
    ...Colors.font_a760b1,
  },

  qna_wrap: {
    ...Spacings.padding_left_right_20,
  },
  qna_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  qna_txt_wrap: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_left_6,
    ...Spacings.padding_right_14,
  },
  qna_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_left_30,
    ...Spacings.margin_top_20,
    ...Arranges.between,
  },
  qna_img: {
    width: 72,
    height: 72,
  },
  qna_text1: {
    ...Texts.contents_text_38,
    ...Colors.font_222,
    ...Spacings.margin_bottom_10,
    fontWeight: "500",
  },
  qna_text2: {
    ...Texts.button_text_10,
    ...Colors.font_666666,
  },
  qna_info_first: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  qna_info_second: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  info_txt1: {
    ...Texts.contents_text_9,
    ...Texts.font_weight_500,
  },
  info_txt2: {
    ...Texts.button_text_9,
    ...Colors.font_999,
  },
  info_txt3: {},
  info_txt4: {},
  qna_bar: {
    width: 1,
    height: 12,
    ...Spacings.margin_left_right_7,
    ...Colors.background_eeeeee,
  },
  qna_item_wrap: {
    ...Colors.border_color_eee,
    ...Spacings.padding_bottom_20,
  },
  qna_item_wrap_padding: {
    ...Spacings.padding_top_25,
  },
  qna_item_wrap_border: {
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  qna_item_wrap_divider: {
    borderTopWidth: 1,
    borderTopColor: "#eee",
  },
  empty_wrap: {
    ...Positions.fullscreen,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  empty_text: {
    ...Texts.contents_text_21,
    ...Texts.font_weight_500,
  },
  recommend_user_image: {
    width: 64,
    height: 64,
    borderRadius: 32,
  },
  recommend_users_text1: {
    ...Spacings.margin_bottom_10,
    ...Spacings.padding_left_20,
    ...Texts.contents_text_28,
    ...Texts.font_weight_500,
    ...Colors.font_333,
  },
  recommend_user_item: {
    ...Spacings.margin_right_16,
  },
  first_recommend_user_item: {
    ...Spacings.padding_left_20,
  },
  recommend_user_text2: {
    ...Texts.contents_text_5,
    ...Arranges.text_align_center,
    ...Spacings.margin_top_4,
    width: 56,
  },
  recommend_noting_tag_wrap: {
    ...Spacings.padding_right_left_16,
  },
  recommend_noting_tag_list_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
  },
  recommend_tag_item_bottom: {
    ...Colors.background_f7f4fa,
    ...Spacings.padding_left_right_16,
    ...Spacings.border_radius_300,
    ...Spacings.margin_right_6,
    ...Spacings.margin_bottom_8,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.padding_top_bottom_14,
  },
  tag_list: {
    ...Spacings.padding_right_left_16,
  },
  tag_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.margin_bottom_20,
  },
  tag_last_item: {
    marginBottom: 140,
  },
  tag_wrap: {
    ...Spacings.margin_left_10,
  },
  tag_title_text: {
    ...Texts.contents_text_28,
    ...Texts.font_weight_500,
    width: '75%',
  },
  tag_count_text: {
    ...Spacings.margin_top_10,
    ...Texts.contents_text_31,
    ...Colors.font_999,
  },

  poster_list: {
    ...Spacings.padding_right_left_16,
  },
  poster_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_bottom_20,
  },
  poster_profile_image: {
    width: 48,
    height: 48,
    ...Spacings.border_radius_100,
    ...Spacings.margin_right_10,
  },
  poster_profile_text: {
    ...Texts.contents_text_34,
    ...Texts.font_weight_500,
  },

  latest_search: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_bottom_8,
    ...Spacings.margin_top_32,
  },
  latest_search_header: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  delete_all_btn_text: {
    ...Texts.btn_text_3,
    ...Texts.font_weight_500,
    ...Colors.font_666666,
  },
  latest_search_list: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
  },
  latest_search_item: {
    ...FlexBoxs.flex,
    ...Arranges.center_h,
    ...FlexBoxs.horizon,
    width: '44%',
    height: 42,
  },
  latest_search_text: {
    ...Texts.contents_text_3,
  },

  auto_complete_list: {
    ...Spacings.padding_top_8,
  },
  auto_complete_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_right_left_20,
    ...Spacings.padding_top_bottom_11,
    ...Arranges.center_h,
  },
  auto_complete_text: {
    ...Spacings.margin_left_6,
    ...Texts.contents_text_3,
  },
  auto_complete_match_text: {
    ...Colors.font_a760b1,
  },
  sheet: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_top_16,
    ...Spacings.padding_bottom_19,
    ...Spacings.padding_left_right_20,
  },
  close_btn: {
    ...Positions.absolute,
    top: 6,
    right: 6,
  },
  sheet_title_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 32,
  },
  sheet_title: {
    ...Texts.contents_text_33,
    ...Colors.font_222,
  },
  sort_list: {
    ...Spacings.margin_top_16,
    ...FlexBoxs.flex,
    ...Arranges.center_h,
  },
  sort_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Spacings.padding_top_bottom_15,
  },
  sort_text: {
    ...Texts.contents_text_38,
  },
  sort_active_text: {
    ...Colors.font_be69c3,
    fontWeight: "bold",
  },
};
