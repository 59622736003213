import { TempMember } from "../api/tempMember/model";
import AuthStore from "../store/AuthStore";
import { TEMP_MEMBER } from "../constants/Storage";

export const setTempMemberInStorage = async (
  tempMember: TempMember
): Promise<boolean> => {
  try {
    await localStorage.setItem(TEMP_MEMBER, JSON.stringify(tempMember));

    AuthStore.setTempMember(tempMember);
    return true;
  } catch (error) {
    return false;
  }
};

export const mergeAndSetTempMemberInStorage = async (
  tempMember: TempMember
): Promise<boolean> => {
  const prevTempMember = await getTempMemberFromStorage();
  return await setTempMemberInStorage({ ...prevTempMember, ...tempMember });
};

export const getTempMemberFromStorage =
  async (): Promise<TempMember | null> => {
    try {
      const tempMember = await localStorage.getItem(TEMP_MEMBER);
      if (tempMember) {
        return JSON.parse(tempMember) as TempMember;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

export const removeTempMemberFromStorage = async (): Promise<boolean> => {
  try {
    await localStorage.removeItem(TEMP_MEMBER);

    AuthStore.setTempMember(null);
    return true;
  } catch (error) {
    return false;
  }
};
