import React, { CSSProperties } from "react";
import {
  Arranges,
  FlexBoxs,
  Spacings,
  Texts,
  Colors,
  Positions,
  Assets,
  Icons,
} from "../../assets/styles";
import { ReactComponent as AngleIcon } from "../../assets/icons/new_back_arrow.svg";
import { ReactComponent as HomeIcon } from "../../assets/icons/gnb-home-32.svg";
import { Styles } from "../../assets/types/Style";
import PostStore from "../../store/PostStore";
import classes from "./PostsListHeader.module.scss";
import { MAIN_TAB } from "../../constants/main";
import MainStore from "../../store/MainStore";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import clsx from "clsx";

export interface PostsListHeaderProps {
  title: string;
  isHome?: boolean;
  goBack?: () => void;
  onClickHome?: () => void;
  onSearch?: () => void;
  wrapStyle?: CSSProperties;
}

const PostsListHeader = ({
  title,
  isHome,
  goBack,
  onClickHome,
  onSearch,
  wrapStyle,
}: PostsListHeaderProps) => {
  const toggleScreen = () => {
    if (goBack) {
      goBack();
    } else {
      //   navigate("SearchScreen");
    }
  };

  const moveToHome = () => {
    if (onClickHome !== undefined) onClickHome();
    else {
      goTo("/Main");
    }
  };

  return (
    // <div style={[header, between_header]}>
    <div className={clsx(classes.header, "common-header-system")}
         style={{...(wrapStyle ? wrapStyle : {})}}
    >
      <div className={classes.inner_header}>
        {/* <div style={center}> */}
        <div className={classes.title}>
          <span className={classes.title_txt}>{title}</span>
        </div>

        <div className={classes.left}>
          <div
            className={classes.icon}
            onClick={toggleScreen}
            aria-label="back"
          >
            <AngleIcon />
          </div>
          {isHome && (
            <div
              className={classes.icon}
              onClick={moveToHome}
              aria-label="home"
            >
              <HomeIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const styles: Styles = {
  header: {
    ...FlexBoxs.horizon,
    ...Spacings.padding_top_bottom_11,
    ...Arranges.center_h,
    ...Spacings.padding_right_left_16,
    ...Colors.background_fff,
  },
  alarm_header: {
    ...Arranges.end_v,
  },
  login_header: {
    ...Colors.background_6c2773,
  },
  my_home_header: {
    ...Colors.background_6c2773,
  },
  profile_background_424242: {
    ...Colors.background_424242,
  },
  left: {
    width: 78,
    ...FlexBoxs.horizon,
  },
  center: {
    ...Positions.fullscreen,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
  },
  inapp_center: {
    ...FlexBoxs.flex_1,
    marginLeft: "5.1vw",
    marginRight: "7.9vw",
  },
  title_txt: {
    ...Texts.header_text_0,
  },
  login_txt: {
    ...Spacings.padding_left_20,
    ...Texts.header_text_2,
  },
  right: {
    ...FlexBoxs.horizon,
  },
  between_header: {
    ...Arranges.between,
  },
  skip_btn: {
    ...Spacings.border_radius_100,
    ...Spacings.padding_top_bottom_8,
    ...Spacings.padding_right_left_12,
    borderWidth: 1,
  },

  post_write_button: {
    ...Colors.border_color_eee,
    ...Spacings.border_radius_100,
    borderWidth: 1,
    height: 36,
    width: 50,
    ...Arranges.center_v_h,
  },
  post_write_button_text: {
    ...Texts.button_text_10,
    ...Colors.font_999,
  },
  search_btn: {
    ...Spacings.margin_right_6,
  },

  drop_down_list: {
    ...Positions.absolute,
    ...Spacings.padding_right_left_14,
    ...Spacings.padding_top_bottom_8,
    ...Colors.background_fff,
    width: 198,
    zIndex: 10,
  },
  drop_down_item: {
    ...Arranges.center_v,
    ...Colors.border_color_black_015,
    borderBottomWidth: 1,
    height: 42,
  },
  last_item: {
    borderBottomWidth: 0,
  },
  drop_down_item_text: {
    ...Texts.btn_text_1,
    ...Colors.font_222,
  },

  report_modal: {
    width: "82vw",
    ...Colors.background_fff,
    ...Spacings.border_top_left_radius_12,
    ...Spacings.border_top_right_radius_12,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
    ...Colors.border_color_transparent,
    ...Arranges.margin_center_v_h,
    ...Assets.modal_box_shadow,
  },
  modal_header: {
    ...Arranges.center_v_h,
    ...Positions.relative,
    height: 64,
  },
  modal_body: {
    ...FlexBoxs.vertical,
    ...Arranges.center_v_h,
    ...Spacings.padding_bottom_24,
  },
  icons: {
    ...Icons.icon_32,
    ...Positions.absolute,
    ...Positions.top_6,
    ...Positions.right_10,
  },
  header_text: {
    ...Texts.modal_text_0,
  },
  contents_text: {
    ...Texts.contents_text_3,
  },
  modal_footer: {
    height: 48,
  },
  button: {
    ...Colors.background_222,
    ...Arranges.center_v_h,
    ...FlexBoxs.flex_1,
    ...Spacings.border_bottom_left_radius_12,
    ...Spacings.border_bottom_right_radius_12,
  },
  footer_button_text: {
    ...Texts.button_text_3,
    ...Colors.font_fff,
  },

  url_copy_btn: {
    ...Spacings.padding_top_bottom_8,
    ...Spacings.padding_right_left_12,
    ...Spacings.border_radius_100,
    ...Colors.border_color_222,
    borderWidth: 1,
  },
  url_copy_btn_text: {
    ...Texts.button_text_4,
  },
};

export default PostsListHeader;
