import React from 'react'
import { useTranslation } from 'react-i18next'
import { Arranges, FlexBoxs, Positions, Texts } from '../../../assets/styles'
import { ReactComponent as FollowerListImage } from '../../../assets/icons/follower_list.svg'
import { Styles } from '../../../assets/types/Style'
import classes from "./EmptyLikes.module.scss"

const EmptyLikes = () => {
  const { t } = useTranslation()

  return (
    <div className={classes.noting_likes_wrapper}>
      <FollowerListImage />
      <div className={classes.noting_likes}>
        <div className={classes.noting_likes_box}>
          <span className={classes.noting_likes_txt} aria-label="Empty likes">
            {t('screen.like.message.emptyLikes')}
          </span>
        </div>
      </div>
    </div>
  )
}

export default EmptyLikes
