import PreferenceStore from "../../../store/PreferenceStore";
import { observer } from "mobx-react";
import { PreferenceTypeCode } from "../../../constants/Preference.enum";
import SeletablePreference from "./SeletablePreference";

const HomeAppliancePreference = observer(() => {
  const {
    homeAppliancePreference,
    selectedHomeAppliancePreferenceListCodes,
    selectHomeAppliancePreferenceListCodes,
    preferenceSteps,
  } = PreferenceStore;

  const handleSelect = (item: string) => {
    selectHomeAppliancePreferenceListCodes(item);
  };

  const currentStep =
    preferenceSteps.findIndex(
      (item) => item === PreferenceTypeCode.HOME_APPLIANCE
    ) + 1;
  const totalStepCount = preferenceSteps.length;

  return homeAppliancePreference ? (
    <SeletablePreference
      preference={homeAppliancePreference}
      selectedPreferenceCodes={selectedHomeAppliancePreferenceListCodes}
      onSelectPrefereceCode={handleSelect}
      currentStep={currentStep}
      totalStepCount={totalStepCount}
    />
  ) : (
    <></>
  );
});

export default HomeAppliancePreference;
