import React, { ReactNode, useEffect, useState } from 'react'
import './FadeInView.css'

const FadeInView = (props: { children: ReactNode }) => {
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    setFadeIn(true)
  }, [])

  return (
    <div className={`fade-in ${fadeIn ? 'fade-in' : ''}`}>{props.children}</div>
  )
}

export default FadeInView
