import { ReactComponent as Spinner } from "../../assets/animations/spinner_black.svg";
import { ReactComponent as DarkSpinner } from "../../assets/animations/darkSpinner.svg";

import classes from "./CustomActivityIndicatorSmall.module.scss";

const CustomActivityIndicator = ({
  isDarkSpinner,
}: {
  isDarkSpinner?: Boolean;
}) => {
  return (
    <div className={classes.wrap}>
      {isDarkSpinner ? (
        <DarkSpinner style={{ width: 100, height: 100 }} />
      ) : (
        <Spinner style={{ width: 100, height: 100 }} />
      )}
    </div>
  );
};

export default CustomActivityIndicator;
