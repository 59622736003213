import { observer } from "mobx-react";
import { Platform, PlatformTypes } from "../../native/platform";
import { useCallback, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import dayjs from "dayjs";
import { createPortal } from "react-dom";
import { useWindowSize } from "react-use";
import CommonStore from "../../store/CommonStore";
import "./AppInstallSideBanner.scss";

export const WIDTH_APP_INSTALL_SIDE_BANNER = 350;

const AppInstallSideBanner = observer(() => {
  const { width: widthScreen } = useWindowSize();

  const isShowOS = useCallback(() => {
    return (
      Platform.OS === PlatformTypes.MACOS_WEB ||
      Platform.OS === PlatformTypes.WINDOWS_WEB ||
      Platform.OS === PlatformTypes.WEB
    );
  }, []);

  const isAppInstallSideBanner = isShowOS() && widthScreen >= 1000;

  useEffect(() => {
    if (isAppInstallSideBanner) {
      CommonStore.setIsShowAppInstallSideBanner(true);
      document.body.classList.add("app-install-side-banner");
    } else {
      CommonStore.setIsShowAppInstallSideBanner(false);
      document.body.classList.remove("app-install-side-banner");
    }

    return () => {
      document.body.classList.remove("app-install-side-banner");
    };
  }, [isAppInstallSideBanner]);

  const render = () => (
    <div
      id="AppInstallSideBanner"
      style={{ width: WIDTH_APP_INSTALL_SIDE_BANNER }}
    >
      <div className="content" style={{ width: WIDTH_APP_INSTALL_SIDE_BANNER }}>
        <div className="image_wrap">
          <LazyLoadImage
            src={`${
              process.env.REACT_APP_PUBLIC_BUCKET_URL
            }/SIDE_BANNER/sideBanner_1.png?${dayjs().format("YYYYMMDDHHmmss")}`}
          />
        </div>
        <div className="image_wrap">
          <LazyLoadImage
            src={`${
              process.env.REACT_APP_PUBLIC_BUCKET_URL
            }/SIDE_BANNER/sideBanner_2.png?${dayjs().format("YYYYMMDDHHmmss")}`}
          />
        </div>
      </div>
    </div>
  );

  return isAppInstallSideBanner && createPortal(render(), document.body!);
});
export default AppInstallSideBanner;
