import { ReactComponent as CommentIcon } from "../../../assets/icons/new_comment_gray.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/new_heart_off.svg";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { getDateStr } from "../../../utils/datetime";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { BrandCommunityPopularArticleResponse } from "../../../api/brand/model";
import { ReactComponent as ViewIcon } from "../../../assets/icons/icon_view.svg";
import { Virtuoso } from "react-virtuoso";

const PopularArticle = observer(
  ({
    pageType,
    disabledBrandName,
    renderData,
    goToArticleDetail,
    onEndReached,
    handleMore,
  }: {
    pageType: string;
    disabledBrandName?: boolean;
    renderData: BrandCommunityPopularArticleResponse[] | [];
    goToArticleDetail: (articleId: number) => void;
    onEndReached?: () => void;
    handleMore?: () => void;
  }) => {
    const {
      qna_list,
      qna_item,
      qna_wrap,
      qna_info_wrap,
      qna_info,
      qna_top,
      qna,
      qna_image,
      qna_comment,
      like,
      like_text,
      qna_item_text1,
      qna_item_text3,
      qna_item_text4,
      qna_item_text5,
      qna_item_bar,
      comment_icon,
      bx_ellipsis,
      notice_badge_back,
      notice_badge_text,
      notice_chat_back,
      notice_chat_text
    } = styles;

    const { t } = useTranslation();

    const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
    const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";

    const renderItem = () => {
      return (
        <Virtuoso
          style={qna_list}
          useWindowScroll
          data={renderData}
          itemContent={(index, item) => {
            const isEnd = index === renderData.length - 1;
            return (
              <div key={index} style={{ paddingBottom: 16, ...(index === 0 ? { paddingTop: 4 } : {}) }}>
                <div style={{ ...qna_item, ...(isEnd ? { borderBottomWidth: 0 } : {}) }}>
                  <div style={qna_wrap}>
                    <div style={styles.bx_comm_popular_wrap}>
                      <div style={styles.bx_comm_pp_left}>
                        <TouchableWithAuthCheck
                          style={qna_top}
                        onPress={() => {
                          goToArticleDetail(item.articleId);
                        }}
                        >
                          <div style={qna}>
                            <div style={styles.item_title_wrap}>
                              <div style={{ position: "relative", width: "100%" }}>
                                <div style={qna_item_text1}>
                                  {item.articleTypeCode === "NOTICE" ? (
                                    <span style={{ ...styles.flag_default_text, ...styles.flag_notice_text }}>
                                      {t("screen.brandCommunity.label.notice")}
                                    </span>
                                  ) : (
                                    <span style={{ ...styles.flag_default_text, ...styles.flag_notice_text }}>{item.articleTypeName}</span>
                                  )}
                                  <span style={item.articleTypeCode === "NOTICE" ? { width: 8, display: 'inline-block', flexShrink: 0 } : { width: 8, display: 'inline-block', flexShrink: 0 }} />
                                  <p style={{ overflow: 'hidden', display: '-webkit-box', WebkitLineClamp: '1', lineClamp: '1', WebkitBoxOrient: 'vertical', whiteSpace: 'pre-wrap' }}>{item.articleTitle}</p>
                                </div>
                                <div style={{ position: "absolute", maxWidth: "100%" }}>
                                  <p style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%'}} 
                                    dangerouslySetInnerHTML={{
                                    __html: item.articleContents || "",
                                  }}></p>
                                  </div>
                                <div style={{ ...(item.articleTypeCode === "NOTICE" ? notice_badge_back : notice_chat_back) }}>
                                  {item.articleTypeCode === "NOTICE" ? (
                                    <p style={notice_badge_text}>{item.articleTypeName}</p>
                                  ) : (
                                    <p style={notice_chat_text}>{item.articleTypeName}</p>
                                  )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </TouchableWithAuthCheck>
                        <div style={qna_info_wrap}>
                          <div style={qna_info}>
                            {pageType === "BrandCommunityMain" && (
                              <>
                                <div style={bx_ellipsis}>
                                  <p style={qna_item_text3}>
                                    {item.communityName}
                                  </p>
                                </div>
                              </>
                            )}

                            {pageType === "BrandCommunityHome" && (
                              <>
                                <div style={bx_ellipsis}>
                                  <p style={qna_item_text3}>
                                    {item.nickname}
                                  </p>
                                </div>
                              </>
                            )}
                            <div style={qna_item_bar} />
                            <div style={qna_comment}>
                              <CommentIcon style={comment_icon} />
                              <p style={qna_item_text4}>{item.commentCount}</p>
                            </div>
                            <div style={like}>
                              <ViewIcon />
                              <p style={{ ...qna_item_text5, ...like_text, ...styles.view_num }}>{item.articleViewCount}</p>
                            </div>
                            <div style={like}>
                              <HeartIcon />
                              <p style={{ ...qna_item_text5, ...like_text }}>{item.articleLikeCount}</p>
                            </div>
                            {pageType === "BrandCommunityHome" && (
                              <>
                                <div style={qna_item_bar} />
                                <p style={qna_item_text5}>{getDateStr(item.createdDatetime)}</p>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      {item.thumbnailFilePath && (
                        <TouchableWithAuthCheck
                          style={styles.bx_comm_img}
                          onPress={() => {
                            goToArticleDetail(item.articleId);
                          }}
                        >
                          <FastImageWithFallback
                            style={qna_image}
                            source={{ uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}` }}
                            fallbackImageUri={FALLBACK_IMAGE_URL}
                          />
                          {item.fileCount > 1 && (
                            <div style={styles.num_comm_img}>
                              <p style={styles.num_comm_text}>{item.fileCount}</p>
                            </div>
                          )}
                        </TouchableWithAuthCheck>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
          components={{
            Footer: () => (
              handleMore ? (
                <>
                  <div style={styles.btn_wrap}>
                    <TouchableWithAuthCheck style={styles.all_view_btn} onPress={() => handleMore()}>
                      <p style={styles.all_view_btn_text}>{t("screen.brandCommunity.button.morePopularArticle")}</p>
                    </TouchableWithAuthCheck>
                  </div>
                </>
              ) : <></>
            ),
          }}
          endReached={() => {
            onEndReached && onEndReached()
          }}
        />
      );
    };

    return (
      <>
        <div style={styles.bx_in_title}>
          <div style={styles.title_text}>
            <div style={styles.bg_title} />
            <p style={styles.title_text_st1}>{t("screen.brandCommunity.label.popularArticle")}</p>
          </div>
          <div>
            <p style={styles.title_more}>{t("screen.brandCommunity.message.popularArticleGuide1")}</p>
          </div>
        </div>
        <p style={styles.sub_title}>{t("screen.brandCommunity.message.popularArticleGuide2")}</p>
        {renderData && renderData.length > 0 && renderItem()}
      </>
    );
  }
);

const styles: Styles = {
  bg_title: {
    height: 8,
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#F7E237",
  },
  bx_in_title: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.margin_bottom_6,
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_top_30,
  },
  title_text: {
    position: "relative",
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  title_text_st1: {
    fontSize: 18,
    fontWeight: "500",
    color: "#222",
    letterSpacing: -0.72,
    zIndex: 1,
  },
  title_more: {
    ...Texts.contents_text_0,
    ...Colors.font_999,
  },
  sub_title: {
    ...Spacings.padding_left_right_16,
    ...Texts.contents_text_0,
    ...Colors.font_999,
    marginTop: 10,
  },
  brand_ci_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_bottom_8,
  },
  brand_ci_img: {
    width: 24,
    height: 24,
    ...Spacings.border_radius_22,
    ...Spacings.margin_right_6,
    ...Colors.background_999999,
  },
  brand_name: {
    ...Texts.contents_text_5,
  },
  item_title_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  flag_default: {
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: "auto",
    ...Colors.background_f5f5f5,
    ...Spacings.border_radius_4,
    ...Spacings.padding_left_right_6,
    ...Spacings.padding_top_bottom_4,
    ...Spacings.margin_right_6,
  },
  flag_notice: {
    ...Colors.background_f7e237,
  },

  flag_default_text: {
    ...Texts.font_size_16,
    ...Colors.font_be69c3,
    ...Texts.font_weight_500,
    display: 'inline-block',
    width: 34,
    flexShrink: 0,
  },
  flag_notice_text: {
    opacity: 0,
    color: "rgba(0,0,0,0)",
  },
  notice_badge_back: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 34,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 22,
    borderRadius: 4,
    backgroundColor: "#F7E237",
  },
  notice_badge_text: {
    fontSize: 10,
    fontWeight: "700",
  },
  notice_chat_back: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 34,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    height: 22,
    borderRadius: 4,
    backgroundColor: "#F5F5F5",
  },
  notice_chat_text: {
    ...Colors.font_be69c3,
    fontSize: 10,
    fontWeight: "700",
  },
  bx_comm_popular_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
  },
  bx_comm_pp_left: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    minHeight: 72,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  bx_comm_img: {
    width: 72,
    height: 72,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    ...Positions.relative,
    ...Spacings.border_radius_4,
    ...Colors.background_f5f5f5,
    overflow: "hidden",
  },
  num_comm_img: {
    width: 22,
    height: 22,
    ...Positions.absolute,
    ...Colors.background_666,
    ...Spacings.border_radius_4,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    opacity: 0.8,
    right: 0,
    bottom: 0,
  },
  num_comm_text: {
    ...Texts.contents_text_47,
    ...Colors.font_fff,
  },
  question_icon: {
    ...Spacings.margin_right_6,
  },
  qna_list: {
    ...Spacings.margin_top_16,
  },
  qna_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Spacings.padding_left_right_16,
    ...Spacings.padding_bottom_16,
    // ...Spacings.margin_bottom_16,
    ...Colors.border_color_eee,
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  qna_wrap: {
    ...FlexBoxs.flex_1,
  },
  qna_info_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Spacings.margin_top_8,
    marginRight: 10,
    overflow: "hidden",
  },

  qna_info: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  qna_small_info: {
    ...FlexBoxs.flex,
    ...Arranges.start_h,
    ...FlexBoxs.vertical,
    ...FlexBoxs.flex_1,
  },
  small_wrap: {
    ...FlexBoxs.flex,
    ...Spacings.margin_top_4,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    width: "100%",
  },
  small_wrap_inner: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
  },
  like: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  like_text: {
    ...Spacings.margin_left_2,
  },
  view_num: {
    ...Spacings.margin_left_3,
    marginRight: 8,
  },
  qna_item_text1: {
    ...FlexBoxs.flex,
    flexShrink: 1,
    flexGrow: 1,
    flexBasis: "auto",
    ...Texts.contents_text_34,
    ...Texts.font_weight_500,
    textAlign: 'start',
    overflow: 'hidden', 
    whiteSpace: 'nowrap', 
    textOverflow: 'ellipsis', 
    maxWidth: '100%'
  },
  qna_item_text3: {
    ...Texts.contents_text_9,
    ...Texts.font_weight_500,
    ...Colors.font_999,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    lineClamp: '2',
    WebkitBoxOrient: 'vertical'
  },
  qna_top: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    width: '100%',
    flex: "1 1 auto",
  },
  qna: {
    ...FlexBoxs.flex_1,
    ...Spacings.margin_right_8,
  },
  qna_image: {
    width: 72,
    height: 72,
    ...Spacings.border_radius_6,
  },
  qna_comment: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  comment_icon: {
    ...Spacings.margin_right_4,
  },
  qna_item_text4: {
    ...Texts.contents_text_9,
    ...Colors.font_999,
    marginRight: 8,
  },
  qna_item_text5: {
    ...Texts.contents_text_9,
    ...Colors.font_999,
  },
  qna_item_bar: {
    ...Spacings.margin_left_8,
    ...Spacings.margin_right_7,
    width: 1,
    height: 14,
    ...Colors.background_eeeeee,
  },
  reply_btn_text: {
    ...Texts.btn_text_2,
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
  go_question: {
    height: 53,
    borderRadius: 6,
    ...Spacings.margin_left_right_16,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.between,
    ...Arranges.center_h,
    ...Colors.background_f6f4fa,
    ...Spacings.padding_left_right_20,
    ...Spacings.margin_top_bottom_20,
  },
  go_question_txt1: {
    ...Texts.contents_text_38,
    ...Colors.font_be69c3,
    fontWeight: "500",
  },
  go_question_txt2: {
    ...Texts.btn_text_2,
    ...Colors.font_be69c3,
    fontWeight: "500",
  },
  go_question_txt_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_v_h,
  },
  tab_item_wrap: {
    ...Spacings.padding_right_left_16,
    ...Spacings.padding_top_bottom_8,
  },
  tab_item: {
    height: 34,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.padding_left_right_20,
    ...Spacings.border_radius_300,
    ...Spacings.margin_right_6,
    ...Colors.border_color_eee,
    ...Colors.background_fff,
    borderWidth: 1,
  },
  tab_active_item: {
    ...Colors.background_222,
  },
  tab_txt: {
    ...Texts.contents_text_41,
    ...Colors.font_666666,
  },
  tab_active_txt: {
    ...Colors.font_fff,
  },
  header_option: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Arranges.between,
    ...Colors.background_f5f5f5,
    ...Spacings.padding_left_right_20,
    ...Spacings.padding_top_bottom_16,
  },
  header_check_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  header_check_btn_txt: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    ...Spacings.margin_left_6,
  },
  header_sort_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  header_sort_btn_txt: {
    ...Texts.btn_text_1,
    ...Colors.font_666666,
    ...Spacings.margin_right_6,
  },
  empty: {
    ...FlexBoxs.flex_1,
    ...Spacings.padding_left_right_16,
    ...Spacings.margin_top_16,
  },
  empty_wrap: {
    ...Positions.fullscreen,
    ...FlexBoxs.flex_1,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
  },
  empty_text: {
    ...Texts.contents_text_38,
    ...Texts.font_weight_500,
    ...Colors.font_666666,
  },
  write_qna_btn: {
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.margin_top_14,
    ...Spacings.border_radius_300,
    ...Colors.border_color_be69c3,
    borderWidth: 1,
    height: 44,
    width: 120,
  },
  write_qna_btn_text: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_be69c3,
  },
  tag_option: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Colors.background_f5f5f5,
    ...Spacings.padding_left_20,
    marginBottom: 1,
  },
  tag_label_wrap: {
    ...FlexBoxs.flex,
    ...Arranges.center_v,
    zIndex: 100,
  },
  tag_label: {
    ...Spacings.padding_top_bottom_8,
    ...Texts.contents_text_41,
    ...Texts.font_weight_bold,
    ...Colors.font_222,
  },
  gradient: {
    ...Positions.absolute,
    ...Positions.top,
    ...Positions.bottom,
    right: -16,
    width: 16,
  },

  btn_wrap: {
    ...Spacings.margin_left_right_16,
  },
  all_view_btn: {
    borderWidth: 1,
    borderStyle: 'solid',
    height: 53,
    ...FlexBoxs.flex,
    ...Arranges.center_v_h,
    ...Spacings.border_radius_4,
    ...Colors.border_color_eee,
    width: '100%'
  },
  all_view_btn_text: {
    ...Texts.button_text_6,
    ...Texts.font_weight_500,
  },

  bx_ellipsis: {
    flexShrink: 1,
    flexGrow: 0,
    overflow: "hidden",
  },
};

export default PopularArticle;
