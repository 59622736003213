import { action, computed, makeObservable, observable } from "mobx";
import { UserVoltHistory, UserVoltInfo } from "../api/volt/model";
import AuthStore from "./AuthStore";
import { getMyVolt, getMyVoltHistory } from "../api/volt/api";
import { EventTypeCode } from "../constants/Volt.enum";

export const PAGE_SIZE = 20;

class VoltStore {
  constructor() {
    makeObservable(this);
  }

  @observable _userId = "";
  @observable _userVoltInfo: UserVoltInfo | undefined;
  @observable _userVoltHistory: UserVoltHistory[] = [];
  @observable _userVoltHistoryTotalCount = 0;
  @observable _pageIndex = 0;

  @computed get userId() {
    return this._userId;
  }

  @computed get userVoltInfo() {
    return this._userVoltInfo;
  }

  @computed get userVoltHistory() {
    return this._userVoltHistory;
  }

  @computed get userVoltHistoryTotalCount() {
    return this._userVoltHistoryTotalCount;
  }

  @computed get pageIndex() {
    return this._pageIndex;
  }

  @action setUserId(id: string) {
    this._userId = id;
  }

  @action setUserVoltInfo(info: UserVoltInfo) {
    this._userVoltInfo = info;
  }

  @action setUserVoltHistory(history: UserVoltHistory[]) {
    this._userVoltHistory = history;
  }

  @action setUserVoltHistoryTotalCount(totalCount: number) {
    this._userVoltHistoryTotalCount = totalCount;
  }

  @action setPageIndex(pageIndex: number) {
    this._pageIndex = pageIndex;
  }

  @action clear = () => {
    this.setUserId(AuthStore.sessionUser?.memberUuid || "");
    this.setUserVoltInfo({
      voltAmount: 0,
      voltScheduledExpiryAmount: 0,
      voltSecondScheduledExpiryAmount: 0,
      voltThirdScheduledExpiryAmount: 0,
    });

    void this.getUserVoltInfo();
    void this.getUserVoltHistory();
  };

  @action getUserVoltInfo = async () => {
    await getMyVolt(
      this.userId != "" ? this.userId : AuthStore.sessionUser?.memberUuid || ""
    ).then((response) => {
      this.setUserVoltInfo(response);
    });
  };

  @action getUserVoltHistory = async (eventTypeCode?: EventTypeCode) => {
    this.setUserVoltHistory([]);
    this.setUserVoltHistoryTotalCount(0);
    this.setPageIndex(0);

    const targetId =
      this.userId != "" ? this.userId : AuthStore.sessionUser?.memberUuid || "";
    if (targetId != "") {
      if (eventTypeCode) {
        await getMyVoltHistory(
          targetId,
          eventTypeCode,
          PAGE_SIZE,
          this.pageIndex
        ).then((response) => {
          this.setUserVoltHistory(response.historyData);
          this.setUserVoltHistoryTotalCount(response.totalCount);
        });
      } else {
        await getMyVoltHistory(
          targetId,
          undefined,
          PAGE_SIZE,
          this.pageIndex
        ).then((response) => {
          this.setUserVoltHistory(response.historyData);
          this.setUserVoltHistoryTotalCount(response.totalCount);
        });
      }
    }
  };

  @action getMoreUserVoltHistory = async (eventTypeCode?: EventTypeCode) => {
    const targetId =
      this.userId != "" ? this.userId : AuthStore.sessionUser?.memberUuid || "";
    const offset = PAGE_SIZE * (this.pageIndex + 1);

    if (targetId != "" && this.userVoltHistoryTotalCount > offset) {
      this.setPageIndex(this.pageIndex + 1);

      if (eventTypeCode) {
        await getMyVoltHistory(targetId, eventTypeCode, PAGE_SIZE, offset).then(
          (response) => {
            this.setUserVoltHistory(
              this.userVoltHistory.concat(response.historyData)
            );
            this.setUserVoltHistoryTotalCount(response.totalCount);
          }
        );
      } else {
        await getMyVoltHistory(targetId, undefined, PAGE_SIZE, offset).then(
          (response) => {
            this.setUserVoltHistory(
              this.userVoltHistory.concat(response.historyData)
            );
            this.setUserVoltHistoryTotalCount(response.totalCount);
          }
        );
      }
    }
  };
}

export default new VoltStore();
