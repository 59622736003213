import { SearchSortType } from "../../constants/Search";
import { Category, Tab } from "../../constants/Article.enum";
import { UploadFile } from "../file/model";
import { FeedWriteProduct } from "../post/model";

export interface BrandSubscribeResponse {
  communityId: number;
  communityName: string;
  communityLogoUrl?: string | null;
  todayArticleCreatedYn: string;
}

export interface ArticleListVO {
  communityId: number;
  articleId: number;
  articleTypeCode: string;
  articleTypeName: string;
  articleTitle: string;
  articleContents: string;
  articlePreviewContents: string;
  articleDisclosureYn: string;
  articleTopFixYn: string;
  articleLikeCount: number;
  articleViewCount: number;
  articleCommentCount: number;
  articleFileGroupId: string;
  filePath: string;
  thumbnailFilePath: string;
  fileCount: number;
  nickname: string;
  profileUrl: string;
  createdDatetime: string;
  createMemberUuid: string;
  updatedDatetime: string;
}

export interface GetArticleListRequest {
  communityId: number;
  query?: string;
  category?: Category;
  tab?: Tab;
  articleTopFixYn?: string;
  sort?: SearchSortType;
  pageSize?: number;
  pageIndex?: number;
}

export interface GetArticleListResponse {
  totalCount: number;
  list: ArticleListVO[];
}
export interface BrandRecommendResponse {
  communityId: number;
  communityName: string;
  communityLogoUrl: string | null;
  communityIntroduce: string;
  subscriptionYn: string;
  brandHomeBannerImagePath?: string;
}
export interface BrandCommunityLineUpResponse {
  communityId: number;
  communityName: string;
  communityLogoUrl: string | null;
  communityIntroduce: string;
  communitySubscriberCount: string;
  communityArticleCount: string;
  communityProductCount: string;
  subscriptionYn: string;
}
export enum BrandLineUpSortType {
  LATEST = "dateDESC", //최신순
  POPULAR = "popularityDESC", //인기순
  ACCURACY = "accuracyDESC", // 관련순
}
export enum BrandListTabType {
  TOTAL = "TOTAL", //전체
  SUBSCRIBING = "SUBSCRIBING", //구독중
}

export interface BrandCommunityPopularArticleResponse {
  articleId: number;
  communityId: number;
  communityName: string;
  articleTypeCode: string;
  articleTypeName: null | string;
  articleTitle: string;
  articleContents: string;
  createdDatetime: string;
  fileCount: number;
  commentCount: number;
  articleLikeCount: number;
  articleViewCount: number;
  nickname: string;
  thumbnailFilePath?: string;
  filePath?: string;
  sortOrder: number;
}
export interface BrandCommunityHomeInfoResponse {
  communityId: number;
  memberId: null | string;
  subscriptionYn: string;
  communityName: string;
  communityLogoUrl: null | string;
  communityIntroduce: string;
  communitySubscriberCount: number;
  communityArticleCount: number;
  communityProductCount: number;
  communityShopLink?: null | string;
  communityMallKeyword?: string;
  corpMemberYn?: string;
}

export enum TabTypeCode {
  POST = "post",
  QNA = "qna",
  AB = "ab",
}

export interface FeedItem {
  feedId: number;
  feedTitle: string;
  thumbnailFilePath: string;
  originalFilePath: null | string;
  nickname: string;
  feedTypeCode: string;
  feedStatusCode: string;
  imageCount: number;
  videoCount: number;
  createdDatetime: string;
  subscribeYn: string;
  commentCount: number;

  adesc?: string;
  afilePath?: string;
  athumbnailFilePath?: string;
  bdesc?: string;
  bfilePath?: string;
  bthumbnailFilePath?: string;
  feedEndDate?: string;
  feedStartDate?: string;
  abFlag?: string;
  processStatus?: string;
  voterCount?: string;
  feedContents?: string;
}

export interface SearchProductResultResponse {
  totalCount: number;
  list?: SearchProductItem[];
  query?: string;
}

export interface SearchProductItem {
  productId?: number;
  productName?: string;
  productModelId?: string;
  productModelName?: string;
  brandId?: number;
  brandNameKor?: string;
  brandNameEng?: string;
  productFileGroupId: number;
  originalFilePath?: string;
  thumbnailFilePath?: string;
  lifecareProductId?: number;
  manufacturerNameKor?: string;
  myProductId?: number;
}
