import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_small.svg";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Spacings, Texts } from "../../../assets/styles";
import { observer } from "mobx-react";
import { SearchSortType } from "../../../constants/Search";
import { useTranslation } from "react-i18next";
import BrandCommunityProductListStore from "../../../store/BrandCommunityProductListStore";

const BrandCommunityProductListFilter = observer(() => {

  const { t } = useTranslation();

  const { header_option, header_check_btn_txt, header_sort_btn, header_sort_btn_txt } = styles;

  const { sort, totalCount, showSortBox, setShowSortBox } = BrandCommunityProductListStore;

  return (
    <>
      <div style={header_option}>
        <p style={header_check_btn_txt}>{t("screen.brandCommunity.label.totalNum", { totalNum: totalCount })}</p>
        {totalCount !== undefined && totalCount !== 0 && (
          <button
            style={header_sort_btn}
            onClick={() => setShowSortBox(!showSortBox)}
          >
            <p style={header_sort_btn_txt}>
              {sort === SearchSortType.POPULAR
                ? t("screen.brandCommunity.sortType.popular")
                : t("screen.brandCommunity.sortType.latest")}
            </p>
            <ArrowDown />
          </button>
        )}
      </div>
    </>
  );
});

const styles: Styles = {
  wrapper: { ...Colors.background_fff },

  header_option: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Arranges.between,
    ...Colors.background_fff,
    ...Spacings.padding_left_right_20,
    ...Spacings.padding_top_bottom_16,
  },
  header_check_btn_txt: {
    ...Texts.btn_text_1,
    ...Texts.font_weight_500,
    ...Colors.font_222,
    ...Spacings.margin_left_6,
  },
  header_sort_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  header_sort_btn_txt: {
    ...Texts.btn_text_1,
    ...Colors.font_666666,
    ...Spacings.margin_right_6,
  },
};

export default BrandCommunityProductListFilter;
