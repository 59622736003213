import { SuccessOrNot } from "../../constants/Common.enum";
import { Category } from "../../constants/Qna.enum";
import { SearchSortType } from "../../constants/Search";
import QnaListStore from "../../store/QnaListStore";
import { callAPIWithDELETEMethod, callAPIWithGETMethod, callAPIWithPOSTMethod } from "../common/api";
import WriteCommonResponse from "../post/model";
import { GetQnaListRequest, GetQnaListResponse, Qna, QnaWriteRequest, RecommendUserResponse } from "./model";

export const getRecommendUser = async (productIds?: number[], isLoading = true): Promise<RecommendUserResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/recommendation/answerers",
      params: productIds,
    },
    isLoading
  );

  return (response?.successOrNot === SuccessOrNot.Y ? response?.data : null) as RecommendUserResponse;
};

export const saveQna = async (qnaItem: QnaWriteRequest, isLoading = true): Promise<WriteCommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/feed/qna",
      body: qnaItem,
    },
    isLoading
  );
};

export const getQnaDetail = async (feedId: number, isLoading = true): Promise<Qna | string> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/feed/qna/${feedId}`,
    },
    isLoading
  );

  return (response?.successOrNot === SuccessOrNot.Y ? response?.data : response.statusCode) as Qna | string;
};

export const deleteQna = async (feedIds: number[], isLoading = true): Promise<boolean> => {
  const response = await callAPIWithDELETEMethod(
    {
      url: "/v1/feed/qna",
      body: { feedIds },
    },
    isLoading
  );

  return (response?.successOrNot === SuccessOrNot.Y ? response?.data : false) as boolean;
};

export const getRecentlyQnaList = async (
  pageSize: number,
  pageIndex: number,
  isLoading = true
): Promise<Qna[] | string> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/feed/qnas?pageSize=${pageSize}&pageIndex=${pageIndex}`,
    },
    isLoading
  );

  return (response?.successOrNot === SuccessOrNot.Y ? response?.data?.list : null) as Qna[] | string;
};

export const getInterestingQnaList = async (
  pageSize: number,
  pageIndex: number,
  memberUuid: string,
  isLoading = true
): Promise<Qna[] | string> => {
  const response = await callAPIWithGETMethod(
    {
      url: `/v1/feed/interestedQnas?pageSize=${pageSize}&pageIndex=${pageIndex}&memberUuid=${memberUuid}`,
    },
    isLoading
  );

  return (response?.successOrNot === SuccessOrNot.Y ? response?.data?.list : null) as Qna[] | string;
};

export const getQnaList = async (
  getQnaListRequest: GetQnaListRequest,
  isLoading = true
): Promise<GetQnaListResponse> => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/feed/qnas/total",
      params: {
        topics: getQnaListRequest.topics?.join(),
        categories: getQnaListRequest.categories?.join(),
        sort: getQnaListRequest.sort,
        waitingAnswerOnly: getQnaListRequest.waitingAnswerOnly,
        pageSize: getQnaListRequest.pageSize,
        pageIndex: getQnaListRequest.pageIndex,
      },
    },
    isLoading
  );
  return response?.successOrNot === SuccessOrNot.Y ? response?.data : response.statusCode;
};
