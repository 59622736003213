import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import './i18n'
import { receiveDeeplinkInfo } from './native/deeplink'
import { clickNotification, receiveNotification } from './native/notification'
import { RouterProvider } from 'react-router-dom'
import router from './Router'
import WebViewInterface from './native/WebViewInterface'
import ReactModal from 'react-modal'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
ReactModal.setAppElement('#root')
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

declare global {
  interface Window {
    androidCallbacks: AndroidCallbacks
  }
}
interface AndroidCallbacks {
  [callbackKey: string]: any
}
window.androidCallbacks = {}
;(window as any).receiveDeeplinkInfo = receiveDeeplinkInfo
;(window as any).receiveNotification = receiveNotification
;(window as any).clickNotification = clickNotification
;(window as any).ReactNativeWebView = WebViewInterface
