import {
  EventProperty,
  TrackFunction,
  HandleLoginFunction,
  HandleLogoutFunction,
  SetUserPropertyFunction,
  UpdateUserInfoFunction,
} from './types'
import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  FC,
  RefObject,
  ReactNode,
  useRef,
} from 'react'
import { TrackerRef } from './TrackFunction'
import { useFirebaseAnalytics, useMixpanel, useFacebook } from './service'
import dayjs from 'dayjs'
import { logBranch } from './service/branch'
import { use220DataScience } from './service/220ds'
import { use220BackEnd } from './service/220backend'
import { Location, useLocation } from 'react-router-dom'

interface ITrackHandler {
  handle?: TrackFunction
  setUserProperty?: SetUserPropertyFunction
  updateUserInfo?: UpdateUserInfoFunction
  handleLogin?: HandleLoginFunction
  handleLogout?: HandleLogoutFunction
}

const TrackHandleContext = createContext<ITrackHandler>({})

export const useTrackHandler = () => useContext(TrackHandleContext)

const notEmpty = (value?: string) =>
  value !== undefined && value !== null && value !== '' && value !== 'NA'

const usePreviousLocation = (value: Location) => {
  const ref = useRef<Location>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const TrackingHandlerProvider: FC<{
  trackerRef?: TrackerRef
  children?: ReactNode
}> = ({ trackerRef, children }) => {
  const { log: logFirebase } = useFirebaseAnalytics()
  const { log: logMixpanel, mixPanel } = useMixpanel(
    process.env.REACT_APP_MIXPANEL_TOKEN
  )
  const { log: logFacebook } = useFacebook()
  const { log: log220Ds } = use220DataScience()
  const { log: log220BackEnd } = use220BackEnd()
  const location = useLocation()
  const previousLocation = usePreviousLocation(location)

  const setUserProperty: SetUserPropertyFunction = useCallback(
    (key, value) => {
      if (!key || !value) return

      mixPanel?.people.set(key, value)
    },
    [mixPanel]
  )

  // TODO : ga 추가
  const handleLogin: HandleLoginFunction = useCallback(
    (memberUuid: string) => {
      if (!memberUuid) return

      mixPanel?.identify(memberUuid)
      // .catch(undefined)
    },
    [mixPanel]
  )

  const handleLogout: HandleLogoutFunction = useCallback(() => {
    mixPanel?.reset()
  }, [mixPanel])

  const updateUserInfo: UpdateUserInfoFunction = useCallback(
    (userInfo) => {
      if (!userInfo) return

      if (mixPanel) {
        notEmpty(userInfo.nickname) &&
          mixPanel.people.set('nickname', userInfo.nickname)
        notEmpty(userInfo.genderCode) &&
          mixPanel.people.set('gender', userInfo.genderCode)
        notEmpty(userInfo.birthYear) &&
          mixPanel.people.set('birthYear', userInfo.birthYear)
        notEmpty(userInfo.createdDateTime) &&
          mixPanel.people.set(
            'signup_date',
            dayjs(userInfo.createdDateTime).format('YYYY-MM-DD')
          )
      }
    },
    [mixPanel]
  )

  const handle: TrackFunction = useCallback(
    (event, properties) => {
      const commonProps: EventProperty = {}

      commonProps['source_screen'] = location.pathname
      commonProps['source_screen_from'] = previousLocation?.pathname
      // commonProps["source"] = `${commonProps["source_screen"] || ""}|${
      //   commonProps["source_component"] || ""
      // }`;

      const mergedProperties = {
        ...commonProps,
        ...properties,
      }

      switch (event) {
        case 'complete_signup':
          logFirebase(event, mergedProperties)
          logMixpanel(event, mergedProperties)
          log220Ds(event, mergedProperties)
          // logMoengage(event, mergedProperties);
          logFacebook(event, mergedProperties)
          // logAppsFyler(event, mergedProperties);
          logBranch(event, mergedProperties)
          break
        case 'view_post_detail':
        case 'complete_mission':
        case 'complete_upload_post':
          logBranch(event, mergedProperties)
          break
        /* falls through */
        case 'view_home':
        case 'complete_login':
          logFirebase(event, mergedProperties)
          logMixpanel(event, mergedProperties)
          log220Ds(event, mergedProperties)
          // logMoengage(event, mergedProperties);
          logFacebook(event, mergedProperties)
          log220BackEnd(event, mergedProperties)
          break
        case 'view_post_page':
        case 'view_qna_page':
        case 'view_mission_page':
        case 'view_magazine_page':
        case 'submit_mission':
        case 'click_creation_button':
        case 'create_post':
        case 'click_main_banner':
        case 'upload_post':
        case 'create_qna':
        case 'upload_qna':
        case 'create_ab':
        case 'upload_ab':
        case 'click_follow_button':
        case 'click_search_button':
          logFirebase(event, mergedProperties)
          logMixpanel(event, mergedProperties)
          log220Ds(event, mergedProperties)
          break
        case 'view_signup_page':
        case 'click_signup':
        case 'start_onboarding':
        case 'complete_onboarding':
        case 'view_qna_detail':
        case 'view_ab_detail':
        case 'view_magazine_detail':
        case 'click_like_button':
        case 'click_share_button':
        case 'complete_comment':
        case 'click_bookmark':
        case 'complete_upload_qna':
        case 'complete_upload_ab':
        case 'view_mission_detail':
          logFirebase(event, mergedProperties)
          logMixpanel(event, mergedProperties)
          log220Ds(event, mergedProperties)
          logFacebook(event, mergedProperties)
          break
        case 'skip_signup':
          logMixpanel(event, mergedProperties)
          log220Ds(event, mergedProperties)
          logFacebook(event, mergedProperties)
          break
        case 'scroll_main_banner':
        case 'change_post_tab':
        case 'edit_post':
        case 'delete_post':
        case 'edit_qna':
        case 'delete_qna':
        case 'edit_ab':
        case 'delete_ab':
        case 'click_morepost_button':
        case 'view_faq_list':
        case 'click_faq_detail':
        case 'click_popular_post':
        case 'view_ranking_page':
        case 'view_product_detail':
        case 'click_regist_my_product':
        case 'complete_regist_my_product':
        case 'click_imagefilter_button':
        case 'complete_imagefilter_button':
        case 'click_product_info':
        case 'click_myhome_bookmark':
        case 'post_expose':
        case 'complete_agree_term':
        case 'complete_onboarding_step1':
        case 'complete_onboarding_step2':
        case 'complete_onboarding_step3':
        case 'complete_onboarding_step4':
        case 'complete_onboarding_step5':
        case 'view_220mall_page': // 라이프케어 화면 이벤트 태깅
        case 'click_view_category_item':
        case 'click_mall_popularitem':
        case 'click_mall_popularitem_post':
        case 'click_mall_pickitem':
        case 'click_mall_curation':
        case 'click_mall_categorybest':
        case 'click_mall_category':
        case 'click_mall_productdetail':
        case 'click_mall_banner':
        case 'click_mall_search':
        case 'click_mall_orderdetails':
        case 'click_mall_allow_agreement':
        case 'click_main_quick_menu':
        case 'click_push_allow_button':
        case 'click_alarm_detail':
        case 'click_mission_complete_next_button':
          logMixpanel(event, mergedProperties)
          log220Ds(event, mergedProperties)
          // logMoengage(event, mergedProperties);
          break
        case 'click_withdraw_button':
          logBranch(event, mergedProperties)
          break
        /* falls through */
        case 'click_withdraw_before_button':
          logMixpanel(event, mergedProperties)
          log220Ds(event, mergedProperties)
          logFirebase(event, mergedProperties)
          break
        case 'view_my_volt':
        case 'view_friend_recommend':
        case 'complete_auto_login':
          log220BackEnd(event, mergedProperties)
          break
        case 'view_signup_page_v2':
        case 'click_main_signup_v2':
        case 'click_other_signup_v2':
        case 'skip_signup_v2':
        case 'click_second_signup_v2':
        case 'complete_agree_term_v2':
        case 'click_onboarding_step1_gender':
        case 'complete_onboarding_step1_v2':
        case 'complete_onboarding_step2_v2':
          logMixpanel(event, mergedProperties)
          log220BackEnd(event, mergedProperties)
          break
      }
    },
    [
      location.pathname,
      previousLocation?.pathname,
      logFirebase,
      logMixpanel,
      log220Ds,
      logFacebook,
      log220BackEnd,
    ]
  )

  useEffect(() => {
    if (trackerRef) {
      trackerRef.current = {
        track: handle,
        setUserProperty,
        updateUserInfo,
        handleLogin,
        handleLogout,
      }
    }
  }, [
    trackerRef,
    handle,
    setUserProperty,
    updateUserInfo,
    handleLogin,
    handleLogout,
  ])

  return (
    <TrackHandleContext.Provider
      value={{ handle, setUserProperty, handleLogin, handleLogout }}
    >
      {children}
    </TrackHandleContext.Provider>
  )
}
