import React from "react";
import { observer } from "mobx-react";
import TitleHeader from "../../../components/common/TitleHeader";
import { HeaderItem } from "../../../constants/Header";
import { ReactComponent as ImgVolt } from "../../../assets/images/pop_benefit_volt.svg";
import { ReactComponent as Icon1volt } from "../../../assets/icons/220mall_1volt.svg";
import { ReactComponent as Icon3volt } from "../../../assets/icons/220mall_3volt.svg";
import { ReactComponent as Icon5volt } from "../../../assets/icons/icon_5volt.svg";
import { ReactComponent as Icon10volt } from "../../../assets/icons/icon_10volt.svg";
import { ReactComponent as IconQuestionVolt } from "../../../assets/icons/icon_volt_question.svg";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import './BenefitPop.scss'

const BenefitPop = observer(
  ({
    closeBtnClick,
    modalShow,
  }: {
    closeBtnClick: any;
    modalShow: boolean;
  }) => {
    const { t } = useTranslation();
    return (
      //   <Modal animationType="fade" transparent={true} visible={modalShow}>
      <Modal
        isOpen={modalShow}
        style={{
          overlay: {
            zIndex: 200,
          },
          content: {
            border: 'unset',
            margin: 0,
            padding: 0,
            display: "flex",
            height: "100%",
            width: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          },
        }}
      >
        <div
          id="benefitPop"
        >
          <TitleHeader
            title={t("screen.volt.label.voltInfo")}
            isBack={false}
            rightItem={HeaderItem.CLOSE}
            onClickClose={closeBtnClick}
          />
          <div
            className="bx_content_container"
          >
            <div className="bx_content_wrap">
              <ImgVolt />
              <div className="txt_st1">
                <span className="txt_st1_bold">
                  {t("screen.volt.label.voltInfoMessageDetail1")}
                </span>
                {t("screen.volt.label.voltInfoMessageDetail1-1")}
                <span className="txt_st1_bold">
                  {t("screen.volt.label.voltInfoMessageDetail2")}
                </span>
                {t("screen.volt.label.voltInfoMessageDetail3")}
                <span className="txt_st1_bold">
                  {t("screen.volt.label.voltInfoMessageDetail4")}
                </span>
              </div>
              <div className="txt_title_strong">
                {t("screen.volt.label.voltGetInfoMessage")}
              </div>

              <div className="bx_info_benefit">
                <Icon1volt />
                <div className="txt_info_title">
                  {t("screen.volt.label.appAttendanceStampGuide")}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.appAttendanceStampDetail")}
                  <span className="txt_info_cont_bold">
                    {t("screen.volt.label.nVolt", { voltAmount: 1 })}
                  </span>{" "}
                  {t("screen.volt.label.accumulate")}
                </div>
                <div className="txt_info_title">
                  {t("screen.volt.label.answerQnaGuide")}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.answerQnaDetail1")}
                  <span className="txt_info_cont_bold">
                    {t("screen.volt.label.nVolt", { voltAmount: 1 })}
                  </span>{" "}
                  {t("screen.volt.label.accumulate")}{" "}
                  {t("screen.volt.label.aDaybLimit", { day: 1, limit: 5 })}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.answerQnaDetail2")}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.answerQnaDetail3")}
                </div>
                <div className="txt_info_title">
                  {t("screen.volt.label.registMyApplianceGuide")}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.registMyApplianceDetail")}
                  <span className="txt_info_cont_bold">
                    {t("screen.volt.label.nVolt", { voltAmount: 1 })}
                  </span>{" "}
                  {t("screen.volt.label.accumulate")}
                  {t("screen.volt.label.registMyApplianceDetail1")}
                </div>
              </div>

              <div className="bx_info_benefit">
                <Icon3volt />
                <div className="txt_info_title">
                  {t("screen.volt.label.writeArticleGuide")}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.writeArticleDetail1")}
                  <span className="txt_info_cont_bold">
                    {t("screen.volt.label.nVolt", { voltAmount: 3 })}
                  </span>{" "}
                  {t("screen.volt.label.accumulate")}
                </div>
                <div className="txt_info_cont">
                  {t("screen.volt.label.writeArticleDetail2")}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.writeArticleDetail3")}
                  <span className="txt_info_cont_bold">
                    {t("screen.volt.label.nVolt", { voltAmount: 2 })}
                  </span>{" "}
                  {t("screen.volt.label.accumulate")}
                </div>
              </div>

              <div className="bx_info_benefit">
                <Icon10volt />
                <div className="txt_info_title">
                  {t("screen.volt.label.appMemberRegistTitle")}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.appMemberRegistDetail")}
                  <span className="txt_info_cont_strong">
                    {t("screen.volt.label.nVolt", { voltAmount: 10 })}
                  </span>{" "}
                  {t("screen.volt.label.accumulateImmediately")}
                </div>

                <div className="txt_info_title">
                  {t("screen.volt.label.inviteFriendsTitle")}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.inviteFriendsDetail")}
                  <span className="txt_info_cont_strong">
                    {t("screen.volt.label.nVolt", { voltAmount: 10 })}
                  </span>{" "}
                  {t("screen.volt.label.accumulate")}{" "}
                  {t("screen.volt.label.1month10personLimit")}
                </div>
              </div>

              <div className="bx_info_benefit">
                <IconQuestionVolt />
                <div className="txt_info_title">
                  {t("screen.volt.label.missionParticipateTitle")}
                </div>
                <div className="txt_info_cont">
                  {"\u2022"}
                  {t("screen.volt.label.missionParticipateDetail")}
                  <span style={{ fontWeight: "700" }}>
                    {t("screen.volt.label.volt")}{" "}
                    {t("screen.volt.label.accumulate")}
                  </span>
                </div>
                {/* <div className="txt_info_title">{t("screen.volt.label.mallBuyTitle")}</div>
              <div className="txt_info_cont">
                {"\u2022"}
                {t("screen.volt.label.mallBuyDetail1")}
                <div style={{ fontWeight: "700" }}>{t("screen.volt.label.mallBuyDetail2")}</div>
              </div>
              <div className="txt_info_cont">{t("screen.volt.label.mallBuyDetail3")}</div> */}
              </div>
            </div>

            <div className="line_bar" />

            <div className="bx_notice">
              <div className="in_notice">
                <div className="txt_noti_title">
                  {t("screen.volt.label.noticeTitle")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail1")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail4")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail2")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail3")}
                </div>
                <div className="txt_noti_cont">
                  {"\u2022"}
                  {t("screen.volt.label.noticeDetail6")}
                </div>

                {/* <div className="txt_noti_cont">
                {"\u2022"}
                {t("screen.volt.label.noticeDetail5")}
                <div style={{ fontWeight: "700" }}>{t("screen.volt.label.noticeDetail5_1")}</div>
                {t("screen.volt.label.noticeDetail5_2")}
                {t("screen.volt.label.noticeDetail5_3")}
                <div style={{ fontWeight: "700" }}>{t("screen.volt.label.noticeDetail5_4")}</div>
                {t("screen.volt.label.noticeDetail5_5")}
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);

export default BenefitPop;
