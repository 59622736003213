import React, { useEffect, useState } from "react";
import { SimilarUserItem } from "../../../../api/account/model";
import CommonStore from "../../../../store/CommonStore";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../../constants/ActHistory";
import { getSimilarUsers } from "../../../../api/account/api";
import FastImageWithFallback from "../../../../components/common/FastImageWithFallback";
import { Arranges, Colors, Spacings, Texts } from "../../../../assets/styles";
import { Styles } from "../../../../assets/types/Style";
import CustomActivityIndicatorSmall from "../../../../components/common/CustomActivityIndicatorSmall";
import Slider, { Settings } from "react-slick";
import { flex } from "../../../../assets/styles/flexBoxs";
import { goTo } from "../../../../hooks/navigate/NavigateFunction";

const PostRecommendUser = ({ userId }: { userId: string }) => {
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const [similarUsers, setSimilarUsers] = useState([] as SimilarUserItem[]);

  const sliderSettings: Settings = {
    infinite: false,
    arrows: false,
  };

  const moveToAccount = (
    // uuid: string,
    // index: number,
    nickname: string
    // profileUrl: string,
    // profileBackgroundColor: string
  ) => {
    // TODO: ActHistory 활성화
    // CommonStore.callActHistory(
    //   ActHistoryTypeCode.USER_CLICK,
    //   uuid,
    //   ActPageCode.POST,
    //   ActSectionCode.SIMILAR_USER,
    //   index.toString()
    // );
    goTo(`/user/${nickname}`);
  };

  useEffect(() => {
    const getSimilarUser = async () => {
      const res = await getSimilarUsers({
        memberUuid: userId,
        maxSimilarMemberCount: 3,
      });
      if (res !== null) setSimilarUsers(res.similarMemberList);
    };
    void getSimilarUser();
    return () => setSimilarUsers([]);
  }, [userId]);

  const renderRecommendUsers = (item: {
    item: SimilarUserItem;
    index: number;
  }) => {
    return (
      //   <div style={item.index ? styles.recommend_user_item : styles.first_item}>
      <div style={styles.recommend_user_item}>
        <div
          onClick={() => moveToAccount(item.item.nickname)}
          aria-label={`Recommend user${item.index}`}
        >
          <FastImageWithFallback
            style={styles.recommend_user_image}
            source={
              item.item.profileUrl !== undefined &&
              item.item.profileUrl !== null
                ? {
                    uri: `${PUBLIC_BUCKET_URL}${item.item.profileUrl}`,
                  }
                : require("../../../../assets/images/fall_back_image.png")
            }
          />
        </div>
        <div style={styles.recommend_user_text2} aria-label="User nickname">
          {item.item.nickname}
        </div>
      </div>
    );
  };

  return (
    <div
      id="post-recomment-user-wrap"
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "16px",
        marginTop: "20px",
      }}
    >
      {/* <Slider {...sliderSettings}> */}
      {similarUsers ? (
        similarUsers.slice(0, 3).map((item, index) => {
          return renderRecommendUsers({ item, index });
        })
      ) : (
        <CustomActivityIndicatorSmall />
      )}
      {/* </Slider> */}
    </div>
    // <FlatList
    //   data={similarUsers.slice(0, 3)}
    //   renderItem={renderRecommendUsers}
    //   horizontal={true}
    //   showsHorizontalScrollIndicator={false}
    //   bounces={false}
    //   alwaysBounceHorizontal={false}
    //   scrollToOverflowEnabled={true}
    //   overScrollMode={"never"}
    //   ListEmptyComponent={
    //     <CustomActivityIndicatorSmall style={styles.more_loader} />
    //   }
    // />
  );
};

export default PostRecommendUser;

const styles: Styles = {
  recommend_user_image: {
    width: 64,
    height: 64,
    borderRadius: 32,
  },
  recommend_users_text1: {
    ...Spacings.padding_left_16_responsive,
    ...Spacings.margin_bottom_10,
    ...Texts.contents_text_28,
    ...Texts.font_weight_500,
    ...Colors.font_333,
  },
  recommend_user_item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  recommend_user_text2: {
    ...Texts.contents_text_5,
    ...Arranges.text_align_center,
    ...Spacings.margin_top_4,
    width: 56,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  more_loader: {
    ...Spacings.margin_top_16,
  },
};
