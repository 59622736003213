import { observer } from "mobx-react-lite";
import { Post } from "../../../api/feed/model";
import PopularPostItem from "../presenter/PopularPostItem";
import { useCallback, useEffect, useState } from "react";
import MainStore from "../../../store/MainStore";
import { useTracker } from "../../../hooks/tracker";
import { getPopularFeedDetailList } from "../../../api/feed/api";
import { useTranslation } from "react-i18next";
import "./PopularPost.scss";
import Slider, { Settings } from "react-slick";
import { goTo } from "../../../hooks/navigate/NavigateFunction";

const PopularPost = observer(() => {
  const { track } = useTracker();
  const [isClickItem, setIsClickItem] = useState(true);
  const { popularPostsList, setPopularPostsList } = MainStore;
  const [offset, setOffset] = useState(0);
  const [isDataEnded, setDataEnded] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const dataLength = popularPostsList?.length || 0;
    const dataEnded = dataLength < 10;
    setDataEnded(dataEnded);
    setOffset(dataEnded ? 0 : 1);
  }, [popularPostsList]);

  const handlePopularPostPress = useCallback(
    (post: Post, targetIndex: number) => () => {
      track("click_popular_post", {
        content_id: post.feedId,
        content_title: post.feedTitle,
        content_order: targetIndex,
      });

      goTo("/FeedScreen", {
        state: {
          itemIndex: targetIndex,
          feedParentTypeCode: "MAINTAB_POPULAR_POSTS",
        },
      });
    },
    [track]
  );

  const onEndReached = () => {
    if (isDataEnded) return;

    void getPopularFeedDetailList(10, offset).then((response) => {
      const list = response.data?.feedFindVOList;
      const length = list?.length || 0;
      setPopularPostsList([...popularPostsList, ...(list as Post[])]);
      if (length < 10) {
        setDataEnded(true);
      } else {
        setOffset(offset + 1);
      }
    });
  };

  const settings: Settings = {
    infinite: false,
    swipeToSlide: true,
    arrows: false,
    dots: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    focusOnSelect: true,
    beforeChange: () => {
      setIsClickItem(false);
    },
    afterChange: () => {
      setTimeout(() => {
        setIsClickItem(true);
      }, 100);
    },
  };

  return (
    <div id="PopularPost" className="popular_post">
      <div className="title_wrap">
        <div className="title_content">
          <div className="popular_post_title_text">
            {t(`screen.main.label.popularPost`)}
          </div>
        </div>
      </div>
      <div className="popular_post_list">
        <Slider {...settings}>
          {popularPostsList &&
            popularPostsList.map((item: any, index: number) => {
              const post = item;
              return (
                <PopularPostItem
                  key={`popularPost ${post.feedId}`}
                  onPress={
                    isClickItem ? handlePopularPostPress(post, index) : () => {}
                  }
                  post={post}
                  index={index}
                  mainListIndex={index}
                />
              );
            })}
        </Slider>
      </div>
    </div>
  );
});

export default PopularPost;
