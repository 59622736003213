import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Colors, FlexBoxs } from "../../assets/styles";
import AuthStore from "../../store/AuthStore";
import QnaStore from "../../store/QnaStore";
import QnaMain from "./presenter/QnaMain";
import MainLayout from "../main/MainLayout";

const QnaScreen = observer(function QnaScreen(data: any) {
  const { qnaMainStore } = QnaStore;

  useEffect(() => {
    const { sessionUser, tempMember } = AuthStore;

    const memberUuid = sessionUser?.memberUuid
      ? sessionUser?.memberUuid
      : tempMember?.tempMemberUUID;

    const { initList } = qnaMainStore;

    void initList(memberUuid ?? "");
  }, [data, qnaMainStore]);

  return (
    <MainLayout>
      <div style={{ ...FlexBoxs.flex_1, ...Colors.background_255 }}>
        <QnaMain />
      </div>
    </MainLayout>
  );
});

export default QnaScreen;
