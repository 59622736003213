import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";

import { AbSearchCondition } from "../../../api/ab/model";
import { useEffectOnlyOnce } from "../../../hooks/UseEffectOnlyOnce";
import AbStore, { PAGE_SIZE } from "../../../store/AbListStore";
import { Virtuoso } from "react-virtuoso";

import AbItem from "./AbListItem";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";

const MyAbListView = observer(() => {
  const {
    myAbs,
    retrieveMyAbList,
    myAbLoadingStatus,
    setMyAbLoadingStatus,
    extraDataforMyAbListView,
    setNewExtraDataforMyAbListView,
    clear,
  } = AbStore;
  const [searchCondition, setSearchCondition] = useState<AbSearchCondition>({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });
  const { t } = useTranslation();

  const [showTopButton, setShowTopButton] = useState<boolean>(false);

  const ref = useRef(null);

  const handleRefresh = () => {
    setMyAbLoadingStatus("REFRESHING");
    setSearchCondition((prev) => {
      return { ...prev, pageIndex: 0 };
    });
  };

  const handleFlatListEndReached = () => {
    if (myAbLoadingStatus === "LOADED") {
      setSearchCondition((prev) => {
        return { ...prev, pageIndex: prev.pageIndex + 1 };
      });
    }
  };

  useEffect(() => {
    void retrieveMyAbList(searchCondition);
  }, [retrieveMyAbList, searchCondition]);

  useEffect(() => {
    setNewExtraDataforMyAbListView();
  }, [setNewExtraDataforMyAbListView]);

  useEffectOnlyOnce(undefined, () => {
    clear();
  });

  return (
    <>
      {myAbs.length > 0 ? (
        <div className="contents">
          {myAbs.map((ab: any, colIndex: number) => {
            return <AbItem index={colIndex} ab={ab} key={ab.feedId} />;
          })}
          <InfiniteScrollObserver onObserver={handleFlatListEndReached} />
          <div style={{ height: 60, backgroundColor: "transparent" }} />
        </div>
      ) : (
        <div className="nothing_search_img">
          <div className="empty_wrap">
            <p className="empty_text">{t("screen.ab.label.emptySearch")}</p>
          </div>
        </div>
      )}

      {/* <Virtuoso
        className="contents"
        data={myAbs}
        itemContent={(index, item) => {
          return <AbItem index={index} ab={item} key={item.feedId} />;
        }}
        useWindowScroll
        endReached={handleFlatListEndReached}
        components={{
          EmptyPlaceholder: () => (
            <div className="nothing_search_img">
              <div className="empty_wrap">
                <p className="empty_text">{t("screen.ab.label.emptySearch")}</p>
              </div>
            </div>
          ),
          Footer: () => (
            <div style={{ height: 60, backgroundColor: "transparent" }} />
          ),
        }}
      /> */}
      {/* <FlatList
        windowSize={3}
        showsVerticalScrollIndicator={false}
        ref={ref}
        style={styles.contents}
        data={myAbs}
        extraData={extraDataforMyAbListView}
        renderItem={({ item, index }) => {
          return <AbItem index={index} ab={item} key={item.feedId} />;
        }}
        ListEmptyComponent={() => (
          <View style={styles.nothing_search_img}>
            <View style={styles.empty_wrap}>
              <Text style={styles.empty_text}>
                {t("screen.ab.label.emptySearch")}
              </Text>
            </View>
          </View>
        )}
        onEndReachedThreshold={0.5}
        onEndReached={handleFlatListEndReached}
        refreshing={myAbLoadingStatus === "REFRESHING"}
        onRefresh={handleRefresh}
        onScroll={(event) =>
          handleTopButtonScroll(
            event,
            () => {
              setShowTopButton(true);
            },
            () => {
              setShowTopButton(false);
            },
            ref
          )
        }
      /> */}
    </>
  );
});

export default MyAbListView;
