import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CancelIcon } from "../../../assets/icons/cancel.svg";
import RecommenderStore from "../../../store/RecommenderStore";
import classes from "./RecommenderSection.module.scss";

type RecommenderSectionProps = {
  inputRef?: React.MutableRefObject<any> | undefined;
  onSubmitEditing?: () => void;
};

const RecommenderSection = observer((props: RecommenderSectionProps) => {
  const { t } = useTranslation();

  const {
    recommenderNickname,
    setRecommenderNickname,
    resetRecommenderNickname,
    isRecommenderNicknameValid,
    recommenderValidStatusCode,
    isRecommenderNicknameComplete,
  } = RecommenderStore;

  const changeRecommenderNickName = (nickname: string) => {
    void setRecommenderNickname(nickname);
  };

  const renderRecommendNicknameGuideComponent = () => {
    if (!isRecommenderNicknameValid) {
      return (
        <div className={classes.alert_wrap}>
          <span className={classes.alert_text}>
            {t("screen.preference.myInfo.recommender.message.guide")}
          </span>
        </div>
      );
    } else if (recommenderValidStatusCode === "RECOMMEND_NICKNAME_NOT_EXIST") {
      return (
        <div className={classes.alert_wrap}>
          <span className={classes.alert_text}>
            {t(
              "screen.preference.myInfo.recommender.message.recommendNicknameNotExistGuide1"
            )}
          </span>
          <span className={classes.alert_text}>
            {t(
              "screen.preference.myInfo.recommender.message.recommendNicknameNotExistGuide2"
            )}
          </span>
        </div>
      );
    } else if (recommenderValidStatusCode === "ALREADY_REGISTERED") {
      return (
        <div className={classes.alert_wrap}>
          <span className={classes.alert_text}>
            {t(
              "screen.preference.myInfo.recommender.message.alreadyRegisteredGuide"
            )}
          </span>
        </div>
      );
    } else if (recommenderValidStatusCode === "EXIST_DUPLICATION_DATA") {
      return (
        <div className={classes.alert_wrap}>
          <span className={classes.alert_text}>
            {t(
              "screen.preference.myInfo.recommender.message.existDuplicationDataGuide"
            )}
          </span>
        </div>
      );
    } else if (isRecommenderNicknameComplete) {
      return <></>;
    }
  };

  return (
    <>
      <div className={classes.info_item}>
        <span className={classes.recommender_label}>
          {t("screen.preference.myInfo.recommender.title")}
        </span>
        <div className={classes.recommender_input_wrap}>
          <input
            ref={props.inputRef}
            className={classes.recommender_input}
            onChange={(e) => changeRecommenderNickName(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                props.onSubmitEditing && props.onSubmitEditing();
              }
            }}
            value={recommenderNickname}
            placeholder={t(
              "screen.preference.myInfo.recommender.message.placeholder"
            )}
            aria-label={`recommender nickname`}
            autoCapitalize={"none"}
            autoComplete={"off"}
            // autoCorrect={false}
            // returnKeyType={"next"}
            maxLength={15}
          />

          {recommenderNickname.length > 0 && (
            <button
              className={classes.cancel_btn}
              onClick={resetRecommenderNickname}
            >
              <CancelIcon />
            </button>
          )}
        </div>
        {recommenderNickname.length > 0 &&
          renderRecommendNicknameGuideComponent()}
      </div>
    </>
  );
});

export default RecommenderSection;
