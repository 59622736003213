import { useCallback } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import CustomBottomSheet from "./CustomBottomSheet";
import { useTranslation } from "react-i18next";
import classes from "./BirthYearBottomSheet.module.scss";
import clsx from "clsx";

export interface BirthYearBottomSheetProps {
  open: boolean;
  selectedYear: string | null;
  onSelect: (year: string) => void;
  onClose: () => void;
}

const START_YEAR = 1900;
const JOIN_POSSIBLE_MIN_AGE = 14;

const getBirthYearList = () => {
  const years: { key: string; value: string }[] = [];
  let currentYear: number = new Date().getFullYear() - JOIN_POSSIBLE_MIN_AGE;
  while (START_YEAR <= currentYear) {
    years.push({ key: `${currentYear}`, value: `${currentYear}년` });
    currentYear--;
  }
  return years;
};

const BirthYearBottomSheet = ({
  open,
  selectedYear,
  onSelect,
  onClose,
}: BirthYearBottomSheetProps) => {
  const { t } = useTranslation();

  const renderYear = useCallback(
    (selectedYear: string | null) =>
      ({
        item,
        index,
      }: {
        item: { key: string; value: string };
        index: number;
      }) => {
        return (
          <div className={classes.year_item} key={item.key}>
            <button
              onClick={() => {
                onSelect(item.key);
                onClose && onClose();
              }}
            >
              <span
                className={clsx(
                  classes.year_text,
                  item.key === selectedYear && classes.selected_year_text
                )}
              >
                {item.value}
              </span>
            </button>
          </div>
        );
      },
    [onSelect, onClose]
  );

  return (
    <CustomBottomSheet open={open} onClose={onClose} snapHeight={366} modalContentStyles={{
      padding: "unset",
    }}>
      <div aria-label={`birthYear selector`} className={classes.year_sheet}>
        <button className={classes.close_btn} onClick={onClose}>
          <CloseIcon />
        </button>
        <div className={classes.year_title_wrap}>
          <span className={classes.year_title}>
            {t("screen.profileUpdate.birthYear.placeholder")}
          </span>
        </div>
        <div className={classes.year_list}>
          {getBirthYearList().map((year, index) => {
            return renderYear(selectedYear)({ item: year, index });
          })}
        </div>
      </div>
    </CustomBottomSheet>
  );
};

BirthYearBottomSheet.displayName = "BirthYearBottomSheet";
export default BirthYearBottomSheet;
