import axios from "axios";
import CommonStore from "../../store/CommonStore";
import { t } from "i18next";
import CommonResponse from "../http";
import { callAPIWithPOSTMethod } from "../common/api";

const REDIRECT_PATH = "/auth";

function generateState() {
  return Math.random().toString(36).substring(2, 15);
}

export const naverAuthorize = () => {
  const state = generateState();
  const url = `https://nid.naver.com/oauth2.0/authorize?client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&response_type=code&redirect_uri=${window.location.origin}${REDIRECT_PATH}&state=${state}`;
  window.location.href = url;
};

export const getNaverToken = async (code: string, state: string) => {
  try {
    const res = await axios.get(
      `naver/oauth2.0/token?client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&client_secret=${process.env.REACT_APP_NAVER_CLIENT_SECRET}&grant_type=authorization_code&state=${state}&code=${code}&redirect_uri=${window.location.origin}${REDIRECT_PATH}`,
      {
        method: "GET",
      }
    );

    return res.data.access_token;
  } catch (err) {
    return undefined;
  }
};

export const getNaverUser = async (accessToken: string) => {
  try {
    const res = await axios.get("naverApi/v1/nid/me", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data.response;
  } catch (err) {
    return { id: "" };
  }
};

export const naverUnlink = async () => {
  try {
    const accessToken = await localStorage.getItem("NAVER_ACCESSTOKEN");
    const res = await axios.get(
      `naver/oauth2.0/token?grant_type=delete&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&client_secret=${process.env.REACT_APP_NAVER_CLIENT_SECRET}&access_token=${accessToken}`,
      {
        method: "GET",
      }
    );
    return res.data;
  } catch (err) {
    CommonStore.setToastOption({
      show: true,
      message1: t("screen.social.naverError1"),
      message2: t("screen.social.naverError2"),
    });
  }
};

export const naverSocialLogin = async (
  code: string,
  state: string,
  isLoading = true
): Promise<CommonResponse> => {
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/session/naverSocialLogin",
      body: {
        clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
        clientSecret: process.env.REACT_APP_NAVER_CLIENT_SECRET,
        grantType: "authorization_code",
        state,
        code,
      },
    },
    isLoading
  );
};

export const naverSocialDelete = async (
  isLoading = true
): Promise<CommonResponse> => {
  const accessToken = await localStorage.getItem("NAVER_ACCESSTOKEN");
  return await callAPIWithPOSTMethod(
    {
      url: "/v1/session/naverSocialLogin",
      body: {
        clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
        clientSecret: process.env.REACT_APP_NAVER_CLIENT_SECRET,
        grantType: "delete",
        accessToken: accessToken,
      },
    },
    isLoading
  );
};
