export enum SearchTabMenu {
  Brand = "brand-community",
  BrandArticle = "brand-community-article",
  Main = "main",
  Product = "product",
  Post = "post",
  Poster = "poster",
  Qna = "qna",
  Tag = "tag",
  Total = "total",
}

export enum SearchSortType {
  LATEST = "dateDESC",
  POPULAR = "popularityDESC",
  ACCURACY = "accuracyDESC",
}

export enum AutoCompleteWhat {
  DEFAULT = "default",
  PRODUCT = "product",
  TAG = "tag",
}

export enum RankingChangeCode {
  STAY = "STAY",
  NEW = "NEW",
  UP = "UP",
  DOWN = "DOWN",
}

export const SEARCH_RESULT_COUNT = 20;

export const BLANK_TOTAL_RESULT = {
  productList: [],
  productCount: 0,
  postList: [],
  postCount: 0,
  brandCommunityList: [],
  brandCommunityCount: 0,
  brandCommunityArticleList: [],
  brandCommunityArticleCount: 0,
  qnaList: [],
  qnaCount: 0,
  posterList: [],
  posterCount: 0,
  tagList: [],
  tagCount: 0,
};
