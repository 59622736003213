import React from "react";
import { useTranslation } from "react-i18next";

import { SortType } from "../../../api/ab/model";
import "./AbListFilter.scss";

interface AbListFilterProps {
  value?: SortType;
  onChange: (sortType: SortType) => void;
}
const AbListFilter = ({ value, onChange }: AbListFilterProps) => {
  const handleChange = (sortType: SortType) => {
    onChange(sortType);
  };
  const { t } = useTranslation();

  return (
    <div className="sort_btn_wrap">
      <div
        // disabled={value === "Latest"}
        onClick={() => {
          handleChange("Latest");
        }}
        className={value === "Latest" ? "sort_active_btn" : "sort_btn"}
        aria-label="latest"
      >
        <p
          className={
            value === "Latest" ? "sort_btn_active_text" : "sort_btn_text"
          }
        >
          {t("screen.ab.button.latest")}
        </p>
      </div>
      <div
        // disabled={value === "Dday"}
        onClick={() => {
          handleChange("Dday");
        }}
        className={value === "Dday" ? "sort_active_btn" : "sort_btn"}
        aria-label="dday"
      >
        <p
          className={
            value === "Dday" ? "sort_btn_active_text" : "sort_btn_text"
          }
        >
          {t("screen.ab.button.dday")}
        </p>
      </div>
    </div>
  );
};

export default AbListFilter;
