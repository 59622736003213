import React, { CSSProperties, useCallback, useRef } from 'react'

import { ReactComponent as Magazine } from '../../assets/icons/main_banner_badge_magazine.svg'
import { ReactComponent as Mission } from '../../assets/icons/main_banner_badge_mission.svg'
import { ReactComponent as Post } from '../../assets/icons/main_banner_badge_post.svg'
import { ReactComponent as Qna } from '../../assets/icons/main_banner_badge_qna.svg'
import { ReactComponent as Ab } from '../../assets/icons/main_banner_badge_ab.svg'
import { ReactComponent as Myhome } from '../../assets/icons/main_banner_badge_myhome.svg'
import { ReactComponent as Product } from '../../assets/icons/main_banner_badge_product.svg'
import { ReactComponent as Ranking } from '../../assets/icons/main_banner_badge_ranking.svg'
import { BannerInfo } from '../../api/banner/model'
import { ContentType } from '../../constants/Banner.enum'
import FastImageWithFallback from './FastImageWithFallback'
import classes from './CommonBanner.module.scss'

const LineBannerImage = ({
  banner,
  index,
  showTitleDim,
  showTitleIndicator,
  height,
  onPress,
  titleTextStyle,
  textPositionStyle,
  imageSizeStyle,
}: {
  banner: BannerInfo
  index: number
  showTitleDim?: boolean
  showTitleIndicator?: boolean
  height?: string | number
  onPress: (index: number, banner: BannerInfo) => void
  titleTextStyle?: CSSProperties
  textPositionStyle?: CSSProperties
  imageSizeStyle?: CSSProperties
}) => {
  return (
    <div
      onClick={() => {
        onPress(index, banner)
      }}
      style={{ overflow: 'clip', position: 'relative' }}
    >
      <div>
        <FastImageWithFallback
          wrapperStyle={{ width: '100%', height: height ?? 0 }}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          source={{
            uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${banner.filePath}`,
          }}
          aria-label={`banner image${index}`}
        />
      </div>
      {showTitleIndicator && (
        <div>
          <div className={classes.top_img_text_wrap}>
            {/* TODO YY 사용확인  {bannerFlag()} */}

            {showTitleDim ? (
              // 텍스트 dim필요시 사용
              <div
                className={classes.gradient_box}
                style={{ ...textPositionStyle }}
              >
                <div
                  aria-label={`banner title${index}`}
                  className={classes.title}
                >
                  <span
                    className={classes.title_text}
                    style={titleTextStyle ? titleTextStyle : {}}
                  >
                    {banner.bannerMainTitle ?? ''}
                  </span>
                </div>
              </div>
            ) : (
              <div
                className={classes.gradient_box}
                style={{ ...textPositionStyle }}
              >
                <div
                  aria-label={`banner title${index}`}
                  className={classes.title}
                >
                  <span
                    className={classes.title_text}
                    style={titleTextStyle ? titleTextStyle : {}}
                  >
                    {banner.bannerMainTitle ?? ''}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default LineBannerImage
