import { SuccessOrNot } from "../../constants/Common.enum";
import { callAPIWithPOSTMethod } from "../common/api";
import {
  FileUrlRequest,
  FileUrlResponse,
  PreSignedUrlRequestForAllPath,
  PreSignedUrlResponseForAllPath,
} from "./model";

export const getFilePreSignedUrl = async (
  data: FileUrlRequest,
  isLoading = true
): Promise<FileUrlResponse[]> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/file/presignedUrl",
      body: data,
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as FileUrlResponse[];
};

export const getPreSignedUrlForAllPath = async (
  data: PreSignedUrlRequestForAllPath,
  isLoading = true
): Promise<PreSignedUrlResponseForAllPath> => {
  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/file/presignedUrl/allpath",
      body: data,
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y && response?.statusCode === "OK"
      ? response?.data
      : null
  ) as PreSignedUrlResponseForAllPath;
};
