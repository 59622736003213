// 이벤트 핸들러 타입 정의
type EventCallback = (data: any) => void

// EventBus 클래스 정의
class EventBus {
  private events: { [key: string]: EventCallback[] } = {}

  subscribe(event: string, callback: EventCallback) {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(callback)
  }

  unsubscribe(event: string, callback: EventCallback) {
    if (!this.events[event]) return
    this.events[event] = this.events[event].filter(
      (existingCallback) => existingCallback !== callback
    )
  }

  publish(event: string, data: any) {
    if (!this.events[event]) return
    this.events[event].forEach((callback) => callback(data))
  }
}

const eventBus = new EventBus()
export default eventBus
