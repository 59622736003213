import React, { useState, useEffect, useRef, useMemo } from "react";
// import { useSafeAreaInsets } from "react-native-safe-area-context";
import { observer } from "mobx-react-lite";
import CustomBottomSheet from "../../../components/common/CustomBottomSheet";
import MainStore from "../../../store/MainStore";
import TabStore from "../../../store/TabStore";
import CommonStore from "../../../store/CommonStore";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { parseQueryParam } from "../../../utils/queryParam";
import { BannerInfo, BannerLinkInfo } from "../../../api/banner/model";
import { ContentType, LinkType } from "../../../constants/Banner.enum";
import { openInAppBrowser } from "../../../utils/common";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { POPUP_BANNER } from "../../../constants/Storage";
import AuthStore from "../../../store/AuthStore";
import CustomCarousel from "../../../components/common/CustomCarousel";
import Slider, { Settings } from "react-slick";
import "./MainPopupBanner.scss";
import PostDetailStore from "../../../store/PostDetailStore";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import clsx from "clsx";

const MainPopupBanner = observer(
  ({
    selfWidth,
    onCloseAction,
  }: {
    selfWidth?: number;
    onCloseAction: () => void | undefined;
  }) => {
    // const insets = useSafeAreaInsets();
    // const bottomInset = insets.bottom / PixelRatio.get();

    const [index, setIndex] = useState<number>(0);
    const { popupBannerList, setMainPopupBannerIndex } = MainStore;
    const { showBottomSheet, setShowBottomSheet } = MainStore;
    const { setTabBarDisplay } = TabStore;

    const innnerHeightRef = useRef<HTMLDivElement | null>(null);

    // const carouselHeight = responsiveScreenHeight(29.7);
    // const carouselWidth = responsiveScreenWidth(100);
    const { t } = useTranslation();
    const { sessionUser } = AuthStore;

    const handleBannerPress = (
      bannerLinkInfo: BannerLinkInfo,
      index: number
    ) => {
      if (bannerLinkInfo.linkType === LinkType.INTERNAL) {
        switch (bannerLinkInfo.contentType) {
          case ContentType.POST:
            // CommonStore.navigate("PostDetailScreen", {
            //   feedId: bannerLinkInfo.contentId,
            //   postIndex: index,
            // });
            goTo(`/posts/feed/${bannerLinkInfo.contentId}`);
            break;
          case ContentType.MAGAZINE:
            goTo(`/magazine/${bannerLinkInfo.contentId}`);
            break;
          case ContentType.MISSION:
            // sessionUser?.memberUuid
            //   ? goTo("/MissionDetail", {
            //       state: {
            //         missionId: bannerLinkInfo.contentId,
            //       },
            //     })
            //   : MainStore.setShowJoinModal(true);
            goTo("/MissionDetail", {
              state: {
                missionId: bannerLinkInfo.contentId,
              },
            });
            break;
          default:
            break;
        }
      } else if (bannerLinkInfo.linkType === LinkType.EXTERNAL) {
        void openInAppBrowser(bannerLinkInfo.linkUrl || "");
      } else {
        //
      }
      setShowBottomSheet(false);
    };

    const renderBannerImage = ({
      item,
      index,
    }: {
      item: BannerInfo;
      index: number;
    }) => {
      const bannerLinkInfo = parseQueryParam(item.bannerLinkParameter);

      return (
        <>
          <div
            onClick={() => {
              handleBannerPress(bannerLinkInfo, index);
            }}
          >
            <FastImageWithFallback
              className="MainPopupBanner_image"
              style={{ width: "100%", aspectRatio: 3 / 2, objectFit: "cover" }}
              source={{
                uri: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}${item?.filePath}`,
              }}
            />
          </div>
        </>
      );
    };

    const notShowToday = async () => {
      const today = dayjs().format("YYYYMMDD");
      const popupItemStr = JSON.stringify({ yn: "N", lastDay: today });
      await localStorage.setItem(POPUP_BANNER, popupItemStr);
      setShowBottomSheet(false);
    };

    const onCloseBottomSheet = () => {
      setShowBottomSheet(false);
      onCloseAction && onCloseAction();
    };

    const handleAfterChange = (currentSlide: number) => {
      setIndex(currentSlide);
    };

    const zerosWithDataSize = useMemo(() => {
      return new Array(popupBannerList?.length || 0).fill(0);
    }, [popupBannerList?.length]);

    const settings = {
      infinite: false,
      afterChange: handleAfterChange,
    };

    return (
      <div id="MainPopupBanner">
        <CustomBottomSheet
          open={showBottomSheet}
          onClose={onCloseBottomSheet}
          // snapHeight={innnerHeightRef.current?.clientHeight || 350}
          // enableDynamicSizing
          // handleComponent={() => <></>}
          usePadding={false}
        >
          <div
            className="MainPopupBanner_bottom_sheet_view"
            ref={innnerHeightRef}
          >
            <div
              className="MainPopupBanner_carousel_list_wrap"
              style={{ width: selfWidth }}
            >
              {/* <CustomCarousel
              accessibilityLabel={"main popup banner carousel"}
              itemWidth={selfWidth}
              data={popupBannerList}
              renderItem={renderBannerImage}
              onSnapToItem={(idx) => setMainPopupBannerIndex(idx)}
              // showTextIndicator
              // textIndexContainerStyle={styles.carousel_idx}
              // textIndexStyle={styles.carousel_idx_text}
              showDotIndicator
              // dotContainerStyle={styles.carousel_pagination_container}
              // dotStyle={styles.carousel_pagination_active}
              // inactiveDotStyle={styles.carousel_pagination_inactive}
            /> */}

              <Slider {...settings}>
                {popupBannerList &&
                  popupBannerList.map((item: any, index: number) => {
                    return renderBannerImage({ item, index });
                  })}
              </Slider>

              {(popupBannerList || []).length > 1 && (
                <div className="MainPopupBanner_dot_indicator">
                  {zerosWithDataSize.map((_item, idx) => {
                    const isFocused = idx === index;
                    return (
                      <div
                        className={clsx(
                          "MainPopupBanner_dot",
                          isFocused
                            ? "MainPopupBanner_dot_active"
                            : "MainPopupBanner_dot_inactive"
                        )}
                        key={`carousel-dot-${idx}`}
                      />
                    );
                  })}
                </div>
              )}
            </div>

            <div className="MainPopupBanner_footer" style={{ height: 52 }}>
              <div className="MainPopupBanner_footer_btns">
                <div
                  onClick={() => {
                    void notShowToday();
                  }}
                >
                  <div className="MainPopupBanner_footer_text1">
                    {t("screen.main.button.notShowToday")}
                  </div>
                </div>
                <div onClick={onCloseBottomSheet}>
                  <div className="MainPopupBanner_footer_text2">
                    {t("screen.main.button.close")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CustomBottomSheet>
      </div>
    );
  }
);

export default MainPopupBanner;
