import { Filter } from 'konva/lib/Node'
import { clamp } from '../../utils/number'

export class CustomFilters {
  public static _1977 = (imageData: ImageData) => {
    const data = imageData.data
    for (let i = 0; i < data.length; i += 4) {
      data[i] = data[i] * 1.1 + 50 // Red
      data[i + 1] = data[i + 1] * 0.9 + 20 // Green
      data[i + 2] = data[i + 2] * 0.85 + 50 // Blue
    }
  }

  public static Temprature = (temperature: number): Filter => {
    return (imageData) => {
      const data = imageData.data
      const t = temperature / 100

      // Adjust RGB values based on temperature
      for (let i = 0; i < data.length; i += 4) {
        let r = data[i]
        let g = data[i + 1]
        let b = data[i + 2]

        if (t <= 66) {
          r = 255
          g = t
          g = 99.4708025861 * Math.log(g) - 161.1195681661
        } else {
          r = t - 60
          r = 329.698727446 * Math.pow(r, -0.1332047592)
          g = t - 60
          g = 288.1221695283 * Math.pow(g, -0.0755148492)
        }

        if (t >= 66) {
          b = 255
        } else {
          if (t <= 19) {
            b = 0
          } else {
            b = t - 10
            b = 138.5177312231 * Math.log(b) - 305.0447927307
          }
        }

        data[i] = clamp(r, 0, 255)
        data[i + 1] = clamp(g, 0, 255)
        data[i + 2] = clamp(b, 0, 255)
      }
    }
  }

  public static Aden = (imageData: ImageData) => {
    const data = imageData.data
    const len = data.length

    for (let i = 0; i < len; i += 4) {
      // 밝기 조정
      data[i] = data[i] + 15 // Red
      data[i + 1] = data[i + 1] + 15 // Green
      data[i + 2] = data[i + 2] + 15 // Blue

      // 채도 감소
      const r = data[i]
      const g = data[i + 1]
      const b = data[i + 2]
      const avg = (r + g + b) / 3
      const desaturation = 0.85 // 채도 감소 인자

      data[i] = avg + (r - avg) * desaturation
      data[i + 1] = avg + (g - avg) * desaturation
      data[i + 2] = avg + (b - avg) * desaturation

      // 색조 추가
      data[i] = data[i] * 0.9 // Red 감소
      data[i + 1] = data[i + 1] * 1.1 // Green 증가
      data[i + 2] = data[i + 2] * 1.1 // Blue 증가
    }
  }

  public static Willow = (imageData: ImageData) => {
    const data = imageData.data
    const len = data.length

    for (let i = 0; i < len; i += 4) {
      // 흑백 변환
      const avg = 0.299 * data[i] + 0.587 * data[i + 1] + 0.114 * data[i + 2]
      data[i] = avg // Red
      data[i + 1] = avg // Green
      data[i + 2] = avg // Blue

      // 대비 증가
      const contrast = 1.1 // 대비 인자
      data[i] = (data[i] - 128) * contrast + 128
      data[i + 1] = (data[i + 1] - 128) * contrast + 128
      data[i + 2] = (data[i + 2] - 128) * contrast + 128

      // 약간의 색조 추가
      data[i] = data[i] * 0.9 + 20 // Red
      data[i + 1] = data[i + 1] * 0.9 + 20 // Green
      data[i + 2] = data[i + 2] * 1.1 // Blue
    }
  }

  public static Walden = (imageData: ImageData) => {
    const data = imageData.data

    for (let i = 0; i < data.length; i += 4) {
      // 밝기 증가
      data[i] = Math.min(255, data[i] + 20) // Red
      data[i + 1] = Math.min(255, data[i + 1] + 20) // Green
      data[i + 2] = Math.min(255, data[i + 2] + 20) // Blue

      // 대비 증가
      const contrastFactor = 1.2
      const avg = 128
      data[i] = (data[i] - avg) * contrastFactor + avg
      data[i + 1] = (data[i + 1] - avg) * contrastFactor + avg
      data[i + 2] = (data[i + 2] - avg) * contrastFactor + avg

      // 채도 증가
      const r = data[i]
      const g = data[i + 1]
      const b = data[i + 2]
      const max = Math.max(r, g, b)
      const min = Math.min(r, g, b)
      const l = (max + min) / 2
      const s =
        max === min
          ? 0
          : l > 0.5
          ? (max - min) / (2.0 - max - min)
          : (max - min) / (max + min)
      const satFactor = 1.1

      data[i] = r + (r - l) * s * satFactor
      data[i + 1] = g + (g - l) * s * satFactor
      data[i + 2] = b + (b - l) * s * satFactor

      // 녹색 색조 추가
      data[i] = data[i] * 0.9 // Red
      data[i + 1] = data[i + 1] * 1.1 // Green
      data[i + 2] = data[i + 2] * 0.9 // Blue
    }
  }

  public static Valencia = (imageData: ImageData) => {
    const data = imageData.data

    for (let i = 0; i < data.length; i += 4) {
      // 밝기 증가
      data[i] = Math.min(255, data[i] + 15) // Red
      data[i + 1] = Math.min(255, data[i + 1] + 15) // Green
      data[i + 2] = Math.min(255, data[i + 2] + 15) // Blue

      // 색상 조정
      data[i] = data[i] * 1.08 // Red 증가
      data[i + 2] = data[i + 2] * 0.88 // Blue 감소

      // 채도 증가
      const r = data[i]
      const g = data[i + 1]
      const b = data[i + 2]

      const avg = (r + g + b) / 3
      data[i] = r + (r - avg) * 0.1
      data[i + 1] = g + (g - avg) * 0.1
      data[i + 2] = b + (b - avg) * 0.1
    }
  }

  public static Brannan = (imageData: ImageData) => {
    const data = imageData.data
    for (let i = 0; i < data.length; i += 4) {
      // Brightness and contrast adjustments
      data[i] = Math.min(255, data[i] * 1.1 + 20) // Red
      data[i + 1] = Math.min(255, data[i + 1] * 1.1 + 20) // Green
      data[i + 2] = Math.min(255, data[i + 2] * 1.05 + 10) // Blue
    }
  }

  public static XPro2 = (imageData: ImageData) => {
    const data = imageData.data
    const width = imageData.width
    const height = imageData.height

    // 색상 조정 및 대비 증가
    for (let i = 0; i < data.length; i += 4) {
      data[i] = Math.min(255, data[i] * 1.2) // Red
      data[i + 1] = Math.min(255, data[i + 1] * 1.1) // Green
      data[i + 2] = Math.min(255, data[i + 2] * 0.9) // Blue
    }

    // 비네팅 효과 추가
    const centerX = width / 2
    const centerY = height / 2
    const maxDistance = Math.sqrt(centerX * centerX + centerY * centerY)

    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const offset = (y * width + x) * 4
        const distance = Math.sqrt((x - centerX) ** 2 + (y - centerY) ** 2)
        const vignettingFactor = 1 - (distance / maxDistance) * 0.5

        data[offset] *= vignettingFactor // Red
        data[offset + 1] *= vignettingFactor // Green
        data[offset + 2] *= vignettingFactor // Blue
      }
    }
  }

  public static MayFair = (imageData: ImageData) => {
    const data = imageData.data
    const brightness = 0.15 // 기본 밝기 증가
    const contrast = 0.2 // 기본 대비 증가
    const saturation = 0.3 // 기본 채도 증가
    const warmth = 0.25 // 기본 따뜻한 색조 추가

    for (let i = 0; i < data.length; i += 4) {
      // RGB 값 가져오기
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // 밝기 조정
      r += brightness * 255
      g += brightness * 255
      b += brightness * 255

      // 대비 조정
      r = ((r / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      g = ((g / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      b = ((b / 255 - 0.5) * (contrast + 1) + 0.5) * 255

      // 채도 조정
      const gray = 0.3 * r + 0.59 * g + 0.11 * b
      r = r + saturation * (gray - r)
      g = g + saturation * (gray - g)
      b = b + saturation * (gray - b)

      // 따뜻한 색조 추가
      r += warmth * 30
      g += warmth * 15

      // 값 제한
      data[i] = Math.min(255, Math.max(0, r))
      data[i + 1] = Math.min(255, Math.max(0, g))
      data[i + 2] = Math.min(255, Math.max(0, b))
    }
  }

  public static Maven = (imageData: ImageData) => {
    const data = imageData.data
    const contrast = 0.2 // 기본 대비 증가
    const brightness = 0.05 // 기본 밝기 약간 조정
    const blueTint = 0.1 // 기본 파란색 색조 추가

    for (let i = 0; i < data.length; i += 4) {
      // RGB 값 가져오기
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // 대비 조정
      r = ((r / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      g = ((g / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      b = ((b / 255 - 0.5) * (contrast + 1) + 0.5) * 255

      // 밝기 조정
      r += brightness * 255
      g += brightness * 255
      b += brightness * 255

      // 파란색 색조 추가
      b += blueTint * 50

      // 값 제한
      data[i] = Math.min(255, Math.max(0, r))
      data[i + 1] = Math.min(255, Math.max(0, g))
      data[i + 2] = Math.min(255, Math.max(0, b))
    }
  }

  public static Moon = (imageData: ImageData) => {
    const data = imageData.data
    const contrast = 1.2 // 기본 대비 증가 (흑백 필터에서 대조를 극대화)
    const brightness = -0.1 // 기본 밝기 감소
    const blueTint = 0.05 // 기본 파란색 색조 추가

    for (let i = 0; i < data.length; i += 4) {
      // RGB 값 가져오기
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // 그레이스케일 변환 (흑백)
      const gray = 0.3 * r + 0.59 * g + 0.11 * b

      // 대비 조정
      let newGray = ((gray / 255 - 0.5) * (contrast + 1) + 0.5) * 255

      // 밝기 조정
      newGray += brightness * 255

      // 파란색 색조 추가
      b = newGray + blueTint * 255

      // 값 제한
      data[i] = Math.min(255, Math.max(0, newGray))
      data[i + 1] = Math.min(255, Math.max(0, newGray))
      data[i + 2] = Math.min(255, Math.max(0, b))
    }
  }

  public static Nashville = (imageData: ImageData) => {
    const data = imageData.data
    const contrast = 0.15 // Increase contrast
    const redTint = 0.2 // Add red hue
    const desaturation = 0.1 // Slight desaturation
    const highlightBoost = 0.05 // Brighten highlights

    for (let i = 0; i < data.length; i += 4) {
      // Get RGB values
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Convert to grayscale for desaturation
      const gray = 0.3 * r + 0.59 * g + 0.11 * b

      // Apply desaturation
      r = r + (gray - r) * desaturation
      g = g + (gray - g) * desaturation
      b = b + (gray - b) * desaturation

      // Increase contrast
      r = ((r / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      g = ((g / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      b = ((b / 255 - 0.5) * (contrast + 1) + 0.5) * 255

      // Add red tint
      r += redTint * 50
      b -= redTint * 20 // Reduce blue slightly

      // Boost highlights
      r += highlightBoost * 255
      g += highlightBoost * 255
      b += highlightBoost * 255

      // Limit values to the range [0, 255]
      data[i] = Math.min(255, Math.max(0, r))
      data[i + 1] = Math.min(255, Math.max(0, g))
      data[i + 2] = Math.min(255, Math.max(0, b))
    }
  }
  public static Perpetua = function (imageData: ImageData) {
    const data = imageData.data
    const contrast = 0.15 // Increase contrast
    const warmTint = 0.2 // Add warm color tint
    const vignette = 0.2 // Add vignette effect

    const width = imageData.width
    const height = imageData.height

    for (let i = 0; i < data.length; i += 4) {
      // Get RGB values
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Increase contrast
      r = ((r / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      g = ((g / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      b = ((b / 255 - 0.5) * (contrast + 1) + 0.5) * 255

      // Add warm color tint
      r += warmTint * 50
      g += warmTint * 20

      // Apply vignette effect
      const centerX = width / 2
      const centerY = height / 2
      const dx = ((i / 4) % width) - centerX
      const dy = Math.floor(i / 4 / width) - centerY
      const distance = Math.sqrt(dx * dx + dy * dy)
      const vignetteFactor = Math.max(
        0,
        1 - (distance / (Math.min(width, height) / 2)) * vignette
      )

      r *= vignetteFactor
      g *= vignetteFactor
      b *= vignetteFactor

      // Limit values to the range [0, 255]
      data[i] = Math.min(255, Math.max(0, r))
      data[i + 1] = Math.min(255, Math.max(0, g))
      data[i + 2] = Math.min(255, Math.max(0, b))
    }
  }
  public static Reyes = function (imageData: ImageData) {
    const data = imageData.data
    const desaturation = 0.2 // Desaturation amount
    const warmTint = 0.1 // Warm color tint
    const highlightReduction = 0.1 // Highlight reduction

    for (let i = 0; i < data.length; i += 4) {
      // Get RGB values
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Convert to grayscale for desaturation
      const gray = 0.3 * r + 0.59 * g + 0.11 * b

      // Apply desaturation
      r = gray + (r - gray) * (1 - desaturation)
      g = gray + (g - gray) * (1 - desaturation)
      b = gray + (b - gray) * (1 - desaturation)

      // Add warm color tint
      r += warmTint * 30
      g += warmTint * 10

      // Reduce highlights
      const highlightFactor = 1 - highlightReduction
      if (r > 200) r *= highlightFactor
      if (g > 200) g *= highlightFactor
      if (b > 200) b *= highlightFactor

      // Limit values to the range [0, 255]
      data[i] = Math.min(255, Math.max(0, r))
      data[i + 1] = Math.min(255, Math.max(0, g))
      data[i + 2] = Math.min(255, Math.max(0, b))
    }
  }
  public static Rise = function (imageData: ImageData) {
    const data = imageData.data
    const contrast = 0.2 // Increase contrast
    const warmTint = 0.15 // Add warm color tint
    const saturationBoost = 0.1 // Slight saturation boost

    for (let i = 0; i < data.length; i += 4) {
      // Get RGB values
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Convert to grayscale for desaturation
      const gray = 0.3 * r + 0.59 * g + 0.11 * b

      // Apply contrast
      r = ((r / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      g = ((g / 255 - 0.5) * (contrast + 1) + 0.5) * 255
      b = ((b / 255 - 0.5) * (contrast + 1) + 0.5) * 255

      // Apply saturation boost
      const newR = gray + (r - gray) * (1 + saturationBoost)
      const newG = gray + (g - gray) * (1 + saturationBoost)
      const newB = gray + (b - gray) * (1 + saturationBoost)

      // Apply warm color tint
      r = newR + warmTint * 40
      g = newG + warmTint * 20
      b = newB

      // Limit values to the range [0, 255]
      data[i] = Math.min(255, Math.max(0, r))
      data[i + 1] = Math.min(255, Math.max(0, g))
      data[i + 2] = Math.min(255, Math.max(0, b))
    }
  }
  public static Slumber = function (imageData: ImageData) {
    const data = imageData.data
    const desaturation = 0.3 // Desaturation amount
    const sepiaTint = 0.3 // Sepia tint strength

    for (let i = 0; i < data.length; i += 4) {
      // Get RGB values
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Convert to grayscale for desaturation
      const gray = 0.3 * r + 0.59 * g + 0.11 * b

      // Apply desaturation
      r = gray + (r - gray) * (1 - desaturation)
      g = gray + (g - gray) * (1 - desaturation)
      b = gray + (b - gray) * (1 - desaturation)

      // Apply sepia tint
      const tr = r * 0.393 + g * 0.769 + b * 0.189
      const tg = r * 0.349 + g * 0.686 + b * 0.168
      const tb = r * 0.272 + g * 0.534 + b * 0.131

      r = tr * sepiaTint + r * (1 - sepiaTint)
      g = tg * sepiaTint + g * (1 - sepiaTint)
      b = tb * sepiaTint + b * (1 - sepiaTint)

      // Apply softening (blur effect simulation)
      if (i > 4) {
        r = (data[i - 4] + r + data[i + 4]) / 3
        g = (data[i - 3] + g + data[i + 5]) / 3
        b = (data[i - 2] + b + data[i + 6]) / 3
      }

      // Limit values to the range [0, 255]
      data[i] = Math.min(255, Math.max(0, r))
      data[i + 1] = Math.min(255, Math.max(0, g))
      data[i + 2] = Math.min(255, Math.max(0, b))
    }
  }
  public static Stinson = function (imageData: ImageData) {
    const data = imageData.data
    const vibrance = 0.2 // Increase color intensity
    const glowAmount = 0.05 // Soft glow amount
    const warmTint = 0.1 // Warm color tint strength

    // Temporary image data for softening
    const softenedData = new Uint8ClampedArray(data.length)

    // Apply vibrance and warm tint
    for (let i = 0; i < data.length; i += 4) {
      // Get RGB values
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Convert to grayscale
      const gray = 0.3 * r + 0.59 * g + 0.11 * b

      // Increase vibrance
      r = gray + (r - gray) * (1 + vibrance)
      g = gray + (g - gray) * (1 + vibrance)
      b = gray + (b - gray) * (1 + vibrance)

      // Apply warm color tint
      r = r + warmTint * 40
      g = g + warmTint * 20
      b = b

      // Store adjusted color
      softenedData[i] = Math.min(255, Math.max(0, r))
      softenedData[i + 1] = Math.min(255, Math.max(0, g))
      softenedData[i + 2] = Math.min(255, Math.max(0, b))
      softenedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Apply soft glow (simple blur effect)
    for (let i = 0; i < data.length; i += 4) {
      const x = (i / 4) % imageData.width
      const y = Math.floor(i / 4 / imageData.width)

      let rTotal = 0,
        gTotal = 0,
        bTotal = 0
      let count = 0

      // Average nearby pixels
      for (let dy = -1; dy <= 1; dy++) {
        for (let dx = -1; dx <= 1; dx++) {
          const nx = x + dx
          const ny = y + dy
          if (
            nx >= 0 &&
            ny >= 0 &&
            nx < imageData.width &&
            ny < imageData.height
          ) {
            const ni = (ny * imageData.width + nx) * 4
            rTotal += softenedData[ni]
            gTotal += softenedData[ni + 1]
            bTotal += softenedData[ni + 2]
            count++
          }
        }
      }

      // Calculate average and apply glow effect
      const index = i
      data[index] = (rTotal / count) * (1 + glowAmount)
      data[index + 1] = (gTotal / count) * (1 + glowAmount)
      data[index + 2] = (bTotal / count) * (1 + glowAmount)
      data[index + 3] = softenedData[i + 3] // Alpha channel
    }
  }
  public static Brooklyn = function (imageData: ImageData) {
    const data = imageData.data
    const coolTint = 0.2 // Cool tint strength
    const vignetteAmount = 0.3 // Vignette effect strength
    const contrastAmount = 0.2 // Soft contrast strength

    // Temporary image data for vignette
    const vignettedData = new Uint8ClampedArray(data.length)

    // Apply cool tint and contrast
    for (let i = 0; i < data.length; i += 4) {
      // Get RGB values
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Convert to grayscale for desaturation
      const gray = 0.3 * r + 0.59 * g + 0.11 * b

      // Apply contrast
      r = ((r / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      g = ((g / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      b = ((b / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Apply cool color tint
      r = r
      g = g
      b = b + coolTint * 50

      // Store adjusted color
      vignettedData[i] = Math.min(255, Math.max(0, r))
      vignettedData[i + 1] = Math.min(255, Math.max(0, g))
      vignettedData[i + 2] = Math.min(255, Math.max(0, b))
      vignettedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Apply vignette effect
    const width = imageData.width
    const height = imageData.height
    const centerX = width / 2
    const centerY = height / 2
    const radius = Math.min(width, height) / 2

    for (let i = 0; i < data.length; i += 4) {
      const x = (i / 4) % width
      const y = Math.floor(i / 4 / width)

      const dx = x - centerX
      const dy = y - centerY
      const distance = Math.sqrt(dx * dx + dy * dy)
      const vignette = Math.max(0, 1 - (distance / radius) * vignetteAmount)

      const index = i
      data[index] = vignettedData[index] * vignette
      data[index + 1] = vignettedData[index + 1] * vignette
      data[index + 2] = vignettedData[index + 2] * vignette
      data[index + 3] = vignettedData[index + 3] // Alpha channel
    }
  }
  public static Earlybird = function (imageData: ImageData) {
    const data = imageData.data
    const warmTint = 0.3 // Warm tint strength
    const contrastAmount = 0.2 // Soft contrast strength
    const vignetteAmount = 0.3 // Vignette effect strength

    // Temporary image data for contrast and warm tint
    const tintedData = new Uint8ClampedArray(data.length)

    // Apply warm tint and contrast
    for (let i = 0; i < data.length; i += 4) {
      // Get RGB values
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Apply contrast
      r = ((r / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      g = ((g / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      b = ((b / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Apply warm color tint
      r = r + warmTint * 50
      g = g + warmTint * 20

      // Store adjusted color
      tintedData[i] = Math.min(255, Math.max(0, r))
      tintedData[i + 1] = Math.min(255, Math.max(0, g))
      tintedData[i + 2] = Math.min(255, Math.max(0, b))
      tintedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Apply vignette effect
    const width = imageData.width
    const height = imageData.height
    const centerX = width / 2
    const centerY = height / 2
    const radius = Math.min(width, height) / 2

    for (let i = 0; i < data.length; i += 4) {
      const x = (i / 4) % width
      const y = Math.floor(i / 4 / width)

      const dx = x - centerX
      const dy = y - centerY
      const distance = Math.sqrt(dx * dx + dy * dy)
      const vignette = Math.max(0, 1 - (distance / radius) * vignetteAmount)

      const index = i
      data[index] = tintedData[index] * vignette
      data[index + 1] = tintedData[index + 1] * vignette
      data[index + 2] = tintedData[index + 2] * vignette
      data[index + 3] = tintedData[index + 3] // Alpha channel
    }
  }
  public static Clarendon = function (imageData: ImageData) {
    const data = imageData.data
    const contrastAmount = 0.3 // Contrast enhancement strength
    const saturationAmount = 0.2 // Saturation boost
    const coolTint = 0.2 // Cool tint strength

    // Temporary image data for contrast, saturation, and tint
    const adjustedData = new Uint8ClampedArray(data.length)

    // Apply contrast, saturation, and cool tint
    for (let i = 0; i < data.length; i += 4) {
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Apply contrast
      r = ((r / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      g = ((g / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      b = ((b / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Convert to HSL for saturation adjustment
      const max = Math.max(r, g, b)
      const min = Math.min(r, g, b)
      const lightness = (max + min) / 2

      // Adjust saturation
      const saturationFactor = 1 + saturationAmount
      r = Math.min(255, lightness + (r - lightness) * saturationFactor)
      g = Math.min(255, lightness + (g - lightness) * saturationFactor)
      b = Math.min(255, lightness + (b - lightness) * saturationFactor)

      // Apply cool color tint
      r = Math.min(255, r)
      g = Math.min(255, g)
      b = Math.min(255, b + coolTint * 50)

      // Store adjusted color
      adjustedData[i] = Math.min(255, Math.max(0, r))
      adjustedData[i + 1] = Math.min(255, Math.max(0, g))
      adjustedData[i + 2] = Math.min(255, Math.max(0, b))
      adjustedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Copy adjusted data back to image data
    for (let i = 0; i < data.length; i++) {
      data[i] = adjustedData[i]
    }
  }
  public static Gingham = function (imageData: ImageData) {
    const data = imageData.data
    const warmTint = 0.3 // Warm tint strength
    const contrastAmount = 0.2 // Soft contrast strength
    const vignetteAmount = 0.4 // Vignette effect strength

    // Temporary image data for contrast, tint, and vignette
    const tintedData = new Uint8ClampedArray(data.length)

    // Apply contrast and warm tint
    for (let i = 0; i < data.length; i += 4) {
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Apply contrast
      r = ((r / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      g = ((g / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      b = ((b / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Apply warm color tint
      r = Math.min(255, r + warmTint * 50)
      g = Math.min(255, g + warmTint * 30)
      b = Math.min(255, b + warmTint * 20)

      // Store adjusted color
      tintedData[i] = Math.min(255, Math.max(0, r))
      tintedData[i + 1] = Math.min(255, Math.max(0, g))
      tintedData[i + 2] = Math.min(255, Math.max(0, b))
      tintedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Apply vignette effect
    const width = imageData.width
    const height = imageData.height
    const centerX = width / 2
    const centerY = height / 2
    const radius = Math.min(width, height) / 2

    for (let i = 0; i < data.length; i += 4) {
      const x = (i / 4) % width
      const y = Math.floor(i / 4 / width)

      const dx = x - centerX
      const dy = y - centerY
      const distance = Math.sqrt(dx * dx + dy * dy)
      const vignette = Math.max(0, 1 - (distance / radius) * vignetteAmount)

      const index = i
      data[index] = tintedData[index] * vignette
      data[index + 1] = tintedData[index + 1] * vignette
      data[index + 2] = tintedData[index + 2] * vignette
      data[index + 3] = tintedData[index + 3] // Alpha channel
    }
  }
  public static Hudson = function (imageData: ImageData) {
    const data = imageData.data
    const warmTint = 0.25 // Warm tint strength
    const contrastAmount = 0.3 // Contrast enhancement strength
    const saturationAmount = -0.2 // Desaturation strength
    const vignetteAmount = 0.3 // Vignette effect strength

    // Temporary image data for adjustments
    const adjustedData = new Uint8ClampedArray(data.length)

    // Apply contrast, tint, and desaturation
    for (let i = 0; i < data.length; i += 4) {
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Apply contrast
      r = ((r / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      g = ((g / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      b = ((b / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Convert to HSL for saturation adjustment
      const max = Math.max(r, g, b)
      const min = Math.min(r, g, b)
      const lightness = (max + min) / 2

      // Adjust saturation
      const saturationFactor = 1 + saturationAmount
      r = Math.min(255, lightness + (r - lightness) * saturationFactor)
      g = Math.min(255, lightness + (g - lightness) * saturationFactor)
      b = Math.min(255, lightness + (b - lightness) * saturationFactor)

      // Apply warm color tint
      r = Math.min(255, r + warmTint * 50)
      g = Math.min(255, g + warmTint * 30)

      // Store adjusted color
      adjustedData[i] = Math.min(255, Math.max(0, r))
      adjustedData[i + 1] = Math.min(255, Math.max(0, g))
      adjustedData[i + 2] = Math.min(255, Math.max(0, b))
      adjustedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Apply vignette effect
    const width = imageData.width
    const height = imageData.height
    const centerX = width / 2
    const centerY = height / 2
    const radius = Math.min(width, height) / 2

    for (let i = 0; i < data.length; i += 4) {
      const x = (i / 4) % width
      const y = Math.floor(i / 4 / width)

      const dx = x - centerX
      const dy = y - centerY
      const distance = Math.sqrt(dx * dx + dy * dy)
      const vignette = Math.max(0, 1 - (distance / radius) * vignetteAmount)

      const index = i
      data[index] = adjustedData[index] * vignette
      data[index + 1] = adjustedData[index + 1] * vignette
      data[index + 2] = adjustedData[index + 2] * vignette
      data[index + 3] = adjustedData[index + 3] // Alpha channel
    }
  }
  public static Inkwell = function (imageData: ImageData) {
    const data = imageData.data
    const contrastAmount = 0.5 // High contrast strength
    const warmTint = 0.2 // Warm tint strength

    // Temporary image data for adjustments
    const grayscaleData = new Uint8ClampedArray(data.length)

    // Convert to grayscale and apply contrast
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i]
      const g = data[i + 1]
      const b = data[i + 2]

      // Convert RGB to grayscale
      const gray = 0.299 * r + 0.587 * g + 0.114 * b

      // Apply contrast
      const contrastedGray =
        ((gray / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Apply warm color tint
      const rTint = Math.min(255, contrastedGray + warmTint * 50)
      const gTint = Math.min(255, contrastedGray + warmTint * 20)

      // Store adjusted color
      grayscaleData[i] = rTint
      grayscaleData[i + 1] = gTint
      grayscaleData[i + 2] = contrastedGray
      grayscaleData[i + 3] = data[i + 3] // Alpha channel
    }

    // Copy adjusted data to imageData
    for (let i = 0; i < data.length; i++) {
      data[i] = grayscaleData[i]
    }
  }
  public static Kelvin = function (imageData: ImageData) {
    const data = imageData.data
    const warmTint = 0.3 // Warm tint strength
    const contrastAmount = 0.3 // Contrast enhancement strength

    // Temporary image data for adjustments
    const adjustedData = new Uint8ClampedArray(data.length)

    // Apply contrast and warm tint
    for (let i = 0; i < data.length; i += 4) {
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Apply contrast
      r = ((r / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      g = ((g / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      b = ((b / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Apply warm color tint
      r = Math.min(255, r + warmTint * 100)
      g = Math.min(255, g + warmTint * 50)

      // Store adjusted color
      adjustedData[i] = Math.min(255, Math.max(0, r))
      adjustedData[i + 1] = Math.min(255, Math.max(0, g))
      adjustedData[i + 2] = Math.min(255, Math.max(0, b))
      adjustedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Copy adjusted data to imageData
    for (let i = 0; i < data.length; i++) {
      data[i] = adjustedData[i]
    }
  }
  public static Lark = function (imageData: ImageData) {
    const data = imageData.data
    const contrastAmount = 0.4 // Contrast enhancement strength
    const warmTint = 0.2 // Warm tint strength

    // Temporary image data for adjustments
    const adjustedData = new Uint8ClampedArray(data.length)

    // Convert to grayscale, apply contrast, and add warm tint
    for (let i = 0; i < data.length; i += 4) {
      const r = data[i]
      const g = data[i + 1]
      const b = data[i + 2]

      // Convert RGB to grayscale
      const gray = 0.299 * r + 0.587 * g + 0.114 * b

      // Apply contrast
      const contrastedGray =
        ((gray / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Apply warm color tint
      const rTint = Math.min(255, contrastedGray + warmTint * 50)
      const gTint = Math.min(255, contrastedGray + warmTint * 20)

      // Store adjusted color
      adjustedData[i] = rTint
      adjustedData[i + 1] = gTint
      adjustedData[i + 2] = contrastedGray
      adjustedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Copy adjusted data to imageData
    for (let i = 0; i < data.length; i++) {
      data[i] = adjustedData[i]
    }
  }
  public static Lofi = function (imageData: ImageData) {
    const data = imageData.data
    const contrastAmount = 0.3 // Contrast enhancement strength
    const desaturationAmount = -0.5 // Desaturation strength
    const vignetteAmount = 0.4 // Vignette effect strength

    // Temporary image data for adjustments
    const adjustedData = new Uint8ClampedArray(data.length)

    // Apply contrast, desaturation, and vignette
    for (let i = 0; i < data.length; i += 4) {
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Apply contrast
      r = ((r / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      g = ((g / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      b = ((b / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Convert to HSL for desaturation
      const max = Math.max(r, g, b)
      const min = Math.min(r, g, b)
      const lightness = (max + min) / 2

      // Adjust saturation
      const saturationFactor = 1 + desaturationAmount
      r = Math.min(255, lightness + (r - lightness) * saturationFactor)
      g = Math.min(255, lightness + (g - lightness) * saturationFactor)
      b = Math.min(255, lightness + (b - lightness) * saturationFactor)

      // Store adjusted color
      adjustedData[i] = Math.min(255, Math.max(0, r))
      adjustedData[i + 1] = Math.min(255, Math.max(0, g))
      adjustedData[i + 2] = Math.min(255, Math.max(0, b))
      adjustedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Apply vignette effect
    const width = imageData.width
    const height = imageData.height
    const centerX = width / 2
    const centerY = height / 2
    const radius = Math.min(width, height) / 2

    for (let i = 0; i < data.length; i += 4) {
      const x = (i / 4) % width
      const y = Math.floor(i / 4 / width)

      const dx = x - centerX
      const dy = y - centerY
      const distance = Math.sqrt(dx * dx + dy * dy)
      const vignette = Math.max(0, 1 - (distance / radius) * vignetteAmount)

      const index = i
      data[index] = adjustedData[index] * vignette
      data[index + 1] = adjustedData[index + 1] * vignette
      data[index + 2] = adjustedData[index + 2] * vignette
      data[index + 3] = adjustedData[index + 3] // Alpha channel
    }
  }
  public static Toaster = function (imageData: ImageData) {
    const data = imageData.data
    const warmTint = 0.3 // Warm tint strength
    const contrastAmount = 0.2 // Mild contrast enhancement
    const desaturationAmount = -0.3 // Slight desaturation

    // Temporary image data for adjustments
    const adjustedData = new Uint8ClampedArray(data.length)

    // Apply contrast, desaturation, and warm tint
    for (let i = 0; i < data.length; i += 4) {
      let r = data[i]
      let g = data[i + 1]
      let b = data[i + 2]

      // Apply contrast
      r = ((r / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      g = ((g / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255
      b = ((b / 255 - 0.5) * (1 + contrastAmount) + 0.5) * 255

      // Convert to HSL for desaturation
      const max = Math.max(r, g, b)
      const min = Math.min(r, g, b)
      const lightness = (max + min) / 2

      // Adjust saturation
      const saturationFactor = 1 + desaturationAmount
      r = Math.min(255, lightness + (r - lightness) * saturationFactor)
      g = Math.min(255, lightness + (g - lightness) * saturationFactor)
      b = Math.min(255, lightness + (b - lightness) * saturationFactor)

      // Apply warm color tint
      r = Math.min(255, r + warmTint * 100)
      g = Math.min(255, g + warmTint * 50)

      // Store adjusted color
      adjustedData[i] = Math.min(255, Math.max(0, r))
      adjustedData[i + 1] = Math.min(255, Math.max(0, g))
      adjustedData[i + 2] = Math.min(255, Math.max(0, b))
      adjustedData[i + 3] = data[i + 3] // Alpha channel
    }

    // Copy adjusted data to imageData
    for (let i = 0; i < data.length; i++) {
      data[i] = adjustedData[i]
    }
  }
}
