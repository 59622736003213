import React, { useEffect, useState } from "react";
import { QnaWrite } from "./presenter";
import { useTranslation } from "react-i18next";
import { Colors } from "../../assets/styles";
import { observer } from "mobx-react";
import QnaWriteStore from "../../store/QnaWriteStore";
import { FeedMode } from "../../constants/Feed";
import QnaStore from "../../store/QnaStore";
import { UserActTypeCode } from "../../constants/FeedType.enum";
import AuthStore from "../../store/AuthStore";
import { useTracker } from "../../hooks/tracker";
import { NewProduct, Product } from "../../api/product/model";
import { useLocation, useNavigationType } from "react-router-dom";

const QnaWriteScreen = observer((data: any) => {
  const { t } = useTranslation();
  const { sessionUser, tempMember } = AuthStore;
  const params = useLocation().state;
  const navigationType = useNavigationType();

  const [tracker] = useState(useTracker());

  useEffect(() => {
    tracker.track("create_qna", {});
  }, [tracker]);

  useEffect(() => {
    const { clear, getRecommendTag, addTag, addProduct } = QnaWriteStore;
    const { syncQna } = QnaStore;
    const tags = params?.tags as string[];
    const productInfo: NewProduct = params?.productInfo;

    if (navigationType !== "POP") {
      clear();
      void getRecommendTag();

      if (tags !== undefined) {
        tags.forEach((tag) => addTag(String(tag)));
      }
    }
    if (productInfo) {
      addProduct(productInfo);
    }

    return () => {
      syncQna(undefined, undefined, UserActTypeCode.CREATE);
    };
  }, [data, navigationType]);

  // todo : 기능 복구
  // if (params?.onLeave != undefined) {
  //   navigation.addListener("beforeRemove", (e: any) => {
  //     params?.onLeave(true);
  //   });
  // }

  return (
    <div
      style={{
        flex: 1,
        ...Colors.background_fff,
      }}
      aria-label="Qna write screen"
    >
      <QnaWrite mode={FeedMode.WRITE} />
    </div>
  );
});

export default QnaWriteScreen;
