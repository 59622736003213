import { t } from "i18next";
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { getFeedFilter } from "../api/feed/api";
import {
  deleteMyProduct,
  deleteMyProductWithReason,
  getMyProduct,
} from "../api/product/api";
import { bottomOffset } from "../utils/common";
import { showBottomToast } from "../utils/Toast";
import { getCommonCode } from "../api/commonCode/api";
import {
  CommonGroupCode,
  ProductDeleteCode,
} from "../constants/CommonCode.enum";
import { CommonCode } from "../api/commonCode/model";
import {
  GetMyProductRequest,
  MyProductDeleteRequest,
  MyProductRequest,
} from "../api/product/model";
import AuthStore from "./AuthStore";

export interface FilterList {
  groupCode: string;
  codeValue: string;
  codeName: string;
  sortOrder: string;
  subFilterList: SubFilterList[];
}
export interface SubFilterList {
  groupCode: string;
  codeValue: string;
  codeName: string;
  sortOrder: string;
}

export interface MyAppliancesList {
  idx: number;
  productFlag?: boolean;
  productId?: number;
  tempProductId?: number;
  productName?: string;
  brandName?: string;
  modelName?: string;
  productStatus: string;
  categoryCode?: string;
  categoryName?: string;
  imageUrl?: string;
  isSelected?: boolean;
  isEditSelected?: boolean;
  createDatetime?: string;
  feedId?: number;
  upYn?: string;
}

export interface productList {
  productFlag?: boolean;
  productId?: number;
}

class MyAppliancesStore {
  constructor() {
    makeObservable(this);
  }

  @observable _selectProductList: productList[] | undefined;
  @observable _categoryList: SubFilterList[] | undefined;
  @observable _currentTab: SubFilterList["codeValue"] = "ALL";
  @observable _targetMemberUuid = "";
  @observable _totalCount = 0;
  @observable _myAppliancesList: MyAppliancesList[] | [] | undefined;
  @observable _cursor: number | undefined;
  @observable _showTopButton = false;
  @observable refresh = false;
  @observable _editInfo: {
    idx: number;
    productId: number;
    productFlag: boolean;
  } = {
    idx: 0,
    productId: 0,
    productFlag: false,
  };
  @observable _isEmpty = false;
  @observable _deleteCodeMap: Map<string, string> = new Map<string, string>();
  @observable _mode = "none";

  @action clear = () => {
    this._targetMemberUuid = "";
    this._currentTab = "ALL";
    this._totalCount = 0;
    this._myAppliancesList = [];
    this._cursor = undefined;
    this._isEmpty = false;
    this._mode = "none";
    this._editInfo = {
      idx: 0,
      productId: 0,
      productFlag: false,
    };
  };

  @computed get showTopButton() {
    return this._showTopButton;
  }

  @computed get mode() {
    return this._mode;
  }

  @action setMode(mode: string) {
    this._mode = mode;
  }

  @action setShowTopButton = (x: boolean) => {
    this._showTopButton = x;
  };

  @computed get targetMemberUuid() {
    return this._targetMemberUuid;
  }
  @action setTargetMemberUuid = (uuid: string) => {
    this._targetMemberUuid = uuid;
  };

  @computed get categoryList() {
    return this._categoryList;
  }
  @action setCategoryList = (list: SubFilterList[]) => {
    this._categoryList = list;
  };

  @computed get selectProductList() {
    return this._selectProductList;
  }
  @action setCSelectProductList = (list: productList[]) => {
    this._selectProductList = list;
  };

  @action getCategoryList = async () => {
    const result = await getFeedFilter();
    if (result.successOrNot == "Y") {
      const res = result.data.find(
        (x: FilterList) => x.codeValue === "CATEGORY1"
      );
      const fliterList: SubFilterList[] = res.subFilterList;
      res && res.subFilterList.length > 0 && this.setCategoryList(fliterList);
    }
  };

  @action clickEachTab = (tab: string) => {
    this.setCurrentTab(tab);
  };

  @action setCurrentTab = (tab: string) => {
    this._currentTab = tab;
  };

  @computed get currentTab() {
    return this._currentTab;
  }

  @action setTotalCount = (count: number) => {
    this._totalCount = count;
  };

  @computed get totalCount() {
    return this._totalCount;
  }

  @action setMyAppliancesList = (list: MyAppliancesList[]) => {
    this._myAppliancesList = list;
  };

  @computed get myAppliancesList() {
    return this._myAppliancesList;
  }

  @computed get existMyList() {
    return this.myAppliancesList && this.myAppliancesList?.length > 0;
  }

  @action setCursor = (cursor: number) => {
    this._cursor = cursor;
  };

  @computed get cursor() {
    return this._cursor;
  }

  @computed get isValid() {
    let checkingValid = false;

    const result = this._myAppliancesList?.filter((x) => x.isSelected);

    if (result && result?.length > 0) {
      checkingValid = true;
    } else {
      checkingValid = false;
    }

    return checkingValid;
  }

  @computed get isEditValid() {
    return this.editNum > 0;
  }

  @computed get isValidRegister() {
    let checkingValid = false;

    const result = this._myAppliancesList?.filter((x) => x.isSelected);

    if (result && result?.length > 5) {
      checkingValid = true;
    } else {
      checkingValid = false;
    }

    return checkingValid;
  }

  @computed get selectedCount() {
    return this._myAppliancesList?.filter((x) => x.isSelected).length;
  }

  @computed get editNum() {
    return this._editInfo.idx;
  }

  @computed get editInfo() {
    return this._editInfo;
  }

  @computed get isEmpty() {
    return this._isEmpty;
  }

  @computed get deleteReasonMap() {
    return this._deleteCodeMap;
  }

  @action showValidToast = () => {
    showBottomToast(t("상품을 선택해 주세요."), bottomOffset());
  };

  @action getMyProduct = async (isAdditionalData = false) => {
    const myFlag =
      (AuthStore.sessionUser?.memberUuid === this.targetMemberUuid ||
        this.targetMemberUuid === "") &&
      this.mode == "none";

    const request: GetMyProductRequest = !isAdditionalData
      ? {
          memberUuid: this.targetMemberUuid,
          category: this.currentTab === "ALL" ? "" : this.currentTab,
          order: "1",
          myFlag: myFlag,
        }
      : {
          memberUuid: this.targetMemberUuid,
          category: this.currentTab === "ALL" ? "" : this.currentTab,
          cursor: this.cursor,
          myFlag: myFlag,
        };

    if (!this.targetMemberUuid) {
      delete request["memberUuid"];
    }

    const result = await getMyProduct(request);

    if (
      this.mode !== "none" &&
      result.successOrNot == "Y" &&
      result.data.myProductList.length == 0
    ) {
      this.mode === "del" &&
        showBottomToast("등록 대기중인 가전은 삭제가 불가합니다.");
      this.mode === "edit" &&
        showBottomToast("등록 대기중인 가전은 수정이 불가합니다.");
      return "block";
    }

    if (result.successOrNot == "Y") {
      !isAdditionalData && this.setTotalCount(result.data.totalCount | 0);

      if (result.data.myProductList.length > 0) {
        const cursorVal: number =
          result.data.myProductList[result.data.myProductList.length - 1].idx;
        this.setCursor(cursorVal);
        result.data.myProductList.map((x: MyAppliancesList) => {
          x.isSelected = false;
        });
      }
      // isSelected
      const list: MyAppliancesList[] = !isAdditionalData
        ? result.data.myProductList
        : this.myAppliancesList && this.myAppliancesList?.length > 0
        ? [...this.myAppliancesList, ...result.data.myProductList]
        : result.data.myProductList;
      this.setMyAppliancesList(list);

      if (
        this.currentTab === "ALL" &&
        this.myAppliancesList?.length == 0 &&
        this.mode == "none"
      ) {
        this.setIsEmpty(true);
      } else {
        this.setIsEmpty(false);
      }
    }
  };

  @action initMyProduct = async (tab?: string) => {
    if (tab) {
      this.setCurrentTab(tab);
    } else {
      this.setCurrentTab("ALL");
    }
    await this.getCategoryList();
    await this.getMyProduct();
    await this.initCommonCode();
  };

  @action selectProduct = (item: MyAppliancesList) => {
    this._myAppliancesList?.map((x) => {
      if (x.productFlag) {
        if (x.productId === item.productId) x.isSelected = !x.isSelected;
      } else {
        if (x.tempProductId === item.tempProductId)
          x.isSelected = !x.isSelected;
      }
    });

    this.refresh = !this.refresh;
  };

  @action setEditInfo = (
    idx: number,
    productId: number,
    productFlag: boolean
  ) => {
    this._editInfo.idx = idx;
    this._editInfo.productId = productId;
    this._editInfo.productFlag = productFlag;
  };

  @action handleTotalCheck = (check: boolean) => {
    this._myAppliancesList?.map((x) => {
      x.isSelected = check;
    });
  };

  @action setIsEmpty = (flag: boolean) => {
    this._isEmpty = flag;
  };

  @action initCommonCode = async () => {
    this._deleteCodeMap.clear();
    const result = await getCommonCode(CommonGroupCode.PRODUCT_DELETE_REASON);
    for (const r of result) {
      this._deleteCodeMap.set(r.code, r.codeName);
    }
  };

  @action deleteMyProduct = async (removeReason: string) => {
    const deleteProducts = [] as MyProductRequest[];
    this.myAppliancesList
      ?.filter((my) => my.isSelected && my.productFlag)
      .forEach((my) => {
        deleteProducts.push({
          productId: my.productId,
          productFlag: true,
        });
      });

    this.myAppliancesList
      ?.filter((my) => my.isSelected && !my.productFlag)
      .map((my) => {
        deleteProducts.push({
          productId: my.tempProductId,
          productFlag: false,
        });
      });

    const delReq = {
      products: deleteProducts,
      removeReason: removeReason,
    } as MyProductDeleteRequest;

    return deleteMyProductWithReason(delReq);
  };

  @action unCheckedAll = () => {
    this.myAppliancesList?.map((x) => {
      x.isSelected = false;
    });
  };
}

export default new MyAppliancesStore();
