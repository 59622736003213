import React, { useRef, useState } from "react";

import "./QnaListFilter.scss";
import { ReactComponent as CheckOffIcon } from "../../../assets/icons/checkbox_big_off.svg";
import { ReactComponent as CheckOnIcon } from "../../../assets/icons/checkbox_big_on.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_small.svg";

import { observer } from "mobx-react";
import { Category, Topic } from "../../../constants/Qna.enum";
import { SearchSortType } from "../../../constants/Search";
import { useTranslation } from "react-i18next";
import QnaStore from "../../../store/QnaStore";
import Slider from "react-slick";

const QnaListFilter = observer(() => {
  const { qnaListStore } = QnaStore;
  const { t } = useTranslation();

  const tabs = {
    category: [
      { code: Category.ALL, text: t("screen.qnaList.filter.category.all") },
      { code: Category.SOUND, text: t("screen.qnaList.filter.category.sound") },
      {
        code: Category.SEASON,
        text: t("screen.qnaList.filter.category.season"),
      },
      { code: Category.COOK, text: t("screen.qnaList.filter.category.cook") },
      { code: Category.VIDEO, text: t("screen.qnaList.filter.category.video") },
      {
        code: Category.HOUSEWORK,
        text: t("screen.qnaList.filter.category.housework"),
      },
      {
        code: Category.BEAUTY,
        text: t("screen.qnaList.filter.category.beauty"),
      },
      //5862 - filter 변경에 따라서 주석처리 되었으며 housework로 통합 , db use_yn = 'N' , digital code add
      // { code: Category.HYGIENE, text: t("screen.qnaList.filter.category.hygiene") },
      // { code: Category.CLOTHES, text: t("screen.qnaList.filter.category.clothes") },
      {
        code: Category.HEALTH,
        text: t("screen.qnaList.filter.category.health"),
      },
      {
        code: Category.DIGITAL,
        text: t("screen.qnaList.filter.category.digital"),
      },
    ],
    type: [
      { code: Topic.ALL, text: t("screen.qnaList.filter.topic.all") },
      { code: Topic.INTERIOR, text: t("screen.qnaList.filter.topic.interior") },
      { code: Topic.FAILURE, text: t("screen.qnaList.filter.topic.failure") },
      { code: Topic.WARRANTY, text: t("screen.qnaList.filter.topic.warranty") },
      { code: Topic.TIPS, text: t("screen.qnaList.filter.topic.tips") },
    ],
  };

  const cardSlidersettings = {
    infinite: false,
    swipeToSlide: true,
    arrows: false,
    dots: false,
    variableWidth: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
  };

  return (
    <div className="qna_filter_wrapper">
      <div className="tag_option">
        <div className="tag_label_wrapper">
          <p className="tag_label" aria-label="category filter">
            {t(`screen.qnaList.filter.category.title`)}
          </p>
          <div
            style={{
              background:
                "linear-gradient(to right, #f5f5f5, rgba(245, 245, 245, 0) 0",
            }}
          />
        </div>
        <div className="chip_wrapper">
          <div id="qnaListFilterSlider" className="slider-container">
            <Slider {...cardSlidersettings}>
              {tabs.category.map((item, index) => {
                return (
                  <button
                    className={
                      qnaListStore.selectedCategories.has(item.code) ||
                      (qnaListStore.selectedCategories.size === 0 &&
                        index === 0)
                        ? "chip_active"
                        : "chip_passive"
                    }
                    style={{ marginRight: 8 }}
                    key={index}
                    onClick={() => qnaListStore.toggleCategory(item.code)}
                  >
                    <span className="chip_text">{item.text}</span>
                  </button>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="tag_option">
        <div className="tag_label_wrapper">
          <p className="tag_label" aria-label="topic filter">
            {t(`screen.qnaList.filter.topic.title`)}
          </p>
          <div
            style={{
              background:
                "linear-gradient(to right, #f5f5f5, rgba(245, 245, 245, 0) 0",
            }}
          />
        </div>
        <div className="chip_wrapper">
          <div id="qnaListFilterSlider" className="slider-container">
            <Slider {...cardSlidersettings}>
              {tabs.type.map((item, index) => {
                return (
                  <button
                    className={
                      qnaListStore.selectedTopics.has(item.code) ||
                      (qnaListStore.selectedTopics.size === 0 && index === 0)
                        ? "chip_active"
                        : "chip_passive"
                    }
                    key={index}
                    onClick={() => qnaListStore.toggleTopic(item.code)}
                  >
                    <span className="chip_text">{item.text}</span>
                  </button>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <div className="header_option">
        <button
          className="header_check_btn"
          onClick={() => {
            qnaListStore.setWaitingAnswerOnly(!qnaListStore.waitingAnswerOnly);
          }}
        >
          {qnaListStore.waitingAnswerOnly ? <CheckOnIcon /> : <CheckOffIcon />}
          <p
            className="header_check_btn_txt"
            aria-label="Toggle button about reply"
          >
            {t(`screen.qnaList.filter.waitingAnswerOnly`)}
          </p>
        </button>
        <button
          className="header_sort_btn"
          onClick={() => qnaListStore.setShowSortBox(!qnaListStore.showSortBox)}
          aria-label="Sorting filter"
        >
          <p className="header_sort_btn_txt">
            {qnaListStore.sort === SearchSortType.LATEST
              ? t("screen.qnaList.filter.sort.date")
              : t("screen.qnaList.filter.sort.popularity")}
          </p>
          <ArrowDown />
        </button>
      </div>
    </div>
  );
});

export default QnaListFilter;
