import React, { ReactNode } from 'react'
import { useState } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import './ContentsNotificationModal.scss'

export interface ContentsNotificationModalProps {
  isVisible: boolean
  title?: string
  buttonLabel?: string
  children?: ReactNode
  ref?: any | undefined
  modalContentStyle?: React.CSSProperties
  onRequestClose?: () => void
  onClickButton?: () => void
}

const ContentsNotificationModal: React.FC<ContentsNotificationModalProps> = ({
  isVisible,
  title,
  buttonLabel,
  children,
  modalContentStyle,
  onRequestClose,
  onClickButton,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(isVisible)
  const { t } = useTranslation()

  const handleCloseModal = () => {
    setModalIsOpen(false)
    if (onRequestClose) onRequestClose()
  }

  const handleButtonClick = () => {
    if (onClickButton) onClickButton()
    else handleCloseModal()
  }

  return (
    <div className="modal-wrapper">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        className="notification-modal"
        overlayClassName="notification-modal-overlay"
        id="contents-notification-modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10000,
            maxWidth: 450,
            margin: 'auto',
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            backgroundColor: '#fff',
            position: 'relative',
            borderRadius: 12,
            outline: 'none',
            width: '80%',
            ...modalContentStyle,
          },
        }}
      >
        <div className="modal-header">
          <h2 className="header-text">{title || 'Alert'}</h2>
          <button
            className="icons"
            onClick={handleCloseModal}
            aria-label="Close"
          >
            <CloseIcon />
          </button>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <button
            className="button black"
            onClick={handleButtonClick}
            aria-label="Close button"
          >
            <span className="footer-button-text">
              {buttonLabel || t('common.label.close')}
            </span>
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default ContentsNotificationModal
