// import { Dimensions, Platform } from "react-native";

// export const windowHeight = Dimensions.get("window").height;

export const modal_box_shadow = {
  //   ...Platform.select({
  //     ios: {
  //       shadowColor: "#000",
  //       shadowOffset: { width: 4, height: 4 },
  //       shadowOpacity: 0.25,
  //     },
  //     android: { elevation: 5 },
  //   }),
};
