import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import TriangleRightBlackIcon from "../../assets/icons/triangle_right_black.svg";
import { CertificationCodeStatus } from "../../constants/Certification.enum";
import PhoneAuthStore from "../../store/PhoneAuthStore";
import { useTranslation } from "react-i18next";
import classes from "./PhoneAuthSection.module.scss";
import CommonStore from "../../store/CommonStore";

export const PhoneAuthSection = observer(() => {
  const {
    timerStart,
    setTimerStart,
    inputPhoneCertificationCode,
    setInputPhoneCertificationCode,
    phoneCertificationCodeStatus,
    sendAuthPhone,
    checkPhoneCertCode,
    invalidCertificationCount,
    setInvalidCertificationCount,
  } = PhoneAuthStore;

  const { t } = useTranslation();
  const INITIAL_MINUTE_DISPLAY = "3";
  const INITIAL_SECOND_DISPLAY = "00";
  const AUTH_TIME_LIMIT = 180;

  const time = useRef(AUTH_TIME_LIMIT);
  const timerId = useRef<any>();

  const [startTime, setStartTime] = useState<number>(0);
  const [expirationMinDisplay, setExpirationMinDisplay] = useState<string>(
    INITIAL_MINUTE_DISPLAY
  );
  const [expirationSecDisplay, setExpirationSecDisplay] = useState<string>(
    INITIAL_SECOND_DISPLAY
  );
  const [resendCount, setResendCount] = useState<number>(0);
  const [isDisableResendBtn, setIsDisableResendBtn] = useState<boolean>(false);

  useEffect(() => {
    if (timerStart) {
      setExpirationMinDisplay(INITIAL_MINUTE_DISPLAY);
      setExpirationSecDisplay(INITIAL_SECOND_DISPLAY);
      time.current = AUTH_TIME_LIMIT;
      setStartTime(Date.now());
    } else {
      timerId.current && clearInterval(Number(timerId.current));
    }
  }, [timerStart]);

  useEffect(() => {
    if (timerStart) {
      timerId.current = setInterval(() => {
        const diffSec = Math.floor((Date.now() - startTime) / 1000);
        const extraAuthSec = Math.max(
          0,
          (time.current = AUTH_TIME_LIMIT - diffSec)
        );
        setExpirationMinDisplay(Math.floor(extraAuthSec / 60).toString());
        setExpirationSecDisplay((extraAuthSec % 60).toString());
      }, 1000);
    } else {
      clearInterval(timerId.current as NodeJS.Timeout);
    }

    return () => {
      clearInterval(timerId.current as NodeJS.Timeout);
    };
  }, [startTime, timerStart]);

  useEffect(() => {
    time.current <= 0 && setTimerStart(false);
  }, [expirationSecDisplay, setTimerStart]);

  const renderPhoneAuthenticationResponse = () => {
    if (phoneCertificationCodeStatus || time.current <= 0) {
      return (
        <div
          className={classes.auth_code_bottom}
          aria-label={"phone authentication result"}
        >
          {phoneCertificationCodeStatus ===
            CertificationCodeStatus.EXPIRED_CERTIFICATION_CODE ||
          time.current <= 0 ? (
            <span className={classes.auth_code_bottom_text}>
              {t("screen.join.joinPhone.authenticationCodeOutdated")}
            </span>
          ) : (
            <span className={classes.auth_code_bottom_text}>
              {t("screen.join.joinPhone.invalidAuthenticationCode")}
            </span>
          )}
        </div>
      );
    }
  };

  const resendAuthPhone = () => {
    setResendCount((x) => {
      return x + 1;
    });
    if (resendCount === 5) {
      alert(t("screen.join.joinPhone.resendLimitAlert"));
      setIsDisableResendBtn(true);
      setTimerStart(false);
      setTimeout(() => {
        setResendCount(0);
        setIsDisableResendBtn(false);
      }, 30000);
    } else {
      void sendAuthPhone();
    }
  };

  return (
    <div className={classes.auth_code_wrap}>
      <div className={classes.auth_code}>
        <div className={classes.auth_code_top}>
          <input
            className={classes.auth_code_input}
            value={inputPhoneCertificationCode}
            maxLength={6}
            onChange={(e) => {
              const text = e.target.value;
              setInputPhoneCertificationCode(text);
              if (text.length === 6) {
                if (invalidCertificationCount === 4) {
                  CommonStore.setNotificationModalProps({
                    isVisible: true,
                    contents1: t(
                      "screen.join.joinWithEmail.invalidCertificationCount"
                    ),
                    onClickDefaultButton: () => {
                      CommonStore.resetNotificationModalProps();
                    },
                  });
                } else {
                  void checkPhoneCertCode().then((result) => {
                    if (result === "") {
                      // Keyboard.dismiss();
                      e.target?.blur();
                    } else if (
                      result ===
                      CertificationCodeStatus.INVALID_CERTIFICATION_CODE
                    ) {
                      setInvalidCertificationCount(
                        invalidCertificationCount + 1
                      );
                    }
                  });
                }
              }
            }}
            aria-label={"phone authentication input"}
            autoComplete={"off"}
            inputMode={"numeric"}
            autoFocus={true}
          />
          <span className={classes.timer_text} aria-label={"timer"}>
            {expirationMinDisplay}:{expirationSecDisplay.padStart(2, "0")}
          </span>
        </div>
        {renderPhoneAuthenticationResponse()}
      </div>
      <div className={classes.auth_code_alert}>
        <img src={TriangleRightBlackIcon} alt="TriangleRightBlackIcon" />
        <span className={classes.auth_code_alert_text}>
          {t("screen.join.joinPhone.authenticationCodeInputPlaceholder")}
        </span>
        <button
          className={classes.resend}
          onClick={resendAuthPhone}
          aria-label={"resend authentication phone"}
          disabled={isDisableResendBtn}
        >
          <span className={classes.resend_text}>
            {t("screen.join.joinPhone.resendAuthenticationPhone")}
          </span>
        </button>
      </div>
    </div>
  );
});

export default PhoneAuthSection;
