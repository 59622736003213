import React, { forwardRef, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { AccountFeedItem } from "../../../api/account/model";
import { AccountFeedTypeCode, FeedUIList } from "../../../constants/Account";
import EmptyFeed from "./EmptyFeed";

import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import MyHomeStore from "../../../store/MyHomeStore";
import { FEED_CALL_STATE } from "../../../constants/Feed";
import { CustomActivityIndicator } from "../../../components/common";
import { ActHistoryRequest } from "../../../api/data/model";
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from "../../../constants/ActHistory";
import { sendActHistory } from "../../../api/data/api";
import AuthStore from "../../../store/AuthStore";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { Virtuoso } from "react-virtuoso";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import "./AccountMagazine.scss";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import CommonStore from "../../../store/CommonStore";
import { useWindowScroll } from "react-use";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";

const AccountMagazine = observer(
  ({
    isMy,
    userId,
    dataRefresh,
    endReached,
  }: {
    isMy: boolean;
    userId: string;
    dataRefresh?: () => void;
    endReached: () => void;
  }) => {
    const widthApp = CommonStore.widthApp;
    const { t } = useTranslation();
    const { getAccountStore } = MyHomeStore;
    const { sessionUser } = AuthStore;
    const accountStore = getAccountStore(userId);

    const PUBLIC_BUCKET_URL = process.env.REACT_APP_PUBLIC_BUCKET_URL;

    // const { y } = useWindowScroll();

    // useEffect(() => {
    //   const bottom =
    //     window.scrollY !== 0 &&
    //     Math.ceil(window.innerHeight + window.scrollY) >=
    //       document.documentElement.scrollHeight;

    //   if (bottom && accountStore?.magazineCanLoadMore) {
    //     accountStore?.getAccountFeed(
    //       accountStore?.magazinePageIndex + 1,
    //       AccountFeedTypeCode.MAGAZINE
    //     );
    //     accountStore?.setMagazinePageIndex(accountStore?.magazinePageIndex + 1);
    //   }
    // }, [y]);

    const handleMoreRequest = useCallback(() => {
      if (accountStore?.magazineCanLoadMore) {
        accountStore?.getAccountFeed(
          accountStore?.magazinePageIndex + 1,
          AccountFeedTypeCode.MAGAZINE
        );
        accountStore?.setMagazinePageIndex(accountStore?.magazinePageIndex + 1);
      }
    }, [accountStore.getAccountFeed, accountStore.setMagazinePageIndex]);

    const handleMagazinePress = (feedId: number, index: number) => {
      goTo(`/magazine/${feedId}`, {
        state: {
          callState: FEED_CALL_STATE.MY_HOME,
        },
      });

      if (userId !== sessionUser?.memberUuid) {
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.MAGAZINE_READ,
          actHistoryTargetId: feedId.toString(),
          actPageCode: ActPageCode.OTHER_USER_HOME,
          actSectionCode: ActSectionCode.MAGAZINE,
          attr1: userId,
        };
        void sendActHistory(actHistory);
      }
    };

    const getRandomNumber = () => {
      return Math.floor(Math.random() * 2);
    };

    const renderContent = (item: AccountFeedItem) => {
      return (
        <div
          className="content_wrapper"
          aria-label={`Account Magazine List Item ${item.feedId} Content`}
        >
          <div
            aria-label={`Account Magazine List Item ${item.feedId} Content Nickname`}
          >
            <div
              aria-label={`Account Magazine List Item ${item.feedId} Content Title`}
              className="content_title_wrapper"
            >
              <p>{item.feedTitle}</p>
            </div>
          </div>
        </div>
      );
    };

    const renderMagazine = ({
      item,
      index,
    }: {
      item: AccountFeedItem;
      index: number;
    }) => {
      return (
        <TouchableWithAuthCheck
          key={item.feedId}
          onPress={() => handleMagazinePress(item.feedId, index)}
          aria-label={`Account Mgazine List Item ${item.feedId}`}
          withoutFeedback={true}
          className="account_magazine_item_wrapper"
        >
          <FastImageWithFallback
            source={{
              uri: `${PUBLIC_BUCKET_URL}${item.thumbnailFilePath || ""}`,
            }}
            wrapperStyle={{ aspectRatio: 1 }}
            className="account_magazine_image"
            fallbackImageUri={`${PUBLIC_BUCKET_URL}${
              item.originalFilePath || ""
            }`}
            renderFallbackNode={({ children, style }) => {
              return <div>{children}</div>;
            }}
          />

          {renderContent(item)}
        </TouchableWithAuthCheck>
      );
    };

    return (
      <div className="account_magazine_wrapper">
        {accountStore?.magazineList.length > 0 ? (
          <>
            <div className="magazine_list_container">
              {accountStore?.magazineList.map((item: any, index: number) => {
                return renderMagazine({ item, index });
              })}
              <InfiniteScrollObserver onObserver={handleMoreRequest} />
              {/* <Virtuoso
              className="mission_list"
              data={magazineList.slice()}
              itemContent={(index: number, item: AccountFeedItem) => {
                return renderMagazine({ item, index });
              }}
              endReached={() => {
                void endReached();
              }}
              useWindowScroll
              increaseViewportBy={800}
              components={{
                Footer: () => (
                  <div style={{ height: 60, backgroundColor: "transparent" }} />
                ),
                List: forwardRef(
                  (
                    { children, ...props },
                    ref: React.LegacyRef<HTMLDivElement> | undefined
                  ) => (
                    <div
                      ref={ref}
                      {...props}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                      }}
                    >
                      {children}
                    </div>
                  )
                ),
                Item: ({ children, ...props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      width: "calc(50% - 4px)",
                      marginBottom: 4,
                    }}
                    className="magazine_item"
                  >
                    {children}
                  </div>
                ),
              }}
            /> */}
            </div>
            {/* <div
              style={{
                height: "10px",
                marginBottom: "20px",
                backgroundColor: "red",
              }}
              ref={setObserverRef}
            ></div> */}
          </>
        ) : (
          <div className="empty_data_wrapper">
            <img
              className="empty_data"
              src={require("../../../assets/images/empty_qna_list.png")}
              alt="empty_magazine_list"
              width={widthApp}
            />
            <p className="empty_text">
              {t("screen.myHome.message.noMagazine")}
            </p>
          </div>
        )}
      </div>
    );
  }
);

export default AccountMagazine;
