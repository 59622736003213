import { createRef, MutableRefObject } from 'react'
import {
  TrackFunction,
  SetUserPropertyFunction,
  HandleLoginFunction,
  HandleLogoutFunction,
  UpdateUserInfoFunction,
} from './types'

export type TrackerRef = MutableRefObject<null | {
  track: TrackFunction
  setUserProperty: SetUserPropertyFunction
  updateUserInfo: UpdateUserInfoFunction
  handleLogin: HandleLoginFunction
  handleLogout: HandleLogoutFunction
}>

export const trackerRef: TrackerRef = createRef()

export const track: TrackFunction = (event, properties) => {
  void trackerRef.current?.track(event, properties)
}

export const setUserProperty: SetUserPropertyFunction = (key, value) => {
  void trackerRef.current?.setUserProperty(key, value)
}

export const updateUserInfo: UpdateUserInfoFunction = (userInfo) => {
  void trackerRef.current?.updateUserInfo(userInfo)
}

export const handleLogin: HandleLoginFunction = (userId) => {
  void trackerRef.current?.handleLogin(userId)
}

export const handleLogout: HandleLogoutFunction = () => {
  void trackerRef.current?.handleLogout()
}
