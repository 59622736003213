// 원본 소스
// import { observer } from "mobx-react";
// import React, { useEffect, useState } from "react";
// import { View } from "react-native";
// import { Color } from "../../assets/styles/variable";
// import MissionMain from "./presenter/MissionMain";
// import MissionStore from "../../store/MissionStore";
// import { MISSION_TYPE_PATH } from "../../constants/mission";

import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Color } from "../../assets/styles/variable";
import MissionMain from "./presenter/MissionMain";
import MissionStore from "../../store/MissionStore";
import { MISSION_TYPE_PATH } from "../../constants/mission";
import MainLayout from "../main/MainLayout";

const MissionScreen = observer((data: any) => {
  const params = data?.route?.params;

  // useEffect(() => {
  //   const location =
  //     (params?.location as MISSION_TYPE_PATH) ?? MISSION_TYPE_PATH.ONGOING;
  //   MissionStore.setSelectedTab(location);
  // }, [params]);

  return (
    //원본 소스
    //   <View
    //   style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: Color.WHITE }}
    //   accessible={true}
    //   accessibilityLabel={"Mission screen"}
    // >
    //   <MissionMain location={params?.location} />
    // </View>
    <MainLayout>
      <div
        id="MissionScreen"
        style={{
          display: "flex",
          flexDirection: "column", // flex는 웹에서 column이 기본값이 아니므로 명시적으로 지정
          justifyContent: "center",
          // alignItems: 'center',
          // height: '100vh', // 전체 뷰포트 높이를 차지
          backgroundColor: Color.WHITE,
        }}
        // 웹 접근성 속성
        role="Mission" // 웹에서는 적절한 ARIA 역할을 추가하는 것이 좋습니다
        aria-label="Mission screen"
      >
        <MissionMain location={params?.location} />
      </div>
    </MainLayout>
  );
});

export default MissionScreen;
