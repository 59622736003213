import { callAPIWithGETMethod, callAPIWithPUTMethod } from "../common/api";
import CommonResponse from "../http";
import AuthStore from "../../store/AuthStore";
import { SearchTabMenu, SEARCH_RESULT_COUNT } from "../../constants/Search";
// import SearchStore from "../../store/SearchStore";
import { SuccessOrNot } from "../../constants/Common.enum";
import { FilterParam, PopularRanking, WithdrawalMemberFeed } from "./model";
import { FeedTypeCode } from "../../constants/Feed";

// 인기 피드 상세 목록 조회
export const getPopularFeedDetailList = async (
  pageSize: number,
  start: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/popularity/postDetails?pageSize=${pageSize}&pageIndex=${start}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 추천 피드 상세 조회
export const getRecommendFeedDetailList = async (
  topFeedId: number,
  memberUuid: string,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/recommendation/postDetails?topFeedId=${topFeedId}&memberUuid=${memberUuid}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};
// 추천 피드 상세 조회
export const getRecommendedFeedList = async (
  pageSize: number,
  start: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  const { tempMember } = AuthStore;

  const tempMemberUUID: string = tempMember
    ? `&tempMemberUuid=${tempMember.tempMemberUUID as string}`
    : "";

  try {
    response = await callAPIWithGETMethod(
      {
        url:
          `/v1/recommendation/postDetails?pageSize=${pageSize}&pageIndex=${start}` +
          tempMemberUUID,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 인기 피드 목록 조회
export const getPopularPostList = async (
  pageSize: number,
  start: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/popularity/posts?pageSize=${pageSize}&pageIndex=${start}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 인기 태그 목록 조회
export const getPopularTagList = async (
  pageSize: number,
  start: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/popularity/tags?pageSize=${pageSize}&pageIndex=${start}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }
  return response;
};

// 인기 QNA 목록 조회
export const getPopularQnaList = async (
  pageSize: number,
  start: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/popularity/qnas?pageSize=${pageSize}&pageIndex=${start}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }
  return response;
};

// 인기 제품 랭킹 목록 조회
export const getPopularRanking = async (
  pageSize: number,
  start: number,
  isLoading = true
): Promise<CommonResponse<{ totalCount: number; list: PopularRanking[] }>> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/ranking/product?pageSize=${pageSize}&pageIndex=${start}&ageGroupCode=all&genderGroupCode=all&preferenceCode=all`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }
  return response;
};

// 멤버 피드 상세조회
export const getUserFeedList = async (
  pageSize: number,
  start: number,
  topFeedId: number,
  memberUuid: string,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/feed/postDetails?pageSize=${pageSize}&pageIndex=${start}&topFeedId=${topFeedId}&memberUuid=${memberUuid}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 메거진 상세조회
export const getMagazineDetail = async (
  selectCount: number,
  startIndex: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/feed/magazineDetails?selectCount=${selectCount}&startIndex=${startIndex}`, // TODO: selectCount 수정
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 추천 포스트 목록 조회
export const getRecommendFeedList = async (
  pageSize: number,
  pageIndex: number,
  feedTypeCode: string,
  followingYn: string,
  memberUuid: string,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/recommendation/posts?pageSize=${pageSize}&pageIndex=${pageIndex}&feedTypeCode=${feedTypeCode}&followingYn=${followingYn}&memberUuid=${memberUuid}&topFeedId=${20002}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 최신&팔로잉 포스트 목록 조회
export const getRecentFeedList = async (
  pageSize: number,
  pageIndex: number,
  feedTypeCode: string,
  followingYn: string,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/feed/recent?pageSize=${pageSize}&pageIndex=${pageIndex}&feedTypeCode=${feedTypeCode}&followingYn=${followingYn}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 최신 필터
export const getFilteredFeedList = async (
  filterParam: FilterParam,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    let queryString =
      `pageSize=${filterParam.pageSize}&` +
      `page=${filterParam.page}&` +
      `direction=${filterParam.direction}&` +
      `feedTypeCode=${filterParam.feedTypeCode}&` +
      `sort=${filterParam.sort}&`;

    if (filterParam.memberUuid) {
      queryString += `memberUuid=${filterParam.memberUuid}&`;
    }
    if (filterParam.tempMemberUuid) {
      queryString += `tempMemberUuid=${filterParam.tempMemberUuid}&`;
    }
    if (filterParam.filterQuery?.CATEGORY1) {
      queryString += `filterQuery.CATEGORY1=${filterParam.filterQuery.CATEGORY1}&`;
    }
    if (filterParam.filterQuery?.THEME) {
      queryString += `filterQuery.THEME=${filterParam.filterQuery.THEME}&`;
    }
    if (filterParam.filterQuery?.LIFE_STYLE) {
      queryString += `filterQuery.LIFE_STYLE=${filterParam.filterQuery.LIFE_STYLE}&`;
    }
    if (filterParam.filterQuery?.SPACE) {
      queryString += `filterQuery.SPACE=${filterParam.filterQuery.SPACE}`;
    }

    response = await callAPIWithGETMethod(
      {
        url: `/v1/feed/recent/postDetailsByCursorFilter?` + queryString,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 검색한 포스트 목록 조회
export const getSearchingFeedList = async (
  searchTabMenu: SearchTabMenu,
  query: string,
  topFeedId: number,
  pageSize = SEARCH_RESULT_COUNT,
  pageIndex = 0,
  isLoading = true
): Promise<CommonResponse> => {
  const tempMemberUuid = AuthStore.tempMember?.tempMemberUUID;
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/search/${searchTabMenu}/feedDetails?`,
        params: {
          query,
          topFeedId,
          pageSize,
          pageIndex,
          // sort: SearchStore.getSortType(),
          sort: "dateDESC",
          tempMemberUuid,
        },
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 제품 검색 - 포스트 목록 조회 (realPost, Q&A, 사용법)
export const getProductFeedList = async (
  pageSize: number,
  pageIndex: number,
  productFeedSearchType: string,
  productId: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  const { tempMember } = AuthStore;
  const tempMemberUuidParam: string = tempMember
    ? `&tempMemberUuid=${tempMember.tempMemberUUID as string}`
    : "";

  try {
    response = await callAPIWithGETMethod(
      {
        url:
          `/v1/data/search/product/feedDetails?pageSize=${pageSize}&pageIndex=${pageIndex}` +
          `&productFeedSearchType=${productFeedSearchType}&productId=${productId}` +
          tempMemberUuidParam,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 제품 검색 - 포스트 상세 조회 (realPost, Q&A, 사용법)
export const getProductFeedDetailList = async (
  topFeedId: number,
  pageSize: number,
  pageIndex: number,
  productFeedSearchType: string,
  productId: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  const { tempMember } = AuthStore;
  const tempMemberUuidParam: string = tempMember
    ? `&tempMemberUuid=${tempMember.tempMemberUUID as string}`
    : "";
  const topFeedIdParam: string = topFeedId ? `&topFeedId=${topFeedId}` : "";

  try {
    response = await callAPIWithGETMethod(
      {
        url:
          `/v1/data/search/product/feedDetails?pageSize=${pageSize}&pageIndex=${pageIndex}` +
          `&productFeedSearchType=${productFeedSearchType}&productId=${productId}` +
          topFeedIdParam +
          tempMemberUuidParam,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 최신 피드 상세 목록 조회
export const getRecentFeedDetailList = async (
  pageSize: number,
  cursor: string | null,
  feedTypeCode: string,
  followingYn: string,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };

  let queryParamStr = `&pageSize=${pageSize}&followingYn=${followingYn}&feedTypeCode=${feedTypeCode}`;
  queryParamStr += cursor ? `&cursor=${cursor}` : "";

  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/feed/recent/postDetailsByCursor?${queryParamStr}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

export const getWithdrawalMemberFeeds = async (
  memberUuid: string,
  isLoading = true
) => {
  const response = await callAPIWithGETMethod(
    {
      url: "/v1/feeds/withdrawal",
      params: { memberUuid },
    },
    isLoading
  );

  return (
    response?.successOrNot === SuccessOrNot.Y ? response?.data : {}
  ) as WithdrawalMemberFeed[];
};

export const restoreWithdrawalMemberFeeds = async (isLoading = true) => {
  const response = await callAPIWithPUTMethod(
    {
      url: "/v1/feed/withdrawal",
    },
    isLoading
  );

  return response?.successOrNot;
};

// 태그 피드 상세 조회
export const getTagFeedDetailList = async (
  feedTypeCode: FeedTypeCode,
  pageSize: number,
  pageIndex: number,
  tagId: number,
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/feed/tag/feedDetails?&pageSize=${pageSize}&pageIndex=${pageIndex}&tagId=${tagId}&feedTypeCode=${feedTypeCode}`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }

  return response;
};

// 포스트 필터 조회
export const getFeedFilter = async (
  isLoading = true
): Promise<CommonResponse> => {
  let response: CommonResponse = {
    successOrNot: "N",
    statusCode: "",
    data: {},
  };
  try {
    response = await callAPIWithGETMethod(
      {
        url: `/v1/feed/filter`,
      },
      isLoading
    );
  } catch (error) {
    response.data = error;
  }
  return response;
};
