import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { FeedTypeCode } from "../../../constants/Feed";
import TagMainForeground from "./TagMainForeground";
import TagDetailStore from "../../../store/TagDetailStore";
import { styles } from "../TagScreen";
import { TitleHeader } from "../../../components/common";
import { useTranslation } from "react-i18next";
import TagPostComponent from "./TagPostComponent";
import TagQnaComponent from "./TagQnaComponent";
import TagAbComponent from "./TagAbComponent";
import TagStore from "../../../store/TagStore";
import CustomActivityIndicatorSmall from "../../../components/common/CustomActivityIndicatorSmall";
import ListWithStickyTabs from "../../../components/common/ListWithStickyTabs";
import NotificationModal from "../../../components/modals/NotificationModal";
import { goBack } from "../../../hooks/navigate/NavigateFunction";
import { InfiniteScrollObserver } from "../../../components/common/InfiniteScrollObserver";
import { useDebounce, useWindowScroll } from "react-use";
import "./TagMain.scss";
import { useNavigationType } from "react-router-dom";

const TagMain = observer(({ tagId }: { tagId: number }) => {
  const { t } = useTranslation();
  const [moreLoading, setMoreLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const [tagDetailStore, setTagDetailStore] = useState<TagDetailStore>(
    new TagDetailStore()
  );
  const [tabState, setTabState] = useState<FeedTypeCode>(
    tagDetailStore?.feedTypeCode || FeedTypeCode.POST
  );
  const [showNoDataPopup, setShowNoDataPopup] = useState(false);
  const navigationType = useNavigationType();

  const { y } = useWindowScroll();
  const [debouncedScrollY, setDebouncedScrollY] = useState(y);
  useDebounce(() => {
    setDebouncedScrollY(y);
  }, 250, [y])

  const isProduct = tagDetailStore?.tagDetail?.productId !== undefined;

  useEffect(() => {
    setFirstLoading(true);

    const asyncFunction = async (tagId: number) => {
      const tagDetailStore = await TagStore.initTagDetailStore(tagId);
      if (tagDetailStore) {
        if (navigationType !== "POP") {tagDetailStore.clearData()}
        setTagDetailStore(tagDetailStore);
        setTabState(tagDetailStore.feedTypeCode);
      } else {
        setTimeout(() => {
          setShowNoDataPopup(true);
        }, 500);
      }
    };

    !TagStore.loading &&
      void asyncFunction(tagId).finally(() => {
        setFirstLoading(false);
      });
  }, []);

  useEffect(() => {
    const previousScrollPosition = tagDetailStore.getScrollValue();
    if (previousScrollPosition !== 0 && !firstLoading) {
      const timer = setTimeout(() => {
        window.scrollTo(0, previousScrollPosition);
        clearTimeout(timer);
      }, 600);
    }
  }, [firstLoading]);

  useEffect(() => {
    if (tagDetailStore && !firstLoading) {
      tagDetailStore.setScrollValue(debouncedScrollY);
    }
  }, [debouncedScrollY]);

  // const checkIsClear = useCallback(() => {
  //   // 해당 태그화면에 처음 진입하는 것인지 확인하는 메서드. 웹에서 동일하게 진행할 필요 있는지 고려 필요함
  //   const routes = navigation.getState().routes;
  //   const tagScreenRoutes = routes.filter((route) => {
  //     return route.name == "TagScreen" && route?.params.tagId === tagId;
  //   });

  //   return tagScreenRoutes.length === 0;
  // }, [tagId, navigation]);

  // useEffect(() => {
  //   // 해당 태그 화면에 처음 진입한 상황이면 언마운트 시 tagDetail을 Clear
  //   return () => {
  //     checkIsClear() && tagDetailStore?.clear();
  //   };
  // }, [tagDetailStore, checkIsClear]);

  // useEffect(() => {
  //   return () => {
  //     tagDetailStore?.clear();
  //   };
  // }, [tagDetailStore]);

  // useEffect(() => {
  //   console.log("INIT FEED INDEX TO SCROLL");

  //   const selectedFeedIndex = tagDetailStore.getSelectedFeedIndex();
  //   console.log("LOADED Feed INDEX : ", selectedFeedIndex);

  //   const timer = setInterval(() => {
  //     if (
  //       selectedFeedIndex !== 0 &&
  //       tagMainRef.current?.children[selectedFeedIndex]
  //     ) {
  //       const targetFeed = tagMainRef.current.children[selectedFeedIndex];
  //       console.log("TARGET FEED AT : ", targetFeed.scrollTop);
  //       targetFeed.scrollIntoView();
  //       clearInterval(timer);
  //     } else {
  //       return;
  //     }
  //   }, 300);
  // }, [tagMainRef.current, tagDetailStore]);

  const toastConfig = {
    tomatoToast: ({ props }: { [key: string]: any }) => (
      <div style={styles.toast}>
        <span style={styles.toast_text}>{props.text1}</span>
      </div>
    ),
  };

  const renderHeader = () => {
    return (
      <div
        style={{
          width: "100%",
          zIndex: 10,
        }}
      >
        <TitleHeader isHome />
      </div>
    );
  };
  const onEndReached = async () => {
    if (!firstLoading && !moreLoading && tagId && !tagDetailStore.feedLoading) {
      setMoreLoading(true);
      if (tabState === FeedTypeCode.POST && tagDetailStore.postCanLoadMore) {
        await tagDetailStore.getMoreFeedList(FeedTypeCode.POST, tagId);
      } else if (
        tabState === FeedTypeCode.QNA &&
        tagDetailStore.qnaCanLoadMore
      ) {
        await tagDetailStore.getMoreFeedList(FeedTypeCode.QNA, tagId);
      } else if (tabState === FeedTypeCode.AB && tagDetailStore.abCanLoadMore) {
        await tagDetailStore.getMoreFeedList(FeedTypeCode.AB, tagId);
      }
      setMoreLoading(false);
    }
  };

  const tabs = [
    {
      title: t(`screen.tag.label.post`),
      key: FeedTypeCode.POST,
      content: (
        <TagPostComponent
          postList={tagDetailStore.postList}
          isProduct={isProduct}
          tagId={tagId}
          tagName={tagDetailStore.tagDetail.tagName}
          loading={tagDetailStore.feedLoading}
        />
      ),
    },
    {
      title: t(`screen.tag.label.qna`),
      key: FeedTypeCode.QNA,
      content: (
        <TagQnaComponent
          qnaList={tagDetailStore.qnaList}
          isProduct={isProduct}
          tagName={tagDetailStore.tagDetail.tagName}
          loading={tagDetailStore.feedLoading}
        />
      ),
    },
    {
      title: t(`screen.tag.label.ab`),
      key: FeedTypeCode.AB,
      content: (
        <TagAbComponent
          abList={tagDetailStore.abList}
          abPageIndex={tagDetailStore.abPageIndex}
          isProduct={isProduct}
          tagName={tagDetailStore.tagDetail.tagName}
          loading={tagDetailStore.feedLoading}
        />
      ),
    },
  ];

  const stickyTabsRef = useRef(null);

  return (
    <div id="tag-main-section">
      {showNoDataPopup && (
        <NotificationModal
          isVisible={showNoDataPopup}
          contents1={t("common.message.blockedOrDeleted")}
          useTwoButton={false}
          isBlack={true}
          onRequestClose={() => {
            setShowNoDataPopup(false);
            goBack();
          }}
          defaultButtonText={t("common.label.check")}
        />
      )}
      {!showNoDataPopup && renderHeader()}
      {!showNoDataPopup && !firstLoading && (
        <>
          <ListWithStickyTabs
            contentScrollRef={stickyTabsRef}
            scrollableHeader={
              <div>
                <TagMainForeground tagDetail={tagDetailStore.tagDetail} />
              </div>
            }
            className="tag-main-sticky-tab"
            isShowSpacer={false}
            stickyTabs={tabs}
            onChangeTab={(key: string) => {
              setTabState(key as FeedTypeCode);
              tagDetailStore.setFeedTypeCode(key as FeedTypeCode);
              tagDetailStore.clearList();

              if (key === FeedTypeCode.POST) {
                void tagDetailStore.getFeedList(
                  tagDetailStore.postPageIndex,
                  FeedTypeCode.POST,
                  tagId
                );
              } else if (key === FeedTypeCode.QNA) {
                void tagDetailStore.getFeedList(
                  tagDetailStore.qnaPageIndex,
                  FeedTypeCode.QNA,
                  tagId
                );
              } else if (key === FeedTypeCode.AB) {
                void tagDetailStore.getFeedList(
                  tagDetailStore.abPageIndex,
                  FeedTypeCode.AB,
                  tagId
                );
              }
            }}
            activeTabKey={tabState}
            showScrollToTop={true}
          />
          <InfiniteScrollObserver onObserver={onEndReached} threshold={0} rootMargin="300px 0px"/>
        </>
      )}

      {firstLoading && <CustomActivityIndicatorSmall />}
    </div>
  );
});

export default TagMain;
