import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PostLocalSave } from "../../store/PostWriteStore";
import { POST_TEMP_DATA } from "../../constants/Storage";
import { in60Days } from "../../utils/datetime";
import AddModalItem from "../molecules/addModalItem/AddModalItem";
import { FeedTypeCode } from "../../constants/Feed";
import { NotificationModal } from "../modals";
import CustomBottomSheet from "./CustomBottomSheet";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { observer } from "mobx-react";
import "./WriteButtonBottomSheet.scss";
import CommonStore from "../../store/CommonStore";
import { goTo } from "../../hooks/navigate/NavigateFunction";
import {
  ActHistoryTargetId,
  ActHistoryTypeCode,
  ActPageCode,
} from "../../constants/ActHistory";
import { useLocation } from "react-router-dom";
import { getIndexedDB } from "../../utils/IndexedDB";
import TabStore from "../../store/TabStore";
import { StackTypeCode } from "../../constants/Stack";

const WriteButtonBottomSheet = observer(
  ({ show, onClose }: { show: boolean; onClose: () => void }) => {
    const [showPostPopup, setShowPostPopup] = useState(false);
    const [postData, setPostData] = useState({} as PostLocalSave | null);
    const { t } = useTranslation();
    const location = useLocation();
    const { currentTab } = TabStore;

    const checkSavedPostThenMove = async () => {
      setPostData(null);
      const result = await getIndexedDB(POST_TEMP_DATA);

      if (result) {
        const storedData: PostLocalSave = JSON.parse(result as string);
        const postTempDate = storedData.date;
        if (postTempDate && in60Days(postTempDate)) {
          setPostData(storedData);
          moveToWritePost(storedData)
            .then(() => {})
            .catch((err: any) => {});
        } else {
          moveToWritePost()
            .then(() => {})
            .catch((err: any) => {});
        }
      } else {
        moveToWritePost()
          .then(() => {})
          .catch((err: any) => {});
      }
    };

    const moveToWritePost = async (data?: PostLocalSave) => {
      const checkFileExists = async (filePath: string | undefined) => {
        if (filePath && filePath.length > 0) {
          const img = new Image();
          img.src = filePath;
          try {
            await new Promise((resolve, reject) => {
              img.onload = resolve;
              img.onerror = reject;
            });
          } catch (e) {
            return false;
          }
        }
        return true;
      };

      if (data) {
        for (const item of data.wholeTempData.contents) {
          for (const file of item.files) {
            const {
              imageLocalFilePath,
              videoLocalFilePath,
              thumbnailTempPath,
            } = file;

            const isImageValid = await checkFileExists(imageLocalFilePath);
            if (!isImageValid) {
              writeNewPost(null);
              return;
            }

            const isVideoValid = await checkFileExists(videoLocalFilePath);
            if (!isVideoValid) {
              writeNewPost(null);
              return;
            }

            const isThumbnailValid = await checkFileExists(thumbnailTempPath);
            if (!isThumbnailValid) {
              writeNewPost(null);
              return;
            }
          }
        }
      }
      data ? setShowPostPopup(true) : writeNewPost(null);
    };

    const writeActHistory = (actHistoryTargetId: ActHistoryTargetId) => {
      let actPageCode: ActPageCode | null = null;
      if (location.pathname === "/" || location.pathname === "/main") {
        actPageCode = ActPageCode.MAIN;
      } else if (location.pathname === "/posts") {
        actPageCode = ActPageCode.POST;
      } else if (location.pathname === "/qna") {
        actPageCode = ActPageCode.QNA;
      }

      CommonStore.callActHistory(
        ActHistoryTypeCode.PAGE_CLICK,
        actHistoryTargetId,
        actPageCode
      );
    };

    const writeNewPost = (formerData: PostLocalSave | undefined | null) => {
      closeBottomSheet();
      writeActHistory(ActHistoryTargetId.POST_WRITE);
      // goTo("/post/write", { state: { data: formerData } });
      CommonStore.setShowDownloadAppDrive(true);
    };

    const writeNewQna = () => {
      closeBottomSheet();
      writeActHistory(ActHistoryTargetId.QNA_WRITE);
      // goTo("/qna/write");
      CommonStore.setShowDownloadAppDrive(true);
    };

    const writeNewAorB = () => {
      closeBottomSheet();
      writeActHistory(ActHistoryTargetId.AB_WRITE);
      // navigate("AbWriteScreen");
      CommonStore.setShowDownloadAppDrive(true);
    };

    const closeBottomSheet = () => {
      onClose();
    };

    return (
      <div id="WriteButtonBottomSheet">
        <CustomBottomSheet
          open={show}
          onClose={closeBottomSheet}
          snapHeight={320}
          modalContentStyles={{
            padding: "unset",
            height: "unset",
          }}
        >
          <div className="WriteButtonBottomSheet_sheet">
            <div className="WriteButtonBottomSheet_title">
              <div className="WriteButtonBottomSheet_title_text">
                {t("screen.addModal.label.write")}
              </div>
            </div>

            <div
              className="WriteButtonBottomSheet_close_btn"
              onClick={closeBottomSheet}
            >
              <CloseIcon />
            </div>

            <div>
              <AddModalItem
                onClick={checkSavedPostThenMove}
                actionText={t("screen.addModal.label.firstActionText")}
                infoText={t("screen.addModal.label.firstInfoText")}
                isLastChild={false}
                accessibilityLabel={"post"}
                feedTypeCode={FeedTypeCode.POST}
                beforeOnPress={closeBottomSheet}
              ></AddModalItem>
              <AddModalItem
                onClick={writeNewQna}
                actionText={t("screen.addModal.label.secondActionText")}
                infoText={t("screen.addModal.label.secondInfoText")}
                isLastChild={false}
                accessibilityLabel={"qna"}
                feedTypeCode={FeedTypeCode.QNA}
                beforeOnPress={closeBottomSheet}
              ></AddModalItem>
              <AddModalItem
                onClick={writeNewAorB}
                actionText={t("screen.addModal.label.thirdActionText")}
                infoText={t("screen.addModal.label.thirdInfoText")}
                isLastChild={true}
                accessibilityLabel={"ab"}
                feedTypeCode={FeedTypeCode.AB}
                beforeOnPress={closeBottomSheet}
              ></AddModalItem>
            </div>
          </div>
        </CustomBottomSheet>

        {showPostPopup && (
          <NotificationModal
            isVisible={showPostPopup}
            title={t("screen.post.title.loadPost")}
            contents1={t("screen.post.message.loadTempSaved")}
            useTwoButton={true}
            isBlack={true}
            defaultButtonText={t("screen.post.button.newOne")}
            extraButtonText={t("screen.post.button.load")}
            onClickDefaultButton={() => {
              writeNewPost(null);
              setShowPostPopup(false);
            }}
            onClickExtraButton={() => {
              writeNewPost(postData);
              setShowPostPopup(false);
            }}
            onRequestClose={() => {
              setShowPostPopup(false);
            }}
          />
        )}
      </div>
    );
  }
);

export default WriteButtonBottomSheet;
