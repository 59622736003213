import { useTranslation } from "react-i18next";
import FastImageWithFallback from "../../../components/common/FastImageWithFallback";
import { Styles } from "../../../assets/types/Style";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { ReactComponent as IconCheck } from "../../../assets/icons/icon_follow_check.svg";
import { ReactComponent as BtnMore } from "../../../assets/icons/icon_more_arrow.svg";
import { BrandCommunityLineUpResponse, BrandListTabType } from "../../../api/brand/model";
import { countFollow } from "../../../utils/numberCount";
import { brandSubscribe } from "../../../api/brand/api";
import { showBottomToast } from "../../../utils/Toast";
import { bottomOffset } from "../../../utils/common";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { observer } from "mobx-react";
import { Virtuoso } from "react-virtuoso";

const TodayBrand = observer(({ renderData }: { renderData: BrandCommunityLineUpResponse[] | [] }) => {
  const PUBLIC_BUCKET_URL = String(process.env.REACT_APP_PUBLIC_BUCKET_URL);
  const FALLBACK_IMAGE_URL = PUBLIC_BUCKET_URL + "/ASSETS/fall_back_image.png";

  const { t } = useTranslation();

  const goToBrandPageHome = (communityId: number) => {
    goTo("/BrandCommunityHome", {
      state: { targetBrandId: communityId }
    }
    );
  };

  const goToPopularBrandList = () => {
    goTo("/BrandCommunityList", { state: { brandListType: BrandListTabType.TOTAL } });
  };

  const actionBrandSubscribe = async (brand: BrandCommunityLineUpResponse, index: number) => {
    if (brand?.communityId) {
      await brandSubscribe(brand?.communityId).then((res) => {
        if (res.successOrNot === "Y") {
          renderData?.splice(index, 1, {
            ...brand,
            subscriptionYn: "Y",
            communitySubscriberCount: (Number(brand.communitySubscriberCount) + 1).toString(),
          });
          showBottomToast(t("screen.brandCommunity.message.subscribe"), bottomOffset());
        }
      });
    }
  };

  return (
    <>
      {renderData.length > 0 && (
        <div>
          <div style={styles.line_height_10} />
          <div style={styles.bx_in_title}>
            <div style={styles.title_text}>
              <div style={styles.bg_title}></div>
              <p style={styles.txt_title}>{t("screen.brandCommunity.label.todayBrand")}</p>
            </div>
            <button style={styles.header_more_btn} onClick={goToPopularBrandList}>
              <p style={styles.header_more_btn_txt}>{t("screen.brandCommunity.button.more")}</p>
              <BtnMore />
            </button>
          </div>
          <Virtuoso
            style={{ ...styles.brand_list }}
            useWindowScroll
            data={renderData ? renderData.slice(0, 5) : []}
            initialItemCount={renderData.length}
            itemContent={(index: number, item: BrandCommunityLineUpResponse) => {
              return (
                <div style={styles.brand_item} key={index}>
                  <button
                    style={styles.info_wrap}
                    onClick={() => {
                      goToBrandPageHome(item.communityId);
                    }}
                  >
                    <FastImageWithFallback
                      style={styles.profileImage}
                      source={{ uri: `${PUBLIC_BUCKET_URL}${item.communityLogoUrl || ""}` }}
                      fallbackImageUri={FALLBACK_IMAGE_URL}
                    />
                    <div style={styles.info_text_wrap}>
                      <p style={styles.txt_info_title} >
                        {item.communityName}
                      </p>
                      <p style={styles.txt_info_detail}>
                        {item.communityIntroduce}
                      </p>
                      <div style={styles.num_view_wrap}>
                        <div style={styles.num_item_wrap}>
                          <p style={styles.view_text1}>{t("screen.brandCommunity.label.subscriber")}</p>
                          <p style={styles.view_text2}>{countFollow(Number(item.communitySubscriberCount))}</p>
                        </div>
                        <div style={styles.div_dot}></div>
                        <div style={styles.num_item_wrap}>
                          <p style={styles.view_text1}>{t("screen.brandCommunity.label.article")}</p>
                          <p style={styles.view_text2}>{countFollow(Number(item.communityArticleCount))}</p>
                        </div>
                        <div style={styles.div_dot}></div>
                        <div style={styles.num_item_wrap}>
                          <p style={styles.view_text1}>{t("screen.brandCommunity.label.product")}</p>
                          <p style={styles.view_text2}>{countFollow(Number(item.communityProductCount))}</p>
                        </div>
                      </div>
                    </div>
                  </button>

                  {item.subscriptionYn == "Y" ? (
                    <div style={{ ...styles.btn_follow, ...styles.btn_following }}>
                      <IconCheck />
                      <p style={{ ...styles.txt_btn_follow, ...styles.txt_btn_following }}>
                        {t("screen.brandCommunity.label.subscribing")}
                      </p>
                    </div>
                  ) : (
                    <TouchableWithAuthCheck
                      style={styles.btn_follow}
                      onPress={() => {
                        void actionBrandSubscribe(item, index);
                      }}
                    >
                      <p style={styles.txt_btn_follow}>
                        {t("screen.brandCommunity.button.subscribe")}
                      </p>
                    </TouchableWithAuthCheck>
                  )}
                </div>
              );
            }}
          />
          <div style={styles.line_height_10} />
        </div>
      )}
    </>
  );
});

export default TodayBrand;

export const styles: Styles = {
  line_height_10: {
    height: 10,
    backgroundColor: "#f5f5f5",
  },
  txt_title: {
    fontSize: 18,
    fontWeight: "500",
    color: "#222",
    zIndex: 1,
    position: "relative",
  },
  bg_title: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#F7E237",
    height: 8,
  },
  bx_in_title: {
    paddingLeft: 16,
    paddingRight: 16,
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...FlexBoxs.wrap,
    ...Spacings.margin_top_20,
    ...Arranges.between,
    ...Arranges.center_h,
  },
  title_text: {
    ...Positions.relative,
  },
  header_more_btn: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  header_more_btn_txt: {
    color: "#999",
    fontSize: 14,
    letterSpacing: -0.56,
    marginRight: 2,
  },

  brand_list: {
    ...Spacings.margin_top_20,
    ...Spacings.margin_right_16,
    ...Spacings.margin_left_16,
    ...Colors.background_fff,
  },
  brand_item: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
    ...Spacings.margin_right_8,
    ...Spacings.padding_bottom_20,
    ...FlexBoxs.flex_1,
    overflow: "hidden",
  },
  info_wrap: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
    overflow: "hidden",
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  info_text_wrap: {
    paddingLeft: 14,
    paddingRight: 14,
    ...FlexBoxs.flex,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "auto",
  },
  txt_info_title: {
    fontSize: 16,
    color: "#222",
    fontWeight: "500",
    letterSpacing: -0.64,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  txt_info_detail: {
    fontSize: 12,
    fontWeight: "400",
    color: "#666",
    lineHeight: 18,
    letterSpacing: -0.48,
    marginTop: 5,
    marginBottom: 1.89,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%'
  },
  num_view_wrap: {
    ...FlexBoxs.flex,
    ...FlexBoxs.horizon,
    ...Arranges.center_h,
  },
  num_item_wrap: {
    ...FlexBoxs.flex,
    flexDirection: "row",
    alignItems: "center",
  },
  view_text1: {
    fontSize: 12,
    color: "#666",
    letterSpacing: -0.48,
    marginRight: 2,
  },
  view_text2: {
    fontSize: 12,
    color: "#682C78",
    letterSpacing: -0.48,
  },
  div_dot: {
    ...Colors.background_666,
    width: 3,
    height: 3,
    borderRadius: 3,
    marginHorizontal: 4,
  },
  brand_btn: {
    ...FlexBoxs.flex,
    ...Spacings.border_radius_300,
    ...Spacings.padding_left_right_14,
    ...Arranges.center_v_h,
    ...Colors.background_be69c3,
    height: 36,
  },
  btn_follow: {
    ...FlexBoxs.flex,
    flexDirection: "row",
    width: 52,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#BE69C3",
    borderRadius: 4,
  },
  btn_following: {
    backgroundColor: "#fff",
  },
  txt_btn_follow: {
    color: "#fff",
    fontSize: 14,
    fontWeight: "500",
  },
  txt_btn_following: {
    color: "#BE69C3",
  },
  profileImage: {
    width: 64,
    height: 64,
    borderWidth: 1.4,
    borderColor: "#fff",
    borderRadius: 100,
    overflow: "hidden",
    ...FlexBoxs.flex,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
  },
};
