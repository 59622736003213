import { getTempMemberFromStorage } from "../../service/Preference.service";
import { getSessionUserFromStorage } from "../../service/Session.service";
import { callAPIWithPOSTMethod } from "../common/api";
import { ActHistoryRequest } from "./model";

export const sendActHistory = async (
  actHistory: ActHistoryRequest,
  isLoading = false
): Promise<boolean> => {
  let actMemberUuid = actHistory.actMemberUuid;

  if (!actMemberUuid) {
    const sessionUser = await getSessionUserFromStorage();
    const tempMember = await getTempMemberFromStorage();
    actMemberUuid = sessionUser?.memberUuid || tempMember?.tempMemberUUID || "";
  }

  const response = await callAPIWithPOSTMethod(
    {
      url: "/v1/data/actHistory",
      body: {
        ...actHistory,
        actMemberUuid,
      },
    },
    isLoading
  );

  return response?.successOrNot === "Y" && response?.statusCode === "OK"
    ? true
    : false;
};

// ActHistoryRequest 의 actHistoryTypeCode 는 ActHistoryTypeCode enum 사용
// ActHistoryRequest 의 actPageCode 는 ActPageCode enum 사용
// ActHistoryRequest 의 actSectionCode 는 ActSectionCode enum 사용

// ex) Main 의 추천 피드 클릭 이벤트
// actHistoryTypeCode : "POST_READ"
// actHistoryTargetId : feed_id
// actPageCode : MAIN
// actSectionCode : FEED
// actMemberUuid : 비회원 또는 회원의 uuid
// attr1 : 노출순서
// attr2 : 클릭 시점의 좋아요 수
// attr3 : 클릭 시전의 댓글 수
// attr4 : 상세보기/더보기 여부 (DETAIL or MORE)
