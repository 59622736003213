import { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Colors } from "../../assets/styles";
import { observer } from "mobx-react";
import { FeedMode } from "../../constants/Feed";
import ArticleWrite from "./presenter/ArticleWrite";
import ArticleWriteStore from "../../store/ArticleWriteStore";

const ArticleModifyScreen = observer((data: any) => {
    const location = useLocation();
    const params = location.state;

    const articleId = (useParams()?.articleId || params?.feedId) as number;

    const effectRan = useRef(false);

    /* eslint-disable */
    useEffect(() => {
        if (!effectRan.current) {
            const { clear, getDetailInfo, isEdittingArticle, setEdittingArticle } = ArticleWriteStore;
            if (!isEdittingArticle) {
                clear();
                void getDetailInfo(articleId);
                setEdittingArticle(true);
            }
        }
        effectRan.current = true;
    }, []);
    /* eslint-enable */

    return (
        <div
            style={{
                flex: 1,
                ...Colors.background_fff,
            }}
        >
            <ArticleWrite mode={FeedMode.MODIFY} communityId={ArticleWriteStore.wholeData.communityId} />
        </div>
    );
});

export default ArticleModifyScreen;
