import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationModal } from '../../components/modals'
import { FEED_CALL_STATE, FeedTypeCode } from '../../constants/Feed'
import {
  MainFeedTypeCode,
  UserActTypeCode,
} from '../../constants/FeedType.enum'
// import { goBack, push } from "../../navigation/NavigationFunction";
// import BookmarkStore from "../../store/BookmarkStore";
// import CommentStore from "../../store/CommentStore";
import MainStore from '../../store/MainStore'
import QnaDetailStore from '../../store/QnaDetailStore'
import QnaStore from '../../store/QnaStore'
import QnaDetail from './presenter/QnaDetail'
import { ActHistoryRequest } from '../../api/data/model'
import {
  ActHistoryTypeCode,
  ActPageCode,
  ActSectionCode,
} from '../../constants/ActHistory'
import { sendActHistory } from '../../api/data/api'
import { Qna } from '../../api/qna/model'
import MyHomeStore from '../../store/MyHomeStore'
import { AccountFeedTypeCode } from '../../constants/Account'

import { TitleHeader } from '../../components/common'
import { CommentTypeCode } from '../../constants/Comment'
import { useLocation, useParams } from 'react-router-dom'
import CustomActivityIndicator from '../../components/common/CustomActivityIndicator'
import { canGoBack, goBack, goTo } from '../../hooks/navigate/NavigateFunction'
import JoinOverlapComponent from '../../components/common/JoinOverlapComponent'
import { HeaderItem } from '../../constants/Header'
import AuthStore from '../../store/AuthStore'
import CommonStore from '../../store/CommonStore'
import { useTracker } from '../../hooks/tracker'
import CommentStore from '../../store/CommentStore'
import SearchStore from '../../store/SearchStore'
import TagStore from '../../store/TagStore'
import ReportStore from '../../store/ReportStore'
import { ReportTargetTypeCode } from '../../constants/Report'
import BookmarkStore from '../../store/BookmarkStore'

interface ActData {
  actPageCode: ActPageCode
  actSectionCode?: ActSectionCode
}

const QnaDetailScreen = observer(function QnaDetailScreen(data: any) {
  const location = useLocation()
  const { track } = useTracker()

  const params = location.state
  const feedId = useParams().feedId || (location.state.feedId as number)
  const callState = params?.callState || FEED_CALL_STATE.MY_HOME
  const index = location.state?.index || 0
  const isShowComment: boolean = params?.isShowComment as boolean

  const { deleteQna } = QnaStore
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const { pathname } = useLocation()
  const { sessionUser } = AuthStore
  const { commentCount } = CommentStore
  const { setToastOption } = CommonStore
  const { popQnaFromSearch, reportQnaCount, setReportQnaCount } = SearchStore
  const { popFromMainList, refreshPopularTag } = MainStore
  const { openReportBottomSheet } = ReportStore

  const { initQnaDetailStore, removeQnaDetailStore, syncQna } = QnaStore
  // const { clear } = CommentStore;

  const [showFailPopup, setFailPopup] = useState(false)
  const [isShowBlockedModal, setIsShowBlockedModal] = useState(false)

  const [qnaDetailStore, setQnaDetailStore] = useState<QnaDetailStore>()
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  const [modalButtonName, setModalButtonName] = useState<string>(
    t('common.label.check')
  )

  const callActHistory = useCallback(
    (params: any, qnaDetail: Qna) => {
      if (params && params.actData && qnaDetail && qnaDetail?.feedId >= 0) {
        const actData: ActData = params.actData
        const actHistory: ActHistoryRequest = {
          actHistoryTypeCode: ActHistoryTypeCode.QNA_READ,
          actHistoryTargetId: params.feedId.toString(),
          actPageCode: actData.actPageCode,
          actSectionCode: actData.actSectionCode,
          attr1: index.toString(),
          attr2: qnaDetail.likeCount.toString(),
          attr3: qnaDetail.commentCount.toString(),
        }
        void sendActHistory(actHistory)
      }
    },
    [index]
  )

  const moveToComment = useCallback(() => {
    goTo(`${pathname}/comment`, {
      state: {
        feedId: feedId,
        feedType: CommentTypeCode.QNA,
      },
    })
  }, [feedId])

  useEffect(() => {
    if (isShowComment) {
      moveToComment()
    }
  }, [isShowComment, moveToComment])

  const handleHeaderOptionClick = (type: string) => {
    switch (type) {
      case 'DELETE':
        // setShowDeletePopup(true);
        CommonStore.setShowDownloadAppDrive(true)
        track('delete_qna', {})
        break
      case 'MODIFY':
        if (commentCount > 0) {
          setToastOption({
            show: true,
            message1: t(`screen.qna.message.existReply`),
            // bottomOffset: Platform.OS === "ios" ? insets.bottom : 30,
          })
        } else {
          CommonStore.setShowDownloadAppDrive(true)
          // goTo(`/Qna/Modify/${feedId}`, {
          //   state: {
          //     feedId: feedId,
          //   },
          // });
        }

        track('edit_qna', {})
        break
      case 'REPORT':
        openReportBottomSheet(
          {
            reportTargetId: String(feedId),
            reportTargetTypeCode: ReportTargetTypeCode.QNA,
          },
          () => {
            // TODO: QNA메인 화면 완성되면 거기서도 신고한 QNA는 미노출하도록 추가
            void refreshPopularTag()
            qnaDetailStore?.setIsDeleted(true)

            if (callState === FEED_CALL_STATE.SEARCH_QNA) {
              const count = reportQnaCount
              setReportQnaCount(count + 1)
            }

            deletedFeed(feedId as number)

            if (canGoBack()) {
              goBack()
            }
          }
        )
        break
      default:
        break
    }
  }

  // useEffect(() => {
  //   try {
  //     const routes = location.state.routes;
  //     if (routes && routes.length > 0 && routes[0].state?.history) {
  //       const history = routes[0].state?.history as Array<{
  //         key: string;
  //         type: string;
  //       }>;
  //       if (
  //         history &&
  //         history.length > 0 &&
  //         history[history.length - 1].key.includes("HomeStack")
  //       ) {
  //         setModalButtonName(t("common.label.gotoHome"));
  //       }
  //     } else if (!routes[0].state) {
  //       setModalButtonName(t("common.label.gotoHome"));
  //     }
  //   } catch (e) {
  //     //
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    setLoading(true)

    void QnaStore.initQnaDetailStore(feedId as number)
      .then((res) => {
        setQnaDetailStore(res)
        if (res.errorStatus !== '') {
          setTimeout(() => {
            setFailPopup(true)
            setIsShowBlockedModal(true)
          }, 500)
        } else {
          callActHistory(params, res.qnaDetail)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [feedId, params, callActHistory])

  useEffect(() => {
    return () => {
      if (qnaDetailStore != undefined) {
        const { qnaDetail, clearQnaDetailStore, isDeleted } = qnaDetailStore

        syncQna(
          feedId as number,
          qnaDetail,
          isDeleted ? UserActTypeCode.DELETE : UserActTypeCode.MODIFY
        )

        void MainStore.updateMainListFeed()
        void BookmarkStore.updateBookmarkList(
          feedId as number,
          FeedTypeCode.QNA,
          isDeleted ? undefined : qnaDetail
        )

        if (isDeleted) {
          MyHomeStore.sync(
            feedId as number,
            AccountFeedTypeCode.QNA,
            UserActTypeCode.DELETE
          )
          TagStore.sync(
            feedId as number,
            FeedTypeCode.QNA,
            UserActTypeCode.DELETE
          )
        } else {
          MyHomeStore.updateQna(feedId as number, qnaDetail)
          TagStore.updateQna(feedId as number, qnaDetail)
        }

        CommentStore.clear()
        clearQnaDetailStore()
        removeQnaDetailStore(feedId as number)
        // void FastImage.clearMemoryCache();
      }
    }
  }, [
    qnaDetailStore,
    feedId,
    syncQna,
    // clear,
    feedId,
    removeQnaDetailStore,
  ])

  const deletedFeed = (feedId: number) => {
    popQnaFromSearch(feedId)

    popFromMainList(feedId, [MainFeedTypeCode.POPULAR_QNA])

    MyHomeStore.sync(feedId, AccountFeedTypeCode.QNA, UserActTypeCode.DELETE)

    TagStore.sync(feedId, FeedTypeCode.QNA, UserActTypeCode.DELETE)
  }
  return (
    <div
      style={{ flex: 1, backgroundColor: 'white' }}
      aria-label={'Qna Detail screen'}
    >
      <TitleHeader
        title={t('screen.qnaDetail.label.title')}
        isBack={true}
        needAuth
        isHome
        rightItem={HeaderItem.MORE}
        moreOptionMenuList={
          qnaDetailStore?.qnaDetail?.createMemberUuid ===
          sessionUser?.memberUuid
            ? ['DELETE', 'MODIFY']
            : ['REPORT']
        }
        onClickMore={handleHeaderOptionClick}
        // onClickBack={goBack}
      />
      {!loading ? (
        showFailPopup ? (
          <NotificationModal
            isVisible={isShowBlockedModal}
            contents1={t('common.message.blockedOrDeleted')}
            onRequestClose={() => {
              setIsShowBlockedModal(false)
              // goBack();
            }}
            defaultButtonText={modalButtonName}
          />
        ) : sessionUser ? (
          <QnaDetail
            qnaDetailStore={qnaDetailStore as QnaDetailStore}
            feedId={feedId as number}
            // mainListIndex={mainListIndex}
            index={index}
            callState={callState}
          />
        ) : (
          <JoinOverlapComponent>
            <div
              style={{
                height: '100vh',
                overflowY: 'hidden',
                pointerEvents: 'none',
              }}
            >
              <QnaDetail
                qnaDetailStore={qnaDetailStore as QnaDetailStore}
                feedId={feedId as number}
                // mainListIndex={mainListIndex}
                index={index}
                callState={callState}
              />
            </div>
          </JoinOverlapComponent>
        )
      ) : (
        <CustomActivityIndicator isDarkSpinner={true} />
      )}
      {showDeletePopup && (
        <NotificationModal
          isVisible={showDeletePopup}
          contents1={t('screen.notificationModal.message.deleteMessage')}
          useTwoButton={true}
          isBlack={false}
          defaultButtonText={t('screen.notificationModal.button.OK')}
          extraButtonText={t('screen.notificationModal.button.cancel')}
          onClickDefaultButton={() => {
            setShowDeletePopup(false)
            CommonStore.setLoading(true)

            void deleteQna(feedId as number).then(() => {
              qnaDetailStore?.setIsDeleted(true)
              deletedFeed(feedId as number)

              CommonStore.setLoading(false)
              goBack()
            })
          }}
          onClickExtraButton={() => setShowDeletePopup(false)}
          onRequestClose={() => setShowDeletePopup(false)}
        />
      )}
    </div>
  )
})

export default QnaDetailScreen
