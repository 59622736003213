import { observer } from "mobx-react";
import { Styles } from "../../../assets/types/Style";
import { ReactComponent as HeartOffIcon } from "../../../assets/icons/heart_off.svg";
import { ReactComponent as HeartOnIcon } from "../../../assets/icons/heart_on.svg";
import { ReactComponent as BookmarkOffIcon } from "../../../assets/icons/bookmark_off.svg";
import { ReactComponent as BookmarkOnIcon } from "../../../assets/icons/bookmark_on.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { Arranges, Colors, FlexBoxs, Positions, Spacings, Texts } from "../../../assets/styles";
import LikeStore from "../../../store/LikeStore";
import BookmarkStore from "../../../store/BookmarkStore";
import { useTranslation } from "react-i18next";
import { goTo } from "../../../hooks/navigate/NavigateFunction";
import { CommentTypeCode } from "../../../constants/Comment";
import CommentStore from "../../../store/CommentStore";
import { LikeTypeCode } from "../../../constants/Like";
import TouchableWithAuthCheck from "../../../components/common/TouchableWithAuthCheck";
import { ActHistoryTypeCode, ActPageCode } from "../../../constants/ActHistory";
import { onShare, ShareInfo } from "../../../utils/share";
import TouchableWithAsyncTask from "../../../components/common/TouchableWithAsyncTask";
import { ShareScreenCode } from "../../../constants/ShareScreen.enum";
import ArticleDetailStore from "../../../store/ArticleDetailStore";

const ArticleDetailFooter = observer(() => {
    const {
        action_wrap,
        action_wrap_padding,
        action_left,
        action_right,
        action_icon_btn,
        action_text,
        number_text,
        like_text,
    } = styles;

    const { t } = useTranslation();
    const { saveBookmark, cancelBookmark } = BookmarkStore;
    const { saveLike, cancelLike } = LikeStore;

    const onLikeButtonPress = async () => {
        if (ArticleDetailStore.articleDetail.likeYn === "Y") {
            await cancelLike(
                ArticleDetailStore.articleDetail.articleId,
                "ARTICLE",
                () => {
                    ArticleDetailStore.cancelArticleLike();
                },
                () => {
                    // fail
                }
            );
        } else {
            await saveLike(
                ArticleDetailStore.articleDetail.articleId,
                "ARTICLE",
                () => {
                    ArticleDetailStore.saveArticleLike();
                },
                () => {
                    // fail
                }
            );
        }
    };

    const onBookmarkButtonPress = async () => {
        if (ArticleDetailStore.articleDetail.bookmarkYn == "Y") {
            await cancelBookmark(
                ArticleDetailStore.articleDetail.articleId,
                "ARTICLE",
                () => {
                    ArticleDetailStore.setBookmarkYn("N");
                },
                () => {
                    // fail
                }
            );
        } else {
            await saveBookmark(
                ArticleDetailStore.articleDetail.articleId,
                "ARTICLE",
                () => {
                    ArticleDetailStore.setBookmarkYn("Y");
                },
                () => {
                    // fail
                }
            );
        }
    };

    const onShareButtonPress = () => {
        const actHistoryData = {
            actHistoryTypeCode: ActHistoryTypeCode.ARTICLE_SHARE,
            actPageCode: ActPageCode.ARTICLE_DETAIL,
            feedId: (ArticleDetailStore.articleDetail.articleId ?? 0).toString(),
            likeCount: (ArticleDetailStore.articleDetail.articleLikeCount ?? 0).toString(),
            commentCount: (ArticleDetailStore.articleDetail.commentCount ?? 0).toString(),
        };
        const shareInfo: ShareInfo = {
            title: ArticleDetailStore.articleDetail?.articleTitle,
            descriptionText: ArticleDetailStore.articleDetail?.articleContents,
            screen: ShareScreenCode.ARTICLE_DETAIL_SCREEN,
            targetId: ArticleDetailStore.articleDetail?.articleId,
            path: `ArticleDetail/${ArticleDetailStore.articleDetail?.articleId}`,
        };
        void onShare(shareInfo, actHistoryData);
    };

    const moveToComment = () => {
        CommentStore.clear();
        goTo(`/${CommentTypeCode.ARTICLE}/${ArticleDetailStore.articleDetail?.articleId}/comment`, {
            state: {
                feedId: ArticleDetailStore.articleDetail?.articleId,
                feedType: CommentTypeCode.ARTICLE,
                feedAuthorId: ArticleDetailStore.articleDetail?.createMemberUuid,
            }
        });
    };

    const onLikeListButtonPress = () => {
        goTo("/LikeScreen", {
            state: {
                feedId: ArticleDetailStore.articleDetail.articleId,
                feedType: LikeTypeCode.ARTICLE,
            }
        });
    };

    return (
        <div style={action_wrap}>
            <div style={action_wrap_padding}>
                <div style={action_left}>
                    <TouchableWithAuthCheck
                        onPress={() => {
                            onLikeListButtonPress();
                        }}
                    >
                        <p style={{ ...action_text, ...like_text }}>
                            {t(`screen.article.label.like`)}{" "}
                            <span style={number_text}>{ArticleDetailStore.articleDetail.articleLikeCount}</span>
                        </p>
                    </TouchableWithAuthCheck>

                    <TouchableWithAuthCheck
                        onPress={() => {
                            ArticleDetailStore.articleDetail.articleCanCommentYn === "Y" && moveToComment();
                        }}
                    >
                        <p style={action_text}>
                            {t(`screen.article.label.comment`)}{" "}
                            <span style={number_text}>{ArticleDetailStore.articleDetail.commentCount}</span>
                        </p>
                    </TouchableWithAuthCheck>
                </div>
                <div style={action_right}>
                    <TouchableWithAsyncTask
                        style={action_icon_btn}
                        onPress={onLikeButtonPress}
                    >
                        {ArticleDetailStore.articleDetail.likeYn === "Y" ? <HeartOnIcon /> : <HeartOffIcon />}
                    </TouchableWithAsyncTask>
                    <TouchableWithAsyncTask
                        style={action_icon_btn}
                        onPress={onBookmarkButtonPress}
                    >
                        {ArticleDetailStore.articleDetail.bookmarkYn === "Y" ? <BookmarkOnIcon /> : <BookmarkOffIcon />}
                    </TouchableWithAsyncTask>
                    <button
                        style={action_icon_btn}
                        onClick={onShareButtonPress}
                    >
                        <ShareIcon />
                    </button>
                </div>
            </div>
        </div>
    );
});

export default ArticleDetailFooter;

const styles: Styles = {
    action_wrap: {
        // marginBottom: -10,
    },
    action_wrap_padding: {
        ...Spacings.padding_right_left_20,
        ...Spacings.padding_top_20,
        ...Arranges.between,
        ...Arranges.center_h,
        ...FlexBoxs.horizon,
        ...FlexBoxs.flex
    },
    action_left: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
    },
    action_right: {
        ...FlexBoxs.flex,
        ...FlexBoxs.horizon,
        ...Arranges.center_v_h,
    },
    action_icon_btn: {
        ...Spacings.margin_right_10,
    },
    action_text: {
        ...Texts.contents_text_35,
        ...Texts.font_weight_500,
        ...Colors.font_999,
    },
    number_text: {
        ...Texts.font_weight_500,
        ...Colors.font_666666,
    },
    like_text: {
        ...Spacings.margin_right_10,
    },
    gradient: {
        ...Positions.absolute,
        ...Positions.right,
        ...Positions.left,
        ...FlexBoxs.flex_1,
        top: -30,
        height: 30,
    },
    add_comment_btn: {
        width: 90,
        height: 40,
        ...FlexBoxs.flex,
        ...Arranges.center_v_h,
        ...Colors.background_a760b1,
        ...Spacings.border_radius_4,
    },
    add_comment_btn_text: {
        ...Texts.btn_text_1,
        ...Texts.font_weight_500,
        ...Colors.font_fff,
    },
};
